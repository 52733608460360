import React from 'react';
// import history from '../../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../../store/login/actions';
import * as CommonAction from '../../../store/common/actions';
import { bindActionCreators } from 'redux';
import SignUpForm from './SignUpForm';
import './signUpFirst.scss';
import './getDiv.scss';
// import Card from '../../../components/atoms/Card';
// import heartBox from '../../../Assets/images/SignUp/heartBox.svg';
// import customerCare from '../../../Assets/images/SignUp/customerCare.svg';
// import priceTag from '../../../Assets/images/SignUp/priceTag.svg';
// import saving from '../../../Assets/images/SignUp/saving.svg';
// import backgroundImage from "../../../Assets/images/SignUp/signupbackground.svg";
// import dashboardImage from '../../../Assets/images/SignUp/signUpDashboard.png';
import dashboardImage from '../../../Assets/images/SignUp/updatedImage.svg';
// import signupBannerMobile from '../../../Assets/images/SignUp/signupbanner.svg';
import SignupMain from '../../../Assets/images/SignUp/ActivateAccount';
import ThreeDivSignUp from './ThreeDivSignUp';
import topImage from '../../../Assets/images/DouglasImages/signIn.jpeg';
import TemplateDash from './templateDash';
import insurance from '../../../Assets/images/DouglasImages/serviceIcon/insurance.svg';
import mortgage from '../../../Assets/images/DouglasImages/serviceIcon/mortgage.svg';
import homeWarranty from '../../../Assets/images/DouglasImages/serviceIcon/homeWarranty.svg';
import title from '../../../Assets/images/DouglasImages/serviceIcon/title.svg';
import internet from '../../../Assets/images/DouglasImages/serviceIcon/internet.svg';
import utility from '../../../Assets/images/DouglasImages/serviceIcon/utility.svg';
import homeSecurity from '../../../Assets/images/DouglasImages/serviceIcon/homeSecurity.svg';
import moving from '../../../Assets/images/DouglasImages/serviceIcon/moving.svg';
import alarm from '../../../Assets/images/DouglasImages/serviceIcon/alarm.svg';
import carpenter from '../../../Assets/images/DouglasImages/serviceIcon/carpenter.svg';
import electrician from '../../../Assets/images/DouglasImages/serviceIcon/electrician.svg';
import applianceRepair from '../../../Assets/images/DouglasImages/serviceIcon/applianceRepair.svg';
import AnalyticsService from '../../analytics-service';

type initialProps = {
    history: any;
    loginAction: any;
    loginState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {
    fullName: string;
    email: string;
    password: string;
    phone: string;
    phoneError: string;
    showPassword: boolean;
    nameError: string;
    emailError: string;
    passwordError: string;
    hashCode: any;
    isDataArrived: boolean;
    slug: any;
    // street: any;
    // street2: any;
    // date: any;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    displayName: any;
    display_name: any;
    formStarted: boolean;
};

class SignUpFirst extends React.Component<initialProps, initialState> {
    state: initialState = {
        fullName: '',
        email: '',
        password: '',
        showPassword: false,
        nameError: '',
        emailError: '',
        passwordError: '',
        hashCode: null,
        isDataArrived: true,
        phone: '',
        phoneError: '',
        slug: '',
        // street: '',
        // street2: '',
        // date: '',
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        displayName: '',
        display_name: '',
        formStarted: false
    };

    async componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        await this.setState({
            slug: slug,
        });

        let displayName: any = localStorage.getItem('displayName');
        await this.setState({ displayName: displayName });

        let windowSearch = window.location.search.split('&');
        windowSearch.forEach((item: any) => {
            if (item && item.split('=')[0].includes('hash_code')) {
                this.setState({ hashCode: item.split('=')[1] });
                const payload = {
                    hashCode: item.split('=')[1],
                };
                this.props.commonAction.moveGetWithHashCode(payload);
            }
            if (item && item.split('=')[0].includes('utm_source')) {
                this.setState({ utm_source: item.split('=')[1] });
            }
            if (item && item.split('=')[0].includes('utm_medium')) {
                this.setState({ utm_medium: item.split('=')[1] });
            }
            if (item && item.split('=')[0].includes('utm_content')) {
                this.setState({ utm_content: item.split('=')[1] });
            }
            if (item && item.split('=')[0].includes('utm_campaign')) {
                this.setState({ utm_campaign: item.split('=')[1] });
            }
        });

        const token = localStorage.getItem('token');
        if (token) {
            this.props.history.push('/dashboard');
        }

        AnalyticsService.pageViewed({
            page: 'Signup Page',
            category_name: 'Signup Page Viewed'
        })
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.fullName = `${nextProps.commonState.moveWithHashCode[0].user_ref.first_name} ${nextProps.commonState.moveWithHashCode[0].user_ref.last_name}`;
            currentState.email = nextProps.commonState.moveWithHashCode[0].user_ref.email;
            currentState.phone = nextProps.commonState.moveWithHashCode[0].user_ref.phone;
            // currentState.street = nextProps.commonState.moveWithHashCode[0].source_full_address;
            // currentState.street2 = nextProps.commonState.moveWithHashCode[0].destination_full_address;
            // currentState.date = nextProps.commonState.moveWithHashCode[0].moving_date;
        }

        return currentState;
    }

    handleInputAnalytics = (fieldLabel: string) => {
        if (!this.state.formStarted) {
            AnalyticsService.formFieldStarted({
                page: 'Signup Page',
                category_name: 'Filling Out Signup Form',
                form_pre_filled: location.search.includes('hash_code'),
                form_field_name: fieldLabel
            });
        }
        this.setState({
            formStarted: true
        })
    }

    handleButtonAnalytics = (copy: string) => {
        AnalyticsService.buttonClicked({
            page: 'Signup Page',
            category_name: 'Button Clicked',
            button_copy: copy,
            form_button: true,
            form_pre_filled: location.search.includes('hash_code')
        });
    }

    handleChangeName = (e: any) => {
        if (e.target.value !== '') {
            this.setState({ nameError: '' });
        }
        this.setState({ fullName: e.target.value });
        this.handleInputAnalytics('Full Name');
    };

    handleChangeEmail = (e: any) => {
        if (e.target.value !== '') {
            this.setState({ emailError: '' });
        }
        this.setState({ email: e.target.value.trim() });
        this.handleInputAnalytics('Email ID (this will be your username)');
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    handleChangePhone = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        if (e.target.value !== '') {
            this.setState({ phoneError: '' });
        }
        this.setState({ phone: phonenumber });
        this.handleInputAnalytics('Phone no');
    };

    handleChangePassword = (e: any) => {
        if (e.target.value !== '') {
            this.setState({ passwordError: '' });
        }
        this.setState({ password: e.target.value });
        this.handleInputAnalytics('Create Password');
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    // responseGoogle = (response:any) => {
    //     if (response.wc.access_token) {
    //       const body = {
    //         fullName: response.profileObj.name,
    //         profileName: response.profileObj.givenName,
    //         email: response.profileObj.email,
    //         googleProvider: {
    //           email: response.profileObj.email,
    //           googleId: response.profileObj.googleId,
    //           token: response.wc.access_token
    //         }
    //       }
    //   }
    // }

    // onFailure = (res: any) => {
    //     alert(
    //       `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
    //     );
    //   };

    signInClick = () => {
        this.handleButtonAnalytics('Sign In');
        this.props.history.push('/login');
        window.scrollTo(0, 0);
    };

    validate = () => {
        let validPhone: any = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        let isValid: boolean = true;
        let emailregx: any = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // let fullNameValid: any = /^[a-zA-Z ]*$/;
        let fullNameValid: any = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;
        if (this.state.fullName === '' || !fullNameValid.test(this.state.fullName)) {
            isValid = false;
            this.setState({ nameError: 'Please Provide Full Name' });
        } else {
            this.setState({ nameError: '' });
        }

        if (this.state.email === '') {
            isValid = false;
            this.setState({ emailError: 'Please provide Email' });
        } else if (!this.state.email.match(emailregx)) {
            isValid = false;
            this.setState({ emailError: 'Please enter valid Email ' });
        } else {
            this.setState({ emailError: '' });
        }

        if (this.state.phone === '' || !validPhone.test(this.state.phone)) {
            this.setState({ phoneError: 'Please enter a valid Phone Number' });
            isValid = false;
        } else {
            this.setState({ phoneError: '' });
        }

        if (this.state.password === '') {
            isValid = false;
            this.setState({ passwordError: 'Please provide Password' });
        } else if (this.state.password.length < 8) {
            isValid = false;
            this.setState({ passwordError: 'Password must be minimum 8 letters' });
        } else if (this.state.password.length > 14) {
            isValid = false;
            this.setState({ passwordError: 'Password length exceded it must be maximum 14 letters' });
        } else {
            this.setState({ passwordError: '' });
        }

        // if (!this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')) {
        //     isValid = false;
        //     this.setState({ emailError: 'Please Provide Valid Email' });
        // } else {
        //     this.setState({ emailError: '' });
        // }

        return isValid;
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let displayName: any = localStorage.getItem('displayName');
        let conciergeReferenceField : any = localStorage.getItem('concierge_reference_field');
        return (
            <div className="signup-main-page">
                {isDouglasElliman ? (
                    <div className="signup-getStartedDivDouglas">
                        <div className="topImageClass">
                            <img src={topImage} alt="" />
                        </div>
                        <div className="signup-getStartedLeftDiv">
                            {/* <div className="textDiv"> */}
                            {/* <div className="hello-div-name">
                               Hi, <span className="name">{this.state.fullName.split(' ')[0]}</span>
                           </div>
                           <div className="hello-div">
                               {' '}
                               Welcome to your{' '}
                               {this.state.slug && this.state.slug.slug == 'gomillie'
                                   ? 'PCS'
                                   : this.state.slug && this.state.slug.display_name
                                   ? this.state.slug.display_name
                                   : ''}{' '}
                               Concierge
                           </div>
                           <div className="hello-subPara">Let's get started with your account</div> */}
                            {/* </div> */}
                            {/* <div className="imageDiv">
                           <img src={backgroundImage} className="backgroundImage" /> 
                       </div> */}
                            {/* <div className="signup-getStartedMobile"> */}
                            {/* <div className="signup-getStartedRightDiv"> */}
                            {/* <img src={signupMain} /> */}
                            {/* <SignupMain /> */}
                            {/* </div> */}
                            {/* <div className="hello-subPara">Let's get started with your account</div> */}
                            {/* </div> */}
                            {/* <div> */}
                            <SignUpForm
                                fullName={this.state.fullName}
                                email={this.state.email}
                                password={this.state.password}
                                handleChangeName={this.handleChangeName}
                                handleChangeEmail={this.handleChangeEmail}
                                handleChangePassword={this.handleChangePassword}
                                handleClickShowPassword={this.handleClickShowPassword}
                                showPassword={this.state.showPassword}
                                // responseGoogle={this.responseGoogle}
                                // onFailure={this.onFailure}
                                signInClick={this.signInClick}
                                nameError={this.state.nameError}
                                emailError={this.state.emailError}
                                passwordError={this.state.passwordError}
                                validate={this.validate}
                                hashCode={this.state.hashCode}
                                phone={this.state.phone}
                                handleChangePhone={this.handleChangePhone}
                                phoneError={this.state.phoneError}
                                utm_source={this.state.utm_source}
                                utm_medium={this.state.utm_medium}
                                utm_content={this.state.utm_content}
                                utm_campaign={this.state.utm_campaign}
                                handleButtonAnalytics={this.handleButtonAnalytics}
                            />
                            {/* </div> */}
                        </div>
                        {/* <div className="signup-getStartedRightDiv"> */}
                        {/* <img src={signupMain} /> */}
                        {/* <SignupMain /> */}
                        {/* </div> */}
                    </div>
                ) : (
                    <div className="signup-getStartedDiv">
                        <div className="signup-getStartedLeftDiv">
                            <div className="textDiv">
                                <div className="hello-div-name">
                                    Hi, <span className="name">{this.state.fullName}</span>
                                </div>

                                <div className="hello-div">
                                    Welcome to&nbsp;
                                    {this.state.slug && this.state.slug.slug == 'gomillie'
                                        ? 'PCS'
                                        : displayName
                                        ? displayName
                                        : ''}
                                </div>
                                <div className="hello-subPara">Let's get started with your account</div>
                            </div>
                            {/* <div className="imageDiv">
                            <img src={backgroundImage} className="backgroundImage" /> 
                        </div> */}
                            <div className="signup-getStartedMobile">
                                <div
                                    className={
                                        isDouglasElliman
                                            ? 'signup-getStartedRightDiv signup-getStartedRightDivD'
                                            : 'signup-getStartedRightDiv'
                                    }
                                >
                                    {/* <img src={signupMain} /> */}
                                    <SignupMain />
                                </div>
                            </div>
                            <div>
                                <SignUpForm
                                    fullName={this.state.fullName}
                                    email={this.state.email}
                                    password={this.state.password}
                                    handleChangeName={this.handleChangeName}
                                    handleChangeEmail={this.handleChangeEmail}
                                    handleChangePassword={this.handleChangePassword}
                                    handleClickShowPassword={this.handleClickShowPassword}
                                    showPassword={this.state.showPassword}
                                    // responseGoogle={this.responseGoogle}
                                    // onFailure={this.onFailure}
                                    signInClick={this.signInClick}
                                    nameError={this.state.nameError}
                                    emailError={this.state.emailError}
                                    passwordError={this.state.passwordError}
                                    validate={this.validate}
                                    hashCode={this.state.hashCode}
                                    phone={this.state.phone}
                                    handleChangePhone={this.handleChangePhone}
                                    phoneError={this.state.phoneError}
                                    utm_source={this.state.utm_source}
                                    utm_medium={this.state.utm_medium}
                                    utm_content={this.state.utm_content}
                                    utm_campaign={this.state.utm_campaign}
                                    handleButtonAnalytics={this.handleButtonAnalytics}
                                />
                            </div>
                        </div>
                        <div className="signup-getStartedRightDiv">
                            {/* <img src={signupMain} /> */}
                            <SignupMain />
                        </div>
                    </div>
                )}

                {/* <div className="signupBannerMobile">
                    <img src={signupBannerMobile} />
                </div> */}

                {!isDouglasElliman && (
                    <div>
                        {' '}
                        <div>
                            <div className={isDouglasElliman ? 'signUp-get-div signUp-get-divD' : 'signUp-get-div'}>
                                {/* <div className="get-div-section1"></div> */}

                                <div>
                                    <ThreeDivSignUp />
                                </div>

                                {/* <div className="get-div-section2">
                            <div className="getText">What Do You Get</div>
                            <div className="get-section2-div1">
                                <Card
                                    className="CardSignUp"
                                    imgClassname="CardImgSignUp"
                                    // width="32%"
                                    // height="300px"
                                    cardImage={saving}
                                    cardTitle="Saves time & money"
                                    cardContent="Get the best deals on and set up your TV, internet, and other utilities in one place."
                                />
                                <Card
                                    className="CardSignUp"
                                    imgClassname="CardImgSignUp"
                                    // width="32%"
                                    // height="300px"
                                    cardImage={customerCare}
                                    cardTitle="Lifetime personal assistance"
                                    cardContent="Get lifetime access to your concierge via voice & mobile devices."
                                />
                            </div>
                            <div className="get-section2-div1">
                                <Card
                                    className="CardSignUp"
                                    imgClassname="CardImgSignUp"
                                    // width="32%"
                                    // height="300px"
                                    cardImage={heartBox}
                                    cardTitle="All that in one place"
                                    cardContent="A one stop platform for all your home needs and personal home management."
                                />

                                <Card
                                    className="CardSignUp"
                                    imgClassname="CardImgSignUp"
                                    // width="32%"
                                    // height="300px"
                                    cardImage={priceTag}
                                    cardTitle="Top deals & discounts"
                                    cardContent="Get the best deals on and set up your TV, internet, and other utilities in one place."
                                />
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div
                            className="dashboardDiv"
                            style={{
                                background:
                                    this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59',
                            }}
                        >
                            {/* <div className="dashboard-div-section1"></div> */}

                            {/* <div className="dashboard-div-section2"> */}
                            <div className="dashboardText">
                                As a{' '}
                                {this.state.slug && this.state.slug.slug == 'service member,veteran,military spouse,'
                                    ? 'PCS'
                                    : 'valuable client'}
                                , you can access all these services via the channel of your choice. For Free. For Life
                            </div>
                            <div>
                                <img width="100%" src={dashboardImage} alt="" />
                            </div>
                            {/* </div> */}
                            {/* <img src={dashboardImage} /> */}
                        </div>
                    </div>
                )}
                {isDouglasElliman && (
                    <div className="BelowSectionDouglas">
                        <div className="firstSection">Your Lifetime Move & Home Management Assistant</div>
                        <div>
                            <TemplateDash
                                pageImage="custom"
                                headerText={''}
                                subheaderText={`Your ${
                                    this.state.slug && this.state.slug.slug == 'gomillie'
                                        ? 'PCS'
                                        : conciergeReferenceField
                                        ? conciergeReferenceField
                                        : ''
                                } access begins the day you list your home or go under contract, helping you find the best rates and the right partners to finance and protect your home.`}
                                highlightedText={'Before the Move'}
                                isLeft={true}
                                isRight={false}
                                icon1={insurance}
                                icon2={mortgage}
                                icon3={homeWarranty}
                                icon4={title}
                                icon1Text={'Insurance'}
                                icon2Text={'Mortgage'}
                                icon3Text={'Home Warranty'}
                                icon4Text={'Home Title'}
                                num={1}
                            />
                            <br></br>
                            <TemplateDash
                                pageImage="custom"
                                headerText={''}
                                subheaderText={`Once you are ready for your move, your ${
                                    this.state.slug && this.state.slug.slug == 'gomillie'
                                        ? 'PCS'
                                        : conciergeReferenceField
                                        ? conciergeReferenceField
                                        : ''
                                } makes it easy to compare and set up your internet, TV, electricty, gas, movers and home security in minutes.`}
                                highlightedText={'During the Move'}
                                isLeft={false}
                                isRight={true}
                                icon1={utility}
                                icon2={internet}
                                icon3={moving}
                                icon4={homeSecurity}
                                icon1Text={'Utility'}
                                icon2Text={'Internet'}
                                icon3Text={'Moving'}
                                icon4Text={'Home Security'}
                                num={2}
                            />
                            <br></br>
                            <TemplateDash
                                pageImage="custom"
                                headerText={''}
                                subheaderText={`Your ${
                                    this.state.slug && this.state.slug.slug == 'gomillie'
                                        ? 'PCS'
                                        : conciergeReferenceField
                                        ? conciergeReferenceField
                                        : ''
                                } access doesn't end with your move. Get lifetime access to top rated home pros for your home improvement and home maintenance needs.`}
                                highlightedText={'After the Move'}
                                isLeft={true}
                                isRight={false}
                                icon1={electrician}
                                icon2={alarm}
                                icon3={applianceRepair}
                                icon4={carpenter}
                                icon1Text={'Electrician'}
                                icon2Text={'Alarm and Security'}
                                icon3Text={'Appliance Repair'}
                                icon4Text={'Carpenter'}
                                num={3}
                            />
                            <br></br>
                            <TemplateDash
                                pageImage="custom"
                                headerText={'to give you a competitive edge on all platforms'}
                                subheaderText={
                                    'As a valuable client, you can access all these services via the channel of your choice. For Free. For Life'
                                }
                                isHighlightedFirst={true}
                                highlightedText={'Powerful features'}
                                isLeft={false}
                                isRight={true}
                                num={4}
                            />
                        </div>
                    </div>
                )}
                <div className="footerMobile">{`©${new Date().getFullYear()} ${
                    isDouglasElliman
                        ? 'Douglas Elliman'
                        : displayName
                        ? displayName
                        : this.state && this.state.slug && this.state.slug.name
                } | All right reserved`}</div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        loginAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    loginState: state.login,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFirst);
// export default Login;
