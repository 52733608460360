import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Select, InputLabel, Theme } from '@material-ui/core';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import './Main.scss';
import InputBase from '@material-ui/core/InputBase';

interface defaultProps {
    showDownPaymentneeded?: boolean;
    equityData?:number | any;
    addtionalPaymentData?:number | any;
    yearData?:number
    setYearData?:any
    interestData?:number
    setInterestData?:any
    handleSetErrors?: any;
    errors?: any;
    handleAnalytics?: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0),
        },
    }),
);
let Mortgage: any = [
    { value: '30', label: '30-years fixed' },
    { value: '20', label: '20-years fixed' },
    { value: '15', label: '15-years fixed' },
    { value: '10', label: '10-years fixed' },
];
const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                width: '160px',
                [theme.breakpoints.down('sm')]: {
                    width: '130px',
                },
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            fontWeight: 'bold',

            padding: '18px 26px 18px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
            },
        },
    }),
)(InputBase);

export default function RadioButtonsGroup(props: defaultProps) {

    const {
        showDownPaymentneeded,
        equityData,
        addtionalPaymentData,
        yearData,
        setYearData,
        interestData,
        setInterestData,
        handleSetErrors,
        errors,
        handleAnalytics
    } = props;

    const classes = useStyles();
    const handleChangeMortage = (event: any) => {
        setYearData(event.target.value);
        handleAnalytics('Select Mortage Term', 'term');
    };
    const handleChangeInterest = (event: any) => {
        const value = event.target.value;
        setInterestData(value);
        handleSetErrors(value | 0, 'interestRate');
        handleAnalytics('Interest Rate (APR)', 'apr');
    };

    const showDownPaymentneededStatic = true;
    return (
        <div style={{ width: '100%', margin: 'auto 0px' }}>
            <form>
                <table
                    style={{
                        width: '98%',
                        margin: 'auto',
                    }}
                >
                    <tbody>
                    <tr>
                        <td>
                            <div
                                style={{
                                    fontSize: '12px',
                                }}
                                className="headingText"
                            >
                                Equity
                            </div>
                        </td>
                        {showDownPaymentneeded || showDownPaymentneededStatic ? (
                            <td>
                                <div
                                    style={{
                                        fontSize: '12px',
                                    }}
                                    className="headingText"
                                >
                                    Additional Down Payment Needed
                                </div>
                            </td>
                        ) : (
                            ''
                        )}
                    </tr>
                    <tr>
                        <td width={'50%'}>
                            <div
                                style={{
                                    marginLeft: '10px',
                                }}
                            >
                                <div className="dollarArrangment">$</div>
                                <label className="boldest-24px-text">{equityData || 0}</label>
                            </div>
                        </td>
                        {showDownPaymentneeded || showDownPaymentneededStatic ? (
                            <td width={'50%'}>
                                <div
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                >
                                    <div className="dollarArrangment">$</div>
                                    <label className="boldest-24px-text">{addtionalPaymentData || 0}</label>
                                </div>
                            </td>
                        ) : (
                            ''
                        )}
                    </tr>
                    <tr>
                        <td>
                            <div className="headingText">Select Mortage Term</div>
                        </td>
                        <td>
                            <div className="headingText">Interest Rate (APR)</div>
                        </td>
                    </tr>
                    <tr>
                        <td width="50%">
                            <div className='termSelectCover'>
                                <FormControl className={classes.margin}>
                                    <InputLabel id="demo-customized-select-label"></InputLabel>

                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={yearData}
                                        onChange={handleChangeMortage}
                                        input={<BootstrapInput />}
                                    >
                                        {Mortgage.map((ele: any, i: number) => {
                                            return <MenuItem key={i} value={ele.value}>{ele.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </td>
                        <td className='interestRateField'>
                            <div className="inputFeildMain1 interestRateCover">
                                <span className="per1">%</span>
                                <input
                                    style={{
                                        marginLeft: '14px',
                                    }}
                                    type="number"
                                    className="inputFeild1"
                                    onChange={event => {
                                        handleChangeInterest(event);
                                    }}
                                    value={interestData}
                                />
                            </div>
                            {errors?.interestRate && <span className="errMessage">Should be in range 1-15%.</span>}
                        </td>
                    </tr>
                    </tbody>
                </table>
                
            </form>
        </div>
    );
}
