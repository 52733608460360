import {
    GET_ENERGY_TYPE,
    GET_ENERGY_TYPE_FAILURE,
    GET_ENERGY_TYPE_SUCCESS,
    GET_COMPANY,
    GET_COMPANY_FAILURE,
    GET_COMPANY_SUCCESS,
    GET_ENERGY_PLAN,
    GET_ENERGY_PLAN_FAILURE,
    GET_ENERGY_PLAN_SUCCESS,
    GET_SWITCH_TYPE,
    GET_SWITCH_TYPE_FAILURE,
    GET_SWITCH_TYPE_SUCCESS,
    POST_SUBMIT_CONTRACT,
    POST_SUBMIT_CONTRACT_FAILURE,
    POST_SUBMIT_CONTRACT_SUCCESS,
    GET_ENERGY_SUMMARY,
    GET_ENERGY_SUMMARY_FAILURE,
    GET_ENERGY_SUMMARY_SUCCESS,
    GET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN_FAILURE,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_DISCLAIMER_DETAILS,
    GET_DISCLAIMER_DETAILS_FAILURE,
    GET_DISCLAIMER_DETAILS_SUCCESS,
    GET_ACCOUNT_NUMBER,
    GET_ACCOUNT_NUMBER_FAILURE,
    GET_ACCOUNT_NUMBER_SUCCESS,
} from './actionTypes';
import {
    GetEnergyType,
    GetEnergyTypeRequest,
    GetEnergyTypeSuccess,
    GetEnergyTypeSuccessPayload,
    GetEnergyTypeFailure,
    GetEnergyTypeFailurePayload,
    GetCompany,
    GetCompanyRequest,
    GetCompanySuccess,
    GetCompanySuccessPayload,
    GetCompanyFailure,
    GetCompanyFailurePayload,
    GetEnergyPlan,
    GetEnergyPlanRequest,
    GetEnergyPlanSuccess,
    GetEnergyPlanSuccessPayload,
    GetEnergyPlanFailure,
    GetEnergyPlanFailurePayload,
    GetSwitchType,
    GetSwitchTypeRequest,
    GetSwitchTypeSuccess,
    GetSwitchTypeSuccessPayload,
    GetSwitchTypeFailure,
    GetSwitchTypeFailurePayload,
    PostSubmitContract,
    PostSubmitContractRequest,
    PostSubmitContractSuccess,
    PostSubmitContractSuccessPayload,
    PostSubmitContractFailure,
    PostSubmitContractFailurePayload,
    GetEnergySummary,
    GetEnergySummaryRequest,
    GetEnergySummarySuccess,
    GetEnergySummarySuccessPayload,
    GetEnergySummaryFailure,
    GetEnergySummaryFailurePayload,
    GetAccessToken,
    GetAccessTokenRequest,
    GetAccessTokenSuccess,
    GetAccessTokenSuccessPayload,
    GetAccessTokenFailure,
    GetAccessTokenFailurePayload,
    GetDisclaimerDetails,
    GetDisclaimerDetailsRequest,
    GetDisclaimerDetailsSuccess,
    GetDisclaimerDetailsSuccessPayload,
    GetDisclaimerDetailsFailure,
    GetDisclaimerDetailsFailurePayload,
    GetAccountNumber,
    GetAccountNumberRequest,
    GetAccountNumberSuccess,
    GetAccountNumberSuccessPayload,
    GetAccountNumberFailure,
    GetAccountNumberFailurePayload,
} from './types';

export const getEnergyType = (payload: GetEnergyTypeRequest): GetEnergyType => ({
    type: GET_ENERGY_TYPE,
    payload,
});

export const getEnergyTypeSuccess = (payload: GetEnergyTypeSuccessPayload): GetEnergyTypeSuccess => ({
    type: GET_ENERGY_TYPE_SUCCESS,
    payload,
});

export const getEnergyTypeFailure = (payload: GetEnergyTypeFailurePayload): GetEnergyTypeFailure => ({
    type: GET_ENERGY_TYPE_FAILURE,
    payload,
});

export const getCompany = (payload: GetCompanyRequest): GetCompany => ({
    type: GET_COMPANY,
    payload,
});

export const getCompanySuccess = (payload: GetCompanySuccessPayload): GetCompanySuccess => ({
    type: GET_COMPANY_SUCCESS,
    payload,
});

export const getCompanyFailure = (payload: GetCompanyFailurePayload): GetCompanyFailure => ({
    type: GET_COMPANY_FAILURE,
    payload,
});

export const getEnergyPlan = (payload: GetEnergyPlanRequest): GetEnergyPlan => ({
    type: GET_ENERGY_PLAN,
    payload,
});

export const getEnergyPlanSuccess = (payload: GetEnergyPlanSuccessPayload): GetEnergyPlanSuccess => ({
    type: GET_ENERGY_PLAN_SUCCESS,
    payload,
});

export const getEnergyPlanFailure = (payload: GetEnergyPlanFailurePayload): GetEnergyPlanFailure => ({
    type: GET_ENERGY_PLAN_FAILURE,
    payload,
});

export const getSwitchType = (payload: GetSwitchTypeRequest): GetSwitchType => ({
    type: GET_SWITCH_TYPE,
    payload,
});

export const getSwitchTypeSuccess = (payload: GetSwitchTypeSuccessPayload): GetSwitchTypeSuccess => ({
    type: GET_SWITCH_TYPE_SUCCESS,
    payload,
});

export const getSwitchTypeFailure = (payload: GetSwitchTypeFailurePayload): GetSwitchTypeFailure => ({
    type: GET_SWITCH_TYPE_FAILURE,
    payload,
});

export const postSubmitContract = (payload: PostSubmitContractRequest): PostSubmitContract => ({
    type: POST_SUBMIT_CONTRACT,
    payload,
});

export const postSubmitContractSuccess = (payload: PostSubmitContractSuccessPayload): PostSubmitContractSuccess => ({
    type: POST_SUBMIT_CONTRACT_SUCCESS,
    payload,
});

export const postSubmitContractFailure = (payload: PostSubmitContractFailurePayload): PostSubmitContractFailure => ({
    type: POST_SUBMIT_CONTRACT_FAILURE,
    payload,
});

export const getEnergySummary = (payload: GetEnergySummaryRequest): GetEnergySummary => ({
    type: GET_ENERGY_SUMMARY,
    payload,
});

export const getEnergySummarySuccess = (payload: GetEnergySummarySuccessPayload): GetEnergySummarySuccess => ({
    type: GET_ENERGY_SUMMARY_SUCCESS,
    payload,
});

export const getEnergySummaryFailure = (payload: GetEnergySummaryFailurePayload): GetEnergySummaryFailure => ({
    type: GET_ENERGY_SUMMARY_FAILURE,
    payload,
});

export const getAccessToken = (payload: GetAccessTokenRequest): GetAccessToken => ({
    type: GET_ACCESS_TOKEN,
    payload,
});

export const getAccessTokenSuccess = (payload: GetAccessTokenSuccessPayload): GetAccessTokenSuccess => ({
    type: GET_ACCESS_TOKEN_SUCCESS,
    payload,
});

export const getAccessTokenFailure = (payload: GetAccessTokenFailurePayload): GetAccessTokenFailure => ({
    type: GET_ACCESS_TOKEN_FAILURE,
    payload,
});

export const getAccountNumber = (payload: GetAccountNumberRequest): GetAccountNumber => ({
    type: GET_ACCOUNT_NUMBER,
    payload,
});

export const getAccountNumberSuccess = (payload: GetAccountNumberSuccessPayload): GetAccountNumberSuccess => ({
    type: GET_ACCOUNT_NUMBER_SUCCESS,
    payload,
});

export const getAccountNumberFailure = (payload: GetAccountNumberFailurePayload): GetAccountNumberFailure => ({
    type: GET_ACCOUNT_NUMBER_FAILURE,
    payload,
});

export const getDisclaimerDetails = (payload: GetDisclaimerDetailsRequest): GetDisclaimerDetails => ({
    type: GET_DISCLAIMER_DETAILS,
    payload,
});

export const getDisclaimerDetailsSuccess = (
    payload: GetDisclaimerDetailsSuccessPayload,
): GetDisclaimerDetailsSuccess => ({
    type: GET_DISCLAIMER_DETAILS_SUCCESS,
    payload,
});

export const getDisclaimerDetailsFailure = (
    payload: GetDisclaimerDetailsFailurePayload,
): GetDisclaimerDetailsFailure => ({
    type: GET_DISCLAIMER_DETAILS_FAILURE,
    payload,
});
