import React, { Component } from 'react';
import PlanCard from '../../components/HomeInsurance/insurancePlanCard';
import CallQuotesCard from '../../components/HomeInsurance/callToQuotesCard';
// import SelectInput from '../../components/atoms/SelectInput';
import Button from '../../components/atoms/Button';
// import XfinityIcon from '../../Assets/images/xfinity.png';
import Modal from '../../components/atoms/Modal';
import CoverageDetails from './CoverageDetails';
import PropertyDetailsCard from '../../components/HomeInsurance/PropertyDetailsCard';
import OwnerDetailsCard from '../../components/HomeInsurance/HomeOwnerDetailsCard';
import EditIcon from '@material-ui/icons/Edit';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import * as SignupAction from '../../store/login/signup/actions';
// import '../InternetAndCable/ICmain.scss';
import './insurance.scss';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InfoIcon from '@material-ui/icons/Info';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Footer from './Footer';
import GirlSvg from '../../Assets/images/InsuranceImages/MaskGroup11';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
// import { CircularProgress } from '@material-ui/core';
// import { InlineWidget } from 'react-calendly';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import {
    CallQouteCardSeleton,
    PlanCardSeleton,
} from '../../components/skeletonLoaders/InsuranceSkeleton/insuranceSeleton';
import AnalyticsService from '../analytics-service';
import { trustedFormUrl } from '../../config';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

var intervalVar: any;
type initialProps = {
    totalSteps: number;
    activeStep: number;
    commonAction?: any;
    commonState?: any;
    insuranceAction?: any;
    insuranceState?: any;
    history?: any;
    signupAction?: any;
    signupState?: any;
};

type initialState = {
    sort: any;
    planData: any;
    quoteData: any;
    showCoverage: boolean;
    showModal: boolean;
    property: any;
    insurance_detail: any;
    count: number;
    viewCoverageData: any;
    AnchorEl?: any;
    calendlyOpen: any;
    date: any;
    time: any;
    firstName: any;
    lastName: any;
    email: any;
    phone: any;
    zip: any;
    checked: any;
    submitClick: any;
    isDouglasElliman: boolean;
    hashcode: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    concierge_name: string;
    appointment_type: any;
    moveId: any;
    isAnalitycsTriggerredOnce: any;
};

// let plansList: any = [];

function getAge(dateString: any) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export class PlanPage extends Component<initialProps, initialState> {
    state: initialState = {
        showModal: false,
        sort: { value: 'LH', label: 'Low to High' },
        property: {
            address: '',
            sqFootage: 0,
            yearBuilt: 0,
            noOfStories: '',
            roofType: '',
            heater: '',
            pool: '',
        },
        insurance_detail: {
            name: '',
            age: 0,
            gender: '',
            maritalStatus: '',
        },
        viewCoverageData: null,
        planData: [],
        quoteData: [],
        showCoverage: false,
        count: 0,
        AnchorEl: null,
        calendlyOpen: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zip: '',
        checked: false,
        submitClick: false,
        isDouglasElliman: false,
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        concierge_name: '',
        appointment_type: '',
        moveId: '',
        isAnalitycsTriggerredOnce: {
            date: false,
            time: false,
            firstName: false,
            lastName: false
        }
    };

    trustedUrl: string | null | undefined = null

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        this.setState({
            showModal: true,
        });
        setTimeout(() => {
            this.setState({ showModal: false });
        }, 5000);
        
        this.props.commonAction.moveGet();

        const trusted_url = this.props.history.location.state?.trustedUrl
        this.props.insuranceAction.insuranceQuote({ trusted_url });
        this.props.insuranceAction.insuranceQuoteList();
        intervalVar = setInterval(() => {
            if (this.state.count < 9) {
                this.props.insuranceAction.insuranceQuoteList();
                this.setState({
                    count: this.state.count + 1,
                });
            }
        }, 10000);

        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const move =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            this.setState({
                firstName: move && move.user_ref && move.user_ref.first_name,
                lastName: move && move.user_ref && move.user_ref.last_name,
                email: move && move.user_ref && move.user_ref.email,
                phone: move && move.user_ref && move.user_ref.phone,
                zip: move && move.destination && move.destination.zip_code,
                hashcode: move && move.hash_code,
            });
        } else {
            this.props.commonAction.moveGet();
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            // if (
            //     slug &&
            //     slug.enabled_features &&
            //     slug.enabled_features.includes('Customize Concierge Contact Options')
            // ) {
            //     this.setState({ custom_schedule_call_info_enabled: true });
            // }
        }
        if (slug) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');
            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                `Banner Viewed`,
                {
                    page: 'Home Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    category_name: 'Home Insurance',
                    banner: true,
                    banner_title: 'You could qualify for additional discounts',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }

        if(!document.getElementById("trustedFormScriptTag")){
            const field = 'xxCertUrl';
            const provideReferrer: string = 'false';
            const invertFieldSensitivity = false;
            const tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.id = "trustedFormScriptTag"
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                `://${trustedFormUrl}/trustedform.js?provide_referrer=` +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date().getTime() +
                Math.random() +
                '&invert_field_sensitivity=' +
                invertFieldSensitivity;
            const s = document.getElementsByTagName('script')[0];

            s && s.parentNode && s.parentNode.insertBefore(tf, s);
        }
    }

    componentWillUnmount() {
        document.getElementById("trustedFormScriptTag")?.remove()
        clearInterval(intervalVar);
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.insuranceState;
        const cur = this.props && this.props.insuranceState;
        if (prev.quoteList !== cur.quoteList && cur.quoteList.length > 0) {
            let plansList: any = cur && cur.quoteList;
            let planData: any = [],
                quoteData: any = [];
            plansList.map((el: any) => {
                if (el && el.data && el.data.status === 'error') {
                    quoteData.push({
                        cardImage: el && el.image_url,
                        mobileNumber: el && el.phone_number,
                        allData: el,
                    });
                } else if (el && el.data && el.data.status === 'succeeded') {
                    planData.push({
                        cardImage: el && el.image_url,
                        currency: '$',
                        amount: (parseFloat(el && el.data && el.data.quote && el.data.quote.term_premium) / 12)
                            .toFixed(2)
                            .toString(),
                        yearlyAmount: el && el.data && el.data.quote && el.data.quote.term_premium,
                        planRate: 'month',
                        planValidFor:
                            el && el.data && el.data.quote && el.data.quote.term
                                ? `for ${el.data.quote.term} months`
                                : '',
                        allData: el,
                    });
                }
            });

            if (this.state.sort.value === 'LH') {
                planData = this.sortByKey(planData, 'yearlyAmount');
            } else {
                planData = this.sortByKey(planData, 'yearlyAmount').reverse();
            }

            this.setState({ planData, quoteData });
        }
        if (
            (prevProps && prevProps.signupState && prevProps.signupState.landingMovePost) !==
            (this.props && this.props.signupState && this.props.signupState.landingMovePost)
        ) {
            this.props.commonAction.moveGet();
            const trusted_url = this.props.history.location.state?.trustedUrl
            this.props.insuranceAction.insuranceQuote({ trusted_url });
            this.props.insuranceAction.insuranceQuoteList();
        }

        if (
            prev.appointment !== cur.appointment &&
            cur.appointment &&
            cur.appointment[0] &&
            cur.appointment.status === 200
        ) {
            this.setState({ submitClick: false, calendlyOpen: false });
            if (
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding'
            ) {
                this.props.history.push({
                    pathname: '/landing/insurance/summary',
                    state: {
                        fromLanding: 'fromLanding',
                    },
                });
            } else {
                this.props.history.push({
                    pathname: '/dashboard/home-insurance/summary',
                    state: this.props.history && this.props.history.location && this.props.history.location.state,
                });
            }
        }
    }

    sortByKey = (array: any, key: any) => {
        return array.sort(function(a: any, b: any) {
            var x = parseFloat(a[key]);
            var y = parseFloat(b[key]);

            return x < y ? -1 : x > y ? 1 : 0;
        });
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].property
        ) {
            currentState.firstName =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.first_name;
            currentState.lastName =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.last_name;
            currentState.email =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.email;
            currentState.phone =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].user_ref &&
                nextProps.commonState.move[0].user_ref.phone;
            currentState.zip =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].destination &&
                nextProps.commonState.move[0].destination.zip_code;
            currentState.hashcode = nextProps.commonState.move[0] && nextProps.commonState.move[0].hash_code;
            let property = {
                address: nextProps.commonState.move[0].property.address,
                sqFootage: nextProps.commonState.move[0].property.total_area_sq_ft,
                yearBuilt: nextProps.commonState.move[0].property.year_built,
                noOfStories: `${nextProps.commonState.move[0].property.stories} floors`,
                roofType: nextProps.commonState.move[0].property.roof_material_type
                    ? nextProps.commonState.move[0].property.roof_material_type
                    : 'null',
                heater: nextProps.commonState.move[0].property.heating_type,
                pool: nextProps.commonState.move[0].property.pool_type,
            };

            let insurance_detail = {
                name: `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`,
                age: getAge(nextProps.commonState.move[0].insurance_detail.date_of_birth),
                gender: nextProps.commonState.move[0].insurance_detail.gender,
                maritalStatus: nextProps.commonState.move[0].insurance_detail.marital_status,
            };

            currentState.property = property;
            currentState.insurance_detail = insurance_detail;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            nextProps.commonState.appointmentDetailsArrived = false;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }
        return currentState;
    }
    handleLTH = () => {
        let planData;
        if (this.state.planData) {
            planData = this.sortByKey(this.state.planData, 'yearlyAmount');
        }
        this.setState({
            sort: { value: 'LH', label: 'Low to High' },
            planData,
        });

        this.handleClose();

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'Low to High'
        });
    };

    handleHTL = () => {
        let planData;
        if (this.state.planData) {
            planData = this.sortByKey(this.state.planData, 'yearlyAmount').reverse();
        }
        this.setState({
            sort: { value: 'HL', label: 'High to Low' },
            planData,
        });

        this.handleClose();

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'High to Low'
        });
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };
    // sortByHandler = (e: any) => {
    //     let planData;
    //     if (e.target.value === 'LH' && this.state.planData) {
    //         planData = this.sortByKey(this.state.planData, 'yearlyAmount');
    //     } else {
    //         planData = this.sortByKey(this.state.planData, 'yearlyAmount').reverse();
    //     }
    //     this.setState({
    //         sort: { value: e.target.value, label: e.target.label },
    //         planData,
    //     });
    // };
    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'Schedule A Call',
            banner: true,
            banner_title: 'You could qualify for additional discounts',
        });

        AnalyticsService.scheduleCallStarted({
            page: 'Home Insurance',
            concierge_name: 
            this.props.commonState.move[0] &&
            this.props.commonState.move[0].assigned_concierge &&
            this.props.commonState.move[0].assigned_concierge.full_name,
            appointment_type: 'FULL_MOVE'
        });       
    };

    selectPlan = (item: any) => {
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding'
        ) {
            this.props.history.push({
                pathname: '/landing/insurance/order',
                state: { data: item, type: 'success', fromLanding: 'fromLanding' },
            });

            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Card Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );

            analytics.track(
                'Card Viewed',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );
        } else {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Card Selection Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );

            analytics.track(
                'Card Viewed',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans',
                    brand_name: item && item.carrier_name,
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/dashboard/home-insurance/order',
                state: { data: item, type: 'success' },
            });
        }
    };

    viewCoverage = (e: any, data: any) => {
        e.preventDefault();
        this.setState({ showCoverage: true, viewCoverageData: data });
    };

    closeModal = () => {
        this.setState({ calendlyOpen: false, showCoverage: false, viewCoverageData: null });

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Schedule Call',
            button_copy: 'Cancel',
            popup: true,
            form_button: true,
            popup_title: 'Schedule a Call Back'
        });
    };

    onCall = (item: any) => {
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding'
        ) {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    button_copy: 'Call to Quote',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                    banner: false,
                    banner_title: '',
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/landing/insurance/order',
                state: { data: item, type: 'error', fromLanding: 'fromLanding' },
            });
        } else {
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item && item.carrier_name,
                    card: true,
                    category_name: 'Insurance',
                    product_name: 'Insurance Plans Quotes',
                    brand_name: item && item.carrier_name,
                    banner: false,
                    banner_title: '',
                },
                { user_id: move_id },
            );
            this.props.history.push({
                pathname: '/dashboard/home-insurance/order',
                state: { data: item, type: 'error' },
            });
        }
    };

    inputAnalyticsTracking = (fieldLabel: string, fieldName: string) => {
        if (!this.state.isAnalitycsTriggerredOnce[fieldName]) {
        AnalyticsService.formFieldStarted({
            page: 'Home Insurance',
            category_name: 'Home Insurance Schedule Call',
            button_copy: 'Submit',
            form_button: true,
            form_pre_filled: false,
            form_field_name: fieldLabel
        })

        this.setState({
            isAnalitycsTriggerredOnce: {
                ...this.state.isAnalitycsTriggerredOnce,
                [fieldName]: true
            }
        });
    } else {
        return
    }
    }

    about = () => {};
    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });

        this.inputAnalyticsTracking('Select date', 'date');
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });

        this.inputAnalyticsTracking('Select time', 'time');
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });

        this.inputAnalyticsTracking('First Name', 'firstName');
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });

        this.inputAnalyticsTracking('Last Name', 'lastName');
    };

    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    submitHandler = () => {
        this.setState({ submitClick: true });
        //    if (
        //        this.props.history &&
        //        this.props.history.location &&
        //        this.props.history.location.state &&
        //        this.props.history.location.state.fromLanding &&
        //        this.props.history.location.state.fromLanding == 'fromLanding'
        //    ) {
        //        this.props.history.push({
        //            pathname: '/landing/insurance/order',
        //            state: { data: {}, type: 'error', fromLanding: 'fromLanding' },
        //        });
        //    } else {
        //        this.props.history.push({
        //            pathname: '/dashboard/home-insurance/order',
        //            state: { data: {}, type: 'error' },
        //        });
        //    }
        const { firstName, lastName, email, zip, phone, date, time, checked } = this.state;

        if (firstName && lastName && email && zip && phone && date && time && checked) {
            this.props.insuranceAction.insuranceAppointment({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                zip_code: zip,
                appointment_datetime: `${new Date(date).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`,
                trusted_url: this.trustedUrl,
            });
        }
        
        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Schedule Call',
            button_copy: 'Submit',
            popup: true,
            form_button: true,
            popup_title: 'Schedule a Call Back'
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        const { isDouglasElliman } = this.state;
        const breadList = [
            {
                name: 'Insurance',
                url: ''
            }
        ]

        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="insuranceSteps">
                    <div
                    //  style={{ minHeight: '120vh', overflow: 'auto' }}
                    >
                        <div className="main-head-insurance">
                            <h2 className="heading-insurance-regular">Insurance</h2>
                            <BreadCrumb breadList={breadList} homeCrumb={this.props.history.location?.state?.fromLanding == 'fromLanding' ? 'Home' : 'Dashboard'} />
                        </div>
                        <div className="steps1Insurance">
                            <div className="PlanMainPage">
                                {
                                    // this.state.showModal && (
                                    //     <Modal
                                    //         isShowModal={this.state.showModal}
                                    //         onCloseModal={() => this.setState({ showModal: false })}
                                    //         showClose={false}
                                    //         style={{
                                    //             position: 'absolute',
                                    //             width: '100vw',
                                    //             height: '100vh',
                                    //             backgroundColor: '#ffffff',
                                    //             left: '0px',
                                    //             top: '0px',
                                    //         }}
                                    //     >
                                    //         <div
                                    //             style={{
                                    //                 display: 'flex',
                                    //                 flexDirection: 'column',
                                    //                 justifyContent: 'center',
                                    //                 alignItems: 'center',
                                    //                 height: '100%',
                                    //             }}
                                    //         >
                                    //             <div className="modalHead">Searching for the best rates for you</div>
                                    //             <div className="main-divv">
                                    //                 <div className="modalData">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal">
                                    //                         Fetching best quotes from 40+ leading insurance
                                    //                     </div>
                                    //                 </div>
                                    //                 <div className="modalData1">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal1">
                                    //                         Quotes available for 14061 Old Wye Mills Road, Wye Mills
                                    //                     </div>
                                    //                 </div>
                                    //                 <div className="modalData2">
                                    //                     <CheckCircleIcon />
                                    //                     <div className="textModal2">Personalizing your quote right now</div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </Modal>
                                    // )
                                }
                                <div className="cardsDiv1">
                                    <div className="insurancetitle" style={{marginTop: '10px', textAlign: 'left'}}>Coverage is quoted by InsuraMatch, a licensed insurance agency.</div>
                                    <div className="headerDiv" style={{marginTop: '10px'}}>
                                        <div className="insurancetitle">
                                            Here are some estimated quotes
                                            <div className="titleCount">{` (${this.state.planData.length})`}</div>
                                        </div>
                                        <div className="sortByDiv">
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    className="avg-customer-review"
                                                    onClick={this.handleMenu}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Sort by: <span>{this.state.sort && this.state.sort.label}</span>
                                                </div>
                                                <ExpandMoreIcon />
                                            </div>
                                            <Menu
                                                className="InsuranceDrop"
                                                id="simple-menu"
                                                anchorEl={this.state.AnchorEl}
                                                keepMounted
                                                open={Boolean(this.state.AnchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                                <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                            </Menu>

                                            {/* <div className="sortByText">Sort by price:</div>
                                    <FormControl>
                                        <Select
                                            native
                                            value={this.state.sort.label}
                                            onChange={(e: any) => this.sortByHandler(e)}
                                        >
                                            <option label="Low to High" value="LH">
                                                Low to High
                                            </option>
                                            <option label="High to Low" value="HL">
                                                High to Low
                                            </option>
                                        </Select>
                                    </FormControl> */}
                                        </div>
                                    </div>
                                    <div
                                        className="discountDiv"
                                        style={{
                                            background: isDouglasElliman
                                                ? slug && slug.primary_color
                                                : '#009CBD'
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#1D7E64'
                                                : '#273E59',
                                            padding: isDouglasElliman ? '10px 30px' : '0px 30px',
                                        }}
                                    >
                                        <div className="discountContent">
                                            <div className="discountTitle">
                                                You could qualify for additional discounts
                                            </div>
                                            <div className="discountText">
                                                Click below to speak to our licensed insurance specialists and finalize
                                                your policy
                                            </div>
                                            <div>
                                                {/* <Button backgroundColor="#FFFFFF" color="#273E59" onClick={this.getStarted}>
                                                Get Started
                                            </Button> */}
                                                <Button
                                                    className="btnOrder9"
                                                    backgroundColor="#fff"
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : `0 0 3px 0 ${
                                                                  slug && slug.primary_color
                                                                      ? slug.primary_color
                                                                      : window.location.pathname.includes('landing')
                                                                      ? '#30AB86'
                                                                      : '#273E59'
                                                              }`
                                                    }
                                                    border={
                                                        isDouglasElliman
                                                            ? '1px solid #100B28'
                                                            : `1px solid ${
                                                                  slug && slug.primary_color
                                                                      ? slug.primary_color
                                                                      : '#273E59'
                                                              }`
                                                    }
                                                    width="140px"
                                                    height="40px"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : window.location.pathname.includes('landing')
                                                            ? '#30AB86'
                                                            : '#273E59'
                                                    }
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="14px"
                                                    margin="0 5px 0 0"
                                                    onClick={() => this.calendlyHandle()}
                                                >
                                                    Schedule a Call
                                                </Button>
                                            </div>
                                        </div>
                                        {!isDouglasElliman && (
                                            <div className="discountImgDiv">
                                                {/* <img src={GirlSvg} alt="" /> */}
                                                <GirlSvg />
                                            </div>
                                        )}
                                    </div>

                                    <div className="only-mobile-edit-button">
                                        <div
                                            onClick={() =>
                                                window.location.pathname.includes('landing')
                                                    ? this.props.history.push({
                                                          pathname: '/landing/insurance/steps',
                                                          state: {
                                                              edit: true,
                                                              fromLanding: 'fromLanding',
                                                          },
                                                      })
                                                    : this.props.history.push({
                                                          pathname: '/dashboard/home-insurance/steps',
                                                          state: { edit: true },
                                                      })
                                            }
                                            style={{
                                                color: isDouglasElliman ? '#009CBD' : '#0066F5',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                margin: '35px 0px 20px 0px',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <EditIcon style={{ color: isDouglasElliman ? '#009CBD' : '#0066F5' }} />
                                            <span>Edit Property Details</span>
                                        </div>
                                    </div>

                                    {this.state.planData.length > 0 ? (
                                        this.state.planData.map((item: any, i: number) => {
                                            return (
                                                <div key={i} className="planCardDiv">
                                                    <PlanCard
                                                        width="100%"
                                                        className="plancardInsurancePage"
                                                        // height="300px"
                                                        cardImage={item.cardImage}
                                                        currency={item.currency}
                                                        amount={item.amount}
                                                        yearlyAmount={item.yearlyAmount}
                                                        planRate={item.planRate}
                                                        planValidFor={item.planValidFor}
                                                        selectPlan={() => this.selectPlan(item.allData)}
                                                        viewCoverage={(e: any) => this.viewCoverage(e, item.allData)}
                                                        isDouglasElliman={isDouglasElliman}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <PlanCardSeleton />
                                        // <div
                                        //     style={{
                                        //         marginTop: '20px',
                                        //         display: 'flex',
                                        //         justifyContent: 'center',
                                        //         alignItems: 'center',
                                        //     }}
                                        // >
                                        //     <CircularProgress />
                                        // </div>
                                    )}

                                    <div className="quoteDiv">
                                        <div className="headerDiv">
                                            <div className="insurancetitle">
                                                These quotes are not available online, but may be available when you
                                                call
                                                <div className="titleCount">{` (${this.state.quoteData.length})`}</div>
                                            </div>
                                        </div>

                                        {this.state.quoteData.length > 0 ? (
                                            this.state.quoteData.map((item: any, i: number) => {
                                                return (
                                                    <div key={i} className="planCardDiv">
                                                        <CallQuotesCard
                                                            width="100%"
                                                            // height="300px"
                                                            className="callquoteInsurance"
                                                            cardImage={item.cardImage}
                                                            mobileNumber={item.mobileNumber}
                                                            onCall={() => this.onCall(item.allData)}
                                                            about={this.about}
                                                            isDouglasElliman={isDouglasElliman}
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <CallQouteCardSeleton />
                                            // <div
                                            //     style={{
                                            //         marginTop: '20px',
                                            //         display: 'flex',
                                            //         justifyContent: 'center',
                                            //         alignItems: 'center',
                                            //     }}
                                            // >
                                            //     <CircularProgress />
                                            // </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mobileDetailsDiv">
                                    <div style={{ margin: '20px 0px' }}>
                                        <OwnerDetailsCard
                                            name={this.state.insurance_detail.name}
                                            age={this.state.insurance_detail.age}
                                            gender={this.state.insurance_detail.gender}
                                            maritalStatus={this.state.insurance_detail.maritalStatus}
                                        />
                                    </div>
                                </div>
                                <div className="detailsDiv">
                                    <div className="propertyDetails">
                                        <PropertyDetailsCard
                                            address={this.state.property.address}
                                            sqFootage={this.state.property.sqFootage}
                                            yearBuilt={this.state.property.yearBuilt}
                                            noOfStories={this.state.property.noOfStories}
                                            roofType={this.state.property.roofType}
                                            heater={this.state.property.heater}
                                            pool={this.state.property.pool}
                                            history={this.props.history}
                                            isDouglasElliman={isDouglasElliman}
                                        />
                                    </div>
                                    <div>
                                        <OwnerDetailsCard
                                            name={this.state.insurance_detail.name}
                                            age={this.state.insurance_detail.age}
                                            gender={this.state.insurance_detail.gender}
                                            maritalStatus={this.state.insurance_detail.maritalStatus}
                                        />
                                    </div>
                                </div>

                                {this.state.showCoverage ? (
                                    <Modal
                                        isShowModal={this.state.showCoverage}
                                        onCloseModal={this.closeModal}
                                        showClose={false}
                                        className="insuranceRecommendationMainPageModal"
                                    >
                                        <CoverageDetails onBack={this.closeModal} data={this.state.viewCoverageData} />
                                    </Modal>
                                ) : null}
                            </div>
                            <div>
                                <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                                    *The insurance quote(s) displayed above are provided by licensed insurance agencies
                                    or carriers and are preliminary estimates only and are subject to change based on
                                    additional information we may receive later in the quoting process, the coverages,
                                    deductibles and payment options you choose along with other discounts, features or
                                    savings that may apply.
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.calendlyOpen && (
                        <Modal
                            isShowModal={this.state.calendlyOpen}
                            onCloseModal={() => this.setState({ calendlyOpen: false })}
                            showClose={false}
                            // className="scheduleCallInsuranceMain"
                            className="modalClasses"
                        >
                            <div>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon
                                        onClick={() => this.setState({ calendlyOpen: false })}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    <div style={{ fontSize: '16px', color: '#333333', fontWeight: 'bolder' }}>
                                        Call Schedule Details
                                    </div>
                                    <div className="ModalTimeDate">
                                        <div>
                                            {/* <TextField
                                                id="date"
                                                label="Select Date"
                                                defaultValue={Date.now}
                                                value={this.state.date}
                                                onChange={(e: any) => this.dateHandler(e)}
                                                type="date"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Select date"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    value={this.state.date ? new Date(this.state.date) : null}
                                                    onChange={(e: any) => this.dateHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    autoOk={true}
                                                    error={
                                                        (this.state.submitClick && !this.state.date) ||
                                                        (this.state.date !== '' && !Date.parse(this.state.date))
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {(this.state.submitClick && !this.state.date && 'Please select date') ||
                                                    (this.state.date !== '' &&
                                                        !Date.parse(this.state.date) &&
                                                        'Invalid Date')}
                                            </div>
                                        </div>
                                        <div>
                                            {/* <TextField
                                                id="time"
                                                label="Select Time"
                                                value={this.state.time}
                                                defaultValue="12:00"
                                                onChange={(e: any) => this.timeHandler(e)}
                                                type="time"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    ampm={true}
                                                    variant="inline"
                                                    margin="normal"
                                                    id="time"
                                                    inputVariant="filled"
                                                    label="Select time"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    value={this.state.time ? this.state.time : null}
                                                    onChange={(e: any) => this.timeHandler(e)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    keyboardIcon={<AccessAlarmIcon />}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    // InputProps={{
                                                    //     onFocus: () => {
                                                    //         this.setState({ isOpen1: true });
                                                    //     },
                                                    // }}
                                                    error={
                                                        (this.state.submitClick && !this.state.time) ||
                                                        (this.state.time !== '' && !Date.parse(this.state.time))
                                                    }
                                                    // open={this.state.isOpen1}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {(this.state.submitClick && !this.state.time && 'Please select time') ||
                                                    (this.state.time !== '' &&
                                                        !Date.parse(this.state.time) &&
                                                        'Invalid Date')}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <form>
                                        <div className="peronalDetailsInsurance">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.submitClick && this.state.firstName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.submitClick && this.state.lastName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                // onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                // onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Zip code"
                                                value={this.state.zip}
                                                // onChange={(e: any) => this.zipHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <input type="hidden" name="xxCertUrl" ref={(input) => { this.trustedUrl = input?.value }}/>
                                        </div>
                                    </form>
                                    <div className={isDouglasElliman ? 'checkboxMain' : 'checkboxnoDoug'}>
                                        <FormControlLabel
                                            label="By clicking the 'Submit' button, you are providing your signed written consent to receive calls or texts from InsuraMatch and its agents to market insurance products at the number (wireless or otherwise) you provided above. As part of that consent, you understand and agree that (1) those calls may be auto-dialed and use artificial or pre-recorded voice messages or SMS communications., and (2) your agreement to receive these calls is not required to purchase any goods or services, and (3) InsuraMatch will not sell your information to third parties without your consent. You agree to InsuraMatch's Terms & Conditions, Compensation and Information Disclosure and Privacy Policy and authorize InsuraMatch and the companies it represents to obtain consumer reports on the persons listed in your application."
                                            className="checkbox11"
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.checked}
                                                    onChange={this.handleChange}
                                                    name="checkedG"
                                                />
                                            }
                                        />
                                        <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                            {this.state.submitClick && !this.state.checked && 'Please accept'}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={`0 0 3px 0 ${
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }`}
                                        width="130px"
                                        height="50px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        borderRadius="5px"
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius="5px"
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.submitHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                            {/* <div className="scheduleCallBack" onClick={() => this.setState({ calendlyOpen: false })}>
                                <ArrowBackIcon />
                            </div>
                            <InlineWidget
                                styles={{ height: '100%' }}
                                url={
                                    moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url
                                }
                                prefill={{
                                    email:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.email,
                                    firstName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.first_name,
                                    lastName:
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        this.props.commonState.move[0].user_ref.last_name,
                                }}
                            /> */}
                            {/* <div className="iFrame" >
                     { moveData && moveData.assigned_concierge
                             && moveData.assigned_concierge.nylas_full_service_scheduler_url ?   
                               <iframe src={`${moveData && moveData.assigned_concierge
                             && moveData.assigned_concierge.nylas_full_service_scheduler_url}?utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas&name=${moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.first_name.concat(moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.last_name) }&email=${moveData &&
                                moveData.user_ref &&
                                moveData.user_ref.email}&phone=${moveData &&
                                    moveData.user_ref &&
                                    moveData.user_ref.phone}&hash_code=${moveData &&
                                        moveData.hash_code}&interested_services=home-insurance`}
                                allow="encrypted-media"
                                // position="relative"
                                className="iframe"
                                //  referrerPolicy="same-origin"
                                //  sandbox="allow-scripts"
                                id="myFrame"



                            ></iframe> : <div>Nylas Url Not Found</div>


                                    }
                        </div> */}
                        </Modal>
                    )}
                    <Footer history={this.props.history} />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    insuranceState: state.insurance,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanPage);
