import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
// import CloseIcon from '@material-ui/icons/Close';
// import TextField from '@material-ui/core/TextField';
import customerServiceImg from '../../Assets/images/SecurityLanding/Concierge.png';
// import { InlineWidget } from 'react-calendly';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import * as SignupAction from '../../store/login/signup/actions';
import Skeleton from 'react-loading-skeleton';
import { scheduleSegment } from '../../utils/utlis';
type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    signupAction?: any;
    signupState?: any;
};
type initialState = {
    calendlyOpen: boolean;
    calendlyOpen2: boolean;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    isDataArrived: boolean;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    logo: any;
    full_name: string;
    phone2: string;
    openForm: boolean;
    custom_schedule_call_info_enabled: any;
    isDouglasElliman: boolean;
    hashcode: string;
    concierge_name: string;
    appointment_type: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
};
export class Footer extends Component<initialProps, initialState> {
    state = {
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        calendlyOpen2: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        calendlyOpen: false,
        isDataArrived: true,
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        logo: '',
        full_name: '',
        phone2: '',
        openForm: false,
        custom_schedule_call_info_enabled: false,
        isDouglasElliman: false,
        hashcode: '',
        concierge_name: '',
        appointment_type: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
    };
    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        } else {
            let moveData = this.props.commonState.move[0];
            this.setState({
                appointment_details: moveData.appointment_details,
                reschedule_link: moveData.appointment_details && moveData.appointment_details.reschedule_link,
                cancel_link: moveData.appointment_details && moveData.appointment_details.cancellation_link,
                nylas_full_service_scheduler_url:
                    //  nextProps.commonState.move[0].full_service_scheduler_url
                    moveData.full_service_scheduler_url,
                appointmentTime: moveData.appointment_details && moveData.appointment_details.start_time,
                phone2: moveData && moveData.assigned_concierge && moveData.assigned_concierge.phone,
                lastName: moveData.user_ref && moveData.user_ref.last_name,
                email: moveData.user_ref && moveData.user_ref.email,
                phone: moveData.user_ref && moveData.user_ref.phone,
                firstName: moveData.user_ref && moveData.user_ref.first_name,
                moveId: moveData.id,
                hashcode: moveData.hash_code,
                // custom_schedule_call_info: moveData.realtor && moveData.realtor.custom_schedule_call_info,
            });
        }

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding' &&
            this.props.history.location.state.data
        ) {
            const data = this.props.history.location.state.data;
            const dest: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                moveId: data && data.id,
                hashcode: data && data.hash_code,
            });
        }
    }
    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Button Clicked',
            'Home Security Footer',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Home Security',
            'Schedule A Call',
            false,
            ``,
            false,
            ' ',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Home Security',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
    };

    // calendlyHandleCancel=()=>{
    //     this.setState({ calendlyOpen: true,
    //         scheduleClicked:false,
    //         rescheduleClicked:false,
    //         cancelClicked:true
    //      });

    // }
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        // if(this.state.cancelClicked){
        //     let countNew=0
        //     setInterval(() => {
        //         if (countNew < 5) {
        //             this.props.commonAction.appointmentGet(payload)
        //                 countNew=countNew + 1
        //         }
        //     }, 5000);

        // }else{
        this.props.commonAction.appointmentGet(payload);
        setTimeout(() => {
            this.props.commonAction.moveGet();
        }, 2000);
        // }
    };
    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
        });
    };
    closeModal = () => {
        this.setState({ calendlyOpen2: false, isSubmitClicked: false, openForm: false });
    };

    closeCalendlyModal = async () => {
        await this.setState({ isSubmitClicked: true });

        if (this.state.openForm) {
            if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
                let payload = {
                    move_id: this.state.moveId,
                    body: {
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone,
                    },
                };
                this.props.commonAction.scheduleCallPost(payload);
                this.setState({ openForm: false });
            }
        } else {
            if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
                await this.setState({ calendlyOpen2: false, isSubmitClicked: false });

                const domain = window.location.hostname;
                let name = domain.split('.');
                const payload = {
                    destination_detail: this.state.address,
                    destination_home: '1',
                    moving_date: new Date().toISOString().split('T')[0],
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    type: 'security',
                    user_ref: {
                        email: this.state.email,
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        // password: password,
                        phone: this.state.phone,
                        site_name:
                            name[0] == 'security'
                                ? 'dtc-marketing'
                                : name[0] == 'security-demo'
                                ? 'apps-demo'
                                : 'moveeasy-demo',
                    },
                };
                this.props.signupAction.landingMovePost(payload);
            }
        }
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.signupState && nextProps.signupState.landingMovePosted) {
            nextProps.signupState.landingMovePosted = false;
            // nextProps.signupState.landingtokenSet = false;
            const { street, state, zipcode, city } = currentState;
            nextProps.history.push({
                pathname: '/dashboard/home-security/summary',
                state: {
                    fromLanding: 'fromLanding',
                    data: {
                        destination: {
                            lookup_name: city,
                            zip_code: zipcode,
                            state_data: state,
                        },
                        destination_street: street,
                        destination_full_address: currentState.address,
                    },
                    date: currentState.date,
                    time: currentState.time,
                },
            });
        }
        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.concierge_name =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.concierge_name;
            currentState.appointment_type =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.appointment_type;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.logo =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    ? nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    : '';
            currentState.full_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.full_name;
            currentState.phone2 =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.phone;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            // currentState.custom_schedule_call_info =
            //     nextProps.commonState.move[0].realtor &&
            //     nextProps.commonState.move[0].realtor.custom_schedule_call_info;
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.openForm = false;
            currentState.isSubmitClicked = false;
        }
        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        // console.log(this.props);
        let prev: any = prevProps.commonState;
        let cur: any = this.props.commonState;
        if (
            prev.scheduleCallPost !== cur.scheduleCallPost &&
            cur.scheduleCallPost.status &&
            cur.scheduleCallPost.status === 202
        ) {
            this.props.commonState.isScheduleCallPost = false;
            this.setState({ openForm: false, isSubmitClicked: false });
        }
    }

    formHandler = () => {
        this.setState({
            openForm: true,
        });
    };

    render() {
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.state;
        return (
            <div className="HSFooter">
                <div className="footerDashboard">
                    {this.state.logo ? (
                        <div style={{ marginRight: '10px' }}>
                            <img
                                src={
                                    this.state.logo
                                        ? this.state.logo
                                        : this.props &&
                                          this.props.history &&
                                          this.props.history.location &&
                                          this.props.history.location.state &&
                                          this.props.history.location.state.fromLanding &&
                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? customerServiceImg
                                        : ''
                                }
                                width="45px"
                                height="45px"
                                style={{ borderRadius: '50%' }}
                            />
                        </div>
                    ) : (
                        <Skeleton />
                    )}
                    <div className="footerNameDetails">
                        <div className="footerNameDetails1">
                            {this.state.full_name ? (
                                this.state.full_name
                            ) : this.props &&
                              this.props.history &&
                              this.props.history.location &&
                              this.props.history.location.state &&
                              this.props.history.location.state.fromLanding &&
                              this.props.history.location.state.fromLanding == 'fromLanding' ? (
                                'Jeff Nevers'
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                        {this.state.full_name ||
                        (this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.fromLanding &&
                            this.props.history.location.state.fromLanding == 'fromLanding') ? (
                            <div className="footerNameDetails2">Home Security Specialist</div>
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    {this.state.full_name ? (
                        <div className="footerNamequestion">
                            <div className="footerNamequestion1">Have Questions?</div>
                            <div className="footerNamequestion2">{this.state.full_name}</div>
                        </div>
                    ) : (
                        <Skeleton />
                    )}
                    {this.state.custom_schedule_call_info_enabled ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="footerQuestion">
                                <span style={{ color: '#006DFF', fontWeight: 800 }}>
                                    Get up to{' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>$</span>
                                    1,700
                                </span>{' '}
                                worth of free home security equipment
                            </div>
                            <Button
                                className="footerbtn"
                                backgroundColor="#FFFFFF"
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : slug && slug.primary_color
                                        ? `0 0 3px 0 ${slug.primary_color}`
                                        : '0 0 3px 0 #273E59'
                                }
                                border={
                                    isDouglasElliman
                                        ? '1px solid #100B28'
                                        : `1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                }
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273E59'
                                }
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                width="120px"
                                height="35px"
                                fontSize="12px"
                                fontWeight="600"
                                onClick={() => this.formHandler()}
                            >
                                Contact Us
                            </Button>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {this.state.reschedule_link && this.state.appointmentTime ? (
                                <div className="appointment-time-div-2">
                                    <div className="details-2"> Appointment Details </div>
                                    <div className="details-content-2">
                                        {' '}
                                        Your Call is scheduled on{' '}
                                        {`${new Date(this.state.appointmentTime)
                                            .toString()
                                            .substring(0, 15)},${new Date(
                                            this.state.appointmentTime,
                                        ).toLocaleTimeString('en-US')} `}{' '}
                                    </div>
                                </div>
                            ) : this.state.nylas_full_service_scheduler_url ? (
                                <div className="footerQuestion">
                                    <span style={{ color: '#006DFF', fontWeight: 800 }}>
                                        Get up to{' '}
                                        <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>$</span>
                                        1,700
                                    </span>{' '}
                                    worth of free home security equipment
                                </div>
                            ) : (
                                <Skeleton />
                            )}

                            {this.state.reschedule_link ? (
                                <div>
                                    <Button
                                        className="footerbtn"
                                        color="#FFFFFF"
                                        width="120px"
                                        height="35px"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="12px"
                                        fontWeight="600"
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color
                                                          ? slug.primary_color
                                                          : window.location.pathname.includes('landing')
                                                          ? '#30ab86'
                                                          : '#273e59'
                                                  }`
                                        }
                                        onClick={() => this.calendlyHandleReschedule()}
                                    >
                                        Reschedule
                                    </Button>
                                </div>
                            ) : this.state.nylas_full_service_scheduler_url ? (
                                <Button
                                    className="footerbtn"
                                    backgroundColor="#FFFFFF"
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : slug && slug.primary_color
                                            ? `0 0 3px 0 ${slug.primary_color}`
                                            : '0 0 3px 0 #273E59'
                                    }
                                    border={
                                        isDouglasElliman
                                            ? '1px solid #100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30ab86'
                                            : '#273e59'
                                    }
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30ab86'
                                            : '#273e59'
                                    }
                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                    width="120px"
                                    height="35px"
                                    fontSize="12px"
                                    fontWeight="600"
                                    onClick={() => this.calendlyHandle()}
                                >
                                    Schedule a Call
                                </Button>
                            ) : (
                                <Skeleton height="30px" width="200px" />
                            )}
                        </div>
                    )}

                    {/* <div className="footerCallDetails">
                        <div style={{ color: '#333333', fontSize: '14px', margin: '0 10px' }}>or</div>
                        <div style={{ color: '#333333', fontSize: '17px' }}>
                            Call {moveData && moveData.assigned_concierge && moveData.assigned_concierge.phone}
                        </div>
                    </div> */}
                </div>
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="scheduleCallMoving"
                    >
                        <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}
                        {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                        (this.state.rescheduleClicked && this.state.reschedule_link) ||
                        (this.state.cancelClicked && this.state.cancel_link) ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.scheduleClicked
                                            ? this.state.nylas_full_service_scheduler_url
                                            : this.state.rescheduleClicked
                                            ? this.state.reschedule_link
                                            : this.state.cancelClicked
                                            ? this.state.cancel_link
                                            : null
                                    }&utm_source=platform&utm_medium=web&utm_content=footer&utm_campaign=security-platform
                                    
                                        `}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}

                {this.state.calendlyOpen2 ||
                    (this.state.openForm && (
                        <Modal
                            isShowModal={this.state.calendlyOpen2 || this.state.openForm}
                            onCloseModal={this.closeModal}
                            showClose={false}
                            className="modalClassesSecurity"
                        >
                            <div style={{ height: '100%' }}>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    {/* <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Call Schedule Details
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disablePast
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date"
                                                            className="date11"
                                                            value={this.state.date ? new Date(this.state.date) : null}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            autoOk={true}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.date) ||
                                                                (this.state.date !== '' && !Date.parse(this.state.date))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.date &&
                                                            'Please select date') ||
                                                            (this.state.date !== '' &&
                                                                !Date.parse(this.state.date) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            disableToolbar
                                                            ampm={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="time"
                                                            inputVariant="filled"
                                                            label="Select time"
                                                            className="date11"
                                                            value={this.state.time ? this.state.time : null}
                                                            onChange={(e: any) => this.timeHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            keyboardIcon={<AccessAlarmIcon />}
                                                            autoOk={true}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen1: true });
                                                            //     },
                                                            // }}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.time) ||
                                                                (this.state.time !== '' && !Date.parse(this.state.time))
                                                            }
                                                        // open={this.state.isOpen1}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.time &&
                                                            'Please select time') ||
                                                            (this.state.time !== '' &&
                                                                !Date.parse(this.state.time) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <div className="peronalDetailsInsurance">
                                        <div className="flex-div">
                                            <div className="div-1-name">
                                                <TextField
                                                    id="time"
                                                    label="First Name"
                                                    value={this.state.firstName}
                                                    onChange={(e: any) => this.firstNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.firstName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.firstName === ''
                                                        ? 'Please enter first name'
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="div-2-name">
                                                <TextField
                                                    id="time"
                                                    label="Last Name"
                                                    value={this.state.lastName}
                                                    onChange={(e: any) => this.lastNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.lastName === ''
                                                        ? 'Please enter last name'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <TextField
                                            id="time"
                                            label="Email"
                                            value={this.state.email}
                                            onChange={(e: any) => this.emailHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.email === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.email === ''
                                                ? 'Please enter email'
                                                : this.state.isSubmitClicked &&
                                                  !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                ? 'Please enter valid email'
                                                : ''}
                                        </div>
                                        <TextField
                                            id="time"
                                            label="Phone Number"
                                            value={this.state.phone}
                                            onChange={(e: any) => this.phoneHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.phone === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.phone === ''
                                                ? 'Please enter phone number'
                                                : this.state.isSubmitClicked && this.state.phone.length < 12
                                                ? 'Please enter valid phone number'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFFFFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : slug && slug.primary_color
                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                : '0 0 3px 0 #273E59'
                                        }
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273E59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        width="130px"
                                        height="50px"
                                        // color={'#30ab86'}

                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // color={
                                        //     isDouglasElliman
                                        //         ? '#100B28'
                                        //         : slug && slug.primary_font_color
                                        //         ? slug.primary_font_color
                                        //         : window.location.pathname.includes('landing')
                                        //         ? '#30ab86'
                                        //         : '#273e59'
                                        // }
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color
                                                          ? slug.primary_color
                                                          : window.location.pathname.includes('landing')
                                                          ? '#30ab86'
                                                          : '#273e59'
                                                  }`
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.closeCalendlyModal()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    ))}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
