export const HEADER_OPTIONS = 'HEADER_OPTIONS';
export const HEADER_OPTIONS_SUCCESS = 'HEADER_OPTIONS_SUCCESS';
export const HEADER_OPTIONS_ERROR = 'HEADER_OPTIONS_ERROR';

export const MOVE_API_GET = 'MOVE_API_GET';
export const MOVE_API_GET_SUCCESS = 'MOVE_API_GET_SUCCESS';
export const MOVE_API_GET_ERROR = 'MOVE_API_GET_ERROR';

export const APPOINTMENT_DETAILS_GET = 'APPOINTMENT_DETAILS_GET';
export const APPOINTMENT_DETAILS_GET_SUCCESS = 'APPOINTMENT_DETAILS_GET_SUCCESS';
export const APPOINTMENT_DETAILS_GET_ERROR = 'APPOINTMENT_DETAILS_GET_ERROR';

export const MOVE_ADDRESS_UPDATE = 'MOVE_ADDRESS_UPDATE';
export const MOVE_ADDRESS_UPDATE_SUCCESS = 'MOVE_ADDRESS_UPDATE_SUCCESS';
export const MOVE_ADDRESS_UPDATE_ERROR = 'MOVE_ADDRESS_UPDATE_ERROR';

export const REALTOR_DETAILS = 'REALTOR_DETAILS';
export const REALTOR_DETAILS_SUCCESS = 'REALTOR_DETAILS_SUCCESS';
export const REALTOR_DETAILS_ERROR = 'REALTOR_DETAILS_ERROR';

export const REQUEST_SERVICE = 'REQUEST_SERVICE';
export const REQUEST_SERVICE_SUCCESS = 'REQUEST_SERVICE_SUCCESS';
export const REQUEST_SERVICE_ERROR = 'REQUEST_SERVICE_ERROR';

export const REQUEST_SERVICE_GET = 'REQUEST_SERVICE_GET';
export const REQUEST_SERVICE_GET_SUCCESS = 'REQUEST_SERVICE_GET_SUCCESS';
export const REQUEST_SERVICE_GET_ERROR = 'REQUEST_SERVICE_GET_ERROR';

export const MOVE_API_WITH_HASHCODE_GET = 'MOVE_API_WITH_HASHCODE_GET';
export const MOVE_API_WITH_HASHCODE_GET_SUCCESS = 'MOVE_API_WITH_HASHCODE_GET_SUCCESS';
export const MOVE_API_WITH_HASHCODE_GET_ERROR = 'MOVE_API_WITH_HASHCODE_GET_ERROR';

export const POST_CANCELLATION_GET = 'POST_CANCELLATION_GET';
export const POST_CANCELLATION_GET_SUCCESS = 'POST_CANCELLATION_GET_SUCCESS';
export const POST_CANCELLATION_GET_ERROR = 'POST_CANCELLATION_GET_ERROR';

// featured enable schedule call post
export const SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED = 'SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED';
export const SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS = 'SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS';
export const SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR = 'SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR';

// service agent/provider data
export const GET_SERVICE_AGENTS_SUCCESS = 'GET_SERVICE_AGENTS_SUCCESS';
export const GET_SERVICE_PROVIDERS_SUCCESS = 'GET_SERVICE_PROVIDERS_SUCCESS';
export const GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS = 'GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS';


export const REQUEST_SERVICE_AGENT_BY_SERVICES = 'REQUEST_SERVICE_AGENT_BY_SERVICES';
export const REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS = 'REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS';
export const REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR = 'REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR';