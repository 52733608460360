import React, { Component } from 'react';
import './title.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as SignUpAction from '../../store/login/signup/actions';
import Button from '../../components/atoms/Button';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Title from '../../Assets/images/Mortgage&title/titlehome';
import HolidatyVillage from '../../Assets/images/Mortgage&title/holiday_village_white_24dp.svg';
import { scroller } from 'react-scroll';
import Circle from '../../Assets/images/Mortgage&title/check_circle_black_24dp.svg';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import Modal from '../../components/atoms/Modal';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../utils/utlis';
import _ from 'lodash';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    signupAction: any;
    signupState: any;
    commonAction?: any;
    commonState?: any;
    history?: any;
    location?: any;
};

type initialState = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    continueClick: boolean;
    enquire: boolean;
    option: any;
    id: string | number;
    slug: any;
    moveId: any;
    isDouglasElliman: boolean;
    hashcode: any;
    customBrokerageName: any;
    prefilled: boolean;
    folderOpen1: boolean;
    pdfDouglas1: any;
    enqiry1: boolean;
    brokerage_name: string;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
};

class Mortgage extends Component<initialProps, initialState> {
    state: initialState = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        continueClick: false,
        option: 'email',
        enquire: false,
        id: '',
        slug: null,
        moveId: '',
        isDouglasElliman: false,
        hashcode: '',
        customBrokerageName: '',
        prefilled: false,
        folderOpen1: false,
        pdfDouglas1: null,
        enqiry1: false,
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        window.scrollTo(0, 0);
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            slug: slug,
            brokerage_name: slug.display_name,
            brokerage_id: slug.brokerage_link,
            agent_name: slug.contact_name,
            agent_id: slug.id,
        });

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Title',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );

            formStartedSegment(
                'Form Started',
                'Title',
                'Title',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                '',
                'Next',
                true,
                this.state.prefilled,
                1,
                move_id,
            );
        }

        this.props.commonAction.requestServiceGet();
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];

            const user = data && data.user_ref;
            this.setState({
                email: user && user.email,
                firstName: user && user.first_name,
                lastName: user && user.last_name,
                phone: user && user.phone,
                moveId: data && data.id,
                hashcode: data && data.hash_code,
                customBrokerageName: data && data.realtor && data.realtor.display_name,
            });
            if (user && user.email && user.first_name && user.last_name && user.phone) {
                this.setState({
                    prefilled: true,
                });
            }
        } else {
            this.props.commonAction.moveGet();
        }
        if (this.props.location && this.props.location.state && this.props.location.state.length > 0) {
            this.props.location &&
                this.props.location.state &&
                this.props.location.state.map((el: any) => {
                    if (el && el.name === 'Title') {
                        this.setState({ id: el && el.id, pdfDouglas1: el && el.disclosure_pdf });
                    }
                });
        } else if (
            this.props.commonState &&
            this.props.commonState.header &&
            this.props.commonState.header.length === 0
        ) {
            const isHomeOwner = localStorage.getItem('isHomeOwner');
            this.props.commonAction.header({ partner_slug: slug && slug.slug, fetchMoveService: isHomeOwner ? 1 : 0 });
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const user = cur.move[0] && cur.move[0].user_ref;
            this.setState({
                email: user && user.email,
                firstName: user && user.first_name,
                lastName: user && user.last_name,
                phone: user && user.phone,
                moveId: cur.move[0] && cur.move[0].id,
                hashcode: cur.move[0] && cur.move[0].hash_code,
                customBrokerageName: cur.move[0] && cur.move[0].realtor && cur.move[0].realtor.display_name,
            });
            if (user && user.email && user.first_name && user.last_name && user.phone) {
                this.setState({
                    prefilled: true,
                });
            }
        }
        if (
            prev.requestService !== cur.requestService &&
            cur.requestService &&
            cur.requestService.service_requested === 'title'
        ) {
            this.setState({ enquire: true });
            this.props.commonAction.moveGet();
        }
        if (prev.requestService !== cur.requestService && cur.requestService && cur.requestService.length > 0) {
            cur.requestService.map((el: any) => {
                if (el && el.service_requested === 'title' && el.no_of_request > 0) {
                    this.setState({ enquire: true });
                }
            });
        }
    }

    firstNameHandler = (e: any) => {
        this.setState({ firstName: e.target.value });
    };

    lastNameHandler = (e: any) => {
        this.setState({ lastName: e.target.value });
    };

    emailHandler = (e: any) => {
        this.setState({ email: e.target.value });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({ phone: phonenumber });
    };

    handleRadioChange = (e: any) => {
        this.setState({ option: e.target.value });
    };

    handleInquireAgain = () => {
        this.setState({ enquire: false });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Title',
            this.state.brokerage_name,
            brokerage_key,
            this.state.agent_name,
            agent_key,
            'Title',
            'Inquire Again',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };

    submitHandler = () => {
        this.setState({ continueClick: true });
        const { email, firstName, lastName, phone, option, id, moveId } = this.state;
        if (
            email &&
            firstName &&
            lastName &&
            phone &&
            option &&
            id &&
            moveId &&
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)
        ) {
            this.props.signupAction.signupUpdate({
                moveId: this.state.moveId,
                phoneUpdate: 'phoneUpdate',
                data: {
                    ...(this.props.commonState && this.props.commonState.move && this.props.commonState.move[0]),
                    user_ref: {
                        ...(this.props.commonState &&
                            this.props.commonState.move &&
                            this.props.commonState.move[0] &&
                            this.props.commonState.move[0].user_ref),
                        phone: phone,
                    },
                },
                noRedirect: true,
            });
            if (this.state.pdfDouglas1 != null) {
                this.setState({
                    folderOpen1: true,
                });
            }
            {
                this.props.commonAction.requestService({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    way_of_contact: option,
                    service_date: new Date().toISOString().split('T')[0],
                    service_requested: 'title',
                    service_id: id,
                });
            }
        }

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        formCompletedSegment(
            'Form Completed',
            'Title Form',
            'Title',
            this.state.brokerage_name,
            brokerage_key,
            this.state.agent_name,
            agent_key,
            'Carrington Mortgage Services',
            'Submit',
            true,
            this.state.prefilled,
            'Title Form',
            1,
            move_id,
        );
    };

    lall1 = () => {
        this.state.enqiry1 = true;
        const { email, firstName, lastName, phone, option, id } = this.state;
        if (this.state.enqiry1 == true) {
            this.props.commonAction.requestService({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                way_of_contact: option,
                service_date: new Date().toISOString().split('T')[0],
                service_requested: 'title',
                service_id: id,
            });
            this.state.folderOpen1 = false;
        }
    };

    handleCtaButtonClick = (displayData: any, scrollToClass: string) => {
        if (_.size(displayData) > 0 && _.isEmpty(displayData[0].email_id)) {
            displayData[0].cta_url && window.open(displayData[0].cta_url, '_blank');
        } else {
            scroller.scrollTo(scrollToClass, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -180,
            });
        }
    };

    render() {
        let slug1: any = localStorage.getItem('slug');
        if (slug1) {
            slug1 = JSON.parse(slug1);
        }
        let displayData: any;

        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Title')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Title')
                : [];

        const ishaveDisplayData = _.size(displayData) > 0;

        let detailCard = (
            <div>
                <div style={{ fontWeight: 800, color: '#333', fontSize: '22px', margin: '20px 0 10px 0' }}>Title</div>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            ishaveDisplayData && _.has(displayData[0], 'office_content_data.contact_info')
                                ? displayData[0].office_content_data.contact_info
                                : ishaveDisplayData && displayData[0].office_contact,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            ishaveDisplayData && _.has(displayData[0], 'office_content_data.email_id')
                                ? displayData[0].office_content_data.email_id
                                : ishaveDisplayData && displayData[0].email_id,
                    }}
                />
            </div>
        );

        const { isDouglasElliman } = this.state;
        const ctaButtonText =
            ishaveDisplayData && _.isEmpty(displayData[0].email_id)
                ? displayData[0].CTA
                : this.state.enquire
                ? 'Check Status'
                : 'Inquire Now';

        const headerText =
            ishaveDisplayData &&
            _.has(displayData[0], 'office_content_data.header') &&
            !_.isEmpty(displayData[0].office_content_data.header)
                ? displayData[0].office_content_data.header
                : ishaveDisplayData && displayData[0].header;

        const subheaderText =
            ishaveDisplayData &&
            _.has(displayData[0], 'office_content_data.sub_header') &&
            !_.isEmpty(displayData[0].office_content_data.sub_header)
                ? displayData[0].office_content_data.sub_header
                : ishaveDisplayData && displayData[0].sub_header;

        const imageToDisplay =
            ishaveDisplayData && _.has(displayData[0], 'office_content_data.image')
                ? displayData[0].office_content_data.image
                : ishaveDisplayData && displayData[0].image;

        const disclaimerText =
            ishaveDisplayData && _.has(displayData[0], 'office_content_data.disclaimer')
                ? displayData[0].office_content_data.disclaimer
                : ishaveDisplayData && displayData[0].disclaimer;

        const contextText =
            ishaveDisplayData && _.has(displayData[0], 'office_content_data.content_text')
                ? displayData[0].office_content_data.content_text
                : ishaveDisplayData && displayData[0].content_text;

        const breadList = [
             {
                name: 'Title',
                url: ''
             }
        ]

        return (
            <div className="title-page-container">
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="title"
                            headerText="Get Help With Your"
                            subheaderText={subheaderText}
                            buttonText={ctaButtonText}
                            highlightedText={'Title Services'}
                            onClick={() =>
                                this.handleCtaButtonClick(
                                    displayData,
                                    this.state.enquire ? 'title-bottom-div3' : 'title-bottom-div2',
                                )
                            }
                        />
                    </div>
                ) : (
                    <div>
                        <div className="main-head-title">
                            <h2 className="heading-title">Title</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className="title-main-div">
                            <div className="title-main-div1">
                                <div>
                                    <img src={imageToDisplay} alt="" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                                </div>
                                <div
                                    style={{
                                        color: '#333',
                                        fontSize: '32px',
                                        fontWeight: 800,
                                        margin: '20px 0 10px 0',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {headerText}
                                </div>
                                <div
                                    style={{
                                        color: '#333',
                                        fontSize: '16px',
                                        margin: '0 0 20px 0',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {subheaderText}
                                </div>
                                {ishaveDisplayData &&
                                _.isEmpty(displayData[0].email_id) &&
                                !displayData[0].CTA ? null : (
                                    <div>
                                        <Button
                                            className="btnIC"
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                slug1 && slug1.primary_color
                                                    ? `0 0 3px 0 ${slug1.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            color={slug1 && slug1.primary_color ? `${slug1.primary_color}` : '#273E59'}
                                            borderRadius="5px"
                                            border={
                                                slug1 && slug1.primary_color
                                                    ? `1px solid ${slug1.primary_color}`
                                                    : '1px solid #273E59'
                                            }
                                            width="140px"
                                            height="40px"
                                            fontSize="14px"
                                            fontWeight="600"
                                            onClick={() =>
                                                this.handleCtaButtonClick(
                                                    displayData,
                                                    this.state.enquire ? 'title-bottom-div3' : 'title-bottom-div1',
                                                )
                                            }
                                        >
                                            {ctaButtonText}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="title-main-div2" style={{ marginBottom: '-5px' }}>
                                <Title />
                            </div>
                        </div>
                        <div className="title-mobile-top">
                            <div>
                                <img
                                    src={ishaveDisplayData && displayData[0].image}
                                    style={{ maxWidth: '250px', maxHeight: '125px' }}
                                />
                            </div>
                            <div>
                                <Title className="title-home-img" />
                            </div>
                            <div style={{ color: '#333', fontSize: '32px', fontWeight: 800, margin: '20px 0 10px 0' }}>
                                {headerText}
                            </div>
                            <div style={{ color: '#333', fontSize: '16px', margin: '0 0 30px 0' }}>{subheaderText}</div>
                            <div className="btntitle">
                                <Button
                                    className="btntitle-1"
                                    backgroundColor="#FFFFFF"
                                    boxShadow={
                                        slug1 && slug1.primary_color
                                            ? `0 0 3px 0 ${slug1.primary_color}`
                                            : '0 0 3px 0 #273E59'
                                    }
                                    color={slug1 && slug1.primary_color ? `${slug1.primary_color}` : '#273E59'}
                                    borderRadius="5px"
                                    border={
                                        slug1 && slug1.primary_color
                                            ? `1px solid ${slug1.primary_color}`
                                            : '1px solid #273E59'
                                    }
                                    width="100%"
                                    height="50px"
                                    fontSize="14px"
                                    fontWeight="600"
                                    onClick={() =>
                                        this.handleCtaButtonClick(
                                            displayData,
                                            this.state.enquire ? 'title-bottom-div3' : 'title-bottom-div2',
                                        )
                                    }
                                >
                                    {ctaButtonText}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={
                        isDouglasElliman ? 'title-bottom-div title-bottom-divMainRemoveDouglas' : 'title-bottom-div'
                    }
                >
                    <div className="title-bottom-div1">
                        <div
                            style={{
                                color: '#333',
                                fontSize: '22px',
                                fontWeight: 800,
                                marginBottom: '20px',
                                textTransform: 'capitalize',
                            }}
                        >
                            Looking for a reputable Title company for your new home? Look no further.
                        </div>
                        <div style={{ color: '#6B6C6F', fontSize: '16px', lineHeight: '30px' }}>
                            <div
                                style={{ marginBottom: '25px' }}
                                dangerouslySetInnerHTML={{
                                    __html: contextText,
                                }}
                            ></div>
                        </div>
                    </div>
                    {ishaveDisplayData && _.isEmpty(displayData[0].email_id) ? (
                        <div className="siteRedirectContentCover">
                            <h4>Go to website to learn more</h4>
                            <div className="custom-submitbtn">
                                {displayData[0].CTA && displayData[0].cta_url && (
                                    <Button
                                        className="custom-sub"
                                        border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? `${slug1.primary_color}`
                                                : '#273E59'
                                        }
                                        width="160px"
                                        height="50px"
                                        fontSize="16px"
                                        onClick={() => {
                                            window.open(displayData[0].cta_url, '_blank');
                                        }}
                                    >
                                        {displayData[0].CTA}
                                    </Button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            {!this.state.enquire ? (
                                <div className="title-bottom-div2">
                                    <div
                                        className="title-enquireCardTitle"
                                        style={{
                                            backgroundColor: isDouglasElliman
                                                ? '#100B28'
                                                : slug1 && slug1.primary_color
                                                ? slug1.primary_color
                                                : '#273e59',
                                        }}
                                    >
                                        <div style={{ marginRight: '15px' }}>
                                            <img src={HolidatyVillage} alt="" />
                                        </div>
                                        <div>Inquire for title</div>
                                    </div>
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'title-enquireCardBody inputFieldsDouglasEllimanTitle'
                                                : 'title-enquireCardBody'
                                        }
                                    >
                                        <div className="title-formRow">
                                            <div className="title-formRow1">
                                                <TextField
                                                    className={
                                                        isDouglasElliman ? 'title-input title-inputD' : 'title-input'
                                                    }
                                                    label="First Name"
                                                    id="outlined-start-adornment"
                                                    onChange={this.firstNameHandler}
                                                    variant="filled"
                                                    value={this.state.firstName}
                                                    error={this.state.continueClick && !this.state.firstName}
                                                />
                                                <div className="error">
                                                    {this.state.continueClick &&
                                                        !this.state.firstName &&
                                                        'Please enter first name'}
                                                </div>
                                            </div>
                                            <div className="title-formRow1">
                                                <TextField
                                                    className={
                                                        isDouglasElliman ? 'title-input title-inputD' : 'title-input'
                                                    }
                                                    label="Last Name"
                                                    id="outlined-start-adornment"
                                                    onChange={this.lastNameHandler}
                                                    variant="filled"
                                                    value={this.state.lastName}
                                                    error={this.state.continueClick && !this.state.lastName}
                                                />
                                                <div className="error">
                                                    {this.state.continueClick &&
                                                        !this.state.lastName &&
                                                        'Please enter last name'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-formRow">
                                            <div className="title-formRow1">
                                                <TextField
                                                    className={
                                                        isDouglasElliman ? 'title-input title-inputD' : 'title-input'
                                                    }
                                                    label="Email"
                                                    id="outlined-start-adornment"
                                                    onChange={this.emailHandler}
                                                    variant="filled"
                                                    value={this.state.email}
                                                    error={this.state.continueClick && !this.state.email}
                                                />
                                                <div className="error">
                                                    {this.state.continueClick &&
                                                        !this.state.email &&
                                                        'Please enter email'}
                                                </div>
                                            </div>
                                            <div className="title-formRow1">
                                                <TextField
                                                    className={
                                                        isDouglasElliman ? 'title-input title-inputD' : 'title-input'
                                                    }
                                                    label="Phone"
                                                    id="outlined-start-adornment"
                                                    onChange={this.phoneHandler}
                                                    variant="filled"
                                                    value={this.state.phone}
                                                    error={
                                                        (this.state.continueClick && !this.state.phone) ||
                                                        (this.state.continueClick &&
                                                            this.state.phone !== '' &&
                                                            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                                this.state.phone,
                                                            ))
                                                    }
                                                />
                                                <div className="error">
                                                    {this.state.continueClick && !this.state.phone
                                                        ? 'Please enter phone'
                                                        : this.state.continueClick &&
                                                          this.state.phone &&
                                                          !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                              this.state.phone,
                                                          )
                                                        ? 'Please enter valide phone number'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-radio-text">
                                            Would you prefer for the vendor to contact you by phone or email?
                                        </div>
                                        <div>
                                            <RadioGroup
                                                aria-label="contact"
                                                name="contact"
                                                className={isDouglasElliman ? 'titleRadio titleRadioD' : 'titleRadio'}
                                                value={this.state.option}
                                                onChange={this.handleRadioChange}
                                            >
                                                <FormControlLabel
                                                    className="titleRadio1"
                                                    value="email"
                                                    control={<Radio />}
                                                    label="Email"
                                                />
                                                <FormControlLabel
                                                    className="titleRadio1"
                                                    value="phone"
                                                    control={<Radio />}
                                                    label="Phone"
                                                />
                                            </RadioGroup>
                                        </div>
                                        <div className="title-submitbtn">
                                            <Button
                                                className="title-sub"
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug1 && slug1.primary_color
                                                        ? `${slug1.primary_color}`
                                                        : '#273E59'
                                                }
                                                width="160px"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={this.submitHandler}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        <div className="title-submitbtn1">
                                            <Button
                                                className="title-sub"
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug1 && slug1.primary_color
                                                        ? `${slug1.primary_color}`
                                                        : '#273E59'
                                                }
                                                width="90vw"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={this.submitHandler}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {detailCard}
                                    </div>
                                </div>
                            ) : (
                                <div className="title-bottom-div3">
                                    <div>
                                        <div>
                                            <img src={Circle} alt="" />
                                        </div>
                                        <div className="title-submitted-query">
                                            Your request has been submitted successfully
                                        </div>
                                        <div className="title-submitted-query1">
                                            One of our customer service specialists will get in touch with you.
                                        </div>
                                        <div>
                                            <Button onClick={this.handleInquireAgain}>Inquire Again</Button>
                                        </div>
                                    </div>
                                    {detailCard}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="title-last-div">
                    <div className="title-pleasenote">Please Note:</div>
                    <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                        {(ishaveDisplayData && displayData[0].disclaimer) ||
                        (ishaveDisplayData && _.has(displayData[0], 'office_content_data.disclaimer')) ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: disclaimerText,
                                }}
                            />
                        ) : (
                            'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                        )}
                    </div>
                </div>
                {this.state.pdfDouglas1 ? (
                    this.state.folderOpen1 ? (
                        <Modal
                            isShowModal={this.state.folderOpen1}
                            onCloseModal={() => {
                                this.setState({
                                    folderOpen1: false,
                                });
                            }}
                            showClose={false}
                            className="modalFolderCreateFolderMort"
                        >
                            <div>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            marginLeft: '22px',
                                            fontFamily: 'Roboto',
                                        }}
                                    >
                                        Terms & Conditions
                                    </div>
                                    <div className="closeIconWaranty"></div>
                                </div>
                                <div className="iFrameN">
                                    <iframe
                                        src={this.state.pdfDouglas1 ? this.state.pdfDouglas1 : null}
                                        allow="encrypted-media"
                                        className="iframeMot"
                                        id="myFrame"
                                    ></iframe>
                                    <div></div>
                                </div>
                                <div>
                                    <p>ACKNOWLEDGEMENT</p>
                                    <p>
                                        I/we have read this disclosure form and understand that is{' '}
                                        {this.state.brokerage_name} referring mw/us to purchase the above described
                                        settlement service(s) and may receive a financial or other benefit as the result
                                        of this referral.
                                    </p>
                                </div>
                                <div className="buttonMotDouglas">
                                    <Button onClick={this.lall1}>Accept & Continue</Button>
                                </div>
                            </div>
                        </Modal>
                    ) : null
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        signupAction: bindActionCreators(SignUpAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(Mortgage);
