import React from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonNew from '../atoms/Button';
import '../../styles/dashBoardWelcome.scss';

type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    buttonText2?: string;
    buttonBackGroundColor2?: string;
    buttonColor2?: string;
    buttonShadow2?: string;
    buttonHeight2?: string;
    buttonWidth2?: string;
    onClick?: any;
    onClick2?: any;
    buttonMargin?: any;
    buttonMargin2?: any;
    padding?: any;
};

const DashBoardWelcome = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} welcome-card-main`}
            style={{
                border: props.border ? props.border : '#E2E2E2 1px solid',
                width: props.width ? props.width : '587px',
                height: props.height ? props.height : 'fit-content',
                padding: props.padding ? props.padding : '0px',
            }}
        >
            <div className="main-cards">
                {!isDouglasElliman && (
                    <div className="main-card-1">
                        <img
                            src={props.icon}
                            height={props.imageHeight ? props.imageHeight : '173px'}
                            width={props.imageWidth ? props.imageWidth : '213px'}
                            className="welcome-image"
                        />
                    </div>
                )}
                <div
                    className={isDouglasElliman ? 'main-card-2 left-divD' : 'main-card-2'}
                    style={isDouglasElliman ? { width: '100%' } : {}}
                >
                    <div className="main-title">{props.title}</div>
                    <div className="main-subtitle">{props.subTitle}</div>
                    <div className="main-button-wrapper">
                        <ButtonNew
                            backgroundColor={props.buttonBackGroundColor}
                            boxShadow={props.buttonShadow}
                            color={props.buttonColor}
                            borderRadius="5px"
                            fontSize="14px"
                            fontWeight="600"
                            height={props.buttonHeight ? props.buttonHeight : '40px'}
                            width={props.buttonWidth ? props.buttonWidth : '110px'}
                            margin={props.buttonMargin ? props.buttonMargin : '2px 13px 10px 0px'}
                            onClick={props.onClick}
                        >
                            {props.buttonText}
                        </ButtonNew>

                        <ButtonNew
                            backgroundColor={props.buttonBackGroundColor2}
                            boxShadow={props.buttonShadow2}
                            color={props.buttonColor2}
                            borderRadius="5px"
                            fontSize="14px"
                            fontWeight="600"
                            height={props.buttonHeight2 ? props.buttonHeight2 : '40px'}
                            width={props.buttonWidth2 ? props.buttonWidth2 : '70px'}
                            margin={props.buttonMargin2 ? props.buttonMargin2 : '40px 0px 10px 0px'}
                            onClick={props.onClick2}
                            data-cy="skip-tour-button"
                        >
                            {props.buttonText2}
                        </ButtonNew>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashBoardWelcome;
