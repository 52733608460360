import React from "react";
import { makeStyles } from "@material-ui/core";


interface cardProps {
    width?:string;
    height?:string;
    borderRadius?:string;
    border?:any;
    cardMargin?:string;
    cardPadding?:string;
    onClick?:any;
    className?:string;
    imgWidth?:string;
    imgHeight?:string;
    cardImage?:any;
    // cardTitle?:string;
    cardContent?:string; 
    boxShadow?:any;
    color?:string;
    fontWeight?: any;
}

const CompareCard= (props: cardProps) => {

    const useStyles = makeStyles(() => ({
        root: {
          // "&:hover": {
          //   boxShadow: "0 0 10px #9C9DA1"
          // },
         
        },
    
      }));

      const classes = useStyles();
  return (
    <div
      style={{
        width: props.width ? props.width : "200px",
        height: props.height ? props.height : "500px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: props.borderRadius ? props.borderRadius : "3px",
        boxShadow: props.boxShadow ? props.boxShadow : "0px 12px 38px #0000001A",
        border:props.border ? props.border : null,
        opacity: "1",
        margin: props.cardMargin ? props.cardMargin : "",
        padding: props.cardPadding ? props.cardPadding : "5px",
        cursor: "pointer",
      }}
      onClick={props.onClick}
      className={props.className ? `${props.className} ${classes.root}` : classes.root}
    >
              <div
        className="cardContent"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px",
        }}
      >
      <div style={{display:"flex",justifyContent:"center"}}>
      <img
          width={props.imgWidth ? props.imgWidth : "250px"}
          height={props.imgHeight ? props.imgHeight : "200px"}
          src={props.cardImage}
          alt=""
        />
      </div>

<div>
      {/* <div
        style={{
          height: "14px",
          fontSize:"14px",
          textAlign: "left",
          fontWeight: 800,
          color: "#333",
          opacity: "1",
          margin: "15px 15px",
        //   paddingTop: "5px",
        }}
      >
        {props.cardTitle}
      </div> */}

      </div>

    <div>
    <p
        style={{
          height: "16px",
          textAlign: "center",
          fontSize: "15px",
          color: props.color?props.color:"#333333",
          opacity: "0.8",
          margin: "10px 0px",
          fontWeight: props.fontWeight? props.fontWeight : "normal"
      
        }}
      >
        {props.cardContent}
      </p>
    </div>

    
      </div>

    </div>
  );
};

export default CompareCard;