import React, { Component } from 'react';
import CheckboxText from '../../components/atoms/CheckboxText';
import './utilityFilter.scss';

type initialProps = {
    time: any;
    suppliers: any;
    styles?: any;
    changeTime?: any;
    changeSupplier?: any;
};
type initialState = {};
export class FiltersUtility extends Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="main-filters-utility">
                <div className="headDv-utility">
                    <div className="heads-utility">Contract Term</div>
                </div>

                {this.props.time.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div
                                className={
                                    isDouglasElliman
                                        ? 'checkbox-item-utility checkbox-item-utilityDoug'
                                        : 'checkbox-item-utility'
                                }
                            >
                                <CheckboxText
                                    className="counting-utility"
                                    checked={item.value}
                                    onChange={() => this.props.changeTime(index)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                            </div>
                        </div>
                    );
                })}

                {this.props.suppliers.length ? <div className="line1-utility"></div> : null}
                {this.props.suppliers.length ? <div className="heads-utility">Supplier</div> : null}

                {this.props.suppliers.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div
                                className={
                                    isDouglasElliman
                                        ? 'checkbox-item-utility checkbox-item-utilityDoug'
                                        : 'checkbox-item-utility'
                                }
                            >
                                <CheckboxText
                                    className="counting-utility"
                                    checked={item.value}
                                    onChange={() => this.props.changeSupplier(index)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default FiltersUtility;
