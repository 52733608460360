import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={64.609}
            height={72.984}
            viewBox="0 0 64.609 72.984"
            className="checkBoximg"
        >
            <defs>
                <style>
                    {`.hsStepprefix__a{fill:${
                        slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="hsStepprefix__a"
                d="M42.505 51.27a1.2 1.2 0 00.567-1.018v-8.375a9.572 9.572 0 10-19.143 0v8.375h-3.3a4.755 4.755 0 00-3.384 1.4l-3.384 3.384h-1.9v-1.2a1.2 1.2 0 00-1.2-1.2H0v2.393h9.572v14.366H0v2.393h10.768a1.2 1.2 0 001.2-1.2v-1.2h1.91a13.357 13.357 0 009.148 3.589h6.888a4.791 4.791 0 004.786-4.786v-8.375a4.794 4.794 0 00-3.589-4.634V41.877a2.393 2.393 0 014.786 0v5.982a1.2 1.2 0 00.661 1.07l4.786 2.393a1.2 1.2 0 001.161-.052zm-10.2 8.554v8.375a2.4 2.4 0 01-2.393 2.393h-6.889a10.985 10.985 0 01-7.819-3.239 1.2 1.2 0 00-.846-.35h-2.393v-9.572h2.393a1.2 1.2 0 00.846-.35l3.735-3.735a2.377 2.377 0 011.692-.7h3.3v2.393h-2.395v2.393h8.375a2.4 2.4 0 012.389 2.392zm8.375-11.508l-2.393-1.2v-5.243a4.786 4.786 0 10-9.572 0v13.161h-2.393V41.877a7.179 7.179 0 0114.358 0z"
            />
            <path
                className="hsStepprefix__a"
                d="M63.413 0H3.593a1.2 1.2 0 00-1.2 1.2v49.052h2.393V2.393h27.519v27.519h2.388V2.393h27.519v59.823H37.093v2.393h26.32a1.2 1.2 0 001.2-1.2V1.2a1.2 1.2 0 00-1.2-1.2z"
            />
            <path
                className="hsStepprefix__a"
                d="M29.912 31.108V5.986a1.2 1.2 0 00-1.2-1.2H8.379a1.2 1.2 0 00-1.2 1.2v44.266h2.393V7.179h17.947v23.929zM37.09 53.841v4.786a1.2 1.2 0 001.2 1.2h20.34a1.2 1.2 0 001.2-1.2V5.986a1.2 1.2 0 00-1.2-1.2H38.29a1.2 1.2 0 00-1.2 1.2v25.122h2.393V7.179H57.43v50.252H39.483v-3.59z"
            />
            <path
                className="hsStepprefix__a"
                d="M54.858 24.497a1.2 1.2 0 00-1.018-.567h-4.108l-3.241-5.4a1.2 1.2 0 00-2.176.287l-2.393 8.375 2.3.658 1.635-5.724 2.171 3.618a1.2 1.2 0 001.026.58h2.85l-1.527 3.054a1.2 1.2 0 00.075 1.2l2.044 3.066-1.611 4.029-2.363-1.575a1.2 1.2 0 00-1.851 1.144l.961 7.687-1.788-.6-.757 2.27 3.589 1.2a1.2 1.2 0 001.566-1.283l-.872-6.978 1.414.942a1.2 1.2 0 001.775-.551l2.392-5.982a1.2 1.2 0 00-.115-1.108l-2.008-3.012 2.082-4.165a1.2 1.2 0 00-.052-1.165zM21.569 21.826l2.321-.58-2.393-9.572a1.2 1.2 0 00-2.007-.556l-4.786 4.786a1.2 1.2 0 00-.289 1.224l.818 2.454-2.454.818a1.2 1.2 0 00-.617 1.8l2.008 3.012-3.275 6.556a1.2 1.2 0 00.406 1.531l2.922 1.949-1.039 5.2a1.2 1.2 0 001.174 1.431h3.589a1.2 1.2 0 00.957-.479l3.589-4.786-1.914-1.436-3.23 4.307h-1.532l.91-4.551a1.2 1.2 0 00-.51-1.23l-2.711-1.807 3.117-6.234a1.2 1.2 0 00-.075-1.2l-1.524-2.286 2.1-.7a1.2 1.2 0 00.757-1.513l-.963-2.888 2.769-2.769z"
            />
        </svg>
    );
}

export default SvgComponent;
