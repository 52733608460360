import {
    GET_CHECKLIST_DATA,
    GET_CHECKLIST_DATA_SUCCESS,
    GET_CHECKLIST_DATA_ERROR,
    ADD_TASK,
    ADD_TASK_SUCCESS,
    ADD_TASK_ERROR,
    UPDATE_TASK,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_ERROR,
    DELETE_TASK,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_ERROR,
    SET_REMINDER,
    SET_REMINDER_SUCCESS,
    SET_REMINDER_ERROR,
    UPDATE_REMINDER,
    UPDATE_REMINDER_SUCCESS,
    UPDATE_REMINDER_ERROR,
    DELETE_REMINDER,
    DELETE_REMINDER_SUCCESS,
    DELETE_REMINDER_ERROR,
} from './actionTypes';
import * as types from './types';

export const getChecklist = () => ({
    type: GET_CHECKLIST_DATA,
});

export const getChecklistSuccess = (payload: types.ChecklistGetSuccessPayload): types.ChecklistGetSuccess => ({
    type: GET_CHECKLIST_DATA_SUCCESS,
    payload,
});

export const getChecklistFailure = (payload: types.ChecklistGetFailurePayload): types.ChecklistGetFailure => ({
    type: GET_CHECKLIST_DATA_ERROR,
    payload,
});

export const addTask = (payload: types.ChecklistAddTaskPayload): types.ChecklistAddTask => ({
    type: ADD_TASK,
    payload,
});

export const addTaskSuccess = (payload: types.ChecklistAddTaskSuccessPayload): types.ChecklistAddTaskSuccess => ({
    type: ADD_TASK_SUCCESS,
    payload,
});

export const addTaskFailure = (payload: types.ChecklistAddTaskFailurePayload): types.ChecklistAddTaskFailure => ({
    type: ADD_TASK_ERROR,
    payload,
});

export const updateTask = (payload: types.ChecklistUpdateTaskPayload): types.ChecklistUpdateTask => ({
    type: UPDATE_TASK,
    payload,
});

export const updateTaskSuccess = (
    payload: types.ChecklistUpdateTaskSuccessPayload,
): types.ChecklistUpdateTaskSuccess => ({
    type: UPDATE_TASK_SUCCESS,
    payload,
});

export const updateTaskFailure = (
    payload: types.ChecklistUpdateTaskFailurePayload,
): types.ChecklistUpdateTaskFailure => ({
    type: UPDATE_TASK_ERROR,
    payload,
});

export const deleteTask = (payload: types.ChecklistDeleteTaskPayload): types.ChecklistDeleteTask => ({
    type: DELETE_TASK,
    payload,
});

export const deleteTaskSuccess = (
    payload: types.ChecklistDeleteTaskSuccessPayload,
): types.ChecklistDeleteTaskSuccess => ({
    type: DELETE_TASK_SUCCESS,
    payload,
});

export const deleteTaskFailure = (
    payload: types.ChecklistDeleteTaskFailurePayload,
): types.ChecklistDeleteTaskFailure => ({
    type: DELETE_TASK_ERROR,
    payload,
});

export const setReminder = (payload: types.SetReminderPayload): types.SetReminder => ({
    type: SET_REMINDER,
    payload,
});

export const setReminderSuccess = (payload: types.SetReminderSuccessPayload): types.SetReminderSuccess => ({
    type: SET_REMINDER_SUCCESS,
    payload,
});

export const setReminderFailure = (payload: types.SetReminderFailurePayload): types.SetReminderFailure => ({
    type: SET_REMINDER_ERROR,
    payload,
});

export const updateReminder = (payload: types.UpdateReminderPayload): types.UpdateReminder => ({
    type: UPDATE_REMINDER,
    payload,
});

export const updateReminderSuccess = (payload: types.UpdateReminderSuccessPayload): types.UpdateReminderSuccess => ({
    type: UPDATE_REMINDER_SUCCESS,
    payload,
});

export const updateReminderFailure = (payload: types.UpdateReminderFailurePayload): types.UpdateReminderFailure => ({
    type: UPDATE_REMINDER_ERROR,
    payload,
});

export const deleteReminder = (payload: types.DeleteReminderPayload): types.DeleteReminder => ({
    type: DELETE_REMINDER,
    payload,
});

export const deleteReminderSuccess = (payload: types.DeleteReminderSuccessPayload): types.DeleteReminderSuccess => ({
    type: DELETE_REMINDER_SUCCESS,
    payload,
});

export const deleteReminderFailure = (payload: types.DeleteReminderFailurePayload): types.DeleteReminderFailure => ({
    type: DELETE_REMINDER_ERROR,
    payload,
});
