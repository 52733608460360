import React, { Component } from 'react';
import './checklistMain.scss';
import ChecklistCard from '../../components/atoms/checklistComponents/checklistCard';
import AddTaskCard from '../../components/atoms/checklistComponents/AddTaskCard';
import { AddTasks, EditTask } from '../../components/atoms/checklistComponents/addTasks';
import WeekSwitch from '../../components/atoms/checklistComponents/WeekSwitch';
import * as ChecklistAction from '../../store/checklist/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { CircularProgress } from '@material-ui/core';
import * as CommonAction from '../../store/common/actions';
import Modals from './checklistReminderModal';
import CompletedCards from '../../components/atoms/checklistComponents/ChecklistCompletedCard';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    checklistAction?: any;
    checklistState?: any;
    commonAction?: any;
    commonState?: any;
    location?: any;
};

type initialState = {
    showTasks: boolean;
    task: any;
    showAll: string;
    AnchorEl?: any;
    sort: any;
    history?: any;
    index: number;
    checklistKeys: any;
    movingDate: any;
    editAddtask: boolean;
    editId: any;
    reminderModal: boolean;
    date: any;
    reminders: any;
    emailId: string;
    phoneNumber: any;
    taskData: any;
    emailId1: any;
    phoneNumber1: any;
    isSecondEmail: any;
    tabName: any;
    mainIndex: number;
    hashCode: any;
    isHashDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
};

var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export class checklistMain extends Component<initialProps, initialState> {
    state: initialState = {
        showTasks: false,
        task: '',
        showAll: 'Show All',
        AnchorEl: null,
        sort: { value: 'Weekly', label: 'Weekly' },
        index: -1,
        checklistKeys: [],
        movingDate: '',
        editAddtask: false,
        editId: null,
        reminderModal: false,
        date: '',
        reminders: { phone: false, email: false },
        emailId: '',
        phoneNumber: '',
        phoneNumber1: '',
        taskData: {},
        emailId1: '',
        isSecondEmail: false,
        tabName: 'All',
        mainIndex: -1,
        hashCode: '',
        isHashDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].moving_date;
            this.setState({ movingDate: data });
        } else {
            this.props.commonAction.moveGet();
        }
        this.props.checklistAction.getChecklist();

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        var slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Your Moving Checklist',
                    category_name: 'Your Moving Checklist',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );

            analytics.track(
                'Checklist Viewed',
                {
                    page: 'Your Moving Checklist',
                    category_name: 'Your Moving Checklist',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }
    // if (slug) {
    //     slug = JSON.parse(slug);
    // }
    // if (slug && slug.display_name) {
    //     let brokerage: any;

    //     brokerage = slug.display_name;
    //     let brokerage_id: any = slug.brokerage_link;
    //     let agent_id: any = slug.id;

    //     let agent_name: any = slug.contact_name;
    //     let move_id: any = localStorage.getItem('moveKey');

    //     let windownew: any = window;
    //     let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    //     analytics.page(
    //         'Checklist Viewed',
    //         {
    //             page: 'Your Moving Checklist',
    //             category_name: 'Your Moving Checklist',
    //             brokerage_name: brokerage,
    //             brokerage_id: brokerage_id,
    //             agent_name: agent_name,
    //             agent_key: agent_key,
    //         },
    //         { user_id: move_id },
    //     );
    // }

    static getDerivedStateFromProps(nextProps: any, currentState: any) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashDataArrived
        ) {
            currentState.isHashDataArrived = false;
            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.checklistAction.getChecklist();

                nextProps.commonAction.moveGet();
            } else {
                localStorage.clear();
                history.push({
                    pathname: `/signUp`,
                    search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                });
            }
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].moving_date
        ) {
            currentState.movingDate = nextProps.commonState.move[0].moving_date;
        }
        if (nextProps && nextProps.checklistState) {
            if (
                nextProps.checklistState.checklistTask &&
                nextProps.checklistState.isAdded &&
                Object.keys(nextProps.checklistState.checklistTask).length > 0 &&
                currentState.showTasks
            ) {
                nextProps.checklistState.isAdded = false;
                currentState.task = '';
                currentState.showTasks = false;

                nextProps.checklistAction.getChecklist();
            }
            if (nextProps.checklistState.isDeleted) {
                nextProps.checklistState.isDeleted = false;
                currentState.editAddtask = false;
                currentState.editId = null;

                nextProps.checklistAction.getChecklist();
            }
            if (!nextProps.checklistState.isLoading && nextProps.checklistState.checklist) {
                currentState.checklistKeys = Object.keys(nextProps.checklistState.checklist);
            }

            if (
                nextProps.checklistState.checklistUpdateTask &&
                nextProps.checklistState.isUpdated &&
                Object.keys(nextProps.checklistState.checklistUpdateTask).length > 0
            ) {
                nextProps.checklistState.isUpdated = false;
                currentState.editAddtask = false;
                currentState.editId = null;
                nextProps.checklistAction.getChecklist();
            }
            if (
                nextProps.checklistState.setReminder &&
                nextProps.checklistState.isSetReminder &&
                Object.keys(nextProps.checklistState.setReminder).length > 0
            ) {
                nextProps.checklistState.isSetReminder = false;
                nextProps.checklistAction.getChecklist();
            }
            if (
                nextProps.checklistState.updateReminder &&
                nextProps.checklistState.isUpdateReminder &&
                Object.keys(nextProps.checklistState.updateReminder).length > 0
            ) {
                nextProps.checklistState.isUpdateReminder = false;
                nextProps.checklistAction.getChecklist();
            }
            if (nextProps.checklistState.isDeleteReminder) {
                nextProps.checklistState.isDeleteReminder = false;
                nextProps.checklistAction.getChecklist();
            }
        }

        if (currentState.index < 0 && currentState.checklistKeys.length > 0) {
            // console.log('CurrentState.index>>>', currentState.index);
            // let month = new Date().toLocaleString('default', { month: 'long' })
            //     ? new Date().toLocaleString('default', { month: 'long' })
            //     : currentState.movingDate && months[new Date(currentState.movingDate).getMonth()];

            let month1 = new Date().getMonth();
            let month: any = null;

            let movingMonth = currentState.movingDate && new Date(currentState.movingDate).getMonth();
            let year = new Date(currentState.movingDate).getFullYear();

            if (month1 < movingMonth) {
                month = months[month1];
            }
            if (month1 >= movingMonth) {
                month = months[movingMonth];
            }

            //check current month and moving month if moving month is less than curretn month ,then month =moving month ,if moving month is >=current month then month = current month

            // let today = new Date().getDate();
            // console.log(
            //     'GetMOnths',
            //     month,
            //     currentState.movingDate && months[new Date(currentState.movingDate).getMonth()],
            // );

            currentState.checklistKeys.length > 0 &&
                currentState.checklistKeys.find((el: any, index: number) => {
                    // if (el.includes(month) && currentState.index < 0) {
                    //     currentState.index = index;
                    // }
                    // console.log("Date",el);
                    if (el.includes(month)) {
                        // let date1: number | undefined =
                        //     el.match(/\d+/g).length > 0 && el.match(/\d+/g)[0]
                        //         ? parseInt(el.match(/\d+/g)[0])
                        //         : undefined;
                        // let date2: number | undefined =
                        //     el.match(/\d+/g).length > 0 && el.match(/\d+/g)[1]
                        //         ? parseInt(el.match(/\d+/g)[1])
                        //         : undefined;
                        let elDateSplit = el.split(' to ');
                        // console.log(
                        //     'EL value',
                        //     'parseInt',
                        //     new Date(elDateSplit[0].slice(0, -2)),
                        //     elDateSplit[0].slice(0, -2),
                        // );

                        let date1: any | undefined = new Date(elDateSplit[0].slice(0, -2))
                            ? new Date(new Date(elDateSplit[0].slice(0, -2)).setFullYear(year))
                            : undefined;
                        let date2: any | undefined =
                            elDateSplit[1] && new Date(elDateSplit[1].slice(0, -2))
                                ? new Date(new Date(elDateSplit[1].slice(0, -2)).setFullYear(year))
                                : undefined;
                        // console.log("Date",date1,date2);
                        let moveMonth = new Date(currentState.movingDate);

                        // let day1 =
                        //     months.indexOf(el.split(' ').length > 0 && el.split(' ')[0]) &&
                        //     date1 &&
                        //     new Date(2021, months.indexOf(el.split(' ').length > 0 && el.split(' ')[0]), date1);
                        // let day2 =
                        //     months.indexOf(el.split(' ').length === 5 && el.split(' ')[3]) &&
                        //     date2 &&
                        //     new Date(2021, months.indexOf(el.split(' ').length === 5 && el.split(' ')[3]), date2);
                        // console.log("checking fasle", date1 && date2 && date1 <= new Date() && date2 >= new Date())

                        if (
                            date1 &&
                            date2 &&
                            new Date(moveMonth) >= new Date() &&
                            new Date(date1) <= new Date() &&
                            new Date(date2) >= new Date()
                        ) {
                            currentState.index = index;
                            currentState.mainIndex = index;
                        }

                        if (
                            date1 &&
                            date2 &&
                            new Date(date1).toLocaleString('default', { month: 'long' }) == month &&
                            new Date(date2).toLocaleString('default', { month: 'long' }) == month &&
                            new Date(moveMonth) <= new Date() &&
                            new Date(date1) >= new Date(moveMonth) &&
                            new Date(date2) >= new Date(moveMonth) &&
                            currentState.index == -1
                        ) {
                            currentState.index = index;
                            currentState.mainIndex = index;
                        }

                        // let val1, val2;
                        // val1 = !day2
                        //     ? day1 && (day1 >= new Date() || day1 <= new Date())
                        //     : day1 && day1 > day2
                        //     ? day1 && day1 >= new Date()
                        //     : day1 && day1 <= new Date();
                        // val2 = !day2
                        //     ? false
                        //     : day2 && day1 && day1 < day2
                        //     ? day2 && day1 && day2 >= new Date()
                        //     : day2 && day1 && day2 <= new Date();
                        //     console.log("val1,val2" ,val1,val2)
                        //     console.log("day1,day2" ,day1,day2)
                        //     console.log("date1,date2" ,date1,date2)
                        // if (!day2 && val1 && currentState.index < 0) currentState.index = index;
                        // if (currentState.index < 0 && val1 && val2) {
                        //     currentState.index = index;
                        //     currentState.mainIndex = index;
                        // }
                    }
                });

            if (currentState.index < 0) {
                if (month1 < movingMonth) {
                    currentState.index = currentState.checklistKeys.length - 1;
                }
                if (month1 >= movingMonth) {
                    currentState.index = 0;
                }
            }
        }

        return currentState;
    }

    handleShowTasks = () => {
        this.setState({
            showTasks: !this.state.showTasks,
        });
    };

    handleTasksChange = (e: any) => {
        this.setState({
            task: e.target.value,
        });
    };
    cardClicked1 = () => {};

    onSelectHandler = (e: any) => {
        this.setState({
            showAll: e.value,
        });
    };

    handleWeekly = () => {
        this.setState({
            sort: { value: 'Weekly', label: 'Weekly' },
        });

        this.handleClose();
    };

    handleMonthly = () => {
        this.setState({
            sort: { value: 'Monthly', label: 'Monthly' },
        });

        this.handleClose();
    };

    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    cardClicked = (url: any) => {
        window.open(url, '_blank');
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let brokerage: any;

        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;
        let agent_key: any = slug.realtor_key;

        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Checklist',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Checklist',
            'Read About It',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };
    doneClicked = (id: any) => {
        let payload1 = {
            checklistId: id,
            payload: {
                is_completed: true,
            },
        };
        this.props.checklistAction.updateTask(payload1);
    };
    reminderClicked = async (item: any) => {
        if (item.taskreminder) {
            let email = false,
                phone = false;
            if (item.taskreminder.reminder_option === 'Email') {
                email = true;
            } else if (item.taskreminder.reminder_option === 'Phone') {
                phone = true;
            } else if (item.taskreminder.reminder_option === 'Email & Phone') {
                email = true;
                phone = true;
            }
            await this.setState({
                emailId: item.taskreminder.primary_email ? item.taskreminder.primary_email : '',
                emailId1: item.taskreminder.secondary_email ? item.taskreminder.secondary_email : '',
                phoneNumber: item.taskreminder.primary_phone ? item.taskreminder.primary_phone : '',
                phoneNumber1: item.taskreminder.secondary_phone ? item.taskreminder.secondary_phone : '',
                date:
                    item.taskreminder.datetime && item.taskreminder.datetime.length >= 16
                        ? `${item.taskreminder.datetime.substring(0, 16)}`
                        : '',
                reminders: { phone: phone, email: email },
            });
        }
        this.setState({ reminderModal: true, taskData: item });
    };
    closeModal = () => {
        this.setState({
            reminderModal: false,
            taskData: {},
            date: '',
            emailId: '',
            emailId1: '',
            phoneNumber: '',
            phoneNumber1: '',
            reminders: { phone: false, email: false },
        });
    };
    dateHandler = (e: any) => {
        this.setState({
            date: e.target.value,
        });
    };

    handleSetReminders = (reminder: string) => {
        this.setState({ reminders: reminder });
    };

    handleEmail = (e: any) => {
        this.setState({
            emailId: e.target.value,
        });
    };
    handleEmail1 = (e: any) => {
        this.setState({
            emailId1: e.target.value,
        });
    };

    secondEmailShow = () => {
        this.setState({
            isSecondEmail: true,
        });
    };

    secondEmailRemove = () => {
        this.setState({
            isSecondEmail: false,
            emailId1: '',
            phoneNumber1: '',
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    handlePhone = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({
            phoneNumber: phonenumber,
        });
    };
    handlePhone1 = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({
            phoneNumber1: phonenumber,
        });
    };

    setReminderFinal = () => {
        if (this.state.taskData && this.state.taskData.id && !this.state.taskData.taskreminder) {
            let reminderOption = '';
            if (this.state.reminders.email && this.state.reminders.phone) {
                if (this.state.emailId && this.state.phoneNumber) {
                    reminderOption = 'Email & Phone';
                } else if (this.state.emailId) {
                    reminderOption = 'Email';
                } else if (this.state.phoneNumber) {
                    reminderOption = 'Phone';
                }
            } else if (this.state.reminders.email && this.state.emailId) {
                reminderOption = 'Email';
            } else if (this.state.reminders.phone && this.state.phoneNumber) {
                reminderOption = 'Phone';
            }

            let payload = {
                checklist: this.state.taskData.id,
                datetime: this.state.date ? this.state.date : null,
                reminder_option: reminderOption,
                primary_email: this.state.reminders.email && this.state.emailId ? this.state.emailId : null,
                primary_phone: this.state.reminders.phone && this.state.phoneNumber ? this.state.phoneNumber : null,
                secondary_email: this.state.reminders.email && this.state.emailId1 ? this.state.emailId1 : null,
                secondary_phone: this.state.reminders.phone && this.state.phoneNumber1 ? this.state.phoneNumber1 : null,
            };
            this.props.checklistAction.setReminder(payload);
        } else {
            let reminderOption = '';
            if (this.state.reminders.email && this.state.reminders.phone) {
                if (this.state.emailId && this.state.phoneNumber) {
                    reminderOption = 'Email & Phone';
                } else if (this.state.emailId) {
                    reminderOption = 'Email';
                } else if (this.state.phoneNumber) {
                    reminderOption = 'Phone';
                }
            } else if (this.state.reminders.email && this.state.emailId) {
                reminderOption = 'Email';
            } else if (this.state.reminders.phone && this.state.phoneNumber) {
                reminderOption = 'Phone';
            }

            let payload = {
                checklistId: this.state.taskData.taskreminder.id,
                payload: {
                    checklist: this.state.taskData.id,
                    datetime: this.state.date ? this.state.date : null,
                    reminder_option: reminderOption,
                    primary_email: this.state.reminders.email && this.state.emailId ? this.state.emailId : null,
                    primary_phone: this.state.reminders.phone && this.state.phoneNumber ? this.state.phoneNumber : null,
                    secondary_email: this.state.reminders.email && this.state.emailId1 ? this.state.emailId1 : null,
                    secondary_phone:
                        this.state.reminders.phone && this.state.phoneNumber1 ? this.state.phoneNumber1 : null,
                },
            };
            this.props.checklistAction.updateReminder(payload);
        }
        this.closeModal();
    };

    deleteReminderFinal = () => {
        if (this.state.taskData && this.state.taskData.taskreminder) {
            let payload = {
                checklistId: this.state.taskData.taskreminder.id,
            };
            this.props.checklistAction.deleteReminder(payload);
        }
        this.closeModal();
    };
    editClicked = (id: any, name: string) => {
        this.setState({ editAddtask: true, editId: id, task: name });
    };
    saveHandler = () => {
        if (this.state.task && this.state.movingDate && this.state.checklistKeys) {
            if (this.state.editAddtask && this.state.editId) {
                const payload = {
                    payload: { name: this.state.task },
                    checklistId: this.state.editId,
                };
                this.props.checklistAction.updateTask(payload);
            } else {
                let year = new Date().getFullYear();
                let date = parseInt(this.state.checklistKeys[this.state.index].match(/\d+/)[0]);
                let month = months.indexOf(this.state.checklistKeys[this.state.index].split(' ')[0]);
                let date1: any = new Date(this.state.movingDate);
                let date2: any = new Date(year, month, date);
                var Difference_In_Time = date2.getTime() - date1.getTime();
                var Days: any = Difference_In_Time / (1000 * 3600 * 24);
                const payload = {
                    name: this.state.task,
                    days_to_move: parseInt(Days),
                };
                this.props.checklistAction.addTask(payload);
            }
        }
    };
    closeHandler = () => {
        this.setState({ showTasks: false, task: '', editAddtask: false, editId: null });
    };
    deleteHandler = () => {
        if (this.state.editId) {
            this.props.checklistAction.deleteTask({ checklistId: this.state.editId });
        }
    };

    handlePrevWeek = () => {
        let { index } = this.state; //mainIndex
        if (index <= 0) {
            this.setState({
                index: 0,
            });
            // }
            // else if (index <= mainIndex) {
            //     this.setState({ index: mainIndex });
        } else {
            this.setState({
                index: index - 1,
            });
        }
    };

    handleNextWeek = () => {
        let { index, checklistKeys } = this.state;
        let len = checklistKeys.length;
        if (index >= len - 1) {
            this.setState({
                index: len - 1,
            });
        } else {
            this.setState({
                index: index + 1,
            });
        }
    };
    tabChangeHandler = (e: any) => {
        this.setState({
            tabName: e,
        });
    };

    render() {
        const { showTasks, task, index, checklistKeys, isDouglasElliman } = this.state;
        let cardsArray: any = [];
        if (
            this.props.checklistState &&
            this.props.checklistState.checklist &&
            Object.keys(this.props.checklistState.checklist).length > 0
        ) {
            for (const [key, value] of Object.entries(this.props.checklistState.checklist)) {
                if (key === checklistKeys[index]) {
                    cardsArray = value;
                }
            }
            if (this.state.tabName === 'Main') {
                cardsArray =
                    this.state.showAll === 'Show All'
                        ? cardsArray.filter((item: any) => {
                              return (
                                  (item.must_do && item.is_overdue && !item.is_completed) ||
                                  (!item.must_do && item.is_overdue && !item.is_completed)
                              );
                          })
                        : cardsArray.filter((item: any) => {
                              if (
                                  item.associated_items &&
                                  item.associated_items.length > 0 &&
                                  item.associated_items[0].name === this.state.showAll
                              )
                                  return (
                                      (item.must_do && item.is_overdue && !item.is_completed) ||
                                      (!item.must_do && item.is_overdue && !item.is_completed)
                                  );
                          });
            }
            if (this.state.tabName === 'Overdue') {
                cardsArray =
                    this.state.showAll === 'Show All'
                        ? cardsArray.filter((item: any) => {
                              return item.is_overdue && !item.must_do && !item.is_completed;
                          })
                        : cardsArray.filter((item: any) => {
                              if (
                                  item.associated_items &&
                                  item.associated_items.length > 0 &&
                                  item.associated_items[0].name === this.state.showAll
                              )
                                  return item.is_overdue && !item.must_do && !item.is_completed;
                          });
            }
            if (this.state.tabName === 'Completed') {
                cardsArray =
                    this.state.showAll === 'Show All'
                        ? cardsArray.filter((item: any) => {
                              return item.is_completed;
                          })
                        : cardsArray.filter((item: any) => {
                              if (
                                  item.associated_items &&
                                  item.associated_items.length > 0 &&
                                  item.associated_items[0].name === this.state.showAll
                              )
                                  return item.is_completed;
                          });
            }
            if (this.state.tabName === 'All') {
                cardsArray =
                    this.state.showAll === 'Show All'
                        ? cardsArray.filter((item: any) => {
                              return !item.is_completed;
                          })
                        : cardsArray.filter((item: any) => {
                              if (
                                  item.associated_items &&
                                  item.associated_items.length > 0 &&
                                  item.associated_items[0].name === this.state.showAll
                              )
                                  return !item.is_completed;
                          });
            }
        }

        const breadList = [
            {
                name: 'Checklist',
                url: ''
            }
        ]

        return (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="checklist"
                            headerText={`Your`}
                            subheaderText={"Here's all your moving tasks. Let's start knocking them off your list."}
                            highlightedText={'Moving Checklist'}
                        />
                    </div>
                ) : (
                    <div className="ChecklistMain">
                        <h2 className="Checklistheading">Your Moving Checklist</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                    </div>
                )}

                <div
                    className={
                        isDouglasElliman ? 'ChecklistMainContent ChecklistMainRemoveDouglas' : 'ChecklistMainContent'
                    }
                >
                    {checklistKeys && checklistKeys.length > 0 ? (
                        <WeekSwitch
                            onSelectHandler={this.onSelectHandler}
                            showAll={this.state.showAll}
                            handlePrevWeek={this.handlePrevWeek}
                            handleNextWeek={this.handleNextWeek}
                            handleMenu={(e: any) => this.handleMenu(e)}
                            handleClose={this.handleClose}
                            AnchorEl={this.state.AnchorEl}
                            sort={this.state.sort}
                            // startMonth={this.state.startMonth}
                            // endMonth={this.state.endMonth}
                            // startDt={this.state.startDt}
                            // endDt={this.state.endDt}
                            handleWeekly={this.handleWeekly}
                            handleMonthly={this.handleMonthly}
                            checklistKeys={checklistKeys}
                            index={index}
                        />
                    ) : (
                        <WeekSwitch
                            onSelectHandler={this.onSelectHandler}
                            showAll={this.state.showAll}
                            handlePrevWeek={this.handlePrevWeek}
                            handleNextWeek={this.handleNextWeek}
                            handleMenu={(e: any) => this.handleMenu(e)}
                            handleClose={this.handleClose}
                            AnchorEl={this.state.AnchorEl}
                            sort={this.state.sort}
                            // startMonth={this.state.startMonth}
                            // endMonth={this.state.endMonth}
                            // startDt={this.state.startDt}
                            // endDt={this.state.endDt}
                            handleWeekly={this.handleWeekly}
                            handleMonthly={this.handleMonthly}
                            checklistKeys={checklistKeys}
                            index={index}
                        />
                    )}
                    {/* tab div */}
                    <div className="checklistTabdiv">
                        <div className="checklistTabInnerDiv">
                            <div
                                className={this.state.tabName === 'All' ? 'checklistTabactive' : 'checklistTabtext'}
                                onClick={() => {
                                    this.tabChangeHandler('All');
                                }}
                            >
                                All
                            </div>
                            <div
                                className={this.state.tabName === 'Main' ? 'checklistTabactive' : 'checklistTabtext'}
                                onClick={() => {
                                    this.tabChangeHandler('Main');
                                }}
                            >
                                Essentials And Overdue
                            </div>
                            <div
                                className={this.state.tabName === 'Overdue' ? 'checklistTabactive' : 'checklistTabtext'}
                                onClick={() => {
                                    this.tabChangeHandler('Overdue');
                                }}
                            >
                                Overdue
                            </div>
                            <div
                                className={
                                    this.state.tabName === 'Completed' ? 'checklistTabactive' : 'checklistTabtext'
                                }
                                onClick={() => {
                                    this.tabChangeHandler('Completed');
                                }}
                            >
                                Completed
                            </div>
                        </div>
                        {this.state.tabName === 'Completed' ? null : (
                            <div className="checklistAddTask" onClick={this.handleShowTasks}>
                                + Add Task
                            </div>
                        )}
                    </div>
                    <div className="checklistAddTaskMobile" onClick={this.handleShowTasks}>
                        + Add Task
                    </div>
                    {showTasks && (
                        <AddTasks
                            handleTasksChange={this.handleTasksChange}
                            value={task}
                            onSave={this.saveHandler}
                            onClose={this.closeHandler}
                            isDouglasElliman={isDouglasElliman}
                        />
                    )}

                    {/* cards div */}

                    {this.state.tabName === 'Main' && cardsArray && cardsArray.length > 0 && (
                        <div className="checklistMainCards">
                            {cardsArray.map((item: any, i: number) => {
                                if (item.is_custom && this.state.editAddtask && item.id === this.state.editId) {
                                    return (
                                        <EditTask
                                            key={i}
                                            handleTasksChange={this.handleTasksChange}
                                            value={task}
                                            onSave={this.saveHandler}
                                            onClose={this.closeHandler}
                                            onDelete={this.deleteHandler}
                                        />
                                    );
                                } else if (item.is_custom) {
                                    return (
                                        <AddTaskCard
                                            mainData={item.name}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            editClicked={() => this.editClicked(item.id, item.name)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChecklistCard
                                            mainData={item.name}
                                            paraData={item.description}
                                            isDouglasElliman={isDouglasElliman}
                                            blueCard={
                                                !item.must_do && !item.is_overdue
                                                    ? ''
                                                    : item.must_do && item.is_overdue
                                                    ? 'Essentials & Overdue'
                                                    : 'Overdue'
                                            }
                                            isQuote={false}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                            buttonText={
                                                item.do_it_now && item.do_it_now.heading
                                                    ? item.do_it_now.heading
                                                    : 'Read It'
                                            }
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                    {this.state.tabName === 'Overdue' && cardsArray && cardsArray.length > 0 && (
                        <div className="checklistMainCards">
                            {cardsArray.map((item: any, i: number) => {
                                if (item.is_custom && this.state.editAddtask && item.id === this.state.editId) {
                                    return (
                                        <EditTask
                                            key={i}
                                            handleTasksChange={this.handleTasksChange}
                                            value={task}
                                            onSave={this.saveHandler}
                                            onClose={this.closeHandler}
                                            onDelete={this.deleteHandler}
                                        />
                                    );
                                } else if (item.is_custom) {
                                    return (
                                        <AddTaskCard
                                            mainData={item.name}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            editClicked={() => this.editClicked(item.id, item.name)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChecklistCard
                                            mainData={item.name}
                                            paraData={item.description}
                                            isDouglasElliman={isDouglasElliman}
                                            blueCard={'Overdue'}
                                            isQuote={false}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                            buttonText={
                                                item.do_it_now && item.do_it_now.heading
                                                    ? item.do_it_now.heading
                                                    : 'Read It'
                                            }
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                    {this.state.tabName === 'Completed' && cardsArray && cardsArray.length > 0 && (
                        <div className="checklistMainCards">
                            {cardsArray.map((item: any, i: number) => {
                                let blueCardData = '';
                                if (item.must_do && item.is_overdue) {
                                    blueCardData = 'Essential and Overdue';
                                } else if (!item.must_do && item.is_overdue) {
                                    blueCardData = 'Overdue';
                                } else {
                                    blueCardData = '';
                                }
                                return (
                                    <CompletedCards
                                        key={i}
                                        mainData={item.name}
                                        paraData={item.description ? item.description : ''}
                                        blueCard={blueCardData}
                                        isQuote={true}
                                        onClick={() => this.cardClicked1()}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {this.state.tabName === 'All' && cardsArray && cardsArray.length > 0 && (
                        <div className="checklistMainCards">
                            {cardsArray.map((item: any, i: number) => {
                                if (item.is_custom && this.state.editAddtask && item.id === this.state.editId) {
                                    return (
                                        <EditTask
                                            key={i}
                                            handleTasksChange={this.handleTasksChange}
                                            value={task}
                                            onSave={this.saveHandler}
                                            onClose={this.closeHandler}
                                            onDelete={this.deleteHandler}
                                        />
                                    );
                                } else if (item.is_custom) {
                                    return (
                                        <AddTaskCard
                                            mainData={item.name}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            editClicked={() => this.editClicked(item.id, item.name)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChecklistCard
                                            mainData={item.name}
                                            paraData={item.description}
                                            isDouglasElliman={isDouglasElliman}
                                            blueCard={
                                                !item.must_do && !item.is_overdue
                                                    ? ''
                                                    : item.must_do && item.is_overdue
                                                    ? 'Essentials & Overdue'
                                                    : 'Overdue'
                                            }
                                            isQuote={false}
                                            disabled={
                                                item.do_it_now && item.do_it_now.new_url
                                                    ? false
                                                    : item.do_it_now && item.do_it_now.url
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                this.cardClicked(
                                                    item.do_it_now && item.do_it_now.new_url
                                                        ? item.do_it_now.new_url
                                                        : item.do_it_now && item.do_it_now.url
                                                        ? item.do_it_now.url
                                                        : '',
                                                )
                                            }
                                            doneClicked={() => this.doneClicked(item.id)}
                                            reminderClicked={() => this.reminderClicked(item)}
                                            reminderText={item.taskreminder ? 'Edit Reminder' : 'Add Reminder'}
                                            buttonText={
                                                item.do_it_now && item.do_it_now.heading
                                                    ? item.do_it_now.heading
                                                    : 'Read It'
                                            }
                                        />
                                    );
                                }
                            })}
                        </div>
                    )}
                    {cardsArray && cardsArray.length > 0 ? (
                        <div></div>
                    ) : (
                        <div className="dataAvailable">No task available</div>
                    )}
                </div>
                <Modals
                    calender={false}
                    reminderModal={this.state.reminderModal}
                    isDouglasElliman={isDouglasElliman}
                    closeModal={this.closeModal}
                    date={this.state.date}
                    reminders={this.state.reminders}
                    emailId={this.state.emailId}
                    phoneNumber={this.state.phoneNumber}
                    taskData={this.state.taskData}
                    dateHandler={(e: any) => this.dateHandler(e)}
                    handleSetReminders={(val: any) => this.handleSetReminders(val)}
                    handleEmail={(e: any) => this.handleEmail(e)}
                    handlePhone={(e: any) => this.handlePhone(e)}
                    setReminderFinal={() => this.setReminderFinal()}
                    deleteReminderFinal={() => this.deleteReminderFinal()}
                    handleEmail1={(e: any) => this.handleEmail1(e)}
                    handlePhone1={(e: any) => this.handlePhone1(e)}
                    emailId1={this.state.emailId1}
                    phoneNumber1={this.state.phoneNumber1}
                    secondEmailShow={() => this.secondEmailShow()}
                    secondEmailRemove={() => this.secondEmailRemove()}
                    isSecondEmail={this.state.isSecondEmail}
                    buttonText={
                        this.state.taskData && this.state.taskData.taskreminder ? 'Update Reminder' : 'Set Reminder'
                    }
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        checklistAction: bindActionCreators(ChecklistAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    checklistState: state.checklist,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(checklistMain);
