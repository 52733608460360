import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="36.813" height="35" viewBox="0 0 36.813 35">
        <path
            fill={
                window.location.pathname.includes('home-warranty') || window.location.pathname.includes('homewarranty')
                    ? '#009CBD'
                    : '#100b28'
            }
            d="M35.116 39.1c0 .488-.488.906-1.185.906h-.349a.865.865 0 01-.837-.906v-4.393H11.69V7.794h23.426v3.068A9.449 9.449 0 0136.51 11V6.4H10.3v28.307H6.6V39.1a2.321 2.321 0 002.3 2.3H34a2.417 2.417 0 002.51-2.3v-6.345a9.449 9.449 0 01-1.394.139zm-21.962.906H8.9a.923.923 0 01-.906-.906v-3h23.357v3a1.93 1.93 0 00.209.906zm2.58-11.016a.659.659 0 01.7-.7h8.994a11.378 11.378 0 001.185 1.394H16.431a.7.7 0 01-.698-.694zM26.4 14.348h-9.97a.7.7 0 110-1.394h11.5a9.57 9.57 0 00-1.53 1.394zM15.733 25.016a.659.659 0 01.7-.7h7.181c.139.488.279.976.418 1.394h-7.6a.75.75 0 01-.699-.694zm0-3.7a.659.659 0 01.7-.7H23.4a7.578 7.578 0 00-.07 1.255v.139h-6.9a.75.75 0 01-.697-.69zm0-3.7a.659.659 0 01.7-.7h8.088a8.31 8.31 0 00-.558 1.394h-7.53a.7.7 0 01-.7-.685zm27.679 4.253a8.994 8.994 0 11-8.994-8.994 8.816 8.816 0 015.229 1.673l-.976.976a7.584 7.584 0 103.347 6.275 7.28 7.28 0 00-.837-3.416l-6.345 6.554a.633.633 0 01-.488.209.633.633 0 01-.488-.209l-3-3a.69.69 0 11.976-.976l2.51 2.51 6.484-6.624a.068.068 0 00.07-.07.068.068 0 01.07-.07h.07c.07 0 .07-.07.139-.07h.421c.07 0 .07 0 .139.07.07 0 .07.07.139.07h.07a.068.068 0 00.07.07.068.068 0 01.07.07 10.918 10.918 0 011.325 4.961z"
            transform="translate(-6.6 -6.4)"
        ></path>
    </svg>
);

export default SvgComponent;
