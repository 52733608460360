// Home Valuation Main Card
export const HOME_VALUATION_CARD_DETAILS = 'HOME_VALUATION_CARD_DETAILS';
export const HOME_VALUATION_CARD_DETAILS_SUCCESS = 'HOME_VALUATION_CARD_DETAILS_SUCCESS';
export const HOME_VALUATION_CARD_DETAILS_ERROR = 'HOME_VALUATION_CARD_DETAILS_ERROR';

// Loan Details
export const LOAN_DETAILS = 'LOAN_DETAILS';
export const LOAN_DETAILS_SUCCESS = 'LOAN_DETAILS_SUCCESS';
export const LOAN_DETAILS_ERROR = 'LOAN_DETAILS_ERROR';

// Saving Calc
export const HOME_VALUATION_SAVING_CALCULATOR_DETAILS = 'HOME_VALUATION_SAVING_CALCULATOR_DETAILS';
export const HOME_VALUATION_SAVING_CALCULATOR_DETAILS_SUCCESS = 'HOME_VALUATION_SAVING_CALCULATOR_DETAILS_SUCCESS ';
export const HOME_VALUATION_SAVING_CALCULATOR_DETAILS_ERROR = 'HOME_VALUATION_SAVING_CALCULATOR_DETAILS_ERROR ';
