import React, { useEffect } from 'react';
import Button from '../../../components/atoms/Button';
import './remainingMortageBalance.scss';
import GreenCheckMark from '../../../Assets/images/green_check.png';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import AnalyticsService from '../../../pages/analytics-service';
import { renderContactButtonText } from '../../../utils/common-functions';

interface bannerProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    bannerMargin?: string;
    bannerPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    bannerImage?: any;
    imgName?: any;
    bannerContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    mortageTitle?: string;
    userName?: string;
    userPhone?: string;
    userEmail?: string;
    userTitle?: string;
    greetMessage?: string;
    options?: string[];
    onContactMeClick?: any;
    onAgentContact: any;
    serviceAgentData?: any;
    serviceProviders?: any;
    analyticsData: any;
}

const options = [
    'Refinance your loan and lower your monthly payment',
    'See how you can pay off your home sooner',
    'Leverage your home equity with a HELOC',
];

const MikeMortageBalance = (props: bannerProps) => {
    const serviceProvider =
        (props.serviceProviders?.data && props.serviceProviders?.data.length && props.serviceProviders?.data[0]) || {};
    const { serviceAgentData, serviceProviders, analyticsData } = props;

    const renderHelloText = () => {
        if (!serviceAgentData?.photo_url?.length && serviceAgentData?.first_name && serviceProviders?.name) {
            return `Hi there! I am ${serviceAgentData?.first_name} ${serviceAgentData?.last_name} from ${serviceProviders?.name} and am here to answer any questions and help you explore your options.`
        } else {
            return 'Hi there! I am here to answer any questions and help you explore your options.'
        }
    }

    useEffect(() => {
        AnalyticsService.cardViewed({
             ...analyticsData,
             category_name: "Mortgage Contact Card Viewed"
         });
    }, [])

    return (
        <div
            onClick={props.onClick}
        >
            {serviceAgentData?.photo_url?.length ? (
                <>
               {serviceProvider?.logo || serviceAgentData?.service_provider?.logo && <div className="remaining-balance-edit">
                    <span className="edit-icon">
                        <img
                            src={serviceProvider?.logo || serviceAgentData?.service_provider?.logo}
                            width={'162px'}
                        />
                    </span>
                </div>}
                </>
            ) : (
                ''
            )}
            {serviceAgentData?.photo_url?.length ? (
               <>{serviceProviders?.name && <div className="mortage-title">{serviceProviders.name}</div>}</>
            ) : (
                ''
            )}
            {serviceAgentData?.photo_url?.length ? (
                <div className="mortage-userinfo-container">
                    {serviceAgentData?.photo_url && <img
                        width={props.imgWidth ? props.imgWidth : '120px'}
                        height={props.imgHeight ? props.imgHeight : '120px'}
                        src={serviceAgentData.photo_url}
                        style={{
                            marginBottom: '5px',
                            borderRadius: '60px'
                        }}
                        alt=""
                    />}
                    <div className="mortage-user-details">
                        <div className="mortage-user-details-username">
                            {`${serviceAgentData?.first_name &&  serviceAgentData.first_name} ${serviceAgentData?.last_name && serviceAgentData.last_name}`}
                        </div>
                        {serviceAgentData?.service_role && <div className="mortage-user-details-usertitle">
                            {serviceAgentData.service_role}
                        </div>}
                        {serviceAgentData?.primary_phone_no && <div className="mortage-user-details-userphone">
                            <div className="mortage-user-details-userphone-icon">
                                <PhoneOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceAgentData.primary_phone_no}</div>
                        </div>}
                        {serviceAgentData?.email && <div className="mortage-user-details-useremail">
                            <div className="mortage-user-details-useremail-icon">
                                <EmailOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceAgentData.email}</div>
                        </div>}
                    </div>
                </div>
            ) : (
                ''
            )}
            {!serviceAgentData?.photo_url?.length && serviceProviders?.logo?.length ? (
                <div>
                    <img
                        width={'194px'}
                        src={serviceProviders?.logo}
                        style={{ margin: '15px 0' }}
                        alt="Mortage Logo"
                    />
                    <div className="mortage-user-details" style={{ margin: 0 }}>
                        {serviceProviders?.name && <div className="mortage-user-details-username">{serviceProviders.name}</div>}
                        {serviceProviders?.phone && <div className="mortage-user-details-userphone">
                            <div className="mortage-user-details-userphone-icon">
                                <PhoneOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceProviders.phone}</div>
                        </div>}
                        {serviceProviders?.email && <div className="mortage-user-details-useremail">
                            <div className="mortage-user-details-useremail-icon">
                                <EmailOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceProviders.email}</div>
                        </div>}
                    </div>
                </div>
            ) : (
                ''
            )}
            {!serviceAgentData?.photo_url?.length && !serviceProviders?.logo?.length ? (
                <div>
                    <div className="mortage-user-details" style={{ margin: '15px 0' }}>
                       {serviceProviders?.name && <div className="mortage-user-details-username">{serviceProviders.name}</div>}
                        {serviceProviders?.phone && <div className="mortage-user-details-userphone">
                            <div className="mortage-user-details-userphone-icon">
                                <PhoneOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceProviders.phone}</div>
                        </div>}
                       {serviceProviders?.email &&  <div className="mortage-user-details-useremail">
                            <div className="mortage-user-details-useremail-icon">
                                <EmailOutlined fontSize="inherit" />
                            </div>
                            <div>{serviceProviders.email}</div>
                        </div>}
                    </div>
                </div>
            ) : (
                ''
            )}

            <div className="mortage-greet-message">{renderHelloText()}</div>

            <div className="mortage-list">
                {options.map((option, i) => (
                    <div key={i} className="mortage-list-icon">
                        <div className="mortage-list-iconBox1">
                            {' '}
                            <img src={GreenCheckMark} alt="" />
                        </div>

                        <div className="mortage-list-iconBox2">{option}</div>
                    </div>
                ))}
            </div>

            <div>
                <Button onClick={props.onAgentContact} borderRadius="4px" children={renderContactButtonText(serviceAgentData, serviceProviders)} width="150px" />
            </div>
        </div>
    );
};

export default MikeMortageBalance;
