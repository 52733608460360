import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import history from '../../../routes/History';
import styles from './breadCrumb.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';

type ILink = {
    name: string;
    url: string;
    redirectStates?: any;
};

type IProps = {
    breadList: ILink[];
    homeCrumb?: string;
};

const BreadCrumb = (props: IProps) => {
    const [isHomeOwner, setIsHomeOwner] = useState(false);
    const { breadList, homeCrumb } = props;
    const [ allowedBreads, setAllowedBreads] = useState<ILink[]>([])
    const { header: serviceList} = useSelector((state: AppState) => state.common)

    const slugList = serviceList.map((el: any) => el.react_url_route)

    useEffect(() => { //Filter services that are in enabled service list https://moveeasy.atlassian.net/browse/MNSP-3603
        let allBreads = breadList.filter((bl) =>  bl.url == "" || slugList.includes(bl.url))
        setAllowedBreads(allBreads)
    }, [breadList])

    useEffect(() => {
        if (localStorage.getItem('isHomeOwner') == 'true') setIsHomeOwner(true);
    }, [props]);

    const handleDashboardClick = () => {
        let redirectUrl: string = isHomeOwner ? '/dashboardhomeOwner' : '/dashboard';

        if (!_.isEmpty(homeCrumb)) {
            if (homeCrumb === 'Home' || homeCrumb === 'HomePage') {
                redirectUrl = '/';
            }
        }

        history.push(redirectUrl);
    };

    const handleRedirect = (url: string, redirectState?: any) => {
        if (_.isEmpty(url)) return;
        if (!_.isEmpty(redirectState)) {
            history.push({
                pathname: url,
                state: redirectState,
            });
        } else {
            history.push(url);
        }
    };

    return (
        <div className={styles.breadCrumbCover}>
            <li onClick={handleDashboardClick}>{homeCrumb ? homeCrumb : 'Dashboard'}</li>
            <li className={styles.arrorwIcon}>{'>'}</li>
            {allowedBreads.map((item: ILink, i: number) => (
                <Fragment key={i}>
                    <li
                        className={item.url && styles.link}
                        onClick={() => handleRedirect(item.url, item.redirectStates)}
                    >
                        {item.name}
                    </li>
                    <li className={styles.arrorwIcon}>{'>'}</li>
                </Fragment>
            ))}
        </div>
    );
};

export default BreadCrumb;
