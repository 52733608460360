import React from 'react';
import "../../styles/howItWorksLanding.scss"

interface ICardProps {
    serial: number;
    heading: string;
    desc: string;
    clicked: number;
    headingClass ? : string;
    descClass ? : string
}

const HowItWorksCard = (props: ICardProps) => {
    return (
        <div className={props.clicked === props.serial ? 'active-card' : 'card-wrapper'}>
            <div className={props.clicked === props.serial ? 'active-serial' : 'serial'}>{props.serial}</div>
            <div style={{ paddingLeft: '13px' }}>
                <div className={props.headingClass ? props.headingClass : "card-heading"}>{props.heading}</div>
                <div className={props.descClass ? props.descClass : "card-desc"}>{props.desc}</div>
            </div>
        </div>
    );
};

export default HowItWorksCard;
