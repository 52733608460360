export const EXTERNAL_DIGITAL_PLANS = 'EXTERNAL_DIGITAL_PLANS';
export const EXTERNAL_DIGITAL_PLANS_SUCCESS = 'EXTERNAL_DIGITAL_PLANS_SUCCESS';
export const EXTERNAL_DIGITAL_PLANS_ERROR = 'EXTERNAL_DIGITAL_PLANS_ERROR';

export const SELECT_DIGITAL_PLANS = 'SELECT_DIGITAL_PLANS';
export const SELECT_DIGITAL_PLANS_SUCCESS = 'SELECT_DIGITAL_PLANS_SUCCESS';
export const SELECT_DIGITAL_PLANS_ERROR = 'SELECT_DIGITAL_PLANS_ERROR';

export const SELECT_DIGITAL_PLANS_GET = 'SELECT_DIGITAL_PLANS_GET';
export const SELECT_DIGITAL_PLANS_GET_SUCCESS = 'SELECT_DIGITAL_PLANS_GET_SUCCESS';
export const SELECT_DIGITAL_PLANS_GET_ERROR = 'SELECT_DIGITAL_PLANS_GET_ERROR';

export const GET_STEPS = 'GET_STEPS';
export const GET_STEPS_SUCCESS = 'GET_STEPS_SUCCESS';
export const GET_STEPS_ERROR = 'GET_STEPS_ERROR';

export const CHANGE_STEPS = 'CHANGE_STEPS';
export const CHANGE_STEPS_SUCCESS = 'CHANGE_STEPS_SUCCESS';
export const CHANGE_STEPS_ERROR = 'CHANGE_STEPS_ERROR';

export const LANDING_DIGITAL_PLANS = 'LANDING_DIGITAL_PLANS';
export const LANDING_DIGITAL_PLANS_SUCCESS = 'LANDING_DIGITAL_PLANS_SUCCESS';
export const LANDING_DIGITAL_PLANS_ERROR = 'LANDING_DIGITAL_PLANS_ERROR'

export const GET_IC_SUMMARY = 'GET_IC_SUMMARY';
export const GET_IC_SUMMARY_SUCCESS = 'GET_IC_SUMMARY_SUCCESS';
export const GET_IC_SUMMARY_ERROR = 'GET_IC_SUMMARY_ERROR'
