import React from 'react';
import './MonthlyUsageCard.scss';
import bill from '../../Assets/images/utilityImages/bill.png';
import monthlyAverage from '../../Assets/images/utilityImages/monthlyAverage.png';
import kwh from '../../Assets/images/utilityImages/kwh.png';
import EditIcon from '@material-ui/icons/Edit';

type initialProps = {
    monthlyAverage?: number;
    kwhCost?: number;
    monthlyBill?: number;
    address?: string;
    editButtonClick?: any;
};

type initialState = {};

class MonthlyUsageCard extends React.Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        return (
            <div className="monthly-usage-card-main">
                <div className="montly-usage-card-submain">
                    <div className="electricityUtiltity">
                        Your Electricity Utility
                        <span>
                            {this.props.address}
                            <EditIcon className="editicon" onClick={this.props.editButtonClick} />
                        </span>
                    </div>
                    <div className="monthly-main">
                        <div className="monthlyValues1">
                            <div className="iconMonthly">
                                <img src={monthlyAverage} alt="" />
                            </div>
                            <div className="montlyUsagePower">
                                <div className="monthlyHeading">Monthly Average Usage</div>
                                <div className="monthlyValues">{this.props.monthlyAverage}kWh</div>
                            </div>
                        </div>
                        <div className="monthlyValues1">
                            <div className="iconMonthly">
                                <img src={kwh} alt="" />
                            </div>
                            <div className="montlyUsagePower">
                                <div className="monthlyHeading">Cost of 1 Kwh</div>
                                <div className="monthlyValues">{this.props.kwhCost}¢</div>
                            </div>
                        </div>
                        <div className="monthlyValues1">
                            <div className="iconMonthly">
                                <img src={bill} alt="" />
                            </div>
                            <div className="montlyUsagePower">
                                <div className="monthlyHeading">Approx Monthly Bill</div>
                                <div className="monthlyValues">{this.props.monthlyBill}¢</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MonthlyUsageCard;
