import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
let slug: any = localStorage.getItem('slug');
slug = JSON.parse(slug);
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    barColorPrimary: {
        background: slug && slug.primary_color ? slug.primary_color : '#333',
    },
    barColorLanding: {
        background: '#30ab86',
    },
});
type initialProps = {
    value: any;
    fromLanding?: boolean;
};
export default function LinearDeterminate(props: initialProps) {
    const classes = useStyles();
    // const [progress, setProgress] = React.useState(0);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress(oldProgress => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 10;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 500);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    return (
        <div className={classes.root}>
            <LinearProgress
                classes={{ barColorPrimary: props.fromLanding ? classes.barColorLanding : classes.barColorPrimary }}
                color="primary"
                variant="determinate"
                value={props.value}
                style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
            />
        </div>
    );
}
