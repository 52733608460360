import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={82.813}
            height={82.523}
            className="checkBoximg"
            viewBox="0 0 82.813 82.523"
        >
            <defs>
                <style>
                    {`.MoviesStepprefix__a{fill:${
                       props.fromlanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="MoviesStepprefix__a"
                d="M76.65 34.76h-5.771V7.74A7.75 7.75 0 0063.138 0H7.742A7.75 7.75 0 000 7.74v40.552a7.75 7.75 0 007.742 7.742h26.6v12.044H21.721a1.1 1.1 0 100 2.2h28.231a1.1 1.1 0 100-2.2H36.539V56.034H55.3v20.327a6.169 6.169 0 006.162 6.162H76.65a6.169 6.169 0 006.163-6.162V40.922a6.17 6.17 0 00-6.163-6.162zM57.5 67.439h5.735a3.294 3.294 0 006.214 0h11.164v4.593H57.5zm7.743-1.1a1.1 1.1 0 111.1 1.1 1.1 1.1 0 01-1.101-1.1zm4.206-1.1a3.294 3.294 0 00-6.214 0H57.5V42.908h23.113v22.331zm11.158-24.531H57.5a3.969 3.969 0 013.957-3.749H76.65a3.968 3.968 0 013.957 3.749zM7.742 2.2h55.4a5.548 5.548 0 015.538 5.54v27.02h-7.218a6.169 6.169 0 00-6.162 6.162v.406H2.2V7.74A5.548 5.548 0 017.742 2.2zm0 51.634A5.548 5.548 0 012.2 48.292v-4.765h53.1v10.308zm68.908 26.49H61.462a3.967 3.967 0 01-3.962-3.963v-2.13h23.113v2.131a3.967 3.967 0 01-3.963 3.962zm0 0"
            />
            <path
                className="MoviesStepprefix__a"
                d="M71.397 76.178h-4.683a1.1 1.1 0 000 2.2h4.683a1.1 1.1 0 000-2.2zm0 0M16.475 35.088h4.985a4.068 4.068 0 007.833 0h25.082a1.1 1.1 0 100-2.2H29.296a4.068 4.068 0 00-7.834 0h-4.987a1.1 1.1 0 100 2.2zm8.9-2.969a1.869 1.869 0 11-1.869 1.869 1.87 1.87 0 011.869-1.869zm0 0M33.529 22.099a1.1 1.1 0 001.136-.069l4.751-3.285a1.1 1.1 0 000-1.809l-4.751-3.285a1.1 1.1 0 00-1.725.9v6.57a1.1 1.1 0 00.589.978zm1.61-5.447l1.718 1.188-1.718 1.188zm0 0"
            />
            <path
                className="MoviesStepprefix__a"
                d="M35.422 26.857a9.017 9.017 0 10-9.017-9.017 9.017 9.017 0 009.017 9.017zm0-15.835a6.818 6.818 0 11-6.818 6.818 6.817 6.817 0 016.818-6.818zm0 0M72.407 52.041l-3.849-2.662a1.1 1.1 0 00-1.725.9v5.327a1.1 1.1 0 001.725.9l3.849-2.663a1.1 1.1 0 000-1.808zm-3.375 1.469v-1.129l.816.564zm0 0"
            />
            <path
                className="MoviesStepprefix__a"
                d="M69.06 45.43a7.514 7.514 0 107.514 7.514 7.515 7.515 0 00-7.514-7.514zm0 12.829a5.315 5.315 0 115.315-5.315 5.315 5.315 0 01-5.315 5.316zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
