import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    insuranceAppointmentFailure,
    insuranceAppointmentSuccess,
    insuranceQuoteFailure,
    insuranceQuoteSuccess,
    insuranceQuoteListFailure,
    insuranceQuoteListSuccess,
    updatePersonalFailure,
    updatePersonalSuccess,
    updatePropertyFailure,
    updatePropertySuccess,
    insuranceAppointmentGetFailure,
    insuranceAppointmentGetSuccess,
    getHISummarySuccess,
    getHISummaryFailure
} from './actions';
import {
    UPDATE_MOVE_PERSONAL_INFO,
    UPDATE_MOVE_PROPERTY_INFO,
    INSURANCE_APPOINTMENTS,
    INSURANCE_QUOTE,
    INSURANCE_QUOTE_LIST,
    INSURANCE_APPOINTMENTS_GET,
    GET_HI_SUMMARY
} from './actionTypes';
import apiJunction from '../../utils/api';
import { InsuranceQuote } from './types';
import { getErrorMessage } from '../errorHandler';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* insuranceQuote(action: InsuranceQuote) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            body: action.payload,
            url: `/api/services/insurance-quote/`,
        });
        if (response.data) {
            yield put(insuranceQuoteSuccess({ quote: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceQuoteFailure({ errorQuote: e instanceof Error ? getErrorMessage(e) : "Something went wrong!"}));
        // history.push('/server-error');
    }
}

function* insuranceQuoteList() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/insurance-quote-list/`,
        });
        if (response.data) {
            yield put(insuranceQuoteListSuccess({ quoteList: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceQuoteListFailure({ errorQuoteList: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* insuranceAppointment(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/insurance-appointments/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(insuranceAppointmentSuccess({ appointment: { ...response.data, status: response.status } }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceAppointmentFailure({ errorAppointment: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* insuranceAppointmentGet() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/insurance-appointments/`,
        });
        if (response.data) {
            yield put(insuranceAppointmentGetSuccess({ appointmentGet: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(insuranceAppointmentGetFailure({ errorAppointmentGet: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* updatePersonal(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/${action.payload.move_id}/update_move_insurance_personal_information/`,
            body: action.payload.payload,
        });
        if (response.data) {
            yield put(updatePersonalSuccess({ updatePersonal: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updatePersonalFailure({ errorUpdatePersonal: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* updateProperty(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-api/${action.payload.move_id}/update_move_property_information/`,
            body: action.payload.payload,
        });
        if (response.data) {
            yield put(updatePropertySuccess({ updateProperty: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updatePropertyFailure({ errorUpdateProperty: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* getHISummaryList(action: any) { //action: any
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            // url: `csr/api/v1/move-order/summary/?move=224849&service=Insurance`
            // url: `csr/api/v1/move-order/summary/?move=${action.payload.moveId}&service=Insurance`,
            url: `/api/move/csr-summary-details/summary/?move=${action.payload.moveId}&service=Insurance`,
        });
        if (response.data && response.status === 200) {
            yield put(getHISummarySuccess({ summary: { ...response.data.move_orders, status: response.status } }));
        }
    } catch (e) {
        yield put(getHISummaryFailure({ error: getErrorMessage(e) }));
    }
}


export default function* internetSaga() {
    yield all([
        takeLatest(INSURANCE_APPOINTMENTS, insuranceAppointment),
        takeLatest(INSURANCE_APPOINTMENTS_GET, insuranceAppointmentGet),
        takeLatest(INSURANCE_QUOTE, insuranceQuote),
        takeLatest(INSURANCE_QUOTE_LIST, insuranceQuoteList),
        takeLatest(UPDATE_MOVE_PERSONAL_INFO, updatePersonal),
        takeLatest(UPDATE_MOVE_PROPERTY_INFO, updateProperty),
        takeLatest(GET_HI_SUMMARY, getHISummaryList)
    ]);
}
