import React from 'react';

import './AffordabilityCard.scss';
// import '../../../styles/homeCard.scss';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    cardPriceFirst?: string;
    cardImageBlur?: any;
    selected?: boolean;
}

const AffordabilityCard = (props: cardProps) => {
    return (
        <div onClick={props.onClick}>
            <div className="mainimage">
                <img
                    src={props.selected ? props.cardImage : props.cardImageBlur}
                    style={{
                        marginRight: 'auto',
                    }}
                    alt=""
                    className={props.imgClassname ? props.imgClassname : ''}
                />
            </div>
            <div>
                <span className="mainText">
                    <b>{props.cardTitle}</b>
                </span>
                <span className="mainSubText">{props.cardContent}</span>
            </div>
        </div>
    );
};

export default AffordabilityCard;
