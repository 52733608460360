import {
GET_OFFERS,
GET_OFFERS_ERROR,
GET_OFFERS_SUCCESS,
POST_SECURITY,
POST_SECURITY_SUCCESS,
POST_SECURITY_ERROR
} from './actionTypes';
import {
    Offers,
    OffersRequest,
    OffersFailure,
    OffersFailurePayload,
    OffersSuccess,
    OffersSuccessPayload,
    PostSecurity,
    PostSecuritySuccess,
    PostSecurityFailure,
    PostSecurityRequest,
    PostSecurityFailurePayload,
    PostSecuritySuccessPayload
  
} from './types';

export const offers = (payload: OffersRequest): Offers => ({
    type: GET_OFFERS,
    payload,
});

export const offersSuccess = (payload: OffersSuccessPayload): OffersSuccess => ({
    type: GET_OFFERS_SUCCESS,
    payload,
});

export const offersFailure = (payload: OffersFailurePayload): OffersFailure => ({
    type: GET_OFFERS_ERROR,
    payload,
});


//post security


export const postSecurity = (payload: PostSecurityRequest): PostSecurity => {
    return {
        type: POST_SECURITY,
        payload,
    };
};

export const postSecuritySuccess = (payload: PostSecuritySuccessPayload): PostSecuritySuccess => {
    return {
        type: POST_SECURITY_SUCCESS,
        payload,
    };
};

export const postSecurityFailure = (payload: PostSecurityFailurePayload): PostSecurityFailure => {
    return {
        type: POST_SECURITY_ERROR,
        payload,
    };
};


