import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={652.678}
      height={200.851}
      viewBox="0 0 652.678 200.851"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={-0.237}
          y1={0.502}
          x2={0.756}
          y2={0.502}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff9b2b" />
          <stop offset={0.191} stopColor="#f69427" />
          <stop offset={0.602} stopColor="#ea8c23" />
          <stop offset={1} stopColor="#e68922" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={1.075}
          y1={-0.178}
          x2={0.044}
          y2={0.973}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#feb840" />
          <stop offset={0.369} stopColor="#feb33c" />
          <stop offset={0.75} stopColor="#fea734" />
          <stop offset={1} stopColor="#ff9b2b" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={1.018}
          y1={-0.026}
          x2={0.042}
          y2={0.945}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#feb840" />
          <stop offset={0.25} stopColor="#feab36" />
          <stop offset={0.631} stopColor="#fe9f2e" />
          <stop offset={1} stopColor="#ff9b2b" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1={-0.238}
          y1={0.5}
          x2={0.762}
          y2={0.5}
          xlinkHref="#prefix__a"
        />
        <style>
          {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 
            ? `.moving_prefix__d{fill:${slug.color_shade4}}.moving_prefix__e{fill:${slug.color_shade3}}.moving_prefix__f{fill:#bed2fa}.moving_prefix__g{fill:#ffd5c0}.moving_prefix__h{fill:#e8988c}.moving_prefix__k{fill:#7eb3ff}.moving_prefix__m{fill:${slug.color_shade3}}.moving_prefix__n{fill:#fff}.moving_prefix__o{fill:#3c3c3b}.moving_prefix__p{fill:#575756}.moving_prefix__q{fill:${slug.color_shade1}}.moving_prefix__r{fill:#9d9d9c}.moving_prefix__s{fill:#c8e9f7}.moving_prefix__t{fill:${slug.primary_color}}.moving_prefix__u{fill:#1e2859}.moving_prefix__w{fill:#e68922}.moving_prefix__x{fill:#ffd980}.moving_prefix__z{fill:#fec1b5}`
            : ".moving_prefix__d{fill:#5b7088}.moving_prefix__e{fill:#162c47}.moving_prefix__f{fill:#bed2fa}.moving_prefix__g{fill:#ffd5c0}.moving_prefix__h{fill:#e8988c}.moving_prefix__k{fill:#7eb3ff}.moving_prefix__m{fill:#3c536f}.moving_prefix__n{fill:#fff}.moving_prefix__o{fill:#3c3c3b}.moving_prefix__p{fill:#575756}.moving_prefix__q{fill:#08192f}.moving_prefix__r{fill:#9d9d9c}.moving_prefix__s{fill:#c8e9f7}.moving_prefix__t{fill:#273e59}.moving_prefix__u{fill:#1e2859}.moving_prefix__w{fill:#e68922}.moving_prefix__x{fill:#ffd980}.moving_prefix__z{fill:#fec1b5}"
          }
        </style>
      </defs>
      <path
        d="M652.678 194.348c-67.931-.276-166.741-.152-276.733-.152-204.873 0-370.955-.428-370.955 2.092s166.082 4.563 370.955 4.563c109.992 0 208.8-.589 276.733-1.525z"
        fill="#dad6d3"
      />
      <path fill="#f0f0f0" d="M626.607 50.918h1.178v145.514h-1.178z" />
      <path d="M641.037 86.345h-27.684l4.116-32.966h19.451z" fill="#e0e0e0" />
      <path className="moving_prefix__d" d="M477.146 165.463h36.455v30.99h-36.455z" />
      <path className="moving_prefix__e" d="M521.913 196.453h-8.312v-30.99h8.312z" />
      <path className="moving_prefix__d" d="M485.146 134.473h36.455v30.99h-36.455z" />
      <path className="moving_prefix__e" d="M529.913 165.463h-8.312v-30.99h8.312z" />
      <path
        className="moving_prefix__f"
        d="M583.76 67.211c.094-1.609.206-3.6-2.455-2.994-3.289.745-18.455 3.779-23.9 4.911-2.061.429-5.9 1.025-6.231 2.591-.382 1.811 1.234 2.083 2.839 2.193 4.549.311 18.555-.295 23.5-.655a7.32 7.32 0 006.247-6.046z"
      />
      <path
        className="moving_prefix__f"
        d="M577.986 46.908a1.437 1.437 0 00-2.42-.546c-1.075.978-23.267 22.616-23.972 23.553a2.593 2.593 0 00-.538 2.731c1 2.184 5.731.849 7.324-.444 1.635-1.327 18.869-23.09 19.293-23.617a1.594 1.594 0 00.313-1.677z"
      />
      <path
        className="moving_prefix__g"
        d="M597.798 144.825c.089-5.457.164-11.486-1.113-15.74-.694-2.314-2.315-3.232-4.778-2.912-1 .129-2.493 1.324-3.043 4.025-.687 3.371-.295 8.77-.131 12.3.288 6.22 2.911 38.658 3.4 44.8.22 2.8 1.313 3.33 2.274 3.294 1.685-.063 2.037-1.789 2.161-3.459.068-.968 1.107-34.782 1.23-42.308zM588.339 188.234c-1.824-11.061-8.074-40.16-8.753-42.656-.565-2.077-2.069-4.678-4.2-5.691a3.365 3.365 0 00-4.58 2.236 10.659 10.659 0 00.472 3.929c1.734 6.3 12.094 39.37 13.024 42.06a3.037 3.037 0 002.838 2.217c.882-.082 1.406-.96 1.199-2.095z"
      />
      <path
        className="moving_prefix__h"
        d="M597.799 144.826c-.016.97-.047 2.378-.088 4.094-3.57-.648-7.1-.714-8.5.933-.242-3.346-.416-5.983-.481-7.351-.163-3.534-.556-8.934.132-12.3.55-2.7 2.046-3.9 3.043-4.025 2.462-.32 4.082.6 4.777 2.912 1.281 4.247 1.206 10.279 1.117 15.737zM580.672 150.332c-3.159-1.274-6.595-1.732-8.412-.87a195.18 195.18 0 01-.99-3.409 10.711 10.711 0 01-.471-3.931 3.364 3.364 0 014.581-2.234c2.134 1.011 3.636 3.613 4.2 5.69.15.531.544 2.256 1.092 4.754z"
      />
      <path
        d="M600.869 143.452c-.452-2.274-1.058-6.12-1.578-9.062a31.771 31.771 0 01-.476-7c.278-5.54 1.795-10.139.424-15.747a29.928 29.928 0 00-3.085-7.568c-.962-1.7-3.681-3.738-5.686-4.614a12.057 12.057 0 00-6.89-.949c-1.435.158-7.427-.113-8.912.1a3.012 3.012 0 00-2.578 1.819c-.992 3.716-2.562 9.222-3.643 15.2a170.005 170.005 0 00-2.7 27.685c0 2.56 1.29 2.876 4.124 3.166a123.25 123.25 0 0015.192.2c3.275-.142 5.746-.22 8.692-.414l1.4-16.251 1.531 16.041a83.41 83.41 0 003.031-.306c1.434-.174 1.341-1.361 1.154-2.3z"
        fill="#120e59"
      />
      <path
        d="M600.869 143.451c-.452-2.274-1.058-6.12-1.578-9.062a31.771 31.771 0 01-.476-7c.278-5.54 1.795-10.139.424-15.747a29.937 29.937 0 00-3.085-7.568c-.962-1.7-3.681-3.738-5.686-4.614l-.174-.073c.11.785 1.279 2.157 2.067 3.368a21.423 21.423 0 012.958 10.533c-.014 3.484-1.068 6.265-1.3 9.728a15.522 15.522 0 001.13 7l1.531 16.041a83.41 83.41 0 003.031-.306c1.438-.173 1.345-1.361 1.158-2.3z"
        fill="#0d0d38"
      />
      <path
        className="moving_prefix__f"
        d="M598.152 61.881c-3.849-.546-11.281.744-14.146 1.632-2.193.679-2.912.9-3.5 2.107a42.132 42.132 0 00-1.817 5.136 11.6 11.6 0 01-2.391 4.6c-1.1 1.131-2.519 1.9-3.338 3.3a3.521 3.521 0 00.906 4.513c.535.507 1.6 1.029 1.814 1.575a3.954 3.954 0 01-.2 1.989 96.475 96.475 0 00-3.371 13.507c-.263 1.848 1.072 2.868 2.667 3.2a29.271 29.271 0 007.641.278 40.785 40.785 0 0010.3-2.177c.976-.4 1.433-.6 1.166-2.359-.12-.792-.739-2.574-1.223-4.766a5.849 5.849 0 01.455-3.274c1.1-2.51 4.666-8.72 6.4-13.988 1.036-3.147 2.668-8.173 2.635-11.724-.013-1.478-.848-3.102-3.998-3.549z"
      />
      <path
        className="moving_prefix__g"
        d="M594.362 61.751c-.42-.355-2.663.2-3.364-.275-.821-.551-.735-2.118-.711-2.978.049-1.744 1.62-7.216 1.129-10.455-.176-1.162-.319-1.816-1.552-1.83a9.551 9.551 0 01-3.848-.612 7.679 7.679 0 01-3.174-2.041c-.368-.369-.745-.949-1.178-.883-.471.071-.787.814-.916 1.53-.553 3.066.691 5.247 2.159 7.911a6.44 6.44 0 002.381 2.593c.995.582.777.965.826 1.652.106 1.479.163 3.543.183 4.89a1.843 1.843 0 01-.416 1.357 3.605 3.605 0 01-1.414.629 1.259 1.259 0 00-.729.6 12.8 12.8 0 00-1.17 3.191 8.732 8.732 0 00-.231 3.806c.26 1.132 1.371 1.472 2.638 1.222 2.2-.434 4.287-2.24 5.328-3.2a20.744 20.744 0 003.943-5.4c.245-.635.521-1.364.116-1.707z"
      />
      <path
        className="moving_prefix__g"
        d="M582.193 40.379a22.263 22.263 0 00-3.194-.484.613.613 0 00-.74.533c-.248 1.5-.416 3.027-.61 3.881-.44.468-1.877 1.754-2.294 2.237a1.846 1.846 0 00.347 2.061 1.615 1.615 0 002.009-.012 8.833 8.833 0 012.139-1.771c.633-.328.226-1.8.516-2.978a5.122 5.122 0 011.625-2.491c.558-.424.822-.822.202-.976z"
      />
      <path
        className="moving_prefix__k"
        d="M593.361 79.053a12.091 12.091 0 01-3.13 2.209c-2.135 1.03-7.678.9-11.216.9-1.661 0-3.092.022-4.373-.756-1.018-.619-1.722-1.838-2.078-1.633-.186.107-.2.982.118 1.769a4.637 4.637 0 001.565 1.982c.652.48 1.3.877 1.444 1.342.077.253.309.5.857.5.89 0 4.338.089 6.033.089h6.481a5.25 5.25 0 003.861-1.832 8.4 8.4 0 001.718-4.279c.123-1.391-.66-.887-1.28-.291z"
      />
      <path
        className="moving_prefix__f"
        d="M618.817 86.053c-3.534-5.4-11.029-16.53-14.637-20.388a11.235 11.235 0 00-3.781-3.16c-1.37-.594-3.236.992-3.913 2-1.023 1.527-.918 2.705-.507 4.479.437 1.886 2.28 4.037 3.763 5.563 2.212 2.277 10.559 10.339 12.141 12.157 1.116 1.283.7 2.494-.827 3.725-3.59 2.9-9.248 6.839-13.473 9.958-1.253.925-1.626 1.535-1.591 2.606.049 1.538 1.959 1.619 3.22.9 4.083-2.321 17.123-10.37 18.889-11.726 1.972-1.51 2.309-3.681.716-6.114z"
      />
      <path
        className="moving_prefix__h"
        d="M590.964 51.834c-.116-1.1-.533-1.1-.665-.573a7.465 7.465 0 01-.861 1.976 3.551 3.551 0 01-1.716 1.52c-.9.231-1.848-.243-2.1 0-.349.337.123 1.172.606 1.542a3 3 0 002.831.188c1.584-.917 2.087-2.925 1.905-4.653z"
      />
      <path
        d="M599.325 57.238c-.666-1.853-2.077-3.325-3.053-4.982-1.139-1.928-1.072-4.466-1.7-6.619a10.515 10.515 0 00-2.522-4.344 8.06 8.06 0 00-6.377-2.5 6.075 6.075 0 00-5.959 4.8c-.626 2.295.352 4.59.417 7.24.072 2.932-1.957 5.715-2.48 8.05-.735 3.289.723 4.75 1.657 5.428a1.5 1.5 0 00.974.221c1.505-.3 4.152-1 5.355-1.4a1.265 1.265 0 00.859-.965c.144-1.939-.06-5.585-.112-6.662-.033-.676-.006-.714-.472-.877a3.767 3.767 0 01-1.114-.641c-1.419-1.183-1.7-3.047-2.376-4.165-1.711-2.814-1.423-5.35-.587-6.551a12.725 12.725 0 008.2 3.479c.285.02.445.157.562.528a13.95 13.95 0 01-.5 6.7 14.893 14.893 0 00-.785 6.725 4.642 4.642 0 004.38 3.847c2.312.162 5.436-1.815 5.934-2.33.59-1.145.299-3.296-.301-4.982z"
        fill="#333"
      />
      <path
        className="moving_prefix__g"
        d="M591.665 47.831c-.023.776-.094 1.436-.671 1.522s-1.128-.588-1.243-1.356a1.212 1.212 0 01.837-1.547c.747-.248 1.101.589 1.077 1.381zM600.36 101.31a2.042 2.042 0 00-1.708-1.493c-.357.018-.821.484-1.308.557-1.534.229-1.986-.918-2.972-1.8-.9-.8-2.179.081-2.739.424a2.489 2.489 0 01-.718.273c-1.037.2-3.69.853-3.85 1.226s.36 1.131.831 1.569a5.665 5.665 0 002.191.235 18.1 18.1 0 012.534 0 4.056 4.056 0 012.073.916 3.481 3.481 0 004.877.5 1.927 1.927 0 00.789-2.407z"
      />
      <g>
        <path
          className="moving_prefix__d"
          d="M539.941 165.463h48.694v30.99h-48.694z"
        />
        <path
          className="moving_prefix__e"
          d="M601.528 196.453h-12.893v-30.99h12.893z"
        />
        <path
          className="moving_prefix__m"
          d="M577.705 176.393h-48.694l10.93-10.93h48.694zM598.751 176.393h12.893l-10.112-10.93h-12.893z"
        />
        <g>
          <path
            className="moving_prefix__n"
            d="M569.566 185.347h13.286v.589h-13.286zM569.566 187.169h13.286v.589h-13.286zM569.566 188.991h13.286v.589h-13.286zM569.566 190.813h8.54v.589h-8.54z"
          />
        </g>
      </g>
      <g>
        <g transform="translate(61.937 149.476)">
          <path
            className="moving_prefix__o"
            d="M35.484 0a23.489 23.489 0 110 46.977h-12V0z"
          />
          <circle className="moving_prefix__o" cx={23.489} cy={23.489} r={23.489} />
          <circle
            className="moving_prefix__p"
            cx={15.033}
            cy={15.033}
            r={15.033}
            transform="translate(8.456 8.456)"
          />
        </g>
        <path
          className="moving_prefix__o"
          d="M58.9 149.476a23.489 23.489 0 110 46.977h-12v-46.977z"
        />
        <g transform="translate(238.211 149.476)">
          <path
            className="moving_prefix__o"
            d="M35.485 0a23.489 23.489 0 110 46.977h-12V0z"
          />
          <circle className="moving_prefix__o" cx={23.489} cy={23.489} r={23.489} />
          <circle
            className="moving_prefix__p"
            cx={15.033}
            cy={15.033}
            r={15.033}
            transform="translate(8.456 8.456)"
          />
        </g>
        <path
          className="moving_prefix__o"
          d="M235.174 149.476a23.489 23.489 0 110 46.977h-12v-46.977z"
        />
        <path
          className="moving_prefix__m"
          d="M301.965 168.771l16.39-1.151a8.836 8.836 0 008.217-8.814V95.45l-7.347-83.643A12.944 12.944 0 00306.331-.004H167.07v168.775z"
        />
        <path
          className="moving_prefix__m"
          d="M272.144 0H59.984a13.914 13.914 0 00-13.155 9.381l-32.994 95.738a27.82 27.82 0 01-5.439 9.34l-4.919 5.579a13.913 13.913 0 00-3.478 9.2v36.843a6.679 6.679 0 006.679 6.679h187.944l73.216-5.142a8.836 8.836 0 008.217-8.814V95.45l2.7-83.542C278.966 5.394 277.249 0 272.144 0z"
        />
        <path
          className="moving_prefix__q"
          d="M251.735 168.75v-3.565a28.556 28.556 0 10-57.113 0v7.576z"
        />
        <path
          className="moving_prefix__o"
          d="M251.732 165.187v3.564l-28.556 2.007v-21.28h12a23.37 23.37 0 0114.483 5 28.356 28.356 0 011.426 4.612c.026.116.049.232.073.347l.071.349c.024.115.043.234.065.352s.045.261.067.393c.018.1.033.208.049.312.02.122.037.244.055.365s.031.232.045.347c.031.243.059.489.084.736.012.114.021.228.031.342l.023.261c0 .061.008.122.014.185.016.214.03.43.039.644.006.122.012.243.016.365a.362.362 0 010 .043c.008.212.014.428.016.642l-.001.414z"
        />
        <circle
          className="moving_prefix__p"
          cx={23.489}
          cy={23.489}
          r={23.489}
          transform="translate(199.689 149.476)"
        />
        <circle
          className="moving_prefix__r"
          cx={15.033}
          cy={15.033}
          r={15.033}
          transform="translate(208.145 157.932)"
        />
        <path
          className="moving_prefix__q"
          d="M75.461 172.762v-7.576a28.557 28.557 0 10-57.113 0v7.576z"
        />
        <path
          className="moving_prefix__o"
          d="M75.461 165.187v7.575H46.904v-23.287h12a23.373 23.373 0 0114.481 5 28.467 28.467 0 012.076 10.712z"
        />
        <circle
          className="moving_prefix__p"
          cx={23.489}
          cy={23.489}
          r={23.489}
          transform="translate(23.415 149.476)"
        />
        <circle
          className="moving_prefix__r"
          cx={15.033}
          cy={15.033}
          r={15.033}
          transform="translate(31.871 157.932)"
        />
        <path
          className="moving_prefix__q"
          d="M93.87 20.518h.785V158.72h-.785zM86.262 109.186H70.456v-4.239h15.806zm-15.021-.786h14.235v-2.668H71.241zM46.905 131.399H22.851a3.179 3.179 0 01-3.176-3.175v-19.43h.785v19.43a2.393 2.393 0 002.39 2.39h24.058z"
        />
        <path
          className="moving_prefix__q"
          d="M179.963 159.113H78.636l-.052-.333c-2.383-15.355-16.3-27.382-31.676-27.382v-.785a32.463 32.463 0 0121.469 8.35 33.388 33.388 0 0110.93 19.365h100.66z"
        />
        <path
          className="moving_prefix__q"
          d="M191.45 159.113h-11.486v-.785h10.815a33.389 33.389 0 0110.931-19.365 32.463 32.463 0 0121.469-8.35 31.66 31.66 0 0115.315 4.489c4.708 2.7 8.537 6.264 10.546 9.8h27.017v.786h-27.48l-.112-.2c-1.908-3.478-5.685-7.013-10.362-9.7a30.865 30.865 0 00-14.924-4.385c-15.38 0-29.294 12.028-31.677 27.382zM320.762 20.125v.785H42.855l.271-.785z"
        />
        <path
          className="moving_prefix__k"
          d="M89.839 83.028V24.414a3.9 3.9 0 00-3.9-3.9H50.602a3.9 3.9 0 00-3.718 2.731l-24.872 79.33a3.9 3.9 0 004.449 4.992l51.171-9.781a15.027 15.027 0 0012.207-14.758z"
        />
        <path
          className="moving_prefix__q"
          d="M19.688 108.676l1.948-6.214.749.234-1.948 6.215z"
        />
        <path
          className="moving_prefix__q"
          d="M25.714 108.035a4.291 4.291 0 01-4.077-5.574l24.872-79.33a4.271 4.271 0 014.093-3.006v.785a3.489 3.489 0 00-3.343 2.456l-24.868 79.33a3.5 3.5 0 004 4.489l51.172-9.781.148.771-51.172 9.782a4.392 4.392 0 01-.825.078z"
        />
        <rect
          className="moving_prefix__s"
          width={9.608}
          height={25.765}
          rx={3.994}
          transform="translate(31.101 83.029)"
        />
        <path
          className="moving_prefix__t"
          d="M339.563 20.155l-5.607 75.292q-.426 5.73-.427 11.48v47.66h22.3V20.155z"
        />
        <path
          className="moving_prefix__d"
          d="M336.801 20.155l-7.571 75.292q-.427 5.73-.427 11.48v47.66h20.879v-53.405l1.421-81.027z"
        />
        <path
          className="moving_prefix__q"
          d="M323.489 95.448v59.14H279.14v-59.14l2.027-71.509a3.9 3.9 0 013.894-3.786h29.013a3.9 3.9 0 013.884 3.6z"
        />
        <path
          className="moving_prefix__t"
          d="M263.065 20.155l5.607 75.292q.426 5.73.427 11.48v47.66h-22.3V20.155z"
        />
        <path
          className="moving_prefix__d"
          d="M265.827 20.155l7.571 75.295q.427 5.73.427 11.48v47.657h-20.876v-53.405l-1.421-81.027z"
        />
        <path className="moving_prefix__n" d="M0 154.427h6.887v7.01H0z" />
        <path
          className="moving_prefix__s"
          d="M8.115 122.06l-.6 2.749a27.817 27.817 0 00-.632 5.9v12.917a1.67 1.67 0 01-1.67 1.67H-.005v-16.057a13.91 13.91 0 013.479-9.2h3a1.669 1.669 0 011.641 2.021z"
        />
        <path
          className="moving_prefix__q"
          d="M26.807 144.901H0v.785h26.044q.374-.4.763-.785z"
        />
      </g>
      <g>
        <path
          className="moving_prefix__u"
          d="M454.125 189.413c.286-1.922-.146-3.016.029-4.952a40.427 40.427 0 01.767-4.634c.132-.57.5-2.34-1.1-3.038-1.56-.678-2.769-.676-3.884-1.284-1.251-.683-3.144-1.721-3.735-2.1a3.066 3.066 0 00-4.307.543 2.244 2.244 0 00.5 3.022c1.1.989 2.258 1.706 3.33 2.827 1.193 1.248 2.4 6.345 2.381 8.392-.02 1.633-2.56 5.619-1.17 6.253s4.257-.761 5.216-1.573a6 6 0 001.973-3.456zM387.418 187.186c.31-4.026-.488-5.24-3.29-4.484a4.573 4.573 0 00-2.663 2.481 10.2 10.2 0 01-3.34 4.332 19.5 19.5 0 01-5.427 2.765c-1.409.529-4.813.9-5.232 2.437-.474 1.735 3.12 1.735 5.492 1.735 3.055 0 6.919-1.456 8.88-1.662 1.036-.109 2.593.087 3.841-.066 2.309-.283 2.536-.868 2.357-2.7-.118-1.209-.738-3.253-.618-4.838z"
        />
        <path
          className="moving_prefix__d"
          d="M422.216 69.571c-.208-3.918-1.061-4.811-4.771-5.561a55.243 55.243 0 00-11.991-1.1c-4.914.186-11.666 1.04-13.73 1.771-3.275 1.16-4.037 3.013-4.219 4.8-.286 2.807 1.77 31.609 3.034 36.282 1.011 3.739 3.552 5.216 7.534 5.413 2.813.139 13.816-.005 18.014-.25 4.422-.259 5.412-2.742 5.481-6.579.11-6.114.776-32.371.648-34.776z"
        />
        <path
          className="moving_prefix__d"
          d="M363.619 120.866c4.968-2.986 12.011-8.063 17.017-11.441a20.632 20.632 0 008.3-10.217c1.688-4.95 7.183-19.813 8.152-24.762a13.289 13.289 0 00-.384-6.295c-.7-2.06-3.083-3.919-5.126-3.411-5.066 1.26-5.683 5.507-6.549 10.051-1.07 5.617-2.713 17.068-3.449 20.892a16.432 16.432 0 01-4.472 7.9c-3.42 3.041-14.188 12.027-15.26 13-1.629 1.477-1.936 2.541-1.3 3.9.393.833 1.677 1.224 3.071.383z"
        />
        <path
          className="moving_prefix__e"
          d="M447.4 171.953c-2.965-3.164-16.481-18.339-18.11-20.195a19.289 19.289 0 01-4.673-12.017c-.283-7.292-1-30.043-.96-32.646a10.06 10.06 0 00-.357-3.029c-.651-1.048-1.147.04-2.657.157-2.694.208-4.232-1.216-5.574-2.251-3.875-2.989-3.943-7.748-4.214-12.17-.15-2.456-.321-6.39-.286-8.52.109-6.609.566-10.42 2.441-13.136 1.413-2.047 3.049-3.443 4.947-3.753.59-.1.636-.23.294-.329a16.576 16.576 0 00-2.6-.449c-1.827 0-4.009 1.639-5.593 4.141-1.631 2.576-2.36 6.986-2.633 12.352h-11.3c-.464-5.791.013-12.385.775-15.122.306-1.1.545-1.593.086-1.532-.427.056-1.652.227-2.01.278-.528.075-.614.177-.878.976-1.125 3.4-1.261 12.235-1.229 17 .018 2.6-.177 11.7-.229 14.655a15.211 15.211 0 01-.823 6.035 5.7 5.7 0 01-2.544 2.414 2.2 2.2 0 00-1.027 2.051c-.208 3.546-.271 8.355-.82 10.375a62.619 62.619 0 01-2.49 7.567c-2.276 5.727-4.821 10.494-6.923 15.972-1.853 4.83-1.6 9.162-.975 14.233.735 5.968 3.071 24.541 3.558 29.181.221 2.1 3.228 2.2 5.236 2.388 4.659.439 3.917-2.486 3.944-5.15.03-2.821-.216-14.346-.26-17.467a29.684 29.684 0 012.191-12.68c1.94-5.455 8.129-17.255 9.183-19.442.4-.821 1.312-2.953 2.786-2.827 1.714.146 1.772 1.288 2.461 3.266.858 2.46 3.787 12.547 4.859 15.783a21.2 21.2 0 005.51 8.8c4.1 4.012 18.78 17.774 24.439 22.778 1.456 1.288 2.367 1 3.993.065a15.673 15.673 0 003.878-3.845c.915-1.586.244-2.135-1.416-3.907z"
        />
        <path
          d="M405.362 129.94c-.546-1.345-.842-1.979-1.412-3.278a1.987 1.987 0 00-1.476-1.016c-.806-.114-5.442-.428-6.428-.414-2 3.666-.478 8.077-1.46 11.263-.636 2.063-2.755 3.5-3.43 5.173-1.144 2.845-.881 5.274-1.316 8.269-.39 2.685-2.275 4.745-2.714 7.3-.546 3.176 1.121 6.093 1.316 9.9.171 3.359-.162 6.532-.146 8.058.012 1.295.635 3.687 1.472 4.361-.035-4.317-.214-12.952-.253-15.588a29.714 29.714 0 012.19-12.681c1.942-5.453 8.131-17.253 9.183-19.441.4-.821 1.312-2.952 2.786-2.827a1.979 1.979 0 011.9 1.593 6.034 6.034 0 00-.212-.672z"
          fill="#1f367a"
        />
        <path
          className="moving_prefix__w"
          d="M370.305 151.545l-.951-12.2h3.556v12.2zM399.126 151.545l-.951-12.2h3.556v12.2z"
        />
        <path
          className="moving_prefix__x"
          d="M397.238 94.259H376.2a2.763 2.763 0 00-2.674 2.067l-3.525 13.537h21.038l3.525-13.537a2.763 2.763 0 012.674-2.067z"
        />
        <path
          d="M1090.561 597.568v.005l.023.025z"
          transform="translate(-692.975 -486.801)"
          fill="url(#prefix__a)"
        />
        <path
          className="moving_prefix__g"
          d="M395.599 44.206c.658-2.667 3.958-4.172 7.065-4.372a7.559 7.559 0 017.229 3.821c1.059 2.192 1.363 5.807-1.375 11.278-.655 1.308-.353 1.849-.114 3.271.054.321.4 2.076.545 2.676.474 1.959 1.354 1.464 1.63 2.172 1.041 2.667-4.257 6.6-8.649 6.784-4.09.169-3.965-4.518-3.717-5.6.2-.855.964-.993 1.384-1.26.7-.443.781-.839.764-1.666a10.168 10.168 0 00-.043-.554c-.1-.91-.222-3.221-.815-3.71-.5-.417-.759-.566-1.322-.993a6.419 6.419 0 01-1.518-2.728 39.866 39.866 0 01-.357-4.931c-.14-1.648-1.116-2.527-.707-4.188z"
        />
        <path
          className="moving_prefix__h"
          d="M397.761 55.6a1.933 1.933 0 00.42.456c.562.427.812.582 1.322.993a2.914 2.914 0 01.587 1.69 3.87 3.87 0 001.086-.054c1.721-.244 3.425-2.152 3.957-3 .578-.917 1.212-3.141.965-3.5-.143-.211-1.283 1.466-1.481 1.712-1.736 2.165-4.793 2.392-6.856 1.703zM396.305 48.397c.016.2.029.414.039.646.151-.548.061-1.218.453-1.736a2.823 2.823 0 011.683-.939 27.542 27.542 0 015.292-.273c1.277-.039 3.08-.33 1.981-1.755-1.806-2.338-6.362-2.911-8.96-1.693a3.3 3.3 0 00-.755.484 3.94 3.94 0 00-.439 1.073c-.409 1.662.567 2.541.706 4.193z"
        />
        <path d="M396.111 41.073a8.7 8.7 0 016.137-3.136c3.8-.234 7.953 1.25 8.531 3.657.217.906.12 1.8.7 2.4 1.624 1.678.254 5.182-.277 6.5-.73 1.813-2.5 5.584-3.146 5.333a2.924 2.924 0 01-.93-1.982c.085-1.188.13-2.425.326-3.6a1.639 1.639 0 00-.26-1.566c-.146.311-.477 1.218-.477 1.218l-1.2-.559a9.725 9.725 0 01-1.335-6.038 2.514 2.514 0 00-.953-.712 7.711 7.711 0 00-6.678 1.183 1.884 1.884 0 00-.792 1.685c0 .37.224 1.289-.135 1.2s-.753-1-.814-2.3a6 6 0 011.303-3.283z" />
        <path
          className="moving_prefix__z"
          d="M406.771 49.308c-.314 1.2.048 2.328.81 2.528s1.634-.608 1.948-1.8-.048-2.329-.81-2.529-1.635.609-1.948 1.801z"
        />
        <path
          className="moving_prefix__e"
          d="M407.259 38.217c-2.329-.693-6.342-1.092-8.207.049a7.953 7.953 0 00-4.063 4.987c-.136.484.153.816.456.678a10.271 10.271 0 016.206-.559 13.217 13.217 0 014.4 1.873 18.7 18.7 0 015.089 4.241c.547.633.974-.266 1.085-.746.964-4.142.947-8.764-4.966-10.523z"
        />
        <path
          className="moving_prefix__d"
          d="M405.59 44.68c-.17-.822-1.324-2.746-4.571-3.76-1.242-.388-3.284-1.474-4.608-1.9-.633-.2-.768-.079-.669-.063a3.565 3.565 0 011.407.446 16.076 16.076 0 012.636 2.26 16.094 16.094 0 002.564 1.6c.9.445 2.469 1.1 2.943 1.368.217.114.327.189.298.049z"
        />
        <path
          className="moving_prefix__e"
          d="M405.488 44.689c-1.842-1.028-4.68-1.809-6.743-4.134a4.691 4.691 0 00-6.58-.7c-.786.647-1.958 2.128-1.432 3.517.472 1.249 2.612 1.95 3.78 2.1.57.075.7-.009.938-.275a5.079 5.079 0 012.1-1.521 9.757 9.757 0 015.591.2 18.623 18.623 0 012.114.955c.303.168.492.014.232-.142z"
        />
        <path
          d="M900.468 523.5a3.869 3.869 0 00-3.7-2.749h-89.55v31.209h90.86l3.525-13.537a2.763 2.763 0 012.674-2.067h.079z"
          transform="translate(-528.077 -442.095)"
          fill="url(#prefix__b)"
        />
        <path
          d="M923.619 595.407h-116.4v14.74h116.4a2.764 2.764 0 002.764-2.764v-9.213a2.763 2.763 0 00-2.764-2.763z"
          transform="translate(-528.077 -485.543)"
          fill="url(#prefix__c)"
        />
        <path
          d="M402.523 94.259h-5.285a2.763 2.763 0 00-2.674 2.067l-3.525 13.537h2.045v14.74H279.141v14.74h123.382a2.764 2.764 0 002.764-2.764V97.022a2.763 2.763 0 00-2.764-2.763z"
          fill="#feb840"
        />
        <path
          d="M925.934 598.41a2.747 2.747 0 01.449 1.508v9.212a2.764 2.764 0 01-2.764 2.764h-116.4v7.006h126.145v-10.809z"
          transform="translate(-528.077 -487.291)"
          fill="url(#prefix__d)"
        />
        <path
          className="moving_prefix__n"
          d="M402.523 93.967h-26.026l-3.826-12.648a4.138 4.138 0 00-3.983-2.956h-89.547v.585h89.547a3.556 3.556 0 013.423 2.541l3.78 12.5a3.055 3.055 0 00-2.648 2.268l-.038.148h.6a2.472 2.472 0 012.391-1.849h19.244a3.048 3.048 0 00-1.163 1.7l-3.468 13.318H279.141v.585h116.4a2.474 2.474 0 012.471 2.471v9.213a2.474 2.474 0 01-2.47 2.47v.585a3.059 3.059 0 003.056-3.056v-9.213a3.06 3.06 0 00-3.056-3.056h-4.125l3.43-13.171a2.472 2.472 0 012.391-1.849h5.285a2.474 2.474 0 012.471 2.471v39.557a2.474 2.474 0 01-2.471 2.471v.585a3.059 3.059 0 003.056-3.056V97.022a3.059 3.059 0 00-3.056-3.055z"
        />
        <path
          className="moving_prefix__x"
          d="M395.542 109.864h-116.4v2.9h116.4a2.764 2.764 0 012.764 2.764v-2.9a2.763 2.763 0 00-2.764-2.764z"
        />
        <path
          className="moving_prefix__d"
          d="M405.925 124.364c3.9-3.77 12.857-12.391 16.573-16.541 2.786-3.111 5.817-6.207 5.427-11.276-.523-6.806-1.82-18.912-2.795-23.884-.467-2.382-.715-5.752-2.762-6.849-1.77-.948-2.819-1.665-4.062-.934-4.15 2.441-3.33 6.633-2.867 10.708.605 5.324 2.1 15.862 2.3 19.449.141 2.475-1.267 6.4-2.554 8.445-2.172 3.447-10.9 15.961-11.7 17.032-1.316 1.771-1.323 2.744-.553 3.965a2.085 2.085 0 002.993-.115z"
        />
        <path
          className="moving_prefix__z"
          d="M405.625 121.09a2.255 2.255 0 00-2.51-.1c-.936.633-2.918 1.779-4.095 2.494a5.1 5.1 0 01-3.45.6c-1.642-.2-2.625-.188-3.155.159-.548.359-2.153 1.093-.894 1.629 1.344.572 4.721 1.868 5.935 2.3a4.066 4.066 0 002.162.236c1.345-.389 2.619-.856 3.442-1.16.907-.335 1.737-1.735 2.561-2.642a2.386 2.386 0 00.004-3.516z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
