import React, { Component } from 'react';
import './HomeWarrantyCard.scss';
// import Checkbox from '@material-ui/core/Checkbox';
// import ShieldImage from '../../Assets/images/WarantyHome/shield.png';
import Button from '../../components/atoms/Button';

type initialProps = {
    history?: any;
    title?: string;
    name?: string;
    planRate?: string;
    viewDetails?: any;
    onClickCheckBox?: any;
    isSelected?: boolean;
    recommended?: boolean;
    // itemValues?: any;
    // modalHandler?: any;
    handleSelectPlanData?: any;
};
type initialState = {
    slug: any;
};

export class HomeWarrantyCard extends Component<initialProps, initialState> {
    state: initialState = {
        slug: null,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                className="warrantyCardMainDiv"
                // onClick={props.onClickCheckBox}
                style={{
                    marginTop: this.props.recommended ? '38px' : '0px',
                    border: this.props.isSelected
                        ? '1px solid #0066F5'
                        : this.props.recommended
                        ? '1px solid #009CBD'
                        : '1px solid #e2e2e2',
                }}
            >
                {/* <Checkbox className="cardCheck" checked={props.isSelected} /> */}
                <div className="centerText">
                    {/* <div className="shield">
                    <img src={ShieldImage} />
                    <div className="firstLetter">{`${props.title && props.title.split('Shield')[1].charAt(0)}`}</div>
                </div> */}
                    {this.props.recommended ? (
                        <div className="recommend-detailed-card">
                            <div className="recommend-internal">Recommended</div>
                        </div>
                    ) : null}
                    <div className="title">
                        {this.props.title && this.props.title.split('Shield')[1] ? 'Shield' : this.props.title}
                        <span style={{ fontWeight: 'bold' }}>
                            {this.props.title &&
                            this.props.title.split('Shield')[1] &&
                            this.props.title &&
                            this.props.title.split('Shield')[1].split(': Buyer Only') &&
                            this.props.title.split('Shield')[1].split(': Buyer Only')[0]
                                ? this.props.title.split('Shield')[1].split(': Buyer Only')[0]
                                : this.props.title && this.props.title.split('Shield')[1]}
                        </span>
                    </div>
                    {/* <div className="name">{this.props.name}</div> */}
                    <div className="lineDiv"></div>
                    <div className="rateDiv">
                        <div className="rateText">Plans starting as low as:</div>
                        <div className="rateTextDiv">
                            {/* <div className="dollarBeforeDiscount">$</div>
                            <div className="rateBeforeDiscount">320</div> */}
                            <div className="dollar">$</div>
                            <div className="rate">{this.props.planRate}</div>
                        </div>
                    </div>
                    <div className="lineDiv"></div>

                    {/* <div className="appliances-waranty">
                        {this.props.itemValues.map((item: any) => (
                            <div className="appliances-inner-content">{item}</div>
                        ))}
                    </div> */}
                    <div className="button-select-plan">
                        <Button
                            className="submitButton"
                            border={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? `1px solid ${slug.primary_color}`
                                    : '1px solid #273E59'
                            }
                            color={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : '#273E59'
                            }
                            backgroundColor="#ffff"
                            onClick={this.props.handleSelectPlanData}
                        >
                            Select Plan
                        </Button>
                    </div>
                    {/* <div className="viewDetails" onClick={props.viewDetails}>
                    View details
                </div> */}
                </div>
            </div>
        );
    }
}

export default HomeWarrantyCard;
