export const GET_CHECKLIST_BY_MOVE_ID = 'GET_CHECKLIST_BY_MOVE_ID';
export const GET_CHECKLIST_BY_MOVE_ID_SUCCESS = 'GET_CHECKLIST_BY_MOVE_ID_SUCCESS';
export const GET_CHECKLIST_BY_MOVE_ID_ERROR = 'GET_CHECKLIST_BY_MOVE_ID_ERROR';

export const POST_SUBSCRIPTION_DETAILS = 'POST_SUBSCRIPTION_DETAILS';
export const POST_SUBSCRIPTION_DETAILS_SUCCESS = 'POST_SUBSCRIPTION_DETAILS_SUCCESS';
export const POST_SUBSCRIPTION_DETAILS_ERROR = 'POST_SUBSCRIPTION_DETAILS_ERROR';

export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_ERROR = 'GET_SUBSCRIPTION_DETAILS_ERROR';

export const UPDATE_SUBSCRIPTION_DETAILS = 'UPDATE_SUBSCRIPTION_DETAILS';
export const UPDATE_SUBSCRIPTION_DETAILS_SUCCESS = 'UPDATE_SUBSCRIPTION_DETAILS_SUCCESS';
export const UPDATE_SUBSCRIPTION_DETAILS_ERROR = 'UPDATE_SUBSCRIPTION_DETAILS_ERROR';

// Home Valuation Main Card
export const HOME_VALUATION_CARD_DETAILS = 'HOME_VALUATION_CARD_DETAILS';
export const HOME_VALUATION_CARD_DETAILS_SUCCESS = 'HOME_VALUATION_CARD_DETAILS_SUCCESS';
export const HOME_VALUATION_CARD_DETAILS_ERROR = 'HOME_VALUATION_CARD_DETAILS_ERROR';

// Home Valuation Main Card
export const MORTAGE_DETAILS = 'MORTAGE_DETAILS';
export const MORTAGE_DETAILS_SUCCESS = 'MORTAGE_DETAILS_SUCCESS';
export const MORTAGE_DETAILS_ERROR = 'MORTAGE_DETAILS_ERROR';

// Add User Dertails
export const ADD_USER_DETAILS = 'ADD_USER_DETAILS';
export const ADD_USER_DETAILS_SUCCESS = 'ADD_USER_DETAILS_SUCCESS';
export const ADD_USER_DETAILS_ERROR = 'ADD_USER_DETAILS_ERROR';
