import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    newServicesFailure,
    newServicesSuccess,
    requestsFiledSuccess,
    requestsFiledFailure,
    phoneVerifySuccess,
    phoneVerifyFailure,
    otpVerifySuccess,
    otpVerifyFailure,
    delRequestsFiledFailure,
    delRequestsFiledSuccess,
    saveResponseSuccess,
    saveResponseFailure,
    updateResponseSuccess,
    updateResponseFailure,
} from './actions';
import {
    UPDATE_ADDRESS_NEW_SERVICES_REQUEST,
    UPDATE_ADDRESS_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_PHONE_VERIFY_REQUEST,
    UPDATE_ADDRESS_OTP_VERIFY_REQUEST,
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST,
    UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST,
} from './actionTypes';
import apiJunction from '../../utils/api';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* updateAddressNewServices() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/checklist-api/?must_do=true`,
        });
        if (response.data && response.status === 200) {
            yield put(newServicesSuccess({ newServices: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(newServicesFailure({ error: e.message }));
    }
}

function* updateAddressRequestsFiled() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/address-api/`,
        });
        if (response.data && response.status === 200) {
            yield put(requestsFiledSuccess({ requestsFiled: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(requestsFiledFailure({ requestsFiledError: e.message }));
    }
}

function* updateAddressPhoneVerify(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/accounts/users/${action.payload.userId}/update_phone_verify/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(phoneVerifySuccess({ phoneVerified: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(phoneVerifyFailure({ phoneVerifiedError: e.message }));
    }
}

function* updateAddressOtpVerify(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/accounts/users/${action.payload.userId}/verify_code/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(otpVerifySuccess({ otpVerified: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(otpVerifyFailure({ otpVerifiedError: e.message }));
    }
}

function* updateAddressDelRequestsFiled(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'delete',
            url: `/api/checklist/address-api/${action.payload.id}/`,
            
        });
        if (response.status === 204) {
            yield put(delRequestsFiledSuccess({ deleteRequestsFiled: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(delRequestsFiledFailure({ deleteRequestsFiledError: e.message }));
    }
}

function* updateAddressSaveResponse(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/checklist/address-api/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            yield put(saveResponseSuccess({ saveResponse: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(saveResponseFailure({ saveResponseError: e.message }));
    }
}

function* updateAddressUpdateResponse(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'put',
            url: `/api/checklist/address-api/${action.payload.id}/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(updateResponseSuccess({ updateResponse: response.data }));
        } else {
        }
    } catch (e: any) {
        yield put(updateResponseFailure({ updateResponseError: e.message }));
    }
}

export default function* checklistDashboardSaga() {
    yield all([takeLatest(UPDATE_ADDRESS_NEW_SERVICES_REQUEST, updateAddressNewServices)]);
    yield all([takeLatest(UPDATE_ADDRESS_REQUEST_FILED_REQUEST, updateAddressRequestsFiled)]);
    yield all([takeLatest(UPDATE_ADDRESS_PHONE_VERIFY_REQUEST, updateAddressPhoneVerify)]);
    yield all([takeLatest(UPDATE_ADDRESS_OTP_VERIFY_REQUEST, updateAddressOtpVerify)]);
    yield all([takeLatest(UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST, updateAddressDelRequestsFiled)]);
    yield all([takeLatest(UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST, updateAddressSaveResponse)]);
    yield all([takeLatest(UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST, updateAddressUpdateResponse)]);
}
