import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../atoms/Button';
import '../../styles/insurancePlanCard.scss';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    currency?: string;
    amount?: string;
    planRate?: string;
    planValidFor?: string;
    boxShadow?: any;
    selectPlan?: any;
    yearlyAmount?: string;
    // href?:string;
    viewCoverage?: any;
    isDouglasElliman: boolean;
}

const InsurancePlanCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
            },
        },
    }));

    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <div
            style={{
                width: props.width ? props.width : '200px',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 10px #9C9DA1',
                border: props.border ? props.border : null,
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '',
                padding: props.cardPadding ? props.cardPadding : '15px',
                cursor: 'pointer',
            }}
            className={props.className ? `${props.className} ${classes.root}` : classes.root}
        >
            <div className="insuranceCardMain">
                <div>
                    <div className="LogoDiv">
                        <img
                            width={props.imgWidth ? props.imgWidth : '80px'}
                            height={props.imgHeight ? props.imgHeight : '35px'}
                            src={props.cardImage}
                            style={{
                                marginRight: 'auto',
                            }}
                            alt=""
                        />
                        {/* <div className="viewCoverage">
                            <a
                                href=""
                                style={{
                                    color: props.isDouglasElliman ? '#009CBD' : 'blue',
                                }}
                                onClick={props.viewCoverage}
                            >
                                View Coverage Plan
                            </a>
                        </div> */}
                    </div>
                </div>
                <div>
                    <div className="planRateDiv">
                        <div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div
                                        className="currency"
                                        style={{
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '24px',
                                            marginTop: '7px',
                                        }}
                                    >
                                        {props.currency ? props.currency : ''}
                                    </div>
                                    <div
                                        className="amount"
                                        style={{
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: '48px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {props.amount ? props.amount : 'NA'}
                                    </div>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <div>
                                        * <img src={iIcon} height="15px" width="15px" alt="" />
                                    </div>

                                    <div style={{ margin: '10px 0', fontSize: '14px' }}>
                                        /{props.planRate ? props.planRate : 'NA'}
                                    </div>
                                </div>
                            </div>
                            <div className="rate">
                                <span style={{ fontFamily: 'Poppins, sans-serif' }}>{props.currency}</span>
                                {`${props.yearlyAmount}* ${props.planValidFor}`}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ButtonInsuranceCard">
                    <Button
                        width="100%"
                        backgroundColor={
                            props.isDouglasElliman
                                ? '#100B28'
                                : slug && slug.primary_color
                                ? slug.primary_color
                                : window.location.pathname.includes('landing')
                                ? '#30AB86'
                                : '#273E59'
                        }
                        onClick={props.selectPlan}
                        className={`${!props.isDouglasElliman && 'radiusBtn'}`}
                    >
                        Select Plan
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InsurancePlanCard;
