import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={54.708} height={51.279} viewBox="0 0 54.708 51.279" {...props}>
            <defs>
                <style>{`.InsuraceMainRoofprefix__a{fill:${
                    slug && slug.color_shade4
                        ? props.isdouglaselliman
                            ? '#ffffff'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }}.InsuraceMainRoofprefix__b{fill:${
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }}.prefix__c{fill:#fff}`}</style>
            </defs>
            <path className="InsuraceMainRoofprefix__a" d="M.941 10.565h14.087v14.086H.941zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M15.028 25.593H.942A.941.941 0 010 24.651V10.564a.941.941 0 01.942-.942h14.086a.941.941 0 01.942.942v14.087a.941.941 0 01-.942.942zM1.883 23.709h12.2V11.504h-12.2zm0 0"
            />
            <path className="InsuraceMainRoofprefix__a" d="M14.148 10.565h13.206V22.89H14.148zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M27.353 23.771H14.147a.88.88 0 01-.88-.881V10.564a.88.88 0 01.88-.88h13.206a.88.88 0 01.881.88v12.325a.881.881 0 01-.881.882zM15.027 22.01h11.445V11.445H15.028zm0 0"
            />
            <path className="InsuraceMainRoofprefix__a" d="M27.352 10.565h13.206V22.89H27.352zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M40.56 23.771H27.354a.88.88 0 01-.881-.881V10.564a.88.88 0 01.881-.88H40.56a.88.88 0 01.881.88v12.325a.88.88 0 01-.881.882zM28.235 22.01H39.68V11.445H28.235zm0 0"
            />
            <path className="InsuraceMainRoofprefix__a" d="M39.68 10.565h14.086v14.086H39.68zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M53.763 25.593H39.68a.941.941 0 01-.942-.942V10.564a.941.941 0 01.942-.942h14.083a.941.941 0 01.942.942v14.087a.941.941 0 01-.942.942zm-13.145-1.884h12.2V11.504h-12.2zm0 0"
            />
            <path className="prefix__c" d="M15.028 33.455H.941v-9.723l7.043-3.275 7.043 3.275zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M15.028 34.335H.941a.88.88 0 01-.881-.88v-9.722a.881.881 0 01.509-.8l7.043-3.275a.89.89 0 01.743 0l7.043 3.275a.881.881 0 01.509.8v9.722a.88.88 0 01-.879.88zm-13.206-1.76h12.325v-8.281l-6.159-2.866-6.163 2.866zm0 0"
            />
            <path className="prefix__c" d="M27.354 33.455H14.148v-9.723l6.6-3.275 6.6 3.275zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M27.353 34.335H14.147a.88.88 0 01-.88-.88v-9.722a.879.879 0 01.489-.788l6.6-3.275a.883.883 0 01.782 0l6.6 3.275a.878.878 0 01.49.788v9.722a.88.88 0 01-.875.88zm-12.326-1.761h11.445v-8.3l-5.722-2.838-5.723 2.838zm0 0"
            />
            <path
                d="M46.992 41.47l-6.5 4.28-6.461-4.28-6.444 4.28-6.435-4.28-6.43 4.28-6.788-4.28-7 4.419v4.508l6.991-4.418 6.6 4.28 6.509-4.28 6.458 4.28 6.422-4.28 6.385 4.28 6.676-4.28 6.78 4.418v-4.508zm0 0"
                fill="#c7ab5f"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M53.766 51.279a.887.887 0 01-.481-.143l-6.3-4.108-6.2 3.972a.886.886 0 01-.966-.01l-5.9-3.952-5.931 3.953a.88.88 0 01-.975 0l-5.973-3.958-6.024 3.96a.883.883 0 01-.962 0l-6.13-3.972-6.513 4.125a.88.88 0 01-1.351-.744v-4.509a.88.88 0 01.411-.744l7-4.419a.884.884 0 01.94 0l6.305 3.975 5.956-3.963a.879.879 0 01.975 0l5.941 3.951 5.956-3.955a.883.883 0 01.973 0l5.976 3.959 6.011-3.96a.878.878 0 01.964 0l6.775 4.419a.881.881 0 01.4.738v4.509a.879.879 0 01-.88.88zm-45.834-6.18a.877.877 0 01.479.142l6.12 3.966 6.03-3.963a.883.883 0 01.97 0l5.97 3.957 5.935-3.955a.882.882 0 01.979 0l5.906 3.959 6.189-3.968a.881.881 0 01.956 0l5.419 3.531v-2.407l-5.892-3.843-6.014 3.962a.88.88 0 01-.97 0l-5.974-3.957-5.957 3.956a.882.882 0 01-.975 0l-5.948-3.955-5.942 3.954a.875.875 0 01-.957.012l-6.318-3.978-6.116 3.862V48.8l5.64-3.565a.887.887 0 01.47-.136zm0 0"
            />
            <path className="prefix__c" d="M40.559 33.455H27.352v-9.723l6.6-3.275 6.6 3.275zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M40.56 34.335H27.354a.88.88 0 01-.881-.88v-9.722a.879.879 0 01.489-.788l6.6-3.275a.883.883 0 01.782 0l6.6 3.275a.878.878 0 01.49.788v9.722a.88.88 0 01-.874.88zm-12.325-1.761H39.68v-8.3l-5.723-2.838-5.722 2.838zm0 0"
            />
            <path className="prefix__c" d="M53.766 33.455H39.68v-9.723l7.043-3.275 7.043 3.275zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M53.766 34.335H39.679a.88.88 0 01-.881-.88v-9.722a.881.881 0 01.509-.8l7.043-3.275a.89.89 0 01.743 0l7.043 3.275a.881.881 0 01.509.8v9.722a.88.88 0 01-.879.88zM40.56 32.574h12.326v-8.281l-6.163-2.866-6.163 2.866zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__a"
                d="M46.723 28.599l-7.043 4.043v11.276l.373.071 6.8-4.28 6.909 4.418V32.643zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M53.715 44.988a.782.782 0 01-.424-.12l-6.439-4.117-6.331 3.981a.882.882 0 01-.632.119l-.373-.071a.88.88 0 01-.717-.865V32.643a.881.881 0 01.442-.764l7.043-4.043a.877.877 0 01.877 0l7.043 4.043a.88.88 0 01.442.764v11.414a.939.939 0 01-.457.808 1 1 0 01-.474.123zm-13.16-11.835v9.477l5.829-3.665a.883.883 0 01.943 0l5.554 3.553V33.15l-6.163-3.538zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__a"
                d="M27.542 43.989l6.4-4.28 6.338 4.28.277-.212V32.643l-6.6-4.043-6.6 4.043v11.225zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M27.542 44.869a.878.878 0 01-.479-.142l-.188-.122a.881.881 0 01-.4-.739V32.642a.88.88 0 01.421-.751l6.6-4.044a.877.877 0 01.92 0l6.6 4.044a.88.88 0 01.421.751v11.134a.881.881 0 01-.346.7l-.278.212a.883.883 0 01-1.028.031l-5.847-3.948-5.911 3.95a.881.881 0 01-.485.148zm6.4-6.04a.876.876 0 01.493.151l5.242 3.54v-9.384l-5.723-3.5-5.722 3.5v9.331l5.221-3.489a.881.881 0 01.492-.149zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__a"
                d="M14.532 43.989l6.511-4.28 6.244 4.28.066-.157V32.643l-6.6-4.043-6.6 4.043v11.212zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M27.292 44.869a.878.878 0 01-.5-.154l-5.757-3.946-6.017 3.955a.879.879 0 01-.775.095l-.385-.135a.879.879 0 01-.589-.831V32.642a.879.879 0 01.42-.751l6.6-4.044a.877.877 0 01.92 0l6.6 4.044a.88.88 0 01.421.751v11.189a.882.882 0 01-.069.341l-.066.157a.885.885 0 01-.812.54zm-12.26-11.734v9.474l5.531-3.636a.885.885 0 01.981.009l4.932 3.381v-9.228l-5.722-3.5zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__a"
                d="M13.741 43.989l.412-.144V33.017l-6.6-4.418-6.6 4.418v11.11l6.492-4.418zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M.941 45.009a.88.88 0 01-.881-.881V33.017a.878.878 0 01.391-.731l6.6-4.419a.883.883 0 01.979 0l6.6 4.419a.878.878 0 01.391.731v10.828a.881.881 0 01-.591.832l-.412.144a.882.882 0 01-.784-.1l-5.806-3.943-6 4.082a.882.882 0 01-.487.149zm6.492-6.179a.877.877 0 01.494.152l5.339 3.625v-9.119l-5.723-3.829-5.722 3.829v8.976l5.117-3.484a.883.883 0 01.495-.151zm0 0"
            />
            <path
                className="prefix__c"
                d="M46.992 39.71l-6.5 4.28-6.461-4.28-6.444 4.28-6.435-4.28-6.43 4.28-6.788-4.28-7 4.418v4.509l6.991-4.419 6.6 4.28 6.509-4.28 6.458 4.28 6.422-4.28 6.385 4.28 6.676-4.28 6.78 4.419v-4.509zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M53.766 49.517a.883.883 0 01-.481-.143l-6.3-4.108-6.197 3.971a.886.886 0 01-.966-.009l-5.9-3.952-5.934 3.954a.883.883 0 01-.975 0l-5.973-3.958-6.024 3.96a.88.88 0 01-.962 0l-6.13-3.972-6.515 4.118a.881.881 0 01-1.348-.741v-4.509a.881.881 0 01.411-.745l7-4.418a.884.884 0 01.94 0l6.305 3.975 5.956-3.963a.88.88 0 01.975 0l5.94 3.955 5.956-3.955a.883.883 0 01.973 0l5.976 3.958 6.011-3.959a.878.878 0 01.964 0l6.775 4.418a.882.882 0 01.4.738v4.505a.88.88 0 01-.461.774.89.89 0 01-.416.106zm-45.834-6.18a.877.877 0 01.479.142l6.12 3.966 6.03-3.964a.883.883 0 01.97 0l5.97 3.957 5.935-3.955a.883.883 0 01.979 0l5.906 3.958 6.189-3.968a.878.878 0 01.956 0l5.419 3.531v-2.407l-5.892-3.843-6.014 3.962a.88.88 0 01-.97 0l-5.974-3.957-5.957 3.956a.883.883 0 01-.975 0l-5.948-3.955-5.942 3.954a.875.875 0 01-.957.012l-6.318-3.975-6.116 3.862v2.424l5.64-3.565a.887.887 0 01.47-.135zm0 0"
            />
            <path className="prefix__c" d="M34.397.88h12.326v4.4H34.397zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M46.723 6.162H34.397a.88.88 0 01-.881-.88V.88a.88.88 0 01.881-.882h12.326a.88.88 0 01.88.881v4.4a.88.88 0 01-.88.883zM35.278 4.398h10.565V1.76H35.278zm0 0"
            />
            <path className="InsuraceMainRoofprefix__a" d="M34.397 5.283V21.13l6.127 2.165 6.2-2.165V5.283zm0 0" />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M40.525 24.174a.875.875 0 01-.293-.05l-6.127-2.165a.882.882 0 01-.587-.831V5.282a.88.88 0 01.881-.881h12.326a.88.88 0 01.88.881v15.847a.882.882 0 01-.59.832l-6.2 2.165a.887.887 0 01-.29.048zm-5.247-3.668l5.249 1.855 5.316-1.857V6.167H35.278zm0 0"
            />
            <path
                className="InsuraceMainRoofprefix__b"
                d="M46.723 10.564H41.44a.88.88 0 110-1.761h5.283a.88.88 0 010 1.761zm0 0M39.68 14.966h-5.283a.881.881 0 010-1.761h5.283a.881.881 0 010 1.761zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
