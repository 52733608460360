import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 29.727}
            height={props.height ? props.height : 29.254}
            viewBox="0 0 29.727 29.254"
        >
            <defs>
                <style>
                    {// ".prefix__a{fill:#fff}.prefix__b{fill:#bebebe}.prefix__c{fill:#6b6c6f}"
                    `.titleSvgprefix__a{fill:#fff}.titleSvgprefix__b{fill:${
                        window.location.pathname.includes('title') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.titleSvgprefix__c{fill:${
                        window.location.pathname.includes('title') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path
                className="titleSvgprefix__a"
                d="M23.822 10.768l3.717 2.706 1.728 1.258h-1.618v6.529h-7.813v-6.525h-1.617l5.524-4.022zm0 0"
            />
            <path
                className="titleSvgprefix__b"
                d="M29.182 3.139v4.978h-5.361V3.139a2.68 2.68 0 012.53-2.676h.153a2.68 2.68 0 012.678 2.676zm0 0"
            />
            <path
                className="titleSvgprefix__a"
                d="M26.349.459a2.68 2.68 0 00-2.527 2.68v7.63l-.079-.057-5.524 4.022h1.615v6.525h3.985v3.14a4.349 4.349 0 01-4.3 4.4c-.089 0-.176 0-.264-.008a4.356 4.356 0 01-4.037-4.388v-2.852H4.774l.16-18.059A3.063 3.063 0 017.996.459zm0 0"
            />
            <path
                className="titleSvgprefix__b"
                d="M19.259 28.785v.008h-14.5a4.349 4.349 0 01-4.3-4.4v-2.846h14.758v2.846a4.356 4.356 0 004.042 4.392zm0 0"
            />
            <path
                className="titleSvgprefix__c"
                d="M8.108 7.375h7.561a.46.46 0 100-.919H8.108a.46.46 0 100 .919zm0 0M16.738 9.97h-4.732a.46.46 0 000 .919h4.732a.46.46 0 000-.919zm0 0M10.165 10.888a.46.46 0 100-.919H8.108a.46.46 0 100 .919zm0 0M20.511 6.916a.46.46 0 00-.459-.459h-2.56a.46.46 0 000 .919h2.56a.459.459 0 00.459-.46zm0 0M9.256 15.141a.537.537 0 01.536.536.46.46 0 00.919 0 1.457 1.457 0 00-1-1.379v-.163a.46.46 0 00-.919 0v.163a1.454 1.454 0 00.459 2.834.536.536 0 110 1.072h-.007a.536.536 0 01-.532-.536.46.46 0 10-.919 0 1.457 1.457 0 001 1.38v.209a.46.46 0 00.919 0v-.209a1.454 1.454 0 00-.459-2.835.536.536 0 110-1.072zm0 0M14.617 15.141a.537.537 0 01.536.536.46.46 0 00.919 0 1.457 1.457 0 00-1-1.379v-.163a.46.46 0 00-.919 0v.163a1.454 1.454 0 00.459 2.834.536.536 0 110 1.072h-.007a.536.536 0 01-.532-.536.46.46 0 10-.919 0 1.457 1.457 0 001 1.38v.209a.46.46 0 00.919 0v-.209a1.454 1.454 0 00-.459-2.835.536.536 0 010-1.072zm0 0"
            />
            <path
                className="titleSvgprefix__c"
                d="M29.538 14.361l-1.35-.983v-1.535a.46.46 0 00-.919 0v.867l-2.987-2.175V8.577h4.9a.46.46 0 00.459-.459V3.14A3.14 3.14 0 0026.5 0H8a3.534 3.534 0 00-3.526 3.488l-.156 17.6H.459a.459.459 0 00-.459.463v2.846a4.819 4.819 0 004.559 4.851v.005h14.7v-.008c.091.005.179.008.267.008a4.814 4.814 0 004.76-4.856v-2.68h3.368a.46.46 0 00.459-.459v-6.066h1.159a.459.459 0 00.27-.831zM26.377.923h.125a2.221 2.221 0 012.221 2.217v4.518h-4.442V3.14A2.221 2.221 0 0126.377.923zM4.763 28.334a3.9 3.9 0 01-3.844-3.937V22.01h13.839v2.387a4.887 4.887 0 001.976 3.937zm18.6-3.937a3.9 3.9 0 01-3.844 3.937c-.076 0-.153 0-.235-.008a3.9 3.9 0 01-3.606-3.929v-2.846a.459.459 0 00-.459-.459H5.237l.155-17.6A2.612 2.612 0 018 .919h16.284a3.133 3.133 0 00-.921 2.221v7.28l-5.414 3.941a.459.459 0 00.27.831h1.158v6.065a.459.459 0 00.459.459h3.526zm4.287-10.125a.459.459 0 00-.459.459v6.065H20.3v-6.064a.46.46 0 00-.459-.459h-.206l4.112-2.994 4.112 2.994zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
