import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width={565}
      // height={164}
      viewBox="0 0 565 164"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <rect
            width={565}
            height={164}
            rx={4}
            transform="translate(756 247)"
            fill="#002e62"
            stroke="#002e62"
            strokeWidth={0.2}
          />
        </clipPath>
        <style>
          {
            ".prefix__c{fill:#f0bf97}.prefix__d{fill:#466eb6}.prefix__e{fill:#feb33c}.prefix__g{fill:#f47458}.prefix__i{fill:#f7a491}.prefix__j{fill:#a95c4b}.prefix__k{fill:#7eb3ff}.prefix__l{fill:#fff}.prefix__m{fill:#874a3c}.prefix__n{fill:#ffca17}.prefix__q{fill:#e68922}.prefix__r{fill:#3c8f7c}.prefix__s{fill:#355389}.prefix__t{fill:#6f3425}.prefix__u{fill:#0c7dfb}.prefix__v{fill:#004da0}.prefix__w{fill:#b75742}.prefix__x{fill:#13375b}"
          }
        </style>
      </defs>
      <g transform="translate(-756 -247)" clipPath="url(#prefix__a)">
        <path
          className="prefix__c"
          d="M1061.669 481.058a71.475 71.475 0 01-7.743 9.55c-.235.222-1.807.727-2.057.95-2.2 6.972.631 12.894 3.671 17.553.5-3.777.865-8.939 1.446-9.578a76.256 76.256 0 0110.462-10.746c-3.366-1.891-3.766-4.459-5.779-7.729z"
        />
        <path
          className="prefix__d"
          d="M1053.742 490.724c-2.492.15-7.209 1.708-7.182 2.2.032.592 2.7 21.145 5.95 22.954 1.953 1.088 2.313-2.11 3.028-6.771-3.039-4.656-3.184-17.194-1.796-18.383z"
        />
        <path
          className="prefix__c"
          d="M1010.025 471.451a94.112 94.112 0 01-11.017 5.684c-.307.1-1.945-.111-2.266-.015-4.961 5.369-4.918 11.934-4.153 17.445 2.064-3.2 4.589-7.72 5.386-8.05a100.893 100.893 0 0113.915-4.668c-2.239-3.146-1.437-6.581-1.865-10.396zM1067.044 333.118s7.878-14.824 9.044-20.133 5.2-32.045 4.864-33.767-6.726-5.865-5.938-7.328 6.87-1.466 8.263-1.341a12.6 12.6 0 015.389 1.925c1.2.988-1.347 33.029-2.108 39.926s-12.31 29.5-12.31 29.5zM1031.638 354.54s-16.058 25.711-18.86 30.763-13.254 17.923-13.781 18.544-11.579.06-11.262 1.34 5.392.016 5.487 1.518-8.736 11.058-7.851 11.443c1.656.721 5.627-5.641 6.337-5.159s-4.754 6.261-3.864 7.493 4.668-5.951 5.375-5.866-2.657 7.227-1.111 7.3c.839.038 2.839-6.423 3.638-5.852s.03 5.745 1 5.7.065-4.42 1.556-6.725 1.39-4.736 4.821-7.715 16.731-12.063 21.551-20.087 15.517-25.725 15.517-25.725z"
        />
        <path
          className="prefix__e"
          d="M1070.004 392.786l-27.884 8.983s15.337 26.084 18.369 31.283 16.092 18.036 16.109 21.395c.012 2.378-12.293 18.139-19.711 27.756 1.675 3.117 6.257 7.128 9.158 8.9 12.356-11.713 30.376-30.178 30.708-36.234.483-8.837-26.749-62.083-26.749-62.083z"
        />
        <path
          className="prefix__d"
          d="M998.777 477.162c-2.319-.924-7.23-1.523-7.416-1.063-.223.55-6.559 20.283-4.39 23.3 1.3 1.815 2.992-.925 5.623-4.838-.765-5.507 4.645-17.265 6.183-17.399z"
        />
        <path
          className="prefix__e"
          d="M1064.285 408.879c.355-1.718 2.654-6.762 3.839-10.78a22.032 22.032 0 00.529-10.256l-20.511-.955-.785 1.525a12.362 12.362 0 01-3.517 4.213c-3.072 2.321-8.461 7.418-8.3 14.508.228 9.9 7.27 46.174 4.928 47.53-4.226 2.449-21.859 11.028-32.385 16.305.145 3.837 1.219 9.3 3.219 12.6 8.335-3.191 37.672-16.177 43.127-19.359 2.465-1.436 9.252-52.412 9.856-55.331z"
        />
        <path d="M1014.728 293.454c1.627 4.3-.946 7.5-2.736 11.734-1.2 2.841 1.448 7.353 4.442 9.431 7.584 5.264 9.364 5.4 8.815 12.55-1.321 17.22 19.268 9.879 22.579 9.332s5.811-.4 7.394-1.494c4.769-3.3 9.244-13.211 8.405-21.56s-10.78-5.48-13.054-9.313 4.6-8.607-1.258-15.008c-16.816-18.384-34.37-6.053-35 .976a7.481 7.481 0 00.413 3.352z" />
        <path
          d="M1072.498 354.279s5.722-13.6 6.638-15.854c0 0-7.992-7.118-12.762-6.563 0 0-6.181 9.71-9.825 10.061s-16.462.9-18.709 2.233-11.782 15.7-11.782 15.7 8.568 8.583 12.015 8.565c0 0 2.342-5.8 3.143-4.74s3.967 23.579 5.567 25.294c1.48 1.588 19.431 7.645 24.614 6.531 0 0 2.791-21.683 2.641-35.027-.024-2.041-1.54-6.2-1.54-6.2z"
          fill="#5281ec"
        />
        <path
          className="prefix__c"
          d="M1047.501 335.538s2.023 6.685 2.037 9.4 2.179 11.258 7.878 10.7-.058-11.136-.866-13.718-1.779-10.73-1.779-10.73z"
        />
        <path
          className="prefix__g"
          d="M1055.311 335.275c-.32-2.223-.541-4.083-.541-4.083l-7.269 4.347s.414 1.367.868 3.116a6.7 6.7 0 006.942-3.38z"
        />
        <path
          className="prefix__c"
          d="M1042.764 317.002a9.512 9.512 0 00-3.484 5.587c-.71 3.65 1.457 12.654 9.287 14.072s10.376-6.152 8.226-11.643-6.295-12.298-14.029-8.016z"
        />
        <path
          className="prefix__c"
          d="M1057.518 322.728c.008 1.534-.71 2.781-1.6 2.785s-1.625-1.234-1.632-2.768.711-2.781 1.6-2.785 1.624 1.235 1.632 2.768zM1042.683 330.367c1.03 1.235 1.188 2.8.354 3.5s-2.346.26-3.376-.975-1.188-2.8-.353-3.5 2.345-.26 3.375.975z"
        />
        <path
          d="M1080.502 271.95a3.459 3.459 0 00-.475.354l-.873.789c.8.19 2.139.035 3.384.977 0 .001-.246-1.019-2.036-2.12z"
          fill="#f47257"
        />
        <path
          className="prefix__i"
          d="M1079.663 275.069l-.493-1.953a11.332 11.332 0 01-3.533-.561l-1.362-.593a.841.841 0 00-1.114.453.842.842 0 00.256.975s3.681 3.031 4.974 3.952c.641.456 2.657 1.687 2.657 1.687l1.175-2.2z"
        />
        <g>
          <path d="M1179.219 310.912a13.81 13.81 0 00-18.04 1.147 14.338 14.338 0 00-11.253-.229c-6.96 2.9-12.295 12.85-7.783 19.218.518 6.017 2.259 10.942 8.067 11.772 2.616 6.762 13.077 10 20.035 4.878 5.179 2.409 11.356.069 15.665-3.735 1.016-.9 1.972-2.395 2.337-2.563 0 0 14.263-.753 14.751-14.409.509-14.292-12.898-25.938-23.779-16.079z" />
          <path
            className="prefix__j"
            d="M1104.086 472.855a79.544 79.544 0 00.05 12.465c.041.323.879 1.691.929 2.023-2.442 7-7.9 10.082-12.771 12.014 1.818-3.372 4.862-6.984 4.813-7.855a109.908 109.908 0 00-1.636-15.89c3.51.513 5.281-1.328 8.615-2.757z"
          />
          <path
            className="prefix__k"
            d="M1132.476 387.315l19.374 20.342s-12.305 9.339-16.916 12.8-27.07 15.2-27.552 16.071a31.963 31.963 0 01.681 5.294c-.057 7.514-.906 22.033-2.2 34.41-2.647 2.14-7.768 2.064-10.883 2.44-5.448-16.123-4.35-45.568-3.862-49.059.791-5.68 41.358-42.298 41.358-42.298z"
          />
          <path
            className="prefix__j"
            d="M1190.676 433.654c-.92.434-8.666 7.876-12.119 12.123-1.9-2.589-2.711-5.12-5.1-7.281 3.389-2.5 10.545-8.9 11.58-10.674 2.456-4.212 3.675-4.508 3.675-4.508s12.65 10.619 13.1 12.424-7.442-3.826-11.136-2.084z"
          />
          <path d="M1184.504 428.764c-1.125-.422 2-5.283 2.7-6.412.783-1.27 1.694-1.395 1.9-1.372.562.062 14.619 11.863 16.607 14.942a3.661 3.661 0 01.323.891c.448 3.477-13.023-.993-14.478-2.989-1.296-1.775-1.991-3.161-7.052-5.06z" />
          <path
            className="prefix__k"
            d="M1127.108 391.772l20.145-5.373c-.081.025 0 .825.009.887.77 7.508 10.68 6.039 12.845 19.194 1.531 9.294-3.888 39.109-3.888 39.109s9.763-5.344 20.024-11.064c.935-.522 6.667 7.8 6.667 7.8-15.515 19.7-36.291 25.365-38.658 24.055-8.081-4.472-13.3-63.394-13.279-63.386-1.198-3.643-3.865-11.222-3.865-11.222z"
          />
          <path
            className="prefix__j"
            d="M1164.348 347.61s24.048-1.645 29.358-1.692 24.264-3.516 25-3.676 4.808-9.287 5.71-8.434-2.318 4.554-1.141 5.324 12.747-2.3 12.676-1.375c-.131 1.727-7.01 2.16-6.924 2.978s7.134-1.139 7.749.177-6.845 1.208-7.082 1.843 7.011 1.072 6.4 2.408c-.331.726-6.437-.55-6.32.385s4.647 2.659 4.187 3.456-3.614-1.971-6.128-1.771-4.442-1-8.342.529-17.019 8.578-25.611 8.964-27.576 1.292-27.576 1.292zM1137.662 341.579s-5.991-23.349-7.614-28.4-4.282-24.141-4.36-24.886 7.307-7.481 6.213-8.07-3.595 3.631-4.7 2.756-1.821-12.823-2.676-12.467c-1.6.667.152 7.333-.653 7.51s-1.159-7.131-2.6-7.3 1 6.878.474 7.3-3.221-6.319-4.3-5.319c-.584.543 2.544 5.939 1.62 6.121s-3.984-3.577-4.6-2.89 3.006 2.811 3.605 5.262 2.344 3.9 2.118 8.086-2.8 18.851-.467 27.131 7.435 26.586 7.435 26.586z"
          />
          <path
            className="prefix__l"
            d="M1127.128 367.31c.25-4.159 1.251-15.335 1.619-16.674-.594-4.439-4.052-11.38-4.713-13.3 0 0 6.868-6.153 11.229-5.483 0 0 3.792 6.784 6.979 9.147a8.375 8.375 0 001.659.783c3.335.441 15.027 1.7 17.1 2.68l-3.857 18.625c-.777.99-4.557 22.5-6.093 24.1s-21.405 7.077-24.219 4.555c-.108-.104-.209-16.023.296-24.433z"
          />
          <path
            className="prefix__l"
            d="M1155.029 343.927a9.46 9.46 0 013.617-.087c5.293.646 14.214 1.669 14.214 1.669s.591 11.61-1.543 14.082c0 0-14.194.219-13.966 1.465z"
          />
          <path
            className="prefix__j"
            d="M1152.463 335.924s-2.117 6.352-2.235 8.958-2.438 10.733-7.652 10.033.483-10.678 1.326-13.13a101.719 101.719 0 002.05-10.238z"
          />
          <path
            className="prefix__m"
            d="M1145.297 335.445c.38-2.123.655-3.9.655-3.9l6.51 4.377s-.433 1.3-.918 2.963a6.036 6.036 0 01-6.247-3.44z"
          />
          <path
            className="prefix__j"
            d="M1157.532 318.284a9.037 9.037 0 012.985 5.458c.511 3.52-1.829 12.092-9.078 13.227s-9.3-6.2-7.107-11.4 6.259-11.614 13.2-7.285z"
          />
          <path
            className="prefix__j"
            d="M1143.755 323.351c-.067 1.471.545 2.687 1.366 2.717s1.539-1.137 1.605-2.608-.544-2.687-1.365-2.717-1.539 1.138-1.606 2.608z"
          />
          <path d="M1104.142 485.508c.719-1.011 4.121 3.742 4.954 4.745a2.492 2.492 0 01.7 2.274c-.216.532-14.884 11.767-18.609 12.864a1.835 1.835 0 01-1.162-.189c-1.6-1.2 4.3-12.034 7.239-13.7 1.903-1.072 3.645-1.441 6.878-5.994zM1156.778 317.73s-.1 9.914 2.667 12.092.9 6.321.9 6.321 4.134-9.648 2.2-13.2a13.3 13.3 0 00-5.767-5.213z" />
          <path
            className="prefix__j"
            d="M1157.466 330.967c-1 1.154-1.2 2.651-.461 3.343s2.146.317 3.14-.838 1.2-2.651.46-3.343-2.145-.315-3.139.838z"
          />
        </g>
        <g transform="translate(1064.77 251)">
          <circle
            className="prefix__n"
            cx={37.109}
            cy={37.109}
            transform="translate(.011)"
            r={37.109}
          />
          <ellipse
            className="prefix__n"
            cx={37.109}
            cy={35.372}
            rx={37.109}
            ry={35.372}
            transform="translate(0 3.493)"
          />
          <path
            d="M37.124 3.475c20.2 0 36.625 15.381 37.1 34.524v-.88a37.11 37.11 0 10-74.219 0v.88C.493 18.856 16.925 3.475 37.124 3.475z"
            fill="#ffe58f"
          />
          <circle
            cx={28.91}
            cy={28.91}
            transform="translate(8.23 9.952)"
            fill="none"
            stroke="#e68922"
            strokeMiterlimit={10}
            strokeWidth={3}
            r={28.91}
          />
          <path
            className="prefix__q"
            d="M35.522 54.923v-1.911a19.817 19.817 0 01-9.139-3.287 2.641 2.641 0 01-.549-3.888 2.641 2.641 0 013.522-.478 14.932 14.932 0 006.18 2.588v-9.045q-5.265-1.259-7.707-3.307a7.491 7.491 0 01-2.444-6.078 8.5 8.5 0 012.817-6.589 11.4 11.4 0 017.337-2.794v-.839a1.491 1.491 0 011.491-1.491 1.493 1.493 0 011.491 1.491v.88a18.829 18.829 0 017.243 2.04 2.489 2.489 0 01.88 3.651l-.129.182a2.512 2.512 0 01-3.228.743 14.8 14.8 0 00-4.769-1.541v8.757h.138q5.344 1.262 7.859 3.392a7.694 7.694 0 012.515 6.219 8.458 8.458 0 01-2.888 6.635 12 12 0 01-7.63 2.794v1.864a1.494 1.494 0 01-1.491 1.491 1.491 1.491 0 01-1.499-1.479zm-3.284-28.572a3.337 3.337 0 00-1.174 2.609 3.278 3.278 0 00.93 2.468 9.558 9.558 0 003.539 1.761v-8.07a5.587 5.587 0 00-3.295 1.232zm9.8 20.423a3.6 3.6 0 00.293-5.282 10.125 10.125 0 00-3.815-1.793v8.376a6.5 6.5 0 003.524-1.3z"
          />
        </g>
        <g>
          <path
            className="prefix__i"
            d="M938.789 539.288c.269-1.929-.335-5.25-1.846-10.444 2.992.007 5.508-1.318 8.566-1.271.836 4.885 2.129 12.906 2.129 12.906l.139 6.34-21.3.16s.177.092 6.456-3.875c.469-.295 5.596-1.951 5.856-3.816z"
          />
          <path
            className="prefix__r"
            d="M947.879 548.447h-21.983c-.813-.073-1.011-.737-.729-1.145.581-.843 4.868-2.864 7.751-4.2 5.056.532 13.943-1.549 14.742-2.72 1.385 3.874.673 7.965.219 8.065z"
          />
          <path
            className="prefix__i"
            d="M965.313 539.288c.269-1.93.728-4.347.056-10.784 2.992.007 5.84-.554 8.9-.508 0 5.025-.106 12.481-.106 12.481l.139 6.341-21.3.16s.177.091 6.455-3.875c.473-.293 5.6-1.95 5.856-3.815z"
          />
          <path
            className="prefix__r"
            d="M974.408 548.447h-21.983c-.813-.073-1.011-.737-.729-1.145.58-.843 4.868-2.864 7.751-4.2 5.056.532 13.943-1.549 14.742-2.72 1.385 3.874.671 7.965.219 8.065z"
          />
          <path
            className="prefix__j"
            d="M949.842 355.398l-8.01-8.451s-8.574 4.715-18.569 9.22c-2.748-4.782-7.233-20.514-7.473-21.264s5.252-10.2 4.023-10.44-2.825 5.047-4.123 4.511-4.64-12.793-5.422-12.124c-1.463 1.251 1.757 7.624.986 8.088s-2.727-7.064-4.217-6.742 2.515 6.853 2.075 7.481-4.624-5.5-5.489-4.081c-.47.77 3.86 5.336 2.97 5.847s-4.792-2.365-5.259-1.433 3.641 1.9 4.781 4.26 3.214 3.274 3.9 7.732 2.569 28.277 7.425 30.19c4.243 1.669 32.402-12.794 32.402-12.794z"
          />
          <path
            className="prefix__s"
            d="M944.667 400.979l6.5 3.623 13.2 20.189-23.613 55.579s2.659 8.358 3.526 12.534c.907 4.368 3.13 41.868 3.13 41.868l-10.074.169s-9.569-39.921-11.189-52.878c-1.968-15.735 10.013-53.207 10.013-53.207 2.234-7.645 7.052-23.374 7.052-23.374s1.178-3.832 1.455-4.503z"
          />
          <path
            className="prefix__d"
            d="M967.276 393.396a19.018 19.018 0 002.015 10.166c1.637 3.3 4.466 5.891 6.492 9.937 3.834 7.656 5.915 18.53 4.477 25.519 0 0-7.926 30.266-7.611 41.328.116 4.079 1.515 11.428 2.411 16.4.791 4.392.514 36.976.514 36.976l-10.7.207-8.615-53.443-3.642-47.505-7.945-32z"
          />
          <path
            className="prefix__t"
            d="M942.27 322.795c1.039-4.906 2.111-9.83 6.986-13.363 3.945-2.86 8.184-3.677 12.679-2.047 12.734 4.618 5.885 14.724 21.687 25.495 4.91 3.347 6.774 4.514 10.3 8.973 4.932 6.243 7.628 14.619 5.149 21.514-5.127 14.26-21.015 18.38-29.326 15.357-15.469-5.628-28.357-25.247-29.214-30.6-.923-5.735 1.068-22.175 1.739-25.329z"
          />
          <path
            className="prefix__l"
            d="M941.092 360.706c-3.585 1.657-10.077 4.145-10.077 4.145l-3.694-11.682c20.876-11.875 19.239-9.959 34.908-9.726 12.517.179 17.763-2.212 28.543 26.887 0 0-8.206 3.687-11.144 4.87-1.263-3.6-3.547-10.771-4.878-6.681a118.911 118.911 0 00-4.473 18.838c-.628 3.807-1.994 8.68-1.275 12.558a116.306 116.306 0 01-15.037 2.041.273.273 0 01-.056 0 51.02 51.02 0 01-9.238-.976s-1.277-26.993-1.959-28.12c-.756-1.247-3.946-5.276-1.232-11.3.476-1.071.347-1.197-.388-.854z"
          />
          <path
            className="prefix__j"
            d="M960.263 331.428s1 8.05 1.585 10.845c.615 2.925 1.54 12.134-4.524 13.63s-4.19-10.021-4.29-12.995-1.819-13.342-1.819-13.342z"
          />
          <path
            className="prefix__m"
            d="M960.59 333.997c-.062.037-.12.077-.183.113-3.351 1.921-6.269 1.618-8.458.123-.391-2.574-.738-4.667-.738-4.667l9.048 1.862.331 2.569z"
          />
          <path
            className="prefix__j"
            d="M958.44 310.464a10.377 10.377 0 015.6 4.528c2.048 3.529 3.071 13.664-4.472 17.988s-12.9-2.6-12.668-9.086 2.036-15.053 11.54-13.43z"
          />
          <path
            className="prefix__j"
            d="M945.235 321.112c-.083 1.685.637 3.09 1.61 3.14s1.829-1.277 1.913-2.962-.637-3.09-1.61-3.14-1.829 1.277-1.913 2.962z"
          />
          <path
            className="prefix__t"
            d="M957.372 310.197s4.066 10.6 8.046 11.729 3.661 6.345 3.661 6.345.509-12.053-3.126-15.007a15.793 15.793 0 00-8.581-3.067z"
          />
          <path
            className="prefix__j"
            d="M963.711 323.997c-.613 1.657-.211 3.339.9 3.758s2.507-.585 3.121-2.242.212-3.34-.9-3.758-2.507.589-3.121 2.242zM956.759 407.559c-.148.02-.813-.067-1.357-.054a10.417 10.417 0 00-1.41.127 3.983 3.983 0 00-.86-.517c-.235-.107-.617-.148-.88-.269s-.5-.356-.753-.47c-.664-.289-1.276-.356-1.477-.007-.168.289 3.533 2.552 3.533 2.552 3.425.02 7.011 1.766 14.949-3.412 4.533-1.981 26.548-12.854 26.991-15.224.249-1.33-3.129-11.625-5.89-19.443-2.128.954-6.655 2.969-9.187 4.029 2.323 4.923 5.064 11.451 4.775 12.25-.074.208-.349.584-15.232 10.329-.376.242-.759.5-1.148.752q-1.572 1.028-3.358 2.19l-4.245-.444s-6.722.464-6.924 1.229c-.087.349.47.524 1.155.625h.02l.3.04h.013c.739.088 1.518.1 1.706.168a4.4 4.4 0 011.014.5c-4.19 1.713-3.868 1.1-5.258 1.015-.349-.027-.719.074-1.048.047-.564-.047-1.014-.383-1.451-.41a1.89 1.89 0 00-1.269.383c-.148.148.128.363.665.6 3.237 1.4 5.917.866 6.776 1.074.578.141-.477.356-2.122.181a8.643 8.643 0 01-1.491-.02c-.363-.081-.692-.316-1.048-.4-.329-.081-.665-.074-.94-.127-.362-.067-.866-.342-1.155-.376a2.743 2.743 0 00-1.719.168c-.249.222 4.634 2.1 4.634 2.1s3.365.484 4.117.484c.003-.005.453.197-.426.33z"
          />
        </g>
        <g>
          <path
            className="prefix__c"
            d="M1268.352 346.499s-2.709-30.17-3.348-36.27 2.338-25.944 2.425-26.806 10.031-6.728 8.934-7.651-4.924 3.253-5.96 2 .976-14.927-.074-14.726c-1.965.375-1.565 8.325-2.517 8.335s.377-8.335-1.213-8.87-.494 8.013-1.192 8.368-2.144-7.906-3.6-7.031c-.789.475 1.47 7.316.382 7.3s-3.657-4.987-4.511-4.355 2.732 3.89 2.831 6.8 1.725 4.968.48 9.642-8.472 21.464-7.795 31.375 2.957 31 2.957 31z"
          />
          <path
            className="prefix__u"
            d="M1268.335 319.463l-16.2 3.481.605 35.117 14.228-5z"
          />
          <path
            className="prefix__t"
            d="M1304.761 316.835a15.259 15.259 0 00-19.425-4.647 15.846 15.846 0 00-11.81-3.9c-8.291.8-17.158 9.58-14.461 17.77-1.406 6.522-1.168 12.29 4.7 15.052.567 7.99 10.566 14.8 19.577 11.657 4.687 4.226 11.97 3.76 17.756 1.141 1.365-.618 2.86-1.888 3.3-1.947.005 0 15.308 3.836 20.257-10.428 5.173-14.924-5.205-31.578-19.894-24.698z"
          />
          <path
            className="prefix__v"
            d="M1206.433 444.787c.878-6.282 42.974-47.329 42.974-47.329l21.406 22.476s-13.6 10.319-18.692 14.149c-3.144 2.362-13.79 8.2-21.611 12.552h-24.314c.103-.85.186-1.479.237-1.848z"
          />
          <path
            className="prefix__v"
            d="M1275.965 430.456c-2.393-14.536-9.372-24.736-10.224-33.031-.006-.069-.1-.953-.008-.98l-22.259 5.936s-1.935 8.59-.611 12.615c-.013 0 2.948 18.073 7.06 35.871 8.866-.448 17.662-1.593 26.584-2.62.229-6.165.229-13.107-.542-17.791z"
          />
          <path
            className="prefix__u"
            d="M1245.172 372.708c.276-4.595-.292-14.3.115-15.778a13.093 13.093 0 00-.054-.368l16.9-11.822c.057-.007-.166-.038.4.037 3.684.487 16.1 4.249 18.4 5.334l-.424 15.757c-1.365.685-11.338 42.377-8.679 46.624 1.3 2.076-32.756.836-33.706-3.23-.415-1.76 6.489-27.265 7.048-36.554z"
          />
          <path
            className="prefix__c"
            d="M1271.491 340.671s-1.416 7.178-1.547 10.057-3.617 11.7-9.378 10.926.088-11.9 1.019-14.609 2.712-11.21 2.712-11.21z"
          />
          <path
            className="prefix__g"
            d="M1263.384 340.011c1.393 2.316 3.894 3.8 7.606 3.339.277-1.545.5-2.677.5-2.677l-7.194-4.836s-.396 1.892-.912 4.174z"
          />
          <path
            className="prefix__c"
            d="M1277.515 319.829a10.633 10.633 0 013.513 6.422c.6 4.142-2.152 14.23-10.682 15.564s-10.938-7.294-8.364-13.417 7.366-13.664 15.533-8.569z"
          />
          <path
            className="prefix__c"
            d="M1261.398 326.479c-.073 1.625.6 2.969 1.509 3s1.7-1.257 1.776-2.881-.6-2.969-1.509-3-1.702 1.256-1.776 2.881z"
          />
          <path
            className="prefix__t"
            d="M1276.043 317.354s-.128 12.717 3.421 15.511 1.158 8.109 1.158 8.109 5.3-12.377 2.825-16.936a17.054 17.054 0 00-7.404-6.684z"
          />
          <path
            className="prefix__c"
            d="M1277.019 335.201c-1.1 1.276-1.326 2.929-.509 3.693s2.371.35 3.47-.926 1.326-2.929.509-3.693-2.371-.35-3.47.926z"
          />
          <path
            className="prefix__c"
            d="M1274.904 348.46s14.15-25.548 17.6-30.98 12.388-27 12.707-27.848-6.285-11-4.823-11.358 3.108 5.354 4.665 4.664 6.049-14.479 6.942-13.8c1.671 1.27-2.416 8.552-1.527 9.005s3.536-8.007 5.279-7.767-3.276 7.758-2.785 8.418 5.7-6.427 6.661-4.926c.52.815-4.8 6.187-3.767 6.682s5.762-2.978 6.271-1.986-4.383 2.379-5.834 5.069-3.94 3.862-4.951 8.835-2.471 22.958-7.733 31.953-16.831 28.9-16.831 28.9z"
          />
          <path
            className="prefix__u"
            d="M1270.202 346.796c4.091-5 14.51-18.483 14.51-18.483s10.629 7.192 11.777 10.979c0 0-19.286 29.094-17.862 29.668.001-.001-9.809-20.472-8.425-22.164z"
          />
        </g>
        <g>
          <path
            className="prefix__c"
            d="M888.016 331.798c-.74 3.041-2.075 7.846-2.075 7.846.106 1.321.5 6.819.961 13.558l-9.9-3.964s.118-10.98-.323-11.652c-.374-.568-1 .141-4.038-4.2a8.477 8.477 0 01-1.04-3.494s-.18-2.758.439-3.015a1.287 1.287 0 011.554.631c.224.477.166 1.587.391 2.063a3.431 3.431 0 002.26 1.021c.6-.03-.076-8.157-.076-8.157s-.336-2.305-.507-3.059c-.146-.643-.731-2.528-.731-2.528s-.687-2.735.162-2.944c.5-.124 1.777 2.1 2.651 4.458.942 2.54 1.476 5.234 1.745 5.2.633-.085.3-4.541.3-4.541a14.293 14.293 0 01-.614-3c-.6-1.18-.99-3.37.058-3.551a18.179 18.179 0 011.574 2.748s.543 1.959.86 2.874c.558 1.614.642 4.164.933 5.288.242.935.461.551.561.59a23.538 23.538 0 00-.212-3.033 18 18 0 00-.225-2.94 21.444 21.444 0 01-.424-3.1c-.17-2.305 1.282-2.069 1.784.039.19.8.392 1.679.592 2.567.78 3.455.758 6.784 1.13 6.812.484.036.165-6.025.165-6.025s.742-3.355 1.479-.242a27.158 27.158 0 01.755 6.689c-.087.866.551 4.02-.189 7.061z"
          />
          <path
            className="prefix__e"
            d="M887.371 345.077s-11.16-2.671-11.267-1.775c-2.344 19.632-3.4 26.733-3.794 26.937-1.353.7-19.283-15.7-22.479-16.81a13.325 13.325 0 00-4.788-.4c-3.8.112-4.553.126-4.553.126l-.139.01.021 3.576c4.284 3.855 14.092 62.243 14.919 62.2.923-.043 20.31 4.836 19.968 3.2-.055-.265-13.143-10.975-16.854-26.432-1.247-5.189-1.862-10.776-2.537-15.666 4.307 2.16 21.131 10.439 23.8 9.345 8.288-3.39 7.703-44.311 7.703-44.311z"
          />
          <path
            className="prefix__i"
            d="M920.757 448.749s18.037 14.129 18.639 16.633c.867 3.608 17.383-1.135 13.4-1.87-.916-.169-3.849-.913-4.09-1.042-1.29-.689-17.339-20.029-17.339-20.029z"
          />
          <path
            className="prefix__w"
            d="M960.541 463.513l-11.516-.938s-3.292 2.724-9.618 2.852a14.746 14.746 0 00-.53 6.267c.083.583.18 2.344.768 2.3 2.128-.145 7.8-1.555 14.255-2.847 0 0 8.68-1.839 13.694-4.838s-7.053-2.796-7.053-2.796z"
          />
          <path
            className="prefix__x"
            d="M839.939 413.309a78.844 78.844 0 0027.816-1.2c16.9-3.954 35.594-7.61 41.071-4.426 4.408 2.562 31.935 44.157 31.935 44.157l-10.63 7.757-30.309-33.939s-38.669 14.268-47.354 12.687c-19.78-3.601-20.061-19.397-12.529-25.036z"
          />
          <path
            className="prefix__x"
            d="M854.38 404.915l13.216 15.706-24.516 7.47-3.14-14.782z"
          />
          <path
            className="prefix__i"
            d="M881.239 476.655s-1.342 21.34-1.84 25.034c-.5 3.678 9.8 15.231 8.549 11.377-.287-.886-1.025-3.82-1.026-4.094-.006-1.462 3.617-26.348 3.617-26.348z"
          />
          <path
            className="prefix__w"
            d="M894.184 521.014l-7.2-11.711a19.247 19.247 0 01-7.583-7.614 25.786 25.786 0 00-5.263 3.017c-.473.351-3.7 2.709-3.475 3.254.828 2 5.22 6.295 10.024 10.98a129.39 129.39 0 0011.83 8.479c5.018 2.986 1.667-6.405 1.667-6.405z"
          />
          <path
            className="prefix__s"
            d="M902.646 433.645c-4.619-6.159-41.895-12.009-41.895-12.009l-10.394-19.977-22.984 14.173s2.3 18.289 4.022 23.175c2.3 6.512 17.744 6.844 27.178 6.747 8.68-.089 24.536 1.68 24.536 1.68l-5.363 47.308s10.541 2.4 13.5 2.011c.34-.042 17.146-55.447 11.4-63.108z"
          />
          <path
            className="prefix__q"
            d="M840.478 353.201l-12.966.724s-.3.091-.388.107c-1.056.2-.1 37.984.846 52.1s2.853 20.043 2.853 20.043 17.587-3.123 22.984-5.217c2.24-.87 7.662-7.647 7.662-7.647a56.851 56.851 0 01-8.329-19.541c-2.151-9.328-4.805-25.864-6.324-29.316-3.489-7.928-6.338-11.253-6.338-11.253z"
          />
          <path
            className="prefix__c"
            d="M827.879 342.476l-.368 11.449s8.394 14.464 12.7 12.448c3.616-1.691.406-12.694.406-12.694s-.625-2.522-1.494-5.42a20.985 20.985 0 01-1.074-5.829c.094-3.912-10.17.046-10.17.046z"
          />
          <path
            className="prefix__g"
            d="M838.052 342.43c.091-3.912-10.173.046-10.173.046l-.036 1.119a5.785 5.785 0 001.146 1.3 14.127 14.127 0 0010 2.877 19.708 19.708 0 01-.937-5.342z"
          />
          <g>
            <path d="M837.909 316.363s4.278 6.448 4.6 11.875a28.146 28.146 0 01-.83 9.129l-13.73-16.404z" />
            <path
              className="prefix__c"
              d="M843.927 327.128c.193 1.9-.545 3.532-1.65 3.645s-2.156-1.336-2.35-3.237.545-3.532 1.65-3.645 2.156 1.337 2.35 3.237z"
            />
            <path
              className="prefix__c"
              d="M823.508 323.588c-.314-.067-.284 15.729 4.295 19.446s12.547 4.044 14.875.3c2.574-4.141.125-20.786-6.685-24.321s-12.485 4.575-12.485 4.575z"
            />
            <path d="M822.347 313.794c-3.5 3.065-5.188 5.3-7.553 10.752-2.908 6.7 2.861 13.641 7.7 16.4s5.24 6.094 5.24 6.094l.2-2.056a5.132 5.132 0 00-.062-1.891 8.832 8.832 0 00-.688-1.668c-.412-.881-1.735-3.9-2.25-4.693-1.362-2.1-1.63-2.554-.958-4.548.517-1.537-.082-4.923.718-7.454l6.512-1.4s4.076-.979 6.133-3.353c5.908-6.825-5.775-14.426-14.992-6.183z" />
            <path
              className="prefix__c"
              d="M825.523 333.909c.8 1.733.636 3.517-.372 3.983s-2.475-.561-3.276-2.294-.636-3.517.372-3.983 2.474.56 3.276 2.294z"
            />
          </g>
          <path
            className="prefix__c"
            d="M781.15 290.339c.74 3.041 2.075 7.846 2.075 7.846-.105 1.321-.5 6.819-.959 13.558l9.9-3.964s-.118-10.98.323-11.652c.374-.568 1 .141 4.038-4.2a8.484 8.484 0 001.04-3.494s.181-2.758-.438-3.015a1.286 1.286 0 00-1.554.632c-.225.476-.167 1.586-.391 2.063a3.428 3.428 0 01-2.261 1.021c-.6-.029.075-8.157.075-8.157s.337-2.3.508-3.06c.146-.643.731-2.529.731-2.529s.687-2.735-.163-2.944c-.5-.123-1.777 2.1-2.651 4.458-.942 2.54-1.475 5.234-1.745 5.2-.632-.085-.3-4.541-.3-4.541a14.309 14.309 0 00.615-3c.595-1.181.99-3.37-.057-3.552a18.141 18.141 0 00-1.575 2.748s-.543 1.959-.859 2.874c-.558 1.615-.642 4.164-.933 5.288-.242.935-.461.551-.561.59a23.526 23.526 0 01.213-3.033 17.824 17.824 0 01.224-2.94 21.453 21.453 0 00.424-3.1c.169-2.305-1.282-2.069-1.783.038-.191.8-.392 1.679-.593 2.568-.779 3.455-.757 6.784-1.13 6.812-.484.036-.165-6.025-.165-6.025s-.743-3.355-1.481-.241a27.184 27.184 0 00-.754 6.689c.087.862-.554 4.016.187 7.062z"
          />
          <path
            className="prefix__e"
            d="M827.511 353.928s-12.856-.6-16.173-2.1c-2.011-.91-18.53-14.221-18.53-14.221l.185-35.639-11.617-.884s-6.025 39.393-3.938 43.7c2.154 4.453 26.335 22.785 31.17 30.74 2.786 4.583 8.6 66.91 9.476 67.471 6.256 3.256 25.577 6.508 25.122 4.533-12.576-54.583-15.695-93.6-15.695-93.6z"
          />
        </g>
        <g>
          <path
            className="prefix__k"
            d="M914.747 257.905c-20.132-9.26-49.579 2.43-54.994 14.2-2.708 5.887-1.079 13.094 3.743 19.986l-21.965 22.062 29.211-14.039a63.941 63.941 0 0015.657 10.085c20.132 9.26 40.843 7.221 46.259-4.553s2.221-38.481-17.911-47.741z"
          />
          <path
            className="prefix__v"
            d="M895.155 277.076c-1.431 5.97-5.953 8.182-10.079 7.193-4.345-1.041-6.991-5.11-5.708-10.465 1.22-5.092 5.263-8.208 10.135-7.04 4.917 1.177 6.82 5.44 5.652 10.312zm-10.785-2.259c-.715 2.985-.221 5.378 1.71 5.841 1.887.452 3.272-1.258 4.04-4.463.694-2.9.44-5.325-1.622-5.819-1.974-.474-3.443 1.587-4.127 4.441zm.031 19.474l20.964-22.87a1.617 1.617 0 011.568-.48 1.617 1.617 0 01.814 2.666l-20.99 22.846a1.6 1.6 0 01-1.55.473 1.6 1.6 0 01-.806-2.635zm28.457-.439c-1.431 5.97-5.953 8.182-10.079 7.193-4.3-1.031-6.947-5.1-5.707-10.466 1.22-5.092 5.263-8.208 10.135-7.04 4.916 1.178 6.818 5.44 5.651 10.312zm-10.74-2.249c-.76 2.974-.266 5.367 1.666 5.83 1.888.452 3.272-1.258 4.041-4.463.694-2.9.484-5.315-1.579-5.809-2.02-.484-3.445 1.588-4.128 4.441z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
