import {
    GET_CHECKLIST_DATA,
    GET_CHECKLIST_DATA_SUCCESS,
    GET_CHECKLIST_DATA_ERROR,
    ADD_TASK,
    ADD_TASK_SUCCESS,
    ADD_TASK_ERROR,
    UPDATE_TASK,
    DELETE_TASK,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_ERROR,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_ERROR,
    SET_REMINDER,
    SET_REMINDER_SUCCESS,
    SET_REMINDER_ERROR,
    UPDATE_REMINDER,
    UPDATE_REMINDER_SUCCESS,
    UPDATE_REMINDER_ERROR,
    DELETE_REMINDER,
    DELETE_REMINDER_SUCCESS,
    DELETE_REMINDER_ERROR,
} from './actionTypes';

import { ChecklistGetActions, ChecklistGetState } from './types';

const initialState: ChecklistGetState = {
    checklist: [],
    error: null,
    checklistTask: [],
    errorTask: null,
    isLoading: false,
    isUpdated: false,
    isDeleted: false,
    isAdded: false,
    checklistDeleteTask: [],
    errorDeleteTask: null,
    checklistUpdateTask: [],
    errorUpdateTask: null,
    setReminder: [],
    errorSetReminder: null,
    isSetReminder: false,
    updateReminder: [],
    errorUpdateReminder: null,
    isUpdateReminder: false,
    deleteReminder: [],
    errorDeleteReminder: null,
    isDeleteReminder: false,
};

export default (state = initialState, action: ChecklistGetActions) => {
    switch (action.type) {
        case GET_CHECKLIST_DATA:
            return {
                ...state,
                isLoading: true,
                checklist: [],
                error: null,
            };
        case GET_CHECKLIST_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                checklist: action.payload.checklist,
                error: null,
            };
        case GET_CHECKLIST_DATA_ERROR:
            return {
                ...state,
                isLoading: false,
                checklist: [],
                error: action.payload.error,
            };
        case ADD_TASK:
            return {
                ...state,
                checklistTask: [],
                isAdded: true,
                error: null,
            };
        case ADD_TASK_SUCCESS:
            return {
                ...state,
                checklistTask: action.payload.checklistTask,
                isAdded: true,
                error: null,
            };
        case ADD_TASK_ERROR:
            return {
                ...state,
                checklist: [],
                isAdded: false,
                error: action.payload.errorTask,
            };
        case UPDATE_TASK:
            return {
                ...state,
                isUpdated: true,
                checklistUpdateTask: [],
                error: null,
            };
        case UPDATE_TASK_SUCCESS:
            return {
                ...state,
                isUpdated: true,
                checklistUpdateTask: action.payload.checklistUpdateTask,
                error: null,
            };
        case UPDATE_TASK_ERROR:
            return {
                ...state,
                isUpdated: false,
                checklistUpdateTask: [],
                error: action.payload.errorUpdateTask,
            };
        case DELETE_TASK:
            return {
                ...state,
                isDeleted: true,
                checklistDeleteTask: [],
                error: null,
            };
        case DELETE_TASK_SUCCESS:
            return {
                ...state,
                isDeleted: true,
                checklistDeleteTask: action.payload.checklistDeleteTask,
                error: null,
            };
        case DELETE_TASK_ERROR:
            return {
                ...state,
                isDeleted: false,
                checklistDeleteTask: [],
                error: action.payload.errorDeleteTask,
            };
        case SET_REMINDER:
            return {
                ...state,
                isSetReminder: true,
                setReminder: [],
                error: null,
            };
        case SET_REMINDER_SUCCESS:
            return {
                ...state,
                isSetReminder: true,
                setReminder: action.payload.setReminder,
                error: null,
            };
        case SET_REMINDER_ERROR:
            return {
                ...state,
                isSetReminder: false,
                setReminder: [],
                error: action.payload.errorSetReminder,
            };
        case UPDATE_REMINDER:
            return {
                ...state,
                isUpdateReminder: true,
                UpdateReminder: [],
                error: null,
            };
        case UPDATE_REMINDER_SUCCESS:
            return {
                ...state,
                isUpdateReminder: true,
                updateReminder: action.payload.updateReminder,
                error: null,
            };
        case UPDATE_REMINDER_ERROR:
            return {
                ...state,
                isupdateReminder: false,
                updateReminder: [],
                error: action.payload.errorUpdateReminder,
            };
        case DELETE_REMINDER:
            return {
                ...state,
                isDeleteReminder: true,
                deleteReminder: [],
                error: null,
            };
        case DELETE_REMINDER_SUCCESS:
            return {
                ...state,
                isDeleteReminder: true,
                deleteReminder: action.payload.deleteReminder,
                error: null,
            };
        case DELETE_REMINDER_ERROR:
            return {
                ...state,
                isDeleteReminder: false,
                deleteReminder: [],
                error: action.payload.errorDeleteReminder,
            };

        default:
            return {
                ...state,
            };
    }
};
