import React from "react";
import { makeStyles } from "@material-ui/core";
import "../../styles/card.scss";

interface cardProps {
    width?:string;
    height?:string;
    borderRadius?:string;
    border?:any;
    cardMargin?:string;
    cardPadding?:string;
    onClick?:any;
    className?:string;
    imgWidth?:string;
    imgHeight?:string;
    cardImage?:any;
    cardTitle?:string;
    cardContent?:string; 
    boxShadow?:any;
    imgClassname?:string;
}

const CommonCard = (props: cardProps) => {

    const useStyles = makeStyles(() => ({
        root: {
          "&:hover": {
            boxShadow: "0 0 10px #e4e6e8"
          },
         
        },
      }));

      const classes = useStyles();
  return (
    <div
      style={{
        width: props.width ? props.width : "200px",
        height: props.height ? props.height : "500px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: props.borderRadius ? props.borderRadius : "3px",
        boxShadow: props.boxShadow ? props.boxShadow : null,
        border:props.border ? props.border : null,
        opacity: "1",
        margin: props.cardMargin ? props.cardMargin : "",
        padding: props.cardPadding ? props.cardPadding : "5px",
        cursor: "pointer",
      }}
      onClick={props.onClick}
      className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-height-width`}
    >
              <div
        className="cardContent"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px",
        }}
      >
      <div>
      <img
          width={props.imgWidth ? props.imgWidth : "80px"}
          height={props.imgHeight ? props.imgHeight : "80px"}
          src={props.cardImage}
          style={{
            marginRight: "auto",
          }}
          alt=""
          className={props.imgClassname ? props.imgClassname : ""}
        />
      </div>

<div>
      <div className="collectionCardTitle"
      >
        {props.cardTitle}
      </div>

      </div>

    <div>
    <p  className="collectionCardPara"
      >
        {props.cardContent}
      </p>
    </div>

    
      </div>

    </div>
  );
};

export default CommonCard;
