import React, { Component } from 'react';
import Button from '../atoms/Button';
import PolicyDetailsCard from './PolicyDetailsCard';
import QuoteDetailsCard from './QuoteDetailsCard';
import Modal from '../atoms/Modal';
import SelectInput from '../atoms/SelectInput';
import CheckboxText from '../atoms/CheckboxText';
import TextField from '@material-ui/core/TextField';
import '../../styles/scheduleACall.css';

type initialProps = {
    history: any;
};

type initialState = {
    scheduleACallBack: boolean;
    date: any;
    time: any;
    checked: boolean;
    firstName: string;
    lastName: string;
    email: string;
    zip: number;
    phone: number;
    flexibleTime: boolean;
};

class ScheduleACall extends Component<initialProps, initialState> {
    state: initialState = {
        scheduleACallBack: false,
        date: '',
        time: '',
        checked: false,
        firstName: '',
        lastName: '',
        email: '',
        zip: 711112,
        phone: 998876543,
        flexibleTime: false,
    };

    handleCallBack = () => {
        this.setState({
            scheduleACallBack: true,
        });
    };

    handleCloseModal = () => {
        this.setState({
            scheduleACallBack: false,
        });
    };

    handleDateChange = (e: any) => {
        this.setState({
            date: e,
        });
    };

    handleTimeChange = (e: any) => {
        this.setState({
            time: e,
        });
    };

    handleCheck = () => {
        const { checked } = this.state;
        this.setState({
            checked: !checked,
        });
    };

    handleFlexibleTime = () => {
        let { flexibleTime } = this.state;
        this.setState({
            flexibleTime: !flexibleTime,
        });
    };

    onChangeHandler = (e: any, name: string) => {
        this.setState({
            ...this.state,
            [name]: e.target.value,
        });
    };

    onSelectHandler = (e: any, name: string) => {
        this.setState({
            ...this.state,
            [name]: e.value,
        });
    };

    redirectToThankYou = () => {
        this.props.history.push('/insurance-thankyou');
    };

    render() {
        const {
            scheduleACallBack,
            date,
            time,
            checked,
            firstName,
            lastName,
            email,
            phone,
            zip,
            flexibleTime,
        } = this.state;
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <div className="schedule-a-call-heading">Great, you are almost done</div>
                        <div className="schedule-a-call-subheading">
                            You can either set up a call with our concierge team or call the insurance agent directly
                        </div>

                        <div className="schedule-a-call-container">
                            <div className="schedule-a-call-row-wrapper">
                                <Button
                                    // backgroundColor="#273E59"
                                    // color="#fff"
                                    fontSize="16px"
                                    borderRadius="4px"
                                    onClick={this.handleCallBack}
                                >
                                    Schedule a Call Back
                                </Button>
                                <div className="schedule-a-call-or">or</div>
                                <div className="schedule-a-call-or">
                                    {/* <img /> */}
                                    <div className="schedule-a-call-row">
                                        <span className="schedule-a-call-subheading">Call</span>&nbsp;
                                        <span className="schedule-a-call-phone">844-232-2700</span>&nbsp;
                                        <span className="schedule-a-call-subheading">to finalise your policy</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ paddingTop: '12px' }}>
                            <PolicyDetailsCard premiumMonthly="28" premiumYearly="336" width="40%" />
                        </div>
                        <div style={{ paddingTop: '12px' }}>
                            <QuoteDetailsCard
                                deductible={1000}
                                dwellingCoverage={150000}
                                liabilityCoverage={500000}
                                medicalCoverage={5000}
                                personalProperty={75000}
                                lossOfUse={45000}
                            />
                        </div>
                    </div>
                </div>
                {scheduleACallBack ? (
                    <Modal
                        isShowModal={scheduleACallBack}
                        onCloseModal={this.handleCloseModal}
                        showClose={true}
                        top="0%"
                    >
                        <div>
                            <div className="schedule-a-call-heading">Schedule a Call Back</div>
                            <div className="schedule-a-call-divider"></div>

                            <div>
                                <div className="schedule-a-call-details-heading">Call schedule details</div>
                                <div className="schedule-a-call-container schedule-a-call-personal-details">
                                    <div className="schedule-a-call-select">
                                        <SelectInput
                                            className="react-select alert-select"
                                            onChange={(e: any) => this.onSelectHandler(e, 'date')}
                                            options={[
                                                { value: '15 Apr 2021', label: '15 Apr 2021' },
                                                { value: '16 Apr 2021', label: '16 Apr 2021' },
                                            ]}
                                            placeholder={date ? date : 'Select Date'}
                                            value={date}
                                            // label="Select Date"
                                        />
                                    </div>
                                    <div className="schedule-a-call-select">
                                        <SelectInput
                                            className="react-select alert-select "
                                            onChange={(e: any) => this.onSelectHandler(e, 'time')}
                                            options={[
                                                { value: '11:30AM', label: '11:30AM' },
                                                { value: '12:30PM', label: '12:30PM' },
                                            ]}
                                            placeholder={time ? time : 'Select Time'}
                                            value={time}
                                            // label="Select Time"
                                        />
                                    </div>
                                    <CheckboxText
                                        checked={flexibleTime}
                                        onChange={this.handleFlexibleTime}
                                        label="I am flexible on time"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="schedule-a-call-details-heading">Personal details</div>
                                <div className="schedule-a-call-container schedule-a-call-personal-details">
                                    <div>
                                        <TextField
                                            className="input1"
                                            value={firstName}
                                            label="FirstName"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.onChangeHandler(e, 'firstName')}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            className="input1"
                                            value={lastName}
                                            label="LastName"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.onChangeHandler(e, 'lastName')}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className="schedule-a-call-container schedule-a-call-personal-details">
                                    <div>
                                        <TextField
                                            className="input1"
                                            value={email}
                                            label="Email"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.onChangeHandler(e, 'email')}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            className="input1"
                                            value={phone}
                                            label="Phone Number"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.onChangeHandler(e, 'phone')}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className="schedule-a-call-container schedule-a-call-personal-details">
                                    <div>
                                        <TextField
                                            className="input1"
                                            value={zip}
                                            label="Zip Code"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.onChangeHandler(e, 'zip')}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{ display: 'flex', flexDirection: 'row' }}
                                className="schedule-a-call-personal-details"
                            >
                                <div>
                                    <CheckboxText checked={checked} onChange={this.handleCheck} label="" />
                                </div>
                                <div>
                                    By clicking the <span style={{ fontWeight: 'bold' }}>'Submit'</span> button, you are
                                    providing your signed written consent to receive calls or texts from InsuraMatch and
                                    its agents to market insurance products at the number (wireless or otherwise) you
                                    provided above. As part of that consent, you understand and agree that (1) those
                                    calls may be auto-dialed and use artificial or pre-recorded voice messages or SMS
                                    communications., and (2) your agreement to receive these calls is not required to
                                    purchase any goods or services, and (3) InsuraMatch will not sell your information
                                    to third parties without your consent. You agree to InsuraMatch's{' '}
                                    <span style={{ color: '#0066F5' }}>
                                        Terms & Conditions, Compensation and Information Disclosure
                                    </span>{' '}
                                    and <span style={{ color: '#0066F5' }}>Privacy Policy</span> and authorize
                                    InsuraMatch and the companies it represents to obtain consumer reports on the
                                    persons listed in your application.
                                </div>
                            </div>

                            <div className="schedule-a-call-footer">
                                <Button
                                    backgroundColor="#fff"
                                    border="1px solid #273E59"
                                    borderRadius="4px"
                                    color="#273E59"
                                    fontSize="16px"
                                    fontWeight="bold"
                                    onClick={this.handleCloseModal}
                                >
                                    Cancel
                                </Button>
                                <div style={{ paddingLeft: '16px' }}>
                                    <Button
                                        backgroundColor="#273E59"
                                        borderRadius="4px"
                                        // color="#fff"
                                        fontSize="16px"
                                        fontWeight="bold"
                                        onClick={this.redirectToThankYou}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
export default ScheduleACall;
