import {
  GET_MAKES,
  GET_MAKES_ERROR,
  GET_MAKES_SUCCESS,
  GET_MODELS,
  GET_MODELS_ERROR,
  GET_MODELS_SUCCESS,
  POST_SHIPPING,
  POST_SHIPPING_SUCCESS,
  POST_SHIPPING_ERROR
} from './actionTypes';
import {
  GetMakes,
  GetMakesRequest,
  GetMakesFailure,
  GetMakesFailurePayload,
  GetMakesSuccess,
  GetMakesSuccessPayload,
  GetModels,
  GetModelsRequest,
  GetModelsFailure,
  GetModelsSuccess,
  GetModelsFailurePayload,
  GetModelsSuccessPayload,
  PostShipping,
  PostShippingFailure,
  PostShippingSuccess,
  PostShippingFailurePayload,
  PostShippingRequest,
  PostShippingSuccessPayload
} from './types';

export const getMakes = (payload: GetMakesRequest): GetMakes => ({
    type: GET_MAKES,
    payload,
});

export const getMakesSuccess = (payload: GetMakesSuccessPayload): GetMakesSuccess => ({
    type: GET_MAKES_SUCCESS,
    payload,
});

export const getMakesFailure = (payload: GetMakesFailurePayload): GetMakesFailure => ({
    type: GET_MAKES_ERROR,
    payload,
});


//Get Models
export const getModels = (payload: GetModelsRequest): GetModels => ({
  type: GET_MODELS,
  payload,
});

export const getModelsSuccess = (payload: GetModelsSuccessPayload): GetModelsSuccess => ({
  type: GET_MODELS_SUCCESS,
  payload,
});

export const getModelsFailure = (payload: GetModelsFailurePayload): GetModelsFailure => ({
  type: GET_MODELS_ERROR,
  payload,
});

//Post Shipping

export const postShipping = (payload: PostShippingRequest): PostShipping => {
  return {
      type: POST_SHIPPING,
      payload,
  };
};

export const postShippingSuccess = (payload: PostShippingSuccessPayload): PostShippingSuccess => {
  return {
      type: POST_SHIPPING_SUCCESS,
      payload,
  };
};

export const postShippingFailure = (payload: PostShippingFailurePayload): PostShippingFailure => {
  return {
      type: POST_SHIPPING_ERROR,
      payload,
  };
};


