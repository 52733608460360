import React from 'react';
import styles from './checkmark.module.scss';
import Done from './Done';
import Error from './Error';

const PREDEFINED_SIZE_MAP = {
    small: 1,
    medium: 1.6,
    large: 2,
    xLarge: 2.6,
    xxLarge: 3.2,
};

const PREDEFINED_COLOR_MAP = {
    success: '#7ac142',
    danger: '#dc3545',
};

interface CheckmarkProps {
    size: keyof typeof PREDEFINED_SIZE_MAP;
    type: keyof typeof PREDEFINED_COLOR_MAP;
}

export const CustomCheckmark = (props: CheckmarkProps) => {
    const { size, type } = props;
    return (
        <div
            className={`${styles.checkmarkWrapper} ${styles[size]}`}
        >
            {type === 'success' && <Done />}
            {type === 'danger' && <Error />}
        </div>
    );
};

