import * as React from 'react';

type initialProps = {
    checked?: boolean;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={74.304} height={68.323} viewBox="0 0 74.304 68.323">
            <g
                data-name="cargo (1)"
                fill={slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'}
            >
                <path
                    data-name="Path 40263"
                    d="M70.543 45.704v-1.517a2.373 2.373 0 00-.133-.786l-2.11-8.356h2.241a1.053 1.053 0 100-2.106H52.285a.957.957 0 00-1 .995V55.05H27a6.946 6.946 0 00-6.14 0h-6.591a6.949 6.949 0 00-6.141 0H1.656A1.7 1.7 0 000 56.751v4.134a1.686 1.686 0 001.656 1.687h2.677a7.164 7.164 0 001.994 3.773 6.979 6.979 0 0011.237-2.152A6.985 6.985 0 0028.8 66.345a7.164 7.164 0 001.994-3.773h24.818a7.162 7.162 0 001.993 3.772 6.993 6.993 0 009.746 0 7.169 7.169 0 001.993-3.776h3.9a1.082 1.082 0 001.055-1.085v-9.538a7.076 7.076 0 00-3.756-6.241zM4.293 60.466H2.106v-3.309h3.5a7.535 7.535 0 00-1.313 3.309zm11.768 1a4.863 4.863 0 01-9.722-.009v-.1a4.852 4.852 0 011.3-3.306l.02-.02.021-.022a4.816 4.816 0 011.2-.927 4.87 4.87 0 014.636 0 4.815 4.815 0 011.2.925l.021.022.02.02a4.847 4.847 0 011.3 3.306.558.558 0 01.004.106zm1.5-3a8.564 8.564 0 00-.767-1.309h1.536a8.691 8.691 0 00-.766 1.308zm11.168 2.879v.125a4.821 4.821 0 01-4.832 4.737 4.881 4.881 0 01-4.846-4.761c0-.033.006-.067.006-.1a4.864 4.864 0 012.543-4.271h.006a4.805 4.805 0 012.318-.587 4.866 4.866 0 012.316.587 4.809 4.809 0 012.5 3.912c.014.112-.011.23-.011.358zm22.562-.882H30.834a7.689 7.689 0 00-1.3-3.309H51.29zm9.476-25.42h5.363l2.249 8.914a.859.859 0 00.031.088.372.372 0 01.027.139v.8a7.1 7.1 0 00-1.2-.16H62.76a1.945 1.945 0 01-1.994-1.9zm-7.37 0h5.264v7.877a4.1 4.1 0 003.159 3.937v2.776H53.4zM67.34 61.459a4.862 4.862 0 01-9.722-.006v-.108a4.862 4.862 0 019.71-.356c.009.115.013.234.013.356-.002.039-.002.077-.002.117zm4.858-1h-2.813a6.966 6.966 0 00-13.812 0H53.4v-4.3-.02V51.745h9.421a1.042 1.042 0 001.108-.98v-3.833h3.307a5.018 5.018 0 014.964 5.013z"
                />
                <path
                    data-name="Path 40264"
                    d="M9.025 34.108a1.053 1.053 0 00-1.053 1.053v12.113a1.053 1.053 0 102.106 0V35.161a1.053 1.053 0 00-1.053-1.053z"
                />
                <path
                    data-name="Path 40265"
                    d="M17.147 34.108a1.053 1.053 0 00-1.053 1.053v12.113a1.053 1.053 0 102.106 0V35.161a1.053 1.053 0 00-1.053-1.053z"
                />
                <path
                    data-name="Path 40266"
                    d="M25.119 34.108a1.053 1.053 0 00-1.053 1.053v12.113a1.053 1.053 0 102.106 0V35.161a1.053 1.053 0 00-1.053-1.053z"
                />
                <path
                    data-name="Path 40267"
                    d="M33.091 34.108a1.053 1.053 0 00-1.053 1.053v12.113a1.053 1.053 0 102.106 0V35.161a1.053 1.053 0 00-1.053-1.053z"
                />
                <path
                    data-name="Path 40268"
                    d="M41.213 34.108a1.053 1.053 0 00-1.053 1.053v12.113a1.053 1.053 0 102.106 0V35.161a1.053 1.053 0 00-1.053-1.053z"
                />
                <path
                    data-name="Path 40269"
                    d="M2.557 53.095h45.124a1.118 1.118 0 001.053-1.126v-21.61a.49.49 0 00-.017-.091 1.059 1.059 0 00-.062-.2.924.924 0 00-.034-.071l-.011-.022c-.009-.017-.02-.034-.03-.05l-.023-.038-.023-.03c-.008-.009-.025-.035-.039-.052l-.011-.013a1.04 1.04 0 00-.231-.2.855.855 0 00-.072-.043h-.006q-.044-.023-.09-.043h.02L26.473 20.57v-1.2a4.213 4.213 0 002.748-3.869 4.108 4.108 0 00-3.049-3.944V8.122h.461a1.047 1.047 0 001.021-.791l1.514-6.054a1.021 1.021 0 00-.192-.887 1.039 1.039 0 00-.83-.39h-6.055a1.033 1.033 0 00-1.021 1.287l1.514 6.047a1.044 1.044 0 001.021.788h.461v4.351a1.053 1.053 0 001.053 1.053 1.976 1.976 0 11-1.975 1.975 1.053 1.053 0 00-1.053-1.053 1.035 1.035 0 00-1.032 1.053 4.026 4.026 0 003.308 4v1.059L2.185 29.49H2.17c-.024.01-.053.021-.076.033l-.028.012-.057.033-.036.021-.034.025c-.017.012-.034.024-.05.038a1.039 1.039 0 00-.35.559c0 .011-.006.021-.008.032s0 .03-.007.044-.007.04-.009.06 0 .02 0 .03V51.969a1.119 1.119 0 001.042 1.126zm21.87-47.079l-.988-3.915h3.36l-.992 3.915zm.946 16.461l16.99 7H7.989zM3.607 31.586h43.021v19.4H3.607z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
