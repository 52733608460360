import React, { useEffect } from 'react';
import Button from '../../../components/atoms/Button';
import AnalyticsService from '../../../pages/analytics-service';
import { renderContactButtonText } from '../../../utils/common-functions';
import './HomeBanner.scss';
interface mikebannerProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    bannerMargin?: string;
    bannerPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    agentImage?: any;
    brokerageImage?: any;
    imgName?: any;
    bannerTitle?: string;
    brokerageTitle?: string;
    bannerContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    onAgentContact: any;
    serviceAgentData?: any;
    serviceProviders?: any;
    analyticsData?: any;
}

const MikeHomeBanner = (props: mikebannerProps) => {
    const { serviceAgentData, serviceProviders, analyticsData } = props;

    useEffect(() => {
        AnalyticsService.cardViewed({
             ...analyticsData,
             category_name: "Mortgage Contact Card Viewed"
         });
    }, [])

    const getButtonText = () => {
       return renderContactButtonText(serviceAgentData, serviceProviders);
    };

    return (
        <>
            {serviceAgentData?.photo_url?.length ? (
                <div
                    className="MainBanner hellcBannerCard"
                    onClick={props.onClick}
                >
                    <div className="banner-Container">
                        <div className="bannerImage">
                           {serviceAgentData?.photo_url && <img
                                width={props.imgWidth ? props.imgWidth : '80px'}
                                height={props.imgHeight ? props.imgHeight : '80px'}
                                src={serviceAgentData.photo_url}
                                style={{
                                    borderRadius: '40px',
                                    width: '80px',
                                    height: '80px',
                                    marginRight: 'auto',
                                    marginBottom: '5px',
                                }}
                                alt="Agent Profile Image"
                                className={props.imgClassname ? props.imgClassname : ''}
                            />}
                            <span className="nameBanner">{`${serviceAgentData?.first_name ||
                                ''} ${serviceAgentData?.last_name || ''}`}</span>
                        </div>
                    </div>

                    <div style={{ flex: '0.8' }}>
                        <div>
                            <img src={serviceProviders?.logo} alt="" width={186} />
                        </div>
                        <div className="bannerTitle" style={{ margin: '10px 0 !important' }}>
                            {serviceProviders?.name}
                        </div>
                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{props.bannerTitle}</div>

                        <div className="bannerSubTitle">{props.bannerContent}</div>
                    </div>
                    <div style={{ flex: '0.1', paddingLeft: '50px' }}>
                        <Button onClick={props.onAgentContact} borderRadius="4px" children={getButtonText()} width="150px" />
                    </div>
                </div>
            ) : (
                ''
            )}
            {!serviceAgentData?.photo_url?.length ? (
                <div
                    className="MainBanner hellcBannerCard"
                    onClick={props.onClick}
                >
                    <div style={{ flex: 1 }}>
                        <div>
                            {serviceProviders?.logo && <img src={serviceProviders.logo} alt="" width={186} />}
                        </div>
                        <div className="bannerTitle m-0 mt-1">{serviceProviders?.name}</div>
                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{props.bannerTitle}</div>

                        <div className="bannerSubTitle">{props.bannerContent}</div>
                    </div>
                    <div style={{ flex: '0.1', paddingLeft: '50px' }}>
                        <Button onClick={props.onAgentContact} borderRadius="4px" children={getButtonText()} width="150px" />
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default MikeHomeBanner;
