import React, { Component } from 'react';
import Modal from '../../components/atoms/Modal';
import './checklistModal.scss';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '../../Assets/images/ICImages/close.svg';
// import TextField from '@material-ui/core/TextField';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '../../components/atoms/Button';
import EventIcon from '@material-ui/icons/Event';
import DoneIcon from '@material-ui/icons/Done';
type initialProps = { calender: boolean };

type initialState = {
    setRemainder: boolean;
    date: any;
    reminders: any;
    emailId: string;
    phoneNumber: any;
    getMovingQuotes: boolean;
};

export class CheckistModal extends Component<initialProps, initialState> {
    state: initialState = {
        setRemainder: false,
        date: '',
        reminders: { phone: false, email: false },
        emailId: '',
        phoneNumber: '',
        getMovingQuotes: false,
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e.target.value,
        });
    };

    handleSendReminders = (type: string) => {
        let reminder = this.state.reminders;
        type === 'phone'
            ? (reminder.phone = !reminder.phone)
            : type === 'email'
            ? (reminder.email = !reminder.email)
            : (reminder = reminder);
        this.setState({ reminders: reminder });
    };

    handleEmail = (e: any) => {
        this.setState({
            emailId: e.target.value,
        });
    };

    handlePhone = (e: any) => {
        this.setState({
            phoneNumber: e.target.value,
        });
    };

    render() {
        // const { calender } = this.props;
        const {
            // setRemainder,
            getMovingQuotes,
        } = this.state;
        return (
            <div className="checklistModals">
                <Modal
                    isShowModal={getMovingQuotes}
                    onCloseModal={() => this.setState({ getMovingQuotes: false })}
                    showClose={false}
                    className="setReminder"
                >
                    <div className="setReminderContent">
                        <div className="setReminderGetMovingQuotes">
                            <span className="setReminder-upperContentBlueChecklist">Essentials & Overdue</span>
                            <img
                                src={CloseIcon}
                                width="21px"
                                onClick={() => this.setState({ getMovingQuotes: false })}
                            />
                        </div>
                        <div className="setReminderGetMovingQuotesText">Getting Moving Quotes</div>
                        <div className="setReminderGetMovingQuotesContent">
                            Let us help by providing you with a list of top rated movers with their BBB ratings &
                            reviews, and schedule an in-home consultation with three moving companies.
                        </div>
                        <div className="setReminderGetMovingQuotesContentBtnWrapper">
                            <Button
                                // backgroundColor="#273E59"
                                // color="#fff"
                                borderRadius="4px"
                                border="1px solid #273E59"
                            >
                                Complete Now
                            </Button>
                        </div>
                        <div className="setReminderGetMovingQuotesContentFooter">
                            <div className="setReminderGetMovingQuotesContentFooterContent">
                                <EventIcon style={{ color: '#6B6C6F' }} />
                                <div className="setReminderGetMovingQuotesContentFooterContentText">Edit reminder</div>
                            </div>
                            <div
                                className="setReminderGetMovingQuotesContentFooterContent"
                                style={{ paddingLeft: '16px' }}
                            >
                                <DoneIcon style={{ color: '#6B6C6F' }} />
                                <div className="setReminderGetMovingQuotesContentFooterContentText">Mark Complete</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default CheckistModal;
