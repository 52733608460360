// import React, { useState } from 'react';
import React, { Component } from 'react';
import Congrats from './Congrats';
import Main from './Calculator/Main';
import AddLoanDetails from '../../../../components/homeOwnerDashboardComponents/AddLoanDetails';

interface SavingCalculatorProps {
    fewerPayments?: string;
    tillDate?: string;
    saving?: string | number;
    debit?: string | number;
    isPostingSavingCalcData: boolean;
    postSavingsCalcData: any;
    hashCode: string;
    serviceAgentData?: any;
    onAgentContact: any;
    loanDetails?: any;
    serviceProviders?: any;
    isFetchingHomeValuation?: any;
    averageCalulations?: any;
    handleLoanDetailsClick?: any;
    analyticsData?: any;
}

export default class SavingCalculator extends Component<SavingCalculatorProps> {
    render() {
        const {
            fewerPayments,
            tillDate,
            saving,
            debit,
            isFetchingHomeValuation,
            handleLoanDetailsClick,
            loanDetails,
            analyticsData,
            postSavingsCalcData,
            hashCode,
            serviceAgentData,
            onAgentContact,
            isPostingSavingCalcData,
            serviceProviders,
        } = this.props;

        const showNoDataView = !isFetchingHomeValuation && (loanDetails?.message || !loanDetails);
        return (
            <div className="mainContainerSavingCalculator">
                <div className={`subContainer ${showNoDataView ? 'disabledCalculator' : ''}`}>
                    <Main
                        isPostingSavingCalcData={isPostingSavingCalcData}
                        postSavingsCalcData={postSavingsCalcData}
                        hashCode={hashCode}
                        loanDetails={loanDetails}
                        showNoDataView={showNoDataView}
                        analyticsData={analyticsData}
                    />
                </div>
                <div className="subContainer1">
                    <Congrats
                        fewerPayments={fewerPayments}
                        tillDate={tillDate}
                        saving={saving || 0}
                        debit={debit || 0}
                        serviceAgentData={serviceAgentData}
                        onAgentContact={onAgentContact}
                        isPostingSavingCalcData={isPostingSavingCalcData}
                        serviceProviders={serviceProviders || null}
                        showNoDataView={showNoDataView}
                        analyticsData={analyticsData}
                    />
                    {showNoDataView && (
                        <div id="savingCalculatorNodata">
                            <AddLoanDetails handleClickAdd={handleLoanDetailsClick} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
