import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    const isDouglasElliman = props.className.includes('douglasElliman');

    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={45.6} height={55.006} viewBox="0 0 45.6 55.006">
            <defs>
                <style>
                    {' '}
                    {slug && slug.color_shade1 && slug.color_shade4
                        ? isDouglasElliman
                            ? `.waterprefix__a{fill:#ffffff;stroke:#333333;stroke-width:2px}`
                            : `.waterprefix__a{fill:${slug.color_shade4};stroke:${slug.color_shade1};stroke-width:2px}`
                        : '.waterprefix__a{fill:#5b7088;stroke:#002e62;stroke-width:2px}'}
                </style>
            </defs>
            <path
                className="waterprefix__a"
                d="M23.574 19.869l2.149 4.142a13.217 13.217 0 01-11.978 17.834A13.216 13.216 0 011.767 24.01L13.745.924l8.361 16.118M42.154 26.145l2.107 4.06a6.364 6.364 0 11-11.812 0l5.906-11.383 2.5 4.812M29.956 53.809a4.241 4.241 0 01-5.322-5.588l3.936-7.585 3.935 7.585a4.343 4.343 0 01-2.549 5.588z"
            />
            <path
                className="waterprefix__b"
                d="M13.746 42.771h-.028A14.141 14.141 0 01.902 23.687a.928.928 0 01.045-.1L12.923.5a.925.925 0 01.821-.5.924.924 0 01.821.5l9.923 19.14a.925.925 0 01-1.642.852l-9.1-17.559-11.13 21.453a12.291 12.291 0 0011.13 16.535 12.292 12.292 0 0011.131-16.535L22.754 20.3a.925.925 0 111.641-.852l2.149 4.142a.958.958 0 01.046.1 14.141 14.141 0 01-12.816 19.08zM38.357 39.923h-.028a7.441 7.441 0 01-6.744-10.042.955.955 0 01.045-.1l5.906-11.383a.925.925 0 011.642 0l3.958 7.674a.925.925 0 01-1.642.852l-3.137-6.094-5.058 9.749a5.439 5.439 0 1010.117 0l-2.08-4.009a.925.925 0 111.642-.852l2.107 4.061a.929.929 0 01.045.1 7.441 7.441 0 01-6.744 10.042zM28.57 55.005h-.029a5.267 5.267 0 01-4.774-7.108.911.911 0 01.046-.1l3.936-7.585a.925.925 0 01.821-.5.925.925 0 01.821.5l3.935 7.585a.962.962 0 01.045.1 5.267 5.267 0 01-4.773 7.108zm-3.087-6.411a3.315 3.315 0 106.174 0l-3.087-5.95z"
            />
        </svg>
    );
}

export default SvgComponent;
