import React from 'react';
import Slider from './SliderHomeEquity';
import './Main.scss';
import HomeEquityViewHaloc from './HomeEquityViewHaloc';
import { numberWithCommas } from '../../../../utils/helper';
function Main({ loanDetails, currentAvgValuation, analyticsData }: any) {
    let slider_blue_part_value = 0;
    if (loanDetails?.remaining_equity_balance?.heloc_percentage && loanDetails?.remaining_equity_balance?.total_heloc_value) {
        slider_blue_part_value = loanDetails?.remaining_equity_balance?.heloc_percentage * loanDetails?.remaining_equity_balance?.total_heloc_value * 0.01;
    }
    const remainingLoan = loanDetails?.remaining_equity_balance?.remaining_balance;
    let totalHomeEquity = Math.round(loanDetails?.remaining_equity_balance?.equity_balance)
    return (
        <div className="MainContainerHomeEquity">
            <div className="MainContainerHomeEquityBox">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Slider
                        slider_blue_part_value={numberWithCommas(Math.round(slider_blue_part_value))}
                        heloc_percentage={loanDetails?.remaining_equity_balance?.heloc_percentage}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', paddingTop: 10 }}>
                        <div>
                            <span className='EquityAmountLabel'>Total Home Equity:&nbsp;</span>
                            <span className='EquityAmount'>${numberWithCommas(totalHomeEquity)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="MainContainerHomeEquityBox1">
               {currentAvgValuation &&  <HomeEquityViewHaloc analyticsData={analyticsData} totalHomeEquity={totalHomeEquity} remainingLoan={remainingLoan} currentAvgValuation={currentAvgValuation}/>}
            </div>
        </div>
    );
}

export default Main;
