import {
    HOME_VALUATION_CARD_DETAILS,
    HOME_VALUATION_CARD_DETAILS_SUCCESS,
    HOME_VALUATION_CARD_DETAILS_ERROR,
    HOME_VALUATION_SAVING_CALCULATOR_DETAILS,
    HOME_VALUATION_SAVING_CALCULATOR_DETAILS_SUCCESS,
    HOME_VALUATION_SAVING_CALCULATOR_DETAILS_ERROR,
    LOAN_DETAILS,
    LOAN_DETAILS_SUCCESS,
    LOAN_DETAILS_ERROR,
} from './actionTypes';
import { HomeValuationCardDetails, HomeValuationCardDetailsSuccess, HomeValuationCardDetailsFailure } from './types';
import * as types from './types';
import {
    LoanDetails,
    LoanDetailsSuccess,
    LoanDetailsFailure,
} from './types';

// Home Valuations
export const homeValuationCardDetails = (payload: any): HomeValuationCardDetails => ({
    type: HOME_VALUATION_CARD_DETAILS,
    payload,
});

export const homeValuationCardDetailsSuccess = (payload: any): HomeValuationCardDetailsSuccess => ({
    type: HOME_VALUATION_CARD_DETAILS_SUCCESS,
    payload,
});

export const homeValuationCardDetailsFailure = (payload: any): HomeValuationCardDetailsFailure => ({
    type: HOME_VALUATION_CARD_DETAILS_ERROR,
    payload,
});

// Loan Details
export const loanDetails = (payload: any): LoanDetails => ({
    type: LOAN_DETAILS,
    payload,
});

export const loanDetailsSuccess = (
    payload: any,
): LoanDetailsSuccess => ({
    type: LOAN_DETAILS_SUCCESS,
    payload,
});

export const loanDetailsFailure = (
    payload: any,
): LoanDetailsFailure => ({
    type: LOAN_DETAILS_ERROR,
    payload,
});
export const homeValuationSavingCalculatorDetails = (
    payload: types.HomeValuationSavingCaculatorDetailsRequest,
): types.HomeValuationSavingCalcultorDetails => ({
    type: HOME_VALUATION_SAVING_CALCULATOR_DETAILS,
    payload,
});

export const homeValuationSavingCalculatorDetailsSuccess = (
    payload: types.HomeValuationSavingCalcultorDetailsSuccessPayload,
): types.HomeValuationSavingCalcultorDetailsSuccess => ({
    type: HOME_VALUATION_SAVING_CALCULATOR_DETAILS_SUCCESS,
    payload,
});

export const homeValuationSavingCalculatorDetailsFailure = (
    payload: types.HomeValuationSavingCalcultorDetailsFailurePayload,
): types.HomeValuationSavingCalcultorDetailsFailure => ({
    type: HOME_VALUATION_SAVING_CALCULATOR_DETAILS_ERROR,
    payload,
});
