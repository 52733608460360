import React, { Component } from 'react';
import Button from '../../components/atoms/Button';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MoveAction from '../../store/common/actions';
import * as ReserveStorageAction from '../../store/move/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Moving.scss';
import * as DashboardAction from '../../store/dashboard/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    id?: string;
    image?: string;
    location?: any;
    history?: any;
    moveState?: any;
    moveAction?: any;
    reserveStorageState?: any;
    reserveStorageAction?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    formData: {
        fullName: string;
        phone: string;
        email: string;
        moveInDate: any;
        moveOutDate: any;
    };
    isDataArrived: boolean;
    isShowMobileSelectedUnit: boolean;
    anchorEl1: any;
    anchorEl: any;
    moveInDateError: string;
    moveOutDateError: string;
    fullNameError: string;
    phoneError: string;
    emailError: string;
};

let validPhone: any = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

class OrderPlace extends Component<initialProps, initialState> {
    state: initialState = {
        formData: {
            fullName: '',
            phone: '',
            email: '',
            moveInDate: null,
            moveOutDate: null,
        },
        moveInDateError: '',
        moveOutDateError: '',
        fullNameError: '',
        phoneError: '',
        emailError: '',
        anchorEl1: null,
        anchorEl: null,
        isDataArrived: true,
        isShowMobileSelectedUnit: false,
    };

    componentDidMount() {
        this.props.moveAction.moveGet();
        this.props.dashboardAction.checklist();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.moveState && nextProps.moveState.move && currentState.isDataArrived) {
            nextProps.moveState.move.map((item: any) => {
                currentState.isDataArrived = false;
                const fullName = `${item.user_ref.first_name} ${item.user_ref.last_name}`;
                const phone = item.user_ref.phone;
                const email = item.user_ref.email;

                currentState.formData.fullName = fullName;
                currentState.formData.phone = phone;
                currentState.formData.email = email;
            });
        }

        if (nextProps && nextProps.reserveStorageState && nextProps.reserveStorageState.postReserveStorageArrived) {
            nextProps.reserveStorageState.postReserveStorageArrived = false;
            updateChecklistTask(100, nextProps.dashboardAction);
            nextProps.history.push({
                pathname: '/dashboard/movers/reserve_storage_thankyou',
                state: nextProps.location.state,
            });
            window.scrollTo(0, 0);
        }
        return currentState;
    }

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    handleChange = (e: any, name: any) => {
        if (name === 'phone') {
            let phonenumber = this.formatPhoneInput(e.currentTarget.value);
            const formData = { ...this.state.formData, [name]: phonenumber };
            this.setState({ formData });
        } else {
            const formData = { ...this.state.formData, [name]: e.currentTarget.value };
            this.setState({ formData });
        }
    };

    validate = () => {
        let isValid: boolean = true;
        if (this.state.formData.fullName == '') {
            isValid = false;
            this.setState({ fullNameError: 'Please Provide Name' });
        }
        if (this.state.formData.phone == '') {
            isValid = false;
            this.setState({ phoneError: 'Please Provide Phone Number' });
        }
        if (this.state.formData.email == '') {
            isValid = false;
            this.setState({ emailError: 'Please Provide Email' });
        }
        if (this.state.formData.moveInDate == null) {
            isValid = false;
            this.setState({ moveInDateError: 'Please Provide Move In Date' });
        }

        // if (this.state.formData.moveOutDate == null) {
        //     isValid = false;
        //     this.setState({ moveOutDateError: 'Please Provide Move Out Date' });
        // }

        if (!validPhone.test(this.state.formData.phone)) {
            isValid = false;
            this.setState({ phoneError: 'Please Provide Valid Phone Number' });
        }

        return isValid;
    };

    requestAppointment = async () => {
        //    if(this.validate()) {
        let payload: any;
        if (this.state.formData.moveOutDate == null) {
            payload = {
                email: this.state.formData.email,
                first_name: this.state.formData.fullName.split(' ')[0],
                last_name: this.state.formData.fullName.split(' ')[1],
                movein:
                    this.state.formData.moveInDate !== null
                        ? this.state.formData.moveInDate.toISOString().split('T')[0]
                        : '',
                phone_number: this.state.formData.phone,
                space_id: this.props.location.state.id,
            };
            let slug: any = localStorage.getItem('slug');
            slug = JSON.parse(slug);
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            if (
                this.state.formData.email &&
                this.state.formData.fullName &&
                this.state.formData.phone &&
                this.state.formData.moveInDate
            ) {
                buttonClicked(
                    'Button Clicked',
                    'StorageUnit Order Page',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    'Moving Services',
                    'Confirm Reservation',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    false,
                    '',
                    '',
                    move_id,
                );
            }
        } else {
            payload = {
                email: this.state.formData.email,
                first_name: this.state.formData.fullName.split(' ')[0],
                last_name: this.state.formData.fullName.split(' ')[1],
                movein:
                    this.state.formData.moveInDate !== null
                        ? this.state.formData.moveInDate.toISOString().split('T')[0]
                        : '',
                moveout:
                    this.state.formData.moveOutDate !== null
                        ? this.state.formData.moveOutDate.toISOString().split('T')[0]
                        : '',
                phone_number: this.state.formData.phone,
                space_id: this.props.location.state.id,
            };
            let slug: any = localStorage.getItem('slug');
            slug = JSON.parse(slug);
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            if (
                this.state.formData.email &&
                this.state.formData.fullName &&
                this.state.formData.phone &&
                this.state.formData.moveInDate &&
                this.state.formData.moveOutDate
            ) {
                buttonClicked(
                    'Button Clicked',
                    'StorageUnit Order Page',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    'Moving Services',
                    'Confirm Reservation',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    false,
                    '',
                    '',
                    move_id,
                );
            }
        }

        await this.props.reserveStorageAction.postReserveStorage(payload);
        //    }
    };

    showMobileSelectedCard = () => {
        this.setState({ isShowMobileSelectedUnit: !this.state.isShowMobileSelectedUnit });
    };

    dateHandler = (e: any, name: string) => {
        const formData = { ...this.state.formData, [name]: e };
        this.setState({ formData, anchorEl: null, anchorEl1: null });
    };

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const redirectWithStates = {
            facilityId: this.props.history.location?.state?.facilityId,
            facilityName: this.props.history.location?.state?.name,
            facilityAddress: this.props.history.location?.state?.address,
            facilityImage: this.props.history.location?.state?.facilityImage,
        }

        const breadList = [
            {
                name: 'Moving Services',
                url: '/dashboard/movers'
            },
            {
                name: 'Reserve a Storage Unit',
                url: '/dashboard/storage'
            },
            {
                name: 'Tri-Village Self Storage',
                url: `/dashboard/storage/${this.props.history.location?.state?.facilityId}`,
                redirectStates: redirectWithStates
            },
            {
                name: 'Order Placing',
                url: ''
            },
        ]

        return (
            <div className={isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'}>
                <div className="order-place-main">
                    <div className="main-head-moving-order">
                        <h2 className="heading">Moving Services</h2>
                        <BreadCrumb breadList={breadList} />
                    </div>
                    <div className="storageDetailsMobile">
                        <div className="selectedStorageDetailsTitleDiv">
                            <div className="selectedStorageHeadingMobile">Selected storage units</div>
                            <div className="selectedStorage Arrow">
                                <ExpandMoreIcon onClick={this.showMobileSelectedCard} />
                            </div>
                        </div>
                        {this.state.isShowMobileSelectedUnit ? (
                            <div className="selectedStorageDetailsContentDiv">
                                <div className="nameHeading">{this.props.location.state.name}</div>
                                <div className="addressHeading">{this.props.location.state.address}</div>
                                <div className="unitCard">
                                    {this.props.location.state.image ? (
                                        <div className="unitLogo">
                                            <img src={this.props.location.state.image} alt="" />
                                        </div>
                                    ) : null}
                                    <div className="unitTitleDiv">
                                        <div className="unitTitle">{`${this.props.location.state.dimension} Unit`}</div>
                                        <div className="unitLocation">
                                            {this.props.location.state.amenities.map((amenity: any) => {
                                                return `${amenity}, `;
                                            })}
                                            {/* Floor 1, Drive up, Outdoor access */}
                                        </div>
                                    </div>
                                    <div className="unitCurrencyDiv">
                                        <div className="unitCurrency">$</div>
                                        <div className="unitAmount">
                                            {this.props.location.state.price.split('.')[0]}
                                        </div>
                                        <div className="unitRate">/mo</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="orderMainHeading">Great, you are almost done</div>
                    <div className="orderSubHeading">
                        Please provide some basic information so we can set up an appointment for you
                        <img src={iIcon} style={{ marginLeft: '3px' }} alt="" />
                    </div>

                 <div className="orderFlexDiv">
                        <div className="completeReservationDiv">
                            <div
                                className="completeReservationHeading"
                                style={{
                                    background: isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59',
                                }}
                            >
                                Schedule A Call
                            </div>
                            <div className="reservationFormDiv">
                                <div className="personalDetailsDiv">
                                    <div className="detailsHeading">Personal Details</div>
                                    <div className="orderFlexDiv">
                                        <div className="orderPlacingInputDiv">
                                            <TextField
                                                // disabled={this.state.formData.fullName != ''}
                                                className={isDouglasElliman ? 'call-input' : 'call-inputnoDoug'}
                                                label="Full Name"
                                                error={this.state.formData.fullName != '' ? true : false}
                                                id="outlined-start-adornment"
                                                onChange={(e: any) => this.handleChange(e, 'fullName')}
                                                variant="outlined"
                                                value={this.state.formData.fullName}
                                            />
                                            <div
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                className="errorMoving"
                                            >
                                                {this.state.formData.fullName == '' ? (
                                                    <small>{this.state.fullNameError}</small>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        <div className="orderPlacingInputDiv">
                                            <TextField
                                                // disabled={this.state.formData.phone != ''}
                                                error={this.state.formData.phone != '' ? true : false}
                                                className={isDouglasElliman ? 'call-input' : 'call-inputnoDoug'}
                                                label="Phone Number"
                                                id="outlined-start-adornment"
                                                onChange={(e: any) => this.handleChange(e, 'phone')}
                                                variant="outlined"
                                                value={this.state.formData.phone}
                                            />
                                            <div
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                className="errorMoving"
                                            >
                                                {this.state.formData.phone == '' ||
                                                !validPhone.test(this.state.formData.phone) ? (
                                                    <small>{this.state.phoneError}</small>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        <div className="orderPlacingInputDiv">
                                            <TextField
                                                className={isDouglasElliman ? 'call-input' : 'call-inputnoDoug'}
                                                disabled={this.state.formData.email != ''}
                                                error={this.state.formData.email != '' ? true : false}
                                                label="Email Id"
                                                id="outlined-start-adornment"
                                                onChange={(e: any) => this.handleChange(e, 'email')}
                                                variant="outlined"
                                                value={this.state.formData.email}
                                            />
                                            <div
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                className="errorMoving"
                                            >
                                                {this.state.formData.email == '' ? (
                                                    <small>{this.state.emailError}</small>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="personalDetailsDiv datesInputDiv">
                                    <div className="detailsHeading">Moving Details</div>
                                    <div className="datepickerDiv">
                                        <div className="orderPlacingDateInputDiv">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    // disablePast
                                                    error={this.state.formData.moveInDate != '' ? true : false}
                                                    disableToolbar
                                                    className={isDouglasElliman ? 'call-input1' : 'call-input1noDoug'}
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="Move in date"
                                                    minDate={new Date()}
                                                    maxDate={
                                                        this.state.formData.moveOutDate &&
                                                        this.state.formData.moveOutDate <=
                                                            new Date(new Date().setDate(new Date().getDate() + 15))
                                                            ? this.state.formData.moveOutDate
                                                            : new Date(new Date().setDate(new Date().getDate() + 15))
                                                    }
                                                    // className="date-signUp2"
                                                    value={this.state.formData.moveInDate}
                                                    onChange={(e: any) => {
                                                        this.dateHandler(e, 'moveInDate');
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: e => {
                                                            this.setState({ anchorEl1: e.currentTarget });
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                        disableRestoreFocus: true,
                                                        // onClose: () => {
                                                        //     this.setState({ anchorEl1: null });
                                                        // },
                                                        // anchorEl: this.state.anchorEl1,
                                                    }}
                                                    // open={Boolean(this.state.anchorEl1)}
                                                    // error={Boolean(this.state.)}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                className="errorMoving"
                                            >
                                                {this.state.formData.moveInDate == null ? (
                                                    <small>{this.state.moveInDateError}</small>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className="orderPlacingDateInputDiv">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    error={this.state.formData.moveOutDate != '' ? true : false}
                                                    // disablePast
                                                    disableToolbar
                                                    className={isDouglasElliman ? 'call-input1' : 'call-input1noDoug'}
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="Move out date"
                                                    minDate={
                                                        this.state.formData.moveInDate
                                                            ? this.state.formData.moveInDate
                                                            : new Date()
                                                    }
                                                    // maxDate={new Date(new Date().setDate(new Date().getDate() + 21))}
                                                    // className="date-signUp2"
                                                    value={this.state.formData.moveOutDate}
                                                    onChange={(e: any) => {
                                                        this.dateHandler(e, 'moveOutDate');
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: e => {
                                                            this.handleClick(e);
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        disableRestoreFocus: true,
                                                        // onClose: () => {
                                                        //     this.setState({ anchorEl: null });
                                                        // },
                                                        // anchorEl: this.state.anchorEl,
                                                    }}
                                                    // open={Boolean(this.state.anchorEl)}
                                                    // error={Boolean(this.state.)}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div
                                                // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                className="errorMoving"
                                            >
                                                {this.state.formData.moveOutDate == null ? (
                                                    <small>{this.state.moveOutDateError}</small>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button className="confirmReservationButton" onClick={this.requestAppointment}>
                                    Confirm Reservation
                                </Button>
                            </div>
                        </div>
                        <div className="selectedStorageDiv">
                            <div className="selectedStotageHeading">Selected storage units</div>
                            <div className="storageDiv">
                                <div className="nameHeading">{this.props.location.state.name}</div>
                                <div className="addressHeading">{this.props.location.state.address}</div>
                                <div className="unitCard">
                                    {this.props.location.state.image ? (
                                        <div className="unitLogo">
                                            <img src={this.props.location.state.image} alt="" />
                                        </div>
                                    ) : null}
                                    <div className="unitTitleDiv">
                                        <div className="unitTitle">{`${this.props.location.state.dimension} Unit`}</div>
                                        <div className="unitLocation">
                                            {this.props.location.state.amenities.map((amenity: any) => {
                                                return `${amenity}, `;
                                            })}
                                            {/* Floor 1, Drive up, Outdoor access */}
                                        </div>
                                    </div>
                                    <div className="unitCurrencyDiv">
                                        <div className="unitCurrency">$</div>
                                        <div className="unitAmount">
                                            {this.props.location.state.price.split('.')[0]}
                                        </div>
                                        <div className="unitRate">/mo</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moveAction: bindActionCreators(MoveAction, dispatch),
        reserveStorageAction: bindActionCreators(ReserveStorageAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moveState: state.common,
    reserveStorageState: state.move,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPlace);
