import React, { Component } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PolicyDetailsCard from '../../components/HomeInsurance/PolicyDetailsCard';

type initialProps = {
    onBack: any;
    data: any;
};

type initialState = {
    coverageData: any;
};

class CoverageDetails extends Component<initialProps, initialState> {
    state: initialState = {
        coverageData: [
            {
                coverageTitle: 'Deductible',
                amount: '3,500',
                details:
                    'Provides coverage to repair or rebuild your home. Note: You want enough coverage to cover the estimated cost to rebuild your home using today’s prices- (not the selling price or market value of your home).',
            },
            {
                coverageTitle: 'Deductible',
                amount: '3,500',
                details:
                    'Provides coverage to repair or rebuild your home. Note: You want enough coverage to cover the estimated cost to rebuild your home using today’s prices- (not the selling price or market value of your home).',
            },
        ],
    };

    //TODO: Remove and use utility function
    numberWithCommas = (x: any) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    render() {
        const { data } = this.props;
        const deductAmount =
            (data && data.data && data.data.coverage && data.data.coverage.deductible_all_other) +
            (data && data.data && data.data.coverage && data.data.coverage.deductible_theft) +
            (data && data.data && data.data.coverage && data.data.coverage.deductible_wind_hail);
        const deductible = this.numberWithCommas(deductAmount);
        const medicalPayment = this.numberWithCommas(
            data && data.data && data.data.coverage && data.data.coverage.medical_payments,
        );
        const DwellingCoverage = this.numberWithCommas(
            data && data.data && data.data.coverage && data.data.coverage.dwelling,
        );
        const personalCoverage = this.numberWithCommas(
            data && data.data && data.data.coverage && data.data.coverage.personal_property,
        );
        const liabilityCoverage = this.numberWithCommas(
            data && data.data && data.data.coverage && data.data.coverage.personal_liability,
        );
        const lossOfUse = this.numberWithCommas(
            data && data.data && data.data.coverage && data.data.coverage.loss_of_use,
        );
        const premiumMonthly = this.numberWithCommas(
            (parseFloat(data && data.data && data.data.quote && data.data.quote.term_premium) / 12)
                .toFixed(2)
                .toString(),
        );
        const premiumYearly = this.numberWithCommas(
            data && data.data && data.data.quote && data.data.quote.term_premium,
        );
        return (
            <div className="coverageMainDiv" style={{ overflow: 'auto', height: '100vh' }}>
                <div className="backIcon">
                    <ArrowBackIcon fontSize="large" onClick={this.props.onBack} />{' '}
                    <span style={{ marginLeft: '15px' }}>Back</span>
                </div>
                <div className="policyDetailsDiv">
                    <PolicyDetailsCard
                        width="100%"
                        border="none"
                        premiumMonthly={premiumMonthly}
                        premiumYearly={premiumYearly}
                        logo={data && data.image_url}
                    />
                </div>
                <div className="aboutDiv">
                    <div className="aboutHeadingInsurance">About {data && data.carrier_name}</div>
                    <div className="aboutContentInsurance">
                        Today we have assets of $4.6 billion and write $2.0 billion in premium in 33 states. And we
                        continue to rely on independent agents to market our products.Throughout our nearly 100-year
                        history, we’ve developed a well-respected heritage and built a strong foundation.
                    </div>
                </div>

                <div className="aboutDiv">
                    <div className="aboutHeadingInsurance">Coverages</div>
                    {/* {this.state.coverageData.map((item: any) => {
                        return ( */}
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Deductible</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{deductible}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            Provides coverage to repair or rebuild your home. Note: You want enough coverage to cover
                            the estimated cost to rebuild your home using today’s prices- (not the selling price or
                            market value of your home).
                        </div>
                    </div>
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Medical Payments</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{medicalPayment}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            This coverage can help cover medical expenses for guests that are injured while on your
                            property.
                        </div>
                    </div>
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Dwelling Coverage</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{DwellingCoverage}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            Provides coverage for structures not attached to yous home (shed, detached garage, etc.)
                        </div>
                    </div>
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Personal Property</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{personalCoverage}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            Provides coverage for personal items, such as furniture, clothes, sporting goods and
                            electronics.
                        </div>
                    </div>
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Liability Coverage</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{liabilityCoverage}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            This coverage can help protect you from claims made against you related to bodily injury or
                            property damage. It can also help cover legal costs to defend those claims.
                        </div>
                    </div>
                    <div className="coverage">
                        <div className="coverageTitleDiv">
                            <div className="coverageTitle">Loss of Use</div>
                            <div className="coverageAmountDiv">
                                <div className="coverageCurrency">
                                    {' '}
                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                </div>
                                <div className="coverageAmount">{lossOfUse}</div>
                            </div>
                        </div>
                        <div className="coverageDetailsDiv">
                            This coverage can help pay for additional living expenses if you have a covered loss and you
                            are not able to live in your home while it is being repaired.
                        </div>
                    </div>
                    {/* );
                    })} */}
                </div>
            </div>
        );
    }
}

export default CoverageDetails;
