import React from 'react';
import './HomeWarantyTableCard.scss';
import Checkbox from '@material-ui/core/Checkbox';
// import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';

type initialProps = {
    history?: any;
    confirmation?: boolean;
    invoice?: boolean;
    contract?: boolean;
    myself?: string;
    email?: string;
    header?: boolean;
    head1?: string;
    head2?: string;
    // head3?: string;
    id?: number;
    onConfirmationClick?: any;
    onInvoiceClick?: any;
};
const HomeWarrantyTableCard = (props: initialProps) => {
    return (
        <div>
            {props.header ? (
                <div className="tableMainClass">
                    <div className="tableRow1">{props.head1}</div>
                    <div className="tableRow2">{props.head2}</div>
                    {/* <div className="tableRow2">{props.head3}</div> */}
                    <div className="tableCheck">
                        <div className="tableRowSecondLast"></div>
                        <div className="tableRowLast"></div>
                    </div>
                </div>
            ) : (
                <div className="tableMainClass">
                    <div className="tableRow1">
                        <Checkbox
                            checked={props.confirmation}
                            onClick={() => props.onConfirmationClick(props.id)}
                            disabled={props.myself === 'Myself'}
                        />
                    </div>
                    <div className="tableRow2">
                        <Checkbox
                            checked={props.invoice}
                            onClick={() => props.onInvoiceClick(props.id)}
                            disabled={props.myself === 'Myself'}
                        />
                    </div>
                    {/* <div className="tableRow2">
                        <Checkbox checked={props.contract} />
                    </div> */}
                    <div className="tableCheck">
                        <div className="tableRowSecondLast">{props.myself}</div>
                        <div className="tableRowLast">{props.email}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomeWarrantyTableCard;
