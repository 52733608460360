import {
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_DETAILS_ERROR,
    GET_PRODUCT_DETAILS_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_SUCCESS,
    POST_ORDER_WARRANTY,
    POST_ORDER_WARRANTY_ERROR,
    POST_ORDER_WARRANTY_SUCCESS,
    GET_HOMEWARRANTY_DETAILS,
    GET_HOMEWARRANTY_DETAILS_SUCCESS,
    GET_HOMEWARRANTY_DETAILS_ERROR,
    GET_HOMEWARRANTY_PROVIDER_DETAILS,
    GET_HOMEWARRANTY_PROVIDER_DETAILS_ERROR,
    GET_HOMEWARRANTY_PROVIDER_DETAILS_SUCCESS,
    UPDATE_HOMEWARRANTY_DETAILS,
    UPDATE_HOMEWARRANTY_DETAILS_SUCCESS,
    UPDATE_HOMEWARRANTY_DETAILS_ERROR,
    POST_HOMEWARRANTY_DETAILS,
    POST_HOMEWARRANTY_DETAILS_SUCCESS,
    POST_HOMEWARRANTY_DETAILS_ERROR,
    GET_PLAN_TAX,
    GET_PLAN_TAX_SUCCESS,
    GET_PLAN_TAX_ERROR,
} from './actionTypes';

import { WarrantyActions, WarrantyState } from './types';

const initialState: WarrantyState = {
    postOrderWarranty: [],
    isPostOrderWarranty: false,
    error: '',
    getProductDetails: [],
    isGetProductDetails: false,
    getProducts: [],
    isGetProducts: false,
    getHomeWarrantyDetails: [],
    isGetHomeWarrantyDetails: false,
    getHomeWarrantyProviderDetails: [],
    isGetHomeWarrantyProviderDetails: false,
    updateHomeWarantyDetailsInfo: [],
    postHomeWarrantyDetails: [],
    isPostHomeWarrantyDetails: false,
    getPlanTax: [],
    isGetPlanTax: false,
};

export default (state = initialState, action: WarrantyActions) => {
    switch (action.type) {
        case POST_ORDER_WARRANTY:
            return {
                ...state,
                postOrderWarranty: [],
                isPostOrderWarranty: false,
                errprQuote: null,
            };
        case POST_ORDER_WARRANTY_SUCCESS:
            return {
                ...state,
                postOrderWarranty: action.payload.postOrderWarranty,
                isPostOrderWarranty: true,
                errprQuote: null,
            };
        case POST_ORDER_WARRANTY_ERROR:
            return {
                ...state,
                postOrderWarranty: [],
                isPostOrderWarranty: false,
                error: action.payload.error,
            };

        case GET_PRODUCT_DETAILS:
            return {
                ...state,
                getProductDetails: [],
                isGetProductDetails: false,
                error: null,
            };
        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                getProductDetails: action.payload.getProductDetails,
                isGetProductDetails: true,
                error: null,
            };
        case GET_PRODUCT_DETAILS_ERROR:
            return {
                ...state,
                getProductDetails: [],
                isGetProductDetails: false,
                error: action.payload.error,
            };

        case GET_PRODUCTS:
            return {
                ...state,
                getProducts: [],
                isGetProducts: false,
                error: null,
            };
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                getProducts: action.payload.getProducts,
                isGetProducts: true,
                error: null,
            };
        case GET_PRODUCTS_ERROR:
            return {
                ...state,
                getProducts: [],
                isGetProducts: false,
                error: action.payload.error,
            };

        case GET_HOMEWARRANTY_DETAILS:
            return {
                ...state,
                getHomeWarrantyDetails: [],
                isGetHomeWarrantyDetails: false,
                error: null,
            };
        case GET_HOMEWARRANTY_DETAILS_SUCCESS:
            return {
                ...state,
                getHomeWarrantyDetails: action.payload.getHomeWarrantyDetails,
                isGetHomeWarrantyDetails: true,
                error: null,
            };
        case GET_HOMEWARRANTY_DETAILS_ERROR:
            return {
                ...state,
                getHomeWarrantyDetails: [],
                isGetHomeWarrantyDetails: false,
                error: action.payload.error,
            };
        case GET_HOMEWARRANTY_PROVIDER_DETAILS:
            return {
                ...state,
                getHomeWarrantProviderDetails: [],
                isGetHomeWarrantProviderDetails: false,
                error: null,
            };
        case GET_HOMEWARRANTY_PROVIDER_DETAILS_SUCCESS:
            return {
                ...state,
                getHomeWarrantProviderDetails: action.payload.getHomeWarrantProviderDetails,
                isGetHomeWarrantProviderDetails: true,
                error: null,
            };
        case GET_HOMEWARRANTY_PROVIDER_DETAILS_ERROR:
            return {
                ...state,
                getHomeWarrantProviderDetails: [],
                isGetHomeWarrantProviderDetails: false,
                error: action.payload.error,
            };
        case UPDATE_HOMEWARRANTY_DETAILS:
            return {
                ...state,
                updateHomeWarantyDetailsInfo: [],
                error: null,
            };
        case UPDATE_HOMEWARRANTY_DETAILS_SUCCESS:
            return {
                ...state,
                updateHomeWarantyDetailsInfo: action.payload.updateHomeWarantyDetailsInfo,
                error: null,
            };
        case UPDATE_HOMEWARRANTY_DETAILS_ERROR:
            return {
                ...state,
                updateHomeWarantyDetailsInfo: [],
                error: action.payload.error,
            };
        case POST_HOMEWARRANTY_DETAILS:
            return {
                ...state,
                postHomeWarrantyDetails: [],
                isPostHomeWarrantyDetails: false,
                errprQuote: null,
            };
        case POST_HOMEWARRANTY_DETAILS_SUCCESS:
            return {
                ...state,
                postHomeWarrantyDetails: action.payload.postHomeWarrantyDetails,
                isPostHomeWarrantyDetails: true,
                errprQuote: null,
            };
        case POST_HOMEWARRANTY_DETAILS_ERROR:
            return {
                ...state,
                postHomeWarrantyDetails: [],
                isPostHomeWarrantyDetails: false,
                error: action.payload.error,
            };
        case GET_PLAN_TAX:
            return {
                ...state,
                getPlanTax: [],
                isGetPlanTax: false,
                errprQuote: null,
            };
        case GET_PLAN_TAX_SUCCESS:
            return {
                ...state,
                getPlanTax: action.payload.getPlanTax,
                isGetPlanTax: true,
                errprQuote: null,
            };
        case GET_PLAN_TAX_ERROR:
            return {
                ...state,
                getPlanTax: [],
                isGetPlanTax: false,
                error: action.payload.error,
            };

        default:
            return {
                ...state,
            };
    }
};
