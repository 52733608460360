import React, { Component } from 'react';
import './insurance.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InsuranceAction from '../../store/insurance/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as Action from '../../store/common/actions';
import Footer from './Footer';
import callIcon from '../../Assets/images/InternetAndCable/call_icon.svg'
import utilityBox from '../../Assets/images/InsuranceImages/utilityBox.svg'
import arrowDown from '../../Assets/images/InternetAndCable/arrow_down.svg'
import Button from '../../components/atoms/Button';
import iccalendar from '../../Assets/images/calendar.svg'
import Modal from '../../components/atoms/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
const moment = require('moment');

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    insuranceAction?: any;
    insuranceState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    isShowContent: boolean;
    isShowHideText: string;
    summaryData: any[];
    isHISummaryArrived: boolean;
    isHomeOwner: string | null;
    calendlyOpen: boolean;
    scheduleClicked: boolean;
    appointment_details: any;
    isDouglasElliman: boolean;
    home_insurance_scheduler_url: string;
    nylasModalClosed: boolean;
    isSubscriptionData: boolean;
    subscription_details: any[];
    concierge_phone: any;
    destination_full_address: any;
};

export class Summary extends Component<initialProps, initialState> {
    state = {
        isShowContent: false,
        isShowHideText: 'More Details',
        summaryData: [],
        isHISummaryArrived: false,
        isHomeOwner: 'false',
        calendlyOpen: false,
        scheduleClicked: false,
        isDouglasElliman: false,
        home_insurance_scheduler_url: '',
        nylasModalClosed: false,
        appointment_details: '',
        isSubscriptionData: false,
        subscription_details: [],
        concierge_phone: '',
        destination_full_address: ''
    };

    componentDidMount() {
        let moveId: any = localStorage.getItem('moveId');
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        if(!hash_code){
            this.props.commonAction.moveGet();
        }
        this.props.insuranceAction.insuranceAppointmentGet();
        this.props.insuranceAction.getHISummary({ moveId });
        this.props.dashboardAction.getSubscriptionDetails();
        this.setState({ isHomeOwner: localStorage.getItem('isHomeOwner') })
    }

    componentDidUpdate(prevProps: any) {
        const homeInsurance = this.props && this.props.insuranceState;
        const moveData = this.props && this.props.commonState;
        const subscriptionDetails = this.props && this.props.homeOwnerDashboardState;
        let prev: any = prevProps.commonState;
        let cur: any = this.props.commonState;
        if (
            homeInsurance.summary &&
            Object.keys(homeInsurance.summary).length > 1 &&
            homeInsurance.summary.status === 200
        ) {
            delete homeInsurance.summary.status
            this.state.summaryData = { ...homeInsurance.summary }
        }
        if (prevProps.insuranceState.isHISummaryArrived != homeInsurance.isHISummaryArrived ) this.setState({isHISummaryArrived: true})

        if (prev != cur && moveData.move[0]) {
            this.state.home_insurance_scheduler_url = moveData.move[0].home_insurance_scheduler_url
            this.setState({
                concierge_phone: moveData.move[0].assigned_concierge.phone,
                destination_full_address: moveData.move[0].destination_full_address
            })
        }
        if (this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0 &&
            prevProps.homeOwnerDashboardState.getSubscriptionDetails != this.props.homeOwnerDashboardState.getSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((element: any) => {
                if(element.service.name === 'Home Insurance'){
                    this.setState({ isSubscriptionData: true, subscription_details: subscriptionDetails.getSubscriptionDetails.results })
                }
            })
        }
    }

    onShowHideView = (isShow: boolean) => {
        let tempState: any = {
            isShowContent: isShow,
        }
        if (isShow) tempState.isShowHideText = 'Show Less'
        else tempState.isShowHideText = 'More Details'
        this.setState(tempState)
    }

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true
        });
    };

    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false, nylasModalClosed: true  });
    };

    render() {
        // let displayTime: any[] = [], formattedDate = "", time = "";
        let formattedDate = ""
        if (this.props.insuranceState.appointment.appointment_time){
            // time = this.props.insuranceState?.appointment?.appointment_time
            // displayTime = time.split(" ");
            // formattedDate = moment(this.props.insuranceState.appointment.appointment_time).format('MMMM Do, YYYY');
            formattedDate = moment(this.props.insuranceState.appointment.appointment_time).format('hh:mm A, MMMM Do, YYYY');
        }
        if (Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Scheduled") {
            // time = (Object.values(this.state.summaryData)[0]['appointment_time'])
            // displayTime = time.split(" ");
            // formattedDate = moment(Object.values(this.state.summaryData)[0]['appointment_date']).format('MMMM Do, YYYY');
            formattedDate = moment(Object.values(this.state.summaryData)[0]['appointment_date']).format('hh:mm A, MMMM Do, YYYY');
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        const currencyFormat = (num: string) => {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         }

         const breadList = [
            {
                name: 'Order summary Home Insurance',
                url: ''
            }
        ]
        const subscriptionBreadList = [
            {
                name: 'Subscription details for Insurance',
                url: ''
            }
        ]
        
        return (
            <div>
                {!this.state.isHISummaryArrived ? (<div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 99,
                        flexDirection: 'column'
                    }}>
                    <CircularProgress />
                    <div style={{marginTop: '15px'}}> Working on it.Please Wait... </div>
                </div>) :
                    Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Warm Transfer" ? (
                        <>
                            <div className="main-head">
                                <h2 className="heading">Insurance</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                            </div>
                            <p className='warmTransferClass'>
                                We connected you with our insurance specialist.They will be able to offer you multiple quotes from different providers.
                            </p>
                        </>
                    ) : Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Scheduled" || this.props.insuranceState?.appointment?.appointment_time && !this.state.isSubscriptionData ? (
                        <>
                            <div className="main-head">
                                <h2 className="heading">Insurance</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>
                            <div className="summarydetailbox">
                                <div className="summarydetailbox_head">
                                    <h2>Call Scheduled</h2>
                                    <p>Our concierge team will call you to complete your order.</p>
                                </div>
                                <div className="summarydetailbox_body">
                                    <div className="borderbox">
                                        <div className="borderbox_top">
                                            <h3>Call schedule details</h3>
                                            <button className="topbtn" onClick={() => this.calendlyHandleReschedule()}>Reschedule</button>
                                        </div>
                                        <p className="scheduled_datetime">
                                            <img src={iccalendar} alt="calender" />
                                            <b>{`${formattedDate}`}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Sold" ? (
                        <>
                            <div className="greybg">
                                <div className="main-head">
                                    <h2 className="heading">Insurance</h2>
                                    <div className='mb-4'>
                                        <BreadCrumb breadList={subscriptionBreadList} />
                                    </div>
                                </div>
                                <div className='summaryHeader'>
                                    <h3>Subscription Details For Insurance</h3>
                                    <p>If you need to update or manage your plan, please<br></br>reach out to your service provider's customer service.</p>
                                </div>
                            </div>
                            {Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData).map((item, index) => {
                                return (
                                    <>
                                        <div className='summaryCards summarybox summarybox2'>
                                            {index == 0 ? (
                                                <div className="summarybox_head">
                                                    <h5 className='summaryHeader'>Your previous policy</h5>
                                                    {/* <button className="summarybox_headbtn"
                                                    onClick={() => {
                                                        this.props.history.push('/dashboard/home-insurance/edit');
                                                    }}
                                                    >Edit Current Plan</button> */}
                                                </div>
                                            ) : ''}
                                            <div className="summarybox_body">
                                                <div className='summaryContent'>
                                                    <div className='summaryContent_l'>
                                                        <div className="img_wrapper">
                                                            <img src={item['plan_detail']['logo']} alt="plan detail logo" />
                                                            <p className="img_name">{item['plan_detail']['provider_name']}</p>
                                                        </div>
                                                    </div>
                                                    <div className='summaryContent_r'>
                                                        <div className="plan_wrapper">
                                                            <p>Monthly Premium</p>
                                                            <p className="plan_detail">
                                                                <span>$</span><span className="palnvalue">{item['plan_detail']['price']}
                                                                    <span className="req">
                                                                        *
                                                                    </span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="call_bar">
                                                    <p>Have any customer service related issue? Call</p>
                                                    <span>
                                                        AT&T Customer Service
                                                        <a href={`tel:${item['concierge_phone_number']}`}>
                                                            <span className="call_icon">
                                                                <img src={callIcon} alt="phone" />
                                                            </span>
                                                            {item['concierge_phone_number']}</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={`summaryfooter ${this.state.isShowContent ? 'showcontent' : ''}`}>
                                                <div className="summary_hidden_content">
                                                    <div className="content_row">
                                                        <div className="content_col33">
                                                            <p className="lab_val">Policy Expiry Date</p>
                                                            {/* <p>{item['install_date']}</p> */}
                                                        </div>
                                                        <div className="content_col33">
                                                            <p className="lab_val">Policy Number</p>
                                                            {/* <p>{item['account_no']}</p> */}
                                                        </div>
                                                        <div className="content_col33">
                                                            <p className="lab_val">Address</p>
                                                            <p>{item['destination_address']}</p>
                                                        </div>
                                                        <div className="content_col33">
                                                            <p className="lab_val">Deductible</p>
                                                            {/* <p>{item['plan_detail']['provider_name']}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="readmorebtn"
                                                    onClick={() =>
                                                        this.onShowHideView(!this.state.isShowContent)
                                                    }
                                                >{this.state.isShowHideText}
                                                    <img src={arrowDown} alt="arrow icon" />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                            {/* <div className="utility_box">
                <div className="utility_boxl">
                    <div>
                        <img src={insuranceimg} />
                    </div>
                </div>
                <div className="utility_boxr">
                    <div>
                        <h3 className="utility_title">With Home Security You Can Save Up To 15% Off Your Home Insurance</h3>
                        <p>Get your home security certificate to see home much you can be saving on your home insurance.</p>
                        <button className="gotoutility_btn">Get Certificate</button>
                    </div>
                </div>
            </div> */}

                            <div className="bittom_btn_div havetwobtns">
                                {/* <button className="borderbtn">Explore New Plans</button> */}
                                {/* <button className="borderbtn">Back To Dashboard</button> */}
                                <Button
                                    onClick={() => {
                                        History.push('/dashboardhomeowner');
                                    }}
                                >
                                    Back To Dashboard
                                </Button>
                            </div>
                            <Footer />
                        </>
                    ) : this.state.isSubscriptionData && this.state.subscription_details && this.state.subscription_details.length > 0 ? (
                        <>
                        <div className="greybg">
                            <div className="main-head">
                                <h2 className="heading">Home Insurance</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>
                            <div className='summaryHeader'>
                                <h3>Subscription Details Home Insurance</h3>
                                <p>If you need to update or manage your plan, <br /> please reach out to your service provider's customer service.</p>
                            </div>
                        </div>
                        {this.state.subscription_details.map((element: any, i: number) => {
                            if(element['service']['name'] === 'Home Insurance'){
                                return(
                                    <div key={i} className='summaryCards summarybox summarybox2'>
                                            <div className="summarybox_head">
                                                <h5 className='summaryHeader'>Plan Details</h5>
                                                
                                                <button className="summarybox_headbtn"
                                                    onClick={() => {
                                                        this.props.history.push({
                                                            pathname: '/dashboard/home-insurance/edit',
                                                            state: { detail: this.state.subscription_details ,from: 'summary' }
                                                        })
                                                    }}
                                                >Edit Current Plan</button>
                                            </div>
                                        <div className="summarybox_body">
                                            <div className='summaryContent'>
                                                <div className='summaryContent_l'>
                                                    <div className="img_wrapper">
                                                        {/* <img src={element['provider']} alt="plan detail logo" /> */}
                                                        {element['provider_logo'] ? (<img src={element['provider_logo']} alt="plan detail logo" />) : ""}
                                                        <p className="img_name">{element['provider']}</p>
                                                    </div>
                                                </div>
                                                <div className='summaryContent_r'>
                                                    <p className="lab_val">Monthly Plan</p>
                                                    <p className="plan_detail">
                                                        <span>$</span><span className="palnvalue">{element['price'] ? currencyFormat(element['price']) : '-'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="call_bar hw_summary">
                                                <p>Have any customer service related issue? Call</p>
                                                <span>
                                                    Dedicated Concierge
                                                    <a href={`tel:${this.state.concierge_phone}`}>
                                                        <span className="call_icon">
                                                            <img src={callIcon} alt="phone" />
                                                        </span>
                                                        {this.state.concierge_phone}</a>
                                                </span>
                                            </div>
                                        </div>

                                        <div className={`summaryfooter ${this.state.isShowContent   ? 'showcontent' : ''}`}>
                                            <div className="summary_hidden_content">
                                                <div className="content_row">
                                                    {/* <div className="content_col33">
                                                        <p className="lab_val">Installation Date</p>
                                                        <p>{moment(element['install_date']).format('MMMM YYYY')}</p>
                                                    </div> */}
                                                    <div className="content_col33">
                                                        <p className="lab_val">Account Number</p>
                                                        <p><b>{element['account_number']}</b></p>
                                                    </div>
                                                    <div className="content_col33">
                                                        <p className="lab_val">Address</p>
                                                        <p><b>{this.state.destination_full_address}</b></p>
                                                    </div>
                                                    <div className="content_col33">
                                                        <p className="lab_val">Plan Details</p>
                                                        <p><b>{element['service']['name']}</b></p>
                                                    </div>
                                                    <div className='content_col33'>
                                                        <p className='lab_val'>Deductible</p>
                                                        <p><b>{element['deductible'] ? currencyFormat(element['deductible']) : ''}</b></p>
                                                    </div>

                                                </div>
                                            </div>
                                            <button className="readmorebtn"
                                                onClick={() =>
                                                    this.onShowHideView(!this.state.isShowContent)
                                                }
                                            >{this.state.isShowHideText}
                                                <img src={arrowDown} alt="arrow icon" />
                                            </button>
                                        </div>
                                    </div>

                                     
                                )
                            }
                        })}
                        <div className="utility_box">
                            <div className="utility_boxl">
                                <div>
                                    <img src={utilityBox} alt="" />
                                </div>
                            </div>
                            <div className="utility_boxr">
                                <div>
                                    <h3 className="utility_title">Have A Home Security System?</h3>
                                    <p>With a home security system, you can save up to 15% on your home insurance.</p>
                                    <Button className="gotoutility_btn"
                                        backgroundColor="#fff"
                                        color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug && slug.primary_color
                                            : '#273E59'
                                        }
                                        border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug && slug.primary_color
                                            : '#273E59'
                                        }`}
                                        borderRadius="4px"
                                    onClick={() => {
                                        this.props.history.push('/dashboard/home-security');
                                    }}
                                    >Learn More</Button>
                                </div>
                            </div>
                        </div>
                        <div className="bittom_btn_div havetwobtns">
                            {/* <button className="borderbtn">Explore New Plans</button> */}
                            <Button
                                onClick={() => {
                                    this.props.history.push('/dashboardhomeowner');
                                }}
                            >
                                Back To Dashboard
                            </Button>
                        </div>
                        <Footer />
                    </>
                    ) : ""
                }
                
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="scheduleCallMoving"
                    >
                        <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>

                        {(this.state.scheduleClicked) ? (
                            <div className="iFrame">
                                <iframe
                                    src={this.state.home_insurance_scheduler_url
                                    }
                                    allow="encrypted-media"
                                    className="iframe"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
            </div >
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({ 
    commonState: state.common, 
    insuranceState: state.insurance,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
