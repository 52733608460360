import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={71}
            height={71}
            viewBox="0 0 71 71"
        >
            <defs>
                <style>{`.hsmedicalprefix__a{fill:${
                    slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                }}`}</style>
            </defs>
            <path
                className="hsmedicalprefix__a"
                d="M19.173 41.042h10.485v10.783a1.65 1.65 0 001.648 1.648h8.089a1.65 1.65 0 001.647-1.648V41.042h10.483a1.65 1.65 0 001.648-1.648v-7.939a1.65 1.65 0 00-1.648-1.647H41.042V19.473a1.65 1.65 0 00-1.648-1.648h-8.089a1.65 1.65 0 00-1.648 1.648v10.335H19.173a1.65 1.65 0 00-1.648 1.648v7.939a1.65 1.65 0 001.648 1.647zm.449-9.137h10.485a1.65 1.65 0 001.648-1.648V19.925h7.19V30.26a1.65 1.65 0 001.648 1.648h10.485v7.04H40.593a1.65 1.65 0 00-1.648 1.648v10.785h-7.19V40.593a1.65 1.65 0 00-1.648-1.648H19.625z"
            />
            <path
                className="hsmedicalprefix__a"
                d="M71 8.288A8.288 8.288 0 0062.712 0H8.288A8.288 8.288 0 000 8.288v54.424A8.288 8.288 0 008.288 71h54.424A8.288 8.288 0 0071 62.712zm-2.1 54.424a6.191 6.191 0 01-6.188 6.188H8.288A6.191 6.191 0 012.1 62.712V8.288A6.191 6.191 0 018.288 2.1h54.424A6.191 6.191 0 0168.9 8.288z"
            />
            <path
                className="hsmedicalprefix__a"
                d="M15.178 64.11h40.194a8.3 8.3 0 008.288-8.288V15.628a8.3 8.3 0 00-8.288-8.288H15.178a8.3 8.3 0 00-8.288 8.288v40.193a8.3 8.3 0 008.288 8.289zM8.99 15.628a6.2 6.2 0 016.188-6.188h40.194a6.2 6.2 0 016.191 6.191v40.193a6.2 6.2 0 01-6.191 6.191H15.178a6.2 6.2 0 01-6.191-6.191z"
            />
        </svg>
    );
}

export default SvgComponent;
