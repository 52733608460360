import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={71}
            height={71}
            viewBox="0 0 71 71"
        >
            <defs>
                <style>
                    {`.filmStepprefix__a{fill:${
                        props.fromlanding && props.checked
                            ? '#30AB86'
                            : slug && slug.primary_color && props.checked
                            ? slug.primary_color
                            : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="filmStepprefix__a"
                d="M.986 71h69.028a.986.986 0 00.986-.986V.986A.986.986 0 0070.014 0H.986A.986.986 0 000 .986v69.028A.986.986 0 00.986 71zm.986-13.806h10.453v11.834H1.972zm56.6-13.806h10.456v11.834H58.575zm0-1.972V29.583h10.456v11.834zm0-13.806V15.778h10.456v11.833zM56.6 69.028H14.4V1.972h42.2zM12.425 27.611H1.972V15.778h10.453zm0 1.972v11.834H1.972V29.583zm0 13.806v11.833H1.972V43.389zm46.15 25.639V57.194h10.453v11.834zm10.453-55.222H58.575V1.972h10.453zM12.425 1.972v11.834H1.972V1.972zm0 0"
            />
            <path
                className="filmStepprefix__a"
                d="M35.505 50.605a15.106 15.106 0 10-15.11-15.1 15.105 15.105 0 0015.11 15.1zm0-28.239a13.133 13.133 0 11-13.138 13.139 13.133 13.133 0 0113.138-13.138zm0 0"
            />
            <path
                className="filmStepprefix__a"
                d="M32.238 41.96a.986.986 0 001.012-.048l8.415-5.591a.986.986 0 000-1.644l-8.415-5.591a.986.986 0 00-1.532.822V41.09a.985.985 0 00.52.87zm1.452-10.212l5.647 3.752-5.647 3.752zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
