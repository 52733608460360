import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    signupFailure,
    signupSuccess,
    signupUpdateFailure,
    signupUpdateSuccess,
    landingMovePostFailure,
    landingMovePostSuccess,
    ghostLoginFailure,
    ghostLoginSuccess,
    landingMovePostForSecurityFailure,
    landingMovePostForSecuritySuccess,
    haveAPreapprovalFailure,
    haveAPreapprovalSuccess,
    cretaeContactServiceProvidersFailure,
    cretaeContactServiceProvidersSuccess,
    getAPreapprovalFailure,
    getAPreapprovalSuccess,
} from './actions';
import {
    SIGNUP,
    SIGNUP_UPDATE,
    LANDING_MOVE_POST,
    POST_GHOST_LOGIN,
    HAVE_A_PREAPPROVAL,
    LANDING_MOVE_POST_FOR_SECURITY,
    CREATE_CONATCT_SERVICE_PROVIDERS,
    GET_A_PREAPPROVAL,
} from './actionTypes';
import apiJunction from '../../../utils/api';
import history from '../../../routes/History';
import { Store } from 'react-notifications-component';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
    // history?:any;
}

function* signup(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/move-api/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            localStorage.setItem('token', response.data.user_ref.token);
            yield put(
                signupSuccess({
                    signup: response.data,
                }),
            );

            if (response.data.user_ref.token && localStorage.getItem('token')) {
                localStorage.setItem('moveId', response.data && response.data.id);
                localStorage.setItem('moveKey',response.data && response.data.move_key);
                
                if (response.data.status && response.data.status === 'Home Owner') {
                    localStorage.setItem('isHomeOwner', 'true');
                    history.push({
                        // pathname: '/dashboardhomeowner',
                        pathname: '/signUp/HomeOwnerWelcome',
                        state: {
                            fromSignUp: 'fromSignUp',
                        },
                    });
                } else {
                    history.push({
                        // pathname: '/dashboard',
                        pathname: '/signUp/welcome',
                        state: {
                            fromSignUp: 'fromSignUp',
                        },
                    });
                }
            }
        }  

        else {
         yield put(
            signupFailure({
                error: response?.data?.user_ref?.non_field_errors[0],
            }),
          );
            Store.addNotification({
                title: 'Warning',
                message: "User already exists with this email" ,
                type: 'warning',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e: any) {
        yield put(
            signupFailure({
                error: e.message,
            }),
        );
        // console.log("Response Data",response)
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occurred!',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* signupUpdate(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'put',
            url: `api/move/move-api/${action.payload.moveId}/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            // localStorage.setItem('token', response.data.user_ref.token);

            yield put(
                signupUpdateSuccess({
                    signupUpdate: response.data,
                }),
            );
            if (response.data.user_ref.token && localStorage.getItem('token')) {
                if (response.data.status && response.data.status === 'Home Owner') {
                    localStorage.setItem('isHomeOwner', 'true');
                    if(!action.payload.noRedirect) {
                        history.push({
                            // pathname: '/dashboardhomeowner',
                            pathname: '/signUp/HomeOwnerWelcome',
                            state: {
                                fromSignUp: 'fromSignUp',
                            },
                        });
                    }
                } else {
                    if(!action.payload.noRedirect) {
                        history.push({
                            // pathname: '/dashboard',
                            pathname: '/signUp/welcome',
                            state: {
                                fromSignUp: 'fromSignUp',
                            },
                        });
                    }
                }
            }

            // if (!action.payload.phoneUpdate) {
            //     history.push({
            //         pathname: '/dashboard',
            //     });
            // }
        } else {
            Store.addNotification({
                title: 'Error',
                message: response.data && response.data.message ? response.data.message : 'Error Occured',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(
            signupUpdateFailure({
                error: e.message,
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occured',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* landingMovePost(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/landing-moves/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            // console.log("usercreated", response.data)
            localStorage.setItem('token', response.data.user_ref.token);
            localStorage.setItem('moveId', response.data.id);
            localStorage.setItem('moveKey',response.data && response.data.move_key);
            yield put(
                landingMovePostSuccess({
                    landingMovePost: response.data,
                }),
            );

            // if (response.data.user_ref.token && localStorage.getItem('token')) {
            //     history.push({
            //         pathname: '/dashboard',
            //         state: {
            //             fromSignUp: 'fromSignUp',
            //         },
            //     });
            // }
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e: any) {
        yield put(
            landingMovePostFailure({
                error: e.message,
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occurred!',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* landingMovePostForSecurity(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/landing-moves/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            // console.log("usercreated", response.data)
            localStorage.setItem('token', response.data.user_ref.token);
            localStorage.setItem('moveId', response.data.id);
            localStorage.setItem('moveKey',response.data && response.data.move_key);
            yield put(
                landingMovePostForSecuritySuccess({
                    landingMovePost: response.data,
                }),
            );

            // if (response.data.user_ref.token && localStorage.getItem('token')) {
            //     history.push({
            //         pathname: '/dashboard',
            //         state: {
            //             fromSignUp: 'fromSignUp',
            //         },
            //     });
            // }
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e: any) {
        yield put(
            landingMovePostForSecurityFailure({
                error: e.message,
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occurred!',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

 function* ghostSignup(action: any) {


    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/accounts/ghost-login/`,
            body: action.payload,
        });
        
  

        if (response.data && response.data.has_signed_up == true && response.status === 200) {
              localStorage.setItem('token', response.data.token);
             yield put(
                ghostLoginSuccess({
                    ghostSignUp: response.data,
                }),
            );

            if (response.data.token && localStorage.getItem('token')) {
                history.push({
                    pathname: '/dashboard',
                });
            }
        } else {
            Store.addNotification({
                title: 'Not a valid hash code.Please sign up to continue!',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            setTimeout(() => {
                history.push({
                    pathname: `/signUp?hash_code=${action.payload.user_id}`,
                });
            }, 2000);
        }
    } catch (e: any) {
        yield put(
            ghostLoginFailure({
                error: (e as Error).message,
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occurred!',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* haveAPreapproval(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-pre-approval/`,
            body: action.payload,
        });

        if (response) {
            //   localStorage.setItem('token', response.data.token);
             yield put(
                haveAPreapprovalSuccess({
                    haveAPreapproval: response.data,
                }),
            );
        }

    } catch (e) {
        yield put(
            haveAPreapprovalFailure({
                error: (e as Error).message,
            }),
        );
        // history.push('/server-error');
    }
}

function* getAPreapproval() { //action: any
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-pre-approval/pre-approval-request/`,
            // body: action.payload,
        });

        if (response) {
            //   localStorage.setItem('token', response.data.token);
             yield put(
                getAPreapprovalSuccess({
                    getAPreapproval: response.data,
                }),
            );
        }

    } catch (e) {
        yield put(
            getAPreapprovalFailure({
                error: (e as Error).message,
            }),
        );
        // history.push('/server-error');
    }
}

function* cretaeContactServiceProviders(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/service-agents/create-multiple-contact-service-providers/`,
            body: action.payload,
        });
        
        if (response) {
            //   localStorage.setItem('token', response.data.token);
             yield put(
                cretaeContactServiceProvidersSuccess({
                    cretaeContactServiceProviders: response.data,
                }),
            );
        }

    } catch (e) {
        yield put(
            cretaeContactServiceProvidersFailure({
                error: (e as Error).message,
            }),
        );
        // history.push('/server-error');
    }
}

function* signupSaga() {
    yield all([takeLatest(SIGNUP, signup)]);
    yield all([takeLatest(SIGNUP_UPDATE, signupUpdate)]);
    yield all([takeLatest(LANDING_MOVE_POST, landingMovePost)]);
    yield all([takeLatest(LANDING_MOVE_POST_FOR_SECURITY, landingMovePostForSecurity)]);
    yield all([takeLatest(POST_GHOST_LOGIN, ghostSignup)]);
    yield all([takeLatest( HAVE_A_PREAPPROVAL, haveAPreapproval)])
    yield all([takeLatest(CREATE_CONATCT_SERVICE_PROVIDERS, cretaeContactServiceProviders)])
    yield all([takeLatest( GET_A_PREAPPROVAL, getAPreapproval)])
}

export default signupSaga;
