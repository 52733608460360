import React,{useState} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import '../../styles/landingpageHeader.css'

interface faqProps {
    question?:string; 
    answer?:string; 
    
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    // heading: {
    //   fontSize: theme.typography.pxToRem(15),
    //   fontWeight: theme.typography.fontWeightRegular,
    // },
    expanded: {
        fontSize: theme.typography.pxToRem(15),
        color:"#0066F5",
        fontWeight:"bold"
    },
  }),
);

const SimpleAccordion=(props:faqProps)=> {
  const classes = useStyles();

  const [expand,setexpand]=useState(false)


  return (
    <div className={classes.root}>
      <Accordion onChange={()=>{
          setexpand(!expand)}
          }>
        <AccordionSummary
          expandIcon={expand===true ? <RemoveIcon style={{color: "#0066F5"}} /> : <AddIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={expand===true ? classes.expanded : ""}>{props.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordianAnswer">
          {props.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}


export default SimpleAccordion;