import React from 'react';
import './Main.scss';
function SliderHomeEquity({ slider_blue_part_value, heloc_percentage }: any) {
    return (
        <>
            <div className="sliderParent">
                <div>
                    <div className="sliderChild" style={{ width: `${Math.round(heloc_percentage)}%` }}>HELOC ${slider_blue_part_value}</div>
                    <div className="sliderHomeEquity"></div>
                </div>
            </div>
        </>
    );
}

export default SliderHomeEquity;
