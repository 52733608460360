import React from 'react';
 import './BlogCard.scss';
 import sandglass from '../../../Assets/images/HomeValuation/sandglass.svg';
 interface bannerProps {
     width?: string;
     height?: string;
     borderRadius?: string;
     border?: any;
     bannerMargin?: string;
     bannerPadding?: string;
     onClick?: any;
     className?: string;
     imgWidth?: string;
     imgHeight?: string;
     bannerImage?: any;
     imgName?: any;
     bannerTitle?: string;
     bannerContent?: string;
     boxShadow?: any;
     imgClassname?: string;
 }

 const EquityBanner = (props: bannerProps) => {
     return (
         <div
             className="EquityBanner"
             onClick={props.onClick}
             // className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-height-width`}
         >
             <div
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     color: '#6B6C6F',
                     fontSize: '12px',
                     fontWeight: 'bold',
                     margin: '0px 10px',
                 }}
             >
                 <img
                     width={props.imgWidth ? props.imgWidth : '80px'}
                     height={props.imgHeight ? props.imgHeight : '80px'}
                     src={sandglass}
                     style={{
                         marginRight: 'auto',
                         marginBottom: '5px',
                     }}
                     alt=""
                     className={props.imgClassname ? props.imgClassname : ''}
                 />
                 <span style={{ textAlign: 'center' }}>{props.imgName}</span>
             </div>
             <div className="equityBannerTitle">
                 {props.bannerTitle}
                 <div className="bannerDescription">{props.bannerContent}</div>
             </div>
         </div>
     );
 };

export default EquityBanner;
