import React, { useState, useEffect, useRef, Fragment } from 'react';

import './AffordabilityCard.scss';
// import '../../../styles/homeCard.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AffordabalityCardData from '../../../../pages/HomeValuation/AffordabalityCardData';

interface cardProps {
    handleCardChange: any;
    defaultSelectedCard: any;
}

const AffordabilityCardMobile = (props: cardProps) => {
    const { handleCardChange, defaultSelectedCard } = props;
    const [showSelection, setShowSelection] = useState(false);
    const [selectedCard, setSelectedCard] = useState({
        cardImage: '',
        cardTitle: '',
        cardContent: '',
    });
    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: { target: any }) {
          if (menuRef.current) {
            const x = menuRef.current as unknown as any;
            if (!x.contains(event.target)) {
              setTimeout(() => {
                setShowSelection(false);
              }, 200);
            }
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [menuRef]);

    useEffect(() => {
        const setDefaultCard = () => {
            setSelectedCard({
                cardImage: defaultSelectedCard.image,
                cardTitle: defaultSelectedCard.title,
                cardContent: defaultSelectedCard.subTitle,
            });
        };

        defaultSelectedCard && setDefaultCard();
        return () => {
            setSelectedCard({
                cardImage: '',
                cardTitle: '',
                cardContent: '',
            });
        };
    }, [defaultSelectedCard]);

    const handleClick = () => {
        setShowSelection(!showSelection);
    };

    const handleSelect = (cardData: any, index: number) => {
        setSelectedCard({
            cardImage: cardData.image,
            cardTitle: cardData.title,
            cardContent: cardData.subTitle,
        });

        handleCardChange(index);
        handleClick();
    };

    return (
        <div className='calculatorCardContainer'>
            <div className="AffordabilityCardMobileMain" onClick={handleClick}>
                <div>
                    <img src={selectedCard.cardImage} alt="" />
                </div>
                <div style={{ flex: 0.95 }} className="text">
                    {selectedCard.cardTitle}
                    <span style={{ fontWeight: 'normal' }}>{selectedCard.cardContent}</span>
                </div>
                <div style={{ flex: 0.01 }} className={`arrowIconCover ${showSelection && 'activeArrow'}`}>
                    <KeyboardArrowDownIcon />
                </div>
            </div>
            {showSelection && (
                <ul ref={menuRef}>
                    {AffordabalityCardData.map((card: any, i: number) => (
                       <Fragment key={i}>
                        {selectedCard.cardTitle !== card.title && <li key={i} onClick={() => handleSelect(card, i)}>
                            <div className="AffordabilityCardMobileMain">
                                <div>
                                    <img src={card.imageNotSelected} alt="" />
                                </div>
                                <div style={{ flex: 0.95 }} className="text">
                                    {card.title}
                                    <span style={{ fontWeight: 'normal' }}>{card.subTitle}</span>
                                </div>
                            </div>
                        </li>}
                       </Fragment>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AffordabilityCardMobile;
