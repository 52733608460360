import React from 'react';
import './SavingCalculator.scss';
import Calender from '../../../../Assets/images/HomeValuation/calendar.png';
import Add from '../../../../Assets/images/HomeValuation/add.svg';
import CongratsQuestion from './CongratsQuestion';
import Skeleton from 'react-loading-skeleton';
import CommonService from '../../../../utils/common-functions';

interface CongratsProps {
    fewerPayments?: string;
    tillDate?: string;
    saving: string | number;
    debit: string | number;
    serviceAgentData?: any;
    onAgentContact: any;
    isPostingSavingCalcData?: boolean;
    serviceProviders?:any;
    showNoDataView?:boolean;
    analyticsData?: any;
}

const { formatCurrency } = CommonService;

const Congrats = (props: CongratsProps) => {
    return (
        <section className={`mainSavingCalculator ${props.showNoDataView && 'blurBackground'}`}>
            <span className="heading">Congratulations! You will save:</span>

            <div>
                {!props.isPostingSavingCalcData ? (<span className="saving">
                    <span className="saving-heading">TOTAL SAVINGS</span>
                    <span style={{ position: 'absolute', marginTop: '6px' }}>
                        <img src={Add} alt="" />
                    </span>{' '}
                    <span style={{ marginLeft: "12px" }} className="text" data-cy="total-savings">
                        <span className="text-dollar">$</span>
                        {formatCurrency({ value: Number(props.saving) })}
                    </span>
                </span>) : <Skeleton width={180} height={40} />}
                {!props.isPostingSavingCalcData ? (<span className="saving">
                    <span className="saving-heading">PAYOFF LOAN EARLY</span>
                    <span style={{ position: 'absolute', marginTop: '6px' }}>
                        <img src={Calender} alt="" />
                    </span>{' '}
                    <span className="text" data-cy="morgage-time">{props.debit}</span>
                    <span className="subtext">Fewer payments ({props.fewerPayments}) </span>
                    <span className="subtext">Your loan will end in {props.tillDate}</span>
                </span>) : <Skeleton width={180} height={50} />}
            </div>
            <CongratsQuestion
                agentImage={props.serviceAgentData?.photo_url}
                serviceAgentData={props.serviceAgentData}
                onAgentContact={props.onAgentContact}
                serviceProviders={props.serviceProviders || null}
                showNoDataView={props.showNoDataView}
                analyticsData={props.analyticsData}
            />
        </section>
    );
};

export default Congrats;
