import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import './BetterExperience.scss';

interface BetterExperienceProps {
    open: boolean;
    setExperince: any;
}
const BetterExperience = (props: BetterExperienceProps) => {
    return (
        <>
            {props.open ? (
                <div className="BetterExperienceMain">
                    <div className="BetterExperienceTitle">
                        {/* <span className="BetterExperienceTitletext">Log into Desktop for better Experience</span> */}
                        <span className="BetterExperienceTitlesubtext">
                        Multiple sources are used to provide different home valuation data on this page. For more information on the data sources, please log into your account on a desktop computer.
                        </span>
                    </div>
                    <div
                        onClick={() => {
                            props.setExperince(false);
                        }}
                    >
                        <CloseIcon />
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default BetterExperience;
