import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import '../../styles/howItWorksLanding.scss';
import pdfIcon from '../../Assets/images/documentry/pdfIcon.png';
import csvIcon from '../../Assets/images/documentry/csvIcon.png';
import wordIcon from '../../Assets/images/documentry/wordIcon.png';
import docIcon from '../../Assets/images/documentry/documentIcon.jpg';
import moment from 'moment';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
}));

export default function TrustedProsCategoryTabs(props: any) {
    const classes = useStyles();

    // let tabvalue: number = props.selectedFolder ? props.selectedFolder : 0;

    const [value, setValue] = React.useState(props.selectedFolder || 0);
    const handleChange = (newValue: any) => {
        setValue(parseInt(newValue.currentTarget.id.split('-')[3]));
    };

    let documentData: any = [];
    props.tabsHeading.map((data: any) => {
        props.tabsContentData.map((item: any) => {
            if (item.folders_id === data.id && value === data.tabId) {
                documentData.push(item);
            }
            return documentData;
        });
    });

    return (
        <div className={`${classes.root} documentryPros`}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="off"
                aria-label="scrollable prevent tabs example"
                TabIndicatorProps={{ style: { background: '#0066f5' } }}
            >
                {props.tabsHeading
                    ? props.tabsHeading.map((heading: any, i: number) => {
                          return <Tab key={i} className="check" label={heading.name} {...a11yProps(heading.tabId)} />;
                      })
                    : null}
            </Tabs>
            {props.tabsHeading
                ? props.tabsHeading.map((heading: any, i: number) => {
                      return (
                          <TabPanel key={i} value={value} index={heading.tabId}>
                              <div className="scroll-cardsDocumentry" id="container">
                                  {documentData.map((item: any, i: number) => {
                                      let imageIcon: any = '';
                                      let isVideo: boolean = false;
                                      let videoType: string = '';
                                      if (item.document) {
                                          if (item.document && item.document.includes('pdf')) {
                                              imageIcon = pdfIcon;
                                          } else if (item.document && item.document.includes('csv')) {
                                              imageIcon = csvIcon;
                                          } else if (
                                              item.document &&
                                              (item.document.includes('.doc') ||
                                                  item.document.includes('docx') ||
                                                  item.document.includes('.docm') ||
                                                  item.document.includes('dot') ||
                                                  item.document.includes('dotm') ||
                                                  item.document.includes('dotx'))
                                          ) {
                                              imageIcon = wordIcon;
                                          } else if (
                                              item.document &&
                                              (item.document.includes('gif') ||
                                                  item.document.includes('GIF') ||
                                                  item.document.includes('jpg') ||
                                                  item.document.includes('JPG') ||
                                                  item.document.includes('jpeg') ||
                                                  item.document.includes('JPEG') ||
                                                  item.document.includes('JFIF') ||
                                                  item.document.includes('jfif') ||
                                                  item.document.includes('pjpeg') ||
                                                  item.document.includes('PJPEG') ||
                                                  item.document.includes('pjp') ||
                                                  item.document.includes('PJP') ||
                                                  item.document.includes('png') ||
                                                  item.document.includes('PNG') ||
                                                  item.document.includes('svg') ||
                                                  item.document.includes('SVG') ||
                                                  item.document.includes('WEBP') ||
                                                  item.document.includes('webp'))
                                          ) {
                                              imageIcon = item.document;
                                          } else if (
                                              item &&
                                              item.document &&
                                              (item.document.includes('mp4') || item.document.includes('MP4'))
                                          ) {
                                              isVideo = true;
                                              imageIcon = item && item.document;
                                              videoType = 'mp4';
                                          } else if (
                                              item &&
                                              item.document &&
                                              (item.document.includes('mov') || item.document.includes('MOV'))
                                          ) {
                                              isVideo = true;
                                              imageIcon = item && item.document;
                                              videoType = 'mov';
                                          } else if (
                                              item &&
                                              item.document &&
                                              (item.document.includes('wmv') || item.document.includes('WMV'))
                                          ) {
                                              isVideo = true;
                                              imageIcon = item && item.document;
                                              videoType = 'wmv';
                                          } else if (
                                              item &&
                                              item.document &&
                                              (item.document.includes('avi') || item.document.includes('AVI'))
                                          ) {
                                              isVideo = true;
                                              imageIcon = item && item.document;
                                              videoType = 'avi';
                                          } else if (
                                              item &&
                                              item.document &&
                                              (item.document.includes('mkv') || item.document.includes('MKV'))
                                          ) {
                                              isVideo = true;
                                              imageIcon = item && item.document;
                                              videoType = 'mkv';
                                          } else {
                                              imageIcon = docIcon;
                                          }
                                      }
                                      return (
                                          <div key={i} className={item.id ? 'documentDataImages' : 'documentAdding '}>
                                              {item.showAddTopButton ? (
                                                  <div
                                                      className="titleButton"
                                                      onClick={() => props.addDocumntsCliked(item.folders_id)}
                                                  >
                                                      <AddCircleOutlineIcon />
                                                      {'  '} Add {heading.name}
                                                  </div>
                                              ) : null}
                                              {item.id ? (
                                                  <div
                                                      className="documentDataValue"
                                                      onClick={() => props.goPreview(item.folders_id, item.id)}
                                                  >
                                                      <div className="documentImagesInfo">
                                                          <div className="image">
                                                              {isVideo ? (
                                                                  <video width="100%" height="100px">
                                                                      <source
                                                                          src={imageIcon}
                                                                          type={`video/${videoType}`}
                                                                      />
                                                                  </video>
                                                              ) : (
                                                                  <img src={imageIcon} alt="" />
                                                              )}
                                                          </div>
                                                          <div className="imageName">{item.name}</div>
                                                          <div className="folderName">
                                                              {`${moment(item.created_date).format('DD')} ${moment(
                                                                  item.created_date,
                                                              ).format('MMM')} ${moment(item.created_date).format(
                                                                  'YYYY',
                                                              )}`}
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null}

                                              {item.addData ? (
                                                  <div>
                                                      <div className="imageGuy">
                                                          <img src={item.notFound} alt="" />
                                                      </div>
                                                      <div className="notFoundHead">
                                                          There are no {heading.name && heading.name.toLowerCase()}{' '}
                                                          added
                                                      </div>
                                                      <div
                                                          className=""
                                                          onClick={() => {
                                                              props.addDocumntsCliked(item.folders_id);
                                                          }}
                                                      >
                                                          {' '}
                                                          <div className="titleButtonNotFound">
                                                              <AddCircleOutlineIcon />
                                                              {'  '} Add {heading.name}
                                                          </div>
                                                      </div>
                                                  </div>
                                              ) : null}
                                          </div>
                                      );
                                  })}
                              </div>
                          </TabPanel>
                      );
                  })
                : null}
        </div>
    );
}
