import React, { Component } from 'react';
import './recommendationCardsAll.scss';
import adt from '../../../Assets/images/HomeSecurity/adt.png';
import vivint from '../../../Assets/images/HomeSecurity/vivint.png';
import alder from '../../../Assets/images/HomeSecurity/alder.png';
import simpliSafe from '../../../Assets/images/HomeSecurity/simpliSafe.png';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../../store/common/actions';
import * as SecurityAction from '../../../store/security/actions';
import * as DashboardAction from '../../../store/dashboard/actions';
import Modal from '../Modal';
// import { InlineWidget } from 'react-calendly';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewDetailsPage from '../../../pages/HomeSecurity/viewDeatils';
// import CloseIcon from '@material-ui/icons/Close';
// import TextField from '@material-ui/core/TextField';
import * as SignupAction from '../../../store/login/signup/actions';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { scheduleSegment, buttonClicked } from '../../../utils/utlis';
import AnalyticsService from '../../../pages/analytics-service';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { updateChecklistTask } from '../../../utils/common-functions';

type initialProps = {
    adtRecommended?: boolean;
    vivintRecommended?: boolean;
    alderRecommended?: boolean;
    simpliRecommended?: boolean;
    commonAction?: any;
    commonState?: any;
    history?: any;
    securityType?: any;
    securityInstallation?: any;
    fromLanding?: any;
    data?: any;
    securityAction?: any;
    securityState: any;
    signupAction?: any;
    signupState?: any;
    landing?: any;
    isDouglasElliman?: boolean;
    dashboardAction: any;
};

type initialState = {
    calendlyOpen: boolean;
    cardsContentNew: any;
    isViewDetails: boolean;
    viewDetailsData: any;
    selectedService: any;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_home_security_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    hashcode: string;
    date: string;
    time: string;
    firstName: string;
    phone2: string;
    lastName: string;
    email: string;
    phone: string;
    isDataArrived: boolean;
    isSubmitClicked: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    full_name: string;
    openForm: boolean;
    concierge_name: string;
    appointment_type: any;
    brokerage_key: any;
    agent_key: any;
    logo: any;
    isAnalitycsTriggerredOnce: any;
    isFormStarted: boolean;
};
export class RecommendationCardsAll extends Component<initialProps, initialState> {
    state: initialState = {
        calendlyOpen: false,
        cardsContentNew: [],
        isViewDetails: false,
        viewDetailsData: {},
        selectedService: [],
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_home_security_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        hashcode: '',
        date: '',
        time: '',
        firstName: '',
        phone2: '',
        lastName: '',
        email: '',
        phone: '',
        isDataArrived: true,
        isSubmitClicked: false,
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        full_name: '',
        openForm: false,
        concierge_name: '',
        appointment_type: '',
        brokerage_key: '',
        agent_key: '',
        logo: '',
        isAnalitycsTriggerredOnce: {
            firstName: false,
            lastName: false,
            email: false,
            phone: false
        },
        isFormStarted: false,
    };

    componentDidMount() {
        this.props.securityAction.offers();
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        } else {
            let moveData = this.props.commonState.move[0];
            this.setState({
                appointment_details: moveData.appointment_details,
                reschedule_link: moveData.appointment_details && moveData.appointment_details.reschedule_link,
                cancel_link: moveData.appointment_details && moveData.appointment_details.cancellation_link,
                nylas_home_security_scheduler_url:
                    //  nextProps.commonState.move[0].full_service_scheduler_url
                    moveData.full_service_scheduler_url,
                appointmentTime: moveData.appointment_details && moveData.appointment_details.start_time,
                phone2: moveData && moveData.assigned_concierge && moveData.assigned_concierge.phone,
                lastName: moveData.user_ref && moveData.user_ref.last_name,
                email: moveData.user_ref && moveData.user_ref.email,
                phone: moveData.user_ref && moveData.user_ref.phone,
                firstName: moveData.user_ref && moveData.user_ref.first_name,
                moveId: moveData.id,
                hashcode: moveData.hash_code,
                // custom_schedule_call_info: moveData.realtor && moveData.realtor.custom_schedule_call_info,
            });
        }
        let cardsData: any = [];
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        // if (
        //     this.props &&
        //     this.props.history &&
        //     this.props.history.location &&
        //     this.props.history.location.state &&
        //     this.props.history.location.state.securityInstallation &&
        //     this.props.history.location.state.securityInstallation === 'professional'
        // ) {
        cardsData = [
            {
                recommended: true,
                cardLogo: adt,
                title: 'ADT',
                monthlyfees: '47.99 - 67.99',
                upfrontCost: '$99 -$599',
                installationCost: '$99',
                waivedOff: true,
                installationType: 'Professional',
                special: 'MOST POPULAR',
                price: 'Up to $1700',
                speciality: 'in Security Equipment including a Free Camera, Garage Controller and more*',
                topFeatures: 'Highest Rated System - Pro Installation - Best Rated 24/7 Monitoring',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                recommended: true,
                cardLogo: alder,
                title: 'Alder',
                monthlyfees: '39.99 - 49.99',
                upfrontCost: '$49.99 activation',
                installationCost: 'NA',
                waivedOff: false,
                installationType: 'DIY',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: '$1000',
                speciality: 'in Security Equipment + free indoor camera*',
                topFeatures: '2 Way Voice + Lifetime Warranty on All Equipment',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                recommended: false,
                cardLogo: vivint,
                title: 'Vivint',
                monthlyfees: '19.99 - 79.99',
                upfrontCost: '$25 activation',
                installationCost: '$100',
                waivedOff: true,
                installationType: 'Professional',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: '50% Off',
                speciality: 'of Monthly monitoring for life + $500 off equipment*',
                topFeatures: 'Best Home Automation. Professional Install. 2 Way Voice. Compatable with Google + Alexa',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
            {
                recommended: false,
                cardLogo: simpliSafe,
                title: 'Simplisafe',
                monthlyfees: '9.99 - 24.99',
                upfrontCost: '$229 - $599',
                installationCost: 'NA',
                waivedOff: false,
                installationType: 'DIY',
                special: `${slug && slug.display_name ? slug.display_name : slug && slug.name} Exclusive`,
                price: '35% OFF',
                speciality: 'of Security Equipments + 2 Months Free Monitoring*',
                topFeatures: 'Easy DIY Installation. Cellular Backup. Compatable with Alexa',
                condition: 'Certain restrictions apply. Offers apply to new home owners and are subject to change',
                about:
                    'ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible.',
                featuresAndDiscounts: [
                    'Free $1,700 Worth Of Home Security Equipment',
                    '24/7 Professional Monitoring',
                    'Theft Protection Guarantee',
                    '6-Month, Money-Back Guarantee',
                    'Service & Repairs Included',
                ],
            },
        ];
        this.setState({ cardsContentNew: cardsData });
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        let cardValues: any = [];
        cardsData.map((item: any) => {
            cardValues.push(item.title);
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        analytics.track(
            `Product Table Viewed`,
            {
                page: 'Security Recommendations',
                brokerage_name: this.state.brokerage_name,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: this.state.agent_name,
                category_name: 'Home Security',
                card: false,
                card_title: '',
                product_name: cardValues,
                brand_name: cardValues,
            },
            { user_id: move_id },
        );

        updateChecklistTask(100, this.props.dashboardAction);
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            let hash_code =
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
                service_name: "SECURITY_EXPERT"
            };
            if (hash_code) {
                let countNew = 0;
                setInterval(() => {
                    if (countNew < 5) {
                        nextProps.commonAction.appointmentGet(payload);
                        countNew = countNew + 1;
                    }
                }, 5000);
            }
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.appointment_details = nextProps.commonState.appointmentDetails
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails &&
                nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails &&
                nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.nylas_home_security_scheduler_url = nextProps.commonState.move[0].home_security_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_home_security_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails &&
                nextProps.commonState.appointmentDetails.start_time;
            currentState.logo =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    ? nextProps.commonState.move[0].assigned_concierge.headshot_logo
                    : '';
            currentState.full_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.full_name;
            currentState.phone2 =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].assigned_concierge &&
                nextProps.commonState.move[0].assigned_concierge.phone;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
        }

        if (nextProps && nextProps.securityState && nextProps.securityState.securityDataPosted) {
            nextProps.securityState.securityDataPosted = false;

            if (nextProps.landing) {
                nextProps.history.push({
                    pathname: '/dashboard/home-security/summary',
                    state: {
                        securityType: nextProps.securityType ? nextProps.securityType : '',
                        securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                        selectedService: currentState.selectedService,
                        fromLanding: 'fromLanding',
                        // data: nextProps.history.location.state.data,
                        date: currentState.date,
                        time: currentState.time,
                        firstName: currentState.firstName,
                        lastName: currentState.lastName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            } else {
                nextProps.history.push({
                    pathname: '/dashboard/home-security/summary',
                    state: {
                        securityType: nextProps.securityType ? nextProps.securityType : '',
                        securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                        selectedService: currentState.selectedService,
                        fromLanding: '',
                        date: currentState.date,
                        time: currentState.time,
                        firstName: currentState.firstName,
                        lastName: currentState.lastName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            }
        }
        if (
            !(
                nextProps &&
                nextProps.history &&
                nextProps.history.location &&
                nextProps.history.location.state &&
                nextProps.history.location.state.from &&
                nextProps.history.location.state.from == 'home'
            )
        ) {
            if(nextProps.commonState &&
                nextProps.commonState.move &&
                nextProps.commonState.move.length > 0 &&
                nextProps.commonState.appointmentDetails &&
                nextProps.commonState.appointmentDetails.reschedule_link ){

                nextProps.history.push({
                    pathname: '/dashboard/home-security/summary',
                    state: {
                        securityType: nextProps.securityType ? nextProps.securityType : '',
                        securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                        selectedService: currentState.selectedService,
                        fromLanding: 'fromLanding',
                        // data: nextProps.history.location.state.data,
                        date: currentState.date,
                        time: currentState.time,
                        firstName: currentState.firstName,
                        lastName: currentState.lastName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            }
        }

        if (nextProps && nextProps.signupState && nextProps.signupState.landingtokenSet) {
            nextProps.signupState.landingtokenSet = false;
            const payload2 = {
                provider_name: currentState.selectedService[0] && currentState.selectedService[0].title,
                plan_name: currentState.selectedService[0] && currentState.selectedService[0].title,
                price: currentState.selectedService[0] && currentState.selectedService[0].monthlyfees,
                plan_details: {
                    securityType: nextProps.securityType ? nextProps.securityType : '',
                    securityInstallation: nextProps.securityInstallation ? nextProps.securityInstallation : '',
                    installationCost:
                        currentState.selectedService[0] && currentState.selectedService[0].installationCost,
                    installationType:
                        currentState.selectedService[0] && currentState.selectedService[0].installationType,
                    monthlyfees: currentState.selectedService[0] && currentState.selectedService[0].monthlyfees,
                    topFeatures: currentState.selectedService[0] && currentState.selectedService[0].topFeatures,
                },
            };

            nextProps.securityAction.postSecurity(payload2);
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.concierge_name =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.concierge_name;
            currentState.appointment_type =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.appointment_type;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.openForm = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }
    calendlyHandle = (item: any) => {
        let selectedItem = [];
        selectedItem.push(item);
        this.setState({ 
            calendlyOpen: true, 
            selectedService: selectedItem,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        scheduleSegment(
            'Button Clicked',
            'Security Recommendations',
            brokerage,
            brokerage_key,
            agent_key,
            agent_name,
            'Home Security',
            'Schedule A Call',
            false,
            ``,
            false,
            ' ',
            move_id,
        );
    };
    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
    };
    // closeCalendlyModal = async () => {
    //     await this.setState({ isSubmitClicked: true });
    //     if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
    //         await this.setState({ calendlyOpen: false });
    //         if (this.props.landing) {
    //             const domain = window.location.hostname;
    //             let name = domain.split('.');
    //             const payload = {
    //                 // destination_detail: this.state.address ? this.state.address : '',
    //                 destination_home: '1',
    //                 moving_date: new Date().toISOString().split('T')[0],
    //                 moving_in_date: null,
    //                 moving_items_list: ['2'],
    //                 moving_option_value: ['2'],
    //                 selected_service_list: ['11'],
    //                 type: 'security',
    //                 user_ref: {
    //                     email: this.state.email,
    //                     first_name: this.state.firstName,
    //                     last_name: this.state.lastName,
    //                     // password: password,
    //                     phone: this.state.phone,
    //                     site_name:
    //                         name[0] == 'security'
    //                             ? 'dtc-marketing'
    //                             : name[0] == 'security-demo'
    //                             ? 'apps-demo'
    //                             : 'moveeasy-demo',
    //                 },
    //             };
    //             this.props.signupAction.landingMovePostForSecurity(payload);
    //             // this.props.history.push({
    //             //     pathname: '/landing/home-security/summary',
    //             //     state: {
    //             //         securityType: this.props.securityType,
    //             //         securityInstallation: this.props.securityInstallation,
    //             //         selectedService: this.state.selectedService,
    //             //         fromLanding: 'fromLanding',
    //             //         data: this.props.history.location.state.data,
    //             //         date: this.state.date,
    //             //         time: this.state.time,
    //             //         firstName: this.state.firstName,
    //             //         lastName: this.state.lastName,
    //             //         email: this.state.email,
    //             //         phone: this.state.phone,
    //             //     },
    //             // });
    //         } else {
    //             const payload2 = {
    //                 provider_name: this.state.selectedService[0] && this.state.selectedService[0].title,
    //                 plan_name: this.state.selectedService[0] && this.state.selectedService[0].title,
    //                 price: this.state.selectedService[0] && this.state.selectedService[0].monthlyfees,
    //                 plan_details: {
    //                     securityType: this.props.securityType ? this.props.securityType : '',
    //                     securityInstallation: this.props.securityInstallation ? this.props.securityInstallation : '',
    //                     installationCost:
    //                         this.state.selectedService[0] && this.state.selectedService[0].installationCost,
    //                     installationType:
    //                         this.state.selectedService[0] && this.state.selectedService[0].installationType,
    //                     monthlyfees: this.state.selectedService[0] && this.state.selectedService[0].monthlyfees,
    //                     topFeatures: this.state.selectedService[0] && this.state.selectedService[0].topFeatures,
    //                 },
    //             };

    //             this.props.securityAction.postSecurity(payload2);
    //         }
    //     }
    //     let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
    //     let brokerage: any;

    //     brokerage = brokerageCheck.display_name;
    //     let brokerage_key: any = brokerageCheck.brokerage_key;
    //     let agent_key: any = brokerageCheck.realtor_key;

    //     let agent_name: any = brokerageCheck.contact_name;
    //     let move_id: any = localStorage.getItem('moveKey');

    //     buttonClicked(
    //         'Button Clicked',
    //         'Security Recommendations',
    //         brokerage,
    //         brokerage_key,
    //         agent_name,
    //         agent_key,
    //         'Home Security',
    //         'Submit',
    //         false,
    //         '',
    //         false,
    //         '',
    //         '',
    //         false,
    //         '',
    //         true,
    //         1,
    //         '',
    //         move_id,
    //     );
    // };
    closeModal = () => {
        this.setState({ calendlyOpen: false, isSubmitClicked: false });
        let brokerage: any;

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
            service_name: "SECURITY_EXPERT"
        };
        if (hash_code) {
            let countNew = 0;
            setInterval(() => {
                if (countNew < 5) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 5000);
        }
        setTimeout(() => {
            this.props.commonAction.moveGet();
            if(this.state.reschedule_link) {
                this.props.history.push({
                    pathname: '/landing/home-security/summary',
                    state: {
                        securityType: this.props.securityType,
                        securityInstallation: this.props.securityInstallation,
                        selectedService: this.state.selectedService,
                        fromLanding: 'fromLanding',
                        data: this.props.history?.location?.state?.data,
                        date: this.state.date,
                        time: this.state.time,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone,
                    },
                });
            }
        }, 2000);

        buttonClicked(
            'Button Clicked',
            'Security Recommendations',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Home Security',
            'Cancel',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    analyticsHandler = (fieldLabel: string, fieldName: string) => {
        if (!this.state.isAnalitycsTriggerredOnce[fieldName]) {

            const trackProperties = {
                page: 'Home Security',
                category_name: 'Home Security Recommedations',
                form_button: true,
                form_pre_filled: true,
                form_field_name: fieldLabel,
                button_copy: 'Submit'
            }

            if (this.state.isFormStarted) {
                AnalyticsService.formFieldInput(trackProperties);
            } else {
                AnalyticsService.formFieldStarted(trackProperties);
            }
            this.setState({
                isFormStarted: true
            });
        }

        this.setState({
            isAnalitycsTriggerredOnce : {
                ...this.state.isAnalitycsTriggerredOnce,
                [fieldName]: true
            }
        })
    }

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });

        this.analyticsHandler('First name', 'firstName');
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });

        this.analyticsHandler('Last name', 'lastName');
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });

        this.analyticsHandler('Email', 'email');
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });

        this.analyticsHandler('Phone Number', 'phone');
    };

    onCloseModal = () => {
        this.setState({ isViewDetails: false });
    };

    viewDetailsClick = (item: any) => {
        this.setState({ isViewDetails: true, viewDetailsData: item });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="main-div-recommendation">
                <div className="card-1-recommendation">
                    <div className="recommended-section"></div>
                    <div className="logo-section"></div>
                    <div className="brand-title"></div>
                    <div className="monthly-fees">Monthly fee</div>
                    <div className="upfront-cost-1">Upfront Cost</div>
                    <div className="installation-cost-1">Installation Cost</div>
                    <div className="installation-type-1">Installation Type</div>
                    <div className="exclusive-deals">
                        <div className="exclusive-deals-1">Exclusive Deals</div>
                    </div>
                    <div className="top-features">
                        <div className="top-features-1">Top Features</div>
                    </div>
                    <div className="conditions">
                        <div className="conditions-1">Conditions</div>
                    </div>
                    <div className="call"></div>
                    <div className="view-details"></div>
                </div>
                <div className="main-cards-security">
                    {this.state.cardsContentNew.map((item: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className="card-2-recommendation"
                                style={{ boxShadow: item.recommended ? '0px 0px 3px #bebebe' : '' }}
                            >
                                <div className="recommended-section">
                                    {item.recommended ? (
                                        <div
                                            className={
                                                this.props.isDouglasElliman ? 'recommended addColor' : 'recommended'
                                            }
                                        >
                                            Recommended
                                        </div>
                                    ) : null}
                                </div>
                                <div className="logo-section">
                                    <div className="logo-card">
                                        <img src={item.cardLogo} style={{ width: '100%', height: '100%' }} alt="" />
                                    </div>
                                </div>
                                <div className="brand-title">{item.title}</div>
                                <div className="monthly-fees">${item.monthlyfees}</div>
                                <div className="upfront-cost">{item.upfrontCost}</div>
                                <div className="installation-cost">
                                    {item.installationCost != 'NA' ? (
                                        // <del className="cross">
                                        <span className="waived">{item.installationCost}</span>
                                    ) : (
                                        // </del>
                                        'NA'
                                    )}{' '}
                                    {item.waivedOff == true ? 'Waived Off' : ''}{' '}
                                </div>
                                <div className="installation-type">{item.installationType}</div>
                                <div className="exclusive-deals">
                                    <div className="tag-special">
                                        <div className="tag-div">
                                            <LocalOfferIcon
                                                style={{
                                                    color: this.props.isDouglasElliman
                                                        ? '#009CBD'
                                                        : item.recommended
                                                        ? '#006DFF'
                                                        : '#333',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                color: this.props.isDouglasElliman
                                                    ? '#009CBD'
                                                    : item.recommended
                                                    ? '#006DFF'
                                                    : '#333',
                                                fontWeight: 'bold',
                                                margin: '0 5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.special}
                                        </div>
                                    </div>

                                    <div className="price">
                                        {' '}
                                        {/* {!item.price.includes('OFF') ? <sup className="dollar">$</sup> : ''} */}
                                        {item.price}
                                    </div>

                                    <div className="speciality">{item.speciality}</div>
                                </div>
                                <div className="top-features">{item.topFeatures}</div>
                                <div className="conditions">
                                    <div className="condition-inner">
                                        <div className="condition">{item.condition}</div>
                                        <div className="info-icon">
                                            <InfoIcon style={{ color: '#6B6C6F', height: '15px', width: '15px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="call">
                                    {/* <Button
                                        onClick={() => this.calendlyHandle(item)}
                                        backgroundColor={
                                            this.props.isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : this.props.landing
                                                ? '#30ab86'
                                                : '#273E59'
                                        }

                                        data-cy={`home-security-schedule-call-${index}`}
                                    >
                                        Schedule A Call
                                    </Button> */}
                                    {this.state.reschedule_link ? (
                                        <div>
                                            <Button
                                                className="footerbtn"
                                                color="#FFFFFF"
                                                width="120px"
                                                height="35px"
                                                backgroundColor={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : window.location.pathname.includes('landing')
                                                        ? '#30AB86'
                                                        : '#273e59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                fontSize="12px"
                                                fontWeight="600"
                                                border={
                                                    isDouglasElliman
                                                        ? '1px solid #100B28'
                                                        : `1px solid ${
                                                            slug && slug.primary_color
                                                                ? slug.primary_color
                                                                : window.location.pathname.includes('landing')
                                                                ? '#30ab86'
                                                                : '#273e59'
                                                        }`
                                                }
                                                data-cy={`home-security-reschedule-call-${index}`}
                                                onClick={() => this.calendlyHandleReschedule()}
                                            >
                                                Reschedule
                                            </Button>
                                        </div>
                                    ) : this.state.nylas_home_security_scheduler_url ? (
                                        <Button
                                            className="footerbtn"
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : slug && slug.primary_color
                                                    ? `0 0 3px 0 ${slug.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            border={
                                                isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                    ? '#30ab86'
                                                    : '#273e59'
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                    ? '#30ab86'
                                                    : '#273e59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            width="120px"
                                            height="35px"
                                            fontSize="12px"
                                            fontWeight="600"
                                            data-cy={`home-security-schedule-call-${index}`}
                                            onClick={() => this.calendlyHandle(item)}
                                        >
                                            Schedule a Call
                                        </Button>
                                    ) : (
                                        <Skeleton height="30px" width="200px" />
                                    )}
                                </div>
                                {/* <div className="view-details" onClick={() => this.viewDetailsClick(item)}>
                                    View Details
                                </div> */}
                            </div>
                        );
                    })}
                </div>

                {this.state.calendlyOpen && (
                    <Modal
                    isShowModal={this.state.calendlyOpen}
                    onCloseModal={this.closeModal}
                    showClose={false}
                    className="scheduleCallMoving"
                >
                    <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                        <ArrowBackIcon />
                    </div>
                    {(this.state.scheduleClicked && this.state.nylas_home_security_scheduler_url) ||
                    (this.state.rescheduleClicked && this.state.reschedule_link) ||
                    (this.state.cancelClicked && this.state.cancel_link) ? (
                        <div className="iFrame">
                            <iframe
                                src={`${
                                    this.state.cancelClicked
                                        ? this.state.cancel_link
                                        : this.state.rescheduleClicked
                                        ? this.state.reschedule_link
                                        : this.state.nylas_home_security_scheduler_url 
                                        ? this.state.nylas_home_security_scheduler_url 
                                        : null
                                }&utm_source=platform&utm_medium=web&utm_content=footer&utm_campaign=security-platform
                                
                                    `}
                                allow="encrypted-media"
                                // position="relative"
                                className="iframe"
                                //  referrerPolicy="same-origin"
                                //  sandbox="allow-scripts"
                                id="myFrame"
                            ></iframe>
                        </div>
                    ) : null}
                </Modal>
                )}

                {this.state.isViewDetails ? (
                    <Modal
                        isShowModal={this.state.isViewDetails}
                        showClose={false}
                        onCloseModal={this.onCloseModal}
                        className="securityRecommendationMainPageModal"
                    >
                        <ViewDetailsPage onBack={this.onCloseModal} data={this.state.viewDetailsData} />
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        securityAction: bindActionCreators(SecurityAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    securityState: state.security,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationCardsAll);
