import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38.645}
      height={39.539}
      viewBox="0 0 38.645 39.539"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupInsurance_prefix__a{fill:#fff}.signupInsurance_prefix__b{fill:${slug.color_shade3}}.signupInsurance_prefix__c{fill:${slug.color_shade1}}`
            : ".signupInsurance_prefix__a{fill:#fff}.signupInsurance_prefix__b{fill: #5b7088}.signupInsurance_prefix__c{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        className="signupInsurance_prefix__a"
        d="M33.277 24.753l1.407 1.024.22.16v13.092H19.316V26.01l-.035-.047 7.829-5.7.336.244 3.64 2.65zm0 0"
      />
      <path
        className="signupInsurance_prefix__a"
        d="M34.904 22.133v3.808l-.22-.16-1.407-1.024v-3.9zm0 0"
      />
      <path
        d="M31.219 8.05v13.505a10.155 10.155 0 01-.133 1.6l-3.64-2.646v-10.02L15.864 4.857 4.283 10.529v10.852a9.9 9.9 0 003.86 7.3l7.721 5.266 3.452-2.354v4.616l-3.452 2.355-9.953-6.788a13.439 13.439 0 01-5.4-10.218V8.03L15.866.509zm0 0"
        // fill="#5b7088"
        className="signupInsurance_prefix__b"
      />
      <path
        className="signupInsurance_prefix__a"
        d="M19.281 25.963l.035.047v5.583l-3.452 2.354-7.721-5.268a9.9 9.9 0 01-3.86-7.3V10.528l11.582-5.672 11.582 5.632v10.02l-.336-.244zm0 0"
      />
      <path
        className="signupInsurance_prefix__c"
        d="M19.451 16.671l-4.77 4.369-2.373-2.573a.509.509 0 00-.748.69l2.717 2.946a.509.509 0 00.718.03l5.144-4.711a.509.509 0 00-.687-.751zm0 0"
      />
      <path
        className="signupInsurance_prefix__c"
        d="M38.433 27.878l-3.02-2.2v-3.545a.508.508 0 00-.194-.4l-1.627-1.282a.509.509 0 00-.823.4v2.9l-1.135-.827a10.446 10.446 0 00.1-1.372V8.049a.509.509 0 00-.285-.457L16.091.052a.509.509 0 00-.448 0L.286 7.573a.508.508 0 00-.285.457v13.525a13.992 13.992 0 005.624 10.639l9.953 6.788a.509.509 0 00.573 0l2.656-1.812v1.86a.509.509 0 00.509.509h15.588a.509.509 0 00.509-.509V26.938l2.421 1.761a.51.51 0 10.6-.823zm-4.647-5.979l.61.481v2.559l-.61-.444zM15.865 37.946l-9.667-6.593a12.885 12.885 0 01-5.18-9.8V8.347l14.848-7.272 14.845 7.291v13.189c0 .222-.01.449-.028.68l-2.731-1.986v-9.76a.509.509 0 00-.287-.458L16.087 4.399a.508.508 0 00-.446 0L4.059 10.072a.509.509 0 00-.285.457V21.38a10.471 10.471 0 004.078 7.719l7.721 5.266a.509.509 0 00.573 0l2.656-1.812v3.382zm-.077-10.068a.509.509 0 00.6.822l2.421-1.762v4.387l-2.943 2.007-7.435-5.07a9.477 9.477 0 01-3.639-6.882V10.846l11.073-5.423 11.072 5.384v8.979a.506.506 0 00-.127.066zm18.608 10.643H19.825V26.199l7.286-5.3 7.285 5.3zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
