import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32.387}
      height={46.225}
      viewBox="0 0 32.387 46.225"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupElectrician_prefix__c{fill:${slug.color_shade1}}`
            : ".signupElectrician_prefix__c{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        d="M12.715 43.329l17.918-27.606a.886.886 0 00-.97-1.3l-11.919 1.45 2.848-13.377c.1-.451-.554-.709-.864-.343L2.111 22.963a.876.876 0 00.875 1.388l13.14-1.238-4.308 19.914c-.103.481.622.725.897.302z"
        fill="#fff"
      />
      <path
        d="M11.006 46.184a.673.673 0 01-.477-.764l3.914-18.092a.533.533 0 01.02-.067l.725-3.378L.836 25.236a.787.787 0 01-.736-.342.616.616 0 01.055-.743L20.377.27a.81.81 0 01.911-.212.666.666 0 01.435.742l-1.385 6.68a.592.592 0 01-.079.2l-1.575 7.4 12.845-1.561a.8.8 0 01.731.294.619.619 0 01.023.717L11.928 45.891a.782.782 0 01-.657.334.846.846 0 01-.265-.041zm1.859-4.437L30.2 15.045l-12.349 1.5a.82.82 0 01-.654-.205.639.639 0 01-.197-.592l1.851-8.682a.606.606 0 01.069-.184l.711-3.489L2.416 23.725l13.634-1.286a.822.822 0 01.636.218.622.622 0 01.184.585l-1 4.634a.385.385 0 01-.021.073z"
        // fill="#273e59"
        className="signupElectrician_prefix__c"
      />
    </svg>
  )
}

export default SvgComponent
