import React, { Component } from 'react';
import SelectInput from '../SelectInput';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './checklistCard.scss';
import Skeleton from 'react-loading-skeleton';

type initialProps = {
    onSelectHandler?: any;
    showAll?: any;
    handlePrevWeek?: any;
    handleNextWeek?: any;
    handleMenu?: any;
    handleClose?: any;
    AnchorEl?: any;
    sort?: any;
    history?: any;
    startMonth?: string;
    endMonth?: string;
    startDt?: string;
    endDt?: string;
    handleWeekly?: any;
    handleMonthly?: any;
    checklistKeys?: any;
    index?: any;
};

type initialState = {};

export class WeekSwitch extends Component<initialProps, initialState> {
    render() {
        const { checklistKeys, index } = this.props;
        let len: any = 0;
        if (checklistKeys) {
            len = checklistKeys.length;
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                <div className="checklist-overdue-content-header1">
                    <div
                        className={
                            isDouglasElliman
                                ? 'checklist-overdue-select addressFlexDiv checklist-overdue-selectDoug'
                                : 'checklist-overdue-select'
                        }
                    >
                        <SelectInput
                            className="react-select alert-select"
                            onChange={(e: any) => this.props.onSelectHandler(e)}
                            options={[
                                { value: 'Moving', label: 'Moving' },
                                { value: 'Pets', label: 'Pets' },
                                { value: 'Cars', label: 'Cars' },
                                { value: 'Infant', label: 'Infant' },
                                { value: 'General', label: 'General' },
                                { value: 'Show All', label: 'Show All' },
                            ]}
                            placeholder={this.props.showAll ? this.props.showAll : 'Show All'}
                            value={this.props.showAll}
                        />
                    </div>
                    <div>
                        <div className="checklist-overdue-carousel-wrapper">
                            {index <= 0 ? (
                                checklistKeys[index] ? (
                                    <div
                                        onClick={this.props.handlePrevWeek}
                                        className="checklist-overdue-carousel-icon-disabled"
                                    >
                                        <ChevronLeftIcon style={{ color: '#D8DCE6' }} />
                                    </div>
                                ) : (
                                    <Skeleton height={40} width={30} />
                                )
                            ) : checklistKeys[index] ? (
                                <div
                                    onClick={this.props.handlePrevWeek}
                                    className={
                                        isDouglasElliman
                                            ? 'checklist-overdue-carousel-icon  checklist-overdue-carousel-iconDoug'
                                            : 'checklist-overdue-carousel-icon'
                                    }
                                >
                                    <ChevronLeftIcon style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF' }} />
                                </div>
                            ) : (
                                <Skeleton height={40} width={30} />
                            )}
                            <div className="checklist-overdue-weekrange">
                                {checklistKeys[index] ? (
                                    checklistKeys[index]
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                        <Skeleton height={30} width={200} />
                                    </div>
                                )}
                            </div>
                            {index >= len - 1 ? (
                                checklistKeys[index] ? (
                                    <div
                                        onClick={this.props.handleNextWeek}
                                        className="checklist-overdue-carousel-icon-disabled"
                                    >
                                        <ChevronRightIcon style={{ color: '#D8DCE6' }} />
                                    </div>
                                ) : (
                                    <Skeleton height={40} width={30} />
                                )
                            ) : checklistKeys[index] ? (
                                <div
                                    onClick={this.props.handleNextWeek}
                                    className={
                                        isDouglasElliman
                                            ? 'checklist-overdue-carousel-icon  checklist-overdue-carousel-iconDoug'
                                            : 'checklist-overdue-carousel-icon'
                                    }
                                >
                                    <ChevronRightIcon style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF' }} />
                                </div>
                            ) : (
                                <Skeleton height={40} width={30} />
                            )}
                        </div>

                        <div className="checklist-overdue-carousel-text">
                            Here’s all your moving tasks. Let’s start knocking them off your list.
                        </div>
                    </div>
                    {/* 
                    <div className="checklist-overdue-menu-wrapper">
                        <div
                            onClick={(e: any) => this.props.handleMenu(e)}
                            style={{
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                Show me:
                                <span className="checklist-overdue-showme">
                                    {this.props.sort && this.props.sort.label}
                                </span>
                            </div>
                            <ExpandMoreIcon />
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.props.AnchorEl}
                            keepMounted
                            open={Boolean(this.props.AnchorEl)}
                            onClose={this.props.handleClose}
                        >
                            <MenuItem onClick={(e: any) => this.props.handleWeekly(e)}>Weekly</MenuItem>
                            <MenuItem onClick={(e: any) => this.props.handleMonthly(e)}>Monthly</MenuItem>
                        </Menu>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default WeekSwitch;
