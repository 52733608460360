import React, { Component } from 'react';
import './Amount.scss';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CommonService from '../../utils/common-functions';
import { equityBalanceTooltipText, pendingLoanAmountTooltipText } from '../../utils/constants';

type initialProps = {
    width?: string;
    width1?: string;
    loanAmt: number;
    equityAmt: number;
};

type initialState = {};

export class Amount extends Component<initialProps, initialState> {
    state: initialState = {};
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        return (
            <div className="main-div-amount">
                <div className="border-line-amount"></div>
                <div
                    className="main-div-amount-pending"
                    style={{ width: this.props.width ? this.props.width : 'auto' }}
                >
                    <div className="main-div-amount-sub-head" style={{ color: '#fff' }}>
                        Pending Loan Amount
                        <ReactTooltip
                            effect="solid"
                            border={false}
                            multiline={true}
                            className="tooltipCover"
                            id="pending-loan-tip"
                            place="top"
                        >{pendingLoanAmountTooltipText}</ReactTooltip>{' '}
                        <InfoIcon
                            data-tip data-for="pending-loan-tip"
                            style={{
                                height: '16px',
                                width: '16px',
                                marginLeft: '4px',
                                marginBottom: '-4px',
                                position: 'absolute',
                            }}
                        />
                    </div>
                    <div className="main-div-amount-sub-amount" style={{ color: '#fff' }}>
                        {CommonService.formatCurrency({ value: this.props?.loanAmt })}
                    </div>
                </div>
                <div
                    className="main-div-amount-balance"
                    style={{ width: this.props.width1 ? this.props.width1 : 'auto' }}
                >
                    <div className="main-div-amount-sub-head" style={{ color: '#333333' }}>
                        Equity Balance
                        <ReactTooltip
                            effect="solid"
                            multiline={true}
                            className="tooltipCover"
                            id="equity-balance-tip"
                            place="top"
                        >{equityBalanceTooltipText}</ReactTooltip>{' '}
                        <InfoIcon
                            data-tip data-for="equity-balance-tip"
                            style={{
                                height: '16px',
                                width: '16px',
                                marginLeft: '4px',
                                marginBottom: '-4px',
                                position: 'absolute',
                            }}
                        />
                    </div>
                    <div className="main-div-amount-sub-amount " style={{ color: '#00D56D' }}>
                        {CommonService.formatCurrency({ value: this.props?.equityAmt })}
                    </div>
                </div>
            </div>
        );
    }
}
export default Amount;
