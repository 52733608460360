import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import '../../styles/carouselCard.css';

interface ICarouselProps {
    avatar?: string;
    name?: string;
    title?: string;
    desc?: string;
    rate?: number;
    className ? : string;
}

const CarouselCard = (props: ICarouselProps) => {
    return (
        <Card className={props.className ? props.className : "card"} style={{borderRadius:"23px"}}>
            <CardContent>
                <Avatar alt="Remy Sharp" src={props.avatar} />
                <div className="name">{props.name}</div>
                <div className="title">{props.title}</div>
                <p className="desc">{props.desc}</p>
                <div className="rating">
                    <Rating name="read-only" value={props.rate} readOnly />
                </div>
            </CardContent>
        </Card>
    );
};

export default CarouselCard;
