import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43.354}
      height={35.399}
      viewBox="0 0 43.354 35.399"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupCarpenter_prefix__a{fill:${slug.color_shade1}}`
            : ".signupCarpenter_prefix__a{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        className="signupCarpenter_prefix__a"
        d="M8.652 13.505l4.466-4.466a1.824 1.824 0 000-2.579l-.772-.772a1.826 1.826 0 00-2.579 0l-4.466 4.466a1.826 1.826 0 000 2.579l.772.772a1.826 1.826 0 002.579 0zm-2.536-1.587a.672.672 0 010-.949l4.466-4.466a.672.672 0 01.949 0l.772.772a.671.671 0 010 .949L7.837 12.69a.672.672 0 01-.949 0z"
      />
      <path
        className="signupCarpenter_prefix__a"
        d="M43.321 17.894l-.609-1.706a14.75 14.75 0 00-3.25-5.24 4.6 4.6 0 00-5.317-5.3l-1.466-1.467.542-.54a.563.563 0 000-.8L30.528.158a.568.568 0 00-.8 0L24.8 5.073a.563.563 0 000 .8l2.693 2.683a.568.568 0 00.8 0l.543-.54L30.3 9.473a4.847 4.847 0 00.061 1.757l-5.92 5.9-8.313-8.289a2.316 2.316 0 00-.224-3.019l-4.781-4.763a2.336 2.336 0 00-3.294 0L.682 8.181a2.314 2.314 0 000 3.282l4.781 4.763a2.333 2.333 0 002.836.352l2.96.552 1.608 2.779a.566.566 0 00.386.273l3.166.591 1.6 2.762-7.919 7.884a2.325 2.325 0 00.013 3.293 2.351 2.351 0 001.664.687 2.317 2.317 0 001.641-.675l8.962-8.928.808 1.4a.567.567 0 00.387.273l3.166.59 1.607 2.779a.566.566 0 00.387.273l3.166.59 1.608 2.78a.566.566 0 00.386.273l3.421.639a.568.568 0 00.5-.15l2.027-1.966a.563.563 0 00.005-.8L27.763 20.432l5.815-5.793a4.9 4.9 0 002.048.13 14.825 14.825 0 005.26 3.238l1.712.607a.565.565 0 00.723-.721zM27.895 7.356L26 5.471l4.125-4.11 1.892 1.885zm-21.63 8.069l-4.782-4.763a1.189 1.189 0 010-1.686L8.63 1.857a1.2 1.2 0 011.692 0L15.1 6.621a1.19 1.19 0 010 1.686l-7.145 7.121a1.2 1.2 0 01-1.69-.003zm11.006 4.556a.566.566 0 00-.386-.273l-3.166-.591-1.608-2.779a.566.566 0 00-.386-.273l-2.365-.441 5.988-5.965 8.3 8.266-4.8 4.78zm21.367 12.88l-1.405 1.363-2.876-.537-1.608-2.78a.566.566 0 00-.387-.273l-3.166-.59-1.606-2.778a.566.566 0 00-.387-.273l-3.166-.59-.829-1.434 3.754-3.74zm-26.022 1.065a1.211 1.211 0 01-1.716-1.709l19.889-19.813a4.367 4.367 0 001.666 1.759zm28.65-16.982a13.677 13.677 0 01-5.053-3.181.568.568 0 00-.52-.152 3.482 3.482 0 01-4.224-4.208.563.563 0 00-.153-.519l-1.652-1.646L31.9 5.007l1.652 1.646a.567.567 0 00.521.152 3.482 3.482 0 014.227 4.209.563.563 0 00.153.519 13.608 13.608 0 013.193 5.033l.21.587z"
      />
    </svg>
  )
}

export default SvgComponent
