import React from 'react';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../store/login/signup/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import './welcomeVideo.scss';
import playicon  from '../../Assets/images/welcome/play-btn.svg'
import Button from '../../components/atoms/Button';
type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {    
    homeOwnerVideoURL: string;
    fullName: string;
    fullNameUser: string;
    buttonShow: boolean;
};
class welcomeVideo extends React.Component<initialProps, initialState> {
    state: initialState = {
        homeOwnerVideoURL: '',
        fullName: '',
        fullNameUser: '',
        buttonShow: true,
    };
    myRef: any = React.createRef();

    componentDidMount() {
        this.props.commonAction.moveGet();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState &&
            nextProps.commonState.realtor
        ) {
            currentState.homeOwnerVideoURL =
                nextProps.commonState &&
                nextProps.commonState.realtor.home_owner_video_url
            currentState.fullName =
            nextProps.commonState.move[0] &&
                nextProps.commonState.realtor.display_name
            currentState.fullNameUser =
            nextProps.commonState.move[0]?.user_ref &&
            `${nextProps.commonState.move[0]?.user_ref.first_name} ${nextProps.commonState.move[0]?.user_ref.last_name}`;
        }
        return currentState;
    }
    handleBottom = () => {
        this.myRef.current.play()
        this.setState({buttonShow: false})
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { homeOwnerVideoURL } = this.state;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className='innercontainer_page greybg'>
                <div className='welcomvideo_wrapper welcomvideo_wrapper_small'>
                    <div className='welcomevideo_banner'>
                        <p>Hi <span>{this.state.fullNameUser}</span>,</p>
                        <h1>Welcome To Your Homeowner<br/>Dashboard</h1>
                    </div>
                    <div className="welcomvideo_middlecontainer">
                        <h3 className='title'>Overview of {this.state.fullName}</h3>
                        <div className='videowrapper'>
                            {homeOwnerVideoURL ? (
                            <>
                                <video ref={this.myRef}>
                                    <source src={homeOwnerVideoURL} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                                    {/* Your browser does not support the video tag. */}
                                </video>
                                {this.state.buttonShow ? (<img src={playicon} onClick={this.handleBottom} className="playbtn" />) : ""}
                                
                             </>
                            ) : ""}                            
                           
                        </div>
                        <br></br>
                        <Button
                            className='bluebtn'
                            boxShadow={
                                isDouglasElliman
                                    ? 'none'
                                    : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                            }
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : window.location.pathname.includes('landing')
                                    ? '#30AB86'
                                    : '#273e59'
                            }
                            data-cy={"mof-video-getstarted-button"}
                            onClick={() => {
                                this.props.history.push({
                                    pathname: '/dashboardhomeowner',
                                    state: {
                                        fromSignUp: 'fromSignUp',
                                    },
                                });
                            }}
                            >
                                Let’s Get Started
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeVideo);
