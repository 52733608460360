import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
    >
      <defs>
        <style>
          {
            // ".prefix__a{fill:#bebebe}.prefix__b{fill:#fff}.prefix__c{fill:#6b6c6f}"
            `.dashboardSvgprefix__a{fill:${(window.location.pathname==="/dashboard" && slug && slug.primary_color) ?  `${slug.primary_color} !important` : "#bebebe !important"}}.dashboardSvgprefix__b{fill:#fff}.dashboardSvgprefix__c{fill:${(window.location.pathname==="/dashboard" && slug && slug.primary_color) ?  `${slug.primary_color} !important` : "#6b6c6f !important"}}`
          }
        </style>
      </defs>
      <path
        className="dashboardSvgprefix__a"
        d="M6.167 0h-5A1.168 1.168 0 000 1.167v3a1.168 1.168 0 001.167 1.166h5a1.168 1.168 0 001.166-1.166v-3A1.168 1.168 0 006.167 0zm0 0"
      />
      <path
        className="dashboardSvgprefix__b"
        d="M6.167 6.667h-5A1.168 1.168 0 000 7.835v7a1.168 1.168 0 001.167 1.167h5a1.168 1.168 0 001.166-1.167v-7a1.168 1.168 0 00-1.166-1.168zm0 0"
      />
      <path
        className="dashboardSvgprefix__a"
        d="M14.835 10.667h-5a1.168 1.168 0 00-1.167 1.167v3a1.168 1.168 0 001.167 1.167h5a1.168 1.168 0 001.167-1.167v-3a1.168 1.168 0 00-1.167-1.167zm0 0"
      />
      <path
        className="dashboardSvgprefix__b"
        d="M14.835 0h-5a1.168 1.168 0 00-1.167 1.167v7a1.168 1.168 0 001.167 1.166h5a1.168 1.168 0 001.167-1.167v-7A1.168 1.168 0 0014.835 0zm0 0"
      />
      <path
        className="dashboardSvgprefix__c"
        d="M6.167 5.333h-5A1.168 1.168 0 010 4.167v-3A1.168 1.168 0 011.167 0h5a1.168 1.168 0 011.166 1.167v3a1.168 1.168 0 01-1.166 1.166zM1.167 1A.167.167 0 001 1.167v3a.167.167 0 00.167.167h5a.167.167 0 00.167-.167v-3A.167.167 0 006.167 1zm0 0M6.167 16h-5A1.168 1.168 0 010 14.835v-7a1.168 1.168 0 011.167-1.167h5a1.168 1.168 0 011.166 1.167v7A1.168 1.168 0 016.167 16zm-5-8.333A.167.167 0 001 7.835v7a.167.167 0 00.167.167h5a.167.167 0 00.167-.167v-7a.167.167 0 00-.167-.167zm0 0M14.835 16h-5a1.168 1.168 0 01-1.167-1.167v-3a1.168 1.168 0 011.167-1.167h5a1.168 1.168 0 011.167 1.167v3A1.168 1.168 0 0114.835 16zm-5-4.333a.167.167 0 00-.167.167v3a.167.167 0 00.167.167h5a.167.167 0 00.167-.167v-3a.167.167 0 00-.167-.167zm0 0M14.835 9.333h-5a1.168 1.168 0 01-1.167-1.167v-7A1.168 1.168 0 019.835 0h5a1.168 1.168 0 011.167 1.167v7a1.168 1.168 0 01-1.167 1.166zM9.835 1a.167.167 0 00-.167.167v7a.167.167 0 00.167.167h5a.167.167 0 00.167-.167v-7A.167.167 0 0014.835 1zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
