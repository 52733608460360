import { Button } from '@material-ui/core';
import React from 'react';
import './CustomServiceCard.scss';

type initialProps = {
    image?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    onButtonClick?: any;
    history?: any;
};

const HomeServiceCard = (props: initialProps) => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    // let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    return (
        <div className="CustomServiceCardMain">
            <div className="image">
                <img src={props.image} className="imageHomeService" alt=""/>
            </div>
            <div className="homeServiceCardInnerDiv">
                <div className="titleHomeService">{props.title}</div>
                <div className="descriptionHomeService">{props.description}</div>
                <div className="buttonDivHomeService">
                    <Button
                        style={{border:`1px solid ${
                                slug && slug.primary_color
                                ? slug && slug.primary_color
                                : '#273E59'
                        }`}}
                        
                        className="buttonHomeService"
                        onClick={props.onButtonClick}
                    >
                        {props.buttonText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HomeServiceCard;
