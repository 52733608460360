import React from 'react';
import './Main.scss';

interface defaultProps {
    value: string | undefined | number;
    handleChange: any;
    hasError: boolean;
    showNoDataView?: boolean;
}

export default function Interset({ value, handleChange, hasError, showNoDataView }: defaultProps) {

    return (
        <div className='aprFieldContaner'>
            <div className="headingText">Enter Interest Rate (APR)</div>
            <div className='inputWithIcon'>
            <input type="number" className="inputSelected" value={value || ''} onChange={handleChange} disabled={showNoDataView ? showNoDataView : false} data-cy='interest-saving-calculator' />
            <div className="radioButtonPerSelectedSaving">%</div>
            </div>
            {hasError && <span className="errMessage">{`Please enter Valid APR. 0 < APR <= 100`}</span>}
        </div>
    );
}
