import React from 'react';
import './scheduleACall.scss';
// import Iframe from 'react-iframe';
import ButtonNew from '../../../components/atoms/Button';
import { connect } from 'react-redux';
import * as CommonAction from '../../../store/common/actions';
import { bindActionCreators } from 'redux';

type InitialProps = {
    history?: any;
    commonAction: any;
    commonState: any;
};

type InitialState = {
    slug: any;
    hashcode: any;
    isDataArrived: boolean;
    isDataArrived2: boolean;
    isDataArrivedNylus: boolean;
    service: string;
    name: string;
    email: string;
    phone: string;
    carteServices: string;
    url: string;
};

class ScheduleACall extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: '',
        hashcode: '',
        isDataArrived: true,
        isDataArrived2: true,
        service: '',
        name: '',
        email: '',
        phone: '',
        carteServices: '',
        isDataArrivedNylus: true,
        url: '',
    };

    async componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        this.props.commonAction.moveGet();
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            nextProps.history.location.state.hashcode &&
            nextProps.history.location.state.service &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.hashcode = nextProps.history.location.state.hashcode;
            currentState.name = nextProps.history.location.state.name;
            currentState.phone = nextProps.history.location.state.phone;
            currentState.email = nextProps.history.location.state.email;
            currentState.service = nextProps.history.location.state.service;
        }

        if (
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            nextProps.history.location.state.carteServices &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            currentState.carteServices = nextProps.history.location.state.carteServices;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url &&
            currentState.service &&
            currentState.email &&
            currentState.phone &&
            currentState.hashcode &&
            currentState.name &&
            !currentState.carteServices &&
            currentState.isDataArrivedNylus
        ) {
            currentState.isDataArrivedNylus = false;
            let url: string = '';
            if (currentState.service == 'Full Service Move') {
                url = `${nextProps.commonState.move[0].full_service_scheduler_url}&utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas`;
            }
            if (currentState.service == 'Utility Bundle') {
                url = `${nextProps.commonState.move[0].full_service_scheduler_url}&utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas&interested_services=internetcable,utilities`;
            }

            currentState.url = url;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url &&
            currentState.service &&
            currentState.email &&
            currentState.phone &&
            currentState.hashcode &&
            currentState.name &&
            currentState.carteServices &&
            currentState.isDataArrivedNylus
        ) {
            currentState.isDataArrivedNylus = false;
            let url = '';

            if (currentState.service == 'La Carte') {
                url = `${nextProps.commonState.move[0].full_service_scheduler_url}&utm_source=platform&utm_medium=web&utm_content=appointment-request&utm_campaign=nylas`;
            }

            currentState.url = url;
        }

        return currentState;
    }

    backToDashboard = () => {
        this.props.history.push({
            pathname: '/signUp',
            state: {
                hashcode: this.state.hashcode,
            },
        });
    };

    backHandler = () => {
        if (this.state.service == 'Full Service Move' || this.state.service == 'Utility Bundle') {
            this.props.history.push({
                pathname: '/servicerequirement',
                state: {
                    hashcode: this.state.hashcode,
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            });
        } else if (this.state.service == 'La Carte') {
            this.props.history.push({
                pathname: '/laCarte',
                state: {
                    hashcode: this.state.hashcode,
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            });
        }
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="callPageMain" style={{ marginTop: '100px' }}>
                <div className="iFrame">
                    <iframe
                        src={this.state.url}
                        allow="encrypted-media"
                        // position="relative"
                        className="iframe"
                        //  referrerPolicy="same-origin"
                        //  sandbox="allow-scripts"
                        id="myFrame"
                    ></iframe>
                </div>

                <div className="buttons-below">
                    <ButtonNew
                        backgroundColor="#FFFFFF"
                        boxShadow={`0 0 3px 0 ${
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }`}
                        color={
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }
                        fontSize="16px"
                        onClick={() => {
                            this.backHandler();
                        }}
                        margin="0 10px"
                    >
                        Back
                    </ButtonNew>
                    <ButtonNew
                        backgroundColor={
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }
                        // color="#FFFFFF"
                        fontSize="16px"
                        onClick={() => {
                            this.backToDashboard();
                        }}
                        margin="0 10px"
                    >
                        Back to Dashboard
                    </ButtonNew>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleACall);
