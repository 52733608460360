import React, { Component } from 'react';
import CheckboxText from '../../components/atoms/CheckboxText';
import '../../styles/filtersTrusted.scss';

type initialProps = {
    Date: any;
    TimeOfDay: any;
    styles?: any;
    changeDate ? : any;
    changeTimeOfDay ? : any
};
type initialState = {
    checked: boolean;
};
export class FiltersTrusted extends Component<initialProps, initialState> {
    state: initialState = {
        checked: false,
    };

    handleDateChange = (e: any) => {
        let array = this.props.Date.map((item: any) => {
            if (item.name === e.target.name) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                };
                return item1;
            } else{
                let item2 = {
                    name: item.name,
                    value: false,
                };
                return item2
                
            };
        });
        this.props.changeDate(array);
    };

    handletimeOfDayChange = (e: any) => {
        let array = this.props.TimeOfDay.map((item: any) => {
            if (item.name === e.target.name) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                };
                return item1;
            } else {
                let item2 = {
                    name: item.name,
                    value: false,
                };
                return item2
            };
        });
        this.props.changeTimeOfDay(array);
    };


    render() {
        return (
            <div className="main-filters-trusted">
                <div className="headDv-trusted">
                    <div className="heads-trusted">Date</div>
                </div>

                {this.props.Date.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className="checkbox-item-trusted">
                                <CheckboxText
                                    className="counting-trusted"
                                    checked={item.value}
                                    onChange={(e: any) => this.handleDateChange(e)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className="line1-trusted"></div>
                <div className="heads-trusted">Time Of The Day</div>
                {this.props.TimeOfDay.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className="checkbox-item-trusted">
                                <CheckboxText
                                    className="counting-trusted"
                                    checked={item.value}
                                    onChange={(e: any) => this.handletimeOfDayChange(e)}
                                    label={`${item.name}`}
                                    name={item.name}
                                />
                            </div>
                        </div>
                    );
                })}

            
            </div>
        );
    }
}

export default FiltersTrusted;
