import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={61.533}
            height={71}
            viewBox="0 0 61.533 71"
        >
            <path
                d="M59.166 49.918v-9.685A15.4 15.4 0 0043.783 24.85H34.1a3.371 3.371 0 00-.819-1.311 3.483 3.483 0 00-2.517-1.056 3.55 3.55 0 00-3.332 2.367H24.85a1.183 1.183 0 01-1.183-1.183v-2.585a3.529 3.529 0 000-6.665V0h-21.3v14.414a3.367 3.367 0 00-1.31.82A3.473 3.473 0 000 17.75a3.55 3.55 0 002.367 3.332v9.684A15.4 15.4 0 0017.75 46.15h9.684a3.529 3.529 0 006.665 0h2.584a1.183 1.183 0 011.183 1.183v2.584a3.529 3.529 0 000 6.665V71h21.3V56.586a3.375 3.375 0 001.31-.82 3.473 3.473 0 001.057-2.516 3.55 3.55 0 00-2.367-3.332zM4.733 2.367H21.3V14.2H4.733zM2.366 17.75a1.153 1.153 0 01.363-.843 1.126 1.126 0 01.82-.341h18.934a1.183 1.183 0 010 2.367H3.55a1.183 1.183 0 01-1.184-1.183zm24.85 26.033H17.75A13.032 13.032 0 014.733 30.767V21.3H21.3v2.367a3.55 3.55 0 003.55 3.55h2.367zm4.733 1.183a1.183 1.183 0 01-2.367 0V26.033a1.183 1.183 0 011.183-1.183 1.155 1.155 0 01.844.362 1.142 1.142 0 01.34.821zm4.733-1.183h-2.366V27.217h9.467A13.032 13.032 0 0156.8 40.233V49.7H40.233v-2.367a3.55 3.55 0 00-3.55-3.55zM56.8 68.633H40.233V56.8H56.8zm2-14.541a1.132 1.132 0 01-.821.341H39.05a1.183 1.183 0 010-2.367h18.933a1.183 1.183 0 011.183 1.183 1.157 1.157 0 01-.366.844z"
                fill={slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'}
            />
        </svg>
    );
}

export default SvgComponent;
