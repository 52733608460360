import {
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_UPDATE,
    SIGNUP_UPDATE_ERROR,
    SIGNUP_UPDATE_SUCCESS,
    LANDING_MOVE_POST,
    LANDING_MOVE_POST_ERROR,
    LANDING_MOVE_POST_SUCCESS,
    LANDING_MOVE_POST_FOR_SECURITY,
    LANDING_MOVE_POST_FOR_SECURITY_ERROR,
    LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
    POST_GHOST_LOGIN,
    POST_GHOST_LOGIN_SUCCESS,
    POST_GHOST_LOGIN_ERROR,
    HAVE_A_PREAPPROVAL,
    HAVE_A_PREAPPROVAL_ERROR,
    HAVE_A_PREAPPROVAL_SUCCESS,
    CREATE_CONATCT_SERVICE_PROVIDERS,
    CREATE_CONATCT_SERVICE_PROVIDERS_SUCCESS,
    CREATE_CONATCT_SERVICE_PROVIDERS_ERROR,
    GET_A_PREAPPROVAL,
    GET_A_PREAPPROVAL_ERROR,
    GET_A_PREAPPROVAL_SUCCESS,
} from './actionTypes';

import { SignupActions, SignupState } from './types';

const initialState: SignupState = {
    signup: [],
    error: null,
    signupUpdate: [],
    landingMovePost: [],
    signUpUpdated: false,
    landingMovePosted: false,
    landingtokenSet: false,
    ghostSignUp: [],
    haveAPreapproval: [],
    cretaeContactServiceProviders: [],
    getAPreapproval: [],
    
};

export default (state = initialState, action: SignupActions) => {
    switch (action.type) {
        case SIGNUP:
            return {
                ...state,
                signup: [],
                error: null,
                status: "loading"
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signup: action.payload.signup,
                error: null,
                status: "success"
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                signup: [],
                error: action.payload.error,
                status: "failed"
            };

        case SIGNUP_UPDATE:
            return {
                ...state,
                signupUpdate: [],
                error: null,
            };
        case SIGNUP_UPDATE_SUCCESS:
            return {
                ...state,
                signupUpdate: action.payload.signupUpdate,
                signUpUpdated: true,
                error: null,
            };
        case SIGNUP_UPDATE_ERROR:
            return {
                ...state,
                signupUpdate: [],
                error: action.payload.error,
            };

        case LANDING_MOVE_POST:
            return {
                ...state,
                landingMovePost: [],
                error: null,
                landingMovePosted: false,
                landingtokenSet: false,
            };
        case LANDING_MOVE_POST_SUCCESS:
            return {
                ...state,
                landingMovePost: action.payload.landingMovePost,
                error: null,
                landingMovePosted: true,
                landingtokenSet: true,
            };
        case LANDING_MOVE_POST_ERROR:
            return {
                ...state,
                landingMovePost: [],
                error: action.payload.error,
                landingMovePosted: false,
                landingtokenSet: false,
            };
        case LANDING_MOVE_POST_FOR_SECURITY:
            return {
                ...state,
                landingMovePost: [],
                error: null,
                landingtokenSet: false,
            };
        case LANDING_MOVE_POST_FOR_SECURITY_SUCCESS:
            return {
                ...state,
                landingMovePost: action.payload.landingMovePost,
                error: null,
                landingtokenSet: true,
            };
        case LANDING_MOVE_POST_FOR_SECURITY_ERROR:
            return {
                ...state,
                landingMovePost: [],
                error: action.payload.error,
                landingtokenSet: false,
            };
        case POST_GHOST_LOGIN:
            return {
                ...state,
                ghostSignUp: [],
                error: null,
            };
        case POST_GHOST_LOGIN_SUCCESS:
            return {
                ...state,
                ghostSignUp: action.payload.ghostSignUp,
                error: null,
            };
        case POST_GHOST_LOGIN_ERROR:
            return {
                ...state,
                ghostSignUp: [],
                error: action.payload.error,
            };
        case HAVE_A_PREAPPROVAL:
            return {
                ...state,
                haveAPreapproval: [],
                error: null,
            };
        case HAVE_A_PREAPPROVAL_SUCCESS:
            return {
                ...state,
                haveAPreapproval: action.payload.haveAPreapproval,
                error: null,
            };
        case HAVE_A_PREAPPROVAL_ERROR:
            return {
                ...state,
                haveAPreapproval: [],
                error: action.payload.error,
            };
        case CREATE_CONATCT_SERVICE_PROVIDERS:
            return {
                ...state,
                cretaeContactServiceProviders: [],
                error: null,
            };
        case CREATE_CONATCT_SERVICE_PROVIDERS_SUCCESS:
            return {
                ...state,
                cretaeContactServiceProviders: action.payload.cretaeContactServiceProviders,
                error: null,
            };
        case CREATE_CONATCT_SERVICE_PROVIDERS_ERROR:
            return {
                ...state,
                cretaeContactServiceProviders: [],
                error: action.payload.error,
            };
        case GET_A_PREAPPROVAL:
            return {
                ...state,
                getAPreapproval: [],
                error: null,
            };
        case GET_A_PREAPPROVAL_SUCCESS:
            return {
                ...state,
                getAPreapproval: action.payload.getAPreapproval,
                error: null,
            };
        case GET_A_PREAPPROVAL_ERROR:
            return {
                ...state,
                getAPreapproval: [],
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
