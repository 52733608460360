import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        // width={39.422}
        // height={25}
        width={props.width ? props.width : 48.222}
        height={props.height ? props.height : 31}
        viewBox="0 0 48.222 31"
    >
        <g data-name="Group 35698">
            <path
                data-name="Path 42877"
                d="M9.672 7.817a2.509 2.509 0 1 0 2.509 2.51 2.51 2.51 0 0 0-2.509-2.51m4.3-1.814a.509.509 0 0 0-.508-.508H5.887a.508.508 0 0 0-.507.508V16.54a.505.505 0 0 0 .155.362 4.237 4.237 0 0 1 8.283 0 .5.5 0 0 0 .155-.362Zm1.447 0V16.54a1.956 1.956 0 0 1-1.955 1.954H5.887a1.956 1.956 0 0 1-1.954-1.954V6.003a1.957 1.957 0 0 1 1.954-1.955h7.577a1.957 1.957 0 0 1 1.955 1.955m22.563-4.048a.508.508 0 0 0-.507-.508H1.955a.509.509 0 0 0-.508.508v21.09a.509.509 0 0 0 .508.508h35.517a.508.508 0 0 0 .507-.508Zm1.447 0v21.09A1.957 1.957 0 0 1 37.475 25H1.955A1.957 1.957 0 0 1 0 23.045V1.955A1.957 1.957 0 0 1 1.955 0h35.517a1.957 1.957 0 0 1 1.954 1.955m-6.008 15.952h-6.559a1.085 1.085 0 0 0 0 2.171h6.559a1.085 1.085 0 0 0 0-2.171m.724-5.025h-14.7a.723.723 0 1 0 0 1.447h14.7a.723.723 0 1 0 0-1.447m0-7.561h-14.7a.724.724 0 1 0 0 1.447h14.7a.724.724 0 1 0 0-1.447m.724 4.5a.723.723 0 0 1-.724.724h-14.7a.723.723 0 1 1 0-1.447h14.7a.723.723 0 0 1 .724.723"
                fill={window.location.pathname.includes('driverlicense') ? '#009CBD' : '#100b28'}
            />
        </g>
    </svg>
);

export default SvgComponent;
