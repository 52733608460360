import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46.493}
      height={25.949}
      viewBox="0 0 46.493 25.949"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupSecurity_prefix__b{fill:${slug.color_shade3}}.signupSecurity_prefix__c{fill:${slug.color_shade1}}`
            : ".signupSecurity_prefix__b{fill:#5b7088}.signupSecurity_prefix__c{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        d="M29.878 5.798l2.463 1.793.265.193v17.6H13.888V7.87l-.042-.056 9.4-6.844zm0 0"
        // fill="#5b7088"
        className="signupSecurity_prefix__b"
      />
      <path
        d="M32.606 2.368v5.415l-.265-.193-2.463-1.793V.568zm0 0"
        fill="#fff"
      />
      <path
        className="signupSecurity_prefix__c"
        d="M38.449 21.133a.659.659 0 00.932 0 9.39 9.39 0 000-13.269.659.659 0 10-.932.931 8.072 8.072 0 010 11.407.659.659 0 000 .931zm0 0"
      />
      <path
        className="signupSecurity_prefix__c"
        d="M41.537 4.775a.659.659 0 000 .932 12.44 12.44 0 010 17.582.659.659 0 00.932.932 13.757 13.757 0 000-19.445.659.659 0 00-.932-.001zm0 0M8.043 7.863a.659.659 0 00-.932 0 9.39 9.39 0 000 13.269.659.659 0 00.932-.931 8.073 8.073 0 010-11.407.659.659 0 000-.931zm0 0"
      />
      <path
        className="signupSecurity_prefix__c"
        d="M4.49 24.413a.659.659 0 00.466-1.124 12.439 12.439 0 010-17.582.659.659 0 00-.932-.932 13.757 13.757 0 000 19.445.656.656 0 00.466.193zm0 0M29.61.067a.565.565 0 00-.3.5v4.122L23.576.514a.565.565 0 00-.665 0L9.676 10.15a.565.565 0 10.665.913l2.979-2.169v16.491a.564.564 0 00.565.565h18.718a.565.565 0 00.565-.565V8.894l2.979 2.169a.565.565 0 00.665-.913L33.17 7.501V2.369a.565.565 0 00-.253-.471L30.189.094a.566.566 0 00-.579-.027zm2.43 24.753H14.452V8.071l8.794-6.4 8.794 6.4zm0-22.147v4l-1.598-1.162v-3.9zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
