import { isEmpty } from 'lodash';
import { isJsonString } from '../utils/helper';

const getMoveKey = () => {
    const moveId: any = localStorage.getItem('moveKey') || '';
    return moveId
}

const getSlug = () => {
    const slug: any = localStorage.getItem('slug');

    if (!isEmpty(slug) && isJsonString(slug)) {
        return JSON.parse(slug)
    } else {
        return null;
    }
}

const getAnalytics = (): any => {
    const windownew: any = window;
    return windownew && windownew.analytics ? windownew.analytics : [];
}

const AnalyticsService: any = {
    pageViewed: ({ page, category_name }: any) => {
        getAnalytics().track(
            'Page Viewed',
            {
                page: page,
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_name: getSlug()?.contact_name || '',
                agent_id: getSlug()?.id || '',
                category_name: category_name || ''
            },
            {
                user_id: getMoveKey(),
            },
        );
    },

    buttonClicked: ({
        page,
        category_name,
        button_copy,
        popup,
        popup_title,
        card,
        card_title,
        card_type,
        banner,
        banner_title,
        form_button,
        form_step_number,
        brand_name,
    }: any) => {
        getAnalytics().track(
            'Button Clicked',
            {
                page: page || 'Home Owner Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_id: getSlug()?.id || '',
                agent_name: getSlug()?.contact_name || '',
                category_name: category_name || '',
                brand_name: brand_name || '',
                button_copy: button_copy || '',
                form_button: form_button || false,
                banner: banner || false,
                banner_title: banner_title || '',
                popup: popup || false,
                popup_title: popup_title || '',
                card: card || false,
                card_title: card_title || '',
                card_type: card_type || '',
                form_step_number: form_step_number || null,
            },
            { user_id: getMoveKey() },
        );
    },

    cardClicked: ({
        page,
        category_name,
        button_copy,
        card,
        card_title,
        card_type,
        eventName,
    }: any) => {
        getAnalytics().track(
            eventName || 'Card Clicked',
            {
                page: page || 'Home Owner Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_id: getSlug()?.id || '',
                agent_name: getSlug()?.contact_name || '',
                category_name: category_name || '',
                button_copy: button_copy || '',
                card: card || false,
                card_title: card_title || '',
                card_type: card_type || '',
            },
            { user_id: getMoveKey() },
        );
    },

    formCompleted: ({
        page,
        category_name,
        button_copy,
        form_name,
        form_button,
        form_pre_filled,
        form_step_number,
        eventName,
    }: any) => {
        getAnalytics().track(
            eventName || 'Form Completed',
            {
                page: page || 'Home Owner Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_id: getSlug()?.id || '',
                agent_name: getSlug()?.contact_name || '',
                category_name: category_name || '',
                button_copy: button_copy || '',
                form_name: form_name || '',
                form_button: form_button || true,
                form_pre_filled: form_pre_filled || false,
                form_step_number: form_step_number || null,
            },
            { user_id: getMoveKey() },
        );
    },

    cardViewed: ({ cardTitle, page, category_name }: any) => {
        getAnalytics().track(
            'Card Viewed',
            {
                page: page || 'Home Owner Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_name: getSlug()?.contact_name || '',
                agent_id: getSlug()?.id || '',
                card_title: cardTitle,
                category_name: category_name || ''
            },
            { user_id: getMoveKey() },
        );
    },

    signedUp: () => {
        getAnalytics().track(
            'Signed Up',
            {
                page: 'Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_name: getSlug()?.contact_name || '',
                agent_id: getSlug()?.id || '',
                source: getSlug()?.contact_name || '',
            },
            { user_id: getMoveKey() },
        );
    },

    signedIn: () => {
        getAnalytics().track(
            'Signed In',
            {
                page: 'Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_name: getSlug()?.contact_name || '',
                agent_id: getSlug()?.id || ''
            },
            { user_id: getMoveKey() },
        );
    },

    formFieldStarted: ({
        page,
        category_name,
        brand_partner_name,
        button_copy,
        form_button,
        form_pre_filled,
        form_field_name,
        form_step_number,
        hasMinimalFields
    }: any) => {

        const dataToTrack: any = {
            page: page || 'Home Owner Dashboard',
            category_name: category_name || 'Started Filling Form Fields',
            brokerage_name: getSlug()?.display_name || '',
            brokerage_id: getSlug()?.brokerage_link || '',
            agent_name: getSlug()?.contact_name || '',
            agent_id: getSlug()?.id || '',
            form_field_name: form_field_name || '',
        }

        if (!hasMinimalFields) {
            dataToTrack.brand_partner_name = brand_partner_name || ''
            dataToTrack.button_copy = button_copy || ''
            dataToTrack.form_button = form_button || false
            dataToTrack.form_pre_filled = form_pre_filled || false
            dataToTrack.form_step_number = form_step_number || null
        }


        getAnalytics().track(
            'Form Started',
            {
               ...dataToTrack
            },
            { user_id: getMoveKey() },
        );
    },


    formFieldInput: ({
        page,
        category_name,
        brand_partner_name,
        button_copy,
        hasMinimalFields,
        form_button,
        form_pre_filled,
        form_field_name,
        form_step_number,
    }: any) => {

        const dataToTrack: any = {
            page: page || 'Dashboard',
            category_name: category_name || '',
            brokerage_name: getSlug()?.display_name || '',
            brokerage_id: getSlug()?.brokerage_link || '',
            agent_name: getSlug()?.contact_name || '',
            agent_id: getSlug()?.id || '',
            form_field_name: form_field_name || '',
            form_pre_filled: form_pre_filled || false,
        };

        if (!hasMinimalFields) {
            dataToTrack.brand_partner_name = brand_partner_name || '';
            dataToTrack.button_copy = button_copy || '';
            dataToTrack.form_button = form_button || false;
            dataToTrack.form_step_number = form_step_number || null;
        }

        getAnalytics().track(
            'Form Field Inputted',
            {
                ...dataToTrack,
            },
            { user_id: getMoveKey() },
        );
    },

    scheduleCallStarted: ({
        page,
        concierge_name,
        appointment_type
    }: any) => {
        getAnalytics().track(
            'Schedule Call Started',
            {
                page: page || 'Dashboard',
                brokerage_name: getSlug()?.display_name || '',
                brokerage_id: getSlug()?.brokerage_link || '',
                agent_name: getSlug()?.contact_name || '',
                agent_id: getSlug()?.id || '',
                concierge_name: concierge_name || '',
                appointment_type: appointment_type || ''
            },
            { user_id: getMoveKey() },
        );
    },
};

export default AnalyticsService;
