import React from 'react';
import styles from './checkmark.module.scss';

const Error = () => {
    return (
        <svg className={`${styles.icon} ${styles.error}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={`${styles.circle} ${styles.error__circle}`} cx="26" cy="26" r="25" fill="none" />
            <path className={`${styles.icon_x}`} fill="none" d="M16 16 L36 36 M16 36 L36 16" />
        </svg>
    );
};

export default Error;
