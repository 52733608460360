import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    const isDouglasElliman = props.className.includes('douglasElliman');
    
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={38.167} height={58.602} viewBox="0 0 38.167 58.602">
            <defs>
                <style>
                    {slug && slug.color_shade1 && slug.color_shade4
                        ? isDouglasElliman
                            ? `.electricityprefix__b{fill:#333333}`
                            : `.electricityprefix__b{fill:${slug.color_shade1}}`
                        : '.electricityprefix__b{fill:#002e62}'}
                </style>
            </defs>
            <path
                d="M14.984 54.928l21.116-35a1.108 1.108 0 00-1.143-1.644l-14.046 1.838 3.356-16.955a.572.572 0 00-1.018-.435L2.488 29.11a1.1 1.1 0 001.032 1.76l15.486-1.569-5.078 25.246a.572.572 0 001.056.381z"
                fill={slug && slug.color_shade1 ? (isDouglasElliman ? '#ffffff' : slug.color_shade4) : '#5b7088'}
            />
            <path
                className="electricityprefix__b"
                d="M38.016 17.512a.907.907 0 00-.861-.373l-15.136 1.973 1.947-9.838a.861.861 0 00-.709-1 .891.891 0 00-1.043.683l-2.179 11.01a.837.837 0 00.229.751.912.912 0 00.767.26l14.557-1.9-20.425 33.85 3.61-17.953a.861.861 0 00-.706-1.006.89.89 0 00-1.045.68L12.41 57.586a.854.854 0 00.562.967.92.92 0 00.313.055.9.9 0 00.771-.427l23.99-39.769a.831.831 0 00-.03-.9z"
            />
            <path
                className="electricityprefix__b"
                d="M16.953 35.014a.861.861 0 00.706 1.006.931.931 0 00.17.016.884.884 0 00.875-.7l1.176-5.875a.836.836 0 00-.218-.743.912.912 0 00-.751-.273l-16.064 1.63L23.135 4.3l-.919 4.862a.861.861 0 00.709 1 .891.891 0 001.043-.683L25.6 1.017a.851.851 0 00-.514-.945.914.914 0 00-1.073.266L.182 30.618a.832.832 0 00-.066.94.9.9 0 00.87.432L17.9 30.276z"
            />
        </svg>
    );
}

export default SvgComponent;
