import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 23.918}
        height={props.height ? props.height : 34.394}
        viewBox="0 0 23.918 34.394"
    >
        <g data-name="Group 35700" fill={window.location.pathname.includes('checklist') ? '#009CBD' : '#100b28'}>
            <path
                data-name="Path 42885"
                d="M23.065 32.576a.371.371 0 0 1-.37.37H1.426a.37.37 0 0 1-.37-.37V2.841a.37.37 0 0 1 .37-.37h5.921l-.129 1.222-.53 1.057H4.414A1.426 1.426 0 0 0 2.99 6.174v23.491a1.426 1.426 0 0 0 1.424 1.424h15.292a1.426 1.426 0 0 0 1.425-1.424V6.174a1.426 1.426 0 0 0-1.425-1.424h-2.273l-.53-1.057-.129-1.222h5.921a.371.371 0 0 1 .37.37ZM5.619 7.439a.526.526 0 0 0 .448.25h11.985a.527.527 0 0 0 .471-.764l-.562-1.12h1.744a.371.371 0 0 1 .37.37v23.491a.371.371 0 0 1-.37.37H4.412a.37.37 0 0 1-.37-.37V6.174a.37.37 0 0 1 .37-.37h1.745l-.561 1.12a.526.526 0 0 0 .022.514M8.201 4.08a.516.516 0 0 0 .052-.181l.3-2.845h7.009l.3 2.845a.517.517 0 0 0 .053.181l1.28 2.554H6.926Zm14.493-2.664h-6.032l-.1-.945A.527.527 0 0 0 16.038 0H8.08a.527.527 0 0 0-.524.471l-.1.945h-6.03A1.426 1.426 0 0 0 .001 2.84v29.735a1.426 1.426 0 0 0 1.425 1.424h21.27a1.426 1.426 0 0 0 1.425-1.424V2.841a1.426 1.426 0 0 0-1.425-1.424"
            />
            <path
                data-name="Path 42886"
                d="M10.704 15.521a.528.528 0 0 0 .741.017l3.819-3.6a.527.527 0 0 0-.723-.768l-3.44 3.242-1.505-1.557a.528.528 0 0 0-.758.734Z"
            />
            <path
                data-name="Path 42887"
                d="M10.704 21.142a.528.528 0 0 0 .741.017l3.819-3.6a.527.527 0 1 0-.723-.767l-3.44 3.241-1.505-1.557a.528.528 0 0 0-.758.734Z"
            />
            <path
                data-name="Path 42888"
                d="M10.704 26.732a.528.528 0 0 0 .741.017l3.819-3.6a.527.527 0 1 0-.723-.767l-3.44 3.242-1.505-1.557a.528.528 0 0 0-.758.734Z"
            />
        </g>
    </svg>
);

export default SvgComponent;
