import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import './Assets/font/Euclid/EuclidCircularA-Bold.otf';
import './Assets/font/Euclid/EuclidCircularA-BoldItalic.otf';
import './Assets/font/Euclid/EuclidCircularA-Light.otf';
import './Assets/font/Euclid/EuclidCircularA-LightItalic.otf';
import './Assets/font/Euclid/EuclidCircularA-Medium.otf';
import './Assets/font/Euclid/EuclidCircularA-MediumItalic.otf';
import './Assets/font/Euclid/EuclidCircularA-Regular.otf';
import './Assets/font/Euclid/EuclidCircularA-RegularItalic.otf';
import './Assets/font/Euclid/EuclidCircularA-Semibold.otf';
import './Assets/font/Euclid/EuclidCircularA-SemiboldItalic.otf';
import history from './routes/History';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Provider } from 'react-redux';
import store from './store';
import UIControlProvider from './UIContext';

console.log(`${process.env.REACT_APP_MOVEEASY_ENV} ${process.env.REACT_APP_VERSION}`)

ReactDOM.render(
    <UIControlProvider>
        <ErrorBoundary history={history}>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    </UIControlProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
