import _ from "lodash";
import history from "../routes/History";

export default {
    /**
    * Formats given number. to given locale.
    * @param Object of type FormatCurrencyProps. Locale of currency which defaults to en-US. value os number which you want to format.
    * @returns string.
    */
    formatCurrency: ({ locale = "en-US", value = 0 }: FormatCurrencyProps) => new Intl.NumberFormat(locale).format(Math.round(value)),

    /**
    * Take user to desired route of website.
    * @param path for path of page.
    */
    navigateToRoute: (path: string) => history.push(path),
};

export interface FormatCurrencyProps {
    locale?: string;
    value: number;
};


/**
 * @returns String - button text for contact cards of service agent/brokerage
 */
export const renderContactButtonText = (serviceAgentData: any, serviceProviders: any) => {
    if (serviceAgentData) {
        if (serviceAgentData.email) {
            return 'Contact Me';
        } else {
            return 'Learn More';
        }
    } else {
        if (serviceProviders) {
            if (serviceProviders.email) {
                return 'Contact Me';
            } else {
                return 'Learn More';
            }
        }
    }
};


/**
 * Triggers API for update checklist task complete pecentages.
 */
export const updateChecklistTask = (percentage: number, action: any) => {
    const checklistTask: any = localStorage.getItem('activeChecklistTask');
    
    if (!_.isEmpty(checklistTask)) {
        const task = JSON.parse(checklistTask);

        if (window.location.pathname.includes(task.url)) {
            action.updateServicePercentage({
                data: {
                    completed_percentage: percentage,
                },
                service_id: task.id,
            });

            if (percentage === 100) localStorage.removeItem('activeChecklistTask');
        }
    } 
}
