import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            className="logoutButtonIcon logoutText"
            viewBox="0 0 18 18"
            {...props}
        >
            <path d="M0 0h18v18H0z" fill="none" />
            <path
                className="logout_a"
                d="M12.533 5.611l-1 .956 1.823 1.756H6.173v1.355h7.187l-1.827 1.749 1 .962L16.067 9zM3.346 4.256H9V2.9H3.346a1.389 1.389 0 00-1.413 1.356v9.489A1.389 1.389 0 003.346 15.1H9v-1.355H3.346z"
                fill="#6b6c6f"
            />
        </svg>
    );
}

export default SvgComponent;
