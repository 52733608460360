import React, { Component } from 'react';
import './homeSecurity.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import Footer from './Footer';
import './Summary.scss';
import callIcon from '../../Assets/images/InternetAndCable/call_icon.svg'
import arrowDown from '../../Assets/images/InternetAndCable/arrow_down.svg'
import * as HomeSecurityAction from '../../store/homeSecurity/actions';
import Button from '../../components/atoms/Button';
import iccalendar from '../../Assets/images/calendar.svg';
import Modal from '../../components/atoms/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import insuranceimg from '../../Assets/images/insurance-img.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
const moment = require('moment');

type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    homeSecurityAction?: any;
    homeSecurityState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    reschedule_link: any;
    cancel_link: any;
    appointmentTime: any;
    isShowContent: boolean;
    isShowHideText: string;
    summaryData: any[];
    scheduleData: any[];
    isHSSummaryArrived: boolean;
    status: string;
    isHomeOwner: string | null;
    calendlyOpen: boolean;
    scheduleClicked: boolean;
    appointment_details: any;
    isDouglasElliman: boolean;
    home_security_scheduler_url: string;
    nylasModalClosed: boolean;
    knowMoreLink: string;
    isSubscriptionData: boolean;
    subscription_details: any[];
    concierge_phone: any;
    destination_full_address: any;
}

class Summary extends Component<initialProps, initialState> {
    state = {
        reschedule_link: null,
        cancel_link: null,
        appointmentTime: '',
        isShowContent: false,
        isShowHideText: 'More Details',
        summaryData: [],
        scheduleData: [],
        isHSSummaryArrived: false,
        status: '',
        isHomeOwner: 'false',
        calendlyOpen: false,
        scheduleClicked: false,
        appointment_details: '',
        isDouglasElliman: false,
        home_security_scheduler_url: '',
        nylasModalClosed: false,
        knowMoreLink: '',
        isSubscriptionData: false,
        subscription_details: [],
        concierge_phone: '',
        destination_full_address: ''
    };

    componentDidMount() {
        let moveId: any = localStorage.getItem('moveId');
        this.props.homeSecurityAction.getHSSummary({ moveId });
        this.props.dashboardAction.getSubscriptionDetails();
        this.setState({ isHomeOwner: localStorage.getItem('isHomeOwner') })
        this.props.commonAction.moveGet();
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
            service_name: "SECURITY_EXPERT"
        };
        if (hash_code) {
            this.props.commonAction.appointmentGet(payload);
        }
    }

    componentDidUpdate(prevProps: any) {
        const curI = this.props && this.props.homeSecurityState;
        const moveData = this.props && this.props.commonState;
        const prev = prevProps && prevProps.commonState;
        const curr = this.props && this.props.commonState;
        const subscriptionDetails = this.props && this.props.homeOwnerDashboardState;
        if(prev.header.length !== curr.header.length || this.state.knowMoreLink === ""){
            curr.header.map((item: any) => {
                if (item.name && (item.slug == 'home-insurance' || item.slug == 'insurance')) {
                    this.setState({knowMoreLink: "/dashboard/" + item.slug})
                }
            })
        }
        if (
            curI.summary &&
            Object.keys(curI.summary).length > 1 &&
            curI.summary.status === 200
        ) {
            delete curI.summary.status
            this.state.summaryData = { ...curI.summary }
            this.state.status = curI.summary[0].status
        }
        // if (prevProps.homeSecurityState.isHSSummaryArrived != curI.isHSSummaryArrived ) this.setState({isHSSummaryArrived: true})

        if (prev.move !== curr.move && moveData.move[0]) {
            this.state.home_security_scheduler_url = moveData.move[0].home_security_scheduler_url
            this.setState({
                concierge_phone: moveData.move[0].assigned_concierge.phone,
                destination_full_address: moveData.move[0].destination_full_address
            })
        }
        if(prev.move !== curr.move){
            let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
                service_name: "SECURITY_EXPERT"
            };
            if (hash_code) {
                this.props.commonAction.appointmentGet(payload);
                this.setState({isHSSummaryArrived: true})
            }            
        }

        if(prev.appointmentDetails !== curr.appointmentDetails && curr.appointmentDetails.length !== 0){
            this.setState({scheduleData: this.props.commonState.appointmentDetails})
        }
        if (this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0 &&
            prevProps.homeOwnerDashboardState.getSubscriptionDetails != this.props.homeOwnerDashboardState.getSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((element: any) => {
                if(element.service.slug === 'home-security'){
                    this.setState({ isSubscriptionData: true, subscription_details: subscriptionDetails.getSubscriptionDetails.results })
                }
            })
        }
    }
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
        }
        return currentState;
    }

    onShowHideView = (isShow: boolean) => {
        let tempState: any = {
            isShowContent: isShow,
        }
        if (isShow) tempState.isShowHideText = 'Show Less'
        else tempState.isShowHideText = 'More Details'
        this.setState(tempState)
    }

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true
        });
    };

    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false, nylasModalClosed: true });
    };

    render() {

        // let displayTime: any[] = [], formattedDate = "", time = "";
        let formattedDate = ""
        // if (Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Scheduled") {
        //     time = (Object.values(this.state.summaryData)[0]['appointment_time'])
        //     displayTime = time.split(" ");
        //     formattedDate = moment(Object.values(this.state.summaryData)[0]['appointment_date']).format('MMMM Do, YYYY');
        // }
        if(this.state.scheduleData.length !==0 && this.state.appointmentTime){
            // time = this.state.appointmentTime
            // displayTime = time.split(" ");
            formattedDate = moment(this.state.appointmentTime).format('hh:mm A MMMM Do, YYYY');
        }
        const currencyFormat = (num: string) => {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Order summary home security',
                url: ''
            }
        ]

        return (
            <div>
                {!this.state.isHSSummaryArrived ? (<div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 99,
                        flexDirection: 'column'
                    }}>
                    <CircularProgress />
                    <div style={{marginTop: '15px'}}> Working on it.Please Wait... </div>
                </div>) :
                    Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Warm Transfer" ? (
                        <>
                            <div className="main-head">
                                <h2 className="heading">Home Security</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>
                            <p className='warmTransferClass'>
                                We connected you with our security specialist. They will be able to help you select the right provider to for your home securuty & smart home automation needs.
                            </p>
                        </>
                    ) : this.state.scheduleData.length !==0 && !this.state.isSubscriptionData && this.state.appointmentTime && (Object.values(this.state.summaryData).length === 0 || Object.values(this.state.summaryData)[0]['status'] === "Scheduled") ? (
                        <>
                            <div className="main-head">
                                <h2 className="heading">Home Security</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>
                            <div className="summarydetailbox">
                                <div className="summarydetailbox_head">
                                    <h2>Call Scheduled</h2>
                                    <p>Our concierge team will call you to complete your order and schedule<br></br> installation.</p>
                                </div>
                                <div className="summarydetailbox_body">
                                    <div className="borderbox">
                                        <div className="borderbox_top">
                                            <h3>Call schedule details</h3>
                                            <button className="topbtn" onClick={() => this.calendlyHandleReschedule()}>Reschedule</button>
                                        </div>
                                        <p className="scheduled_datetime">
                                            <img src={iccalendar} alt="calender" />
                                            {`${formattedDate}`}
                                        </p>
                                    </div>
                                    <div className="buttom_btd_div_hs">
                                        <Button
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                        }
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                            onClick={() => {
                                                this.props.history.push('/dashboard');
                                            }}
                                        >
                                            Back To Dashboard
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData)[0]['status'] === "Sold" ? (
                        <>
                            <div className="greybg">
                                <div className="main-head">
                                    <h2 className="heading">Home Security</h2>
                                    <div className='mb-4'>
                                        <BreadCrumb breadList={breadList} />
                                    </div>
                                </div>

                                <div className='summaryHeader'>
                                    <h3>Subscription Details Home Security</h3>
                                    <p>Thank you for your order! If you need to update or manage your plan, please<br></br>reach out to your dedicated Concierge.</p>
                                </div>


                            </div>
                            {Object.values(this.state.summaryData).length > 0 && Object.values(this.state.summaryData).map((item, index) => {
                                return (
                                    <>
                                        <div className='summaryCards summarybox summarybox2'>
                                            {index == 0 ? (
                                                <div className="summarybox_head">
                                                    <h5 className='summaryHeader'>Plan Details</h5>
                                                    {/* <button className="summarybox_headbtn"
                                     onClick={() => {
                                        this.props.history.push('/dashboard/home-security/edit');
                                    }}
                                    >Edit Current Plan</button> */}
                                                </div>
                                            ) : ''}
                                            <div className="summarybox_body">
                                                <div className='summaryContent'>
                                                    <div className='summaryContent_l'>
                                                        <div className="img_wrapper">
                                                            <img src={item['plan_detail']['logo']} alt="plan detail logo" />
                                                            <p className="img_name">{item['plan_detail']['provider_name']}</p>
                                                        </div>
                                                    </div>
                                                    <div className='summaryContent_r'>
                                                        <div className="plan_wrapper">
                                                            <p>Monthly Premium</p>
                                                            <p className="plan_detail">
                                                                <span>$</span><span className="palnvalue">{item['plan_detail']['price'] ? currencyFormat(item['plan_detail']['price']) : currencyFormat(item['paid_amount'])}
                                                                    <span className="req">
                                                                        *
                                                                    </span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="call_bar hw_summary">
                                                    <p>Want to change your subscription? Call</p>
                                                    <span>
                                                        Dedicated Concierge
                                                        <a href={`tel:${item['concierge_phone_number']}`}>
                                                            <span className="call_icon">
                                                                <img src={callIcon} alt="phone" />
                                                            </span>
                                                            {item['concierge_phone_number']}</a>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={`summaryfooter ${this.state.isShowContent ? 'showcontent' : ''}`}>
                                                <div className="summary_hidden_content">
                                                    <div className="content_row">

                                                        <div className="content_col33">
                                                            <p className="lab_val">Account Number</p>
                                                            <p><b>{item['account_no']}</b></p>
                                                        </div>
                                                        <div className="content_col33">
                                                            <p className="lab_val">Address</p>
                                                            <p><b>{item['destination_address']}</b></p>
                                                        </div>
                                                        <div className="content_col33">
                                                            <p className="lab_val">Installation Date</p>
                                                            <p><b>{moment(item['install_date']).format('MMMM YYYY')}</b></p>
                                                        </div>
                                                        {/* <div className="content_col33">
                                                        <p className="lab_val">Plan Details</p>
                                                        <p>Internet 100 Mbps</p>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <button className="readmorebtn"
                                                    onClick={() =>
                                                        this.onShowHideView(!this.state.isShowContent)
                                                    }
                                                >{this.state.isShowHideText}
                                                    <img src={arrowDown} alt="arrow icon" />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            <div className="utility_box">
                            <div className="utility_boxl">
                                <div>
                                    <img src={insuranceimg} alt="" />
                                </div>
                            </div>
                            <div className="utility_boxr">
                                <div>
                                    <h3 className="utility_title">Get Insurance Quotes In Minutes</h3>
                                    <p>Compare personalized insurance quotes from multiple provides all in one place in minutes! Advertising Disclosure</p>
                                    <Button className="gotoutility_btn"
                                        backgroundColor="#fff"
                                        color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug && slug.primary_color
                                            : '#273E59'
                                        }
                                        border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug && slug.primary_color
                                            : '#273E59'
                                        }`}
                                        borderRadius="4px"
                                    onClick={() => {
                                            this.props.history.push(this.state.knowMoreLink)
                                    }}
                                    >Learn More</Button>
                                </div>
                            </div>
                        </div>

                            <div className="bittom_btn_div havetwobtns">
                                {/* <button className="borderbtn">Explore New Plans</button> */}
                                <Button
                                    onClick={() => {
                                        History.push('/dashboardhomeowner');
                                    }}
                                >
                                    Back To Dashboard
                                </Button>
                            </div>
                            <Footer />
                        </>
                    ) : this.state.isSubscriptionData && this.state.subscription_details && this.state.subscription_details.length > 0 ? (
                        <>
                        <div className="greybg">
                            <div className="main-head">
                                <h2 className="heading">Home Security</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>

                            <div className='summaryHeader'>
                                <h3>Subscription Details Home Security</h3>
                                <p>Thank you for your order! If you need to update or manage your plan, please<br></br>reach out to your dedicated Concierge.</p>
                            </div>
                        </div>
                        {this.state.subscription_details.map((element: any) => {
                            if(element['service']['name'] === 'Home Security'){
                                return(
                                    <div className='summaryCards summarybox summarybox2'>
                                            <div className="summarybox_head">
                                                <h5 className='summaryHeader'>Your previous policy</h5>
                                                
                                                <button className="summarybox_headbtn"
                                                    onClick={() => {
                                                        this.props.history.push({
                                                            pathname: '/dashboard/home-security/edit',
                                                            state: { detail: this.state.subscription_details , from: 'summary' }
                                                        })
                                                    }}
                                                >Edit Current Plan</button>
                                            </div>
                                        <div className="summarybox_body">
                                            <div className='summaryContent'>
                                                <div className='summaryContent_l'>
                                                    <div className="img_wrapper">
                                                        {element['provider_logo'] ? (<img src={element['provider_logo']} alt="plan detail logo" />) : ""}
                                                        <p className="img_name">{element['provider']}</p>
                                                    </div>
                                                </div>
                                                <div className='summaryContent_r'>
                                                    <p className="lab_val">Monthly Plan</p>
                                                    <p className="plan_detail">
                                                        <span>$</span><span className="palnvalue">{element['price'] ? currencyFormat(element['price']) : '-'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="call_bar hw_summary">
                                                <p>Have any customer service related issue? Call</p>
                                                <span>
                                                    Dedicated Concierge
                                                    <a href={`tel:${this.state.concierge_phone}`}>
                                                        <span className="call_icon">
                                                            <img src={callIcon} alt="phone" />
                                                        </span>
                                                        {this.state.concierge_phone}</a>
                                                </span>
                                            </div>
                                        </div>

                                        <div className={`summaryfooter ${this.state.isShowContent   ? 'showcontent' : ''}`}>
                                            <div className="summary_hidden_content">
                                                <div className="content_row">
                                                    {/* <div className="content_col33">
                                                        <p className="lab_val">Installation Date</p>
                                                        <p>{moment(element['install_date']).format('MMMM YYYY')}</p>
                                                    </div> */}
                                                    <div className="content_col33">
                                                        <p className="lab_val">Account Number</p>
                                                        <p><b>{element['account_number']}</b></p>
                                                    </div>
                                                    <div className="content_col33">
                                                        <p className="lab_val">Address</p>
                                                        <p><b>{this.state.destination_full_address}</b></p>
                                                    </div>
                                                    <div className="content_col33">
                                                        <p className="lab_val">Plan Details</p>
                                                        <p><b>{element['service']['name']}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="readmorebtn"
                                                onClick={() =>
                                                    this.onShowHideView(!this.state.isShowContent)
                                                }
                                            >{this.state.isShowHideText}
                                                <img src={arrowDown} alt="arrow icon" />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        <div className="utility_box">
                        <div className="utility_boxl">
                            <div>
                                <img src={insuranceimg} alt="" />
                            </div>
                        </div>
                        <div className="utility_boxr">
                            <div>
                                <h3 className="utility_title">Get Insurance Quotes In Minutes</h3>
                                <p>Compare personalized insurance quotes from multiple provides all in one place in minutes! Advertising Disclosure</p>
                                <Button className="gotoutility_btn"
                                    backgroundColor="#fff"
                                    color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug && slug.primary_color
                                        : '#273E59'
                                    }
                                    border={`1px solid ${
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug && slug.primary_color
                                        : '#273E59'
                                    }`}
                                    borderRadius="4px"
                                onClick={() => {
                                    this.props.history.push(this.state.knowMoreLink)
                                }}
                                >Learn More</Button>
                            </div>
                        </div>
                    </div>

                        <div className="bittom_btn_div havetwobtns">
                            {/* <button className="borderbtn">Explore New Plans</button> */}
                            <Button
                                onClick={() => {
                                    History.push('/dashboardhomeowner');
                                }}
                            >
                                Back To Dashboard
                            </Button>
                        </div>
                        <Footer />
                    </>
                    ) : ""
                }
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="scheduleCallMoving"
                    >
                        <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>

                        {(this.state.scheduleClicked) ? (
                            <div className="iFrame">
                                <iframe
                                        src={`${ this.state.reschedule_link
                                                ? this.state.reschedule_link
                                                : this.state.home_security_scheduler_url
                                                ? this.state.home_security_scheduler_url
                                                : null
                                        }
                                    `}
                                        
                                    allow="encrypted-media"
                                    className="iframe"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        homeSecurityAction: bindActionCreators(HomeSecurityAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeSecurityState: state.homeSecurity,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
