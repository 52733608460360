import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 22.832}
            height={props.height ? props.height : 31.134}
            viewBox="0 0 22.832 31.134"
        >
            <defs>
                <style>
                    {/* {".prefix__b{fill:#6b6c6f}"} */}
                    {`.homeAddressSvgprefix__b{fill:${
                        window.location.pathname.includes('address') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}
          .homeAddressSvgprefix__d{fill:${
              window.location.pathname.includes('address') && slug && slug.primary_color
                  ? `${slug.primary_color} !important`
                  : '#bebebe !important'
          }}`}
                </style>
            </defs>
            <path
                d="M.519 11.419c0 6.019 10.9 19.2 10.9 19.2s10.9-13.18 10.9-19.2a10.9 10.9 0 10-21.8 0zm10.9-6.746l6.746 5.189h-2.079v6.227h-9.34v-6.23H4.67zm0 0"
                // fill="#bebebe"
                className="homeAddressSvgprefix__d"
            />
            <path
                className="homeAddressSvgprefix__b"
                d="M11.416 31.134a.52.52 0 01-.4-.188C10.566 30.4 0 17.55 0 11.416a11.416 11.416 0 0122.832 0c0 6.134-10.566 18.986-11.016 19.53a.52.52 0 01-.4.188zm0-30.1A10.39 10.39 0 001.038 11.416c0 5.123 8.425 15.938 10.378 18.375 1.953-2.438 10.378-13.253 10.378-18.375A10.39 10.39 0 0011.416 1.038zm0 0"
            />
            <path
                className="homeAddressSvgprefix__b"
                d="M18.478 9.449L11.732 4.26a.519.519 0 00-.633 0L4.354 9.449a.519.519 0 00.316.93h1.557v5.708a.519.519 0 00.519.519h9.34a.519.519 0 00.519-.519v-5.708h1.557a.519.519 0 00.316-.93zm-8.1 6.119v-3.114h2.076v3.113zm5.708-6.227a.519.519 0 00-.519.519v5.708h-2.075v-3.633a.519.519 0 00-.519-.519H9.859a.519.519 0 00-.519.519v3.632H7.265V9.86a.519.519 0 00-.519-.519h-.55l5.22-4.015 5.22 4.015zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
