import React, { useState } from 'react';
import { ReactComponent as DropDown } from '../../../../Assets/images/HomeValuation/expandCircle.svg';
import { ReactComponent as Calculator } from '../../../../Assets/images/HomeValuation/calculator.svg';
import './Main.scss';
import { numberWithCommas } from '../../../../utils/helper';
import CommonService from '../../../../utils/common-functions';
import AnalyticsService from '../../../../pages/analytics-service';

//TODO: Color codes move to constants
function HomeEquityViewHaloc({ totalHomeEquity, remainingLoan, currentAvgValuation, analyticsData }: any) {

    const [homeEquityView, setHomeEquityView] = useState(false);
    let estimatedHomeValue = currentAvgValuation;
    let allowedOutstandingLoan = (80 / 100) * estimatedHomeValue;// 80% * estimatedHomeValue
    const estimatedMorgageBalance = totalHomeEquity - estimatedHomeValue;
    const maxAvailableHaloc = allowedOutstandingLoan - remainingLoan;
    const openHomeEquityView = () => {
        setHomeEquityView(!homeEquityView);

        analyticsData && AnalyticsService.buttonClicked({
            ...analyticsData,
            eventName: 'HELOC Button Clicked',
            category_name: 'Valuation HELOC Clicked',
            button_copy: 'View my HELOC breakdown'
        });

    }
    const checkNumber = (amount: any) => {
        if (Math.sign(amount) == -1) {
            return `-$${numberWithCommas(Math.abs(amount))}`;
        } else {
            return `$${numberWithCommas(amount)}`;
        }
    }
    return (
        <div className="HomeEquityViewHaloc">
            <div className="HomeEquityViewHalocMain" onClick={openHomeEquityView}>
                <div className="HomeEquityViewBoxHeading">
                    <div className="HomeEquityViewHalocBox">
                        <Calculator fill={homeEquityView ? "#0066F5" : "#6B6C6F"} />
                    </div>
                    <div className={`HomeEquityViewHalocBox1 ${homeEquityView && 'active'}`}>View my HELOC breakdown</div>
                    <DropDown fill={homeEquityView ? "#0066F5" : "#6B6C6F"} className={`${homeEquityView ? 'openIcon' : 'closeIcon'}`} />
                </div>
                {homeEquityView &&
                    <div className="HomeEquityViewHalocBoxData">
                        <div className="HomeEquityViewHalocData">
                            <div className="HomeEquityViewHalocData-boxPercentage">
                                <div className='pr'>
                                    <span className={`HomeEquityViewHalocData-amount ${Math.sign(estimatedHomeValue) == -1 && 'red'}`}>{checkNumber(estimatedHomeValue)}</span>
                                    <span className="HomeEquityViewHalocDataVal">Est. home value</span>
                                </div>
                                <div>
                                    <span className="HomeEquityViewHalocData-percentage"><span className='axe'>x</span>80%</span>
                                </div>
                            </div>
                            <div className="HomeEquityViewHalocBox1">
                                <span className={`HomeEquityViewHalocData-amount ${Math.sign(allowedOutstandingLoan) == -1 && 'red'}`}>{checkNumber(allowedOutstandingLoan)}</span>

                            </div>
                            <div className="HomeEquityViewHalocBox1 borderBottom">
                                <span className={`HomeEquityViewHalocData-amount ${Math.sign(estimatedMorgageBalance) == -1 && 'red'}`}>
                                    {checkNumber(estimatedMorgageBalance)}
                                </span>
                                <span className='estval'>Est. mortgage balance</span>
                            </div>
                            <div className="HomeEquityViewHalocBox1">
                                <span className='HomeEquityViewHalocData-amount available-heloc'>
                                    {`${Math.sign(maxAvailableHaloc) == -1 ? `-$${CommonService.formatCurrency({ value: maxAvailableHaloc })}` : `$${CommonService.formatCurrency({ value: maxAvailableHaloc })}`}`}</span>
                                <span className='estval'>Max. Available HELOC</span>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default HomeEquityViewHaloc;
