import React, { useState } from 'react';

import './Menu.scss';
import SidNavData from '../SideNavData';

const Menu = () => {
    const [selected, setSelecetd] = useState('Home Value Estimation');
    return (
        <>
            <div className="scrollmenu">
                {SidNavData.map((ele, i) => {
                    return (
                        <a
                            key={i}
                            id="#anchor"
                            style={{ scrollMarginTop: 100 }}
                            onClick={() => {
                                setSelecetd(ele.title);
                            }}
                            href={ele.href}
                            className={
                                selected === ele.title
                                    ? 'scrollMenuContainerActive anchor'
                                    : ' scrollMenuContainer anchor'
                            }
                        >
                            {ele.title}
                        </a>
                    );
                })}
            </div>
        </>
    );
};

export default Menu;
