import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import Footer from './Footer';
import './EditPlan.scss';
import SelectInput from '../../components/atoms/SelectInput';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
// import { InputLabel } from '@material-ui/core';
// import Button from '../../components/atoms/Button';
import * as ServiceActions from '../../store/savings/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    location?: any;
    commonAction?: any;
    commonState?: any;
    serviceAction?: any;
    serviceState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    insuranceProvider: any;
    insuranceProviderOptions: any;
    insuranceDeductableAmount: string;
    insuranceOtherProvider: string;
    insuranceAmount: string;
    insuranceAccountNumber: string;
    insuranceId: string;
    isHomeOwner: string | null;
    serviceId: string;
    moveId: string;
    submitForm: boolean;
};

class EditPlan extends Component<initialProps, initialState> {
    state: initialState = {
        insuranceProvider: null,
        insuranceProviderOptions: [],
        insuranceDeductableAmount: '',
        insuranceOtherProvider: '',
        insuranceAmount: '',
        insuranceAccountNumber: '',
        insuranceId: '',
        isHomeOwner: 'false',
        serviceId: '',
        moveId: '',
        submitForm: false,
    };

    componentDidMount() {
        let moveId: any = localStorage.getItem('moveId');
        this.setState({ isHomeOwner: localStorage.getItem('isHomeOwner'), moveId: moveId })
        this.props.serviceAction.getInsuranceProvider();
        this.props.dashboardAction.getSubscriptionDetails();
        this.props.serviceAction.getDigitalServiceProvider();

    }
    componentDidUpdate = (prevProps: initialProps) => {
        if(prevProps.homeOwnerDashboardState.updateSubscriptionDetails !== this.props.homeOwnerDashboardState.getSubscriptionDetails && (this.props.history.location.state.from !== 'summary' || this.state.submitForm)){
            if(this.props.homeOwnerDashboardState.updateSubscriptionDetails?.id){
                this.props.history.push('/dashboard/home-insurance/summary');
            }
        }
        if (
            prevProps.serviceState.getInsuranceProvider !== this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.isGetInsuranceProvider
        ) {
            let insuranceProvider: any = [];

            this.props.serviceState.getInsuranceProvider.map((item: any) => {
                insuranceProvider.push({ label: item.name, value: item.name, logo: item.logo });
            });
            insuranceProvider.push({ label: 'Other', value: 'Other' });

            this.setState({
                insuranceProviderOptions: insuranceProvider,
            });
        }
        setTimeout(() => {
            if (this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0 &&
                prevProps.homeOwnerDashboardState.getSubscriptionDetails != this.props.homeOwnerDashboardState.getSubscriptionDetails
            ) {
                this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((item: any) => {
                    if(item.service.name === 'Home Insurance'){
                        let options: any = this.state.insuranceProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        
                        this.setState({
                            insuranceId: item.id,
                            insuranceProvider: provider,
                            insuranceDeductableAmount: item.deductible,
                            insuranceOtherProvider: item.provider,
                            insuranceAmount: item.price,
                            insuranceAccountNumber: item.account_number,
                            serviceId: item.service_id
                        });
                
                    }
                })

            }
        }, 1000);
    }

    onChange = (e: any, name: string) => {
        if (
            name == 'insuranceAmount' ||
            name == 'insuranceDeductableAmount'
        ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/[^\d.]/g, '') });
        } else if (
            name == 'insuranceAccountNumber'
        ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/\D/g, '') });
        } else {
            this.setState({ ...this.state, [name]: e.target.value });
        }
    };


    insurancePostSubcriptionDetails = () => {
        if (
            this.state.insuranceAmount &&
            this.state.insuranceAccountNumber &&
            this.state.insuranceDeductableAmount &&
            this.state.insuranceProvider &&
            this.state.insuranceProvider.value &&
            ((this.state.insuranceProvider.value == 'Other' && this.state.insuranceOtherProvider) ||
                this.state.insuranceProvider.value != 'Other')
        ) {
            if (this.state.insuranceId) {
                let payload = {
                    id: this.state.insuranceId,
                    body: {
                        id: this.state.insuranceId,
                        service_id: this.state.serviceId ? this.state.serviceId : "",
                        provider:
                            this.state.insuranceProvider &&
                            this.state.insuranceProvider.value &&
                            this.state.insuranceProvider.value != 'Other'
                                ? this.state.insuranceProvider.value
                                : this.state.insuranceProvider &&
                                  this.state.insuranceProvider.value &&
                                  this.state.insuranceProvider.value == 'Other'
                                ? this.state.insuranceOtherProvider
                                : '',
                        provider_logo:
                            this.state.insuranceProvider &&
                            this.state.insuranceProvider.logo &&
                            this.state.insuranceProvider.logo != 'Other'
                                ? this.state.insuranceProvider.logo
                                : this.state.insuranceProvider &&
                                    this.state.insuranceProvider.logo &&
                                    this.state.insuranceProvider.logo == 'Other'
                                ? this.state.insuranceOtherProvider
                                : '',
                        price: this.state.insuranceAmount,
                        account_number: this.state.insuranceAccountNumber,
                        move: this.state.moveId,
                        deductible: this.state.insuranceDeductableAmount,
                    },
                };
                this.setState({submitForm: true})
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                // History.push('/dashboard/home-insurance/summary');
            }
        }
    };

    onSelect = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };

    render() {

        const breadList = [
            {
                name: 'Edit Insurance Details',
                url: ''
            }
        ]

        return (
            <div>
                <>
                    <div className="greybglong">
                        <div className="main-head">
                            <h2 className="heading">Insurance</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className='summaryHeader'>
                            <h3>Edit Insurance Details</h3>
                            <p>Update your subscription plan details.</p>
                        </div>
                    </div>

                    <div className="form_section">
                        <form>
                            <div className="form_box">
                                <div className="edit_securityform edit_securityformleft">
                                    <div className="HomeOwnerDashboardformDiv">
                                        <div className="formTitleFlexDiv">
                                            {/* <div className="titleServiceName">Insurance</div> */}
                                            {/* <div className="imnotsure">I am not sure</div> */}
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <SelectInput
                                                        label={'What insurance provider do you have today?'}
                                                        value={
                                                            this.state.insuranceProvider && this.state.insuranceProvider.value
                                                        }
                                                        onChange={(e: any) => this.onSelect(e, 'insuranceProvider')}
                                                        placeholder={
                                                            this.state.insuranceProvider && this.state.insuranceProvider.label
                                                                ? this.state.insuranceProvider.label
                                                                : 'Select insurance provider'
                                                        }
                                                        options={this.state.insuranceProviderOptions}
                                                        // inputLa
                                                    />
                                                    <div className="error">
                                                        {this.state.insuranceProvider == null
                                                            ? 'Enter insurance Provider'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.insuranceProvider &&
                                            this.state.insuranceProvider.value &&
                                            this.state.insuranceProvider.value == 'Other' ? (
                                                <div className="inputFlexDiv2">
                                                    <div className="inputDiv1">
                                                        <CustomizedInput
                                                            labelName="Enter provider name"
                                                            value={this.state.insuranceOtherProvider}
                                                            onChange={(e: any) => this.onChange(e, 'insuranceOtherProvider')}
                                                            placeholder=""
                                                        />
                                                        <div className="error">
                                                            {!this.state.insuranceOtherProvider
                                                                ? 'Enter Insurance Provider'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="What is your deductible?"
                                                        value={this.state.insuranceDeductableAmount}
                                                        onChange={(e: any) => this.onChange(e, 'insuranceDeductableAmount')}
                                                        placeholder=""
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                    />
                                                    <div className="error">
                                                        {!this.state.insuranceDeductableAmount
                                                            ? 'Enter Insurance deductable amount'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inputFlexDiv2 edit_paying_input">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="How much are you paying?"
                                                        value={this.state.insuranceAmount}
                                                        onChange={(e: any) => this.onChange(e, 'insuranceAmount')}
                                                        placeholder=""
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                    />
                                                    <div className="error">
                                                        {!this.state.insuranceAmount
                                                            ? 'Enter Insurance amount'
                                                            : this.state.insuranceAmount &&
                                                            parseInt(this.state.insuranceAmount) <= 0
                                                            ? 'Amount must be greater than 0'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="inputsupportText">/Month</div>
                                            </div>
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="Your Policy Number"
                                                        value={this.state.insuranceAccountNumber}
                                                        onChange={(e: any) => this.onChange(e, 'insuranceAccountNumber')}
                                                        placeholder=""
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        // }}
                                                    />
                                                    <div className="error">
                                                        {!this.state.insuranceAccountNumber
                                                            ? 'Enter Insurance policy number'
                                                            : this.state.insuranceAccountNumber.length < 4
                                                            ? 'Policy number should be atleast 4 digits'
                                                            : this.state.insuranceAccountNumber &&
                                                            parseInt(this.state.insuranceAccountNumber) <= 0
                                                            ? 'invalid policy number'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_btns">
                                <button className="theme_btn" onClick={() => {
                                    History.push('/dashboard/home-insurance/summary');
                                }}>Cancel</button>
                                <button className="theme_btn" type="button" onClick={() => { this.insurancePostSubcriptionDetails() }}>Confirm Details</button>
                            </div>
                        </form>
                    </div>

                </>
                <Footer />
            </div >
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    serviceState: state.service,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);
