import React, { Component } from 'react';
import './ICmain.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InternetAction from '../../store/internet/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as EnergyAction from '../../store/energy/actions';
import Button from '../../components/atoms/Button';
import Footer from './Footer';
import './Summary.scss';
import wifi from '../../Assets/images/InternetAndCable/wifi.svg';
import utilityimg from '../../Assets/images/utility-img.png';
import circle from '../../Assets/images/InternetAndCable/circle.svg';
import greenCircle from '../../Assets/images/InternetAndCable/circle_green.svg';
import desktopWindows from '../../Assets/images/InternetAndCable/desktop_windows.svg';
import callIcon from '../../Assets/images/InternetAndCable/call_icon.svg';
import arrowDown from '../../Assets/images/InternetAndCable/arrow_down.svg';
import phoneIcon from '../../Assets/images/icons/phone.svg';
import clock from '../../Assets/images/icons/clock.svg';
import date from '../../Assets/images/icons/date.svg';
import earth from '../../Assets/images/icons/earth.svg';
import localPhone from '../../Assets/images/icons/phone.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '../../components/atoms/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
const moment = require('moment');

var intervalVar: any;
type initialProps = {
    commonAction?: any;
    commonState?: any;
    internetAction?: any;
    internetState?: any;
    history?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    energyAction?: any;
    energyState?: any;
};
type initialState = {
    ESsummaryData: any;
    defaultShowHideText: string;
    isShowContent: boolean;
    isShowHideText: string;
    isShowHideIndex: number;
    summaryData: any[];
    isMoveOrderData: boolean;
    isICSummaryArrived: boolean;
    isICScheduleArrived: boolean;
    isHomeOwner: string | null;
    calendlyOpen: boolean;
    scheduleClicked: boolean;
    nylasModalClosed: boolean;
    isAppointmentScheduled: boolean;
    concierge_name: string;
    concierge_image: string;
    phone: any;
    timezone: any;
    call_duration: any;
    start_time: any;
    end_time: any;
    reschedule_link: any;
    isSubscriptionData: boolean;
    subscription_details: any[];
    concierge_phone: any;
    destination_full_address: any;
    showUtilityBox: boolean;
};

export class Summary extends Component<initialProps, initialState> {
    state = {
        ESsummaryData: [],
        defaultShowHideText: 'More Details',
        isShowContent: false,
        isShowHideText: 'More Details',
        isShowHideIndex: 0,
        summaryData: [],
        isMoveOrderData: false,
        isICSummaryArrived: false,
        isICScheduleArrived: false,
        isHomeOwner: 'false',
        calendlyOpen: false,
        scheduleClicked: false,
        nylasModalClosed: false,
        isAppointmentScheduled: false,
        concierge_name: '',
        concierge_image: '',
        phone: '',
        timezone: '',
        call_duration: '',
        start_time: '',
        end_time: '',
        reschedule_link: '',
        isSubscriptionData: false,
        subscription_details: [],
        concierge_phone: '',
        destination_full_address: '',
        showUtilityBox: false,
    };

    componentDidMount() {
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };
        if(!hash_code){
            this.props.commonAction.moveGet();
        }else {
            // API actions call
            let countNew = 0;
            intervalVar = setInterval(() => {
                if (countNew < 7) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 5000);
        }

        let moveId: any = localStorage.getItem('moveId');
        this.props.internetAction.getICSummary({ moveId });
        this.props.dashboardAction.getSubscriptionDetails();
        this.props.energyAction.getEnergySummary();

        this.setState({ isHomeOwner: localStorage.getItem('isHomeOwner') })
    }

    componentDidUpdate(prevProps: any) {
        let prev: any = prevProps.commonState;
        let cur: any = this.props.commonState;
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        const ICSummary = this.props && this.props.internetState;
        const moveData = this.props && this.props.commonState;
        const subscriptionDetails = this.props && this.props.homeOwnerDashboardState;
        const appointmentDetails = moveData.appointmentDetails
        if (
            ICSummary.summary &&
            Object.keys(ICSummary.summary).length > 1 &&
            ICSummary.summary.status === 200
        ) {
            this.setState({ isMoveOrderData: true })
            delete ICSummary.summary.status
            this.state.summaryData = { ...ICSummary.summary }
        } else if (appointmentDetails.length != 0 && prev.appointmentDetails != cur.appointmentDetails) {
            const { concierge_name, concierge_image, phone, call_duration, timezone, start_time, end_time, reschedule_link } = appointmentDetails
            this.setState({
                isICScheduleArrived: true,
                isAppointmentScheduled: true,
                concierge_name,
                concierge_image,
                phone,
                call_duration,
                timezone,
                start_time,
                end_time,
                reschedule_link
            })
        } else if (this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0 &&
            prevProps.homeOwnerDashboardState.getSubscriptionDetails != this.props.homeOwnerDashboardState.getSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((element: any) => {
            if(element.service.slug === 'internet_cable'){
                this.setState({ isSubscriptionData: true, subscription_details: subscriptionDetails.getSubscriptionDetails.results })
            }})
        }

        if (prev != cur && moveData.move[0]) {
            if(prev.move[0]?.hash_code !== cur.move[0].hash_code){
                const payload = {
                    hash_code: cur.move[0].hash_code,
                };
                let countNew = 0;
                intervalVar = setInterval(() => {
                    if (countNew < 7 && cur.appointmentDetails.length === 0) {
                        this.props.commonAction.appointmentGet(payload);
                        countNew = countNew + 1;
                        if(countNew === 6){
                            this.setState({
                                isICScheduleArrived: true,
                            })
                        }
                    }
                }, 5000);
            }
            this.setState({
                concierge_phone: moveData.move[0].assigned_concierge.phone,
                destination_full_address: moveData.move[0].destination_full_address
            })
        }

        if (prevProps.internetState.isICSummaryArrived != ICSummary.isICSummaryArrived) {
            this.setState({ isICSummaryArrived: true })
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ 
                ESsummaryData: curEnergy.getEnergySummary,
            });
        }
        if (
            this.props &&
            this.props.commonState &&
            this.props.commonState.header &&
            this.props.commonState.header.length > 0 &&
            prev.header.length !== this.props.commonState.header.length
        ) {
                this.props.commonState.header.map((el: any) => {
                    if (el && el.slug === "energy-savings") {
                        this.setState({showUtilityBox: true})
                    }
                });
        }
    }

    componentWillUnmount() {
        clearInterval(intervalVar);
    }

    onShowHideView = (isShow: boolean, index: number) => {
        let tempState: any = {
            isShowContent: isShow,
            isShowHideIndex: index
        }
        if (isShow) tempState.isShowHideText = 'Show Less'
        else tempState.isShowHideText = 'More Details'
        this.setState(tempState)
    }

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true
        });
    };

    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false, nylasModalClosed: true });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const currencyFormat = (num: string) => {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let showCompletedElectricity: boolean = false;
        let showCompletedGas: boolean = false;
        let showSummary: boolean = false;
        this.state.ESsummaryData &&
            this.state.ESsummaryData.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });
            Object.values(this.state.summaryData).length > 0 &&
            Object.values(this.state.summaryData).map((items) => {
                if (items['status'] !== 'Hold') {
                    showSummary = true;
                }
            });

            const breadList = [
                {
                    name: 'Order summary internet & cable',
                    url: ''
                }
            ]

        return (
            <div>
                {!this.state.isICSummaryArrived || !this.state.isICScheduleArrived ? (<div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 99,
                        flexDirection: 'column'
                    }}>
                    <CircularProgress />
                    <div style={{ marginTop: '15px' }}> Working on it.Please Wait... </div>
                </div>) :
                    this.state.isAppointmentScheduled && !this.state.isSubscriptionData && !this.state.isMoveOrderData && this.state.call_duration? (
                        <>
                            <div className="greybgextralong">
                                <div className="main-head">
                                    <h2 className="heading">Internet and Cable</h2>
                                    <div className='mb-4'>
                                        <BreadCrumb breadList={breadList} />
                                    </div>
                                </div>
                            </div>
                            <div className="summarydetailbox">
                                <div className="summarydetailbox_head">
                                    <h2>Your Call Has Been Scheduled</h2>
                                    <p>Our concierge team will call you to complete your order <br></br>and schedule installation.</p>
                                </div>
                                <div className="summarydetailbox_body">
                                    <div className="borderbox">
                                        <div className="schedule_profile_box">
                                            <div className="profile_wrapper schedule_profileleft">
                                                <div className="profile_img">
                                                    <img src={this.state.concierge_image} alt="Image" />
                                                </div>
                                                <div className="profiledetail">
                                                    <h4 className="name">{this.state.concierge_name}</h4>
                                                    <p className="posi">Dedicated Concierge</p>
                                                </div>
                                            </div>
                                            <div className="schedule_profileright">
                                                <button className="borderbtn" onClick={() => this.calendlyHandleReschedule()}>Reschedule</button>
                                            </div>
                                            <div className="schedule_profilebottom">
                                            {this.state.phone ? (
                                                <p className="scheduled_datetime">
                                                    <img src={phoneIcon} alt="phone" />
                                                    {this.state.phone}
                                                </p>)
                                            : ""}
                                                <p className="scheduled_datetime">
                                                    <img src={clock} alt="clock" />
                                                    {this.state.call_duration} min
                                                </p>
                                                <p className="scheduled_datetime">
                                                    <img src={date} alt="calender" />
                                                    {moment(this.state.start_time).format('h:mm a')} - {moment(this.state.end_time).format('h:mm a')}, {moment(this.state.start_time).format('dddd, LL')}
                                                </p>
                                                <p className="scheduled_datetime">
                                                    <img src={earth} alt="earth" />
                                                    {this.state.timezone}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom_padd verticlehavetwobtns">
                                    <button className="bluebtn bigbtn"
                                        onClick={() => {
                                            History.push('/dashboardhomeowner');
                                        }}
                                    >
                                        Back To Dashboard
                                    </button><br></br>
                                    <button className='borderbtn bigbtn'
                                        onClick={() => {
                                            this.props.history.push('/dashboard/internet/steps');
                                        }}
                                    >
                                        Explore New Plans
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : this.state.isMoveOrderData && showSummary  ? (
                        <>
                            <div className="greybg">
                                <div className="main-head">
                                    <h2 className="heading">Internet and Cable</h2>
                                    <div className='mb-4'>
                                        <BreadCrumb breadList={breadList} />
                                    </div>
                                </div>
                                <div className='summaryHeader'>
                                    <h3> Subscription Details For Internet & Cable</h3>
                                    <p>Thank you for your order! If you need to update or manage your plan, please reach<br></br> out to your dedicated Concierge.</p>
                                </div>
                            </div>
                            {Object.values(this.state.summaryData).length > 0 && showSummary && Object.values(this.state.summaryData).map((item, index) => {
                                if(item['status'] !== "Hold"){
                                    return (
                                        <>
                                            <div className='summaryCards summarybox summarybox2'>
                                                {/* {index == 0 ? ( */}
                                                    <div className="summarybox_head">
                                                        <h5 className='summaryHeader'>Plan Details</h5>
                                                    </div>
                                                {/* ) : ''} */}
                                                <div className="summarybox_body">
                                                    <div className='summaryContent'>
                                                        <div className='summaryContent_l'>
                                                            <div className="img_wrapper">
                                                                {item['plan_detail']['logo'] ? (<img src={item['plan_detail']['logo']} alt="plan detail logo" />) : ""}
                                                                <p className="img_name">{item['provider_name'] ? item['provider_name'] : item['plan_detail']['provider_name'] ? item['plan_detail']['provider_name'] : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className='summaryContent_m'>
                                                            <p className="lab_val">Services Selected</p>
                                                            <ul>
                                                                {item['product_type'] == 'Internet/Cable' ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={wifi} alt="wifi icon" />
                                                                            <p>{item['product_type']}</p>
                                                                        </li>
                                                                    </>
                                                                ) : item['product_type'] == 'TV' ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={desktopWindows} alt="desktop icon" />
                                                                            <p>{item['product_type']}</p>
                                                                        </li>
                                                                    </>
                                                                ) : item['product_type'] == 'Phone' ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={localPhone} alt="phone icon" />
                                                                            <p>{item['product_type']}</p>
                                                                        </li>
                                                                    </>
                                                                ) : item['product_type'] == 'Internet' ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={wifi} alt="wifi icon" />
                                                                            <p>{item['product_type']}</p>
                                                                        </li>
                                                                    </>
                                                                ): item['product_type'] == 'Voice' ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={localPhone} alt="phone icon" />
                                                                            <p>{item['product_type']}</p>
                                                                        </li>
                                                                    </>
                                                                ) : ""
                                                                }</ul>
                                                        </div>
                                                        <div className='summaryContent_r'>
                                                            {item['plan_detail']['price'] ? (
                                                            <>
                                                                <p className="lab_val">Monthly Plan</p>
                                                                <p className="plan_detail">
                                                                    <span>$</span><span className="palnvalue">{currencyFormat(item['plan_detail']['price'])}</span>
                                                                </p>
                                                            </>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                    <div className="call_bar hw_summary">
                                                        <p>Want to change your subscription? Call</p>
                                                        <span>
                                                            Dedicated Concierge
                                                            <a href={`tel:${item['concierge_phone_number']}`}>
                                                                <span className="call_icon">
                                                                    <img src={callIcon} alt="phone" />
                                                                </span>
                                                                {item['concierge_phone_number']}</a>
                                                        </span>
                                                    </div>
                                                </div>
    
                                                <div className={`summaryfooter ${this.state.isShowContent && this.state.isShowHideIndex === index  ? 'showcontent' : ''}`}>
                                                    <div className="summary_hidden_content">
                                                        <div className="content_row">
                                                            {item['install_date'] ? (
                                                                <div className="content_col33">
                                                                    <p className="lab_val">Installation Date</p>
                                                                    <p><b>{moment(item['install_date']).format('MMMM YYYY')}</b></p>
                                                                </div>
                                                            ): ""}
                                                            {item['account_no'] ? (
                                                                <div className="content_col33">
                                                                    <p className="lab_val">Account Number</p>
                                                                    <p><b>{item['account_no']}</b></p>
                                                                </div>
                                                            ): ""}
                                                            {item['destination_address'] ? (
                                                                <div className="content_col33">
                                                                    <p className="lab_val">Address</p>
                                                                    <p><b>{item['destination_address']}</b></p>
                                                                </div>
                                                            ) : ""}
                                                            {item['plan_detail']['name'] ? (
                                                                <div className="content_col33">
                                                                    <p className="lab_val">Plan Details</p>
                                                                    <p><b>{item['plan_detail']['name']}</b></p>
                                                                </div>
                                                            ) : ""}
                                                        </div>
                                                    </div>
                                                    <button className="readmorebtn"
                                                        onClick={() =>
                                                            this.onShowHideView(this.state.isShowHideIndex === index ? !this.state.isShowContent : this.state.isShowContent, index)
                                                        }
                                                    >{this.state.isShowHideIndex === index ? this.state.isShowHideText : this.state.defaultShowHideText}
                                                        <img src={arrowDown} alt="arrow icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })}
                            {this.state.showUtilityBox ? (
                                <div className="utility_box">
                                    <div className="utility_boxl">
                                        <div>
                                            <img src={utilityimg} alt="" />
                                        </div>
                                    </div>
                                    <div className="utility_boxr ic_summary">
                                        <div>
                                            <h3 className="utility_title">Explore Potential Savings On Utilities</h3>
                                            <p>Visit Utilities to see if you have alternative providers available to save some money.</p>
                                            <ul>
                                                <li>
                                                    <img src={showCompletedElectricity ? greenCircle : circle} style={{ padding: '5px' }} alt="" />
                                                    Electricity
                                                </li>
                                                <li>
                                                    <img src={showCompletedGas ? greenCircle : circle} style={{ padding: '5px' }} alt="" />
                                                    Gas
                                                </li>
                                            </ul>
                                            <Button className="gotoutility_btn"
                                                backgroundColor="#fff"
                                                color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                                }
                                                border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                                }`}
                                                borderRadius="4px"
                                                onClick={() => {
                                                    this.props.history.push('/dashboard/energy-savings');
                                                }}>Go To Utilities</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                            <div className="bittom_btn_div">
                            <Button
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273e59'
                                }
                                    onClick={() => {
                                        this.props.history.push('/dashboardhomeowner');
                                    }}
                                >
                                    Back To Dashboard
                                </Button>
                            </div>
                            <Footer />
                        </>
                    ) : this.state.isSubscriptionData ? (
                        <>
                            <div className="greybg">
                                <div className="main-head">
                                    <h2 className="heading">Internet and Cable</h2>
                                    <div className='mb-4'>
                                        <BreadCrumb breadList={breadList} />
                                    </div>
                                </div>
                                <div className='summaryHeader'>
                                    <h3>Subscription Details For Internet & Cable</h3>
                                    <p>Thank you for your order! If you need to update or manage your plan, please reach<br></br> out to your dedicated Concierge.</p>
                                </div>
                            </div>
                            {this.state.subscription_details && this.state.subscription_details.length > 0 && this.state.subscription_details.map((element: any, index) => {
                                if(element.service.slug === 'internet_cable'){
                                    return (
                                        <>
                                            <div className='summaryCards summarybox summarybox2'>
                                                <div className="summarybox_head">
                                                    <h5 className='summaryHeader'>Plan Details</h5>
                                                    <button className="summarybox_headbtn"
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: '/dashboard/internet/editplan',
                                                                state: { detail: this.state.subscription_details , from: 'summary' }
                                                            })
                                                        }}
                                                    >Edit Current Plan</button>
                                                </div>
                                                <div className="summarybox_body">
                                                    <div className='summaryContent'>
                                                        <div className='summaryContent_l'>
                                                            <div className="img_wrapper">
                                                                {/* <img src={element['provider']} alt="plan detail logo" /> */}
                                                                {element['provider_logo'] ? (<img src={element['provider_logo']} alt="plan detail logo" />) : ""}
                                                                <p className="img_name">{element['provider']}</p>
                                                            </div>
                                                        </div>
                                                        <div className='summaryContent_m'>
                                                            <p className="lab_val">Services Selected</p>
                                                            <ul>
                                                                {element['services_brought']?.includes('Internet') ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={wifi} alt="wifi icon" />
                                                                            <p>Internet</p>
                                                                        </li>
                                                                    </>
                                                                ) : "" }
                                                                { element['services_brought']?.includes('TV') ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={desktopWindows} alt="desktop icon" />
                                                                            <p>TV</p>
                                                                        </li>
                                                                    </>
                                                                ) : ""}
                                                                { element['services_brought']?.includes('Phone') ? (
                                                                    <>
                                                                        <li>
                                                                            <img src={localPhone} alt="phone icon" />
                                                                            <p>Phone</p>
                                                                        </li>
                                                                    </>
                                                                ) : ""
                                                                }</ul>
                                                        </div>
                                                        <div className='summaryContent_r'>
                                                            {element['price'] ? (
                                                            <>
                                                                <p className="lab_val">Monthly Plan</p>
                                                                <p className="plan_detail">
                                                                    <span>$</span><span className="palnvalue">{currencyFormat(element['price'])}</span>
                                                                </p>
                                                            </>
                                                            ): ""}
                                                        </div>
                                                    </div>
                                                    <div className="call_bar hw_summary">
                                                        <p>Want to change your subscription? Call</p>
                                                        <span>
                                                            Dedicated Concierge
                                                            <a href={`tel:${this.state.concierge_phone}`}>
                                                                <span className="call_icon">
                                                                    <img src={callIcon} alt="phone" />
                                                                </span>
                                                                {this.state.concierge_phone}</a>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className={`summaryfooter ${this.state.isShowContent && this.state.isShowHideIndex === index  ? 'showcontent' : ''}`}>
                                                    <div className="summary_hidden_content">
                                                        <div className="content_row">
                                                            {/* <div className="content_col33">
                                                                <p className="lab_val">Installation Date</p>
                                                                <p>{moment(element['install_date']).format('MMMM YYYY')}</p>
                                                            </div> */}
                                                            {element['account_number'] ? (
                                                            <div className="content_col33">
                                                                <p className="lab_val">Account Number</p>
                                                                <p><b>{element['account_number']}</b></p>
                                                            </div>
                                                            ): ""}
                                                            <div className="content_col33">
                                                                <p className="lab_val">Address</p>
                                                                <p><b>{this.state.destination_full_address}</b></p>
                                                            </div>
                                                            {element['service']['name'] ? (
                                                            <div className="content_col33">
                                                                <p className="lab_val">Plan Details</p>
                                                                <p><b>{element['service']['name']}</b></p>
                                                            </div>
                                                            ): ""}
                                                        </div>
                                                    </div>
                                                    <button className="readmorebtn"
                                                        onClick={() =>
                                                            this.onShowHideView(this.state.isShowHideIndex === index ? !this.state.isShowContent : this.state.isShowContent, index)
                                                        }
                                                    >{this.state.isShowHideText}
                                                        <img src={arrowDown} alt="arrow icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })}
                            {this.state.showUtilityBox ? (
                                <div className="utility_box">
                                    <div className="utility_boxl">
                                        <div>
                                            <img src={utilityimg} alt="" />
                                        </div>
                                    </div>
                                    <div className="utility_boxr">
                                        <div>
                                            <h3 className="utility_title">Explore Potential Savings On Utilities</h3>
                                            <p>Visit Utilities to see if you have alternative providers available to save some money.</p>
                                            <ul>
                                                <li>
                                                    <img src={showCompletedElectricity ? greenCircle : circle} alt="" style={{ padding: '5px' }} />
                                                    Electricity
                                                </li>
                                                <li>
                                                    <img src={showCompletedGas ? greenCircle : circle} alt="" style={{ padding: '5px' }} />
                                                    Gas
                                                </li>
                                            </ul>
                                            <Button className="gotoutility_btn"
                                                backgroundColor="#fff"
                                                color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                                }
                                                border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59'
                                                }`}
                                                borderRadius="4px"
                                                onClick={() => {
                                                    this.props.history.push('/dashboard/energy-savings');
                                                }}>Go To Utilities</Button>
                                        </div>
                                    </div>
                                </div>
                            ): ""}
                            <div className="bittom_btn_div">
                                <Button
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273e59'
                                }
                                    onClick={() => {
                                        History.push('/dashboardhomeowner');
                                    }}
                                >
                                    Back To Dashboard
                                </Button>
                            </div>
                            <Footer />
                        </>
                    ) :
                        <p className='warmTransferClass'>
                            No Data to display
                        </p>
                }
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="scheduleCallMoving"
                    >
                        <div className="scheduleCallBackMoving" onClick={() => this.setState({ calendlyOpen: false })}>
                            <ArrowBackIcon />
                        </div>

                        {(this.state.scheduleClicked) ? (
                            <div className="iFrame">
                                <iframe
                                    src={this.state.reschedule_link
                                    }
                                    allow="encrypted-media"
                                    className="iframe"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
            </div >
        );
    }
}


function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        internetAction: bindActionCreators(InternetAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    internetState: state.internet,
    homeOwnerDashboardState: state.homeOwnerdashboard,
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
