import React, { Component } from 'react';
import './checklistCard.scss';
import Button from '../Button';
import CheckIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';

type initialProps = {
    mainData?: string;
    paraData?: string;
    blueCard?: string;
    isQuote?: boolean;
    onClick?: any;
    reminderText?: string;
    doneClicked?: any;
    reminderClicked?: any;
    buttonText?: any;
    disabled?: any;
    isDouglasElliman?: boolean;
};

type initialState = {};

export class checklistCard extends Component<initialProps, initialState> {
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.props;
        return (
            <div className={isDouglasElliman ? 'cardDivChecklist DouglascardDivChecklist' : 'cardDivChecklist'}>
                <div className="cardInnerContentChecklist">
                    <div className="cardContentChecklist">
                        <div className="upperContentChecklist">
                            <div className="upperContentMainChecklist">{this.props.mainData}</div>
                            {this.props.blueCard && (
                                <div className="upperContentBlueChecklist">{this.props.blueCard}</div>
                            )}
                        </div>
                        <div className="upperContentParaChecklist">{this.props.paraData}</div>
                    </div>

                    <div className="checklistCardHover">
                        <div className="checklistDone" onClick={this.props.doneClicked}>
                            <div className="circleDiv">
                                <CheckIcon />
                            </div>
                            <span className="textChecklistCard">Done</span>
                        </div>
                        <div className="checklistDone" onClick={this.props.reminderClicked}>
                            <div
                                // className={this.props.isQuote ? "circleDiv" : "otherCircle"}
                                className="circleDiv"
                            >
                                <EventIcon />
                            </div>
                            <span className="textChecklistCard">{this.props.reminderText}</span>
                        </div>
                    </div>

                    <div className={this.props.isQuote ? 'buttonCardChecklist' : 'buttonCardChecklist1'}>
                        {this.props.buttonText === 'Complete It Now' ? (
                            <Button
                                width="100%"
                                // color="#FFFFFF"
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59'
                                }
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                fontSize="16px"
                                border={
                                    isDouglasElliman
                                        ? '1px solid #100B28'
                                        : `1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                }
                                onClick={() => this.props.onClick()}
                                disabled={this.props.disabled}
                            >
                                Complete Now
                            </Button>
                        ) : (
                            <Button
                                width="100%"
                                backgroundColor="#FFFFFF"
                                boxShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                }
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59'
                                }
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                fontSize="16px"
                                fontWeight="bold"
                                border={
                                    isDouglasElliman
                                        ? '1px solid #100B28'
                                        : `1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                }
                                onClick={() => this.props.onClick()}
                                disabled={this.props.disabled}
                            >
                                {this.props.buttonText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default checklistCard;
