import {
    SIGNUP,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP_UPDATE,
    SIGNUP_UPDATE_ERROR,
    SIGNUP_UPDATE_SUCCESS,
    LANDING_MOVE_POST,
    LANDING_MOVE_POST_ERROR,
    LANDING_MOVE_POST_SUCCESS,
    LANDING_MOVE_POST_FOR_SECURITY,
    LANDING_MOVE_POST_FOR_SECURITY_ERROR,
    LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
    POST_GHOST_LOGIN,
    POST_GHOST_LOGIN_ERROR,
    POST_GHOST_LOGIN_SUCCESS,
    HAVE_A_PREAPPROVAL,
    HAVE_A_PREAPPROVAL_ERROR,
    HAVE_A_PREAPPROVAL_SUCCESS,
    CREATE_CONATCT_SERVICE_PROVIDERS,
    CREATE_CONATCT_SERVICE_PROVIDERS_SUCCESS,
    CREATE_CONATCT_SERVICE_PROVIDERS_ERROR,
    GET_A_PREAPPROVAL,
    GET_A_PREAPPROVAL_ERROR,
    GET_A_PREAPPROVAL_SUCCESS,
} from './actionTypes';
import {
    Signup,
    SignupRequest,
    SignupFailure,
    SignupFailurePayload,
    SignupSuccess,
    SignupSuccessPayload,
    SignupUpdate,
    SignupUpdateRequest,
    SignupUpdateFailure,
    SignupUpdateFailurePayload,
    SignupUpdateSuccess,
    SignupUpdateSuccessPayload,
    LandingMovePost,
    LandingMovePostRequest,
    LandingMovePostFailure,
    LandingMovePostFailurePayload,
    LandingMovePostSuccess,
    LandingMovePostSuccessPayload,
    LandingMovePostForSecurity,
    LandingMovePostForSecurityRequest,
    LandingMovePostForSecurityFailure,
    LandingMovePostForSecurityFailurePayload,
    LandingMovePostForSecuritySuccess,
    LandingMovePostForSecuritySuccessPayload,
    GhostSignup,
    GhostSignupFailure,
    GhostSignupSuccess,
    GhostSignupFailurePayload,
    GhostSignupRequest,
    GhostSignupSuccessPayload,
    HaveAPreapproval,
    HaveAPreapprovalRequest,
    HaveAPreapprovalFailure,
    HaveAPreapprovalFailurePayload,
    HaveAPreapprovalSuccess,
    HaveAPreapprovalSuccessPayload,
    CretaeContactServiceProviders,
    CretaeContactServiceProvidersRequest,
    CretaeContactServiceProvidersFailure,
    CretaeContactServiceProvidersFailurePayload,
    CretaeContactServiceProvidersSuccess,
    CretaeContactServiceProvidersSuccessPayload,
    GetAPreapproval,
    GetAPreapprovalRequest,
    GetAPreapprovalFailure,
    GetAPreapprovalFailurePayload,
    GetAPreapprovalSuccess,
    GetAPreapprovalSuccessPayload,
} from './types';

// SIGNUP
export const signup = (payload: SignupRequest): Signup => {
    return {
        type: SIGNUP,
        payload,
    };
};

export const signupSuccess = (payload: SignupSuccessPayload): SignupSuccess => {
    return {
        type: SIGNUP_SUCCESS,
        payload,
    };
};

export const signupFailure = (payload: SignupFailurePayload): SignupFailure => {
    return {
        type: SIGNUP_ERROR,
        payload,
    };
};

export const signupUpdate = (payload: SignupUpdateRequest): SignupUpdate => {
    return {
        type: SIGNUP_UPDATE,
        payload,
    };
};

export const signupUpdateSuccess = (payload: SignupUpdateSuccessPayload): SignupUpdateSuccess => {
    return {
        type: SIGNUP_UPDATE_SUCCESS,
        payload,
    };
};

export const signupUpdateFailure = (payload: SignupUpdateFailurePayload): SignupUpdateFailure => {
    return {
        type: SIGNUP_UPDATE_ERROR,
        payload,
    };
};

// Landing move
export const landingMovePost = (payload: LandingMovePostRequest): LandingMovePost => {
    return {
        type: LANDING_MOVE_POST,
        payload,
    };
};

export const landingMovePostSuccess = (payload: LandingMovePostSuccessPayload): LandingMovePostSuccess => {
    return {
        type: LANDING_MOVE_POST_SUCCESS,
        payload,
    };
};

export const landingMovePostFailure = (payload: LandingMovePostFailurePayload): LandingMovePostFailure => {
    return {
        type: LANDING_MOVE_POST_ERROR,
        payload,
    };
};

// Landing move for security recommendation
export const landingMovePostForSecurity = (payload: LandingMovePostForSecurityRequest): LandingMovePostForSecurity => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY,
        payload,
    };
};

export const landingMovePostForSecuritySuccess = (
    payload: LandingMovePostForSecuritySuccessPayload,
): LandingMovePostForSecuritySuccess => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY_SUCCESS,
        payload,
    };
};

export const landingMovePostForSecurityFailure = (
    payload: LandingMovePostForSecurityFailurePayload,
): LandingMovePostForSecurityFailure => {
    return {
        type: LANDING_MOVE_POST_FOR_SECURITY_ERROR,
        payload,
    };
};

//cretaeContactServiceProviders
export const cretaeContactServiceProviders = (payload: CretaeContactServiceProvidersRequest): CretaeContactServiceProviders => {
    return {
        type: CREATE_CONATCT_SERVICE_PROVIDERS,
        payload,
    };
};

export const cretaeContactServiceProvidersSuccess = (payload: CretaeContactServiceProvidersSuccessPayload): CretaeContactServiceProvidersSuccess => {
    return {
        type: CREATE_CONATCT_SERVICE_PROVIDERS_SUCCESS,
        payload,
    };
};

export const cretaeContactServiceProvidersFailure = (payload: CretaeContactServiceProvidersFailurePayload): CretaeContactServiceProvidersFailure => {
    return {
        type: CREATE_CONATCT_SERVICE_PROVIDERS_ERROR,
        payload,
    };
};

//haveAPreapproval
export const haveAPreapproval = (payload: HaveAPreapprovalRequest): HaveAPreapproval => {
    return {
        type: HAVE_A_PREAPPROVAL,
        payload,
    };
};

export const haveAPreapprovalSuccess = (payload: HaveAPreapprovalSuccessPayload): HaveAPreapprovalSuccess => {
    return {
        type: HAVE_A_PREAPPROVAL_SUCCESS,
        payload,
    };
};

export const haveAPreapprovalFailure = (payload: HaveAPreapprovalFailurePayload): HaveAPreapprovalFailure => {
    return {
        type: HAVE_A_PREAPPROVAL_ERROR,
        payload,
    };
};

//GetAPreapproval
export const getAPreapproval = (payload: GetAPreapprovalRequest): GetAPreapproval => {
    return {
        type: GET_A_PREAPPROVAL,
        payload,
    };
};

export const getAPreapprovalSuccess = (payload: GetAPreapprovalSuccessPayload): GetAPreapprovalSuccess => {
    return {
        type: GET_A_PREAPPROVAL_SUCCESS,
        payload,
    };
};

export const getAPreapprovalFailure = (payload: GetAPreapprovalFailurePayload): GetAPreapprovalFailure => {
    return {
        type: GET_A_PREAPPROVAL_ERROR,
        payload,
    };
};

//ghost
export const ghostLogin = (payload: GhostSignupRequest): GhostSignup => {
    return {
        type: POST_GHOST_LOGIN,
        payload,
    };
};

export const ghostLoginSuccess = (payload: GhostSignupSuccessPayload): GhostSignupSuccess => {
    return {
        type: POST_GHOST_LOGIN_SUCCESS,
        payload,
    };
};

export const ghostLoginFailure = (payload: GhostSignupFailurePayload): GhostSignupFailure => {
    return {
        type: POST_GHOST_LOGIN_ERROR,
        payload,
    };
};