import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
// import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import TextField from '@material-ui/core/TextField';
// import iIcon from '../../Assets/images/Moving/iIcon.svg';
import LocationIcon from '../../Assets/images/InsuranceImages/insuranceHomeLocation';
import HomeIcon from '../../Assets/images/InsuranceImages/homefeetb';
import BuiltIcon from '../../Assets/images/InsuranceImages/craneb';
import HeaterIcon from '../../Assets/images/InsuranceImages/heaterb';
import RoofIcon from '../../Assets/images/InsuranceImages/roof';
import PoolIcon from '../../Assets/images/InsuranceImages/poolb';
import StairsIcon from '../../Assets/images/InsuranceImages/stepsb';
import FoundationIcon from '../../Assets/images/InsuranceImages/foundation';
import ConstructionIcon from '../../Assets/images/InsuranceImages/construction';
import SelectInput from '../../components/atoms/SelectInput';
import Checkbox from '@material-ui/core/Checkbox';
import './insurance.scss';
import { InputLabel } from '@material-ui/core';
// import PlanPage from './PlanPage';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import * as SignupAction from '../../store/login/signup/actions';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Footer from './Footer';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import Linear from '../../components/atoms/linearProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Action2 from '../../store/dashboard/actions';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../utils/utlis';
import AnalyticsService from '../analytics-service';
import { trustedFormUrl } from '../../config';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    dashboardAction: any;
    CheckListDashboardState: any;
    skip: any;
    commonAction?: any;
    commonState?: any;
    insuranceAction?: any;
    insuranceState?: any;
    history?: any;
    signupAction?: any;
    signupState?: any;
};

type initialState = {
    activeStep: number;
    skippedStep: number;
    step1: {
        street: string;
        city: string;
        apt: string;
        zipcode: string;
        address: string;
    };
    step2: {
        size: number | null;
        builtYear: number | null;
        noOfStories: string;
        roofType: string;
    };
    step3: {
        heatingSystem: string;
        pool: string;
        constructionType: string | null;
        foundationType: string | null;
    };
    step4: {
        birthdate: any;
        gender: string;
        maritalStatus: string;
        checkedG: boolean;
        fullName: string;
        email: string;
        phone: string;
    };
    roofTypeOptions: any;
    heaterOptions: any;
    poolOptions: any;
    genderOptions: any;
    maritalStatusOptions: any;
    moveId: any;
    continueClick: boolean;
    slug: any;
    isOpen?: boolean;
    hostname?: string;
    site_name: string;
    step2Clicked: boolean;
    constructionTypeOptions: any;
    foundationTypeOptions: any;
    isDouglasElliman: boolean;
    hash_code: any;
    customBrokerageName: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    brand_partner_name: any;
    button_copy: any;
    form_button: any;
    form_pre_filled: boolean;
    form_field_name: any;
    form_step_number: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

export class steps extends Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 0,
        skippedStep: 0,
        moveId: null,
        continueClick: false,
        isOpen: false,
        step1: {
            street: '',
            city: '',
            apt: '',
            zipcode: '',
            address: '',
        },
        step2: {
            size: null,
            builtYear: null,
            noOfStories: '',
            roofType: '',
        },
        step3: {
            heatingSystem: '',
            pool: '',
            constructionType: '',
            foundationType: '',
        },
        step4: {
            birthdate: null,
            gender: '',
            maritalStatus: '',
            checkedG: false,
            fullName: '',
            email: '',
            phone: '',
        },
        roofTypeOptions: [
            { value: 'Architectural Shingle', label: 'Architectural Shingle' },
            { value: 'Asphalt Shingle', label: 'Asphalt Shingle' },
            { value: 'Fiberglass', label: 'Fiberglass' },
            { value: 'Clay Tile', label: 'Clay Tile' },
            { value: 'Slate', label: 'Slate' },
            { value: 'Metal', label: 'Metal' },
            { value: 'Composition', label: 'Composition' },
            { value: 'Asbestos', label: 'Asbestos' },
            { value: 'Tar &amp; Gravel', label: 'Tar &amp; Gravel' },
            { value: 'Wood Shingle', label: 'Wood Shingle' },
            { value: 'Not Sure', label: 'Not Sure' },
        ],
        heaterOptions: [
            { value: 'Electric', label: 'Electric' },
            { value: 'Gas - Forced Air', label: 'Gas - Forced Air' },
            { value: 'Gas - Hot Water', label: 'Gas - Hot Water' },
            { value: 'Oil - Forced Air', label: 'Oil - Forced Air' },
            { value: 'Oil - Hot Water', label: 'Oil - Hot Water' },
            { value: 'Not Sure', label: 'Not Sure' },
        ],
        poolOptions: [
            { value: 'None', label: 'None' },
            { value: 'In-ground (fenced)', label: 'In-ground (fenced)' },
            { value: 'In-ground (unfenced)', label: 'In-ground (unfenced)' },
            { value: 'Above ground (fenced)', label: 'Above ground (fenced)' },
            { value: 'Above ground (unfenced)', label: 'Above ground (unfenced)' },
        ],
        genderOptions: [
            { value: 'Female', label: 'Female' },
            { value: 'Male', label: 'Male' },
            { value: 'Other', label: 'Other' },
        ],
        maritalStatusOptions: [
            { value: 'Single', label: 'Single' },
            { value: 'Married', label: 'Married' },
            { value: 'Domestic Partner', label: 'Domestic Partner' },
            { value: 'Divorced', label: 'Divorced' },
            { value: 'Separated', label: 'Separated' },
            { value: 'Widowed', label: 'Widowed' },
        ],
        slug: { primary_color: '' },
        hostname: '',
        site_name: 'moveeasy-demo',
        step2Clicked: false,
        isDouglasElliman: false,
        constructionTypeOptions: [
            { value: 'Wood Frame', label: 'Wood Frame' },
            { value: 'Masonry', label: 'Masonry' },
            { value: 'Concrete', label: 'Concrete' },
            { value: 'Log', label: 'Log' },
            { value: 'Other', label: 'Other' },
        ],
        foundationTypeOptions: [
            { value: 'Slab', label: 'Slab' },
            { value: 'Crawl Space', label: 'Crawl Space' },
            { value: 'Basement', label: 'Basement' },
            { value: 'Pier and beam / piling', label: 'Pier and beam / piling' },
            { value: 'Other', label: 'Other' },
        ],
        hash_code: '',
        customBrokerageName: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        brand_partner_name: '',
        button_copy: '',
        form_button: '',
        form_pre_filled: true,
        form_field_name: '',
        form_step_number: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };

    trustedUrl: string | undefined | null = null

    componentDidMount() {
        this.props.dashboardAction.checklist();
        const domain = window.location.hostname;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (domain && domain.split('.')[0]) {
            this.setState({ hostname: domain.split('.')[0] });
        }

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.data
        ) {
            const data =
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.data;
            const dest: any = data && data.destination;
            const property: any = data && data.property;
            const insurance: any = data && data.insurance_detail;
            const user: any = data && data.user_ref;
            this.setState({
                activeStep: 1,
                step1: {
                    city: dest && dest.lookup_name,
                    apt: '',
                    zipcode: dest && dest.zip_code,
                    street: data && data.destination_street,
                    address: data && data.destination_full_address,
                },
                step2: {
                    size: property && property.total_area_sq_ft,
                    builtYear: property && property.year_built,
                    noOfStories: property && property.stories,
                    roofType: property && property.roof_material_type,
                },
                step3: {
                    heatingSystem: property && property.heating_type ? property.heating_type : '',
                    pool: property && property.pool_type ? property.pool_type : '',
                    constructionType: property && property.construction_type ? property.construction_type : '',
                    foundationType: property && property.foundation_type ? property.foundation_type : '',
                },
                step4: {
                    birthdate: insurance && insurance.date_of_birth,
                    gender: insurance && insurance.gender,
                    maritalStatus: insurance && insurance.marital_status,
                    checkedG: false,
                    fullName:
                        user && user.first_name && user.last_name
                            ? `${user && user.first_name} ${user && user.last_name}`
                            : '',
                    email: user && user.email ? user.email : '',
                    phone: user && user.phone ? user.phone : '',
                },
                moveId: data && data.id,
            });
            if (
                this.state.step1.city &&
                this.state.step1.zipcode &&
                this.state.step1.street &&
                this.state.step1.address &&
                this.state.step2.size &&
                this.state.step2.builtYear &&
                this.state.step2.noOfStories &&
                this.state.step2.roofType &&
                this.state.step3.heatingSystem &&
                this.state.step3.pool &&
                this.state.step3.constructionType &&
                this.state.step3.foundationType &&
                this.state.step4.birthdate &&
                this.state.step4.gender &&
                this.state.step4.maritalStatus &&
                this.state.step4.fullName &&
                this.state.step4.email &&
                this.state.step4.phone
            ) {
                this.setState({
                    prefilled: true,
                });
            }
        } else {
            this.props.commonAction.moveGet();
        }

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        
        if(!document.getElementById("trustedFormScriptTag")){
            const field = 'xxCertUrl';
            const provideReferrer: string = 'false';
            const invertFieldSensitivity = false;
            const tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.id = "trustedFormScriptTag"
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                `://${trustedFormUrl}/trustedform.js?provide_referrer=` +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date().getTime() +
                Math.random() +
                '&invert_field_sensitivity=' +
                invertFieldSensitivity;
            const s = document.getElementsByTagName('script')[0];

            s && s.parentNode && s.parentNode.insertBefore(tf, s);
        }
    }

    componentWillUnmount() {
        document.getElementById("trustedFormScriptTag")?.remove()
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0]
        ) {
            currentState.hash_code = nextProps.commonState.move[0].hash_code;
            currentState.customBrokerageName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }
        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const prevI = prevProps && prevProps.insuranceState;
        const curI = this.props && this.props.insuranceState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest: any = cur.move[0] && cur.move[0].destination;
            const property: any = cur.move[0] && cur.move[0].property;
            const insurance: any = cur.move[0] && cur.move[0].insurance_detail;
            const user: any = cur.move[0] && cur.move[0].user_ref;
            this.setState({
                step1: {
                    city: dest && dest.lookup_name,
                    apt: '',
                    zipcode: dest && dest.zip_code,
                    street: cur.move[0] && cur.move[0].destination_street,
                    address: cur.move[0] && cur.move[0].destination_full_address,
                },
                step2: {
                    size: property && property.total_area_sq_ft,
                    builtYear: property && property.year_built,
                    noOfStories: property && property.stories,
                    roofType: property && property.roof_material_type,
                },
                step3: {
                    heatingSystem: property && property.heating_type,
                    pool: property && property.pool_type,
                    constructionType: property && property.construction_type,
                    foundationType: property && property.foundation_type,
                },
                step4: {
                    birthdate: insurance && insurance.date_of_birth,
                    gender: insurance && insurance.gender,
                    maritalStatus: insurance && insurance.marital_status,
                    checkedG: false,
                    fullName: `${user && user.first_name} ${user && user.last_name}`,
                    email: user && user.email,
                    phone: user && user.phone,
                },
                moveId: cur.move[0] && cur.move[0].id,
            });
            if (
                this.state.step1.city &&
                this.state.step1.zipcode &&
                this.state.step1.street &&
                this.state.step1.address &&
                this.state.step2.size &&
                this.state.step2.builtYear &&
                this.state.step2.noOfStories &&
                this.state.step2.roofType &&
                this.state.step3.heatingSystem &&
                this.state.step3.pool &&
                this.state.step3.constructionType &&
                this.state.step3.foundationType &&
                this.state.step4.birthdate &&
                this.state.step4.gender &&
                this.state.step4.maritalStatus &&
                this.state.step4.fullName &&
                this.state.step4.email &&
                this.state.step4.phone
            ) {
                this.setState({
                    prefilled: true,
                });
            }
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.setState({
                activeStep: 1,
                continueClick: false,
            });
            window.scrollTo(0, 0);
            this.props.commonAction.moveGet();
        }
        if (prevI.updateProperty !== curI.updateProperty && curI.updateProperty && curI.updateProperty.message) {
            if (this.state.activeStep === 1) {
                this.setState({
                    activeStep: 2,
                    continueClick: false,
                });
            } else {
                this.setState({
                    activeStep: 3,
                    continueClick: false,
                });
            }
            window.scrollTo(0, 0);
            this.props.commonAction.moveGet();
        }
        if (prevI.updatePersonal !== curI.updatePersonal && curI.updatePersonal && curI.updatePersonal.message) {
            if (
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding'
            ) {
                this.props.history.push({
                    pathname: '/landing/insurance/plans',
                    state: {
                        fromLanding: 'fromLanding',
                        trustedUrl : this.trustedUrl
                    },
                });
            } else {
                this.props.history.push({ 
                    pathname: '/dashboard/home-insurance/plans',
                    state: {
                        trustedUrl : this.trustedUrl
                    }
                });

                updateChecklistTask(50, this.props.dashboardAction);
            }
            //  this.props.commonAction.moveGet();
        }
        if (
            (prevProps && prevProps.signupState && prevProps.signupState.landingMovePost) !==
            (this.props && this.props.signupState && this.props.signupState.landingMovePost)
        ) {
            this.setState({ step2Clicked: false });
            if (localStorage.getItem('token') && localStorage.getItem('moveId')) {
                const moveId = localStorage.getItem('moveId');
                this.setState({ moveId: moveId });
                const { street, city, zipcode } = this.state.step1;
                const { size, builtYear, noOfStories, roofType } = this.state.step2;
                const { heatingSystem, pool, constructionType, foundationType } = this.state.step3;
                const { birthdate, gender, maritalStatus } = this.state.step4;
                const place = city.split(',');
                const payload = {
                    heating_type: heatingSystem,
                    roof_material_type: roofType,
                    pool_type: pool,
                    year_built: builtYear,
                    total_area_sq_ft: size,
                    stories: noOfStories,
                    street: street,
                    city: place && place[0],
                    state: place && place[1],
                    zip_code: zipcode,
                    construction_type: constructionType,
                    foundation_type: foundationType,
                };
                this.props.insuranceAction.updateProperty({ move_id: moveId, payload });
                setTimeout(() => {
                    const payload1 = {
                        gender: gender,
                        marital_status: maritalStatus,
                        occupation: null,
                        career_status: null,
                        is_secondary_holder_exist: false,
                        secondary_holder_first_name: null,
                        secondary_holder_last_name: null,
                        spouse_gender: null,
                        accepted_terms: true,
                        date_of_birth: new Date(birthdate).toISOString().split('T')[0],
                    };
                    this.props.insuranceAction.updatePersonal({ move_id: moveId, payload: payload1 });
                }, 2000);
                // this.props.history.push({
                //         pathname: '/landing/insurance/plans',
                //         state: {
                //             fromLanding: 'fromLanding',
                //         },
                //     });
            }
        }
    }
    getSteps = () => {
        return ['', '', '', ''];
    };

    inputAnalyticsTracking = (fieldName: string) => {
        AnalyticsService.formFieldStarted({
            page: 'Home Insurance',
            category_name: 'Home Insurance Step Form',
            button_copy: this.state.activeStep === 2 ? 'Review Your Quotes' : 'Next',
            form_button: true,
            form_pre_filled: false,
            form_field_name: fieldName,
            form_step_number: this.state.activeStep + 1,
        })
    }

    onChangeHandlerStep1 = async (e: any, name: string) => {
        let step1: any;
        if (name === 'street') {
            step1 = { ...this.state.step1, [name]: e };
        } else {
            step1 = { ...this.state.step1, [name]: e.target.value };
        }
        await this.setState(() => ({ step1 }));

        this.inputAnalyticsTracking(name);
    };

    onChangeHandlerStep2 = async (e: any, name: string) => {
        let step2: any;
        if (name === 'builtYear') {
            let year = this.formatYearInput(e.target.value);
            step2 = { ...this.state.step2, [name]: year };

            this.inputAnalyticsTracking('What year was it built?');
        } else if (name === 'size') {
            let size = this.formatSizeInput(e.target.value);
            step2 = { ...this.state.step2, [name]: size };

            this.inputAnalyticsTracking('What is the sq. footage?');
        } else {
            step2 = { ...this.state.step2, [name]: e.target.value };
        }
        await this.setState(() => ({ step2 }));
    };

    onSelectHandlerStep2 = async (e: any, name: string) => {
        const steps = { ...this.state.step2, [name]: e.value };
        await this.setState(() => ({ step2: steps }));

        const fieldFullName = name === 'noOfStories' ? 'How many stories?' : 'What is the roof made of?'
        this.inputAnalyticsTracking(fieldFullName);
    };
    onChangeHandlerStep3 = async (e: any, name: string) => {
        let step3 = { ...this.state.step3, [name]: e.target.value };

        await this.setState(() => ({ step3 }));
    };

    onSelectHandlerStep3 = async (e: any, name: string) => {
        const steps = { ...this.state.step3, [name]: e.value };
        await this.setState(() => ({ step3: steps }));

        let fieldFullName;

        if (name === 'heatingSystem') {
            fieldFullName = 'What is the primary heating system?'
        } else if (name === 'pool') {
            fieldFullName = 'Does it have a swimming pool?'
        } else if (name === 'constructionType') {
            fieldFullName = 'What is the construction type?'
        } else {
            fieldFullName = 'What is the foundation type?'
        }

        this.inputAnalyticsTracking(fieldFullName);
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    formatYearInput = (year: string) => {
        let build_year = year;
        build_year = build_year.replace(/\D/g, '');
        build_year = build_year.substring(0, 4);
        return build_year;
    };

    formatSizeInput = (size: string) => {
        let sqsize = size;
        sqsize = sqsize.replace(/\D/g, '');
        sqsize = sqsize.substring(0, 10);
        return sqsize;
    };

    onChangeHandlerStep4 = async (e: any, name: string) => {
        let step4: any;
        if (name === 'birthdate') {
            step4 = { ...this.state.step4, [name]: e === '' || e === null ? '' : e };
            this.inputAnalyticsTracking('Date of birth');

        } else if (name === 'phone') {
            let phone = this.formatPhoneInput(e.target.value);
            step4 = { ...this.state.step4, [name]: phone };
            this.inputAnalyticsTracking('Phone Number');

        } else {
            step4 = { ...this.state.step4, [name]: e.target.value };

            this.inputAnalyticsTracking(name);
        }
        await this.setState(() => ({ step4 }));
    };

    onSelectHandlerStep4 = async (e: any, name: string) => {
        const step = { ...this.state.step4, [name]: e.value };
        await this.setState(() => ({ step4: step }));

        const fieldFullName = name === 'gender' ? 'Gender' : 'Marital Status';

        this.inputAnalyticsTracking(fieldFullName);
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const step4 = { ...this.state.step4, checkedG: event.target.checked };
        this.setState(() => ({ step4 }));
    };

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        step1: {
                            street: `${streetNumber} ${route}`,
                            city: `${city}, ${state}`,
                            zipcode: zip,
                            apt: this.state.step1 && this.state.step1.apt,
                            // state: { name: state, label: state },
                            address: add,
                        },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                // if (
                //     (country === 'USA' || country === 'US') &&
                //     state &&
                //     city &&
                //     zip &&
                //     streetNumber &&
                //     route &&
                //     this.state.moveId
                // ) {
                //     this.props.commonAction.moveAddressUpdate({
                //         destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                //         move_id: this.state.moveId,
                //     });
                // }
            })
            .catch(error => console.error('error', error));
    };

    validateYear = (year: number | null) => {
        var d = new Date();
        var n = d.getFullYear();
        if (year !== null && year >= 1900 && year <= n) return true;
        else return false;
    };
    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <div>
                        <div className="insuranceAddressCard">
                            <div className="insuranceBoxCard addHeight">
                                <div className="heading">
                                    Let's start by verifying the address you would like to insure
                                </div>
                                <div className="addressFlexDiv">
                                    <div className="insuranceBox">
                                        {/* <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'baseline',
                                                marginBottom: '-10px',
                                            }}
                                        >
                                            <div style={{ color: '#6B6C6F', fontSize: '12px' }}>
                                                Why do we ask for your address?
                                            </div>
                                            <div style={{ marginLeft: '7px' }}>
                                                <img src={iIcon} height="12px" width="12px" />
                                            </div>
                                        </div> */}
                                        <div className="inputs">
                                            {/* <img src={LocationIcon} alt="" className="location-Icon" /> */}
                                            {/* <LocationIcon
                                                className="location-Icon"
                                                fill={this.state.slug && this.state.slug.primary_color}
                                                stroke={this.state.slug && this.state.slug.primary_color}
                                            /> */}
                                            <LocationIcon
                                                fromlanding={
                                                    this.props.history &&
                                                    this.props.history.location &&
                                                    this.props.history.location.state &&
                                                    this.props.history.location.state.fromLanding &&
                                                    this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? 1
                                                        : 0
                                                }
                                                isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                className="location-Icon"
                                            />
                                            <div
                                                className={
                                                    this.state.isDouglasElliman
                                                        ? 'innerInput1 innerInputDouglas'
                                                        : 'innerInput1'
                                                }
                                                style={{ width: '100%' }}
                                            >
                                                <AutoComplete
                                                    value={this.state.step1 && this.state.step1.street}
                                                    onChange={e => this.onChangeHandlerStep1(e, 'street')}
                                                    onSelect={this.handleSelect}
                                                    placeholder="Street"
                                                    // inputTitle="To"
                                                    className="input"
                                                    error={
                                                        this.state.continueClick &&
                                                        this.state.step1 &&
                                                        this.state.step1.street === ''
                                                    }
                                                    helperText={
                                                        this.state.continueClick &&
                                                        this.state.step1 &&
                                                        this.state.step1.street === ''
                                                            ? 'Please enter street'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            {/* <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.step1 &&
                                                    this.state.step1.street === '' &&
                                                    'Please enter street'}
                                            </div> */}

                                            <div
                                                className={
                                                    this.state.isDouglasElliman
                                                        ? 'innerInputs insurance innerInputsDouglas'
                                                        : 'innerInputs insurance'
                                                }
                                            >
                                                <div className="insuranceInnerInput1">
                                                    <TextField
                                                        className="input2"
                                                        label="City"
                                                        value={this.state.step1 && this.state.step1.city}
                                                        id="outlined-start-adornment"
                                                        onChange={(e: any) => this.onChangeHandlerStep1(e, 'city')}
                                                        variant="filled"
                                                        error={
                                                            this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.city === ''
                                                        }
                                                        helperText={
                                                            this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.city === ''
                                                                ? 'Please enter city'
                                                                : ''
                                                        }
                                                    />
                                                    {/* <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.city === '' &&
                                                            'Please enter city'}
                                                    </div> */}
                                                </div>
                                                <div className="insuranceInnerInput2">
                                                    <TextField
                                                        className="input3"
                                                        label="Apt/unit"
                                                        id="outlined-start-adornment"
                                                        value={this.state.step1 && this.state.step1.apt}
                                                        onChange={(e: any) => this.onChangeHandlerStep1(e, 'apt')}
                                                        variant="filled"
                                                    />
                                                    {/* <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.apt === '' &&
                                                            'Please enter Apt/unit'}
                                                    </div> */}
                                                </div>
                                                <div className="insuranceInnerInput3">
                                                    <TextField
                                                        className="input4"
                                                        label="Zip code"
                                                        id="outlined-start-adornment"
                                                        value={this.state.step1 && this.state.step1.zipcode}
                                                        onChange={(e: any) => this.onChangeHandlerStep1(e, 'zipcode')}
                                                        variant="filled"
                                                        error={
                                                            this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.zipcode === ''
                                                        }
                                                        helperText={
                                                            this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.zipcode === ''
                                                                ? 'Please enter zip code'
                                                                : ''
                                                        }
                                                    />
                                                    {/* <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step1 &&
                                                            this.state.step1.zipcode === '' &&
                                                            'Please enter zip code'}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="insuranceAddressCard">
                            <div className="insuranceBoxCard">
                                <div className="heading">Tell us a little bit about your home</div>
                                <div className="addressFlexDiv">
                                    <div className="insuranceBox">
                                        <div className="inputs">
                                            <div className="innerInputs" style={{ marginTop: '-20px' }}>
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={HomeIcon} alt="" className="icons" /> */}
                                                        <HomeIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div style={{ width: '200px' }}>
                                                        <div className="inputLabel">
                                                            <InputLabel className="inputLable">
                                                                What is the sq. footage?
                                                            </InputLabel>
                                                        </div>
                                                        <TextField
                                                            // InputLabelProps={ {shrink: true }}
                                                            className={
                                                                this.state.isDouglasElliman
                                                                    ? 'input1 input1Douglas'
                                                                    : 'input1'
                                                            }
                                                            label="Square FT"
                                                            type="text"
                                                            id="outlined-start-adornment"
                                                            onChange={(e: any) => this.onChangeHandlerStep2(e, 'size')}
                                                            variant="filled"
                                                            value={this.state.step2 && this.state.step2.size}
                                                            error={
                                                                this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.size
                                                                    ? true
                                                                    : this.state.continueClick &&
                                                                      this.state.step2 &&
                                                                      this.state.step2.size &&
                                                                      this.state.step2.size < 500
                                                                    ? true
                                                                    : this.state.step2Clicked &&
                                                                      this.state.step2 &&
                                                                      this.state.step2.size &&
                                                                      this.state.step2.size < 500
                                                                    ? true
                                                                    : false
                                                            }
                                                            // InputLabelProps={{
                                                            //     // shrink:
                                                            //     //     this.state.step2 && this.state.step2.size
                                                            //     //         ? true
                                                            //     //         : false,
                                                            // }}
                                                            // helperText={
                                                            //     this.state.continueClick &&
                                                            //     this.state.step2 &&
                                                            //     !this.state.step2.size
                                                            //         ? 'Please enter Square ft.'
                                                            //         : ''
                                                            // }
                                                        />
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                            this.state.step2 &&
                                                            !this.state.step2.size
                                                                ? 'Please enter Square ft.'
                                                                : this.state.continueClick &&
                                                                  this.state.step2 &&
                                                                  this.state.step2.size &&
                                                                  this.state.step2.size < 500
                                                                ? 'Please enter valid Square ft.'
                                                                : this.state.step2Clicked &&
                                                                  this.state.step2 &&
                                                                  this.state.step2.size &&
                                                                  this.state.step2.size < 500
                                                                ? 'Please enter valid Square ft.'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={BuiltIcon} alt="" className="icons" /> */}
                                                        <BuiltIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div style={{ width: '200px' }}>
                                                        <div className="inputLabel">
                                                            <InputLabel>What year was it built?</InputLabel>
                                                        </div>
                                                        <TextField
                                                            className={
                                                                this.state.isDouglasElliman
                                                                    ? 'input1 input1Douglas'
                                                                    : 'input1'
                                                            }
                                                            label="Year"
                                                            type="text"
                                                            id="outlined-start-adornment"
                                                            value={this.state.step2 && this.state.step2.builtYear}
                                                            onChange={(e: any) =>
                                                                this.onChangeHandlerStep2(e, 'builtYear')
                                                            }
                                                            variant="filled"
                                                            error={
                                                                this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.builtYear
                                                                    ? true
                                                                    : this.state.continueClick &&
                                                                      this.state.step2.builtYear &&
                                                                      !this.validateYear(this.state.step2.builtYear)
                                                                    ? true
                                                                    : this.state.step2Clicked &&
                                                                      this.state.step2 &&
                                                                      !this.state.step2.builtYear
                                                                    ? true
                                                                    : this.state.step2Clicked &&
                                                                      this.state.step2.builtYear &&
                                                                      !this.validateYear(this.state.step2.builtYear)
                                                                    ? true
                                                                    : false
                                                            }
                                                            // InputLabelProps={{
                                                            //     shrink:
                                                            //         this.state.step2 && this.state.step2.builtYear
                                                            //             ? true
                                                            //             : false,
                                                            // }}
                                                        />
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                            this.state.step2 &&
                                                            !this.state.step2.builtYear
                                                                ? 'Please enter build year.'
                                                                : this.state.continueClick &&
                                                                  this.state.step2.builtYear &&
                                                                  !this.validateYear(this.state.step2.builtYear)
                                                                ? 'Enter valid year'
                                                                : this.state.step2Clicked &&
                                                                  this.state.step2 &&
                                                                  !this.state.step2.builtYear
                                                                ? 'Please enter build year.'
                                                                : this.state.step2Clicked &&
                                                                  this.state.step2.builtYear &&
                                                                  !this.validateYear(this.state.step2.builtYear)
                                                                ? 'Enter valid year'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="innerInputs">
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={StairsIcon} alt="" className="icons" /> */}
                                                        <StairsIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>How many stories?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step2.noOfStories
                                                                    ? 'slectedAdressInsurance'
                                                                    : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.noOfStories
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step2.noOfStories && (
                                                                <div className="stateTextAddressInsurance">Stories</div>
                                                            )}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep2(e, 'noOfStories')
                                                                }
                                                                options={[
                                                                    { value: '1', label: '1' },
                                                                    { value: '2', label: '2' },
                                                                    { value: '3', label: '3' },
                                                                    { value: '4', label: '4' },
                                                                ]}
                                                                placeholder={
                                                                    this.state.step2.noOfStories
                                                                        ? this.state.step2.noOfStories
                                                                        : 'Select number of stories'
                                                                }
                                                                value={this.state.step2 && this.state.step2.noOfStories}
                                                                // label="Select number of stories"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.noOfStories &&
                                                                'Please select no of stories.'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={RoofIcon} alt="" className="icons" /> */}
                                                        <RoofIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>What is the roof made of?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step2.roofType
                                                                    ? 'slectedAdressInsurance'
                                                                    : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.roofType
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step2.roofType && (
                                                                <div className="stateTextAddressInsurance">
                                                                    Roof Type
                                                                </div>
                                                            )}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep2(e, 'roofType')
                                                                }
                                                                options={this.state.roofTypeOptions}
                                                                placeholder={
                                                                    this.state.step2.roofType
                                                                        ? this.state.step2.roofType
                                                                        : 'Select roof type'
                                                                }
                                                                value={this.state.step2 && this.state.step2.roofType}
                                                                // label="Select roof type"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step2 &&
                                                                !this.state.step2.roofType &&
                                                                'Please select roof type.'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="insuranceAddressCard">
                            <div className="insuranceBoxCard">
                                <div className="heading">Tell us a little bit about your home</div>
                                <div className="addressFlexDiv">
                                    <div className="insuranceBox">
                                        <div className="inputs">
                                            <div className="innerInputs">
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={HeaterIcon} alt="" className="icons" /> */}
                                                        <HeaterIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>What is the primary heating system?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step3.heatingSystem
                                                                    ? 'slectedAdressInsurance'
                                                                    : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.heatingSystem
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step3.heatingSystem && (
                                                                <div className="stateTextAddressInsurance">
                                                                    Heating System
                                                                </div>
                                                            )}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep3(e, 'heatingSystem')
                                                                }
                                                                options={this.state.heaterOptions}
                                                                placeholder={
                                                                    this.state.step3.heatingSystem
                                                                        ? this.state.step3.heatingSystem
                                                                        : 'Select heating system'
                                                                }
                                                                value={
                                                                    this.state.step3 && this.state.step3.heatingSystem
                                                                }
                                                                // label="Select heating system"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.heatingSystem &&
                                                                'Please select heating system'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={PoolIcon} alt="" className="icons" /> */}
                                                        <PoolIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>Does it have a swimming pool?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step3.pool ? 'slectedAdressInsurance' : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.pool
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step3.pool && (
                                                                <div className="stateTextAddressInsurance">Pool</div>
                                                            )}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep3(e, 'pool')
                                                                }
                                                                options={this.state.poolOptions}
                                                                placeholder={
                                                                    this.state.step3.pool
                                                                        ? this.state.step3.pool
                                                                        : 'Select pool type'
                                                                }
                                                                value={this.state.step3 && this.state.step3.pool}
                                                                // label="Select pool type"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.pool &&
                                                                'Please select pool type'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="innerInputs">
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={StairsIcon} alt="" className="icons" /> */}
                                                        <ConstructionIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>What is construction type?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step3.constructionType
                                                                    ? 'slectedAdressInsurance'
                                                                    : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.constructionType
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step3.constructionType && (
                                                                <div className="stateTextAddressInsurance">
                                                                    Construction Type
                                                                </div>
                                                            )}
                                                            {/* <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep2(e, 'constructionType')
                                                                }
                                                                options={this.state.constructionTypeOptions}
                                                                placeholder={
                                                                    this.state.step2 &&
                                                                    this.state.step2.constructionType
                                                                        ? this.state.step2.constructionType
                                                                        : 'Select construction type'
                                                                }
                                                                // value={
                                                                //     this.state.step2 &&
                                                                //     this.state.step2.constructionType
                                                                // }
                                                                label="Select construction type"
                                                            /> */}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep3(e, 'constructionType')
                                                                }
                                                                options={this.state.constructionTypeOptions}
                                                                placeholder={
                                                                    this.state.step3 &&
                                                                    this.state.step3.constructionType &&
                                                                    this.state.step3.constructionType !== undefined
                                                                        ? this.state.step3.constructionType
                                                                        : 'Select construction type'
                                                                }
                                                                value={
                                                                    this.state.step3 &&
                                                                    this.state.step3.constructionType
                                                                }
                                                                // label="Select pool type"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.constructionType &&
                                                                'Please select construction type.'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="addressFlexDiv">
                                                    <div className="iconDiv12">
                                                        {/* <img src={RoofIcon} alt="" className="icons" /> */}
                                                        <FoundationIcon
                                                            fromlanding={
                                                                this.props.history &&
                                                                this.props.history.location &&
                                                                this.props.history.location.state &&
                                                                this.props.history.location.state.fromLanding &&
                                                                this.props.history.location.state.fromLanding ==
                                                                    'fromLanding'
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="inputLabel">
                                                            <InputLabel>What is the foundation type?</InputLabel>
                                                        </div>
                                                        <div
                                                            style={{ position: 'relative' }}
                                                            className={`${
                                                                this.state.step3.foundationType
                                                                    ? 'slectedAdressInsurance'
                                                                    : ''
                                                            } ${
                                                                this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.foundationType
                                                                    ? 'errorInsurance'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {this.state.step3.foundationType && (
                                                                <div className="stateTextAddressInsurance">
                                                                    Foundation Type
                                                                </div>
                                                            )}
                                                            <SelectInput
                                                                className="react-select alert-select"
                                                                onChange={(e: any) =>
                                                                    this.onSelectHandlerStep3(e, 'foundationType')
                                                                }
                                                                options={this.state.foundationTypeOptions}
                                                                placeholder={
                                                                    this.state.step3.foundationType
                                                                        ? this.state.step3.foundationType
                                                                        : 'Select foundation type'
                                                                }
                                                                defaultValue=""
                                                                value={
                                                                    this.state.step3 && this.state.step3.foundationType
                                                                }
                                                                // label="Select foundation type"
                                                            />
                                                        </div>
                                                        <div className="error">
                                                            {this.state.continueClick &&
                                                                this.state.step3 &&
                                                                !this.state.step3.foundationType &&
                                                                'Please select foundation type.'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <form>
                        <div className="insuranceAddressCard">
                            <div className="insuranceBoxCard">
                                <div className="heading">Finally, tell us a little bit about yourself?</div>
                                <div className="marginDiv">
                                    {this.state.hostname &&
                                        (this.state.hostname.includes('insurance') ||
                                            this.state.hostname.includes('insurance-demo')) ? (
                                        <div
                                            className={
                                                this.props.history &&
                                                    this.props.history.location &&
                                                    this.props.history.location.state &&
                                                    this.props.history.location.state.fromLanding &&
                                                    this.props.history.location.state.fromLanding == 'fromLanding'
                                                    ? 'addressFlexDiv-landing'
                                                    : 'addressFlexDiv'
                                            }
                                        >
                                            <div
                                                style={{
                                                    margin: '0px 10px',
                                                    width:
                                                        this.props.history &&
                                                            this.props.history.location &&
                                                            this.props.history.location.state &&
                                                            this.props.history.location.state.fromLanding &&
                                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                            ? '100%'
                                                            : '',
                                                }}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    <TextField
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'input1 input1Douglas'
                                                                : 'input1'
                                                        }
                                                        label="Full Name"
                                                        type="text"
                                                        id="outlined-start-adornment"
                                                        value={this.state.step4 && this.state.step4.fullName}
                                                        onChange={(e: any) => this.onChangeHandlerStep4(e, 'fullName')}
                                                        variant="filled"
                                                        error={
                                                            this.state.continueClick &&
                                                                this.state.step4 &&
                                                                !this.state.step4.fullName
                                                                ? true
                                                                : this.state.continueClick && this.state.step4.fullName
                                                                    ? // !this.validateYear(this.state.step2.builtYear)
                                                                    true
                                                                    : false
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step4 &&
                                                            !this.state.step4.fullName
                                                            ? 'Please enter full name.'
                                                            : this.state.continueClick && this.state.step4.fullName
                                                                ? // !this.validateYear(this.state.step2.builtYear)
                                                                'Enter valid name'
                                                                : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    margin: '0px 10px',
                                                    width:
                                                        this.props.history &&
                                                            this.props.history.location &&
                                                            this.props.history.location.state &&
                                                            this.props.history.location.state.fromLanding &&
                                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                            ? '100%'
                                                            : '',
                                                }}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    <TextField
                                                        className="input1"
                                                        label="Email"
                                                        type="text"
                                                        id="outlined-start-adornment"
                                                        value={this.state.step4 && this.state.step4.email}
                                                        onChange={(e: any) => this.onChangeHandlerStep4(e, 'email')}
                                                        variant="filled"
                                                        error={
                                                            this.state.continueClick &&
                                                                this.state.step4 &&
                                                                !this.state.step4.email
                                                                ? true
                                                                : this.state.continueClick && this.state.step4.email
                                                                    ? // !this.validateYear(this.state.step2.builtYear)
                                                                    true
                                                                    : false
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step4 &&
                                                            !this.state.step4.email
                                                            ? 'Please enter email.'
                                                            : this.state.continueClick && this.state.step4.email
                                                                ? // !this.validateYear(this.state.step2.builtYear)
                                                                'Enter valid email'
                                                                : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    margin: '0px 10px',
                                                    width:
                                                        this.props.history &&
                                                            this.props.history.location &&
                                                            this.props.history.location.state &&
                                                            this.props.history.location.state.fromLanding &&
                                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                            ? '100%'
                                                            : '',
                                                }}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    <TextField
                                                        className="input1"
                                                        label="Phone Number"
                                                        type="text"
                                                        id="outlined-start-adornment"
                                                        value={this.state.step4 && this.state.step4.phone}
                                                        onChange={(e: any) => this.onChangeHandlerStep4(e, 'phone')}
                                                        variant="filled"
                                                        error={
                                                            this.state.continueClick &&
                                                                this.state.step4 &&
                                                                !this.state.step4.phone
                                                                ? true
                                                                : this.state.continueClick && this.state.step4.phone
                                                                    ? // !this.validateYear(this.state.step2.builtYear)
                                                                    true
                                                                    : false
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.continueClick &&
                                                            this.state.step4 &&
                                                            !this.state.step4.phone
                                                            ? 'Please enter phone.'
                                                            : this.state.continueClick && this.state.step4.phone
                                                                ? // !this.validateYear(this.state.step2.builtYear)
                                                                'Enter valid phone'
                                                                : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div
                                        className={
                                            this.props.history &&
                                                this.props.history.location &&
                                                this.props.history.location.state &&
                                                this.props.history.location.state.fromLanding &&
                                                this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? 'addressFlexDiv-landing'
                                                : 'addressFlexDiv'
                                        }
                                    >
                                        <div
                                            style={{
                                                margin: '0px 10px',
                                                width:
                                                    this.props.history &&
                                                        this.props.history.location &&
                                                        this.props.history.location.state &&
                                                        this.props.history.location.state.fromLanding &&
                                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '100%'
                                                        : '',
                                            }}
                                        >
                                            <div style={{ position: 'relative' }}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableFuture
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date"
                                                        inputVariant="filled"
                                                        label="Date of birth"
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'dateInsurance'
                                                                : 'dateInsurance1'
                                                        }
                                                        value={
                                                            this.state.step4.birthdate
                                                                ? new Date(this.state.step4.birthdate)
                                                                : null
                                                        }
                                                        onChange={(e: any) => this.onChangeHandlerStep4(e, 'birthdate')}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            // onFocus: () => {
                                                            //     this.setState({ isOpen: true });
                                                            // },
                                                        }}
                                                        error={
                                                            (this.state.continueClick &&
                                                                this.state.step4 &&
                                                                !this.state.step4.birthdate) ||
                                                            (this.state.step4 &&
                                                                this.state.step4.birthdate === '' &&
                                                                !Date.parse(this.state.step4.birthdate))
                                                        }
                                                        autoOk={true}
                                                    // PopoverProps={{
                                                    //     disableRestoreFocus: true,
                                                    //     onClose: () => {
                                                    //         this.setState({ isOpen: false });
                                                    //     },
                                                    // }}
                                                    // InputProps={{
                                                    //     onFocus: () => {
                                                    //         this.setState({ isOpen: true });
                                                    //     },
                                                    // }}
                                                    // open={this.state.isOpen}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="error">
                                                {(this.state.continueClick &&
                                                    this.state.step4 &&
                                                    !this.state.step4.birthdate &&
                                                    'Please enter birthdate') ||
                                                    (this.state.step4 &&
                                                        this.state.step4.birthdate === '' &&
                                                        !Date.parse(this.state.step4 && this.state.step4.birthdate) &&
                                                        'Invalid date')}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                margin: '0 10px',
                                                width:
                                                    this.props.history &&
                                                        this.props.history.location &&
                                                        this.props.history.location.state &&
                                                        this.props.history.location.state.fromLanding &&
                                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '100%'
                                                        : '',
                                            }}
                                        >
                                            <div
                                                style={{ position: 'relative' }}
                                                className={`${this.state.step4.gender ? 'slectedAdressInsurance' : ''
                                                    } ${this.state.continueClick &&
                                                        this.state.step4 &&
                                                        !this.state.step4.gender
                                                        ? 'errorInsurance'
                                                        : ''
                                                    }`}
                                            >
                                                {this.state.step4.gender && (
                                                    <div className="stateTextAddressInsurance1">Gender</div>
                                                )}
                                                <SelectInput
                                                    className="react-select alert-select"
                                                    onChange={(e: any) => this.onSelectHandlerStep4(e, 'gender')}
                                                    options={this.state.genderOptions}
                                                    placeholder={
                                                        this.state.step4.gender ? this.state.step4.gender : 'Gender'
                                                    }
                                                    value={this.state.step4.gender}
                                                // label="Gender"
                                                />
                                            </div>
                                            <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.step4 &&
                                                    !this.state.step4.gender &&
                                                    'Please select gender'}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                margin: '0 10px',
                                                width:
                                                    this.props.history &&
                                                        this.props.history.location &&
                                                        this.props.history.location.state &&
                                                        this.props.history.location.state.fromLanding &&
                                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? '100%'
                                                        : '',
                                            }}
                                        >
                                            <div
                                                style={{ position: 'relative' }}
                                                className={`${this.state.step4.maritalStatus ? 'slectedAdressInsurance' : ''
                                                    } ${this.state.continueClick &&
                                                        this.state.step4 &&
                                                        !this.state.step4.maritalStatus
                                                        ? 'errorInsurance'
                                                        : ''
                                                    }`}
                                            >
                                                {this.state.step4.maritalStatus && (
                                                    <div className="stateTextAddressInsurance2">Marital Status</div>
                                                )}
                                                <SelectInput
                                                    className="react-select alert-select"
                                                    onChange={(e: any) => this.onSelectHandlerStep4(e, 'maritalStatus')}
                                                    options={this.state.maritalStatusOptions}
                                                    placeholder={
                                                        this.state.step4 && this.state.step4.maritalStatus
                                                            ? this.state.step4.maritalStatus
                                                            : 'Marital status'
                                                    }
                                                    value={this.state.step4 && this.state.step4.maritalStatus}
                                                // label="Marital status"
                                                />
                                            </div>
                                            <div className="error">
                                                {this.state.continueClick &&
                                                    this.state.step4 &&
                                                    !this.state.step4.maritalStatus &&
                                                    'Please select marital status'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.step4 &&
                            this.state.step4.maritalStatus &&
                            this.state.step4.gender &&
                            this.state.step4.birthdate ? (
                            <div>
                                <div style={{ display: 'flex', marginTop: '20px', textAlign: 'justify' }}>
                                    <Checkbox
                                        color="primary"
                                        className="checkbox"
                                        checked={this.state.step4.checkedG}
                                        onChange={this.handleChange}
                                        name="checkedG"
                                    />
                                    <div className="innerContentInsurance">
                                        {/* By clicking the button below, you are providing your signed written consent to
                                    receive calls or texts from InsuraMatch and its agents to market insurance
                                    products at the number (wireless or otherwise) you provided above, even if the
                                    number is on a federal, state or local do not call list. As part of that
                                    consent, you understand and agree that (1) those calls may be auto-dialed and
                                    use artificial or pre-recorded voice messages or SMS communications, and (2)
                                    your agreement to receive these calls is not required to purchase any goods or
                                    services, and (3) InsuraMatch will not sell your information to third parties
                                    without your consent, and (4) InsuraMatch may make additional attempts to
                                    call/message you if we are unable to reach you at the scheduled time. You may
                                    revoke your consent by contacting us at{' '}
                                    <span
                                        className={
                                            this.state.isDouglasElliman ? 'redirectInsuranceD' : 'redirectInsurance'
                                        }
                                    >
                                        844-406-1890.
                                    </span>{' '}
                                    You agree to InsuraMatch's{' '} */}
                                        By clicking the “review your quotes” button below, you consent to Move Easy
                                        sharing your quote information with InsuraMatch and agree to InsuraMatch’s
                                        <span
                                            onClick={() =>
                                                window.open(
                                                    'https://www.insuramatch.com/terms-and-conditions',
                                                    '_blank',
                                                )
                                            }
                                            className={
                                                this.state.isDouglasElliman ? 'redirectInsuranceD' : 'redirectInsurance'
                                            }
                                        >
                                            Terms & Conditions,
                                        </span>{' '}
                                        <span
                                            onClick={() =>
                                                window.open(
                                                    'https://www.insuramatch.com/compensation-and-fcra-information-disclosure',
                                                    '_blank',
                                                )
                                            }
                                            className={
                                                this.state.isDouglasElliman ? 'redirectInsuranceD' : 'redirectInsurance'
                                            }
                                        >
                                            Compensation and Information Disclosure
                                        </span>{' '}
                                        and{' '}
                                        <span
                                            onClick={() =>
                                                window.open('https://www.insuramatch.com/privacy-policy', '_blank')
                                            }
                                            className={
                                                this.state.isDouglasElliman ? 'redirectInsuranceD' : 'redirectInsurance'
                                            }
                                        >
                                            Privacy Policy
                                        </span>{' '}
                                        and authorize InsuraMatch and the companies it represents to obtain consumer
                                        reports on the persons listed in your application.
                                    </div>
                                </div>
                                <div className="error">
                                    {this.state.continueClick &&
                                        this.state.step4 &&
                                        !this.state.step4.checkedG &&
                                        'Please accept terms & conditions'}
                                </div>
                            </div>
                        ) : null}
                        
                        <input type="hidden" name="xxCertUrl" ref={(input) => { this.trustedUrl = input?.value }}/>
                        </form>
                    </div>
                );
            default:
                return 'step not found';
        }
    };

    handleNext = async () => {

        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Step Form',
            button_copy: this.state.activeStep === 3 ? 'Review Your Quotes' : 'Next',
            form_button: true,
            form_step_number: this.state.activeStep + 1
        });
        
        const { street, city, address, zipcode } = this.state.step1;
        const { size, builtYear, noOfStories, roofType } = this.state.step2;
        const { heatingSystem, pool, constructionType, foundationType } = this.state.step3;
        const { birthdate, gender, maritalStatus, checkedG } = this.state.step4;
        // if (this.state.activeStep !== this.getSteps().length - 1) {

        if (
            this.state.hostname &&
            !this.state.hostname.includes('insurance') &&
            !this.state.hostname.includes('insurance-demo')
        ) {
            if (this.state.activeStep === 0) {
                await this.setState({ continueClick: true });
                if (street && city && address && zipcode && this.state.moveId) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${street}, ${city}, ${zipcode}`,
                        move_id: this.state.moveId,
                    });
                }
              
            } else if (this.state.activeStep === 1) {
                await this.setState({ continueClick: true });
                if (
                    size &&
                    builtYear &&
                    noOfStories &&
                    roofType &&
                    // heatingSystem &&
                    // pool &&
                    this.state.moveId &&
                    this.validateYear(builtYear)
                ) {
                    const place = city.split(',');
                    const payload = {
                        heating_type: heatingSystem,
                        roof_material_type: roofType,
                        pool_type: pool,
                        year_built: builtYear,
                        total_area_sq_ft: size,
                        stories: noOfStories,
                        street: street,
                        city: place && place[0],
                        state: place && place[1],
                        zip_code: zipcode,
                    };
                    this.props.insuranceAction.updateProperty({ move_id: this.state.moveId, payload });
                    // this.setState({ activeStep: 2 });
                    let move_id: any = localStorage.getItem('moveKey');
                   

                    formStartedSegment(
                        'Form Started',
                        'Home Insurance',
                        'Home Insurance',
                        this.state.brokerage_name,
                        this.state.brokerage_key,
                        this.state.agent_name,
                        this.state.agent_key,
                        '',
                        'Next',
                        this.state.prefilled,
                        false,
                        4,
                        move_id,
                    );
                }
            } else if (this.state.activeStep === 2) {
                await this.setState({ continueClick: true });
                if (
                    size &&
                    builtYear &&
                    noOfStories &&
                    roofType &&
                    heatingSystem &&
                    pool &&
                    constructionType &&
                    foundationType &&
                    this.state.moveId &&
                    this.validateYear(builtYear)
                ) {
                    const place = city.split(',');
                    const payload = {
                        heating_type: heatingSystem,
                        roof_material_type: roofType,
                        pool_type: pool,
                        year_built: builtYear,
                        total_area_sq_ft: size,
                        stories: noOfStories,
                        street: street,
                        city: place && place[0],
                        state: place && place[1],
                        zip_code: zipcode,
                        construction_type: constructionType,
                        foundation_type: foundationType,
                    };
                    this.props.insuranceAction.updateProperty({ move_id: this.state.moveId, payload });
                }
               
            } else if (this.state.activeStep === 3) {
                await this.setState({ continueClick: true });
                if (birthdate && gender && maritalStatus && checkedG && this.state.moveId) {
                    const payload = {
                        gender: gender,
                        marital_status: maritalStatus,
                        occupation: null,
                        career_status: null,
                        is_secondary_holder_exist: false,
                        secondary_holder_first_name: null,
                        secondary_holder_last_name: null,
                        spouse_gender: null,
                        accepted_terms: checkedG,
                        date_of_birth: new Date(birthdate).toISOString().split('T')[0],
                    };
                    this.props.insuranceAction.updatePersonal({ move_id: this.state.moveId, payload });
                }
                let move_id: any = localStorage.getItem('moveKey');
              

                formCompletedSegment(
                    'Form Completed',
                    'Insurance Form',
                    'Home Insurance',
                    this.state.brokerage_name,
                    this.state.brokerage_key,
                    this.state.agent_name,
                    this.state.agent_key,
                    '',
                    'Review Your Quotes',
                    true,
                    this.state.prefilled,
                    'Insurance Form',
                    this.state.activeStep + 1,
                    move_id,
                );
            }
        } else {
            const domain = window.location.hostname;
            let name = domain.split('.');
            if (this.state.activeStep === 3) {
                const payload = {
                    destination_detail: this.state.step1.address,
                    destination_home: '1',
                    moving_date: new Date().toISOString().split('T')[0],
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    type: 'insurance',
                    user_ref: {
                        email: this.state.step4.email,
                        first_name:
                            this.state.step4.fullName && this.state.step4.fullName.split(' ')[0]
                                ? this.state.step4.fullName.split(' ')[0]
                                : '',
                        last_name:
                            this.state.step4.fullName && this.state.step4.fullName.split(' ')[1]
                                ? this.state.step4.fullName.split(' ')[1]
                                : '',
                        // password: password,
                        phone: this.state.step4.phone,
                        site_name:
                            name[0] == 'internet'
                                ? 'dtc-marketing'
                                : name[0] == 'internet-demo'
                                ? 'apps-demo'
                                : 'moveeasy-demo',
                    },
                };
                if (localStorage.getItem('token') && localStorage.getItem('moveId')) {
                    const moveId = localStorage.getItem('moveId');
                    this.setState({ moveId: moveId });
                    const { street, city, zipcode } = this.state.step1;
                    const { size, builtYear, noOfStories, roofType } = this.state.step2;
                    const { heatingSystem, pool } = this.state.step3;
                    const { birthdate, gender, maritalStatus } = this.state.step4;
                    const place = city.split(',');
                    const payload = {
                        heating_type: heatingSystem,
                        roof_material_type: roofType,
                        pool_type: pool,
                        year_built: builtYear,
                        total_area_sq_ft: size,
                        stories: noOfStories,
                        street: street,
                        city: place && place[0],
                        state: place && place[1],
                        zip_code: zipcode,
                    };
                    this.props.insuranceAction.updateProperty({ move_id: moveId, payload });
                    setTimeout(() => {
                        const payload1 = {
                            gender: gender,
                            marital_status: maritalStatus,
                            occupation: null,
                            career_status: null,
                            is_secondary_holder_exist: false,
                            secondary_holder_first_name: null,
                            secondary_holder_last_name: null,
                            spouse_gender: null,
                            accepted_terms: true,
                            date_of_birth: new Date(birthdate).toISOString().split('T')[0],
                        };
                        this.props.insuranceAction.updatePersonal({ move_id: moveId, payload: payload1 });
                    }, 2000);
                } else {
                    this.props.signupAction.landingMovePost(payload);
                }

                // this.props.history.push({
                //     pathname: '/landing/insurance/plans',
                //     state: {
                //         fromLanding: 'fromLanding',
                //     },
                // });
            } else if (this.state.activeStep === 1) {
                await this.setState({ step2Clicked: true });
                if (
                    this.state.step2 &&
                    this.state.step2.builtYear &&
                    this.validateYear(this.state.step2.builtYear) &&
                    this.state.step2.size &&
                    this.state.step2.size >= 500
                )
                    this.setState({ activeStep: this.state.activeStep + 1 });
            } else {
                this.setState({ activeStep: this.state.activeStep + 1 });
            }
        }
    };

    handleBack = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1,
        });
        window.scrollTo(0, 0);
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Home Insurance',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Home Insurance',
            'Previous',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            this.state.activeStep,
            '',
            move_id,
        );
    };

    handleSkip = () => {
        this.props.skip(this.state.activeStep, this.getSteps().length);
    };
    render() {
        const steps = this.getSteps();
        const { isDouglasElliman } = this.state;
        const breadList = [
            {
                name: 'Insurance',
                url: ''
            }
        ]

        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className={isDouglasElliman ? 'insuranceSteps DouglasInsuranceTetxfields' : 'insuranceSteps'}>
                    <div className="main-head-insurance">
                        <h2 className="heading-insurance-regular">Insurance</h2>
                        <BreadCrumb breadList={breadList} homeCrumb={this.props.history.location?.state?.fromLanding == 'fromLanding' ? 'Home' : 'Dashboard'} />
                    </div>
                    <div className="steps">
                        {/* {this.state.activeStep === steps.length ? (
                        <PlanPage activeStep={this.state.activeStep} totalSteps={steps.length} />
                    ) : ( */}
                        <div>
                            <div>{this.getStepContent(this.state.activeStep)}</div>
                            <div className="Para12">
                                STEP {this.state.activeStep + 1} OF {steps.length}
                            </div>
                            <div
                                style={{ marginBottom: '15px' }}
                                className={isDouglasElliman ? 'progressStepD' : 'progressStep'}
                            >
                                {/* <LinearProgress
                                    className="linearProgressInsurance"
                                    variant="determinate"
                                    classes={{
                                        barColorPrimary: 'red',
                                    }}
                                    value={((this.state.activeStep + 1) / 3) * 100}
                                    style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                /> */}

                                <Linear
                                    fromLanding={
                                        this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.state &&
                                        this.props.history.location.state.fromLanding &&
                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? true
                                            : false
                                    }
                                    value={((this.state.activeStep + 1) / 4) * 100}
                                />
                            </div>
                            <Stepper activeStep={this.state.activeStep} className="insuranceStepper">
                                {steps.map((_, i: number) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: { optional?: React.ReactNode } = {};
                                    return (
                                        <Step key={i} {...stepProps}>
                                            <StepLabel {...labelProps}></StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className="buttonsInsurancePage">
                                <div></div>

                                <div className="buttonDivInsurance">
                                    {this.state.activeStep === 0 ? null : (
                                        <Button
                                            className="btnIC"
                                            disabled={this.state.activeStep === 0}
                                            onClick={this.handleBack}
                                            backgroundColor="#FFFFFF"
                                            border={
                                                isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : `1px solid ${
                                                          this.state.slug && this.state.slug.primary_color
                                                              ? this.state.slug.primary_color
                                                              : window.location.pathname.includes('landing')
                                                              ? '#30AB86'
                                                              : '#273E59'
                                                      }`
                                            }
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          this.state.slug && this.state.slug.primary_color
                                                              ? this.state.slug.primary_color
                                                              : window.location.pathname.includes('landing')
                                                              ? '#30AB86'
                                                              : '#273E59'
                                                      }`
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                    ? '#30AB86'
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 10px 0 0"
                                            width="110px"
                                            height="50px"
                                        >
                                            Previous
                                        </Button>
                                    )}

                                    <Button
                                        onClick={this.handleNext}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273E59'
                                        }
                                        width={this.state.activeStep === steps.length - 1 ? '220px' : '100px'}
                                        height="50px"
                                        fontSize="16px"
                                        disabled={
                                            this.state.activeStep === 0
                                                ? this.state.step1.street === '' ||
                                                  this.state.step1.city === '' ||
                                                  this.state.step1.zipcode === ''
                                                : this.state.activeStep === 1
                                                ? (!this.state.step2.builtYear || !this.state.step2.size) &&
                                                  //   this.state.step2.heatingSystem !== '' &&
                                                  this.state.step2.noOfStories !== '' &&
                                                  //   this.state.step2.pool !== '' &&
                                                  this.state.step2.roofType !== ''
                                                : this.state.activeStep === 2
                                                ? this.state.step3.heatingSystem == '' ||
                                                  this.state.step3.pool == '' ||
                                                  this.state.step3.constructionType == '' ||
                                                  this.state.step3.foundationType == ''
                                                : this.state.activeStep === 3
                                                ? this.state.step4.birthdate !== '' &&
                                                  this.state.step4.gender !== '' &&
                                                  this.state.step4.maritalStatus !== '' &&
                                                  !this.state.step4.checkedG
                                                : true
                                        }
                                    >
                                        {this.state.activeStep === steps.length - 1 ? 'Review Your Quotes' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                    <Footer history={this.props.history} />
                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
        dashboardAction: bindActionCreators(Action2, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    insuranceState: state.insurance,
    signupState: state.signup,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(steps);
