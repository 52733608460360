import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38.23}
      height={41.154}
      viewBox="0 0 38.23 41.154"
      {...props}
    >
      <defs>
        <style> 
          {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupAlarm_prefix__a{fill:${slug.color_shade1}}`
            : ".signupAlarm_prefix__a{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        className="signupAlarm_prefix__a"
        d="M16.387 30.274a.557.557 0 10-.847.723l1.979 2.318a.557.557 0 00.775.07l4.61-3.75a.557.557 0 10-.7-.864l-4.189 3.408zm0 0"
      />
      <path
        className="signupAlarm_prefix__a"
        d="M.557 14.514h4.879v21.2a.555.555 0 00.557.553h6.713a11.022 11.022 0 005.041 4.4l1.016.439a.56.56 0 00.443 0l1.144-.49a11.029 11.029 0 005.116-4.352h6.773a.555.555 0 00.557-.553v-21.2h4.879a.557.557 0 00.53-.382.55.55 0 00-.2-.618l-3.816-2.758V4.327a.555.555 0 00-.557-.553h-3.923a.555.555 0 00-.557.553v2.794L19.443.106a.56.56 0 00-.655 0L.229 13.515a.55.55 0 00-.2.617.556.556 0 00.528.382zm25.424 16.032a9.732 9.732 0 01-1.213 4.708.551.551 0 00-.246.424 9.919 9.919 0 01-4.608 3.922l-.924.4-.793-.343a9.908 9.908 0 01-4.586-4.048.553.553 0 00-.17-.3 9.734 9.734 0 01-1.192-4.659v-4.873l6.766-3.68 6.967 3.685zm4.285-25.667h2.805v5.073l-2.805-2.026zM19.115 1.236l16.848 12.173h-3.725a.555.555 0 00-.557.553v21.2h-5.617a10.824 10.824 0 001.031-4.616v-5.1a.551.551 0 00-.3-.488l-7.527-3.98a.56.56 0 00-.529 0l-7.319 3.981a.552.552 0 00-.29.485v5.2a10.82 10.82 0 00.986 4.512H6.549v-21.2a.555.555 0 00-.557-.553H2.267zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
