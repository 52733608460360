import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
  <svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  width="36.023"
  height="34.435"
  viewBox="0 0 36.023 34.435"
>
<defs>
         <style>
          {
            `.dashboardSvgprefix__a{fill:${(window.location.pathname.includes("/homeValuation") && slug && slug.primary_color) ?  `${slug.primary_color} !important` : "#bebebe !important"}}`
          }
        </style>
      </defs>
  <g data-name="discount (2)">
    <path
    className="dashboardSvgprefix__a"
      fill="#bebebe"
      d="M40.974 18.731h-5.122v20.655h-24.73V18.731H6L23.487 6l9.983 7.268 2.033 1.48 1.642 1.2zm-8.8 9.521a8.687 8.687 0 10-8.687 8.687 8.687 8.687 0 008.687-8.687zm0 0"
      data-name="Path 53784"
      transform="translate(-5.476 -5.475)"
    ></path>
    <path
      fill="#fff"
      d="M323.863 46.988v6.337l-.017.023-1.642-1.2-2.033-1.48v-3.68zm0 0"
      data-name="Path 53785"
      transform="translate(-292.178 -42.88)"
    ></path>
    <g   className="dashboardSvgprefix__a" fill="#707070" data-name="Group 43669">
      <path
        d="M109.845 173.558a9.213 9.213 0 10-8.282-5.178 9.212 9.212 0 008.282 5.178zm-6.429-14.244a8.165 8.165 0 11-1.734 5.032 8.165 8.165 0 011.734-5.031zm0 0"
        data-name="Path 53789"
        transform="translate(-91.834 -141.57)"
      ></path>
      <path
        d="M.524 13.78h4.6v20.13a.525.525 0 00.525.525h24.73a.525.525 0 00.525-.525V13.78h4.6a.525.525 0 00.309-.949l-3.6-2.619v-6.1a.525.525 0 00-.525-.525h-3.694a.525.525 0 00-.525.525v2.649L18.32.1a.525.525 0 00-.62 0L.215 12.832a.525.525 0 00.309.949zm27.994-9.147h2.642v4.816l-2.642-1.923zM18.011 1.174l13.349 9.718 2.526 1.839h-3.51a.525.525 0 00-.525.525v20.13H6.171v-20.13a.525.525 0 00-.525-.525h-3.51zm0 0"
        data-name="Path 53790"
      ></path>
    </g>
    <g transform="translate(11.595 16.02)">
      <path
        fill="none"
        d="M0 0h12.831v12.831H0z"
        data-name="Path 53219"
      ></path>
      <path
        className="dashboardSvgprefix__a"
        fill="#707070"
        d="M9.485 6l1.224 1.224L8.1 9.833 5.962 7.695 2 11.662l.754.754 3.208-3.208L8.1 11.346l3.368-3.363 1.224 1.224V6z"
        data-name="Path 53220"
        transform="translate(-.931 -2.792)"
      ></path>
    </g>
  </g>
</svg>
  )
}

export default SvgComponent
