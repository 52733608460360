import React from 'react';
import './dashboardHomeOwner.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommmonFunctions from '../../utils/common-functions';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as DashboardChecklistAction from '../../store/dashboard/actions';
import DashboardBackground from '../../Assets/images/homeOwnerDashboard/homeownerHeader.svg';
import DashboardMobile from '../../Assets/images/homeOwnerDashboard/dashboardMobile.svg';
import Skeleton from 'react-loading-skeleton';
import ApiService from '../api-service';
import CommonService from '../../utils/common-functions';
import eastedLogo from '../../Assets/images/HomeValuation/eastedLogo.png';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import BlogIcon from '../../Assets/images/DashBoard/auto_stories_black_24dp.svg';
import HomeServiceIcon from '../../Assets/images/homeOwnerDashboard/maps_home_work_black_24dp.svg';
import BlueArrow from '../../Assets/images/HomeValuation/BlueArrow.png';
import BlogCardTopImage from '../../components/dashboardComponents/BlogCardTopImage';
import SibcyCLineBlog from '../../Assets/images/DashBoard/sibcyClineBlog2.png';
import TrustedProsImage from '../../Assets/images/homeOwnerDashboard/trustedPros.svg';
import DocumentsImage from '../../Assets/images/homeOwnerDashboard/Documents.svg';
import EnergyImage from '../../Assets/images/homeOwnerDashboard/energy.svg';
import HomeServiceCard from '../../components/homeOwnerDashboardComponents/homeServiceCard';
import UpcomingTaskSecondCard from '../../components/homeOwnerDashboardComponents/upcomingTaskSecondCard';
import UpcomingTaskFirstCard from '../../components/homeOwnerDashboardComponents/upcomingTaskFirstCard';
import SaveMoneyImage from '../../Assets/images/homeOwnerDashboard/saveMoney.svg';
import SubscriptionIcon from '../../Assets/images/homeOwnerDashboard/card_membership_black_24dp.svg';
import utilitiesIcon from '../../Assets/images/homeOwnerDashboard/solar-energy.svg';
import protectionIcon from '../../Assets/images/homeOwnerDashboard/security.svg';
import homeImprovement from '../../Assets/images/HomeValuation/BannerSeeYourOptions.svg';
import inventoryIcon from '../../Assets/images/homeOwnerDashboard/box.svg';
import SubscriptionTopCard from './SubscriptionTopCard';
import SubscriptionGetStartedCard from '../../components/homeOwnerDashboardComponents/SubscriptionGetStartedCard';
import Button from '../../components/atoms/Button';
import InsuranceServiceImage from '../../Assets/images/homeOwnerDashboard/insuranceService.svg';

import Joyride from 'react-joyride';
import DashBoardWelcome from '../../components/dashboardComponents/DashBoardWelcome';
import DashBoardWelcomeImage from '../../Assets/images/DashBoard/DashBoardWelcome.svg';
import Modal from 'react-modal';
import { scroller } from 'react-scroll';
import DashBoardstepRight from '../../components/dashboardComponents/DashBoardStepRight';
import StepRight from '../../Assets/images/DashBoard/StepRight.svg';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DashboardHomeOwnerTopCard from './dashboardHomeOwnerTopCard';
// import MovingCar from '../../Assets/images/Moving/MovingCar.svg';
// import Utilities from '../../Assets/images/DashBoard/utilities.svg';
// import HomeRepair from '../../Assets/images/DashBoard/homeRepair.svg';
// import ChangeAddress from '../../Assets/images/DashBoard/changeAddress.svg';
// import Providers from '../../Assets/images/DashBoard/providers.svg';
// import Moving from '../../Assets/images/DashBoard/MovingImage.svg';
// import MaskGroup from '../../Assets/images/DashBoard/Mask Group 24.svg';
import * as Action from '../../store/nextMove/actions';
import CallWithMikeCard from '../../components/homeOwnerDashboardComponents/CallWithMikeHomeOwner';
import GetStartedMovingServiceCard from '../../components/dashboardComponents/getStartedMovingServiceCard';
import moveEasy from '../../Assets/images/move_easy.svg';
import TextField from '@material-ui/core/TextField';
import ModalCheck from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { scheduleSegment, popUpSegment } from '../../utils/utlis';
import HomeValModal from '../../components/atoms/HomeValuation/Model/Main';
import DataModel from '../../components/atoms/HomeValuation/Model/DataModel';
import Refinance from '../../components/HomeValuation/Refinance';
import HomeImprovement from '../../components/HomeValuation/HomeImprovement';
import Amount from '../../components/HomeValuation/Amount';
import HomeCard from '../../components/atoms/HomeValuation/HomeCard';
import AddLoanDetails from '../../components/homeOwnerDashboardComponents/AddLoanDetails';
import Zillow from '../../../src/Assets/images/HomeValuation/zillow.png';
// import yourMortgage from '../../Assets/images/HomeValuation/yourMortgage.png';
import AnalyticsService from '../analytics-service';
// import LocalStorageService from '../../utils/localStorage';
import { DefaultToast as defaultToast, ToastConfig } from '../../utils/toast';
import ContactDataModal from '../../components/atoms/HomeValuation/Model/ContactDataModal';
import moment from 'moment';
import _ from 'lodash';
import UpcomingTaskPrimaryCard from '../../components/homeOwnerDashboardComponents/UpcomingTaskPrimaryCard'; 
import ICImage from '../../Assets/images/landingIC.svg';
import SecurityServiceImage from '../../Assets/images/homeOwnerDashboard/securityService.svg';

var intervalVar: any;

type initialProps = {
    history?: any;
    commonAction: any;
    commonState: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    nextMoveState?: any;
    nextMoveAction: any;
    dashboardChecklistAction: any;
};

type initialState = {
    fullName: string;
    toStreet: string;
    toAddress: string;
    toPin: string;
    moveDataArrived: boolean;
    moveId: string;
    firstName: string;
    upcomingTaskData: any[];
    upcomingTaskDataPrimary: any[];
    homeServiceData: any;
    utilityGetStartedModal: boolean;
    protectionGetStartedModal: boolean;
    modalIsOpen: boolean;
    currentStep: number;
    run: boolean;
    stepIndex?: any;
    size: number;
    count: number;
    isDataArrived: boolean;
    isHeaderDataArrived: boolean;
    homeUtilitiesService: any;
    homeProtectionService: any;
    homeDocumentryService: any;
    date: any;
    homeUtilitiesServiceCompleted: any;
    homeProtectionServiceCompleted: any;
    homeUtilitiesServiceprogressValue: number;
    homeProtectionServiceprogressValue: number;
    saveMoneyTasks: any;
    progressValueCount: number;
    deregulated: boolean;
    saveMoneyTasksDeregulate: any;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    editHash: string;
    cancelOpen: boolean;
    userDetails?: any;
    isLoadingPropertyValuation?: boolean;
    propertyValuation?: any;
    isLoadingAverageValuation?: boolean;
    propertyAverageValuation?: any;
    serviceProviders?: any;

    reasonError: string;
    reason: string;
    showLoader: boolean;
    calendlyOpen: boolean;
    custom_schedule_call_info_enabled: boolean;
    formOpen: boolean;
    isSubmitClicked: boolean;
    lastName: string;
    email: string;
    phone: string;
    custom_schedule_call_info: any;
    staticCheck: any;
    // savings: boolean;
    hashcode: string;
    customBrokerageName: any;
    customBrandName: any;
    concierge_name: string;
    appointment_type: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key?: any;
    agent_key?: any;

    move_key?: string;
    finacingOptionsUrl: string;

    showMortageModal: boolean;
    isFetchingLoanData: boolean;
    loanDetails: any;
    loanAPIError?: any;

    blogs: any;
    isFetchingBlogs: boolean;
    serviceAgentData?: any;

    mortgageData?: any;
    modalType: string;
    prefferedMortgage?: any;
    isAgentSubmitContactSuccess: boolean;
    isFetchingCardData: boolean;
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
    },
    overlay: {
        background: '#14141480',
        zIndex: 20000,
    },
};

const customStylesNew = {
    content: {
        top: '48%',
        left: '40%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
    },
    overlay: {
        background: '#14141480',
        zIndex: 20000,
    },
};

class HomeOwnerDashboard extends React.Component<initialProps, initialState> {
    state: initialState = {
        fullName: '',
        toStreet: '',
        toAddress: '',
        toPin: '',
        moveDataArrived: true,
        moveId: '',
        firstName: '',
        upcomingTaskData: [],
        upcomingTaskDataPrimary: [],
        homeServiceData: [
            {
                image: TrustedProsImage,
                title: 'Find Your Trusted Pros',
                description: 'Have a home project and need a referral? Check out recommended vendors',
                buttonText: 'Search Pros',
                url: '/dashboard/ourvendors',
            },
            {
                image: DocumentsImage,
                title: 'Add Your Documents',
                description: 'Track and manage your important home documents in your account',
                buttonText: 'Add Documents',
                url: '/dashboard/my-documents',
            },
            {
                name: 'Energy Savings',
                image: EnergyImage,
                title: 'Save On Energy',
                description: 'See what energy savings that may be available for your home.',
                buttonText: 'Save Now',
                url: '/dashboard/energy-savings',
            },
            {
                image: SecurityServiceImage,
                title: "Home Security With 24/7 Monitoring",
                name: "Home Security With 24/7 Monitoring",
                description: "Save up to $1,700 in exclusive savings on home security hardware with installation costs as low as $0.",
                buttonText: "Get Started",
                url: "/dashboard/home-security"
            },
            {
                image: ICImage,
                title: "Internet & Cable",
                name: "Internet & Cable",
                description: "Find the plan that meets your needs. Choose from broadband Internet, cable TV, and voice providers available in your area.",
                buttonText: "Get Started",
                url: "/dashboard/internet"
            },
            {
                image: InsuranceServiceImage,
                title: "Home Insurance",
                name: "Home Insurance",
                description: "With just a few clicks, you can get free quotes from multiple home insurance providers, all in one place.",
                buttonText: "Get Started",
                url: "/dashboard/home-insurance"
            }
        ],
        utilityGetStartedModal: false,
        protectionGetStartedModal: false,
        modalIsOpen: false,
        currentStep: 0,
        run: false,
        stepIndex: 0,
        size: 0,
        count: 0,
        isDataArrived: true,
        isHeaderDataArrived: true,
        homeUtilitiesService: [],
        homeProtectionService: [],
        homeDocumentryService: [],
        date: null,
        homeUtilitiesServiceCompleted: [],
        homeProtectionServiceCompleted: [],
        homeUtilitiesServiceprogressValue: 0,
        homeProtectionServiceprogressValue: 0,
        custom_schedule_call_info_enabled: false,

        saveMoneyTasks: [
            { name: 'Mortgage', completed: false },
            { name: 'Home Insurance', completed: false },
            { name: 'Internet & Cable', completed: false },
            { name: 'Home Security', completed: false },
            { name: 'Utilities', completed: false },
        ],
        saveMoneyTasksDeregulate: [
            { name: 'Mortgage', completed: false },
            { name: 'Home Insurance', completed: false },
            { name: 'Internet & Cable', completed: false },
            { name: 'Home Security', completed: false },
        ],
        progressValueCount: 0,
        deregulated: false,

        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        showLoader: true,
        cancelOpen: false,
        reasonError: '',
        reason: '',
        editHash: '',
        calendlyOpen: false,
        formOpen: false,
        isSubmitClicked: false,
        lastName: '',
        email: '',
        phone: '',
        custom_schedule_call_info: '',
        staticCheck: [],
        // savings: false,
        hashcode: '',
        customBrokerageName: '',
        customBrandName: '',
        concierge_name: '',
        appointment_type: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        showMortageModal: false,
        isFetchingLoanData: true,
        isLoadingPropertyValuation: false,
        isLoadingAverageValuation: false,
        loanDetails: null,
        loanAPIError: null,
        blogs: null,
        isFetchingBlogs: true,
        finacingOptionsUrl: '#',
        modalType: 'mortage',
        prefferedMortgage: {
            name: '',
            id: null,
            email: '',
            phone: '',
            address: '',
        },
        isAgentSubmitContactSuccess: false,
        isFetchingCardData: true,
    };

    toggleMortageModal = (modalType = 'mortage') => {
        this.setState({ showMortageModal: !this.state.showMortageModal, modalType });

        if (modalType === 'mortage') {
            AnalyticsService.buttonClicked({
                category_name: 'Update Home details Button Clicked',
                button_copy: 'Update Home details',
            });
        }
    };

    handleCloseModal = () => {
        this.setState({ isAgentSubmitContactSuccess: false });
        this.toggleMortageModal();
    }

    fetchContactCardData = () => {
        ApiService.getServiceAgents()
            .then(serviceAgentData => {
                this.props.commonAction.getServiceAgentInfo(serviceAgentData)
                this.setState({ isFetchingCardData: false })
            })
            .catch(e => {
                console.debug(e);
                this.setState({ isFetchingCardData: false });
            });

        ApiService.getServiceProviders()
            .then(serviceProviders => {
                this.props.commonAction.getServiceProviderInfo(serviceProviders)
                this.setState({ isFetchingCardData: false })
            })
            .catch(e => {
                this.setState({ isFetchingCardData: false });
                this.showToast(
                    'Error',
                    'danger',
                    'Oops! Something went while fetching service providers. Error: ' + ' ' + e.message || '',
                );
            });
    };

    async componentDidMount() {
        this.props.dashboardAction.homeValuationCardDetails();
        this.setState({ isFetchingBlogs: true });
        ApiService.getBlogs('Homer-Owner-DashBoard')
            .then(blogs => this.setState({ blogs, isFetchingBlogs: false }))
            .catch(() => this.setState({ isFetchingBlogs: true }));
        ApiService.getMortageData()
            .then(mortgageData => this.setState({ mortgageData }))
            .catch(() => {});

        if (!this.props.commonState.serviceAgentInfo && !this.props.commonState.serviceProviderInfo) {
            this.fetchContactCardData()
        } else {
            this.setState({ isFetchingCardData: false })
        }
        
        if (this.props.commonState.serviceAgentInfo) this.setState({ serviceAgentData: this.props.commonState.serviceAgentInfo})
        if (this.props.commonState.serviceProviderInfo) this.setState({ serviceProviders: this.props.commonState.serviceProviderInfo})

        const moveData = this.props.commonState.move[0] || '';
        await this.fetchLoanDetails(moveData);
        
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.setState({ size: window.screen.width });
        window.scrollTo(0, 0);
        this.props.dashboardAction.getSubscriptionDetails();
        let slug: any = localStorage.getItem('slug');
        const moveId: any = localStorage.getItem('moveId');

        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }

        AnalyticsService.pageViewed({page: 'Home Owner Dashboard', category_name: 'Home Owner Dashboard Page Viewed'});

        if (moveId) {
            let payload = {
                moveId: JSON.parse(moveId),
            };
    
            intervalVar = setInterval(() => {
                if (this.state.count < 20) {
                    this.props.dashboardAction.checklistByMoveId(payload);
                    this.setState({
                        count: this.state.count + 1,
                    });
                }
            }, 4000);
        }
    }
    componentWillUnmount = () => clearInterval(intervalVar);

    async componentDidUpdate(prevProps: initialProps) {
        if(!_.isEqual(prevProps.commonState.serviceAgentInfo, this.props.commonState.serviceAgentInfo)){
            this.setState({ serviceAgentData: this.props.commonState.serviceAgentInfo})
        }

        if(!_.isEqual(prevProps.commonState.serviceProviderInfo, this.props.commonState.serviceProviderInfo)){
            this.setState({ serviceProviders: this.props.commonState.serviceProviderInfo})
        }
        
        if (
            prevProps.commonState.moveAddress != this.props.commonState.moveAddress &&
            this.props.commonState.moveAddress
        ) {
            let payload = {
                moveId: this.state.moveId,
            };
            this.props.dashboardAction.checklistByMoveId(payload);
            const isHomeOwner = localStorage.getItem('isHomeOwner');

            let slug: any = localStorage.getItem('slug');
            slug = JSON.parse(slug);
            this.props.commonAction.header({
                partner_slug: slug && slug.slug,
                fetchMoveService: isHomeOwner ? 1 : 0,
            });
        }

        if (prevProps.commonState?.move != this.props.commonState?.move) {
            const moveData = this.props.commonState.move[0];
            await this.fetchLoanDetails(moveData);
        }

        const propertyId = this.props?.homeOwnerDashboardState?.homeValuationCardDetails?.id || null;
        if (
            propertyId &&
            prevProps?.homeOwnerDashboardState?.homeValuationCardDetails?.id !== propertyId &&
            !isNaN(propertyId)
        ) {
            this.setState({ isLoadingAverageValuation: true, isLoadingPropertyValuation: true });
                ApiService.getPropertyValuationFromTP(propertyId)
                .then(propertyValuation => {
                    this.setState({ propertyValuation, isLoadingPropertyValuation: false });
                    ApiService.getPropertyAverageValuation(propertyId)
                        .then(propertyAverageValuation =>
                            this.setState({ propertyAverageValuation, isLoadingAverageValuation: false }),
                        )
                        .catch(err =>{
                            this.setState({ isLoadingAverageValuation: false });
                            this.showToast(
                                'Error!',
                                'danger',
                                'Something went wrong while fetching average valuation. Error: ' + ' ' + err?.message ||
                                    '',
                            )}
                        );
                })
                .catch(err => {
                    this.setState({ isLoadingPropertyValuation: false });
                    defaultToast({ title: 'Error!', type: 'danger', message: err?.message })
                });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            JSON.stringify(nextProps.commonState.appointmentDetails) !==
                JSON.stringify(currentState.appointment_details) &&
            nextProps.commonState.appointmentDetailsArrived
        ) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            currentState.editHash =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.edit_hash;
            currentState.showLoader = false;
            currentState.concierge_name =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.concierge_name;
            currentState.appointment_type =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.appointment_type;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.move_key = nextProps.commonState.move[0] && nextProps.commonState.move[0].move_key;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
            if (currentState.concierge_name && currentState.appointment_type) {
                let windownew: any = window;
                let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                analytics.track(
                    'Schedule Call Completed',
                    {
                        page: 'Home Owner Dashboard',
                        brokerage_name: currentState.customBrokerageName,
                        brokerage_id: currentState.brokerage_id,
                        agent_name: currentState.agent_name,
                        agent_id: currentState.agent_id,
                        concierge_name: currentState.concierge_name,
                        appointment_type: currentState.appointment_type,
                    },
                    { user_id: currentState.moveId.toString() },
                );
            }
        } else if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.error &&
            nextProps.commonState.error != null &&
            nextProps.commonState.error != ''
        ) {
            currentState.showLoader = false;
        }
        if (nextProps && nextProps.commonState && nextProps.commonState.move[0] && currentState.moveDataArrived) {
            currentState.moveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;

            currentState.fullName =
                nextProps.commonState.move[0].user_ref &&
                `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            currentState.fullName =
                nextProps.commonState.move[0].user_ref &&
                `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.toStreet = nextProps.commonState.move[0].destination_street;
            currentState.toAddress = nextProps.commonState.move[0].destination_full_address;
            currentState.toPin =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.zip_code;
            currentState.date = nextProps.commonState.move[0].moving_date;
            currentState.deregulated =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.is_deregulated;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.editHash =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.edit_hash;
            currentState.custom_schedule_call_info =
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.custom_schedule_call_info;
            currentState.showLoader = false;
            currentState.customBrokerageName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name;
            currentState.customBrandName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.brand;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;

            const brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            const move_key = nextProps.commonState.move[0] && nextProps.commonState.move[0].move_key;
            const finacingOptionsUrl = `https://www.acornfinance.com/apply/?d=MOV-DASHBOARD&loanAmount=7000&utm_medium=MOV-${brokerage_key}&utm_content=MOV-${move_key}#/financing`;
            currentState.finacingOptionsUrl = finacingOptionsUrl;

            currentState.userDetails =
                (nextProps.commonState.move?.length && nextProps.commonState?.move[0]?.user_ref) || null;

            // let appoint = currentState.appointment_details;

            let payload2 = {
                moveId: nextProps.commonState.move[0].id,
            };

            nextProps.nextMoveAction.getNextMove(payload2);

            if (nextProps.commonState.move[0].no_of_login < 2) {
                if (
                    nextProps &&
                    nextProps.history &&
                    nextProps.history.location &&
                    nextProps.history.location.state &&
                    nextProps.history.location.state.fromSignUp == 'fromSignUp'
                ) {
                    currentState.modalIsOpen = true;
                    let move_id: any = localStorage.getItem('moveKey');
                    popUpSegment(
                        'Popup Viewed',
                        'Home Owner Dashboard',
                        currentState.brokerage_name,
                        currentState.brokerage_id,
                        currentState.agent_id,
                        currentState.agent_name,
                        `Hi ${currentState.firstName} ,Let's take a tour`,
                        move_id,
                    );
                }
            }
            let payload = {
                moveId: nextProps.commonState.move[0].id,
            };
            // intervalVar = setInterval(() => {
            //     if (currentState.count < 9) {
            nextProps.dashboardAction.checklistByMoveId(payload);
            // this.setState({
            //         currentState.count = currentState.count + 1;
            //         // });
            //     }
            // }, 3000);
        }

        if (
            nextProps &&
            nextProps.nextMoveState &&
            nextProps.nextMoveState.getNextMove &&
            nextProps.nextMoveState.getNextMove.results
        ) {
            let resultNextMove: any = [];

            nextProps.nextMoveState.getNextMove.results.map((item: any) => {
                resultNextMove.push(item);
            });

            let saveMoney: any = currentState.saveMoneyTasks;
            let saveMoneyDer: any = currentState.saveMoneyTasksDeregulate;
            if (currentState.deregulated === true) {
                if (saveMoney.length > 0) {
                    saveMoney.map((item: any) => {
                        resultNextMove.map((item2: any) => {
                            if (item.name === item2.service) {
                                item.completed = true;
                            }
                        });
                    });
                }
                
               const totalCompletedItems = saveMoney.filter((item3: any) => item3.completed);
               currentState.progressValueCount = (totalCompletedItems.length / saveMoney.length) * 100;
            } else {

                if (saveMoneyDer.length > 0) {
                    saveMoneyDer.map((item: any) => {
                        resultNextMove.map((item2: any) => {
                            if (item.name === item2.service) {
                                item.completed = true;
                            }
                        });
                    });
                }

                const totalCompletedItems = saveMoneyDer.filter((item3: any) => item3.completed);
                currentState.progressValueCount = (totalCompletedItems.length  / saveMoneyDer.length) * 100;
            }
        }
        
        if (
            nextProps &&
            nextProps.homeOwnerDashboardState &&
            nextProps.homeOwnerDashboardState.checklistByMoveId &&
            nextProps.homeOwnerDashboardState.checklistByMoveId.length &&
            // currentState.progressValueCount &&
            (currentState.isDataArrived || nextProps.homeOwnerDashboardState.isChecklistByMoveId)
        ) {
            currentState.count = 20;
            nextProps.homeOwnerDashboardState.isChecklistByMoveId = false;

            let completedFalse: any[] = [];

            let orderedChecklist = nextProps.homeOwnerDashboardState.checklistByMoveId;

            orderedChecklist = orderedChecklist
                .sort((a: any, b: any) => (a.ordering > b.ordering ? 1 : -1))
                .map((item: any) => {
                    return item;
                });
                
            let orderedChecklistNew: any = [];

            orderedChecklist.map((item2: any) => {
                if (
                    item2 &&
                    item2.do_it_now &&
                    item2.do_it_now.new_url &&
                    item2.do_it_now.new_url != '' &&
                    item2.do_it_now.new_url != null &&
                    // item2.do_it_now.new_url.includes('/dashboard') &&
                    !(item2.name.toUpperCase() == 'Junk Removal'.toUpperCase()) &&
                    !item2.do_it_now.new_url.includes('savings-flow')
                ) {
                    orderedChecklistNew.push(item2);
                }
            });
            
            orderedChecklistNew.map((item: any) => {

                if (item.is_completed == false && item.completed_percentage == 0) {
                    let obj3 = {
                        id: item.id,
                        className: 'dashboardCard cardMargin',
                        // image: item.new_image && item.new_image.image_svg_string,
                        image: item.image,
                        title: item.name,
                        description: item.description_short,
                        buttonText: item.do_it_now && item.do_it_now.heading,
                        percentage: item.completed_percentage,
                        progress: false,
                        url: item.do_it_now && item.do_it_now.new_url,
                        bullet_points: item.bullet_points,
                    };
                    completedFalse.push(obj3);
                }
                // if(true ||currentState.progressValueCount){
                    if (currentState.progressValueCount >= 100){
                        currentState.upcomingTaskData = completedFalse.slice(1, 3);
                        currentState.upcomingTaskDataPrimary = completedFalse.slice(0, 1);
                    }else{
                        currentState.upcomingTaskData = completedFalse.slice(0, 2);
                    }
                // }
            });
        }

        if (
            nextProps.commonState.header &&
            nextProps.commonState.header &&
            nextProps.commonState.header.length &&
            currentState.isHeaderDataArrived
        ) {
            currentState.isHeaderDataArrived = false;

            let homeUtilitiesService: any = [];
            let homeProtectionService: any = [];
            let homeDocumentryService: any = [];
            nextProps.commonState.header.map((item: any) => {
                if (item.service_type == 'HOME-UTILITIES') {
                    homeUtilitiesService.push({ ...item, completed: false, name: item.name });
                }
                if (item.service_type == 'HOME-PROTECTION') {
                    homeProtectionService.push({ ...item, completed: false, name: item.name });
                }
                if (item.service_type == 'HOME-INVENTORY') {
                    homeDocumentryService.push({ ...item, completed: false, name: item.name });
                }
            });
            currentState.homeUtilitiesService = homeUtilitiesService;
            currentState.homeProtectionService = homeProtectionService;
            currentState.homeDocumentryService = homeDocumentryService;
        }

        if (
            nextProps &&
            nextProps.homeOwnerDashboardState &&
            nextProps.homeOwnerDashboardState.getSubscriptionDetails &&
            nextProps.homeOwnerDashboardState.getSubscriptionDetails.results &&
            nextProps.homeOwnerDashboardState.isGetSubscriptionDetails
        ) {
            nextProps.homeOwnerDashboardState.isGetSubscriptionDetails = false;
            let homeUtilitiesServiceCompleted: any = [];
            let homeProtectionServiceCompleted: any = [];
            nextProps.homeOwnerDashboardState.getSubscriptionDetails.results.map((item: any) => {
                // if (item.service && item.service.service_type && item.service.service_type == 'HOME-UTILITIES') {
                homeUtilitiesServiceCompleted.push(item);
                // }
                // if (item.service && item.service.service_type && item.service.service_type == 'HOME-PROTECTION') {
                homeProtectionServiceCompleted.push(item);
                // }
                return true;
            });
            currentState.homeUtilitiesServiceCompleted = homeUtilitiesServiceCompleted;
            currentState.homeProtectionServiceCompleted = homeProtectionServiceCompleted;
        }
        
        if (nextProps && nextProps.commonState && nextProps.commonState.header) {
            let options: any = [];
            nextProps.commonState.header.map((item1: any) => {
                if(currentState.homeServiceData){
                    currentState.homeServiceData.map((item: any) => {
                        if (item1.react_url_route == item.url && options.length < 3) { //show max 3 items
                            options.push({ ...item });
                        }
                    })
                }
            });
            
            currentState.staticCheck = options;
        }
        return currentState;
    }

    onClickUtilityGetStarted = () => {
        // this.setState({ utilityGetStartedModal: true });
        this.props.history.push({
            pathname: '/dashboard/subscription_details/home_utilities',
            state: {
                homeUtilityServiceList: this.state.homeUtilitiesService,
                moveId: this.state.moveId,
                zip_code: this.state.toPin,
                getSubscriptionDetails: this.state.homeUtilitiesServiceCompleted,
            },
        });
    };
    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    onClickProtectionGetStarted = () => {
        // this.setState({ utilityGetStartedModal: true });
        this.props.history.push({
            pathname: '/dashboard/subscription_details/home_protection',
            state: {
                homeProtectionServiceList: this.state.homeProtectionService,
                moveId: this.state.moveId,
                getSubscriptionDetails: this.state.homeProtectionServiceCompleted,
            },
        });
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    skipHandler = () => {
        this.closeModal();
        this.setState({
            run: false,
        });

        scroller.scrollTo('dashboardHeader', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    startedHandler = () => {
        this.setState({ currentStep: 1, modalIsOpen: false, run: true });
        popUpSegment(
            'Popup Viewed',
            'Home Owner Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_id,
            'a' + this.state.agent_id,
            this.state.agent_name,
            'Your Upcoming Tasks',
            this.state.moveId,
        );
    };

    cardOneHandler = () => {
        this.setState({ currentStep: 2, stepIndex: 1 });
        popUpSegment(
            'Popup Viewed',
            'Home Owner Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_id,
            'a' + this.state.agent_id,
            this.state.agent_name,
            'Home Services On The Go',
            this.state.moveId,
        );
    };
    cardTwoHandler = () => {
        this.setState({ currentStep: 3, stepIndex: 2 });
        popUpSegment(
            'Popup Viewed',
            'Home Owner Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_id,
            'a' + this.state.agent_id,
            this.state.agent_name,
            'New Subscriptions',
            this.state.moveId,
        );
    };

    arrowHandler2 = () => {
        this.setState({ currentStep: this.state.currentStep - 1, stepIndex: this.state.stepIndex - 1 });
    };
    setSize = () => {
        this.setState({ size: window.screen.width });
    };
    getStarted = () => {
        let day1: any = new Date();
        let day2: any = new Date(this.state.date);
        var difference = Math.abs(day2 - day1);
        let days = difference / (1000 * 3600 * 24);

        if (days < 365) {
            this.props.history.push({
                state: {
                    showConfirmation: true,
                },
                pathname: '/dashboard/savings-flow',
            });
        } else {
            let payload = {
                moveId: this.state.moveId,
            };

            this.props.nextMoveAction.getNextMove(payload);
            this.props.history.push({
                state: {
                    showConfirmation: false,
                },
                pathname: '/dashboard/savings-flow',
            });
        }

        AnalyticsService.buttonClicked({
            category_name: `${this.state.progressValueCount > 0 ? 'Complete Your Savings' : 'Get Started'} Button Clicked`,
            button_copy: this.state.progressValueCount > 0 ? 'Complete Your Savings' : 'Get Started',
            card: true,
            card_title: 'Save Money In Just 5 Minutes',
            card_type: 'Mortgage Contact Card'
        });

    };

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
    };

    cancelModalOpened = () => {
        this.setState({
            cancelOpen: true,
        });
    };

    cancelModalClosed = () => {
        this.setState({
            cancelOpen: false,
        });
    };
    nylasModalClosed = () => {
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        // if(this.state.cancelClicked){
        //     let countNew=0
        //     setInterval(() => {
        //         if (countNew < 10) {
        //             this.props.commonAction.appointmentGet(payload)
        //                 countNew=countNew + 1
        //         }
        //     }, 5000);
        // }else{
        this.props.commonAction.appointmentGet(payload);
        this.setState({
            showLoader: true,
        });
        // }
    };
    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        scheduleSegment(
            'Button Clicked',
            'Home Owner Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_id,
            'a' + this.state.agent_id,
            this.state.agent_name,
            'Home Owner Dashboard',
            'Schedule A Call',
            true,
            `Schedule A Call with ${this.props.commonState &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].assigned_concierge &&
                this.props.commonState.move[0].assigned_concierge.full_name}and save $300`,
            true,
            'Upcoming tasks ',
            this.state.moveId,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_id: any = brokerageCheck.brokerage_link;
        let agent_id: any = brokerageCheck.id;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveId');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Dashboard Home Owner',
                brokerage_name: brokerage,
                brokerage_id: brokerage_id,
                agent_name: agent_name,
                agent_id: 'a' + agent_id,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id.toString() },
        );
    };
    cancelButtonClicked = () => {
        if (!this.state.reason) {
            this.setState({
                reasonError: 'Please provide a reason',
            });
        } else {
            this.props.commonAction.cancelAppointment({
                hash: this.state.editHash,
                reason: this.state.reason,
            });

            this.setState({
                cancelOpen: false,
            });

            let hash_code =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
            };
            this.setState({
                showLoader: true,
            });
            let countNew = 0;
            setInterval(() => {
                if (countNew < 6) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 10000);

            // setTimeout(()=>{
            //     this.props.commonAction.appointmentGet(payload)
            // },20000)
        }
    };

    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };
    closeFormHandler = async () => {
        this.setState({ isSubmitClicked: true });
        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            this.state.phone.length >= 12
        ) {
            //   this.setState({
            //      formOpen:false
            //   })
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
    };

    getPercentage = (loan = 1, equity = 1) => {
        const total = loan + equity;
        return {
            loanPercentage: Math.round((loan * 100) / total),
            equityPercentage: Math.round((equity * 100) / total),
        };
    };

    fetchLoanDetails = async (moveData: any) => {
        if (moveData?.hash_code) {
            this.setState({ isFetchingLoanData: true });
            ApiService.getLoanDetails(moveData)
                .then(data => this.setState({ loanDetails: data, isFetchingLoanData: false }))
                .catch(err => this.setState({ loanAPIError: err, isFetchingLoanData: false }));
        }
    };

    showToast = (title: ToastConfig['title'], type: ToastConfig['type'], message: ToastConfig['message']) =>
        defaultToast({ title, type, message });

    onAgentContact = () => {
        this.toggleMortageModal('agentContact');

        AnalyticsService.buttonClicked({
            category_name: 'Mortgage Card Contact Me Button Clicked',
            button_copy: 'Contact Me',
            card: true,
            card_title: 'Mortgage Contact',
            card_type: 'Mortgage Contact Card'
        });
    };

    handleButtonClicked = () => {
        const serviceAgentData = (this.state.serviceAgentData?.length && this.state.serviceAgentData[0]) || null;
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data[0]) || null;

        if (serviceAgentData) {
            if (serviceAgentData.email || serviceAgentData?.service_provider.email) {
                this.onAgentContact();
            } else {
                if (serviceAgentData.website || serviceAgentData?.service_provider.website) {
                    const website = serviceAgentData.website || serviceAgentData?.service_provider.website || '';
                    window.open(website, '_blank');
                }
            }
        } else {
            if (serviceProviders) {
                if (serviceProviders.email) {
                    this.onAgentContact();
                } else {
                    if (serviceProviders.website) {
                        window.open(serviceProviders.website, '_blank');
                    }
                }
            }
        }
    };

    onAgentContactSubmit = async (formData: any) => {
        const createReqBody = {
            contact_from: 'HOMEOWNER Dashboard',
            ...formData,
        };
        const data = this.state.serviceAgentData;
        const serviceProviderData = this.state.serviceProviders?.data;

        let id = null;
        let isServiceAgent = false;
        if (data?.length && data[0]?.id) {
            isServiceAgent = true;
            id = data[0]?.id;
        } else {
            isServiceAgent = false;
            if (serviceProviderData) {
                createReqBody.service_provider = this.state.prefferedMortgage.id;
            }
        }

        ApiService.postCreateContactRequest(createReqBody, id, isServiceAgent)
            .then(() => {
                this.showToast('Success!', 'success', 'Created Agent contact request.');
                this.setState({
                    isAgentSubmitContactSuccess: true,
                });
            })
            .catch(() => {
                this.showToast('Error!', 'danger', 'Something went wrong while creating request. Please try later');
                this.setState({
                    isAgentSubmitContactSuccess: true,
                });
            });

        AnalyticsService.formCompleted({
            category_name: 'Contact Request Submitted',
            form_name: `Inquire for ${this.state.prefferedMortgage?.name || 'Mortgage'}`,
            form_pre_filled: true,
            button_copy: 'Submit'
        });
    };

    onMortageSubmit = async (data: any) => {
        const { mortgageData } = this.state;
        if (mortgageData?.results && mortgageData?.results?.length && mortgageData?.results[0].id) {
            await ApiService.putLoanOrMortageDetails(
                { ...data, id: mortgageData?.results[0].id },
                mortgageData?.results[0].id,
            );
        } else {
            await ApiService.postLoanOrMortageDetails(data);
        }
        // this.props.homeValuationActions.mortageDetails(data);
        this.toggleMortageModal();
        const moveData = this.props?.commonState?.move?.length && this.props?.commonState?.move[0];
        this.fetchLoanDetails(moveData);
    };

    onPrefferedMortgage = (mortgage: any) => {
        this.setState({
            prefferedMortgage: {
                name: mortgage.name,
                id: mortgage.id,
                email: mortgage.email,
                phone: mortgage.phone,
                address: mortgage.address,
            },
        });
    };

    
    handleClickExplore = () => {
        AnalyticsService.buttonClicked({
            card: true,
            card_title: 'Your estimate home value',
            category_name: `Explore My Home Value Link Button Clicked`,
            button_copy: 'Explore My Home Value',
        });

        CommmonFunctions.navigateToRoute(
            '/dashboard/homeValuation#home-value-estimation',
        );
        window.scrollTo(0, 0);
    };

    handleClickBtn = async (item: any) => {
        if (_.isEmpty(item)) return;

        if (item.url.includes('moveeasy.com')) {

            const redirectUrl = item.url.split('moveeasy.com')[1] || '';

            const taskDataToStore = {
                id: item.id,
                url: redirectUrl
            }

            localStorage.setItem('activeChecklistTask', JSON.stringify(taskDataToStore));

            let stateToSend: any = {
                moveId: this.state.moveId,
                getSubscriptionDetails: this.state.homeProtectionServiceCompleted,
            }

            if (item.url.includes('/subscription_details/home_protection')) {
                stateToSend.homeProtectionServiceList = this.state.homeProtectionService;

                this.props.history.push({
                    pathname: redirectUrl,
                    state: stateToSend
                })
                
            } else if (item.url.includes('/subscription_details/home_utilities')) {
                stateToSend.homeUtilityServiceList = this.state.homeUtilitiesService;
                stateToSend.zip_code = this.state.toPin;
                stateToSend.getSubscriptionDetails = this.state.homeUtilitiesServiceCompleted;

                this.props.history.push({
                    pathname: redirectUrl,
                    state: stateToSend
                })
            } else {
                this.props.history.push(redirectUrl);
            }
        } else {
            await this.props.dashboardChecklistAction.updateServicePercentage({
                data: {
                    completed_percentage: 100,
                },
                service_id: item.id
            });

            window.open(item.url, '_blank');
        }
    }

    render() {
        const { homeValuationCardDetails } = this.props?.homeOwnerDashboardState;
        const { loanDetails, isFetchingLoanData, progressValueCount, upcomingTaskData, upcomingTaskDataPrimary } = this.state;
        const loanAmt = loanDetails?.remaining_equity_balance?.remaining_balance;
        const remainingEquity = loanDetails?.remaining_equity_balance?.equity_balance;
        const { loanPercentage, equityPercentage } = this.getPercentage(loanAmt, remainingEquity);
        const { finacingOptionsUrl } = this.state;
        const analyticsCommon = { page: "Home Owner Dashboard" };
        const serviceAgentData = (this.state.serviceAgentData?.length && this.state.serviceAgentData[0]) || null;
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data[0]) || null;
        const mortgageData = (this.state.mortgageData?.results?.length && this.state.mortgageData?.results[0]) || null;
        const priceRange =
        (this.state.propertyValuation?.low_value &&
            this.state.propertyValuation?.high_value &&
            `${CommonService.formatCurrency({
                value: this.state.propertyValuation?.low_value,
            })} to ${CommonService.formatCurrency({ value: this.state.propertyValuation?.high_value })}`) ||
        false;
        const buyer_seller_info =
            this.props?.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info?.deeds?.length &&
            this.props?.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info?.deeds[0];

        const estatedPriceRange =
            (this.state.propertyAverageValuation?.property_valuations && this.state.propertyAverageValuation?.property_valuations[1]?.low_value &&
                this.state.propertyAverageValuation?.property_valuations[1]?.high_value &&
                `${CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.low_value,
                })} to ${CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.high_value,
                })}`) ||
            false;
        const estatedcardPriceFirst =
            (this.state.propertyAverageValuation?.property_valuations && this.state.propertyAverageValuation?.property_valuations[1]?.current_value &&
                CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.current_value,
                })) ||
            false;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        window.onresize = this.setSize;
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        // let appointmentDetails = this.state.appointment_details;
        let moveData =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0];

        let utilityServiceCount: number = 0;
        let protectionServiceCount: number = 0;
        let homeUtilitiesServiceprogressValue: number = 0;
        let homeProtectionServiceprogressValue: number = 0;
        let homeUtilitiesService: any = this.state.homeUtilitiesService;
        let homeProtectionService: any = this.state.homeProtectionService;
        if (homeUtilitiesService.length) {
            homeUtilitiesService.map((item: any) => {
                this.state.homeUtilitiesServiceCompleted.map((item2: any) => {
                    if (item.id == item2.service_id) {
                        item.completed = true;
                    }
                });
            });
        }
        homeUtilitiesService.map((item: any) => {
            if (item.completed) {
                utilityServiceCount = utilityServiceCount + 1;
            }
            return utilityServiceCount;
        });
        if (homeProtectionService.length) {
            homeProtectionService.map((item: any) => {
                this.state.homeProtectionServiceCompleted.map((item2: any) => {
                    if (item.id == item2.service_id) {
                        item.completed = true;
                    }
                });
            });
        }
        homeProtectionService.map((item: any) => {
            if (item.completed) {
                protectionServiceCount = protectionServiceCount + 1;
            }
            return protectionServiceCount;
        });
        homeUtilitiesServiceprogressValue = parseInt(
            ((utilityServiceCount / homeUtilitiesService.length) * 100).toFixed(),
        );
        homeProtectionServiceprogressValue = parseInt(
            ((protectionServiceCount / homeProtectionService.length) * 100).toFixed(),
        );

        let steps: any[] = [
            {
                target: '.upcomingTaskFlexDiv',
                content:
                    window.screen.width > 768 ? (
                        <div className="step2-right-card">
                            <DashBoardstepRight
                                className2="main-div-stepHomeOwner"
                                // bottomarrow={window.screen.width == 1024 ? false : true}
                                bottomarrow={true}
                                icon={StepRight}
                                currentStep="1"
                                totalSteps="3"
                                title="Your Upcoming Tasks"
                                subTitle="The most important notifications including savings, renewals, or reminders"
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                buttonText2="Skip"
                                buttonColor2={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick2={this.skipHandler}
                                onClick={this.cardOneHandler}
                                // className2={window.screen.width == 1024 ? 'only-iPadPro' : ''}
                            />
                        </div>
                    ) : (
                        <div className="step2-right-card-mobile">
                            <DashBoardstepRight
                                // arrowMarginTop="0"
                                // toparrow
                                bottomarrow={true}
                                icon={StepRight}
                                currentStep="1"
                                totalSteps="3"
                                title="Your Upcoming Tasks"
                                subTitle="The most important notifications including where you can save or details related to service expiry and renewal."
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                buttonText2="Skip"
                                buttonColor2={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick2={this.skipHandler}
                                onClick={this.cardOneHandler}
                            />
                        </div>
                    ),
                placement: `top`,
                disableBeacon: true,
                offset: 0,
            },
            {
                target: '.homeserviceCardFlexDiv',
                content:
                    window.screen.width > 768 ? (
                        <div className="step2-right-card">
                            <DashBoardstepRight
                                className2="main-div-stepHomeOwner"
                                // leftarrow
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="2"
                                totalSteps="3"
                                title="Home Services On The Go"
                                subTitle={`Need repairs? Need to store your home documents? Check out these services`}
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                buttonText2="Skip"
                                buttonColor2={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick2={this.skipHandler}
                                onClick={this.cardTwoHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ) : (
                        <div>
                            <DashBoardstepRight
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="2"
                                totalSteps="3"
                                title="Home Services On The Go"
                                subTitle={`Need repairs or want something fixed? Need a document repository, here are your home related services`}
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                buttonText2="Skip"
                                buttonColor2={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick2={this.skipHandler}
                                onClick={this.cardTwoHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ),
                placement: `top`,
                disableBeacon: true,
                offset: 0,
            },
            {
                target: '.subscriptionCardFlexDiv',
                content:
                    window.screen.width > 768 ? (
                        <div className="step-3-right">
                            <DashBoardstepRight
                                className2="main-div-stepHomeOwner"
                                // leftarrow
                                bottomarrow={true}
                                arrowBack
                                icon={StepRight}
                                currentStep="3"
                                totalSteps="3"
                                title="New Subscriptions"
                                subTitle="See the summary of all your subscriptions in one place. Once updated, we can determine if you are eligible for savings"
                                buttonText="Done"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick={this.skipHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ) : (
                        <div className="only-mobile-step-3">
                            <DashBoardstepRight
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="3"
                                totalSteps="3"
                                title="New Subscriptions"
                                subTitle="See summary for all your subscriptions in one place. This will also help us indicate savings if you are eligible."
                                buttonText="Done"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick={this.skipHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ),
                placement: `top`,
                disableBeacon: true,
                offset: 0,
            },
        ];

        const homeDetailsData = {
            beds: homeValuationCardDetails?.beds_count || 0,
            baths: homeValuationCardDetails?.baths || 0,
            areaInSqft: homeValuationCardDetails?.total_area_sq_ft || 0,
        }

        const totalUpcomingtasks = progressValueCount < 100 ? (upcomingTaskData.length + 1): upcomingTaskDataPrimary.length + upcomingTaskData.length || 1;
        return (
            <div
                className="dashboard-main-page-home-owner"
                style={{
                    marginTop: window.location.pathname === '/' ? '0px' : '-7%',
                }}
            >
                <div className="dashboardHeader-home-owner">
                    <div className="dashboardHeaderTitleDiv-home-owner">
                        <div className="welcomeTextDiv1">
                            <div className="welomeText1">Good Morning,</div>
                            <div className="nameDiv1">
                                {this.state.fullName ? this.state.fullName : <Skeleton height="40px" width="250px" />}
                            </div>
                        </div>

                        {/* <div className="morningText">
                            YOUR NEW HOME ADDRESS <CreateIcon className="pencilIcon" />
                        </div>
                        <div className="addressDiv">
                            <div className="zipcode">{this.state.toPin}</div>
                            <div className="street">{this.state.toStreet}</div>
                        </div> */}
                        {!homeValuationCardDetails?.isFetchingHomeValuation ? (
                            <DashboardHomeOwnerTopCard
                                toAddress={homeValuationCardDetails?.address || ''}
                                property_valuations={homeValuationCardDetails?.property_valuations || {}}
                                homeDetails={homeDetailsData}
                                isFetchingHomeValuation={homeValuationCardDetails?.isFetchingHomeValuation || false}
                                averageValuations={this.state?.propertyAverageValuation}
                                isLoading={this.state?.isLoadingAverageValuation || this.state?.isLoadingPropertyValuation || false}
                                toggleMortageModal={this.toggleMortageModal}
                                loanDetails={loanDetails}
                                analyticsData={analyticsCommon}
                            />
                        ) : (
                            <Skeleton height={120} width={250} />
                        )}
                    </div>
                    <div className="dashboardImgDiv-home-owner">
                        {window.screen.width > 800 ? <img src={DashboardBackground} alt="" /> : <img src={DashboardMobile} alt="" />}
                    </div>
                </div>

                <div className="dashboardBottom">
                    {/* upcoming task */}
                    <div className="dashboardBlocks">
                        <div className="blocksTitleDiv">
                            <NewReleasesIcon className="blocksIconRed" />
                            <div className="blocksTitle">Upcoming tasks ({totalUpcomingtasks})</div>
                        </div>
                        <div className="upcomingTaskFlexDiv haveequalheight">
                            <div className="upcomingTaskLeftDiv">
                                {/* {console.log("upcomingTaskDataPrimary",this.state.upcomingTaskDataPrimary)} */}
                                {this.state.progressValueCount < 100 ? (
                                    <UpcomingTaskFirstCard
                                        image={SaveMoneyImage}
                                        title="Save Money In Just 5 Minutes"
                                        description="Answer few more questions and avail for eligible savings"
                                        buttonText={
                                            this.state.progressValueCount > 0 ? 'Complete Your Savings' : 'Get Started'
                                        }
                                        taskProgressValue={this.state.progressValueCount}
                                        getStarted={this.getStarted}
                                        // savings={this.state.savings}
                                        saveMoneyTasks={
                                            this.state.deregulated
                                                ? this.state.saveMoneyTasks
                                                : this.state.saveMoneyTasksDeregulate
                                        }
                                        loader={this.state.moveId == '' ? true : false}
                                    />
                                ) : this.state.upcomingTaskDataPrimary.length ? (
                                    this.state.upcomingTaskDataPrimary &&
                                    this.state.upcomingTaskDataPrimary.map((item: any, i: number) => {
                                    
                                        return (
                                            <div className="upcomingTaskRightFlexDiv" key={i}>
                                                <UpcomingTaskPrimaryCard
                                                history = {this.props.history}
                                                    image={item.image}
                                                    title={item.title}
                                                    description={item.description}
                                                    buttonText={item.buttonText}
                                                    taskProgressValue={item.completed_percentage}
                                                    bullet_points = {item.bullet_points}
                                                    getStarted={() => this.handleClickBtn(item)}
                                                    loader={this.state.moveId == '' ? true : false}
                                                />
                                            </div>
                                        );
                                    })
                                ) : this.state.custom_schedule_call_info_enabled && !this.state.upcomingTaskDataPrimary ? (
                                    <CallWithMikeCard
                                        icon={
                                            moveData &&
                                            moveData.assigned_concierge &&
                                            moveData.assigned_concierge.headshot_logo
                                        }
                                        title={`Schedule A Call with ${moveData &&
                                            moveData.assigned_concierge &&
                                            moveData.assigned_concierge.full_name} and save $300`}
                                        saveMoneyTasks={
                                            this.state.deregulated
                                                ? this.state.saveMoneyTasks
                                                : this.state.saveMoneyTasksDeregulate
                                        }
                                        subTitle={'You are eligible for savings in services below'}
                                        // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                        buttonText="Contact Us"
                                        buttonColor={'#D92F25'}
                                        buttonBackGroundColor="#fff"
                                        buttonShadow="0 1px 1px #161D251A"
                                        buttonBorder={'1px solid #D92F25'}
                                        onClick={() => this.formHandler()}
                                    />
                                ) : (
                                    <CallWithMikeCard
                                        showLoader={this.state.showLoader}
                                        appointmentTime={`${new Date(this.state.appointmentTime)
                                            .toString()
                                            .substring(0, 15)},${new Date(
                                            this.state.appointmentTime,
                                        ).toLocaleTimeString('en-US')} `}
                                        icon={
                                            moveData &&
                                            moveData.assigned_concierge &&
                                            moveData.assigned_concierge.headshot_logo
                                        }
                                        title={`Schedule A Call with ${moveData &&
                                            moveData.assigned_concierge &&
                                            moveData.assigned_concierge.full_name} and save $300`}
                                        saveMoneyTasks={
                                            this.state.deregulated
                                                ? this.state.saveMoneyTasks
                                                : this.state.saveMoneyTasksDeregulate
                                        }
                                        subTitle={'You are eligible for savings in services below'}
                                        // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                        buttonText="Schedule A Call"
                                        buttonColor="#fff"
                                        buttonBackGroundColor="#273E59"
                                        buttonShadow="0 1px 1px #161D251A"
                                        buttonBorder="1px solid #273E59"
                                        rescheduleText={this.state.reschedule_link ? 'Reschedule' : ''}
                                        rescheduleBackGroundColor="#273E59"
                                        rescheduleColor="#fff"
                                        rescheduleShadow="0 1px 1px #161D251A"
                                        rescheduleBorder="1px solid #273E59"
                                        cancelText={this.state.editHash ? 'Cancel' : ''}
                                        cancelColor="#273E59"
                                        cancelBackGroundColor="#fff"
                                        cancelShadow="0 1px 1px #161D251A"
                                        cancelBorder="1px solid #273E59"
                                        onClick={() => this.calendlyHandle()}
                                        onClickReschedule={() => {
                                            this.calendlyHandleReschedule();
                                        }}
                                        onClickCancel={() => {
                                            this.cancelModalOpened();
                                        }}
                                    />
                                )}
                            </div>
                            <div className="upcomingTaskRightDiv">
                                {/* {console.log(this.state.upcomingTaskData)} */}
                                {this.state.upcomingTaskData.length ? (
                                    this.state.upcomingTaskData &&
                                    this.state.upcomingTaskData.map((item: any, i: number) => {
                                        // let icon =
                                        //     item && item.url && item.url.includes('/dashboard/internet')
                                        //         ? Providers
                                        //         : item && item.url && item.url.includes('/dashboard/utilities')
                                        //         ? Utilities
                                        //         : item && item.url && item.url.includes('/dashboard/autoease')
                                        //         ? MovingCar
                                        //         : item && item.url && item.url.includes('/dashboard/address')
                                        //         ? ChangeAddress
                                        //         : item && item.url && item.url.includes('/dashboard/ourvendors')
                                        //         ? HomeRepair
                                        //         : item && item.url && item.url.includes('/dashboard/storage')
                                        //         ? Storage
                                        //         : item && item.url && item.url.includes('/dashboard/insurance')
                                        //         ? MaskGroup
                                        //         : item && item.url && item.url.includes('/dashboard/movers')
                                        //         ? Moving
                                        //         : item.image;
                                        
                                        return (
                                            <div className="upcomingTaskRightFlexDiv" key={i}>
                                                <UpcomingTaskSecondCard
                                                    image={item.image}
                                                    title={item.title}
                                                    description={item.description}
                                                    buttonText={item.buttonText}
                                                    // url={
                                                    //     item.do_it_now &&
                                                    //     item.do_it_now.new_url &&
                                                    //     item.do_it_now.new_url.split('moveeasy.com') &&
                                                    //     item.do_it_now.new_url.split('moveeasy.com')[1]
                                                    //         ? item.do_it_now.new_url.split('moveeasy.com')[1]
                                                    //         : ''
                                                    // }
                                                    showButton={
                                                        item.url.includes('/subscription_details/home_protection') &&
                                                        !this.state.homeProtectionService.length
                                                            ? false
                                                            : item.url.includes(
                                                                  '/subscription_details/home_utilities',
                                                              ) && !this.state.homeUtilitiesService.length
                                                            ? false
                                                            : true
                                                    }
                                                    onButtonClick={() => this.handleClickBtn(item)}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="getStartedSkeleton">
                                        <GetStartedMovingServiceCard className="dashboardCardSkeleton" />
                                        <GetStartedMovingServiceCard className="dashboardCardSkeleton" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <HomeValModal
                        isShowModal={this.state.showMortageModal}
                        onCloseModal={this.handleCloseModal}
                        showClose={this.state.showMortageModal}
                        close={this.handleCloseModal}
                        cross={true}
                        type={this.state?.modalType}
                        prefferedMortgage={this.state.prefferedMortgage}
                    >
                        {this.state?.modalType === 'agentContact' ? (
                            <ContactDataModal
                                onSubmit={this.onAgentContactSubmit}
                                toggleModal={this.handleCloseModal}
                                details={serviceAgentData}
                                userDetails={this.state?.userDetails}
                                isLoading={isFetchingLoanData}
                                move_key={this.state?.hashcode || this.state?.move_key || ''}
                                type={'agentContact'}
                                onPrefferedMortgage={this.onPrefferedMortgage}
                                providersData={this.state.serviceProviders?.data}
                                prefferedMortgage={this.state.prefferedMortgage}
                                isSubmitSuccess={this.state.isAgentSubmitContactSuccess}
                                analyticsData={analyticsCommon}
                            />
                        ) : (
                            <DataModel
                                onMortageSubmit={this.onMortageSubmit}
                                toggleMortageModal={this.toggleMortageModal}
                                loanDetails={loanDetails}
                                isFetchingLoanData={isFetchingLoanData}
                                analyticsData={analyticsCommon}
                                loanData={buyer_seller_info}
                                showPurchasePriceField={this.state?.propertyAverageValuation?.average_value ? false : !loanAmt && !remainingEquity}
                            />
                        )}
                    </HomeValModal>
                </div>
                {/* home services */}
                {(this.state.staticCheck?.length > 0 && this.state.homeServiceData?.length > 0) && <div className="dashboardBottom">
                    <div className="dashboardBlocks">
                        <div className="blocksTitleDiv">
                            <img src={HomeServiceIcon} className="blocksIconRed" alt="" />
                            <div className="blocksTitle">Your Home Services</div>
                        </div>
                        <div className="homeserviceCardFlexDiv1">
                            {this.state.staticCheck.map((item: any, i: number) => {
                                return (
                                    <div className="homeServiceCardDiv" key={i}>
                                        <HomeServiceCard
                                            image={item.image}
                                            title={item.title}
                                            description={item.description}
                                            buttonText={item.buttonText}
                                            onButtonClick={() => this.props.history.push(`${item.url}`)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>}
                {/* current subscription */}
                <div className="dashboardBottom">
                    <div className="dashboardBlocks">
                        <div className="blocksTitleDiv">
                            <img src={SubscriptionIcon} className="blocksIconRed" alt="" />
                            <div className="blocksTitle">{`Your current subscription details`}</div>
                        </div>
                        <div className="homeserviceCardFlexDiv subscriptionCardFlexDiv">
                            <div className="homeServiceCardDiv">
                                <div className="homeServiceCardTopDiv">
                                    <SubscriptionTopCard
                                        image={utilitiesIcon}
                                        title="Home Utilities"
                                        progressValue={homeUtilitiesServiceprogressValue}
                                    />
                                </div>
                                <div className="homeServiceCardBottomDiv">
                                    <SubscriptionGetStartedCard
                                        heading="Provide Your Utilities Details"
                                        subHeading="Fill up the form to track your subscriptions"
                                        progressValue={homeUtilitiesServiceprogressValue}
                                        // history={this.props.history}
                                        services={homeUtilitiesService}
                                        completedService={this.state.homeUtilitiesServiceCompleted}
                                        onClickService={this.onClickUtilityGetStarted}
                                    />
                                    {homeUtilitiesServiceprogressValue == 0 ? (
                                        homeUtilitiesService.length ? (
                                            <div className="buttonDivhomeOwnerDashboard">
                                                <Button
                                                    className="buttonhomeOwnerDashboard"
                                                    onClick={this.onClickUtilityGetStarted}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        ) : (
                                            <Skeleton width={'100px'} height="30px" />
                                        )
                                    ) : null}
                                </div>
                            </div>
                            <div className="homeServiceCardDiv">
                                <div className="homeServiceCardTopDiv">
                                    <SubscriptionTopCard
                                        image={protectionIcon}
                                        title="Home Protection"
                                        progressValue={homeProtectionServiceprogressValue}
                                    />
                                </div>
                                <div className="homeServiceCardBottomDiv">
                                    <SubscriptionGetStartedCard
                                        heading="Provide Your Protection Details"
                                        subHeading="Fill up the form to track your subscriptions"
                                        progressValue={homeProtectionServiceprogressValue}
                                        services={homeProtectionService}
                                        // history={this.props.history}
                                        completedService={this.state.homeUtilitiesServiceCompleted}
                                        onClickService={this.onClickProtectionGetStarted}
                                    />
                                    {homeProtectionServiceprogressValue == 0 ? (
                                        homeUtilitiesService.length ? (
                                            <div className="buttonDivhomeOwnerDashboard">
                                                <Button
                                                    className="buttonhomeOwnerDashboard"
                                                    onClick={this.onClickProtectionGetStarted}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        ) : (
                                            <Skeleton width={'100px'} height="30px" />
                                        )
                                    ) : null}
                                </div>
                            </div>
                            <div className="homeServiceCardDiv">
                                <SubscriptionTopCard image={inventoryIcon} title="Home Inventory" progressValue={0} />
                                <div className="homeServiceCardBottomDiv">
                                    <SubscriptionGetStartedCard
                                        heading="Provide Your Protection Details"
                                        subHeading="Fill up the form to track your subscriptions"
                                        services={
                                            this.state.homeDocumentryService.length
                                                ? this.state.homeDocumentryService
                                                : [{ completed: true, name: 'Documentry' }]
                                        }
                                    />
                                    <div className="buttonDivhomeOwnerDashboard">
                                        <Button
                                            className="buttonhomeOwnerDashboard"
                                            onClick={() => {
                                                this.props.history.push('/dashboard/my-documents');
                                            }}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboardBottom1">
                    <div className="dashboardBlocks">
                        <div className="blocksTitleDiv">
                            <img src={HomeServiceIcon} className="blocksIconGrey" alt="" />
                            <div className="blocksTitle">{`Your home valuation`}</div>
                        </div>
                        <div className="homeValuationMain">
                            <div className="row specCardCoverRow">
                                <div className="refinanceClass">
                                    <Refinance
                                        // image={yourMortgage}
                                        serviceAgentData={serviceAgentData}
                                        serviceProviders={serviceProviders}
                                        mortgageData={mortgageData}
                                        isLoading={this.state?.isFetchingCardData}
                                        onContact={this.handleButtonClicked}
                                        analyticsData={analyticsCommon}
                                    ></Refinance>
                                </div>

                                <div className=" homeImprovementClass">
                                    <HomeImprovement finacingOptionsUrl={finacingOptionsUrl} analyticsData={analyticsCommon}  />

                                    <div className="homeImprovementClassImage">
                                        <img src={homeImprovement} alt="" />
                                    </div>
                                </div>

                                {/* <div className="col-12 col-sm-12 col-xl-6 "> */}
                                <div className="homeCardEstimate">
                                    <div className="homeCardUpperDiv">
                                        {loanAmt && remainingEquity && !isFetchingLoanData ? (
                                            <div>
                                                {!isFetchingLoanData ? (
                                                    <div className="homeCardUpperDiv1">
                                                        Your estimate home value{' '}
                                                        <span
                                                            style={{
                                                                fontWeight: 'bold',
                                                                color: '#333',
                                                                fontSize: '20px',
                                                            }}
                                                        >
                                                            ${' '}
                                                            {this.state?.propertyAverageValuation?.average_value &&
                                                                CommmonFunctions.formatCurrency({
                                                                    value:
                                                                        this.state?.propertyAverageValuation
                                                                            ?.average_value || 0,
                                                                })}
                                                        </span>{' '}
                                                    </div>
                                                ) : (
                                                    <Skeleton width={180} height={40} />
                                                )}
                                                {loanPercentage && equityPercentage && !isFetchingLoanData ? (
                                                    <Amount
                                                        width={`${loanPercentage}%`}
                                                        width1={`${equityPercentage}%`}
                                                        loanAmt={loanAmt}
                                                        equityAmt={remainingEquity}
                                                    />
                                                ) : (!loanAmt || !remainingEquity) && !isFetchingLoanData ? (
                                                    <AddLoanDetails handleClickAdd={this.toggleMortageModal} />
                                                ) : (
                                                    <Skeleton width={250} height={40} />
                                                )}
                                                <div>
                                                    <div className="row">
                                                        <div className="col-6 col-sm-3 homeCardUpperDiv2">
                                                            <div className="homeCardUpperInnerDivHeadingText">
                                                                Loan Term
                                                            </div>
                                                            <div className="homeCardUpperInnerDivSubHeadingText">
                                                                {loanDetails?.loan_year || ' '}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-2 homeCardUpperDiv2">
                                                            <div className="homeCardUpperInnerDivHeadingText">APR</div>
                                                            <div className="homeCardUpperInnerDivSubHeadingText">
                                                                {loanDetails?.loan_percentage || 0}%
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 mt-sm-0 col-12 col-sm-7 homeCardUpperDiv2">
                                                            <div className="homeCardUpperInnerDivHeadingText">
                                                                Off Market
                                                            </div>
                                                            <div className="homeCardUpperInnerDivSubHeadingText">
                                                                <span
                                                                    style={{ color: '#6B6C6F', fontWeight: 'normal' }}
                                                                >
                                                                    Last sold for
                                                                </span>{' '}
                                                                {buyer_seller_info?.recording_date &&
                                                                buyer_seller_info?.sale_price
                                                                    ? `${CommmonFunctions.formatCurrency({
                                                                          value: buyer_seller_info?.sale_price,
                                                                      })} on ${moment(
                                                                          buyer_seller_info?.recording_date,
                                                                          'YYYY-MM-DD',
                                                                      ).format('DD MMMM YYYY')}`
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="learnMore">
                                                        <a
                                                            className="text"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={this.handleClickExplore}
                                                        >
                                                            Explore My Home Value
                                                            <span style={{ marginLeft: '5px' }}>
                                                                <img src={BlueArrow} alt="" />
                                                            </span>{' '}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <AddLoanDetails handleClickAdd={this.toggleMortageModal} />
                                        )}
                                    </div>
                                    <div className="homeCardMain">
                                        <div className="homeCardMainBlock">
                                            <HomeCard
                                                styleHomeCard="homeCardStyle"
                                                border="0.5px solid #E0E0E0"
                                                cardPriceFirst={
                                                    (this.state.propertyValuation?.current_value &&
                                                        CommonService.formatCurrency({
                                                            value: this.state.propertyValuation?.current_value,
                                                        })) ||
                                                    false
                                                }
                                                cardPrice={priceRange}
                                                isLoading={
                                                    this.state?.isLoadingPropertyValuation ||
                                                    this.state?.isLoadingAverageValuation
                                                }
                                                cardContent="Estimation from"
                                                cardImage={Zillow}
                                                margin="20px auto"
                                            />
                                        </div>
                                        <div className="homeCardMainBlock">
                                            <HomeCard
                                                styleHomeCard="homeCardStyle"
                                                border="0.5px solid #E0E0E0"
                                                cardPriceFirst={estatedcardPriceFirst}
                                                cardPrice={estatedPriceRange}
                                                isLoading={
                                                    this.state?.isLoadingPropertyValuation ||
                                                    this.state?.isLoadingAverageValuation
                                                }
                                                cardContent="Estimation from"
                                                cardImage={eastedLogo}
                                                marginTop="12px"
                                                margin="20px auto"
                                            />
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboardBottom">
                    {/* blogs */}
                    <div className="dashboardBlocks">
                        <div className="blocksTitleDiv">
                            <img src={BlogIcon} className="blocksIconGrey" alt="" />
                            <div className="blocksTitle">{`Blogs`}</div>
                        </div>
                        {slug && slug.slug === 'sibcycline' ? (
                            <div className="BlogsCardDiv">
                                {this.state.blogs?.results?.map((blog: any, i: number) => (
                                    <div className="blogCard-div1SibcyCline" key={i}>
                                        <BlogCardTopImage
                                            icon={blog?.image || SibcyCLineBlog}
                                            title={blog?.name || ''}
                                            subTitle={blog?.description.slice(0, 150) + '...' || ''}
                                            viewLink={blog?.links || ''}
                                            analyticsData={analyticsCommon}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={`BlogsCardDiv ${this.state.blogs?.results?.length < 4 && 'blogsDashCover'}`}>
                                {this.state.blogs?.results?.map((blog: any, i: number) => (
                                    <div className="blogCard-div2" key={i}>
                                        <BlogCardTopImage
                                            height={'100%'}
                                            icon={blog?.image || SibcyCLineBlog}
                                            title={blog?.name || ''}
                                            subTitle={blog?.description.slice(0, 150) + '...' || ''}
                                            viewLink={blog?.links || ''}
                                            imageLink={blog?.image || ''}
                                            analyticsData={analyticsCommon}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="Disclammer">
                    Zillow Data Is Provided “As Is” Via The Zestimate Api. © Zillow, Inc., 2006-2020. Use Is Subject To
                    Terms Of Use
                </div>
                <div className="dashbaord-modal">
                    {this.state.currentStep >= 1 ? (
                        <Joyride
                            floaterProps={{
                                disableAnimation: true,
                                disableFlip: true,
                            }}
                            scrollOffset={window.screen.width > 425 ? 200 : 100}
                            run={this.state.run}
                            steps={steps}
                            hideBackButton={true}
                            stepIndex={this.state.stepIndex}
                            spotlightClicks={false}
                            spotlightPadding={0}
                            disableOverlayClose={true}
                            styles={{
                                options: {
                                    width: '100%',
                                    beaconSize: 0,
                                    arrowColor: 'transparent',
                                    spotlightShadow: 'none',
                                    backgroundColor: 'transparent',
                                    overlayColor: '#14141480',
                                    primaryColor: '#000',
                                    textColor: '#333',
                                    zIndex: 20000,
                                },
                                tooltip: {
                                    animation: 'none',
                                },

                                buttonClose: {
                                    display: 'none',
                                },
                                buttonNext: {
                                    display: 'none',
                                },
                            }}
                        />
                    ) : null}
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        style={
                            this.state.currentStep == 0 || window.screen.width < 1366 ? customStyles : customStylesNew
                        }
                        contentLabel="Dashboard Modal"
                    >
                        {this.state.currentStep == 0 ? (
                            <DashBoardWelcome
                                icon={DashBoardWelcomeImage}
                                title={`Hi ${this.state.firstName},  Let's take a tour`}
                                subTitle="You’ve moved into a new home. Let’s show you some of our homeowner features."
                                buttonText="Get Started"
                                buttonColor="#fff"
                                buttonBackGroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonShadow={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                buttonText2="Skip"
                                buttonColor2={slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'}
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={`0 0 3px 0  ${
                                    slug && slug.primary_color ? slug.primary_color : '#273E59'
                                }`}
                                onClick2={this.skipHandler}
                                onClick={this.startedHandler}
                            />
                        ) : null}
                    </Modal>
                </div>
                {this.state.formOpen && (
                    <ModalCheck
                        isShowModal={this.state.formOpen}
                        onCloseModal={() => {
                            this.setState({ formOpen: false });
                        }}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="line12"></div>
                            <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                              !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                            ? 'Please enter valid email'
                                            : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                            ? 'Please enter valid phone number'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={`0 0 3px 0 ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    width="130px"
                                    height="50px"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    onClick={() => this.setState({ formOpen: false })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeFormHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </ModalCheck>
                )}
                {this.state.calendlyOpen && (
                    <ModalCheck
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                        // style={{
                        //     position: 'absolute',
                        //     width: '700px',
                        //     // height: '650px',
                        //     backgroundColor: '#fff',
                        //     left: '25%',
                        //     top: '5%',
                        // }}
                    >
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}

                        {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                        (this.state.rescheduleClicked && this.state.reschedule_link) ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.scheduleClicked
                                            ? this.state.nylas_full_service_scheduler_url
                                            : this.state.rescheduleClicked
                                            ? this.state.reschedule_link
                                            : null
                                    }&utm_source=platform&utm_medium=web&utm_content=dashboard&utm_campaign=moving-platform
                                   `}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </ModalCheck>
                )}
                {
                    <ModalCheck
                        isShowModal={this.state.cancelOpen}
                        onCloseModal={this.cancelModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                    >
                        <div className="cancellation-modal">
                            <div className="logo-heading-div">
                                <div className="moveaesy-logo">
                                    <img src={moveEasy} alt="" />
                                </div>
                                <div className="cancellation-heading">Appointment With Moving Concierge</div>
                            </div>
                            <div className="modal-body">
                                <div className="left-detail">
                                    <div className="appointment-details-heading">Appoinment Details</div>
                                    <div className="appointment-details">
                                        Call is Scheduled On
                                        <div className="details">
                                            {`${new Date(this.state.appointmentTime)
                                                .toString()
                                                .substring(0, 15)},${new Date(
                                                this.state.appointmentTime,
                                            ).toLocaleTimeString('en-US')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="right-form">
                                    <div className="reason-cancellation">Reason For Cancelling</div>
                                    <div className="input-reason">
                                        <TextField
                                            id="standard-basic"
                                            label="Enter Reason"
                                            variant="standard"
                                            onChange={(e: any) => {
                                                this.setState({
                                                    reason: e.target.value,
                                                });
                                            }}
                                        />
                                        {!this.state.reason ? (
                                            <div style={{ fontSize: '14px', color: 'red' }}>
                                                {this.state.reasonError}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="cancel-event-button">
                                        <Button onClick={this.cancelButtonClicked} width="150px">
                                            Cancel Event
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalCheck>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        nextMoveAction: bindActionCreators(Action, dispatch),
        dashboardChecklistAction: bindActionCreators(DashboardChecklistAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeOwnerDashboardState: state.homeOwnerdashboard,
    nextMoveState: state.nextMove,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOwnerDashboard);
