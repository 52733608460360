import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';

export default function(props: any) {
    const [svg, setSvg] = useState('');
    // const [_isLoaded, setIsLoaded] = useState(false);
    // const [_isErrored, setIsErrored] = useState(false);

    useEffect(() => {
        fetch(props.url)
            .then(res => res.text())
            .then((_svg: string) => {
                setSvg(_svg);
            })
        .catch((e) => {
            console.log("Error", e)
        })
        // .then(() => setIsLoaded(true));
    }, [props.url]);

    return (
        <span
            style={{ fill: props.color ? props.color : '#707070' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
        />
    );
}
