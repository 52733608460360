import React, { Component } from 'react';
import './insurance.scss';
import Image from '../../Assets/images/InsuranceImages/mainInsurance';
import Button from '../../components/atoms/Button';
// import Steps from './steps';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import Footer from './Footer';
import CustomPage from '../CustomPage/CustomPage';
// import { CircularProgress } from '@material-ui/core';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    insuranceAction?: any;
    insuranceState?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    isDataArrivedHeader: boolean;
    contentText: any;
    isDefault: boolean;
    hashCode: any;
    isHashcodeDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    slug: any;
    moveId: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    category_name: any;
    homeOwnerDashboardRedirection: boolean;
};

class HomeInsurance extends Component<initialProps, initialState> {
    state: initialState = {
        isDataArrivedHeader: true,
        isDefault: true,
        contentText: '',
        hashCode: '',
        isHashcodeDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        slug: null,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        category_name: '',
        homeOwnerDashboardRedirection: false,
    };
    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        this.props.dashboardAction.getSubscriptionDetails();
        if (
            !(
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.type === 'back'
            )
        ) {
            this.props.insuranceAction.insuranceAppointmentGet();
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Home Insurance',
                    category_name: 'Home Insurance',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.insuranceState;
        const cur = this.props && this.props.insuranceState;
        if(this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0){
            this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((element: any) => {
                if(element.service.name === 'Home Insurance'){
                    this.setState({homeOwnerDashboardRedirection: true})
                }
            })
        }
        if (
            prev.appointment !== cur.appointment &&
            cur.appointment &&
            (cur.appointment.appointment_time || cur.appointment.id)
        ) {
            this.props.history.push('/dashboard/home-insurance/summary');
        }
        if(this.state.homeOwnerDashboardRedirection){
            this.props.history.push('/dashboard/home-insurance/summary');
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashcodeDataArrived
        ) {
            currentState.isHashcodeDataArrived = false;
            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.commonAction.moveGet();
                if (
                    nextProps.commonState &&
                    nextProps.commonState.header &&
                    nextProps.commonState.header.length === 0
                ) {
                    localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                    const isHomeOwner = localStorage.getItem('isHomeOwner');
                    let slug: any = localStorage.getItem('slug');
                    slug = JSON.parse(slug);

                    nextProps.commonAction.header({
                        partner_slug: window.location.hostname.split('.')[0]
                            ? window.location.hostname.split('.')[0]
                            : slug && slug.slug,
                        fetchMoveService: isHomeOwner ? 1 : 0,
                    });
                }
                if (
                    !(
                        nextProps.history &&
                        nextProps.history.location &&
                        nextProps.history.location.state &&
                        nextProps.history.location.state.type === 'back'
                    )
                ) {
                    nextProps.insuranceAction.insuranceAppointmentGet();
                }
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.header &&
            nextProps.commonState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let insurance: any = [];
            insurance = nextProps.commonState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'insurance',
            );

            if (insurance.length) {
                currentState.isDefault = insurance[0].is_default;
                currentState.contentText = insurance.content_text;
            }
        }
        return currentState;
    }
    buttonClicked = () => {
        this.props.history.push({
            pathname: '/dashboard/home-insurance/steps',
            state: {
                data: this.props.commonState && this.props.commonState.move && this.props.commonState.move[0],
            },
        });
    };
    handleAnalytics = () => {
        this.props.history.push({
            pathname: '/dashboard/home-insurance/steps',
            state: {
                data: this.props.commonState && this.props.commonState.move && this.props.commonState.move[0],
            },
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Button Clicked',
            {
                page: 'Home Insurance',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'GET STARTED',
                card: false,
                card_title: '',
                card_type: 'Start Page',
                category_name: 'Home Insurance',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { isDouglasElliman } = this.state;
        // if (
        //     this.props.history &&
        //     this.props.history.location &&
        //     this.props.history.location.state !== undefined &&
        //     !(this.props.insuranceState.appointment && this.props.insuranceState.appointment.appointment_time)
        // ) {
        //     return (
        //         <div
        //             style={{
        //                 marginTop: '20px',
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center',
        //             }}
        //         >
        //             <CircularProgress />
        //         </div>
        //     );
        // } else {

        // console.log("isDefault",this.state.isDefault)

        const breadList = [
            {
                name: 'Insurance',
                url: ''
            }
        ]

        return this.state.isDefault == true ? (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="insurance"
                            onClick={this.buttonClicked}
                            isCoverImage={true}
                            headerText={`Hey ${
                                this.props.commonState &&
                                this.props.commonState.move &&
                                this.props.commonState.move[0] &&
                                this.props.commonState.move[0].user_ref &&
                                (this.props.commonState.move[0].user_ref.first_name ||
                                    this.props.commonState.move[0].user_ref.last_name)
                                    ? this.props.commonState.move[0].user_ref.first_name ||
                                      this.props.commonState.move[0].user_ref.last_name
                                    : ''
                            } Get Insurance Quotes `}
                            subheaderText={'Get multiple quotes in minutes!'}
                            highlightedText={'In Minutes'}
                            buttonText={'Get Started'}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="main-head-insurance">
                            <h2 className="heading-insurance-regular">Insurance</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className="InsurancecoverDiv">
                            <div className="mainPageInsurance">
                                <Image className="InsuranceImageMain" />
                                <h2 className="heading">
                                    Hey 
                                    <span style={{ textTransform: 'capitalize' }}>{`${
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        (this.props.commonState.move[0].user_ref.first_name ||
                                            this.props.commonState.move[0].user_ref.last_name)
                                            ? this.props.commonState.move[0].user_ref.first_name ||
                                              this.props.commonState.move[0].user_ref.last_name
                                            : ''
                                    },`}</span>{' '}
                                    let us help you get home insurance quotes in minutes
                                </h2>
                                <p>Compare personalized insurance quotes from multiple provides all in one place</p>
                                <div className="lineInsurance"></div>
                                <div className="btn-div">
                                    <Button
                                        // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                        // color={slug && slug.primary_font_color ? slug.primary_font_color : '#fff'}
                                        fontSize="16px"
                                        width="100%"
                                        height="50px"
                                        onClick={this.handleAnalytics}
                                        disabled={
                                            !(
                                                this.props.commonState &&
                                                this.props.commonState.move &&
                                                this.props.commonState.move[0]
                                            )
                                        }
                                    >
                                        Get Started
                                    </Button>
                                </div>
                                <div style={{ fontSize: '12px', color: '#6b6c6f', margin: '0 0px', marginTop: '20px' }}>
                                    Insurance Services are provided by licensed insurance agents and/or insurance
                                    companies that partner with Move Easy to advertise on its website. MoveEasy may
                                    receive compensation for insurance advertising. This service is completely optional
                                    and provided as a convenience to you for comparison shopping any time. Neither
                                    MoveEasy or your real estate agent or broker endorse any particular carrier or
                                    policy advertised. Not all insurance options that may be available to you are listed
                                    on this site.
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer show={isDouglasElliman ? true : false} />
            </div>
        ) : (
            <CustomPage history={this.props.history} />
        );
        // }
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        commonAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    insuranceState: state.insurance,
    commonState: state.common,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeInsurance);
