import React from 'react';
import { connect } from 'react-redux';
import * as Action from '../../store/login/signup/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import './welcomeVideo.scss';
import playicon  from '../../Assets/images/welcome/play-btn.svg'
import Button from '../../components/atoms/Button';
import ApiService from '../api-service';
import _ from 'lodash';
import AnalyticsService from '../analytics-service';

type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {    
    homeOwnerVideoURL: string;
    fullName: string;
    fullNameUser: string;
    buttonShow: boolean;
    homeinspectionTypeProviders: any;
    serviceProviders: any;
    serviceProvidersHomeInspection: any;
    hashcode: string;
    videoSeen: boolean;
};
class welcomeVideo extends React.Component<initialProps, initialState> {
    state: initialState = {
        homeOwnerVideoURL: '',
        fullName: '',
        fullNameUser: '',
        buttonShow: true,
        homeinspectionTypeProviders: null,
        serviceProviders: '',
        serviceProvidersHomeInspection: '',
        hashcode: '',
        videoSeen: false
    };
    myRef: any = React.createRef();

    componentDidMount() {
        AnalyticsService.pageViewed({
            page: 'Onboarding Video Page',
            category_name: 'Onboarding Video Page Viewed'
        })
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState &&
            nextProps.commonState.realtor
        ) {
            currentState.homeOwnerVideoURL =
                nextProps.commonState &&
                nextProps.commonState.realtor.moving_owner_video_url
            currentState.fullName =
            nextProps.commonState.move[0] &&
                nextProps.commonState.realtor.display_name
            currentState.fullNameUser =
            nextProps.commonState.move[0]?.user_ref &&
            `${nextProps.commonState.move[0]?.user_ref.first_name} ${nextProps.commonState.move[0]?.user_ref.last_name}`;
        }
        currentState.homeinspectionTypeProviders = nextProps.commonState.inspectionServiceProviderInfo;

        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].hash_code &&
            !currentState.hashcode
        ) {
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceAgents()
            .then(serviceAgentData => {
                nextProps.commonAction.getServiceAgentInfo(serviceAgentData)
            })
            .catch(e => {
                console.debug(e);
            });
            var providerType = "brokerage-home-inspection"
            var moveHash = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceProviders(providerType,moveHash)
            .then(serviceProviders => {
                nextProps.commonAction.getServiceProviderInfo(serviceProviders)
            })
            .catch(e => {
                console.debug(e);
            });
        }

        if(nextProps.commonState.serviceProviderInfo){
            currentState.serviceProviders = nextProps.commonState.serviceProviderInfo
        }

        return currentState;
    }

    handleButton = () => {
        this.myRef.current.play();
        this.setState({buttonShow: false});

        if (!this.state.videoSeen) {
            this.handleButtonAnalytics('', 'Video Play Button Clicked');
            this.setState({ videoSeen: true })
        }
    };

    handleButtonAnalytics = (copy: string, category?: string) => {
        AnalyticsService.buttonClicked({
            page: 'Onboarding Video Page',
            category_name: category || 'Button Clicked',
            button_copy: copy,
            card: true,
            card_type: 'Video',
            card_title: 'Overview of Elliman Showroom Personal Assistant'
        });
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        let enablePreApprovalPage = false;
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Show Preapproval Mover Onboarding')
            ) {
                enablePreApprovalPage = true;
            }
        }
        const { homeOwnerVideoURL } = this.state;

        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className='innercontainer_page greybg'>
                <div className='welcomvideo_wrapper'>
                    <div className='welcomevideo_banner'>
                        <p>Hi <span>{this.state.fullNameUser}</span>,</p>
                        <h1>Welcome To Your Moving Dashboard</h1>
                    </div>
                    <div className="welcomvideo_middlecontainer">
                        <h3 className='title'>Overview of {this.state.fullName}</h3>
                        <div className='videowrapper'>
                            {homeOwnerVideoURL ? (
                            <>
                                <video ref={this.myRef}>
                                    <source src={homeOwnerVideoURL} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                                    {/* Your browser does not support the video tag. */}
                                </video>
                                {this.state.buttonShow ? (<img src={playicon} onClick={this.handleButton} className="playbtn" />) : ""}
                                
                             </>
                            ) : ""}                            
                           
                        </div>
                        <br></br>
                        <Button
                            className='bluebtn'
                            boxShadow={
                                isDouglasElliman
                                    ? 'none'
                                    : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                            }
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : window.location.pathname.includes('landing')
                                    ? '#30AB86'
                                    : '#273e59'
                            }
                            data-cy={"mof-video-getstarted-button"}
                            onClick={() => {
                                this.handleButtonAnalytics('Let’s Get Started');
                                if ( enablePreApprovalPage ) {
                                    this.props.history.push('/signUp/preapproval');
                                } else if ( this.state.serviceProviders?.data?.length > 0 ) {
                                    this.props.history.push('/signUp/preApproval/next');
                                } else {
                                    this.props.history.push({
                                        pathname: '/dashboard',
                                        state: {
                                            fromSignUp: 'fromSignUp',
                                        },
                                    });
                                }
                            }}
                            >
                                Let’s Get Started
                        </Button>                        
                    </div>
                </div>
                <p className='stepwrapper'>Step 1 of 4</p>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeVideo);
