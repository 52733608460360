import React, { Component } from 'react';
import '../HomeWaranty/HomeWarantyMain.scss';
import Button from '../../components/atoms/Button';
// import callIcon from '../../Assets/images/InternetAndCable/call_icon.svg';
// import history from '../../routes/History'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EnergyAction from '../../store/energy/actions';
import arrowDown from '../../Assets/images/InternetAndCable/arrow_down.svg';
import Footer from './Footer';
import { powerkiosImageUrl } from '../../config';
import { CircularProgress } from '@material-ui/core';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
const moment = require('moment');

type initialProps = {
    history?: any;
    energyAction?: any;
    energyState?: any;
};

type initialState = {
    summaryData: any;
    isShowContent: boolean;
    defaultShowHideText: string;
    isShowHideText: string;
    isShowHideIndex: number;
    accessToken: any;
    showExploreButton: boolean;
};

export class EnergySavingsHome extends Component<initialProps, initialState> {
    state: initialState = { 
        summaryData: [],
        isShowContent: false,
        defaultShowHideText: 'More Details',
        isShowHideText: 'More Details',
        isShowHideIndex: 0,
        accessToken: '',
        showExploreButton: true,
    };

    componentDidMount() {
        this.props.energyAction.getAccessToken();
        if (
            this.props &&
            this.props.energyState &&
            this.props.energyState.length > 0 
        ) {
            this.setState({
                summaryData: this.props.energyState.getEnergySummary,
            });
        } else {
            this.props.energyAction.getEnergySummary();
        }
        if (this.props.history.location.state.showCompletedElectricity &&
            this.props.history.location.state.showCompletedGas) {
                this.setState({showExploreButton: false});
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        let prevW = prevProps.energyState;
        let curW = this.props.energyState;

        if (prevW.getEnergySummary !== curW.getEnergySummary && curW.getEnergySummary) {
            this.setState({
                summaryData: curW.getEnergySummary && curW.getEnergySummary,
            });
            let showCompletedElectricity: boolean = false;
            let showCompletedGas: boolean = false;
            curW.getEnergySummary &&
            curW.getEnergySummary.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });
            if(showCompletedElectricity && showCompletedGas){
                this.setState({ showExploreButton: false})
            }
        }
        if (
            prevW.getAccessToken != curW.getAccessToken &&
            curW.getAccessToken &&
            curW.getAccessToken.token &&
            curW.isGetAccessToken
        ) {
            curW.isGetAccessToken = false;
            this.setState({ accessToken: curW.getAccessToken.token });
        }
    }

    onClickKnowMore = () => {
        this.props.history.push({
            pathname: '/dashboard/energy-savings',
            state: {
                from: 'home',
            },
        });
    };
    onShowHideView = (isShow: boolean, index: number) => {
        let tempState: any = {
            isShowContent: isShow,
            isShowHideIndex: index
        }
        if (isShow) tempState.isShowHideText = 'Show Less'
        else tempState.isShowHideText = 'More Details'
        this.setState(tempState)
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Energy Savings',
                url: ''
            }
        ]

        return (
            <div>
                <div className="greybg">
                    <div className="main-head">
                        <h2 className="heading">Energy Savings</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                    </div>
                    <div className='summaryHeader summaryHeaderHome'>
                        <h3 className='summaryHomeWarrenty'>Subscription Details For Energy Savings</h3>
                        <p>If you need to update or manage your plan, please  <br/>
                        reach out to your service provider's customer service.</p>
                    </div>
                </div>
                {this.state.summaryData.length > 0 &&
                this.state.summaryData.map((item: any, index: any) => {
                    let displayRate: any =
                    item.contract_payload && item.contract_payload.displayRate;
                    if (displayRate.includes('$')) {
                        displayRate = displayRate.replace('$', '');
                        displayRate = parseFloat(displayRate) * 100;
                        displayRate = displayRate.toFixed(2);
                        displayRate = `${displayRate}¢`;
                    }
                    return item.contract_payload ? (
                        <div key={index} className='summaryCards summarybox summarybox2 summaryboxEnergy'>
                            <div className="summarybox_head">
                                <h5 className='summaryHeader' style={{ fontWeight: 600 }}>Your selected {item.service_type? item.service_type: ''} provider</h5>
                            </div>
                            <div className="summarybox_body">
                                <div className="main-final-utility">
                                    <div className="lineFinal">
                                        <div className="logoFinal">
                                        {item &&
                                        item.supplier_url &&
                                        item.supplier_url
                                        ? <img src={`${powerkiosImageUrl}${item.supplier_url}?access_token=${this.state.accessToken}`} alt="" />
                                        : ''}
                                        {item.service_provider_name ? item.service_provider_name : "-"}
                                        </div>

                                        <div className="kwhContent">
                                            <div className="kwhFinalValue">{displayRate? displayRate : ""}</div>
                                            <div className="kwhFinalUnit">per Kwh</div>
                                        </div>
                                        <div className="kwhContent">
                                            <div className="kwhFinalValue">{item.contract_payload.term? item.contract_payload.term: ""}Months</div>
                                            <div className="kwhFinalUnit">Contract Term</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="call_bar">
                                    <p>Have any customer service related issue? Call</p>
                                    <span>
                                        Customer Service
                                        <a href={`tel:(800)-735-4663`}>
                                            <span className="call_icon">
                                                <img src={callIcon} alt="phone" />
                                            </span>
                                            (800)-735-4663</a>
                                    </span>
                                </div> */}
                            </div>
                            <div className={`summaryfooter ${this.state.isShowContent && this.state.isShowHideIndex === index  ? 'showcontent' : ''}`}>
                                <div className="summary_hidden_content">
                                    <div className="content_row">
                                        <div className="content_col33">
                                            <p className="lab_val">Service Type</p>
                                            <p>{item.service_type?
                                                item.service_type: ''}</p>
                                        </div>
                                        <div className="content_col33">
                                            <p className="lab_val">Service Start Date</p>
                                            <p>{item.contract_payload.contractDate?
                                                moment(item.contract_payload.contractDate).format('MM-DD-YYYY'): ''}</p>
                                        </div>
                                        <div className="content_col33">
                                            <p className="lab_val">Rate Expiration Date</p>
                                            <p>{item.contract_payload.expirationDate?
                                            moment(item.contract_payload.expirationDate).format('MM-DD-YYYY'): ''}</p>
                                        </div>
                                        <div className="content_col33">
                                            <p className="lab_val">Cancellation fee</p>
                                            <p>{item.contract_payload.cancellationPenaltyDesc?
                                            item.contract_payload.cancellationPenaltyDesc: '-'}</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="readmorebtn"
                                        onClick={() =>
                                            this.onShowHideView(this.state.isShowHideIndex === index ? !this.state.isShowContent : this.state.isShowContent, index)
                                        }
                                >
                                    {this.state.isShowHideIndex === index ? this.state.isShowHideText : this.state.defaultShowHideText}
                                    <img src={arrowDown} alt="arrow icon" />
                                </button>
                            </div>
                        </div>
                    ) : 
                    (
                        <div  key={index} className='energyPageLoader'>
                            <CircularProgress />
                            <div> Working on it.Please Wait... </div>
                        </div>
                    ) 
                    ;
                })}
                <div className="bittom_btn_div havetwobtns">
                    {this.state.showExploreButton?(
                        <button className="borderbtn"
                        onClick={this.onClickKnowMore}
                    >Explore New Plans</button>
                    ): "" }
                    <Button
                    boxShadow={
                        isDouglasElliman
                            ? 'none'
                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                    }
                    backgroundColor={
                        isDouglasElliman
                            ? '#100B28'
                            : slug && slug.primary_color
                            ? slug.primary_color
                            : window.location.pathname.includes('landing')
                            ? '#30AB86'
                            : '#273e59'
                    }
                        onClick={() => {
                            this.props.history.push('/dashboard');
                        }}
                    >
                        Back To Dashboard
                    </Button>
                </div>
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnergySavingsHome);
