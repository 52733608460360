import React, { Component } from 'react';
import './Moving.scss';
import RoomIcon from '@material-ui/icons/Room';
// import SelectInput from '../../components/atoms/SelectInput';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import unitLogo from '../../Assets/images/MovingImages/download.svg';
import DummyIcon from '../../Assets/images/Moving/storageDummyIcon.svg';
// import Button from '../../components/atoms/Button';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckIcon from '@material-ui/icons/Check';
// import OrderPlace from "./OrderPlace";
// import logo from '../../Assets/images/MovingImages/facilityImage.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/move/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as DashboardAction from '../../store/dashboard/actions';
import downArrow from '../../Assets/images/Moving/down-arrow.png';
import small1 from '../../Assets/images/Moving/5x5-storage.svg';
import small2 from '../../Assets/images/Moving/5x10-storage.svg';
import large from '../../Assets/images/Moving/10x10-storage.svg';
import large2 from '../../Assets/images/Moving/10x15-storage.svg';
import large3 from '../../Assets/images/Moving/10x20-storage.svg';
import large4 from '../../Assets/images/Moving/10x30-storage.svg';
import UnitCard from './UnitCard';
import * as MoveAction from '../../store/common/actions';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    // facilityId: any;
    // facilityName:string;
    // facilityImage:any;
    // facilityAddress:string;
    history?: any;
    location?: any;
    moversAction?: any;
    moversState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    moveAction?: any;
    moveState?: any;
};

type initialState = {
    sortBy: string;
    totalClicked: boolean;
    smallClicked: boolean;
    mediumClicked: boolean;
    largeClicked: boolean;
    // continueClicked:boolean;
    isStorageShow: boolean;
    AnchorEl?: any;
    // storageItem:any;
    isShowMobileAbout: boolean;
    isDataArrived2: boolean;
    serviceId: any;
    completedMovingPercentage: any;
    isDataArrived3: boolean;
    checkedItems: any;
    isDataArrivedHeader: boolean;
    movingService: boolean;
};

class StorageCardDetails extends Component<initialProps, initialState> {
    state: initialState = {
        sortBy: '',
        totalClicked: true,
        smallClicked: false,
        mediumClicked: false,
        largeClicked: false,
        // continueClicked: false,
        isStorageShow: true,
        AnchorEl: null,
        // storageItem: {},
        isShowMobileAbout: false,
        isDataArrived2: true,
        serviceId: '',
        completedMovingPercentage: '',
        checkedItems: [],
        isDataArrived3: true,
        isDataArrivedHeader: true,
        movingService: false,
    };

    componentDidMount() {
        // this.setState({totalClicked: true})
        const payload = {
            facility_id: this.props.location.state.facilityId,
        };
        this.props.moversAction.reserveStorageByFacilityId(payload);
        this.props.dashboardAction.checklist();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            nextProps.CheckListDashboardState.checklist.forEach((item: any) => {
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/storage')) {
                    currentState.serviceId = item.id;
                }
                if (item.do_it_now && item.do_it_now.new_url.includes('/dashboard/movers')) {
                    currentState.completedMovingPercentage = item.completed_percentage;
                }
            });
        }

        if (
            nextProps &&
            nextProps.moversState &&
            nextProps.moversState.selectedMovers &&
            nextProps.moversState.selectedMovers.length &&
            currentState.isDataArrived3
        ) {
            currentState.isDataArrived3 = false;
            currentState.checkedItems = nextProps.moversState.selectedMovers;
        }

        if (
            nextProps &&
            nextProps.moveState &&
            nextProps.moveState.header &&
            nextProps.moveState.header.length &&
            currentState.isDataArrivedHeader
        ) {
            currentState.isDataArrivedHeader = false;
            let moving: any = [];
            moving = nextProps.moveState.header.filter(
                (el: any) => el.name && el.name.toLowerCase() === 'moving service',
            );

            if (moving.length) {
                currentState.movingService = true;
            }
        }
        return currentState;
    }

    handleLTH = () => {
        this.setState({ sortBy: 'Low To High' });
        this.handleClose();
    };

    handleHTL = () => {
        this.setState({ sortBy: 'High To Low' });
        this.handleClose();
    };
    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    // sortBy = (e: any) => {
    //     this.setState({ sortBy: e.value });
    // };

    totalTabClicked = () => {
        this.setState({
            totalClicked: true,
            smallClicked: false,
            mediumClicked: false,
            largeClicked: false,
        });
    };

    smallTabClicked = () => {
        this.setState({
            totalClicked: false,
            smallClicked: true,
            mediumClicked: false,
            largeClicked: false,
        });
    };

    mediumTabClicked = () => {
        this.setState({
            totalClicked: false,
            smallClicked: false,
            mediumClicked: true,
            largeClicked: false,
        });
    };

    largeTabClicked = () => {
        this.setState({
            totalClicked: false,
            smallClicked: false,
            mediumClicked: false,
            largeClicked: true,
        });
    };

    continue = async (item: any) => {
        // this.setState({continueClicked: true, isStorageShow: false, storageItem:item})

        await this.props.history.push({
            pathname: '/dashboard/movers/reserve_order_place',
            state: {
                facilityId: this.props.location.state.facilityId,
                name: this.props.location.state.facilityName,
                address: this.props.location.state.facilityAddress,
                id: item.space_id,
                dimension: item.dimensions,
                price: item.price,
                amenities: item.amenities,
                image: item.image ? item.image : DummyIcon,
                facilityImage: this.props.location.state.facilityImage,
            },
        });
        window.scrollTo(0, 0);
        this.props.dashboardAction.updateServicePercentage({
            data: {
                completed_percentage: 66,
            },
            service_id: this.state.serviceId,
        });
    };

    showMobileAbout = () => {
        this.setState({ isShowMobileAbout: !this.state.isShowMobileAbout });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let storageUnits: any = [];
        let smallStorageUnits: any = [];
        let mediumStorageUnits: any = [];
        let largeStorageUnits: any = [];
        let aboutDescription: string = '';
        const officeHours: any = [];
        const accessHours: any = [];
        const amenities: any = [];
        const storageUnitsData: any = [];
        if (this.props && this.props.moversState && this.props.moversState.reserveStorageByFacilityId) {
            aboutDescription = this.props.moversState.reserveStorageByFacilityId.description;
            if (this.props.moversState.reserveStorageByFacilityId.spaces) {
                this.props.moversState.reserveStorageByFacilityId.spaces.forEach((item: any) => {
                    let dimension1: any = item.dimensions ? parseInt(item.dimensions.split("' ")[0]) : 0;
                    let dimension2: any = item.dimensions
                        ? parseInt(item.dimensions.split('x ')[1] ? item.dimensions.split('x ')[1].split("'")[0] : 0)
                        : 0;

                    let image: any;
                    if (dimension1 <= 5 && dimension2 <= 9) {
                        image = small1;
                    }
                    if (dimension1 <= 5 && dimension2 > 9) {
                        image = small2;
                    }
                    if (dimension1 > 5 && dimension1 < 10) {
                        image = small2;
                    }

                    if (dimension1 >= 10 && dimension2 < 10) {
                        image = large;
                    }
                    if (dimension1 >= 10 && dimension2 >= 10 && dimension2 < 15) {
                        image = large;
                    }

                    if (dimension1 >= 10 && dimension2 >= 15 && dimension2 < 20) {
                        image = large2;
                    }

                    if (dimension1 >= 10 && dimension2 >= 20 && dimension2 < 30) {
                        image = large3;
                    }
                    if (dimension1 >= 10 && dimension2 >= 30) {
                        image = large4;
                    }
                    if (dimension1 >= 20 && dimension2 >= 0) {
                        image = large4;
                    }
                    let data = {
                        space_id: item.space_id,
                        amenities: item.amenities,
                        price: item.price,
                        dimensions: item.dimensions,
                        image: image,
                    };
                    storageUnitsData.push(data);
                });
            }
            if (this.props.moversState.reserveStorageByFacilityId.office_hours) {
                this.props.moversState.reserveStorageByFacilityId.office_hours.forEach((item: any) => {
                    officeHours.push(item);
                });
            }
            if (this.props.moversState.reserveStorageByFacilityId.access_hours) {
                this.props.moversState.reserveStorageByFacilityId.access_hours.forEach((item: any) => {
                    accessHours.push(item);
                });
            }
            if (this.props.moversState.reserveStorageByFacilityId.amenities) {
                this.props.moversState.reserveStorageByFacilityId.amenities.forEach((item: any) => {
                    amenities.push(item);
                });
            }
        }

        if (this.state.sortBy !== '' && this.state.sortBy === 'High To Low') {
            storageUnitsData.sort((a: any, b: any) => {
                let priceA = parseFloat(a.price);
                let priceB = parseFloat(b.price);
                if (priceA < priceB) {
                    return 1;
                }
                if (priceA > priceB) {
                    return -1;
                }
                return 0;
            });
        }

        if (this.state.sortBy !== '' && this.state.sortBy === 'Low To High') {
            storageUnitsData.sort((a: any, b: any) => {
                let priceA = parseFloat(a.price);
                let priceB = parseFloat(b.price);
                if (priceA < priceB) {
                    return -1;
                }
                if (priceA > priceB) {
                    return 1;
                }
                return 0;
            });
        }
        // const facilityData = {
        //     name: 'Tri-Village Self Storage',
        //     address: '3490 Trabue Road Columbus, OH 43204',
        //     // storageUnits: [{}],
        //     // image: logo,
        //     about:
        //         'Our Tri-Village Self Storage facility on Trabue Rd. gives you the extra space you need without disturbing your day-to-day living. We provide clean, secure storage units near you in Franklin County, View More',
        //     phone: '9876543210',
        //     email: 'trivillageselfstorage@storage.com',
        // };

        storageUnits = storageUnitsData;

        smallStorageUnits = storageUnitsData.filter((item: any) => {
            let dimension: any = item.dimensions ? parseInt(item.dimensions.split("' ")[0]) : 0;
            if (dimension <= 5) {
                return item;
            }
        });
        mediumStorageUnits = storageUnitsData.filter((item: any) => {
            let dimension: any = item.dimensions ? parseInt(item.dimensions.split("' ")[0]) : 0;
            if (dimension > 5 && dimension < 10) {
                return item;
            }
        });
        largeStorageUnits = storageUnitsData.filter((item: any) => {
            let dimension: any = item.dimensions ? parseInt(item.dimensions.split("' ")[0]) : 0;
            if (dimension >= 10) {
                return item;
            }
        });

        const totalUnits = storageUnitsData.length;
        const smallUnits = smallStorageUnits.length;
        const mediumUnits = mediumStorageUnits.length;
        const largeUnits = largeStorageUnits.length;

        if (this.state.totalClicked) {
            storageUnits = storageUnitsData;
        }

        if (this.state.smallClicked) {
            storageUnits = smallStorageUnits;
        }

        if (this.state.mediumClicked) {
            storageUnits = mediumStorageUnits;
        }

        if (this.state.largeClicked) {
            storageUnits = largeStorageUnits;
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Moving Services',
                url: '/dashboard/movers'
            },
            {
                name: 'Reserve a Storage Unit',
                url: '/dashboard/storage'
            },
            {
                name: 'Tri-Village Self Storage',
                url: ''
            }
        ]

        return (
            <div className="reserveStorage">
                <div className={isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'}>
                    <div className="reserveStorageFacilityDiv">
                        <h2 className="heading">Moving Services</h2>
                        <BreadCrumb breadList={breadList} />
                    </div>
                    <div className="tab-div-moving">
                        {this.state.movingService == true ? (
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    if (
                                        this.state.completedMovingPercentage >= 60 &&
                                        this.state.completedMovingPercentage < 100
                                    ) {
                                        this.props.history.push('/dashboard/movers/recommended');
                                    }
                                    if (this.state.completedMovingPercentage == 100) {
                                        this.props.history.push({
                                            pathname: '/dashboard/movers/thankyou',
                                            state: {
                                                checkedItems: this.state.checkedItems,
                                            },
                                        });
                                    }
                                    if (this.state.completedMovingPercentage < 60) {
                                        this.props.history.push('/dashboard/movers');
                                    }
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Moving
                            </div>
                        ) : null}
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/autoease' ||
                                    this.props.history.location.pathname === `/dashboard/autoease/`)
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/autoease');
                                window.scrollTo(0, 0);
                            }}
                        >
                            Auto Shipping
                        </div>
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/storage' ||
                                    this.props.history.location.pathname === '/dashboard/storage/:id' ||
                                    !(
                                        this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou'
                                    ))
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/storage');
                            }}
                        >
                            Reserve a Storage Unit
                        </div>
                    </div>
                    {this.state.isStorageShow ? (
                        <div className="Storage-main-div">
                            <div className="image-div">
                                <img src={this.props.location.state.facilityImage} alt="" />
                                <div className="facilityNameMobile">{this.props.location.state.facilityName}</div>
                                <div className="facilityAddressMobile">
                                    <RoomIcon className="room-icon" />
                                    {this.props.location.state.facilityAddress}
                                </div>
                                <div className="dataDiv">
                                    <div className="reserveStorageAboutDiv">
                                        <div className="aboutHeading">{`About ${this.props.location.state.facilityName}`}</div>
                                        <div className="selectedStorage Arrow">
                                            <ExpandMoreIcon onClick={this.showMobileAbout} />
                                        </div>
                                    </div>

                                    {this.state.isShowMobileAbout ? (
                                        <div>
                                            <div className="aboutDescription">{aboutDescription}</div>
                                            <hr style={{ border: '1px solid #e4e6e8' }} />
                                            {this.props.moversState.reserveStorageByFacilityId.phone_number ? (
                                                <div className="phoneDiv">
                                                    <div className="phoneIcon">
                                                        <PhoneIcon className="phone" />
                                                    </div>
                                                    <div className="phoneDetails">
                                                        <div className="phoneHeading">Phone Number</div>
                                                        <div className="phoneNumber">
                                                            {
                                                                this.props.moversState.reserveStorageByFacilityId
                                                                    .phone_number
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {this.props.moversState.reserveStorageByFacilityId.emailId ? (
                                                <div>
                                                    <div className="phoneDiv">
                                                        <div className="phoneIcon">
                                                            <MailOutlineIcon className="phone" />
                                                        </div>
                                                        <div className="phoneDetails">
                                                            <div className="phoneHeading">Email Id</div>
                                                            <div className="phoneNumber">trival@gmail.com</div>
                                                        </div>
                                                    </div>
                                                    <hr style={{ border: '1px solid #e4e6e8' }} />
                                                </div>
                                            ) : null}

                                            {officeHours.length && accessHours.length ? (
                                                <div className="TimingDiv">
                                                    <div className="hoursDiv">
                                                        <div className="officeHoursDiv">
                                                            <div className="hoursHeading">Office hours</div>
                                                            {officeHours.map((item: any, i: number) => {
                                                                return (
                                                                    <div key={i} className="hoursDiv">
                                                                        <div className="days">
                                                                            {item.split(': ')[0]}
                                                                        </div>
                                                                        <div
                                                                            className="hours"
                                                                            style={{
                                                                                color:
                                                                                    item.split(': ')[1] == 'Closed'
                                                                                        ? 'red'
                                                                                        : '#333333',
                                                                            }}
                                                                        >
                                                                            {item.split(': ')[1]}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="accessHoursDiv">
                                                            <div className="hoursHeading">Access hours</div>
                                                            {accessHours.map((item: any, i: number) => {
                                                                return (
                                                                    <div key={i} className="hoursDiv">
                                                                        <div className="hours">
                                                                            {item.split(': ')[1]}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <hr style={{ border: '1px solid #e4e6e8' }} />
                                                </div>
                                            ) : null}
                                            {amenities.length ? (
                                                <div className="featursDivReserveStorage">
                                                    <div className="amenityHeading">Features & amenities</div>
                                                    {amenities.map((amenity: any, i: number) => {
                                                        return (
                                                            <div key={i} className="amenityDiv">
                                                                <CheckIcon />
                                                                <div className="amenityTitle">{amenity}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="dataDivDesktop">
                                    <div className="reserveStorageAboutDiv">
                                        <div className="aboutHeading">{`About ${this.props.location.state.facilityName}`}</div>
                                    </div>
                                    <div>
                                        <div className="aboutDescription">{aboutDescription}</div>
                                        <hr style={{ border: '1px solid #e4e6e8' }} />
                                        {this.props.moversState.reserveStorageByFacilityId.phone_number ? (
                                            <div className="phoneDiv">
                                                <div className="phoneIcon">
                                                    <PhoneIcon className="phone" />
                                                </div>
                                                <div className="phoneDetails">
                                                    <div className="phoneHeading">Phone Number</div>
                                                    <div className="phoneNumber">
                                                        {this.props.moversState.reserveStorageByFacilityId.phone_number}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {this.props.moversState.reserveStorageByFacilityId.emailId ? (
                                            <div>
                                                <div className="phoneDiv">
                                                    <div className="phoneIcon">
                                                        <MailOutlineIcon className="phone" />
                                                    </div>
                                                    <div className="phoneDetails">
                                                        <div className="phoneHeading">Email Id</div>
                                                        <div className="phoneNumber">trival@gmail.com</div>
                                                    </div>
                                                </div>
                                                <hr style={{ border: '1px solid #e4e6e8' }} />
                                            </div>
                                        ) : null}

                                        {officeHours.length && accessHours.length ? (
                                            <div className="TimingDiv">
                                                <div className="hoursDiv">
                                                    <div className="officeHoursDiv">
                                                        <div className="hoursHeading">Office hours</div>
                                                        {officeHours.map((item: any, i: number) => {
                                                            return (
                                                                <div key={i} className="hoursDiv">
                                                                    <div className="days">{item.split(': ')[0]}</div>
                                                                    <div className="hours">{item.split(': ')[1]}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className="accessHoursDiv">
                                                        <div className="hoursHeading">Access hours</div>
                                                        {accessHours.map((item: any, i: number) => {
                                                            return (
                                                                <div key={i} className="hoursDiv">
                                                                    <div className="hours">{item.split(': ')[1]}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <hr style={{ border: '1px solid #e4e6e8' }} />
                                            </div>
                                        ) : null}
                                        {amenities.length ? (
                                            <div className="featursDivReserveStorage">
                                                <div className="amenityHeading">Features & amenities</div>
                                                {amenities.map((amenity: any, i: number) => {
                                                    return (
                                                        <div key={i} className="amenityDiv">
                                                            <CheckIcon />
                                                            <div className="amenityTitle">{amenity}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="facility-details">
                                <div className="facilityName">{this.props.location.state.facilityName}</div>
                                <div className="facilityAddress">
                                    <RoomIcon className="room-icon" />
                                    {this.props.location.state.facilityAddress}
                                </div>
                                <div className="headerDiv">
                                    <div className="titleDiv">Storage Units</div>
                                    <div className="selectDiv">
                                        <div
                                            className="avg-customer-review"
                                            onClick={this.handleMenu}
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            Sort by: Price
                                        </div>
                                        <div className="down-arrow">
                                            <img src={downArrow} className="sortArrow" alt="" />
                                        </div>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={this.state.AnchorEl}
                                            keepMounted
                                            open={Boolean(this.state.AnchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                            <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                                <div className="tab">
                                    <div
                                        onClick={this.totalTabClicked}
                                        className={this.state.totalClicked ? 'blueTab' : 'blackTab'}
                                    >{`All Units (${totalUnits})`}</div>
                                    <div
                                        onClick={this.smallTabClicked}
                                        className={this.state.smallClicked ? 'blueTab' : 'blackTab'}
                                    >{`Small Units (${smallUnits})`}</div>
                                    <div
                                        onClick={this.mediumTabClicked}
                                        className={this.state.mediumClicked ? 'blueTab' : 'blackTab'}
                                    >{`Medium Units (${mediumUnits})`}</div>
                                    <div
                                        onClick={this.largeTabClicked}
                                        className={this.state.largeClicked ? 'blueTab' : 'blackTab'}
                                    >{`Large Units (${largeUnits})`}</div>
                                </div>

                                {storageUnits.length ? (
                                    storageUnits.map((item: any, i: number) => {
                                        return (
                                            <div key={i} className="tabContent">
                                                <UnitCard
                                                    item={item}
                                                    DummyIcon={DummyIcon}
                                                    slug={slug}
                                                    continue={this.continue}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="notAvailableUnitsText">Selected units is not available</div>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* {this.state.continueClicked ? (
                  <OrderPlace name={this.props.location.state.facilityName} address={this.props.location.state.facilityAddress} id={this.state.storageItem.space_id} dimension={this.state.storageItem.dimensions} price={this.state.storageItem.price} amenities={this.state.storageItem.amenities}/>
                ) : null} */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        moveAction: bindActionCreators(MoveAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    CheckListDashboardState: state.checklistDashboard,
    moveState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageCardDetails);
