import React from 'react';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../store/login/signup/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import './preApproval.scss';
import ApiService from '../api-service';
import { InputLabel} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import user from '../../Assets/images/welcome/user.png'
import Button from '../../components/atoms/Button';
import moment from 'moment';

type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {    
    homeOwnerVideoURL: string;
    fullName: string;
    phone: string;
    email: string;
    image: string;
    licenceNumber: string;
    buttonShow: boolean;
    serviceAgentData: any;
    serviceProviders: any;
    serviceDeadline: any;
    closingDate: any;
    serviceProvidersError: string;
    serviceDeadlineError: string;
    closingDateError: string;
    showSuccessMessage: boolean;
    hashcode: string;
    
};
class welcomeVideo extends React.Component<initialProps, initialState> {
    state: initialState = {
        homeOwnerVideoURL: '',
        fullName: '',
        phone: '',
        email: '',
        image: '',
        licenceNumber: '',
        buttonShow: true,
        serviceAgentData: '',
        serviceProviders: '',
        serviceDeadline: '',
        closingDate: '',
        serviceProvidersError: '',
        serviceDeadlineError: '',
        closingDateError: '',
        showSuccessMessage: false,
        hashcode: '',
    };

  
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].hash_code &&
            !currentState.hashcode && !currentState.serviceAgentData
        ) {
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceAgents()
            .then(serviceAgentData => {
                nextProps.commonAction.getServiceAgentInfo(serviceAgentData)
            })
            .catch(e => {
                console.debug(e);
            });
            var providerType = "brokerage-home-inspection"
            var moveHash = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceProviders(providerType,moveHash)
            .then(serviceProviders => {
                nextProps.commonAction.getServiceProviderInfo(serviceProviders)
            })
            .catch(e => {
                console.debug(e);
            });
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].realtor
        ) {
            currentState.homeOwnerVideoURL =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.home_owner_video_url
            currentState.fullName =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.contact_name
            currentState.phone =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.phone
            currentState.email =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.email
            currentState.image =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.contact_logo ? nextProps.commonState.move[0].realtor.contact_logo : user
            currentState.licenceNumber =
                nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor.dre_no
        }
        if(nextProps.commonState.serviceAgentInfo){
            currentState.serviceAgentData = nextProps.commonState.serviceAgentInfo
        }

        if(nextProps.commonState.serviceProviderInfo && !currentState.serviceProviders){
            {nextProps.commonState.serviceProviderInfo?.data?.map((item:any) => {
                item["isChecked"] = false
            })}
            currentState.serviceProviders = nextProps.commonState.serviceProviderInfo
        }
        if(nextProps.signupState.cretaeContactServiceProviders?.message){
            currentState.showSuccessMessage = true
        }
        
        return currentState;
    }

    handleOnChange = (event:any, _option:any , index:any) => {
        let values = {
            data: [...this.state.serviceProviders?.data]
        };
        values = JSON.parse(JSON.stringify(values))
        values.data[index]['isChecked'] = event.target.checked;
        this.setState({serviceProviders: values})
     };
     validateFields = (serviceProvidersList:any) => {
        let validity: Boolean = true;
        if (serviceProvidersList?.length === 0) {
            this.setState({ serviceProvidersError: 'Please select one provider' });
            validity = false;
        } else {
            this.setState({ serviceProvidersError: '' });
        }

        if (this.state.serviceDeadline === '') {
            this.setState({ serviceDeadlineError: 'Please enter service deadline' });
            validity = false;
        } else {
            this.setState({ serviceDeadlineError: '' });
        }

        if (this.state.closingDate === '') {
            this.setState({ closingDateError: 'Please enter closing date' });
            validity = false;
        } else {
            this.setState({ closingDateError: '' });
        }

        return validity;
    };
     proceedButtonHandler = () => {
        let serviceProvidersList = this.state.serviceProviders?.data.filter((provider:any)=>{
            return provider.isChecked
         });
        if (this.validateFields(serviceProvidersList)) {
            let serviceMap: any[] = [];
            serviceProvidersList.map(
                (item:any) => serviceMap.push(item.id)
              );
              let payload: any = {
                "contact_from": "MOVING Dashboard",
                "service_provider_list": serviceMap,
                "service_deadline_date": moment(this.state.serviceDeadline).format('YYYY-MM-DD'),
                "closing_date": moment(this.state.closingDate).format('YYYY-MM-DD'),
            };
            this.props.signupAction.cretaeContactServiceProviders(payload);
        }
     }
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data) || null;

        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let serviceProvidersList = this.state.serviceProviders?.data? this.state.serviceProviders?.data?.filter((provider:any)=>{
            return provider.isChecked
         }) : ''
        return (
            <div className="innercontainer_page greybg">
                <div className="innerbox_width havesidebar">
                    <div className="sidebar_blue homebgimg">
                        <h2 className="sidebartitle">Set up a<br></br><span>Home Inspection appointment</span></h2>
                    </div>
                    <div className="contentbar">
                        <h2 className="contentbar_maintitle">
                            <a href="#">                         
                                {/* <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { this.props.history.push('/signUp/preapproval') }}>
                                    <path d="M33.5164 15.3643C32.1448 15.3643 8.47879 15.3643 8.47879 15.3643L19.5526 4.25335C20.5227 3.2806 20.5227 1.70261 19.5526 0.729997C18.5825 -0.243332 17.0096 -0.243332 16.0409 0.729997L0.727602 16.094C-0.242534 17.0668 -0.242534 18.6449 0.727602 19.6174L16.0409 34.9821C16.5259 35.469 17.1614 35.712 17.7968 35.712C18.4321 35.712 19.0677 35.469 19.5526 34.9821C20.5227 34.0094 20.5227 32.4314 19.5526 31.4589L8.47879 20.3474C8.47879 20.3474 32.145 20.3474 33.5164 20.3474C34.8879 20.3474 36 19.2317 36 17.8558C36 16.4798 34.888 15.3643 33.5164 15.3643Z" fill="#333333"/>
                                </svg> */}
                                Contact home Inspectors
                            </a>
                        </h2>
                        <p className="contentbar_subtitle">Select & submit recommended home Inspectors</p>
                        {!this.state.showSuccessMessage ? (
                        <>
                        <div className="error">
                            {this.state.serviceProvidersError 
                                ? this.state.serviceProvidersError
                                : ''}
                        </div>
                        <div className="contentlist">
                            <div className="checkbox_wrapper">
                                {serviceProviders?.map((item:any, index:any) => {
                                    return(
                                        <>
                                        <div className="single_checkbox">
                                            <input type="checkbox" name={item.slug} data-cy={"signup-input-expiration-date-"+index} value={item.name}  onChange={(e) => this.handleOnChange(e, item, index)} id={item.slug} />
                                            <div className="custom_check">
                                                <label className="custom_check_ic"></label>
                                                <label className="custom_check_text">{item.name}</label>
                                                <div className="check_img_wrapper">
                                                    <img src={item.logo}/>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='preApprovalDates mb_50'>
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <InputLabel
                                            htmlFor="bootstrap-input"
                                            className={'text-black ml-2 mb-2 mt-3'}
                                            style={{ fontFamily: 'Poppins', color: '#162855' }}
                                        >
                                            Service Deadline
                                        </InputLabel>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                // disablePast
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date"
                                                inputVariant="filled"
                                                label="Select date"
                                                className="date11"
                                                value={
                                                    this.state.serviceDeadline
                                                        ? new Date(this.state.serviceDeadline)
                                                        : null
                                                }
                                                onChange={(e: any) => this.setState({ serviceDeadline: e })}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={ new Date() }
                                                PopoverProps={{
                                                    style: { zIndex: 1000000 },
                                                }}
                                                autoOk={true}
                                                data-cy="signup-input-service-deadline"
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div className="error">
                                            {this.state.serviceDeadlineError && !this.state.serviceDeadlineError
                                                ? this.state.serviceDeadlineError
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputFlexDiv2">
                                    <div className="inputDiv1">
                                        <InputLabel
                                            htmlFor="bootstrap-input"
                                            className={'text-black ml-2 mb-2 mt-3'}
                                            style={{ fontFamily: 'Poppins', color: '#162855' }}
                                        >
                                            Closing Date
                                        </InputLabel>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                // disablePast
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date"
                                                inputVariant="filled"
                                                label="Select date"
                                                className="date11"
                                                value={
                                                    this.state.closingDate
                                                        ? new Date(this.state.closingDate)
                                                        : null
                                                }
                                                onChange={(e: any) => this.setState({ closingDate: e })}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={ new Date() }
                                                PopoverProps={{
                                                    style: { zIndex: 1000000 },
                                                }}
                                                autoOk={true}
                                                data-cy="signup-input-closing-date"
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div className="error">
                                            {this.state.closingDateError && !this.state.closingDate
                                                ? this.state.closingDateError
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btns">
                                <Button
                                    className='bluebtn'
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                    }
                                    data-cy="signup-input-step-four-submit"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    disabled={serviceProvidersList.length > 0 && this.state.serviceDeadline && this.state.closingDate ? false : true}
                                        // onClick={() => {
                                        //     this.props.history.push('/dashboard');
                                        // }}
                                        onClick={this.proceedButtonHandler}
                                    >
                                        Submit Request
                                </Button>
                            </div>
                        </div>
                        </>
                        ) : (
                        <div className="contentlist">
                            <div className="thankyou_wrapper havespace">
                                <div className="thankyou_wrapper_ic">
                                    <svg id="Group_42451" data-name="Group 42451" xmlns="http://www.w3.org/2000/svg" width="39.835" height="39.835" viewBox="0 0 39.835 39.835">
                                    <circle id="Ellipse_135" data-name="Ellipse 135" cx="19.918" cy="19.918" r="19.918" fill="#00d56d"/>
                                    <path id="Check" d="M110.895,2642.323l-6.618-6.664a.94.94,0,0,1,0-1.332l1.337-1.334a.952.952,0,0,1,1.337,0l4.614,4.666,10.284-10.385a.952.952,0,0,1,1.337,0l1.337,1.334a.938.938,0,0,1,0,1.33l-12.291,12.385a.947.947,0,0,1-1.337,0" transform="translate(-94.205 -2615.093)" fill="#fff"/>
                                    </svg>
                                </div>
                                <div className="thankyou_wrapper_content">
                                    <h4>Great! Your request has been submitted<br></br>successfully</h4>
                                    <p>Thank you for your submission. You will be contacted soon</p>
                                </div>
                            </div>
                            <div className="btns">
                                <Button
                                    className='bluebtn'
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                    }
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    data-cy="signup-input-proceed-to-dashboard"
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname: '/dashboard',
                                                state: {
                                                    fromSignUp: 'fromSignUp',
                                                },
                                            });
                                        }}
                                    >
                                        Proceed To Dashboard
                                </Button>
                                
                            </div>
                        </div>
                        )}
                    </div>
                </div>
                <p className='stepwrapper'>Step 4 of 4</p>
            </div>
            
               
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeVideo);
