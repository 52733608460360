import React from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonNew from '../atoms/Button';
import arrowBack from '../../Assets/images/DashBoard/arrowBack.svg';
import '../../styles/dashBoardStepRight.scss';
// import { classNames } from 'react-select/src/utils';

type iProps = {
    backgroundColor?: string;
    className?: string;
    className2?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    buttonText2?: string;
    buttonBackGroundColor2?: string;
    buttonColor2?: string;
    buttonShadow2?: string;
    buttonHeight2?: string;
    buttonWidth2?: string;
    onClick?: any;
    onClick2?: any;
    buttonMargin?: any;
    buttonMargin2?: any;
    padding?: any;
    currentStep?: string;
    totalSteps?: string;
    arrowMarginTop?: string;
    arrowBack?: any;
    arrowHandler?: any;
    leftarrow?: any;
    toparrow?: any;
    bottomarrow?: any;
    imageClass?: any;
};

const DashBoardStepRight = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div className={props.className2 ? props.className2 : 'main-div-step'}>
            {props.leftarrow ? (
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderTop: '20px solid transparent',
                        borderRight: '40px solid #ECF4FD',
                        borderBottom: '20px solid transparent',
                        marginTop: props.arrowMarginTop ? props.arrowMarginTop : '75px',
                    }}
                ></div>
            ) : null}
            {props.toparrow ? (
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderTop: '16px solid transparent',
                        borderRight: '40px solid #ECF4FD',
                        borderBottom: '20px solid transparent',
                        marginTop: props.arrowMarginTop ? props.arrowMarginTop : '0px',
                        marginLeft: props.className2 == 'tavs-card' ? '36%' : '50%',
                        transform: 'rotate(90deg)',
                    }}
                ></div>
            ) : null}
            <div
                className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-right-body`}
                style={{
                    border: props.border ? props.border : 'none',
                    width: props.width ? props.width : '370px',
                    height: props.height ? props.height : '215px',
                    padding: props.padding ? props.padding : '0px',
                    borderRadius: '5px',
                    backgroundColor: props.backgroundColor ? props.backgroundColor : '#ECF4FD',
                }}
            >
                <div
                    className="basic-card-body"
                    style={{
                        marginLeft: props.className2 === 'tabs-card' ? '0px' : '20px',
                        width: props.className2 == 'tabs-card' ? '80%' : '100%',
                        margin: props.className2 == 'tabs-card' ? '0 auto' : '0 20px',
                    }}
                >
                    <div style={{ margin: '14px 0 10px 0px', color: '#6B6C6F' }}>
                        <div style={{ display: 'flex' }}>
                            {props.arrowBack ? (
                                <div
                                    style={{ cursor: 'pointer', padding: '2px 10px 0 0' }}
                                    onClick={props.arrowHandler}
                                >
                                    <img src={arrowBack} alt="" />
                                </div>
                            ) : null}
                            <div>
                                {' '}
                                {props.currentStep} {props.currentStep ? 'of' : null} {props.totalSteps}{' '}
                            </div>
                        </div>
                    </div>
                    <div className="step-title">{props.title}</div>
                    <div className="step-subtitle">{props.subTitle}</div>
                    <div className="wrapper-one">
                        <div className="wrapper-two">
                            <div>
                                <ButtonNew
                                    backgroundColor={props.buttonBackGroundColor}
                                    boxShadow={props.buttonShadow}
                                    color={props.buttonColor}
                                    borderRadius="5px"
                                    fontSize="14px"
                                    fontWeight="600"
                                    height={props.buttonHeight ? props.buttonHeight : '40px'}
                                    width={props.buttonWidth ? props.buttonWidth : '70px'}
                                    margin={props.buttonMargin ? props.buttonMargin : '2px 13px 10px 0px'}
                                    onClick={props.onClick}
                                >
                                    {props.buttonText}
                                </ButtonNew>
                            </div>

                            <div>
                                {props.buttonText2 ? (
                                    <ButtonNew
                                        backgroundColor={props.buttonBackGroundColor2}
                                        boxShadow={props.buttonShadow2}
                                        color={props.buttonColor2}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={props.buttonHeight2 ? props.buttonHeight2 : '40px'}
                                        width={props.buttonWidth2 ? props.buttonWidth2 : '70px'}
                                        margin={props.buttonMargin2 ? props.buttonMargin2 : '10px 0px 10px 0px'}
                                        onClick={props.onClick2}
                                    >
                                        {props.buttonText2}
                                    </ButtonNew>
                                ) : null}{' '}
                            </div>
                        </div>

                        {!isDouglasElliman && (
                            <div
                                style={{ marginTop: '-40px' }}
                                className={props.imageClass ? props.imageClass : 'wrapper-two-image-div'}
                            >
                                <img
                                    src={props.icon}
                                    height={props.imageHeight ? props.imageHeight : '100px'}
                                    width={props.imageWidth ? props.imageWidth : '145px'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {props.bottomarrow ? (
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderTop: '20px solid transparent',
                        borderRight: '40px solid #ECF4FD',
                        borderBottom: '20px solid transparent',
                        marginTop: props.arrowMarginTop ? props.arrowMarginTop : '0px',
                        marginLeft: '50%',
                        transform: 'rotate(270deg)',
                        marginBottom: '0',
                    }}
                ></div>
            ) : null}
        </div>
    );
};

export default DashBoardStepRight;
