import React from 'react';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../store/login/signup/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import './preApproval.scss';
import ApiService from '../api-service';
// import logo from '../../Assets/images/welcome/logo.png'
import DouglasLogo from '../../Assets/images/DouglasImages/DouglasLogo.svg';
import Button from '../../components/atoms/Button';
import History from '../../routes/History';
import _ from 'lodash';

type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {    
    homeOwnerVideoURL: string;
    fullName: string;
    phone: string;
    email: string;
    image: string;
    licenceNumber: string;
    buttonShow: boolean;
    serviceAgentData: any;
    serviceProviders: any;
    serviceProvidersHomeInspection: any;
    hashcode: string;
    clickSubmit: boolean;
    homeinspectionTypeProviders: any
};
class welcomeVideo extends React.Component<initialProps, initialState> {
    state: initialState = {
        homeOwnerVideoURL: '',
        fullName: '',
        phone: '',
        email: '',
        image: '',
        licenceNumber: '',
        buttonShow: true,
        serviceAgentData: '',
        serviceProviders: '',
        serviceProvidersHomeInspection: '',
        hashcode: '',
        clickSubmit: false,
        homeinspectionTypeProviders: null
    };
    
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].hash_code &&
            !currentState.hashcode
        ) {
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceAgents()
            .then(serviceAgentData => {
                nextProps.commonAction.getServiceAgentInfo(serviceAgentData)
            })
            .catch(e => {
                console.debug(e);
            });
            var providerType = "brokerage-home-inspection"
            var moveHash = nextProps.commonState.move[0].hash_code;

            ApiService.getServiceProviders(providerType, moveHash)
              .then((providers: any) => {
                nextProps.commonAction.getInspectionServiceProviderInfo(providers);
              })
              .catch(e => {
                console.debug(e);
            });
            

            ApiService.getServiceProviders()
            .then(serviceProviders => {
                nextProps.commonAction.getServiceProviderInfo(serviceProviders);
            })
            .catch(e => {
                console.debug(e);
            });
        }

        currentState.homeinspectionTypeProviders = nextProps.commonState.inspectionServiceProviderInfo;
        if(nextProps.commonState.serviceAgentInfo){
            currentState.serviceAgentData = nextProps.commonState.serviceAgentInfo
        }

        if(nextProps.commonState.serviceProviderInfo && !currentState.serviceProviders){
            currentState.serviceProviders = nextProps.commonState.serviceProviderInfo
        }

        if(currentState.serviceProviders && nextProps.commonState.serviceProviderInfo &&!currentState.serviceProvidersHomeInspection){
            var providerType = "brokerage-home-inspection"
            var moveHash = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceProviders(providerType,moveHash)
            .then(serviceProviders => {
                nextProps.commonAction.getServiceProviderInfo(serviceProviders);
                nextProps.commonAction.getInspectionServiceProviderInfo(serviceProviders);
            })
            .catch(e => {
                console.debug(e);
            });
            currentState.serviceProvidersHomeInspection = nextProps.commonState.serviceProviderInfo;
        }

        return currentState;
    }

    proceedButtonHandler = () => {

        if (!_.isEmpty(this.state.homeinspectionTypeProviders?.data)) {
        this.state.serviceProvidersHomeInspection?.data?.length > 0 ? History.push('/signUp/preApproval/next') : 
        this.props.history.push({
            pathname: '/dashboard',
            state: {
                fromSignUp: 'fromSignUp',
            },
        });
        } else {
            this.props.history.push({
                pathname: '/dashboard',
                state: {
                    fromSignUp: 'fromSignUp',
                },
            });
        }
     }

    render() {

       let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const serviceAgentData = (this.state.serviceAgentData?.length && this.state.serviceAgentData[0]) || null;
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data[0]) || null;
        let name, phone, email, serviceLogo, licenceNumber :any = ''

        const hasAgentData = !_.isEmpty(serviceAgentData);
        const hasServiceProviderData = !_.isEmpty(serviceProviders);

        if(hasAgentData){
            name = `${serviceAgentData.first_name} ${serviceAgentData.last_name}`
            phone = serviceAgentData.primary_phone_no
            email = serviceAgentData.email
            serviceLogo = serviceAgentData.photo_url
            licenceNumber = serviceAgentData.license_no
        } else if (hasServiceProviderData){
            name =serviceProviders.name
            phone = serviceProviders.phone
            email = serviceProviders.email
            serviceLogo = serviceProviders.logo
            licenceNumber = serviceProviders.license_no
        }
        serviceLogo = serviceLogo ? serviceLogo : null

        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="innercontainer_page greybg">
                <div className="innerbox_width havesidebar">
                    <div className="sidebar_blue">
                        <h2 className="sidebartitle">Set up a<br></br><span>Preapproval</span></h2>
                    </div>
                    <div className="contentbar">
                        <h2 className="contentbar_maintitle">
                            <a href="#">                         
                                <svg width="36" height="36" viewBox="0 0 36 36" data-cy="signup-back-to-step-two" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                // onClick={() => { this.props.history.push('/signUp/preapproval') }}
                                 onClick={() => {
                                        History.push({
                                            pathname: '/signUp/preapproval',
                                            state: {
                                                from: 'getPreapproval',
                                            },
                                        });
                                    }}
                                >
                                    <path d="M33.5164 15.3643C32.1448 15.3643 8.47879 15.3643 8.47879 15.3643L19.5526 4.25335C20.5227 3.2806 20.5227 1.70261 19.5526 0.729997C18.5825 -0.243332 17.0096 -0.243332 16.0409 0.729997L0.727602 16.094C-0.242534 17.0668 -0.242534 18.6449 0.727602 19.6174L16.0409 34.9821C16.5259 35.469 17.1614 35.712 17.7968 35.712C18.4321 35.712 19.0677 35.469 19.5526 34.9821C20.5227 34.0094 20.5227 32.4314 19.5526 31.4589L8.47879 20.3474C8.47879 20.3474 32.145 20.3474 33.5164 20.3474C34.8879 20.3474 36 19.2317 36 17.8558C36 16.4798 34.888 15.3643 33.5164 15.3643Z" fill="#333333"/>
                                </svg>
                                Need to get Preapproved?
                            </a>
                        </h2>
                        <div className="contentbarlogo">
                            {/* <img src={logo}/> */}
                            <img src={isDouglasElliman ? DouglasLogo : slug && slug.logo} alt="" />
                            <p>Your Mortgage</p>
                        </div>
                        <div className="contentbar_profile">
                            {serviceLogo && <div className={`contentbar_profileimg ${hasAgentData && 'agentProfilePhotoCover'}`}>
                                <img src={serviceLogo}/>
                            </div>}
                            <div className={`contentbar_profilecontent  ${_.isEmpty(serviceLogo) && 'p-0'}`}>
                                <h4>{name}</h4>
                                <ul>
                                    {phone? (
                                        <li>
                                            <a href={`tel:${phone}`}>
                                                <svg id="call_black_24dp" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <path id="Path_38896" data-name="Path 38896" d="M0,0H16V16H0Z" fill="none"/>
                                                <path id="Path_38897" data-name="Path 38897" d="M5.36,4.333a8.208,8.208,0,0,0,.3,1.727l-.8.8a9.884,9.884,0,0,1-.507-2.527H5.36m6.573,8.013a8.5,8.5,0,0,0,1.733.3v.993a10.284,10.284,0,0,1-2.533-.5l.8-.793M6,3H3.667A.669.669,0,0,0,3,3.667,11.332,11.332,0,0,0,14.333,15,.669.669,0,0,0,15,14.333V12.007a.669.669,0,0,0-.667-.667,7.6,7.6,0,0,1-2.38-.38.56.56,0,0,0-.207-.033.683.683,0,0,0-.473.193L9.807,12.587A10.1,10.1,0,0,1,5.413,8.193L6.88,6.727a.669.669,0,0,0,.167-.68,7.574,7.574,0,0,1-.38-2.38A.669.669,0,0,0,6,3Z" transform="translate(-1 -1)" fill="#707070"/>
                                                </svg>
                                                {phone}</a>
                                        </li>
                                    ) : ""}
                                    {email? (
                                        <li>
                                            <a href={`mailto:${email}`}>
                                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.6139 0.251892H1.38608C0.622785 0.251892 0 0.874677 0 1.63797V12.362C0 13.1253 0.622785 13.7481 1.38608 13.7481H16.6139C17.3772 13.7481 18 13.1253 18 12.362V1.63797C18 0.874677 17.3772 0.251892 16.6139 0.251892ZM16.4127 1.27721L9.09494 7.53544L1.77722 1.27721H16.4127ZM16.9747 12.362C16.9747 12.5595 16.8114 12.7228 16.6139 12.7228H1.38608C1.18861 12.7228 1.02532 12.5595 1.02532 12.362V1.98354L8.76076 8.59873C8.76456 8.60253 8.77215 8.60632 8.77595 8.61012C8.77975 8.61392 8.78734 8.61772 8.79114 8.62151C8.80253 8.62911 8.81013 8.6367 8.82152 8.6405C8.82532 8.6443 8.82911 8.6443 8.83291 8.6481C8.8481 8.65569 8.86329 8.66328 8.87848 8.67088C8.88228 8.67088 8.88608 8.67468 8.88987 8.67468C8.90127 8.67848 8.91266 8.68607 8.92785 8.68987C8.93165 8.68987 8.93924 8.69366 8.94304 8.69366C8.95443 8.69746 8.96582 8.70126 8.97721 8.70126C8.98101 8.70126 8.98861 8.70506 8.9924 8.70506C9.0038 8.70885 9.01899 8.70885 9.03038 8.71265C9.03418 8.71265 9.03797 8.71265 9.04177 8.71265C9.05696 8.71265 9.07595 8.71645 9.09114 8.71645C9.10633 8.71645 9.12532 8.71645 9.14051 8.71265C9.1443 8.71265 9.1481 8.71265 9.1519 8.71265C9.16329 8.71265 9.17848 8.70885 9.18987 8.70506C9.19367 8.70506 9.20127 8.70126 9.20506 8.70126C9.21646 8.69746 9.22785 8.69366 9.23924 8.69366C9.24304 8.69366 9.25063 8.68987 9.25443 8.68987C9.26582 8.68607 9.27721 8.68227 9.2924 8.67468C9.2962 8.67468 9.3 8.67088 9.3038 8.67088C9.31899 8.66328 9.33418 8.65569 9.34937 8.6481C9.35316 8.6443 9.35696 8.6443 9.36076 8.6405C9.37215 8.63291 9.37975 8.62911 9.39114 8.62151C9.39494 8.61772 9.40253 8.61392 9.40633 8.61012C9.41013 8.60632 9.41772 8.60253 9.42152 8.59873L16.9747 2.14683V12.362Z" fill="#6B6C6F"/>
                                                </svg>
                                                {email}</a>
                                        </li>
                                    ) : ""}
                                    <li>{licenceNumber}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="contentlist">
                            <div className="thankyou_wrapper">
                                <div className="thankyou_wrapper_ic">
                                    <svg id="Group_42451" data-name="Group 42451" xmlns="http://www.w3.org/2000/svg" width="39.835" height="39.835" viewBox="0 0 39.835 39.835">
                                    <circle id="Ellipse_135" data-name="Ellipse 135" cx="19.918" cy="19.918" r="19.918" fill="#00d56d"/>
                                    <path id="Check" d="M110.895,2642.323l-6.618-6.664a.94.94,0,0,1,0-1.332l1.337-1.334a.952.952,0,0,1,1.337,0l4.614,4.666,10.284-10.385a.952.952,0,0,1,1.337,0l1.337,1.334a.938.938,0,0,1,0,1.33l-12.291,12.385a.947.947,0,0,1-1.337,0" transform="translate(-94.205 -2615.093)" fill="#fff"/>
                                    </svg>
                                </div>
                                <div className="thankyou_wrapper_content">
                                    <h4>Great! Your request has been submitted<br></br> successfully</h4>
                                    <p>Thank you for your submission. You will be contacted soon</p>
                                </div>
                            </div>
                            <div className="btns">
                                <Button
                                    className='bluebtn'
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                    }
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    data-cy="signup-input-Next-step-two"
                                    onClick={this.proceedButtonHandler}
                                        // onClick={() => {
                                        //     this.state.serviceProviders?.data?.length > 0 ? History.push('/signUp/preApproval/next') : this.props.history.push('/dashboard')
                                        // }}
                                    >
                                        Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='stepwrapper'>Step 3 of 4</p>
            </div>
            
               
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeVideo);
