import React, { Component } from 'react';
import './HomeProjectCard.scss';
import homeImprovement from '../../Assets/images/HomeValuation/BannerSeeYourOptions.svg';
import Button from '../atoms/Button';
import AnalyticsService from '../../pages/analytics-service';

type initialProps = {
    width?: any;
    height?: any;
    analyticsData?:any;
    finacingOptionsUrl?:string;
};

type initialState = {};

class HomeProjectCard extends Component<initialProps, initialState> {
    state: initialState = {};
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        AnalyticsService.cardViewed({
            ...this.props.analyticsData, 
            cardTitle: "Get More Done Faster With Financing",
            category_name: "Financing Card Viewed"
         })
    }

    handleSeeOptionsClicked = () => {
        window.open(this.props.finacingOptionsUrl, '_blank');
        AnalyticsService.buttonClicked({ 
            ...this.props.analyticsData,
            category_name: 'Financing Card See Your Options Button Clicked',
            card: true,
            card_title: 'Get More Done Faster With Financing',
            button_copy: 'See Your Options'
         });
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        return (
            <div
                className="main-div-home-project"
                // style={{
                //     width: this.props.width ? this.props.width : 'auto',
                //     height: this.props.height ? this.props.height : 'auto',
                // }}
            >
                <div className="main--head-div-home-project">
                    Get More Done
                    <span className="main--headColor-div-home-project"> Faster With Financing</span>{' '}
                </div>
                <div className="main--button-div-home-project">
                    <Button
                        backgroundColor={'#fff'}
                        width="143px"
                        height="38px"
                        color={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                        borderRadius={'5px'}
                        fontSize="14px"
                        onClick={this.handleSeeOptionsClicked}
                    >
                        See Your Options
                    </Button>
                </div>
                <div className="main--image-div-home-project">
                    <img src={homeImprovement} alt=""/>
                </div>
            </div>
        );
    }
}
export default HomeProjectCard;
