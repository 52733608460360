import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41.102}
      height={37.94}
      viewBox="0 0 41.102 37.94"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupWarrenty_prefix__a{fill:#fff}.signupWarrenty_prefix__b{fill:${slug.color_shade3}}.signupWarrenty_prefix__c{fill:${slug.color_shade1}}`
            : ".signupWarrenty_prefix__a{fill:#fff}.signupWarrenty_prefix__b{fill:#5b7088}.signupWarrenty_prefix__c{fill:#273e59}"
          }
        </style>
      </defs>
      <rect
        className="signupWarrenty_prefix__a"
        width={39.521}
        height={22.132}
        rx={3}
        transform="translate(.79 .79)"
      />
      <path
        className="signupWarrenty_prefix__a"
        d="M40.311 5.533v11.065H.79V5.533a4.5 4.5 0 01.632-2.355 2.214 2.214 0 01.158-.253A4.708 4.708 0 015.533.79h30.035a4.756 4.756 0 014.743 4.743zM5.533 22.922A4.748 4.748 0 01.79 18.18v-1.581h7.9v6.323z"
      />
      <path className="signupWarrenty_prefix__b" d="M8.695 16.599h23.713v3.162H8.695z" />
      <path className="signupWarrenty_prefix__b" d="M8.695 19.76h23.713v3.162H8.695z" />
      <path
        className="signupWarrenty_prefix__c"
        d="M35.569 0H5.533A5.539 5.539 0 000 5.533V18.18a5.539 5.539 0 005.533 5.533h30.036A5.539 5.539 0 0041.1 18.18V5.533A5.539 5.539 0 0035.569 0zM5.533 1.581h30.036a3.957 3.957 0 013.952 3.952v10.275H1.581V5.533a3.958 3.958 0 013.952-3.952zM31.617 18.97H9.485v-1.581h22.132zm-30.036-.79v-.79H7.9v4.743H5.533a3.958 3.958 0 01-3.952-3.953zm7.9 3.952v-1.581h22.136v1.581zm26.084 0H33.2v-4.743h6.323v.79a3.957 3.957 0 01-3.954 3.953z"
      />
      <path
        className="signupWarrenty_prefix__c"
        d="M35.568 14.228h1.581a.79.79 0 100-1.581h-1.581a.79.79 0 000 1.581zM19.634 26.083a.79.79 0 10-1.581 0 2 2 0 01-.515 1.38 3.677 3.677 0 000 4.611 2.116 2.116 0 010 2.768 3.546 3.546 0 00-.813 2.306.79.79 0 001.581 0 2 2 0 01.515-1.383 3.682 3.682 0 000-4.614 2.11 2.11 0 010-2.763 3.545 3.545 0 00.813-2.305zM24.377 26.083a.79.79 0 10-1.581 0 2.012 2.012 0 01-.514 1.38 3.675 3.675 0 000 4.611 2.116 2.116 0 010 2.768 3.546 3.546 0 00-.813 2.306.79.79 0 001.581 0 2.007 2.007 0 01.515-1.383 3.682 3.682 0 000-4.614 2.112 2.112 0 010-2.763 3.536 3.536 0 00.812-2.305zM29.119 26.083a.79.79 0 10-1.581 0 2.012 2.012 0 01-.514 1.38 3.675 3.675 0 000 4.611 2.116 2.116 0 010 2.768 3.546 3.546 0 00-.813 2.306.79.79 0 001.581 0 2.007 2.007 0 01.515-1.383 3.682 3.682 0 000-4.614 2.112 2.112 0 010-2.763 3.536 3.536 0 00.812-2.305zM14.892 26.083a.79.79 0 10-1.581 0 2 2 0 01-.515 1.38 3.677 3.677 0 000 4.611 2.116 2.116 0 010 2.768 3.546 3.546 0 00-.813 2.306.79.79 0 001.581 0 2 2 0 01.515-1.383 3.682 3.682 0 000-4.614 2.11 2.11 0 010-2.763 3.545 3.545 0 00.813-2.305z"
      />
    </svg>
  )
}

export default SvgComponent
