import React, { Suspense, Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/Routes';
// import Loader from './components/Loader/Loader';
import history from './routes/History';
import Navbar from './components/Navbar/Navbar';
// import NavbarLanding from './components/Navbar/NavbarLanding';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from './store/common/actions';
import * as Action2 from './store/login/signup/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type initialProps = { commonAction?: any; commonState?: any; signupState?: any; signupAction?: any };
const domain = window.location.hostname;
const name = domain.split('.');

type initialState = {
    isDataArrived: boolean;
};
// let slug: any = localStorage.getItem('slug');
// slug = JSON.parse(slug);
// localStorage.getItem('isDouglasElliman') ? true : false;

export class App extends Component<initialProps, initialState> {
    state: initialState = {
        isDataArrived: true,
    };
    componentDidMount() {
        this.props.commonAction.realtor({ slug: name[0] });

        if (window.location.pathname.includes('oboardprocess/ghost-login/')) {
            // this.setState({
            //     hashCode:window.location.pathname.split('oboardprocess/ghost-login/')[1]
            // })
            if (window.location.pathname.split('oboardprocess/ghost-login/')[1]) {
                const payload = {
                    user_id: window.location.pathname.split('oboardprocess/ghost-login/')[1],
                };
                this.props.signupAction.ghostLogin(payload);
            } else {
                history.push('/signUp');
            }
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (
            window.location.hostname.split('.')[0] === 'douglas-elliman' ||
            window.location.hostname.split('.')[0] === 'douglas-elliman-demo' ||
            (slug && slug.brokerage_slug === 'douglas-elliman') ||
            (slug && slug.brokerage_slug === 'douglas-elliman-demo')
        ) {
            localStorage.setItem('isDouglasElliman', 'True');
            // this.props.commonAction.realtor({ slug: 'douglas-elliman' });
        }
        if (window.location.hostname.split('.')[0] === 'sibcycline' || (slug && slug.brokerage_slug === 'sibcycline')) {
            localStorage.setItem('isSibcycline', 'True');
            // this.props.commonAction.realtor({ slug: 'douglas-elliman' });
        }
    }

    render() {
        if (
            !localStorage.getItem('token') &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/signUp' &&
            window.location.pathname !== '/signUp/welcome' &&
            window.location.pathname !== '/signUp/HomeOwnerWelcome' &&
            window.location.pathname !== '/signUp/preapproval' &&
            window.location.pathname !== '/signUp/getPreApproval' &&
            window.location.pathname !== '/signUp/haveAPreApproval' &&
            window.location.pathname !== '/signUp/preApproval/next' &&
            window.location.pathname !== '/signUp/next' &&
            window.location.pathname !== '/login' &&
            !window.location.pathname.includes('/setpassword') &&
            !window.location.pathname.includes('/resetpassword') &&
            !window.location.pathname.includes('/dashboard/internet') &&
            !window.location.pathname.includes('/dashboard/movers') &&
            !window.location.pathname.includes('/dashboard/storage') &&
            !window.location.pathname.includes('/oboardprocess/ghost-login') &&
            !window.location.pathname.includes('/dashboard/autoease') &&
            !window.location.pathname.includes('/dashboard/address') &&
            !window.location.pathname.includes('/dashboard/checklist') &&
            !window.location.pathname.includes('/dashboard/ourvendors') &&
            !window.location.pathname.includes('/dashboard/utilities') &&
            !window.location.pathname.includes('/dashboard/home-insurance') &&
            !window.location.pathname.includes('/dashboard/driverlicense') &&
            !window.location.pathname.includes('/dashboard/home-security') &&
            window.location.pathname !== '/internetLanding' &&
            window.location.pathname !== '/insuranceLandingPage' &&
            window.location.pathname !== '/premovesignup' &&
            window.location.pathname !== '/dashboard/home-warranty' &&
            // window.location.pathname !== '/servicerequirement' &&
            // window.location.pathname !== '/laCarte' &&
            // window.location.pathname !== '/scheduleacall' &&
            window.location.pathname !== '/securityLanding' &&
            window.location.pathname.includes('/HomeWaranty/HomeWarantyMain') &&
            name[0] !== 'internet' &&
            name[0] !== 'insurance' &&
            name[0] !== 'security' &&
            name[0] !== 'internet-demo' &&
            name[0] !== 'insurance-demo' &&
            name[0] !== 'security-demo' &&
            window.location.pathname !== '/standalonepage' &&
            window.location.pathname !== '/standalonepagerecommendations' &&
            window.location.pathname !== '/standalonefinal'
        ) {
            history.push('/signUp');
        }
        let showNav = false;
        // let showNavLanding: boolean = false;

        // let showNav: boolean = true;
        if (
            (history.location.pathname === '/' &&
                name[0] !== 'internet' &&
                name[0] !== 'insurance' &&
                name[0] !== 'security' &&
                name[0] !== 'internet-demo' &&
                name[0] !== 'insurance-demo' &&
                name[0] !== 'security-demo') ||
            history.location.pathname == '/dashboard' ||
            history.location.pathname.includes('/oboardprocess/ghost-login') ||
            history.location.pathname === '/signUp' ||
            history.location.pathname === '/signUp/welcome' ||
            history.location.pathname === '/signUp/HomeOwnerWelcome' ||
            history.location.pathname === '/signUp/preapproval' ||
            history.location.pathname === '/signUp/getPreApproval' ||
            history.location.pathname === '/signUp/haveAPreApproval' ||
            history.location.pathname === '/signUp/preApproval/next' ||
            window.location.pathname === '/login' ||
            history.location.pathname === '/signUp/' ||
            history.location.pathname === '/signUp/next' ||
            history.location.pathname === '/dashboard/mortgage' ||
            history.location.pathname.includes('/dashboard/mortgage/') ||
            history.location.pathname === '/dashboard/title' ||
            history.location.pathname.includes('/dashboard/title/') ||
            history.location.pathname === '/dashboard/home-insurance' ||
            history.location.pathname.includes('/dashboard/home-insurance/') ||
            history.location.pathname === '/dashboard/home-insurance/steps' ||
            history.location.pathname === '/dashboard/home-insurance/plans' ||
            history.location.pathname === '/dashboard/home-insurance/order' ||
            history.location.pathname === '/dashboard/home-insurance/summary' ||
            history.location.pathname === '/dashboard/movers' ||
            history.location.pathname.includes('/dashboard/movers/') ||
            history.location.pathname === '/dashboard/movers/steps' ||
            history.location.pathname === '/dashboard/movers/recommended' ||
            history.location.pathname === '/dashboard/movers/quote' ||
            history.location.pathname === '/dashboard/storage' ||
            history.location.pathname.includes('/dashboard/storage/') ||
            history.location.pathname === '/dashboard/storage/:id' ||
            history.location.pathname === '/dashboard/movers/reserve_order_place' ||
            history.location.pathname === '/dashboard/movers/thankyou' ||
            history.location.pathname === '/dashboard/movers/reserve_storage_thankyou' ||
            history.location.pathname === '/dashboard/utilities' ||
            history.location.pathname.includes('/dashboard/utilities/') ||
            history.location.pathname === '/dashboard/utilities/old' ||
            history.location.pathname === '/dashboard/address' ||
            history.location.pathname.includes('/dashboard/address/') ||
            history.location.pathname === '/dashboard/internet' ||
            history.location.pathname.includes('/dashboard/internet/') ||
            history.location.pathname === '/dashboard/internet/summary' ||
            history.location.pathname === '/dashboard/internet/recommendations' ||
            history.location.pathname === '/dashboard/internet/steps' ||
            history.location.pathname === '/dashboard/internet/order' ||
            history.location.pathname === '/dashboard/internet/location' ||
            history.location.pathname === '/dashboard/driverlicense' ||
            history.location.pathname.includes('/dashboard/driverlicense/') ||
            history.location.pathname === '/dashboard/driverlicense/vehicleregistration' ||
            history.location.pathname === '/dashboard/home-security' ||
            history.location.pathname.includes('/dashboard/home-security/') ||
            history.location.pathname === '/dashboard/home-security/steps' ||
            history.location.pathname === '/dashboard/home-security/recommendations' ||
            history.location.pathname === '/dashboard/home-security/order' ||
            history.location.pathname === '/dashboard/home-security/summary' ||
            history.location.pathname === '/dashboard/checklist' ||
            history.location.pathname.includes('/dashboard/checklist/') ||
            history.location.pathname === '/dashboard/checklist/overdue' ||
            history.location.pathname === '/dashboard/checklist/completed' ||
            // history.location.pathname === '/dashboard/warranty' ||
            // history.location.pathname.includes('/dashboard/warranty/') ||
            history.location.pathname.includes('/dashboard/ourvendors') ||
            history.location.pathname.includes('/dashboard/ourvendors/') ||
            history.location.pathname.includes('/dashboard/autoease') ||
            history.location.pathname.match('/dashboard/*') ||
            history.location.pathname.includes('/setpassword') ||
            history.location.pathname.includes('/resetpassword') ||
            history.location.pathname.includes('/dashboard/savings-flow') ||
            history.location.pathname.includes('/dashboard/savings-flow/confirmSaving') ||
            history.location.pathname === '/servicerequirement' ||
            history.location.pathname === '/laCarte' ||
            history.location.pathname === '/scheduleacall' ||
            window.location.pathname === '/premovesignup' ||
            window.location.pathname === '/standalonepage' ||
            window.location.pathname === '/standalonepagerecommendations' ||
            window.location.pathname === '/standalonefinal' ||
            window.location.pathname === '/dashboard/home-warranty' ||
            window.location.pathname === '/dashboard/home-warranty/steps' ||
            window.location.pathname.includes('/dashboard/home-warranty/steps') ||
            window.location.pathname === '/dashboard/home-warranty/step4' ||
            window.location.pathname.includes('/dashboard/home-warranty/step4') ||
            window.location.pathname === '/dashboard/home-warranty/thanku' ||
            window.location.pathname.includes('/dashboard/home-warranty/thanku') ||
            window.location.pathname.includes('/dashboard/home-warranty') ||
            window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
            window.location.pathname === '/dashboard/subscription_details/home_protection'
        ) {
            showNav = true;
        }

        if (window.location.pathname === '/dashboard/my-documents/preview') {
            showNav = false;
        }

        return (
            <Suspense
                fallback={
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                }
            >
                {/* douglasEllimenSite className created globally for changing css/scss file */}
                <div
                    className={`AppMainPage ${localStorage.getItem('isDouglasElliman') === 'True' &&
                        'douglasEllimenSite'}`}
                    style={{
                        paddingBottom:
                            history.location.pathname === '/' ||
                            history.location.pathname === '/signUp' ||
                            history.location.pathname === '/signUp/welcome' ||
                            history.location.pathname === '/signUp/HomeOwnerWelcome' ||
                            history.location.pathname === '/signUp/preapproval' ||
                            history.location.pathname === '/signUp/getPreApproval' ||
                            history.location.pathname === '/signUp/haveAPreApproval' ||
                            history.location.pathname === '/signUp/preApproval/next' ||
                            history.location.pathname === '/signUp/next' ||
                            history.location.pathname === '/login' ||
                            history.location.pathname.includes('/setpassword') ||
                            history.location.pathname.includes('/dashboard/home-warranty') ||
                            history.location.pathname.includes('/resetpassword')
                                ? '0px'
                                : '0px',
                        marginBottom:
                            history.location.pathname === '/' ||
                            history.location.pathname === '/signUp' ||
                            history.location.pathname === '/signUp/welcome' ||
                            history.location.pathname === '/signUp/HomeOwnerWelcome' ||
                            history.location.pathname === '/signUp/preapproval' ||
                            history.location.pathname === '/signUp/getPreApproval' ||
                            history.location.pathname === '/signUp/haveAPreApproval' ||
                            history.location.pathname === '/signUp/preApproval/next' ||
                            history.location.pathname === '/signUp/next' ||
                            history.location.pathname === '/login' ||
                            history.location.pathname.includes('/setpassword') ||
                            history.location.pathname.includes('/resetpassword') ||
                            history.location.pathname.includes('/bad-request') ||
                            history.location.pathname.includes('/server-error') ||
                            history.location.pathname.includes('/dashboard/internet') ||
                            history.location.pathname.includes('/dashboard/home-warranty') ||
                            history.location.pathname.includes('/dashboard/movers') ||
                            history.location.pathname.includes('/dashboard/home-security') ||
                            history.location.pathname.includes('/*') ||
                            (localStorage.getItem('isDouglasElliman') &&
                                localStorage.getItem('isDouglasElliman') == 'True') ||
                            history.location.pathname.includes('/dashboard/nextmove') ||
                            history.location.pathname.includes('/*') ||
                            history.location.pathname.includes('/dashboard/savings-flow') ||
                            history.location.pathname.includes('/dashboardhomeowner') ||
                            history.location.pathname.includes('/dashboard/my-documents/preview') ||
                            history.location.pathname.includes('/dashboard/my-documents') ||
                            history.location.pathname.includes('/dashboard/savings-flow/awesomeSaving') ||
                            history.location.pathname.includes('/dashboard/savings-flow/confirmSaving') ||
                            window.location.pathname === '/dashboard/subscription_details/home_utilities' ||
                            window.location.pathname === '/dashboard/subscription_details/home_protection'
                                ? '0px'
                                : '230px',
                    }}
                >
                    <ReactNotifications />
                    <Router>
                        {showNav ? <Navbar data-cy="navbar" history={history} /> : null}
                        {/* {showNavLanding ? <NavbarLanding   /> : null} */}
                        <Routes />
                    </Router>
                </div>
            </Suspense>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        signupAction: bindActionCreators(Action2, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
