import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import '../../components/dashboardComponents/dashboardCss/moveFrommoveToCard2.scss';
// import moveTruck from '../../Assets/images/DashBoard/moveTruck.svg';
// import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import Bed from '../../../src/Assets/images/HomeValuation/double-bed.svg';
import Square from '../../../src/Assets/images/HomeValuation/try-square.svg';
import Bath from '../../../src/Assets/images/HomeValuation/bathtub.svg';
import Stairs from '../../Assets/images/HomeValuation/stairs.svg';
import moment, { isMoment } from 'moment';
import './dashboardHomeOwnerTop.scss';
import AddLoanDetails from '../../components/homeOwnerDashboardComponents/AddLoanDetails';
import AnalyticsService from '../analytics-service';
import { estimatedValueTooltipText, sinceDataTooltipText } from '../../utils/constants';
import trending_up from '../../Assets/images/HomeValuation/trending_up_black.svg';
import trending_down from '../../Assets/images/HomeValuation/trending_down_black_24dp.svg';
import LeftBlueArrow from '../../Assets/images/left_arrow_blue.png';
import history from '../../routes/History';

interface initialProps {
    history?: any;
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    commonState?: any;
    commonAction?: any;
    toAddress?: string;
    toPin?: string;
    fromAddress?: string;
    fromPin?: string;
    movingDate?: any;
    property_valuations: any[];
    homeDetails: any;
    isFetchingHomeValuation: boolean;
    toggleMortageModal: any;
    averageValuations: any;
    isLoading?: boolean;
    loanDetails: any;
    analyticsData: any;
}

type initialState = {
    fromPin?: string;
    fromAddress?: string;
    street?: any;
    clicked1?: boolean;
    isDataArrived: boolean;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    moveId?: string;
    property_valuations?: any[];
    homeDetails: any;
    isFetchingHomeValuation: boolean;
    isLoading: boolean;
};
class DashboardHomeOwnerTopCard extends Component<initialProps, initialState> {
    state: initialState = {
        fromPin: this.props.fromPin ? this.props.fromPin : '',
        fromAddress: this.props.fromAddress ? this.props.fromAddress : '',
        clicked1: false,
        street: this.props.toAddress,
        isDataArrived: true,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        moveId: '',
        property_valuations: [],
        homeDetails: null,
        isFetchingHomeValuation: true,
        isLoading: false
    };

    componentDidMount() {
        this.setState({
            isLoading: true
        })

        AnalyticsService.cardViewed({
            ...this.props.analyticsData,
             cardTitle: 'Home Owner Dashboard Top Card',
             category_name: "Home Owner Dashboard Top Card Viewed"
         });
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                moveId: cur.move[0].id,
                fromAddress: cur.move[0].source && cur.move[0].source.lookup_name,
                fromPin: cur.move[0].source && cur.move[0].source.zip_code,
                street: `${cur.move[0].source_full_address}`,
            });
        }
        
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            let moveData = nextProps.commonState.move[0];

            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address
            ) {
                currentState.moveId = moveData.id;
                currentState.fromAddress = moveData.source && moveData.source.lookup_name;
                currentState.fromPin = moveData.source && moveData.source.zip_code;
                currentState.brokerage_name =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.display_name;
                currentState.brokerage_id =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.brokerage_link;
                currentState.agent_id =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.id;
                currentState.agent_name =
                    nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor &&
                    nextProps.commonState.move[0].realtor.contact_name;
                currentState.street = `${moveData.source_full_address}`;
                currentState.property_valuations = nextProps.property_valuations;
                currentState.homeDetails = nextProps.homeDetails;
                currentState.isLoading = false;
                currentState.isFetchingHomeValuation = nextProps.isFetchingHomeValuation;
            }
        }
        return currentState;
    }

    handleClickAway = () => {
        this.setState({
            clicked1: false,
        });
    };

    handleClickLearnMore = () => {
        AnalyticsService.buttonClicked({
            ...this.props.analyticsData,
            card: true,
            card_title: 'Home Owner Dashboard Top Card',
            category_name: `Learn More Link Button Clicked`,
            button_copy: 'Learn More',
        });

        history?.push('/dashboard/homeValuation#home-value-estimation');
    };

    formatCurrency = ({ locale = 'en-US', value = 0 }) => new Intl.NumberFormat(locale).format(Math.round(value));

    render() {
        // Read values
        const loanDetails = this.props?.loanDetails;
        const valuation_ob_len = this.state?.property_valuations && this.state?.property_valuations.length;
        const latest_valuation_object: any =
            valuation_ob_len &&
            this.state?.property_valuations &&
            this.state?.property_valuations[valuation_ob_len - 1];
        // const current_value = latest_valuation_object?.current_value;
        const forecast_standard_deviation = latest_valuation_object?.forecast_standard_deviation;
        const average_value = this.props?.averageValuations?.average_value;
        const last_record_date = this.props?.averageValuations?.last_record_date;
        const percentage_difference = this.props?.averageValuations?.percentage_difference;
        const created_date = last_record_date ? moment(last_record_date, "YYYY-MM-DD"): '';
        const showTrend = average_value && created_date && isMoment(created_date) && percentage_difference != 0;
        
        const showPurchaseInfo = !this.state.isLoading && this.state.homeDetails && (Object.keys(this.state.homeDetails).length === 0 || !this.props?.averageValuations) &&  this.props?.loanDetails?.purchase_price > 0;

        return (
            <div className="dashboardTopInfoCardContainer">
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div className="cardCss" onClick={this.props.onClick}>
                        <div className="cardContent">
                            <div className="card-section-1">
                                <div style={{ display: 'flex', margin: '10px' }}>
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <div className="section-1-heading">
                                            Your Home Address
                                                <div>
                                                    <div
                                                        className="section-1-content address-small-title"
                                                        style={{
                                                            fontSize: '16px',
                                                            color: 'rgba(51, 51, 51, 1)',
                                                            fontFamily: "'Roboto', sans-serif ",
                                                        }}
                                                    >
                                                        {this.state.street}
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>

                <div className="cardCss">
                    <div
                        className="cardContent"
                        // style={{
                        //     display: 'flex',
                        //     flexDirection: 'row',
                        // }}
                    >
                        <div
                            className={`card-section-1 ${!this.state.isFetchingHomeValuation &&
                                !this.state.property_valuations?.length &&
                                'nodata-card-section'}`}
                        >
                            <div style={{ margin: '10px' }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    // onClick={() => {
                                    //     this.setState({ clicked1: true });
                                    // }}
                                >
                                    {average_value ? (
                                        <div className="section-1-heading">
                                            <span className="topCardInfoLabel">Estimated Home Value</span>
                                            <ReactTooltip
                                                place="right"
                                                effect='solid'
                                                border={false}
                                                multiline={true}
                                                className="tooltipCover"
                                                id={'title-tooltip'}
                                            >
                                                {estimatedValueTooltipText}
                                            </ReactTooltip>
                                            <InfoIcon
                                                fontSize="inherit"
                                                data-tip
                                                data-for={'title-tooltip'}
                                                style={{ height: '16px', width: '16px', marginLeft: '5px' }}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {!this.state.isFetchingHomeValuation ? (
                                        <div
                                            className="main-home-line"
                                            style={{
                                                display: 'flex',
                                                // justifyContent: "center",
                                                alignItems: 'center',
                                            }}
                                        >
                                            {average_value && !this.props.isLoading ? <div className="section-4-heading" style={{ marginTop: '5px' }}>
                                                $
                                                <div className="section-4-subheading">
                                                    {this.formatCurrency({ value: average_value })}
                                                </div>
                                            </div> : (this.state.isFetchingHomeValuation ? <Skeleton width={120} height={40} />: '')}
                                            {showTrend && created_date ? (
                                                <>
                                                    <div style={{ margin: '4px 0px 0px 8px' }}>
                                                        {percentage_difference > 0 ? (
                                                            <>
                                                                <img src={trending_up} alt="" />{" "}
                                                                <span className="dateInfoCover">
                                                                    <span className="growpercent">
                                                                        { percentage_difference }%
                                                                    </span>{' '}
                                                                    since {created_date.format('MMM DD YYYY')}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img src={trending_down} alt="" />{" "}
                                                                <span className="dateInfoCover">
                                                                    <span className="lowpercent">
                                                                        { Math.abs(percentage_difference) }%
                                                                    </span>{' '}
                                                                    since {created_date.format('MMM DD YYYY')}
                                                                </span>
                                                            </>
                                                        )}
                                                        <div
                                                            className="section-5-subheading"
                                                            style={{ color: '#6B6C6F', display: 'inline-block' }}
                                                            data-tip
                                                            data-for={'date-tooltip'}
                                                        >
                                                            <ReactTooltip
                                                            place="top"
                                                            border={false}
                                                            effect="solid"
                                                            multiline={true}
                                                            className="tooltipCover"
                                                            id={'date-tooltip'}
                                                        >{sinceDataTooltipText}</ReactTooltip>{' '}
                                                        <InfoIcon
                                                            fontSize="inherit"
                                                            data-tip
                                                            data-for={'date-tooltip'}
                                                            style={{
                                                                height: '16px',
                                                                width: '16px',
                                                            }}
                                                        />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : !(last_record_date && forecast_standard_deviation && created_date) ? (
                                        ''
                                    ) : (
                                        <Skeleton height="40px" width="250px" />
                                    )}

                                    {!this.state.isFetchingHomeValuation && average_value ? (
                                        <div className="row">
                                            <div className="homeServicesContainer">
                                                {this.state.homeDetails ? (
                                                    <>
                                                        {this.state.homeDetails?.beds ? (
                                                            <div className="col-3 basicInfo">
                                                                <img src={Bed} style={{ marginRight: '10px' }} alt="" />
                                                                <span className="bold">
                                                                    {this.state.homeDetails?.beds || 0}
                                                                </span>{' '}
                                                                bed
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {this.state.homeDetails?.baths ? (
                                                            <div className="col-3 basicInfo">
                                                                <img src={Bath} style={{ marginRight: '10px' }} alt="" />
                                                                <span className="bold">
                                                                    {this.state.homeDetails?.baths || 0}
                                                                </span>
                                                                <span> bath</span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {this.state.homeDetails?.floors ? (
                                                            <div className="col-3 basicInfo">
                                                                <img src={Stairs} style={{ marginRight: '10px' }} alt="" />
                                                                <span className="bold">
                                                                    {this.state.homeDetails?.floors || 0}
                                                                </span>
                                                                <span> floor</span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {this.state.homeDetails?.areaInSqft ? (
                                                            <div className="col-3 basicInfo">
                                                                <img src={Square} style={{ marginRight: '10px' }} alt="" />
                                                                <span className="bold">
                                                                    {this.formatCurrency({
                                                                        value: this.state.homeDetails?.areaInSqft || 0,
                                                                    })}
                                                                </span>{' '}
                                                                <span> sqft</span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {this.state.isFetchingHomeValuation && (
                                                <Skeleton height="40px" width="250px" />
                                            )}
                                        </>
                                    )}

                                    {this.state.homeDetails && average_value ? (
                                        <div className="moreLinkCover"
                                            onClick={this.handleClickLearnMore}
                                        >
                                            <span className="moreLinkLabel">Learn More</span>
                                            <img width={'16px'} src={LeftBlueArrow} style={{ paddingLeft: '5px' }} alt="" />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {!this.state.isFetchingHomeValuation && (Object.values(this.state?.homeDetails).length === 0 || this.state?.homeDetails.beds == 0) && (this.props?.loanDetails?.message || this.props?.loanDetails?.purchase_price == 0)  && !average_value && (
                                        <AddLoanDetails
                                            handleClickAdd={this.props.toggleMortageModal}
                                            title="Tell us a little more about your home."
                                            description="We'll help you find opportunities to save money and increase the value of your home."
                                            btnTxt="Update Home details"
                                            fromTopCard
                                        /> 
                                    )}

                                    {loanDetails && this.state?.homeDetails && (
                                     <>
                                       { showPurchaseInfo && 
                                       
                                       <div className='purchaseInfoCover fromDashboard'>
                                            <h4 className='purchaseTitle'>Purchase Details</h4>
                                             <div className='d-flex align-items-end'>
                                                 <h1 className='price'>{this.formatCurrency({ value: loanDetails.purchase_price })}</h1>
                                                 <span className='purchaseDate'>Purchased on {loanDetails?.start_date}</span>
                                              </div>

                                              <div className="moreLinkCover"
                                              onClick={this.handleClickLearnMore}
                                        >
                                            <span className="moreLinkLabel">Learn More</span>
                                            <img width={'16px'} src={LeftBlueArrow} style={{ paddingLeft: '5px' }} alt="" />
                                        </div>
                                   
                                    </div>
                                       }
                                       </>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHomeOwnerTopCard);
