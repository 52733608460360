import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/atoms/Button';
import CustomizedInput from '../components/atoms/CustomizedInput';
import Card from '../components/atoms/Card';
import test from '../Assets/images/test.png';
import test_avatar from '../Assets/images/test_avatar.png';
import CarouselCard from '../components/atoms/CarouselCard';
import SimpleAccordion from '../components/atoms/FAQ';
import { getPendingSelector, getTodosSelector, getErrorSelector } from '../store/todo/selectors';
import { fetchTodoRequest } from '../store/todo/actions';
// import { LandingPageInternet } from './InternetLanding/InternetlandingPage';
import LongCard from '../components/atoms/LongCard';
import PlanCard from '../components/atoms/PlanCardResponsive';
import InsurancePlanCard from '../components/HomeInsurance/insurancePlanCard';
import CallToQuotesCard from '../components/HomeInsurance/callToQuotesCard';
import XfinityIcon from '../Assets/images/xfinity.png';
import AwardIcon from '../Assets/images/award.svg';
import WifiIcon from '../Assets/images/wifiblueIcon.svg';
import PhoneIcon from '../Assets/images/phoneIcon.svg';
import TVIcon from '../Assets/images/TVIcon.svg';
// import Navbar from '../components/Navbar/Navbar'
// import callSvg from '../Assets/images/ICImages/Phone';
// import CheckboxCard from '../components/atoms/CheckboxCards';
// import MovingStepperCard from '../components/atoms/MovingStepperCard';
import Modal from '../components/atoms/Modal';
import SelectInput from '../components/atoms/SelectInput';
import UseCoordinates from '../components/atoms/coordinates';
import '../styles/test.scss';
// import LastPage from './InternetAndCable/LastPage';
import ThankYouCard from '../components/HomeInsurance/ThankYouCard';
// import PolicyDetailsCard from '../components/HomeInsurance/PolicyDetailsCard';
// import logo from '../Assets/images/home-insurance.svg';
import QuoteDetailsCard from '../components/HomeInsurance/QuoteDetailsCard';
import PropertyDetailsCard from '../components/HomeInsurance/PropertyDetailsCard';
import HomeOwnerDetailsCard from '../components/HomeInsurance/HomeOwnerDetailsCard';
// import LongCheckboxCard from '../components/atoms/LongCheckboxCard';
import companyLogo from '../Assets/images/MovingImages/NorthAmerican.png';
import RatingIcon from '../Assets/images/MovingImages/rating.svg';
import RatingIconBlue from '../Assets/images/MovingImages/ratingBlue.svg';
import BusinessIcon from '../Assets/images/MovingImages/business_center.svg';
import callIcon from '../Assets/images/MovingImages/call.svg';
import locationIcon from '../Assets/images/MovingImages/location-icon.svg';
import MovingPlanCard from '../components/atoms/movingPlanCard';
import MovingPlanCardMobile from '../components/atoms/movingPlanCardMobile';
import movingLogo from '../Assets/images/MovingImages/moving-logo.png';
import GetAQuoteCard from '../components/atoms/GetAQuoteCard';
import addArrowIcon from '../Assets/images/MovingImages/add-arrow-icon.svg';
import DetailCard from '../components/atoms/DetailCard';
// import RecommendedPage from '../pages/Moving/MovingRecommended';
import '../pages/Moving/Moving.scss';
import MovingNextCard from '../components/atoms/MovingNextCard';
import MovingCar from '../Assets/images/Moving/MovingCar.svg';
import BestDealsCard from '../components/dashboardComponents/bestDealsCard';
import MovingChecklistCompletedCard from '../components/dashboardComponents/movingChecklistCompletedCard';
import Img2 from '../Assets/images/DashBoard/Group 33415.svg';
import Img1 from '../Assets/images/DashBoard/Image 33.png';
import completedCardImage from '../Assets/images/DashBoard/completedcardImg.svg';
import GetStartedMovingServiceCard from '../components/dashboardComponents/getStartedMovingServiceCard';

import DashBoardWelcomeImage from '../Assets/images/DashBoard/DashBoardWelcome.svg';
import StepRight from '../Assets/images/DashBoard/StepRight.svg';
import Blog1 from '../Assets/images/DashBoard/Blog1.png';
import Blog2 from '../Assets/images/DashBoard/Blog2.png';
import DashBoardWelcome from '../components/dashboardComponents/DashBoardWelcome';
import CallWithMikeCard from '../components/dashboardComponents/CallWithMikeCard';
import Mike from '../Assets/images/DashBoard/Mike.png';
import DashBoardstepRight from '../components/dashboardComponents/DashBoardStepRight';
import BlogCardTopImage from '../components/dashboardComponents/BlogCardTopImage';
import BlogCardRightImage from '../components/dashboardComponents/BlogCardRightImage';
import MoveFromMoveToCard from '../components/dashboardComponents/MoveFromMoveToCard';
import MoveFromMoveToCard2 from '../components/dashboardComponents/MoveFromMoveToCard2';

import TrustedProsImageHeadingCard from '../components/atoms/TrustedProsImageHeadingCard';
import TrustedProsIconCard from '../components/atoms/trustedProsIconCard';
import Inspector from '../Assets/images/TrustedPros/Inspector.png';
import leaking from '../Assets/images/TrustedPros/leaking.svg';
import TrustedProsCard from '../components/atoms/TrustedProsCard';
import FiltersTrusted from './TrustedPros/FiltersTrusted';
import Fire from '../Assets/images/HomeSecurity/bonfire';
import BreakIns from '../Assets/images/HomeSecurity/breakIns';
import Pipes from '../Assets/images/HomeSecurity/pipes';
import Leaks from '../Assets/images/HomeSecurity/leaks';
import Package from '../Assets/images/HomeSecurity/package';
import Medical from '../Assets/images/HomeSecurity/medical';
import Footage from '../Assets/images/HomeSecurity/footage';
import ThreeDivSignUp from '../pages/login/signUp/ThreeDivSignUp';
import ServicePreferenceCard from '../components/atoms/PreMove/ServicePreferenceCard';
import LaCarteCard from '../components/atoms/PreMove/LaCarteCard';
import DiyMovingCard from '../components/atoms/DiyMovingCard';
import RecommendationCardsAll from '../components/atoms/HomeSecurity/RecommendationCardsAll';
const marginBottom = {
    marginBottom: '50px',
} as const;

const Home: React.FC = () => {
    const dispatch = useDispatch();
    const pending = useSelector(getPendingSelector);
    const todos = useSelector(getTodosSelector);
    const error = useSelector(getErrorSelector);

    const testing = {
        marginBottom: '50px',
        width: '500px',
    } as const;
    const scheduleCall = () => {};
    const buyNow = () => {};

    useEffect(() => {
        dispatch(fetchTodoRequest());
    }, []);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div style={{ marginTop: '0px', padding: '15px' }}>
            <div
                style={{
                    margin: '100px 0',
                }}
            >
                <RecommendationCardsAll />
            </div>
            <div style={{ marginBottom: '50px', width: '30%' }}>
                <DiyMovingCard discount="10" title="Budget Truck Rental" rate="4.8" reviews="122" logo={companyLogo} />
            </div>
            <div style={{ marginBottom: '50px' }}>
                <ThreeDivSignUp />
            </div>

            <div>
                <BreakIns />
                <Fire />
                <Pipes />
                <Leaks />
                <Package />
                <Medical />
                <Footage />
            </div>
            <div style={{ width: '20%' }}>
                <GetAQuoteCard vendors={true} cardImage={addArrowIcon} width="100%" features={[]} />
                <GetAQuoteCard
                    vendors={true}
                    cardImage={addArrowIcon}
                    width="100%"
                    selectedMovers={2}
                    totalMover={3}
                    selectedMover={true}
                    logoInitials="SE"
                    features={[
                        {
                            name: 'American Van Lines',
                            icon: companyLogo,
                            locationIcon: locationIcon,
                            locationText: '123 Main Street, Columbus, OH 43212',
                        },
                        {
                            name: 'American Van Lines',
                            icon: companyLogo,
                            locationIcon: locationIcon,
                            locationText: '123 Main Street, Columbus, OH 43212',
                        },
                    ]}
                />
                <FiltersTrusted
                    Date={[
                        { name: 'Tomorrow (16 Jun 2021) ', value: false },
                        { name: 'Within 3 days', value: false },
                        { name: ' Within a week', value: false },
                        { name: ' Within 30 days', value: false },
                    ]}
                    TimeOfDay={[
                        { name: 'Morning (8am - 12pm)', value: false },
                        { name: ' Afternoon (12pm - 5pm)', value: false },
                        { name: 'Evening (5pm - 9.30pm)', value: false },
                        { name: 'I’m flexible', value: false },
                    ]}
                />
            </div>
            <TrustedProsCard
                width="40%"
                cardTitle="Stanley Electrical"
                subHeading="Get additional quotes even if you have home insurance to make sure you’re getting the best rate."
                checked={true}
                handleChange={() => console.log()}
                rate={4.9}
                phoneNumber="321.123.1234"
                locationtext="123 Main Street, Columbus, OH 43212"
                logoInitials="SE"
            />
            <TrustedProsImageHeadingCard icon={Inspector} title="Home Inspector" />
            <div style={{ width: '30%' }}>
                <LaCarteCard heading="Moving Services" subheading="Place an order on all new providers" />
            </div>

            <ServicePreferenceCard
                icon={Inspector}
                points={['Hello', 'Test 1', 'Test2']}
                tag={true}
                width={'30%'}
                title="Test Title"
                favoriteText="1.6K people preferred this service"
            />

            <TrustedProsIconCard icon={leaking} title="Plumbing" />

            <MovingPlanCardMobile
                width="30%"
                // height="300px"
                cardImage={movingLogo}
                checked={true}
                handleChange={() => console.log()}
                label={'Streaming movies abcd wertrr'}
                cardTitle="Air Van northAmerican Van Lines"
                rate={4.9}
                ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                verifiedReview="200 verified reviews"
                phoneIcon={callIcon}
                phoneNumber="321.123.1234"
                locationIcon={locationIcon}
                locationtext="123 Main Street, Columbus, OH 43212"
                yearsIcon={BusinessIcon}
                yearText="24yrs"
                licensedIcon={locationIcon}
                licensedText="Licensed"
                insuredIcon={locationIcon}
                insuredText="Insured"
            />
            <div>
                <div style={{ marginBottom: '20px' }}>
                    <MoveFromMoveToCard2 />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <MoveFromMoveToCard
                        fromPin="92103"
                        toPin="14061"
                        fromAddress="San Diego, CA"
                        toAddress="Old Wye Mills Road, MD"
                        dateNumber="21"
                        date="May 21,Tuesday"
                    />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <BlogCardRightImage
                        icon={Blog2}
                        title="Your First Apartment Checklist — Everything You Need to Buy"
                        subTitle="While you dream up how to decorate your new home,
                  we’re here to bring you the ultimate first apartment checklist."
                        viewLink=""
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <BlogCardTopImage
                        icon={Blog1}
                        title="The Ultimate Home Seller Checklist"
                        subTitle="Stocking up on home essentials before your move will make your life"
                        viewLink=""
                    />
                </div>

                <CallWithMikeCard
                    icon={Mike}
                    title="Schedule A Call With Mike"
                    subTitle="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
                    buttonText="Schedule A Call"
                    buttonColor="#D92F25"
                    buttonBackGroundColor="#fff"
                    buttonShadow="0 0 3px 0 #D92F25"
                    paragraph={
                        <div>
                            <div>About Mike</div>
                            <div>
                                below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
                                Malorum" by Cicero are also reproduced in their exact original form{' '}
                                <a style={{ color: '#0066F5', textDecoration: 'underline' }}>Learn More</a>
                            </div>
                        </div>
                    }
                />

                <div style={{ margin: '20px' }}>
                    <DashBoardstepRight
                        arrowBack
                        icon={StepRight}
                        currentStep="2"
                        totalSteps="4"
                        title="Your Moving Details"
                        subTitle="Here is your moving address information and the date of Move"
                        buttonText="Next"
                        buttonColor="#fff"
                        buttonBackGroundColor="#002E60"
                        buttonShadow="0 0 3px 0 #273E59"
                        buttonText2="Skip"
                        buttonColor2="#002E60"
                        buttonBackGroundColor2="#fff"
                        buttonShadow2="0 0 3px 0 #273E59"
                    />
                </div>
                <DashBoardstepRight
                    icon={StepRight}
                    currentStep="1"
                    totalSteps="4"
                    title="Your Moving Details"
                    subTitle="Here is your moving address information and the date of Move"
                    buttonText="Next"
                    buttonColor="#fff"
                    buttonBackGroundColor="#002E60"
                    buttonShadow="0 0 3px 0 #273E59"
                    buttonText2="Skip"
                    buttonColor2="#002E60"
                    buttonBackGroundColor2="#fff"
                    buttonShadow2="0 0 3px 0 #273E59"
                />
                <DashBoardWelcome
                    icon={DashBoardWelcomeImage}
                    title="Hi John, welcome to your brokerage"
                    subTitle="Let’s start with the basics. We’ve created a short guide to help you learn about our services"
                    buttonText="Get Started"
                    buttonColor="#fff"
                    buttonBackGroundColor="#002E60"
                    buttonShadow="0 0 3px 0 #273E59"
                    buttonText2="Skip"
                    buttonColor2="#002E60"
                    buttonBackGroundColor2="#fff"
                    buttonShadow2="0 0 3px 0 #273E59"
                />
                <MovingNextCard
                    title="Set up your Car Shipping"
                    subTitle="Its looks like you are travelling interstate get your shipping done"
                    icon={MovingCar}
                    buttonText="Get Started"
                    buttonColor="#fff"
                    buttonBackGroundColor="#002E60"
                    buttonShadow="0 0 3px 0 #273E59"
                />
            </div>
            <div>
                {/* <PolicyDetailsCard premiumMonthly="28" premiumYearly="336" logo={logo} /> */}
                <QuoteDetailsCard
                    deductible={1000}
                    dwellingCoverage={150000}
                    liabilityCoverage={500000}
                    medicalCoverage={5000}
                    personalProperty={75000}
                    lossOfUse={45000}
                />
                <div style={marginBottom}>
                    <InsurancePlanCard
                        width="98%"
                        // height="300px"
                        cardImage={XfinityIcon}
                        currency="$"
                        amount="59"
                        yearlyAmount="332"
                        planRate="month"
                        planValidFor="for 12 Months"
                        selectPlan={() => console.log()}
                        viewCoverage={(e: any) => {
                            e.preventDefault();
                        }}
                        isDouglasElliman={isDouglasElliman}
                    />
                </div>
                <div style={marginBottom}>
                    <CallToQuotesCard
                        width="98%"
                        // height="300px"
                        cardImage={XfinityIcon}
                        mobileNumber="9876543210"
                        onCall={() => console.log()}
                        about={(e: any) => {
                            e.preventDefault();
                        }}
                        isDouglasElliman={isDouglasElliman}
                    />
                </div>
                <UseCoordinates />
            </div>

            <div>
                <Button width="100px" height="50px">
                    Get Started
                </Button>
                <div style={marginBottom}>
                    <CustomizedInput
                        className="text-field MuiInputBase-input"
                        placeholder="Enter DeviceName"
                        value="Test"
                        onChange={(e: React.ChangeEvent) => {
                            console.log(e);
                        }}
                    />
                </div>
                {/* <LastPage /> */}
                <div style={marginBottom}>
                    <SelectInput
                        className="react-select alert-select"
                        // onChange={}
                        options={[
                            { value: '1x', label: '1x' },
                            { value: '2x', label: '2x' },
                        ]}
                        placeholder="Status"
                        value="Status"
                    />
                </div>
                <div style={marginBottom}>
                    <Card
                        width="20%"
                        height="300px"
                        cardImage={test}
                        cardTitle="30+ Leading Brands to Choose"
                        cardContent="Select plans from the top internet and TV providers like AT&T, Xfinity, Verizon and many more."
                    />
                </div>
                <div style={marginBottom}>
                    <CarouselCard
                        avatar={test_avatar}
                        name="Jessica Mules"
                        title="Customer"
                        rate={5}
                        desc="It is very comfortable because there is free internet for tasks, and cheap food"
                    />
                </div>
                {/* <CheckboxCard
                    checked={false}
                    handleChange={() => console.log()}
                    icon={callSvg}
                    label={'Streaming movies abcd wertrr'}
                /> */}

                <div style={{ margin: '50px', display: 'flex', justifyContent: 'space-between' }}>
                    {/* <MovingStepperCard
                        checked={false}
                        handleChange={() => console.log()}
                        icon={callSvg}
                        label={'PODS'}
                        heading="PODS Portable storage containers"
                        points={[
                            'Best national wide coverage',
                            'Unlimited loading/unloading time',
                            'Store on property or at a facility',
                        ]}
                    /> */}

                    {/* <MovingStepperCard
                        recommended
                        checked={true}
                        handleChange={() => console.log()}
                        icon={callSvg}
                        label={'PODS'}
                        heading="PODS Portable storage containers"
                        points={[
                            'Best national wide coverage',
                            'Unlimited loading/unloading time',
                            'Store on property or at a facility',
                        ]}
                    /> */}
                </div>

                <Modal
                    isShowModal={false}
                    onCloseModal={() => console.log()}
                    showClose={true}
                    // style={{ height: '180px', width: '900px', top: '35%', left: '25%' }}
                >
                    <div>hi</div>
                </Modal>

                <div style={marginBottom}>
                    <LongCard
                        width="32%"
                        // height="300px"
                        cardImage={XfinityIcon}
                        bestForImage={AwardIcon}
                        features={[
                            {
                                name: 'Internet',
                                icon: WifiIcon,
                            },
                            {
                                name: 'TV',
                                icon: TVIcon,
                            },
                            {
                                name: 'Phone',
                                icon: PhoneIcon,
                            },
                        ]}
                        bestForTitle="BEST FOR ENTERTAINMENT"
                        cardTitle="AT&T Internet"
                        availableSpeed="100 Mbps"
                        channels="125+"
                        currency="$"
                        ammount="59"
                        planRate="mo."
                        planValidFor="for 12 Months"
                        reward="Get a $100 visa reward card"
                        scheduleCall={() => scheduleCall()}
                    />
                </div>
                <div style={marginBottom}>
                    {/* <LongCheckboxCard
                        width="32%"
                        cardImage={companyLogo}
                        bestForImage={AwardIcon}
                        bestForTitle="BEST FOR COMPLEX MOVES"
                        checked={false}
                        handleChange={() => console.log()}
                        label={'Streaming movies abcd wertrr'}
                        imgWidth1="107px"
                        cardTitle="Air Van northAmerican Van Lines"
                        rate={4}
                        ratingIcon={RatingIcon}
                        verfiedReviews="122 verified reviews"
                        phoneIcon={callIcon}
                        phoneNumber="321.123.1234"
                        locationIcon={locationIcon}
                        locationtext="123 Main Street, Columbus, OH 43212"
                        yearsIcon={BusinessIcon}
                        yearText="24yrs"
                        licensedIcon={RatingIcon}
                        licensedText="Licensed"
                        insuredIcon={RatingIcon}
                        insuredText="Insured"
                    /> */}
                </div>

                <div style={marginBottom}>
                    <PlanCard
                        width="98%"
                        // height="300px"
                        cardImage={XfinityIcon}
                        features={[
                            {
                                name: 'Internet',
                                icon: WifiIcon,
                            },
                            {
                                name: 'TV',
                                icon: TVIcon,
                            },
                            {
                                name: 'Phone',
                                icon: PhoneIcon,
                            },
                        ]}
                        cardTitle="AT&T Internet"
                        availableSpeed="100 Mbps"
                        channels="125+"
                        currency="$"
                        ammount="59"
                        planRate="mo."
                        planValidFor="for 12 Months"
                        contractTerm="24 month"
                        buyNow={() => buyNow()}
                    />
                </div>
                <div style={marginBottom}>
                    <MovingPlanCard
                        width="98%"
                        // height="300px"
                        cardImage={movingLogo}
                        checked={true}
                        handleChange={() => console.log()}
                        label={'Streaming movies abcd wertrr'}
                        bestForImage={AwardIcon}
                        bestForTitle="BEST FOR COMPLEX MOVES"
                        cardTitle="Air Van northAmerican Van Lines"
                        rate={4}
                        ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                        verifiedReview="200 verified reviews"
                        phoneIcon={callIcon}
                        phoneNumber="321.123.1234"
                        locationIcon={locationIcon}
                        locationtext="123 Main Street, Columbus, OH 43212"
                        yearsIcon={BusinessIcon}
                        yearText="24yrs"
                        licensedIcon={RatingIcon}
                        licensedText="Licensed"
                        insuredIcon={RatingIcon}
                        insuredText="Insured"
                    />
                </div>
                <div>
                    <GetAQuoteCard
                        cardImage={addArrowIcon}
                        width="98%"
                        text1="Select multiple movers"
                        text2="get a quote"
                        selectedMovers={1}
                        totalMover={3}
                        selectedMover={true}
                        features={[
                            {
                                name: 'American Van Lines',
                                icon: companyLogo,
                                locationIcon: locationIcon,
                                locationText: '123 Main Street, Columbus, OH 43212',
                            },
                        ]}
                    />
                </div>
                <div>
                    <DetailCard
                        avatarImage={test_avatar}
                        name="Steve Wilson"
                        subtext="Dedicated Concierge"
                        phoneNumber="(810) 525-8185"
                        phoneIcon={callIcon}
                    />
                </div>

                {/* <div style={marginBottom}>
                    <LandingPageInternet />
                </div> */}

                <div style={testing}>
                    <SimpleAccordion question="test" answer="This is a test answer" />
                </div>
            </div>
            <PropertyDetailsCard
                address="14061 Old Wye Mills Road"
                sqFootage={1800}
                yearBuilt={2018}
                noOfStories="Two floors"
                roofType="Architectural Shingle"
                heater="Electric"
                pool="None"
                isDouglasElliman={isDouglasElliman}
            />
            <HomeOwnerDetailsCard name="Shruti Priya" age={24} gender="Female" maritalStatus="unmarried" />
            <ThankYouCard callScheduleDetails="11:30AM, Friday, April 15, 2021" />

            <div>
                <BestDealsCard
                    cardImage1={Img1}
                    cardImage2={Img2}
                    cardTitle="Free $1,700 worth of home security equipment from ADT"
                    cardSubtitle="Know more by selecting our recommenced product"
                    buttonTitle="Get This Deal"
                />
                <MovingChecklistCompletedCard
                    cardImage={completedCardImage}
                    cardTitle="Your moving Service"
                    cardSubtitle="Selected Moving Companies"
                    tasks={['American Van Lines', 'U-Pack']}
                />

                <GetStartedMovingServiceCard
                    cardImage={completedCardImage}
                    cardTitle="Start Your Moving Service"
                    cardSubtitle="Easily set up your utilities in a few easy steps so they’re ready when you move in."
                    buttonTitle="Get Started"
                    progress={true}
                    progressValue={5}
                />

                <GetStartedMovingServiceCard
                    className="card"
                    cardImage={completedCardImage}
                    cardTitle="Enquire home insurance"
                    cardSubtitle="Get additional quotes even if you have home insurance to make sure you’re getting the best rate."
                    buttonTitle="Enquire Now"
                    progress={false}
                />
            </div>
            {pending ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error</div>
            ) : (
                todos.map((todo, index) => (
                    <div style={{ marginBottom: '10px' }} key={todo.id}>
                        {++index}. {todo.title}
                    </div>
                ))
            )}
        </div>
    );
};

export default Home;
