import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={24} viewBox="0 0 26 24" {...props}>
            <defs>
                <style>
                    {`.homeWarrantyprefix__a{fill:#fff}.homeWarrantyNavbarprefix__b{fill:${
                        window.location.pathname.includes('warranty') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe  !important'
                    }}.homeWarrantyNavbarprefix__c{fill:${
                        window.location.pathname.includes('warranty') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f  !important'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-3 -4)">
                <rect className="homeWarrantyprefix__a" width={25} height={14} rx={3} transform="translate(3.5 4.5)" />
                <path
                    className="homeWarrantyprefix__a"
                    d="M28.5 7.5v7h-25v-7a2.846 2.846 0 01.4-1.49 1.4 1.4 0 01.1-.16A2.978 2.978 0 016.5 4.5h19a3.009 3.009 0 013 3zM6.5 18.5a3 3 0 01-3-3v-1h5v4z"
                />
                <path className="homeWarrantyNavbarprefix__b" d="M8.5 14.5h15v2h-15zM8.5 16.5h15v2h-15z" />
                <path
                    className="homeWarrantyNavbarprefix__c"
                    d="M25.5 4h-19A3.5 3.5 0 003 7.5v8A3.5 3.5 0 006.5 19h19a3.5 3.5 0 003.5-3.5v-8A3.5 3.5 0 0025.5 4zm-19 1h19A2.5 2.5 0 0128 7.5V14H4V7.5A2.5 2.5 0 016.5 5zM23 16H9v-1h14zm-19-.5V15h4v3H6.5A2.5 2.5 0 014 15.5zM9 18v-1h14v1zm16.5 0H24v-3h4v.5a2.5 2.5 0 01-2.5 2.5z"
                />
                <path
                    className="homeWarrantyNavbarprefix__c"
                    d="M25.5 13h1a.5.5 0 000-1h-1a.5.5 0 000 1zM15.42 20.5a.5.5 0 00-1 0 1.264 1.264 0 01-.326.873 2.326 2.326 0 000 2.917 1.339 1.339 0 010 1.751 2.243 2.243 0 00-.514 1.459.5.5 0 001 0 1.265 1.265 0 01.326-.875 2.329 2.329 0 000-2.919 1.335 1.335 0 010-1.748 2.243 2.243 0 00.514-1.458zM18.42 20.5a.5.5 0 00-1 0 1.272 1.272 0 01-.325.873 2.325 2.325 0 000 2.917 1.339 1.339 0 010 1.751 2.243 2.243 0 00-.515 1.459.5.5 0 001 0 1.27 1.27 0 01.326-.875 2.329 2.329 0 000-2.919 1.336 1.336 0 010-1.748 2.237 2.237 0 00.514-1.458zM21.42 20.5a.5.5 0 00-1 0 1.272 1.272 0 01-.325.873 2.325 2.325 0 000 2.917 1.339 1.339 0 010 1.751 2.243 2.243 0 00-.515 1.459.5.5 0 001 0 1.27 1.27 0 01.326-.875 2.329 2.329 0 000-2.919 1.336 1.336 0 010-1.748 2.237 2.237 0 00.514-1.458zM12.42 20.5a.5.5 0 00-1 0 1.264 1.264 0 01-.326.873 2.326 2.326 0 000 2.917 1.339 1.339 0 010 1.751 2.243 2.243 0 00-.514 1.459.5.5 0 001 0 1.265 1.265 0 01.326-.875 2.329 2.329 0 000-2.919 1.335 1.335 0 010-1.748 2.243 2.243 0 00.514-1.458z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
