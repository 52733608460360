// import React, { useEffect } from 'react';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
// import { hexToCSSFilter } from 'hex-to-css-filter';
import '../../styles/checkboxCard.scss';

type iProps = {
    checked: boolean;
    handleChange: any;
    handleChange1?: any;
    className?: string;
    icon: any;
    label: string;
    iconWidth?: string;
    iconHeight?: string;
    width?: string;
    height?: string;
    name?: string;
    iconColour?: string;
    fromLanding ? : boolean
};

const CheckboxCards = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        checkBoxCard: {
            border: props.checked && props.fromLanding ? `#30AB86 2px solid`:  props.checked ?`${props.iconColour} 2px solid` : '#BEBEBE 2px solid',
            width: props.width ? props.width : '170px',
            height: props.height ? props.height : '170px',
            borderRadius: '5px',
            textAlign: 'left',
            margin: '10px',
            '&:hover': {
                borderColor: props.fromLanding ? `#30AB86 !important` :`${props.iconColour} !important`,
                '& $checkroot': {
                    color: props.fromLanding ? `#30AB86 !important` :`${props.iconColour} !important`,
                },
            },
        },
        // checkroot: {},
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.checkBoxCard}` : classes.checkBoxCard}
            onClick={() => props.handleChange1(props.name, props.checked)}
        >
            <Checkbox
                // className={props.className ? `${props.className} ${classes.checkroot}` : classes.checkroot}
                checked={props.checked}
                name={props.name ? props.name : ''}
                onChange={props.handleChange}
                color="default"
                style={{ color: props.fromLanding && props.checked ? '#30AB86' : props.checked ?`${props.iconColour}` : '#BEBEBE' }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div
                className="checkboxCardBody"
              
                //  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
                {props.icon}
                <div
                    style={{
                        color: props.fromLanding && props.checked ? '#30AB86' : props.checked ? `${props.iconColour}` : '#333333',
                        textAlign: 'center',
                        fontSize: '14px',
                        marginTop: '5px',
                    }}
                >
                    {props.label}
                </div>
            </div>
        </div>
    );
};

export default CheckboxCards;
