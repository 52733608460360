import React from "react";
import somethingWentWromgImg  from "../../Assets/images/somethingWentWrong.jpeg"
import styles  from './ErrorBoundary.module.scss';
import Button from '../../components/atoms/Button';
// import { Store } from "redux";

type initialProps = {
  store?: any
  history?: any;
};

type initialState = {
  hasError: boolean
};

class ErrorBoundary extends React.Component<initialProps, initialState> {
  
  state = {
    hasError: false,
  };

  componentDidMount() {
    let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
  }

  static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    // console.log(error)
    return { hasError: true };
  }

  componentDidCatch(_error:any , _errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log("error: " + error);
    // console.log("errorInfo: " + JSON.stringify(errorInfo));
    // console.log("componentStack: " + errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return(
        <div className={styles.someThingWentWrong}>
          <div className={styles.someThingWentWrongOopsText}>
              <h2>Page Temporarily Unavailable</h2>
              <span className={styles.someThingWentWrongOopsTextPera}>We are working quickly to resolve the issue. We apologize for the inconvenience. Please check back soon for updates.</span>                                
          </div>
          <div className={styles.someThingWentWrongImage}>
              <img src={somethingWentWromgImg} alt="" />
          </div>
          <div className={styles.havetwobtns}>
            {/* <Button >Schedule a Call</Button> */}
            <Button
                className={styles.borderbtn}
                onClick={() => {
                  this.props.history.push('/dashboard');
                }}
            >
                Back To Dashboard
            </Button>
        </div>
      </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
