import React, { Component } from 'react';
import './insurance.scss';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import image from '../../Assets/images/InsuranceImages/phone.svg';
// import XfinityIcon from '../../Assets/images/xfinity.png';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
// import iIcon from '../../Assets/images/Moving/iIcon.svg';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Action2 from '../../store/dashboard/actions';
import { scheduleSegment } from '../../utils/utlis';
import { trustedFormUrl } from '../../config';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    dashboardAction: any;
    CheckListDashboardState: any;
    history?: any;
    commonAction?: any;
    commonState?: any;
    insuranceAction?: any;
    insuranceState?: any;
};

type initialState = {
    calendlyOpen: boolean;
    date: any;
    time: any;
    firstName: any;
    lastName: any;
    email: any;
    phone: any;
    zip: any;
    checked: any;
    data: any;
    type: string;
    submitClick: boolean;
    quoteId: string | number | null;
    isOpen: boolean;
    isOpen1: boolean;
    isDouglasElliman: boolean;
};
declare global {
    interface Window {
        trustedForm: {
            id: any;
        };
    }
}
export class OrderPage extends Component<initialProps, initialState> {
    state = {
        calendlyOpen: false,
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zip: '',
        checked: false,
        data: {},
        type: '',
        submitClick: false,
        quoteId: null,
        isOpen: false,
        isOpen1: false,
        isDouglasElliman: false,
    };

    trustedUrl: string | null | undefined = null

    componentDidMount() {
        window.scrollTo(0, 0);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        const state: any = this.props.history && this.props.history.location && this.props.history.location.state;
        this.setState({
            data: state && state.data ? { ...state.data } : {},
            type: state && state.type,
            quoteId: state && state.data && state.data.id,
        });
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const move =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            this.setState({
                firstName: move && move.user_ref && move.user_ref.first_name,
                lastName: move && move.user_ref && move.user_ref.last_name,
                email: move && move.user_ref && move.user_ref.email,
                phone: move && move.user_ref && move.user_ref.phone,
                zip: move && move.destination && move.destination.zip_code,
            });
        } else {
            this.props.commonAction.moveGet();
        }

        if(!document.getElementById("trustedFormScriptTag")){
            const field = 'xxCertUrl';
            const provideReferrer: string = 'false';
            const invertFieldSensitivity = false;
            const tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.id = "trustedFormScriptTag"
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                `://${trustedFormUrl}/trustedform.js?provide_referrer=` +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date().getTime() +
                Math.random() +
                '&invert_field_sensitivity=' +
                invertFieldSensitivity;
            const s = document.getElementsByTagName('script')[0];

            s && s.parentNode && s.parentNode.insertBefore(tf, s);
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const prevI = prevProps && prevProps.insuranceState;
        const curI = this.props && this.props.insuranceState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const move = cur && cur.move && cur.move[0];
            this.setState({
                firstName: move && move.user_ref && move.user_ref.first_name,
                lastName: move && move.user_ref && move.user_ref.last_name,
                email: move && move.user_ref && move.user_ref.email,
                phone: move && move.user_ref && move.user_ref.phone,
                zip: move && move.destination && move.destination.zip_code,
            });
        }
        if (
            prevI.appointment !== curI.appointment &&
            curI.appointment &&
            curI.appointment[0] &&
            curI.appointment.status === 200
        ) {
            this.setState({ submitClick: false, calendlyOpen: false });
            if (
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding'
            ) {
                this.props.history.push({
                    pathname: '/landing/insurance/summary',
                    state: {
                        fromLanding: 'fromLanding',
                    },
                });
            } else {
                this.props.history.push({
                    pathname: '/dashboard/home-insurance/summary',
                    state: this.props.history && this.props.history.location && this.props.history.location.state,
                });

                updateChecklistTask(100, this.props.dashboardAction);
            }
        }
    }

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });
    };

    // emailHandler = (e: any) => {
    //     this.setState({
    //         email: e.target.value,
    //     });
    // };

    // phoneHandler = (e: any) => {
    //     this.setState({
    //         phone: e.target.value,
    //     });
    // };

    // zipHandler = (e: any) => {
    //     this.setState({
    //         zip: e.target.value,
    //     });
    // };

    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Button Clicked',
            'Insurance',
            brokerage,
            brokerage_key,
            agent_key,
            agent_name,
            'Home Insurance',
            'Schedule a Call Back',
            false,
            ``,
            false,
            '',
            move_id,
        );

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Insurance',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };

    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    closeModal = () => {
        this.setState({
            calendlyOpen: false,
            submitClick: false,
        });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Button Clicked',
            {
                page: 'Home Insurance',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'Cancel',
                card: false,
                card_title: '',
                card_type: '',
                category_name: 'Home Insurance',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
    };

    submitHandler = () => {
        this.setState({ submitClick: true });
        const { firstName, lastName, email, zip, phone, date, time, checked, quoteId } = this.state;
        
        if (firstName && lastName && email && zip && phone && date && time && checked && quoteId) {
            this.props.insuranceAction.insuranceAppointment({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                zip_code: zip,
                appointment_datetime: `${new Date(date).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`,
                quote_id: quoteId,
                trusted_url: this.trustedUrl
            });
        }
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        let windownew: any = window;

        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Button Clicked',
            {
                page: 'Home Insurance',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'Submit',
                card: false,
                card_title: '',
                card_type: '',
                category_name: 'Home Insurance',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
    };

    
    componentWillUnmount() {
        document.getElementById("trustedFormScriptTag")?.remove()
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { type, isDouglasElliman } = this.state;
        const fromLandingPage = this.props.history.location?.state?.fromLanding == 'fromLanding';
        let el =
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.data;

        const breadList = [
            {
                name: 'Insurance',
                url: fromLandingPage ? '/landing/insurance/plans' : '/dashboard/home-insurance/plans'
            },
            {
                name: 'Schedule call',
                url: ''
            }
        ]

        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div>
                    <div className="main-head-insurance" style={{ position: 'fixed' }}>
                        <h2 className="heading-insurance-regular">Insurance</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} homeCrumb={fromLandingPage ? 'HomePage' : 'Dashboard'}   />
                        </div>
                    </div>
                    <div className="steps1Insurance">
                        <div>
                            <div className="arrangeLeft">
                                <div className="headInsuranceMain">Great, you are almost done </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <div>
                                        Let's set up a time to speak to a licensed insurance specialists to finalize
                                        your policy
                                    </div>
                                    {/* <div style={{ marginLeft: '7px', height: '15px' }}>
                                        <img src={iIcon} height="15px" width="15px" />
                                    </div> */}
                                    <InfoIcon style={{ height: '20px', width: '20px', color: '#6B6C6F' }} />
                                </div>
                            </div>
                            <div className="main-flexInsurance">
                                <div className="BoxCardLeftInsurance">
                                    <div className="InnerBox1" style={{ flexWrap: 'wrap' }}>
                                        <Button
                                            className="btnOrder"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : window.location.pathname.includes('landing')
                                                    ? '#30AB86'
                                                    : '#273e59'
                                            }
                                            // boxShadow="0 0 3px 0 #273E59"
                                            width="200px"
                                            height="50px"
                                            // color="#FFFFFF"
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            onClick={() => this.calendlyHandle()}
                                        >
                                            Schedule a Call Back
                                        </Button>
                                        <div className="callOrderplaningInsurance2">or</div>
                                        <div className="callOrderplaningInsurance1">
                                            <img src={image} style={{ marginRight: '10px' }} alt="" />
                                            <div>
                                                Call{' '}
                                                <a
                                                    className={
                                                        isDouglasElliman
                                                            ? 'callOrderplaningInsuranceDouglas'
                                                            : 'callOrderplaningInsurance'
                                                    }
                                                    href="tel:845-286-9304"
                                                >
                                                    {`(845) 286-9304`}{' '}
                                                </a>{' '}
                                                to finalize your policy
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {type === 'success' && el ? (
                                    <div className="BoxCardRightInsurance">
                                        <div className="BoxCardInner">
                                            <div
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '22px',
                                                    fontWeight: 700,
                                                    textAlign: 'left',
                                                    padding: '25px 20px 25px 20px',
                                                }}
                                            >
                                                Insurance policy details
                                            </div>
                                            <div className="line12"></div>
                                            <div className="boxPlanDetailInsurance" style={{ padding: '20px' }}>
                                                <img src={el && el.image_url} className="BoxCardRightInsuranceImage" alt="" />

                                                <div className="BoxCardRightInsuranceImageBelow">
                                                    <div>
                                                        <div className="planDetailsTagsInsurance">Premium</div>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div
                                                                className="insuranceplancardamount"
                                                                style={{ fontFamily: 'Poppins, sans-serif' }}
                                                            >
                                                                $
                                                            </div>
                                                            <div
                                                                className="insuranceplancardamount1"
                                                                style={{ fontFamily: 'Poppins, sans-serif' }}
                                                            >
                                                                {Math.floor(
                                                                    el &&
                                                                        el.data &&
                                                                        el.data.quote &&
                                                                        el.data.quote.term_premium / 12,
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#6B6C6F',
                                                                    fontSize: '14px',
                                                                    marginTop: '15px',
                                                                }}
                                                            >
                                                                *
                                                                <InfoIcon
                                                                    style={{
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="planDetailsTagsInsurance"
                                                            style={{ fontSize: '12px' }}
                                                        >
                                                            Monthly Estimate
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-end',
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div
                                                                className="insuranceplancardamount"
                                                                style={{ fontFamily: 'Poppins, sans-serif' }}
                                                            >
                                                                $
                                                            </div>
                                                            <div
                                                                className="insuranceplancardamount1"
                                                                style={{ fontFamily: 'Poppins, sans-serif' }}
                                                            >
                                                                {el &&
                                                                    el.data &&
                                                                    el.data.quote &&
                                                                    el.data.quote.term_premium}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#6B6C6F',
                                                                    fontSize: '14px',
                                                                    marginTop: '15px',
                                                                }}
                                                            >
                                                                *
                                                                <InfoIcon
                                                                    style={{
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="planDetailsTagsInsurance"
                                                            style={{ fontSize: '12px' }}
                                                        >
                                                            Yearly Estimate
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="BoxCardInner">
                                            <div
                                                style={{
                                                    color: '#333333',
                                                    fontSize: '22px',
                                                    fontWeight: 700,
                                                    textAlign: 'left',
                                                    padding: '25px 20px 25px 20px',
                                                }}
                                            >
                                                Quote details
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Deductible</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el &&
                                                    el.data &&
                                                    el.data.coverage &&
                                                    !el.data.coverage.deductible_all_other &&
                                                    !el.data.coverage.deductible_theft &&
                                                    !el.data.coverage.deductible_wind_hail
                                                        ? 0
                                                        : el.data.coverage.deductible_all_other +
                                                          el.data.coverage.deductible_theft +
                                                          el.data.coverage.deductible_wind_hail}
                                                </div>
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Dwelling Coverage</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el && el.data && el.data.coverage && el.data.coverage.dwelling
                                                        ? el.data.coverage.dwelling
                                                        : 0}
                                                </div>
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Liability Coverage</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el &&
                                                    el.data &&
                                                    el.data.coverage &&
                                                    el.data.coverage.personal_liability
                                                        ? el.data.coverage.personal_liability
                                                        : 0}
                                                </div>
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Medical Coverage</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el &&
                                                    el.data &&
                                                    el.data.coverage &&
                                                    el.data.coverage.medical_payments
                                                        ? el.data.coverage.medical_payments
                                                        : 0}
                                                </div>
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Personal Property</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el &&
                                                    el.data &&
                                                    el.data.coverage &&
                                                    el.data.coverage.personal_property
                                                        ? el.data.coverage.personal_property
                                                        : 0}
                                                </div>
                                            </div>
                                            <div className="line12"></div>
                                            <div className="smallDiv">
                                                <div>Loss of Use</div>
                                                <div>
                                                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                    {el && el.data && el.data.coverage && el.data.coverage.loss_of_use
                                                        ? el.data.coverage.loss_of_use
                                                        : 0}
                                                </div>
                                            </div>
                                            <div className="quoteSmall">
                                                *The insurance quote(s) displayed above are provided by licensed
                                                insurance agencies or carriers and are preliminary estimates only and
                                                are subject to change based on additional information we may receive
                                                later in the quoting process, the coverages, deductibles and payment
                                                options you choose along with other discounts, features or savings that
                                                may apply.
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {this.state.calendlyOpen && (
                        <Modal
                            isShowModal={this.state.calendlyOpen}
                            onCloseModal={() => this.closeModal()}
                            showClose={false}
                            className={isDouglasElliman ? 'modalClasses modalClassesDouglasOrder' : 'modalClasses'}
                        >
                            <div>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    
                                    <form>
                                        <div style={{ fontSize: '16px', color: '#333333', fontWeight: 'bolder' }}>
                                            Call Schedule Details
                                        </div>
                                        <div className="ModalTimeDate">
                                            <div>
                                                {/* <TextField
                                                id="date"
                                                label="Select Date"
                                                defaultValue={Date.now}
                                                value={this.state.date}
                                                onChange={(e: any) => this.dateHandler(e)}
                                                type="date"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disablePast
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date"
                                                        inputVariant="filled"
                                                        label="Select date"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        value={this.state.date ? new Date(this.state.date) : null}
                                                        onChange={(e: any) => this.dateHandler(e)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        PopoverProps={{
                                                            style: { zIndex: 1000000 },
                                                        }}
                                                        autoOk={true}
                                                        error={
                                                            (this.state.submitClick && !this.state.date) ||
                                                            (this.state.date !== '' && !Date.parse(this.state.date))
                                                        }
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                    {(this.state.submitClick && !this.state.date && 'Please select date') ||
                                                        (this.state.date !== '' &&
                                                            !Date.parse(this.state.date) &&
                                                            'Invalid Date')}
                                                </div>
                                            </div>
                                            <div>
                                                {/* <TextField
                                                id="time"
                                                label="Select Time"
                                                value={this.state.time}
                                                defaultValue="12:00"
                                                onChange={(e: any) => this.timeHandler(e)}
                                                type="time"
                                                variant="filled"
                                                className="date11"
                                            /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        ampm={true}
                                                        variant="inline"
                                                        margin="normal"
                                                        id="time"
                                                        inputVariant="filled"
                                                        label="Select time"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        value={this.state.time ? this.state.time : null}
                                                        onChange={(e: any) => this.timeHandler(e)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                        keyboardIcon={<AccessAlarmIcon />}
                                                        autoOk={true}
                                                        PopoverProps={{
                                                            style: { zIndex: 1000000 },
                                                        }}
                                                        // InputProps={{
                                                        //     onFocus: () => {
                                                        //         this.setState({ isOpen1: true });
                                                        //     },
                                                        // }}
                                                        error={
                                                            (this.state.submitClick && !this.state.time) ||
                                                            (this.state.time !== '' && !Date.parse(this.state.time))
                                                        }
                                                    // open={this.state.isOpen1}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                    {(this.state.submitClick && !this.state.time && 'Please select time') ||
                                                        (this.state.time !== '' &&
                                                            !Date.parse(this.state.time) &&
                                                            'Invalid Date')}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                // marginTop: '10px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="peronalDetailsInsurance">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.submitClick && this.state.firstName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.submitClick && this.state.lastName === ''}
                                            />
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                // onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                // onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                            <TextField
                                                id="time"
                                                label="Zip code"
                                                value={this.state.zip}
                                                // onChange={(e: any) => this.zipHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                disabled
                                            />
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', marginTop: '20px', textAlign: 'justify' }}>
                                                <Checkbox
                                                    color="primary"
                                                    className="checkbox"
                                                    checked={this.state.checked}
                                                    onChange={this.handleChange}
                                                    name="checkedG"
                                                />
                                                <div style={{ fontSize: 12, color: '#666' }}>
                                                    By clicking the “submit” button below, you are providing your signed
                                                    written consent to receive calls or texts from InsuraMatch and its
                                                    agents to market insurance products at the number (wireless or
                                                    otherwise) you provided above, even if the number is on a federal, state
                                                    or local do not call list. As part of that consent, you understand and
                                                    agree that (1) those calls may be auto-dialed and use artificial or
                                                    pre-recorded voice messages or SMS communications, and (2) your
                                                    agreement to receive these calls is not required to purchase any goods
                                                    or services, and (3) InsuraMatch will not sell your information to third
                                                    parties without your consent, and (4) InsuraMatch may make additional
                                                    attempts to call/message you if we are unable to reach you at the
                                                    scheduled time. You may revoke your consent by contacting us at {' '}
                                                    <a
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                        href="tel:844-406-1890"
                                                    >
                                                        {`844-406-1890`}
                                                    </a>
                                                    . You agree to InsuraMatch's{" "}
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/terms-and-conditions',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Terms & Conditions,
                                                    </span>{' '}
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/compensation-and-fcra-information-disclosure',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Compensation and Information Disclosure
                                                    </span>{' '}
                                                    and{' '}
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                'https://www.insuramatch.com/privacy-policy',
                                                                '_blank',
                                                            )
                                                        }
                                                        className={
                                                            this.state.isDouglasElliman
                                                                ? 'redirectInsuranceD'
                                                                : 'redirectInsurance'
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </span>{' '}
                                                    and authorize InsuraMatch and the companies it represents to obtain
                                                    consumer reports on the persons listed in your application.
                                                </div>
                                            </div>
                                            <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                {this.state.submitClick && !this.state.checked && 'Please accept'}
                                            </div>
                                        </div>                                        
                                        <input type="hidden" name="xxCertUrl" ref={(input) => { this.trustedUrl = input?.value }}/>
                                    </form>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        width="130px"
                                        height="50px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.submitHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        dashboardAction: bindActionCreators(Action2, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    insuranceState: state.insurance,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
