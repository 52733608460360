import {
    GET_HS_SUMMARY,
    GET_HS_SUMMARY_SUCCESS,
    GET_HS_SUMMARY_ERROR
} from './actionTypes';

import { HomeSecurityActions, HSSummaryState } from './types';

const initialState: HSSummaryState = {
    summary: [],
    isHSSummaryArrived: false,
    errorHSSummary: null
};

export default (state = initialState, action: HomeSecurityActions) => {
    switch (action.type) {
        case GET_HS_SUMMARY:
            return {
                ...state,
                summary: [],
                isHSSummaryArrived: false,
                errorHSSummary: null
            };
        case GET_HS_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.payload.summary,
                isHSSummaryArrived: true,
                errorHSSummary: null,
            };
        case GET_HS_SUMMARY_ERROR:
            return {
                ...state,
                summary: [],
                isHSSummaryArrived: false,
                errorHSSummary: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
