import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 22.832}
        height={props.height ? props.height : 31.134}
        viewBox="0 0 22.832 31.134"
    >
        <g data-name="Group 35696">
            <path
                data-name="Path 42873"
                d="M11.531 6.294a4.036 4.036 0 1 0 4.036 4.036 4.04 4.04 0 0 0-4.036-4.036m0 9.3a5.267 5.267 0 1 1 5.267-5.267 5.273 5.273 0 0 1-5.267 5.267m0-14.367a10.312 10.312 0 0 0-10.3 10.3c0 5.037 8.227 15.368 10.3 17.891 2.073-2.523 10.3-12.854 10.3-17.891a10.312 10.312 0 0 0-10.3-10.3m0 29.77a.616.616 0 0 1-.469-.216C10.61 30.25-.001 17.712-.001 11.528a11.531 11.531 0 0 1 23.063 0c0 6.184-10.611 18.722-11.063 19.253a.617.617 0 0 1-.469.216"
                fill={window.location.pathname.includes('address') ? '#009CBD' : '#100b28'}
            />
        </g>
    </svg>
);

export default SvgComponent;
