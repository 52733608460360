import {
    POST_SERVICE,
    POST_SERVICE_ERROR,
    POST_SERVICE_SUCCESS,
    GET_SERVICE_PROVIDER,
    GET_SERVICE_PROVIDER_ERROR,
    GET_SERVICE_PROVIDER_SUCCESS,
    GET_DIGITAL_SERVICE_PROVIDER,
    GET_DIGITAL_SERVICE_PROVIDER_ERROR,
    GET_DIGITAL_SERVICE_PROVIDER_SUCCESS,
    GET_INSURANCE_PROVIDER,
    GET_INSURANCE_PROVIDER_ERROR,
    GET_INSURANCE_PROVIDER_SUCCESS,
} from './actionTypes';

import { ServiceActions, ServiceState } from './types';
const initialState: ServiceState = {
    postService: [],
    isPostService: false,
    getServiceProvider: [],
    isGetServiceProvider: false,
    getDigitalServiceProvider: [],
    isGetDigitalServiceProvider: false,
    getInsuranceProvider: [],
    isGetInsuranceProvider: false,
};

export default (state = initialState, action: ServiceActions) => {
    switch (action.type) {
        case POST_SERVICE:
            return {
                ...state,
                postService: [],
                isPostService: false,
                error: null,
            };
        case POST_SERVICE_SUCCESS:
            return {
                ...state,
                postService: action.payload.postService,
                isPostService: true,
                error: null,
            };
        case POST_SERVICE_ERROR:
            return {
                ...state,
                postService: [],
                isPostService: false,
                error: action.payload.error,
            };

        case GET_SERVICE_PROVIDER:
            return {
                ...state,
                getServiceProvider: [],
                isGetServiceProvider: false,
                error: null,
            };
        case GET_SERVICE_PROVIDER_SUCCESS:
            return {
                ...state,
                getServiceProvider: action.payload.getServiceProvider,
                isGetServiceProvider: true,
                error: null,
            };
        case GET_SERVICE_PROVIDER_ERROR:
            return {
                ...state,
                getServiceProvider: [],
                isGetServiceProvider: false,
                error: action.payload.error,
            };
        case GET_DIGITAL_SERVICE_PROVIDER:
            return {
                ...state,
                getDigitalServiceProvider: [],
                isGetDigitalServiceProvider: false,
                error: null,
            };
        case GET_DIGITAL_SERVICE_PROVIDER_SUCCESS:
            return {
                ...state,
                getDigitalServiceProvider: action.payload.getDigitalServiceProvider,
                isGetDigitalServiceProvider: true,
                error: null,
            };
        case GET_DIGITAL_SERVICE_PROVIDER_ERROR:
            return {
                ...state,
                getDigitalServiceProvider: [],
                isGetDigitalServiceProvider: false,
                error: action.payload.error,
            };
        case GET_INSURANCE_PROVIDER:
            return {
                ...state,
                getInsuranceProvider: [],
                isGetInsuranceProvider: false,
                error: null,
            };
        case GET_INSURANCE_PROVIDER_SUCCESS:
            return {
                ...state,
                getInsuranceProvider: action.payload.getInsuranceProvider,
                isGetInsuranceProvider: true,
                error: null,
            };
        case GET_INSURANCE_PROVIDER_ERROR:
            return {
                ...state,
                getInsuranceProvider: [],
                isGetInsuranceProvider: false,
                error: action.payload.error,
            };

        default:
            return {
                ...state,
            };
    }
};
