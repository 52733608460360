import React from 'react';
import './SuitablePlansCard.scss';

import Button from '../atoms/Button';

type initialProps = {
    logo?: any;

    onSelectClick?: any;

    KwhValue?: number;
    contractTermValue?: number;
    cancelationFee?: any;
    viewDetails?: any;
    isViewMore?: any;
    viewMoreClick?: any;
    disclaimerPath?: any;
};

type initialState = {};

class SuitablePlansCard extends React.Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        return (
            <div className={`electricity-card-main`}>
                <div className="main-body-div-electricity">
                    <div className="tag-title-div-electricity">
                        <div className="logo-div-electricity">
                            <img src={this.props.logo} className="logo" alt="" />
                            {this.props.disclaimerPath &&
                            this.props.disclaimerPath != '' &&
                            this.props.disclaimerPath != null &&
                            this.props.disclaimerPath != undefined ? (
                                <a
                                    className="viewMorePowerKiosk"
                                    href={this.props.disclaimerPath}
                                    target="blank"
                                    // onClick={() => window.open('_blank', this.props.disclaimerPath)}
                                >
                                    Disclaimer
                                </a>
                            ) : null}
                        </div>
                    </div>

                    <div className="rating-reviews-div-electricity">
                        <div className="perKwhValue">{this.props.KwhValue}</div>
                        <div className="perKwh">per kWH</div>
                    </div>
                    <div className="lineDiv"></div>

                    <div className="tag-div-elec">
                        <div className="perKwhValue">
                            {this.props.contractTermValue}
                            <span className="perKwhValue margin-dollar-left">Months</span>
                        </div>
                        <div className="perKwh">Contract term</div>
                    </div>
                    <div className="lineDiv"></div>
                    <div className="tag-div-elec">
                        <div className="cancellationValue">
                            {/* <span className="contract margin-dollar">$</span> */}
                            {this.props.cancelationFee && this.props.cancelationFee != 'There is no cancellation fee.'
                                ? this.props.cancelationFee.length > 25 && this.props.isViewMore
                                    ? this.props.cancelationFee
                                    : this.props.cancelationFee.substring(0, 25)
                                : '$0'}
                            {this.props.cancelationFee && this.props.cancelationFee.length > 30 ? (
                                <div className="viewMorePowerKiosk" onClick={this.props.viewMoreClick}>
                                    {this.props.cancelationFee &&
                                    this.props.cancelationFee.length > 30 &&
                                    this.props.isViewMore
                                        ? 'View Less'
                                        : 'View More'}
                                </div>
                            ) : null}
                        </div>
                        <div className="perKwh">Cancellation Fee</div>
                    </div>
                    <div className="lineDiv"></div>
                    <div className="rating-reviews-div-electricity">
                        <div className="viewDetailsUtility" onClick={this.props.viewDetails}>
                            View Details
                        </div>
                    </div>

                    <div className="button-div-suitable">
                        <Button
                            className="energy-card-button"
                            // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#002e62'}
                            // color={'#fff'}
                            onClick={this.props.onSelectClick}
                        >
                            Select Plan
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default SuitablePlansCard;
