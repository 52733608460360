import React from 'react';
import Slider from 'react-slick';
import BlogCard from '../../components/atoms/HomeValuation/BlogCard';


const BlogCardSlider = (props: any) => {

    const { blogResults, analyticsData } = props;
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // initialSlide: 0
                }
            }
        ]
    };

    return (
        <div>

            <Slider {...settings}>
                {
                    blogResults.length && blogResults.map((ele: any, i: number) => {
                        return (
                            <BlogCard
                                key={i}
                                cardTitle={ele.name}
                                cardImage={ele.image}
                                cardContent={ele.description.slice(0, 100) + '...'}
                                cardLink={ele.links}
                                analyticsData={analyticsData}
                            />
                        );
                    })
                }

            </Slider>

        </div>
    );
};

export default BlogCardSlider