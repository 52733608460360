import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getChecklistFailure,
    getChecklistSuccess,
    addTaskSuccess,
    addTaskFailure,
    updateTaskSuccess,
    updateTaskFailure,
    deleteTaskSuccess,
    deleteTaskFailure,
    setReminderSuccess,
    setReminderFailure,
    updateReminderSuccess,
    updateReminderFailure,
    deleteReminderSuccess,
    deleteReminderFailure,
} from './actions';
import {
    GET_CHECKLIST_DATA,
    ADD_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    SET_REMINDER,
    UPDATE_REMINDER,
    DELETE_REMINDER,
} from './actionTypes';
import apiJunction from '../../utils/api';
import { Store } from 'react-notifications-component';
import history from '../../routes/History';
import { getErrorMessage } from '../errorHandler';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* getChecklist() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/checklist-api/list_by_category/?undefined`,
        });
        if (response.data && response.status === 200) {
            yield put(getChecklistSuccess({ checklist: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getChecklistFailure({ error: getErrorMessage(e)}));
        // history.push('/server-error');
    }
}

function* addTaskChecklist(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/checklist/checklist-api/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            yield put(addTaskSuccess({ checklistTask: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Task added successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(addTaskFailure({ errorTask: getErrorMessage(e) }));
        history.push('/server-error');
    }
}

function* updateTaskChecklist(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/checklist/checklist-api/${action.payload.checklistId}/`,
            body: action.payload.payload,
        });
        if (response.data && response.status === 200) {
            yield put(updateTaskSuccess({ checklistUpdateTask: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Task Updated successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(updateTaskFailure({ errorUpdateTask: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* deleteTaskChecklist(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'delete',
            url: `/api/checklist/checklist-api/${action.payload.checklistId}/`,
        });
        if (response.status === 204) {
            yield put(deleteTaskSuccess({ checklistDeleteTask: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Task Deleted successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(deleteTaskFailure({ errorDeleteTask: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* setReminder(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/checklist/set-reminder-api/`,
            body: action.payload,
        });
        if (response.data && response.status === 201) {
            yield put(setReminderSuccess({ setReminder: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Reminder Set successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(setReminderFailure({ errorSetReminder: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}
function* updateReminder(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/checklist/set-reminder-api/${action.payload.checklistId}/`,
            body: action.payload.payload,
        });
        if (response.data && response.status === 200) {
            yield put(updateReminderSuccess({ updateReminder: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Reminder Updated successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(updateReminderFailure({ errorUpdateReminder: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* deleteReminder(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'delete',
            url: `/api/checklist/set-reminder-api/${action.payload.checklistId}/`,
        });
        if (response.status === 204) {
            yield put(deleteReminderSuccess({ deleteReminder: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Reminder Deleted successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e) {
        yield put(deleteReminderFailure({ errorDeleteReminder: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

export default function* internetSaga() {
    yield all([takeLatest(GET_CHECKLIST_DATA, getChecklist)]);
    yield all([takeLatest(ADD_TASK, addTaskChecklist)]);
    yield all([takeLatest(UPDATE_TASK, updateTaskChecklist)]);
    yield all([takeLatest(DELETE_TASK, deleteTaskChecklist)]);
    yield all([takeLatest(SET_REMINDER, setReminder)]);
    yield all([takeLatest(UPDATE_REMINDER, updateReminder)]);
    yield all([takeLatest(DELETE_REMINDER, deleteReminder)]);
}
