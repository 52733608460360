import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="39.447" height="39.448" viewBox="0 0 39.447 39.448">
            <defs>
                <style>
                    {`.energyprefix__a{fill:${
                        window.location.pathname.includes('energy-savings') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#2d2842 !important'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-12.854 -12.85)">
                <path
                    fill="#fff"
                    stroke="#bcfcfe"
                    strokeWidth="0.5"
                    d="M51.515 36.895l-2.023 5.239-3.126-.092a15.478 15.478 0 01-3.677 3.861l.23 3.126-5.148 2.252-2.159-2.255a17.261 17.261 0 01-5.331.137l-2.023 2.345-5.24-2.023.092-3.126a15.478 15.478 0 01-3.861-3.677l-3.125.23-2.254-5.144 2.252-2.16a17.261 17.261 0 01-.137-5.331l-2.345-2.024 2.023-5.239 3.126.092a15.478 15.478 0 013.677-3.861l-.23-3.126 5.148-2.252 2.16 2.252a17.241 17.241 0 015.332-.138l2.023-2.345 5.24 2.023-.092 3.126a15.478 15.478 0 013.861 3.677l3.125-.23 2.253 5.148-2.256 2.16a17.262 17.262 0 01.138 5.331zm-5.771-4.321A13.167 13.167 0 1032.578 45.74a13.167 13.167 0 0013.166-13.166z"
                    data-name="Path 51373"
                ></path>
                <path
                    fill="#fff"
                    stroke="#bcfcfe"
                    strokeWidth="0.5"
                    d="M32.578 19.961a12.613 12.613 0 11-12.613 12.613 12.613 12.613 0 0112.613-12.613z"
                    data-name="Path 51374"
                ></path>
                <path
                    fill="#fff"
                    d="M223.009 174.977l-5.022 9.622.3-6.491H216l1.314-8.678h5.033l-2.122 5.547z"
                    data-name="Path 51375"
                    transform="translate(-186.927 -144.441)"
                ></path>
                <g
                    className="energyprefix__a"
                    fill="#100b28"
                    data-name="Group 40272"
                    transform="translate(13.115 13.11)"
                >
                    <path
                        className="energyprefix__a"
                        stroke="#100b28"
                        strokeWidth="0.5"
                        d="M19.463 6.325a13.139 13.139 0 1013.139 13.139A13.154 13.154 0 0019.463 6.325zm0 25.354a12.215 12.215 0 1112.215-12.215 12.229 12.229 0 01-12.215 12.215z"
                        data-name="Path 51376"
                    ></path>
                    <path
                        className="energyprefix__a"
                        stroke="#100b28"
                        strokeWidth="0.5"
                        d="M36.604 21.57a17.754 17.754 0 00-.129-4.98l2.073-1.987a.481.481 0 00.108-.54l-2.258-5.161a.481.481 0 00-.476-.287l-2.871.211a15.945 15.945 0 00-3.608-3.436l.085-2.87a.481.481 0 00-.307-.463L23.968.03a.481.481 0 00-.537.135l-1.86 2.156a17.74 17.74 0 00-4.979.128L14.605.377a.481.481 0 00-.54-.108L8.904 2.526a.481.481 0 00-.287.476l.212 2.871a15.94 15.94 0 00-3.437 3.608l-2.869-.084a.484.484 0 00-.463.307L.033 14.956a.481.481 0 00.134.537l2.156 1.86a17.754 17.754 0 00.128 4.978L.379 24.319a.481.481 0 00-.108.54l2.258 5.161a.479.479 0 00.476.287l2.871-.211a15.942 15.942 0 003.609 3.439l-.084 2.869a.481.481 0 00.307.463l5.251 2.023a.481.481 0 00.537-.134l1.86-2.156a17.741 17.741 0 004.978-.128l1.988 2.072a.481.481 0 00.54.108l5.161-2.257a.481.481 0 00.287-.476l-.212-2.871a15.938 15.938 0 003.437-3.608l2.869.084a.478.478 0 00.463-.307l2.027-5.252a.481.481 0 00-.134-.537zm-.512 6.986l-2.793-.082a.478.478 0 00-.414.216 14.991 14.991 0 01-3.572 3.75.481.481 0 00-.194.422l.206 2.794-4.54 1.985-1.933-2.015a.481.481 0 00-.433-.14 16.806 16.806 0 01-5.2.134.48.48 0 00-.426.163l-1.808 2.093-4.615-1.786.082-2.793a.481.481 0 00-.213-.407 14.993 14.993 0 01-3.75-3.572.478.478 0 00-.422-.194l-2.793.206-1.989-4.545L3.3 22.852a.481.481 0 00.14-.433 16.8 16.8 0 01-.134-5.2.481.481 0 00-.163-.426L1.051 14.99l1.783-4.619 2.793.082a.485.485 0 00.414-.214 14.994 14.994 0 013.572-3.75.481.481 0 00.194-.422l-.206-2.794 4.541-1.983 1.933 2.015a.481.481 0 00.434.14 16.782 16.782 0 015.2-.134.481.481 0 00.426-.163l1.806-2.094 4.619 1.783-.082 2.793a.481.481 0 00.214.414 14.991 14.991 0 013.75 3.572.482.482 0 00.422.194l2.793-.206 1.987 4.541-2.019 1.931a.481.481 0 00-.14.434 16.8 16.8 0 01.135 5.2.481.481 0 00.163.426l2.093 1.807z"
                        data-name="Path 51377"
                    ></path>
                    <path
                        d="M217.535 168.977h-2.02l1.849-4.833a.526.526 0 00-.491-.714h-5.033a.526.526 0 00-.52.447l-1.314 8.678a.526.526 0 00.52.6h1.734l-.272 5.941a.526.526 0 00.991.267l5.021-9.617a.526.526 0 00-.466-.769zm-4.388 7.8l.188-4.117a.526.526 0 00-.525-.55h-1.673l1.155-7.627h3.817l-1.849 4.833a.526.526 0 00.491.714h1.916z"
                        data-name="Path 51378"
                        transform="translate(-194.567 -152.077)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
