import React from 'react';
import ButtonNew from '../atoms/Button';
import './upcomingTaskPrimaryCard.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CircularProgress } from '@material-ui/core';
import DOMPurify from 'dompurify';
// import History from '../../routes/History';
type initialProps = {
    history? :any;
    image?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    taskProgressValue?: any;
    saveMoneyTasks?: any;
    getStarted?: any;
    loader?: boolean;
    bullet_points?: any;
};

const UpcomingTaskPrimaryCard = (props: initialProps) => {
    // console.log(props)
    var html = DOMPurify.sanitize(props.bullet_points)
    var new_html = html.replaceAll("<p>","")
    var new_html1 = new_html.replaceAll("</p>","")
    var new_html2 = new_html1.replaceAll("&lt;","<")
    var new_html3 = new_html2.replaceAll("&gt;",">")
    return (
        <div className="UpcomingTaskPrimaryCardMain UpcomingTaskPrimaryCardMain_big">
            <div className="UpcomingTaskFirstTitle">{props.title}</div>
            <div className="UpcomingTaskPrimaryCardLeftDiv">               
                <div className="UpcomingTaskFirstDescription">{props.description}</div>
                <div className="progress">
                    <div className="progressDiv">
                        <div className="progressBarDiv">
                            <LinearProgress
                                className=""
                                variant="determinate"
                                value={props.taskProgressValue ? props.taskProgressValue : 0}
                                style={{
                                    backgroundColor: '#E0E0E0',
                                    borderRadius: '15px',
                                    height: '7px',
                                }}
                            />
                        </div>
                        <div className="completedPercent">{`${
                            props.taskProgressValue ? props.taskProgressValue : '0'
                        }% completed`}</div>
                    </div>
                </div>
                
                <div
                    className='first_card_bullet_points'
                    // dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.bullet_points) }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(new_html3) }}
                    // dangerouslySetInnerHTML={{
                    //     __html:
                    //     props.bullet_points
                    // }}
                />
                <div className="checkDetailsMainDiv">
                    {props.saveMoneyTasks &&
                        props.saveMoneyTasks.map((item: any, i: number) => {
                            return (
                                <div className="checkDetailsDiv" key={i}>
                                    <CheckCircleIcon className={item.completed ? 'checkIconCompleted' : 'checkIcon'} />
                                    <div className="checkTitleDiv">{item.name}</div>
                                </div>
                            );
                        })}
                </div>
                {props.loader ? (
                    <CircularProgress />
                ) : (
                    <div className="UpcomingTaskFirstButtonDiv">
                        {/* <a href={props.getStarted}>
                            <ButtonNew className="buttonUpcomingTaskFirst"
                            // onClick={() => { props.history.push(props.getStarted) }} 
                            //  onClick={() => { History.push(props.getStarted) }} 
                            // onClick={props.getStarted}
                            >
                                {props.buttonText}
                            </ButtonNew>
                        </a> */}
                         <ButtonNew className="buttonUpcomingTaskFirst"
                            // onClick={() => { props.history.push(props.getStarted) }} 
                            //  onClick={() => { History.push(props.getStarted) }} 
                            onClick={props.getStarted}
                            >
                                {props.buttonText}
                            </ButtonNew>
                    </div>
                )}
            </div>
            <div className="imageUpcomingTaskFirstDiv UpcomingTaskPrimaryCardRightDiv">
                <img src={props.image} className="imageUpcomingTaskFirst" alt="" />
            </div>
        </div>
    );
};

export default UpcomingTaskPrimaryCard;
