import React, { Component } from 'react';
import ButtonNew from '../../../components/atoms/Button';
import Brokerage from '../../../Assets/images/MovingImages/brokerage';
import PODSSvg from '../../../Assets/images/Moving/DIYmovingPodsBanner.png';
import PODSSvgBlue from '../../../Assets/images/Moving/podsBlueBanner.svg';
import PODS from '../../../Assets/images/Moving/PODS.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../../store/common/actions';
import * as MoversAction from '../../../store/move/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import '../Moving.scss';
import downArrow from '../../../Assets/images/Moving/down-arrow.png';
import Modal from '../../../components/atoms/Modal';
import CheckIcon from '@material-ui/icons/Check';
import './diy.scss';
import DiyMovingCard from '../../../components/atoms/DiyMovingCard';
import MovingNextCard from '../../../components/atoms/MovingNextCard';
import MovingCar from '../../../Assets/images/Moving/MovingCar.svg';
import MovingStorage from '../../../Assets/images/Moving/MovingStorage.svg';
import movingD from '../../../Assets/images/DouglasImages/dashboardIcons/carShipNew.svg';
import storageD from '../../../Assets/images/DouglasImages/dashboardIcons/storageD.png';
import { buttonClicked } from '../../../utils/utlis';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    location?: any;
    commonAction?: any;
    commonState?: any;
    moversAction?: any;
    moversState?: any;
};

type initialState = {
    showModal: any;
    open?: any;
    showPods?: boolean;
    buffer?: boolean;
    AnchorEl?: any;
    isKnowMoreClicked?: boolean;
    sort: string;
    diyCardDetails: any;
    isDouglasElliman: boolean;
    // portableCardDetails: any;
};

class DIYRecommendedPage extends Component<initialProps, initialState> {
    state: initialState = {
        showModal: false,
        open: false,
        showPods: false,
        AnchorEl: null,
        sort: '',
        isKnowMoreClicked: false,
        diyCardDetails: [
            // {
            //     discount: '10',
            //     logo: '',
            //     title: 'Budget Truck Rental',
            //     rate: '4.8',
            //     reviews: '122',
            //     knowMoreHandler: () => {
            //         console.log('giig');
            //     },
            // },
            // {
            //     discount: '10',
            //     logo: '',
            //     title: 'Penske Truck Rentals',
            //     rate: '4.8',
            //     reviews: '122',
            //     knowMoreHandler: () => {
            //         console.log('giig');
            //     },
            // },
            // {
            //     discount: '10',
            //     logo: '',
            //     title: 'U-Haul Truck Rentals',
            //     rate: '4.8',
            //     reviews: '122',
            //     knowMoreHandler: () => {
            //         console.log('giig');
            //     },
            // },
        ],
        // portableCardDetails: [
        //     {
        //         discount: '10',
        //         logo: '',
        //         title: 'Budget Truck Rental',
        //         knowMoreHandler: () => {
        //             console.log('giig');
        //         },
        //     },
        //     {
        //         discount: '10',
        //         logo: '',
        //         title: 'Penske Truck Rentals',
        //         knowMoreHandler: () => {
        //             console.log('giig');
        //         },
        //     },
        // ],
        isDouglasElliman: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        this.setState({ showModal: true });
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.moversAction.diy();
        setTimeout(() => {
            this.setState({ showModal: false });
        }, 2000);

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }

        if (slug) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Page Viewed',
                {
                    page: 'Moving Services DIY',
                    category_name: 'Moving Services',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );

            analytics.track(
                `Banner Viewed`,
                {
                    page: 'Moving Services DIY',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    category_name: 'Moving Services',
                    banner: true,
                    banner_title: 'Realtor or Brokerage Recommended',
                    brand_name: 'Portable Storage Containers',
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.moversState &&
            nextProps.moversState.diy &&
            nextProps.moversState.diy.length &&
            nextProps.moversState.diyDataArrived
        ) {
            nextProps.moversState.diyDataArrived = false;
            let podsData: any = [];
            nextProps.moversState.diy.map((item: any) => {
                podsData.push(item);
            });
            currentState.diyCardDetails = podsData;
        }
        return currentState;
    }

    handleBookNow = () => {
        this.setState({
            showPods: true,
        });
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        let brokerage: any;
        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;
        let agent_key: any = slug.realtor_key;

        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Moving Services DIY',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Services',
            'Book Now',
            false,
            '',
            false,
            '',
            '',
            true,
            'Your personal moving and storage service',
            false,
            '',
            '',
            move_id,
        );
    };

    handleCloseModal = () => {
        this.setState({ open: false });
    };

    handleClosePODSModal = () => {
        this.setState({ showPods: false });

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        let brokerage: any;
        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;
        let agent_key: any = slug.realtor_key;

        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Moving Services DIY',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Services',
            'Continue to PODS Website',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    handleClosePODSModalCancel = () => {
        this.setState({ showPods: false });

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        let brokerage: any;
        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;
        let agent_key: any = slug.realtor_key;

        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Moving Services DIY',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Services',
            'Cancel',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleCancel = () => {
        this.setState({
            showPods: false,
        });
    };

    handleLTH = () => {
        this.setState({ sort: 'ascending' });
        this.handleClose();
    };

    handleHTL = () => {
        this.setState({ sort: 'descending' });
        this.handleClose();
    };

    onClickKnowMore = () => {
        this.setState({ isKnowMoreClicked: true });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Moving Services DIY',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Services',
            'Learn More',
            false,
            '',
            false,
            '',
            '',
            true,
            'Realtor or Brokerage Recommended',
            false,
            '',
            '',
            move_id,
        );
    };

    onCloseModalKnowMore = () => {
        this.setState({ isKnowMoreClicked: false });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;

        const breadList = [
            {
                name: 'Moving Services',
                url: ''
            }
        ]

        return (
            <div className={isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'}>
                {this.state.showModal ? (
                    <div className="loaderInternetRecomendation">
                        <div className="modalHead">Just a moment we are finding a top picks for you </div>
                        <div className="modalSubHead">
                            We are analyzing your answer and what people similar to you bought
                        </div>
                        <div className="main-divv">
                            <div className="modalData">
                                <CheckCircleIcon />
                                <div className="textModal">Searching through our best movers for deals that are…</div>
                            </div>
                            <div className="modalData1">
                                <CheckCircleIcon />
                                <div className="textModal1">
                                    Available in{' '}
                                    {this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].source_full_address}
                                </div>
                            </div>
                            <div className="modalData2">
                                <CheckCircleIcon />
                                <div className="textModal2">A good day for moving your home</div>
                            </div>
                            <div className="modalData3">
                                <CheckCircleIcon />
                                <div className="textModal3">Applying best deals for PODS</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="main-head-2">
                            <h2 className="heading">Moving Services</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className="tab-div-moving">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/movers' ||
                                        this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/dashboard/movers/recommended',
                                        state: this.props.history.location.state.diy,
                                    });
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Moving
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    this.props.history.location.pathname === '/dashboard/movers/diyrecommended'
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                            >
                                DIY
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/autoease' ||
                                        this.props.history.location.pathname === `/dashboard/autoease/`)
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/autoease');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Auto Shipping
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/storage' ||
                                        this.props.history.location.pathname === `/dashboard/storage/`)
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/storage');
                                }}
                            >
                                Reserve a Storage Unit
                            </div>
                        </div>

                        <div>
                            <Dialog
                                className="dialog-box"
                                open={this.state.open}
                                onClose={this.handleCloseModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
                                    {'Oops. Only Three Movers are Allowed for Selection'}
                                </DialogTitle>

                                <DialogActions>
                                    <ButtonNew onClick={this.handleCloseModal} className="Modal-button">
                                        Ok
                                    </ButtonNew>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div className="moving-main-div">
                            <div className="diy-detail-div">
                                <div
                                    className="black-card-moving"
                                    style={{
                                        background: slug && slug.color_shade1 ? `${slug.color_shade1}` : '#08192f',
                                    }}
                                >
                                    <div className="main-card-moving">
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                                Realtor or Brokerage Recommended
                                            </div>
                                            <div style={{ fontSize: '16px', margin: '10px 0 20px 0' }}>
                                                Answer few more questions and avail for eligible discounts
                                            </div>
                                            <div>
                                                <ButtonNew
                                                    backgroundColor="#FFFFFF"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100b28'
                                                            : slug && slug.primary_color
                                                            ? `${slug.primary_color}`
                                                            : '#273E59'
                                                    }
                                                    fontSize="16px"
                                                    width="110px"
                                                    fontWeight="bold"
                                                    height="40px"
                                                    margin="2px 2px 10px 2px"
                                                    onClick={this.onClickKnowMore}
                                                >
                                                    Learn More
                                                </ButtonNew>
                                            </div>
                                        </div>
                                        {/* <img src={Brokerage} className="brokerageImage" /> */}
                                        {!isDouglasElliman && <Brokerage className="brokerageImage" />}
                                    </div>
                                    <div className="main-card-moving-mobile">
                                        <div style={{ textAlign: 'left' }}>
                                            <div className="mobile-card-heading">Looking for recommended services?</div>
                                            <div className="mobile-card-para">
                                                Check some of the best verified service providers near you
                                            </div>
                                        </div>
                                        <div className="movingmobile">
                                            <div>
                                                <ButtonNew
                                                    backgroundColor="#FFFFFF"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100b28'
                                                            : slug && slug.primary_color
                                                            ? `${slug.primary_color}`
                                                            : '#273E59'
                                                    }
                                                    fontSize="16px"
                                                    width="110px"
                                                    fontWeight="bold"
                                                    height="40px"
                                                    margin="2px 2px 10px 2px"
                                                    onClick={this.onClickKnowMore}
                                                >
                                                    Learn More
                                                </ButtonNew>
                                            </div>

                                            {!isDouglasElliman && <Brokerage className="brokerageImage" />}
                                        </div>
                                    </div>
                                </div>
                                <div className="titleFlexDiv">
                                    <div className="other-suitable-text">Rental Truck option</div>
                                    <div
                                        className="avg-customer-review"
                                        onClick={this.handleMenu}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="average">
                                            <div> Sort by: </div>
                                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                                Avg. Customer Review
                                            </div>
                                            <div className="down-arrow">
                                                <img src={downArrow} height="15px" width="15px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.AnchorEl}
                                        keepMounted
                                        open={Boolean(this.state.AnchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                        <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                    </Menu>
                                </div>

                                <div className="diyMovingCardsFlexDiv">
                                    {this.state.diyCardDetails.map((item: any, i: number) => {
                                        return (
                                            <div key={i} className="diyMovingCard">
                                                <DiyMovingCard
                                                    logo={item.logo}
                                                    discount={item.discount_offer}
                                                    title={item.name}
                                                    rate={item.ratings}
                                                    reviews={item.no_of_reviews}
                                                    onNameClick={() =>
                                                        item.website ? window.open(item.website, '_blank') : ''
                                                    }
                                                    knowMoreHandler={() => {
                                                        item.book_now_url &&
                                                        item.book_now_url != '' &&
                                                        item.book_now_url != null
                                                            ? window.open(item.book_now_url, '_blank')
                                                            : window.open(item.website, '_blank');
                                                        let brokerageCheck: any =
                                                            this.props.commonState && this.props.commonState.realtor;

                                                        let brokerage: any;

                                                        brokerage = brokerageCheck.display_name;
                                                        let brokerage_key: any = brokerageCheck.brokerage_key;
                                                        let agent_key: any = brokerageCheck.realtor_key;

                                                        let agent_name: any = brokerageCheck.contact_name;
                                                        let move_id: any = localStorage.getItem('moveKey');

                                                        buttonClicked(
                                                            'Button Clicked',
                                                            'Moving Services DIY',
                                                            brokerage,
                                                            brokerage_key,
                                                            agent_name,
                                                            agent_key,
                                                            'Moving Services',
                                                            'Learn More',
                                                            false,
                                                            '',
                                                            true,
                                                            item.name,
                                                            'DIY Moving Card',
                                                            false,
                                                            '',
                                                            false,
                                                            '',
                                                            '',
                                                            move_id,
                                                        );
                                                    }}
                                                    showKnowMore={
                                                        (item.book_now_url &&
                                                            item.book_now_url != '' &&
                                                            item.book_now_url != null) ||
                                                        (item.website && item.website != '' && item.website != null)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                {this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.state &&
                                this.props.history.location.state.pods ? (
                                    <div>
                                        <div className="other-suitable-mover">
                                            <div className="other-suitable-text">Portable Storage Containers</div>
                                            <div
                                                className="avg-customer-review"
                                                onClick={this.handleMenu}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div className="average">
                                                    <div> Sort by: </div>
                                                    <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                                        Avg. Customer Review
                                                    </div>
                                                    <div className="down-arrow">
                                                        <img src={downArrow} height="15px" width="15px" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={this.state.AnchorEl}
                                                keepMounted
                                                open={Boolean(this.state.AnchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                                <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                            </Menu>
                                        </div>

                                        <div className="pods-card">
                                            {isDouglasElliman ? (
                                                <img
                                                    src={PODSSvg}
                                                    onClick={() => this.handleBookNow()}
                                                    className="pods-image-moving"
                                                />
                                            ) : (
                                                <img
                                                    src={PODSSvgBlue}
                                                    onClick={() => this.handleBookNow()}
                                                    className="pods-image-moving"
                                                />
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.showPods ? (
                                    <div>
                                        <Dialog
                                            open={this.state.showPods}
                                            onClose={this.handleClosePODSModal}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }}>
                                                <img src={PODS} width="200px" height="70px" alt="" />
                                            </div>
                                            <DialogTitle id="alert-dialog-title">
                                                {'You will be rerouted to PODS website to complete your order'}
                                            </DialogTitle>

                                            <DialogActions>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: 'auto',
                                                        marginLeft: 'auto',
                                                    }}
                                                >
                                                    <ButtonNew
                                                        onClick={this.handleClosePODSModal}
                                                        // color="#ffffff"
                                                        width="300px"
                                                    >
                                                        <a
                                                            href="https://www.pods.com/partners/moveeasy"
                                                            target="_blank"
                                                            style={{ color: '#ffffff' }}
                                                        >
                                                            Continue to PODS Website
                                                        </a>
                                                    </ButtonNew>
                                                    <ButtonNew
                                                        onClick={this.handleClosePODSModalCancel}
                                                        width="300px"
                                                        backgroundColor="#FFFFFF"
                                                        boxShadow={
                                                            isDouglasElliman
                                                                ? 'none'
                                                                : slug && slug.primary_color
                                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                                : '0 0 3px 0 #273E59'
                                                        }
                                                        border={
                                                            isDouglasElliman
                                                                ? '1px solid #100b28'
                                                                : slug && slug.primary_color
                                                                ? `1 px solid ${slug.primary_color}`
                                                                : '1 px solid #273E59'
                                                        }
                                                        color={
                                                            isDouglasElliman
                                                                ? '#100b28'
                                                                : slug && slug.primary_color
                                                                ? `${slug.primary_color}`
                                                                : '#273E59'
                                                        }
                                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                        marginTop="20px"
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                    >
                                                        Cancel
                                                    </ButtonNew>
                                                </div>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                ) : null}

                                {/* <div className="diyMovingCardsFlexDiv">
                                    {this.state.portableCardDetails.map((item: any) => {
                                        return (
                                            <div className="diyMovingCard">
                                                <DiyMovingCard
                                                    logo={item.logo}
                                                    discount={item.discount}
                                                    title={item.title}
                                                    knowMoreHandler={item.knowMoreHandler}
                                                />
                                            </div>
                                        );
                                    })}
                                </div> */}

                                <div className="other-suitable-text">Portable Storage Containers</div>

                                <div className="diyMovingCardsFlexDiv">
                                    <div className="nextCardDiv">
                                        <MovingNextCard
                                            title="Set up your Car Shipping"
                                            subTitle="Its looks like you are travelling interstate get your shipping done"
                                            icon={isDouglasElliman ? movingD : MovingCar}
                                            buttonText="Get Started"
                                            buttonColor={
                                                isDouglasElliman
                                                    ? '#100b28'
                                                    : slug && slug.primary_font_color
                                                    ? slug.primary_font_color
                                                    : '#FFFFFF'
                                            }
                                            height="140px"
                                            padding="0px 0px 15px 0px"
                                            buttonBackGroundColor="#fff"
                                            buttonShadow={
                                                slug && slug.primary_color
                                                    ? `0 0 3px 0 ${slug.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            onClick={() => {
                                                this.props.history.push('/dashboard/autoease');
                                                window.scrollTo(0, 0);
                                                let brokerageCheck: any =
                                                    this.props.commonState && this.props.commonState.realtor;

                                                let brokerage: any;

                                                brokerage = brokerageCheck.display_name;
                                                let brokerage_key: any = brokerageCheck.brokerage_key;
                                                let agent_key: any = brokerageCheck.realtor_key;

                                                let agent_name: any = brokerageCheck.contact_name;
                                                let move_id: any = localStorage.getItem('moveKey');

                                                buttonClicked(
                                                    'Button Clicked',
                                                    'Moving Services DIY',
                                                    brokerage,
                                                    brokerage_key,
                                                    agent_name,
                                                    agent_key,
                                                    'Moving Services',
                                                    'Get Started',
                                                    false,
                                                    '',
                                                    true,
                                                    'Set up your Car Shipping',
                                                    'DIY Moving Card',
                                                    false,
                                                    '',
                                                    false,
                                                    '',
                                                    '',
                                                    move_id,
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="nextCardDiv">
                                        <MovingNextCard
                                            title="Get Storage Facility"
                                            subTitle="Best quotes on storage facility guaranteed"
                                            icon={isDouglasElliman ? storageD : MovingStorage}
                                            buttonText="Learn More"
                                            buttonColor={
                                                isDouglasElliman
                                                    ? '#100b28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#002E60'
                                            }
                                            height="140px"
                                            padding="0px 0px 15px 0px"
                                            buttonBackGroundColor="#fff"
                                            buttonShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : slug && slug.primary_color
                                                    ? `0 0 3px 0 ${slug.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            onClick={() => {
                                                this.props.history.push('/dashboard/storage');
                                                let brokerageCheck: any =
                                                    this.props.commonState && this.props.commonState.realtor;

                                                let brokerage: any;

                                                brokerage = brokerageCheck.display_name;
                                                let brokerage_key: any = brokerageCheck.brokerage_key;
                                                let agent_key: any = brokerageCheck.realtor_key;

                                                let agent_name: any = brokerageCheck.contact_name;
                                                let move_id: any = localStorage.getItem('moveKey');

                                                buttonClicked(
                                                    'Button Clicked',
                                                    'Moving Services DIY',
                                                    brokerage,
                                                    brokerage_key,
                                                    agent_name,
                                                    agent_key,
                                                    'Moving Services',
                                                    'Learn More',
                                                    false,
                                                    '',
                                                    true,
                                                    'Get Storage Facility',
                                                    'DIY Moving Card',
                                                    false,
                                                    '',
                                                    false,
                                                    '',
                                                    '',
                                                    move_id,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.isKnowMoreClicked ? (
                    <Modal
                        isShowModal={this.state.isKnowMoreClicked}
                        onCloseModal={this.onCloseModalKnowMore}
                        showClose={true}
                        className="movingModal"
                    >
                        <div className="ModalTitle">How do we choose our vendors?</div>
                        <div className="ModalSubtitle">
                            We match the best vendors to assist you with your moving needs:
                        </div>
                        <div className="ModalSubtitle">Our Requirements</div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Have to have been in business for at least{' '}
                                <span className="requirementsTextBold">5 years</span>
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Should be <span className="requirementsTextBold">licensed</span> and{' '}
                                <span className="requirementsTextBold">insured</span>
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                <span className="requirementsTextBold">A</span> or{' '}
                                <span className="requirementsTextBold">A+ BBB</span> rated
                            </div>
                        </div>
                        <div className="ModalSubtitle">Other things we look at</div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Any <span className="requirementsTextBold">affiliations</span> or{' '}
                                <span className="requirementsTextBold">badges</span> like ProMover or Angie’s Super
                                Service
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Their unfiltered <span className="requirementsTextBold">Google</span> &{' '}
                                <span className="requirementsTextBold">Yelp ratings</span>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        moversAction: bindActionCreators(MoversAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    moversState: state.move,
});

export default connect(mapStateToProps, mapDispatchToProps)(DIYRecommendedPage);
