import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Store } from 'react-notifications-component';
import {
    getMakesSuccess,
    getMakesFailure,
    getModelsFailure,
    getModelsSuccess,
    postShippingFailure,
    postShippingSuccess,
} from './actions';
import { GET_MAKES, GET_MODELS, POST_SHIPPING } from './actionTypes';
import apiJunction from '../../utils/api';
import { getErrorMessage } from '../errorHandler';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* getMakes() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/concierge/autoease/get_makes/`,
        });

        if (response.data && response.status === 200) {
            yield put(getMakesSuccess({ getMakes: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getMakesFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* getModels(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/concierge/autoease/get_models/?model=${action.payload}`,
        });

        if (response.data && response.status === 200) {
            yield put(getModelsSuccess({ getModels: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getModelsFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

//post Shipping
function* postShipping(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/concierge/shipping-price/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(postShippingSuccess({ postShipping: response.data }));
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e: any) {
        yield put(postShippingFailure({ error: getErrorMessage(e) }));
        Store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

export default function* autoShippingSaga() {
    yield all([takeLatest(GET_MAKES, getMakes)]);
    yield all([takeLatest(GET_MODELS, getModels)]);
    yield all([takeLatest(POST_SHIPPING, postShipping)]);
}
