import {
    GET_MAKES,
    GET_MAKES_ERROR,
    GET_MAKES_SUCCESS,
    GET_MODELS,
    GET_MODELS_ERROR,
    GET_MODELS_SUCCESS,
    POST_SHIPPING,
    POST_SHIPPING_ERROR,
    POST_SHIPPING_SUCCESS
} from './actionTypes';

import { AutoShippingActions, AutoShippingState } from './types';

const initialState: AutoShippingState = {
    getMakes: [],
    getModels:[],
    postShipping:[],
    postDataArrived:false
};

export default (state = initialState, action: AutoShippingActions) => {
    switch (action.type) {
        case GET_MAKES:
            return {
                ...state,
                getMakes: [],
                error: null,
            };
        case GET_MAKES_SUCCESS:
            return {
                ...state,
                getMakes: action.payload.getMakes,
                error: null,
            };
        case GET_MAKES_ERROR:
            return {
                ...state,
                getMakes: [],
                error: action.payload.error,
            };

            case GET_MODELS:
                return {
                    ...state,
                    getModels: [],
                    error: null,
                };
            case GET_MODELS_SUCCESS:
                return {
                    ...state,
                    getModels: action.payload.getModels,
                    error: null,
                };
            case GET_MODELS_ERROR:
                return {
                    ...state,
                    getModels: [],
                    error: action.payload.error,
                };
                case POST_SHIPPING:
                    return {
                        ...state,
                        postShipping: [],
                        error: null,
                    };
                case POST_SHIPPING_SUCCESS:
                    return {
                        ...state,
                       postShipping: action.payload.postShipping,
                       postDataArrived:true,
                        error: null,
                    };
                case POST_SHIPPING_ERROR:
                    return {
                        ...state,
                       postShipping: [],
                        error: action.payload.error,
                    };

        default:
            return {
                ...state,
            };
    }
};
