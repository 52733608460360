import {
    GET_OFFERS,
    GET_OFFERS_ERROR,
    GET_OFFERS_SUCCESS,
    POST_SECURITY,
    POST_SECURITY_ERROR,
    POST_SECURITY_SUCCESS

} from './actionTypes';

import { OffersActions,OffersState } from './types';

const initialState: OffersState = {
    offers: [],
    PostSecurity:[],
    securityDataPosted:false

};

export default (state = initialState, action: OffersActions) => {
    switch (action.type) {

        case GET_OFFERS:
            return {
                ...state,
                offers: [],
                error: null,
            };
        case GET_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.payload.offers,
                error: null,
            };
        case GET_OFFERS_ERROR:
            return {
                ...state,
                offers: [],
                error: action.payload.error,
            };
            case POST_SECURITY:
                return {
                    ...state,
                    PostSecurity: [],
                    error: null,
                    securityDataPosted:false,
                };
            case POST_SECURITY_SUCCESS:
                return {
                    ...state,
                   PostSecurity: action.payload.postSecurity,
                   securityDataPosted:true,
                    error: null,
                };
            case POST_SECURITY_ERROR:
                return {
                    ...state,
                   PostSecurity: [],
                   securityDataPosted:false,
                    error: action.payload.error,

                };

            
        default:
            return {
                ...state,
            };
    }
};
