export const POST_SERVICE = 'POST_SERVICE';
export const POST_SERVICE_SUCCESS = 'POST_SERVICE_SUCCESS';
export const POST_SERVICE_ERROR = 'POST_SERVICE_ERROR';

export const GET_SERVICE_PROVIDER = 'GET_SERVICE_PROVIDER';
export const GET_SERVICE_PROVIDER_SUCCESS = 'GET_SERVICE_PROVIDER_SUCCESS';
export const GET_SERVICE_PROVIDER_ERROR = 'GET_SERVICE_PROVIDER_ERROR';

export const GET_DIGITAL_SERVICE_PROVIDER = 'GET_DIGITAL_SERVICE_PROVIDER';
export const GET_DIGITAL_SERVICE_PROVIDER_SUCCESS = 'GET_DIGITAL_SERVICE_PROVIDER_SUCCESS';
export const GET_DIGITAL_SERVICE_PROVIDER_ERROR = 'GET_DIGITAL_SERVICE_PROVIDER_ERROR';

export const GET_INSURANCE_PROVIDER = 'GET_INSURANCE_PROVIDER';
export const GET_INSURANCE_PROVIDER_SUCCESS = 'GET_INSURANCE_PROVIDER_SUCCESS';
export const GET_INSURANCE_PROVIDER_ERROR = 'GET_INSURANCE_PROVIDER_ERROR';
