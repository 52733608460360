import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={60.733}
        height={props.height ? props.height : 31}
        viewBox="0 0 37.938 31"
    >
        <g data-name="Group 35695">
            <path
                data-name="Path 42872"
                d="M7.878 11.258a1.246 1.246 0 0 1 1.246-1.246h13.542l-1.383-1.387a1.245 1.245 0 0 1 1.761-1.761l3.507 3.508a1.24 1.24 0 0 1 .272.409l.006.019a1.207 1.207 0 0 1 0 .909l-.006.018a1.244 1.244 0 0 1-.272.409l-3.507 3.507a1.245 1.245 0 1 1-1.761-1.761l1.383-1.383H9.124a1.245 1.245 0 0 1-1.246-1.246m40.194 11.993a.584.584 0 0 1-.583.582H2.243a.583.583 0 0 1-.582-.582v-.151a.584.584 0 0 1 .582-.583h32.553V8.963a12.775 12.775 0 0 1 11.9 12.724v.811l.807.022a.579.579 0 0 1 .567.58Zm-9.518 6.088a2.67 2.67 0 0 1-2.667-2.667 2.638 2.638 0 0 1 .278-1.178h4.776a2.625 2.625 0 0 1 .278 1.178 2.669 2.669 0 0 1-2.666 2.667m-26.1 0a2.67 2.67 0 0 1-2.667-2.667 2.628 2.628 0 0 1 .279-1.178h4.775a2.639 2.639 0 0 1 .279 1.178 2.67 2.67 0 0 1-2.667 2.667M1.658 3.734a2.083 2.083 0 0 1 2.081-2.08H31.05a2.083 2.083 0 0 1 2.081 2.08v17.115H3.742a2.083 2.083 0 0 1-2.081-2.081Zm46.686 17.291A14.438 14.438 0 0 0 34.796 7.3V3.74a3.746 3.746 0 0 0-3.742-3.741H3.742A3.746 3.746 0 0 0 0 3.74v15.035a3.711 3.711 0 0 0 .969 2.49A2.232 2.232 0 0 0 0 23.1v.151a2.246 2.246 0 0 0 2.243 2.243H8.3a4.259 4.259 0 0 0-.173 1.178 4.327 4.327 0 1 0 8.655 0 4.289 4.289 0 0 0-.173-1.178h17.79a4.257 4.257 0 0 0-.173 1.178 4.327 4.327 0 1 0 8.654 0 4.259 4.259 0 0 0-.173-1.178h4.782a2.246 2.246 0 0 0 2.244-2.243V23.1a2.228 2.228 0 0 0-1.389-2.074"
                fill={window.location.pathname.includes('movers') ? '#009CBD' : '#100b28'}
            />
        </g>
    </svg>
);

export default SvgComponent;
