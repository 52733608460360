import React, { ChangeEvent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import './Main.scss';
import Slider from '@material-ui/core/Slider';
import SlidingImage from '../../../../../Assets/images/HomeValuation/sliderIconthumb.png'


interface defaultProps {
    value: number;
    setState: any;
    disabled?: boolean;
}

const marks = [
    {
        value: 0,
        label: '$50',
    },
    {
        value: 20,
        label: '$100',
    },
    {
        value: 40,
        label: '$150',
    },
    {
        value: 60,
        label: '$200',
    },
    {
        value: 80,
        label: '$250',
    },
    {
        value: 100,
    },
];

function valuetext(label: number) {
    return `${label}°C`;
}

export default function DiscreteSlider({ value, setState, disabled }: defaultProps) {
    const PrettoSlider = withStyles({

        root: {
            color: '#006DFF',
            height: 2,
            marginBottom: 30,
            marginLeft: 10,
        },
        thumb: {
            height: 22,
            width: 22,
            backgroundColor: 'transparant',
            border: '2px solid currentColor',
            marginTop: -11,
            marginLeft: -9,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            backgroundImage: `url(${SlidingImage})`,
            backgroundPosition: 'center'
        },

        active: {},
        valueLabel: {
            height: 2,
            marginBottom: 100,
            left: 'calc(-50% + 20px)',
            top: 22,
            '& *': {
                background: 'transparent',
            },
        },

        track: {
            height: 4,
            marginLeft: -10,
        },
        rail: {
            height: 4,
            opacity: 0.5,
            marginLeft: -10,
            backgroundColor: '#bfbfbf',
        },
        mark: {
            backgroundColor: '#ffffff',
            height: 8,
            color: '#ffffff',
            width: 4,
            marginLeft: -10,
            marginBottom: 100,
            opacity: 1,
        },
        markActive: {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    })(Slider);

    const handleChange = (event: ChangeEvent<{}>, value: number | number[]) => {
        console.debug(event);
        setState({ sliderValue: value });
    };

    return (
        <div style={{ width: '90%' }}>
            <div className="headingText">Select Additional Monthly Payment</div>
            <PrettoSlider
                // valueLabelFormat={valueLabelFormat}
                defaultValue={value}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                step={1}
                marks={marks}
                onChange={handleChange}
                disabled={disabled ? disabled : false}

            // valueLabelDisplay="on"
            />
        </div>
    );
}
