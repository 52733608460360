import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 41.965}
        height={props.height ? props.height : 35}
        viewBox="0 0 41.965 35"
    >
        <path
            data-name="Path 42870"
            d="M19.465 17.885a10.071 10.071 0 0 0 .551 1.4H9.843a.7.7 0 0 1 0-1.4Zm-9.622 4.011h11.955a10.3 10.3 0 0 0 1.6 1.4H9.843a.7.7 0 0 1 0-1.4M21.798 7.918H9.843a.7.7 0 0 1 0-1.4h13.559a10.3 10.3 0 0 0-1.6 1.4M9.145 11.234a.7.7 0 0 1 .7-.7H20.02a10.105 10.105 0 0 0-.551 1.4H9.843a.7.7 0 0 1-.7-.7m0 3.675a.7.7 0 0 1 .7-.7h9.212c-.015.232-.036.463-.036.7s.02.467.036.7H9.843a.7.7 0 0 1-.7-.7M2.3 33.609a.907.907 0 0 1-.906-.906v-2.978h23.378v2.978a2.292 2.292 0 0 0 .186.906Zm26.256-8.5v7.581a1.05 1.05 0 0 1-1.152.906h-.384a.9.9 0 0 1-.855-.9V29.02a.7.7 0 0 0-.7-.7h-20.4V1.396h23.492v3.311c.232-.016.462-.036.7-.036s.466.02.7.036V.698a.7.7 0 0 0-.7-.7H4.376a.7.7 0 0 0-.7.7v27.626H.696a.7.7 0 0 0-.7.7V32.7a2.3 2.3 0 0 0 2.3 2.3h24.778c.01 0 .019-.006.029-.006h.311a2.432 2.432 0 0 0 2.538-2.3v-7.581c-.232.016-.462.036-.7.036s-.466-.02-.7-.036m5.417-11.626a.349.349 0 0 1-.127.39l-2.506 1.821.958 2.947a.349.349 0 0 1-.537.39l-2.506-1.821-2.506 1.821a.349.349 0 0 1-.537-.39l.957-2.947-2.506-1.821a.349.349 0 0 1 .205-.631h3.1l.956-2.953a.364.364 0 0 1 .664 0l.957 2.947h3.1a.35.35 0 0 1 .332.241m-4.719 8.172A6.746 6.746 0 1 1 36 14.907a6.754 6.754 0 0 1-6.746 6.746m0-14.888a8.142 8.142 0 1 0 8.142 8.141 8.151 8.151 0 0 0-8.142-8.141"
            fill={window.location.pathname.includes('mortgage') ? '#009CBD' : '#100b28 !important'}
        />
    </svg>
);

export default SvgComponent;
