import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 67.04}
        height={props.height ? props.height : 29.47}
        viewBox="0 0 67.04 29.47"
    >
        <path
            data-name="Path 42876"
            d="m42.373 10.301-8.853-6.03-8.854 6.03v12.582h17.707Zm5.325 1.132a1.031 1.031 0 0 1-.582 1.883 1.02 1.02 0 0 1-.579-.179l-2.1-1.43v12.208a1.032 1.032 0 0 1-1.031 1.031H23.637a1.032 1.032 0 0 1-1.031-1.031V11.707l-2.1 1.43a1.031 1.031 0 1 1-1.161-1.7l13.6-9.262a1.029 1.029 0 0 1 1.161 0ZM7.33 1.76A1.031 1.031 0 0 0 5.872.302a20.1 20.1 0 0 0 0 28.4 1.031 1.031 0 1 0 1.458-1.458 18.037 18.037 0 0 1 0-25.479m5.788 2.267a1.032 1.032 0 0 0-1.458 0 14.828 14.828 0 0 0 0 20.945 1.031 1.031 0 1 0 1.458-1.458 12.764 12.764 0 0 1 0-18.029 1.03 1.03 0 0 0 0-1.458M61.168.307a1.031 1.031 0 0 0-1.458 1.458 18.037 18.037 0 0 1 0 25.479 1.031 1.031 0 1 0 1.458 1.458 20.1 20.1 0 0 0 0-28.4m-5.788 24.67a1.031 1.031 0 1 1-1.458-1.458 12.763 12.763 0 0 0 0-18.029 1.031 1.031 0 0 1 1.458-1.458 14.828 14.828 0 0 1 0 20.945"
            fill={window.location.pathname.includes('home-security') ? '#009CBD' : '#100b28'}
        />
    </svg>
);

export default SvgComponent;
