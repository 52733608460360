import React from 'react';
import Select from 'react-select';
import '../../styles/reactSelect.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FormHelperText, InputLabel } from '@material-ui/core';

interface ISelectInputProps {
    className?: string;
    onChange?: any;
    placeholder?: string;
    value?: any;
    disabled?: any;
    options?: any;
    label?: string;
    formatOptionLabel?: any;
    error?: boolean;
    helperText?: string,
    required?: boolean;
    defaultValue?: any;
    isLoading?: boolean;
    heading?: string;
    classNameHeading?: string;
    cyData?: string;
}

const SelectInput = (props: ISelectInputProps) => {
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div style={{ width: '100%' }}>
            {props.heading ? <h3 className={props.classNameHeading}>{props.heading}</h3> : null}
            {props.label ? (
                <InputLabel
                    htmlFor="bootstrap-input"
                    className={'text-black ml-2 mb-2 mt-3'}
                    style={{ fontFamily: 'Poppins', color: '#162855' }}
                >
                    {props.required ? `${props.label} *` : props.label}
                </InputLabel>
            ) : null}
            <Select
                style={{ margin: '20px' }}
                IconComponent={<KeyboardArrowDownIcon />}
                classNamePrefix="select-new"
                className={
                    props.className
                        ? props.className
                        : isDouglasElliman
                        ? 'react-select projec treact-selectD'
                        : 'react-select project'
                }
                helperText={props.helperText}
                onChange={props.onChange}
                options={props.options}
                placeholder={props.placeholder}
                value={props.value}
                isDisabled={props.disabled}
                formatOptionLabel={props.formatOptionLabel ? props.formatOptionLabel : null}
                defaultValue={props.defaultValue}
                isLoading={props.isLoading}
                id={props.cyData}
            />
            {props.error && <FormHelperText>{props.helperText}</FormHelperText> }
        </div>
    );
};

export default SelectInput;
