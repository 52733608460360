import React, { Component } from 'react';
import ButtonNew from '../../components/atoms/Button';
import locationIcon from '../../Assets/images/MovingImages/location-icon.svg';
import GetAQuoteCard from '../../components/atoms/GetAQuoteCard';
import addArrowIcon from '../../Assets/images/MovingImages/add-arrow-icon.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/move/actions';
import * as CommonAction from '../../store/common/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import './trustedProsRecommended.scss';
import * as DashboardAction from '../../store/dashboard/actions';
import FiltersTrusted from './FiltersTrusted';
import TrustedProsCard from '../../components/atoms/TrustedProsCard';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    onSelectQuote?: any;
    selectedItems?: any;
    isDataArrived?: boolean;
    checkedItemsAlmost?: any;
    fromAlmost?: string;
    history?: any;
    location?: any;
    trustedProsAction: any;
    trustedProsState: any;
    dashboardAction: any;
    CheckListDashboardState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {
    quote: boolean;
    isDataArrived: boolean;
    isDataArrived2: boolean;
    showModal: any;
    checkBoxes: string[];
    checkedItems: any[];
    open?: any;
    date?: any;
    timeOfDay?: any;
    serviceId?: any;
    serviceName?: any;
    buffer?: boolean;
    vendors?: any[];
    buffer2?: boolean;
    homeTaskId?: any;
    buffer3?: boolean;
    isShowMobileFilter: boolean;
    checklistDataArrived: boolean;
    isHomeAdvisorDataArrived: boolean;
    homeAdvisorData: any;
    isMoveDataArrived: boolean;
    destinationAddress: string;
};

class TrustedProsRecommended extends Component<initialProps, initialState> {
    state: initialState = {
        quote: false,
        date: [
            { name: 'Tomorrow', value: false },
            { name: 'Within 3 days', value: false },
            { name: ' Within a week', value: false },
            { name: ' Within 30 days', value: false },
        ],
        timeOfDay: [
            { name: 'Morning (8am - 12pm)', value: false },
            { name: ' Afternoon (12pm - 5pm)', value: false },
            { name: 'Evening (5pm - 9.30pm)', value: false },
            { name: 'I’m flexible', value: false },
        ],
        isDataArrived: true,
        isDataArrived2: true,
        showModal: false,
        checkBoxes: [],
        checkedItems: [],
        open: false,
        buffer: false,
        serviceId: '',
        serviceName: '',
        vendors: [],
        buffer2: false,
        buffer3: false,
        homeTaskId: '',
        isShowMobileFilter: false,
        checklistDataArrived: true,
        isHomeAdvisorDataArrived: true,
        homeAdvisorData: [],
        isMoveDataArrived: true,
        destinationAddress: '',
    };

    componentDidMount() {
        let CityStateZip: any = localStorage.getItem('CityStateZip');
        if (CityStateZip) {
            this.props.trustedProsAction.vendors({
                address: CityStateZip,
            });
        } else {
            this.props.trustedProsAction.vendors({
                address: '',
            });
        }
        // this.props.trustedProsAction.vendors();
        this.setState({ showModal: true });

        setTimeout(() => {
            this.setState({ showModal: false });
        }, 4000);

        if (
            !(
                this.props &&
                this.props.CheckListDashboardState &&
                this.props.CheckListDashboardState.checklist &&
                this.props.CheckListDashboardState.checklist.length
            )
        ) {
            this.props.dashboardAction.checklist();
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
    }

    dateChangeHandler = (item: any) => {
        this.setState({
            date: item,
        });
    };

    changeTimeHandler = (item: any) => {
        this.setState({
            timeOfDay: item,
        });
    };

    onClickEditPreference = () => {
        this.setState({ isShowMobileFilter: true });
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.vendors &&
            nextProps.trustedProsState.vendors.length > 0
            // currentState.isDataArrived
        ) {
            // currentState.isDataArrived = false;
            if (
                nextProps &&
                nextProps.location &&
                nextProps.location.state &&
                nextProps.location.state.service_name &&
                nextProps.location.state.id
            ) {
                currentState.serviceName = nextProps.location.state.service_name;
                currentState.serviceId = nextProps.location.state.id;
            }
            nextProps.trustedProsState.vendors.map((item: any) => {
                if (item.name == currentState.serviceName) {
                    currentState.vendors = item.vendors;
                }
            });
        }
        if (
            nextProps &&
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            nextProps.history.location.state.service_name &&
            nextProps.history.location.state.id &&
            nextProps.history.location.state.homeAdvisorData &&
            nextProps.history.location.state.homeAdvisorData.length > 0
        ) {
            // currentState.isDataArrived2 = false

            currentState.serviceName = nextProps.history.location.state.service_name;
            currentState.serviceId = nextProps.history.location.state.id;
            currentState.vendors = nextProps.history.location.state.homeAdvisorData;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.move && currentState.isMoveDataArrived) {
            nextProps.commonState.move.map((item: any) => {
                currentState.isMoveDataArrived = false;
                currentState.destinationAddress =
                    item.destination && item.destination_full_address ? item.destination_full_address : '';
            });
        }

        return currentState;
    }

    handleCheckBoxes = (name: string, item: any) => {
        let checkBoxesNew: string[] = this.state.checkBoxes;
        let checkedItemsNew: string[] = this.state.checkedItems;
        if (!this.state.checkBoxes.includes(name)) {
            checkBoxesNew.push(name);
            checkedItemsNew.push(item);
            this.setState({
                checkBoxes: checkBoxesNew,
                checkedItems: checkedItemsNew,
            });
            let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

            let brokerage: any;

            brokerage = brokerageCheck.display_name;
            let brokerage_key: any = brokerageCheck.brokerage_key;
            let agent_key: any = brokerageCheck.realtor_key;

            let agent_name: any = brokerageCheck.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Card Selection Clicked',
                {
                    page: 'Trusted Pros Recommended Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    card_title: item.name,
                    card: true,
                    category_name: 'Trusted Pros',
                    product_name: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        } else {
            checkBoxesNew = this.state.checkBoxes.filter(item => item != name);
            checkedItemsNew = this.state.checkedItems.filter((item2: any) => item2.name != item.name);
            this.setState({
                checkBoxes: checkBoxesNew,
                checkedItems: checkedItemsNew,
            });
        }
    };

    handleQuote = async () => {
        await this.setState({
            quote: !this.state.quote,
        });
      
        updateChecklistTask(60, this.props.dashboardAction);

        let dateSingle: any = '';
        this.state.date.map((item: any) => {
            if (item.value == true) {
                dateSingle = item.name;
            }
        });

        let timeOfDaySingle: any = '';
        this.state.timeOfDay.map((item: any) => {
            if (item.value == true) {
                timeOfDaySingle = item.name;
            }
        });

        this.props.history.push({
            pathname: '/dashboard/ourvendors/order',
            state: {
                quote: this.state.quote,
                checkedItems: this.state.checkedItems,
                date: dateSingle,
                timeOfDay: timeOfDaySingle,
                serviceName: this.state.serviceName,
                serviceId: this.state.serviceId,
                homeTaskId: this.state.homeTaskId,
                description:
                    this.props &&
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.description
                        ? this.props.location.state.description
                        : '',
                homeAdvisorData:
                    this.props.location && this.props.location.state && this.props.location.state.homeAdvisorData
                        ? this.props.location.state.homeAdvisorData
                        : [],
            },
        });

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;
        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let move_id: any = localStorage.getItem('moveKey');
        let agent_name: any = brokerageCheck.contact_name;
        buttonClicked(
            'Button Clicked',
            'Trusted Pros',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Trusted Pros',
            'Confirm Vendors',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    handleCloseModal = () => {
        this.setState({ open: false });
    };

    handleDeleteItem = (name: string, item: any) => {
        let checkBoxesNext: string[] = this.state.checkBoxes;
        let checkedItemsNext: string[] = this.state.checkedItems;
        checkBoxesNext = this.state.checkBoxes.filter(item => item != name);
        checkedItemsNext = this.state.checkedItems.filter(item2 => item2 != item);
        this.setState({
            checkBoxes: checkBoxesNext,
            checkedItems: checkedItemsNext,
        });
    };

    clearClickedHandler = () => {
        this.setState({
            checkBoxes: [],
            checkedItems: [],
        });
    };

    setValue = (checkedItemsAlmost: any, testArray: any, date: any, time: any) => {
        this.setState({
            checkedItems: checkedItemsAlmost,
            checkBoxes: testArray,
            buffer2: true,
        });

        //date
        let array = this.state.date.map((item: any) => {
            if (item.name === date) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                };
                return item1;
            } else return item;
        });

        this.setState({
            date: array,
        });

        //time
        let array2 = this.state.timeOfDay.map((item: any) => {
            if (item.name === time) {
                let item1 = {
                    name: item.name,
                    value: !item.value,
                };
                return item1;
            } else return item;
        });

        this.setState({
            timeOfDay: array2,
        });
    };

    setValue2 = (homeTaskId: any) => {
        this.setState({
            homeTaskId: homeTaskId,
            buffer3: true,
        });
    };

    render() {
        let date: any = '';
        let time: any = '';
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        date = this.state.date.map((item: any) => {
            if (item.value) {
                return item.name;
            }
        });

        time = this.state.timeOfDay.map((item: any) => {
            if (item.value) {
                return item.name;
            }
        });

        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.checkedItemsAlmost &&
            this.props.location.state.checkedItemsAlmost.length &&
            this.props.location.state.date &&
            this.props.location.state.time &&
            this.state.buffer2 === false
        ) {
            let testArray: string[] = [];
            this.props.location.state.checkedItemsAlmost.map((item: any) => {
                testArray.push(item.name);
            });

            this.setValue(
                this.props.location.state.checkedItemsAlmost,
                testArray,
                this.props.location.state.date,
                this.props.location.state.time,
            );
        }

        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.homeTaskId &&
            this.state.buffer3 === false
        ) {
            this.setValue2(this.props.location.state.homeTaskId);
        }
        let displayData: any;
        displayData =
        this.props.location && this.props.location.state && this.props.location.state.length > 0
        ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
        : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
        ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
        : [];
        
        const breadList = [
            {
                name: 'Trusted Pros',
                url: '/dashboard/ourvendors'
            },
            {
                name: 'Vendors',
                url: '',
            },
        ]
        
        let vendors = this.state.vendors ? [ ...this.state.vendors ] : []
        vendors.sort((m: any , n: any) => n.representation.localeCompare(m.representation)) //Sort to make Brokerage Preferreed at the top

        return (
            <div
                className={
                    isDouglasElliman
                        ? 'trusted-recommended-main-page trusted-recommended-main-pageDouglas'
                        : 'trusted-recommended-main-page'
                }
            >
                {!this.state.isShowMobileFilter ? (
                    <div>
                        <div className="trusted-recommended-main-head-2">
                            <h2 className="trusted-recommended-heading">Trusted Pros</h2>
                            <BreadCrumb breadList={breadList} />
                        </div>
                        {this.state.showModal && (
                            <div className="loaderTrustedProsRecomendation">
                                <div className="trusted-recommended-modalHead">
                                    {' '}
                                    Searching For Best Vendors For You{' '}
                                </div>

                                <div className="trusted-recommended-main-divv">
                                    <div className="trusted-recommended-modalData">
                                        <CheckCircleIcon />
                                        <div className="trusted-recommended-textModal">Fetching best home services</div>
                                    </div>
                                    <div className="trusted-recommended-modalData1">
                                        <CheckCircleIcon />
                                        <div className="trusted-recommended-textModal1">
                                            Quotes Available For{' '}
                                            {localStorage.getItem('TrustedProsAddress')
                                                ? localStorage.getItem('TrustedProsAddress')
                                                : this.state.destinationAddress}
                                        </div>
                                    </div>
                                    <div className="trusted-recommended-modalData2">
                                        <CheckCircleIcon />
                                        <div className="textModal2">Choosing best services right now</div>
                                    </div>
                                    {/* <div className="trusted-recommended-modalData3">
                                    <CheckCircleIcon />
                                    <div className="trusted-recommended-textModal3">Applying best deals for PODS</div>
                                </div> */}
                                </div>
                            </div>
                        )}
                        <div>
                            <Dialog
                                className="dialog-box"
                                open={this.state.open}
                                onClose={this.handleCloseModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {'Oops. Only Three Vendors are Allowed for Selection'}
                                </DialogTitle>

                                <DialogActions>
                                    <ButtonNew
                                        onClick={this.handleCloseModal}
                                        // color="#ffffff"
                                    >
                                        Ok
                                    </ButtonNew>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <div className="trusted-recommended-inner">
                            <div className="trusted-recommended-inner-div1">
                                <div className="preference-heading">Selected Preference</div>
                                <div>
                                    <FiltersTrusted
                                        Date={this.state.date}
                                        TimeOfDay={this.state.timeOfDay}
                                        changeDate={(item: any) => {
                                            this.dateChangeHandler(item);
                                        }}
                                        changeTimeOfDay={(item: any) => {
                                            this.changeTimeHandler(item);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="trusted-recommended-inner-div2">
                                <div className="vendors-heading">
                                    {this.state.serviceName}({vendors.length})
                                </div>

                                <div className="TrustedProsFilterDivMobile">
                                    <div className="filterDateTimeDiv">
                                        <div className="filterDateDiv">
                                            <div className="DateHeading">Date</div>
                                            <div className="DateText">{date}</div>
                                        </div>
                                        <div className="filterTimeDiv">
                                            <div className="DateHeading">Time of the day</div>
                                            <div className="DateText">{time}</div>
                                        </div>
                                    </div>
                                    <div className="editPreferanceDiv" onClick={this.onClickEditPreference}>
                                        <EditIcon className="editPreferanceIcon" />
                                        Edit preferences
                                    </div>
                                </div>

                                <div>
                                    {vendors.map((item: any, i: number) => {
                                        return (
                                            <TrustedProsCard
                                                key={i}
                                                checked={this.state.checkBoxes.includes(item.name)}
                                                cardTitle={item.name}
                                                handleChange={() => {
                                                    this.handleCheckBoxes(item.name, item);
                                                }}
                                                rate={item.google_rating + item.yelp_rating}
                                                phoneNumber={item.phone_no}
                                                locationtext={item.address}
                                                logoInitials={item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1)}
                                                logoImage={item.logo}
                                                website={item.website}
                                                representation={item.representation}
                                                brokerage_image={
                                                    this.props.commonState &&
                                                    this.props.commonState.move &&
                                                    this.props.commonState.move[0] &&
                                                    this.props.commonState.move[0].realtor &&
                                                    this.props.commonState.move[0].realtor.logo
                                                }
                                                homeAdvisor={
                                                    this.props &&
                                                    this.props &&
                                                    this.props.location &&
                                                    this.props.location.state &&
                                                    this.props.location.state.homeAdvisorData
                                                        ? true
                                                        : false
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="trusted-recommended-inner-div3">
                                <GetAQuoteCard
                                    cardImage={addArrowIcon}
                                    width="98%"
                                    text1="Select multiple vendors"
                                    text2="get a quote"
                                    vendors={true}
                                    selectedMovers={this.state.checkedItems.length}
                                    selectedMover={true}
                                    clearClicked={() => {
                                        this.clearClickedHandler();
                                    }}
                                    features={this.state.checkedItems.map((item: any) => {
                                        return {
                                            name: item.name,
                                            logoInitials:
                                                item.name.charAt(0) + item.name.charAt(item.name.indexOf(' ') + 1),
                                            icon: item.van_line_logo,
                                            locationIcon: locationIcon,
                                            locationText: item.address,
                                            deleteClicked: () => {
                                                this.handleDeleteItem(item.name, item);
                                            },
                                        };
                                    })}
                                    onClickedHandle={() => this.handleQuote()}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mobileFilterDrawer">
                        <div className="drawerHeadingDiv">
                            <div className="iconTextDiv">
                                <div className="backIconDiv">
                                    <ArrowBackIcon
                                        className="backIcon"
                                        onClick={() => this.setState({ isShowMobileFilter: false })}
                                    />
                                </div>
                                <div className="headingTextDiv">Edit Preferences</div>
                            </div>
                            <div className="clearAllFilter">Clear all</div>
                        </div>
                        <FiltersTrusted
                            Date={this.state.date}
                            TimeOfDay={this.state.timeOfDay}
                            changeDate={(item: any) => {
                                this.dateChangeHandler(item);
                            }}
                            changeTimeOfDay={(item: any) => {
                                this.changeTimeHandler(item);
                            }}
                        />
                    </div>
                )}
                <div className="mortgage-last-div">
                    <div className="mortgage-pleasenote">Please Note:</div>
                    <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                        {displayData && displayData[0] && displayData[0].disclaimer ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: displayData && displayData[0] && displayData[0].disclaimer,
                                }}
                            />
                        ) : (
                            'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    commonState: state.common,
    CheckListDashboardState: state.checklistDashboard,
    trustedProsState: state.trustedPros,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsRecommended);
