import * as React from 'react';

type initialProps = {
    className?: any;
};
// function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    const fillValue1 = props.className.includes('activeIcon') && slug?.primary_color ? `${slug.primary_color}` : '#6b6c6f';
    const fillValue2 = props.className.includes('activeIcon') && slug?.primary_color ? `${slug.primary_color}` : '#bebebe';

    return (
        <svg
            {...props}
            className={props.className ? props.className : null}
            xmlns="http://www.w3.org/2000/svg"
            width={32.372}
            // height={9.099}
            viewBox="0 0 32.372 9.099"
        >
            <defs>
                <style>
                    {/* {".prefix__a{fill:#fff}.prefix__c{fill:#6b6c6f}"} */}
                    {`.Moreprefix__a{fill:#fff}.Moreprefix__c{fill:${fillValue1}}.Moreprefix__d{fill:${fillValue2}}`}
                </style>
            </defs>
            <path
                className="Moreprefix__a"
                d="M31.466 4.55A3.643 3.643 0 1127.823.907a3.643 3.643 0 013.643 3.643zm0 0"
            />
            <path
                d="M19.828 4.55A3.643 3.643 0 1116.185.907a3.643 3.643 0 013.643 3.643zm0 0"
                // fill="#bebebe"
                className="Moreprefix__d"
            />
            <path className="Moreprefix__a" d="M8.192 4.55A3.643 3.643 0 114.549.907 3.643 3.643 0 018.192 4.55zm0 0" />
            <path
                className="Moreprefix__c"
                d="M27.823 0a4.55 4.55 0 104.55 4.551 4.55 4.55 0 00-4.55-4.55zm0 7.285a2.736 2.736 0 112.736-2.734 2.736 2.736 0 01-2.736 2.735zm0 0M16.186 0a4.55 4.55 0 104.55 4.551 4.55 4.55 0 00-4.55-4.55zm0 7.285a2.736 2.736 0 112.735-2.734 2.736 2.736 0 01-2.735 2.735zm0 0M4.55 0A4.55 4.55 0 109.1 4.551 4.55 4.55 0 004.55 0zm0 7.285a2.736 2.736 0 112.735-2.734A2.736 2.736 0 014.55 7.286zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
