import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    className?: any;
    fromlanding?: number;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={53.72}
            height={69.756}
            viewBox="0 0 41.72 54.756"
            {...props}
            className={props.className}
        >
            <defs>
                <style>{`.repairHsprefix__b{fill:${
                    slug && slug.primary_color && props.checked
                        ? slug.primary_color
                        : props.fromlanding && props.checked
                        ? '#30ab86'
                        : props.checked
                        ? '#273E59'
                        : '#929dab'
                }}`}</style>
            </defs>
            <path
                d="M16.956 6.933a8.162 8.162 0 01-2.487 8.238l9.14 34.11a3.63 3.63 0 01-7.012 1.878l-9.14-34.11A8.165 8.165 0 014.448 2.315l1.757 6.559 5.256-1.408L9.708.907a8.166 8.166 0 017.248 6.026z"
                fill="#fff"
            />
            <path
                // className="repairHsprefix__a"
                fill="#ffffff"
                d="M35.372 21.993v12.7H29.93a5.442 5.442 0 01-5.442 5.442H6.412a2.762 2.762 0 01-2.783-2.669 2.722 2.722 0 012.721-2.773H3.691a2.762 2.762 0 01-2.779-2.669 2.722 2.722 0 012.721-2.773 2.721 2.721 0 110-5.442 2.721 2.721 0 010-5.442h11.7l-.856-3.195a8.152 8.152 0 001.777-2.248h3.7a9.069 9.069 0 019.069 9.069z"
            />
            <path
                fill="#ffffff"
                // className="repairHsprefix__a"
                d="M35.372 18.366h5.442v19.953h-5.442a1.814 1.814 0 01-1.814-1.814V20.18a1.814 1.814 0 011.814-1.814z"
            />
            <path
                className="repairHsprefix__b"
                d="M40.813 17.459h-5.442a2.724 2.724 0 00-2.721 2.721v.907h-2.761a9.977 9.977 0 00-9.936-9.069h-2.3A9.086 9.086 0 009.779.003a.907.907 0 00-.947 1.139l1.523 5.684-3.5.939-1.523-5.683a.907.907 0 00-1.389-.513 9.074 9.074 0 001.734 15.89H3.628a3.626 3.626 0 00-2.4 6.348c-.057.051-.113.1-.168.157a3.625 3.625 0 00.168 5.284c-.057.051-.113.1-.168.157a3.608 3.608 0 00-1.062 2.634 3.672 3.672 0 003.222 3.529 3.611 3.611 0 00-.5 1.913 3.679 3.679 0 003.69 3.558h6.538l2.776 10.354a4.535 4.535 0 108.76-2.347l-2.145-8.007h2.149a6.359 6.359 0 006.284-5.442h1.878v.906a2.724 2.724 0 002.721 2.721h5.442a.907.907 0 00.907-.907V18.366a.907.907 0 00-.907-.907zm-32.476-.644a.907.907 0 00-.7-.654 7.258 7.258 0 01-3.7-12.246L5.329 9.11a.907.907 0 001.111.641l5.256-1.408a.907.907 0 00.641-1.111l-1.392-5.195a7.258 7.258 0 012.919 12.455.907.907 0 00-.276.915l.55 2.052H8.507zm14.4 32.7a2.721 2.721 0 01-5.257 1.408l-2.648-9.884h5.634zm7.2-15.732a.907.907 0 00-.907.907 4.54 4.54 0 01-4.535 4.535H6.407a1.856 1.856 0 01-1.876-1.778 1.815 1.815 0 011.814-1.85h5.442a.907.907 0 100-1.814h-8.1a1.856 1.856 0 01-1.873-1.78 1.815 1.815 0 011.814-1.85H9.07a.907.907 0 000-1.814H3.628a1.814 1.814 0 110-3.628H9.07a.907.907 0 000-1.814H3.628a1.814 1.814 0 110-3.628H20.86a.907.907 0 000-1.814h-4.84l-.535-2a9.126 9.126 0 001.3-1.63h3.17a8.162 8.162 0 018.162 8.163.907.907 0 00.907.906h3.628v10.89zm9.976 3.628h-4.542a.908.908 0 01-.907-.907V20.179a.908.908 0 01.907-.907h4.536z"
                // fill="#273e59"
                // {slug && slug.primary_color ? slug.primary_color : '#273e59'}
            />
        </svg>
    );
}

export default SvgComponent;
