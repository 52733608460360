import {
    UPDATE_ADDRESS_NEW_SERVICES_REQUEST,
    UPDATE_ADDRESS_NEW_SERVICES_SUCCESS,
    UPDATE_ADDRESS_NEW_SERVICES_FAILURE,

    UPDATE_ADDRESS_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_REQUEST_FILED_SUCCESS,
    UPDATE_ADDRESS_REQUEST_FILED_FAILURE,

    UPDATE_ADDRESS_PHONE_VERIFY_REQUEST,
    UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS,
    UPDATE_ADDRESS_PHONE_VERIFY_FAILURE,
    
    UPDATE_ADDRESS_OTP_VERIFY_REQUEST,
    UPDATE_ADDRESS_OTP_VERIFY_SUCCESS,
    UPDATE_ADDRESS_OTP_VERIFY_FAILURE,

    UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS,
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE,
  
    UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST,
    UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS,
    UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE,
    
    UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST,
    UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS,
    UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE,
} from './actionTypes';

import { UpdateAddressActions, UpdateAddressState } from './types';

const initialState: UpdateAddressState = {
    newServices: [],
    requestsFiled: [],
    phoneVerified:[],
    otpVerified: [],
    deleteRequestsFiled: [],
    saveResponse: [],
    updateResponse: [],
};

export default (state = initialState, action: UpdateAddressActions) => {
    switch (action.type) {
        case UPDATE_ADDRESS_NEW_SERVICES_REQUEST:
            return {
                ...state,
                isNewServiceLoading: true,
                newServices: [],
                error: null,
            };
        case UPDATE_ADDRESS_NEW_SERVICES_SUCCESS:
            return {
                ...state,
                isNewServiceLoading: false,
                newServices: action.payload.newServices,
                error: null,
            };
        case UPDATE_ADDRESS_NEW_SERVICES_FAILURE:
            return {
                ...state,
                isNewServiceLoading: false,
                newServices: [],
                error: action.payload.error,
            };

            case UPDATE_ADDRESS_REQUEST_FILED_REQUEST:
                return {
                    ...state,
                    isReqFiledLoading: true,
                    requestsFiled: [],
                    requestsFiledError: null,
                };
            case UPDATE_ADDRESS_REQUEST_FILED_SUCCESS:
                return {
                    ...state,
                    isReqFiledLoading: false,
                    requestsFiled: action.payload.requestsFiled,
                    requestsFiledError: null,
                };
            case UPDATE_ADDRESS_REQUEST_FILED_FAILURE:
                return {
                    ...state,
                    isReqFiledLoading: false,
                    requestsFiled: [],
                    requestsFiledError: action.payload.requestsFiledError,
                };

                case UPDATE_ADDRESS_PHONE_VERIFY_REQUEST:
                    return {
                        ...state,
                        isPhoneVerifyLoading: true,
                        phoneVerified: [],
                        phoneVerifiedError: null,
                    };
                case UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS:
                    return {
                        ...state,
                        isPhoneVerifyLoading: false,
                        phoneVerified: action.payload.phoneVerified,
                        phoneVerifiedError: null,
                    };
                case UPDATE_ADDRESS_PHONE_VERIFY_FAILURE:
                    return {
                        ...state,
                        isPhoneVerifyLoading: false,
                        phoneVerified: [],
                        phoneVerifiedError: action.payload.phoneVerifiedError,
                    };

                    case UPDATE_ADDRESS_OTP_VERIFY_REQUEST:
                        return {
                            ...state,
                            isOtpVerifyLoading: true,
                            otpVerified: [],
                            otpVerifiedError: null,
                        };
                    case UPDATE_ADDRESS_OTP_VERIFY_SUCCESS:
                        return {
                            ...state,
                            isOtpVerifyLoading: false,
                            otpVerified: action.payload.otpVerified,
                            otpVerifiedError: null,
                        };
                    case UPDATE_ADDRESS_OTP_VERIFY_FAILURE:
                        return {
                            ...state,
                            isOtpVerifyLoading: false,
                            phoneVerified: [],
                            otpVerifiedError: action.payload.otpVerifiedError,
                        };

                        case UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST:
                            return {
                                ...state,
                                isDelReqLoading: true,
                                isFileDeleted: false,
                                deleteRequestsFiled: [],
                                deleteRequestsFiledError: null,
                            };
                        case UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS:
                            return {
                                ...state,
                                isDelReqLoading: false,
                                isFileDeleted: true,
                                deleteRequestsFiled: action.payload.deleteRequestsFiled,
                                deleteRequestsFiledError: null,
                            };
                        case UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE:
                            return {
                                ...state,
                                isDelReqLoading: false,
                                isFileDeleted: false,
                                deleteRequestsFiled: [],
                                deleteRequestsFiledError: action.payload.deleteRequestsFiledError,
                            };

                            case UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST:
                                return {
                                    ...state,
                                    isSaveResponseLoading: true,
                                    saveResponse: [],
                                    isResponseSaved: false,
                                    saveResponseError: null,
                                };
                            case UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS:
                                return {
                                    ...state,
                                    isSaveResponseLoading: false,
                                    saveResponse: action.payload.saveResponse,
                                    isResponseSaved: true,
                                    saveResponseError: null,
                                };
                            case UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE:
                                return {
                                    ...state,
                                    isSaveResponseLoading: false,
                                    saveResponse: [],
                                    isResponseSaved: false,
                                    saveResponseError: action.payload.saveResponseError,
                                };
                                
                            case UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST:
                                return {
                                    ...state,
                                    isUpdateResponseLoading: true,
                                    updateResponse: [],
                                    isResponseUpdated: false,
                                    updateResponseError: null,
                                };
                            case UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS:
                                return {
                                    ...state,
                                    isUpdateResponseLoading: false,
                                    updateResponse: action.payload.updateResponse,
                                    isResponseUpdated: true,
                                    updateResponseError: null,
                                };
                            case UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE:
                                return {
                                    ...state,
                                    isUpdateResponseLoading: false,
                                    updateResponse: [],
                                    isResponseUpdated: false,
                                    updateResponseError: action.payload.updateResponseError,
                                };

        default:
            return {
                ...state,
            };
    }
};
