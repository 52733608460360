import React from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonNew from '../atoms/Button';

import './CallWithMikeHomeOwner.scss';
import Skeleton from 'react-loading-skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    paragraph?: any;
    onClick?: any;
    padding?: any;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    buttonMargin?: any;
    buttonMargin2?: any;
    buttonBorder?: any;
    rescheduleText?: any;
    rescheduleBackGroundColor?: string;
    rescheduleColor?: string;
    rescheduleShadow?: string;
    rescheduleHeight?: string;
    rescheduleWidth?: string;
    rescheduleMargin?: any;
    rescheduleMargin2?: any;
    rescheduleBorder?: any;
    cancelText?: any;
    cancelBackGroundColor?: string;
    cancelColor?: string;
    cancelShadow?: string;
    cancelHeight?: string;
    cancelWidth?: string;
    cancelMargin?: any;
    cancelMargin2?: any;
    cancelBorder?: any;
    onClickReschedule?: any;
    onClickCancel?: any;
    appointmentTime?: any;
    showLoader?: any;
    titleNew?: any;
    saveMoneyTasks?: any;
};

const CallWithMikeCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
                '&:hover': {
                    // boxShadow: '0 0 10px #fbada9',
                    // border: "none !important",
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} mike-main`}
            style={{
                border: isDouglasElliman ? '1px solid #DADCE0' : props.border ? props.border : '#D92F25 1px solid',
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : '100%',
                padding: props.padding ? props.padding : '0px',
            }}
        >
            <div className="mike-main-2">
                <div className="mike-div-1">
                    {props.icon ? (
                        <img
                            src={props.icon}
                            height={props.imageHeight ? props.imageHeight : '100%'}
                            width={props.imageWidth ? props.imageWidth : '100%'}
                            style={{ objectFit: 'cover' }}
                        />
                    ) : (
                        <Skeleton height="100%" />
                    )}
                </div>
                <div className="mike-div-2">
                    <div
                        className="mike-title"
                        dangerouslySetInnerHTML={{
                            __html: props.title ? ` ${props.title}` : props.titleNew,
                        }}
                    >
                        {/* {props.title ? `Connect with your Moving Concierge ${props.title}` : props.titleNew} */}
                    </div>
                    <div
                        className="mike-subtitle"
                        dangerouslySetInnerHTML={{
                            __html: props.subTitle ? props.subTitle : '',
                        }}
                    >
                        {/* {props.subTitle} */}
                    </div>
                    <div className="checkDetailsMainDiv">
                        {props.saveMoneyTasks &&
                            props.saveMoneyTasks.map((item: any, i: number) => {
                                return (
                                    <div key={i} className="checkDetailsDiv">
                                        <CheckCircleIcon
                                            className={item.completed ? 'checkIconCompleted' : 'checkIcon'}
                                        />
                                        <div className="checkTitleDiv">{item.name}</div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="not-mobile-div">
                        {props.paragraph ? (
                            <div
                                className="mike-paragraph"
                                dangerouslySetInnerHTML={{
                                    __html: props.paragraph,
                                }}
                            ></div>
                        ) : null}
                        {props.showLoader ? null : props.rescheduleText && props.appointmentTime ? (
                            <div className="appointment-time-div">
                                <div className="details"> Appointment Details </div>
                                <div className="details-content">
                                    {' '}
                                    Your Call is scheduled on {props.appointmentTime}{' '}
                                </div>
                            </div>
                        ) : null}
                        <div className="mike-button-wrapper">
                            {props.showLoader ? (
                                <div>
                                    <CircularProgress />
                                    <div> Working on it.Please Wait... </div>
                                </div>
                            ) : props.rescheduleText || props.cancelText ? (
                                <div>
                                    {props.rescheduleText ? (
                                        <ButtonNew
                                            backgroundColor={
                                                isDouglasElliman ? '#100B28' : props.rescheduleBackGroundColor
                                            }
                                            boxShadow={isDouglasElliman ? 'none' : props.rescheduleShadow}
                                            color={isDouglasElliman ? '#FFF' : props.rescheduleColor}
                                            borderRadius="5px"
                                            fontSize="14px"
                                            fontWeight="600"
                                            height={
                                                isDouglasElliman
                                                    ? '40px'
                                                    : props.rescheduleHeight
                                                    ? props.rescheduleHeight
                                                    : '40px'
                                            }
                                            width={props.rescheduleWidth ? props.rescheduleWidth : '150px'}
                                            margin={
                                                props.rescheduleMargin ? props.rescheduleMargin : '40px 20px 10px 0px'
                                            }
                                            onClick={props.onClickReschedule}
                                            border={
                                                isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : props.rescheduleBorder
                                                    ? props.rescheduleBorder
                                                    : 'none'
                                            }
                                        >
                                            {props.rescheduleText}
                                        </ButtonNew>
                                    ) : null}

                                    {props.cancelText ? (
                                        <ButtonNew
                                            backgroundColor={props.cancelBackGroundColor}
                                            boxShadow={props.cancelShadow}
                                            color={props.cancelColor}
                                            borderRadius="5px"
                                            fontSize="14px"
                                            fontWeight="600"
                                            height={
                                                isDouglasElliman
                                                    ? '40px'
                                                    : props.cancelHeight
                                                    ? props.cancelHeight
                                                    : '40px'
                                            }
                                            width={props.cancelWidth ? props.cancelWidth : '150px'}
                                            margin={props.cancelMargin ? props.cancelMargin : '40px 20px 10px 0px'}
                                            onClick={props.onClickCancel}
                                            border={props.cancelBorder ? props.cancelBorder : 'none'}
                                        >
                                            {props.cancelText}
                                        </ButtonNew>
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    <ButtonNew
                                        backgroundColor={isDouglasElliman ? '#100B28' : props.buttonBackGroundColor}
                                        boxShadow={isDouglasElliman ? 'none' : props.buttonShadow}
                                        color={isDouglasElliman ? '#FFF' : props.buttonColor}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={
                                            isDouglasElliman ? '40px' : props.buttonHeight ? props.buttonHeight : '40px'
                                        }
                                        width={props.buttonWidth ? props.buttonWidth : '150px'}
                                        margin={props.buttonMargin ? props.buttonMargin : '40px 20px 10px 0px'}
                                        onClick={props.onClick}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : props.buttonBorder
                                                ? props.buttonBorder
                                                : 'none'
                                        }
                                    >
                                        {props.buttonText}
                                    </ButtonNew>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mike-mobile-div">
                        <div className="mike-paragraph">{props.paragraph}</div>
                        {props.rescheduleText && props.appointmentTime ? (
                            <div className="appointment-time-div">
                                <div className="details"> Appointment Details </div>
                                <div className="details-content">
                                    {' '}
                                    Your Call is scheduled on {props.appointmentTime}{' '}
                                </div>
                            </div>
                        ) : null}
                        <div className="mike-button-wrapper">
                            {props.rescheduleText ? (
                                <div>
                                    <ButtonNew
                                        backgroundColor={isDouglasElliman ? '#100B28' : props.rescheduleBackGroundColor}
                                        boxShadow={isDouglasElliman ? 'none' : props.rescheduleShadow}
                                        color={isDouglasElliman ? '#FFF' : props.rescheduleColor}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={
                                            isDouglasElliman
                                                ? '40px'
                                                : props.rescheduleHeight
                                                ? props.rescheduleHeight
                                                : '40px'
                                        }
                                        width={props.rescheduleWidth ? props.rescheduleWidth : '150px'}
                                        margin={props.rescheduleMargin ? props.rescheduleMargin : '40px 20px 10px 0px'}
                                        onClick={props.onClickReschedule}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : props.rescheduleBorder
                                                ? props.rescheduleBorder
                                                : 'none'
                                        }
                                    >
                                        {props.rescheduleText}
                                    </ButtonNew>

                                    <ButtonNew
                                        backgroundColor={props.cancelBackGroundColor}
                                        boxShadow={props.cancelShadow}
                                        color={props.cancelColor}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={
                                            isDouglasElliman ? '40px' : props.cancelHeight ? props.cancelHeight : '40px'
                                        }
                                        width={props.cancelWidth ? props.cancelWidth : '150px'}
                                        margin={props.cancelMargin ? props.cancelMargin : '40px 20px 10px 0px'}
                                        onClick={props.onClickCancel}
                                        border={props.cancelBorder ? props.cancelBorder : 'none'}
                                    >
                                        {props.cancelText}
                                    </ButtonNew>
                                </div>
                            ) : (
                                <div>
                                    <ButtonNew
                                        backgroundColor={isDouglasElliman ? '#100B28' : props.buttonBackGroundColor}
                                        boxShadow={isDouglasElliman ? 'none' : props.buttonShadow}
                                        color={props.buttonColor}
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={
                                            isDouglasElliman ? '40px' : props.buttonHeight ? props.buttonHeight : '40px'
                                        }
                                        width={props.buttonWidth ? props.buttonWidth : '150px'}
                                        margin={props.buttonMargin ? props.buttonMargin : '40px 20px 10px 0px'}
                                        onClick={props.onClick}
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : props.buttonBorder
                                                ? props.buttonBorder
                                                : 'none'
                                        }
                                    >
                                        {props.buttonText}
                                    </ButtonNew>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallWithMikeCard;
