import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import './SubscriptionTopCard.scss';

type initialProps = {
    image?: string;
    title?: string;
    progressValue?: any;
};
const SubscriptionTopCard = (props: initialProps) => {
    return (
        <div className="SubscriptionTopMainCard" style={{ background: props.progressValue ? '#162C47' : '#EEEEEE' }}>
            <div className="imageSubscriptionTopCard">
                <img src={props.image} alt="" />
            </div>
            <div className="iconTitleDiv" style={{ color: props.progressValue ? '#ffffff' : '#333333' }}>
                <div className="titleSubscriptionTopCard">{props.title}</div>

                <div className="progressValueSubscriptionTopCard">
                    {props.progressValue ? (
                        <div className="progress">
                            <div className="progressDiv">
                                <div className="progressBarDiv">
                                    <LinearProgress
                                        className=""
                                        variant="determinate"
                                        value={props.progressValue ? props.progressValue : 0}
                                        style={{
                                            backgroundColor: '#E0E0E0',
                                            borderRadius: '15px',
                                            height: '7px',
                                        }}
                                    />
                                </div>
                                <div className="completedPercent">{`${
                                    props.progressValue ? props.progressValue : '0'
                                }% completed`}</div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionTopCard;
