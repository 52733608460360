import React, { useState, useEffect } from 'react';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyBlvLHeulLrfI9srS5jZMZKKi1xaGuxTBc');
function useCoordinates() {
    const [coordinate, setCoordinate] = useState({
        lat: 0,
        long: 0,
    });
    let geoId: any;

    useEffect(() => {
        //ComponenntDidMount

        geoId = window.navigator.geolocation.watchPosition(position => {
            let lat: string = position.coords.latitude.toString();
            let long: string = position.coords.longitude.toString();
            let address: string;

            Geocode.fromLatLng(lat, long).then(
                response => {
                    address = response.results[0].formatted_address;
                    console.log(address);
                },
                error => {
                    console.error('error', error);
                },
            );

            setCoordinate({
                lat: position.coords.latitude,
                long: position.coords.longitude,
            });
        });

        return () => {
            navigator.geolocation.clearWatch(geoId);
        };
    });

    return (
        <div>
            <h1>Lat: {coordinate.lat}</h1>
            <h1>Long: {coordinate.long}</h1>
        </div>
    );
}
export default useCoordinates;
