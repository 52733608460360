export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';
export const GET_VENDORS_ERROR = 'GET_VENDORS_ERROR';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const POST_IMPRESSION = 'POST_IMPRESSION';
export const POST_IMPRESSION_SUCCESS = 'POST_IMPRESSION_SUCCESS';
export const POST_IMPRESSION_ERROR = 'POST_IMPRESSION_ERROR';

export const GET_CATEGORIES_QUESTIONS = 'GET_CATEGORIES_QUESTIONS';
export const GET_CATEGORIES_QUESTIONS_SUCCESS = 'GET_CATEGORIES_QUESTIONS_SUCCESS';
export const GET_CATEGORIES_QUESTIONS_ERROR = 'GET_CATEGORIES_QUESTIONS_ERROR';

export const POST_VENDORS = 'POST_VENDORS';
export const POST_VENDORS_SUCCESS = 'POST_VENDORS_SUCCESS';
export const POST_VENDORS_ERROR = 'POST_VENDORS_ERROR';

export const REQUEST_VENDORS = 'REQUEST_VENDORS';
export const REQUEST_VENDORS_SUCCESS = 'REQUEST_VENDORS_SUCCESS';
export const REQUEST_VENDORS_ERROR = 'REQUEST_VENDORS_ERROR';

export const GET_MOVE_VENDOR = 'GET_MOVE_VENDOR';
export const GET_MOVE_VENDOR_SUCCESS = 'GET_MOVE_VENDOR_SUCCESS';
export const GET_MOVE_VENDOR_ERROR = 'GET_MOVE_VENDOR_ERROR';

export const GET_HOME_ADVISOR = 'GET_HOME_ADVISOR';
export const GET_HOME_ADVISOR_SUCCESS = 'GET_HOME_ADVISOR_SUCCESS';
export const GET_HOME_ADVISOR_ERROR = 'GET_HOME_ADVISOR_ERROR';


export const GET_STANDALONE_VENDORS = 'GET_STANDALONE_VENDORS';
export const GET_STANDALONE_VENDORS_SUCCESS = 'GET_STANDALONE_VENDORS_SUCCESS';
export const GET_STANDALONE_VENDORS_ERROR = 'GET_STANDALONE_VENDORS_ERROR';

export const GET_STANDALONE_OFFICES = 'GET_STANDALONE_OFFICES';
export const GET_STANDALONE_OFFICES_SUCCESS = 'GET_STANDALONE_OFFICES_SUCCESS';
export const GET_STANDALONE_OFFICES_ERROR = 'GET_STANDALONE_OFFICES_ERROR';


export const POST_STANDALONE_VENDORS = 'POST_STANDALONE_VENDORS';
export const POST_STANDALONE_VENDORS_SUCCESS = 'POST_STANDALONE_VENDORS_SUCCESS';
export const POST_STANDALONE_VENDORS_ERROR = 'POST_STANDALONE_VENDORS_ERROR';

export const POST_STANDALONE_IMPRESSION = 'POST_STANDALONE_IMPRESSION';
export const POST_STANDALONE_IMPRESSION_SUCCESS = 'POST_STANDALONE_IMPRESSION_SUCCESS';
export const POST_STANDALONE_IMPRESSION_ERROR = 'POST_STANDALONE_IMPRESSION_ERROR';

export const POST_STANDALONE_REQUESTVENDORS = 'POST_STANDALONE_REQUESTVENDORS';
export const POST_STANDALONE_REQUESTVENDORS_SUCCESS = 'POST_STANDALONE_REQUESTVENDORS_SUCCESS';
export const POST_STANDALONE_REQUESTVENDORS_ERROR = 'POST_STANDALONE_REQUESTVENDORS_ERROR';