import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 38.902}
        height={props.height ? props.height : 21}
        viewBox="0 0 38.902 31"
    >
        <path
            data-name="Path 42875"
            d="M40.26 9.574a.923.923 0 0 1-1.305 0 26.463 26.463 0 0 0-37.379 0A.923.923 0 0 1 .271 8.269a28.309 28.309 0 0 1 39.99 0 .924.924 0 0 1 0 1.305m-5.35 4.045a.923.923 0 0 1-1.305 1.305 18.89 18.89 0 0 0-26.679 0 .923.923 0 0 1-1.305-1.305 20.733 20.733 0 0 1 29.29 0m-5.35 5.349a.923.923 0 0 1-1.305 1.306 11.311 11.311 0 0 0-15.979 0 .923.923 0 0 1-1.305-1.306 13.161 13.161 0 0 1 18.59 0m-7.617 9.493a2.374 2.374 0 1 1 0-3.357 2.376 2.376 0 0 1 0 3.357m-1.677-5.9a4.22 4.22 0 1 0 2.983 1.236 4.193 4.193 0 0 0-2.983-1.236"
            fill={window.location.pathname.includes('internet') ? '#009CBD' : '#100b28'}
        />
    </svg>
);

export default SvgComponent;
