import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_DATA,
    RESET_PASSWORD_DATA_SUCCESS,
    RESET_PASSWORD_DATA_ERROR,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_DATA,
    SET_PASSWORD_DATA_SUCCESS,
    SET_PASSWORD_DATA_ERROR
} from './actionTypes';
import {
    Login,
    LoginRequest,
    LoginFailure,
    LoginFailurePayload,
    LoginSuccess,
    LoginSuccessPayload,
    ResetPassword,
    ResetPasswordFailure,
    ResetPasswordRequest,
    ResetPasswordSuccess,
    ResetPasswordFailurePayload,
    ResetPasswordSuccessPayload,
    ResetPasswordData,
    ResetPasswordDataFailure,
    ResetPasswordDataSuccess,
    ResetPasswordDataFailurePayload,
    ResetPasswordDataRequest,
    ResetPasswordDataSuccessPayload,
    SetPassword,
    SetPasswordFailure,
    SetPasswordSuccess,
    SetPasswordFailurePayload,
    SetPasswordRequest,
    SetPasswordSuccessPayload,
    SetPasswordData,
    SetPasswordDataFailure,
    SetPasswordDataFailurePayload,
    SetPasswordDataSuccess,
    SetPasswordDataRequest,
    SetPasswordDataSuccessPayload
} from './types';

export const login = (payload: LoginRequest): Login => {
    return {
        type: LOGIN,
        payload,
    };
};

export const loginSuccess = (payload: LoginSuccessPayload): LoginSuccess => ({
    type: LOGIN_SUCCESS,
    payload,
});

export const loginFailure = (payload: LoginFailurePayload): LoginFailure => ({
    type: LOGIN_ERROR,
    payload,
});

export const resetPassword = (payload: ResetPasswordRequest): ResetPassword => {
    return {
        type: RESET_PASSWORD,
        payload,
    };
};

export const resetPasswordSuccess = (payload: ResetPasswordSuccessPayload): ResetPasswordSuccess => ({
    type: RESET_PASSWORD_SUCCESS,
    payload,
});

export const resetPasswordFailure = (payload: ResetPasswordFailurePayload): ResetPasswordFailure => ({
    type: RESET_PASSWORD_ERROR,
    payload,
});


//Reset Password Data

export const resetPasswordData = (payload: ResetPasswordDataRequest): ResetPasswordData => {
    return {
        type: RESET_PASSWORD_DATA,
        payload,
    };
};

export const resetPasswordDataSuccess = (payload: ResetPasswordDataSuccessPayload): ResetPasswordDataSuccess => ({
    type: RESET_PASSWORD_DATA_SUCCESS,
    payload,
});

export const resetPasswordDataFailure = (payload: ResetPasswordDataFailurePayload): ResetPasswordDataFailure => ({
    type: RESET_PASSWORD_DATA_ERROR,
    payload,
});

// set password 

export const setPassword = (payload: SetPasswordRequest): SetPassword => {
    return {
        type: SET_PASSWORD,
        payload,
    };
};

export const setPasswordSuccess = (payload: SetPasswordSuccessPayload): SetPasswordSuccess => ({
    type: SET_PASSWORD_SUCCESS,
    payload,
});

export const setPasswordFailure = (payload: SetPasswordFailurePayload): SetPasswordFailure => ({
    type: SET_PASSWORD_ERROR,
    payload,
});

//set password data
// set password 

export const setPasswordData = (payload: SetPasswordDataRequest): SetPasswordData => {
    return {
        type: SET_PASSWORD_DATA,
        payload,
    };
};

export const setPasswordDataSuccess = (payload: SetPasswordDataSuccessPayload): SetPasswordDataSuccess => ({
    type: SET_PASSWORD_DATA_SUCCESS,
    payload,
});

export const setPasswordDataFailure = (payload: SetPasswordDataFailurePayload): SetPasswordDataFailure => ({
    type: SET_PASSWORD_DATA_ERROR,
    payload,
});