import {
    GET_CHECKLIST,
    GET_CHECKLIST_ERROR,
    GET_CHECKLIST_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE,
    UPDATE_SERVICE_PERCENTAGE_SUCCESS,
    UPDATE_SERVICE_PERCENTAGE_ERROR,
   
} from './actionTypes';
import {
    CheckList,
    CheckListRequest,
    CheckListFailure,
    CheckListFailurePayload,
    CheckListSuccess,
    CheckListSuccessPayload,
    UpdateServicePercentage,
    UpdateServicePercentageRequest,
    UpdateServicePercentageFailure,
    UpdateServicePercentageFailurePayload,
    UpdateServicePercentageSuccess,
    UpdateServicePercentageSuccessPayload
   
} from './types';

export const checklist = (payload: CheckListRequest): CheckList => ({
    type: GET_CHECKLIST,
    payload,
});

export const checklistSuccess = (payload: CheckListSuccessPayload): CheckListSuccess => ({
    type: GET_CHECKLIST_SUCCESS,
    payload,
});

export const checklistFailure = (payload: CheckListFailurePayload): CheckListFailure => ({
    type: GET_CHECKLIST_ERROR,
    payload,
});

// patch move api

export const updateServicePercentage = (payload: UpdateServicePercentageRequest): UpdateServicePercentage => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE,
        payload,
    };
};

export const updateServicePercentageSuccess = (
    payload: UpdateServicePercentageSuccessPayload,
): UpdateServicePercentageSuccess => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE_SUCCESS,
        payload,
    };
};

export const updateServicePercentageFailure = (
    payload: UpdateServicePercentageFailurePayload,
): UpdateServicePercentageFailure => {
    return {
        type: UPDATE_SERVICE_PERCENTAGE_ERROR,
        payload,
    };
};

