import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={192.126}
            height={145.614}
            viewBox="0 0 192.126 145.614"
            {...props}
        >
            <defs>
                <style>{`.prefix__c{fill:#a95c4b}.GirlHsprefix__d{fill:${
                    slug && slug.color_shade1 ? slug.color_shade1 : '#08192f'
                }}.GirlHsprefix__e{fill:${slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}}`}</style>
            </defs>
            <g fill="#d9dee3">
                <path d="M120.093 51.855c22.96 4.039 41.774.645 59.943 21.693 17.19 19.881 14.76 38.334.806 54.784-36.453 42.97-81.956-7.018-133.347-12.789-44.771-5.051-64.781-76.631-29.346-103.72 11.42-8.728 34.694-17.414 50.093-7.21 21.568 14.3 20.324 41.689 51.851 47.242z" />
                <path
                    d="M120.093 51.855c22.96 4.039 41.774.645 59.943 21.693 17.19 19.881 14.76 38.334.806 54.784-36.453 42.97-81.956-7.018-133.347-12.789-44.771-5.051-64.781-76.631-29.346-103.72 11.42-8.728 34.694-17.414 50.093-7.21 21.568 14.3 20.324 41.689 51.851 47.242z"
                    opacity={0.9}
                />
            </g>
            <path d="M143.901 31.796a8.054 8.054 0 00-10.521.669 8.362 8.362 0 00-6.562-.134c-4.059 1.689-7.17 7.494-4.539 11.208.3 3.509 1.317 6.381 4.7 6.865 1.525 3.944 7.626 5.829 11.684 2.845 3.021 1.4 6.622.04 9.135-2.178.593-.523 1.15-1.4 1.363-1.495 0 0 8.318-.439 8.6-8.4.305-8.338-7.514-15.13-13.86-9.38z" />
            <path
                className="prefix__c"
                d="M100.086 126.238a46.343 46.343 0 00.029 7.269c.024.188.513.986.542 1.18-1.424 4.082-4.605 5.879-7.448 7.006 1.06-1.967 2.835-4.073 2.807-4.581a64.027 64.027 0 00-.954-9.266c2.047.299 3.08-.774 5.024-1.608z"
            />
            <path
                className="GirlHsprefix__d"
                d="M116.643 76.353l11.3 11.863s-7.176 5.446-9.866 7.468-15.787 8.863-16.068 9.373a18.676 18.676 0 01.4 3.087c-.033 4.382-.528 12.849-1.285 20.067-1.544 1.248-4.53 1.2-6.347 1.423-3.177-9.4-2.537-26.574-2.252-28.61.461-3.316 24.118-24.671 24.118-24.671z"
            />
            <path
                className="prefix__c"
                d="M150.582 103.377a60.767 60.767 0 00-7.068 7.07c-1.111-1.51-1.581-2.986-2.975-4.246 1.977-1.459 6.15-5.19 6.753-6.225 1.432-2.456 2.143-2.629 2.143-2.629s7.378 6.193 7.64 7.245-4.337-2.231-6.493-1.215z"
            />
            <path
                className="GirlHsprefix__e"
                d="M146.984 100.525c-.656-.246 1.166-3.081 1.572-3.739.457-.741.988-.813 1.109-.8.327.036 8.526 6.918 9.685 8.714a2.154 2.154 0 01.188.52c.261 2.028-7.6-.579-8.443-1.743-.754-1.031-1.16-1.844-4.111-2.952z"
            />
            <path
                className="GirlHsprefix__d"
                d="M113.511 78.952l11.748-3.134c-.047.014 0 .481 0 .517.449 4.378 6.228 3.521 7.491 11.193.892 5.42-2.268 22.807-2.268 22.807s5.693-3.116 11.678-6.452c.545-.3 3.888 4.549 3.888 4.549-9.048 11.487-21.164 14.792-22.545 14.028-4.713-2.608-7.757-36.97-7.744-36.965-.693-2.123-2.248-6.543-2.248-6.543z"
            />
            <path
                className="prefix__c"
                d="M135.23 53.197s14.025-.959 17.122-.987 14.15-2.051 14.577-2.144 2.8-5.416 3.33-4.918-1.352 2.656-.666 3.1 7.433-1.34 7.392-.8c-.076 1.007-4.088 1.259-4.038 1.737s4.16-.664 4.519.1-3.992.7-4.131 1.075 4.089.626 3.732 1.4c-.193.423-3.754-.321-3.685.224s2.71 1.551 2.442 2.015-2.107-1.149-3.574-1.032-2.591-.583-4.865.308-9.925 5-14.936 5.228-16.082.753-16.082.753zM119.666 49.681s-3.494-13.616-4.44-16.565-2.5-14.078-2.543-14.513 4.261-4.363 3.624-4.706-2.1 2.117-2.738 1.607-1.062-7.478-1.561-7.27c-.932.389.089 4.276-.381 4.379s-.676-4.158-1.517-4.258.585 4.011.276 4.259-1.878-3.685-2.505-3.1c-.341.316 1.484 3.463.945 3.569s-2.323-2.086-2.68-1.685 1.753 1.64 2.1 3.068 1.367 2.277 1.235 4.716-1.633 10.994-.272 15.822 4.335 15.5 4.335 15.5z"
            />
            <path
                className="GirlHsprefix__e"
                d="M113.523 64.687c.146-2.425.73-8.943.944-9.724-.346-2.589-2.363-6.637-2.748-7.755 0 0 4.005-3.588 6.549-3.2 0 0 2.211 3.957 4.07 5.335a4.892 4.892 0 00.968.457c1.945.257 8.763.991 9.975 1.563l-2.25 10.862c-.453.577-2.657 13.119-3.554 14.052s-12.483 4.127-14.124 2.656c-.065-.058-.124-9.344.17-14.246z"
            />
            <path
                className="GirlHsprefix__e"
                d="M129.795 51.05a5.518 5.518 0 012.11-.051c3.086.377 8.289.973 8.289.973s.345 6.771-.9 8.213c0 0-8.278.127-8.145.854z"
            />
            <path
                className="prefix__c"
                d="M128.299 46.383a30.763 30.763 0 00-1.3 5.224c-.069 1.52-1.422 6.259-4.463 5.85s.282-6.227.774-7.657a59.336 59.336 0 001.2-5.971z"
            />
            <path
                d="M124.119 46.104c.222-1.238.382-2.274.382-2.274l3.8 2.553s-.253.758-.536 1.728a3.52 3.52 0 01-3.646-2.007z"
                fill="#6f3425"
            />
            <path
                className="prefix__c"
                d="M131.255 36.096a5.271 5.271 0 011.741 3.183c.3 2.053-1.067 7.052-5.294 7.713s-5.421-3.615-4.145-6.65 3.65-6.771 7.698-4.246z"
            />
            <path
                className="prefix__c"
                d="M123.22 39.05c-.039.858.318 1.567.8 1.585s.9-.663.937-1.521-.318-1.567-.8-1.584-.899.662-.937 1.52z"
            />
            <path
                className="GirlHsprefix__e"
                d="M100.119 133.617c.419-.59 2.4 2.182 2.889 2.767a1.453 1.453 0 01.41 1.326c-.126.31-8.68 6.862-10.853 7.5a1.07 1.07 0 01-.678-.111c-.935-.7 2.508-7.018 4.222-7.987a8.824 8.824 0 004.01-3.495z"
            />
            <path d="M130.815 35.772s-.058 5.781 1.555 7.052.526 3.687.526 3.687 2.411-5.627 1.285-7.7a7.755 7.755 0 00-3.366-3.039z" />
            <path
                className="prefix__c"
                d="M131.215 43.494c-.58.673-.7 1.546-.269 1.95s1.252.185 1.831-.489.7-1.546.269-1.949-1.251-.186-1.831.488z"
            />
            <g>
                <path
                    className="GirlHsprefix__d"
                    d="M49.942 43.322c-1.981 8.262-8.239 11.324-13.95 9.955-6.014-1.441-9.676-7.073-7.9-14.485 1.689-7.047 7.284-11.36 14.027-9.744 6.805 1.631 9.438 7.53 7.823 14.274zm-14.926-3.127c-.99 4.131-.306 7.443 2.367 8.084 2.612.626 4.529-1.742 5.592-6.177.961-4.009.609-7.37-2.245-8.054-2.735-.656-4.765 2.197-5.714 6.146zm.043 26.953l29.015-31.653a2.238 2.238 0 012.171-.664 2.238 2.238 0 011.126 3.69L38.32 70.141a2.212 2.212 0 01-2.145.655 2.212 2.212 0 01-1.117-3.649zm39.384-.608c-1.98 8.262-8.239 11.324-13.95 9.955-5.954-1.427-9.616-7.058-7.9-14.485 1.689-7.047 7.284-11.359 14.027-9.744 6.805 1.63 9.438 7.53 7.822 14.273zm-14.865-3.113c-1.051 4.116-.367 7.428 2.306 8.069 2.613.626 4.529-1.741 5.592-6.176.961-4.01.67-7.356-2.184-8.04-2.796-.671-4.768 2.197-5.714 6.146z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
