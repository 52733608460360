import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={49.946} height={49.945} viewBox="0 0 49.946 49.945">
            <defs>
                <style>
                    {slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? `.stairUpprefix__c{fill:#333333}`
                            : `.stairUpprefix__c{fill:${slug.primary_color}}`
                        : '.stairUpprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <path
                d="M48.697 3.746v44.951H1.249v-7.492h7.492v-7.492h7.492v-7.492h7.492v-7.492h7.492v-7.492h7.492V3.746z"
                fill={slug && slug.color_shade4 ? (props.isdouglaselliman ? '#ffffff' : slug.color_shade4) : '#5b7088'}
            />
            <path
                className="stairUpprefix__c"
                d="M1.249 24.973a1.244 1.244 0 00.882-.365L22.476 4.263v4.478a1.25 1.25 0 002.5 0V1.249a1.217 1.217 0 00-.1-.477A1.244 1.244 0 0023.725 0h-7.492a1.25 1.25 0 100 2.5h4.478L.365 22.84a1.249 1.249 0 00.884 2.133zM48.701 2.497h-9.989a1.249 1.249 0 00-1.249 1.249v6.243H31.22a1.249 1.249 0 00-1.249 1.249v6.243h-6.243a1.249 1.249 0 00-1.249 1.249v6.243h-6.243a1.249 1.249 0 00-1.249 1.249v6.243H8.741a1.249 1.249 0 00-1.249 1.249v6.243H1.249a1.249 1.249 0 00-1.248 1.248v7.492a1.249 1.249 0 001.249 1.249h47.451a1.249 1.249 0 001.249-1.249V3.746a1.249 1.249 0 00-1.249-1.249zm-1.249 44.951H2.501v-4.995h6.243a1.249 1.249 0 001.249-1.249v-6.243h6.243a1.249 1.249 0 001.249-1.249v-6.243h6.243a1.249 1.249 0 001.249-1.249v-6.243h6.243a1.249 1.249 0 001.249-1.249v-6.243h6.243a1.249 1.249 0 001.249-1.249V4.997h7.492z"
            />
        </svg>
    );
}

export default SvgComponent;
