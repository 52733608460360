import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 23.918}
            height={props.height ? props.height : 34.394}
            viewBox="0 0 23.918 34.394"
        >
            <defs>
                <style>
                    {/* {".prefix__b{fill:#fff}.prefix__c{fill:#6b6c6f}"} */}
                    {`.ChecklistSvgprefix__b{fill:#fff}.ChecklistSvgprefix__c{fill:${
                        window.location.pathname.includes('checklist') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}.ChecklistSvgSvgprefix__d{fill:${
                        window.location.pathname.includes('checklist') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}`}
                </style>
            </defs>
            <path
                d="M23.481 5.632v25.421a2.9 2.9 0 01-2.9 2.9H3.337a2.9 2.9 0 01-2.9-2.9V5.632a2.9 2.9 0 012.9-2.9h3.92v.917a1.251 1.251 0 001.25 1.25h6.895a1.25 1.25 0 001.25-1.25v-.921h3.92a2.9 2.9 0 012.909 2.904zm-5.854 10.64h2.348l-2.508-1.825-5.507-4.01-8.017 5.835h2.348v7.686h11.335zm0 0"
                fill="#bebebe"
                className="ChecklistSvgSvgprefix__d"
            />
            <path
                className="ChecklistSvgprefix__b"
                d="M19.975 16.271h-2.352v7.686H6.292v-7.686H3.944l8.015-5.835 5.507 4.01zm0 0M7.261 2.727v-1.04A1.25 1.25 0 018.513.437h6.895a1.25 1.25 0 011.25 1.25v1.958a1.25 1.25 0 01-1.25 1.25H8.513a1.251 1.251 0 01-1.25-1.25zm0 0"
            />
            <path
                className=".ChecklistSvgprefix__c"
                d="M23.918 31.052V5.631a3.345 3.345 0 00-3.341-3.341h-3.483v-.6A1.69 1.69 0 0015.406 0H8.512a1.69 1.69 0 00-1.688 1.688v.6H3.341A3.345 3.345 0 000 5.631v25.421a3.345 3.345 0 003.341 3.341h17.236a3.345 3.345 0 003.341-3.341zM7.7 1.688a.814.814 0 01.812-.814h6.895a.814.814 0 01.813.813v1.958a.814.814 0 01-.813.813H8.512a.814.814 0 01-.812-.813zM.874 31.052V5.631a2.469 2.469 0 012.467-2.466h3.483v.48a1.69 1.69 0 001.688 1.687h6.895a1.69 1.69 0 001.688-1.688v-.48h3.483a2.469 2.469 0 012.466 2.467v25.421a2.469 2.469 0 01-2.466 2.467H3.341a2.469 2.469 0 01-2.467-2.467zm0 0"
            />
            <path
                className=".ChecklistSvgprefix__c"
                d="M20.231 15.917l-8.015-5.835a.437.437 0 00-.514 0l-8.015 5.835a.437.437 0 00.257.791h1.91v7.249a.437.437 0 00.437.437h11.335a.437.437 0 00.437-.437v-7.249h1.911a.437.437 0 00.257-.791zm-2.605-.084a.437.437 0 00-.437.437v7.249H6.728V16.27a.437.437 0 00-.437-.437h-1l6.672-4.857 6.672 4.857zm0 0"
            />
            <path
                className=".ChecklistSvgprefix__c"
                d="M10.084 18.162a.437.437 0 10-.621.615l1.57 1.586a.436.436 0 00.31.13.437.437 0 00.309-.128l2.8-2.8a.437.437 0 00-.618-.618l-2.491 2.492zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
