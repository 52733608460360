import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../../components/atoms/Button';
import './Moving.scss';
import { buttonClicked } from '../../utils/utlis';

const UnitCard = (props: any) => {
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                // border: '1px solid #006dff',
                '& $unitButton': {
                    backgroundColor: isDouglasElliman
                        ? '#100B28 !important'
                        : props.slug && props.slug.primary_color
                        ? `${props.slug.primary_color} !important`
                        : '#273e59 !important',
                    color: '#FFFFFF !important',
                },
            },
        },
        unitButton: {
            backgroundColor: 'transparent !important',
            border: isDouglasElliman
                ? '1px solid #100B28 !important'
                : props.slug && props.slug.primary_color
                ? `1px solid ${props.slug.primary_color} !important`
                : '1px solid #273e59 !important',
        },
    }));

    const classes = useStyles();

    return (
        <div className={`unitCard ${classes.root}`}>
            {props.item.image ? (
                <div className="unitLogo">
                    <img src={props.item.image} alt="" />
                </div>
            ) : (
                <div className="unitLogo">
                    <img src={props.DummyIcon} alt="" />
                </div>
            )}
            <div className="unitTitleDiv">
                <div className="unitTitle">{`${props.item.dimensions} Unit`}</div>
                <div className="unitLocation">
                    {props.item.amenities.map((amenity: any) => {
                        return `${amenity}, `;
                    })}
                    {/* Floor 1, Drive up, Outdoor access */}
                </div>
            </div>
            <div className="unitCurrencyDiv">
                <div className="unitCurrency">$</div>
                <div className="unitAmount">{parseInt(props.item.price)}</div>
                <div className="unitRate">/mo</div>
            </div>
            <div className="unitButtonDiv">
                <Button
                    width="100px"
                    height="50px"
                    className={`unitButton ${classes.unitButton}`}
                    color={
                        isDouglasElliman
                            ? '#100B28'
                            : props.slug && props.slug.primary_color
                            ? props.slug.primary_color
                            : '#273e59'
                    }
                    border={
                        isDouglasElliman
                            ? '1px solid #100B28'
                            : `1px solid ${
                                  props.slug && props.slug.primary_color ? props.slug.primary_color : '#273e59'
                              }`
                    }
                    onClick={() => {
                        props.continue(props.item);
                        let slug: any = localStorage.getItem('slug');
                        if (slug) {
                            slug = JSON.parse(slug);
                        }
                        if (slug && slug.display_name) {
                            let brokerage: any;

                            brokerage = slug.display_name;
                            let brokerage_key: any = slug.brokerage_key;
                            let agent_key: any = slug.realtor_key;

                            let agent_name: any = slug.contact_name;
                            let move_id: any = localStorage.getItem('moveKey');

                            buttonClicked(
                                'Button Clicked',
                                'Storage Facility',
                                brokerage,
                                brokerage_key,
                                agent_name,
                                agent_key,
                                'Moving Services',
                                'Continue',
                                false,
                                '',
                                true,
                                props.item.dimensions + ' Unit',
                                'Storage Unit Card',
                                false,
                                '',
                                false,
                                '',
                                '',
                                move_id,
                            );
                        }
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default UnitCard;
