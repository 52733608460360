export const GET_ENERGY_TYPE = 'GET_ENERGY_TYPE';
export const GET_ENERGY_TYPE_SUCCESS = 'GET_ENERGY_TYPE_SUCCESS';
export const GET_ENERGY_TYPE_FAILURE = 'GET_ENERGY_TYPE_FAILURE';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const GET_ENERGY_PLAN = 'GET_ENERGY_PLAN';
export const GET_ENERGY_PLAN_SUCCESS = 'GET_ENERGY_PLAN_SUCCESS';
export const GET_ENERGY_PLAN_FAILURE = 'GET_ENERGY_PLAN_FAILURE';

export const GET_SWITCH_TYPE = 'GET_SWITCH_TYPE';
export const GET_SWITCH_TYPE_SUCCESS = 'GET_SWITCH_TYPE_SUCCESS';
export const GET_SWITCH_TYPE_FAILURE = 'GET_SWITCH_TYPE_FAILURE';

export const POST_SUBMIT_CONTRACT = 'POST_SUBMIT_CONTRACT';
export const POST_SUBMIT_CONTRACT_SUCCESS = 'POST_SUBMIT_CONTRACT_SUCCESS';
export const POST_SUBMIT_CONTRACT_FAILURE = 'POST_SUBMIT_CONTRACT_FAILURE';

export const GET_ENERGY_SUMMARY = 'GET_ENERGY_SUMMARY';
export const GET_ENERGY_SUMMARY_SUCCESS = 'GET_ENERGY_SUMMARY_SUCCESS';
export const GET_ENERGY_SUMMARY_FAILURE = 'GET_ENERGY_SUMMARY_FAILURE';

export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const GET_ACCESS_TOKEN_FAILURE = 'GET_ACCESS_TOKEN_FAILURE';

export const GET_DISCLAIMER_DETAILS = 'GET_DISCLAIMER_DETAILS';
export const GET_DISCLAIMER_DETAILS_SUCCESS = 'GET_DISCLAIMER_DETAILS_SUCCESS';
export const GET_DISCLAIMER_DETAILS_FAILURE = 'GET_DISCLAIMER_DETAILS_FAILURE';

export const GET_ACCOUNT_NUMBER = 'GET_ACCOUNT_NUMBER';
export const GET_ACCOUNT_NUMBER_SUCCESS = 'GET_ACCOUNT_NUMBER_SUCCESS';
export const GET_ACCOUNT_NUMBER_FAILURE = 'GET_ACCOUNT_NUMBER_FAILURE';
