import React from 'react';

import TextInputs from './TextInputs';

function Main(props: any) {
    return (
        <div className="mainSection">
            <TextInputs
                isFetchingHomeValuation={props.isFetchingHomeValuation}
                affordcalculator={props.affordcalculator}
                property_valuations={props.property_valuations}
                homeValuationCardDetails={props.homeValuationCardDetails}
                onPostAffordablityDataPost={props.onPostAffordablityDataPost}
                isCalculatingAffordability={props.isCalculatingAffordability}
                stopCalculating={props.stopCalculating}
                hash_code={props?.hash_code}
                Rent={props.Rent}
                setRent={props.setRent}
                remainingMortgageBalance={props.remainingMortgageBalance}
                estimatedHomeValue={props.estimatedHomeValue}
                analyticsData={props.analyticsData}
            />
        </div>
    );
}

export default Main;
