import React, { Component } from 'react';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import Delete from '../../../Assets/images/UpdateAddress/updateAddDel.svg';
import './updateAddressModal.scss';
import Back from '../../../Assets/images/UpdateAddress/updateAddBackArrow.svg';

type initialProps = {
    showVerificationModal: boolean;
    handleCloseModal?: any;
    confirmText: string;
    btnText1: string;
    btnText2: string;
    btnColor1: any;
    onDelete: any;
    isDouglasElliman: boolean;
};

export default class DeleteRequestModal extends Component<initialProps> {
    render() {
        const {
            showVerificationModal,
            handleCloseModal,
            confirmText,
            btnText1,
            btnText2,
            btnColor1,
            onDelete,
            isDouglasElliman,
        } = this.props;
        return (
            <Modal
                isShowModal={showVerificationModal}
                onCloseModal={() => handleCloseModal()}
                showClose={false}
                className="updateAddDelModal"
            >
                <div>
                    {window.innerWidth <= 600 && (
                        <div
                            style={{ paddingLeft: '12px', paddingTop: '23px', cursor: 'pointer' }}
                            onClick={() => handleCloseModal()}
                        >
                            <img src={Back} width="24px" height="24px" alt="" />
                        </div>
                    )}
                    <div className="updateAddDelMain">
                        <div className="updateAddDel">
                            <img src={Delete} width="60px" height="60px" alt="" />
                        </div>
                        <div className="updateAddConfirmText">{confirmText}</div>

                        <div className="updateAddDelBtnWrapper">
                            <div className="updateAddCancelBtn">
                                <Button
                                    backgroundColor="#fff"
                                    color={isDouglasElliman ? '#100B28' : btnColor1 ? btnColor1 : '#002E62'}
                                    fontSize="16px"
                                    width="106px"
                                    border="none"
                                    boxShadow="none"
                                    onClick={() => handleCloseModal()}
                                >
                                    {btnText1}
                                </Button>
                            </div>
                            <div className="updateAddDelBtn">
                                <Button
                                    backgroundColor="#fff"
                                    color={isDouglasElliman ? '#100B28' : btnColor1 ? btnColor1 : '#002E62'}
                                    fontSize="16px"
                                    width="106px"
                                    border="none"
                                    boxShadow="none"
                                    onClick={() => onDelete()}
                                >
                                    {btnText2}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
