import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import './remainingMortageBalance.scss';
import MaterialEditIcon from '../../../Assets/images/Icon material-edit.svg';
import Group42860_HouseIcon from '../../../Assets/images/Group_42860.svg';
import CommonService from '../../../utils/common-functions';
import materialInfo from '../../../Assets/images/HomeValuation/material-info.svg';
import ReactTooltip from 'react-tooltip';
import { remainingMortgageBalanceTooltipText } from '../../../utils/constants';
import AnalyticsService from '../../../pages/analytics-service';

interface bannerProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    bannerMargin?: string;
    bannerPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    bannerImage?: any;
    imgName?: any;
    bannerTitle?: string;
    bannerContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    mortageBalance?: string; // Pass formated as string 
    loanTerm?: string;
    apr?: string;
    monthlyPayment?: string;
    startDate?: string;
    endDate?: string;
    isFetchingLoanData?: boolean;
    loanDetails: {
        price: number | undefined,
        loan_year: string | undefined,
        loan_percentage: number | undefined,
        monthly_payment: number | undefined,
        start_date: string | undefined,
        end_date: string | undefined
    };
    remainingBalance: number;
    handleLoanDetailsClick: any;
    analyticsData: any;
}

const getLoaderSkeleton = (width: number, height: number) => <Skeleton width={width} height={height} />;

const RemaingMortageBalance = (props: bannerProps) => {
    const { isFetchingLoanData, remainingBalance, handleLoanDetailsClick, analyticsData } = props;
    
    useEffect(() => {
        AnalyticsService.cardViewed({
             ...analyticsData,
             cardTitle: 'Mortgage Balance',
             category_name: "Mortgage Balance Card Viewed"
         });
    }, [])

    return (
        <div
            style={{ position: 'relative' }}
            onClick={props.onClick}
        // className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-height-width`}
        >
            <>
                {!isFetchingLoanData ?
                    (<div className="remaining-balance-edit" style={{ float: 'right' }} onClick={() => handleLoanDetailsClick('Edit')}>
                        <span className="edit-icon">
                            <img src={MaterialEditIcon} width={'10px'} height={'10px'} alt="" />
                        </span>
                        <span>Edit</span>

                    </div>)
                    : getLoaderSkeleton(50, 40)
                }
            </>
            <div
                style={{
                    color: '#6B6C6F',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    margin: '30px auto',
                    textAlign: 'center',
                }}
            >
                <img
                    width={props.imgWidth ? props.imgWidth : '250px'}
                    height={props.imgHeight ? props.imgHeight : '150px'}
                    src={Group42860_HouseIcon}
                    style={{
                        margin: '15px auto',
                    }}
                    alt="RemaingMortageBalance_Home_icon"
                    className={props.imgClassname ? props.imgClassname : ''}
                />
                <span style={{ textAlign: 'center' }}>{props.imgName}</span>
            </div>
            <div className="MortageBalance">
                <div className="mortgageTitleText">
                    Remaining Mortgage Balance
                    <ReactTooltip
                            effect="solid"
                            border={false}
                            multiline={true}
                            className="tooltipCover"
                            id="remaining-balance-tip"
                        >{remainingMortgageBalanceTooltipText}</ReactTooltip>
                    <img data-tip data-for="remaining-balance-tip" src={materialInfo} style={{ marginTop: '-20px', marginLeft: '5px' }} alt="" />
                </div>

                {!isFetchingLoanData ? (
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                fontWeight: 900,
                                color: '#000',
                                fontSize: '14px',
                                display: 'inline-block',
                            }}
                        >
                            $
                        </div>
                        <span style={{ color: '#333', fontWeight: 'bold', fontSize: '34px', margin: '15px 8px' }}>
                            {' '}
                            { remainingBalance && CommonService.formatCurrency({ value: remainingBalance })}
                        </span>
                    </div>
                ) : (
                    getLoaderSkeleton(150, 40)
                )}

                <div style={{ color: '#6B6C6F', fontWeight: 'bold', fontSize: '12px', marginTop: '20px' }}>
                    <table>
                     <tbody>
                        <tr>
                            <td>
                                <div>Loan Term</div>
                            </td>
                            <td>
                                <div>APR</div>
                            </td>
                            <td>
                                <div>Monthly Payment</div>
                            </td>
                        </tr>
                        <tr style={{ color: '#333333', fontWeight: 'bold', fontSize: '14px' }}>
                            <td>
                                <div>
                                    {!isFetchingLoanData
                                        ? props?.loanDetails?.loan_year || ' '
                                        : getLoaderSkeleton(50, 40)}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {!isFetchingLoanData
                                        ? `${props?.loanDetails?.loan_percentage || 0}%`
                                        : getLoaderSkeleton(50, 40)}
                                </div>
                            </td>
                            <td>
                                <div>
                                    ${!isFetchingLoanData
                                        ? props?.loanDetails?.monthly_payment ? CommonService.formatCurrency({value: Math.round(props?.loanDetails?.monthly_payment || 0)}) : '0'
                                        : getLoaderSkeleton(50, 40)}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{ color: '#6B6C6F', fontWeight: 'bold', fontSize: '12px', marginTop: '20px' }}>
                    <table>
                    <tbody>
                        <tr>
                            <td>
                                <div>Start Date</div>
                            </td>
                            <td>
                                <div>End Date</div>
                            </td>
                            <td>&emsp;&emsp;</td>
                        </tr>
                        <tr style={{ color: '#333333', fontWeight: 'bold', fontSize: '14px' }}>
                            <td>
                                <div>{!isFetchingLoanData ? (props?.loanDetails?.start_date || ' ') : getLoaderSkeleton(50, 40)}</div>
                            </td>
                            <td>
                                <div>{!isFetchingLoanData ? (props?.loanDetails?.end_date || '') : getLoaderSkeleton(50, 40)}</div>
                            </td>
                            <td>&emsp;&emsp; </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RemaingMortageBalance;



