import {
    UPDATE_ADDRESS_NEW_SERVICES_REQUEST,
    UPDATE_ADDRESS_NEW_SERVICES_SUCCESS,
    UPDATE_ADDRESS_NEW_SERVICES_FAILURE,

    UPDATE_ADDRESS_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_REQUEST_FILED_SUCCESS,
    UPDATE_ADDRESS_REQUEST_FILED_FAILURE,

    UPDATE_ADDRESS_PHONE_VERIFY_REQUEST,
    UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS,
    UPDATE_ADDRESS_PHONE_VERIFY_FAILURE,
    
    UPDATE_ADDRESS_OTP_VERIFY_REQUEST,
    UPDATE_ADDRESS_OTP_VERIFY_SUCCESS,
    UPDATE_ADDRESS_OTP_VERIFY_FAILURE,
   
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST,
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS,
    UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE,

    UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST,
    UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS,
    UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE,
    
    UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST,
    UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS,
    UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE,
} from './actionTypes';
import {
    NewServices,
    NewServicesRequest,
    NewServicesFailure,
    NewServicesFailurePayload,
    NewServicesSuccess,
    NewServicesSuccessPayload,

    RequestsFiled,
    RequestsFiledRequest,
    RequestsFiledSuccess,
    RequestsFiledSuccessPayload,
    RequestsFiledFailure,
    RequestsFiledFailurePayload,

    PhoneVerifyRequest,
    PhoneVerifySuccessPayload,
    PhoneVerifyFailurePayload,
    PhoneVerify,
    PhoneVerifySuccess,
    PhoneVerifyFailure,

    OtpVerifyRequest,
    OtpVerifySuccessPayload,
    OtpVerifyFailurePayload,
    OtpVerify,
    OtpVerifySuccess,
    OtpVerifyFailure,

    DeleteRequestsFiledRequest,
    DeleteRequestsFiledSuccessPayload,
    DeleteRequestsFiledFailurePayload,
    DeleteRequestsFiled,
    DeleteRequestsFiledSuccess,
    DeleteRequestsFiledFailure,

    SaveResponse,
    SaveResponseRequest,
    SaveResponseSuccessPayload,
    SaveResponseSuccess,
    SaveResponseFailurePayload,
    SaveResponseFailure,

    UpdateResponseRequest,
    UpdateResponseSuccessPayload,
    UpdateResponseFailurePayload,
    UpdateResponse,
    UpdateResponseSuccess,
    UpdateResponseFailure,
} from './types';

export const newServices = (payload: NewServicesRequest): NewServices => ({
    type: UPDATE_ADDRESS_NEW_SERVICES_REQUEST,
    payload,
});

export const newServicesSuccess = (payload: NewServicesSuccessPayload): NewServicesSuccess => ({
    type: UPDATE_ADDRESS_NEW_SERVICES_SUCCESS,
    payload,
});

export const newServicesFailure = (payload: NewServicesFailurePayload): NewServicesFailure => ({
    type: UPDATE_ADDRESS_NEW_SERVICES_FAILURE,
    payload,
});

export const requestsFiled = (payload: RequestsFiledRequest): RequestsFiled => ({
    type: UPDATE_ADDRESS_REQUEST_FILED_REQUEST,
    payload,
});

export const requestsFiledSuccess = (payload: RequestsFiledSuccessPayload): RequestsFiledSuccess => ({
    type: UPDATE_ADDRESS_REQUEST_FILED_SUCCESS,
    payload,
});

export const requestsFiledFailure = (payload: RequestsFiledFailurePayload): RequestsFiledFailure => ({
    type: UPDATE_ADDRESS_REQUEST_FILED_FAILURE,
    payload,
});

export const phoneVerify = (payload: PhoneVerifyRequest): PhoneVerify => ({
    type: UPDATE_ADDRESS_PHONE_VERIFY_REQUEST,
    payload,
});

export const phoneVerifySuccess = (payload: PhoneVerifySuccessPayload): PhoneVerifySuccess => ({
    type: UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS,
    payload,
});

export const phoneVerifyFailure = (payload: PhoneVerifyFailurePayload): PhoneVerifyFailure => ({
    type: UPDATE_ADDRESS_PHONE_VERIFY_FAILURE,
    payload,
});

export const otpVerify = (payload: OtpVerifyRequest): OtpVerify => ({
    type: UPDATE_ADDRESS_OTP_VERIFY_REQUEST,
    payload,
});

export const otpVerifySuccess = (payload: OtpVerifySuccessPayload): OtpVerifySuccess => ({
    type: UPDATE_ADDRESS_OTP_VERIFY_SUCCESS,
    payload,
});

export const otpVerifyFailure = (payload: OtpVerifyFailurePayload): OtpVerifyFailure => ({
    type: UPDATE_ADDRESS_OTP_VERIFY_FAILURE,
    payload,
});

export const delRequestsFiled = (payload: DeleteRequestsFiledRequest): DeleteRequestsFiled => ({
    type: UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST,
    payload,
});

export const delRequestsFiledSuccess = (payload: DeleteRequestsFiledSuccessPayload): DeleteRequestsFiledSuccess => ({
    type: UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS,
    payload,
});

export const delRequestsFiledFailure = (payload: DeleteRequestsFiledFailurePayload): DeleteRequestsFiledFailure => ({
    type: UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE,
    payload,
});
export const saveResponse = (payload: SaveResponseRequest): SaveResponse => ({
    type: UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST,
    payload,
});

export const saveResponseSuccess = (payload: SaveResponseSuccessPayload): SaveResponseSuccess => ({
    type: UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS,
    payload,
});

export const saveResponseFailure = (payload: SaveResponseFailurePayload): SaveResponseFailure => ({
    type: UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE,
    payload,
});

export const updateResponse = (payload: UpdateResponseRequest): UpdateResponse => ({
    type: UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST,
    payload,
});

export const updateResponseSuccess = (payload: UpdateResponseSuccessPayload): UpdateResponseSuccess => ({
    type: UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS,
    payload,
});

export const updateResponseFailure = (payload: UpdateResponseFailurePayload): UpdateResponseFailure => ({
    type: UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE,
    payload,
});
