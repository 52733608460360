// import React, { useEffect } from 'react';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import iIcon from '../../Assets/images/Moving/iIcon.svg';
// import tickIcon from '../../Assets/images/Moving/tickIcon.svg';
import CheckIcon from '@material-ui/icons/Check';
import '../../styles/movingStepperCard.scss';

type iProps = {
    checked: boolean;
    handleChange: any;
    className?: string;
    icon: any;
    label: string;
    iconWidth?: string;
    iconHeight?: string;
    width?: string;
    height?: string;
    name?: string;
    heading?: string;
    points?: any;
    recommended?: any;
    isDouglasElliman: boolean;
};

const MovingStepperCard = (props: iProps) => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                borderColor: '#333333 !important',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} card-main-class`}
            onClick={props.handleChange}
            style={{
                border: props.checked
                    ? props.isDouglasElliman
                        ? '#009CBD 2px solid'
                        : slug && slug.primary_color
                        ? `${slug.primary_color} 2px solid`
                        : `#273E59 2px solid`
                    : '#BEBEBE 2px solid',
                width: props.width ? props.width : '290px',
                height: props.height ? props.height : '100%',
                borderRadius: '5px',
                textAlign: 'left',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '60%' }}>
                    <Checkbox
                        className={props.className ? `${props.className} ${classes.checkroot}` : classes.checkroot}
                        checked={props.checked}
                        name={props.name ? props.name : ''}
                        onChange={props.handleChange}
                        color="default"
                        style={{
                            color: props.checked
                                ? props.isDouglasElliman
                                    ? '#009CBD'
                                    : slug && slug.primary_color
                                    ? `${slug.primary_color}`
                                    : '#273E59'
                                : '#333',
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                {props.recommended ? (
                    <div
                        style={{
                            width: '50%',
                            backgroundColor: props.isDouglasElliman ? '#009CBD' : '#1966E7',
                            color: '#fff',
                            fontWeight: 'bold',
                            paddingTop: '10px',
                            fontSize: '14px',
                            borderRadius: '4px',
                            textAlign: 'center',
                            margin: '-1px -1px 0px 0px',
                        }}
                    >
                        <span style={{ marginLeft: '5px' }}>RECOMMENDED</span>
                    </div>
                ) : null}
            </div>
            <div
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', margin: '10px', height: '65px' }}
            >
                {props.icon}
            </div>
            <div
                style={{
                    // color: props.checked
                    //     ? slug && slug.primary_color
                    //         ? `${slug.primary_color}`
                    //         : '#273E59'
                    //     : '#BEBEBE',
                    color: props.checked ? '#333333' : '#BEBEBE',
                    textAlign: 'left',
                    fontSize: '22px',
                    marginTop: '5px',
                    fontWeight: 1000,
                    margin: '0 10px',
                }}
            >
                {props.label}
            </div>

            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', padding: '10px' }}>
                <div
                    style={{
                        fontSize: '16px',
                        // color: props.checked
                        //     ? slug && slug.primary_color
                        //         ? `${slug.primary_color}`
                        //         : '#273E59'
                        //     : '#BEBEBE',
                        color: props.checked ? '#333333' : '#BEBEBE',
                        textAlign: 'left',
                        // padding: '0 5px',
                        display: 'flex',
                    }}
                >
                    <div> {props.heading}</div>
                    <div style={{ marginLeft: '3px', marginTop: '3px' }}>
                        <img src={iIcon} width="15px" height="15px" alt="" />
                    </div>
                </div>
                {props.points.map((item: string, i: number) => {
                    return (
                        <div
                            key={i}
                            className="points-div"
                            style={{
                                paddingTop: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                color: props.checked
                                    ? //  slug && slug.primary_color
                                      //     ? `${slug.primary_color}`
                                      // :
                                      '#273E59'
                                    : '#BEBEBE',
                            }}
                        >
                            {/* <div style={{ marginLeft: '-5px' }}>
                                <img src={tickIcon} width="25px" height="10px" />
                            </div> */}
                            <CheckIcon
                                style={{
                                    fontSize: '22px',
                                    color: props.checked ? '#00D56D' : '#BEBEBE',
                                    marginRight: '6px',
                                }}
                            />
                            <div style={{ fontSize: '14px' }}>{item}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MovingStepperCard;
