import React, { useEffect } from 'react';
import Button from '../../../components/atoms/Button';
import BlueArrow from '../../../Assets/images/HomeValuation/BlueArrow.png';
import BestDeals from '../../../Assets/images/HomeValuation/BestDeals.png';
import './HomeBanner.scss';
import AnalyticsService from '../../../pages/analytics-service';

interface bannerProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    bannerMargin?: string;
    bannerPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    bannerImage?: any;
    imgName?: any;
    bannerTitle?: string;
    bannerContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    buttonPresent?: boolean;
    Top?: string;
    onButtonClick?: Function;
    href1?: string;
    fromDeals?: boolean;
    analyticsData: any;
}
let slug: any = localStorage.getItem('slug');

slug = JSON.parse(slug);

const HomeBanner = (props: bannerProps) => {

    const { fromDeals, analyticsData, bannerTitle } = props;

    useEffect(() => {
        AnalyticsService.cardViewed({
             ...analyticsData,
             cardTitle: bannerTitle,
             category_name: fromDeals ? "Deals Card Viewed" : "Contact Card Viewed"
         });
    }, [])
    
    return (
        <div
            className={`MainBanner ${fromDeals && 'dealsBannerCover'}`}
            onClick={props.onClick}
        >
            <div className="banner-Container">
                {fromDeals && (
                    <div className="ribbonCover">
                        <img src={BestDeals} alt="" />
                    </div>
                )}
                <div className="bannerImage">
                   <img
                        src={props?.bannerImage || slug?.contact_logo}
                        style={{
                            marginRight: 'auto',
                            marginBottom: '5px',
                            width: '80px',
                            height: '80px',
                            borderRadius: props.borderRadius,
                        }}
                        alt=""
                        className={props.imgClassname ? props.imgClassname : ''}
                    />
                    {props.buttonPresent && (
                        <span className="nameBanner">{slug && slug.contact_name ? slug.contact_name : ''}</span>
                    )}
                </div>
            </div>
            <div style={{ marginTop: props.Top ? props.Top : '0' }} className="bannerTitle">
                {bannerTitle}
                <div className="bannerSubTitle">{props.bannerContent}</div>
                {props.buttonPresent && (
                    <>
                        <br />
                        <span className="mobile-nameBanner">{slug && slug.contact_name ? slug.contact_name : ''}</span>
                    </>
                )}
            </div>

            {props.buttonPresent ? (
                <Button
                    marginTop="-10px"
                    onClick={props.onButtonClick || ''}
                    borderRadius="4px"
                    children="Contact Me"
                />
            ) : (
                <div className="learnMore">
                    <a href={props.href1} target="_blank" className="text">
                        Learn More{' '}
                        <span style={{ marginLeft: '5px' }}>
                            <img src={BlueArrow} alt="" />
                        </span>{' '}
                    </a>
                </div>
            )}
        </div>
    );
};

export default HomeBanner;
