import { all, fork } from 'redux-saga/effects';

import todoSaga from './todo/sagas';
import loginSaga from './login/sagas';
import internetSaga from './internet/sagas';
import commonSaga from './common/sagas';
import moversSaga from './move/sagas';
import insuranceSaga from './insurance/sagas';
import utilitySaga from './utility/sagas';
import checklistSaga from './checklist/sagas';
import checklistDashboardSaga from './dashboard/sagas';
import dmvSage from './dmv/sagas';
import signupSaga from './login/signup/sagas';
import updateAddressSaga from './updateAddress/sagas';
import trustedProsSaga from './trustedPros/sagas';
import autoShippingSaga from './autoshipping/sagas';
import preMoveSaga from './premove/sagas';
import securitySaga from './security/sagas';
import warrantySaga from './homeWarranty/sagas';
import energySaga from './energy/sagas';
import documentrySaga from './documentary/sagas';
import serviceSaga from './savings/sagas';
import homeOwnerdashboardSaga from './homeOwnerDashboard/sagas';
import nextMoveSaga from './nextMove/sagas';
import homeSecuritySaga from './homeSecurity/sagas';

export function* rootSaga() {
    yield all([
        fork(todoSaga),
        fork(loginSaga),
        fork(internetSaga),
        fork(commonSaga),
        fork(moversSaga),
        fork(insuranceSaga),
        fork(utilitySaga),
        fork(checklistSaga),
        fork(checklistDashboardSaga),
        fork(dmvSage),
        fork(signupSaga),
        fork(updateAddressSaga),
        fork(trustedProsSaga),
        fork(autoShippingSaga),
        fork(preMoveSaga),
        fork(securitySaga),
        fork(warrantySaga),
        fork(energySaga),
        fork(documentrySaga),
        fork(serviceSaga),
        fork(homeOwnerdashboardSaga),
        fork(nextMoveSaga),
        fork(homeSecuritySaga)
    ]);
}
