import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={75.821}
            height={71}
            viewBox="0 0 75.821 71"
        >
            <defs>
                <style>
                    {`.usaStepprefix__a{fill:${
                       props.fromlanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="usaStepprefix__a"
                d="M25.162 29.665h-2.221v-2.222h2.221zm-3.7 0h-2.221v-2.222h2.221zm0 0"
            />
            <path
                className="usaStepprefix__a"
                d="M22.201 45.373c4.075-6.014 15.073-23.082 15.073-30.3a15.073 15.073 0 10-30.146 0c.001 7.218 10.999 24.286 15.073 30.3zM9.35 15.073a12.852 12.852 0 1125.7 0c0 6.051-9.525 21.225-12.852 26.309C18.892 36.323 9.35 21.13 9.35 15.073zm0 0"
            />
            <path
                className="usaStepprefix__a"
                d="M29.363 15.073a7.162 7.162 0 10-7.162 7.161 7.17 7.17 0 007.162-7.161zm-12.1 0a4.94 4.94 0 114.94 4.94 4.946 4.946 0 01-4.942-4.94zm0 0M70.857 21.616l-11.2 11.2h-4.346l-9.356-9.486h-9v2.217h8.07l9.356 9.486h6.2l10.276-10.276 2.743 2.742v3.319l-2.647 2.647v17.931l-3.471 3.471v5.045l2.557 2.557v2.069l-1.606 1.609-5.655-5.655h-17.75l-1.793 4.33 1.849 1.849-2.108 2.108h-2.62l-8.287-8.287h-14.22L2.221 44.864V25.547h5.225v-2.221H0v22.456L16.928 62.71h14.22l8.287 8.287H43.9l4.329-4.329-2.371-2.371.658-1.587h15.342l6.575 6.575 3.828-3.828v-3.91L69.7 58.992v-3.2l3.471-3.471V34.385l2.647-2.646V26.58zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
