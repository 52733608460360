import {
    GET_MOVERS,
    GET_MOVERS_ERROR,
    GET_MOVERS_SUCCESS,
    GET_SELECTED_MOVERS,
    GET_SELECTED_MOVERS_ERROR,
    GET_SELECTED_MOVERS_SUCCESS,
    GET_REVERSE_STORAGE,
    GET_REVERSE_STORAGE_SUCCESS,
    GET_REVERSE_STORAGE_ERROR,
    GET_RESERVE_STORAGE_BY_FACILITY_ID,
    GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS,
    GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR,
    POST_MOVERS,
    POST_MOVERS_ERROR,
    POST_MOVERS_SUCCESS,
    POST_RESERVE_STORAGE,
    POST_RESERVE_STORAGE_SUCCESS,
    POST_RESERVE_STORAGE_ERROR,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR,
    UPDATE_DISCLAIMER,
    UPDATE_DISCLAIMER_SUCCESS,
    UPDATE_DISCLAIMER_ERROR,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR,
    GET_DIY,
    GET_DIY_ERROR,
    GET_DIY_SUCCESS,
} from './actionTypes';
import {
    Movers,
    MoversRequest,
    MoversFailure,
    MoversFailurePayload,
    MoversSuccess,
    MoversSuccessPayload,
    SelectedMovers,
    SelectedMoversRequest,
    SelectedMoversFailure,
    SelectedMoversFailurePayload,
    SelectedMoversSuccess,
    SelectedMoversSuccessPayload,
    ReverseStorage,
    ReverseStorageRequest,
    ReverseStorageFailure,
    ReverseStorageFailurePayload,
    ReverseStorageSuccess,
    ReverseStorageSuccessPayload,
    PostMovers,
    PostMoversRequest,
    PostMoversFailurePayload,
    PostMoversSuccess,
    PostMoversFailure,
    PostMoversSuccessPayload,
    ReserveStorageByFacilityId,
    ReserveStorageByFacilityIdRequest,
    ReserveStorageByFacilityIdFailure,
    ReserveStorageByFacilityIdFailurePayload,
    ReserveStorageByFacilityIdSuccess,
    ReserveStorageByFacilityIdSuccessPayload,
    PostReserveStorage,
    PostReserveStorageRequest,
    PostReserveStorageFailurePayload,
    PostReserveStorageSuccess,
    PostReserveStorageFailure,
    PostReserveStorageSuccessPayload,
    UpdateMoveSourceHomeInfo,
    UpdateMoveSourceHomeInfoRequest,
    UpdateMoveSourceHomeInfoFailurePayload,
    UpdateMoveSourceHomeInfoSuccess,
    UpdateMoveSourceHomeInfoFailure,
    UpdateMoveSourceHomeInfoSuccessPayload,
    UpdateMoveDestinationHomeInfo,
    UpdateMoveDestinationHomeInfoRequest,
    UpdateMoveDestinationHomeInfoFailurePayload,
    UpdateMoveDestinationHomeInfoSuccess,
    UpdateMoveDestinationHomeInfoFailure,
    UpdateMoveDestinationHomeInfoSuccessPayload,

    UpdateDisclaimer,
    UpdateDisclaimerRequest,
    UpdateDisclaimerFailurePayload,
    UpdateDisclaimerSuccess,
    UpdateDisclaimerFailure,
    UpdateDisclaimerSuccessPayload,
    Diy,
    DiyFailure,
    DiySuccess,
    DiyFailurePayload,
    DiyRequest,
    DiySuccessPayload,
} from './types';

export const movers = (payload: MoversRequest): Movers => ({
    type: GET_MOVERS,
    payload,
});

export const moversSuccess = (payload: MoversSuccessPayload): MoversSuccess => ({
    type: GET_MOVERS_SUCCESS,
    payload,
});

export const moversFailure = (payload: MoversFailurePayload): MoversFailure => ({
    type: GET_MOVERS_ERROR,
    payload,
});

//Get Selected Movers
export const selectedMovers = (payload: SelectedMoversRequest): SelectedMovers => ({
    type: GET_SELECTED_MOVERS,
    payload,
});

export const selectedMoversSuccess = (payload: SelectedMoversSuccessPayload): SelectedMoversSuccess => ({
    type: GET_SELECTED_MOVERS_SUCCESS,
    payload,
});

export const selectedMoversFailure = (payload: SelectedMoversFailurePayload): SelectedMoversFailure => ({
    type: GET_SELECTED_MOVERS_ERROR,
    payload,
});

//Post Movers

export const Postmovers = (payload: PostMoversRequest): PostMovers => {
    return {
        type: POST_MOVERS,
        payload,
    };
};

export const PostmoversSuccess = (payload: PostMoversSuccessPayload): PostMoversSuccess => {
    return {
        type: POST_MOVERS_SUCCESS,
        payload,
    };
};

export const PostmoversFailure = (payload: PostMoversFailurePayload): PostMoversFailure => {
    return {
        type: POST_MOVERS_ERROR,
        payload,
    };
};
// reverse storage

export const reverseStorage = (payload: ReverseStorageRequest): ReverseStorage => ({
    type: GET_REVERSE_STORAGE,
    payload,
});

export const reverseStorageSuccess = (payload: ReverseStorageSuccessPayload): ReverseStorageSuccess => ({
    type: GET_REVERSE_STORAGE_SUCCESS,
    payload,
});

export const reverseStorageFailure = (payload: ReverseStorageFailurePayload): ReverseStorageFailure => ({
    type: GET_REVERSE_STORAGE_ERROR,
    payload,
});

export const reserveStorageByFacilityId = (payload: ReserveStorageByFacilityIdRequest): ReserveStorageByFacilityId => ({
    type: GET_RESERVE_STORAGE_BY_FACILITY_ID,
    payload,
});

export const reserveStorageByFacilityIdSuccess = (
    payload: ReserveStorageByFacilityIdSuccessPayload,
): ReserveStorageByFacilityIdSuccess => ({
    type: GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS,
    payload,
});

export const reserveStorageByFacilityIdFailure = (
    payload: ReserveStorageByFacilityIdFailurePayload,
): ReserveStorageByFacilityIdFailure => ({
    type: GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR,
    payload,
});

export const postReserveStorage = (payload: PostReserveStorageRequest): PostReserveStorage => {
    return {
        type: POST_RESERVE_STORAGE,
        payload,
    };
};

export const postReserveStorageSuccess = (payload: PostReserveStorageSuccessPayload): PostReserveStorageSuccess => {
    return {
        type: POST_RESERVE_STORAGE_SUCCESS,
        payload,
    };
};

export const postReserveStorageFailure = (payload: PostReserveStorageFailurePayload): PostReserveStorageFailure => {
    return {
        type: POST_RESERVE_STORAGE_ERROR,
        payload,
    };
};

// patch move api

export const updateMoveSourceHomeInfo = (payload: UpdateMoveSourceHomeInfoRequest): UpdateMoveSourceHomeInfo => {
    return {
        type: UPDATE_MOVE_SOURCE_HOME_INFORMATION,
        payload,
    };
};

export const updateMoveSourceHomeInfoSuccess = (
    payload: UpdateMoveSourceHomeInfoSuccessPayload,
): UpdateMoveSourceHomeInfoSuccess => {
    return {
        type: UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS,
        payload,
    };
};

export const updateMoveSourceHomeInfoFailure = (
    payload: UpdateMoveSourceHomeInfoFailurePayload,
): UpdateMoveSourceHomeInfoFailure => {
    return {
        type: UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR,
        payload,
    };
};

//update dislcaimar 
export const updateDisclaimer = (payload: UpdateDisclaimerRequest): UpdateDisclaimer => {
    return {
        type: UPDATE_DISCLAIMER,
        payload,
    };
};

export const updateDisclaimerSuccess = (
    payload: UpdateDisclaimerSuccessPayload,
): UpdateDisclaimerSuccess => {
    return {
        type: UPDATE_DISCLAIMER_SUCCESS,
        payload,
    };
};

export const updateDisclaimerFailure = (
    payload: UpdateDisclaimerFailurePayload,
): UpdateDisclaimerFailure => {
    return {
        type: UPDATE_DISCLAIMER_ERROR,
        payload,
    };
};

//update Move Destination

export const updateMoveDestinationHomeInfo = (
    payload: UpdateMoveDestinationHomeInfoRequest,
): UpdateMoveDestinationHomeInfo => {
    return {
        type: UPDATE_MOVE_DESTINATION_HOME_INFORMATION,
        payload,
    };
};

export const updateMoveDestinationHomeInfoSuccess = (
    payload: UpdateMoveDestinationHomeInfoSuccessPayload,
): UpdateMoveDestinationHomeInfoSuccess => {
    return {
        type: UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS,
        payload,
    };
};

export const updateMoveDestinationHomeInfoFailure = (
    payload: UpdateMoveDestinationHomeInfoFailurePayload,
): UpdateMoveDestinationHomeInfoFailure => {
    return {
        type: UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR,
        payload,
    };
};

//get diy
export const diy = (payload: DiyRequest): Diy => ({
    type: GET_DIY,
    payload,
});

export const diySuccess = (payload: DiySuccessPayload): DiySuccess => ({
    type: GET_DIY_SUCCESS,
    payload,
});

export const diyFailure = (payload: DiyFailurePayload): DiyFailure => ({
    type: GET_DIY_ERROR,
    payload,
});
