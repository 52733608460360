import React from 'react';

// douglas icons
import MortgageD from '../Assets/images/DouglasImages/header/mortgage';
import InsuranceD from '../Assets/images/DouglasImages/header/insurance';
import MovingD from '../Assets/images/DouglasImages/header/moving';
import UpdateAddressD from '../Assets/images/DouglasImages/header/updateAddress';
import UtilityProvidersD from '../Assets/images/DouglasImages/header/utility';
import DriversLicenceD from '../Assets/images/DouglasImages/header/drivingLicence';
import InternetCableD from '../Assets/images/DouglasImages/header/internet';
import HomeWarrantyD from '../Assets/images/DouglasImages/header/homeWarranty';
import ExclusiveDealsD from '../Assets/images/DouglasImages/header/exclusive';
import ReserveStorageD from '../Assets/images/headerImages/reserveStorage';
import HomeSecurityD from '../Assets/images/DouglasImages/header/homeSecurity';
import TitleD from '../Assets/images/DouglasImages/header/title';
import TrustedProsD from '../Assets/images/DouglasImages/header/trustedPros';
import ChecklistD from '../Assets/images/DouglasImages/header/checklist';
import CarShippingD from '../Assets/images/DouglasImages/header/carShipping';

//default icons
import EnergyD from '../Assets/images/headerImages/EnergyD';
import Energy from '../Assets/images/headerImages/energy';
import Document from '../Assets/images/headerImages/document';
import Mortgage from '../Assets/images/headerImages/mortgage';
import Insurance from '../Assets/images/headerImages/home-insurance';
import Moving from '../Assets/images/headerImages/moving-truck';
import UpdateAddress from '../Assets/images/headerImages/home-address';
import UtilityProviders from '../Assets/images/headerImages/hydro-power';
import DriversLicence from '../Assets/images/headerImages/id-card';
import InternetCable from '../Assets/images/headerImages/livingroom';
import HomeWarranty from '../Assets/images/headerImages/home-waranty';
import ExclusiveDeals from '../Assets/images/headerImages/exclusiveDeals';
import ReserveStorage from '../Assets/images/headerImages/reserveStorage';
import HomeSecurity from '../Assets/images/headerImages/homeSecurity';
import Title from '../Assets/images/headerImages/title';
import TrustedPros from '../Assets/images/headerImages/trustedPros';
import Checklist from '../Assets/images/headerImages/checklist';
import Relocation from '../Assets/images/headerImages/homeManagement';
import PetTransport from '../Assets/images/headerImages/PetTransport';
import HomeValuation from '../Assets/images/headerImages/Homevaluation';
import { customServiceIcon } from '../config';
import Svg from './Svg';

export const getIcon = (isDouglasElliman: boolean, item: any) => {
    if (isDouglasElliman) {
        let icon: any = <Relocation className="tabIcons" item={item} />;
        if (item.route === 'mortgage') {
            icon = <MortgageD className="tabIcons" />;
        } else if (item.route === 'title') {
            icon = <TitleD className="tabIcons" />;
        } else if (item.route === 'homewarranty' || item.route === 'home-warranty') {
            icon = <HomeWarrantyD className="tabIcons" />;
        } else if (item.route === 'movers') {
            icon = <MovingD className="tabIcons" />;
        } else if (item.route === 'storage') {
            icon = <ReserveStorageD className="tabIcons" />;
        } else if (item.route === 'address') {
            icon = <UpdateAddressD className="tabIcons" />;
        } else if (item.route === 'utilities') {
            icon = <UtilityProvidersD className="tabIcons" />;
        } else if (item.route === 'internet') {
            icon = <InternetCableD className="tabIcons" />;
        } else if (item.route === 'home-insurance') {
            icon = <InsuranceD className="tabIcons" />;
        } else if (item.route === 'ourvendors') {
            icon = <TrustedProsD className="tabIcons" />;
        } else if (item.route === 'driverlicense') {
            icon = <DriversLicenceD className="tabIcons" />;
        } else if (item.route === 'home-security') {
            icon = <HomeSecurityD className="tabIcons" />;
        } else if (item.route === 'deals') {
            icon = <ExclusiveDealsD className="tabIcons" />;
        } else if (item.route === 'checklist') {
            icon = <ChecklistD className="tabIcons" />;
        } else if (item.route === 'autoease') {
            icon = <CarShippingD className="tabIcons" />;
        } else if (item.route === 'my-documents') {
            icon = <Document className="tabIcons" />;
        } else if (item.route === 'energy-savings') {
            icon = <EnergyD className="tabIcons" />;
        }
        return icon;
    } else {
        let icon: any = <Relocation className="tabIcons" item={item} />;
        if (item.is_default === false) {
            icon = <Svg url={customServiceIcon} />;
        }

        if (item.route === 'mortgage') {
            icon = <Mortgage className="tabIcons" />;
        } else if (item.route === 'title') {
            icon = <Title className="tabIcons" />;
        } else if (item.route === 'homewarranty' || item.route === 'home-warranty') {
            icon = <HomeWarranty className="tabIcons" />;
        } else if (item.route === 'movers') {
            icon = <Moving className="tabIcons" />;
        } else if (item.route === 'storage') {
            icon = <ReserveStorage className="tabIcons" />;
        } else if (item.route === 'address') {
            icon = <UpdateAddress className="tabIcons" />;
        } else if (item.route === 'utilities') {
            icon = <UtilityProviders className="tabIcons" />;
        } else if (item.route === 'internet') {
            icon = <InternetCable className="tabIcons" />;
        } else if (item.route === 'home-insurance') {
            icon = <Insurance className="tabIcons" />;
        } else if (item.route === 'ourvendors') {
            icon = <TrustedPros className="tabIcons" />;
        } else if (item.route === 'driverlicense') {
            icon = <DriversLicence className="tabIcons" />;
        } else if (item.route === 'home-security') {
            icon = <HomeSecurity className="tabIcons" />;
        } else if (item.route === 'deals') {
            icon = <ExclusiveDeals className="tabIcons" />;
        } else if (item.route === 'checklist') {
            icon = <Checklist className="tabIcons" />;
        } else if (item.route === 'autoease') {
            icon = <CarShippingD className="tabIcons" />;
        } else if (item.route === 'my-documents') {
            icon = <Document className="tabIcons" />;
        } else if (item.route === 'homeValuation') {
            icon = <HomeValuation className="tabIcons" />;
        } else if (item.route === 'energy-savings') {
            icon = <Energy className="tabIcons" />;
        } else if (item.route === 'pettransport') {
            icon = <PetTransport className="tabIcons" />;
        }
        return icon;
    }
};
