/**
 * To convert any number to currency type
 * @param number Number to be converted to currency type
 * @returns String with comma included
 */
export const numberWithCommas = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * To check if the given number is valid percentage value 0 < number <= 100
 * @param number to check if number in percentage range
 * @returns boolean true or false
 */
export const isValidPercentage = (number: number): boolean => {
    return number <= 100 && number > 0;
};

/**
 * To format currency
 */
export const formatCurrency = (value: number = 0, locale = 'en-US', roundValue = false): string => {
    let number = value;
    if (roundValue) {
        number = Math.round(number);
    }

    return new Intl.NumberFormat(locale).format(number);
};

/**
 * Split dates
 * @param date date in string format ex: 12-09-2022
 * @returns array after splitting [12, 9, 2022]
 */
export const splitDateParts = (date: string): number[] | undefined => {
    if (date && date != '') {
        const stringSplits = date.split('-');
        if (stringSplits.length == 3) {
            return stringSplits.map(function(e): number {
                return parseInt(e);
            });
        }
    }

    return;
};

/**
 * To generate array of numbers in given range
 * @param from From number
 * @param to To number
 * @returns Array of numbers
 */
export function generateYearList(from: number, to: number): number[] {
    let year = [];
    for (let i = from; i <= to; i++) {
        year.push(i); //yearOption.push({ label: `${i}`, value: i });
    }
    return year;
}

/**
 * Convert number array to Dropdown compatible object
 * @param array number array to be converted
 * @returns Object of type DropDownValueObject
 */
export interface DropDownValueObject {
    value: number;
    label: string;
}
export function generateDropdownValueObject(array: number[]): DropDownValueObject[] {
    return array.map(e => {
        return {
            value: e,
            label: `${e}`,
        };
    });
}

//TODO : Simplify
export function getUptoCurrentMonth(monthList: string[], currentMonth:string, year: number, currentYear: number){
    const monthListFiltered = monthList.filter((_, index) => {
        if (currentYear === year) {
            const indexOfCurrentMonth = monthList.indexOf(currentMonth);
            return (indexOfCurrentMonth && indexOfCurrentMonth !== -1 && index <= indexOfCurrentMonth) 
        }else{
            return true
        }
    })

    return monthListFiltered.map((ele: string) => {
        return { value: ele, label: ele }
    })
}

/**
 * Get Service title - dashboard
 * @returns String - service title, concatinating display name and ref field
 */
export function getServiceTitle(){
    let serviceTitle = "Services"
    const displayName: any = localStorage.getItem('displayName');
    const conciergeReferenceField: any = localStorage.getItem('concierge_reference_field')
    if(displayName){
        if(conciergeReferenceField){
            if(conciergeReferenceField.toLocaleLowerCase() == 'concierge'){
                serviceTitle = `${displayName}` 
            } else {
                serviceTitle = `${displayName} ${conciergeReferenceField}`
            }
        } else {
            serviceTitle = `${displayName}`
        }
        serviceTitle = `${serviceTitle} Services`
    }

    return serviceTitle
}

  /**
   * Check if string is valid json
   * @param str any string
   * @returns Return true if valid else false
   */
  export const isJsonString = (str: string) : boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}