import React, { Component } from 'react';
import './documentPreview.scss';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
// import DownloadIcon from '@material-ui/icons/Download';

type initialProps = {
    imageName?: string;
    description?: string;
    toggle: any;
};

type initialState = {};
export class DocumentRightNav extends Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        return (
            <div className="preview-right-nav-main">
                <div className="right-left-side">
                    <div className="right-side-name">{this.props.imageName}</div>
                    <div className="">
                        <ArrowLeftIcon onClick={this.props.toggle} />
                    </div>
                </div>
                <div className="right-right-side">
                    <div className="right-side-des">Description</div>
                    <div className="right-side-theory">{this.props.description}</div>
                </div>
            </div>
        );
    }
}
export default DocumentRightNav;
