import React, { Component } from 'react';
import './dashboardCss/mobileDateCard.scss';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import downArrow from '../../Assets/images/DashBoard/downArrow.svg';
import downArrowblue from '../../Assets/images/DashBoard/downblue.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';

interface initialProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardContent?: string;
    boxShadow?: any;
    commonState?: any;
    commonAction?: any;
}
interface initialState {
    dateNumber?: any;
    date?: string;
    clicked3?: any;
    isDataArrived?: boolean;
    moveId?: any;
    anchorEl?: any;
}

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class MobileDateCard extends Component<initialProps, initialState> {
    state: initialState = {
        date: `${new Date().getDate()} ${monthNames[new Date().getMonth()]} ,${new Date().getFullYear()}`,
        clicked3: false,
        dateNumber: new Date().getDate(),
        anchorEl: null,
        isDataArrived: true,
    };

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                moveId: cur.move[0].id,
                date: days[new Date(cur.move[0].moving_date).getDay()],
                dateNumber: `${new Date(cur.move[0].moving_date).getDate()} ${
                    monthNames[new Date(cur.move[0].moving_date).getMonth()]
                }, ${new Date(cur.move[0].moving_date).getFullYear()}`,
            });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.date = days[new Date(nextProps.commonState.move[0].moving_date).getDay()];
            currentState.dateNumber = `${new Date(nextProps.commonState.move[0].moving_date).getDate()} ${
                monthNames[new Date(nextProps.commonState.move[0].moving_date).getMonth()]
            }, ${new Date(nextProps.commonState.move[0].moving_date)
                .getFullYear()
                .toString()
                .substring(2)}`;
        }
        return currentState;
    }

    componentDidMount() {
        this.props.commonAction.moveGet();
    }

    dateHandler = (e: any) => {
        let now = new Date(e);
        const dateNumber = now.getDate();
        const month = now.getMonth();
        const year = now.getFullYear();

        this.setState({
            date: days[now.getDay()],
            dateNumber: `${dateNumber} ${monthNames[month]}, ${year.toString().substring(2)}`,
            clicked3: false,
            // isOpen:false
        });
        this.props.commonAction.moveAddressUpdate({
            moving_date: e.toISOString().split('T')[0],
            move_id: this.state.moveId,
        });
    };

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        // const isOpen=Boolean(this.state.anchorEl)
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                style={{
                    width: this.props.width ? this.props.width : '500px',
                    height: this.props.height ? this.props.height : '90px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: this.props.borderRadius ? this.props.borderRadius : '3px',
                    boxShadow: this.props.boxShadow ? this.props.boxShadow : null,
                    border: this.props.border ? this.props.border : '1px solid #DADCE0',
                    opacity: '1',
                    margin: this.props.cardMargin ? this.props.cardMargin : '',
                    padding: this.props.cardPadding ? this.props.cardPadding : '0px',
                }}
                className={
                    this.props.className
                        ? `${this.props.className}`
                        : isDouglasElliman
                        ? 'card-date-mobile card-date-mobileD'
                        : `card-date-mobile`
                }
            >
                <div className="cardContent-date">
                    <div className="card-section-3-date">
                        <div className="section-3-heading-date">
                            DATE{' '}
                            <span>
                                {isDouglasElliman ? (
                                    <img src={downArrow} width="10px" height="10px" style={{ marginLeft: '10px' }} alt="" />
                                ) : (
                                    <img
                                        src={downArrowblue}
                                        width="10px"
                                        height="10px"
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}
                            </span>
                        </div>
                        {this.state.clicked3 == false ? (
                            <div>
                                <div className="section-3-number">{this.state.dateNumber}</div>
                                <div
                                    className="section-3-content"
                                    onClick={e => {
                                        this.setState({ clicked3: true });
                                        this.handleClick(e);
                                    }}
                                >
                                    {this.state.date}
                                </div>
                            </div>
                        ) : (
                            <div className="date-picker">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disablePast
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        // label="Date picker inline"
                                        className="date12"
                                        value={this.state.date}
                                        onChange={(e: any) => {
                                            this.dateHandler(e);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            onFocus: e => {
                                                this.handleClick(e);
                                            },
                                        }}
                                        autoOk={true}
                                        PopoverProps={{
                                            disableRestoreFocus: true,
                                            onClose: () => {
                                                this.setState({ clicked3: false, anchorEl: null });
                                            },
                                            anchorEl: this.state.anchorEl,
                                        }}
                                        InputProps={{
                                            onFocus: e => {
                                                this.handleClick(e);
                                            },
                                        }}
                                        open={Boolean(this.state.anchorEl)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileDateCard);
