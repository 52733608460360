export const GET_MOVERS = 'GET_MOVERS';
export const GET_MOVERS_SUCCESS = 'GET_MOVERS_SUCCESS';
export const GET_MOVERS_ERROR = 'GET_MOVERS_ERROR';

//Get swlected movers
export const GET_SELECTED_MOVERS = 'GET_SELECTED_MOVERS';
export const GET_SELECTED_MOVERS_SUCCESS = 'GET_SELECTED_MOVERS_SUCCESS';
export const GET_SELECTED_MOVERS_ERROR = 'GET_SELECTED_MOVERS_ERROR';

// reverse storage
export const GET_REVERSE_STORAGE = 'GET_REVERSE_STORAGE';
export const GET_REVERSE_STORAGE_SUCCESS = 'GET_REVERSE_STORAGE_SUCCESS';
export const GET_REVERSE_STORAGE_ERROR = 'GET_REVERSE_STORAGE_ERROR';

//Post Move
export const POST_MOVERS = 'POST_MOVERS';
export const POST_MOVERS_SUCCESS = 'POST_MOVERS_SUCCESS';
export const POST_MOVERS_ERROR = 'POST_MOVERS_ERROR';
export const GET_RESERVE_STORAGE_BY_FACILITY_ID = 'GET_RESERVE_STORAGE_BY_FACILITY_ID';
export const GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS = 'GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS';
export const GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR = 'GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR';

// post Reserve Storage
export const POST_RESERVE_STORAGE = 'POST_RESERVE_STORAGE';
export const POST_RESERVE_STORAGE_SUCCESS = 'POST_RESERVE_STORAGE_SUCCESS';
export const POST_RESERVE_STORAGE_ERROR = 'POST_RESERVE_STORAGE_ERROR';

// patch move api
export const UPDATE_MOVE_SOURCE_HOME_INFORMATION = 'UPDATE_MOVE_SOURCE_HOME_INFORMATION';
export const UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS = 'UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS';
export const UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR = 'UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR';

export const UPDATE_DISCLAIMER = 'UPDATE_DISCLAIMER';
export const UPDATE_DISCLAIMER_SUCCESS = 'UPDATE_DISCLAIMER_SUCCESS';
export const UPDATE_DISCLAIMER_ERROR = 'UPDATE_DISCLAIMER_ERROR';

export const UPDATE_MOVE_DESTINATION_HOME_INFORMATION = 'UPDATE_MOVE_DESTINATION_HOME_INFORMATION';
export const UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS = 'UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS';
export const UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR = 'UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR';

//Get DIY
export const GET_DIY = 'GET_DIY';
export const GET_DIY_SUCCESS = 'GET_DIY_SUCCESS';
export const GET_DIY_ERROR = 'GET_DIY_ERROR';
