import React from 'react';
import TextField from '@material-ui/core/TextField';
import '../../styles/Login.scss';
import { connect } from 'react-redux';
import * as Action from '../../store/login/actions';
import { bindActionCreators } from 'redux';
import Button from '../../components/atoms/Button';
import * as Action1 from '../../store/common/actions';
import mobileLogin from '../../Assets/images/login/loginbackMobile.svg';
import AnalyticsService from '../analytics-service';

type initialProps = {
    history: any;
    loginAction: any;
    loginState: any;
    commonAction?: any;
    commonState?: any;
    commonReducer?: any;
};

type initialState = {
    password: string;
    confirmPassword: string;
    passwordErr: string;
    confirmPasswordErr: string;
    hash_code: string;
    site_name: string;
    isUserWithHascodeExists: boolean;
    formStarted: boolean;
};

const domain = window.location.hostname;
let name: any = domain.split('.');

class SetPassword extends React.Component<initialProps, initialState> {
    state: initialState = {
        password: '',
        confirmPassword: '',
        passwordErr: '',
        confirmPasswordErr: '',
        hash_code: '',
        site_name: '',
        isUserWithHascodeExists: false,
        formStarted: false
    };

    async componentDidMount() {
        this.props.commonAction.realtor({ slug: name[0] });

        AnalyticsService.pageViewed({
            page: 'Set Password Page',
            category_name: 'Set Password Page Viewed'
        });

        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        if (slug && slug.slug) {
            await this.setState({ site_name: slug?.slug });
        }
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.pathname &&
            this.props.history.location.pathname.split('setpassword/')[1]
        ) {
            await this.setState({
                hash_code: this.props.history.location.pathname.split('setpassword/')[1],
            });
        }
        const token = localStorage.getItem('token');
        if (token) {
            this.props.history.push('/dashboard');
        }
        const payload = {
            hash_code: this.state.hash_code,
            site_name: window.location.hostname.split('.')[0],
        };
        this.props.loginAction.setPassword(payload);
    }
    componentDidUpdate() {}

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.loginState &&
            nextProps.loginState.setPassword &&
            nextProps.loginState.setPassword.hash &&
            !currentState.isUserWithHascodeExists
        ) {
            currentState.isUserWithHascodeExists = true;
            // console.log(nextProps.loginState)
        }

        if (nextProps && nextProps.loginState && nextProps.loginState.passwordSetSuccessful) {
            nextProps.loginState.passwordSetSuccessful = false;
            currentState.password = '';
            currentState.confirmPassword = '';

            nextProps.history.push('/login');
        }

        return currentState;
    }

    handlePasswordChange = (e: any) => {
        this.setState({
            password: e.target.value,
        });

        this.handleInputAnalytics('Set Password');
    };

    handleConfirmPasswordChange = (e: any) => {
        this.setState({
            confirmPassword: e.target.value,
        });

        this.handleInputAnalytics('Confirm Password');
    };

    backToLoginHandler = () => {
        this.handleButtonAnalytics('Back to Login');
        this.props.history.push('/login');
    };

    validateFields = () => {
        let validate = true;

        if (this.state.password.length < 8 || this.state.password.length > 16) {
            validate = false;
            this.setState({
                passwordErr: 'Password length should be between 8 to 16 characters',
            });
        } else {
            this.setState({
                passwordErr: '',
            });
        }

        if (this.state.confirmPassword != '' && this.state.confirmPassword != this.state.password) {
            this.setState({
                confirmPasswordErr: 'Password does not match',
            });
        } else {
            this.setState({
                confirmPasswordErr: '',
            });
        }

        return validate;
    };

    handleSetPassword = () => {
        if (this.validateFields() && this.state.isUserWithHascodeExists) {
            AnalyticsService.formCompleted({
                page: 'Set Password Page',
                category_name: 'Submitted Set Form',
                button_copy:  'Set Password',
                form_name: 'Set Password form',
                form_button: true
            });

            let payload = {
                password: this.state.password,
                hash_code: this.state.hash_code,
            };
            this.props.loginAction.setPasswordData(payload);
        }

        this.handleButtonAnalytics('Set Password');
    };

    handleInputAnalytics = (fieldLabel: string) => {
        if (!this.state.formStarted) {
            AnalyticsService.formFieldStarted({
                page: 'Set Password Page',
                category_name: 'Filling Out Set Password Form',
                form_field_name: fieldLabel
            });
        }
        this.setState({
            formStarted: true
        })
    }

    handleButtonAnalytics = (copy: string) => {
        AnalyticsService.buttonClicked({
            page: 'Set Password Page',
            category_name: 'Button Clicked',
            button_copy: copy,
            form_button: true
        });
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        const { password, passwordErr, confirmPassword, confirmPasswordErr } = this.state;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={isDouglasElliman ? 'container containerDouglas' : 'container'}>
                <div className={isDouglasElliman ? 'login-getStartedDiv login-getStartedDivD' : 'login-getStartedDiv'}>
                    <div className="loginContainer">
                        <div className="loginContent">
                            <div>
                                <div className="loginWelcomeText">Welcome Back to {slug && slug.contact_name}</div>
                                <div
                                    className="loginLine"
                                    style={{
                                        backgroundColor: slug && slug.primary_color ? slug.primary_color : '#273e59',
                                    }}
                                ></div>
                                <div className="loginSignInText">Set Password</div>
                            </div>

                            <div className="login-form-main-div">
                                <div>
                                    <div className="Login-inputDiv">
                                        <TextField
                                            className="loginInput"
                                            error={Boolean(passwordErr)}
                                            type="password"
                                            label="Set Password"
                                            id="outlined-start-adornment"
                                            variant="filled"
                                            onChange={e => this.handlePasswordChange(e)}
                                            // onKeyPress={this.handleKeyPress}
                                            value={password}
                                        />
                                        {passwordErr !== '' ? (
                                            <div className="errorLogin">
                                                <small>{passwordErr}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="Login-inputDiv">
                                        <TextField
                                            className="loginInput"
                                            error={Boolean(confirmPasswordErr)}
                                            type="password"
                                            label="Confirm Password"
                                            id="outlined-start-adornment"
                                            variant="filled"
                                            onChange={e => this.handleConfirmPasswordChange(e)}
                                            // onKeyPress={this.handleKeyPress}
                                            value={confirmPassword}
                                        />
                                        {confirmPasswordErr !== '' ? (
                                            <div className="errorLogin">
                                                <small>{confirmPasswordErr}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    <Button
                                        className="signInButton"
                                        onClick={this.handleSetPassword}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        disabled={this.state.password && this.state.confirmPassword ? false : true}
                                    >
                                        Set Password
                                    </Button>

                                    <Button
                                        className="signInButton"
                                        onClick={this.backToLoginHandler}
                                        boxShadow={`0 0 3px 0 ${
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273E59'
                                        }`}
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        backgroundColor="#fff"
                                    >
                                        Back to Login
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loginMobileImgBottom">
                    <img src={mobileLogin} alt="" width="100%" />
                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        loginAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(Action1, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    loginState: state.login,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
