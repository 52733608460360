import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import './LaCarteCard.scss';
import CheckIcon from '@material-ui/icons/Check';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';


type iProps = {
    className?: string;
    onClick ? : any;
    heading ? : any;
    subheading ? : any;
    checked ? : any;
    checkHandler ? : any
 
};

const LaCarteCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            // '&:hover': {
            //        border:'2px solid #0066F5'        
            // },
            cursor: 'pointer',
        },
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.root} ` : `laCarteCard-main-div ${classes.root}`}    
            onClick={props.checkHandler}
        >
            <div className="carte-check-div">
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
             style={{
                transform: "scale(1.5)",
                borderRadius:'50%'
            }}
        color="primary"
        checked={props.checked}
        // onChange={props.checkHandler}
        inputProps={{  }}
      />

            </div>
            <div className="carte-right-div">
                <div className="carte-heading">
                {props.heading}
                </div>
                <div className="carte-subheading-check">
                        <div className="carte-check">
                        <CheckIcon
                                style={{
                                    fontSize: '22px',
                                    color: '#00D56D',
                                    marginRight: '6px',
                                }}
                            />
                        </div>

                        <div className="carte-sub-heading2">
                            {props.subheading}
                        </div>
                    </div>
            </div>
      
        </div>
    );
};

export default LaCarteCard;
