import React from 'react';
import './NavigationTop.scss';

interface IProps {
    showRental: boolean;
    navigationTab: number;
    setNavigationTab: (x: number) => void;
}

const NavigationTop = (props: IProps) => {

    const { showRental, navigationTab, setNavigationTab } = props;
  
    return (
        <div className="NavigationContainer">
            <div
                onClick={() => {
                    setNavigationTab(0);
                }}
                className={navigationTab === 0 ? 'selectedContainer' : 'notSelectedContainer'}
            >
                Total Summary
            </div>
            <div
                onClick={() => {
                    setNavigationTab(1);
                }}
                className={navigationTab === 1 ? 'selectedContainer' : 'notSelectedContainer'}
            >
                Primary Residence
            </div>
           <div
                onClick={() => {
                    setNavigationTab(2);
                }}
                className={navigationTab === 2 ? 'selectedContainer' : 'notSelectedContainer'}
            >
               {showRental ? "Rental" : "Secondary Residence"}
            </div>
        </div>
    );
};

export default NavigationTop;
