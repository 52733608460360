import React, { Component } from 'react';
import ButtonNew from '../../components/atoms/Button';
import Brokerage from '../../Assets/images/MovingImages/brokerage';
import LongCheckboxCard from '../../components/atoms/LongCheckboxCard';
import RatingIcon from '../../Assets/images/MovingImages/rating.svg';
import RatingIconBlue from '../../Assets/images/MovingImages/ratingBlue.svg';
import BusinessIcon from '../../Assets/images/MovingImages/business_center.svg';
import callIcondetail from '../../Assets/images/Moving/callDEBlue.svg';
import callIconBlueCall from '../../Assets/images/Moving/bluephone.svg';
import callIcon from '../../Assets/images/MovingImages/call.svg';
import locationIcon from '../../Assets/images/MovingImages/location-icon.svg';
import insuredIcon from '../../Assets/images/Moving/insured.svg';
import licensedIcon from '../../Assets/images/Moving/shield.svg';
import AwardIcon from '../../Assets/images/award.svg';
import PODSSvg from '../../Assets/images/Moving/DIYmovingPodsBanner.png';
import PODSSvgBlue from '../../Assets/images/Moving/podsBlueBanner.svg';
import MovingPlanCard from '../../components/atoms/movingPlanCard';
import MovingPlanCardMobile from '../../components/atoms/movingPlanCardMobile';
import GetAQuoteCard from '../../components/atoms/GetAQuoteCard';
import addArrowIcon from '../../Assets/images/MovingImages/add-arrow-icon.svg';
import DetailCard from '../../components/atoms/DetailCard';
import PODS from '../../Assets/images/Moving/PODS.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/move/actions';
import * as CommonAction from '../../store/common/actions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './Moving.scss';
import * as DashboardAction from '../../store/dashboard/actions';
import downArrow from '../../Assets/images/Moving/down-arrow.png';
import Modal from '../../components/atoms/Modal';
import CheckIcon from '@material-ui/icons/Check';
import { buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    onSelectQuote?: any;
    selectedItems?: any;
    moversAction?: any;
    moversState?: any;
    isDataArrived?: boolean;
    checkedItemsAlmost?: any;
    fromAlmost?: string;
    history?: any;
    location?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    // showPods:any
    quote: boolean;
    isDataArrived: boolean;
    showModal: any;
    checkBoxes: string[];
    checkedItems: string[];
    open?: any;
    showPods?: boolean;
    buffer?: boolean;
    AnchorEl?: any;
    sort?: string;
    top?: any;
    bottom?: any;
    fromAlmost: any;
    isKnowMoreClicked?: boolean;
    isDouglasElliman: boolean;
    concierge_ref: any;
};

class MovingRecommendedPage extends Component<initialProps, initialState> {
    state: initialState = {
        //  showPods:false,
        quote: false,
        isDataArrived: true,
        showModal: false,
        checkBoxes: [],
        checkedItems: [],
        open: false,
        showPods: false,
        buffer: false,
        AnchorEl: null,
        sort: '',
        top: [],
        fromAlmost: '',
        bottom: [],
        isKnowMoreClicked: false,
        isDouglasElliman: false,
        concierge_ref: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        window.scrollTo(0, 0);
        this.setState({ showModal: true });
        this.props.moversAction.movers();
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.dashboardAction.checklist();
        setTimeout(() => {
            this.setState({ showModal: false });
        }, 4000);
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.moversState &&
            nextProps.moversState.movers &&
            nextProps.moversState.movers.length &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.top = nextProps.moversState.movers.slice(0, 3);
            currentState.bottom = nextProps.moversState.movers.slice(3);
        }

        return currentState;
    }

    handleQuote = async () => {
        await this.setState({
            quote: !this.state.quote,
        });

        this.props.history.push({
            pathname: '/dashboard/movers/quote',
            state: {
                quote: this.state.quote,
                checkedItems: this.state.checkedItems,
            },
        });

        updateChecklistTask(80, this.props.dashboardAction);

        window.scrollTo(0, 0);
        //  this.props.onSelectQuote(this.state.quote)
        //  this.props.selectedItems(this.state.checkedItems)
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Moving Service',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Service',
            'Continue',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    handleBookNow = () => {
        this.setState({
            showPods: true,
        });
    };

    handleCloseModal = () => {
        this.setState({ open: false });
    };

    handleClosePODSModal = () => {
        this.setState({ showPods: false });
    };

    handleMenu = (event: any) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ AnchorEl: null });
    };

    handleCheckBoxes = (name: string, item: any) => {
        let checkBoxesNew: string[] = this.state.checkBoxes;
        let checkedItemsNew: string[] = this.state.checkedItems;

        if (!this.state.checkBoxes.includes(name)) {
            if (checkBoxesNew.length <= 2) {
                checkBoxesNew.push(name);
                checkedItemsNew.push(item);
                this.setState({
                    checkBoxes: checkBoxesNew,
                    checkedItems: checkedItemsNew,
                });

                let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

                let brokerage: any;

                brokerage = brokerageCheck.display_name;
                let brokerage_key: any = brokerageCheck.brokerage_key;
                let agent_key: any = brokerageCheck.realtor_key;

                let agent_name: any = brokerageCheck.contact_name;
                let move_id: any = localStorage.getItem('moveKey');
                // let category_name :any= Movers,
                // let card:any=true,

                let windownew: any = window;

                let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                let cardValues1: any = [];
                this.state.checkedItems.map((item1: any) => {
                    cardValues1.push(item1.name);
                });

                analytics.track(
                    'Card Selection Clicked',
                    {
                        page: 'Movers Service',
                        brokerage_name: brokerage,
                        brokerage_key: brokerage_key,
                        agent_name: agent_name,
                        agent_key: agent_key,
                        card_title: cardValues1,
                        card_type: '3 top-rated movers',
                        card: 'true',
                        category_name: 'Movers Service',
                    },
                    { user_id: move_id },
                );
            } else {
                this.setState({ open: true });
            }
        } else {
            checkBoxesNew = this.state.checkBoxes.filter(item => item != name);
            checkedItemsNew = this.state.checkedItems.filter((item2: any) => item2.name != item.name);
            this.setState({
                checkBoxes: checkBoxesNew,
                checkedItems: checkedItemsNew,
            });
        }
    };

    handleCancel = () => {
        this.setState({
            showPods: false,
        });
    };

    handleDeleteItem = (name: string, item: any) => {
        let checkBoxesNext: string[] = this.state.checkBoxes;
        let checkedItemsNext: string[] = this.state.checkedItems;
        checkBoxesNext = this.state.checkBoxes.filter(item => item != name);
        checkedItemsNext = this.state.checkedItems.filter(item2 => item2 != item);
        this.setState({
            checkBoxes: checkBoxesNext,
            checkedItems: checkedItemsNext,
        });
    };

    clearClickedHandler = () => {
        this.setState({
            checkBoxes: [],
            checkedItems: [],
        });
    };

    setValue = (checkedItemsAlmost: any, testArray: any, fromAlmost: string) => {
        this.setState({
            checkedItems: checkedItemsAlmost,
            checkBoxes: testArray,
            buffer: true,
            fromAlmost: fromAlmost,
        });
    };

    handleLTH = () => {
        this.setState({ sort: 'ascending' });
        this.handleClose();
    };

    handleHTL = () => {
        this.setState({ sort: 'descending' });
        this.handleClose();
    };

    onClickKnowMore = () => {
        this.setState({ isKnowMoreClicked: true });
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Moving Services DIY',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            '',
            'Learn More',
            false,
            '',
            false,
            '',
            '',
            true,
            'Realtor or Brokerage Recommended',
            false,
            '',
            '',
            move_id,
        );
    };

    onCloseModalKnowMore = () => {
        this.setState({ isKnowMoreClicked: false });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let details =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move[0] &&
            this.props.commonState.move[0].assigned_concierge;
        let concierge =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move[0] &&
            this.props.commonState.move[0].concierge_reference_field;
        let moversArray: any[] = [];
        let top: any[] = [];
        let bottom: any[] = [];
        // let indexArray:number[]=[]
        if (this.props && this.props.moversState && this.props.moversState.movers) {
            moversArray = this.props.moversState.movers;
        }

        top = moversArray.slice(0, 3);
        bottom = moversArray.slice(3, moversArray.length);

        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.checkedItemsAlmost &&
            this.props.location.state.checkedItemsAlmost.length &&
            this.state.buffer === false
        ) {
            let testArray: string[] = [];
            this.props.location.state.checkedItemsAlmost.map((item: any) => {
                testArray.push(item.name);
            });

            this.setValue(this.props.location.state.checkedItemsAlmost, testArray, 'fromAlmost');
        }

        let indexArray: any[] = [];

        if (this.state.fromAlmost == '') {
            this.state.checkedItems.map((item: any) => {
                if (bottom.includes(item)) {
                    top.map((test: any, index: number) => {
                        if (!this.state.checkedItems.includes(test)) {
                            indexArray.push(index);
                        }
                    });
                    let x: any = top[indexArray[indexArray.length - 1]];
                    top = top.map(function(testing) {
                        return testing == x ? item : testing;
                    });
                    bottom = bottom.filter(item2 => item2 != item);
                    bottom = [x].concat(bottom);
                }
            });
        }

        if (this.state.fromAlmost == 'fromAlmost') {
            bottom = bottom.map(item2 => {
                return JSON.stringify(item2);
            });
            let checkedItems = this.state.checkedItems.map(item3 => {
                return JSON.stringify(item3);
            });

            top = top.map(item4 => {
                return JSON.stringify(item4);
            });

            checkedItems.map((item: any) => {
                if (bottom.includes(item)) {
                    top.map((test: any, index: number) => {
                        if (!checkedItems.includes(test)) {
                            indexArray.push(index);
                        }
                    });
                    let x: any = top[indexArray[indexArray.length - 1]];
                    top = top.map(function(testing) {
                        return testing == x ? item : testing;
                    });
                    bottom = bottom.filter(item2 => item2 != item);
                    bottom = [x].concat(bottom);
                }
            });

            top = top.map(item5 => {
                return JSON.parse(item5);
            });

            bottom = bottom.map(item6 => {
                return JSON.parse(item6);
            });
        }

        if (this.state.sort == 'ascending') {
            bottom = bottom
                .sort((a, b) => (a.overall_rating > b.overall_rating ? 1 : -1))
                .map(item => {
                    return item;
                });
        }

        if (this.state.sort == 'descending') {
            bottom = bottom
                .sort((a, b) => (a.overall_rating < b.overall_rating ? 1 : -1))
                .map(item => {
                    return item;
                });
        }
        const { isDouglasElliman } = this.state;

        const breadList = [
            {
                name: 'Moving Services',
                url: ''
            }
        ]
        
        return (
            <div className={isDouglasElliman ? 'moving-main-page moving-main-pageDouglas' : 'moving-main-page'}>
                {this.state.showModal ? (
                    <div className="loaderInternetRecomendation">
                        <div className="modalHead">Just a moment we are finding a top picks for you </div>
                        <div className="modalSubHead">
                            We are analyzing your answer and what people similar to you bought
                        </div>
                        <div className="main-divv">
                            <div className="modalData">
                                <CheckCircleIcon />
                                <div className="textModal">Searching through our best movers for deals that are…</div>
                            </div>
                            <div className="modalData1">
                                <CheckCircleIcon />
                                <div className="textModal1">
                                    Available in{' '}
                                    {this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].source_full_address}
                                </div>
                            </div>
                            <div className="modalData2">
                                <CheckCircleIcon />
                                <div className="textModal2">A good day for moving your home</div>
                            </div>
                            <div className="modalData3">
                                <CheckCircleIcon />
                                <div className="textModal3">Applying best deals for PODS</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="main-head-2">
                            <h2 className="heading">Moving Services</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className="tab-div-moving">
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/movers' ||
                                        this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                        this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                        this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                            >
                                Moving
                            </div>
                            {this.props &&
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.state &&
                            this.props.history.location.state.diy ? (
                                <div
                                    className="tab-text"
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: '/dashboard/movers/diyrecommended',
                                            state: this.props.history.location.state.diy,
                                        });
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    DIY
                                </div>
                            ) : null}
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/autoease' ||
                                        this.props.history.location.pathname === `/dashboard/autoease/`)
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/autoease');
                                    window.scrollTo(0, 0);
                                }}
                            >
                                Auto Shipping
                            </div>
                            <div
                                className={
                                    this.props &&
                                    this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.pathname &&
                                    (this.props.history.location.pathname === '/dashboard/storage' ||
                                        this.props.history.location.pathname === `/dashboard/storage/`)
                                        ? 'tab-active'
                                        : 'tab-text'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/storage');
                                }}
                            >
                                Reserve a Storage Unit
                            </div>
                        </div>

                        <div>
                            <Dialog
                                className="dialog-box"
                                open={this.state.open}
                                onClose={this.handleCloseModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
                                    {'Oops. Only Three Movers are Allowed for Selection'}
                                </DialogTitle>

                                <DialogActions>
                                    <ButtonNew onClick={this.handleCloseModal} className="Modal-button">
                                        Ok
                                    </ButtonNew>
                                </DialogActions>
                            </Dialog>
                        </div>

                        {/* {this.state.quote === false? */}
                        <div className="moving-main-div">
                            <div className="movers-detail-div">
                                <div
                                    className="black-card-moving"
                                    style={{
                                        background: slug && slug.color_shade1 ? `${slug.color_shade1}` : '#08192f',
                                    }}
                                >
                                    <div className="main-card-moving">
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                                Realtor or Brokerage Recommended
                                            </div>
                                            <div style={{ fontSize: '16px', margin: '10px 0 20px 0' }}>
                                                Answer Few Questions and avail for eligible discounts
                                            </div>
                                            <div>
                                                <ButtonNew
                                                    backgroundColor="#FFFFFF"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? `${slug.primary_color}`
                                                            : '#273E59'
                                                    }
                                                    fontSize="16px"
                                                    width="110px"
                                                    fontWeight="bold"
                                                    height="40px"
                                                    margin="2px 2px 10px 2px"
                                                    onClick={this.onClickKnowMore}
                                                >
                                                    Learn More
                                                </ButtonNew>
                                            </div>
                                        </div>
                                        {/* <img src={Brokerage} className="brokerageImage" /> */}
                                        {!isDouglasElliman && <Brokerage className="brokerageImage" />}
                                    </div>
                                    <div className="main-card-moving-mobile">
                                        <div style={{ textAlign: 'left' }}>
                                            <div className="mobile-card-heading">Realtor or Brokerage Recommended</div>
                                            <div className="mobile-card-para">
                                                Answer Few Questions and avail for eligible discounts
                                            </div>
                                        </div>
                                        <div className="movingmobile">
                                            <div>
                                                <ButtonNew
                                                    backgroundColor="#FFFFFF"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? `${slug.primary_color}`
                                                            : '#273E59'
                                                    }
                                                    fontSize="16px"
                                                    width="110px"
                                                    fontWeight="bold"
                                                    height="40px"
                                                    margin="2px 2px 10px 2px"
                                                    onClick={this.onClickKnowMore}
                                                >
                                                    Learn More
                                                </ButtonNew>
                                            </div>

                                            {!isDouglasElliman && <Brokerage className="brokerageImage" />}
                                        </div>
                                    </div>
                                </div>
                                <div className="top-movers">
                                    {top.length
                                        ? `We’ve matched you with ${top.length} top-rated movers to get free estimates`
                                        : ' '}
                                </div>
                                <div className="longCardFlexDiv">
                                    {moversArray.length
                                        ? top.map((item: any, i: number) => {
                                              return (
                                                  <div key={i} className="longCardDiv">
                                                      <LongCheckboxCard
                                                          bestForImage={AwardIcon}
                                                          bestForTitle="Best For Complex moves"
                                                          className="longCard"
                                                          cardImage={item.van_line_logo}
                                                          checked={this.state.checkBoxes.includes(item.name)}
                                                          handleChange={() => {
                                                              this.handleCheckBoxes(item.name, item);
                                                          }}
                                                          label={item.name}
                                                          cardTitle={item.name}
                                                          rate={item.overall_rating}
                                                          ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                                                          verfiedReviews={
                                                              item.google_num_reviews + item.yelp_num_reviews != 0
                                                                  ? `${item.google_num_reviews +
                                                                        item.yelp_num_reviews} Verified Reviews `
                                                                  : null
                                                          }
                                                          phoneIcon={callIcon}
                                                          phoneNumber={item.phone_no}
                                                          locationIcon={locationIcon}
                                                          locationtext={item.address}
                                                          yearsIcon={BusinessIcon}
                                                          yearText={item.year_in_business}
                                                          //   licensedText={item.license_number ? 'Licensed' : 'UnLicensed'}
                                                          licensedText="Licensed"
                                                          insuredText={item.insurance_amount ? 'Insured' : 'UnInsured'}
                                                          insuredIcon={insuredIcon}
                                                          licensedIcon={licensedIcon}
                                                          recommended={
                                                              !(
                                                                  item.preferred_text == 'MOVEEASY verified' ||
                                                                  item.preferred_text == 'MOVEEASY Partner'
                                                              )
                                                                  ? true
                                                                  : false
                                                          }

                                                          //   recommended={index==0 ? true :false}
                                                      />
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                                <div className="mobile-button-div">
                                    {this.state.checkedItems.length > 0 ? (
                                        <ButtonNew
                                            width="100%"
                                            fontSize="16px"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            onClick={this.handleQuote}
                                            margin="40px 0 0 0"
                                        >
                                            Continue
                                        </ButtonNew>
                                    ) : (
                                        <ButtonNew
                                            width="100%"
                                            fontSize="16px"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            disabled
                                        >
                                            Continue
                                        </ButtonNew>
                                    )}
                                </div>
                                <div className="other-suitable-mover">
                                    <div className="other-suitable-text">
                                        Here are some other top rated movers ({bottom.length})
                                    </div>
                                    <div
                                        className="avg-customer-review"
                                        onClick={this.handleMenu}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="average">
                                            <div> Sort by: </div>
                                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                                Avg. Customer Review
                                            </div>
                                            <div className="down-arrow">
                                                <img src={downArrow} height="15px" width="15px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.AnchorEl}
                                        keepMounted
                                        open={Boolean(this.state.AnchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.handleHTL}>High to Low</MenuItem>
                                        <MenuItem onClick={this.handleLTH}>Low to High</MenuItem>
                                    </Menu>
                                </div>

                                {this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.state &&
                                this.props.history.location.state.pods ? (
                                    <div className="pods-card">
                                        {isDouglasElliman ? (
                                            <img
                                                src={PODSSvg}
                                                onClick={() => this.handleBookNow()}
                                                className="pods-image-moving"
                                            />
                                        ) : (
                                            <img
                                                src={PODSSvgBlue}
                                                onClick={() => this.handleBookNow()}
                                                className="pods-image-moving"
                                            />
                                        )}
                                    </div>
                                ) : null}
                                {this.state.showPods ? (
                                    <div>
                                        <Dialog
                                            open={this.state.showPods}
                                            onClose={this.handleClosePODSModal}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }}>
                                                <img src={PODS} width="200px" height="70px" alt="" />
                                            </div>
                                            <DialogTitle id="alert-dialog-title">
                                                {'You will be rerouted to PODS website to complete your order'}
                                            </DialogTitle>

                                            <DialogActions>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: 'auto',
                                                        marginLeft: 'auto',
                                                    }}
                                                >
                                                    <ButtonNew
                                                        onClick={this.handleClosePODSModal}
                                                        // color="#ffffff"
                                                        width="300px"
                                                    >
                                                        <a
                                                            href="https://www.pods.com/partners/moveeasy"
                                                            target="_blank"
                                                            style={{ color: '#ffffff' }}
                                                        >
                                                            Continue to PODS Website
                                                        </a>
                                                    </ButtonNew>
                                                    <ButtonNew
                                                        onClick={this.handleClosePODSModal}
                                                        width="300px"
                                                        backgroundColor="#FFFFFF"
                                                        boxShadow={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : slug && slug.primary_color
                                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                                : '0 0 3px 0 #273E59'
                                                        }
                                                        color={
                                                            isDouglasElliman
                                                                ? '#100B28'
                                                                : slug && slug.primary_color
                                                                ? `${slug.primary_color}`
                                                                : '#273E59'
                                                        }
                                                        borderRadius="5px"
                                                        marginTop="20px"
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                    >
                                                        Cancel
                                                    </ButtonNew>
                                                </div>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                ) : null}

                                {moversArray.length
                                    ? bottom.map((item: any, i: number) => {
                                          return (
                                              <React.Fragment key={i}>
                                                  <div className="bottom-cards-div">
                                                      <MovingPlanCard
                                                          bestForImage={AwardIcon}
                                                          bestForTitle="Best For Complex moves"
                                                          width="100%"
                                                          label={item.name}
                                                          cardImage={item.van_line_logo}
                                                          imgWidth="107px"
                                                          cardTitle={item.name}
                                                          rate={item.overall_rating}
                                                          ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                                                          verifiedReview={
                                                              item.google_num_reviews + item.yelp_num_reviews != 0
                                                                  ? `${item.google_num_reviews +
                                                                        item.yelp_num_reviews} Verified Reviews `
                                                                  : null
                                                          }
                                                          phoneIcon={callIcon}
                                                          phoneNumber={item.phone_no}
                                                          locationIcon={locationIcon}
                                                          locationtext={item.address}
                                                          yearsIcon={BusinessIcon}
                                                          yearText={item.year_in_business}
                                                          licensedIcon={licensedIcon}
                                                          //   licensedText={item.license_number ? 'Licensed' : 'UnLicensed'}
                                                          licensedText="Licensed"
                                                          insuredIcon={insuredIcon}
                                                          insuredText={item.insurance_amount ? 'Insured' : 'UnInsured'}
                                                          checked={this.state.checkBoxes.includes(item.name)}
                                                          handleChange={() => {
                                                              this.handleCheckBoxes(item.name, item);
                                                          }}
                                                      />
                                                  </div>
                                                  <div className="bottom-cards-div-mobile">
                                                      <MovingPlanCardMobile
                                                          width="100%"
                                                          bestForTitle="Best For Complex moves"
                                                          bestForImage={AwardIcon}
                                                          label={item.name}
                                                          cardImage={item.van_line_logo}
                                                          imgWidth="107px"
                                                          cardTitle={item.name}
                                                          rate={item.overall_rating}
                                                          ratingIcon={isDouglasElliman ? RatingIcon : RatingIconBlue}
                                                          verifiedReview={
                                                              item.google_num_reviews + item.yelp_num_reviews != 0
                                                                  ? `${item.google_num_reviews +
                                                                        item.yelp_num_reviews} Verified Reviews `
                                                                  : null
                                                          }
                                                          phoneIcon={callIcon}
                                                          phoneNumber={item.phone_no}
                                                          locationIcon={locationIcon}
                                                          locationtext={item.address}
                                                          yearsIcon={BusinessIcon}
                                                          yearText={item.year_in_business}
                                                          licensedIcon={licensedIcon}
                                                          licensedText={item.license_number ? 'Licensed' : 'UnLicensed'}
                                                          insuredIcon={insuredIcon}
                                                          insuredText={item.insurance_amount ? 'Insured' : 'UnInsured'}
                                                          checked={this.state.checkBoxes.includes(item.name)}
                                                          handleChange={() => {
                                                              this.handleCheckBoxes(item.name, item);
                                                          }}
                                                      />
                                                  </div>
                                              </React.Fragment>
                                          );
                                      })
                                    : null}
                            </div>

                            <div className={this.state.checkedItems.length < 3 ? 'left-div' : 'left-div-2'}>
                                <div style={{ marginLeft: '10px' }}>
                                    <GetAQuoteCard
                                        cardImage={addArrowIcon}
                                        width="98%"
                                        text1="Select multiple movers"
                                        text2="get a quote"
                                        selectedMovers={this.state.checkedItems.length}
                                        totalMover={3}
                                        selectedMover={true}
                                        clearClicked={() => {
                                            this.clearClickedHandler();
                                        }}
                                        features={this.state.checkedItems.map((item: any) => {
                                            return {
                                                name: item.name,
                                                icon: item.van_line_logo,
                                                locationIcon: locationIcon,
                                                locationText: item.address,
                                                deleteClicked: () => {
                                                    this.handleDeleteItem(item.name, item);
                                                },
                                            };
                                        })}
                                        onClickedHandle={() => this.handleQuote()}
                                    />
                                </div>
                                <div style={{ marginLeft: '10px', marginTop: '12px' }}>
                                    <DetailCard
                                        avatarImage={details && details.headshot_logo}
                                        name={details && details.full_name}
                                        subtext={`Dedicated ${concierge}`}
                                        phoneNumber={details && details.phone}
                                        phoneIcon={isDouglasElliman ? callIcondetail : callIconBlueCall}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.isKnowMoreClicked ? (
                    <Modal
                        isShowModal={this.state.isKnowMoreClicked}
                        onCloseModal={this.onCloseModalKnowMore}
                        showClose={true}
                        className="movingModal"
                    >
                        <div className="ModalTitle">How do we choose our vendors?</div>
                        <div className="ModalSubtitle">
                            We match the best vendors to assist you with your moving needs:
                        </div>
                        <div className="ModalSubtitle">Our Requirements</div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Have to have been in business for at least{' '}
                                <span className="requirementsTextBold">5 years</span>
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Should be <span className="requirementsTextBold">licensed</span> and{' '}
                                <span className="requirementsTextBold">insured</span>
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                <span className="requirementsTextBold">A</span> or{' '}
                                <span className="requirementsTextBold">A+ BBB</span> rated
                            </div>
                        </div>
                        <div className="ModalSubtitle">Other things we look at</div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Any <span className="requirementsTextBold">affiliations</span> or{' '}
                                <span className="requirementsTextBold">badges</span> like ProMover or Angie’s Super
                                Service
                            </div>
                        </div>
                        <div className="requirementsDiv">
                            <CheckIcon className="tickIcon" />
                            <div className="requirementsText">
                                Their unfiltered <span className="requirementsTextBold">Google</span> &{' '}
                                <span className="requirementsTextBold">Yelp ratings</span>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    commonState: state.common,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(MovingRecommendedPage);
