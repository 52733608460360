export const POST_NEXTMOVE = 'POST_NEXTMOVE';
export const POST_NEXTMOVE_SUCCESS = 'POST_NEXTMOVE_SUCCESS';
export const POST_NEXTMOVE_ERROR = 'POST_NEXTMOVE_ERROR';

export const SEND_REALTOR_NOTIFICATION = 'SEND_REALTOR_NOTIFICATION';
export const SEND_REALTOR_NOTIFICATION_SUCCESS = 'SEND_REALTOR_NOTIFICATION_SUCCESS';
export const SEND_REALTOR_NOTIFICATION_ERROR = 'SEND_REALTOR_NOTIFICATION_ERROR';

export const GET_NEXTMOVE = 'GET_NEXTMOVE';
export const GET_NEXTMOVE_SUCCESS = 'GET_NEXTMOVE_SUCCESS';
export const GET_NEXTMOVE_ERROR = 'GET_NEXTMOVE_ERROR';

export const UPDATE_NEXTMOVE = 'UPDATE_NEXTMOVE';
export const UPDATE_NEXTMOVE_SUCCESS = 'UPDATE_NEXTMOVE_SUCCESS';
export const UPDATE_NEXTMOVE_ERROR = 'UPDATE_NEXTMOVE_ERROR';
