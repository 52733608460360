import React, { Component } from 'react';
import './documentPreview.scss';
// import DownloadIcon from '@material-ui/icons/Download';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Modal from '../../components/atoms/Modal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '../../components/atoms/Button';

type initialProps = {
    imageName: string;
    deleteHandle: any;
    editHandle: any;
    goBack: any;
    imageLink: any;
};

type initialState = {
    openModal: boolean;
};
export class DocumentPreviewNav extends Component<initialProps, initialState> {
    state: initialState = {
        openModal: false,
    };

    handleModal = () => {
        if (window.screen.width > 600) {
            this.setState({ openModal: false });
        }
    };
    handleDownload = () => {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Download',
                    category_name: 'Documents',
                    card: false,
                    card_title: '',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    // download = (e: any) => {
    //     console.log(e.target.href);
    //     fetch(e.target.href, {
    //         method: 'GET',
    //         headers: {},
    //     })
    //         .then(response => {
    //             response.arrayBuffer().then(function(buffer) {
    //                 const url = window.URL.createObjectURL(new Blob([buffer]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.setAttribute('download', '');
    //                 document.body.appendChild(link);
    //                 link.click();
    //             });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // };

    render() {
        window.onresize = this.handleModal;
        return (
            <div>
                <div className="navbar-main">
                    <div className="navbar-left-side">
                        <span>
                            <ArrowBackIosIcon onClick={() => this.props.goBack()} />
                        </span>
                        <span>{this.props.imageName}</span>
                    </div>
                    <div className="navbar-right-side">
                        <div className="navbar-right-head">
                            <span></span>
                            <span onClick={this.handleDownload}>
                                <a
                                    href={this.props.imageLink}
                                    target="_blank"
                                    // onClick={e => this.download(e)}
                                >
                                    Download
                                </a>
                            </span>
                        </div>
                        <div className="navbar-right-head" onClick={this.props.editHandle}>
                            <span>
                                <EditIcon />
                            </span>
                            <span>Edit</span>
                        </div>
                        <div className="navbar-right-head" onClick={this.props.deleteHandle}>
                            <span className="deleteIcon">
                                <DeleteIcon />
                            </span>
                            <span className="deleteIcon">Delete</span>
                        </div>
                        <MoreHorizIcon className="menuIcon" onClick={() => this.setState({ openModal: true })} />
                    </div>
                </div>

                {this.state.openModal ? (
                    <Modal
                        isShowModal={this.state.openModal}
                        onCloseModal={() => {
                            this.setState({ openModal: false });
                        }}
                        showClose={false}
                        className="modalMenuPreview"
                    >
                        <div className="modaltitleFlexDiv">
                            <EditOutlinedIcon className="modalIcon" />
                            Download
                        </div>
                        <div className="modaltitleFlexDiv">
                            <EditOutlinedIcon className="modalIcon" />
                            Edit
                        </div>
                        <div className="modaltitleFlexDiv" onClick={this.props.deleteHandle}>
                            <DeleteOutlineIcon className="modalIcon" />
                            Delete
                        </div>

                        <Button className="modalButtonPreview" onClick={() => this.setState({ openModal: false })}>
                            Close
                        </Button>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
export default DocumentPreviewNav;
