import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '../../components/atoms/Button';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MortgageImage from '../../Assets/images/homeownerSavings/mortgage.svg';
import InsuranceImage from '../../Assets/images/homeownerSavings/insurance.svg';
import InternetImage from '../../Assets/images/homeownerSavings/internet.svg';
import SecurityImage from '../../Assets/images/homeownerSavings/security.svg';
import UtilityImage from '../../Assets/images/homeownerSavings/utilities.svg';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import SelectInput from '../../components/atoms/SelectInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckboxCard from '../../components/atoms/CheckboxCards';
import CallSvg from '../../Assets/images/ICImages/Phone';
import TvSvg from '../../Assets/images/ICImages/tv';
import InternetSvg from '../../Assets/images/ICImages/internet';
import './savings.scss';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as ServiceActions from '../../store/savings/actions';
import * as CommonAction from '../../store/common/actions';
import * as EnergyAction from '../../store/energy/actions';
import * as UtilityAction from '../../store/utility/actions';
import * as Action from '../../store/nextMove/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as HomeValuationActions from '../../store/homeValuation/actions';
import SavingsConfirmation from './SavingsConfirmation';
import moment from 'moment';
import { DropDownValueObject, generateDropdownValueObject, generateYearList } from '../../utils/helper';
import { errorMessages } from '../../utils/constants';
var regexPattern = /(^100(\.0{1,4})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,4})?$)/i;
type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
    zip_code?: any;
    serviceAction?: any;
    serviceState?: any;
    energyAction?: any;
    energyState?: any;
    UtilityAction?: any;
    UtilityState?: any;
    nextMoveState?: any;
    nextMoveAction: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    loanDetails?: any;
    homeValuationActions?: any;
};
type initialState = {
    activeStep: number;
    step1: {
        loanBalance: string;
        loanTerm: any;
        loanPercentage: string;
    };
    step2: {
        diductibleAmount: string;
        insuranceProvider: any;
        payingAmount: string;
        insuranceProviderInput: string;
    };
    step3: {
        internetProvider: any;
        internetPayingAmount: string;
        internet: boolean;
        tv: boolean;
        phone: boolean;
        internetProviderInput: string;
    };
    step4: {
        securityProvider: any;
        securityPayingAmount: string;
        securityProviderInput: string;
    };
    step5: {
        electricityProvider: any;
        electricityPayingAmount: string;
        gasProvider: any;
        gasPayingAmount: string;
        gasProviderInput: string;
        electricityProviderInput: string;
    };
    step1FormError:{
        loanBalance: boolean,
        loanTerm: boolean,
        loanPercentage: boolean,
    },
    loanTermOptions: any;
    insuranceProviderOptions: any;
    internetProviderOptions: any;
    securityProviderOptions: any;
    electricityProviderOptions: any;
    gasProviderOptions: any;
    moveId: any;
    isMoveDataArrived: boolean;
    serviceTypeElectricity: any;
    serviceTypeGas: any;
    isSubmitClicked: boolean;
    completed: { [k: number]: boolean };
    zip_code: string;
    motgageId: any;
    insuranceId: any;
    internetId: any;
    utilitesId: any;
    securityId: any;
    checked: boolean;
    electricId: any;
    deregulated: any;
    deregulateElectricity: any;
    deregulateGas: any;
    hash_code: string;
    customBrokerageName: string;
    customBrandName: string;
    startYearOptions: DropDownValueObject [];
    endYearOptions: DropDownValueObject [];
};

const currentYear: number = Number(moment().format('YYYY'));
class SavingsPage extends React.Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 0,
        step1FormError:{
            loanBalance: false,
            loanTerm: false,
            loanPercentage: false,
        },
        step1: {
            loanBalance: '',
            loanTerm: null,
            loanPercentage: '',
        },
        step2: {
            diductibleAmount: '',
            insuranceProvider: null,
            payingAmount: '',
            insuranceProviderInput: '',
        },
        step3: {
            internetProvider: null,
            internetPayingAmount: '',
            internet: false,
            tv: false,
            phone: false,
            internetProviderInput: '',
        },
        step4: {
            securityProvider: null,
            securityPayingAmount: '',
            securityProviderInput: '',
        },
        step5: {
            electricityProvider: null,
            electricityPayingAmount: '',
            gasProvider: null,
            gasPayingAmount: '',
            gasProviderInput: '',
            electricityProviderInput: '',
        },
        loanTermOptions: [
            { value: '30-years fixed', label: '30-years fixed' },
            { value: '20-years fixed', label: '20-years fixed' },
            { value: '15-years fixed', label: '15-years fixed' },
            { value: '10-years fixed', label: '10-years fixed' },
            { value: '10-years ARM', label: '10-years ARM' },
            { value: '7-years ARM', label: '7-years ARM' },
            { value: '5-years ARM', label: '5-years ARM' },
            { value: '3-years ARM', label: '3-years ARM' },
        ],
        insuranceProviderOptions: [{ value: 'Other', label: 'Other' }],
        internetProviderOptions: [{ value: 'Other', label: 'Other' }],
        securityProviderOptions: [{ value: 'Other', label: 'Other' }],
        electricityProviderOptions: [{ value: 'Other', label: 'Other' }],
        gasProviderOptions: [{ value: 'Other', label: 'Other' }],
        moveId: '',
        isMoveDataArrived: true,
        serviceTypeElectricity: '',
        serviceTypeGas: '',
        isSubmitClicked: false,
        completed: {},
        zip_code: '',
        motgageId: '',
        insuranceId: '',
        internetId: '',
        utilitesId: '',
        securityId: '',
        checked: false,
        electricId: '',
        deregulated: '',
        deregulateElectricity: '',
        deregulateGas: '',
        hash_code: '',
        customBrokerageName: '',
        customBrandName: '',
        startYearOptions: [],
        endYearOptions: []
    };

    componentDidMount = () => {
        this.props.homeValuationActions.homeValuationCardDetails();
        this.props.serviceAction.getServiceProvider();
        this.props.serviceAction.getDigitalServiceProvider();
        this.props.serviceAction.getInsuranceProvider();
        this.props.energyAction.getEnergyType();

        this.props.commonAction.moveGet();
        this.props.dashboardAction.getSubscriptionDetails();

        if (
            this.props?.commonState?.move?.length > 0 &&
            this.props.commonState.move[0]
        ) {
            let payload = {
                source_zip:
                    this.props.commonState.move[0].destination && this.props.commonState.move[0].destination.zip_code,
                // source_zip: '32720',
                dest_zip:
                    this.props.commonState.move[0].destination && this.props.commonState.move[0].destination.zip_code,
            };
            this.props.UtilityAction.getUtility(payload);
        }

        const startYearList: number[] = generateYearList(currentYear - 25, currentYear)
        const endYearList: number[] = generateYearList(currentYear, currentYear + 35)
        const startYearOptions = generateDropdownValueObject(startYearList)
        const endYearOptions = generateDropdownValueObject(endYearList)
        this.setState({startYearOptions, endYearOptions })

        const dashboardData = this.props?.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info?.deeds?.length ? this.props.homeOwnerDashboardState.homeValuationCardDetails.buyer_seller_info.deeds[0] : null;
        if (dashboardData) {
            this.setPrefilledData(dashboardData);
        }


    };

     setPrefilledData = (dashboardData: any) => {
        const { loan_amount , original_contract_date, loan_interest_rate } = dashboardData;

        const startFrom = moment(original_contract_date, 'DD-MM-YYYY');
        const startingMonth = startFrom.format('MMMM');
        const startingYear = startFrom.format('YYYY');

        const data = {
            loanBalance: loan_amount?.toString(),
            loanTerm: null,
            loanPercentage: loan_interest_rate?.toString(),
            loanStartMonth: { value: startingMonth, label: startingMonth },
            loanStartYear: { value: Number(startingYear), label: startingYear },
        }

        this.setState({ step1 : data})
    }

    componentDidUpdate = (prevProps: initialProps) => {

        if (this.props.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info !== prevProps.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info) {
            const dashboardData = this.props?.homeOwnerDashboardState?.homeValuationCardDetails?.buyer_seller_info?.deeds?.length ? this.props.homeOwnerDashboardState.homeValuationCardDetails.buyer_seller_info.deeds[0] : null;
            if (dashboardData) {
                this.setPrefilledData(dashboardData);
            }
        }

        if (
            prevProps.UtilityState.utility != this.props.UtilityState.utility &&
            this.props.UtilityState.utility.source &&
            this.props.UtilityState.utility.source[0]
        ) {
            let electricityProviderOptions: any = [];
            let gasProviderOptions: any = [];
            electricityProviderOptions.push({
                label:
                    this.props.UtilityState.utility.source[0].electricity_provider_name !== null &&
                        this.props.UtilityState.utility.source[0].electricity_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].electricity_provider_name
                        : 'Other',
                value:
                    this.props.UtilityState.utility.source[0].electricity_provider_name !== null &&
                        this.props.UtilityState.utility.source[0].electricity_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].electricity_provider_name
                        : 'Other',
            });
            gasProviderOptions.push({
                label:
                    this.props.UtilityState.utility.source[0].gas_provider_name != null &&
                        this.props.UtilityState.utility.source[0].gas_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].gas_provider_name
                        : 'Other',
                value:
                    this.props.UtilityState.utility.source[0].gas_provider_name != null &&
                        this.props.UtilityState.utility.source[0].gas_provider_name != 'Not Found'
                        ? this.props.UtilityState.utility.source[0].gas_provider_name
                        : 'Other',
            });
            this.setState({
                electricityProviderOptions: electricityProviderOptions,
                gasProviderOptions: gasProviderOptions,
            });
        }

        if (
            prevProps.serviceState.getServiceProvider !== this.props.serviceState.getServiceProvider &&
            this.props.serviceState.getServiceProvider &&
            this.props.serviceState.isGetServiceProvider
        ) {
            let serviceProvider: any = [];

            this.props.serviceState.getServiceProvider.map((item: any) => {
                serviceProvider.push({ label: item.name, value: item.name });
            });
            serviceProvider.push({ value: 'Other', label: 'Other' });
            this.setState({
                securityProviderOptions: serviceProvider,
            });
        }
        if (
            prevProps.serviceState.getDigitalServiceProvider !== this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.isGetDigitalServiceProvider
        ) {
            let digitalProvider: any = [];

            this.props.serviceState.getDigitalServiceProvider.map((item: any) => {
                digitalProvider.push({ label: item.name, value: item.name });
            });
            digitalProvider.push({ value: 'Other', label: 'Other' });

            this.setState({
                internetProviderOptions: digitalProvider,
            });
        }
        if (
            prevProps.serviceState.getInsuranceProvider !== this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.isGetInsuranceProvider
        ) {
            let insuranceProvider: any = [];

            this.props.serviceState.getInsuranceProvider.map((item: any) => {
                insuranceProvider.push({ label: item.name, value: item.name });
            });
            insuranceProvider.push({ value: 'Other', label: 'Other' });
            this.setState({
                insuranceProviderOptions: insuranceProvider,
            });
        }
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;

            currentState.zip_code =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.zip_code;
            currentState.hash_code = nextProps.commonState.move[0].hash_code;
            currentState.deregulated =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.is_deregulated;
            currentState.deregulateElectricity =
                nextProps.commonState.move[0].regulated_details &&
                    nextProps.commonState.move[0].regulated_details.energy_options_deregulated
                    ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated[0]
                        ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated[0]
                        : ''
                    : null;
            currentState.deregulateGas =
                nextProps.commonState.move[0].regulated_details &&
                    nextProps.commonState.move[0].regulated_details.energy_options_deregulated
                    ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated[1]
                        ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated[1]
                        : ''
                    : null;

            let payload = {
                source_zip: currentState.zip_code,
                // source_zip: '32720',
                dest_zip: currentState.zip_code,
            };
            let payload2 = {
                moveId: currentState.moveId,
            };

            nextProps.nextMoveAction.getNextMove(payload2);

            nextProps.UtilityAction.getUtility(payload);
        }
        if (nextProps.nextMoveState && nextProps.nextMoveState.getNextMove && nextProps.nextMoveState.isGetNextMove) {
            nextProps.nextMoveState.isGetNextMove = false;
            nextProps.nextMoveState.getNextMove.results &&
                nextProps.nextMoveState.getNextMove.results.map((item: any) => {
                    if (item.service == 'Mortgage') {
                        if (item.loan_year && item.loan_year != null && item.loan_year != '') {
                            let loanTerm: any = null;
                            currentState.motgageId = item.id;
                            loanTerm = { label: item.loan_year, value: item.loan_year };
                            currentState.step1.loanTerm = loanTerm;
                        }

                        currentState.step1.loanBalance = item.price;
                        currentState.step1.loanPercentage = item.loan_percentage;
                      
                        
                    } else if (item.service == 'Home Insurance') {
                        let options: any = currentState.insuranceProviderOptions;
                        currentState.insuranceId = item.id;

                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        currentState.step2.diductibleAmount = item.deductible;
                        currentState.step2.insuranceProvider = provider;
                        currentState.step2.insuranceProviderInput = item.provider;
                        currentState.step2.payingAmount = item.price;
                    } else if (item.service === 'Internet & Cable') {
                        currentState.internetId = item.id;
                        let options: any = currentState.internetProviderOptions;
                        if (item.services_brought.includes('internet')) {
                            currentState.step3.internet = true;
                        } else if (item.services_brought.includes('tv')) {
                            currentState.step3.tv = true;
                        } else if (item.services_brought.includes('phone')) {
                            currentState.step3.phone = true;
                        }

                        currentState.internetId = item.id;
                        let provider: any = null;

                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }

                        currentState.step3.internetProvider = provider;
                        currentState.step3.internetProviderInput = item.provider;
                        currentState.step3.internetPayingAmount = item.price;
                    } else if (item.service === 'Home Security') {
                        let options: any = currentState.securityProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        currentState.step4.securityProvider = provider;
                        currentState.step4.securityProviderInput = item.provider;
                        currentState.step4.securityPayingAmount = item.price;
                    } else if (item.service === 'Utilities') {
                        if (item.services_brought === 'Electric') {
                            let options: any = currentState.electricityProviderOptions;
                            let provider: any = null;
                            options.map((item1: any) => {
                                if (item1.value == item.provider) {
                                    provider = item1;
                                }
                            });
                            if (provider == null) {
                                provider = { value: 'Other', label: 'Other' };
                            }
                            currentState.step5.electricityProvider = provider;
                            currentState.step5.electricityProviderInput = item.provider;
                            currentState.electricId = item.id;
                            currentState.step5.electricityPayingAmount = item.price;
                        }
                        if (item.services_brought === 'Gas') {
                            let options: any = currentState.gasProviderOptions;
                            let provider: any = null;
                            options.map((item1: any) => {
                                if (item1.value == item.provider) {
                                    provider = item1;
                                }
                            });
                            if (provider == null) {
                                provider = { value: 'Other', label: 'Other' };
                            }
                            currentState.step5.gasProvider = provider;
                            currentState.step5.gasProviderInput = item.provider;
                            currentState.utilitesId = item.id;
                            currentState.step5.gasPayingAmount = item.price;
                        }
                    }
                });
        }

        if (
            nextProps &&
            nextProps.homeOwnerDashboardState &&
            nextProps.homeOwnerDashboardState.getSubscriptionDetails &&
            nextProps.homeOwnerDashboardState.getSubscriptionDetails.results &&
            nextProps.homeOwnerDashboardState.isGetSubscriptionDetails
        ) {
            nextProps.homeOwnerDashboardState.isGetSubscriptionDetails = false;
            nextProps.homeOwnerDashboardState.getSubscriptionDetails.results.map((item: any) => {
                if (item.service && item.service.name == 'Mortgage') {
                    if (item.loan_year && item.loan_year != null && item.loan_year != '') {
                        let loanTerm: any = null;

                        loanTerm = { label: item.loan_year, value: item.loan_year };
                        currentState.step1.loanTerm = loanTerm;
                    }
                    currentState.step1.loanBalance = item.price;
                    currentState.step1.loanPercentage = item.loan_percentage;
                } else if (item.service && item.service.name == 'Home Insurance') {
                    let options: any = currentState.insuranceProviderOptions;

                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'Other', label: 'Other' };
                    }
                    currentState.step2.diductibleAmount = item.deductible;
                    currentState.step2.insuranceProvider = provider;
                    currentState.step2.insuranceProviderInput = item.provider;
                    currentState.step2.payingAmount = item.price;
                } else if (item.service && item.service.name === 'Internet & Cable') {
                    let options: any = currentState.internetProviderOptions;
                    if (item.services_brought.includes('internet')) {
                        currentState.step3.internet = true;
                    } else if (item.services_brought.includes('tv')) {
                        currentState.step3.tv = true;
                    } else if (item.services_brought.includes('phone')) {
                        currentState.step3.phone = true;
                    }

                    let provider: any = null;

                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'Other', label: 'Other' };
                    }

                    currentState.step3.internetProvider = provider;
                    currentState.step3.internetProviderInput = item.provider;
                    currentState.step3.internetPayingAmount = item.price;
                } else if (item.service && item.service.name === 'Home Security') {
                    let options: any = currentState.securityProviderOptions;
                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'Other', label: 'Other' };
                    }
                    currentState.step4.securityProvider = provider;
                    currentState.step4.securityProviderInput = item.provider;
                    currentState.step4.securityPayingAmount = item.price;
                } else if (item.service && item.service.name === 'Utilities') {
                    if (item.services_brought === 'Electric') {
                        let options: any = currentState.electricityProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        currentState.step5.electricityProvider = provider;
                        currentState.step5.electricityProviderInput = item.provider;

                        currentState.step5.electricityPayingAmount = item.price;
                    }
                    if (item.services_brought === 'Gas') {
                        let options: any = currentState.gasProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        currentState.step5.gasProvider = provider;
                        currentState.step5.gasProviderInput = item.provider;
                        currentState.utilitesId = item.id;
                        currentState.step5.gasPayingAmount = item.price;
                    }
                }
            });
        }

        if (nextProps.serviceState && nextProps.serviceState.postService && nextProps.serviceState.isPostService) {
            nextProps.serviceState.isPostService = false;
        }

        return currentState;
    }
    onChangeStep1 = (e: any, name: 'loanBalance' | 'loanTerm' | 'loanPercentage' | 'monthlyPayment'| 'accountNumber') => {
        let step1: any;
        let val = e.target.value
        let tempStep1FormError : any = {...this.state.step1FormError }
        if(val && val != ""){
            // need to refactor this to make it comman
            if (name === 'loanPercentage') {
                let loanPercentageError = false
                const numberFieldValue = Number(val)
                const lastDigit = String(val).slice(-1)
                if (numberFieldValue && !regexPattern.test(numberFieldValue.toString())) {
                    loanPercentageError = true
                }
                else{
                    if(lastDigit === "0" && val?.toString().split(".")[1].length >= 5 ){
                        loanPercentageError = true
                    }else{
                        loanPercentageError = false
                    }
                }
                tempStep1FormError[name] = loanPercentageError
            }
            else
                tempStep1FormError[name] = false
        }
        
        step1 = { ...this.state.step1, [name]: val.replace(/[^0-9.]/g, '') };
        if(!tempStep1FormError.loanPercentage){
            this.setState({ step1: step1, isSubmitClicked: false, step1FormError: tempStep1FormError });
        }
    };

    onSelectStep1 = (val: any, name: string) => {
        
        let tempStep1FormError : any = {...this.state.step1FormError }
        if(val && val != ""){
            tempStep1FormError[name] = false
        }
        let step1 = { ...this.state.step1, [name]: val };
        this.setState({ step1: step1, isSubmitClicked: false, step1FormError: tempStep1FormError  });
    };

    onChangeStep2 = (e: any, name: string) => {
        let step2: any;
        if (name === 'insuranceProviderInput') {
            step2 = { ...this.state.step2, [name]: e.target.value };
        } else {
            step2 = { ...this.state.step2, [name]: e.target.value.replace(/[^0-9.]/g, '') };
        }
        this.setState({ step2: step2, isSubmitClicked: false });
    };
    onSelectStep2 = (e: any, name: string) => {
        let step2 = { ...this.state.step2, [name]: e };
        this.setState({ step2: step2 });
    };
    onChangeStep3 = (e: any, name: string) => {
        let step3: any;

        if (name === 'internetProviderInput') {
            step3 = { ...this.state.step3, [name]: e.target.value };
        } else {
            step3 = { ...this.state.step3, [name]: e.target.value.replace(/[^0-9.]/g, '') };
        }
        this.setState({ step3: step3, isSubmitClicked: false });
    };
    onSelectStep3 = (e: any, name: string) => {
        let step3 = { ...this.state.step3, [name]: e };
        this.setState({ step3: step3 });
    };
    handleChange3 = async (e: any, e1: any) => {
        const step3 = { ...this.state.step3, [e]: !e1 };
        await this.setState(() => ({ step3 }));
    };
    setCheckboxValStep3 = async (e: any) => {
        const step3 = { ...this.state.step3, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step3 }));
    };
    onChangeStep4 = (e: any, name: string) => {
        let step4: any;
        if (name === 'securityProviderInput') {
            step4 = { ...this.state.step4, [name]: e.target.value };
        } else {
            step4 = { ...this.state.step4, [name]: e.target.value.replace(/[^0-9.]/g, '') };
        }
        this.setState({ step4: step4 });
    };
    onSelectStep4 = (e: any, name: string) => {
        let step4 = { ...this.state.step4, [name]: e };
        this.setState({ step4: step4 });
    };
    onChangeStep5 = (e: any, name: string) => {
        let step5: any;
        if (name == 'gasProviderInput') {
            step5 = { ...this.state.step5, [name]: e.target.value };
        } else if (name == 'electricityProviderInput') {
            step5 = { ...this.state.step5, [name]: e.target.value };
        } else {
            step5 = { ...this.state.step5, [name]: e.target.value.replace(/[^0-9.]/g, '') };
        }
        this.setState({ step5: step5 });
    };
    onSelectStep5 = (e: any, name: string) => {
        let step5 = { ...this.state.step5, [name]: e };
        this.setState({ step5: step5 });
    };

    getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <CustomizedInput
                                    labelName="Original Loan Amount"
                                    error={this.state.step1FormError.loanBalance}
                                    helperText={errorMessages.required}
                                    value={this.state.step1 && this.state.step1.loanBalance}
                                    onChange={(e: any) => this.onChangeStep1(e, 'loanBalance')}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <SelectInput
                                    label={'Loan Term'}
                                    value={
                                        this.state.step1 && this.state.step1.loanTerm && this.state.step1.loanTerm.value
                                    }
                                    onChange={(e: any) => this.onSelectStep1(e, 'loanTerm')}
                                    placeholder={
                                        this.state.step1 && this.state.step1.loanTerm && this.state.step1.loanTerm.label
                                            ? this.state.step1.loanTerm.label
                                            : 'Select Loan Term'
                                    }

                                    error={this.state.step1FormError.loanTerm}
                                    helperText={errorMessages.required}
                                    options={this.state.loanTermOptions}
                                />
                            </div>

                            <div className="inputDiv1">
                                <div className="inputDiv2">
                                    <CustomizedInput
                                        error={this.state.step1FormError.loanPercentage}
                                        helperText={"Please enter valid input"}
                                        labelName="APR"
                                        value={this.state.step1 && this.state.step1.loanPercentage}
                                        onChange={(e: any) => this.onChangeStep1(e, 'loanPercentage')}
                                        placeholder=""
                                        
                                    />
                                </div>
                                <div className="percentageDiv">%</div>
                            </div>
                        </div>

                      {/*  <div className='inputDivSavings'>
                            <div className="inputDiv1">
                                <SelectInput
                                    error={this.state.step1FormError.loanStartMonth}
                                    helperText={errorMessages.required}
                                    label={'Loan Start Month'}
                                    value={
                                        this.state.step1 && this.state.step1.loanStartMonth && this.state.step1.loanStartMonth.value
                                    }
                                    onChange={(e: any) => this.onSelectStep1(e, 'loanStartMonth')}
                                    placeholder={
                                        this.state.step1 && this.state.step1.loanStartMonth && this.state.step1.loanStartMonth.label
                                            ? this.state.step1.loanStartMonth.label
                                            : 'Select Loan Start Month'
                                    }
                                    options={getUptoCurrentMonth(monthList, currentMonth, this.state.step1?.loanStartYear?.value, currentYear)}
                                />
                            </div>
                            <div className="inputDiv1">
                                <SelectInput
                                    error={this.state.step1FormError.loanStartYear}
                                    helperText={errorMessages.required}
                                    label={'Loan Start Year'}
                                    value={
                                        this.state.step1 && this.state.step1.loanStartYear && this.state.step1.loanStartYear.value
                                    }
                                    onChange={(e: any) => this.onSelectStep1(e, 'loanStartYear')}
                                    placeholder={
                                        this.state.step1 && this.state.step1.loanStartYear && this.state.step1.loanStartYear.label
                                            ? this.state.step1.loanStartYear.label
                                            : 'Select Loan Start Year'
                                    }
                                    options={this.state.startYearOptions}
                                />
                            </div>
                        </div>
                         <div className='inputDivSavings'>
                            <div className="inputDiv1">
                                <SelectInput
                                    error={this.state.step1FormError.loanEndMonth}
                                    helperText={errorMessages.required}
                                    label={'Loan End Month'}
                                    value={
                                        this.state.step1?.loanEndMonth?.value
                                    }
                                    onChange={(e: any) => this.onSelectStep1(e, 'loanEndMonth')}
                                    placeholder={
                                        this.state.step1 && this.state.step1.loanEndMonth && this.state.step1.loanEndMonth.label
                                            ? this.state.step1.loanEndMonth.label
                                            : 'Select Loan End Month'
                                    }
                                    options={monthList.map(month => {return {label: month, value: month}})}
                                />
                            </div>

                            <div className="inputDiv1">
                                <SelectInput
                                    error={this.state.step1FormError.loanEndYear}
                                    helperText={errorMessages.required}
                                    label={'Loan End Year'}
                                    value={
                                        this.state.step1 && this.state.step1.loanEndYear && this.state.step1.loanEndYear.value
                                    }
                                    onChange={(e: any) => this.onSelectStep1(e, 'loanEndYear')}
                                    placeholder={
                                        this.state.step1 && this.state.step1.loanEndYear && this.state.step1.loanEndYear.label
                                            ? this.state.step1.loanEndYear.label
                                            : 'Select Loan End Year'
                                    }
                                    options={this.state.endYearOptions}
                                />
                            </div>
                        </div> 

                        <div className='monthlyPayment'>
                            <div className="inputDivSavings">
                                <div className="inputDiv1">
                                    <CustomizedInput
                                        error={this.state.step1FormError.monthlyPayment}
                                        helperText={errorMessages.required}
                                        labelName="Monthly Payment"
                                        value={this.state.step1 && this.state.step1.monthlyPayment}
                                        onChange={(e: any) => this.onChangeStep1(e, 'monthlyPayment')}
                                        placeholder="Monthly Payment"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='accountNumber'>
                            <div className="inputDivSavings">
                                <div className="inputDiv1">
                                    <CustomizedInput
                                        labelName="Account Number"
                                        value={this.state.step1 && this.state.step1.accountNumber}
                                        onChange={(e: any) => this.onChangeStep1(e, 'accountNumber')}
                                        placeholder="Account Number"
                                    />
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <div className="error">
                                    {this.state.isSubmitClicked && this.state.step1.loanTerm == null
                                        ? 'Please enter Loan Term'
                                        : ''}
                                </div>
                            </div>
                            <div className="inputDiv1">
                                <div className="error">
                                    {' '}
                                    {this.state.isSubmitClicked && this.state.step1.loanPercentage == ''
                                        ? 'Please select service provider'
                                        : this.state.isSubmitClicked &&
                                            this.state.step1.loanPercentage &&
                                            !regexPattern.test(this.state.step1.loanPercentage)
                                            ? 'Please enter valid percentage'
                                            : ''}
                                </div>
                            </div>
                        </div> */}

                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <SelectInput
                                    label={'What insurance provider do you have today?'}
                                    value={
                                        this.state.step2 &&
                                        this.state.step2.insuranceProvider &&
                                        this.state.step2.insuranceProvider.value
                                    }
                                    onChange={(e: any) => this.onSelectStep2(e, 'insuranceProvider')}
                                    placeholder={
                                        this.state.step2 &&
                                            this.state.step2.insuranceProvider &&
                                            this.state.step2.insuranceProvider.label
                                            ? this.state.step2.insuranceProvider.label
                                            : 'Select insurance provider'
                                    }
                                    options={this.state.insuranceProviderOptions}
                                // inputLa
                                />
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step2.insuranceProvider == null
                                ? 'Please select service provider'
                                : ''}
                        </div>
                        {this.state.step2.insuranceProvider && this.state.step2.insuranceProvider.value == 'Other' ? (
                            <div>
                                <div className="inputDivSavings">
                                    <CustomizedInput
                                        labelName="Enter insurance provider?"
                                        value={this.state.step2 && this.state.step2.insuranceProviderInput}
                                        onChange={(e: any) => this.onChangeStep2(e, 'insuranceProviderInput')}
                                        placeholder=""
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    // inputLa
                                    />
                                </div>
                                <div className="error">
                                    {this.state.isSubmitClicked && this.state.step2.insuranceProviderInput == ''
                                        ? 'Please enter Provider'
                                        : ''}
                                </div>
                            </div>
                        ) : null}
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step2.insuranceProvider == ''
                                ? 'Select Insurance Provider'
                                : ''}
                        </div>
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <CustomizedInput
                                    labelName="What is your deductible?"
                                    value={this.state.step2 && this.state.step2.diductibleAmount}
                                    onChange={(e: any) => this.onChangeStep2(e, 'diductibleAmount')}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                // inputLa
                                />
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step2.diductibleAmount == ''
                                ? 'Please enter estimated deductible amount'
                                : ''}
                        </div>
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <CustomizedInput
                                    labelName="How much are you paying?"
                                    value={this.state.step2 && this.state.step2.payingAmount}
                                    onChange={(e: any) => this.onChangeStep2(e, 'payingAmount')}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                // inputLa
                                />
                                <span className="percentageDiv"> /month</span>
                            </div>
                            {/* <div className="inputDiv1">
                                <div className="percentageDiv">/month</div>
                            </div> */}
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step2.payingAmount == ''
                                ? 'Please enter estimated monthly payment'
                                : ''}
                        </div>
                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <SelectInput
                                    label={'Which provider are you using?'}
                                    value={this.state.step3 && this.state.step3.internetProvider}
                                    onChange={(e: any) => this.onSelectStep3(e, 'internetProvider')}
                                    placeholder={
                                        this.state.step3 &&
                                            this.state.step3.internetProvider &&
                                            this.state.step3.internetProvider.label
                                            ? this.state.step3.internetProvider.label
                                            : 'Select internet provider'
                                    }
                                    options={this.state.internetProviderOptions}
                                // inputLa
                                />
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step3.internetProvider == null
                                ? 'Please select Provider'
                                : ''}
                        </div>

                        {this.state.step3.internetProvider && this.state.step3.internetProvider.value === 'Other' ? (
                            <div>
                                <div className="inputDivSavings">
                                    <CustomizedInput
                                        labelName="Enter insurance provider?"
                                        value={this.state.step2 && this.state.step3.internetProviderInput}
                                        onChange={(e: any) => this.onChangeStep3(e, 'internetProviderInput')}
                                        placeholder=""
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    // inputLa
                                    />
                                </div>
                                <div className="error">
                                    {this.state.isSubmitClicked && this.state.step3.internetProviderInput == ''
                                        ? 'Please enter Provider'
                                        : ''}
                                </div>
                            </div>
                        ) : null}

                        <div className="inputDivSavings">
                            <div className="inputDiv3">
                                <CheckboxCard
                                    className="individualCardSavings"
                                    checked={this.state.step3.internet}
                                    handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                    handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                    name="internet"
                                    icon={<InternetSvg checked={this.state.step3.internet} />}
                                    label={'Internet'}
                                    iconColour={'#273E59'}
                                // iconColour="#FF0000"
                                />
                                <CheckboxCard
                                    className="individualCardSavings"
                                    checked={this.state.step3.tv}
                                    handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                    handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                    name="tv"
                                    icon={<TvSvg checked={this.state.step3.tv} />}
                                    label={'TV'}
                                    iconColour={'#273E59'}
                                // iconColour="#FF0000"
                                />
                                <CheckboxCard
                                    className="individualCardSavings"
                                    checked={this.state.step3.phone}
                                    handleChange={(e: any) => this.setCheckboxValStep3(e)}
                                    handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
                                    name="phone"
                                    icon={<CallSvg checked={this.state.step3.phone} />}
                                    label={'Phone'}
                                    iconColour={'#273E59'}
                                // iconColour="#FF0000"
                                />
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked &&
                                !this.state.step3.internet &&
                                !this.state.step3.tv &&
                                !this.state.step3.phone
                                ? 'Select services you use today'
                                : ''}
                        </div>
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <CustomizedInput
                                    labelName="How much are you paying?"
                                    value={this.state.step3 && this.state.step3.internetPayingAmount}
                                    onChange={(e: any) => this.onChangeStep3(e, 'internetPayingAmount')}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                // inputLa
                                />
                                <span className="percentageDiv"> /month</span>
                            </div>

                            {/* <div className="inputDiv1">
                                <div className="percentageDiv">/month</div>
                            </div> */}
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step3.internetPayingAmount == ''
                                ? 'Please enter estimated monthly payment'
                                : ''}
                        </div>
                        <div className="buttonInputPowerKiosk">
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="autoshippingStepsContent">
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <SelectInput
                                    label={'Which security provider are you using?'}
                                    value={this.state.step4 && this.state.step4.securityProvider}
                                    onChange={(e: any) => this.onSelectStep4(e, 'securityProvider')}
                                    placeholder={
                                        this.state.step4 &&
                                            this.state.step4.securityProvider &&
                                            this.state.step4.securityProvider.label
                                            ? this.state.step4.securityProvider.label
                                            : 'Select security provider'
                                    }
                                    options={this.state.securityProviderOptions}
                                // inputLa
                                />
                            </div>
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step4.securityProvider == null
                                ? 'Please select Provider'
                                : ''}
                        </div>
                        {this.state.step4.securityProvider && this.state.step4.securityProvider.value === 'Other' ? (
                            <div>
                                <div className="inputDivSavings">
                                    <CustomizedInput
                                        labelName="Enter security provider?"
                                        value={this.state.step4 && this.state.step4.securityProviderInput}
                                        onChange={(e: any) => this.onChangeStep4(e, 'securityProviderInput')}
                                        placeholder=""
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    // inputLa
                                    />
                                </div>
                                <div className="error">
                                    {this.state.isSubmitClicked && this.state.step4.securityProviderInput == ''
                                        ? 'Please enter Provider'
                                        : ''}
                                </div>
                            </div>
                        ) : null}
                        <div className="inputDivSavings">
                            <div className="inputDiv1">
                                <CustomizedInput
                                    labelName="How much are you paying?"
                                    value={this.state.step4 && this.state.step4.securityPayingAmount}
                                    onChange={(e: any) => this.onChangeStep4(e, 'securityPayingAmount')}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                // inputLa
                                />
                                <span className="percentageDiv"> /month</span>
                            </div>
                            {/* <div className="inputDiv1"></div> */}
                        </div>
                        <div className="error">
                            {this.state.isSubmitClicked && this.state.step4.securityPayingAmount == ''
                                ? 'Please enter estimated monthly payment'
                                : ''}
                        </div>

                        <div>{/* <span style={{ color: 'blue' }}>I don't have security provider</span> */}</div>
                        <div className="buttonInputPowerKioskLast">
                            <div className="skipTheActiveStep" onClick={this.handleSkipped}>
                                I don’t have any Security provider
                            </div>

                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWaranty3"
                                onClick={this.handleNext}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="autoshippingStepsContent">
                        {this.state.deregulated === true ? (
                            <div>
                                {this.state.deregulateElectricity === 'Electricity' ? (
                                    <div>
                                        <div className="inputDivSavings">
                                            <div className="inputDiv1">
                                                <SelectInput
                                                    label={'Which provider are you using?'}
                                                    value={
                                                        this.state.step5 &&
                                                        this.state.step5.electricityProvider &&
                                                        this.state.step5.electricityProvider.value
                                                    }
                                                    onChange={(e: any) => this.onSelectStep5(e, 'electricityProvider')}
                                                    placeholder={
                                                        this.state.step5 &&
                                                            this.state.step5.electricityProvider &&
                                                            this.state.step5.electricityProvider.label
                                                            ? this.state.step5.electricityProvider.label
                                                            : 'Select electricity provider'
                                                    }
                                                    options={this.state.electricityProviderOptions}
                                                // inputLa
                                                />
                                            </div>
                                        </div>
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.step5.electricityProvider == null
                                                ? 'Please select Provider'
                                                : ''}
                                        </div>
                                        {this.state.step5.electricityProvider &&
                                            this.state.step5.electricityProvider.value === 'Other' ? (
                                            <div>
                                                <div className="inputDivSavings">
                                                    <CustomizedInput
                                                        labelName="Enter security provider?"
                                                        value={
                                                            this.state.step5 &&
                                                            this.state.step5.electricityProviderInput
                                                        }
                                                        onChange={(e: any) =>
                                                            this.onChangeStep5(e, 'electricityProviderInput')
                                                        }
                                                        placeholder=""
                                                    // InputProps={{
                                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    // }}
                                                    // inputLa
                                                    />
                                                </div>
                                                <div className="error">
                                                    {this.state.isSubmitClicked &&
                                                        this.state.step5.electricityProviderInput == ''
                                                        ? 'Please enter Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className="inputDivSavings">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="How much are you paying?"
                                                    value={this.state.step5 && this.state.step5.electricityPayingAmount}
                                                    onChange={(e: any) =>
                                                        this.onChangeStep5(e, 'electricityPayingAmount')
                                                    }
                                                    placeholder=""
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                // inputLa
                                                />
                                                <span className="percentageDiv"> /month</span>
                                            </div>
                                            {/* <div className="inputDiv1">
                                <div className="percentageDiv">/month</div>
                            </div> */}
                                        </div>
                                        <div className="error">
                                            {this.state.isSubmitClicked &&
                                                this.state.step5.electricityPayingAmount == ''
                                                ? 'Please enter estimated monthly payment'
                                                : ''}
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.deregulateGas === 'Natural Gas' ? (
                                    <div>
                                        <div className="inputDivSavings">
                                            <div className="inputDiv1">
                                                <SelectInput
                                                    label={'Which provider are you using?'}
                                                    value={
                                                        this.state.step5 &&
                                                        this.state.step5.gasProvider &&
                                                        this.state.step5.gasProvider.value
                                                    }
                                                    onChange={(e: any) => this.onSelectStep5(e, 'gasProvider')}
                                                    placeholder={
                                                        this.state.step5 &&
                                                            this.state.step5.gasProvider &&
                                                            this.state.step5.gasProvider.label
                                                            ? this.state.step5.gasProvider.label
                                                            : 'Select gas provider'
                                                    }
                                                    options={this.state.gasProviderOptions}
                                                // inputLa
                                                />
                                            </div>
                                        </div>
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.step5.gasProvider == null
                                                ? 'Please select Provider'
                                                : ''}
                                        </div>
                                        {this.state.step5.gasProvider &&
                                            this.state.step5.gasProvider.value === 'Other' ? (
                                            <div>
                                                <div className="inputDivSavings">
                                                    <CustomizedInput
                                                        labelName="Enter  provider?"
                                                        value={this.state.step5 && this.state.step5.gasProviderInput}
                                                        onChange={(e: any) => this.onChangeStep5(e, 'gasProviderInput')}
                                                        placeholder=""
                                                    // InputProps={{
                                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    // }}
                                                    // inputLa
                                                    />
                                                </div>
                                                <div className="error">
                                                    {this.state.isSubmitClicked &&
                                                        this.state.step5.gasProviderInput == ''
                                                        ? 'Please enter Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="inputDivSavings">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="How much are you paying?"
                                                    value={this.state.step5 && this.state.step5.gasPayingAmount}
                                                    onChange={(e: any) => this.onChangeStep5(e, 'gasPayingAmount')}
                                                    placeholder=""
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                // inputLa
                                                />
                                                <span className="percentageDiv"> /month</span>
                                            </div>
                                            {/* <div className="inputDiv1">
                                <div className="percentageDiv">/month</div>
                            </div> */}
                                        </div>
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.step5.gasPayingAmount == ''
                                                ? 'Please enter estimated monthly payment'
                                                : ''}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="buttonInputPowerKioskLast">
                            {/* <div className="skipTheActiveStep" onClick={this.handleNext}>
                                I don’t have any Utility provider
                            </div> */}
                            <Button
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                // color={'#fff'}
                                // variant="contained"
                                // color="primary"
                                className="btnWarantyCheck"
                                onClick={this.handleNext}
                            >
                                Find My Savings
                            </Button>
                        </div>
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    getSteps() {
        if (this.state.deregulated === false) {
            return ['Mortgage', 'Home Insurance', 'Internet & Cable', 'Home Security'];
        } else {
            return ['Mortgage', 'Home Insurance', 'Internet & Cable', 'Home Security', 'Utilities'];
        }
    }

    handleSkipped = () => {
        if (this.state.activeStep === 3) {
            this.setState({
                activeStep: 4,
            });
        }
    };
    handleBackToHomeOwner = () => {
        // let payload = {
        //     moveId: this.state.moveId,
        // };

        // this.props.nextMoveAction.getNextMove(payload);
        this.props.history.push('/dashboardhomeowner');
    };

    validate = (step : string) : boolean => {
        let notEverythingFilled = false
        if(step == 'Mortgage'){
            let {
                loanBalance,
                loanTerm ,
                loanPercentage
            } = this.state.step1
            
            notEverythingFilled = [
                loanBalance,
                loanTerm,
                loanPercentage
            ].some(val => val === null);

            let step1FormError = { ...this.state.step1FormError }
            if(!loanBalance){
                step1FormError.loanBalance = true
            }
            if(!loanTerm){
                step1FormError.loanTerm = true
            }
            if(!loanPercentage){
                step1FormError.loanPercentage = true
            }
            this.setState({step1FormError})
        }

        return !notEverythingFilled
    }

    handleNext = async () => {
        if (this.state.activeStep === 0) {
            this.setState({ isSubmitClicked: true });
            if (!this.validate('Mortgage')) {
                return
            }
            if (this.state.motgageId) {
                if (
                    this.state.step1.loanBalance &&
                    this.state.step1.loanPercentage &&
                    this.state.step1.loanTerm &&
                    regexPattern.test(this.state.step1.loanPercentage)
                ) {
                    let payload = {
                        serviceId: this.state.motgageId,
                        moveId: this.state.moveId,
                        body: {
                            service: 'Mortgage',
                            price: this.state.step1.loanBalance,
                            loan_year: this.state.step1.loanTerm.value,
                            loan_percentage: this.state.step1.loanPercentage,
                            move: this.state.moveId,
                            provider: 'Mortgage',
                        },
                    };
                    this.props.nextMoveAction.updateNextMove(payload);
                    this.setState({ activeStep: 1, isSubmitClicked: false });
                }
            } else {
                if (
                    this.state.step1.loanBalance &&
                    this.state.step1.loanPercentage &&
                    this.state.step1.loanTerm &&
                    this.state.step1.loanPercentage.match(regexPattern)
                ) {
                    let payload = {
                        service: 'Mortgage',
                        price: this.state.step1.loanBalance,
                        loan_year: this.state.step1.loanTerm.value,
                        loan_percentage: this.state.step1.loanPercentage,
                        move: this.state.moveId,
                        provider: 'Mortgage',
                    };
                    this.props.serviceAction.postService(payload);
                    this.setState({ activeStep: 1, isSubmitClicked: false });
                }
            }
        } else if (this.state.activeStep === 1) {
            // let CheckboxCard;
            this.setState({ isSubmitClicked: true });
            if (this.state.insuranceId) {
                if (
                    this.state.step2.insuranceProvider &&
                    this.state.step2.diductibleAmount &&
                    this.state.step2.payingAmount &&
                    ((this.state.step2.insuranceProvider.value == 'Other' && this.state.step2.insuranceProviderInput) ||
                        this.state.step2.insuranceProvider.value != 'Other')
                ) {
                    const payload = {
                        serviceId: this.state.insuranceId,
                        moveId: this.state.moveId,
                        body: {
                            service: 'Home Insurance',
                            deductible: this.state.step2.diductibleAmount,
                            price: this.state.step2.payingAmount,
                            provider:
                                this.state.step2.insuranceProvider &&
                                    this.state.step2.insuranceProvider.value &&
                                    this.state.step2.insuranceProvider.value != 'Other'
                                    ? this.state.step2.insuranceProvider.value
                                    : this.state.step2.insuranceProvider &&
                                        this.state.step2.insuranceProvider.value &&
                                        this.state.step2.insuranceProvider.value == 'Other'
                                        ? this.state.step2.insuranceProviderInput
                                        : '',
                            // this.state.step2.insuranceProvider && this.state.step2.insuranceProvider.value === 'Other'
                            //     ? this.state.step2.insuranceProviderInput
                            //     : this.state.step2.insuranceProvider.label,
                            move: this.state.moveId,
                        },
                    };

                    await this.props.nextMoveAction.updateNextMove(payload);
                    this.setState({ activeStep: 2, isSubmitClicked: false });
                }
            } else {
                if (
                    this.state.step2.insuranceProvider &&
                    this.state.step2.diductibleAmount &&
                    this.state.step2.payingAmount &&
                    ((this.state.step2.insuranceProvider.value == 'Other' && this.state.step2.insuranceProviderInput) ||
                        this.state.step2.insuranceProvider.value != 'Other')
                ) {
                    let payload = {
                        service: 'Home Insurance',
                        deductible: this.state.step2.diductibleAmount,
                        price: this.state.step2.payingAmount,
                        provider:
                            this.state.step2.insuranceProvider &&
                                this.state.step2.insuranceProvider.value &&
                                this.state.step2.insuranceProvider.value != 'Other'
                                ? this.state.step2.insuranceProvider.value
                                : this.state.step2.insuranceProvider &&
                                    this.state.step2.insuranceProvider.value &&
                                    this.state.step2.insuranceProvider.value == 'Other'
                                    ? this.state.step2.insuranceProviderInput
                                    : '',
                        // this.state.step2.insuranceProvider && this.state.step2.insuranceProvider.value == 'Other'
                        //     ? this.state.step2.insuranceProviderInput
                        //     : this.state.step2.insuranceProvider.label,
                        move: this.state.moveId,
                    };
                    this.props.serviceAction.postService(payload);
                    this.setState({ activeStep: 2, isSubmitClicked: false });
                }
            }
        } else if (this.state.activeStep === 2) {
            this.setState({ isSubmitClicked: true });
            // let dee: any = false;

            if (this.state.internetId) {
                if (
                    this.state.step3.internetProvider &&
                    ((this.state.step3.internetProvider.value == 'Other' && this.state.step3.internetProviderInput) ||
                        this.state.step3.internetProvider.value != 'Other') &&
                    this.state.step3.internetPayingAmount &&
                    (this.state.step3.internet || this.state.step3.tv || this.state.step3.phone)
                ) {
                    let internet = '';
                    if (this.state.step3.internet === true) {
                        internet = 'internet';
                    }
                    let tv = '';
                    if (this.state.step3.tv === true) {
                        tv = 'tv';
                    }
                    let phone = '';
                    if (this.state.step3.tv === true) {
                        phone = 'phone';
                    }
                    let payload = {
                        serviceId: this.state.internetId,
                        moveId: this.state.moveId,
                        body: {
                            service: 'Internet & Cable',
                            provider:
                                this.state.step3.internetProvider &&
                                    this.state.step3.internetProvider.value &&
                                    this.state.step3.internetProvider.value != 'Other'
                                    ? this.state.step3.internetProvider.value
                                    : this.state.step3.internetProvider &&
                                        this.state.step3.internetProvider.value &&
                                        this.state.step3.internetProvider.value == 'Other'
                                        ? this.state.step3.internetProviderInput
                                        : '',
                            // this.state.step3.internetProvider && this.state.step3.internetProvider.value === 'Other'
                            //     ? this.state.step3.internetProviderInput
                            //     : this.state.step3.internetProvider.label,
                            services_brought: `${internet} ${tv}  ${phone}`,
                            price: this.state.step3.internetPayingAmount,
                            move: this.state.moveId,
                        },
                    };
                    this.props.nextMoveAction.updateNextMove(payload);
                    this.setState({ activeStep: 3, isSubmitClicked: false });
                }
            } else {
                if (
                    this.state.step3.internetProvider &&
                    this.state.step3.internetProvider.label &&
                    this.state.step3.internetPayingAmount &&
                    ((this.state.step3.internetProvider.value == 'Other' && this.state.step3.internetProviderInput) ||
                        this.state.step3.internetProvider.value != 'Other') &&
                    (this.state.step3.internet || this.state.step3.tv || this.state.step3.phone)
                ) {
                    let internet = '';
                    if (this.state.step3.internet === true) {
                        internet = 'internet';
                    }
                    let tv = '';
                    if (this.state.step3.tv === true) {
                        tv = 'tv';
                    }
                    let phone = '';
                    if (this.state.step3.tv === true) {
                        phone = 'phone';
                    }
                    let payload = {
                        service: 'Internet & Cable',
                        provider:
                            this.state.step3.internetProvider &&
                                this.state.step3.internetProvider.value &&
                                this.state.step3.internetProvider.value != 'Other'
                                ? this.state.step3.internetProvider.value
                                : this.state.step3.internetProvider &&
                                    this.state.step3.internetProvider.value &&
                                    this.state.step3.internetProvider.value == 'Other'
                                    ? this.state.step3.internetProviderInput
                                    : '',
                        services_brought: `${internet} ${tv}  ${phone}`,
                        price: this.state.step3.internetPayingAmount,
                        move: this.state.moveId,
                    };
                    this.props.serviceAction.postService(payload);
                    this.setState({ activeStep: 3, isSubmitClicked: false });
                }
            }
        } else if (this.state.activeStep === 3) {
            this.setState({ isSubmitClicked: true });
            if (this.state.securityId) {
                if (
                    this.state.step4.securityProvider &&
                    ((this.state.step4.securityProvider.value == 'Other' && this.state.step4.securityProviderInput) ||
                        this.state.step4.securityProvider.value != 'Other') &&
                    // this.state.step4.securityProvider.label &&
                    this.state.step4.securityPayingAmount
                ) {
                    let payload = {
                        serviceId: this.state.securityId,
                        moveId: this.state.moveId,
                        body: {
                            service: 'Home Security',
                            provider:
                                this.state.step4.securityProvider &&
                                    this.state.step4.securityProvider.value &&
                                    this.state.step4.securityProvider.value != 'Other'
                                    ? this.state.step4.securityProvider.value
                                    : this.state.step4.securityProvider &&
                                        this.state.step4.securityProvider.value &&
                                        this.state.step4.securityProvider.value == 'Other'
                                        ? this.state.step4.securityProviderInput
                                        : '',
                            // this.state.step4.securityProvider && this.state.step4.securityProvider.value === 'Other'
                            //     ? this.state.step4.securityProviderInput
                            //     : this.state.step4.securityProvider.label,
                            price: this.state.step4.securityPayingAmount,
                            move: this.state.moveId,
                        },
                    };
                    this.props.nextMoveAction.updateNextMove(payload);

                    if (this.state.deregulated === false) {
                        this.setState({ checked: true, isSubmitClicked: false });
                        this.props.history.push('/dashboard/savings-flow/awesomeSaving');
                    } else {
                        this.setState({ activeStep: 4, isSubmitClicked: false });
                    }
                }
            } else {
                if (
                    this.state.step4.securityProvider &&
                    ((this.state.step4.securityProvider.value == 'Other' && this.state.step4.securityProviderInput) ||
                        this.state.step4.securityProvider.value != 'Other') &&
                    this.state.step4.securityPayingAmount
                ) {
                    let payload = {
                        service: 'Home Security',
                        provider:
                            this.state.step4.securityProvider &&
                                this.state.step4.securityProvider.value &&
                                this.state.step4.securityProvider.value != 'Other'
                                ? this.state.step4.securityProvider.value
                                : this.state.step4.securityProvider &&
                                    this.state.step4.securityProvider.value &&
                                    this.state.step4.securityProvider.value == 'Other'
                                    ? this.state.step4.securityProviderInput
                                    : '',
                        // this.state.step4.securityProvider && this.state.step4.securityProvider.value === 'Other'
                        //     ? this.state.step4.securityProviderInput
                        //     : this.state.step4.securityProvider.label,
                        price: this.state.step4.securityPayingAmount,
                        move: this.state.moveId,
                    };
                    this.props.serviceAction.postService(payload);
                    if (this.state.deregulated === false) {
                        this.setState({ checked: true, isSubmitClicked: false });

                        this.props.history.push('/dashboard/savings-flow/awesomeSaving');
                    } else {
                        this.setState({ activeStep: 4, isSubmitClicked: false });
                    }
                    // if (this.state.deregulated && this.state.checked) {

                    // }
                }
            }
        } else if (this.state.activeStep === 4) {
            let checking = this.state.checked;
            if (this.state.deregulated === false) {
                checking = true;
                // this.setState({ checked: true });
            } else {
                if (this.state.deregulateElectricity === 'Electricity' && this.state.deregulateGas === '') {
                    if (this.state.electricId) {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.electricityPayingAmount &&
                                this.state.step5.electricityProvider &&
                                ((this.state.step5.electricityProvider.value == 'Other' &&
                                    this.state.step5.electricityProviderInput) ||
                                    this.state.step5.electricityProvider.value != 'Other')
                            ) {
                                let payload1 = {
                                    serviceId: this.state.electricId,
                                    moveId: this.state.moveId,
                                    body: {
                                        service: 'Utilities',
                                        provider:
                                            this.state.step5.electricityProvider &&
                                                this.state.step5.electricityProvider.value &&
                                                this.state.step5.electricityProvider.value != 'Other'
                                                ? this.state.step5.electricityProvider.value
                                                : this.state.step5.electricityProvider &&
                                                    this.state.step5.electricityProvider.value &&
                                                    this.state.step5.electricityProvider.value == 'Other'
                                                    ? this.state.step5.electricityProviderInput
                                                    : '',

                                        price: this.state.step5.electricityPayingAmount,
                                        move: this.state.moveId,
                                        services_brought: 'Electric',
                                    },
                                };

                                this.props.nextMoveAction.updateNextMove(payload1);
                                checking = true;
                                this.setState({ isSubmitClicked: false });
                            }
                        }
                    } else {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.electricityPayingAmount &&
                                this.state.step5.electricityProvider &&
                                ((this.state.step5.electricityProvider.value == 'Other' &&
                                    this.state.step5.electricityProviderInput) ||
                                    (this.state.step5.electricityProvider.value != 'Other' &&
                                        this.state.step5.electricityProvider.value))
                            ) {
                                let payload1 = {
                                    service: 'Utilities',
                                    provider:
                                        this.state.step5.electricityProvider &&
                                            this.state.step5.electricityProvider.value &&
                                            this.state.step5.electricityProvider.value !== 'Other'
                                            ? this.state.step5.electricityProvider.value
                                            : this.state.step5.electricityProvider &&
                                                this.state.step5.electricityProvider.value &&
                                                this.state.step5.electricityProvider.value == 'Other'
                                                ? this.state.step5.electricityProviderInput
                                                : '',

                                    price: this.state.step5.electricityPayingAmount,
                                    move: this.state.moveId,
                                    services_brought: 'Electric',
                                };

                                this.props.serviceAction.postService(payload1);
                                checking = true;
                                this.setState({ isSubmitClicked: false });
                            }
                        }
                    }
                } else if (this.state.deregulateElectricity === '' && this.state.deregulateGas === 'Natural Gas') {
                    if (this.state.utilitesId && this.state.electricId) {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.gasProvider &&
                                this.state.step5.gasPayingAmount &&
                                ((this.state.step5.gasProvider.value == 'Other' && this.state.step5.gasProviderInput) ||
                                    this.state.step5.gasProvider.value != 'Other')
                            ) {
                                let payload = {
                                    serviceId: this.state.utilitesId,
                                    moveId: this.state.moveId,
                                    body: {
                                        service: 'Utilities',
                                        provider:
                                            this.state.step5.gasProvider &&
                                                this.state.step5.gasProvider.value &&
                                                this.state.step5.gasProvider.value != 'Other'
                                                ? this.state.step5.gasProvider.value
                                                : this.state.step5.gasProvider &&
                                                    this.state.step5.gasProvider.value &&
                                                    this.state.step5.gasProvider.value == 'Other'
                                                    ? this.state.step5.gasProviderInput
                                                    : '',

                                        price: this.state.step5.gasPayingAmount,
                                        move: this.state.moveId,
                                        services_brought: 'Gas',
                                    },
                                };
                                this.props.nextMoveAction.updateNextMove(payload);
                                checking = true;

                                this.setState({ isSubmitClicked: false });
                            }
                        }
                    } else {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.gasProvider &&
                                this.state.step5.gasPayingAmount &&
                                ((this.state.step5.gasProvider.value == 'Other' && this.state.step5.gasProviderInput) ||
                                    this.state.step5.gasProvider.value != 'Other')
                            ) {
                                let payload = {
                                    service: 'Utilities',
                                    provider:
                                        this.state.step5.gasProvider &&
                                            this.state.step5.gasProvider.value &&
                                            this.state.step5.gasProvider.value != 'Other'
                                            ? this.state.step5.gasProvider.value
                                            : this.state.step5.gasProvider &&
                                                this.state.step5.gasProvider.value &&
                                                this.state.step5.gasProvider.value == 'Other'
                                                ? this.state.step5.gasProviderInput
                                                : '',

                                    price: this.state.step5.gasPayingAmount,
                                    move: this.state.moveId,
                                    services_brought: 'Gas',
                                };
                                this.props.serviceAction.postService(payload);
                                checking = true;

                                this.setState({ isSubmitClicked: false, checked: true });
                            }
                        }
                    }
                } else {
                    if (this.state.utilitesId && this.state.electricId) {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.electricityPayingAmount &&
                                this.state.step5.electricityProvider &&
                                this.state.step5.gasProvider &&
                                this.state.step5.gasPayingAmount &&
                                ((this.state.step5.electricityProvider.value == 'Other' &&
                                    this.state.step5.electricityProviderInput) ||
                                    this.state.step5.electricityProvider.value != 'Other') &&
                                ((this.state.step5.gasProvider.value == 'Other' && this.state.step5.gasProviderInput) ||
                                    this.state.step5.gasProvider.value != 'Other')
                            ) {
                                let payload1 = {
                                    serviceId: this.state.electricId,
                                    moveId: this.state.moveId,
                                    body: {
                                        service: 'Utilities',
                                        provider:
                                            this.state.step5.electricityProvider &&
                                                this.state.step5.electricityProvider.value &&
                                                this.state.step5.electricityProvider.value != 'Other'
                                                ? this.state.step5.electricityProvider.value
                                                : this.state.step5.electricityProvider &&
                                                    this.state.step5.electricityProvider.value &&
                                                    this.state.step5.electricityProvider.value == 'Other'
                                                    ? this.state.step5.electricityProviderInput
                                                    : '',

                                        price: this.state.step5.electricityPayingAmount,
                                        move: this.state.moveId,
                                        services_brought: 'Electric',
                                    },
                                };
                                let payload = {
                                    serviceId: this.state.utilitesId,
                                    moveId: this.state.moveId,
                                    body: {
                                        service: 'Utilities',
                                        provider:
                                            this.state.step5.gasProvider &&
                                                this.state.step5.gasProvider.value &&
                                                this.state.step5.gasProvider.value != 'Other'
                                                ? this.state.step5.gasProvider.value
                                                : this.state.step5.gasProvider &&
                                                    this.state.step5.gasProvider.value &&
                                                    this.state.step5.gasProvider.value == 'Other'
                                                    ? this.state.step5.gasProviderInput
                                                    : '',

                                        price: this.state.step5.gasPayingAmount,
                                        move: this.state.moveId,
                                        services_brought: 'Gas',
                                    },
                                };
                                this.props.nextMoveAction.updateNextMove(payload);
                                this.props.nextMoveAction.updateNextMove(payload1);
                                checking = false;
                                this.setState({ isSubmitClicked: false });
                            }
                        }
                    } else {
                        if (checking === false) {
                            this.setState({ isSubmitClicked: true });
                            if (
                                this.state.step5.electricityPayingAmount &&
                                this.state.step5.electricityProvider &&
                                this.state.step5.gasProvider &&
                                this.state.step5.gasPayingAmount &&
                                ((this.state.step5.electricityProvider.value == 'Other' &&
                                    this.state.step5.electricityProviderInput) ||
                                    (this.state.step5.electricityProvider.value != 'Other' &&
                                        this.state.step5.electricityProvider.value)) &&
                                ((this.state.step5.gasProvider.value == 'Other' && this.state.step5.gasProviderInput) ||
                                    this.state.step5.gasProvider.value != 'Other')
                            ) {
                                let payload1 = {
                                    service: 'Utilities',
                                    provider:
                                        this.state.step5.electricityProvider &&
                                            this.state.step5.electricityProvider.value &&
                                            this.state.step5.electricityProvider.value !== 'Other'
                                            ? this.state.step5.electricityProvider.value
                                            : this.state.step5.electricityProvider &&
                                                this.state.step5.electricityProvider.value &&
                                                this.state.step5.electricityProvider.value == 'Other'
                                                ? this.state.step5.electricityProviderInput
                                                : '',

                                    price: this.state.step5.electricityPayingAmount,
                                    move: this.state.moveId,
                                    services_brought: 'Electric',
                                };
                                let payload = {
                                    service: 'Utilities',
                                    provider:
                                        this.state.step5.gasProvider &&
                                            this.state.step5.gasProvider.value &&
                                            this.state.step5.gasProvider.value != 'Other'
                                            ? this.state.step5.gasProvider.value
                                            : this.state.step5.gasProvider &&
                                                this.state.step5.gasProvider.value &&
                                                this.state.step5.gasProvider.value == 'Other'
                                                ? this.state.step5.gasProviderInput
                                                : '',

                                    price: this.state.step5.gasPayingAmount,
                                    move: this.state.moveId,
                                    services_brought: 'Gas',
                                };
                                this.props.serviceAction.postService(payload);
                                this.props.serviceAction.postService(payload1);
                                checking = true;
                                this.setState({ isSubmitClicked: false });
                            }
                        }
                    }
                }
            }

            if (checking === true) {
                this.props.history.push('/dashboard/savings-flow/awesomeSaving');
            }
        }
        // this.setState({ activeStep: this.state.activeStep + 1 });
    };
    handleBack = () => {
        if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
            this.props.history.push({
                pathname: '/dashboardhomeowner',
            });
        } else {
            this.props.history.push('/dashboard');
        }
    };

    render() {
        const steps = this.getSteps();

        return (
            <div>
                {this.props &&
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.state &&
                    this.props.history.location.state.showConfirmation ? (
                    <SavingsConfirmation handleBack={this.handleBack} />
                ) : (
                    <div className="savingsFlowMainPage">
                        <div className="progressBarFinal">
                            <Box>
                                <LinearProgress variant="determinate" value={((this.state.activeStep + 1) / 7) * 100} />
                            </Box>
                        </div>
                        <div className="savingsFLowMainFlexDiv">
                            <div className="imageContentDiv">
                                <div className="image">
                                    {this.state.activeStep === 0 ? (
                                        <img src={MortgageImage} alt="" />
                                    ) : this.state.activeStep === 1 ? (
                                        <img src={InsuranceImage} alt="" />
                                    ) : this.state.activeStep === 2 ? (
                                        <img src={InternetImage} alt="" />
                                    ) : this.state.activeStep === 3 ? (
                                        <img src={SecurityImage} alt="" />
                                    ) : this.state.activeStep === 4 ? (
                                        <img src={UtilityImage} alt="" />
                                    ) : null}
                                </div>
                                {this.state.activeStep === 0 ? (
                                    <div className="contentDiv">
                                        <div className="contentTitle">Save On Mortgage</div>
                                        <div className="contentDetails">
                                            Rates may have changed since you last financed your home. Our team can help
                                            you compare options to see if you are eligible for savings.
                                        </div>
                                    </div>
                                ) : this.state.activeStep === 1 ? (
                                    <div className="contentDiv">
                                        <div className="contentTitle">Save On Insurance</div>
                                        <div className="contentDetails">
                                            Looking to renew your home insurance? Our team can help you find the best
                                            deals.
                                        </div>
                                    </div>
                                ) : this.state.activeStep === 2 ? (
                                    <div className="contentDiv">
                                        <div className="contentTitle">Save On Internet & Cable</div>
                                        <div className="contentDetails">
                                            Our team can compare options to get you better deals on internet & cable in
                                            your area.
                                        </div>
                                    </div>
                                ) : this.state.activeStep === 3 ? (
                                    <div className="contentDiv">
                                        <div className="contentTitle">Save On Security</div>
                                        <div className="contentDetails">
                                            Not only can you get $1,700 worth of free equipment, but you may qualify for
                                            savings in other areas of your home.
                                        </div>
                                    </div>
                                ) : this.state.activeStep === 4 ? (
                                    <div className="contentDiv">
                                        <div className="contentTitle">Save On Utilities</div>
                                        <div className="contentDetails">
                                            If you are in a deregulated state, you may qualify for savings on
                                            electricity and gas. Our team can help you explore your options.
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="stepperMainDiv">
                                <div className="backIcon" onClick={this.handleBackToHomeOwner}>
                                    <ArrowBackIcon />
                                </div>
                                <div className="SavingHeading">Great! Savings for you in less than 5 min</div>
                                <div className="StepperDiv">
                                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                        {steps.map((label, index) => (
                                            <Step key={label}>
                                                <StepLabel>
                                                    <div className="labelDiv">
                                                        <div className="mainLabel">{label}</div>
                                                        {/* <div className="secondaryLabel">Select Service Provider</div> */}
                                                    </div>
                                                    {/* <div>
                                                    {this.state.activeStep === index ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </div> */}
                                                </StepLabel>
                                                <StepContent>
                                                    <div>{this.getStepContent(index)}</div>

                                                    <div className="savingStepButton">
                                                        {/* <Button className="savingNextButton" onClick={this.handleNext}>
                                                    Next
                                                </Button> */}
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                        {this.state.deregulated === false && this.state.activeStep === 4 && <div className="buttonInputPowerKioskLast">
                                            <Button
                                                className="btnWarantyCheck"
                                                onClick={this.handleNext}
                                            >
                                                Find My Savings
                                            </Button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        energyAction: bindActionCreators(EnergyAction, dispatch),
        UtilityAction: bindActionCreators(UtilityAction, dispatch),
        nextMoveAction: bindActionCreators(Action, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        homeValuationActions: bindActionCreators(HomeValuationActions, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    serviceState: state.service,
    commonState: state.common,
    energyState: state.energy,
    UtilityState: state.utility,
    nextMoveState: state.nextMove,
    homeOwnerDashboardState: state.homeOwnerdashboard
});
export default connect(mapStateToProps, mapDispatchToProps)(SavingsPage);
