import { all, call, put, takeLatest } from 'redux-saga/effects';
// import { Store } from 'react-notifications-component';
import {
    checklistByMoveIdFailure,
    checklistByMoveIdSuccess,
    postSubscriptionDetailsFailure,
    postSubscriptionDetailsSuccess,
    getSubscriptionDetailsFailure,
    getSubscriptionDetailsSuccess,
    updateSubscriptionDetailsFailure,
    updateSubscriptionDetailsSuccess,
    homeValuationCardDetailsSuccess,
    homeValuationCardDetailsFailure,
    addUserDetailsSuccess,
    addUserDetailsFailure,
    mortageDetailsSuccess,
    mortageDetailsFailure,
} from './actions';
import {
    GET_CHECKLIST_BY_MOVE_ID,
    POST_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_DETAILS,
    UPDATE_SUBSCRIPTION_DETAILS,
    HOME_VALUATION_CARD_DETAILS,
    ADD_USER_DETAILS,
    MORTAGE_DETAILS,
} from './actionTypes';
import apiJunction from '../../utils/api';
import { Store } from 'react-notifications-component';
import { getErrorMessage } from '../errorHandler';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function errorNotification(key: any, message: any) {
    Store.addNotification({
        title: `Error ${key} `,
        message: message,

        type: 'danger',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 2000,
            onScreen: true,
        },
    });
}

function* checklistByMoveId(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/checklist-api/get-checklist-by-move/?move_id=${action.payload.moveId}&must_do=true`,
        });
        if (response.data && response.status === 200) {
            yield put(checklistByMoveIdSuccess({ checklistByMoveId: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(checklistByMoveIdFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}
function* postSubscriptionDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-services-subscription/`,
            body: action.payload,
        });
        if (response.data && response.status == 201) {
            yield put(postSubscriptionDetailsSuccess({ postSubscriptionDetails: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Service subscription added successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(postSubscriptionDetailsFailure({ error: getErrorMessage(e) }));
        if (e && e.response && e.response.data) {
            for (let d of e.response.data) {
                let data = d;
                for (let key of Object.keys(data)) {
                    let array = data[key];
                    for (let element of array) {
                        if (typeof element == 'string') {
                            errorNotification(key, element);
                        }
                        if (typeof element == 'object') {
                            for (let key1 of Object.keys(element)) {
                                errorNotification(key1, element[key1][0]);
                            }
                        }
                    }
                }
            }
        }
    }
}

function* getSubscriptionDetails() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/move/move-services-subscription/`,
        });

        if (response.data && response.status === 200) {
            yield put(getSubscriptionDetailsSuccess({ getSubscriptionDetails: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(getSubscriptionDetailsFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* updateSubscriptionDetails(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'put',
            url: `/api/move/move-services-subscription/${action.payload.id}/`,
            body: action.payload.body,
        });

        if (response.data) {
            yield put(updateSubscriptionDetailsSuccess({ updateSubscriptionDetails: response.data }));
            Store.addNotification({
                title: 'Success',
                message: 'Service subscription added successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(updateSubscriptionDetailsFailure({ error: getErrorMessage(e) }));
        if (e && e.response && e.response.data) {
            for (let d of e.response.data) {
                let data = d;
                for (let key of Object.keys(data)) {
                    let array = data[key];
                    for (let element of array) {
                        if (typeof element == 'string') {
                            errorNotification(key, element);
                        }
                        if (typeof element == 'object') {
                            for (let key1 of Object.keys(element)) {
                                errorNotification(key1, element[key1][0]);
                            }
                        }
                    }
                }
            }
        }
    }
}

function* addUserDetails() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/move/move-savings-data/`,
            body: {
                service: 'Mortgage',
                provider: 'AT&T',
                services_brought: '',
                price: 435.6,
                account_number: 12345678,
                move: 24970,
                is_completed: true,
                deductible: 0,
                loan_year: '30-Year-Fixed',
                loan_percentage: 2.4,
                monthly_payment: 2000,
                service_start_date: '2022-01-01',
            },
        });

        // if (response) {
        //     console.log(response, 'dadygfsfgsgu');
        // } else {
        // }
        if (response.data) {
            yield put(addUserDetailsSuccess({ addUserDetails: response.data }));
            // Store.addNotification({
            //     title: 'Success',
            //     message: 'Service subscription added successfully',
            //     type: 'success',
            //     insert: 'bottom',
            //     container: 'bottom-center',
            //     animationIn: ['animate__animated', 'animate__fadeIn'],
            //     animationOut: ['animate__animated', 'animate__fadeOut'],
            //     dismiss: {
            //         duration: 2000,
            //         onScreen: true,
            //     },
            // });
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(addUserDetailsFailure({ error: getErrorMessage(e) }));
        if (e && e.response && e.response.data) {
            // for (let d of e.response.data) {
            //     let data = d;
            //     for (let key of Object.keys(data)) {
            //         let array = data[key];
            //         for (let element of array) {
            //             if (typeof element == 'string') {
            //                 errorNotification(key, element);
            //             }
            //             if (typeof element == 'object') {
            //                 for (let key1 of Object.keys(element)) {
            //                     errorNotification(key1, element[key1][0]);
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
}

function* getHomeValuationCardDetails() {
    try {
        const moveId: number = parseInt(localStorage.getItem('moveId') || '', 10);
        if (!isNaN(moveId)) {
            const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
                method: 'get',
                url: `/api/move/move-api/${moveId}/get-move-home-valuation/`,
            });
            if (response.data && response.status === 200) {
                yield put(homeValuationCardDetailsSuccess({ homeValuationCardDetails: response.data }));
            } else {
                // history.push('/bad-request');
            }
        }
        else {
            yield put(homeValuationCardDetailsFailure({ error: "Invalid Move ID" }));
        }
    } catch (e: any) {
        yield put(homeValuationCardDetailsFailure({ error: e?.message }));
        // history.push('/server-error');
    }
}

//post stand alone impression
function* postMortageDetails(action: any) {
    try {
        const moveId: number = parseInt(localStorage.getItem('moveId') || '', 10);
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-savings-data/?moveId=${moveId}`,
            body: action.payload,
        });

        if (response.data && response.status === 201) {
            yield put(mortageDetailsSuccess({ standAloneImpressionPosted: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(mortageDetailsFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* HomeOwnerDashboardSaga() {
    yield all([takeLatest(GET_CHECKLIST_BY_MOVE_ID, checklistByMoveId)]);
    yield all([takeLatest(POST_SUBSCRIPTION_DETAILS, postSubscriptionDetails)]);
    yield all([takeLatest(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails)]);
    yield all([takeLatest(UPDATE_SUBSCRIPTION_DETAILS, updateSubscriptionDetails)]);
    yield all([takeLatest(HOME_VALUATION_CARD_DETAILS, getHomeValuationCardDetails)]);
    yield all([takeLatest(ADD_USER_DETAILS, addUserDetails)]);
    yield all([takeLatest(MORTAGE_DETAILS, postMortageDetails)]);
}
