import React, { Component } from 'react';
import './updateAddress.scss';
import Mail from '../../Assets/images/UpdateAddress/updateAddMail.svg';
import CurrentHome from '../../Assets/images/UpdateAddress/updateAddCurrentHome.svg';
import FutureHome from '../../Assets/images/UpdateAddress/updateAddFutureHome.svg';
import Redirect from '../../Assets/images/UpdateAddress/redirectAddress.svg';
import Redirectblue from '../../Assets/images/UpdateAddress/redirectblue.svg';
import FutureAddress from '../../Assets/images/UpdateAddress/updateAddFutureAdd.svg';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import Button from '../../components/atoms/Button';
import VerificationModal from '../../components/atoms/updateAddressComponents/verificationModal';
import SubmitRequestModal from '../../components/atoms/updateAddressComponents/submitRequestModal';
import DeleteRequestModal from '../../components/atoms/updateAddressComponents/delRequestModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { connect } from 'react-redux';
import * as Action from '../../store/updateAddress/actions';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import { CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    history?: any;
    updateAddressAction: any;
    UpdateAddressState: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    option: any;
    firstName: string;
    middleName: string;
    lastName: string;
    curHomeStreet: any;
    curHomeDetail: any;
    futureHomeStreet: any;
    futureHomeDetail: any;
    curHomeApt: any;
    futureHomeApt: any;
    futureAddress: any;
    startDate: any;
    endDate: any;
    addRequest: boolean;
    editRequest: boolean;
    showDelModal: boolean;
    goToMyRequests: boolean;
    showVerificationModal: boolean;
    newServicesData?: any;
    phone: any;
    userId: any;
    isMovingDataArrived?: boolean;
    isStartDateOpen?: boolean;
    isEndDateOpen?: boolean;
    individualExceptions: boolean;
    familyExceptions: boolean;
    verficationCodeSent: boolean;
    otp: any;
    otpVerification: boolean;
    filedRequestArr: any;
    newServiceLoading: boolean;
    phoneVerifyLoading: boolean;
    otpVerifyLoading: boolean;
    reqFiledLoading: boolean;
    delId: any;
    percent: any;
    isDataArrived2: boolean;
    phone_verified: boolean;
    move_id: any;
    saveResponseLoading: boolean;
    requestSubmitted: boolean;
    submitClick: boolean;
    responseSaved: boolean;
    deleteLoading: boolean;
    editId: any;
    updateResponseLoading: boolean;
    responseUpdated: boolean;
    showPhoneError: boolean;
    showOtpError: boolean;
    phoneError: string;
    otpError: string;
    sms_code: any;
    hashCode: any;
    isHashDataArrived: boolean;
    isDouglasElliman: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
};

class UpdateAddress extends Component<initialProps, initialState> {
    state: initialState = {
        option: 'Individual',
        firstName: '',
        middleName: '',
        lastName: '',
        curHomeStreet: '',
        curHomeDetail: '',
        futureHomeStreet: '',
        futureHomeDetail: '',
        curHomeApt: '',
        futureHomeApt: '',
        futureAddress: 'permanent',
        startDate: new Date(),
        endDate: new Date(),
        addRequest: false,
        editRequest: false,
        showDelModal: false,
        goToMyRequests: false,
        showVerificationModal: false,
        newServicesData: [],
        phone: '',
        userId: '',
        isMovingDataArrived: true,
        isStartDateOpen: false,
        isEndDateOpen: false,
        individualExceptions: false,
        familyExceptions: false,
        verficationCodeSent: false,
        otp: '',
        otpVerification: false,
        filedRequestArr: [],
        newServiceLoading: false,
        phoneVerifyLoading: false,
        otpVerifyLoading: false,
        reqFiledLoading: false,
        delId: '',
        percent: '',
        isDataArrived2: true,
        phone_verified: false,
        move_id: null,
        saveResponseLoading: false,
        requestSubmitted: false,
        submitClick: false,
        responseSaved: true,
        deleteLoading: false,
        editId: null,
        updateResponseLoading: false,
        responseUpdated: false,
        showPhoneError: false,
        showOtpError: false,
        phoneError: '',
        otpError: '',
        sms_code: '',
        hashCode: '',
        isHashDataArrived: true,
        isDouglasElliman: false,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        window.scrollTo(0, 0);
        this.props.updateAddressAction.requestsFiled();
        this.props.updateAddressAction.newServices();
        this.props.dashboardAction.checklist();
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const user_details: any = data && data.user_ref;
            this.setState({
                firstName: user_details && user_details.first_name,
                middleName: user_details && user_details.middle_name,
                lastName: user_details && user_details.last_name,
                userId: user_details && user_details.id,
                phone: user_details && user_details.phone,
                phone_verified: user_details && user_details.phone_verified,
                curHomeStreet:
                    data && data.source_street && data.source_street.split('$').join('')
                        ? data.source_street.split('$').join('')
                        : '',
                curHomeDetail: data && data.source_detail,
                futureHomeStreet:
                    data && data.destination_street && data.destination_street.split('$').join('')
                        ? data.destination_street.split('$').join('')
                        : '',
                futureHomeDetail: data && data.destination_detail,
                startDate: data && data.moving_date,
                endDate: data && data.moving_end_date,
                move_id: data && data.id,
            });
        } else {
            this.props.commonAction.moveGet();
        }

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Update Address',
                    category_name: 'Update Address',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isHashDataArrived
        ) {
            currentState.isHashDataArrived = false;

            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                nextProps.updateAddressAction.requestsFiled();
                nextProps.updateAddressAction.newServices();
                nextProps.dashboardAction.checklist();
                nextProps.commonAction.moveGet();
                const isHomeOwner = localStorage.getItem('isHomeOwner');
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);

                nextProps.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0]
                        ? window.location.hostname.split('.')[0]
                        : slug && slug.slug,
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }
        if (nextProps && nextProps.UpdateAddressState) {
            if (nextProps.UpdateAddressState.isUpdateResponseLoading) {
                currentState.updateResponseLoading = true;
            } else if (
                !nextProps.UpdateAddressState.isUpdateResponseLoading &&
                nextProps.UpdateAddressState.updateResponse &&
                !(
                    Object.keys(nextProps.UpdateAddressState.updateResponse).length === 0 &&
                    nextProps.UpdateAddressState.updateResponse.constructor === Object
                ) &&
                nextProps.UpdateAddressState.isResponseUpdated
            ) {
                currentState.updateResponseLoading = false;
                currentState.responseUpdated = true;
                nextProps.UpdateAddressState.isResponseUpdated = false;
                nextProps.updateAddressAction.requestsFiled();
            } else if (
                nextProps.UpdateAddressState.updateResponseError &&
                !nextProps.UpdateAddressState.isUpdateResponseLoading
            ) {
                currentState.updateResponseLoading = false;
                nextProps.UpdateAddressState.updateResponseError = null;
            }
            if (nextProps.UpdateAddressState.isSaveResponseLoading) {
                currentState.saveResponseLoading = true;
            } else if (
                nextProps.UpdateAddressState.saveResponse &&
                !(
                    Object.keys(nextProps.UpdateAddressState.saveResponse).length === 0 &&
                    nextProps.UpdateAddressState.saveResponse.constructor === Object
                ) &&
                !nextProps.UpdateAddressState.isSaveResponseLoading &&
                nextProps.UpdateAddressState.isResponseSaved
            ) {
                currentState.requestSubmitted = true;
                currentState.saveResponseLoading = false;
                nextProps.UpdateAddressState.isResponseSaved = false;
            } else if (
                nextProps.UpdateAddressState.saveResponseError &&
                !nextProps.UpdateAddressState.isSaveResponseLoading
            ) {
                currentState.saveResponseLoading = false;
                nextProps.UpdateAddressState.saveResponseError = null;
            }
            if (nextProps.UpdateAddressState.isDelReqLoading) {
                currentState.deleteLoading = true;
            } else if (!nextProps.UpdateAddressState.isDelReqLoading && nextProps.UpdateAddressState.isFileDeleted) {
                currentState.deleteLoading = false;
                nextProps.UpdateAddressState.isFileDeleted = false;
                nextProps.updateAddressAction.requestsFiled();
            } else if (
                !nextProps.UpdateAddressState.isDelReqLoading &&
                nextProps.UpdateAddressState.deleteRequestsFiledError
            ) {
                currentState.deleteLoading = false;
                nextProps.UpdateAddressState.deleteRequestsFiledError = null;
            }
            if (nextProps.UpdateAddressState.isNewServiceLoading) {
                currentState.newServiceLoading = true;
            } else if (
                nextProps.UpdateAddressState.newServices &&
                nextProps.UpdateAddressState.newServices.length &&
                !nextProps.UpdateAddressState.isNewServiceLoading
            ) {
                currentState.newServiceLoading = false;
                let array: any[] = [];

                nextProps.UpdateAddressState.newServices.map((item: any) => {
                    if (item && item.do_it_now && item.do_it_now.new_url.includes('/dashboard/address')) {
                    } else {
                        let obj = {
                            title: item.name,
                            subTitle: item.description_short,
                            icon: item.new_image && item.new_image.image_svg_string,
                            buttonText: item.do_it_now && item.do_it_now.heading,
                            url: item.do_it_now && item.do_it_now.new_url,
                        };
                        array.push(obj);
                    }
                });
                currentState.newServicesData = array;
            } else if (!nextProps.UpdateAddressState.isNewServiceLoading && nextProps.UpdateAddressState.error) {
                currentState.newServiceLoading = false;
                nextProps.UpdateAddressState.error = null;
            }
            if (nextProps.UpdateAddressState.isPhoneVerifyLoading) {
                currentState.phoneVerifyLoading = true;
            } else if (
                nextProps.UpdateAddressState.phoneVerified &&
                nextProps.UpdateAddressState.phoneVerified.message === 'SuccessFully Update' &&
                !nextProps.UpdateAddressState.isPhoneVerifyLoading
            ) {
                currentState.phoneVerifyLoading = false;

                if (
                    nextProps.UpdateAddressState.phoneVerified.sms_code &&
                    nextProps.UpdateAddressState.phoneVerified.message === 'SuccessFully Update'
                ) {
                    currentState.showVerificationModal = false;
                    currentState.verficationCodeSent = true;
                    currentState.sms_code = nextProps.UpdateAddressState.phoneVerified.sms_code;
                    nextProps.UpdateAddressState.phoneVerified.message = 'Done';
                }
            } else if (
                !nextProps.UpdateAddressState.isPhoneVerifyLoading &&
                nextProps.UpdateAddressState.phoneVerifiedError
            ) {
                currentState.phoneVerifyLoading = false;
                nextProps.UpdateAddressState.phoneVerifiedError = null;
            }
            if (nextProps.UpdateAddressState.isOtpVerifyLoading) {
                currentState.otpVerifyLoading = true;
            } else if (
                nextProps.UpdateAddressState.otpVerified &&
                nextProps.UpdateAddressState.otpVerified.message === 'Successfully Verified' &&
                !nextProps.UpdateAddressState.isOtpVerifyLoading
            ) {
                currentState.otpVerifyLoading = false;

                if (
                    nextProps.UpdateAddressState.otpVerified &&
                    nextProps.UpdateAddressState.otpVerified.message === 'Successfully Verified'
                ) {
                    currentState.verficationCodeSent = false;
                    currentState.otpVerification = true;
                    nextProps.UpdateAddressState.otpVerified.message = 'Done';
                    if (currentState.futureAddress && currentState.futureAddress == 'permanent') {
                        nextProps.updateAddressAction.saveResponse({
                            first_name: currentState.firstName,
                            middle_name: currentState.middleName,
                            last_name: currentState.lastName,
                            moving_type: currentState.futureAddress == 'permanent' ? 1 : 0,
                            moving_date:
                                typeof currentState.startDate == 'string'
                                    ? currentState.startDate
                                    : currentState.startDate.toISOString().split('T')[0],
                            source_street:
                                currentState.curHomeStreet && currentState.curHomeStreet.split('$').join('')
                                    ? currentState.curHomeStreet.split('$').join('')
                                    : '',
                            destination_street:
                                currentState.futureHomeStreet && currentState.futureHomeStreet.split('$').join('')
                                    ? currentState.futureHomeStreet.split('$').join('')
                                    : '',
                            source_city_state: currentState.curHomeDetail,
                            destination_city_state: currentState.futureHomeDetail,
                            source_apt: currentState.curHomeApt,
                            destination_apt: currentState.futureHomeApt,
                            house_type: 0,
                            move_id: currentState.move_id,
                            people_type: currentState.option,
                        });
                    } else {
                        nextProps.updateAddressAction.saveResponse({
                            first_name: currentState.firstName,
                            middle_name: currentState.middleName,
                            last_name: currentState.lastName,
                            moving_type: currentState.futureAddress == 'permanent' ? 1 : 0,
                            moving_date:
                                typeof currentState.startDate == 'string'
                                    ? currentState.startDate
                                    : currentState.startDate.toISOString().split('T')[0],
                            source_street:
                                currentState.curHomeStreet && currentState.curHomeStreet.split('$').join('')
                                    ? currentState.curHomeStreet.split('$').join('')
                                    : '',
                            destination_street:
                                currentState.futureHomeStreet && currentState.futureHomeStreet.split('$').join('')
                                    ? currentState.futureHomeStreet.split('$').join('')
                                    : '',
                            source_city_state: currentState.curHomeDetail,
                            destination_city_state: currentState.futureHomeDetail,
                            source_apt: currentState.curHomeApt,
                            destination_apt: currentState.futureHomeApt,
                            house_type: 0,
                            move_id: currentState.move_id,
                            people_type: currentState.option,
                            moving_end_date:
                                typeof currentState.endDate == 'string'
                                    ? currentState.endDate
                                    : currentState.endDate.toISOString().split('T')[0],
                        });
                    }
                }
            } else if (
                !nextProps.UpdateAddressState.isOtpVerifyLoading &&
                nextProps.UpdateAddressState.otpVerifiedError
            ) {
                currentState.otpVerifyLoading = false;
                nextProps.UpdateAddressState.otpVerifiedError = null;
            }
            if (nextProps.UpdateAddressState.isReqFiledLoading) {
                currentState.reqFiledLoading = true;
            } else if (
                nextProps.UpdateAddressState.requestsFiled &&
                nextProps.UpdateAddressState.requestsFiled.length !== 0 &&
                !nextProps.UpdateAddressState.isReqFiledLoading
            ) {
                currentState.reqFiledLoading = false;
                currentState.filedRequestArr = nextProps.UpdateAddressState.requestsFiled;
            } else if (
                nextProps.UpdateAddressState.requestsFiled &&
                nextProps.UpdateAddressState.requestsFiled.length == 0 &&
                !nextProps.UpdateAddressState.isReqFiledLoading
            ) {
                currentState.reqFiledLoading = false;
                currentState.filedRequestArr = [];
            } else if (
                !nextProps.UpdateAddressState.isReqFiledLoading &&
                nextProps.UpdateAddressState.requestsFiledError
            ) {
                currentState.reqFiledLoading = false;
                nextProps.UpdateAddressState.requestsFiledError = null;
            }
        }

        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length &&
            currentState.isMovingDataArrived
        ) {
            currentState.isMovingDataArrived = false;
            currentState.firstName = nextProps.commonState.move[0].user_ref.first_name;
            currentState.middleName = nextProps.commonState.move[0].user_ref.middle_name;
            currentState.lastName = nextProps.commonState.move[0].user_ref.last_name;
            currentState.userId = nextProps.commonState.move[0].user_ref.id;
            currentState.phone = nextProps.commonState.move[0].user_ref.phone;
            currentState.phone_verified = nextProps.commonState.move[0].user_ref.phone_verified;
            currentState.curHomeStreet =
                nextProps.commonState.move[0].source_street &&
                nextProps.commonState.move[0].destination_street.split('$').join('')
                    ? nextProps.commonState.move[0].destination_street.split('$').join('')
                    : '';
            currentState.curHomeDetail = nextProps.commonState.move[0].source_detail;
            currentState.futureHomeStreet =
                nextProps.commonState.move[0].destination_street &&
                nextProps.commonState.move[0].destination_street.split('$').join('')
                    ? nextProps.commonState.move[0].destination_street.split('$').join('')
                    : '';
            currentState.futureHomeDetail = nextProps.commonState.move[0].destination_detail;
            currentState.startDate = nextProps.commonState.move[0].moving_date;
            currentState.endDate = nextProps.commonState.move[0].moving_end_date;
            currentState.move_id = nextProps.commonState.move[0].id;
        }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            updateChecklistTask(50, nextProps.dashboardAction);
        }
        return currentState;
    }

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    onChangeHandler = (e: any) => {
        let phonenumber = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phonenumber,
            showPhoneError: false,
        });
    };

    onOTPChangeHandler = (e: any) => {
        this.setState({
            otp: e.target.value,
            showOtpError: false,
        });
    };

    handleRadioChange = (e: any) => {
        this.setState({ option: e.target.value });
        // this.calculatePercentage();
    };

    handleRadioBoxChange = (e: any) => {
        this.setState({
            option: e,
        });
        // this.calculatePercentage();
    };

    handleFutureAddRadioChange = (e: any) => {
        this.setState({ futureAddress: e.target.value });
        // this.calculatePercentage();
    };

    handleContactChange = (e: any, name: string) => {
        this.setState({
            ...this.state,
            [name]: e.target.value,
        });
    };

    calculatePercentage = () => {
        updateChecklistTask(100, this.props.dashboardAction);
    };

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        curHomeStreet: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                    });
                    // this.calculatePercentage();
                }
            })
            .catch(error => console.error('error', error));
    };

    handleSelect2 = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                if (country === 'USA' || country === 'US') {
                    this.setState({
                        futureHomeStreet: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                    });
                    // this.calculatePercentage();
                }
            })
            .catch(error => console.error('error', error));
    };

    startDateHandler = (e: any) => {
        this.setState({
            startDate: e,
            isStartDateOpen: false,
        });
        // this.calculatePercentage();
    };

    endDateHandler = (e: any) => {
        this.setState({
            endDate: e,
            isEndDateOpen: false,
        });
        // this.calculatePercentage();
    };

    handleAddRequest = () => {
        this.setState({
            addRequest: true,
        });
    };

    handleEditRequest = (item: any) => {
        this.setState({
            editRequest: true,
            editId: item && item.id,
            option: item && item.people_type,
            firstName: item && item.first_name,
            middleName: item && item.middle_name,
            lastName: item && item.last_name,
            curHomeStreet:
                item && item.source_street && item.source_street.split('$').join('')
                    ? item.source_street.split('$').join('')
                    : '',
            curHomeApt: item && item.source_apt,
            curHomeDetail: item && item.source_city_state,
            futureHomeStreet:
                item && item.destination_street && item.destination_street.split('$').join('')
                    ? item.destination_street.split('$').join('')
                    : '',
            futureHomeApt: item && item.destination_apt,
            futureHomeDetail: item && item.destination_city_state,
            futureAddress: item && item.moving_type == 1 ? 'permanent' : 'temporary',
            startDate: item && item.moving_date,
            endDate: item && item.moving_end_date,
        });
        // this.calculatePercentage();
    };

    showDeleteModal = (item: any) => {
        this.setState({
            showDelModal: !this.state.showDelModal,
            delId: item.id,
        });
    };

    handleCloseDelModal = () => {
        this.setState({
            showDelModal: false,
            delId: '',
        });
    };

    handleDeleteRequest = () => {
        const { delId } = this.state;
        this.props.updateAddressAction.delRequestsFiled({
            id: delId,
        });
        this.setState({
            showDelModal: false,
            delId: '',
        });
    };

    handleGoToMyRequests = () => {
        this.setState({
            goToMyRequests: true,
        });
    };

    handleSubmit = () => {
        const {
            phone_verified,
            firstName,
            middleName,
            lastName,
            startDate,
            endDate,
            curHomeStreet,
            curHomeDetail,
            curHomeApt,
            futureHomeStreet,
            futureHomeDetail,
            futureHomeApt,
            option,
            move_id,
            futureAddress,
            editRequest,
            editId,
        } = this.state;
        this.setState({
            submitClick: true,
        });
        if (
            move_id &&
            curHomeStreet &&
            curHomeDetail &&
            // curHomeApt &&
            futureHomeStreet &&
            futureHomeDetail &&
            // futureHomeApt &&
            firstName &&
            // lastName &&
            startDate &&
            // endDate &&
            option &&
            futureAddress
        ) {
            if (phone_verified) {
                if (editRequest) {
                    if (futureAddress == 'permanent') {
                        this.props.updateAddressAction.updateResponse({
                            data: {
                                first_name: firstName,
                                middle_name: middleName,
                                last_name: lastName,
                                moving_type: futureAddress == 'permanent' ? 1 : 0,
                                moving_date:
                                    typeof startDate == 'string' ? startDate : startDate.toISOString().split('T')[0],
                                source_street:
                                    curHomeStreet && curHomeStreet.split('$').join('')
                                        ? curHomeStreet.split('$').join('')
                                        : '',
                                destination_street:
                                    futureHomeStreet && futureHomeStreet.split('$').join('')
                                        ? futureHomeStreet.split('$').join('')
                                        : '',
                                source_city_state: curHomeDetail,
                                destination_city_state: futureHomeDetail,
                                source_apt: curHomeApt,
                                destination_apt: futureHomeApt,
                                house_type: 0,
                                move_id: move_id,
                                people_type: option,
                            },
                            id: editId,
                        });
                    } else {
                        this.props.updateAddressAction.updateResponse({
                            data: {
                                first_name: firstName,
                                middle_name: middleName,
                                last_name: lastName,
                                moving_type: futureAddress == 'permanent' ? 1 : 0,
                                moving_date:
                                    typeof startDate == 'string' ? startDate : startDate.toISOString().split('T')[0],
                                source_street:
                                    curHomeStreet && curHomeStreet.split('$').join('')
                                        ? curHomeStreet.split('$').join('')
                                        : '',
                                destination_street:
                                    futureHomeStreet && futureHomeStreet.split('$').join('')
                                        ? futureHomeStreet.split('$').join('')
                                        : '',
                                source_city_state: curHomeDetail,
                                destination_city_state: futureHomeDetail,
                                source_apt: curHomeApt,
                                destination_apt: futureHomeApt,
                                house_type: 0,
                                move_id: move_id,
                                people_type: option,
                                moving_end_date:
                                    typeof endDate == 'string' ? endDate : endDate.toISOString().split('T')[0],
                            },
                            id: editId,
                        });
                    }
                } else {
                    if (futureAddress == 'permanent') {
                        this.props.updateAddressAction.saveResponse({
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            moving_type: futureAddress == 'permanent' ? 1 : 0,
                            moving_date:
                                typeof startDate == 'string' ? startDate : startDate.toISOString().split('T')[0],
                            source_street:
                                curHomeStreet && curHomeStreet.split('$').join('')
                                    ? curHomeStreet.split('$').join('')
                                    : '',
                            destination_street:
                                futureHomeStreet && futureHomeStreet.split('$').join('')
                                    ? futureHomeStreet.split('$').join('')
                                    : '',
                            source_city_state: curHomeDetail,
                            destination_city_state: futureHomeDetail,
                            source_apt: curHomeApt,
                            destination_apt: futureHomeApt,
                            house_type: 0,
                            move_id: move_id,
                            people_type: option,
                        });
                    } else {
                        this.props.updateAddressAction.saveResponse({
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            moving_type: futureAddress == 'permanent' ? 1 : 0,
                            moving_date:
                                typeof startDate == 'string' ? startDate : startDate.toISOString().split('T')[0],
                            source_street:
                                curHomeStreet && curHomeStreet.split('$').join('')
                                    ? curHomeStreet.split('$').join('')
                                    : '',
                            destination_street:
                                futureHomeStreet && futureHomeStreet.split('$').join('')
                                    ? futureHomeStreet.split('$').join('')
                                    : ' ',
                            source_city_state: curHomeDetail,
                            destination_city_state: futureHomeDetail,
                            source_apt: curHomeApt,
                            destination_apt: futureHomeApt,
                            house_type: 0,
                            move_id: move_id,
                            people_type: option,
                            moving_end_date: typeof endDate == 'string' ? endDate : endDate.toISOString().split('T')[0],
                        });
                    }
                }
            } else {
                this.setState({
                    showVerificationModal: true,
                });
            }
            this.setState({
                editRequest: false,
                addRequest: false,
            });
        }
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;

        buttonClicked(
            'Button Clicked',
            'Update Address',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Update Address',
            'Submit',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            true,
            1,
            '',
            move_id,
        );
    };

    closeVerificationModal = () => {
        this.setState({
            showVerificationModal: false,
        });
    };

    closeOTPModal = () => {
        this.setState({
            verficationCodeSent: false,
        });
    };

    closeMyRequestsModal = () => {
        this.setState({
            otpVerification: false,
            requestSubmitted: false,
        });
        this.props.updateAddressAction.requestsFiled();
    };

    sendVerificationCode = () => {
        const { phone, userId } = this.state;
        if (phone == '' || phone.length == 0) {
            this.setState({
                showPhoneError: true,
                phoneError: 'Please enter Phone number',
            });
        } else if (phone.split('-').join('').length != 10) {
            this.setState({
                showPhoneError: true,
                phoneError: 'Phone number should contain 10 digits',
            });
        } else {
            this.props.updateAddressAction.phoneVerify({
                data: { phone: phone, type: 'sms' },
                userId: userId,
            });
        }
    };

    sendVerificationCodeViaCall = () => {
        const { phone, userId } = this.state;
        if (phone == '' || phone.length == 0) {
            this.setState({
                showPhoneError: true,
                phoneError: 'Please enter Phone number',
            });
        } else if (phone.split('-').join('').length != 10) {
            this.setState({
                showPhoneError: true,
                phoneError: 'Phone number should contain 10 digits',
            });
        } else {
            this.props.updateAddressAction.phoneVerify({
                data: { phone: phone, type: 'phone' },
                userId: userId,
            });
        }
    };

    sendOTP = () => {
        const { otp, userId, sms_code } = this.state;
        if (otp == '') {
            this.setState({
                showOtpError: true,
                otpError: 'Please enter otp',
            });
        } else if (otp == sms_code) {
            this.props.updateAddressAction.otpVerify({
                data: { code: otp },
                userId: userId,
            });
        } else {
            this.setState({
                showOtpError: true,
                otpError: 'Please enter correct otp',
            });
        }
    };

    handleResendText = () => {
        // const { otp } = this.state;
        // this.props.updateAddressAction.otpVerify({
        //     data: { phone: otp },
        //     type: 'phone',
        // });
        const { phone, userId } = this.state;
        this.props.updateAddressAction.phoneVerify({
            data: { phone: phone, type: 'sms' },
            userId: userId,
        });
    };

    handleIndividualExceptions = () => {
        this.setState({
            individualExceptions: !this.state.individualExceptions,
        });
    };

    handleFamilyExceptions = () => {
        this.setState({
            familyExceptions: !this.state.familyExceptions,
        });
    };

    serviceCardHandler = (url: any) => {
        if (url.includes('/dashboard')) {
            let urlString = url.split('/');
            let new_url = `/${urlString[urlString.length - 2]}/${urlString[urlString.length - 1]}`;
            this.props.history.push(new_url);
        } else {
            window.open(url, '_blank');
        }
    };

    render() {
        const {
            option,
            firstName,
            middleName,
            lastName,
            curHomeStreet,
            curHomeDetail,
            futureHomeStreet,
            futureHomeDetail,
            futureHomeApt,
            curHomeApt,
            futureAddress,
            startDate,
            endDate,
            addRequest,
            editRequest,
            showDelModal,
            goToMyRequests,
            showVerificationModal,
            newServicesData,
            individualExceptions,
            familyExceptions,
            verficationCodeSent,
            otp,
            otpVerification,
            filedRequestArr,
            newServiceLoading,
            reqFiledLoading,
            saveResponseLoading,
            requestSubmitted,
            deleteLoading,
            updateResponseLoading,
            isDouglasElliman,
        } = this.state;
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        const breadList = [
            {
                name: 'Update Address',
                url: ''
            }
        ]

        return (
            <div>
                {isDouglasElliman && (
                    <div>
                        <TemplateUpper
                            pageImage="updateAddress"
                            headerText={`Updating`}
                            subheaderText={'Forward your mail in minutes'}
                            highlightedText={'Your Address'}
                        />
                    </div>
                )}
                <div
                    className={
                        isDouglasElliman
                            ? 'UpdateAddressMain UpdateAddressMainRemoveDouglas'
                            : 'UpdateAddressMain UpdateAddressMainRemoveDouglas'
                    }
                >
                    {!isDouglasElliman && (
                        <div>
                            {' '}
                            <h2 className="UpdateAddressheading">Forward Your Mail</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                    )}
                    {showDelModal && (
                        <DeleteRequestModal
                            showVerificationModal={showDelModal}
                            handleCloseModal={this.handleCloseDelModal}
                            confirmText="Are you sure you want to delete this request?"
                            btnText1="Cancel"
                            btnColor1={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnText2="Delete"
                            onDelete={this.handleDeleteRequest}
                            isDouglasElliman={isDouglasElliman}
                        />
                    )}
                    {showVerificationModal && (
                        <VerificationModal
                            showVerificationModal={showVerificationModal}
                            handleCloseModal={this.closeVerificationModal}
                            heading="Is it really you?"
                            subHeading="Please provide your phone number to confirm your identity."
                            btnText="Send Verification Code"
                            btnBackgroundColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnBorderColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            label="Your phone number"
                            value={this.state.phone}
                            onChangeHandler={(e: any) => this.onChangeHandler(e)}
                            onSubmitHandler={this.sendVerificationCode}
                            onSubmitViaCall={this.sendVerificationCodeViaCall}
                            link=""
                            btnWidth="225px"
                            showError={this.state.showPhoneError}
                            errorMsg={this.state.phoneError}
                            isDouglasElliman={isDouglasElliman}
                        />
                    )}
                    {verficationCodeSent && (
                        <VerificationModal
                            showVerificationModal={verficationCodeSent}
                            handleCloseModal={this.closeOTPModal}
                            heading="Enter OTP"
                            subHeading="Please provide OTP sent to your phone To verify"
                            btnText="Verify Now"
                            label="Enter Verification Code"
                            value={otp}
                            onChangeHandler={(e: any) => this.onOTPChangeHandler(e)}
                            link="Resend Text"
                            onSubmitHandler={this.sendOTP}
                            onSubmitViaCall={this.handleResendText}
                            btnBackgroundColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnBorderColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnWidth="139px"
                            showError={this.state.showOtpError}
                            errorMsg={this.state.otpError}
                            isDouglasElliman={isDouglasElliman}
                        />
                    )}
                    {otpVerification || requestSubmitted ? (
                        <SubmitRequestModal
                            showVerificationModal={otpVerification || requestSubmitted}
                            handleCloseModal={this.closeMyRequestsModal}
                            successText="Your mail forwarding request is submitted, we will let you know for further updates"
                            btnText="My Requests"
                            btnBackgroundColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnBorderColor={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                            btnWidth="153px"
                            isDouglasElliman={isDouglasElliman}
                        />
                    ) : null}
                    {reqFiledLoading || saveResponseLoading || deleteLoading || updateResponseLoading ? (
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (!reqFiledLoading && filedRequestArr.length && !addRequest && !editRequest) ||
                      (goToMyRequests && filedRequestArr.length) ? (
                        <div className="updateAddFiledReq">
                            <div className="updateAddFiledReqFirstRow">
                                <div className="updateAddFiledReqText">Filed Requests</div>
                                <div className="updateAddFiledReqAddRequest" onClick={() => this.handleAddRequest()}>
                                    + Add Request
                                </div>
                            </div>
                            <div className="updateAddFiledReqContainer">
                                {filedRequestArr &&
                                    filedRequestArr.map((item: any, index: any) => {
                                        return (
                                            <div className="updateAddFiledReqCard" key={index}>
                                                <div className="updateAddFiledReqFirstRow">
                                                    <div className="updateAddFiledReqRow">
                                                        <div className="updateAddFiledReqName">{item.first_name}</div>
                                                        <div className="updateAddFiledReqStatus">
                                                            {item.filled_status}
                                                        </div>
                                                    </div>

                                                    <div className="updateAddFiledReqRow">
                                                        <div
                                                            className="updateAddFiledReqEdit"
                                                            onClick={() => this.handleEditRequest(item)}
                                                        >
                                                            <EditOutlinedIcon
                                                                style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    color: '#6B6C6F',
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="updateAddFiledReqDel"
                                                            onClick={() => this.showDeleteModal(item)}
                                                        >
                                                            <DeleteOutlineOutlinedIcon
                                                                style={{
                                                                    width: '15px',
                                                                    height: '15px',
                                                                    color: '#6B6C6F',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="updateAddFiledReqSecondRow">
                                                    <div className="updateAddFiledReqContent">
                                                        <div className="updateAddFiledReqHeading">
                                                            Mail forwarding type
                                                        </div>
                                                        <div className="updateAddFiledReqValue">{item.people_type}</div>
                                                    </div>

                                                    <div className="updateAddFiledReqContent">
                                                        <div className="updateAddFiledReqHeading">Current Home</div>
                                                        <div className="updateAddFiledReqValue">
                                                            {item.source_address_full_detail}
                                                        </div>
                                                    </div>

                                                    <div className="updateAddFiledReqContent">
                                                        <div className="updateAddFiledReqHeading">Future Home</div>
                                                        <div className="updateAddFiledReqValue">
                                                            {item.destination_address_full_detail}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="updateAddFiledReqSecondRow">
                                                    <div className="updateAddFiledReqContent">
                                                        <div className="updateAddFiledReqHeading">Move type</div>
                                                        <div className="updateAddFiledReqValue">
                                                            {item.moving_type === 1 ? 'Permanent' : 'Temporary'}
                                                        </div>
                                                    </div>

                                                    <div className="updateAddFiledReqContent">
                                                        <div className="updateAddFiledReqHeading">Start Date</div>
                                                        <div className="updateAddFiledReqValue">{item.moving_date}</div>
                                                    </div>

                                                    {item.moving_type === 0 && (
                                                        <div className="updateAddFiledReqContent">
                                                            <div className="updateAddFiledReqHeading">End Date</div>
                                                            <div className="updateAddFiledReqValue">
                                                                {item.moving_end_date}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            {newServiceLoading ? (
                                <div
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="updateAddNewServices">
                                    <div className="updateAddOurNewServices">Our New Services</div>
                                    <div className="updateAddOurNewServicesRow">
                                        {newServicesData.map((item: any, i: number) => {
                                            return (
                                                <div key={i} className="updateAddServicesCard">
                                                    <div>
                                                        <div className="updateAddServicesCardHeading">{item.title}</div>
                                                        <div className="updateAddServicesCardDesc">{item.subTitle}</div>
                                                        <div className="updateAddServicesCardBtn">
                                                            <Button
                                                                onClick={() => {
                                                                    this.serviceCardHandler(item.url);
                                                                }}
                                                                width="150px !important"
                                                                height="40px !important"
                                                                border={
                                                                    isDouglasElliman
                                                                        ? '1px solid #100B28'
                                                                        : '1px solid #002E62'
                                                                }
                                                                box-shadow={
                                                                    isDouglasElliman
                                                                        ? 'none'
                                                                        : '0 1px 1px #161d251a !important'
                                                                }
                                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                                backgroundColor="transparent"
                                                                fontWeight="600"
                                                                color={isDouglasElliman ? '#100B28' : '#002E62'}
                                                                fontSize={window.innerWidth <= 600 ? '14px' : '16px'}
                                                            >
                                                                {item.buttonText}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="updateAddServicesCardImg">
                                                        {item && item.icon && item.icon.includes('<svg') ? (
                                                            <div
                                                                className="updateAddsvgIcon"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.icon,
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="updateAddServicesImg"
                                                                src={item.icon}
                                                                height="80%"
                                                                width="80%"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : addRequest || editRequest || !filedRequestArr.length ? (
                        <div
                            className={
                                isDouglasElliman
                                    ? 'updateAddMailForwarding removeMarginTopAddress'
                                    : 'updateAddMailForwarding'
                            }
                        >
                            <div
                                className="updateAddMailForwardingHeading"
                                style={{
                                    backgroundColor: isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59',
                                }}
                            >
                                <img src={Mail} width="24px" height="24px" alt=""/>
                                <div className="updateAddMailForwardingText">This Mail Forwarding Is For</div>
                            </div>

                            <div
                                className={
                                    isDouglasElliman
                                        ? 'updateAddMailForwardingBody updateAddMailForwardingBodyAddColor'
                                        : 'updateAddMailForwardingBody'
                                }
                            >
                                <div>
                                    <RadioGroup
                                        aria-label="mail-forwarding"
                                        name="mail-forwarding"
                                        className="updateAddRadio"
                                        value={option}
                                        onChange={this.handleRadioChange}
                                    >
                                        <div
                                            onClick={() => this.handleRadioBoxChange('Individual')}
                                            className={
                                                option == 'Individual'
                                                    ? isDouglasElliman
                                                        ? 'updateAddRadioWrapperActive changeColorUpdateAddress'
                                                        : 'updateAddRadioWrapperActive'
                                                    : 'updateAddRadioWrapper'
                                            }
                                        >
                                            <FormControlLabel
                                                className="updateAddRadio1"
                                                value="Individual"
                                                control={<Radio />}
                                                label="INDIVIDUAL"
                                            />
                                            <div className="updateAddRadioDesc">
                                                It’s just you, complete this form and select this option
                                            </div>
                                            <div
                                                className="updateAddRadioSeeExceptions"
                                                onClick={() => this.handleIndividualExceptions()}
                                            >
                                                See Exceptions
                                            </div>
                                            {individualExceptions && (
                                                <div className="updateAddRadioDesc" style={{ paddingTop: '6px' }}>
                                                    If you receive mail by more than one name (i.e. maiden name or
                                                    nickname), complete a separate form for each name and select this
                                                    section.
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            onClick={() => this.handleRadioBoxChange('Family')}
                                            className={
                                                option == 'Family'
                                                    ? isDouglasElliman
                                                        ? 'updateAddRadioWrapperActive changeColorUpdateAddress'
                                                        : 'updateAddRadioWrapperActive'
                                                    : 'updateAddRadioWrapper'
                                            }
                                        >
                                            <FormControlLabel
                                                className="updateAddRadio1"
                                                value="Family"
                                                control={<Radio />}
                                                label="FAMILY"
                                            />
                                            <div className="updateAddRadioDesc">
                                                If everyone in family has the same last name, complete this form and
                                                select this Option
                                            </div>
                                            <div
                                                className="updateAddRadioSeeExceptions"
                                                onClick={() => this.handleFamilyExceptions()}
                                            >
                                                See Exceptions
                                            </div>
                                            {familyExceptions && (
                                                <div className="updateAddRadioDesc" style={{ paddingTop: '6px' }}>
                                                    If not all family members are moving, OR if some family members have
                                                    different last names, complete a separate form for each mover and
                                                    select the INDIVIDUAL section.
                                                </div>
                                            )}
                                        </div>
                                    </RadioGroup>
                                    {this.state.submitClick && this.state.option == '' ? (
                                        <div className="updateAddRadioError">Please select</div>
                                    ) : null}
                                </div>

                                <div className="updateAddContactInfo">
                                    <div className="updateAddContactInfoHeading">Contact Information</div>
                                    <div className="updateAddContactInfoContent">
                                        <div className="updateAddContactInfoTextbox">
                                            <TextField
                                                className={
                                                    isDouglasElliman
                                                        ? 'updateAddInput1 updateAddInput1Doug'
                                                        : 'updateAddInput1'
                                                }
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                label="First Name"
                                                value={firstName}
                                                onChange={(e: any) => this.handleContactChange(e, 'firstName')}
                                                // onBlur={() => this.calculatePercentage()}
                                                error={this.state.submitClick && firstName === ''}
                                                helperText={
                                                    this.state.submitClick && firstName === ''
                                                        ? 'Please Enter First Name'
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div className="updateAddContactInfoTextbox">
                                            <TextField
                                                className={
                                                    isDouglasElliman
                                                        ? 'updateAddInput1 updateAddInput1Doug'
                                                        : 'updateAddInput1'
                                                }
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                value={middleName}
                                                label="Middle Name"
                                                onChange={(e: any) => this.handleContactChange(e, 'middleName')}
                                                // onBlur={() => this.calculatePercentage()}
                                            />
                                        </div>
                                        <div className="updateAddContactInfoTextbox">
                                            <TextField
                                                className={
                                                    isDouglasElliman
                                                        ? 'updateAddInput1 updateAddInput1Doug'
                                                        : 'updateAddInput1'
                                                }
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                value={lastName}
                                                label="Last Name"
                                                onChange={(e: any) => this.handleContactChange(e, 'lastName')}
                                                // onBlur={() => this.calculatePercentage()}
                                                // error={this.state.submitClick && lastName === ''}
                                                // helperText={
                                                //     this.state.submitClick && lastName === ''
                                                //         ? 'Please Enter Last Name'
                                                //         : ''
                                                // }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="updateAddDetails">
                                <div className="updateAddDetailsHeading">Your Address Details</div>
                                <div className="updateAddDetailsCardWrapper">
                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'updateAddDetailsCard updateAddDetailsCardDougRem'
                                                : 'updateAddDetailsCard'
                                        }
                                    >
                                        <div className="updateAddDetailsSubHeadingWrapper">
                                            <img src={CurrentHome} width="24px" height="24px" alt="" />
                                            <div className="updateAddDetailsSubHeading">Current Home</div>
                                        </div>

                                        <div className="updateAddRow">
                                            <div>
                                                <AutoComplete
                                                    className={
                                                        isDouglasElliman
                                                            ? 'updateAddStreet updateAddStreetDoug'
                                                            : 'updateAddStreet'
                                                    }
                                                    value={
                                                        curHomeStreet.split('$').join('')
                                                            ? curHomeStreet.split('$').join('')
                                                            : ''
                                                    }
                                                    onChange={e => {
                                                        this.setState({ curHomeStreet: e });
                                                    }}
                                                    onSelect={this.handleSelect}
                                                    placeholder="Street Name & Number"
                                                    error={this.state.submitClick && this.state.curHomeStreet === ''}
                                                    helperText={
                                                        this.state.submitClick && this.state.curHomeStreet === ''
                                                            ? 'Please enter street'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <div className="updateAddAptWrapper">
                                                <TextField
                                                    className={
                                                        isDouglasElliman
                                                            ? 'updateAddApt updateAddAptDoug'
                                                            : 'updateAddApt'
                                                    }
                                                    value={curHomeApt}
                                                    label="#Apt"
                                                    id="outlined-start-adornment"
                                                    onChange={(e: any) => this.handleContactChange(e, 'curHomeApt')}
                                                    variant="filled"
                                                    // onBlur={() => this.calculatePercentage()}
                                                    // error={this.state.submitClick && curHomeApt === ''}
                                                    // helperText={
                                                    //     this.state.submitClick && curHomeApt === ''
                                                    //         ? 'Please Enter Apt'
                                                    //         : ''
                                                    // }
                                                />
                                            </div>
                                        </div>

                                        <div className="updateAddAddress">{curHomeDetail}</div>
                                    </div>

                                    <div className="updateAddDetailsImgWrapper">
                                        {isDouglasElliman ? (
                                            <img src={Redirect} width="55px" height="55px" alt="" />
                                        ) : (
                                            <img src={Redirectblue} width="55px" height="55px" alt="" />
                                        )}
                                    </div>

                                    <div
                                        className={
                                            isDouglasElliman
                                                ? 'updateAddDetailsCard updateAddDetailsCardDougRem'
                                                : 'updateAddDetailsCard'
                                        }
                                    >
                                        <div className="updateAddDetailsSubHeadingWrapper">
                                            <img src={FutureHome} width="24px" height="24px" alt="" />
                                            <div className="updateAddDetailsSubHeading">Future Home</div>
                                        </div>

                                        <div className="updateAddRow">
                                            <div>
                                                <AutoComplete
                                                    className={
                                                        isDouglasElliman
                                                            ? 'updateAddStreet updateAddStreetDoug'
                                                            : 'updateAddStreet'
                                                    }
                                                    value={
                                                        futureHomeStreet.split('$').join('')
                                                            ? futureHomeStreet.split('$').join('')
                                                            : ''
                                                    }
                                                    onChange={e => {
                                                        this.setState({ futureHomeStreet: e });
                                                    }}
                                                    onSelect={this.handleSelect2}
                                                    placeholder="Street Name & Number"
                                                    error={this.state.submitClick && this.state.futureHomeStreet === ''}
                                                    helperText={
                                                        this.state.submitClick && this.state.futureHomeStreet === ''
                                                            ? 'Please enter street'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <div className="updateAddAptWrapper">
                                                <div>
                                                    <TextField
                                                        className={
                                                            isDouglasElliman
                                                                ? 'updateAddApt updateAddAptDoug'
                                                                : 'updateAddApt'
                                                        }
                                                        value={futureHomeApt}
                                                        label="#Apt"
                                                        id="outlined-start-adornment"
                                                        onChange={(e: any) =>
                                                            this.handleContactChange(e, 'futureHomeApt')
                                                        }
                                                        variant="filled"
                                                        // onBlur={() => this.calculatePercentage()}
                                                        // error={this.state.submitClick && futureHomeApt === ''}
                                                        // helperText={
                                                        //     this.state.submitClick && futureHomeApt === ''
                                                        //         ? 'Please Enter Apt'
                                                        //         : ''
                                                        // }
                                                    />
                                                </div>
                                                {/* <div className="updateAddRadioError">
                                                    {this.state.submitClick && futureHomeApt === ''
                                                        ? 'Please Enter Apt'
                                                        : ''}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="updateAddAddress">{futureHomeDetail}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="updateAddFutureAddress">
                                <div
                                    className="updateAddMailForwardingHeading"
                                    style={{
                                        backgroundColor: isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59',
                                    }}
                                >
                                    <img src={FutureAddress} width="24px" height="24px" alt="" />
                                    <div className="updateAddMailForwardingText">My Future Address Is</div>
                                </div>
                                <div
                                    className={
                                        isDouglasElliman
                                            ? 'updateAddMailForwardingBody updateAddMailForwardingBodyAddColor'
                                            : 'updateAddMailForwardingBody'
                                    }
                                >
                                    <div>
                                        <RadioGroup
                                            aria-label="mail-forwarding"
                                            name="mail-forwarding"
                                            className="updateAddRadio"
                                            value={futureAddress}
                                            onChange={this.handleFutureAddRadioChange}
                                        >
                                            <div
                                                className={
                                                    futureAddress == 'permanent'
                                                        ? isDouglasElliman
                                                            ? 'updateAddRadioWrapperActive changeColorUpdateAddress'
                                                            : 'updateAddRadioWrapperActive'
                                                        : 'updateAddRadioWrapper'
                                                }
                                            >
                                                <FormControlLabel
                                                    className="updateAddRadio1"
                                                    value="permanent"
                                                    control={<Radio />}
                                                    label="PERMANENT"
                                                />
                                                <div className="updateAddRadioFutureAddDesc">
                                                    Select this if you have no plan to move back to your old house
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    futureAddress == 'temporary'
                                                        ? isDouglasElliman
                                                            ? 'updateAddRadioWrapperActive changeColorUpdateAddress'
                                                            : 'updateAddRadioWrapperActive'
                                                        : 'updateAddRadioWrapper'
                                                }
                                            >
                                                <FormControlLabel
                                                    className="updateAddRadio1"
                                                    value="temporary"
                                                    control={<Radio />}
                                                    label="TEMPORARY"
                                                />
                                                <div className="updateAddRadioFutureAddDesc">
                                                    Select temporary if you plan to return to your old address within 12
                                                    months, Initial forwarding periods are restricted to 6 months, but
                                                    can be extended up to 1 year. The minimum length is 15 days
                                                </div>
                                            </div>
                                        </RadioGroup>
                                        {this.state.submitClick && this.state.futureAddress == '' ? (
                                            <div className="updateAddRadioError">Please select</div>
                                        ) : null}
                                    </div>
                                    <div className="updateAddDateWrapper">
                                        <div>
                                            <div className="updateAddStartDate">Start Date</div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Start"
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    className={
                                                        isDouglasElliman
                                                            ? 'updateAddDate1 updateAddDate1Doug'
                                                            : 'updateAddDate1'
                                                    }
                                                    minDate={startDate ? startDate : new Date()}
                                                    value={startDate}
                                                    onChange={(e: any) => {
                                                        this.startDateHandler(e);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                        onFocus: () => {
                                                            this.setState({ isStartDateOpen: true });
                                                        },
                                                    }}
                                                    autoOk={true}
                                                    PopoverProps={{
                                                        anchorOrigin: { horizontal: 'center', vertical: 'center' },
                                                        transformOrigin: {
                                                            horizontal: 'center',
                                                            vertical: 'center',
                                                        },
                                                        disableRestoreFocus: true,
                                                        onClose: () => {
                                                            this.setState({ isStartDateOpen: false });
                                                        },
                                                    }}
                                                    // InputProps={{
                                                    //     disableUnderline: true,
                                                    //     onFocus: () => {
                                                    //         this.setState({ isStartDateOpen: true });
                                                    //     },
                                                    // }}
                                                    error={
                                                        (this.state.submitClick && !this.state.startDate) ||
                                                        (this.state.startDate !== '' &&
                                                            !Date.parse(this.state.startDate))
                                                    }
                                                    // open={this.state.isStartDateOpen}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {futureAddress === 'temporary' ? (
                                            <div className="updateAddEndDate">
                                                <div className="updateAddStartDate">End Date</div>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        id="date"
                                                        inputVariant="filled"
                                                        label="End"
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        className={
                                                            isDouglasElliman
                                                                ? 'updateAddDate1 updateAddDate1Doug'
                                                                : 'updateAddDate1'
                                                        }
                                                        minDate={
                                                            new Date(
                                                                new Date().setDate(new Date(startDate).getDate() + 1),
                                                            )
                                                        }
                                                        value={endDate}
                                                        onChange={(e: any) => {
                                                            this.endDateHandler(e);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            onFocus: () => {
                                                                this.setState({ isEndDateOpen: true });
                                                            },
                                                        }}
                                                        autoOk={true}
                                                        PopoverProps={{
                                                            anchorOrigin: {
                                                                horizontal: 'center',
                                                                vertical: 'center',
                                                            },
                                                            transformOrigin: {
                                                                horizontal: 'center',
                                                                vertical: 'center',
                                                            },
                                                            disableRestoreFocus: true,
                                                            onClose: () => {
                                                                this.setState({ isEndDateOpen: false });
                                                            },
                                                        }}
                                                        // InputProps={{
                                                        //     disableUnderline: true,
                                                        //     onFocus: () => {
                                                        //         this.setState({ isEndDateOpen: true });
                                                        //     },
                                                        // }}
                                                        error={
                                                            (this.state.submitClick && !this.state.endDate) ||
                                                            (this.state.endDate !== '' &&
                                                                !Date.parse(this.state.endDate))
                                                        }
                                                        // open={this.state.isEndDateOpen}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="updateAddBtnWrapper">
                                <Button
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    borderRadius={isDouglasElliman ? '0px' : '4px'}
                                    // color="#fff"
                                    width={window.innerWidth <= 600 ? '100%' : '161px'}
                                    height="53px"
                                    fontSize="16px"
                                    fontWeight="800"
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateAddressAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    UpdateAddressState: state.updateAddress,
    commonState: state.common,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAddress);
