import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={676.5} height={267.929} viewBox="0 0 676.5 267.929">
            <defs>
                <style>
                    {`.HSMAinprefix__a{fill:${
                        slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'
                    }}.prefix__b{fill:#e0e0e0}.prefix__ac,.prefix__g,.HSMAinprefix__i,.prefix__j,.prefix__k,.prefix__l,.prefix__m,.prefix__p,.prefix__s,.prefix__x{fill-rule:evenodd}.prefix__d,.prefix__m{fill:#cccbc6}.prefix__f{fill:#fff}.prefix__g{fill:#e58174}.prefix__af,.HSMAinprefix__i{fill:${
                        slug && slug.color_shade2 ? slug.color_shade2 : '#162c47'
                    }}.prefix__j{fill:#bcbbb8}.prefix__k{fill:#464647}.prefix__l{fill:#f0bf97}.prefix__p{fill:#fefefe}.prefix__s{fill:#504b41}.HSMAinprefix__ae{fill:${
                        slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'
                    }}.prefix__x{fill:#333439}.prefix__am{fill:#ebebeb}.prefix__ac{fill:#f7f7f7}.prefix__ah{fill:#00d56d}.prefix__ai{fill:#00aa57}.prefix__aj{fill:#ffab00}.prefix__ak{fill:#ffc400}`}
                </style>
            </defs>
            <path className="HSMAinprefix__a" d="M8.5 255.929h668v12H8.5z" />
            <path className="prefix__b" d="M283.483 50.616h187.664v154.2H283.483z" />
            <path
                d="M283.494 211.749c-4.418.029-6.347-2.113-6.466-4.8v-1.6l200.155.053v1.308c-.13 2.844-2.191 5.026-5.936 5.034z"
                fillRule="evenodd"
                fill="#e0e0e0"
            />
            <path className="prefix__d" d="M277.027 204.797h200.155v1.911H277.027z" />
            <path fill="#f2f1ef" d="M373.339 60.628h8.864v133.946h-8.864z" />
            <path className="prefix__d" d="M377.711 56.278h88.265v142.977h-88.265z" />
            <path className="prefix__f" d="M384.208 63.689h75.412v128.154h-75.412zM294.389 59.4h78.9v136.733h-78.9z" />
            <path className="prefix__g" d="M325.183 242.448l-3.072 7.24-6.879-1.141 5-8.749z" />
            <path
                d="M324.93 248.496l-10.875-2.6-.663 1.826-1.179 1.9 1.121 1.252 4.251 6.079 9.812.865s.706-2.368.178-3.065c-.368-.487-4.183-3.574-4.183-3.574z"
                fill="#292d36"
                fillRule="evenodd"
            />
            <path
                className="HSMAinprefix__i"
                d="M326.632 181.913c4.073 6.753 16.157 20.947 16.718 29.333-2.864 8.728-16.535 30.255-16.535 30.255l-9.515-.765s13.779-22.252 17.861-28.81c-2.4-7.777-22.446-30.246-24.32-37.429a64.892 64.892 0 01-2.149-11.516c13.442 5.135 29.1 5.318 38.393 4.059-.126 2.688-.266 6.2-.416 8.292a51.619 51.619 0 01-3.7 5.484c-6.065.766-8.849 1.323-16.337 1.097z"
            />
            <path
                className="HSMAinprefix__i"
                d="M339.188 181.301l5.7-9.473c7.522 5.647 20.732 15.615 28.406 21.549l-.01 2.744-11.328.012z"
            />
            <path
                className="prefix__j"
                d="M371.732 153.386c-10.969-7.182-19.155-21.488-19.155-21.488l8.507-12.9a45.112 45.112 0 003.139 9.075c3.182 6.493 8.9 15.215 12.355 16.891z"
            />
            <path
                className="prefix__k"
                d="M369.77 137.505c-.774 0-4.828.154-6.754 3.333a7.714 7.714 0 00-1.251 3.635 55.309 55.309 0 004.876 4.943c.066-1.581 1.871-6.879 6.143-7.863a44.925 44.925 0 01-3.015-4.047zm-5.01-8.474c.311.64 1.333 2.6 2.918 5.249-4.32-.168-7.416 2.062-9.666 5.627q-1.65-2.147-3.1-4.375a6.869 6.869 0 012.8-4.652c2.47-1.827 7.049-1.849 7.049-1.849z"
            />
            <path
                className="prefix__j"
                d="M395.141 150.334c-1.232.2-3.9.6-5.195.8-7.575 1.152-15.773 2.2-18.457 1.838-.09-1.071-.9-1.181.172-3.857a28.539 28.539 0 014.237-4.509c6.443.185 12.737.3 19.175.238-.963 1.334-.502 3.651.068 5.49z"
            />
            <path
                className="prefix__k"
                d="M387.176 144.842a4.453 4.453 0 00-2.929 3.605 16.161 16.161 0 00.23 3.481l6.207-.915s-1.392-4.024.519-6.149zM375.922 144.601c-3.866 1.331-5.408 6.09-4.207 8.789l7.067-.773s-.962-5.283 2.407-7.875z"
            />
            <path
                className="prefix__l"
                d="M402.936 149.15l-7.855 1.2c-.818-.549-1.268-4.39-.234-5.5l8.078-.214a4.311 4.311 0 01.6 2.211 4.232 4.232 0 01-.589 2.303z"
            />
            <path
                className="prefix__m"
                d="M359.793 98.301c-1.539.38-2.277 4.1-3.715 4.562a67.284 67.284 0 00-16.063 8.155 70.929 70.929 0 00-14.895 15.071 81.2 81.2 0 00-8.886 15.9 165.823 165.823 0 00-7.669 23.2c7.294 4.511 38.555 3.88 38.635 1.62 0 0-.823-13.7 2.221-25.6 4.847-9.8 14.151-24.755 19.551-27.661 1.226-2.349-6.161-15.151-9.179-15.247z"
            />
            <path
                d="M367.023 113.302a3.159 3.159 0 011.916-1.654c-.667-2.747-3.175-7.382-5.558-10.4l-.157-.2-.175-.213c-1.237-1.5-2.416-2.506-3.256-2.533a1.277 1.277 0 00-.384.171 12.771 12.771 0 00-.25 3.442c.555 5.786 3.101 9.566 7.864 11.387z"
                fill="#7e8181"
                fillRule="evenodd"
            />
            <path
                className="prefix__k"
                d="M358.759 124.977a38.273 38.273 0 01-9.551-6.229c-6.527-6.032-7.024-9.19-7.024-9.19a41.843 41.843 0 00-5.979 4.585 27.423 27.423 0 009.233 9.693c3.553 2.46 10.728 4.821 10.728 4.821zM365.559 116.37s-2.756-.561-6.806-4.762a13.45 13.45 0 01-3.772-8.342 50.235 50.235 0 00-6.039 2.553 22.9 22.9 0 005.706 9.785 23.672 23.672 0 007.54 4.743c1.022-1.352 2.165-2.664 3.371-3.977zM347.218 154.782a94.088 94.088 0 01-34.855-2.386l-2.139 6.875c13.764 2.409 23.07 2.539 36.847 1.129zM348.936 143.278c-10.184.891-24.107-1.348-30.232-6.7-1.364 2.776-2.711 5.737-3.857 8.8 10.031 3.29 23.679 5.2 33.174 2.881a48.977 48.977 0 01.915-4.981zM353.859 132.918s-8.415-1.429-12.228-2.952c-9.222-3.684-12.159-9.244-12.159-9.244a49.875 49.875 0 00-5.682 7.271 63.548 63.548 0 0013.153 6.983c5.379 2.149 14.362 2.653 14.362 2.653z"
            />
            <path
                className="prefix__l"
                d="M379.603 89.11c.211 1.509 1.268 6.093 5.213 12.07-.012 4.355.311 5.553-.525 8.468-1.726 2.107-6.174 3.826-8.633 3.851-7.506.429-15.308-7.185-15.984-14.241s-1.473-12.749-.473-12.267 19.069-3.705 19.069-3.705z"
            />
            <path
                d="M379.948 86.604c-5.533 1.054-12.335 3.089-21.958 7.294l-2.221-4.767.646-2.786a10.5 10.5 0 012.228-7.774c1.951-2.346 9.072-5.156 13.443-2.814 2.188 1.173 4.986 4.9 4.986 4.9a9.305 9.305 0 011.885.92c.351 1.409.991 5.027.991 5.027z"
                fill="#595545"
                fillRule="evenodd"
            />
            <path
                className="prefix__g"
                d="M373.54 92.809s.746 2.746 2.808 2.665 3.228.844 3.251 2.861c.025 2.182-3.645 3.205-5.584 1.2a8.647 8.647 0 01-2.146-4.778c-.007-1.539 1.671-1.948 1.671-1.948z"
            />
            <path
                className="prefix__p"
                d="M377.799 103.25s-6.312 1.921-9.8-2.256c-.822 3.017.1 4.442.1 4.442l5.338 1.118 4.533-1.671z"
            />
            <path
                d="M379.338 86.751a43.839 43.839 0 00-7.2 1.591 122.687 122.687 0 00-13.072 5.025l.4 3.952s5.83 1.361 10.162-1.008c1.862-1.5 2.018-2.977 3.391-3.169s3.567 1.418 5.311.552a5.693 5.693 0 002.479-2.562z"
                fillRule="evenodd"
            />
            <path
                d="M367.273 101.798l.794-.829c.609-.03 1.648-.329 1.785-1.309l-.457-.063c-.127.9-1.378.913-1.43.913h-.1l-.928.968z"
                fill="#d6a87e"
                fillRule="evenodd"
            />
            <path
                className="prefix__p"
                d="M364.742 94.63a15.912 15.912 0 001.544-.933c1-.516 3-.944 2.714-1.336-.434-.593-1.8-.335-2.828 0-1.721.555-2.189 2.117-1.43 2.269zM377.996 90.411a7.637 7.637 0 01-1.5.171 5.919 5.919 0 00-1.632.51 1.719 1.719 0 011.1-1.536c.992-.282 2.385.278 2.032.855z"
            />
            <path
                className="prefix__s"
                d="M372.829 81.054l.595-.159c-1.007-3.772-5.832-5.9-6.037-5.99l-.245.565c.048.022 4.757 2.102 5.687 5.584zM367.13 82.546l.6-.149a38.234 38.234 0 00-2.617-7.112l-.549.279a37.692 37.692 0 012.566 6.982zM360.752 84.734l.6-.149c-.909-3.654 1.148-7.894 1.169-7.936l-.553-.273c-.091.182-2.179 4.482-1.216 8.358z"
            />
            <path
                d="M376.63 80.091a4.109 4.109 0 00-.528-.641c.203.241.382.459.528.641z"
                fill="#75736a"
                fillRule="evenodd"
            />
            <path
                d="M360.636 85.029c4.338-1.955 15.87-4.346 16.277-4.4l-.21-.385a.815.815 0 00-.074-.15 35.26 35.26 0 00-.528-.641c-.408-.423-.866-.817-.866-.817s-1.2.245-2.558.571a6.237 6.237 0 01.747 1.691l-.595.159a5.673 5.673 0 00-.788-1.693c-.363.091-.727.187-1.07.283a29.74 29.74 0 00-3.635 1.332c.139.462.27.937.391 1.421l-.6.149a30.44 30.44 0 00-.361-1.318 26.253 26.253 0 00-5.464 3.169l.044.189-.364.091a5.15 5.15 0 00-.346.349z"
                fill="#514c41"
                fillRule="evenodd"
            />
            <path
                className="prefix__s"
                d="M372.829 81.054l.595-.159a6.238 6.238 0 00-.747-1.691c-.209.05-.423.1-.636.157a5.672 5.672 0 01.788 1.693zM367.131 82.546l.6-.149c-.12-.484-.252-.959-.391-1.421q-.283.123-.568.251c.125.429.247.871.359 1.319zM360.986 84.676l.364-.091-.044-.189a7.906 7.906 0 00-.32.28z"
            />
            <path
                d="M367.368 196.131h-5.426l-6.106-4c4.094 1.711 6.869 3.021 11.532 4.008zm-26.59-18.395a9.511 9.511 0 01-1.608 3.564c1.769 1.093 3.682 2.506 5.785 3.76-1.754-2.326-3.664-4.271-4.177-7.324zm-15.654-.692l1.48 4.881c1.731.073 3.617.041 5.4 0l-3.769-1.652-3.11-3.227zm-9.141 64.2l11.312 3.394 2.681-4.978-11.379-5.17z"
                fill="#5b7088"
                fillRule="evenodd"
            />
            <path
                d="M344.403 121.624a193.229 193.229 0 00-17.4 15.658c-1.146 1.186-2.267 2.457-3.364 3.679a140.968 140.968 0 00-3.625 17.229c.026 4.125-.035 26.884-.01 31.009.31.416 1.421 1.96 2.108 2.85 1.162-15.165 2.4-36.063 6.865-50.469 3.837-6.085 9.767-13.291 15.426-19.956z"
                style={{
                    mixBlendMode: 'multiply',
                    isolation: 'isolate',
                }}
                fill="#c5c6c6"
                opacity={0.77}
                fillRule="evenodd"
            />
            <path
                className="prefix__x"
                d="M402.551 150.736a36.566 36.566 0 004.5-1.164 20.468 20.468 0 00-.156-6.089 44.215 44.215 0 01-4.82.3 7.139 7.139 0 00-1.012 3.574 3.89 3.89 0 001.488 3.379z"
            />
            <path
                className="prefix__x"
                d="M406.976 149.667a30.6 30.6 0 004.735.7c1.022-.65 1.857-.466 2.953-1.582.156-.85.906-2.238 1.108-3.029.034-.132-.3-1.169-.244-1.388a10.915 10.915 0 011.979-1.142 3.783 3.783 0 00.237-1.537c-1.547-1.293-4.485-1.428-6.51-1.249l-.515-.52c-.995-1.051-3.815 1.834-4.107 2.409a15.138 15.138 0 00-.976 5.432 7.928 7.928 0 001.34 1.906z"
            />
            <path
                d="M405.99 166.528l-2.494-.839 15.153-45.02c.937-2.783 2.321-4.443 4.233-5.075 2.322-.767 5.143.086 8.879 2.685l-1.5 2.16c-2.917-2.029-5.121-2.819-6.55-2.346-1.056.349-1.919 1.5-2.565 3.416z"
                fill="#535152"
            />
            <path
                className="prefix__x"
                d="M414.912 140.978l-2.527 1.52-1.353-1.878s-3.777 8.686-1.8 9.445a4.052 4.052 0 003.506-.249z"
            />
            <path
                d="M425.223 117.771a39.2 39.2 0 014.339 3.527 9.9 9.9 0 002.191-3.017z"
                fill="#535152"
                fillRule="evenodd"
            />
            <g>
                <path
                    className="prefix__m"
                    d="M291.3 59.625l23.625-16.127V211.16l-23.611-11.4-.01-140.135zm20.616-3.6l-16.156 9.6V193.01l16.146 6.823z"
                />
                <path d="M314.925 43.483l5.129.885-.056 164.847-5.047 1.953z" fill="#ebebeb" fillRule="evenodd" />
                <path
                    className="prefix__m"
                    d="M311.905 201.673l-17.983-7.6V64.457l17.993-10.694v2.261l-16.156 9.6V193.01l16.147 6.823z"
                />
                <path
                    d="M311.916 56.026l-.01 143.808-16.147-6.823V65.626z"
                    opacity={0.999}
                    fill="#f0f0f0"
                    fillRule="evenodd"
                />
                <path
                    className="prefix__ac"
                    d="M314.052 128.786l-3.5.174a3.143 3.143 0 00-1.876 2.437l-.077 6.5a11.294 11.294 0 002.282 4.1l.851-.735a22.554 22.554 0 01-1.141-3.945c.069-1.9.072-3.388.122-5.133.017-.589.534-.648.825-.649.869 0 1.706.01 2.553.019l-.039-2.768z"
                />
                <path className="prefix__d" d="M290.149 59.589h1.165v140.178h-1.165z" />
                <path className="prefix__ac" d="M318.817 209.671l-.024-165.521 1.262.218-.058 164.847z" />
                <path
                    d="M320.024 131.504l-5.082 9.668-.019-5.5a26.727 26.727 0 015.1-6.062z"
                    style={{
                        mixBlendMode: 'multiply',
                        isolation: 'isolate',
                    }}
                    fill="#898989"
                    opacity={0.5}
                    fillRule="evenodd"
                />
            </g>
            <g>
                <path
                    className="prefix__m"
                    d="M315.516 138.215c3.609-6.371 10.439-14.105 16.436-19.939 2.7-2.63 7.239-7.274 11.047-9.246 4.607-.754 8.153 2.776 6.129 8.5-3.313 3.085-9.168 7.639-13.061 11.223-5.1 4.7-10.4 9.729-12.4 12.132-.71-.203-4.785-1.58-8.151-2.67z"
                />
                <path
                    className="prefix__k"
                    d="M346.268 119.864c1.758-4.845-1.179-8.747-6.316-8.59a27.937 27.937 0 00-3.919 3.056s8.281 1.671 6.646 8.751c2.64-2.184 2.388-2.295 3.589-3.217zm-7.5 6.45c-1.014.823-2.106 1.91-4.093 3.755-.671-3.913-3.348-7.278-6.882-7.6l3.661-3.691c4.312-.186 7.259 4.553 7.315 7.532zm-8.126 7.417s-.861-4.449-3.925-5.951a4.652 4.652 0 00-3.354-.379c-1.4 1.589-2.685 3.24-3.963 4.9 1.906-.462 5.78.829 7.748 4.785 2.034-1.893 2.584-2.368 3.495-3.354z"
                />
                <path
                    className="prefix__l"
                    d="M313.703 170.227l.052-7.884c.65-.519 4.566.4 5.377 1.538l-.9 7.633a6.094 6.094 0 01-4.529-1.287z"
                />
                <path
                    className="prefix__m"
                    d="M323.369 141.495a7.3 7.3 0 00-7.864-3.142c-1.921 5.291-1.76 24.013-1.76 24.013.66-.2 4.6.409 5.385 1.631.448-4.022 2.055-13.109 4.239-22.502z"
                />
                <path
                    className="prefix__k"
                    d="M320.566 154.825c-1.076-2.7-5.1-3.934-6.634-3.159q-.129 3.169-.17 6.359s4.1-.356 5.962 1.862c.3-1.8.673-3.918.842-5.062zM315.52 138.2a37.522 37.522 0 00-1.152 6.647c1.4-.384 5.772 1.018 7.237 4.768.4-1.9 1.354-6.769 2.044-8.692-2.773-2.917-5.535-3.418-8.129-2.723z"
                />
                <path
                    className="prefix__x"
                    d="M319.885 170.731l.085 2.018-.848.646.574 2.611 2 5.884a1.2 1.2 0 01-1.817-.128l-1.336-1.971-1.7 4.667-1.509 3.412c-.443.442-.919.152-1.271-.271l.577-4.255-.531-.2-.961 4.286-.86-.322a5.8 5.8 0 01-1.287-1.449c-.074-.1.693-4.306.65-4.416a19.1 19.1 0 00-2.095 1.908 1.538 1.538 0 01-.4-1.406l.92-2.017c1.014-2.226.718-3.873 1.025-5.244a7.02 7.02 0 011.773-2.269v-2.283c1.666-.779 5.367-.749 7.011.799z"
                />
            </g>
            <g>
                <path
                    className="HSMAinprefix__ae"
                    d="M207.161 212.967c-.533 1.265-5.652 2.438-13.249 3.475-1.337.185-2.753.364-4.233.538-25.855 3.044-96.665 4.745-101.029 3.846-6.033-1.239-18.033-61.891-18.033-61.891s35.336-.612 63.4-.911c9.6-.1 42.995-.354 49.187-.354 9.59 0 24.816 53.248 23.956 55.3z"
                />
                <path
                    className="HSMAinprefix__a"
                    d="M197.38 205.182s-10.85-41.558-11.633-45.051a2.743 2.743 0 00-2.5-2.264c1.905-.182 3.293-.2 3.293-.2a8.814 8.814 0 018.294 6.282c2.625 8.189 7.464 23.29 12.366 38.67-.248 0-9.815 2.561-9.815 2.561zM260.574 241.397c.64-15.9-11.011-37.925-15.836-43.437-5.508-6.3-27.436.32-27.436.32l-1.8 60.761h22.985c11.6 0 20.358-6.45 22.086-16.875.2-1.2-.054.53 0-.769z"
                />
                <path
                    className="prefix__af"
                    d="M260.573 242.166c-1.731 10.425-10.483 16.875-22.086 16.875l-16.43-.056c7.518-4.791 16.908-19.313 38.516-16.819z"
                />
                <path
                    className="prefix__af"
                    d="M202.121 204.713s31.005-14.88 36.877-5.16c3.119 5.16 8.245 25.426 8.878 38.347.614 12.555-17.759 21.083-29.661 21.083l-10.875.056z"
                />
                <path
                    className="HSMAinprefix__ae"
                    d="M242.974 250.101c-.343.243-.694.479-1.055.714a51.209 51.209 0 01-17 6.666 75.474 75.474 0 01-16.358 1.624h-10.188l-47.576.011-27.269.008h-.4v-.01l-.945-4.366-9.447-43.612s120.339-3.67 130.057 1.222c4.51 2.271 7.444 12.56 7.334 23.034-.056 5.562-1.319 10.6-7.144 14.709z"
                />
                <path
                    className="prefix__af"
                    d="M242.973 248.899q-.515.411-1.055.809a47.948 47.948 0 01-17 7.557 66.906 66.906 0 01-16.358 1.839h-10.188l-47.576.013-27.269.01-.407-.013-.945-4.95c16.717-3.229 39.845-6.509 63.972-6.256 23.042.243 42.334.213 56.828.988z"
                />
                <path
                    className="HSMAinprefix__a"
                    d="M145.841 243.679c-2.41 9.995-12.97 15.838-23.438 15.267l-21.108-1.15 4.955-60.582s20.5-5.511 25.213 1.05c4.133 5.749 14.963 28.2 14.891 41.143a18.582 18.582 0 01-.512 4.271z"
                />
                <path
                    className="HSMAinprefix__a"
                    d="M146.09 242.454c-1.864 10.11-11.472 16.586-21.956 16.586H107.22c7.918-5.045 17.961-19.055 38.872-16.586z"
                />
                <path
                    className="HSMAinprefix__ae"
                    d="M76.544 259.153a37.4 37.4 0 01-5.227-.379c-12.259-1.828-17.353-10.432-19.605-22.826-2.046-11.3-1.569-25.856-.814-37.317.463-7.047 1.173-14.817 2.11-23.372.855-7.813 1.844-13.275 3.278-14.519 2.12-1.836 6.535-2.574 9.608-2.87 2.013-.193 9.2-1.375 11.59 6.1 6.694 20.921 11.384 35.707 12.366 38.785 5.744-2.5 14.2-5.467 20.711-6.358 5.429-.75 10.138-.231 12.084 3 3.119 5.175 11.7 25.825 12.335 38.788.584 11.951-9.807 20.015-21.042 20.809z"
                />
                <path
                    className="HSMAinprefix__a"
                    d="M80.036 205.182s-10.85-41.558-11.634-45.051a2.742 2.742 0 00-2.5-2.264c1.905-.182 3.293-.2 3.293-.2a8.815 8.815 0 018.294 6.282c2.625 8.189 7.464 23.29 12.366 38.67-.248 0-9.815 2.561-9.815 2.561z"
                />
                <path
                    d="M46.877 215.989s6.97-8.13.43-13.3c0 0-4.776-2.894-8.673-2.658 0 0 5.521 9.254 3.733 10.176s-5.406-10.757-5.406-10.757-10.87-1.052-14.366.627c0 0 9.29 7.605 7.334 8.059s-12-7.011-12-7.011-10.115 1.721-9.577 7.892 4.026 8.08 5.56 7.915 10.5-3.585 10.691-2.33-5.2 5.36-8.978 5.549c0 0 7.132 7.869 11.874 5.6s5.144-5.869 8.1-7.219 5.477-1.347 4.258.031-6.571 2.714-8.261 5.7-3.206 5.006 1.7 4.269 12.878-4.327 13.226-8.437z"
                    fill="#02f57e"
                />
                <path
                    className="prefix__ah"
                    d="M13.327 209.19s27.479-1.06 33.551 6.8a19.881 19.881 0 005.472 7.682l.643 1.754s-4.76-5.022-6.466-5.327c0 0 2.666-9.736-33.2-10.909z"
                />
                <path
                    className="prefix__ah"
                    d="M52.351 223.671a17.252 17.252 0 016.307 12.156l-1.07.1s-1.882-8.061-5.29-11.214.054-1.04.054-1.04zM71.246 205.764s-11.088-6.21-5.916-14.635c0 0 4.143-5.175 8.576-6.5 0 0-2.366 12.566 0 12.86s1.626-14.192 1.626-14.192 11.677-5.618 16.261-5.172c0 0-7.244 12.268-4.878 11.974s10.5-12.714 10.5-12.714 11.977-2.218 13.9 4.878-1.183 10.645-2.958 11.088-13.154.294-12.86 1.772 7.982 3.844 12.269 2.515c0 0-4.73 11.677-10.939 11.086s-8.107-3.839-11.951-4.133-4.9.617-2.983 1.652 6.656-.136 9.762 2.5 5.616 4.266-.148 5.449-16.115.443-18.181-3.992z"
                />
                <path
                    className="prefix__ai"
                    d="M105.839 184.476s-31.044 10.051-34.593 21.287a23.577 23.577 0 01-2.955 10.791v2.218s3.25-7.539 5.027-8.573c0 0-6.95-9.756 32.521-25.723z"
                />
                <path
                    className="prefix__ai"
                    d="M68.291 216.555a20.471 20.471 0 00-2.059 16.12l1.232-.331s-1.2-9.749 1.309-14.65-.481-1.139-.481-1.139z"
                />
                <path
                    className="prefix__ah"
                    d="M53.609 197.201s14.614-8.184 7.792-19.292c0 0-5.454-6.819-11.3-8.573 0 0 3.119 16.563 0 16.952s-2.143-18.706-2.143-18.706-15.4-7.4-21.436-6.819c0 0 9.546 16.171 6.433 15.785s-13.838-16.76-13.838-16.76S3.332 156.866.8 166.218s1.56 14.03 3.9 14.614 17.344.392 16.955 2.341-10.525 5.068-16.176 3.311c0 0 6.238 15.4 14.422 14.617s10.719-5.846 15.784-6.238 8.768.976 6.235 2.34-11.108.446-15.2 3.926-7.406 5.623.194 7.18 21.241.584 23.971-5.26z"
                />
                <path
                    className="prefix__ai"
                    d="M8.008 169.139s40.921 13.252 45.6 28.061a31.065 31.065 0 003.895 14.225v2.924s-4.287-9.938-6.625-11.3c0 0 9.157-12.86-42.872-33.907z"
                />
                <path
                    className="prefix__ai"
                    d="M57.504 211.422s6.312 9.585 2.714 21.249l-1.624-.436s1.575-12.85-1.726-19.313.635-1.5.635-1.5z"
                />
                <path className="prefix__aj" d="M25.473 229.344a28.627 28.627 0 1057.253 0z" />
                <path
                    className="prefix__ak"
                    d="M54.099 257.971c1.165.146-4.691 0-3.485 0a28.626 28.626 0 0028.624-28.626h-7.112c0 14.606-3.892 26.875-18.028 28.626z"
                />
                <path
                    className="prefix__aj"
                    d="M25.076 259.429h58.049a3.527 3.527 0 00-3.534-3.531H28.607a3.532 3.532 0 00-3.531 3.531z"
                />
                <path fill="#db6a1f" d="M24.822 229.342H83.67v.878H24.822z" />
            </g>
            <g>
                <path
                    className="prefix__af"
                    d="M596.742 217.515h-67.26a2.929 2.929 0 00-2.925 2.927v17.259a2.929 2.929 0 002.925 2.927h67.26a2.93 2.93 0 002.928-2.927v-17.259a2.93 2.93 0 00-2.928-2.927zm.871 20.186a.874.874 0 01-.871.871h-67.26a.874.874 0 01-.871-.871v-17.259a.871.871 0 01.871-.868h67.26a.872.872 0 01.871.868z"
                />
                <path className="prefix__af" d="M481.864 146.294h145.951v83.188H481.864z" />
                <path className="HSMAinprefix__ae" d="M486.774 146.294h145.951v83.188H486.774z" />
                <path
                    className="HSMAinprefix__ae"
                    d="M632.725 146.292v83.193H486.774v-9.777c75.333-8.163 112.1-52.993 125.135-73.411z"
                />
                <path
                    className="prefix__af"
                    d="M612.303 240.628H484.816a2.085 2.085 0 00-2.076 2.079v19.444a2.084 2.084 0 002.076 2.076h127.488a2.085 2.085 0 002.079-2.076v-19.444a2.085 2.085 0 00-2.079-2.079z"
                />
                <path
                    className="HSMAinprefix__ae"
                    d="M625.736 240.628H498.248a2.085 2.085 0 00-2.076 2.079v19.444a2.084 2.084 0 002.076 2.076h127.488a2.084 2.084 0 002.078-2.076v-19.444a2.085 2.085 0 00-2.078-2.079z"
                />
                <path className="HSMAinprefix__a" d="M529.45 181.311h64.876v23.209H529.45z" />
                <path
                    className="prefix__af"
                    d="M532.243 181.307l-1.292 23.209h64.876l1.375-23.209zM516.342 221.297h-22.876a2.088 2.088 0 00-2.081 2.081v.692a2.088 2.088 0 002.081 2.079h22.876a2.085 2.085 0 002.079-2.079v-.692a2.086 2.086 0 00-2.079-2.081z"
                />
            </g>
            <path className="prefix__af" d="M650.708 108.197h2.289v155.557h-2.289z" />
            <path
                className="prefix__af"
                d="M632.726 257.831h38.257v5.927h-38.257zM667.544 257.821h-31.39a5.926 5.926 0 015.926-5.926h19.543a5.927 5.927 0 015.927 5.926z"
            />
            <path className="prefix__aj" d="M676.5 108.538h-49.715l12.474-48.778h24.767z" />
            <path className="prefix__ak" d="M659.755 108.538h-15.8l3.118-48.778h9.565z" />
            <g transform="translate(-1029.972 -174.772)">
                <path className="prefix__b" d="M1096.697 263.917h120.775v6.204h-120.775z" />
                <path
                    className="prefix__am"
                    d="M1102.252 241.037h5.324v22.87h-5.324zM1127.983 241.037h5.324v22.87h-5.324zM1157.267 241.373l5.244-.905 3.888 22.527-5.244.905zM1108.844 244.056h5.324v19.861h-5.324zM1121.076 244.056h5.324v19.861h-5.324zM1151.945 244.056h5.324v19.861h-5.324zM1135.102 244.515l5.197-1.155 4.31 19.388-5.198 1.155zM1116.104 239.908h2.657v24.009h-2.657zM1147.751 239.908h2.657v24.009h-2.657zM1179.954 245.815h25.925v17.898h-25.925z"
                />
                <path className="prefix__b" d="M1217.462 316.443h-120.775v-6.204h120.775z" />
                <path
                    className="prefix__am"
                    d="M1211.907 310.24h-5.324v-22.87h5.324zM1186.176 310.24h-5.324v-22.87h5.324zM1153.018 310.234l-5.244-.905 3.888-22.527 5.244.905zM1205.314 310.24h-5.324v-19.861h5.324zM1193.083 310.24h-5.324v-19.861h5.324zM1162.214 310.24h-5.324v-19.861h5.324zM1174.764 310.234l-5.197-1.155 4.309-19.388 5.197 1.155zM1198.055 310.24h-2.657v-24.009h2.657zM1166.399 310.24h-2.657v-24.009h2.657zM1134.214 310.036h-25.925v-17.898h25.925z"
                />
                <circle
                    className="prefix__b"
                    cx={20.537}
                    cy={20.537}
                    r={20.537}
                    transform="rotate(-13.28 1357.38 -4771.592)"
                />
                <circle
                    cx={17.463}
                    cy={17.463}
                    r={17.463}
                    transform="rotate(-9.22 1715.843 -6958.207)"
                    fill="#f0f0f0"
                />
                <path
                    d="M1157.908 187.492v12.2l8.083-4.574"
                    stroke="#e0e0e0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    fill="#f0f0f0"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
