import {
    GET_SELECTABLE_SERVICE,
    GET_SELECTABLE_SERVICE_SUCCESS,
    GET_SELECTABLE_SERVICE_ERROR
} from './actionTypes';
import {
  SelectableServices,
  SelectableServicesRequest,
  SelectableServicesSuccess,
  SelectableServicesFailure,
  SelectableServicesFailurePayload,
  SelectableServicesSuccessPayload
} from './types';

export const selectableServices = (payload: SelectableServicesRequest): SelectableServices => ({
    type: GET_SELECTABLE_SERVICE,
    payload,
});

export const selectableServicesSuccess = (payload: SelectableServicesSuccessPayload): SelectableServicesSuccess => ({
    type: GET_SELECTABLE_SERVICE_SUCCESS,
    payload,
});

export const selectableServicesFailure = (payload: SelectableServicesFailurePayload): SelectableServicesFailure => ({
    type: GET_SELECTABLE_SERVICE_ERROR,
    payload,
});