import React, { Component } from 'react';
import '../HomeWaranty/HomeWarantyMain.scss';
import Image from '../../Assets/images/energy/energyMainImage.svg';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EnergyAction from '../../store/energy/actions';
import PreLoaderImage from '../../Assets/images/SignUp/preloader.gif';
import Footer from './Footer';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    energyAction?: any;
    energyState?: any;
};

type initialState = {
    summaryData: any;
    showProgressLoader: boolean;
};

export class EnergyMainPage extends Component<initialProps, initialState> {
    state: initialState = {
        summaryData: [],
        showProgressLoader: true,
    };

    componentDidMount() {
        this.props.energyAction.getEnergySummary();
        window.scrollTo(0, 0);
        if (
            !(
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.from &&
                this.props.history.location.state.from == 'home'
            )
        ) {
            if (
                this.props &&
                this.props.energyState &&
                this.props.energyState.getEnergySummary &&
                this.props.energyState.getEnergySummary.length > 0 
            ) {
                this.props.history.push({
                    pathname: '/dashboard/energy-savings/home',
                    state: this.props.energyState,
                });
            }
        }        
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Energy Savings',
                    category_name: 'Energy Savings',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: any) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;
        
        if (prevEnergy.getEnergySummary != curEnergy.getEnergySummary && curEnergy.getEnergySummary) {
            if(curEnergy.getEnergySummary.length > 0 && !(this.props.history?.location?.state?.from == 'home')){
                this.props.history.push({
                    pathname: '/dashboard/energy-savings/home',
                    state: curEnergy.getEnergySummary,
                });
            }
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ 
                summaryData: curEnergy.getEnergySummary,
                showProgressLoader: false
            });
        }
    }

    handleAnalytics = () => {
        //

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Button Clicked',
                {
                    page: 'Energy Saving',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'GET STARTED',
                    card: false,
                    card_title: 'Energy Saving',
                    card_type: 'Start Page',
                    category_name: 'Energy Savings',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let showCompletedElectricity: boolean = false;
        let showCompletedGas: boolean = false;
        this.state.summaryData &&
            this.state.summaryData.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });

            const breadList = [
                {
                    name: 'Energy Savings',
                    url: ''
                }
            ]

        return (
            <div>
                <div style={{ marginTop: '40px' }}></div>
                <div className="main-head">
                    <h2 className="heading">Energy Savings</h2>
                    <div className='mb-4'>
                        <BreadCrumb breadList={breadList} />
                    </div>
                </div>
                {this.state.showProgressLoader ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '60vh',
                        }}
                    >
                        <img style={{ maxHeight: '300px' }} src={PreLoaderImage} alt="" />
                        <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                            Working on it.Please Wait...
                        </div>
                    </div>
                ):(
                <div className="WarantycoverDiv">
                    <div className="landingPage">
                        <img className="mainImg" src={Image} alt="" />
                        <h2 className="heading">Save on your utilities </h2>
                        <p className="subtext"> Quickly and easily get multiple saving quotes in minutes!</p>
                        <div className="line"></div>
                        <div className="btn-div">
                            <div className="btn-div2">
                                <Button
                                    className="btnICbtn"
                                    height="50px"
                                    // width="125px"
                                    width="100%"
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                    // onClick={() => this.tabChangeHandler('addressCard')}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: '/dashboard/energy-savings/energySteps',
                                            state: {
                                                showCompletedElectricity: showCompletedElectricity,
                                                showCompletedGas: showCompletedGas,
                                            },
                                        })
                                    }
                                >
                                    Get Started
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                )};
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnergyMainPage);
