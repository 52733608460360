import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={58.805}
            height={88.154}
            viewBox="0 0 58.805 88.154"
        >
            <defs>
                <style>
                    {' '}
                    {`.hsStepfireprefix__a{fill:${
                        slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="hsStepfireprefix__a"
                d="M11.714 72.322A22.871 22.871 0 0047.62 44.59L36.732 26.002a1.121 1.121 0 00-1.915-.032l-3.344 5.289-5.29-13.792a1.121 1.121 0 00-2.013-.166L8.151 44.589a22.88 22.88 0 003.563 27.733zm13.21-51.877l5.256 13.7a1.121 1.121 0 001.994.2l3.554-5.621 9.958 17a20.629 20.629 0 11-35.6 0z"
            />
            <path
                className="hsStepfireprefix__a"
                d="M27.886 76.391a9.821 9.821 0 008.891-13.992l-5.366-11.436a1.121 1.121 0 00-1.015-.645 1.121 1.121 0 00-1.014.651l-3.823 8.282-1.831-2.878a1.121 1.121 0 00-1.961.125l-2.768 5.9A9.821 9.821 0 0027.89 76.39zm-6.861-13.04l1.9-4.054 1.838 2.89a1.121 1.121 0 001.964-.131l3.676-7.96 4.343 9.255a7.579 7.579 0 11-13.722 0zM53.66 79.387H2.112a1.121 1.121 0 00-.933.5 6.984 6.984 0 000 7.769 1.121 1.121 0 00.933.5h51.547a1.121 1.121 0 00.933-.5 6.984 6.984 0 000-7.769 1.121 1.121 0 00-.932-.5zm-.641 6.526H2.752a4.743 4.743 0 010-4.283h50.265a4.744 4.744 0 01.002 4.283zM47.93 21.349v-2.175a1.121 1.121 0 10-2.242 0v2.175a1.121 1.121 0 102.242 0zM57.684 43.065a1.121 1.121 0 00-1.121 1.121v2.175a1.121 1.121 0 102.242 0v-2.175a1.121 1.121 0 00-1.121-1.121zM14.869 6.346v-2.18a1.121 1.121 0 00-2.242 0v2.18a1.121 1.121 0 002.242 0zM36.185 3.3V1.121a1.121 1.121 0 10-2.242 0V3.3a1.121 1.121 0 102.242 0zM5.266 27.623a1.121 1.121 0 00-1.121 1.121v2.175a1.121 1.121 0 002.242 0v-2.175a1.121 1.121 0 00-1.121-1.121z"
            />
        </svg>
    );
}

export default SvgComponent;
