import React from 'react';
import './Main.scss';

interface defaultProps {
    value: number | string;
    setState: any;
    loanTermYears: number;
    showNoDataView?: boolean;
    analyticsData?: any;
}

export default function RadioButtonsGroup({value, setState, loanTermYears, showNoDataView}: defaultProps) {

    const handleChange = (e: any) => {
        setState({ years: e.target.value });
    }

    return (
        <div>
            <div className="headingText">Select Loan Term</div>
            <form>
                <div className={value === '10' ? 'radioButtonSelectedSaving' : 'radioButtonNotSelectedSaving'}>
                    <input
                        type="radio"
                        value="10"
                        checked={value === '10'}
                        onChange={handleChange}
                        disabled={showNoDataView ? showNoDataView : loanTermYears < 10}
                        data-cy="loan-term-10"
                    />{' '}
                    <span className={`blurTextRadioButton ${value === '10' && 'textRadioButton'} ${loanTermYears < 10 && 'disableRadio'}`}>10 Years</span>
                </div>

                <div className={value == '15' ? 'radioButtonSelectedSaving' : 'radioButtonNotSelectedSaving'}>
                    <input
                        type="radio"
                        value="15"
                        checked={value === '15'}
                        onChange={handleChange}
                        disabled={showNoDataView ? showNoDataView : loanTermYears < 15}
                        data-cy="loan-term-15"
                    />{' '}
                    <span className={`blurTextRadioButton ${value === '15' && 'textRadioButton'} ${loanTermYears < 15 && 'disableRadio'}`}>15 Years</span>
                </div>
                <div className={value == '20' ? 'radioButtonSelectedSaving' : 'radioButtonNotSelectedSaving'}>
                    <input
                        type="radio"
                        value="20"
                        checked={value === '20'}
                        onChange={handleChange}
                        disabled={showNoDataView ? showNoDataView : loanTermYears < 20}
                        data-cy="loan-term-20"
                    />{' '}
                    <span className={`blurTextRadioButton ${value === '20' && 'textRadioButton'} ${loanTermYears < 20 && 'disableRadio'}`}>20 Years</span>
                </div>
                <div className={value == '30' ? 'radioButtonSelectedSaving' : 'radioButtonNotSelectedSaving'}>
                    <input
                        type="radio"
                        value="30"
                        checked={value === '30'}
                        disabled={showNoDataView ? showNoDataView : loanTermYears < 30}
                        onChange={handleChange}
                        data-cy="loan-term-30"
                    />{' '}
                    <span className={`blurTextRadioButton ${value === '30' && 'textRadioButton'} ${loanTermYears < 30 && 'disableRadio'}`}>30 Years</span>
                </div>
            </form>
        </div>
    );
}
