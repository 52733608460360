import React, { Component } from 'react';
import './documentry.scss';
import documentryHead from '../../Assets/images/documentry/documentryHead.svg';
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import consumerImage from '../../Assets/images/documentry/consumerImage.png';
import folderImage from '../../Assets/images/documentry/property.svg';
import otherFolderImage from '../../Assets/images/documentry/otherFolder.svg';
import photovideosImage from '../../Assets/images/documentry/photovideo.svg';
import couponImage from '../../Assets/images/documentry/coupon.svg';
import reciepentsImage from '../../Assets/images/documentry/reciepents.svg';
import vehicleImage from '../../Assets/images/documentry/vehicle.svg';
import Modal from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import SelectInput from '../../components/atoms/SelectInput';
import Button from '../../components/atoms/Button';
import copyIconImage from '../../Assets/images/documentry/copyIconImage.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DocumentryAction from '../../store/documentary/actions';
import documentMobile from '../../Assets/images/documentry/documentMobile.svg';
import * as CommonAction from '../../store/common/actions';
import SelectInput from '../../components/atoms/SelectInput';
import pdfIcon from '../../Assets/images/documentry/pdfIcon.png';
import csvIcon from '../../Assets/images/documentry/csvIcon.png';
import wordIcon from '../../Assets/images/documentry/wordIcon.png';
import docIcon from '../../Assets/images/documentry/documentIcon.jpg';
import { formCompletedSegment, formStartedSegment, buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import * as DashboardAction from '../../store/dashboard/actions';
import _ from 'lodash';
import { updateChecklistTask } from '../../utils/common-functions';

// type initialProps = {
//     // documentryAction?: any;
//     // documentryState?: any;
//     // history?: any;
//     // location?: any;
// };
type initialProps = {
    documentryAction?: any;
    documentryState?: any;
    history?: any;
    commonAction?: any;
    commonState?: any;
    dashboardAction: any;
};

type initialState = {
    slug: any;
    folderOptions: any;
    filterValue: any;
    folderName: any;
    folderOpen: boolean;
    title: string;
    step: {
        folder: string;
    };
    folderOptionsSelect: any;

    whatFoldersHelp: string;
    documentAdd: boolean;
    uploadFor?: any;
    image?: any;
    description?: any;
    vendorsOptions: any;
    whatYouNeedHelp: string;
    isMoveDataArrived: boolean;
    moveId: string;
    selectedImage: any;
    folder: any;
    contineuClicked: boolean;
    documentData: any;
    getId: any;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
    hashcode: any;
};

export class Documentry extends Component<initialProps, initialState> {
    state: initialState = {
        whatYouNeedHelp: '',
        slug: null,
        folderOptions: '',
        filterValue: '',
        folderName: [],
        folderOpen: false,
        title: '',
        step: {
            folder: '',
        },
        folderOptionsSelect: [],
        vendorsOptions: [],
        whatFoldersHelp: '',
        documentAdd: false,
        uploadFor: '',
        image: '',
        description: '',
        isMoveDataArrived: true,
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        prefilled: false,
        selectedImage: null,
        folder: null,
        contineuClicked: false,
        documentData: [],
        getId: '',
        brokerage_key: '',
        agent_key: '',
        hashcode: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        this.props.documentryAction.getFolders();
        this.props.documentryAction.getDocuments();

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Documents',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }


    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getFolders &&
            nextProps.documentryState.isGetFolders
        ) {
            nextProps.documentryState.isGetFolders = false;
            let folders: any = [];
            let foldersOptions: any = [];
            let foldersOptionsSelected: any = [];

            nextProps.documentryState.getFolders.data.map((item: any) => {
                folders.push({ id: item[0], name: item[1], description: item[2] });
                foldersOptions.push({ item: item, label: item[1], value: item[1] });
                foldersOptionsSelected.push({ item: item, label: item[1], value: item[0] });
            });

            currentState.vendorsOptions = foldersOptions;
            currentState.folderOptionsSelect = foldersOptionsSelected;
            currentState.folderName = folders;
        }
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
        }

        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.postDocuments &&
            nextProps.documentryState.isPostDocuments
        ) {
            nextProps.documentryState.isPostDocuments = false;
            // let windownew: any = window;
            // let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            // analytics.track(
            //     `Document Upload Completed`,
            //     {
            //         page: 'Documents',
            //         brokerage_name: currentState.brokerage_name,
            //         brokerage_key: currentState.brokerage_key,
            //         agent_key: currentState.agent_key,
            //         agent_name: currentState.agent_name,
            //         folder_name: currentState.folder && currentState.folder.label,
            //     },
            //     { user_id: currentState.hashcode },
            // );
            currentState.documentAdd = false;
            currentState.title = '';
            currentState.description = '';
            currentState.folder = null;
            currentState.selectedImage = null;
            currentState.contineuClicked = false;
            nextProps.documentryAction.getFolders();
            nextProps.documentryAction.getDocuments();

            formCompletedSegment(
                'Form Completed',
                'Documents',
                'Documnets',
                currentState.brokerage_name,
                currentState.brokerage_key,
                currentState.agent_name,
                currentState.agent_key,
                '',
                'Finish',
                true,
                currentState.prefilled,
                'Document Form',
                1,
                currentState.moveId,
            );
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.postFolders &&
            nextProps.documentryState.isPostFolders
        ) {
            nextProps.documentryState.isPostFolders = false;
            let folder: any = {
                item: [
                    nextProps.documentryState.postFolders.id,
                    nextProps.documentryState.postFolders.name,
                    nextProps.documentryState.postFolders.slug,
                ],
                label: nextProps.documentryState.postFolders.name,
                value: nextProps.documentryState.postFolders.id,
            };

            currentState.getId = nextProps.documentryState.postFolders.id;
            currentState.folderOpen = false;
            currentState.title = '';
            currentState.description = '';
            currentState.folder = folder;
            currentState.documentAdd = true;
            currentState.contineuClicked = false;
            // currentState.folderName = [];
            currentState.description = '';
        }

        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getDocuments &&
            nextProps.documentryState.getDocuments.results &&
            nextProps.documentryState.isGetDocuments
        ) {
            nextProps.documentryState.isGetDocuments = false;
            let documentsResults: any = [];

            nextProps.documentryState.getDocuments.results.map((item: any) => {
                documentsResults.push(item);
                return documentsResults;
            });
            currentState.documentData = documentsResults;
        }

        return currentState;
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.documentryState;
        const cur = this.props && this.props.documentryState;

        if (prev.getFolders !== cur.getFolders && cur.getFolders && cur.getFolders.data) {
            this.props.documentryState.getFolders.data.map((item: any) => {
                if (item[0] == this.state.getId) {
                    let windownew: any = window;
                    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                    analytics.track(
                        'New Document Folder Completed',
                        {
                            page: 'Documents',
                            brokerage_name: this.state.brokerage_name,
                            brokerage_key: this.state.brokerage_key,
                            agent_name: this.state.agent_name,
                            agent_key: this.state.agent_key,
                            folder_name: item[1],
                        },
                        { user_id: this.state.moveId },
                    );
                }
                this.setState({
                    getId: '',
                });
            });
        }
    }
    handleFilterValue = async (e: any) => {
        this.setState({
            filterValue: e.target.value,
        });
    };

    onChangeFilterValue = (value: any) => {
        this.setState({
            filterValue: value,
        });
    };
    createFolder = () => {
        this.setState({
            folderOpen: true,
        });
    };

    handletitleChange = (e: any) => {
        this.setState({
            title: e.target.value,
        });
    };
    handledescriptionChange = (e: any) => {
        this.setState({
            description: e.target.value,
        });
    };

    handleChangeWhatYouNeedHelp = (e: any) => {
        // this.setState({ whatFoldersHelp: e.target.value });
        this.setState({ whatYouNeedHelp: e.target.value });
    };
    // onImageChange = (event: any) => {
    //     if (event.target.files && event.target.files[0]) {
    //         let img = event.target.files[0];
    //         this.setState({
    //             image: URL.createObjectURL(img),
    //         });
    //     }
    // };
    updateUploadfor = () => {
        this.setState({ uploadFor: 'upload' });
    };
    // onPasteImage = (name: string, value: any) => {
    //     if (name === 'image') {
    //         this.setState({ image: value });
    //     }
    // };
    addFolders = async () => {
        this.setState({ contineuClicked: true });
        if (this.state.title) {
            const payload = {
                name: this.state.title,
                description: this.state.description,
            };
            await this.props.documentryAction.postFolders(payload);
        }
    };
    handleNextStep = async (index: any) => {
        this.props.history.push({ pathname: '/dashboard/my-documents/documents', state: { selectedfolder: index } });
    };
    getAddDocument = () => {
        this.setState({
            documentAdd: true,
        });
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Document',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Document',
            'Add Document',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );

        formStartedSegment(
            'Form Started',
            'Documents',
            'Documents',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Finish',
            true,
            this.state.prefilled,
            1,
            move_id,
        );
    };

    onSelectVendors = async (e: any) => {
        await this.setState({ whatYouNeedHelp: e.target.value });
        let data: any = [];
        data.push(this.state.vendorsOptions.find((item: any) => item.label.match(this.state.whatYouNeedHelp)));

        let folder: any = null;
        data.map((item: any) => {
            folder = {
                item: item.item,
                label: item.label,
                value: item.item[0],
            };
        });
        this.setState({ folder: folder });
    };
    onSelectFolder = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };
    uploadDocuments = () => {
        this.setState({ contineuClicked: true });
        if (
            this.state.moveId &&
            this.state.folder &&
            this.state.folder.value &&
            this.state.title &&
            this.state.selectedImage &&
            this.state.selectedImage != null
        ) {
            let payload: any = {
                move_id: this.state.moveId,
                folders_id: this.state.folder && this.state.folder.value,
                name: this.state.title,
                description: this.state.description,
                document: this.state.selectedImage,
            };

            const form = new FormData();
            for (var key in payload) {
                form.append(key, payload[key]);
            }
            this.props.documentryAction.postDocuments({ formData: form });
            this.props.documentryAction.getDocuments();

            updateChecklistTask(100, this.props.dashboardAction);
        }

        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Document',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            'Document',
            'Finish',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }

        const breadList = [
            {
                name: 'Document',
                url: ''
            }
        ]

        return (
            <div className="documentMain">
                <div className="documentryMainOFAll">
                    <div className="documentryMainDiv">
                        <div className="headingDocumentry">Documents</div>
                        <div className='mb-4'>
                           <BreadCrumb breadList={breadList} />
                         </div>
                    </div>
                    <div className="documentry-sub-div">
                        <div className="documentry-sub-div1">
                            <div className="documentSubHeadingDiv1">
                                We Provide A Secure Place For All Your Important{' '}
                                <span
                                    style={{
                                        color: slug && slug.primary_color ? `${slug.primary_color}` : '#273E59',
                                    }}
                                >
                                    Documents
                                </span>
                            </div>
                            <div className="topAddDocument">
                                <div className="helpInputDocument">
                                    <AutoComplete
                                        id="free-solo-demo"
                                        freeSolo
                                        onSelect={(e: any) => this.onSelectVendors(e)}
                                        options={this.state.vendorsOptions}
                                        getOptionLabel={(option: any) => option.value}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                className="trustedProsInput"
                                                // label="What did you need help with"
                                                id="outlined-start-adornment"
                                                // onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                                variant="outlined"
                                                value={this.state.whatYouNeedHelp}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder="Search for documents/files/folders"
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="DocumentGetStartedButton"
                                        // color="#fff"
                                        // backgroundColor={
                                        //     slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'
                                        // }
                                        onClick={this.getAddDocument}
                                    >
                                        Add Document
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="documentry-sub-div2" style={{ marginBottom: '-5px' }}>
                            <img src={documentryHead} height="300px" alt="" />
                        </div>
                        <div className="documentry-sub-div4" style={{ marginBottom: '-5px' }}>
                            <img src={documentMobile} alt="" />
                        </div>
                    </div>
                    <div className="topAddDocumentMobile">
                        <div className="helpInputDocument">
                            <AutoComplete
                                id="free-solo-demo"
                                freeSolo
                                onSelect={(e: any) => this.onSelectVendors(e)}
                                options={this.state.vendorsOptions}
                                getOptionLabel={(option: any) => option.value}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        className="trustedProsInput"
                                        // label="What did you need help with"
                                        id="outlined-start-adornment"
                                        // onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                        variant="outlined"
                                        value={this.state.whatYouNeedHelp}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Search for documents/files/folders"
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Button
                                className="DocumentGetStartedButton"
                                // color="#fff"
                                // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                onClick={this.getAddDocument}
                            >
                                Add Document
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="documentInventoryInnerDiv">
                    <div className="documentryHeadRecent">
                        <div className="documentRecent">
                            Recent({this.state.documentData.length > 6 ? '6' : this.state.documentData.length})
                        </div>
                        {this.state.documentData.length > 6 ? (
                            <div
                                className="documentView"
                                onClick={() => {
                                    this.props.history.push('/dashboard/my-documents/documents');
                                }}
                            >
                                View All
                            </div>
                        ) : null}
                    </div>
                    <div className="documentImages">
                        {this.state.documentData &&
                            this.state.documentData.map((item: any, index: number) => {
                                let imageIcon: any = '';
                                let isVideo: boolean = false;
                                let videoType: string = '';
                                if (item.document && item.document.includes('pdf')) {
                                    imageIcon = pdfIcon;
                                } else if (item.document && item.document.includes('csv')) {
                                    imageIcon = csvIcon;
                                } else if (
                                    item.document &&
                                    (item.document.includes('.doc') ||
                                        item.document.includes('docx') ||
                                        item.document.includes('.docm') ||
                                        item.document.includes('dot') ||
                                        item.document.includes('dotm') ||
                                        item.document.includes('dotx'))
                                ) {
                                    imageIcon = wordIcon;
                                } else if (
                                    item.document &&
                                    (item.document.includes('gif') ||
                                        item.document.includes('GIF') ||
                                        item.document.includes('jpg') ||
                                        item.document.includes('JPG') ||
                                        item.document.includes('jpeg') ||
                                        item.document.includes('JPEG') ||
                                        item.document.includes('JFIF') ||
                                        item.document.includes('jfif') ||
                                        item.document.includes('pjpeg') ||
                                        item.document.includes('PJPEG') ||
                                        item.document.includes('pjp') ||
                                        item.document.includes('PJP') ||
                                        item.document.includes('png') ||
                                        item.document.includes('PNG') ||
                                        item.document.includes('svg') ||
                                        item.document.includes('SVG') ||
                                        item.document.includes('WEBP') ||
                                        item.document.includes('webp'))
                                ) {
                                    imageIcon = item.document;
                                } else if (
                                    item &&
                                    item.document &&
                                    (item.document.includes('mp4') || item.document.includes('MP4'))
                                ) {
                                    isVideo = true;
                                    imageIcon = item && item.document;
                                    videoType = 'mp4';
                                } else if (
                                    item &&
                                    item.document &&
                                    (item.document.includes('mov') || item.document.includes('MOV'))
                                ) {
                                    isVideo = true;
                                    imageIcon = item && item.document;
                                    videoType = 'mov';
                                } else if (
                                    item &&
                                    item.document &&
                                    (item.document.includes('wmv') || item.document.includes('WMV'))
                                ) {
                                    isVideo = true;
                                    imageIcon = item && item.document;
                                    videoType = 'wmv';
                                } else if (
                                    item &&
                                    item.document &&
                                    (item.document.includes('avi') || item.document.includes('AVI'))
                                ) {
                                    isVideo = true;
                                    imageIcon = item && item.document;
                                    videoType = 'avi';
                                } else if (
                                    item &&
                                    item.document &&
                                    (item.document.includes('mkv') || item.document.includes('MKV'))
                                ) {
                                    isVideo = true;
                                    imageIcon = item && item.document;
                                    videoType = 'mkv';
                                } else {
                                    imageIcon = docIcon;
                                }
                                if (index < 6) {
                                    return (
                                        <div key={index} className="documentImagesInfo">
                                            <div className="">
                                                {isVideo ? (
                                                    <video width="100%" height="100px">
                                                        <source src={imageIcon} type={`video/${videoType}`} />
                                                    </video>
                                                ) : (
                                                    <img src={imageIcon} alt="" />
                                                )}
                                            </div>
                                            <div className="imageName">{item.name}</div>
                                            <div className="folderName">{item.folders && item.folders.name}</div>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                    <div className="documentryHeadRecent">
                        <div className="documentRecent">Suggested Folders</div>
                        <div className="documentView" onClick={this.createFolder}>
                            Add New Folder
                        </div>
                    </div>
                    <div className="documentFolders">
                        {this.state.folderName &&
                            this.state.folderName.map((item: any, index: number) => {
                                return (
                                    <div key={index} className="documentFolderSub" onClick={() => this.handleNextStep(index)}>
                                        <div className="">
                                            <img
                                                src={
                                                    item.name == 'Property Documents'
                                                        ? folderImage
                                                        : item.name == 'Vehicle Documents'
                                                        ? vehicleImage
                                                        : item.name == 'Receipts'
                                                        ? reciepentsImage
                                                        : item.name == 'Coupons'
                                                        ? couponImage
                                                        : item.name == 'Photos & Videos'
                                                        ? photovideosImage
                                                        : otherFolderImage
                                                }
                                            />
                                        </div>
                                        <div className="folderDocumentName">{item.name}</div>
                                    </div>
                                );
                            })}
                    </div>

                    {/* <object
                        width="100%"
                        height="700"
                        data="http://www.africau.edu/images/default/sample.pdf"
                        type="application/pdf"
                    ></object> */}
                </div>
                {this.state.folderOpen ? (
                    <Modal
                        isShowModal={this.state.folderOpen}
                        onCloseModal={() => {
                            this.setState({
                                folderOpen: false,
                                contineuClicked: false,
                                title: '',
                                description: '',
                            });
                        }}
                        showClose={false}
                        className="modalFolderCreateFolder"
                    >
                        <div>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        margin: 'auto',
                                        fontFamily: 'Roboto',
                                    }}
                                >
                                    Add New Folder
                                </div>
                                <div className="closeIconWaranty">
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({
                                                folderOpen: false,
                                                contineuClicked: false,
                                                title: '',
                                                description: '',
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            {/* <iframe src={'http://www.africau.edu/images/default/sample.pdf'}></iframe> */}
                            <div className="inputValueDiv">
                                <TextField
                                    className="input1"
                                    label="Title"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handletitleChange(e)}
                                    variant="filled"
                                    value={this.state.title}
                                    // error={this.state.step3.titleAgent === ''}
                                    // helperText={
                                    //     this.state.step3.titleAgent === '' ? 'Please enter Title Agent' : ''
                                    // }
                                />
                                <div className="error">
                                    {this.state.contineuClicked && this.state.title == '' ? 'Enter Title' : ''}
                                </div>
                            </div>
                            <div className="inputTextValueDiv">
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    rows={6}
                                    placeholder="Enter Description here (Optional)"
                                    value={this.state.description}
                                    onChange={(e: any) => this.handledescriptionChange(e)}
                                />
                            </div>
                            <div className="buttonDocument">
                                <Button
                                    // color="#fff"
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                    onClick={this.addFolders}
                                >
                                    Finish
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
                {this.state.documentAdd ? (
                    <Modal
                        isShowModal={this.state.documentAdd}
                        onCloseModal={() => {
                            this.setState({
                                documentAdd: false,
                                contineuClicked: false,
                                selectedImage: null,
                                image: '',
                                title: '',
                                description: '',
                                folder: null,
                            });
                        }}
                        showClose={false}
                        className="modalFolderCreate"
                        style={{ position: 'absolute' }}
                    >
                        <div>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        margin: 'auto',
                                        fontFamily: 'Roboto',
                                    }}
                                >
                                    Add Document
                                </div>
                                <div className="closeIconWaranty">
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({
                                                documentAdd: false,
                                                contineuClicked: false,
                                                selectedImage: null,
                                                image: '',
                                                title: '',
                                                description: '',
                                                folder: null,
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div className="ConfirmationDetailsDiv">
                                <span className="ConfirmationLabel">
                                    <input
                                        type="file"
                                        id="file"
                                        ref="fileUploader"
                                        style={{ display: 'none' }}
                                        onChange={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            let currentTarget = document.querySelector(`.uploadText`);
                                            if (e.target.files.length > 0) {
                                                let fileSize: number =
                                                    e.target.files[0] && e.target.files[0].size / 1024;
                                                if (fileSize <= 2048) {
                                                    const fileReader = new FileReader();
                                                    this.setState({ selectedImage: e.target.files[0] });
                                                    fileReader.readAsDataURL(e.target.files[0]);

                                                    let files = e.target.files[0];

                                                    fileReader.onload = () => {
                                                        let image: any = '';
                                                        let isVideo: boolean = false;
                                                        let videoType: string = '';
                                                        if (files && files.type && files.type == 'application/pdf') {
                                                            image = pdfIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('application/') &&
                                                            files.name &&
                                                            files.name.split('.')[1] == 'csv'
                                                        ) {
                                                            image = csvIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('application/') &&
                                                            files.name &&
                                                            (files.name.split('.')[1] == 'doc' ||
                                                                files.name.split('.')[1] == 'docx' ||
                                                                files.name.split('.')[1] == 'docm' ||
                                                                files.name.split('.')[1] == 'dot' ||
                                                                files.name.split('.')[1] == 'dotx' ||
                                                                files.name.split('.')[1] == 'dotm')
                                                        ) {
                                                            image = wordIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('image/')
                                                        ) {
                                                            image = fileReader.result;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mp4') || files.type.includes('MP4'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mp4';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mov') || files.type.includes('MOV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mov';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('wmv') || files.type.includes('WMV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'wmv';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('avi') || files.type.includes('AVI'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'avi';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mkv') || files.type.includes('MKV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mkv';
                                                        } else {
                                                            image = docIcon;
                                                        }
                                                        if (currentTarget && currentTarget !== null) {
                                                            if (isVideo) {
                                                                currentTarget.innerHTML = `<video width="100%" height="100px">
                                                                        <source
                                                                            src="${image}"
                                                                            type="video/${videoType}"
                                                                        />
                                                            </video>`;
                                                            } else {
                                                                currentTarget.innerHTML = `<img src="${image}" alt="" />`;
                                                            }
                                                        }
                                                    };
                                                }
                                            }
                                        }}
                                    />
                                </span>
                                <div className="inputFileDiv" id="upload">
                                    <div
                                        className="uploadText"
                                        onClick={() =>
                                            // `current` points to the mounted file input element
                                            {
                                                this.updateUploadfor();
                                                let element: any = document.getElementById('file') as HTMLElement;
                                                element.click();
                                            }
                                        }
                                    >
                                        <img src={copyIconImage} alt="" />
                                        <div className="helperTextUpload">Upload the file here (Max 2mb each file)</div>
                                        <div className="textUpload">Upload File</div>
                                    </div>
                                </div>
                                <div className="error">
                                    {this.state.contineuClicked && this.state.selectedImage == null
                                        ? 'Upload Image'
                                        : ''}
                                </div>
                            </div>

                            <div className="inputValueDiv">
                                <TextField
                                    className="input1"
                                    label="Title"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handletitleChange(e)}
                                    variant="filled"
                                    value={this.state.title}
                                />
                                <div className="error">
                                    {this.state.contineuClicked && this.state.title == '' ? 'Enter Title' : ''}
                                </div>
                            </div>
                            <div className="inputValueDiv">
                                <SelectInput
                                    onChange={(e: any) => this.onSelectFolder(e, 'folder')}
                                    options={this.state.folderOptionsSelect}
                                    placeholder={
                                        this.state.folder && this.state.folder.label
                                            ? this.state.folder.label
                                            : 'Select Folder'
                                    }
                                    value={this.state.folder && this.state.folder.value ? this.state.folder.value : ''}
                                    label="Add to"
                                />
                                <div className="error">
                                    {this.state.contineuClicked && this.state.folder == null ? 'Select Folder' : ''}
                                </div>
                            </div>

                            <div className="inputTextValueDiv">
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    rows={6}
                                    placeholder="Enter Description here (Optional)"
                                    value={this.state.description}
                                    onChange={(e: any) => this.setState({ description: e.target.value })}
                                />
                            </div>

                            <div className="buttonDocument">
                                <Button
                                    // color="#fff"
                                    onClick={this.uploadDocuments}
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                >
                                    Finish
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        documentryAction: bindActionCreators(DocumentryAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    documentryState: state.documentry,
    commonState: state.common
});

export default connect(mapStateToProps, mapDispatchToProps)(Documentry);
