import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48.458}
      height={40.415}
      viewBox="0 0 48.458 40.415"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupMortgage_prefix__a{fill:#fff}.signupMortgage_prefix__b{fill:${slug.color_shade3}}.signupMortgage_prefix__c{fill:${slug.color_shade1}}`
            : ".signupMortgage_prefix__a{fill:#fff}.signupMortgage_prefix__b{fill:#5b7088}.signupMortgage_prefix__c{fill:#273e59}"
          }
        </style>
      </defs>
      <path
        className="signupMortgage_prefix__a"
        d="M28.599 35.078a10.487 10.487 0 118.763 4.726 10.487 10.487 0 01-8.763-4.726zm13.065-6.461h1.779l-1.9-1.386-4.181-3.043-6.084 4.429h1.782v5.834h8.6zm0 0"
      />
      <path
        className="signupMortgage_prefix__b"
        d="M43.447 28.617h-1.783v5.834h-8.6v-5.834h-1.782l6.084-4.429 4.177 3.039zm0 0"
      />
      <path
        className="signupMortgage_prefix__a"
        d="M34.586.609v18.6a10.489 10.489 0 00-5.99 15.87h-.061a5.769 5.769 0 01-5.339-5.815v-3.769H7.336L7.587.609zm0 0"
      />
      <path
        className="signupMortgage_prefix__b"
        d="M28.538 35.075v.011H6.303a5.76 5.76 0 01-5.695-5.821v-3.769h22.584v3.769a5.769 5.769 0 005.346 5.81zm0 0"
      />
      <path
        className="signupMortgage_prefix__c"
        d="M13.777 6.724h4.739a.609.609 0 100-1.217h-4.739a.609.609 0 000 1.217zm0 0M13.777 13.402h13.788a.609.609 0 100-1.217H13.777a.609.609 0 000 1.217zm0 0M28.174 19.473a.609.609 0 00-.608-.608H13.777a.609.609 0 000 1.217h13.788a.608.608 0 00.609-.609zm0 0"
      />
      <path
        className="signupMortgage_prefix__c"
        d="M37.362 18.224a11.128 11.128 0 00-2.168.213V.608A.609.609 0 0034.586 0h-27a.608.608 0 00-.608.6l-.245 24.286H.608a.608.608 0 00-.608.609v3.769a6.385 6.385 0 005.908 6.416.614.614 0 00.129.014h22.245a11.095 11.095 0 109.08-17.47zM6.3 34.477a5.157 5.157 0 01-5.086-5.213v-3.161h21.37v3.161a6.472 6.472 0 002.616 5.213zm17.5-5.213v-3.769a.608.608 0 00-.608-.608H7.95l.239-23.67h25.789v17.534a11.1 11.1 0 00-6.551 15.507 5.206 5.206 0 01-3.627-4.994zm13.562 9.932a9.879 9.879 0 01-2.616-19.405 9.879 9.879 0 112.615 19.405zm0 0"
      />
      <path
        className="signupMortgage_prefix__c"
        d="M43.805 28.126l-6.084-4.429a.609.609 0 00-.716 0l-6.084 4.429a.609.609 0 00.358 1.1h1.173v5.225a.608.608 0 00.608.608h8.6a.608.608 0 00.608-.608v-5.225h1.174a.609.609 0 00.358-1.1zm-2.749.492v5.225h-7.388v-5.224a.609.609 0 00-.528-.6l4.222-3.074 4.222 3.074a.608.608 0 00-.528.6zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
