import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './homeCard.scss';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardPrice?: string | number | boolean;
    cardContent?: string;

    boxShadow?: any;
    imgClassname?: string;
    cardPriceFirst?: string | number | boolean;
    styleHomeCard?: string;
    marginTop?: string;
    margin?: string;
    isLoading?: boolean;
}

const HomeCard = (props: cardProps) => {
    return (
        <div onClick={props.onClick} className={props.styleHomeCard ? props.styleHomeCard : 'mainCard'} style={{ height: props.height}}>
            <div className="cardContent serviceProviderCover">
                <div className="imageCardHome">
                    <img
                        className={props.cardPriceFirst ? 'imageCardHomeData' : 'imageCardHomeData disableCardImg'}
                        src={props.cardImage}
                        style={{ marginTop: props.marginTop }}
                        alt=""
                        width={props.imgWidth}
                        height={props.imgHeight}
                        //  className={props.imgClassname ? props.imgClassname : ''}
                    />
                </div>
                <div style={{ marginTop: props.margin ? '20px' : '' }}>
                   <div className="collectCardPara">{!props.isLoading && !props.cardPriceFirst ? '' : props.cardContent}</div>
                </div>
                {!props.isLoading ? <div className='desktopPriceCover'>
                    <div className="collectCardNew">
                        {' '}
                        <div className={props.cardPrice ? 'price' : 'price-nodata'}>
                            {' '}
                            {props.cardPrice ? <div className="dollar">$</div> : ''}
                            {props.cardPriceFirst || 'No Data Available'}
                        </div>
                    </div>
                </div> : <Skeleton width={100} height={40} />}
               {!props.isLoading ? <div style={{ marginBottom: props.margin ? '20px' : '' }} className="collectCardTitle desktopRangeCover">
                    {props.cardPrice ? `Range: ${props.cardPrice}` : props.cardPrice || ''}
                </div> : ""}
                {
                    <div className="mobilePriceCover">
                        {!props.isLoading ? <div>
                            <div className="collectCardNew">
                                {' '}
                                <div className={props.cardPrice ? 'price' : 'price-nodata'}>
                                    {' '}
                                    {props.cardPrice ? <div className="dollar">$</div> : ''}
                                    {props.cardPriceFirst || 'No Data Available'}
                                </div>
                            </div>
                        </div> : <Skeleton width={100} height={40} />}
                       {!props.isLoading ?  <div className="collectCardTitle">
                    {props.cardPrice ? `Range: ${props.cardPrice}` : props.cardPrice || ''}
                </div> : ""}
                    </div>
                }
            </div>
        </div>
    );
};
export default HomeCard;
