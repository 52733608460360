// import React,{useState} from 'react'
import React, { ChangeEvent, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Slider from './Slider';
import RadioButton from './RadioButton';
import Interset from './Interset';
import Button from '../../../../../components/atoms/Button';
import { SAVINGCALC_DEFAULT_VALUE, SAVINGCALC_MULTIPIER } from '../../../../../utils/constants';
import AnalyticsService from '../../../../../pages/analytics-service';

interface SavingCalcProps {
    isPostingSavingCalcData: boolean;
    postSavingsCalcData: any;
    calculatorData?: any;
    hashCode: string;
    loanDetails?: any;
    showNoDataView?: boolean;
    analyticsData: any;
}

interface SavingCalcState {
    sliderValue: number;
    years: string;
    apr: string | undefined | number | any;
}

type Itypes = {
    [monthlyPayment: string]: boolean;
    apr: boolean;
    term: boolean;
};

function Main({
    isPostingSavingCalcData,
    postSavingsCalcData,
    hashCode,
    loanDetails,
    showNoDataView,
    analyticsData,
}: SavingCalcProps) {
    const [savingCalulator, setSavingCalulator] = useState<SavingCalcState>({
        sliderValue: 0,
        years: '10',
        apr: loanDetails?.loan_percentage ? `${loanDetails.loan_percentage}` : undefined,
    });

    const [loanTermYears, setLoanTermYears] = useState(10);
    const [aprError, setAprError] = useState<boolean>(false);
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState<Itypes>({
        monthlyPayment: false,
        apr: false,
        term: false
    });
    const [isFormStarted, setIsFormStarted] = useState(false);

    const handleAnalytics = (fieldLabel: string, fieldName: string) => {
        if (!isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
                ...analyticsData,
                category_name: 'Savings Calculator Form',
                form_field_name: fieldLabel,
                hasMinimalFields: true,
            };

            if (isFormStarted) {
                AnalyticsService.formFieldInput(trackProperties);
            } else {
                AnalyticsService.formFieldStarted(trackProperties);
            }
            setIsFormStarted(true);
        }

        setIsAnalitycsTriggerredOnce({
            ...isAnalitycsTriggerredOnce,
            [fieldName]: true,
        });
    };

    const setState = (keyValueJson: any) =>{
        setSavingCalulator({ ...savingCalulator, ...keyValueJson });
    };

    const handleAprChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setSavingCalulator({ ...savingCalulator, apr: Number(value) });

        if (value === 0 || value > 100) {
            setAprError(true);
        } else {
            setAprError(false);
        }
        handleAnalytics('Enter Interest Rate (APR)', 'apr');
    };

    useEffect(() => {
        const setPrefilledData = () => {
            setLoanTermYears(loanDetails.no_of_years?.toString());
            setState({
                apr: loanDetails.loan_percentage,
                years: loanDetails.no_of_years?.toString(),
            });
        };
        loanDetails && setPrefilledData();
    }, [loanDetails]);

    const handleSliderChange = (keyVal: any) => {
        setState(keyVal);
        handleAnalytics('Select Additional Monthly Payment', 'monthlyPayment');
    }
    const handleRadioChange = (keyVal: any) => {
        setState(keyVal);
        handleAnalytics('Select Loan Term', 'term');
    }

    return (
        <div className="mainSection">
            <h3>Savings Calculator</h3>
            <Slider value={savingCalulator?.sliderValue || 0} setState={handleSliderChange} disabled={showNoDataView} />
            <RadioButton
                value={savingCalulator?.years || 10}
                setState={handleRadioChange}
                loanTermYears={loanTermYears}
                showNoDataView={showNoDataView}
            />
            <Interset
                value={savingCalulator?.apr}
                handleChange={handleAprChange}
                hasError={aprError}
                showNoDataView={showNoDataView}
            />
            {!isPostingSavingCalcData ? (
                <Button
                    onClick={() => {
                        if (hashCode) {
                            postSavingsCalcData({
                                no_of_years: parseInt(savingCalulator?.years),
                                rate_of_interest: (savingCalulator?.apr && parseInt(savingCalulator?.apr)) || 0,
                                additional_amount:
                                    savingCalulator?.sliderValue &&
                                    SAVINGCALC_DEFAULT_VALUE + savingCalulator?.sliderValue * SAVINGCALC_MULTIPIER,
                                hash_code: hashCode,
                            });
                        }
                    }}
                    margin={'2px 0px'}
                    width={'180px'}
                    height={'50px'}
                    borderRadius="4px"
                    disabled={
                        showNoDataView
                            ? showNoDataView
                            : isPostingSavingCalcData || !hashCode || !savingCalulator.apr || aprError
                    }
                    children="Calculate"
                    data-cy='calculate-savings-btn'
                />
            ) : (
                <Skeleton width={250} height={40} />
            )}
            <div className="disableSheet"></div>
        </div>
    );
}

export default Main;
