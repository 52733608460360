import React from 'react';
import '../../styles/InternetLandingFooter.scss';
import moveEasy from '../../Assets/images/moveeasyfooter.png';
import AddIcon from '@material-ui/icons/Add';

let isShowProducts = false;
let isShowContacts = false;
let isShowTerms = false;

const InternetLandingFooter = () => {

    const showProducts = () => {
        isShowProducts = !isShowProducts;
        isShowContacts = false;
        isShowTerms = false;
    }

    const showContacts = () => {
        isShowProducts = false;
        isShowContacts = !isShowContacts;
        isShowTerms = false;
    }

    const showTerms = () => {
        isShowProducts = false;
        isShowContacts = false;
        isShowTerms = !isShowTerms;
    } 

    return (
        <div className="footer-main-div">
            <div className="footer">
                <div className="footer-start">
                    <div>
                       <img src={ moveEasy} alt="" />
                    </div>
                    {/* <div className="footer-text">move easy</div> */}
                </div>

                <div className="footer-secondary-div">
                    <div className="product-div">
                        <div className="Heading-div">Products</div>
                        <div className="footer-subheading">Brokers & agent</div>
                        <div className="footer-subheading">Moving Companies</div>
                        <div className="footer-subheading">Blog</div>
                    </div>
                    <div className="contact-div">
                        <div className="Heading-div">Contact</div>
                        <div className="footer-subheading"> help@moveeasy.com </div>
                        <div className="footer-subheading">(810) 525-8185</div>
                    </div>
                    <div className="terms-div">
                        <div className="Heading-div">Terms of use </div>
                        <div className="footer-subheading">Terms and Conditions</div>
                    </div>
                </div>
            </div>
            <div className="footer-mobile">
                <div className="footerImg-div">
                    <img src={moveEasy} alt="" />
                </div>
                <div className="footer-heading-div">
                    <div className="footer-heading">Products</div>
                    <div className="footer-plus-icon">
                        <AddIcon className="addIcon" onClick={showProducts} />
                    </div>
                </div>
                {isShowProducts ? (
                <div className="footerLinks">
                    <ul className="footer-ul">
                        <li className="footer-li">Broker & agents</li>
                        <li className="footer-li">Moving companies</li>
                        <li className="footer-li">Blog</li>
                    </ul>
                </div>
                ) : null}
                <div className="footer-heading-div">
                    <div className="footer-heading">Contact</div>
                    <div className="footer-plus-icon">
                        <AddIcon className="addIcon" onClick={showContacts} />
                    </div>
                </div>
                { isShowContacts ? (
                <div className="footerLinks">
                    <ul className="footer-ul">
                        <li className="footer-li">help@moveeasy.com</li>
                        <li className="footer-li">(810) 525-8185</li>
                    </ul>
                </div>
                ) : null}
                <div className="footer-heading-div">
                    <div className="footer-heading">Terms of use</div>
                    <div className="footer-plus-icon">
                        <AddIcon className="addIcon" onClick={showTerms} />
                    </div>
                </div>
                { isShowTerms ? (
                <div className="footerLinks">
                    <ul className="footer-ul">
                        <li className="footer-li">Terms and Conditions</li>
                    </ul>
                </div>
                ) : null}
            </div>
            <div className="footer-bottom-div">
                <div>©2021 MoveEasy | All right reserved</div>
            </div>
        </div>
    );
};
export default InternetLandingFooter;
