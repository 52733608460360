import React, { Component } from 'react';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import Circle from '../../../Assets/images/UpdateAddress/updateAddSubmit.svg';
import './updateAddressModal.scss';
import Back from '../../../Assets/images/UpdateAddress/updateAddBackArrow.svg';

type initialProps = {
    showVerificationModal: boolean;
    handleCloseModal?: any;
    successText: string;
    btnText: string;
    btnBackgroundColor: any;
    btnWidth: string;
    btnBorderColor: any;
    isDouglasElliman: boolean;
};

export default class SubmitRequestModal extends Component<initialProps> {
    render() {
        const {
            showVerificationModal,
            handleCloseModal,
            successText,
            btnText,
            btnBackgroundColor,
            btnWidth,
            btnBorderColor,
            isDouglasElliman,
        } = this.props;
        return (
            <Modal
                isShowModal={showVerificationModal}
                onCloseModal={() => handleCloseModal()}
                showClose={false}
                className="updateAddSubmitModal"
            >
                <div>
                    {window.innerWidth <= 600 && (
                        <div
                            style={{ paddingLeft: '12px', paddingTop: '23px', cursor: 'pointer' }}
                            onClick={() => handleCloseModal()}
                        >
                            <img src={Back} width="24px" height="24px" alt="" />
                        </div>
                    )}

                    <div className="updateAddSubmitMain">
                        <div className="updateAddCircle">
                            <img src={Circle} width="77px" height="77px" alt="" />
                        </div>
                        <div className="updateAddSuccessText">{successText}</div>

                        <div className="updateAddMakeReq">
                            <Button
                                backgroundColor={
                                    isDouglasElliman ? '#100B28' : btnBackgroundColor ? btnBackgroundColor : '#273E59'
                                }
                                borderRadius={isDouglasElliman ? '0px' : '4px'}
                                border={isDouglasElliman ? '1px solid #100B28' : `1px solid ${btnBorderColor}`}
                                fontSize="16px"
                                width={btnWidth}
                                onClick={() => handleCloseModal()}
                            >
                                {btnText}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
