import React, { Component } from 'react';
import '../../styles/utilityCards.scss';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import Button from '../../components/atoms/Button';
import Skeleton from 'react-loading-skeleton';

type initialProps = {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardSubTitle?: string;
    phone?: string;
    url?: string;
    boxShadow?: any;
    DoItYourselfHandler?: any;
    placeOrderHandler?: any;
    showPlaceOrder?: boolean;
    showDoIt?: boolean;
    isDouglasElliman?: boolean;
};
export class utilityNewCards extends Component<initialProps> {
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        return (
            <div
                className="utilityIndividualCard"
                style={{
                    // width: this.props.width ? this.props.width : '356px',
                    height: this.props.height ? this.props.height : 'auto',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: this.props.borderRadius ? this.props.borderRadius : '8px',
                    boxShadow: this.props.boxShadow ? this.props.boxShadow : '0 0 10px #00000029',
                    border: this.props.border ? this.props.border : '1px solid #E2E2E2',
                    opacity: '1',
                    margin: this.props.cardMargin ? this.props.cardMargin : '0px 10px 0px 0px',
                    padding: this.props.cardPadding ? this.props.cardPadding : '20px 10px 20px 10px',
                }}
            >
                <div className="utilityCardMain">
                    {/* <img
                        width={this.props.imgWidth ? this.props.imgWidth : '35px'}
                        height={this.props.imgHeight ? this.props.imgHeight : '50px'}
                        src={this.props.cardImage}
                        alt=""
                    /> */}
                    {this.props.cardImage ? (
                        <div
                            style={{
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {this.props.cardImage}
                            {/* <img src={this.props.cardImage} alt="" /> */}
                        </div>
                    ) : (
                        <Skeleton height={50} width={40} />
                    )}
                    <div className="headCard">{this.props.cardTitle ? this.props.cardTitle : <Skeleton />}</div>
                    <div className="headSubCard">
                        {this.props.cardSubTitle ? (
                            this.props.cardSubTitle && this.props.cardSubTitle != 'null' ? (
                                this.props.cardSubTitle
                            ) : null
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div className="subCard">
                        {this.props.phone ? (
                            <div style={{ overflowWrap: 'anywhere', display: 'flex' }} className="utiCover">
                                <PhoneIcon className="phoneutility" />
                                <span className="subhead1">
                                    {this.props.phone && this.props.phone != 'null' ? this.props.phone : 'Not Found'}
                                </span>
                            </div>
                        ) : (
                            <Skeleton height={20} width={80} />
                        )}
                        {this.props.url ? (
                            this.props.url != 'null' &&
                            (this.props.url != undefined || this.props.url != 'undefined') ? (
                                <div style={{ overflowWrap: 'anywhere', display: 'flex', cursor: 'pointer' }} className="utiCover">
                                    <LanguageIcon className="phoneutility" />
                                    <a
                                        className="subhead1"
                                        onClick={() => window.open(this.props.url, '_blank')}
                                        // >{`${this.props.cardTitle} link`}</a>
                                    >{`${this.props.url &&
                                        this.props.url.split('/')[2] &&
                                        this.props.url.split('/')[2].substring(4)}`}</a>
                                </div>
                            ) : null
                        ) : (
                            <Skeleton height={20} width={80} />
                        )}
                    </div>
                    <div className="buttonsDiv">
                        {this.props.showDoIt ? (
                            this.props.DoItYourselfHandler ? (
                                <Button
                                    backgroundColor="#FFFFFF"
                                    boxShadow={
                                        this.props.isDouglasElliman
                                            ? 'none'
                                            : slug && slug.primary_color
                                            ? `0 0 3px 0 ${slug.primary_color}`
                                            : '0 0 3px 0 #273E59'
                                    }
                                    border={this.props.isDouglasElliman ? '1px solid #100B28' : 'none'}
                                    color={
                                        this.props.isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    borderRadius={this.props.isDouglasElliman ? '0px' : '5px'}
                                    fontSize="14px"
                                    fontWeight="600"
                                    height="45px"
                                    width="165px"
                                    margin="0px 10px 0px 0px"
                                    onClick={() => {
                                        window.open(this.props.url, '_blank');
                                        this.props.DoItYourselfHandler();
                                    }}
                                >
                                    Do It Yourself
                                </Button>
                            ) : (
                                <div style={{ marginRight: '10px' }}>
                                    <Skeleton height={45} width={150} />
                                </div>
                            )
                        ) : null}

                        {this.props.showPlaceOrder ? (
                            this.props.placeOrderHandler ? (
                                <Button
                                    backgroundColor={
                                        this.props.isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    fontSize="14px"
                                    fontWeight="600"
                                    height="45px"
                                    width="165px"
                                    onClick={() => this.props.placeOrderHandler()}
                                    className={`${!this.props.isDouglasElliman && 'placeOrderBtn'}`}
                                >
                                    Place an Order
                                </Button>
                            ) : (
                                <Skeleton height={45} width={150} />
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default utilityNewCards;
