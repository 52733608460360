import {
    HEADER_OPTIONS,
    HEADER_OPTIONS_ERROR,
    HEADER_OPTIONS_SUCCESS,
    REALTOR_DETAILS,
    REALTOR_DETAILS_ERROR,
    REALTOR_DETAILS_SUCCESS,
    MOVE_API_GET,
    MOVE_API_GET_ERROR,
    MOVE_API_GET_SUCCESS,
    MOVE_ADDRESS_UPDATE,
    MOVE_ADDRESS_UPDATE_ERROR,
    MOVE_ADDRESS_UPDATE_SUCCESS,
    REQUEST_SERVICE,
    REQUEST_SERVICE_ERROR,
    REQUEST_SERVICE_SUCCESS,
    REQUEST_SERVICE_GET,
    REQUEST_SERVICE_GET_ERROR,
    REQUEST_SERVICE_GET_SUCCESS,
    MOVE_API_WITH_HASHCODE_GET,
    MOVE_API_WITH_HASHCODE_GET_ERROR,
    MOVE_API_WITH_HASHCODE_GET_SUCCESS,
    APPOINTMENT_DETAILS_GET,
    APPOINTMENT_DETAILS_GET_SUCCESS,
    APPOINTMENT_DETAILS_GET_ERROR,
    POST_CANCELLATION_GET,
    POST_CANCELLATION_GET_SUCCESS,
    POST_CANCELLATION_GET_ERROR,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS,
    GET_SERVICE_AGENTS_SUCCESS,
    GET_SERVICE_PROVIDERS_SUCCESS,
    REQUEST_SERVICE_AGENT_BY_SERVICES,
    REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS,
    REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR,
    GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS,
} from './actionTypes';
import * as types from './types';

export const header = (payload: types.HeaderRequest): types.Header => ({
    type: HEADER_OPTIONS,
    payload,
});

export const headerSuccess = (payload: types.HeaderSuccessPayload): types.HeaderSuccess => ({
    type: HEADER_OPTIONS_SUCCESS,
    payload,
});

export const headerFailure = (payload: types.HeaderFailurePayload): types.HeaderFailure => ({
    type: HEADER_OPTIONS_ERROR,
    payload,
});

export const requestService = (payload: types.RequestServiceRequest): types.RequestService => ({
    type: REQUEST_SERVICE,
    payload,
});

export const requestServiceSuccess = (payload: types.RequestServiceSuccessPayload): types.RequestServiceSuccess => ({
    type: REQUEST_SERVICE_SUCCESS,
    payload,
});

export const requestServiceFailure = (payload: types.RequestServiceFailurePayload): types.RequestServiceFailure => ({
    type: REQUEST_SERVICE_ERROR,
    payload,
});

export const requestServiceGet = (): types.RequestServiceGet => ({
    type: REQUEST_SERVICE_GET,
});

export const requestServiceGetSuccess = (
    payload: types.RequestServiceGetSuccessPayload,
): types.RequestServiceGetSuccess => ({
    type: REQUEST_SERVICE_GET_SUCCESS,
    payload,
});

export const requestServiceGetFailure = (
    payload: types.RequestServiceGetFailurePayload,
): types.RequestServiceGetFailure => ({
    type: REQUEST_SERVICE_GET_ERROR,
    payload,
});

export const realtor = (payload: types.RealtorRequest): types.Realtor => ({
    type: REALTOR_DETAILS,
    payload,
});

export const realtorSuccess = (payload: types.RealtorSuccessPayload): types.RealtorSuccess => ({
    type: REALTOR_DETAILS_SUCCESS,
    payload,
});

export const realtorFailure = (payload: types.RealtorFailurePayload): types.RealtorFailure => ({
    type: REALTOR_DETAILS_ERROR,
    payload,
});

export const moveGet = (): types.MoveGet => ({
    type: MOVE_API_GET,
});

export const moveGetSuccess = (payload: types.MoveGetSuccessPayload): types.MoveGetSuccess => ({
    type: MOVE_API_GET_SUCCESS,
    payload,
});

export const moveGetFailure = (payload: types.MoveGetFailurePayload): types.MoveGetFailure => ({
    type: MOVE_API_GET_ERROR,
    payload,
});

export const moveAddressUpdate = (payload: types.MoveAddressRequest): types.MoveAddress => ({
    type: MOVE_ADDRESS_UPDATE,
    payload,
});

export const moveAddressUpdateSuccess = (payload: types.MoveAddressSuccessPayload): types.MoveAddressSuccess => ({
    type: MOVE_ADDRESS_UPDATE_SUCCESS,
    payload,
});

export const moveAddressUpdateFailure = (payload: types.MoveAddressFailurePayload): types.MoveAddressFailure => ({
    type: MOVE_ADDRESS_UPDATE_ERROR,
    payload,
});

export const moveGetWithHashCode = (payload: types.MoveGetWithHashCodeRequest): types.MoveGetWithHashCode => ({
    type: MOVE_API_WITH_HASHCODE_GET,
    payload,
});

export const moveGetWithHashCodeSuccess = (
    payload: types.MoveGetWithHashCodeSuccessPayload,
): types.MoveGetWithHashCodeSuccess => ({
    type: MOVE_API_WITH_HASHCODE_GET_SUCCESS,
    payload,
});

export const moveGetWithHashCodeFailure = (
    payload: types.MoveGetWithHashCodeFailurePayload,
): types.MoveGetWithHashCodeFailure => ({
    type: MOVE_API_WITH_HASHCODE_GET_ERROR,
    payload,
});

//Appointment
export const appointmentGet = (payload: types.AppointmentGetRequest): types.AppointmentGet => ({
    type: APPOINTMENT_DETAILS_GET,
    payload,
});

export const appointmentGetSuccess = (payload: types.AppointmentGetSuccessPayload): types.AppointmentGetSuccess => ({
    type: APPOINTMENT_DETAILS_GET_SUCCESS,
    payload,
});

export const appointmentGetFailure = (payload: types.AppointmentGetFailurePayload): types.AppointmentGetFailure => ({
    type: APPOINTMENT_DETAILS_GET_ERROR,
    payload,
});

//post cancellation
export const cancelAppointment = (payload: types.CancelAppointmentRequest) => ({
    type: POST_CANCELLATION_GET,
    payload,
});

export const cancelAppointmentSuccess = (
    payload: types.CancelAppointmentSuccessPayload,
): types.CancelAppointmentSuccess => ({
    type: POST_CANCELLATION_GET_SUCCESS,
    payload,
});

export const cancelAppointmentFailure = (
    payload: types.CancelAppointmentFailurePayload,
): types.CancelAppointmentFailure => ({
    type: POST_CANCELLATION_GET_ERROR,
    payload,
});

//schedule call post
export const scheduleCallPost = (payload: types.ScheduleCallPostRequest) => ({
    type: SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED,
    payload,
});

export const scheduleCallPostSuccess = (
    payload: types.ScheduleCallPostSuccessPayload,
): types.ScheduleCallPostSuccess => ({
    type: SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS,
    payload,
});

export const scheduleCallPostFailure = (
    payload: types.ScheduleCallPostFailurePayload,
): types.ScheduleCallPostFailure => ({
    type: SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR,
    payload,
});

// agents/providers data api
export const getServiceAgentInfo = (
    payload: types.GetServiceAgentSuccessPayload,
): types.GetServiceAgentSuccess => ({
    type: GET_SERVICE_AGENTS_SUCCESS,
    payload,
});

export const getServiceProviderInfo = (
    payload: types.GetServiceProviderSuccessPayload,
): types.GetServiceProviderSuccess => ({
    type: GET_SERVICE_PROVIDERS_SUCCESS,
    payload,
});

export const getInspectionServiceProviderInfo = (
    payload: types.GetInspectionServiceProviderSuccessPayload,
): types.GetInspectionServiceProviderSuccess => ({
    type: GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS,
    payload,
});


//serviceAgenByServices
export const serviceAgenByServices = (payload: types.ServiceAgenByServicesRequest) => ({
    type: REQUEST_SERVICE_AGENT_BY_SERVICES,
    payload,
});

export const serviceAgenByServicesSuccess = (
    payload: types.ServiceAgenByServicesSuccessPayload,
): types.ServiceAgenByServicesSuccess => ({
    type: REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS,
    payload,
});

export const serviceAgenByServicesFailure = (
    payload: types.ServiceAgenByServicesFailurePayload,
): types.ServiceAgenByServicesFailure => ({
    type: REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR,
    payload,
});