import React, { Component } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import adt from '../../Assets/images/HomeSecurity/adt.png';
import CheckIcon from '@material-ui/icons/Check';

type initialProps = {
    onBack?: any;
    data?: any;
};

type initialState = {
};

class CoverageDetails extends Component<initialProps, initialState> {
    state: initialState = {
    }
    render() {
        const { data } = this.props;
        return (
            <div className="coverageMainDiv" style={{ overflow: 'auto', height: '100vh' }}>
                <div className="backIcon">
                    <ArrowBackIcon fontSize="large" onClick={this.props.onBack} style={{cursor: "pointer"}} />{' '}
                    <span style={{ marginLeft: '15px' }}>Back</span>
                </div>
                <div className="policyDetailsDiv">
                    <div>
                        {data.cardLogo ? <img src={data.cardLogo} alt="" /> : <img src={adt} alt="" />}
                        
                    </div>
                    <div className="monthlyFeeDiv">
                        <div className="feeDiv">
                            <span className="dollor">$</span>
                            <span className="amount">{data.monthlyfees ? data.monthlyfees : "27.99 -59.99"}</span>
                        </div>
                        <div className="monthlyText">Monthly fee</div>
                    </div>
                </div>
                <div className="aboutDiv">
                    <div className="aboutHeadingInsurance">About {data && data.title}</div>
                    <div className="aboutContentInsurance">
                        {data.about ? data.about : "ADT is Committed to Your Home and Business Security. For 145 years, ADT has made the security of our customers our top priority. Today, more than 17,000 professionals in over 200 locations throughout the U.S. ensure that our over 6 million customers stay as safe and secure as possible."}
                    </div>
                </div>

                <div className="aboutDiv">
                    <div className="aboutHeadingInsurance">Top features & discounts</div>
                    {data.featuresAndDiscounts && data.featuresAndDiscounts.map((item: any, i: number) => {
                    return (
                        <div key={i} className="featuresDiv">
                        <CheckIcon className="checkIcon" />
                        {item}
                    </div>
                    )
                } 
                    )}
                </div>
            </div>
        );
    }
}

export default CoverageDetails;
