import React from 'react';
import Button from '../Button';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import '../../../styles/checklistAddTasks.scss';
import DeleteIcon from '../../../Assets/images/delete-24px.svg';

type IAddTasksProps = {
    handleTasksChange: any;
    value: any;
    onClose: any;
    onSave: any;
    onDelete?: any;
    isDouglasElliman?: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '45ch',
            },
        },
    }),
);
let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
export const AddTasks = (props: IAddTasksProps) => {
    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);

    return (
        <div
            className={
                props.isDouglasElliman
                    ? 'checklist-overdue-addTasks checklist-overdue-addTasksDouglas'
                    : 'checklist-overdue-addTasks'
            }
        >
            <div className={classes.root}>
                <TextField
                    id="input"
                    label=""
                    value={props.value}
                    placeholder="Enter new task here eg. inform my neighbours and hit enter to add it to the list"
                    multiline
                    onChange={(e: any) => props.handleTasksChange(e)}
                    variant="outlined"
                />
            </div>
            <div className="checklist-overdue-addTasks-btn-wrapper">
                <div className="checklist-overdue-addTasks-save">
                    <Button
                        backgroundColor={props.isDouglasElliman ? '#009CBD' : '#006DFF'}
                        borderRadius="4px"
                        border={props.isDouglasElliman ? '1px solid #009CBD' : '1px solid #006DFF'}
                        width="86px"
                        height="53px"
                        margin="0px 0px 0px 10px"
                        marginTop="0px"
                        disabled={props.value ? false : true}
                        onClick={props.onSave}
                    >
                        Save
                    </Button>
                </div>
                <div className="checklist-overdue-addTasks-cancel">
                    <Button
                        border={`1px solid ${
                            props.isDouglasElliman
                                ? '#009CBD'
                                : slug && slug.primary_color
                                ? slug.primary_color
                                : '#273e59'
                        }`}
                        backgroundColor="#fff"
                        color={
                            props.isDouglasElliman
                                ? '#009CBD'
                                : slug && slug.primary_color
                                ? slug.primary_color
                                : '#273e59'
                        }
                        width="93px"
                        height="53px"
                        margin="0px 0px 0px"
                        marginTop="0px"
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};
export const EditTask = (props: IAddTasksProps) => {
    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <div
            className={
                props.isDouglasElliman
                    ? 'checklist-overdue-editasks checklist-overdue-editasksDoug'
                    : 'checklist-overdue-editasks'
            }
        >
            <div className={classes.root}>
                <TextField
                    id="input"
                    label=""
                    value={props.value}
                    placeholder="Enter new task here eg. inform my neighbours and hit enter to add it to the list"
                    multiline
                    onChange={(e: any) => props.handleTasksChange(e)}
                    variant="outlined"
                />
            </div>
            <div className="checklist-overdue-editTasks-btn-wrapper">
                <div className="checklist-overdue-editTasks-delete" onClick={props.onDelete}>
                    <img src={DeleteIcon} alt="" />
                </div>
                <div
                    className={
                        isDouglasElliman ? 'checklist-overdue-editTasks-save' : 'checklist-overdue-editTasks-saveNoDoug'
                    }
                >
                    <Button
                        backgroundColor={props.isDouglasElliman ? '#009cbd' : '#006DFF'}
                        borderRadius="4px"
                        border={props.isDouglasElliman ? '1px solid #009CBD' : '1px solid #006DFF'}
                        width="86px"
                        height="53px"
                        margin="0px 0px 0px"
                        marginTop="0px"
                        onClick={props.onSave}
                        disabled={props.value ? false : true}
                    >
                        Save
                    </Button>
                </div>
                <div className="checklist-overdue-editTasks-cancel">
                    <Button
                        border={`1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`}
                        backgroundColor="#fff"
                        color={slug && slug.primary_color ? slug.primary_color : '#273e59'}
                        width="93px"
                        height="53px"
                        margin="0px 0px 0px"
                        marginTop="0px"
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

// export default AddTasks;
