import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/atoms/Button';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './AutoShippingAlmostDone.scss';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import { InputLabel } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Action from '../../../store/common/actions';
import * as AutoShippingAction from '../../../store/autoshipping/actions';
import * as DashboardAction from '../../../store/dashboard/actions';
import { formCompletedSegment, scheduleSegment } from '../../../utils/utlis';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../../utils/common-functions';

type initialProps = {
    location: any;
    history: any;
    commonState: any;
    commonAction: any;
    autoShippingAction?: any;
    autoShippingState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    formData: any;
    checkedG: boolean;
    vehicleDetails: any;
    destinationZip: string;
    destinationAddress: string;
    pickupZip: string;
    pickupAddress: string;
    transportType: string;
    isShowPlan: boolean;
    isStateDataArrived: boolean;
    pickupDate: any;
    anchorEl: any;
    isDataArrived2: boolean;
    phoneError: string;
    isDouglasElliman: boolean;
    brokerage_name: string;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    moveId: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
    concierge_ref: any;
};

class AutoShippingAlmostDone extends Component<initialProps, initialState> {
    state: initialState = {
        formData: {
            fullName: '',
            email: '',
            phoneNumber: '',
            dateTime: null,
        },
        checkedG: false,
        vehicleDetails: [],
        destinationZip: '',
        destinationAddress: '',
        pickupZip: '',
        pickupAddress: '',
        transportType: '',
        isShowPlan: true,
        isStateDataArrived: true,
        pickupDate: '',
        anchorEl: null,
        isDataArrived2: true,
        phoneError: '',
        isDouglasElliman: false,
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        moveId: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
        concierge_ref: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
    }

    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            currentState.isStateDataArrived
        ) {
            currentState.isStateDataArrived = false;
            currentState.vehicleDetails = nextProps.history.location.state.vehicleDetails;
            currentState.destinationZip = nextProps.history.location.state.destinationZip;
            currentState.destinationAddress = nextProps.history.location.state.destinationAddress;
            currentState.pickupZip = nextProps.history.location.state.pickupZip;
            currentState.pickupAddress = nextProps.history.location.state.pickupAddress;
            currentState.transportType = nextProps.history.location.state.transportType;
            currentState.pickupDate = nextProps.history.location.state.pickupDate;
            currentState.formData.dateTime = nextProps.history.location.state.pickupDate;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            let moveData = nextProps.commonState.move[0];

            currentState.moveId = moveData.id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.formData.fullName = `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            currentState.formData.email = nextProps.commonState.move[0].user_ref.email;
            currentState.formData.phoneNumber = nextProps.commonState.move[0].user_ref.phone;
            currentState.concierge_ref =
                nextProps.commonState.move[0] && nextProps.commonState.move[0].concierge_reference_field;
        }

        if (nextProps && nextProps.autoShippingState && nextProps.autoShippingState.postDataArrived) {
            nextProps.autoShippingState.postDataArrived = false;

            updateChecklistTask(100, nextProps.dashboardAction);

            nextProps.history.push({
                pathname: '/dashboard/autoease/thank_you',
                state: {
                    vehicleDetails: currentState.vehicleDetails,
                    pickupAddress: currentState.pickupAddress,
                    destinationAddress: currentState.destinationAddress,
                    pickupDate: currentState.formData.dateTime,
                    transportType: currentState.transportType,
                },
            });
            window.scrollTo(0, 0);
        }
        return currentState;
    }

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    handleChange = (e: any, name: string) => {
        let formData: any = '';
        if (name == 'dateTime') {
            formData = { ...this.state.formData, [name]: e };
        } else if (name === 'phoneNumber') {
            let phonenumber = this.formatPhoneInput(e.currentTarget.value);
            formData = { ...this.state.formData, [name]: phonenumber };
        } else {
            formData = { ...this.state.formData, [name]: e.target.value };
        }
        this.setState({ formData: formData });
    };

    handleChangeCheckBox = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await this.setState({ checkedG: event.target.checked });
    };

    validateForm = () => {
        let isValid: boolean = true;

        if (this.state.formData.phoneNumber == '') {
            isValid = false;
            this.setState({ phoneError: 'Please enter phone number' });
        }

        return isValid;
    };

    scheduleCall = () => {
        if (this.validateForm()) {
            this.props.autoShippingAction.postShipping({
                vehicle_infos: this.state.vehicleDetails,
                num_vehicles: this.state.vehicleDetails.length,
                first_avalaible_date: this.state.formData.dateTime.toISOString().split('T')[0],
                phone: this.state.formData.phoneNumber ? this.state.formData.phoneNumber : '123-456-7890',
                origin: this.state.pickupAddress,
                destination: this.state.destinationAddress,
                transport_type: this.state.transportType == 'Enclosed' ? 'Enclosed' : 'OpenTrailer',
                vehicle_condition: true,
            });
        }
        let move_id: any = localStorage.getItem('moveKey');
        formCompletedSegment(
            'Form Completed',
            'Order Placing Page',
            'Moving Services',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Schedule a call',
            true,
            this.state.prefilled,
            'Schedule a call with your concierge Form',
            1,
            move_id,
        );

        scheduleSegment(
            'Button Clicked',
            'Order Placing Page',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Moving Services',
            'Schedule a call',
            false,
            ' ',
            false,
            ' ',
            move_id,
        );
    };

    expandMore = () => {
        this.setState({ isShowPlan: !this.state.isShowPlan });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;

        const breadList = [
            {
                name: 'Moving Services',
                url: '/dashboard/movers'
            },
            {
                name: 'Auto shipping',
                url: '/dashboard/autoease'
            },
            {
                name: 'Order Placing',
                url: ''
            }
        ]

        return (
            <div className="autoshipping-main-page">
                <div
                    className={
                        isDouglasElliman ? 'main-head-moving-main moving-main-pageDouglas' : 'main-head-moving-main'
                    }
                >
                    <h2 className="heading">Moving Services</h2>
                    <BreadCrumb breadList={breadList} />
                </div>
                <div className="autoShippingHeading">Great, you are almost done</div>
                <div className="autoShippingSubHeading">
                    Set up a call with our {this.state.concierge_ref} team for further placing the order
                    <ReactTooltip place="top" type="dark" effect="float" multiline={true} />{' '}
                    <InfoIcon
                        data-tip="sgdhfjhsdj<br />hhhhggds<br />hhh."
                        style={{
                            color: '#6B6C6F',
                            marginLeft: '10px',
                            marginBottom: '-5px',
                        }}
                    />
                </div>

                <div
                    className={
                        isDouglasElliman
                            ? 'autoShippingOrderFlexDiv autoShippingOrderFlexDivD'
                            : 'autoShippingOrderFlexDiv'
                    }
                >
                    <div className="autoShippingFormDiv">
                        <div
                            className="formHeading"
                            style={{
                                backgroundColor: isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : '#273E59',
                                color: isDouglasElliman
                                    ? '#FFF'
                                    : slug && slug.primary_font_color
                                    ? slug.primary_font_color
                                    : '#FFFFFF',
                            }}
                        >
                            Schedule a call with your {this.state.concierge_ref}
                        </div>
                        <div className="personalDetailsAutoshipping">
                            <div className="personalDetailsHeading">Personal Details</div>
                            <div className="autoshippingInputFlexDiv">
                                <div className="autoshippingOrderInput">
                                    <TextField
                                        className="autoshippingInput1"
                                        label="Full Name"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'fullName')}
                                        variant="filled"
                                        value={this.state.formData.fullName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="autoshippingOrderInput">
                                    <TextField
                                        className="autoshippingInput1"
                                        label="Phone Number"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'phoneNumber')}
                                        variant="filled"
                                        value={this.state.formData.phoneNumber}
                                    />
                                    <div
                                        className="errorMoving"
                                        // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                    >
                                        {this.state.formData.phoneNumber == '' ? (
                                            <small>{this.state.phoneError}</small>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="autoshippingOrderInput">
                                    <TextField
                                        className="autoshippingInput1"
                                        label="Email Id"
                                        id="outlined-start-adornment"
                                        onChange={(e: any) => this.handleChange(e, 'email')}
                                        variant="filled"
                                        value={this.state.formData.email}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="personalDetailsHeading">Scheduling Details</div>

                            <div className="autoshippingInputFlexDiv">
                                <div className="autoshippingDateInput">
                                    <div className="datePickerDiv">
                                        {/* <div className="inputLabel">
                                        <InputLabel className="inputLable">Available pickup date</InputLabel>
                                    </div> */}

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disablePast
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Available pickup date"
                                                className="date-autoshipping"
                                                value={this.state.formData.dateTime}
                                                onChange={(e: any) => {
                                                    this.handleChange(e, 'dateTime');
                                                    this.setState({
                                                        anchorEl: null,
                                                    });
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                    onFocus: e => {
                                                        this.handleClick(e);
                                                    },
                                                }}
                                                autoOk={true}
                                                PopoverProps={{
                                                    disableRestoreFocus: true,
                                                    onClose: () => {
                                                        this.setState({ anchorEl: null });
                                                    },
                                                    anchorEl: this.state.anchorEl,
                                                }}
                                                InputProps={
                                                    {
                                                        //   onFocus: (e) => {
                                                        //       this.handleClick(e)
                                                        //   },
                                                    }
                                                }
                                                // open={Boolean(this.state.anchorEl)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                {/* <div className="checkbox-div">
                                    <FormControlLabel
                                        label="I am flexible on time"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                className="checkbox"
                                                checked={this.state.checkedG}
                                                onChange={this.handleChangeCheckBox}
                                                name="checkedG"
                                            />
                                        }
                                    />
                                </div> */}
                            </div>
                            <div>
                                <Button
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273E59'
                                    }
                                    onClick={this.scheduleCall}
                                >
                                    Schedule a Call
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="autoShippingPlansDiv">
                        <div className="planDetailHeading">
                            Plan Details <ExpandMoreIcon className="expandIcon" onClick={this.expandMore} />
                        </div>
                        {this.state.isShowPlan ? (
                            <div className="planDetails">
                                <div>
                                    <div className="destinationText">Move details</div>
                                    <div className="destDetails">{`From - ${this.state.pickupZip}, ${this.state.pickupAddress}`}</div>
                                    <div className="destDetails">{`To - ${this.state.destinationZip}, ${this.state.destinationAddress}`}</div>
                                    <div className="destDetails">{`Transport type - ${this.state.transportType}`}</div>
                                </div>

                                <div className="vehicalDetailsDiv">
                                    {/* <hr style={{border: "1px solid #C6C6C6"}}/> */}
                                    <div className="destinationText">Vehical details</div>
                                    {this.state.vehicleDetails.map((vehicleDetails: any, index: number) => {
                                        return (
                                            <div
                                               key={index}
                                                style={{
                                                    borderBottom:
                                                        this.state.vehicleDetails.length - 1 === index
                                                            ? 'none'
                                                            : '1px solid #C6C6C6',
                                                }}
                                            >
                                                <div className="destDetails">{`Vehicle make - ${vehicleDetails.make}`}</div>
                                                <div className="destDetails">{`Model - ${vehicleDetails.model}`}</div>
                                                <div className="destDetails">{`Operable - ${vehicleDetails.operable}`}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        autoShippingAction: bindActionCreators(AutoShippingAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    autoShippingState: state.autoShipping,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoShippingAlmostDone);
