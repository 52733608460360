import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            // width={293}
            // height={164}
            // width={props.width ? props.width : 293}
            // height={props.height ? props.height : 164}
            viewBox="0 0 293 164"
        >
            <defs>
                <clipPath id="prefix__a">
                    <rect
                        width={293}
                        height={164}
                        rx={4}
                        transform="translate(655 261)"
                        fill="#765b13"
                        stroke="#765b13"
                        strokeWidth={0.2}
                    />
                </clipPath>
                <style>
                    {`.InsuranceMainGirlprefix__g{fill:${
                        slug && slug.color_shade4
                            ? slug.color_shade4
                            : // : window.location.pathname.includes('landing')
                              // ? 'red'
                              '#8195ac'
                    }}.prefix__e{fill:#ffd5c0}.InsuranceMainGirlprefix__f{fill:${
                        slug && slug.primary_color
                            ? slug.primary_color
                            : window.location.pathname.includes('landing')
                            ? '#1D7E64'
                            : '#273e59'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-655 -261)" clipPath="url(#prefix__a)">
                <path
                    d="M840.2 322.066c30.216 5.4 54.976.862 78.888 29 22.623 26.576 19.425 51.243 1.061 73.232-47.974 57.441-107.858-9.381-175.491-17.1-58.922-6.75-85.256-102.434-38.621-138.648 15.03-11.668 45.659-23.278 65.924-9.637 28.386 19.118 26.747 55.731 68.239 63.153z"
                    fill="#fff1cd"
                />
                <path
                    d="M840.2 322.066c30.216 5.4 54.976.862 78.888 29 22.623 26.576 19.425 51.243 1.061 73.232-47.974 57.441-107.858-9.381-175.491-17.1-58.922-6.75-85.256-102.434-38.621-138.648 15.03-11.668 45.659-23.278 65.924-9.637 28.386 19.118 26.747 55.731 68.239 63.153z"
                    opacity={0.9}
                    fill={
                        slug && slug.color_shade4
                            ? slug.color_shade4
                            : window.location.pathname.includes('landing')
                            ? '#5CBD9D'
                            : '#8195ac'
                    }
                />
                <path d="M860.982 293.342a9.768 9.768 0 00-12.76.811 10.143 10.143 0 00-7.959-.162c-4.923 2.049-8.7 9.089-5.505 13.593.366 4.256 1.6 7.739 5.706 8.326 1.85 4.783 9.25 7.07 14.17 3.45 3.663 1.7 8.032.049 11.08-2.642.719-.635 1.395-1.694 1.652-1.812 0 0 10.089-.533 10.433-10.191.361-10.109-9.121-18.344-16.817-11.373z" />
                <path
                    className="prefix__e"
                    d="M807.841 407.883a56.254 56.254 0 00.035 8.816c.029.229.622 1.2.657 1.432-1.727 4.951-5.586 7.13-9.033 8.5 1.286-2.384 3.439-4.94 3.4-5.556a77.614 77.614 0 00-1.157-11.238c2.488.359 3.741-.943 6.098-1.954z"
                />
                <path
                    className="InsuranceMainGirlprefix__f"
                    d="M827.922 347.382l13.7 14.388s-8.7 6.605-11.965 9.057-19.147 10.749-19.487 11.367a22.7 22.7 0 01.482 3.744c-.041 5.315-.641 15.584-1.559 24.338-1.872 1.513-5.494 1.46-7.7 1.726-3.854-11.4-3.077-32.23-2.731-34.7.567-4.02 29.26-29.92 29.26-29.92z"
                />
                <path
                    className="prefix__e"
                    d="M869.084 380.157a73.79 73.79 0 00-8.572 8.576c-1.347-1.831-1.917-3.622-3.608-5.15 2.4-1.769 7.458-6.294 8.19-7.55 1.737-2.979 2.6-3.188 2.6-3.188s8.948 7.511 9.266 8.786-5.257-2.703-7.876-1.474z"
                />
                <path
                    className="InsuranceMainGirlprefix__g"
                    d="M864.721 376.698c-.8-.3 1.415-3.737 1.907-4.535.554-.9 1.2-.986 1.345-.97.4.043 10.341 8.391 11.746 10.568a2.6 2.6 0 01.228.63c.317 2.459-9.211-.7-10.24-2.114-.915-1.256-1.407-2.235-4.986-3.579z"
                />
                <path
                    className="InsuranceMainGirlprefix__f"
                    d="M824.124 350.534l14.248-3.8c-.057.017 0 .583.006.627.545 5.31 7.554 4.271 9.086 13.575 1.082 6.574-2.75 27.661-2.75 27.661s6.9-3.78 14.163-7.825c.662-.369 4.715 5.518 4.715 5.518-10.973 13.931-25.668 17.94-27.343 17.014-5.716-3.163-9.407-44.838-9.392-44.832-.847-2.577-2.733-7.938-2.733-7.938z"
                />
                <path
                    className="prefix__e"
                    d="M850.464 319.298s17.01-1.163 20.765-1.2 17.161-2.487 17.68-2.6 3.4-6.568 4.038-5.965-1.64 3.221-.807 3.765 9.015-1.625 8.966-.972c-.093 1.222-4.958 1.527-4.9 2.107s5.046-.806 5.481.125-4.841.854-5.009 1.3 4.96.759 4.527 1.7c-.235.513-4.553-.389-4.47.272s3.286 1.881 2.961 2.444-2.556-1.394-4.335-1.252-3.142-.707-5.9.374-12.037 6.067-18.114 6.341-19.5.913-19.5.913zM831.589 315.034s-4.237-16.514-5.385-20.09-3.028-17.075-3.083-17.6 5.167-5.292 4.395-5.708-2.543 2.568-3.321 1.949-1.288-9.07-1.892-8.817c-1.13.471.108 5.186-.462 5.311s-.82-5.043-1.84-5.164.71 4.865.335 5.165-2.277-4.47-3.038-3.763c-.414.384 1.8 4.2 1.146 4.329s-2.818-2.53-3.251-2.044 2.126 1.988 2.55 3.721 1.658 2.761 1.5 5.719-1.98 13.333-.33 19.19 5.258 18.8 5.258 18.8z"
                />
                <path
                    className="InsuranceMainGirlprefix__g"
                    d="M824.139 333.233c.176-2.941.885-10.846 1.145-11.794-.42-3.14-2.866-8.049-3.333-9.406 0 0 4.858-4.352 7.943-3.878 0 0 2.682 4.8 4.935 6.47a5.923 5.923 0 001.174.554c2.359.312 10.628 1.2 12.1 1.9l-2.728 13.173c-.55.7-3.223 15.911-4.309 17.042s-15.14 5.006-17.13 3.222c-.082-.075-.154-11.337.203-17.283z"
                />
                <path
                    className="InsuranceMainGirlprefix__g"
                    d="M843.873 316.694a6.7 6.7 0 012.559-.061c3.743.457 10.053 1.18 10.053 1.18s.418 8.212-1.092 9.961c0 0-10.039.154-9.878 1.036z"
                />
                <path
                    className="prefix__e"
                    d="M842.058 311.034a37.31 37.31 0 00-1.582 6.336c-.083 1.843-1.724 7.591-5.412 7.1s.343-7.552.938-9.287a71.855 71.855 0 001.45-7.241z"
                />
                <path
                    d="M836.989 310.696c.268-1.5.464-2.758.464-2.758l4.6 3.1s-.306.919-.65 2.1a4.268 4.268 0 01-4.414-2.442z"
                    fill="#fda77b"
                />
                <path
                    className="prefix__e"
                    d="M845.644 298.557a6.392 6.392 0 012.112 3.86c.362 2.49-1.294 8.553-6.42 9.355s-6.575-4.384-5.027-8.065 4.426-8.212 9.335-5.15z"
                />
                <path
                    className="prefix__e"
                    d="M835.899 302.139c-.047 1.04.385 1.9.966 1.922s1.089-.8 1.136-1.844-.385-1.9-.966-1.921-1.089.804-1.136 1.843z"
                />
                <path
                    className="InsuranceMainGirlprefix__g"
                    d="M807.881 416.833c.509-.716 2.915 2.646 3.5 3.356a1.763 1.763 0 01.5 1.608c-.152.376-10.527 8.323-13.162 9.1a1.3 1.3 0 01-.822-.134c-1.134-.85 3.042-8.511 5.12-9.687a10.705 10.705 0 004.864-4.243z"
                />
                <path d="M845.11 298.165s-.071 7.012 1.886 8.552.638 4.472.638 4.472 2.925-6.824 1.558-9.338a9.408 9.408 0 00-4.082-3.686z" />
                <path
                    className="prefix__e"
                    d="M845.596 307.53c-.7.817-.849 1.875-.325 2.365s1.518.224 2.221-.593.849-1.876.326-2.365-1.518-.224-2.222.593z"
                />
                <g>
                    <path
                        className="InsuranceMainGirlprefix__f"
                        d="M743.993 313.226c-3.108 12.966-12.929 17.77-21.891 15.622-9.437-2.262-15.183-11.1-12.4-22.73 2.651-11.059 11.43-17.827 22.012-15.291 10.682 2.56 14.814 11.816 12.279 22.399zm-23.423-4.908c-1.553 6.483-.48 11.681 3.715 12.686 4.1.983 7.107-2.733 8.775-9.693 1.507-6.292.956-11.566-3.524-12.639-4.291-1.028-7.481 3.449-8.966 9.646zm.067 42.3l45.532-49.673a3.512 3.512 0 013.407-1.042 3.511 3.511 0 011.767 5.79l-45.589 49.621a3.471 3.471 0 01-3.366 1.027 3.472 3.472 0 01-1.752-5.726zm61.805-.954c-3.108 12.966-12.929 17.77-21.891 15.622-9.343-2.239-15.09-11.077-12.4-22.731 2.651-11.059 11.431-17.826 22.012-15.291 10.682 2.556 14.814 11.814 12.279 22.396zm-23.327-4.885c-1.649 6.46-.576 11.657 3.619 12.662 4.1.982 7.108-2.733 8.776-9.692 1.508-6.293 1.052-11.543-3.428-12.617-4.387-1.054-7.483 3.447-8.968 9.64z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
