import * as React from 'react';

type initialProps = {
    checked?: boolean;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={74.652} height={61} viewBox="0 0 74.652 61">
            <defs>
                <style>
                    {slug && slug.primary_color && props.checked
                        ? `.prefix__a{fill:#fff}.prefix__b{fill:${slug.primary_color}}`
                        : '.prefix__a{fill:#fff}.prefix__b{fill:#6b6c6f}'}
                </style>
            </defs>
            <path
                className="prefix__a"
                d="M73.661 33.071v16.69a3.727 3.727 0 01-3.726 3.728h-4.659a6.521 6.521 0 10-13.042 0h-7.95V33.071zm0 0"
            />
            <path
                className="prefix__a"
                d="M44.284 33.071v20.42H22.418a6.521 6.521 0 00-13.042 0H4.717a3.727 3.727 0 01-3.726-3.73V4.717A3.726 3.726 0 014.717.991h35.841a3.726 3.726 0 013.726 3.726zm-11.693-12.17h3.87l-4.137-3.01-9.079-6.61-13.213 9.62h3.872v15.606h18.687zm0 0"
            />
            <path
                className="prefix__b"
                d="M4.717 54.479h3.736a7.51 7.51 0 0014.89 0H51.31a7.51 7.51 0 0014.89 0h3.736a4.723 4.723 0 004.717-4.719V33.071a.939.939 0 00-.017-.175.049.049 0 000-.014.96.96 0 00-.05-.169.166.166 0 00-.006-.017.961.961 0 00-.083-.157l-.008-.014a.985.985 0 00-.12-.149L63.781 21.689a4.316 4.316 0 00-3.052-1.274H45.275V4.717A4.722 4.722 0 0040.558 0H4.717A4.722 4.722 0 000 4.717v45.044a4.723 4.723 0 004.717 4.718zm54.037 4.539a5.53 5.53 0 115.532-5.53 5.53 5.53 0 01-5.532 5.53zM45.275 22.4h15.454a2.324 2.324 0 011.643.686l8.912 9H45.275zm0 11.665h27.4v15.7a2.739 2.739 0 01-2.74 2.735H66.2a7.51 7.51 0 00-14.89 0h-6.035zM15.9 59.018a5.53 5.53 0 115.529-5.53 5.53 5.53 0 01-5.529 5.53zM1.982 4.717a2.738 2.738 0 012.735-2.735h35.841a2.738 2.738 0 012.735 2.735V52.5H23.342a7.51 7.51 0 00-14.89 0H4.717a2.739 2.739 0 01-2.735-2.737zm0 0"
            />
            <path
                className="prefix__b"
                d="M10.031 21.889h2.881v14.618a.991.991 0 00.991.991h18.684a.99.99 0 00.991-.991V21.889h2.879a.991.991 0 00.584-1.792l-2.473-1.8v-2.825a.991.991 0 00-1.982 0v1.384l-8.759-6.377a.991.991 0 00-1.166 0L9.448 20.1a.991.991 0 00.583 1.789zm13.213-9.386l10.17 7.4h-.826a.99.99 0 00-.991.991v14.622h-16.7V20.901a.991.991 0 00-.991-.991h-.826zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
