import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39.543}
      height={32.311}
      viewBox="0 0 39.543 32.311"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupMoving_prefix__a{fill:#fff}.signupMoving_prefix__b{fill:${slug.color_shade3}}.signupMoving_prefix__c{fill:${slug.color_shade1}}`
            : ".signupMoving_prefix__a{fill:#fff}.signupMoving_prefix__b{fill:#5b7088}.signupMoving_prefix__c{fill:#273e59}"
          }
        </style>
      </defs>
      <path
        className="signupMoving_prefix__a"
        d="M39.018 17.517v8.84a1.974 1.974 0 01-1.973 1.975h-2.468a3.454 3.454 0 10-6.909 0h-4.211V17.517zm0 0"
      />
      <path
        className="signupMoving_prefix__b"
        d="M33.411 11.857l5.607 5.66H23.457v-6.179h8.711a1.746 1.746 0 011.243.519zm0 0M31.121 24.878a3.454 3.454 0 11-3.453 3.455 3.454 3.454 0 013.453-3.455zm0 0"
      />
      <path
        className="signupMoving_prefix__a"
        d="M23.457 17.518v10.814H11.875a3.454 3.454 0 00-6.908 0H2.499a1.974 1.974 0 01-1.974-1.974V2.499A1.974 1.974 0 012.499.525h18.984a1.973 1.973 0 011.974 1.974zm-6.2-6.446h2.05l-2.182-1.596-4.809-3.5-7 5.1h2.051v8.266h9.9zm0 0"
      />
      <path
        className="signupMoving_prefix__b"
        d="M19.311 11.074h-2.05v8.266h-9.9v-8.266H5.313l7-5.1 4.809 3.5zm0 0M8.421 24.878a3.454 3.454 0 11-3.455 3.455 3.454 3.454 0 013.455-3.455zm0 0"
      />
      <path
        className="signupMoving_prefix__c"
        d="M2.5 28.857h1.977a3.978 3.978 0 007.887 0h14.814a3.978 3.978 0 007.887 0h1.979a2.5 2.5 0 002.5-2.5v-8.84a.5.5 0 00-.009-.093.026.026 0 000-.007.508.508 0 00-.026-.09v-.009a.51.51 0 00-.044-.083v-.008a.522.522 0 00-.064-.079l-5.606-5.659a2.286 2.286 0 00-1.616-.675h-8.197V2.5a2.5 2.5 0 00-2.5-2.5H2.5A2.5 2.5 0 000 2.5v23.858a2.5 2.5 0 002.5 2.499zm28.623 2.4a2.929 2.929 0 112.93-2.929 2.929 2.929 0 01-2.932 2.933zm-7.139-19.4h8.186a1.231 1.231 0 01.87.363l4.721 4.766H23.982zm0 6.179h14.509v8.315a1.451 1.451 0 01-1.449 1.45h-1.979a3.978 3.978 0 00-7.887 0h-3.2zM8.421 31.261a2.929 2.929 0 112.929-2.929 2.929 2.929 0 01-2.929 2.929zM1.05 2.5A1.45 1.45 0 012.5 1.05h18.983a1.45 1.45 0 011.449 1.45v25.307H12.364a3.978 3.978 0 00-7.887 0H2.5a1.451 1.451 0 01-1.449-1.45zm0 0"
      />
      <path
        className="signupMoving_prefix__c"
        d="M5.313 11.597h1.526v7.741a.525.525 0 00.525.525h9.9a.525.525 0 00.525-.525v-7.741h1.525a.525.525 0 00.309-.949l-1.31-.953v-1.5a.525.525 0 00-1.05 0v.733L12.621 5.55a.525.525 0 00-.618 0l-7 5.1a.525.525 0 00.309.949zm7-4.972l5.386 3.925h-.437a.525.525 0 00-.525.525v7.741H7.889v-7.744a.525.525 0 00-.525-.525h-.438zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
