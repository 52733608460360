import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={43.229} height={59.914} viewBox="0 0 43.229 59.914">
            <defs>
                <style>
                    {slug &&
                    slug.color_shade1 &&
                    slug.color_shade2 &&
                    slug.color_shade3 &&
                    slug.color_shade4 &&
                    slug.primary_color
                        ? props.isdouglaselliman
                            ? `.OldZipprefix__a{fill:#ffffff}.OldZipprefix__b{fill:#fff}.OldZipprefix__c{fill:333333}`
                            : `.OldZipprefix__a{fill:${slug.color_shade4}}.OldZipprefix__b{fill:#fff}.OldZipprefix__c{fill:${slug.primary_color}}`
                        : '.OldZipprefix__a{fill:#5b7088}.OldZipprefix__b{fill:#fff}.OldZipprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <path
                className="OldZipprefix__a"
                d="M21.614 58.984c-7.042 0-12.772-1.763-12.772-3.93 0-1.761 3.814-3.313 9.274-3.773 1.2 1.344 2.173 2.387 2.784 3.032l.713.753.713-.753c.611-.644 1.588-1.688 2.784-3.032 5.461.46 9.274 2.012 9.274 3.773.002 2.167-5.728 3.93-12.77 3.93z"
            />
            <path
                className="OldZipprefix__b"
                d="M42.246 20.632c0 3.557-2.024 8.43-5.993 14.482 0 .02-.02.02-.02.039a136.461 136.461 0 01-14.58 17.861l-.039.039h-.02C20.769 52.189.982 31.852.982 20.632a20.632 20.632 0 0141.264 0z"
            />
            <path
                className="OldZipprefix__a"
                d="M30.456 13.303v14.206a1.966 1.966 0 01-1.67 1.945c-.079 0-.177.02-.295.02H14.737a1.971 1.971 0 01-1.965-1.965V13.303l8.842-5.443z"
            />
            <path
                className="OldZipprefix__b"
                d="M24.562 23.58v5.895h-5.895V23.58a2.03 2.03 0 011.965-1.965h1.965a1.971 1.971 0 011.965 1.965z"
            />
            <path
                className="OldZipprefix__c"
                d="M28.49 30.457a2.951 2.951 0 002.947-2.947v-9.826a.983.983 0 10-1.965 0v9.825a.984.984 0 01-.982.982h-2.945v-4.912a2.951 2.951 0 00-2.947-2.947h-1.966a2.951 2.951 0 00-2.947 2.947v4.913h-2.948a.984.984 0 01-.982-.982v-9.826a.983.983 0 00-1.965 0v9.825a2.951 2.951 0 002.947 2.947zm-8.84-1.965v-4.913a.984.984 0 01.982-.982h1.965a.984.984 0 01.982.982v4.913z"
            />
            <path
                className="OldZipprefix__c"
                d="M33.872 16.556a.966.966 0 00.515.145.983.983 0 00.515-1.82L22.129 7.023a.981.981 0 00-1.03 0L8.327 14.882a.983.983 0 001.03 1.674l12.257-7.543z"
            />
            <path
                className="OldZipprefix__c"
                d="M27.114 50.466C33.3 43.405 43.229 30.788 43.229 22.58A21.614 21.614 0 000 22.58c0 8.208 9.933 20.825 16.114 27.886-4.274.6-8.255 2.038-8.255 4.535 0 3.226 6.919 4.912 13.755 4.912s13.755-1.686 13.755-4.912c0-2.497-3.981-3.935-8.255-4.535zm-5.5-47.536a19.672 19.672 0 0119.65 19.65c0 9.7-16.067 27.216-19.649 31-3.584-3.782-19.649-21.3-19.649-31A19.672 19.672 0 0121.614 2.931zm0 55.018c-7.313 0-11.79-1.908-11.79-2.947 0-.727 2.633-2.23 7.879-2.749a121.67 121.67 0 003.209 3.439.987.987 0 001.4 0c.2-.2 1.436-1.474 3.209-3.439 5.246.519 7.879 2.022 7.879 2.749 0 1.04-4.472 2.948-11.786 2.948z"
            />
        </svg>
    );
}

export default SvgComponent;
