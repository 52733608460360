export const GET_MAKES = 'GET_MAKES';
export const GET_MAKES_SUCCESS = 'GET_MAKES_SUCCESS';
export const GET_MAKES_ERROR = 'GET_MAKES_ERROR';

export const GET_MODELS = 'GET_MODELS';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const GET_MODELS_ERROR = 'GET_MODELS_ERROR';

export const POST_SHIPPING = 'POST_SHIPPING';
export const POST_SHIPPING_SUCCESS = 'POST_SHIPPING_SUCCESS';
export const POST_SHIPPING_ERROR = 'POST_SHIPPING_ERROR';