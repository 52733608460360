import HomeValuation from '../../../Assets/images/HomeValuation/homeValuation.svg';
import savingCalculator from '../../../Assets/images/HomeValuation/savingCalculator.svg';
import mortgage from '../../../Assets/images/HomeValuation/mortgage.svg';
import AffCalculator from '../../../Assets/images/HomeValuation/AffCalculator.svg';
import Equity from '../../../Assets/images/HomeValuation/Equity.svg';
import HomeProjects from '../../../Assets/images/HomeValuation/HomeProjects.svg';

const SideNavData = [
    {
        title: 'Home Value Estimation',
        icon: HomeValuation,
        href: '#home-value-estimation',
        link: 'https://online.kfc.co.in/',
    },
    {
        title: 'Mortgage Balance',
        href: '#mortgage-balance',
        icon: mortgage,
        link: '',
    },
    {
        title: 'Savings Calculator',
        href: '#savings-calculator',
        icon: savingCalculator,
        link: '',
    },
    {
        title: 'Affordability Calculator',
        href: '#affordability-calculator',
        icon: AffCalculator,
        link: '',
    },
    {
        title: 'Home Equity',
        href: '#home-equity',
        icon: Equity,
        link: '',
    },
    {
        title: 'Home Projects',
        href: '#home-projects',
        icon: HomeProjects,
        link: '',
    },
];

export default SideNavData;
