import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={304.562}
            height={261.585}
            viewBox="0 0 304.562 261.585"
            {...props}
        >
            <defs>
                <style>
                    {slug &&
                    slug.color_shade1 &&
                    slug.color_shade2 &&
                    slug.color_shade3 &&
                    slug.color_shade4 &&
                    slug.primary_color
                        ? `.insuranceHomeprefix__b{fill:${slug.color_shade3}}.insuranceHomeprefix__d{fill:#fff}.insuranceHomeprefix__e{fill:#8195ac}.insuranceHomeprefix__f{fill:#9098e6}.insuranceHomeprefix__g{fill:${slug.color_shade4}}.insuranceHomeprefix__h{fill:#f0f0f0}.insuranceHomeprefix__j{fill:#f1f3ff}.insuranceHomeprefix__k{fill:#765b13}.insuranceHomeprefix__l{fill:#00d56d}.insuranceHomeprefix__o{fill:#e68922}.insuranceHomeprefix__q{fill:#f0bf97}.insuranceHomeprefix__r{fill:#fcb9b9}.insuranceHomeprefix__t{fill:#e8988c}.insuranceHomeprefix__w{fill:#feb33c}`
                        : '.insuranceHomeprefix__b{fill:#3c536f}.insuranceHomeprefix__d{fill:#fff}.insuranceHomeprefix__e{fill:#8195ac}.insuranceHomeprefix__f{fill:#9098e6}.insuranceHomeprefix__g{fill:#5b7088}.insuranceHomeprefix__h{fill:#f0f0f0}.insuranceHomeprefix__j{fill:#f1f3ff}.insuranceHomeprefix__k{fill:#765b13}.insuranceHomeprefix__l{fill:#00d56d}.insuranceHomeprefix__o{fill:#e68922}.insuranceHomeprefix__q{fill:#f0bf97}.insuranceHomeprefix__r{fill:#fcb9b9}.insuranceHomeprefix__t{fill:#e8988c}.insuranceHomeprefix__w{fill:#feb33c}'}
                </style>
            </defs>
            <g transform="translate(-577.344 -237.848)">
                <path
                    d="M874.406 476.5H584.844"
                    stroke="#f5f5f5"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={15}
                    fill="transparent"
                />
                <path className="insuranceHomeprefix__b" d="M716.119 420.142h122.193v56.353H716.119z" />
                <path
                    d="M863.454 420.142l-25.84-22.887H704.796l9.593 22.887z"
                    fill={slug && slug.primary_color ? slug.primary_color : '#293e58'}
                />
                <path className="insuranceHomeprefix__b" d="M713.966 336.615h112.885v72.4H713.966z" />
                <path className="insuranceHomeprefix__d" d="M748.712 353.133h44.929v32.776h-44.929z" />
                <path className="insuranceHomeprefix__e" d="M769.62 353.133h3.667v32.776h-3.667z" />
                <path
                    className="insuranceHomeprefix__e"
                    d="M748.712 368.273h44.929v2.662h-44.929zM748.712 350.181h44.929v2.952h-44.929z"
                />
                <path className="insuranceHomeprefix__f" d="M793.641 350.181h4.075v35.729h-4.075z" />
                <path className="insuranceHomeprefix__e" d="M744.372 350.181h4.34v35.729h-4.34z" />
                <path className="insuranceHomeprefix__e" d="M744.372 385.909h53.343v3.484h-53.343z" />
                <path
                    className="insuranceHomeprefix__g"
                    d="M684.922 316.332l27.239 19.586a3.71 3.71 0 002.157.7h110.5a3.7 3.7 0 002.006-6.812l-79.018-50.945a3.7 3.7 0 00-3.747-.156l-49.55 26.464-9.17 4.9a3.7 3.7 0 00-.418 6.267z"
                />
                <path className="insuranceHomeprefix__h" d="M731.257 425.141h100.035v51.354H731.257z" />
                <path fill="#657689" d="M731.257 425.141h100.035v1.533H731.257z" />
                <path
                    className="insuranceHomeprefix__e"
                    d="M731.257 428.678h100.035v1.533H731.257zM731.257 433.392h100.035v1.533H731.257zM731.257 438.106h100.035v1.533H731.257zM731.257 444h100.035v1.533H731.257zM731.257 449.892h100.035v1.533H731.257zM731.257 455.785h100.035v1.533H731.257zM731.257 461.678h100.035v1.533H731.257zM731.257 467.571h100.035v1.533H731.257zM713.966 336.615h112.885v8.171H713.966z"
                />
                <path
                    className="insuranceHomeprefix__e"
                    d="M730.153 424.138h102.244v50.537H730.153zm100.035 2.006h-97.825v46.527h97.825z"
                />
                <path className="insuranceHomeprefix__g" d="M642.106 340.8h76.031v135.7h-76.031z" />
                <path className="insuranceHomeprefix__b" d="M639.704 406.753h81.148v6.444h-81.148z" />
                <path className="insuranceHomeprefix__d" d="M657.789 355.321h44.929v32.776h-44.929z" />
                <path className="insuranceHomeprefix__b" d="M678.698 355.321h3.667v32.776h-3.667z" />
                <path
                    className="insuranceHomeprefix__b"
                    d="M657.789 370.461h44.929v2.662h-44.929zM657.789 352.369h44.929v2.953h-44.929zM702.72 352.369h4.075v35.729h-4.075z"
                />
                <path className="insuranceHomeprefix__b" d="M653.45 352.369h4.34v35.729h-4.34z" />
                <path className="insuranceHomeprefix__b" d="M653.45 388.097h53.344v3.484H653.45z" />
                <path className="insuranceHomeprefix__e" d="M637.093 402.205h86.892v6.444h-86.892z" />
                <path
                    className="insuranceHomeprefix__g"
                    d="M684.922 316.332l27.239 19.586a3.71 3.71 0 002.157.7h19.509q-19.88-15.44-39.317-31.448l-9.165 4.9a3.7 3.7 0 00-.418 6.267z"
                />
                <path className="insuranceHomeprefix__b" d="M718.137 340.8l-37.6-35.06-38.433 35.06z" />
                <path className="insuranceHomeprefix__e" d="M642.106 340.8h76.031v6.068h-76.031z" />
                <path className="insuranceHomeprefix__b" d="M637.093 402.205h86.892v3.222h-86.892z" />
                <path className="insuranceHomeprefix__e" d="M642.106 473.673h196.206v2.823H642.106z" />
                <path
                    className="insuranceHomeprefix__j"
                    d="M648.566 388.819h62.473v2.853h-62.473zM648.566 400.848h62.473v2.853h-62.473z"
                />
                <path
                    className="insuranceHomeprefix__j"
                    d="M705.72 391.671h3.41v10.418h-3.41zM696.881 391.671h3.41v10.418h-3.41zM688.042 391.671h3.41v10.418h-3.41zM679.203 391.671h3.41v10.418h-3.41zM670.364 391.671h3.41v10.418h-3.41zM659.315 391.671h3.41v10.418h-3.41zM650.476 391.671h3.41v10.418h-3.41z"
                />
                <path
                    className="insuranceHomeprefix__k"
                    d="M611.767 377.932l-18.552-9.45.353 12.1 6.583-3.736 7.672 3.559zM582.394 399.808l20.378-13.244-2.4 18.447h-5.537l3.217-6.53-4.505 3.222-3.578 2.237z"
                />
                <path
                    className="insuranceHomeprefix__l"
                    d="M622.61 362.783s-20.6-6.459-14.74-21.294 5.486-11.431 3.893-29.568 15.845-32.274 29.754-34.478 15.567-19.829 35.165-20.17c11.9-.207 25.075 16.1 19.124 34.26-5.078 15.5 2.451 13.432 4.134 28.943s-14.389 27.53-28.262 30.529-27.365 36.984-49.068 11.778z"
                />
                <path
                    d="M628.864 303.092s9.558 13.306 23.5 2.942c0 0 8.567 11.126 23.695 6 0 0-4.428 15.112 12.964 22.356 0 0-20.411 15.334-51.615-9.764-9.707-7.797-8.544-21.534-8.544-21.534z"
                    fill="#00aa57"
                />
                <path
                    className="insuranceHomeprefix__l"
                    d="M676.868 349.501a39.742 39.742 0 01-5.191 1.509c-13.873 3-27.361 36.983-49.068 11.773 0 0-20.6-6.459-14.74-21.294s5.486-11.431 3.893-29.568c-.861-9.8 3.832-18.427 10.524-24.636a48.073 48.073 0 00-3.728 16.192c-.416 15.6 18.329 14.884 18.392 38.507.062 22.189 27.185-3.897 39.918 7.517z"
                />
                <path
                    className="insuranceHomeprefix__k"
                    d="M621.874 382.171c1.573-2.331 2.884-4.908 4.563-7.1 2.183-2.855 4.894-5.449 6.837-8.374 4.046-6.087 7.483-12.552 11.329-18.763 2.271-3.67 4.5-7.379 6.635-11.091q.719-1.249 1.422-2.49c4.138-7.32 7.888-14.6 10.916-21.5a20.809 20.809 0 01-3.86-1.31c-1.209 1.877-2.68 4.1-4.408 6.592q-1.685 2.442-3.7 5.222c-1.68 2.32-3.534 4.8-5.556 7.415q-6.867 8.879-14.371 17.243c-3.6 4.013-7.059 8.143-10.618 12.191l2.359 7.38-6.915 4.791zM624.971 426.862c.542 7.927.862 15.872 1.135 23.819.045 1.362.637 25.819.545 25.819h-24.765c3.042-13.676 5.39-27.588 7.1-41.628.75-6.155 1.063-12.394 1.668-18.516l.5-17.241 6.65 5.138.857 6.974.429 7.655 3.218-6.389 2.575 1.93z"
                />
                <path
                    className="insuranceHomeprefix__k"
                    d="M650.875 337.293a38.092 38.092 0 017.724-.148 23.416 23.416 0 002.673.03c8.346-.365 16.146-4.907 20.907-8.35a23.971 23.971 0 002.33 1.26 47.841 47.841 0 01-9.675 7.182 47.352 47.352 0 01-15.742 5.55c-5.042.783-10.024 3.48-13.87 6.094a57.312 57.312 0 00-7.112 5.68zM650.645 324.605a36.875 36.875 0 01-.57-.782 35.85 35.85 0 01-5.11-10.49 34.629 34.629 0 01-.816-3.348 18.875 18.875 0 002.473-1.394q.144.344.305.688a30.526 30.526 0 006.379 9.021c.278.277.553.532.817.776q-1.738 2.761-3.478 5.53z"
                />
                <path
                    className="insuranceHomeprefix__l"
                    d="M694.385 270.088c-3.316-5.135-11.287-6.714-16.983 5.756-8.06 17.662-29.333 12.731-44.444 4.282a33 33 0 018.559-2.681c13.913-2.2 15.568-19.827 35.164-20.167 6.717-.118 13.845 5.032 17.704 12.81z"
                />
                <path
                    className="insuranceHomeprefix__k"
                    d="M630.081 376.875c-.109-3.109.01-11.709 4.127-17.163a52.75 52.75 0 0111.022-10.787c-4.947 9.809-10.218 19.38-15.149 27.95zM638.243 342.024c2.533-3.61 4.852-7.071 6.944-10.328a300.214 300.214 0 004.889-7.868s1.23.974 2.595 2.145c1.255 1.066-5.737 8.29-14.428 16.05z"
                />
                <path
                    className="insuranceHomeprefix__b"
                    d="M793.533 319.141s-12.868-5.784-14.988-17.333c-.88-1.535-4.91-1.543-6.4-1.977a19.656 19.656 0 01-7.026-3.683c-17.417-13.846-12.863-43.17 5.878-53.684 14.235-7.986 33.218-4.843 46.52 3.578 6.908 4.373 12.841 10.57 15.881 18.248a40.2 40.2 0 011.784 5.752c2.183 9.432.458 20.375-8.513 25.594s-20.186 6.064-30.3 6.752c-.63.044-5.452-.232-5.8.281.002-.004-5.243 7.589 2.964 16.472z"
                />
                <path
                    className="insuranceHomeprefix__g"
                    d="M812.252 265.192v24.334h-12.875v-12.535h-11.946v12.537h-12.874v-24.336a5.568 5.568 0 015.568-5.567h26.561a5.568 5.568 0 015.566 5.567z"
                />
                <path className="insuranceHomeprefix__h" d="M794.064 246.06l-28.624 20.955h55.822z" />
                <path
                    className="insuranceHomeprefix__d"
                    d="M812.659 294.62a1.566 1.566 0 01-.613-.14c-5.248-2.479-7.671-5.827-8.781-8.2a7.942 7.942 0 01-.739-3.729l.3-10.553a1.543 1.543 0 012.089-1.392 7.465 7.465 0 002.491.489 10.649 10.649 0 005.131-1.289 1.544 1.544 0 011.657.051 10.665 10.665 0 005.05 1.577 7.518 7.518 0 002.515-.346 1.544 1.544 0 012.007 1.507l-.3 10.554a7.941 7.941 0 01-.948 3.679c-1.243 2.306-3.855 5.511-9.236 7.687a1.55 1.55 0 01-.623.105z"
                />
                <path
                    className="insuranceHomeprefix__e"
                    d="M812.704 294.999h-.056a1.9 1.9 0 01-.763-.182c-5.352-2.525-7.827-5.953-8.964-8.381a8.334 8.334 0 01-.772-3.9l.3-10.554a1.917 1.917 0 012.6-1.734 7.118 7.118 0 002.366.466 10.359 10.359 0 004.93-1.237 1.922 1.922 0 012.061.063 10.322 10.322 0 004.843 1.511 7.14 7.14 0 002.385-.328 1.915 1.915 0 012.5 1.879l-.3 10.554a8.342 8.342 0 01-.993 3.847c-1.274 2.361-3.943 5.64-9.428 7.856a1.912 1.912 0 01-.709.14zm-8.339-24.123a1.159 1.159 0 00-1.159 1.123l-.3 10.554a7.6 7.6 0 00.7 3.558c1.083 2.315 3.454 5.585 8.6 8.017a1.158 1.158 0 00.93.027c5.276-2.134 7.831-5.264 9.044-7.516a7.6 7.6 0 00.9-3.507l.3-10.553a1.159 1.159 0 00-1.513-1.136 7.9 7.9 0 01-2.64.365 11.134 11.134 0 01-5.249-1.643 1.169 1.169 0 00-1.251-.038 10.942 10.942 0 01-5.331 1.343 7.9 7.9 0 01-2.618-.515 1.151 1.151 0 00-.413-.079z"
                />
                <path
                    className="insuranceHomeprefix__b"
                    d="M811.487 285.593a1.535 1.535 0 01-1.031-.436l-3.012-2.946a1.543 1.543 0 112.144-2.2l2.006 1.964 5.036-4.327a1.543 1.543 0 012 2.332l-6.1 5.24a1.531 1.531 0 01-1.043.373z"
                />
                <ellipse
                    cx={43.421}
                    cy={7.169}
                    rx={43.421}
                    ry={7.169}
                    transform="translate(762.157 485.094)"
                    fill="#f5f5f5"
                />
                <path
                    className="insuranceHomeprefix__o"
                    d="M774.696 411.348a14.414 14.414 0 013.148 2.651 26.574 26.574 0 0016.12 7.333c7.989.673 15.987 1.371 24.007 1.438a66.051 66.051 0 0013.715-1.1c3.053-.62 6.368-1.249 9.106-2.725a3.708 3.708 0 001.064-.722 10.278 10.278 0 00.681-1.249c-2.12-.055-3.954-1.4-5.79-2.324a122.191 122.191 0 00-25.636-9.99c-8.927-2.185-18.328-2.862-27.326-.989a15.83 15.83 0 00-5.764 2.147 7.871 7.871 0 00-3.325 5.53z"
                />
                <path
                    d="M803.482 343.029a4.646 4.646 0 00-.579 2.817 3.716 3.716 0 002.084 2.263c4.559 2.367 9.726-2.561 11.908-6.117 1.135-1.849 1.675-4.4.31-6.092-1.206-1.492-3.376-1.7-5.294-1.651-5.688.154-6.428 4.238-8.429 8.78z"
                    fill="#a1abf4"
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M800.225 332.163a7.869 7.869 0 01-2.026 3.123 14.933 14.933 0 01-5.109 3.234 11.375 11.375 0 01-2.339-2.9 2.115 2.115 0 01-.429-.835 2.9 2.9 0 01.4-1.859l-.7-4.112a1.889 1.889 0 01-.032-.666 1.79 1.79 0 01.281-.6c.795-1.225 1.816-3.758 3.354-4.226a5.193 5.193 0 012.531.481 4.8 4.8 0 00.5.124 14.429 14.429 0 003.383.2 27.194 27.194 0 01.478 4.959 9.969 9.969 0 01-.292 3.077z"
                />
                <path
                    className="insuranceHomeprefix__r"
                    d="M800.225 332.158a7.874 7.874 0 01-2.025 3.123 5.842 5.842 0 01-.513-3.152 29.235 29.235 0 00.227-3.445 10.788 10.788 0 00-1.755-4.882 4.808 4.808 0 00.5.124 14.432 14.432 0 003.383.2 27.215 27.215 0 01.478 4.959 10.055 10.055 0 01-.295 3.073z"
                />
                <path
                    d="M834.466 484.473l-8.4.011c-1.9-18.465-13.458-57.652-16.675-68.319-.527-1.747-.83-2.725-.83-2.725l-1.176 40.631-.511 17.682-.076 2.633c-.055 1.93.794 7.905-.455 9.353-1.291 1.5-5.574.444-7.183.045a3.039 3.039 0 01-2.056-1.232 3.289 3.289 0 01-.257-1.636c.26-18.153-1.6-36.4-4.4-54.275a65.524 65.524 0 01-.647-6.986c-.164-3.339-.227-6.665-.652-10.094a120.939 120.939 0 01-.228-28.194c.6.547-.31.389.43.716q3.884 1.714 7.821 3.3a31.263 31.263 0 0019.506.865s4.185 11.628 8.115 25.164a116.324 116.324 0 012.619 15.2c2.835 22.407 5.055 57.861 5.055 57.861z"
                    fill="#6d89cf"
                />
                <path
                    className="insuranceHomeprefix__t"
                    d="M794.557 335.515c.543-.415 1.373-.057 1.991-.348a2.051 2.051 0 00.807-.92 6.347 6.347 0 00.735-1.635 2.13 2.13 0 00-.258-1.726 3.169 3.169 0 00-1.179-.866 10.116 10.116 0 00-2.952-1.276c-.6-.1-1.6-.446-2.166-.083-1.078.694.721 3.2 1.206 3.839.199.255 2.027 2.853 1.816 3.015z"
                />
                <path
                    d="M797.864 332.296a17.742 17.742 0 002.024-2.759 6.58 6.58 0 00.3-.671.6.6 0 00.024-.062 4.47 4.47 0 00.237-2.766 2.918 2.918 0 00-1.29-1.7 2.106 2.106 0 00-1.363-.3 4.083 4.083 0 00-1.654.766 12.036 12.036 0 00-3.931 4.674.9.9 0 00-.173.53.891.891 0 00.228.439 47.02 47.02 0 003.633 3.923z"
                    fill="#002e62"
                />
                <path
                    d="M800.215 328.841l-.025.025-2.64 2.974-1.576 1.776-1.265-1.343-2.092-2.218 2.362-3.137 1.506-2 .865-.185 1.816-.387a2.811 2.811 0 01.9.855l.156 3.609z"
                    fill="#004da0"
                />
                <path
                    className="insuranceHomeprefix__f"
                    d="M797.55 331.839l-1.577 1.776-1.266-1.343q.189-2.674.271-5.355l1.506-2 .866-.185q.105 3.552.2 7.107z"
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M800.007 324.012l-.989.237c.092.014.114.138.1.227a1.379 1.379 0 01-.522 1.068.889.889 0 01-.575.166 7.132 7.132 0 01-2.177-.107l-.114-.029c-.462-.113-1.128-.271-1.558-.375.531.31 1.012.681 1.517 1.021.126.082.254.157.385.227a8.282 8.282 0 011.118.66l.039.031a.442.442 0 01.146.162c.083.179.218.075.093.227a2.21 2.21 0 01-2.149.586c.287.2.7.393.984.588l-.717.579c-1.688.681-3.553-1.293-3.553-1.293a2.842 2.842 0 01-1.1-1.457 2.513 2.513 0 01.991-2.315 7.537 7.537 0 013.258-1.537c.179-.044.358-.084.537-.121a5.7 5.7 0 014.046.278 1.081 1.081 0 01.526.551.484.484 0 01-.286.626z"
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M800.008 324.013l-.989.237c.092.013.114.137.1.227a1.38 1.38 0 01-.521 1.069.892.892 0 01-.575.166 7.124 7.124 0 01-2.178-.107l-.113-.028a13.177 13.177 0 01-.546-2.9c.179-.044.359-.084.537-.121a5.694 5.694 0 014.046.278 1.074 1.074 0 01.525.551.486.486 0 01-.286.628z"
                />
                <path
                    className="insuranceHomeprefix__t"
                    d="M791.361 324.748a11.718 11.718 0 011.888-.931 4.565 4.565 0 011.044-.246 3.645 3.645 0 01.543-.018c.091 0 .18.014.271.019s.174.021.261.031l1.036.137c.341.052.691.1 1.035.156.688.114 1.376.227 2.06.381-.7-.021-1.4-.075-2.091-.138-.348-.029-.694-.069-1.041-.1l-1.039-.121c-.085-.011-.174-.024-.257-.033s-.166-.019-.25-.023a4.037 4.037 0 00-.5.008 5.119 5.119 0 00-1 .184c-.658.18-1.298.463-1.96.694z"
                />
                <path
                    className="insuranceHomeprefix__d"
                    d="M822.908 369.932c-.167 4.237-.481 8.552-2.065 12.488s-4.643 7.481-8.719 8.653a12.16 12.16 0 01-3.587.443 18.394 18.394 0 01-4.715-.763 21.574 21.574 0 01-6.008-2.752 16.17 16.17 0 01-5.116-5.348c-3.406-5.939-2.171-13.36-.574-20.016 1.827-7.607 4.219-15.491 9.185-21.394a25.845 25.845 0 012.529-2.618 1.777 1.777 0 01.264.414c.471 1 .108 2.329.938 3.148a2.351 2.351 0 001.482.635 4.461 4.461 0 001.021-.035 7.129 7.129 0 001.082-.243 16.114 16.114 0 002.656-1.069c2.542-1.292 4.806-3.306 5.61-5.9q.838.333 1.656.706a33.719 33.719 0 013.374 1.78 1.676 1.676 0 01.688.621 1.7 1.7 0 01.113.825c-.1 2.4-1.11 4.754-1.211 7.075s.853 5.053 1.085 7.4a117.291 117.291 0 01.312 15.95z"
                />
                <path
                    className="insuranceHomeprefix__w"
                    d="M805.063 336.869c-.65 3.682-2.938 6.768-4.83 10.028a26.249 26.249 0 00-1.724 3.445 1.827 1.827 0 00-.09 1.589v.05c.03 6.075-.271 12.135-.731 18.19-.421 5.5-.2 10.941-1.507 16.341-1.333 5.524-1.19 11.591-.78 17.285.335 4.654-.161 9.5-.328 14.191-2.111-2.139-5.965-1.583-8.955-1.888a32.9 32.9 0 01-6.585-1.116c-2.231-.717-2.8-2.932-5.092-3.5 1.178-3.395 1.322-6.821 2.181-10.229a65.351 65.351 0 016.244-15.451l.1-.186c3.52-6.741 7.975-13.683 7.266-21.254.175 1.871-5.814 4.708-7.2 5.071-2.735.712-4.549-.24-5.349-2.424a7.341 7.341 0 01-.373-1.557c-.49-3.592-.8-7.638.105-11.174 1.08-4.208 3.641-7.368 5.79-11.146a50.422 50.422 0 013.741-5.835c.356-.467.914-.923 1.249-1.44a2.064 2.064 0 00.152-.284c.258-.593-.031-1.331.349-1.889a1.29 1.29 0 011.731-.141 7.913 7.913 0 011.32 1.393c1.1 1.191 2.744 1.723 4.012 2.738a1.816 1.816 0 01.638.787c.213.63-.17.839-.627 1l-.057.021a2.816 2.816 0 00-.795.332 1.411 1.411 0 00-.551.857c-.061.245-.087.511-.161.806l-.05.2c-.058.234-.129.514-.21.818a9.283 9.283 0 01-1.384 3.314l-.037.039q2.138-2.089 4.3-4.152c1.324-1.265.744-1.737 2.075-3l.078-.07a8.431 8.431 0 016.085-1.759z"
                />
                <path d="M795.768 339.46l-.057.021a1.583 1.583 0 01.114-.114z" fill="#f1327e" />
                <path
                    className="insuranceHomeprefix__w"
                    d="M841.475 418.296a16.092 16.092 0 01-2.3.954c-2.458.69-4.959 1.216-7.466 1.7a35.768 35.768 0 01-5.954.775 39.038 39.038 0 01-5.421-.384c-2.079-.245-4.187-.091-6.214-.472a2.036 2.036 0 01-1.61-1.578 61.611 61.611 0 01-1.3-10.558c-.12-3.383.038-6.769.206-10.149.3-6.068.628-12.136 1.2-18.185.547-5.8 1.922-11.5 2.243-17.313.079-1.424.105-2.863.207-4.289a20.226 20.226 0 01.853-4.873c.615-1.877 1.636-3.6 2.228-5.485a14.4 14.4 0 00.463-1.975 34.984 34.984 0 00.154-7.07 2.044 2.044 0 013.057-1.866 36.575 36.575 0 003.512 1.688 19.789 19.789 0 015.374 3 8.038 8.038 0 011.055 1.13 11.371 11.371 0 011.987 5.883c.038.376.066.753.088 1.127a98.74 98.74 0 01.155 5.959q0 1.758-.061 3.511c-.114 3.633-1.135 7.483-.182 11.081.6 2.27 1.242 4.541 1.844 6.812q1.385 5.222 2.516 10.5c.658 3.065 1.532 6.253 1.93 9.346.151 1.158.372 2.311.6 3.464.237 1.194.485 2.385.67 3.581.594 3.816.579 7.657 1.327 11.442a2.042 2.042 0 01-1.161 2.244z"
                />
                <path
                    d="M840.635 401.02c-.032 2.326-.2 4.707-1.571 6.645-.341.485-.979.755-1.3 1.262-.528.839-.359 1.235-.479 2.246a5.828 5.828 0 01-2.1 4.061c-1.632 1.239-3.669 1.084-5.54 1.688a3.353 3.353 0 01-1.326.236c-1.677-.164-1.174-1.979-1.828-3.018-.883-1.4-2.124-1.222-2.709-2.888-1.331-3.8-1.52-8.061-1.895-11.931q-.777-8.01-2.116-15.952c-.527-3.132-.071-6.621-.2-9.811-.15-3.793-.908-7.514-.908-11.274a7.675 7.675 0 011.434-4.623 9.406 9.406 0 017.888-3.427 10.105 10.105 0 015.989 2.076q0 1.757-.062 3.511c-.113 3.633-1.135 7.483-.181 11.081.6 2.271 1.241 4.541 1.843 6.812q1.385 5.222 2.516 10.5c.657 3.066 1.532 6.253 1.93 9.346.165 1.154.385 2.307.615 3.46z"
                    fill="#fe9c2c"
                />
                <path
                    className="insuranceHomeprefix__w"
                    d="M837.15 401.517a1.54 1.54 0 00-.453.409.831.831 0 00-.042.081 1.179 1.179 0 00-.014.665q.149.878.3 1.754a.725.725 0 01-.007.385.716.716 0 01-.471.334c-2.022.7-5.147 2.5-7.346 1.767-1.958-.651-.276-3.017-1.485-3.292a1.362 1.362 0 00-.421-.018c-.3-2.287-.844-4.52-1.205-6.8q-1.189-7.476-2.336-14.961-2.324-15.164-4.485-30.354c-.239-1.687 2.157-2.973 3.156-4.062 1.382-1.509 2.724-3.185 3.928-4.761.411-.538 4.016-5.889 4.807-5.485a7.164 7.164 0 013.52 4.183 14.169 14.169 0 01.478 2.879c.007 4.557.894 9.287 1.33 13.826.191 2 .568 4.02.672 6 .123 2.376 0 4.794-.008 7.174v.6a8.6 8.6 0 00.113 1.42 18.81 18.81 0 00.52 1.869q.145.5.263 1.01.1.442.191.89c.146.744.26 1.5.356 2.271.3 2.406.4 4.876.62 7.216.442 4.538 2.534 11.803-1.981 15zM807.761 484.51s.019.463.028 1.194c.016 1.168.01 3.019-.124 4.746a13 13 0 01-.644 3.543s-5.9.114-10.081.014c-3.173-.078-11.775 0-15.745.048l-2.058.024a5.342 5.342 0 01-.227-1.422c-.037-1.453.5-3.585 3.754-4.206 5.093-.973 9.488-2.673 9.861-3.918s.525-8.484 2.884-9.506l1.114-.413.922-.341s-.632 7.561.868 7.934a12.671 12.671 0 001.636.25c2.484.248 6.6.227 7.8-1.441.007-.001 1.352 2.912.012 3.494zM840.699 492.983c-.48 1.77-15.121 1.414-15.927.021a5.367 5.367 0 01-.465-2.145 20.691 20.691 0 01.4-5.365c.427-1.662-1.831-6.324-1.831-6.324s10.187-1.355 10.191 1.274 3.17 5.627 6.017 7.928a10 10 0 011.542 1.469c.941 1.191.426 1.823.073 3.142z"
                />
                <path
                    className="insuranceHomeprefix__o"
                    d="M827.141 484.167c-.066-.646-1.281-6.933-.709-7.2a11.269 11.269 0 018.646-.328 33.027 33.027 0 01-.681 7.052.575.575 0 01-.21.4.616.616 0 01-.478-.018 10.328 10.328 0 00-6.568.094zM836.036 345.713a21.368 21.368 0 01-9.735 5.625 28.428 28.428 0 01-5.686.966c-.44.031-1.23-.077-2.036-.107-1.126-.043-2.282.066-2.543.915.594-1.93.772-3.9 1.228-5.848.716-3.055 1.8-5.965 1.319-9.174-.173-1.148-.055-3.114 1.7-2.923a2.64 2.64 0 01.764.245 8.2 8.2 0 012.221 1.259 12.706 12.706 0 002.927 2.233 39.63 39.63 0 011.6.825 39.6 39.6 0 018.241 5.984z"
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M800.594 319.346a8.022 8.022 0 01-.454 1.862 8.948 8.948 0 01-1.849 3.42l-.957-.989-.708-.734a.974.974 0 01-.351-.972c.216-.849 1.278-1.048 1.9-1.5a7.341 7.341 0 001.065-.934 6.036 6.036 0 00.422-.489 3.531 3.531 0 00.41-.877 1.373 1.373 0 01.407-.6c.017.145.049.289.079.435a2.814 2.814 0 01.066.416 5.532 5.532 0 01-.03.962z"
                />
                <path
                    className="insuranceHomeprefix__t"
                    d="M800.597 319.347a8.03 8.03 0 01-.454 1.862 8.952 8.952 0 01-1.849 3.421l-.958-.989a10.075 10.075 0 001.116-2.317c.269-.6.528-1.213.795-1.817a6.226 6.226 0 00.422-.489 3.507 3.507 0 00.41-.878c.168-.121.312-.251.487-.165a2.852 2.852 0 01.066.416 5.536 5.536 0 01-.035.956z"
                    opacity={0.74}
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M818.545 336.283q-.82-.375-1.656-.706a8.4 8.4 0 01-2.044 3.325 14.756 14.756 0 01-6.218 3.64 5.723 5.723 0 01-2.1.278 15.427 15.427 0 00-.964-5.538 11.008 11.008 0 00-1.635-2.994c0-.009-.012-.016-.019-.025a8.641 8.641 0 00-1.96-1.786c-.429-.286 3.627-4.221 4.187-4.541a7.221 7.221 0 011.183-.538 5.044 5.044 0 015.45 1.1 2.933 2.933 0 01.623 1.039 6.292 6.292 0 001 2.246 2.159 2.159 0 00.808.454c.173.068.347.14.518.213a6.925 6.925 0 011.608.915 3.239 3.239 0 011.248 2 2.649 2.649 0 01-.029.918z"
                />
                <path
                    className="insuranceHomeprefix__t"
                    d="M814.844 338.902a14.755 14.755 0 01-6.219 3.64 5.732 5.732 0 01-2.1.278 15.411 15.411 0 00-.964-5.538c.735.942.754 2.131 1.261 3.218.357.764 1.051 1.986 2.073 1.469 1.579-.8 1.771-3.024 2.017-4.541a9.159 9.159 0 00.094-2.007 10.939 10.939 0 01-.3-1.907 3.676 3.676 0 01-1.547 1.12 7.211 7.211 0 01-1.6.254 3.646 3.646 0 00.692-1.885 6.535 6.535 0 01-1.39.589 13.282 13.282 0 01-2.952.681 8.63 8.63 0 00-1.959-1.786c-.429-.286 3.627-4.221 4.187-4.541a7.232 7.232 0 011.183-.538 14.112 14.112 0 011.723.256 12.123 12.123 0 014.359 1.874 6.274 6.274 0 001 2.246 2.161 2.161 0 00.808.454c.175.068.348.14.519.213a6.546 6.546 0 01.05.681 16 16 0 01-.935 5.77z"
                />
                <path
                    className="insuranceHomeprefix__q"
                    d="M815.571 312.834l-.692 5.676-.794 6.563-.418 3.425a33.115 33.115 0 01-2.991 1.99c-.622.368-1.31.75-2.036 1.107-3.4 1.676-7.606 2.832-9.554-.288-2.474-3.961-.257-10.523 1.312-14.133a33.41 33.41 0 011.239-2.577l10.2-1.287z"
                />
                <path
                    className="insuranceHomeprefix__o"
                    d="M806.378 342.07a10.472 10.472 0 01-.76 1.432c-1.894 3.03-5.145 4.57-7.165 7.606-.216-1.12-.182-2.292-.291-3.442a8.11 8.11 0 00-.31-1.678c-.568-1.824-2.065-2.817-1.687-5a4.5 4.5 0 012.124-3.032c1.676-1.08 3.354-2.324 5.09-3.359.181-.113.362-.214.544-.317 1.707 2.149 2.248 4.932 2.455 7.79z"
                />
                <path
                    className="insuranceHomeprefix__w"
                    d="M806.404 309.27c1.5-1.056 3.111-2.149 4.944-2.264a10.058 10.058 0 012.979.416c2.377.579 4.875 1.221 6.632 2.924a11.133 11.133 0 012.174 3.251c.665 1.378 4.314 9.568 1.9 10.426a3.8 3.8 0 01-2.711.125 16.747 16.747 0 01-8.349-5.78c-2.05-2.669-3.458-6.111-6.49-7.571a1.938 1.938 0 01-1.079-1.527z"
                />
                <path d="M828.152 334.929c-.068.018-.6.9-.756 1.037a1.5 1.5 0 01-.845.454h-.021a2.753 2.753 0 01-2.45-.651 2.669 2.669 0 00.4 1.863 2.8 2.8 0 001.15 1.066 6.764 6.764 0 01-2.858 2.127 3.79 3.79 0 01-1.352.188 3.335 3.335 0 01-.852-.148 2.666 2.666 0 01-1.151-.7c-1.052-1.1-1.362-3.247-2.88-3.284a1.359 1.359 0 00-.526.087 2.149 2.149 0 00-1.055 1.03 4.294 4.294 0 00-.274.628 1.968 1.968 0 00-.032.1c-.177.553-.1 1.113-.255 1.645a8.281 8.281 0 01-1 1.646 1.863 1.863 0 00-.03 2.188 3.02 3.02 0 01-2.445-2.428 8.071 8.071 0 01.242-3.643 12.014 12.014 0 00.1-6.033 13.929 13.929 0 00-.581-1.621c-.054-.13-.114-.261-.166-.39a4.378 4.378 0 01-3.034 4.682c1.128-.485 1.476-1.955 1.272-3.165s-.809-2.327-1-3.54.189-2.684 1.332-3.134a2.412 2.412 0 00.87-.384 1.5 1.5 0 00.3-1.13c0-.69-.146-1.857-.182-3.065-.053-1.792.129-3.67 1.342-4.185a1.953 1.953 0 01.931-.13 3.315 3.315 0 011.467.613c2.341 1.547 5.365 5.563 6.736 6.875a5.189 5.189 0 011.28 1.6c.613 1.423-.124 3.159.5 4.579a4.475 4.475 0 002.3 2.017 2.575 2.575 0 011.666 2.053c.269 1.11.697 1.462 1.857 1.153zM806.663 314.35a2.347 2.347 0 01-2.437.858 4.906 4.906 0 00-2.711.062c-.558.275-.976.821-1.578.979.783-.205.341-2.571 1.117-3.258a3.085 3.085 0 014 .209 4.794 4.794 0 001.314 1.351" />
                <path
                    className="insuranceHomeprefix__o"
                    d="M801.182 312.855s.654-5.578 7.521-2.8 13.314 11.7 15.676 12.972-2.055 6.685-2.055 6.685a38.5 38.5 0 00-7.876-7.661c-4.357-2.954-3.518-4.276-13.266-9.196z"
                />
                <path
                    d="M834.77 346.69c-.518 2.014-2.548 3.215-4.454 4.044a40.541 40.541 0 01-9.65 2.838"
                    fill="transparent"
                />
                <path d="M817.665 330.815l-.03.144-.026-.061a.542.542 0 00.056-.083z" fill="#2342c4" />
                <path
                    className="insuranceHomeprefix__t"
                    d="M793.344 325.38c.13-.014.259-.025.39-.034a7.05 7.05 0 01.944-.018 7.22 7.22 0 01-.908.26c-.3.07-.612.127-.92.174s-.619.083-.932.1a7.085 7.085 0 01-.944.018 7.309 7.309 0 01.908-.26c.305-.07.613-.126.922-.173a4.176 4.176 0 012.239.058l-1.65-.1M790.601 326.893c.454-.078.888-.177 1.344-.227a4.515 4.515 0 01.695-.035 2.4 2.4 0 01.357.04 1.869 1.869 0 01.35.1 5.5 5.5 0 011.2.681c.369.266.72.541 1.1.795a6.711 6.711 0 01-1.228-.593 8.948 8.948 0 00-1.181-.6c-.051-.02-.1-.042-.149-.055a.979.979 0 00-.148-.038 2.749 2.749 0 00-.314-.045c-.216-.021-.437-.017-.661-.017a11.145 11.145 0 01-1.365-.006z"
                />
                <path
                    className="insuranceHomeprefix__o"
                    d="M836.539 411.974a.716.716 0 01-.472.334c-2.021.7-5.147 2.5-7.346 1.767-1.957-.65-.275-3.017-1.485-3.292a2.672 2.672 0 01.988-.542c.568-.183 5.629-.805 8.078-1.155a.889.889 0 00-.042.081 1.181 1.181 0 00-.015.665l.3 1.754a.733.733 0 01-.006.388zM795.768 339.46l-.057.021a2.829 2.829 0 00-.794.332 1.411 1.411 0 00-.551.857 18.9 18.9 0 00-4.062-3.357 18.61 18.61 0 01-2.111-1.447 2.106 2.106 0 00.152-.284c.258-.592-.032-1.33.349-1.889a1.291 1.291 0 011.731-.141 7.922 7.922 0 011.319 1.393c1.1 1.191 2.744 1.723 4.012 2.738a1.812 1.812 0 01.638.788c.215.624-.169.833-.626.989z"
                />
                <path
                    className="insuranceHomeprefix__r"
                    d="M826.442 325.505a8.341 8.341 0 011.117.661 2.556 2.556 0 01-.945-.317 7.35 7.35 0 01-.172-.344z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
