import React, { Component } from 'react';
import './PowerKioskCard.scss';

import Button from '../../components/atoms/Button';

type initialProps = {
    logo?: any;
    title?: string;
    perKwhValue?: number;
    contract?: number;
    savings?: number;
    cancelationFee?: string;

    viewDetails?: any;

    // modalHandler?: any;
    handleSelectPlanPower?: any;
    isViewMore?: any;
    viewMoreClick?: any;
    disclaimerPath?: any;
};
type initialState = {
    slug: any;
};

export class PowerKioskCard extends Component<initialProps, initialState> {
    state: initialState = {
        slug: null,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                className="powerKioskMainDiv"
                // onClick={props.onClickCheckBox}
            >
                <div className="centerTextPower">
                    <div className="logoPower">
                        <img src={this.props.logo} alt="" />
                    </div>
                    <div className="titlePower">
                        {this.props.title}
                        {this.props.disclaimerPath &&
                        this.props.disclaimerPath != '' &&
                        this.props.disclaimerPath != null &&
                        this.props.disclaimerPath != undefined ? (
                            <a
                                className="viewMorePowerKiosk"
                                href={this.props.disclaimerPath}
                                target="blank"
                                // onClick={() => window.open('_blank', this.props.disclaimerPath)}
                            >
                                Disclaimer
                            </a>
                        ) : null}
                    </div>
                    <div className="kwhPowerValue">
                        {this.props.perKwhValue}
                        {/* <span className="unitKwh">¢</span> */}
                    </div>
                    <div className="kwhLable">per kWh</div>
                    <div className="savingsPower">
                        <img src="" />
                        Savings Worth ${this.props.savings}
                    </div>

                    <div className="lineDiv"></div>
                    <div className="">
                        <div className="contractPower">{this.props.contract} Months</div>
                        <div className="kwhLable paddingKwh">Contract Term</div>
                    </div>
                    <div className="lineDiv"></div>
                    <div className="cancelationPower">
                        {' '}
                        {this.props.cancelationFee && this.props.cancelationFee != 'There is no cancellation fee.'
                            ? this.props.cancelationFee.length > 25 && this.props.isViewMore
                                ? this.props.cancelationFee
                                : this.props.cancelationFee.substring(0, 25)
                            : '$0'}
                        {this.props.cancelationFee && this.props.cancelationFee.length > 30 ? (
                            <div className="viewMorePowerKiosk" onClick={this.props.viewMoreClick}>
                                {this.props.cancelationFee &&
                                this.props.cancelationFee.length > 30 &&
                                this.props.isViewMore
                                    ? 'View Less'
                                    : 'View More'}
                            </div>
                        ) : null}
                    </div>
                    <div className="kwhLable paddingKwh">Cancellation Fee</div>

                    <div className="button-select-plan">
                        <Button
                            className="submitButton"
                            color={'#fff'}
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : '#273E59'
                            }
                            onClick={this.props.handleSelectPlanPower}
                        >
                            Select Plan
                        </Button>
                    </div>
                    <div className="viewDetailsValue" onClick={this.props.viewDetails}>
                        View Details{' '}
                    </div>
                </div>
            </div>
        );
    }
}

export default PowerKioskCard;
