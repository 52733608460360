import React from 'react';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import history from '../../routes/History';
import '../../styles/Login.scss';
import { connect } from 'react-redux';
import * as Action from '../../store/login/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import Button from '../../components/atoms/Button';
import mobileLogin from '../../Assets/images/login/loginbackMobile.svg';
// import { GoogleLogin } from 'react-google-login';
// import { AppState } from '../../store/rootReducer';
import history from '../../routes/History';
import AnalyticsService from '../analytics-service';
// import { sendAmplitudeData } from '../../amplitude';

type initialProps = {
    history: any;
    loginAction: any;
    loginState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {
    username: string;
    password: string;
    isButtonDisabled: Boolean;
    isDataArrived: boolean;
    helperText: string;
    isError: Boolean;
    usernameErr: string;
    passwordErr: string;
    keepMeSignedIn: boolean;
    email: string;
    emailError: string;
    forgotPasswordPage: boolean;
    resetedPassword: boolean;
    isLoginSuccess: boolean;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    moveId: any;
    loginFormStarted: boolean;
    FPpageStarted: boolean;
    viewedForgotPassPage: boolean;
};

class Login extends React.Component<initialProps, initialState> {
    state: initialState = {
        username: '',
        password: '',
        isButtonDisabled: false,
        isDataArrived: true,
        helperText: '',
        usernameErr: '',
        passwordErr: '',
        isError: false,
        keepMeSignedIn: false,
        email: '',
        emailError: '',
        forgotPasswordPage: false,
        resetedPassword: false,
        isLoginSuccess: true,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        moveId: '',
        loginFormStarted: false,
        FPpageStarted: false,
        viewedForgotPassPage: false
    };

    componentDidMount() {
        const token = localStorage.getItem('token');
        const isHomeOwner = localStorage.getItem('isHomeOwner');
        if (token) {
            if (isHomeOwner) {
                this.props.history.push('/dashboardhomeowner');
            } else {
                this.props.history.push('/dashboard');
            }
        }

        AnalyticsService.pageViewed({
            page: 'Login Page',
            category_name: 'Login Page Viewed'
        })
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            let moveData = nextProps.commonState.move[0];
            currentState.moveId = moveData.id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
        }
        return currentState;
    }

    componentDidUpdate(prevProps: initialProps) {
        const token = localStorage.getItem('token');
        if (
            prevProps.loginState !== this.props.loginState &&
            this.props.loginState &&
            this.props.loginState.login &&
            this.props.loginState.login.token &&
            token &&
            this.state.isLoginSuccess
        ) {
            this.setState({ isLoginSuccess: false });

            this.props.commonAction.moveGet();

            // this.props.history.push({
            //     pathname: '/dashboard',
            //     state: {
            //         fromSignUp: 'fromSignUp',
            //     },
            // });
        } else if (
            prevProps.loginState !== this.props.loginState &&
            this.props.loginState &&
            this.props.loginState.login &&
            this.props.loginState.login.hijack_login_url
        ) {
            window.open(this.props.loginState.login.hijack_login_url, '_self');
        }
        if (
            prevProps.loginState !== this.props.loginState &&
            this.props.loginState &&
            this.props.loginState.resetPassword &&
            this.props.loginState.resetPassword.message === 'Email Successfully Send'
        ) {
            this.setState({ forgotPasswordPage: false, resetedPassword: true });
        }

        if (
            prevProps.commonState.move != this.props.commonState.move &&
            this.props.commonState.move &&
            this.props.commonState.move[0]
        ) {
            if (this.props.commonState.move[0].status && this.props.commonState.move[0].status == 'Home Owner') {
                localStorage.setItem('isHomeOwner', 'true');
                history.push({
                    pathname: '/dashboardhomeowner',
                    state: {
                        fromSignUp: 'fromSignUp',
                    },
                });
            } else {
                history.push({
                    pathname: '/dashboard',
                    state: {
                        fromSignUp: 'fromSignUp',
                    },
                });
            }
        }
    }

    trackEvent = (page: string, fieldLabel: string) => {
        AnalyticsService.formFieldStarted({
            page: `${page} Page`,
            category_name: `Filling Out ${page} Form`,
            form_field_name: fieldLabel
        });
    }

    handleInputAnalytics = (fieldLabel: string) => {
        if (this.state.forgotPasswordPage && !this.state.FPpageStarted) {
            this.trackEvent('Forgot Password', fieldLabel);
            this.setState({ FPpageStarted: true });
        } 
        
        if (!this.state.forgotPasswordPage && !this.state.loginFormStarted) {
            this.trackEvent('Login', fieldLabel);
            this.setState({ loginFormStarted: true });
        }
    }

    handleButtonAnalytics = (copy: string) => {
        AnalyticsService.buttonClicked({
            page: this.state.resetedPassword ? 'Password Reset Sent Page' : this.state.forgotPasswordPage ? 'Forgot Password Page' : 'Login Page',
            category_name: 'Button Clicked',
            button_copy: copy,
            form_button: true
        });
    }

    handleFormCompletedAnalytics = (page :string, buttonCopy: string) => {
        AnalyticsService.formCompleted({
            page: `${page} Page`,
            category_name: `Submitted ${page} Form`,
            button_copy: buttonCopy,
            form_name: `${page} form`,
            form_button: true
        });
    }


    handleEmailChange = (e: any) => {
        this.setState({
            email: e.target.value,
        });

        this.handleInputAnalytics(this.state.forgotPasswordPage ? 'Enter Email Address' : 'Email');
    };

    handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            this.state.isButtonDisabled || this.handleLogin();
        }
    };

    handlePasswordChange = (e: any) => {
        this.setState({
            password: e.target.value,
        });

        this.handleInputAnalytics('Password');
    };

    validateFields = () => {
        let validate = true;
        // if (this.state.username === '' || !/^[a-zA-Z0-9]+$/.test(this.state.username)) {
        //     validate = false;
        //     this.setState({
        //         usernameErr: 'Please enter valid username',
        //     });
        // } else {
        //     this.setState({
        //         usernameErr: '',
        //     });
        // }

        if (this.state.password === '') {
            validate = false;
            this.setState({
                passwordErr: 'Please enter password',
            });
        } else {
            this.setState({
                passwordErr: '',
            });
        }

        return validate;
    };

    handleLogin = () => {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        this.handleButtonAnalytics('Sign in');
        this.handleFormCompletedAnalytics('Login', 'Sign in');
        AnalyticsService.signedIn();
        

        const {
            // username,
            password,
            email,
        } = this.state;
        let formdata = new FormData();

        formdata.append('username', email);
        formdata.append('password', password);
        formdata.append('site_name', slug && slug.slug ? slug.slug : 'moveeasy-demo');

        this.props.loginAction.login(formdata);
    };

    handleCheckboxChange = (type: any) => {
        if (type === 'keepMeSignedIn') {
            this.setState({
                keepMeSignedIn: !this.state.keepMeSignedIn,
            });
        }
    };

    handleSignUp = () => {
        this.handleButtonAnalytics('Sign up');
        this.props.history.push('./signUp');
        window.scrollTo(0, 0);
    };
    forgotPasswordHandler = () => {
        this.handleButtonAnalytics('Forgot password?');
        this.setState({ forgotPasswordPage: true });
    };
    resetHandler = () => {
        this.handleButtonAnalytics('Reset Password');
        this.handleFormCompletedAnalytics('Forgot Password', 'Reset Password');
        
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { email } = this.state;
        let formdata = new FormData();

        formdata.append('email', email);
        formdata.append('site_name', slug && slug.slug);
        email && this.props.loginAction.resetPassword(formdata);
    };
    backToLoginHandler = () => {
        this.handleButtonAnalytics('Back to Login');
        this.setState({ forgotPasswordPage: false, resetedPassword: false });
    };


    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const {
            email,
            password,
            emailError,
            passwordErr,
            // , keepMeSignedIn
        } = this.state;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className={isDouglasElliman ? 'douglasloginMainContainer' : 'loginMainContainer'}>
            <div className={isDouglasElliman ? 'container containerDouglas' : 'container'}>
                <div className={isDouglasElliman ? 'login-getStartedDiv login-getStartedDivD' : 'login-getStartedDiv'}>
                    <div className="loginContainer">
                        <div className={isDouglasElliman ? 'loginContent loginContentDoug' : 'loginContent'}>
                            {isDouglasElliman ? null : (
                                <div>
                                    <div className="loginWelcomeText">Welcome Back to {slug && slug.name}</div>
                                    <div
                                        className="loginLine"
                                        style={{
                                            backgroundColor: isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59',
                                        }}
                                    ></div>
                                    <div className="loginSignInText" style={{ textAlign: 'left' }}>
                                        Sign in to continue to your account.
                                    </div>
                                </div>
                            )}

                            <div className="login-form-main-div">
                                {this.state.forgotPasswordPage ? (
                                    <div>
                                        <div className="forgotPasswordMainText">
                                            We'll send password reset instructions to the email address associated with
                                            your account.
                                        </div>
                                        <div className="Login-inputDiv" style={{ marginBottom: '15px' }}>
                                            <TextField
                                                className="loginInput"
                                                error={Boolean(emailError)}
                                                label="Enter Email Address"
                                                type="email"
                                                placeholder="Enter Email Address"
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                value={email}
                                                onChange={e => this.handleEmailChange(e)}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                            {emailError !== '' ? (
                                                <div className="errorSignup">
                                                    <small>{emailError}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                        <Button
                                            className="signInButton"
                                            margin="0 0 15px 0"
                                            onClick={this.resetHandler}
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }
                                            disabled={!this.state.email}
                                        >
                                            Reset Password
                                        </Button>
                                        <Button
                                            className="signInButton"
                                            onClick={this.backToLoginHandler}
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                      }`
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }
                                            border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }`}
                                            backgroundColor="#fff"
                                        >
                                            Back to Login
                                        </Button>
                                    </div>
                                ) : this.state.resetedPassword ? (
                                    <div>
                                        <div className="resetedPasswordMain">
                                            Please check your email. Instructions were sent to your email address.
                                        </div>
                                        <Button
                                            className="signInButton"
                                            onClick={this.backToLoginHandler}
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                      }`
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }
                                            backgroundColor="#fff"
                                        >
                                            Back to Login
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        {isDouglasElliman ? (
                                            <div>
                                                <div className=" subHeadingForm">Welcome Back to</div>
                                                <div className="topHeadingForm">{slug && slug.name}</div>
                                                <div className="loginSignInText">
                                                    Sign in to continue to your account.
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="Login-inputDiv">
                                            <TextField
                                                className="loginInput"
                                                error={Boolean(emailError)}
                                                label="Email"
                                                type="email"
                                                placeholder="Email"
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                value={email}
                                                onChange={e => this.handleEmailChange(e)}
                                                onKeyPress={this.handleKeyPress}
                                                inputProps={{
                                                    'data-cy': 'login-input-email'
                                                }}
                                            />
                                            {emailError !== '' ? (
                                                <div className="errorSignup">
                                                    <small>{emailError}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="Login-inputDiv">
                                            <TextField
                                                className="loginInput"
                                                error={Boolean(passwordErr)}
                                                type="password"
                                                label="Password"
                                                id="outlined-start-adornment"
                                                variant="filled"
                                                onChange={e => this.handlePasswordChange(e)}
                                                onKeyPress={this.handleKeyPress}
                                                value={password}
                                                inputProps={{
                                                    'data-cy': 'login-input-password'
                                                }}
                                            />
                                            {passwordErr !== '' ? (
                                                <div className="errorLogin">
                                                    <small>{passwordErr}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* <div className="loginCheckWrapper">
                                    <Checkbox
                                        checked={keepMeSignedIn}
                                        onChange={() => this.handleCheckboxChange('keepMeSignedIn')}
                                        name="keepMeSignedIn"
                                        style={{
                                            color: keepMeSignedIn ? '#2270EE' : '#B3B3B3',
                                        }}
                                    />
                                    <div className="keepMeSignedIn">Keep me signed in until I sign out</div>
                                </div> */}
                                        <Button
                                            className="signInButton"
                                            data-cy="login-submit-form"
                                            onClick={this.handleLogin}
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug.primary_color
                                                    : '#273e59'
                                            }
                                            disabled={this.state.email && this.state.password ? false : true}
                                            
                                        >
                                            Sign in
                                        </Button>
                                        <div
                                            className={'loginForgotPassword'}
                                            onClick={this.forgotPasswordHandler}
                                            style={{ color: isDouglasElliman ? '#100b28' : '#0066f5' }}
                                        >
                                            Forgot password?
                                        </div>
                                        {/* <div className="loginLineDiv">
                                    <div className="loginHrLine"></div>
                                    <div style={{ color: '#6B6C6F', fontSize: '16px', fontWeight: 900 }}>or</div>
                                    <div className="loginHrLine"></div>
                                </div> */}
                                        {/* <div>
                                <GoogleLogin
                                    clientId="213806778009-rcppjlc2avd04okt2jg8tsr1ihiad7qj.apps.googleusercontent.com"
                                    buttonText="Continue with Google"
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    className="googleButton"
                                />
                            </div> */}
                                        <div className="signUpDiv">
                                            Not a member yet?{' '}
                                            <span className={'signUpText'} onClick={this.handleSignUp}>
                                                Sign Up
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!isDouglasElliman && (
                    <div className="loginMobileImgBottom">
                        <img src={mobileLogin} alt="" width="100%" />
                    </div>
                )}
            </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        loginAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    loginState: state.login,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
