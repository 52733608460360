import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30.514}
      height={37.449}
      viewBox="0 0 30.514 37.449"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupAppliance_prefix__a{fill:${slug.color_shade1}}`
            : ".signupAppliance_prefix__a{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        className="signupAppliance_prefix__a"
        d="M15.257 29.411a8.743 8.743 0 10-8.743-8.743 8.743 8.743 0 008.743 8.743zm0-16.551a7.819 7.819 0 017.773 7.063l-.313.211a2.539 2.539 0 01-2.856 0l-.542-.366a.467.467 0 00-.523 0l-.542.366a2.538 2.538 0 01-2.856 0l-.542-.366a.467.467 0 00-.523 0l-.541.366a2.538 2.538 0 01-2.857 0l-.541-.366a.467.467 0 00-.523 0l-.541.366a2.533 2.533 0 01-1.879.4 7.817 7.817 0 017.806-7.674zm-5.4 8.048l.279-.189.28.189a3.468 3.468 0 003.9 0l.28-.189.28.189a3.468 3.468 0 003.9 0l.28-.189.28.189a3.464 3.464 0 003.719.115 7.809 7.809 0 01-15.567.458 3.462 3.462 0 002.364-.573z"
      />
      <path
        className="signupAppliance_prefix__a"
        d="M2.18 20.358a2.552 2.552 0 001.99 2.5 11.365 11.365 0 100-5.007 2.552 2.552 0 00-1.99 2.507zm2.422-1.628a.467.467 0 00.417-.376 10.431 10.431 0 110 4.008.467.467 0 00-.417-.376 1.634 1.634 0 010-3.256z"
      />
      <path
        className="signupAppliance_prefix__a"
        d="M26.934 0H3.581A3.545 3.545 0 000 3.508v30.433a3.545 3.545 0 003.581 3.508h23.353a3.545 3.545 0 003.581-3.508V3.508A3.545 3.545 0 0026.934 0zm-1.246 36.534H4.826v-.226a1.854 1.854 0 011.868-1.833H23.82a1.854 1.854 0 011.868 1.833zm3.892-2.575a2.555 2.555 0 01-.778 1.826 2.662 2.662 0 01-1.869.749h-.311v-.226a2.78 2.78 0 00-2.8-2.748H6.694a2.78 2.78 0 00-2.8 2.748v.226h-.311a2.662 2.662 0 01-1.869-.749 2.555 2.555 0 01-.778-1.826V8.009H29.58zm0-26.866H.934V3.511a2.625 2.625 0 012.647-2.6h23.353a2.625 2.625 0 012.647 2.6z"
      />
      <path
        className="signupAppliance_prefix__a"
        d="M10.184 2.491H4.813a1.479 1.479 0 000 2.958h5.371a1.479 1.479 0 100-2.958zM7.001 4.515H4.808a.545.545 0 110-1.09h2.193zm3.179 0H7.936v-1.09h2.244a.545.545 0 110 1.09zM21.689 2.384a1.586 1.586 0 101.586 1.586 1.586 1.586 0 00-1.586-1.586zm0 2.238a.652.652 0 11.652-.652.652.652 0 01-.652.652zM25.581 2.384a1.586 1.586 0 101.586 1.586 1.586 1.586 0 00-1.586-1.586zm0 2.238a.652.652 0 11.652-.652.652.652 0 01-.652.652zM2.491 17.625a.467.467 0 00.467-.467v-5.9a.467.467 0 00-.934 0v5.9a.467.467 0 00.467.467zM2.491 10.217a.467.467 0 00.467-.467v-.376a.467.467 0 00-.934 0v.376a.467.467 0 00.467.467z"
      />
    </svg>
  )
}

export default SvgComponent
