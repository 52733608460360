import React, { useEffect } from 'react';
import './SavingCalculator.scss';

import Button from '../../../../components/atoms/Button';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AnalyticsService from '../../../../pages/analytics-service';
import { renderContactButtonText } from '../../../../utils/common-functions';

interface CongratsQuestionProps {
    agentImage?: any;
    brokerageImage?: any;
    imgName?: any;
    onAgentContact: any;
    serviceAgentData?: any;
    serviceProviders?: any;
    displayText?: string;
    showNoDataView?: boolean;
    analyticsData: any;
}

const CongratsQuestion = (props: CongratsQuestionProps) => {
    const { serviceAgentData, serviceProviders, displayText, showNoDataView, analyticsData } = props;

    useEffect(() => {
        AnalyticsService.cardViewed({
             ...analyticsData,
             category_name: "Mortgage Contact Card Viewed"
         });
    }, [])

    return (
        <section className={`mainQuestion ${!serviceAgentData?.photo_url?.length && serviceProviders?.logo?.length ? 'cardWithoutHeadShotWithLogo' : ''} ${!serviceAgentData?.photo_url?.length && !serviceProviders?.logo?.length ? 'cardWithoutHeadShotWithoutLogo' : ''}`}>
            {serviceProviders?.logo?.length && (
               <>
              {serviceProviders?.logo && <span className="logoIconCover">
                    <img width={'186px'} src={serviceProviders.logo} alt="" />
                </span>}
               </>
            )}

            {serviceAgentData?.photo_url?.length ? (
                <section className="MikeContainer">
                    <div className="ImagecontainerMike">
                       {props.agentImage && <img style={{ width: '80px', height: '80px', borderRadius: '40px' }} src={props.agentImage} alt="" />}
                    </div>
                    <div className="containerMikeDetails">
                        <h3 className="MikeText brokerNameCover">
                            {props.serviceAgentData?.first_name} {props.serviceAgentData?.last_name}
                        </h3>
                        <div className="phoneEmail">
                           {serviceAgentData?.primary_phone_no && <div className='d-flex align-items-center infoRow'>
                                <span>
                                    <LocalPhoneOutlinedIcon color="action" fontSize="small" />
                                </span>{' '}
                                <span className="details brokenPhoneNumber">
                                    {serviceAgentData.primary_phone_no}
                                </span>{' '}
                            </div>}
                            <div>
                                {serviceAgentData?.email && <span className="email d-flex align-items-center infoRow">
                                    <span>
                                        <EmailOutlinedIcon color="action" fontSize="small" />
                                    </span>{' '}
                                    <span className="details brokerEmail">
                                        {serviceAgentData.email}
                                    </span>
                                </span>}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                ''
            )}
            {!serviceAgentData?.photo_url?.length && serviceProviders?.logo?.length ? (
                <section className="MikeContainer">
                    <div className="containerMikeDetails">
                        {props.serviceProviders?.name && <h3 className="MikeText brokerNameCover">{props.serviceProviders.name}</h3>}
                        <div className="phoneEmail">
                           { serviceAgentData?.primary_phone_no && <div className='d-flex align-items-center infoRow'>
                                <span>
                                    <LocalPhoneOutlinedIcon color="action" fontSize="small" />
                                </span>{' '}
                                <span className="details brokenPhoneNumber">
                                    {serviceAgentData.primary_phone_no}
                                </span>{' '}
                            </div>}
                            <div>
                                {serviceAgentData?.email && <span className="email d-flex align-items-center infoRow">
                                    <span>
                                        <EmailOutlinedIcon color="action" fontSize="small" />
                                    </span>{' '}
                                    <span className="details brokerEmail">
                                        {serviceAgentData.email}
                                    </span>
                                </span>}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                ''
            )}
            {!serviceAgentData?.photo_url?.length && !serviceProviders?.logo?.length ? (
                <section className="MikeContainer">
                    <div className="containerMikeDetails">
                        {serviceProviders?.name && <h3 className="MikeText brokerNameCover">{serviceProviders.name}</h3>}
                        <div className="phoneEmail">
                            {serviceProviders?.primary_phone_no && <div className='d-flex align-items-center infoRow'>
                                <span>
                                    <LocalPhoneOutlinedIcon color="action" fontSize="small" />
                                </span>{' '}
                                <span className="details brokenPhoneNumber">
                                    {serviceProviders.primary_phone_no}
                                </span>{' '}
                            </div>}
                            <div>
                               {serviceProviders?.email && <span className="email d-flex align-items-center infoRow">
                                    <span>
                                        <EmailOutlinedIcon color="action" fontSize="small" />
                                    </span>{' '}
                                    <span className="details brokerEmail">
                                        {serviceProviders.email}
                                    </span>
                                </span>}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                ''
            )}
            <span className="heading brokerBannerHeading">
                {displayText || 'Have any questions about how you can payoff your loan sooner?'}
            </span>
            <span>
                <Button
                    onClick={props.onAgentContact}
                    margin={'2px 16px'}
                    width={'120px'}
                    height={'40px'}
                    borderRadius="4px"
                    children={renderContactButtonText(serviceAgentData, serviceProviders)}
                    disabled={showNoDataView}
                />
            </span>
        </section>
    );
};

export default CongratsQuestion;
