import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './TrustedProsSteps.scss';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import * as CommonAction from '../../store/common/actions';
import RoomIcon from '@material-ui/icons/Room';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
import arrowIcon from '../../Assets/images/TrustedPros/arrow_back_black_24dp.svg';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import { formCompletedSegment } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type InitialProps = {
    street: string;
    zipCode: string;
    history: any;
    commonAction: any;
    commonState: any;
    location: any;
    trustedProsAction: any;
    trustedProsState: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};
type InitialState = {
    activeStep: number;
    address: string;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    moveId: any;
    isDataArrived2: boolean;
    question1: string;
    question1Options: any;
    question2: string;
    question2Options: any;
    buffer: boolean;
    serviceId: any;
    serviceName: any;
    slug: any;
    isDataArrived3: boolean;
    homeTaskId: any;
    taskId: any;
    isMoveDataArrived: boolean;
    selectedOption: any;
    homeAdvisorData: any;
    homeAdvisorApiCall: boolean;
    checklistDataArrived: boolean;
    yesField: any;
    isVendorsDataArrived: boolean;
    serviceDetails: any;
    brokerage_name: any;
    brokerage_id: any;
    agent_name: any;
    agent_id: any;
    prefilled: any;
    brokerage_key: any;
    agent_key: any;
};
// let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

class TrustedProsSteps extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        activeStep: 1,
        address: '',
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
        isDataArrived2: true,
        isDataArrived3: true,
        question1: '',
        question1Options: [],
        question2: '',
        question2Options: ['No', 'Yes'],
        buffer: false,
        serviceId: '',
        serviceName: '',
        slug: { primary_color: '' },
        homeTaskId: '',
        taskId: '',
        isMoveDataArrived: true,
        selectedOption: [],
        homeAdvisorData: [],
        homeAdvisorApiCall: true,
        checklistDataArrived: true,
        yesField: '',
        isVendorsDataArrived: true,
        serviceDetails: {
            vendors: [],
        },
        brokerage_name: '',
        brokerage_id: '',
        agent_name: '',
        agent_id: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };

    componentDidMount() {
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }
        // this.props.commonAction.moveGet();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        if (this.props && this.props.location && this.props.location.state && this.props.location.state.service_name) {
            this.props.trustedProsAction.categoriesQuestions(this.props.location.state.service_name);
        }

        if (
            !(
                this.props &&
                this.props.CheckListDashboardState &&
                this.props.CheckListDashboardState.checklist &&
                this.props.CheckListDashboardState.checklist.length
            )
        ) {
            this.props.dashboardAction.checklist();
        }

        let TrustedProsAddress: any = localStorage.getItem('TrustedProsAddress');
        if (TrustedProsAddress) {
            this.setState({
                address: TrustedProsAddress,
            });
        }

        // let CityStateZip: any= localStorage.getItem('CityStateZip');
        // if(CityStateZip){
        //     this.props.trustedProsAction.vendors({
        //         address:CityStateZip
        //     });
        // }else{

        // this.props.trustedProsAction.vendors({
        //     address:''
        // })
        // }
        // let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        // let brokerage: any;

        // brokerage = brokerageCheck.display_name;
        // let brokerage_id: any = brokerageCheck.brokerage_link;
        // let agent_id: any = brokerageCheck.id;

        // let agent_name: any = brokerageCheck.contact_name;
        // let move_id: any = localStorage.getItem('moveKey');
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.categoriesQuestions &&
            nextProps.trustedProsState.categoriesQuestions.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            //    currentState.homeTaskId= nextProps.trustedProsState.categoriesQuestions[0].id

            nextProps.trustedProsState.categoriesQuestions.map((item: any) => {
                currentState.question1Options.push(item);
            });
            currentState.question1Options.push({ task_name: 'Others' });
            currentState.activeStep = 0;
        }

        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            // currentState.fullName = `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;

            if (!localStorage.getItem('TrustedProsAddress')) {
                currentState.street = nextProps.commonState.move[0].destination_street;
                currentState.zipcode = nextProps.commonState.move[0].destination.zip_code;
                currentState.moveId = nextProps.commonState.move[0].id;
                currentState.city = nextProps.commonState.move[0].destination.city_name;
                currentState.address = nextProps.commonState.move[0].destination_full_address;
                currentState.state = {
                    name: nextProps.commonState.move[0].destination.state_code,
                    label: nextProps.commonState.move[0].destination.state_code,
                };
            }
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.homeAdvisor &&
            nextProps.trustedProsState.homeAdvisor.results &&
            nextProps.trustedProsState.isGetHomeAdvisor
        ) {
            nextProps.trustedProsState.isGetHomeAdvisor = false;
            currentState.homeAdvisorData = nextProps.trustedProsState.homeAdvisor.results;
        }

        if (
            nextProps &&
            nextProps.trustedProsState &&
            nextProps.trustedProsState.vendors &&
            nextProps.trustedProsState.vendors.length &&
            currentState.isVendorsDataArrived
        ) {
            let vendors: any = [];
            nextProps.trustedProsState.vendors.map((vendor: any) => {
                if (vendor.id == currentState.serviceId) {
                    vendors = vendor.vendors;
                }

                return vendors;
            });
            currentState.serviceDetails = {
                vendors: vendors,
            };
        }
        return currentState;
    }

    yesHandler = (e: any) => {
        this.setState({
            yesField: e.target.value,
        });
    };

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    country === 'USA' ||
                    country === 'US'
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    // this.state.moveId
                ) {
                    // this.props.commonAction.moveAddressUpdate({
                    //     destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    //     move_id: this.state.moveId,
                    // });

                    localStorage.setItem('TrustedProsAddress', `${streetNumber} ${route}, ${city}, ${state}, ${zip}`);
                    localStorage.setItem('CityStateZip', ` ${city}, ${state}, ${zip}`);

                    this.props.trustedProsAction.vendors({
                        address: `${city},${state},${zip}`,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleChangeQuestion1 = (e: any) => {
        let data: any = [];

        data.push(this.state.question1Options.find((item: any) => item.task_name === e.target.value));
        this.setState({
            question1: e.target.value,
            selectedOption: data,
            homeTaskId: data[0].id ? data[0].id : '',
            taskId: data[0].task_id ? data[0].task_id : '',
        });
    };

    handleChangeQuestion2 = (e: any) => {
        this.setState({ question2: e.target.value });
    };

    getSteps = () => {
        return ['What Electrician service are you looking for?', 'Anything else you would like to tell the pros?'];
    };

    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                {this.state.activeStep + 1}. What {this.state.serviceName} service are you looking for?
                                <div className="Para11 desktopPara">
                                    QUESTION {this.state.activeStep + 1} OF {this.getSteps().length}
                                </div>
                            </FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={this.state.question1}
                                onChange={this.handleChangeQuestion1}
                            >
                                {this.state.question1Options &&
                                    this.state.question1Options.map((option: any, i: number) => {
                                        return (
                                            <div
                                                key={i}
                                                className="radioInputs"
                                                style={{
                                                    border:
                                                        this.state.question1 === option
                                                            ? '1px solid #009cbd'
                                                            : '1px solid #D8DCE6',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={option.task_name}
                                                    control={<Radio />}
                                                    label={option.task_name}
                                                />
                                                {this.state.question1 === 'Others' && option.name === 'Others' ? (
                                                    <TextField
                                                        className="otherInput"
                                                        placeholder="other"
                                                        onChange={() => {}}
                                                        multiline
                                                        id="outlined-multiline-static"
                                                        rows={2}
                                                        variant="outlined"
                                                        // onFocus={}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                {this.state.question1Options.length ? this.state.activeStep + 1 : 1}. Anything else you
                                would like to tell the pros?
                                <div className="Para11 desktopPara">
                                    QUESTION {this.state.question1Options.length ? this.state.activeStep + 1 : 1} OF{' '}
                                    {this.state.question1Options.length ? this.getSteps().length : 1}
                                </div>
                            </FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={this.state.question2}
                                onChange={this.handleChangeQuestion2}
                            >
                                {this.state.question2Options.map((option: any, i: number) => {
                                    return (
                                        <div
                                            key={i}
                                            className="radioInputs"
                                            style={{
                                                border:
                                                    this.state.question2 === option
                                                        ? '1px solid #009cbd'
                                                        : '1px solid #D8DCE6',
                                            }}
                                            // style={{
                                            //     border:
                                            //         (this.state.question2 === option && isDouglasElliman
                                            //             ? (this.state.question2 === option ? '1px solid #009cbd' :'1px solid #006dff')
                                            //             : (this.state.question2 === option  ?'1px solid #006dff' : '1px solid #009cbd') ),
                                            // }}
                                        >
                                            <FormControlLabel value={option} control={<Radio />} label={option} />
                                            {this.state.question2 === 'Yes' && option === 'Yes' ? (
                                                <TextField
                                                    className="otherInput"
                                                    placeholder="other"
                                                    onChange={(e: any) => {
                                                        this.yesHandler(e);
                                                    }}
                                                    multiline
                                                    id="outlined-multiline-static"
                                                    rows={2}
                                                    variant="outlined"
                                                    value={this.state.yesField}
                                                    // onFocus={}
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                );

            default:
                return 'step not found';
        }
    };

    handleNext = async () => {

        updateChecklistTask(30, this.props.dashboardAction);

        await this.setState({
            activeStep: this.state.activeStep + 1,
        });
        if (this.state.activeStep === 2) {
            if (
                this.state.serviceDetails &&
                this.state.serviceDetails.vendors &&
                !this.state.serviceDetails.vendors.length
            ) {
                this.props.history.push({
                    pathname: '/dashboard/ourvendors/request_service',
                    state: {
                        serviceDetails: this.state.serviceDetails,
                        description: this.state.yesField,
                        service_name: this.state.serviceName,
                        serviceId: this.state.serviceId,
                        homeTaskId: this.state.homeTaskId,
                        taskId: this.state.taskId,
                        yesField: this.state.yesField,
                        vendor_type: this.state.selectedOption[0] && this.state.selectedOption[0].category_name,
                        categoryQuestion: this.state.question1Options && this.state.question1Options.length,
                    },
                });
            }
            // else if (
            //     this.state.serviceDetails &&
            //     this.state.serviceDetails.vendors &&
            //     !this.state.serviceDetails.vendors.length &&
            //     this.state.homeAdvisorData.length
            // ) {
            //     this.props.history.push({
            //         pathname: '/dashboard/ourvendors/steps',
            //         state: {
            //             service_name: this.state.serviceName,
            //             id: this.state.serviceId,
            //             homeTaskId: this.state.homeTaskId,
            //             homeAdvisorData: this.state.homeAdvisorData,
            //             description: this.state.yesField,
            //             address: this.state.address,
            //             taskId: this.state.taskId,
            //             vendor_type: this.state.selectedOption[0].category_name,
            //         },
            //     });
            // }
            else {
                this.props.history.push({
                    pathname: '/dashboard/ourvendors/recommended',
                    state: {
                        service_name: this.state.serviceName,
                        id: this.state.serviceId,
                        homeTaskId: this.state.homeTaskId,
                        description: this.state.yesField,
                    },
                });
            }
            let move_id: any = localStorage.getItem('moveKey');
            formCompletedSegment(
                'Form Completed',
                'Trusted Pros',
                'Trusted Pros',
                this.state.brokerage_name,
                this.state.brokerage_key,
                this.state.agent_name,
                this.state.agent_key,
                '',
                'Next',
                true,
                this.state.prefilled,
                'Trusted Pros Form',
                this.getSteps().length,
                move_id,
            );
        }

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            'Button Clicked',
            {
                page: 'Dashboard',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                button_copy: 'Next',
                category_name: 'Trusted Pros',
                card: false,
                card_title: '',
                popup: false,
                banner: false,
                banner_title: '',
                brand_name: '',
            },
            { user_id: move_id },
        );
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    goBack = () => {
        if (this.state.activeStep === 0 || this.state.question1Options.length == 0) {
            this.props.history.push('/dashboard/ourvendors');
        } else {
            this.setState({
                activeStep: this.state.activeStep - 1,
            });
        }
    };

    setValue = (service_name: any, id: any, serviceDetails: any) => {
        this.setState({
            serviceName: service_name,
            serviceId: id,
            serviceDetails: serviceDetails,
            buffer: true,
        });
    };

    render() {
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.service_name &&
            this.props.location.state.id &&
            this.props.location.state.serviceDetails &&
            this.state.buffer === false
        ) {
            this.setValue(
                this.props.location.state.service_name,
                this.props.location.state.id,
                this.props.location.state.serviceDetails,
            );
        }

        // if (
        //     this.state.serviceDetails &&
        //     this.state.serviceDetails.vendors &&
        //     !this.state.serviceDetails.vendors.length &&
        //     this.state.homeTaskId &&
        //     this.state.homeAdvisorApiCall
        // ) {
        //     this.state.homeAdvisorApiCall = false;
        //     const payload = {
        //         address: this.state.address,
        //         address_type: 'destination',
        //         task_id: this.state.taskId,
        //         vendor_type: this.state.selectedOption[0].category_name,
        //     };
        //     this.props.trustedProsAction.getHomeAdvisor(payload);
        // }
        const steps = this.getSteps();
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Trusted Pros',
                url: ''
            }
        ]

        return (
            <div
                className={
                    isDouglasElliman ? 'trustedPros-main-page trustedPros-main-pageDouglas' : 'trustedPros-main-page'
                }
            >
                <div className="trustedPros-title-div">
                    <div className="main-head-2 allServicesHeadCover">
                        <h2 className="heading">Trusted Pros</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                    </div>
                    <div
                        className={
                            isDouglasElliman
                                ? 'trustedProsAddressDiv trustedProsAddressDivDoug'
                                : 'trustedProsAddressDiv'
                        }
                    >
                        <div className="addressText">Your address</div>
                        <RoomIcon
                            className="addressRoomIcon"
                            style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF' }}
                        />
                        <AutoComplete
                            className="trustedProsStepsAutoComplete"
                            value={this.state.address}
                            onChange={e => {
                                this.setState({ address: e });
                            }}
                            onSelect={this.handleSelect}
                            // placeholder="Street"
                            // inputTitle="To"
                        />
                    </div>
                </div>

                <div className="trustedProsStepper">
                    <div className="TrustedProsSteps">
                        <div className="TrustedProsBackIconDiv">
                            <img src={arrowIcon} onClick={this.goBack} style={{ cursor: 'pointer' }} alt="" />

                            <div className="serviceTitle">{this.state.serviceName}</div>
                        </div>
                        <div>{this.getStepContent(this.state.activeStep)}</div>

                        <Stepper activeStep={this.state.activeStep}>
                            {steps.map((label: any) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: { optional?: React.ReactNode } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}></StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div className="mobilePara">
                            QUESTION {this.state.activeStep + 1} OF {this.getSteps().length}
                        </div>
                        <div className="buttonsTrustedPros">
                            <Button
                                className="btnTrustedPros"
                                onClick={this.handleNext}
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : '#273E59'
                                }
                                disabled={this.state.question2 == 'Yes' && this.state.yesField == ''}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    {/* )} */}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    trustedProsState: state.trustedPros,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustedProsSteps);
