import {
GET_SELECTABLE_SERVICE,
GET_SELECTABLE_SERVICE_ERROR,
GET_SELECTABLE_SERVICE_SUCCESS
} from './actionTypes';

import { SelectableServicesActions, SelectableServicesState } from './types';

const initialState: SelectableServicesState = {
   selectableServices:[]
};

export default (state = initialState, action: SelectableServicesActions) => {
    switch (action.type) {
        case GET_SELECTABLE_SERVICE:
            return {
                ...state,
                selectableServices: [],
                error: null,
            };
        case GET_SELECTABLE_SERVICE_SUCCESS:
            return {
                ...state,
                selectableServices: action.payload.selectableServices,
                error: null,
            };
        case GET_SELECTABLE_SERVICE_ERROR:
            return {
                ...state,
                selecttableServices: [],
                error: action.payload.error,
            };

            
        default:
            return {
                ...state,
            };
    }
};
