import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InsuranceProfile from '../../Assets/images/profile2.png';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import './Navbar.css';
import { connect } from 'react-redux';
import { moveGet, header } from '../../store/common/actions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Popover } from '@material-ui/core';
import DashboardIcon from '../../Assets/images/headerImages/dashboard';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logout from '../../Assets/images/logout';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from 'react-loading-skeleton';
import DouglasLogo from '../../Assets/images/DouglasImages/DouglasLogo.svg';
import MoreIconD from '../../Assets/images/DouglasImages/header/more';
import nextMoveGIF from '../../Assets/images/NextMove/Next_Move.gif';
import MoreIcon from '../../Assets/images/headerImages/more';
import { getIcon } from '../_icon';
import { UIContext } from '../../UIContext';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // marginBottom: '100px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: '15px',
        cursor: 'pointer',
    },
    tabRoot: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        marginTop: '10px',
        // marginBottom: '100px',
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

type initialProps = {
    history: any;
    commonState?: any;
};

const Navbar = (props: initialProps) => {
    const dispatch = useDispatch();
    const context = useContext(UIContext);
    const classes: any = useStyles();
    const [value, setValue] = useState(0);
    const location = useLocation();
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [hasToken, setHasToken] = useState(false);
    // const [moveData, setMoveData] = React.useState({});
    // const [errorData, setErrorData] = React.useState(null);
    // const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    let headerOption: any = [];
    let moreOption: any = [];
    let AllOption: any = [];
    let totalValue: any = new Map();
    let slug: any = localStorage.getItem('slug');

    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    if (
        slug &&
        slug.slug === 'opentoclose' &&
        location.pathname === '/signUp' &&
        location.search.includes('hash_code')
    ) {
        localStorage.setItem('otcbrokerage', 'otcOnBehalfOf');
    }
    if (
        slug &&
        slug.slug === 'opentoclose' &&
        location.pathname === '/signUp' &&
        !location.search.includes('hash_code')
    ) {
        localStorage.removeItem('otcbrokerage');
    }

    let isHomeOwner: boolean = false;
    if (localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') == 'true') {
        isHomeOwner = true;
    }

    const [left, setleft] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setleft(open);
    };

    let num: any = 7;
    if (window.screen.width >= 1920) {
        num = 8;
    }
    if (window.screen.width < 1025) {
        num = 5;
    }
    if (window.screen.width < 769) {
        num = 4;
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        const updateTokenStatus = () => {
            setHasToken(true);
        };

        if (token) {
            updateTokenStatus();
        }

        let url = location.pathname;
        url = url.split('/')[2];
        if (headerOption && headerOption.length > 0 && totalValue && totalValue.size > 0 && url) {
            for (let [key, value1] of totalValue) {
                if (url === value1) {
                    setValue(key);
                }
            }
        } else {
            setValue(-1);
        }
        return () => {
            setHasToken(false);
        };
    }, [headerOption]);
    useEffect(() => {
        if (
            !(
                props.commonState &&
                props.commonState.move &&
                props.commonState.move.length > 0 &&
                props.commonState.move[0]
            )
        ) {
            dispatch(moveGet());
        }
    }, []);

    useEffect(() => {
        slug = slug ? slug.slug : context["domain"]
        if (slug) {
            const isHomeOwner = localStorage.getItem('isHomeOwner');
            const moveCheck = localStorage.getItem('moveId');
            dispatch(header({ partner_slug: slug, fetchMoveService: isHomeOwner ? 1 : 0, moveId: moveCheck }));
        }
    }, []);

    useEffect(() => {
        headerOption.forEach((element: any, i:number) => {
            if(window.location.pathname.includes(element.route)){
                setValue(i)
            }
        });
    }, [window.location.pathname])

    // useEffect(() => {
    //     const data = move && move[0];
    //     setMoveData(data);
    // }, [move]);
    // useEffect(() => {
    //     setErrorData(error);
    // }, [error]);

    const handleMenuItemClick = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: false,
                    more_drop_down_list: true,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
        }
    };

    // const handleClick = (event: any) => {
    //     event.stopPropagation();
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClickMore = (event: any) => {
        event.stopPropagation();
        setAnchorEl1(event.currentTarget);
        // setValue(8);
    };
    const handleAnalytics = () => {};

    const handleAnalytics1 = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: true,
                    more_drop_down_list: false,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
        }
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleCloseMore = () => {
        setAnchorEl1(null);
    };
    const handleChange = (newValue: any) => {
        if (parseInt(newValue.currentTarget.id.split('-')[2]) !== num) {
            setValue(parseInt(newValue.currentTarget.id.split('-')[2]));
        }
        // setValue(newValue.currentTarget.innerText)
        // return (<Link to='/dashboard/mortgage'></Link>);
    };

    const logoutClicked = async () => {
        localStorage.clear();

        let brokerage: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.display_name
                ? props.commonState.move[0].realtor.display_name
                : '';
        let brokerage_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.brokerage_link
                ? props.commonState.move[0].realtor.brokerage_key
                : '';
        let agent_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.brokerage_link
                ? props.commonState.move[0].realtor.realtor_key
                : '';
        let agent_name: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.contact_name
                ? props.commonState.move[0].realtor.contact_name
                : '';

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        let move_id: any = localStorage.getItem('moveKey');
        await analytics.track(
            'Signed Out ',
            { brokerage_name: brokerage, brokerage_key: brokerage_key, agent_key: agent_key, agent_name: agent_name },
            { user_id: move_id },
        );
        props.history.push('/signUp');
    };
    const anaySeg = async (view: any) => {
        let brokerage: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.display_name
                ? props.commonState.move[0].realtor.display_name
                : '';
        let brokerage_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.id
                ? props.commonState.move[0].realtor.brokerage_key
                : '';
        let agent_key: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.id
                ? props.commonState.move[0].realtor.realtor_key
                : '';
        let agent_name: any =
            props.commonState.move &&
            props.commonState.move[0] &&
            props.commonState.move[0].realtor &&
            props.commonState.move[0].realtor.contact_name
                ? props.commonState.move[0].realtor.contact_name
                : '';
        let move_id: any =
            props.commonState.move && props.commonState.move[0] && props.commonState.move[0].id
                ? props.commonState.move[0].id
                : '';

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        await analytics.track(
            `${view}`,
            {
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: agent_name,
                page: 'HomeOwner Dashboard',
            },
            { user_id: move_id },
        );
    };
    const list = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Link to={isHomeOwner ? `/dashboardhomeowner` : `/dashboard`}>
                <ListItem
                    key={0}
                    style={{
                        padding: '15px 16px',
                    }}
                    className={
                        location.pathname === '/dashboard' || location.pathname === '/dashboardhomeowner'
                            ? 'mobileListActive'
                            : 'mobileList'
                    }
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        style={{
                            fontSize: '14px',
                        }}
                        primary="Dashboard"
                    />
                </ListItem>
            </Link>
            <Divider />
            <List>
                {AllOption &&
                    AllOption.length > 0 &&
                    AllOption.map((item: any, i: number) => {
                        let icon = getIcon(isDouglasElliman, item);
                        return (
                            <Link
                                key={i}
                                to={{
                                    pathname: `/dashboard/${item.route}`,
                                    state:
                                        props.commonState &&
                                        props.commonState.header &&
                                        props.commonState.header.length > 0
                                            ? props.commonState.header
                                            : '',
                                }}
                            >
                                <ListItem
                                    className={
                                        value === item.id &&
                                        location.pathname !== '/dashboard' &&
                                        location.pathname !== '/dashboardhomeowner'
                                            ? 'mobileListActive'
                                            : 'mobileList'
                                    }
                                    key={item.id}
                                    onClick={() => handleMenuItemClick(item)}
                                >
                                    <ListItemIcon>
                                        {/* <img src={icon}  /> */}
                                        {icon}
                                    </ListItemIcon>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            color: `${
                                                location.pathname === `/dashboard/${item.route}`
                                                    ? `#333333`
                                                    : '#6B6C6F'
                                            }`,
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                </ListItem>
                            </Link>
                        );
                    })}
            </List>
            <div className="lineLogout"></div>
            <div className="logoutButton1" onClick={() => logoutClicked()}>
                <Logout className="logoutText" />
                <div className="logoutText">Logout</div>
            </div>
        </div>
    );

    const handleChangeRoute = () => {
        if (isHomeOwner) {
            props.history.push('/dashboardhomeowner');
        } else {
            props.history.push('/dashboard');
        }
    };

    if (props.commonState && props.commonState.header && props.commonState.header.length > 0) {
        let array123 = props.commonState.header.filter((item: any) => item.slug !== 'exclusive_deals');

        array123.map((item: any, index: number) => {
            let slug =
                item.react_url_route && item.react_url_route.split('/').length > 1
                    ? item.react_url_route.split('/')[2]
                    : item.slug;

            if (index < num) {
                headerOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    is_default: item.is_default,
                    route: slug,
                    id: index,
                });
                totalValue.set(index, slug);
            } else {
                moreOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index + 1,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index + 1,
                });
                totalValue.set(index + 1, slug);
            }
        });

        if (!isHomeOwner) {
            moreOption.push({
                name: 'Checklist',
                icon: '',
                route: 'checklist',
                id: props.commonState.header.length + 1,
            });

            AllOption.push({
                name: 'Checklist',
                icon: '',
                route: 'checklist',
                id: props.commonState.header.length + 1,
            });

            totalValue.set(props.commonState.header.length + 1, 'checklist');
        }
    }

    let SkeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <div>
            <div className={`${classes.root} MobileScreenHeader`}>
                <AppBar position="fixed">
                    <Toolbar>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '0px 15px',
                            }}
                        >
                            {location.pathname === '/signUp' ||
                            location.pathname === '/signUp/welcome' ||
                            location.pathname === '/signUp/HomeOwnerWelcome' ||
                            location.pathname === '/signUp/preapproval' ||
                            location.pathname === '/signUp/getPreApproval' ||
                            location.pathname === '/signUp/haveAPreApproval' ||
                            location.pathname === '/signUp/preApproval/next' ||
                            location.pathname === '/servicerequirement' ||
                            location.pathname === '/laCarte' ||
                            location.pathname === '/scheduleacall' ||
                            location.pathname === '/signUp/next' ||
                            location.pathname.includes('/resetpassword') ||
                            location.pathname.includes('/setpassword') ||
                            location.pathname === '/premovesignup' ||
                            location.pathname === '/standalonepage' ||
                            location.pathname === '/standalonepagerecommendations' ||
                            location.pathname === '/standalonefinal' ||
                            location.pathname === '/dashboard/my-documents/preview' ||
                            location.pathname === '/login' ? null : (
                                <div>

                                    {window.location.pathname.includes('/dashboardhomeowner') ? (
                                            <IconButton
                                            edge="start"
                                            className={classes.menuButton}
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={toggleDrawer(true)}
                                        >
                                            <MenuIcon />
                                            <React.Fragment>
                                                <Drawer anchor="left" open={left} onClose={toggleDrawer(false)}>
                                                    {list()}
                                                </Drawer>
                                            </React.Fragment>
                                        </IconButton>
                                        ) : (
                                            <ArrowBackIcon onClick={() => props.history.push('/dashboardhomeowner')} />
                                        )}

                                    
                                    {/* <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                    >
                                        {window.location.pathname.includes('/dashboardhomeowner') ? (<></>
                                            // <MenuIcon onClick={toggleDrawer(true)} />
                                        ) : (
                                            <ArrowBackIcon onClick={() => props.history.push(isHomeOwner ? '/dashboardhomeowner' : '/dashboard')} />
                                        )}
                                        <React.Fragment>
                                            <Drawer anchor="left" open={left} onClose={toggleDrawer(false)}>
                                                {list()}
                                            </Drawer>
                                        </React.Fragment>
                                    </IconButton> */}
                                </div>
                            )}
                            <div
                                onClick={() => {
                                    if (isHomeOwner) {
                                        props.history.push('/dashboardhomeowner');
                                    } else {
                                        props.history.push('/dashboard');
                                    }
                                }}
                            >
                                {isDouglasElliman ? (
                                    <img style={{ marginLeft: '-25px' }} src={DouglasLogo} alt="" />
                                ) : (
                                    <img src={slug && slug.logo} width="80px" alt="" />
                                )}
                            </div>
                            {slug && slug.is_brokerage == false ? (
                                <div>
                                    <Avatar
                                        style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                        alt="Remy Sharp"
                                        src={slug && slug.contact_logo ? slug.contact_logo : InsuranceProfile}
                                    />
                                </div>
                            ) : isDouglasElliman ? (
                                <div></div>
                            ) : null}
                        </div>
                    </Toolbar>
                </AppBar>
            </div>{' '}
            <div className={`${classes.root} bigScreenHeader`}>
                <AppBar position="fixed" className="firstBar" style={{ zIndex: 10000 }}>
                    <div className="InnerNavDiv">
                        <Toolbar>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => {
                                            if (localStorage.getItem('token')) {
                                                if (isHomeOwner) {
                                                    props.history.push('/dashboardhomeowner');
                                                } else {
                                                    props.history.push('/dashboard');
                                                }
                                            }
                                        }}
                                    >
                                        <div className="icon-button-image">
                                            <img src={isDouglasElliman ? DouglasLogo : slug && slug.logo} alt="" />
                                        </div>
                                    </IconButton>
                                    {location.pathname === '/' ||
                                    location.pathname === '/signUp' ||
                                    location.pathname === '/signUp/welcome' ||
                                    location.pathname === '/signUp/HomeOwnerWelcome' ||
                                    location.pathname === '/signUp/preapproval' ||
                                    location.pathname === '/signUp/getPreApproval' ||
                                    location.pathname === '/signUp/haveAPreApproval' ||
                                    location.pathname === '/signUp/preApproval/next' ||
                                    location.pathname === '/servicerequirement' ||
                                    location.pathname === '/laCarte' ||
                                    location.pathname === '/scheduleacall' ||
                                    location.pathname === '/signUp/' ||
                                    location.pathname === '/signUp/next' ||
                                    location.pathname.includes('/setpassword') ||
                                    location.pathname.includes('/resetpassword') ||
                                    location.pathname === '/premovesignup' ||
                                    location.pathname === '/standalonepage' ||
                                    location.pathname === '/standalonepagerecommendations' ||
                                    location.pathname === '/standalonefinal' ||
                                    location.pathname === '/login' ? null : (
                                        <>
                                            {hasToken && (
                                                <Typography
                                                    variant="h6"
                                                    className={classes.title}
                                                    noWrap
                                                    onClick={handleChangeRoute}
                                                >
                                                    {isHomeOwner ? 'Home Owner Dashboard' : 'Dashboard'}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {isHomeOwner && hasToken ? (
                                        <div className="nextmoveIconMainDiv">
                                            <div>
                                                <img src={nextMoveGIF} alt="" />
                                            </div>
                                            <div
                                                className="nextMoveSymbol"
                                                // onClick={() => {
                                                //     anaySeg('Next Move Clicked');
                                                // }}
                                            >
                                                <div
                                                    className="clickNext"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        props.history.push('/dashboard/nextmove');
                                                        anaySeg('Next Move Clicked');
                                                    }}
                                                >
                                                    Click Here
                                                </div>
                                                <Typography
                                                    variant="h6"
                                                    className={classes.title}
                                                    noWrap
                                                    onClick={() => {
                                                        props.history.push('/dashboard/nextmove');
                                                        anaySeg('Next Move Clicked');
                                                    }}
                                                >
                                                    {isHomeOwner ? 'Your Next Move' : ''}
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : null}

                                    {/* {window.location.pathname === '/' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ? null : (
                                        <div className="service-main-div">
                                            Services
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                                className={classes.title}
                                            >
                                                <img className="dropdownIcon" src={DropdownIcon} />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}
                                            >
                                                {options.map(option => (
                                                    <MenuItem key={option.value} onClick={handleClose}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>
                                    )} */}
                                </div>
                                <div className="lastHeaderRow">
                                    {slug && slug.is_brokerage == false ? (
                                        <div>
                                            <Avatar
                                                style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                                                alt="Remy Sharp"
                                                src={slug && slug.contact_logo ? slug.contact_logo : InsuranceProfile}
                                            />
                                        </div>
                                    ) : null}
                                    <span className="profileText">
                                        {slug && slug.contact_name ? slug.contact_name : ''}
                                        {slug &&
                                        slug.slug === 'opentoclose' &&
                                        slug.brokerage_slug === 'opentoclose' &&
                                        localStorage.getItem('otcbrokerage') === 'otcOnBehalfOf' &&
                                        slug &&
                                        slug.contact_name ? (
                                            <div className="onbehalfof">on behalf of</div>
                                        ) : (
                                            ''
                                        )}
                                        {slug &&
                                        slug.slug === 'opentoclose' &&
                                        slug.brokerage_slug === 'opentoclose' &&
                                        localStorage.getItem('otcbrokerage') === 'otcOnBehalfOf' &&
                                        slug.contact_name
                                            ? localStorage.getItem('customrealtorname')
                                                ? localStorage.getItem('customrealtorname')
                                                : ''
                                            : ''}
                                        <div className="dre-no"> {slug && slug.phone ? slug.phone : ''} </div>
                                        <div className="dre-no"> {slug && slug.dre_no ? slug.dre_no : ''} </div>
                                    </span>

                                    {location.pathname === '/' ||
                                    location.pathname === '/signUp' ||
                                    location.pathname === '/signUp/welcome' ||
                                    location.pathname === '/signUp/HomeOwnerWelcome' ||
                                    location.pathname === '/signUp/preapproval' ||
                                    location.pathname === '/signUp/getPreApproval' ||
                                    location.pathname === '/signUp/haveAPreApproval' ||
                                    location.pathname === '/signUp/preApproval/next' ||
                                    location.pathname === '/servicerequirement' ||
                                    location.pathname === '/laCarte' ||
                                    location.pathname === '/scheduleacall' ||
                                    location.pathname === '/signUp/' ||
                                    location.pathname === '/signUp/next' ||
                                    location.pathname.includes('/setpassword') ||
                                    location.pathname.includes('/resetpassword') ||
                                    location.pathname === '/premovesignup' ||
                                    location.pathname === '/standalonepage' ||
                                    location.pathname === '/standalonepagerecommendations' ||
                                    location.pathname === '/standalonefinal' ||
                                    location.pathname === '/dashboard/my-documents/preview' ||
                                    location.pathname === '/dashboard/savings-flow' ||
                                    location.pathname === '/dashboard/savings-flow/confirmSaving' ||
                                    location.pathname === '/dashboard/savings-flow/summary' ||
                                    location.pathname === '/dashboard/subscription_details/home_utilities' ||
                                    location.pathname === '/dashboard/subscription_details/home_protection' ||
                                    location.pathname === '/login' ? null : (
                                        // <Button
                                        //     className="btnICbtn"
                                        //     height="40px"
                                        //     // width="125px"
                                        //     margin="0px 0px 0px 10px"
                                        //     width="100px"
                                        //     backgroundColor={
                                        //         slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'
                                        //     }
                                        //     // onClick={() => this.tabChangeHandler('addressCard')}
                                        //     onClick={() => logoutClicked()}
                                        // >
                                        //     Logout
                                        // </Button>
                                        <>
                                            {hasToken && (
                                                <div className="logoutButton" onClick={() => logoutClicked()}>
                                                    <Logout className="logoutText" />
                                                    <div className="logoutText">Logout</div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Toolbar>
                    </div>
                </AppBar>

                {location.pathname === '/dashboard' ||
                (location.pathname === '/' && !localStorage.getItem('isHomeOwner')) ||
                location.pathname === '/servicerequirement' ||
                location.pathname === '/laCarte' ||
                location.pathname === '/scheduleacall' ||
                location.pathname === '/signUp' ||
                location.pathname === '/signUp/welcome' ||
                location.pathname === '/signUp/HomeOwnerWelcome' ||
                location.pathname === '/signUp/preapproval' ||
                location.pathname === '/signUp/getPreApproval' ||
                location.pathname === '/signUp/haveAPreApproval' ||
                location.pathname === '/signUp/preApproval/next' ||
                location.pathname === '/signUp/' ||
                location.pathname === '/signUp/next' ||
                location.pathname.includes('/setpassword') ||
                location.pathname.includes('/resetpassword') ||
                location.pathname === '/premovesignup' ||
                location.pathname === '/standalonepage' ||
                location.pathname === '/standalonepagerecommendations' ||
                location.pathname === '/standalonefinal' ||
                location.pathname === '/standalonefinal' ||
                location.pathname.includes('/dashboard/utilities/utilitiesSavings') ||
                location.pathname.includes('/dashboard/utilities/utilitiesConfirmation') ||
                location.pathname === '/dashboard/nextmove' ||
                location.pathname === '/dashboard/nextMoveFinal' ||
                location.pathname === '/dashboard/savings-flow' ||
                location.pathname === '/dashboard/savings-flow/awesomeSaving' ||
                location.pathname === '/dashboard/savings-flow/confirmSaving' ||
                location.pathname === '/dashboard/savings-flow/summary' ||
                location.pathname === '/dashboard/subscription_details/home_utilities' ||
                location.pathname === '/dashboard/subscription_details/home_protection' ||
                location.pathname === '/login' ? null : (
                    <AppBar position="fixed" style={{ marginTop: '61px' }} className="secondBar">
                        <div className="links">
                            <div className={`${classes.tabRoot} Dashboard`} style={{ marginTop: '20px' }}>
                                <div>
                                    <Tabs
                                        value={value >= 0 && value <= headerOption?.length ? value : false}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }}
                                        className="headerTab"
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: {
                                                background: slug && slug.primary_color ? slug.primary_color : '#273E59',
                                            },
                                        }}
                                        // scrollButtons="auto"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        // aria-label="simple tabs example"
                                        aria-label="icon label tabs example"
                                        // className={classes.customTabRoot}
                                    >
                                        {headerOption && headerOption.length > 0
                                            ? headerOption.map((item: any, i: number) => {
                                                  let icon = getIcon(isDouglasElliman, item);

                                                  return (
                                                      <Tab
                                                          key={i}
                                                          onClick={() => handleAnalytics1(item)}
                                                          component={Link}
                                                          to={{
                                                              pathname: `/dashboard/${item.route}`,
                                                              state:
                                                                  props.commonState &&
                                                                  props.commonState.header &&
                                                                  props.commonState.header.length > 0
                                                                      ? props.commonState.header
                                                                      : '',
                                                          }}
                                                          label={item.name}
                                                          icon={
                                                              //   <img src={icon} className="tabIcons" />
                                                              icon
                                                          }
                                                          {...a11yProps(item.id)}
                                                      />
                                                  );
                                              })
                                            : num === 8
                                            ? SkeletonArray.map(index => {
                                                  return (
                                                      <Tab
                                                          key={index}
                                                          label={<Skeleton height={10} width={80} />}
                                                          icon={<Skeleton height={30} width={40} />}
                                                      />
                                                  );
                                              })
                                            : num === 5
                                            ? SkeletonArray.map(index => {
                                                  if (index <= 6) {
                                                      return (
                                                          <Tab
                                                              key={index}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })
                                            : num === 4
                                            ? SkeletonArray.map(index => {
                                                  if (index <= 5) {
                                                      return (
                                                          <Tab
                                                              key={index}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })
                                            : SkeletonArray.map(index => {
                                                  if (index <= 8) {
                                                      return (
                                                          <Tab
                                                              key={index}
                                                              label={<Skeleton height={10} width={80} />}
                                                              icon={<Skeleton height={30} width={40} />}
                                                          />
                                                      );
                                                  }
                                              })}

                                        {headerOption &&
                                            headerOption.length > 0 &&
                                            moreOption &&
                                            moreOption.length > 0 && (
                                                <Tab
                                                    label={
                                                        open1 ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={handleAnalytics}
                                                            >
                                                                More
                                                                <ExpandLessIcon
                                                                    style={{
                                                                        fill: '#6b6c6f',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={handleAnalytics}
                                                            >
                                                                More
                                                                <ExpandMoreIcon
                                                                    height="5px"
                                                                    width="8px"
                                                                    style={{
                                                                        fill: '#6b6c6f',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    icon={
                                                        isDouglasElliman ? (
                                                            <MoreIconD className={`moreIcon ${open1 || value > num && 'activeIcon'}`} />
                                                        ) : (
                                                            <MoreIcon className={`moreIcon ${open1 || value > num && 'activeIcon'}`} />
                                                        )
                                                    }
                                                    {...a11yProps(num)}
                                                    // onMouseOver={num >= 7 ? handleClickMore : () => {}}
                                                    // onClick={num < 7 ? handleClickMore : () => {}}
                                                    onClick={handleClickMore}
                                                />
                                            )}
                                    </Tabs>
                                    <Popover
                                        open={open1}
                                        anchorEl={anchorEl1}
                                        onClick={handleCloseMore}
                                        className="headerPopover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        {moreOption.map((item: any, i: number) => {
                                            let icon = getIcon(isDouglasElliman, item);
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={() => handleMenuItemClick(item)}
                                                    // onClick={() => handleMenuItemClick(item.route)}
                                                    className="HeaderDropdownValues"
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `/dashboard/${item.route}`,
                                                            state:
                                                                props.commonState &&
                                                                props.commonState.header &&
                                                                props.commonState.header.length > 0
                                                                    ? props.commonState.header
                                                                    : '',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '30%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {/* <img className="tabIcons" src={icon} /> */}
                                                            {icon}
                                                        </div>
                                                        <MenuItem>{item.name}</MenuItem>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        {/* <Footer /> */}
                    </AppBar>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, null)(Navbar);
