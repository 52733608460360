import React, { Component } from 'react';
import './finalPageNextMove.scss';

import Button from '../../components/atoms/Button';
import {CustomCheckmark} from '../../components/atoms/Checkmark';

type initialProps = {
    history?: any;
};
type initialState = {
    slug: any;
};

export class finalPageNextMove extends Component<initialProps, initialState> {
    state: initialState = {
        slug: null,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        return (
            <div className="nextMoveFinalPage">
                <div className="nextMoveWhiteBackground">
                    <div className="nextMoveInnerContentFinal">
                        <div className="nextPageFinalCheckMark">
                            <CustomCheckmark size='xxLarge' type='success' />
                        </div>
                        <div className="nextPageFinalHead">Great, your agent has been notified</div>
                        <div className="nextPageFinalSubHead">
                            Your agent will be in contact to support your next move
                        </div>
                        <div className="nextPageFinalButton">
                            <Button
                                // color={'#fff'}
                                // backgroundColor={
                                //     this.state.slug && this.state.slug.primary_color
                                //         ? this.state.slug.primary_color
                                //         : '#273E59'
                                // }
                                onClick={() => {
                                    this.props.history.push('/dashboardhomeowner');
                                }}
                            >
                                Back To Dashboard
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default finalPageNextMove;
