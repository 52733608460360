import React from 'react';
import './ProgressBar.scss';
// interface SideNavProps {
//     width?: string;
//     height?: string;
//     borderRadius?: string;
//     border?: any;
//     navMargin?: string;
//     navPadding?: string;
//     onClick?: any;
//     className?: string;
//     imgWidth?: string;
//     imgHeight?: string;
//     serviceImage?: any;
//     serviceName?: any;
//     boxShadow?: any;
//     imgClassname?: string;
// }

interface progressbarProps {
    // width?: string;
    // height?: string;
    // borderRadius?: string;
    // border?: any;
    // cardMargin?: string;
    // cardPadding?: string;
    // onClick?: any;
    // className?: string;
    // imgWidth?: string;
    // imgHeight?: string;
    // cardImage?: any;
    // cardPrice?: string;
    // cardContent?: string;

    // boxShadow?: any;
    // imgClassname?: string;
    // cardPriceFirst?: string;
    widthRight?: string;
    widthLeft?: string;
    loanAmt: number;
    equityAmt: number;
}

const formatCurrency = ({ locale = "en-US", value = 0 }) => new Intl.NumberFormat(locale).format(Math.round(value));

const ProgressBar = (props: progressbarProps) => {
    return (
        <section className="main-progressBar">
            <div style={{ flexBasis: props.widthRight, width: props.widthRight }} className="progressBarRight">
                <div className="progressBar-content">
                    <span className="text">Pending Loan Amount</span>
                    <div>
                        <div style={{ position: 'absolute', fontWeight: 900, color: '#000', display: 'inline-block' }}>
                            $
                        </div>
                        <span className="text-no"> {formatCurrency({ value: props.loanAmt })}</span>
                    </div>
                </div>
                <div className="progressBarRightColor"></div>
            </div>
            <div style={{ flexBasis: props.widthLeft, width: props.widthLeft }} className="progressBarLeft">
                <div className="progressBar-content">
                    <span className="text">Equity Balance</span>
                    <div>
                        <div
                            style={{ color: '#00d56d', position: 'absolute', fontWeight: 900, display: 'inline-block' }}
                        >
                            $
                        </div>
                        <span style={{ color: '#00d56d' }} className="text-no">
                            {' '}
                            {formatCurrency({ value: props.equityAmt })}
                        </span>
                    </div>
                </div>
                <div className="progressBarLeftColor"></div>
            </div>
        </section>
    );
};

export default ProgressBar;
