import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LocalStorageService from '../../utils/localStorage';
import { DefaultToast as defaultToast, ToastConfig } from '../../utils/toast';
import 'react-notifications-component/dist/theme.css';
import CommonService from '../../utils/common-functions';
import * as CommonAction from '../../store/common/actions';
import * as HomeValuationActions from '../../store/homeValuation/actions';
import HomeValModal from '../../components/atoms/HomeValuation/Model/Main';
import DataModel from '../../components/atoms/HomeValuation/Model/DataModel';
import HomeEquityMain from '../../components/atoms/HomeValuation/HomeEquity/Main';
import AddLoanDetails from '../../components/homeOwnerDashboardComponents/AddLoanDetails';
import './homeValution.scss';
import '../../components/dashboardComponents/dashboardCss/moveFrommoveToCard2.scss';
import Zillow from '../../../src/Assets/images/HomeValuation/zillow.png';
import Tag from '../../../src/Assets/images/HomeValuation/tag.png';

import Recomended from '../../../src/Assets/images/HomeValuation/recoomentedImage.png';
import HomeCard from '../../components/atoms/HomeValuation/HomeCard';
import HomeBanner from '../../components/atoms/HomeValuation/HomeBanner';
import HomeProjectCard from '../../components/HomeValuation/HomeProjectCard';
import HomeProjectBanner from '../../components/HomeValuation/HomeProjectBanner';
import SavingCalculator from '../../components/atoms/HomeValuation/SavingCalculator/SavingCalculatorData';
import SideNav from '../../components/atoms/HomeValuation/SideNav';
import ProgressBar from '../../components/atoms/HomeValuation/ProgressBar';
import MikeHomeBanner from '../../components/atoms/HomeValuation/MikeHomeBanner';
import EstimatedHomeValue from '../../components/atoms/HomeValuation/EstimatedHomeValue';
import acornIcon from '../../Assets/images/HomeValuation/acornIcon.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import eastedLogo from '../../Assets/images/HomeValuation/eastedLogo.png';

import BasicTable from '../../components/HomeValuation/BasicTable';
import RemaingMortageBalance from '../../components/atoms/HomeValuation/RemaingMortageBalance';
import MikeMortageBalance from '../../components/atoms/HomeValuation/MikeMortageBalance';
import BetterExperience from '../../components/atoms/HomeValuation/BetterExperience/BetterExperience';
import Menu from '../../components/atoms/HomeValuation/HorizontalScrollMenu/Menu';
import EquityBanner from '../../components/atoms/HomeValuation/EquityBanner';
import AffordablityCalculator from '../DashboardHomeOwner/AffordablityCalculator/Main';
import AffordabilityChart from '../../components/atoms/HomeValuation/AffordabilityCalcuatorChart/AffordabilityChart';
import AffordablityCard from '../../components/atoms/HomeValuation/AffordabilityCalcuatorChart/AffordabilityCard';
import AffordabilityCardMobile from '../../components/atoms/HomeValuation/AffordabilityCalcuatorChart/AffordabilityCardMobile';
import AffordablityCardData from './AffordabalityCardData';
import ApiService from '../api-service';
import Disclamer from '../../components/atoms/HomeValuation/Disclamer/Disclamer';
import AnalyticsService from '../analytics-service';
import ContactDataModal from '../../components/atoms/HomeValuation/Model/ContactDataModal';
import BlogCardSlider from './BlogCardSlider';
import _ from 'lodash'

interface initialProps {
    history?: any;
    homeValuationActions?: any;
    commonAction: any;
    homeValuationState?: any;
    commonState: any;
    property_valuations?: any[];
    homeDetails?: any;
    isFetchingHomeValuation?: boolean;
    hash_code: string;
    moveData?: any[];
    token?: string;
}

type initialState = {
    affordcalculator: number;
    street: string;
    property_valuations?: any[];
    homeDetails?: any;
    isFetchingHomeValuation?: boolean;
    moveId?: string;
    hash_code: string;
    moveData?: any[];
    token: string;
    loanDetails?: any;
    loanAPIError?: boolean;
    isFetchingLoanData?: boolean;
    isPostingSavingCalcData: boolean;
    showMortageModal: boolean;
    savingCalcData: any | null;
    brokerage_name?: string;
    brokerage_id?: any;
    agent_name?: string;
    agent_id?: any;
    modalType?: string;
    userDetails?: any;
    isLoadingPropertyValuation?: boolean;
    isLoadingAverageValuation?: boolean;
    propertyValuation?: any;
    propertyAverageValuation?: any;

    blogs: any;
    isFetchingBlogs: boolean;
    relatorData?: any;
    isFetchingRelator: boolean;
    serviceAgentData?: any;
    isFetchingServiceAgent: boolean;
    deals?: any;
    isFetchingDeals?: boolean;
    serviceProviders?: any;
    mortgageData?: any;
    affordabilyDataFromAPI?: any;
    Rent: number;
    isOpen: boolean;
    finacingOptionsUrl: string;
    brokerage_key?: string;
    move_key?: string;
    prefferedMortgage?: any;
    isCalculatingAffordability: boolean;
    isAgentSubmitContactSuccess: boolean;
    isRealtorSubmitContactSuccess: boolean;
};
class HomeValuation extends React.Component<initialProps, initialState> {
    state: initialState = {
        affordcalculator: 0,
        street: '',
        property_valuations: [],
        homeDetails: {},
        isLoadingPropertyValuation: false,
        isLoadingAverageValuation: false,
        isFetchingHomeValuation: true,
        isPostingSavingCalcData: false,
        hash_code: '',
        moveData: [],
        token: '',
        showMortageModal: false,
        savingCalcData: null,
        blogs: null,
        isFetchingBlogs: false,
        isFetchingRelator: false,
        isFetchingServiceAgent: true,
        isOpen: true,
        Rent: 0,
        finacingOptionsUrl: '#',
        prefferedMortgage: {
            name: '',
            id: null,
            email: '',
            phone: '',
            address: '',
        },
        isCalculatingAffordability: false,
        isAgentSubmitContactSuccess: false,
        isRealtorSubmitContactSuccess: false,
    };

    setAffordcalculator = (value: number) => this.setState({ affordcalculator: value });
    setExperince = (value: boolean) => this.setState({ isOpen: value });

    fetchLoanDetails = async (moveData: any) => {
        if (moveData?.hash_code) {
            this.setState({ isFetchingLoanData: true });
            ApiService.getLoanDetails(moveData)
                .then(data => {
                    this.setState({ loanDetails: data, isFetchingLoanData: false });
                    // if (data.message) this.showToast('Warning!', "warning", data.message);
                })
                .catch(err => this.setState({ loanAPIError: err, isFetchingLoanData: false }));
        }
    };

    fetchContactCardData = () => {
        ApiService.getServiceAgents()
            .then(serviceAgentData => {
                this.props.commonAction.getServiceAgentInfo(serviceAgentData)
                this.setState({ isFetchingServiceAgent: false })
            })
            .catch(e => {
                console.debug(e);
                this.setState({ isFetchingServiceAgent: false });
            });

        ApiService.getServiceProviders()
            .then(serviceProviders => {
                this.props.commonAction.getServiceProviderInfo(serviceProviders)
                this.setState({ isFetchingServiceAgent: false })
            })
            .catch(e => {
                this.setState({ isFetchingServiceAgent: false });
                this.showToast(
                    'Error',
                    'danger',
                    'Oops! Something went while fetching service providers. Error: ' + ' ' + e.message || '',
                );
            });
    };

    async componentDidMount() {
        const moveId = parseInt(LocalStorageService.getItem('moveId'), 10);
        this.props.homeValuationActions.homeValuationCardDetails();

        this.setState({
            isFetchingBlogs: true,
            isFetchingDeals: true,
            isLoadingPropertyValuation: true,
            isLoadingAverageValuation: true,
        });

        if (!this.props.commonState.serviceAgentInfo && !this.props.commonState.serviceProviderInfo) {
            this.fetchContactCardData()
        } else {
            this.setState({ isFetchingServiceAgent: false })
        }
        
        if (this.props.commonState.serviceAgentInfo) this.setState({ serviceAgentData: this.props.commonState.serviceAgentInfo})
        if (this.props.commonState.serviceProviderInfo) this.setState({ serviceProviders: this.props.commonState.serviceProviderInfo})

        Promise.all([
            ApiService.getBlogs(),
            ApiService.getMoveData(moveId),
            ApiService.getDeals(),
            ApiService.getMortageData(),
        ])
            .then(([blogs, moveData, deals, mortgageData]) => {
                // if (mortgageData.message) this.showToast('Caught an error!', "warning", mortgageData.message);
                this.setState({
                    blogs,
                    deals,
                    mortgageData,
                    hash_code: moveData?.hash_code || '',
                    isFetchingBlogs: false,
                    isFetchingDeals: false,
                });
                this.fetchLoanDetails(moveData);
            })
            .catch(() =>
                this.setState({ isFetchingBlogs: false, isFetchingDeals: false }),
            );

            AnalyticsService.pageViewed({ page: 'Home Valuation Page', category_name: 'Home Valuation Page Viewed' });
    }

    async componentDidUpdate(prevProps: any) {

        if(!_.isEqual(prevProps.commonState.serviceAgentInfo, this.props.commonState.serviceAgentInfo)){
            this.setState({ serviceAgentData: this.props.commonState.serviceAgentInfo})
        }

        if(!_.isEqual(prevProps.commonState.serviceProviderInfo, this.props.commonState.serviceProviderInfo)){
            this.setState({ serviceProviders: this.props.commonState.serviceProviderInfo})
        }

        const moveData = this.props?.commonState?.move[0];
        if (prevProps.commonState !== this.props.commonState) {
            this.fetchLoanDetails(moveData);
        }
        const propertyId = this.props?.homeValuationState?.homeValuationCardDetails?.id || null;
        if (
            propertyId &&
            prevProps?.homeValuationState?.homeValuationCardDetails?.id !== propertyId &&
            !isNaN(propertyId)
        ) {
            this.setState({ isLoadingAverageValuation: true, isLoadingPropertyValuation: true });
                ApiService.getPropertyValuationFromTP(propertyId)
                .then(propertyValuation => {
                    this.setState({ propertyValuation, isLoadingPropertyValuation: false });
                    ApiService.getPropertyAverageValuation(propertyId)
                        .then(propertyAverageValuation =>
                            this.setState({ propertyAverageValuation, isLoadingAverageValuation: false }),
                        )
                        .catch(err =>{
                            this.setState({ isLoadingAverageValuation: false });
                            this.showToast(
                                'Error!',
                                'danger',
                                'Something went wrong while fetching average valuation. Error: ' + ' ' + err?.message ||
                                    '',
                            )}
                        );
                })
                .catch(err => {
                    this.setState({ isLoadingPropertyValuation: false });
                    defaultToast({ title: 'Error!', type: 'danger', message: err?.message })
                });
        }
    }

    public static async getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        // const token = localStorage.getItem('token') || '';
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0]
        ) {
            let moveData = nextProps.commonState.move[0];
            // this.fetchLoanDetails(moveData);

            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address
            ) {
                currentState.street = `${moveData.source_full_address}`;
                currentState.property_valuations = nextProps.property_valuations;
                currentState.homeDetails = nextProps.homeDetails;
                currentState.isFetchingHomeValuation = nextProps.isFetchingHomeValuation;
                currentState.hash_code = moveData.hash_code;
            }
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
                
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;

            const brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;

            const move_key = nextProps.commonState.move[0] && nextProps.commonState.move[0].move_key;
            const finacingOptionsUrl = `https://www.acornfinance.com/apply/?d=MOV-BANNER-HOMEVAL&loanAmount=10000&utm_medium=MOV-${brokerage_key}&utm_content=MOV-${move_key}#/financing`;
            currentState.finacingOptionsUrl = finacingOptionsUrl;
            currentState.move_key = move_key;
            currentState.brokerage_key = brokerage_key;
            currentState.userDetails =
                (nextProps.commonState.move?.length && nextProps.commonState?.move[0]?.user_ref) || null;
        }
       
        return currentState;
    }

    getPercentage = (loan: number = 1, equity: number = 1) => {
        const total = loan + equity;
        return {
            loanPercentage: Math.round((loan * 100) / total),
            equityPercentage: Math.round((equity * 100) / total),
        };
    };

    onMortageSubmit = async (data: any) => {
        const { mortgageData } = this.state;
        if (mortgageData?.results && mortgageData?.results?.length && mortgageData?.results[0].id) {
            await ApiService.putLoanOrMortageDetails(
                { ...data, id: mortgageData?.results[0].id },
                mortgageData?.results[0].id,
            );
        } else {
            await ApiService.postLoanOrMortageDetails(data);
        }
        // this.props.homeValuationActions.mortageDetails(data);
        this.toggleMortageModal();
        const moveData = this.props?.commonState?.move?.length && this.props?.commonState?.move[0];
        this.fetchLoanDetails(moveData);
    };

    toggleMortageModal = (modalType = 'mortage') => {
        this.setState({ showMortageModal: !this.state.showMortageModal, modalType });
    };

    handleCloseModal = () => {
        this.setState({isAgentSubmitContactSuccess: false, isRealtorSubmitContactSuccess: false});
        this.toggleMortageModal();
    }
    setRent = (data: any) => this.setState({ Rent: data });

    stopCalculating = () => this.setState({ isCalculatingAffordability: false });

    postSavingsCalcData = (data: any) => {
        if (data) {
            this.setState({ isPostingSavingCalcData: true });
            ApiService.postSavingsCalcData(data)
                .then(savingCalcData => {
                    if (savingCalcData?.message)
                        defaultToast({ title: 'Error!', type: 'danger', message: savingCalcData?.message || '' });
                    this.setState({ savingCalcData, isPostingSavingCalcData: false });
                })
                .catch(() => {
                    this.setState({ isPostingSavingCalcData: false });
                    defaultToast({
                        title: 'Error!',
                        type: 'danger',
                        message: 'Something went wrong while calculation. Please try again later.',
                    });
                });

                AnalyticsService.formCompleted({
                    page: 'Home Valuation Page',
                    category_name: 'Savings Calculator Form Submitted',
                    form_name: 'Savings Calculator',
                    form_pre_filled: true,
                    button_copy: 'Calculate'
                });
        }
    };

    onRelatorContact = () => {
        this.toggleMortageModal('realtorContact');

        AnalyticsService.buttonClicked({
            page: 'Home Valuation Page',
            category_name: 'Contact Me Button Clicked',
            button_copy: 'Contact Me',
            card: true,
            card_title: 'Have any questions about the value of your home?',
            card_type: 'Contact Card'
        });
    };

    onRealtorContactSubmit = async (formData: any) => {
        const { hash_code } = this.state;
        if (hash_code) {
            this.setState({ isFetchingRelator: true });
            const data = {
                service_name: 'Mortgage',
                hash_code: hash_code,
                ...formData,
            };
            ApiService.postRealtorData(data)
                .then(relatorData => {
                    this.setState({ isFetchingRelator: false, relatorData, isRealtorSubmitContactSuccess: true });
                    this.showToast('Success!', 'success', 'Created Realtor contact request.');
                })
                .catch(() => {
                    this.setState({ isFetchingRelator: false, isRealtorSubmitContactSuccess: false });
                    this.showToast('Error!', 'danger', 'Something went wrong while creating request. Please try later');
                });
        }

        AnalyticsService.formCompleted({
            page: 'Home Valuation Page',
            category_name: 'Contact Request Submitted',
            form_name: 'Contact Request',
            form_pre_filled: true,
            button_copy: 'Submit'
        });
    };

    onAgentContact = () => { 
        this.toggleMortageModal('agentContact');

        AnalyticsService.buttonClicked({
            page: 'Home Valuation Page',
            category_name: 'Mortgage Card Contact Me Button Clicked',
            button_copy: 'Contact Me',
            card: true,
            card_title: 'Mortgage Contact',
            card_type: 'Mortgage Contact Card'
        });
    };

    handleButtonClicked = () => {
        const serviceAgentData = (this.state.serviceAgentData?.length && this.state.serviceAgentData[0]) || null;
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data[0]) || null;

        if (serviceAgentData) {
            if (serviceAgentData.email || serviceAgentData?.service_provider.email) {
                this.onAgentContact();
            } else {
                if (serviceAgentData.website || serviceAgentData?.service_provider.website) {
                    const website = serviceAgentData.website || serviceAgentData?.service_provider.website || '';
                    window.open(website, '_blank');
                }
            }
        } else {
            if (serviceProviders) {
                if (serviceProviders.email) {
                    this.onAgentContact();
                } else {
                    if (serviceProviders.website) {
                        window.open(serviceProviders.website, '_blank');
                    }
                }
            }
        }
    };

    onAgentContactSubmit = async (formData: any) => {
        const createReqBody = {
            contact_from: 'HOMEOWNER Dashboard',
            ...formData,
        };
        const data = this.state.serviceAgentData;
        const serviceProviderData = this.state.serviceProviders?.data;

        let id = null;
        let isServiceAgent = false;

        if (data?.length && data[0]?.id) {
            isServiceAgent = true;
            id = data[0]?.id;
        } else {
            isServiceAgent = false;
            if (serviceProviderData) {
                createReqBody.service_provider = this.state.prefferedMortgage.id;
            }
        }

        ApiService.postCreateContactRequest(createReqBody, id, isServiceAgent)
            .then(() => {
                this.showToast('Success!', 'success', 'Created Agent contact request.');
                this.setState({
                    isAgentSubmitContactSuccess: true,
                });
            })
            .catch(() => {
                this.showToast('Error!', 'danger', 'Something went wrong while creating request. Please try later');
                this.setState({
                    isAgentSubmitContactSuccess: false,
                });
            });

        AnalyticsService.formCompleted({
            page: 'Home Valuation Page',
            category_name: 'Contact Request Submitted',
            form_name: `Inquire for ${this.state.prefferedMortgage?.name || 'Mortgage'}`,
            form_pre_filled: true,
            button_copy: 'Submit'
        });
    };

    onPostAffordablityDataPost = (data: any) => {
        this.setState({ isCalculatingAffordability: true });

        ApiService.postAffordabilityData(data)
            ?.then(affordabilyDataFromAPI =>
                this.setState({ affordabilyDataFromAPI, isCalculatingAffordability: false }),
            )
            .catch(() => {
                this.setState({ isCalculatingAffordability: false });
            });
    };

    navigateToRoute = (path: string) => this.props.history.push(path);

    showToast = (title: ToastConfig['title'], type: ToastConfig['type'], message: ToastConfig['message']) =>
        defaultToast({ title, type, message });

    onPrefferedMortgage = (mortgage: any) => {
        this.setState({
            prefferedMortgage: {
                name: mortgage.name,
                id: mortgage.id,
                email: mortgage.email,
                phone: mortgage.phone,
                address: mortgage.address,
            },
        });
    };

    handleLoanDetailsClick = (buttonCopy: string) => {
        this.toggleMortageModal();

        AnalyticsService.buttonClicked({
            page: 'Home Valuation Page',
            category_name: `${buttonCopy} Button Clicked`,
            button_copy: buttonCopy,
        });
    }

    render() {
        const { affordcalculator, loanDetails, isFetchingLoanData } = this.state;
        const { homeValuationState } = this.props;
        const loanAmt = loanDetails?.remaining_equity_balance?.remaining_balance;
        const remainingEquity = loanDetails?.remaining_equity_balance?.equity_balance;
        const { loanPercentage, equityPercentage } = this.getPercentage(loanAmt, remainingEquity);
        const serviceAgentData = (this.state.serviceAgentData?.length && this.state.serviceAgentData[0]) || null;
        const serviceProviders =
            (this.state.serviceProviders?.data?.length && this.state.serviceProviders?.data[0]) || null;
        const analyticsData = { page: "Home Valuation Page" };
        const priceRange =
        (this.state.propertyValuation?.low_value &&
            this.state.propertyValuation?.high_value &&
            `${CommonService.formatCurrency({
                value: this.state.propertyValuation?.low_value,
            })} to ${CommonService.formatCurrency({ value: this.state.propertyValuation?.high_value })}`) ||
        false;

        const estatedPriceRange =
            (this.state.propertyAverageValuation?.property_valuations && this.state.propertyAverageValuation?.property_valuations[1]?.low_value &&
                this.state.propertyAverageValuation?.property_valuations[1]?.high_value &&
                `${CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.low_value,
                })} to ${CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.high_value,
                })}`) ||
            false;
        const estatedcardPriceFirst =
            (this.state.propertyAverageValuation?.property_valuations && this.state.propertyAverageValuation?.property_valuations[1]?.current_value &&
                CommonService.formatCurrency({
                    value: this.state.propertyAverageValuation?.property_valuations[1]?.current_value,
                })) ||
            false;

        const dealsObj = this.state.deals;
        let deals = dealsObj?.results?.length > 0 && dealsObj?.results[0];

        let realtorData = LocalStorageService.getItem('slug');
        realtorData = (realtorData && JSON.parse(realtorData)) || null;

        return (
            <>
                <div className="d-block d-sm-none ">
                    <Menu />
                </div>
                <div className="d-block d-sm-none info-toast-message">
                    <BetterExperience open={this.state.isOpen} setExperince={this.setExperince} />
                </div>
                <div className="container-fluid main">
                    <div className="row ">
                        <div className="col col-md-2 col-lg-2 col-xl-3  leftNavPanelContainer">
                            <div style={{ marginTop: '30px' }} className="d-sm-none d-md-none d-lg-block">
                                <span>
                                    <span
                                        onClick={() => this.navigateToRoute('/dashboardhomeowner')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ArrowBackIcon />
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            paddingLeft: '10px',
                                            fontSize: '24px',
                                        }}
                                    >
                                        My Home Valuation
                                    </span>
                                </span>
                            </div>
                            <div className="navigatorHomeValuation">
                                <span
                                    style={{
                                        color: 'var(--bs-link-color)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => this.navigateToRoute('/dashboardhomeowner')}
                                >
                                    Dashboard
                                </span>{' '}
                                {'>'} Home valuation
                            </div>

                            <div
                                className="d-sm-none d-md-none d-lg-block homeValueationSidePanel"
                                // style={{
                                //     paddingTop: '50px',
                                //     position: 'fixed',
                                //     top: '246px',
                                // }}
                            >
                                <SideNav />
                            </div>
                        </div>

                        <div className="col col-md-12 col-lg-12 col-xl-9 col-sm-12 rightContentContainer">
                            <section
                                style={{ marginTop: !this.state.isOpen ? '100px' : '0px' }}
                                id="home-value-estimation"
                            >
                                <div className="estimatedBlock">
                                    <div className="estimatedBlockContainer">
                                        <EstimatedHomeValue
                                            isFetchingHomeValuation={
                                                homeValuationState && homeValuationState?.isFetchingHomeValuation
                                            }
                                            homeValuationCardDetails={
                                                homeValuationState?.homeValuationCardDetails || {}
                                            }
                                            property_valuations={
                                                homeValuationState?.homeValuationCardDetails?.property_valuations || []
                                            }
                                            averageCalulations={this.state?.propertyAverageValuation}
                                            isFetchingAverage={this.state?.isLoadingAverageValuation}
                                            street={this.state.street}
                                            loanDetails={loanDetails}
                                            handleLoanDetailsClick={this.handleLoanDetailsClick}
                                            analyticsData={analyticsData}
                                        />
                                    </div>
                                    <div className="estimatedBlockContainer2">
                                        <HomeCard
                                            border="0.5px solid #E0E0E0"
                                            height="110px"
                                            width="100%"
                                            cardPrice={priceRange}
                                            cardContent="Estimation from"
                                            cardImage={Zillow}
                                            isLoading={this.state?.isLoadingPropertyValuation || this.state?.isLoadingAverageValuation}
                                            cardPriceFirst={
                                                (this.state.propertyValuation?.current_value &&
                                                    CommonService.formatCurrency({
                                                        value: this.state.propertyValuation?.current_value,
                                                    })) ||
                                                false
                                            }
                                            imgWidth="100px"
                                            marginTop="8px"
                                        />
                                        <br></br>
                                        <HomeCard
                                            border="0.5px solid #E0E0E0"
                                            height="110px"
                                            width="100%"
                                            cardPriceFirst={estatedcardPriceFirst}
                                            cardPrice={estatedPriceRange}
                                            cardContent="Estimation from"
                                            isLoading={ this.state?.isLoadingPropertyValuation || this.state?.isLoadingAverageValuation}
                                            cardImage={eastedLogo}
                                            imgWidth="120px"
                                            imgHeight="30px"
                                            marginTop="14px"
                                        />
                                    </div>
                                </div>
                            </section>
                            <div
                                className="d-none d-sm-block fix-mobile"
                                //  style={{
                                //      width: '100%',
                                //  }}
                            >
                                <HomeBanner
                                    Top="-10px"
                                    bannerTitle="Have any questions about the value of your home?"
                                    bannerContent="Reach out to me today and I’d be happy to discuss your home valuation and future purchasing power."
                                    buttonPresent={true}
                                    onButtonClick={this.onRelatorContact}
                                    borderRadius="40px"
                                    analyticsData={analyticsData}
                                />
                            </div>

                            <div
                                // id="#home-value-estimation"
                                className="progressBarBlock"
                            >
                                {!isFetchingLoanData && loanDetails && !loanDetails?.message ? (
                                    <ProgressBar
                                        widthRight={`${loanPercentage || 0}%`}
                                        widthLeft={`${equityPercentage || 0}%`}
                                        loanAmt={loanAmt || 0}
                                        equityAmt={remainingEquity || 0}
                                    />
                                ) : (loanDetails?.message || !loanDetails) && !loanAmt && !isFetchingLoanData ? (
                                    // <AddLoanDetails handleClickAdd={this.toggleMortageModal} />
                                    ''
                                ) : (
                                    <Skeleton width={250} height={40} />
                                )}
                            </div>
                            <section id="mortgage-balance">
                                <div>
                                    <HomeValModal
                                        isShowModal={this.state.showMortageModal}
                                        onCloseModal={this.handleCloseModal}
                                        showClose={this.state.showMortageModal}
                                        close={this.handleCloseModal}
                                        cross={true}
                                        type={this.state?.modalType}
                                        prefferedMortgage={this.state.prefferedMortgage}
                                    >
                                        {this.state?.modalType === 'agentContact' ? (
                                            <ContactDataModal
                                                onSubmit={this.onAgentContactSubmit}
                                                toggleModal={this.handleCloseModal}
                                                details={serviceAgentData}
                                                userDetails={this.state?.userDetails}
                                                isLoading={isFetchingLoanData}
                                                move_key={this.state?.hash_code || this.state?.move_key || ''}
                                                type={'agentContact'}
                                                onPrefferedMortgage={this.onPrefferedMortgage}
                                                prefferedMortgage={this.state.prefferedMortgage}
                                                providersData={this.state.serviceProviders?.data}
                                                isSubmitSuccess={this.state.isAgentSubmitContactSuccess}
                                                analyticsData={analyticsData}
                                            />
                                        ) : this.state?.modalType === 'realtorContact' ? (
                                            <ContactDataModal
                                                onSubmit={this.onRealtorContactSubmit}
                                                toggleModal={this.handleCloseModal}
                                                details={realtorData}
                                                userDetails={this.state?.userDetails}
                                                isLoading={isFetchingLoanData}
                                                move_key={this.state?.hash_code || this.state?.move_key || ''}
                                                type={'realtorContact'}
                                                isSubmitSuccess={this.state.isRealtorSubmitContactSuccess}
                                                analyticsData={analyticsData}
                                            />
                                        ) : (
                                            <DataModel
                                                onMortageSubmit={this.onMortageSubmit}
                                                toggleMortageModal={this.toggleMortageModal}
                                                loanDetails={loanDetails}
                                                isFetchingLoanData={isFetchingLoanData}
                                                analyticsData={analyticsData}
                                                currentAvgValuation={
                                                    this.state?.propertyAverageValuation?.average_value
                                                }
                                                property_valuations={
                                                    homeValuationState?.homeValuationCardDetails?.property_valuations ||
                                                    []
                                                }
                                                loanData={
                                                    homeValuationState?.homeValuationCardDetails?.buyer_seller_info
                                                        ?.deeds?.length &&
                                                    homeValuationState?.homeValuationCardDetails?.buyer_seller_info
                                                        ?.deeds[0]
                                                }
                                                showPurchasePriceField={this.state?.propertyAverageValuation?.average_value ? false : (!loanAmt && !remainingEquity) || ((Object.keys(homeValuationState?.homeValuationCardDetails).length === 0 || !homeValuationState?.homeValuationCardDetails?.address || !this.state?.propertyAverageValuation) && loanDetails?.purchase_price > 0) }
                                            />
                                        )}
                                    </HomeValModal>
                                    <h3 className="homeEquityTitle">Mortgage Balance</h3>

                                    <div style={{ scrollMarginTop: '120px' }} className="mortgage-balance">
                                       
                                            <div className={`mortgage-balance-container ${ (loanDetails?.message || !loanDetails) && !loanAmt && !isFetchingLoanData && 'emptyMortgageCover' }`}>
                                            {!isFetchingLoanData && loanDetails && !loanDetails?.message ? (
                                                <RemaingMortageBalance
                                                    loanDetails={loanDetails}
                                                    isFetchingLoanData={isFetchingLoanData}
                                                    remainingBalance={loanAmt || 0}
                                                    handleLoanDetailsClick={this.handleLoanDetailsClick}
                                                    analyticsData={analyticsData}
                                                />
                                                ) : (loanDetails?.message || !loanDetails) &&
                                                  !loanAmt &&
                                                  !isFetchingLoanData ? (
                                                    <AddLoanDetails 
                                                    handleClickAdd={() => this.handleLoanDetailsClick('Enter Loan Details')}
                                                     />
                                                ) : (
                                                    <Skeleton width={250} height={40} />
                                                )}
                                            </div>
                                        <div className="mortgage-balance-container1  d-none d-sm-block">
                                            <MikeMortageBalance
                                                serviceAgentData={serviceAgentData}
                                                serviceProviders={serviceProviders}
                                                onAgentContact={this.handleButtonClicked}
                                                analyticsData={analyticsData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section style={{ scrollMarginTop: '120px' }} id="savings-calculator">
                                <div
                                    style={{ scrollMarginTop: '120px' }}
                                    className="d-none d-sm-block mortgage-balance"
                                >
                                    <SavingCalculator
                                        fewerPayments={this.state.savingCalcData?.saved_payments}
                                        tillDate={this.state.savingCalcData?.new_loan_term || ''}
                                        saving={this.state.savingCalcData?.amount_saved || 0}
                                        debit={this.state.savingCalcData?.total_saved_years || 0}
                                        hashCode={this.state.hash_code}
                                        postSavingsCalcData={this.postSavingsCalcData}
                                        serviceAgentData={serviceAgentData}
                                        onAgentContact={this.handleButtonClicked}
                                        isPostingSavingCalcData={this.state.isPostingSavingCalcData}
                                        loanDetails={loanDetails}
                                        serviceProviders={serviceProviders}
                                        isFetchingHomeValuation={
                                            homeValuationState && homeValuationState?.isFetchingHomeValuation
                                        }
                                        averageCalulations={this.state?.propertyAverageValuation}
                                        handleLoanDetailsClick={() => this.handleLoanDetailsClick('Enter Loan Details')}
                                        analyticsData={analyticsData}
                                    />
                                    <Disclamer
                                        title={true}
                                        content=" This calculator is meant for educational purposes only. It calculates estimated savings solely based on the information you provide."
                                    />
                                </div>

                                <div style={{ height: 'fit-content' }}>
                                    {' '}
                                   {deals && deals.is_active && <HomeBanner
                                        bannerImage={Tag}
                                        imgName="Kathie Allen"
                                        bannerTitle={deals.title || 'Fixed & Adjustable Rate Mortgages'}
                                        bannerContent={
                                            deals.snippet ||
                                            'These are two of the most popular loan types when buying a home or refinancing your mortgage'
                                        }
                                        href1={deals.company_url || '#'}
                                        buttonPresent={false}
                                        fromDeals
                                        analyticsData={analyticsData}
                                    />}
                                </div>
                            </section>
                            <section style={{ scrollMarginTop: '120px' }} id="affordability-calculator">
                                <div>
                                    <div className="containerHomeEquity">
                                        <h3 className="homeEquityTitle">What Can I Afford if I</h3>
                                        <h6 className="homeEquitySubTitle">Choose and explore your options</h6>
                                    </div>
                                    <div className="AffordablityCard">
                                        {AffordablityCardData.map((ele, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        affordcalculator === index
                                                            ? 'AffordabilityCardMainSelected'
                                                            : 'AffordabilityCardMain'
                                                    }
                                                    onClick={() => {
                                                        this.setAffordcalculator(index);
                                                    }}
                                                >
                                                    <AffordablityCard
                                                        cardTitle={ele.title}
                                                        cardContent={ele.subTitle}
                                                        cardImage={ele.image}
                                                        cardImageBlur={ele.imageNotSelected}
                                                        selected={affordcalculator === index ? true : false}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="AffordablityCardMobile">
                                        <AffordabilityCardMobile
                                            handleCardChange={this.setAffordcalculator}
                                            defaultSelectedCard={AffordablityCardData[affordcalculator]}
                                        />
                                    </div>
                                    <div className="AffordablityCalculator">
                                        <div className="AffordablityCalculatorSection">
                                            <AffordablityCalculator
                                                setRent={this.setRent}
                                                Rent={this.state.Rent}
                                                affordcalculator={affordcalculator}
                                                isFetchingHomeValuation={
                                                    homeValuationState && homeValuationState?.isFetchingHomeValuation
                                                }
                                                homeValuationCardDetails={
                                                    homeValuationState?.homeValuationCardDetails || {}
                                                }
                                                property_valuations={
                                                    homeValuationState?.homeValuationCardDetails?.property_valuations ||
                                                    []
                                                }
                                                onPostAffordablityDataPost={this.onPostAffordablityDataPost}
                                                isCalculatingAffordability={this.state.isCalculatingAffordability}
                                                stopCalculating={this.stopCalculating}
                                                hash_code={this.state.hash_code}
                                                remainingMortgageBalance={loanAmt || 0}
                                                estimatedHomeValue={this.state?.propertyAverageValuation?.average_value}
                                                analyticsData={analyticsData}
                                            />
                                        </div>

                                        <div className="AffordablityChartSection">
                                            <AffordabilityChart
                                                onAgentContact={this.handleButtonClicked}
                                                affordabilyDataFromAPI={this.state.affordabilyDataFromAPI}
                                                onPostAffordablityDataPost={this.onPostAffordablityDataPost}
                                                rent={this.state.Rent}
                                                affordcalculator={affordcalculator}
                                                serviceAgentData={serviceAgentData}
                                                serviceProviders={serviceProviders}
                                                agentImage={serviceAgentData?.photo_url}
                                                primaryData={homeValuationState?.homeValuationCardDetails?.state_insurance_property_tax}
                                                homeValue={this.state?.propertyAverageValuation?.average_value}
                                                primaryMonthlyPayment={loanDetails?.monthly_payment}
                                                analyticsData={analyticsData}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="AffordablityDisclamer">
                                    <Disclamer
                                        title={true}
                                        content=" This calculator is meant for educational purposes only. It calculates estimated monthly payments solely based on the information you provide. The estimated monthly payments generated from the calculator do not constitute an offer."
                                    />
                                </div>
                            </section>
                            <section style={{ scrollMarginTop: '120px' }} id="home-equity">
                                <div className="containerHomeEquity">
                                    <h3 className="homeEquityTitle">Your Home Equity</h3>
                                    {!this.state.isFetchingLoanData ? (
                                        loanDetails?.remaining_equity_balance?.show_heloc ? (
                                            <HomeEquityMain
                                                loanDetails={loanDetails}
                                                currentAvgValuation={
                                                    this.state?.propertyAverageValuation?.average_value
                                                }
                                                analyticsData={analyticsData}
                                            />
                                        ) : (
                                            <EquityBanner
                                                bannerTitle="You Need To Wait For Some Time To Qualify For HELOC Loans"
                                                bannerContent="Once you have more than 20% equity in your home, you will qualify for home equity line of credit (HELOC) loans. See below for information on how you can use your equity."
                                            />
                                        )
                                    ) : (
                                        <Skeleton width={180} height={40} />
                                    )}
                                    <div className="containerHomeEquity">
                                        <h3 className="homeEquityTitle">How Can Your Equity Help You?</h3>
                                        <h6 className="homeEquitySubTitle">Explore the opportunities to leverage and utilize your equity</h6>
                                    </div>
                                    <div className="AlignementBlogCard">
                                        {this.state?.blogs?.results && (
                                            <BlogCardSlider analyticsData={analyticsData} blogResults={this.state?.blogs?.results} />
                                        )}
                                    </div>
                                    <div className="d-none d-sm-block">
                                        <MikeHomeBanner
                                            agentImage={serviceAgentData?.photo_url}
                                            imgName={`${serviceAgentData?.first_name} ${serviceAgentData?.last_name}`}
                                            serviceProviders={serviceProviders}
                                            serviceAgentData={serviceAgentData}
                                            brokerageImage={serviceAgentData?.service_provider?.logo} // serviceAgentData?.service_provider?.logo
                                            bannerTitle="Learn more about HELOCs"
                                            bannerContent="Get all of your questions answered by contacting your loan officer today."
                                            onAgentContact={this.handleButtonClicked}
                                            analyticsData={analyticsData}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section style={{ scrollMarginTop: '120px' }} id="home-projects">
                                <div className="HomeProjectMain ">
                                    <div className="HomeProjectBox">
                                        <div className="d-none d-sm-block">
                                            <h3 className="TableTitle">
                                                Top 5 Home Projects{' '}
                                                <img
                                                    style={{ marginTop: '-10px', marginLeft: '10px' }}
                                                    src={Recomended}
                                                />
                                            </h3>
                                            <BasicTable
                                                brokerage_key={this.state?.brokerage_key || ''}
                                                move_key={this.state?.move_key || ''}
                                                analyticsData={analyticsData}
                                            />
                                            <p className="remodelingDisclaimer">
                                                *Remodeling: News, products & advice for pro remodelers - Key Trends in
                                                the 2021 Cost vs. Value Report | *Like estimated loan options on acorn{' '}
                                                <img src={acornIcon} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="HomeProjectBox1">
                                        <HomeProjectCard
                                            width="100%"
                                            height="fit-content"
                                            analyticsData={analyticsData}
                                            finacingOptionsUrl={this.state.finacingOptionsUrl}
                                        />
                                    </div>
                                </div>
                            </section>
                            <div className="footerInfoBanner">
                                <HomeProjectBanner navigateToRoute={this.navigateToRoute}  analyticsData={analyticsData} />
                                <Disclamer
                                    title={false}
                                    content="Zillow Data Is Provided “As Is” Via The Zestimate Api. © Zillow, Inc., 2006-2020. Use Is Subject To Terms Of Use"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        homeValuationActions: bindActionCreators(HomeValuationActions, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeValuationState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeValuation);

// export default connect(mapStateToProps, mapDispatchToProps)(HomeValuation);
