import _ from 'lodash';
import React, { useEffect } from 'react';
import SideNavData from '../../../components/atoms/HomeValuation/SideNavData';
import './ProgressBar.scss';
import './SideNav.scss';

const SideNav = () => {
    const { hash } = window.location;

    useEffect(() => {
        if (_.isEmpty(hash)) {
            window.location.hash = SideNavData[0].href;
        };
    },[])

    return (
        <div className="navigation">
            <ul style={{ paddingInlineStart: 0 }}>
                {SideNavData.map((val, key) => {
                    return (
                        <li
                            key={key}
                            style={{
                                padding: '20px 20px',
                                margin: '0px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#6B6C6F',
                                opacity: '1',
                            }}
                            className={`${val.href === hash ? 'selected-nav' : ''}`}
                        >
                            <div>
                                <img src={val.icon} style={{ paddingRight: '20px' }} alt="" />
                            </div>
                            <a
                                href={val.href}
                                className="navbarStyle"
                                style={{ textDecorationColor: 'none', color: '#6B6C6F' }}
                            >
                                {val.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SideNav;