import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './warrantySkeleton.scss';

export default class WarrantySkeleton extends Component {
    render() {
        return (
            <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                <div className="warrantyPlanskeletonCard">
                    <div className="warrantyPlanskeletonCard1">
                        <div className="div0">
                            <div className="div1">
                                <Skeleton height="30px" />
                            </div>
                        </div>
                    </div>

                    <div className="warrantyPlanskeletonCard2">
                        <div className="div4">
                            <Skeleton />
                        </div>
                        <div className="div5">
                            <Skeleton />
                        </div>
                        <div className="div6">
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        );
    }
}
