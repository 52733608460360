import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39.35}
      height={31.357}
      viewBox="0 0 39.35 31.357"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupInternet_prefix__b{fill:#fff}.signupInternet_prefix__a{fill:${slug.color_shade3}}.signupInternet_prefix__c{fill:${slug.color_shade1}}`
            : ".signupInternet_prefix__b{fill:#fff}.signupInternet_prefix__a{fill:#5b7088}.signupInternet_prefix__c{fill:#273e59}"
          }
        </style>
      </defs>
      <path
        className="signupInternet_prefix__a"
        d="M18.445 30.742h20.29V19.675h-20.29M.615 19.675h17.83v11.067H.615zM6.763.615h25.823v14.756H6.763z"
      />
      <path
        className="signupInternet_prefix__b"
        d="M20.905 23.364h2.459v7.378h-2.459zM25.209 23.364h2.459v7.378h-2.459zM29.513 23.364h2.459v7.378h-2.459zM33.817 23.364h2.459v7.378h-2.459zM6.451 2.459h25.823v11.067H6.451z"
      />
      <path className="signupInternet_prefix__a" d="M.615 19.675h38.12v4.919H.615z" />
      <path
        className="signupInternet_prefix__c"
        d="M7.993 22.134h3.689v1.23H7.993zM7.993 27.668h3.689v1.23H7.993z"
      />
      <path
        className="signupInternet_prefix__c"
        d="M30.127 15.986H33.2V0H6.148v15.986h3.075v3.074H0v12.3h39.35v-12.3h-9.223zM7.378 1.23h24.594v.615H7.378zm0 1.845h24.594v9.837H7.378zm0 11.682v-.615h24.594v.615zM28.9 15.986v3.074H10.452v-3.074zM17.83 30.127H1.23v-4.3l16.6.018zm0-5.515l-16.6-.019v-4.3h16.6zm4.919 5.515h-1.23v-6.148h1.23zm4.3 0h-1.23v-6.148h1.23zm4.3 0h-1.23v-6.148h1.23zm4.3 0h-1.23v-6.148h1.23zm2.471-9.837v9.837h-1.23v-7.378H33.2v7.378h-.615v-7.378H28.9v7.378h-.615v-7.378h-3.691v7.378h-.615v-7.378H20.29v7.378h-1.23V20.29z"
      />
      <g>
        <path
          className="signupInternet_prefix__c"
          d="M24.095 6.204a.457.457 0 00-.015-.646 6.292 6.292 0 00-4.448-1.714 6.48 6.48 0 00-4.439 1.773.457.457 0 10.632.661 5.571 5.571 0 013.817-1.52 5.378 5.378 0 013.807 1.458.457.457 0 00.646-.012zm0 0"
        />
        <path
          className="signupInternet_prefix__c"
          d="M22.621 7.61a.457.457 0 00-.012-.646 4.16 4.16 0 00-2.974-1.154 4.38 4.38 0 00-2.967 1.214.457.457 0 10.632.661 3.411 3.411 0 014.675-.062.457.457 0 00.646-.013zm0 0"
        />
        <path
          className="signupInternet_prefix__c"
          d="M21.222 8.958a.457.457 0 00-.014-.646 2.29 2.29 0 00-3.131.061.457.457 0 00.632.661 1.384 1.384 0 011.867-.061.457.457 0 00.646-.015zm0 0M19.637 9.618a1.114 1.114 0 10.788.326 1.114 1.114 0 00-.788-.326zm.143 1.256a.2.2 0 11.058-.141.2.2 0 01-.058.144zm0 0"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
