import React from 'react';
import { connect } from 'react-redux';
import './TrustedPros.scss';
import { bindActionCreators } from 'redux';
import * as TrustedProsActions from '../../store/trustedPros/actions';
import TrustedProsIconCard from '../../components/atoms/trustedProsIconCard';
import arrowIcon from '../../Assets/images/TrustedPros/arrow_back_black_24dp.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import AutoComplete from '../../components/atoms/autoComplete';
import RoomIcon from '@material-ui/icons/Room';
import { geocodeByAddress } from 'react-places-autocomplete';
import * as CommonAction from '../../store/common/actions';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type InitialProps = {
    trustedProsAction: any;
    trustedProsState: any;
    popularService?: any;
    onClickPopular?: any;
    goBackHandler?: any;
    vendorsOptions?: any;
    commonAction: any;
    commonState: any;
    history?: any;
};
type InitialState = {
    slug: any;
    whatYouNeedHelp: any;
    address: string;
    isMoveDataArrived: boolean;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    moveId: any;
};

class ViewAllService extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: { primary_color: '' },
        whatYouNeedHelp: [],
        address: '',
        isMoveDataArrived: true,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.zipcode = nextProps.commonState.move[0].destination.zip_code;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.city = nextProps.commonState.move[0].destination.city_name;
            currentState.address = nextProps.commonState.move[0].destination_full_address;
            currentState.state = {
                name: nextProps.commonState.move[0].destination.state_code,
                label: nextProps.commonState.move[0].destination.state_code,
            };
        }

        return currentState;
    }

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: { name: state, label: state },
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    onClickPopularServices = (id: number, name: any, item: any) => {
        this.props.onClickPopular(id, name, item);
    };

    goBack = () => {
        this.props.goBackHandler();
    };
    //     onSelectVendors = async (e: any, ...params:any) => {
    //         console.log(params)
    //         // await this.setState({ whatYouNeedHelp: e.target.value });
    //         if(e.target.value.split(',')) {
    //             await this.setState({ whatYouNeedHelp: e.target.value.split(',') });
    //         } else {
    //             await this.setState({ whatYouNeedHelp: e.target.value });
    //         }

    //         let data: any = [];
    //         data.push(this.props.vendorsOptions.find((item: any) => item.name.includes(this.state.whatYouNeedHelp)));
    // console.log(data)
    //         // this.setState({ selectedVendorService: data });
    //     };

    handleChangeWhatYouNeedHelp = (e: any) => {
        if (e.target.value.split(',')) {
            this.setState({ whatYouNeedHelp: e.target.value.split(',') });
        } else {
            this.setState({ whatYouNeedHelp: e.target.value });
        }

        if (!e.target.value) {
            this.setState({ whatYouNeedHelp: [] });
        }
    };

    onChangeVendors = (e: any, value: string[] | null) => {
        this.setState({ whatYouNeedHelp: value });
        console.log(e);
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Trusted Pros',
                url: ''
            }
        ]

        return (
            <div className="trustedPros-main-page">
                <div className="trustedPros-OrderConfirmPage">
                    <div className="main-head-2  allServicesHeadCover">
                        <h2 className="heading">Trusted Pros</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                        <div className="search-address-div">
                            <div
                                className={
                                    isDouglasElliman
                                        ? 'helpInputAllService helpInputAllServiceDoug'
                                        : 'helpInputAllService'
                                }
                            >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    multiple
                                    //  onSelect={this.onSelectVendors}
                                    onChange={(e: any, value: string[] | null) => this.onChangeVendors(e, value)}
                                    options={this.props.vendorsOptions}
                                    getOptionLabel={(option: any) => option.name}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            className="trustedProsInput"
                                            label="Searching All Services"
                                            id="outlined-start-adornment"
                                            onChange={(e: any) => this.handleChangeWhatYouNeedHelp(e)}
                                            variant="outlined"
                                            value={this.state.whatYouNeedHelp}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder="Plumbing, Electrician, Security"
                                        />
                                    )}
                                />
                            </div>

                            <div
                                className={
                                    isDouglasElliman
                                        ? 'trustedProsAddressDivAllService trustedProsAddressDivAllServiceDoug'
                                        : 'trustedProsAddressDivAllService'
                                }
                            >
                                <div className="addressText">Your address</div>
                                <RoomIcon
                                    className="addressRoomIcon"
                                    style={{ color: isDouglasElliman ? '#009CBD' : '#006DFF' }}
                                />
                                <AutoComplete
                                    className="trustedProsStepsAutoComplete"
                                    value={this.state.address}
                                    onChange={e => {
                                        this.setState({ address: e });
                                    }}
                                    onSelect={this.handleSelect}
                                    // placeholder="Street"
                                    // inputTitle="To"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="MostPopularServiceDiv">
                    <div className="MostPopularServiceTitleDiv">
                        <div className="ViewAllServiceBackIconDiv">
                            <img
                                src={arrowIcon}
                                onClick={() => {
                                    this.goBack();
                                }}
                                style={{ cursor: 'pointer' }}
                            />

                            <div className="viewAllTitle">All Services</div>
                        </div>
                    </div>
                    <div className="PopularCardsDiv">
                        {this.state.whatYouNeedHelp.length == 0
                            ? this.props.popularService.map((item: any, i: number) => {
                                  return (
                                      <TrustedProsIconCard
                                          key={i}
                                          className="popularCard"
                                          icon={item.image ? item.image : ''}
                                          title={item.name ? item.name : ''}
                                          onClick={() => {
                                              this.onClickPopularServices(item.id, item.name, item);
                                          }}
                                      />
                                  );
                              })
                            : this.state.whatYouNeedHelp.map((item: any, i: number) => {
                                  // this.state.whatYouNeedHelp.length && this.state.whatYouNeedHelp.filter((whatYouNeedHelp :any) => {
                                  // if(whatYouNeedHelp.name.match(item.name)){
                                  // console.log(whatYouNeedHelp, item)
                                  return (
                                      // <div>hh</div>
                                      <TrustedProsIconCard
                                          key={i}
                                          className="popularCard"
                                          icon={item.image ? item.image : ''}
                                          title={item.name ? item.name : ''}
                                          onClick={() => {
                                              this.onClickPopularServices(item.id, item.name, item);
                                          }}
                                      />
                                  );
                                  //    }
                                  //  })
                              })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        trustedProsAction: bindActionCreators(TrustedProsActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    trustedProsState: state.trustedPros,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllService);
