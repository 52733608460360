import {
    HEADER_OPTIONS,
    HEADER_OPTIONS_ERROR,
    HEADER_OPTIONS_SUCCESS,
    REALTOR_DETAILS,
    REALTOR_DETAILS_ERROR,
    REALTOR_DETAILS_SUCCESS,
    MOVE_API_GET,
    MOVE_API_GET_ERROR,
    MOVE_API_GET_SUCCESS,
    MOVE_ADDRESS_UPDATE,
    MOVE_ADDRESS_UPDATE_ERROR,
    MOVE_ADDRESS_UPDATE_SUCCESS,
    REQUEST_SERVICE,
    REQUEST_SERVICE_ERROR,
    REQUEST_SERVICE_SUCCESS,
    REQUEST_SERVICE_GET,
    REQUEST_SERVICE_GET_ERROR,
    REQUEST_SERVICE_GET_SUCCESS,
    MOVE_API_WITH_HASHCODE_GET,
    MOVE_API_WITH_HASHCODE_GET_ERROR,
    MOVE_API_WITH_HASHCODE_GET_SUCCESS,
    APPOINTMENT_DETAILS_GET,
    APPOINTMENT_DETAILS_GET_ERROR,
    APPOINTMENT_DETAILS_GET_SUCCESS,
    POST_CANCELLATION_GET,
    POST_CANCELLATION_GET_ERROR,
    POST_CANCELLATION_GET_SUCCESS,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR,
    SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS,
    GET_SERVICE_AGENTS_SUCCESS,
    GET_SERVICE_PROVIDERS_SUCCESS,
    REQUEST_SERVICE_AGENT_BY_SERVICES,
    REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS,
    REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR,
    GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS
} from './actionTypes';

import { CommonActions, CommonState } from './types';

const initialState: CommonState = {
    header: [],
    errorHeader: null,
    move: [],
    errorMove: null,
    moveAddress: [],
    errorMoveAddress: null,
    realtor: [],
    isRealtor: false,
    errorRealtor: null,
    requestService: [],
    errorService: null,
    serviceAgenByServices:[],
    errorserviceAgenByServices: null,
    moveWithHashCode: [],
    errorMoveWithHashCode: null,
    appointmentDetails: [],
    appointmentDetailsArrived: false,
    moveDataArrived: false,
    iscancelDataPosted: false,
    cancelAppointment: [],
    scheduleCallPost: [],
    isScheduleCallPost: false,
    serviceAgentInfo: null,
    serviceProviderInfo: null,
    inspectionServiceProviderInfo: null
};

export default (state = initialState, action: CommonActions) => {
    switch (action.type) {
        case REQUEST_SERVICE:
            return {
                ...state,
                requestService: [],
                errorService: null,
            };
        case REQUEST_SERVICE_SUCCESS:
            return {
                ...state,
                requestService: action.payload.requestService,
                errorService: null,
            };
        case REQUEST_SERVICE_ERROR:
            return {
                ...state,
                requestService: [],
                errorService: action.payload.errorService,
            };

        case REQUEST_SERVICE_GET:
            return {
                ...state,
                requestService: [],
                errorService: null,
            };
        case REQUEST_SERVICE_GET_SUCCESS:
            return {
                ...state,
                requestService: action.payload.requestService,
                errorService: null,
            };
        case REQUEST_SERVICE_GET_ERROR:
            return {
                ...state,
                requestService: [],
                errorService: action.payload.errorService,
            };

        case HEADER_OPTIONS:
            return {
                ...state,
                header: [],
                errorHeader: null,
            };
        case HEADER_OPTIONS_SUCCESS:
            return {
                ...state,
                header: action.payload.header,
                errorHeader: null,
            };
        case HEADER_OPTIONS_ERROR:
            return {
                ...state,
                header: [],
                errorHeader: action.payload.errorHeader,
            };

        case REALTOR_DETAILS:
            return {
                ...state,
                realtor: [],
                isRealtor: false,
                errorRealtor: null,
            };
        case REALTOR_DETAILS_SUCCESS:
            return {
                ...state,
                realtor: action.payload.realtor,
                isRealtor: true,
                errorRealtor: null,
            };
        case REALTOR_DETAILS_ERROR:
            return {
                ...state,
                realtor: [],
                isRealtor: false,
                errorRealtor: action.payload.errorRealtor,
            };

        case MOVE_API_GET:
            return {
                ...state,
                move: [],

                errorMove: null,
                moveDataArrived: false,
            };
        case MOVE_API_GET_SUCCESS:
            return {
                ...state,
                move: action.payload.move,
                errorMove: null,
                moveDataArrived: true,
            };

        case MOVE_API_GET_ERROR:
            return {
                ...state,
                move: [],
                errorMove: action.payload.errorMove,
                moveDataArrived: false,
            };

        case MOVE_ADDRESS_UPDATE:
            return {
                ...state,
                errorMoveAddress: null,
            };
        case MOVE_ADDRESS_UPDATE_SUCCESS:
            return {
                ...state,
                moveAddress: action.payload.moveAddress,
                errorMoveAddress: null,
            };
        case MOVE_ADDRESS_UPDATE_ERROR:
            return {
                ...state,
                moveAddress: [],
                errorMoveAddress: action.payload.errorMoveAddress,
            };

        case MOVE_API_WITH_HASHCODE_GET:
            return {
                ...state,
                moveWithHashCode: [],
                move: [],
                errorMoveWithHashCode: null,
            };
        case MOVE_API_WITH_HASHCODE_GET_SUCCESS:
            return {
                ...state,
                move: action.payload.moveWithHashCode,
                moveWithHashCode: action.payload.moveWithHashCode,
                errorMoveWithHashCode: null,
            };
        case MOVE_API_WITH_HASHCODE_GET_ERROR:
            return {
                ...state,
                moveWithHashCode: [],
                move: [],
                errorMoveWithHashCode: action.payload.errorMoveWithHashCode,
            };

        case APPOINTMENT_DETAILS_GET:
            return {
                ...state,
                appointmentDetails: [],
                error: null,
                appointmentDetailsArrived: false,
            };
        case APPOINTMENT_DETAILS_GET_SUCCESS:
            return {
                ...state,
                appointmentDetails: action.payload.appointmentDetails,
                error: null,
                appointmentDetailsArrived: true,
            };
        case APPOINTMENT_DETAILS_GET_ERROR:
            return {
                ...state,
                appointmentDetails: [],
                error: action.payload.error,
                appointmentDetailsArrived: false,
            };

        case POST_CANCELLATION_GET:
            return {
                ...state,
                cancelAppointmenet: [],
                error: null,
            };
        case POST_CANCELLATION_GET_SUCCESS:
            return {
                ...state,
                cancelAppointmenet: action.payload.cancelAppointment,
                error: null,
            };
        case POST_CANCELLATION_GET_ERROR:
            return {
                ...state,
                cancelAppointmenet: [],
                error: action.payload.error,
            };

        case SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED:
            return {
                ...state,
                scheduleCallPost: [],
                isScheduleCallPost: false,
                error: null,
            };

        case SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_SUCCESS:
            return {
                ...state,
                scheduleCallPost: action.payload.scheduleCallPost,
                isScheduleCallPost: true,
                error: null,
            };

        case SCHEDULE_CALL_POST_FOR_FEATURE_ENABLED_ERROR:
            return {
                ...state,
                scheduleCallPost: [],
                isScheduleCallPost: false,
                error: action.payload.error,
            };

        case GET_SERVICE_AGENTS_SUCCESS:
            return {
                ...state,
                serviceAgentInfo: action.payload,
            };

        case GET_SERVICE_PROVIDERS_SUCCESS:
            return {
                ...state,
                serviceProviderInfo: action.payload,
            };
        case GET_INSPECTION_SERVICE_PROVIDERS_SUCCESS:
                return {
                    ...state,
                    inspectionServiceProviderInfo: action.payload,
                };
        case REQUEST_SERVICE_AGENT_BY_SERVICES:
            return {
                ...state,
                serviceAgenByServices: [],
                errorserviceAgenByServices: null,
            };
        case REQUEST_SERVICE_AGENT_BY_SERVICES_SUCCESS:
            return {
                ...state,
                serviceAgenByServices: action.payload.serviceAgenByServices,
                errorserviceAgenByServices: null,
            };
        case REQUEST_SERVICE_AGENT_BY_SERVICES_ERROR:
            return {
                ...state,
                serviceAgenByServices: [],
                errorserviceAgenByServices: action.payload.errorserviceAgenByServices,
            };
        default:
            return {
                ...state,
            };
    }
};
