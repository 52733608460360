import React from 'react';
import Button from '../atoms/Button';
import '../HomeValuation/HomeProjectBanner.scss';
import './AddLoanDetails.css';
import HomeSpin from '../../Assets/images/homeOwnerDashboard/loan-add-home-search.gif';

const HomeProjectBanner = (props: PropsTypes) => {

    const {title, description, handleClickAdd, btnTxt, fromTopCard } = props;
    return (
        <section className={`Main ${fromTopCard && 'dashboardTopCard'}`}>
            <div className="MainBox1">
                <img src={HomeSpin} width={fromTopCard ? 81 : 168} alt="" />
            </div>
            <div className="MainBox2">
                <span className="mainHeading loan-details-grp1-info-title">{title || "Add your home loan details"}</span>
                <span className="loan-details-grp1-info-description subHeading">{description || "Please enter your loan details to estimate your equity and remaining loan balance."}</span>
                <Button color="#FFF" borderRadius={'5px'} fontSize="14px" onClick={handleClickAdd || null}>
                    {btnTxt || "Enter Loan Details"}
                </Button>
            </div>
        </section >
    );
};

export default HomeProjectBanner;


interface PropsTypes {
    handleClickAdd?: Function;
    title?: string;
    description?: string;
    btnTxt?: string;
    fromTopCard?: boolean;
}
