import React from 'react';
import { makeStyles } from '@material-ui/core';
import '../../styles/longCard.scss';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/longCheckboxCard.css';
import Rating from '@material-ui/lab/Rating';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    bestForImage?: any;
    bestForTitle?: string;
    boxShadow?: any;
    checked: boolean;
    handleChange: any;
    label: string;
    imgWidth1?: string;
    imgHeight1?: string;
    rate?: number;
    ratingIcon?: any;
    verfiedReviews?: any;
    phoneIcon?: any;
    phoneNumber?: string;
    locationIcon?: any;
    locationtext?: string;
    yearsIcon?: any;
    yearText?: string;
    licensedIcon?: any;
    licensedText?: string;
    insuredIcon?: any;
    insuredText?: string;
    recommended?: boolean;
}

const LongCheckboxCard = (props: cardProps) => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));

    const classes = useStyles();
    return (
        <div>
            {props.recommended === true ? (
                <div
                    className={isDouglasElliman ? 'recommended-card recommended-cardDoug' : 'recommended-card'}
                    style={{
                        width: props.width ? props.width : 'inherit',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                        //   border: props.border
                        //   ? props.border
                        //   : props.checked
                        //   ? slug && slug.primary_color
                        //       ? `1px solid ${slug.primary_color}`
                        //       : '1px solid #002E62'
                        //   : ''
                        border: props.recommended
                            ? isDouglasElliman
                                ? '2px solid #009cbd'
                                : '2px solid #1966E7'
                            : props.checked
                            ? slug && slug.primary_color
                                ? `1px solid ${slug.primary_color}`
                                : '1px solid #002E62'
                            : '',
                    }}
                >
                    `{slug && slug.display_name} Recommended`
                </div>
            ) : (
                ''
            )}
            <div
                style={{
                    width: props.width ? props.width : 'inherit',
                    height: props.height ? props.height : 'auto',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: props.borderRadius ? props.borderRadius : '3px',
                    borderTopLeftRadius: props.recommended ? '0' : '3px',
                    borderTopRightRadius: props.recommended ? '0' : '3px',
                    boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                    border: props.recommended
                        ? isDouglasElliman
                            ? '2px solid #009cbd'
                            : ' 2px solid #1966E7'
                        : props.checked
                        ? slug && slug.primary_color
                            ? `1px solid ${slug.primary_color}`
                            : '1px solid #002E62'
                        : '',

                    // border: props.border
                    //     ? props.border
                    //     : props.checked
                    //     ? slug && slug.primary_color
                    //         ? `1px solid ${slug.primary_color}`
                    //         : '1px solid #002E62'
                    //     : '',
                    opacity: '1',
                    margin: props.cardMargin ? props.cardMargin : '0px',
                    padding: props.cardPadding ? props.cardPadding : '15px',
                    cursor: 'pointer',
                    textAlign: 'center',
                }}
                onClick={props.handleChange}
                className={props.className ? `${props.className} ${classes.root}` : classes.root}
            >
                <div className="checkbox-long-div">
                    <Checkbox
                        className={props.className ? `${props.className} ${classes.checkroot}` : classes.checkroot}
                        checked={props.checked}
                        color="default"
                        style={{
                            color: props.checked
                                ? slug && slug.primary_color
                                    ? `${slug.primary_color}`
                                    : '#273E59'
                                : '#BEBEBE',
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>

                <div
                    className="cardContent"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '-10px',
                    }}
                >
                    {/* {props.bestForImage ? (
                        <div>
                            <div className="bestForTitle">
                                <img
                                    width={props.imgWidth ? props.imgWidth : '30px'}
                                    height={props.imgHeight ? props.imgHeight : '30px'}
                                    src={props.bestForImage}
                                    style={{
                                        marginRight: '15px',
                                    }}
                                    alt=""
                                />
                                {props.bestForTitle}
                            </div>
                        </div>
                    ) : null} */}

                    <div className="image-div-long-card">
                        <img
                            width={props.imgWidth1 ? props.imgWidth1 : '100%'}
                            height={props.imgHeight1 ? props.imgHeight1 : ''}
                            src={props.cardImage}
                            style={{
                                marginRight: 'auto',
                                maxHeight: '100%',
                            }}
                            alt=""
                        />
                    </div>

                    <div>
                        <div className="card-line-title">{props.cardTitle}</div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            className={
                                props.rate != 0
                                    ? isDouglasElliman
                                        ? 'rating-button'
                                        : 'rating-buttonDoug'
                                    : 'rating-button-new'
                            }
                        >
                            <img style={{ marginRight: '5px' }} src={props.rate != 0 ? props.ratingIcon : ''} alt="" />
                            <span style={{ marginTop: '0px' }}> {props.rate != 0 ? props.rate : ''}</span>
                        </div>
                    </div>

                    <div className="ratingLongCard">
                        {props.rate != 0 ? <Rating name="read-only" value={props.rate} readOnly /> : null}
                    </div>
                </div>

                <div className="review-count">{props.verfiedReviews ? props.verfiedReviews : ''}</div>

                <div className="years-licencsed-div">
                    <div>
                        <img src={props.yearsIcon} height="25px" width="25px" alt="" />
                        <p className="years-licencsed-text">{props.yearText}yrs</p>
                    </div>
                    <div>
                        <img src={props.licensedIcon} height="25px" width="25px" alt="" />
                        <p className="years-licencsed-text">{props.licensedText}</p>
                    </div>
                    <div>
                        <img src={props.insuredIcon} height="25px" width="25px" alt="" />
                        <p className="years-licencsed-text">{props.insuredText}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}>
                    <div className="info-icon-cover">
                        {' '}
                        <img src={props.phoneIcon} alt="" />{' '}
                    </div>
                    <div>
                        {' '}
                        <p className="" style={{ cursor: 'default', margin: '0px', fontSize: '12px' }}>
                            {props.phoneNumber}
                        </p>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '12px'  }}>
                    <div className="info-icon-cover">
                        {' '}
                        <img src={props.locationIcon} alt="" />{' '}
                    </div>
                    <div></div>{' '}
                    <p className="" style={{ cursor: 'default', margin: '0px', fontSize: '12px' }}>
                        {props.locationtext
                            ? props.locationtext.length < 15
                                ? props.locationtext
                                : `${props.locationtext.substring(0, 15)}....`
                            : ''}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LongCheckboxCard;
