import React, { Component } from 'react';
import './driving.scss';
import Button from '../../components/atoms/Button';
// import MobileSvg from '../../Assets/images/dmvImages/smartphone_black_24dp.svg';
// import EmailSvg from '../../Assets/images/dmvImages/mail_black_24dp.svg';
import DescritionSvg from '../../Assets/images/dmvImages/description_black_24dp.svg';
// import RedSvg from '../../Assets/images/dmvImages/redCal.svg'
import BlueSvg from '../../Assets/images/dmvImages/BlueSvg1.svg';
// import YellowSvg from '../../Assets/images/dmvImages/yellowCal.svg'
import SavingSvg from '../../Assets/images/dmvImages/savings_white_24dp.svg';
import DummySvg from '../../Assets/images/dmvImages/licenseimg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import * as DMVAction from '../../store/dmv/actions';
import RedSvg from '../../Assets/images/dmvImages/redCal.svg';
import YellowSvg from '../../Assets/images/dmvImages/yellowCal.svg';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = { history?: any; commonAction?: any; commonState?: any; dmvAction?: any; dmvState?: any };

type initialState = {
    points: any;
    state: string;
    withInState: boolean;
    dayLimit: number;
    count: number;
    isDouglasElliman: boolean;
};

export class VehicleRegistration extends Component<initialProps, initialState> {
    state = {
        points: { p1: true, p2: false, p3: false, p4: false, p5: false },
        state: '',
        withInState: true,
        dayLimit: 30,
        count: -1,
        isDouglasElliman: false,
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            const dest = data && data.destination;
            const source = data && data.source;
            this.setState({ state: dest.state_code });
            this.props.dmvAction.dmv({ state: dest.state_code });
            if (dest.state_code === source.state_code) {
                this.setState({ withInState: true, dayLimit: 10 });
            }
        } else {
            this.props.commonAction.moveGet();
        }
    }
    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const dmvP = prevProps && prevProps.dmvState;
        const dmvC = this.props && this.props.dmvState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            const dest = cur.move[0] && cur.move[0].destination;
            const source = cur.move[0] && cur.move[0].source;
            this.setState({ state: dest.state_code });
            this.props.dmvAction.dmv({ state: dest.state_code });
            if (dest.state_code === source.state_code) {
                this.setState({ withInState: true, dayLimit: 10 });
            }
        }
        if (dmvP.dmv !== dmvC.dmv && dmvC.dmv && dmvC.dmv.length > 0 && dmvC.dmv[0]) {
            if (this.state.dayLimit === 30 && dmvC.dmv[0] && dmvC.dmv[0].state_vehicle_registrations) {
                dmvC.dmv[0].state_vehicle_registrations.map((el: any) => {
                    if (el && el.procedure_name === 'Timeframe') {
                        let num =
                            el && el.details.match(/\d+/) && el.details.match(/\d+/)[0]
                                ? parseInt(el.details.match(/\d+/)[0])
                                : 0;
                        var result: any = new Date(
                            this.props.commonState &&
                                this.props.commonState.move &&
                                this.props.commonState.move[0] &&
                                this.props.commonState.move[0].moving_date,
                        );
                        result.setDate(result.getDate() + num);

                        let date2: any = new Date();
                        var Difference_In_Time = result.getTime() - date2.getTime();
                        var Days: any = Difference_In_Time / (1000 * 3600 * 24);
                        this.setState({ count: parseInt(Days) });
                    }
                });
            }
        }
    }
    handleChange = (type: string) => {
        let point = this.state.points;
        type === 'p1'
            ? (point.p1 = !point.p1)
            : type === 'p2'
            ? (point.p2 = !point.p2)
            : type === 'p3'
            ? (point.p3 = !point.p3)
            : type === 'p4'
            ? (point.p4 = !point.p4)
            : type === 'p5'
            ? (point.p5 = !point.p5)
            : (point = point);
        this.setState({ points: point });
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let nearestDmv: any = '',
            vitalFormLink: any = '',
            HandbookLink: any = '',
            costArray: any = [],
            documentListArray: any = [];
        const data =
            this.props.dmvState &&
            this.props.dmvState.dmv &&
            this.props.dmvState.dmv[0] &&
            this.props.dmvState.dmv[0].state_vehicle_registrations;

        this.props.dmvState &&
            this.props.dmvState.dmv &&
            this.props.dmvState.dmv[0] &&
            this.props.dmvState.dmv[0].state_regular_passenger_licenses.map((el: any) => {
                if (el.procedure_name === 'Driver Handbook') {
                    HandbookLink = el && el.details;
                }
            });

        if (data) {
            data.map((item: any) => {
                if (item.procedure_name === 'To be applied in person') {
                    nearestDmv = item.details;
                    vitalFormLink = item.forms;
                } else if (item.procedure_name === 'Documents required') {
                    // HandbookLink = '';
                    documentListArray = item.details.split(',');
                } else if (item.procedure_name === 'Cost') {
                    let array = item.details.split(';');
                    array.map((it: any) => {
                        costArray.push({ key: it.split('-')[0], value: it.split('-')[1] ? it.split('-')[1] : '' });
                    });
                }
            });
        }
        let checkListArea = (
            <div className="checklistarea-dmv">
                <div className="dmv-right-1">
                    <div className="dmv-right-11">
                        <img src={DescritionSvg} style={{ marginRight: '5px', marginLeft: '-3px' }} alt="" />
                        Documents Required
                    </div>
                    <div className={this.state.isDouglasElliman ? 'dmv-right-12 dmv-right-12Doug' : 'dmv-right-12'}>
                        <FormGroup>
                            {documentListArray && documentListArray.length > 0 ? (
                                documentListArray.map((item: any, i: number) => {
                                    if (item !== '') {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        // onChange={() => this.handleChange('p1')}
                                                        disabled={true}
                                                        name="p1"
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ color: this.state.points.p1 ? '#2270EE' : '#B3B3B3' }}
                                                    />
                                                }
                                                label={item}
                                            />
                                        );
                                    }
                                })
                            ) : (
                                // <div
                                //     style={{
                                //         marginTop: '20px',
                                //         display: 'flex',
                                //         justifyContent: 'center',
                                //         alignItems: 'center',
                                //     }}
                                // >
                                //     <CircularProgress />
                                // </div>
                                <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                                    <Skeleton style={{ marginBottom: '20px' }} count={4} />
                                    {/* <Skeleton style={{ marginBottom: '20px' }} />
                                    <Skeleton style={{ marginBottom: '20px' }} /> */}
                                    {/* <Skeleton /> */}
                                </SkeletonTheme>
                            )}
                        </FormGroup>
                    </div>
                </div>
                {/* <div className="dmv-right-2">
                    <div style={{ fontSize: '14px' }}>Send info via</div>
                    <div className="dmv-right-2-img">
                        <div className="right-img" style={{ marginRight: '10px' }}>
                            <img src={MobileSvg} />
                        </div>
                        <div className="right-img">
                            <img src={EmailSvg} />
                        </div>
                    </div>
                </div> */}
            </div>
        );

        let icon = BlueSvg;
        if (this.state.dayLimit === 30) {
            this.state.count > 15
                ? (icon = BlueSvg)
                : this.state.count <= 15 && this.state.count > 5
                ? (icon = YellowSvg)
                : (icon = RedSvg);
        }
        if (this.state.dayLimit === 10) {
            this.state.count > 5
                ? (icon = BlueSvg)
                : this.state.count <= 5 && this.state.count > 3
                ? (icon = YellowSvg)
                : (icon = RedSvg);
        }
        const { isDouglasElliman } = this.state;

        const breadList = [
            {
                name: 'Vehicle Registration',
                url: ''
            }
        ]

        return (
            <div>
                {isDouglasElliman && (
                    <div>
                        <TemplateUpper
                            pageImage="dmv"
                            headerText={`Updating your`}
                            subheaderText={'Manage updating your driver’s license in minutes!'}
                            highlightedText={"Driver's license"}
                        />
                    </div>
                )}
                <div className="DrivingLicenceMain">
                    {!isDouglasElliman && (
                        <div className="main-head-dmv">
                            <h2 className="heading-dmv">Driver’s License</h2>
                            <div className='mb-4'>
                               <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                    )}
                    <div className={isDouglasElliman ? 'dmvtab-div dmvMainRemoveDouglas' : 'dmvtab-div'}>
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/driverlicense' ||
                                    this.props.history.location.pathname === `/dashboard/driverlicense/`)
                                    ? isDouglasElliman
                                        ? 'dmvtab-active changeColordmv'
                                        : 'dmvtab-active'
                                    : 'dmvtab-text'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/driverlicense');
                            }}
                        >
                            Driver License
                        </div>
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname ===
                                    '/dashboard/driverlicense/vehicleregistration' ||
                                    this.props.history.location.pathname ===
                                        `/dashboard/driverlicense/vehicleregistration/`)
                                    ? isDouglasElliman
                                        ? 'dmvtab-active changeColordmv'
                                        : 'dmvtab-active'
                                    : 'dmvtab-text'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/driverlicense/vehicleregistration');
                            }}
                        >
                            Vehicle Registration
                        </div>
                    </div>
                    <div
                        className={isDouglasElliman ? 'dmv-content-div dmvMainRemoveContentDouglas' : 'dmv-content-div'}
                    >
                        <div className="dmv-content-left">
                            <div className="dmv-left-1">
                                {this.state.count >= 0 && (
                                    <div
                                        className="calender-dmv"
                                        style={{
                                            background: `url(${icon}) no-repeat center center`,
                                            color:
                                                (this.state.dayLimit === 30 &&
                                                    this.state.count <= 15 &&
                                                    this.state.count > 5) ||
                                                (this.state.dayLimit === 10 &&
                                                    this.state.count <= 5 &&
                                                    this.state.count > 3)
                                                    ? '#333'
                                                    : '#fff',
                                        }}
                                    >
                                        <div style={{ fontSize: '42px', fontWeight: 800 }}>{this.state.count}</div>
                                        <div style={{ fontSize: '16px', fontWeight: 500 }}>Days Left</div>
                                    </div>
                                )}
                                <div className="detail">
                                    <div className="title-dmv">{this.state.state} Vehicle Registration</div>
                                    <div className="subText-dmv ">
                                        After the end of {this.state.count >= 0 ? this.state.count : ''} days, your
                                        vehicle registration expires and there might be a penalty to renew it again. We
                                        suggest you to renew using following ways
                                    </div>
                                </div>
                            </div>
                            <div className="dmv-left-11-checklist">{checkListArea}</div>
                            <div className="dmv-left-2">
                                <div>
                                    <div className="title-dmv1">Register in person</div>
                                    <div className="subText-dmv1">
                                        You will have to register in person at your nearest DMV location
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : slug && slug.primary_color
                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                : '0 0 3px 0 #273E59'
                                        }
                                        border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273E59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        backgroundColor="#FFFFFF"
                                        width="210px"
                                        height="50px"
                                        fontSize="16px"
                                        onClick={() => window.open(nearestDmv, '_blank')}
                                    >
                                        Find your nearest DMV
                                    </Button>
                                </div>
                            </div>
                            <div className="dmv-left-3">
                                <div
                                    className="dmv-left-vehicle-head"
                                    style={{
                                        background: isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59',
                                    }}
                                >
                                    <div className="dmv-left-3-head1">
                                        <img src={SavingSvg} style={{ marginRight: '10px' }} alt="" />
                                        Cost Estimate
                                    </div>
                                    <div className="dmv-left-3-head2">*Additional Tax may apply</div>
                                </div>
                                <div className="dmv-left-3-body">
                                    {costArray && costArray.length > 0 ? (
                                        costArray.map((item: any, i: number) => {
                                            return (
                                                <div key={i} className="dmv-left-3-body1">
                                                    <div className="body-class">{item.key}</div>
                                                    <div
                                                        className="body-amount"
                                                        style={{ fontFamily: 'Poppins, sans-serif' }}
                                                    >
                                                        {item.value}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        // <div
                                        //     style={{
                                        //         marginTop: '20px',
                                        //         display: 'flex',
                                        //         justifyContent: 'center',
                                        //         alignItems: 'center',
                                        //     }}
                                        // >
                                        //     <CircularProgress />
                                        // </div>
                                        // <div className="dataAvailable">No task available</div>
                                        <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                                            <Skeleton style={{ marginBottom: '20px', height: '30px' }} count={2} />
                                            {/* <Skeleton style={{ height: '30px' }} /> */}
                                        </SkeletonTheme>
                                    )}
                                </div>
                            </div>
                            <div
                                className="dmv-left-4"
                                style={{
                                    background: '#100B28',
                                    padding: isDouglasElliman ? '10px 15px 10px 20px' : '0px 15px 0px 20px',
                                }}
                            >
                                <div className="dmv-left-41">
                                    <div style={{ fontWeight: 600, marginBottom: '10px' }}>
                                        Driving exams are waived if you have a current out-of-state driver license
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        You may find the answer to your question in the driver's handbook
                                    </div>
                                    <div className="dmv-left-4-btns">
                                        <div className="dmv-left-4-btns1">
                                            <Button
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor="#FFFFFF"
                                                width="190px"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={() => window.open(vitalFormLink, '_blank')}
                                            >
                                                Download Vital Form
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                boxShadow={
                                                    isDouglasElliman
                                                        ? 'none'
                                                        : slug && slug.primary_color
                                                        ? `0 0 3px 0 ${slug.primary_color}`
                                                        : '0 0 3px 0 #273E59'
                                                }
                                                border={isDouglasElliman ? '1px solid #100B28' : 'none'}
                                                color={
                                                    isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : '#273E59'
                                                }
                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                backgroundColor="#FFFFFF"
                                                width="190px"
                                                height="50px"
                                                fontSize="16px"
                                                onClick={() => window.open(HandbookLink, '_blank')}
                                            >
                                                Download Handbook
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {!isDouglasElliman && <DummySvg />}
                            </div>
                        </div>

                        <div className="dmv-content-right">{checkListArea}</div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        dmvAction: bindActionCreators(DMVAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    dmvState: state.dmv,
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleRegistration);
