import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 48.222}
            height={props.height ? props.height : 31}
            viewBox="0 0 48.222 31"
        >
            <defs>
                <style>
                    {// ".prefix__a{fill:#bebebe}.prefix__b{fill:#fff}.prefix__c{fill:#6b6c6f}"
                    `.DriversLicenceSvgprefix__a{fill:${
                        window.location.pathname.includes('driverlicense') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.DriversLicenceSvgprefix__b{fill:#fff}.DriversLicenceSvgprefix__c{fill:${
                        window.location.pathname.includes('driverlicense') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-97.464 -31.536)">
                <path
                    className="DriversLicenceSvgprefix__a"
                    d="M114.611 35.077V49.06a.3.3 0 01-.3.3h-.781a5.239 5.239 0 00-2.665-4.987 4.053 4.053 0 01-5.77 0 5.239 5.239 0 00-2.665 4.987h-.852a.3.3 0 01-.3-.3V35.077a.3.3 0 01.3-.3h12.737a.3.3 0 01.296.3zm-3.94 6.215a2.69 2.69 0 10-2.69 2.7 2.69 2.69 0 002.69-2.7z"
                />
                <path
                    className="DriversLicenceSvgprefix__b"
                    d="M113.532 49.358h-11.1a5.239 5.239 0 012.665-4.987 4.053 4.053 0 005.77 0 5.239 5.239 0 012.665 4.987z"
                />
                <path className="DriversLicenceSvgprefix__a" d="M129.132 55.722h12.886v2.136h-12.886z" />
                <ellipse
                    className="DriversLicenceSvgprefix__b"
                    cx={2.69}
                    cy={2.7}
                    rx={2.69}
                    ry={2.7}
                    transform="translate(105.292 38.591)"
                />
                <path
                    className="DriversLicenceSvgprefix__c"
                    d="M98.173 31.536a.709.709 0 00-.709.709v29.582a.709.709 0 00.709.709h46.8a.709.709 0 00.709-.709V32.245a.709.709 0 00-.705-.709zm46.091 1.418v28.164H98.882V32.954z"
                />
                <path
                    className="DriversLicenceSvgprefix__c"
                    d="M142.667 55.722a.709.709 0 00-.709-.709h-12.866a.709.709 0 00-.709.709v2.127a.709.709 0 00.709.709h12.866a.709.709 0 00.709-.709zm-12.866.709h11.549v.709h-11.549zM115.133 35.061a.819.819 0 00-.819-.819h-12.738a.819.819 0 00-.819.819v14a.819.819 0 00.819.819h12.737a.819.819 0 00.819-.819zm-12.162 13.781a4.668 4.668 0 012.053-3.831 4.561 4.561 0 005.916 0 4.665 4.665 0 012.053 3.83zM101.8 35.286h12.29v13.556h-.053a5.581 5.581 0 00-2.937-4.934.521.521 0 00-.61.1 3.533 3.533 0 01-5.017 0 .521.521 0 00-.61-.1 5.579 5.579 0 00-2.937 4.933h-.127zM140.33 37.188a.709.709 0 00-.709-.709h-17.526a.709.709 0 000 1.418h17.526a.709.709 0 00.709-.709zM123.614 40.633a.709.709 0 100 1.418h14.491a.709.709 0 100-1.418zM120.575 46.103h20.565a.709.709 0 000-1.418h-20.565a.709.709 0 000 1.418z"
                />
                <path
                    className="DriversLicenceSvgprefix__c"
                    d="M107.981 44.513a3.221 3.221 0 10-3.211-3.221 3.221 3.221 0 003.211 3.221zm0-5.4a2.179 2.179 0 11-2.168 2.179 2.179 2.179 0 012.168-2.179z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
