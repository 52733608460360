import React from 'react';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from '@material-ui/lab/Rating';
import '../../styles/movingPlanCardMobile.scss';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    boxShadow?: any;
    checked: boolean;
    handleChange: any;
    label?: string;
    bestForImage?: any;
    bestForTitle?: string;
    cardTitel?: string;
    rate?: number;
    ratingIcon?: any;
    verifiedReview?: any;
    phoneIcon?: any;
    phoneNumber?: string;
    locationIcon?: any;
    locationtext?: string;
    yearsIcon?: any;
    yearText?: string;
    licensedIcon?: any;
    licensedText?: string;
    insuredIcon?: any;
    insuredText?: string;
}

const MovingPlanCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
            checkroot: {},
        },
    }));
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    const classes = useStyles();
    return (
        <div
            style={{
                width: props.width ? props.width : '200px',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                border: props.border ? props.border : props.checked ? '1px solid #002E62' : '',
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '20px 0px',
                padding: props.cardPadding ? props.cardPadding : '0px',
                cursor: 'pointer',
            }}
            onClick={props.handleChange}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} main-body-mobile`}
        >
            <div className="mover-card-main-mobile">
                <div className="inside-mover-mobile">
                    <div className="checkbox-image-div-mobile">
                        <div className="checkbox-image-mobile">
                            <Checkbox
                                checked={props.checked}
                                onChange={props.handleChange}
                                color="default"
                                style={{ color: props.checked ? '#273E59' : '#BEBEBE' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </div>
                    <div className="best-moves-mobile">
                        {props.bestForImage ? (
                            <div className="best-image-div">
                                <img src={props.bestForImage} className="bestImage" alt="" height="30px" width="30px" />
                                <span className="best-title">{props.bestForTitle}</span>
                            </div>
                        ) : null}
                    </div>

                    <div className="only-image-mobile">
                        <img src={props.cardImage} className="image-image-mobile" alt="" />
                    </div>
                    <div className="movers-heading-mobile">{props.cardTitle}</div>
                    <div className="test-rate-mobile">
                        <div className="rating-div-mobile">
                            {props.rate !== 0 ? (
                                <div
                                    className={isDouglasElliman ? 'rating-button-mobile' : 'rating-button-mobilenoDoug'}
                                >
                                    <img style={{ marginRight: '5px' }} src={props.ratingIcon} alt="" />
                                    <span style={{ marginTop: '4px' }}> {props.rate}</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="best-moves-mobile">
                        <div className="rating-section-mobile">
                            {props.rate !== 0 ? <Rating name="read-only-mobile" value={props.rate} readOnly /> : null}
                        </div>

                        <div className="verified-review-mobile">{props.verifiedReview}</div>
                    </div>
                </div>
                <div className="last-icon-main-div-mobile">
                    <div className="last-icon-div-mobile">
                        <div className="icon-div-mobile">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {' '}
                                <img src={props.yearsIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                {' '}
                                <p className="years-licencsed-text-mobile">{props.yearText}yrs</p>
                            </div>
                        </div>
                        <div className="icon-div-mobile">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {' '}
                                <img src={props.licensedIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                {' '}
                                <p className="years-licencsed-text-mobile">{props.licensedText}</p>
                            </div>
                        </div>
                        <div className="icon-div-mobile">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {' '}
                                <img src={props.insuredIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                <p className="years-licencsed-text-mobile">{props.insuredText}</p>
                            </div>
                        </div>
                    </div>

                    <div className="last-div-mobile">
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px', marginTop: '12px' }} className="phoneAddress-mobile">
                            <img src={props.phoneIcon} alt="" />
                            <p className="bottom-card-detail-mobile">{props.phoneNumber}</p>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                            <img src={props.locationIcon} alt="" />
                            <p className="bottom-card-detail-mobile">{props.locationtext}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovingPlanCard;
