import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 31.801}
        height={props.height ? props.height : 30.4}
        viewBox="0 0 31.801 30.4"
    >
        <g data-name="Group 35701" fill={window.location.pathname.includes('ourvendors') ? '#009CBD' : '#100b28'}>
            <path
                data-name="Path 42889"
                d="M24.309 12.092a1.882 1.882 0 0 1-1.88 1.88h-1.033a1.882 1.882 0 0 1-1.88-1.88v-9.1a1.882 1.882 0 0 1 1.88-1.88h1.033a1.882 1.882 0 0 1 1.88 1.88Zm-1.71 16.528h-1.373l-.315-3.761.525-.86v-9.034h.951v9.034l.525.86ZM22.429.115h-1.033a2.876 2.876 0 0 0-2.873 2.873v9.1a2.876 2.876 0 0 0 1.921 2.708v8.92l-.548.9.418 4.994h3.2l.418-4.994-.548-.9v-8.92a2.876 2.876 0 0 0 1.921-2.708v-9.1A2.876 2.876 0 0 0 22.432.115"
            />
            <path
                data-name="Path 42890"
                d="M15.124 5.581a1.341 1.341 0 1 1-2.682 0V2.336a1.341 1.341 0 1 1 2.682 0ZM8.569 15.655c-.062 0-.124-.009-.187-.009H7.349c-.063 0-.125.005-.188.009V7.286H8.57Zm1.692 2.863v8.608a1.881 1.881 0 0 1-1.88 1.88H7.348a1.882 1.882 0 0 1-1.88-1.88v-8.608a1.882 1.882 0 0 1 1.88-1.88h1.033a1.881 1.881 0 0 1 1.88 1.88M.993 3.409a1.882 1.882 0 0 1 1.88-1.88h8.728a2.307 2.307 0 0 0-.152.8v3.247a2.3 2.3 0 0 0 .123.712H.993Zm12.791-3.41a2.314 2.314 0 0 0-1.526.583 2.828 2.828 0 0 0-.5-.047H2.875A2.876 2.876 0 0 0 .002 3.408v3.877h6.167v8.617a2.874 2.874 0 0 0-1.692 2.616v8.608a2.876 2.876 0 0 0 2.873 2.873h1.033a2.876 2.876 0 0 0 2.873-2.873v-8.608a2.875 2.875 0 0 0-1.692-2.616V7.286h2.195a2.715 2.715 0 0 0 .4-.037 2.33 2.33 0 0 0 3.961-1.668V2.336A2.337 2.337 0 0 0 13.787.002"
            />
        </g>
    </svg>
);

export default SvgComponent;
