export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const RESET_PASSWORD_DATA = 'RESET_PASSWORD_DATA';
export const RESET_PASSWORD_DATA_SUCCESS = 'RESET_PASSWORD_DATA_SUCCESS';
export const RESET_PASSWORD_DATA_ERROR = 'RESET_PASSWORD_DATA_ERROR';

// set password 
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const SET_PASSWORD_DATA = 'SET_PASSWORD_DATA';
export const SET_PASSWORD_DATA_SUCCESS = 'SET_PASSWORD_DATA_SUCCESS';
export const SET_PASSWORD_DATA_ERROR = 'SET_PASSWORD_DATA_ERROR';
