import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../atoms/Button';
import './dashboardCss/movingChecklistCompletedCard.scss';
import GreenTick from '../../Assets/images/DashBoard/greenTick.svg';

interface cardProps {
    onClick?: any;
    className?: string;
    cardTitle?: string;
    cardSubtitle?: string;
    cardImage?: string;
    imgClassName?: string;
    boxShadow?: any;
    tasks?: any;
    onEdit?: any;
}

const MovingChecklistCompletedCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #e4e6e8',
            },
        },
    }));

    const classes = useStyles();
    return (
        <div
            style={{
                opacity: '1',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} completedCard`}
        >
            {props.cardImage ? (
                <div className="completedImageDiv" style={{width: "20%"}}>
                <img
                    src={props.cardImage}
                    style={{
                        marginRight: '10px',
                    }}
                    alt=""
                    className={props.imgClassName ? props.imgClassName : 'cardImg'}
                    // width="100px"
                    // height="70px"
                />
            </div>
            ) : null }
            

           <div className="ContentButtonDiv" style={{width: props.cardImage ? "80%" : "100%"}}>
           <div className="contentDiv">
                <div className="completedCardTitle">{props.cardTitle}</div>
                <p className="completedCardPara">{props.cardSubtitle}</p>
                {props.tasks
                    ? props.tasks.map((task: any, i: number) => {
                          return (
                              <div key={i} className="tasksDiv">
                                  <img src={GreenTick} alt="" />
                                  <div className="taskTitle">{task}</div>
                              </div>
                          );
                      })
                    : null}
            </div>

            <div className="cancelButtonDiv">
                <Button className="cancelButton" onClick={props.onEdit}>Edit</Button>
            </div>
           </div>
        </div>
    );
};

export default MovingChecklistCompletedCard;
