// const serverAddress = 'https://conciergeapidemo.moveeasy.com';
const serverAddress =
    process.env.REACT_APP_MOVEEASY_ENV === 'development'
        ? 'https://conciergeapidemo.moveeasy.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'production'
        ? 'https://conciergeapi.moveeasy.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'preprod'
        ? 'https://conciergepreprod.moveeasy.com'
        : 'https://conciergeapidemo.moveeasy.com';
export const max_refresh_time = 60000;
export const powerkiosImageUrl =
    process.env.REACT_APP_MOVEEASY_ENV === 'development'
        ? 'https://apitest.powerkiosk.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'production'
        ? 'https://api.powerkiosk.com'
        : process.env.REACT_APP_MOVEEASY_ENV === 'preprod'
        ? 'https://conciergepreprod.moveeasy.com'
        : 'https://apitest.powerkiosk.com';
export default serverAddress;
export const customServiceIcon = 'https://d1ifezl1od3ddz.cloudfront.net/images/custom_service_default.svg'
export const trustedFormUrl = 'cert.trustedform.com'
