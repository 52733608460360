import React from 'react';
import './AwesomeSavings.scss';
// import nextMove from '../../Assets/images/NextMove/mike.png';
import Button from '../../components/atoms/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
type initialProps = {
    history?: any;
    commonAction: any;
    commonState: any;
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    paragraph?: any;
    onClick?: any;
    padding?: any;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    buttonMargin?: any;
    buttonMargin2?: any;
    buttonBorder?: any;
    rescheduleText?: any;
    rescheduleBackGroundColor?: string;
    rescheduleColor?: string;
    rescheduleShadow?: string;
    rescheduleHeight?: string;
    rescheduleWidth?: string;
    rescheduleMargin?: any;
    rescheduleMargin2?: any;
    rescheduleBorder?: any;
    cancelText?: any;
    cancelBackGroundColor?: string;
    cancelColor?: string;
    cancelShadow?: string;
    cancelHeight?: string;
    cancelWidth?: string;
    cancelMargin?: any;
    cancelMargin2?: any;
    cancelBorder?: any;
    onClickReschedule?: any;
    onClickCancel?: any;
    appointmentTime?: any;
    showLoader?: any;
    titleNew?: any;
};

type initialState = {};

class CallWith extends React.Component<initialProps, initialState> {
    state: initialState = {};

    render() {
        let moveData =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0];

        return (
            <div className="awesomeSavingsInnerCard">
                <div className="awesomeSavingsCardContent">
                    <div className="awesomeSavingsCardContentImage">
                        <img
                            src={moveData && moveData.assigned_concierge && moveData.assigned_concierge.headshot_logo}
                        />
                    </div>
                    <div className="awesomeSavingsCardContentInner">
                        <div
                            className="awesomeSavingsCardContentSchedule"
                            dangerouslySetInnerHTML={{
                                __html: this.props.title
                                    ? `Schedule A Call With ${this.props.title}`
                                    : this.props.titleNew,
                            }}
                        >
                            {/* Schedule A Call With{' '}
                            {moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name} */}
                        </div>
                        <div className="awesomeSavingsCardContentConies">
                            Our {moveData.concierge_reference_field} can find you savings in each of these services
                        </div>
                        {this.props.showLoader ? null : this.props.rescheduleText && this.props.appointmentTime ? (
                            <div className="appointment-time-div">
                                <div className="details"> Appointment Details </div>
                                <div className="details-content">
                                    {' '}
                                    Your Call is scheduled on {this.props.appointmentTime}{' '}
                                </div>
                            </div>
                        ) : null}
                        <div className="mike-button-wrapper">
                            {this.props.showLoader ? (
                                <div>
                                    <CircularProgress />
                                    <div> Working on it.Please Wait... </div>
                                </div>
                            ) : this.props.rescheduleText || this.props.cancelText ? (
                                <div>
                                    {this.props.rescheduleText ? (
                                        <Button
                                            backgroundColor={this.props.rescheduleBackGroundColor}
                                            boxShadow={this.props.rescheduleShadow}
                                            color={this.props.rescheduleColor}
                                            borderRadius="5px"
                                            fontSize="14px"
                                            fontWeight="600"
                                            height={this.props.rescheduleHeight ? this.props.rescheduleHeight : '40px'}
                                            width={this.props.rescheduleWidth ? this.props.rescheduleWidth : '150px'}
                                            margin={
                                                this.props.rescheduleMargin
                                                    ? this.props.rescheduleMargin
                                                    : '40px 20px 10px 0px'
                                            }
                                            onClick={this.props.onClickReschedule}
                                            border={this.props.rescheduleBorder ? this.props.rescheduleBorder : 'none'}
                                        >
                                            {this.props.rescheduleText}
                                        </Button>
                                    ) : null}

                                    {this.props.cancelText ? (
                                        <Button
                                            backgroundColor={this.props.cancelBackGroundColor}
                                            boxShadow={this.props.cancelShadow}
                                            color={this.props.cancelColor}
                                            borderRadius="5px"
                                            fontSize="14px"
                                            fontWeight="600"
                                            height={this.props.cancelHeight ? this.props.cancelHeight : '40px'}
                                            width={this.props.cancelWidth ? this.props.cancelWidth : '150px'}
                                            margin={
                                                this.props.cancelMargin ? this.props.cancelMargin : '40px 20px 10px 0px'
                                            }
                                            onClick={this.props.onClickCancel}
                                            border={this.props.cancelBorder ? this.props.cancelBorder : 'none'}
                                        >
                                            {this.props.cancelText}
                                        </Button>
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        backgroundColor={this.props.buttonBackGroundColor}
                                        boxShadow={this.props.buttonShadow}
                                        color={this.props.buttonColor}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={this.props.buttonHeight ? this.props.buttonHeight : '40px'}
                                        width={this.props.buttonWidth ? this.props.buttonWidth : '150px'}
                                        margin={
                                            this.props.buttonMargin ? this.props.buttonMargin : '40px 20px 10px 0px'
                                        }
                                        onClick={this.props.onClick}
                                        border={this.props.buttonBorder ? this.props.buttonBorder : 'none'}
                                    >
                                        {this.props.buttonText}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {/* {this.props.rescheduleText && this.props.appointmentTime ? (
                            <div className="appointment-time-div">
                                <div className="details"> Appointment Details </div>
                                <div className="details-content">
                                    {' '}
                                    Your Call is scheduled on {this.props.appointmentTime}{' '}
                                </div>
                            </div>
                        ) : null}
                        <div className="mike-button-wrapper">
                            {this.props.rescheduleText ? (
                                <div>
                                    <Button
                                        backgroundColor={this.props.rescheduleBackGroundColor}
                                        boxShadow={this.props.rescheduleShadow}
                                        color={this.props.rescheduleColor}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={this.props.rescheduleHeight ? this.props.rescheduleHeight : '40px'}
                                        width={this.props.rescheduleWidth ? this.props.rescheduleWidth : '150px'}
                                        margin={
                                            this.props.rescheduleMargin
                                                ? this.props.rescheduleMargin
                                                : '40px 20px 10px 0px'
                                        }
                                        onClick={this.props.onClickReschedule}
                                        border={this.props.rescheduleBorder ? this.props.rescheduleBorder : 'none'}
                                    >
                                        {this.props.rescheduleText}
                                    </Button>

                                    <Button
                                        backgroundColor={this.props.cancelBackGroundColor}
                                        boxShadow={this.props.cancelShadow}
                                        color={this.props.cancelColor}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={this.props.cancelHeight ? this.props.cancelHeight : '40px'}
                                        width={this.props.cancelWidth ? this.props.cancelWidth : '150px'}
                                        margin={
                                            this.props.cancelMargin ? this.props.cancelMargin : '40px 20px 10px 0px'
                                        }
                                        onClick={this.props.onClickCancel}
                                        border={this.props.cancelBorder ? this.props.cancelBorder : 'none'}
                                    >
                                        {this.props.cancelText}
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        backgroundColor={this.props.buttonBackGroundColor}
                                        boxShadow={this.props.buttonShadow}
                                        color={this.props.buttonColor}
                                        borderRadius="5px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        height={this.props.buttonHeight ? this.props.buttonHeight : '40px'}
                                        width={this.props.buttonWidth ? this.props.buttonWidth : '150px'}
                                        margin={
                                            this.props.buttonMargin ? this.props.buttonMargin : '40px 20px 10px 0px'
                                        }
                                        onClick={this.props.onClick}
                                        border={this.props.buttonBorder ? this.props.buttonBorder : 'none'}
                                    >
                                        {this.props.buttonText}
                                    </Button>
                                </div>
                            )}
                        </div> */}
                        {/* <div className="">
                            <Button
                            // onClick={() => this.calendlyHandle()}

                            // onClick={() => {
                            //     this.props.history.push('/dashboard/savings/summary');
                            // }}
                            >
                                Schedule A Call
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(CallWith);
