export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_ERROR = 'GET_PRODUCT_DETAILS_ERROR';

export const POST_ORDER_WARRANTY = 'POST_ORDER_WARRANTY';
export const POST_ORDER_WARRANTY_SUCCESS = 'POST_ORDER_WARRANTY_SUCCESS';
export const POST_ORDER_WARRANTY_ERROR = 'POST_ORDER_WARRANTY_ERROR';

export const GET_HOMEWARRANTY_DETAILS = 'GET_HOMEWARRANTY_DETAILS';
export const GET_HOMEWARRANTY_DETAILS_SUCCESS = 'GET_HOMEWARRANTY_DETAILS_SUCCESS';
export const GET_HOMEWARRANTY_DETAILS_ERROR = 'GET_HOMEWARRANTY_DETAILS_ERROR';

export const GET_HOMEWARRANTY_PROVIDER_DETAILS = 'GET_HOMEWARRANTY_PROVIDER_DETAILS';
export const GET_HOMEWARRANTY_PROVIDER_DETAILS_SUCCESS = 'GET_HOMEWARRANTY_PROVIDER_DETAILS_SUCCESS';
export const GET_HOMEWARRANTY_PROVIDER_DETAILS_ERROR = 'GET_HOMEWARRANTY_PROVIDER_DETAILS_ERROR';

export const UPDATE_HOMEWARRANTY_DETAILS = 'UPDATE_HOMEWARRANTY_DETAILS';
export const UPDATE_HOMEWARRANTY_DETAILS_SUCCESS = 'UPDATE_HOMEWARRANTY_DETAILS_SUCCESS';
export const UPDATE_HOMEWARRANTY_DETAILS_ERROR = 'UPDATE_HOMEWARRANTY_DETAILS_ERROR';

export const POST_HOMEWARRANTY_DETAILS = 'POST_HOMEWARRANTY_DETAILS';
export const POST_HOMEWARRANTY_DETAILS_SUCCESS = 'POST_HOMEWARRANTY_DETAILS_SUCCESS';
export const POST_HOMEWARRANTY_DETAILS_ERROR = 'POST_HOMEWARRANTY_DETAILS_ERROR';

export const GET_PLAN_TAX = 'GET_PLAN_TAX';
export const GET_PLAN_TAX_SUCCESS = 'GET_PLAN_TAX_SUCCESS';
export const GET_PLAN_TAX_ERROR = 'GET_PLAN_TAX_ERROR';
