import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 38.902}
            height={props.height ? props.height : 21}
            viewBox="0 0 38.902 31"
        >
            <defs>
                <style>
                    {// ".prefix__a{fill:#bebebe}.prefix__b{fill:#fff}.prefix__c{fill:#6b6c6f}"
                    `.internet_cableSvgprefix__a{fill:${
                        window.location.pathname.includes('internet') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.InternetSvgprefix__b{fill:#fff}.internet_cableSvgprefix__c{fill:${
                        window.location.pathname.includes('internet') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path
                className="internet_cableSvgprefix__a"
                d="M18.235 30.392h20.059V19.451H18.235M.608 19.451h17.627v10.941H.608zM6.686.608h25.529v14.588H6.686z"
            />
            <path
                className="InternetSvgprefix__b"
                d="M20.667 23.098h2.431v7.294h-2.431zM24.922 23.098h2.431v7.294h-2.431zM29.177 23.098h2.431v7.294h-2.431zM33.432 23.098h2.431v7.294h-2.431zM6.377 2.431h25.529v10.941H6.377z"
            />
            <path className="internet_cableSvgprefix__a" d="M.608 19.451h37.686v4.863H.608z" />
            <path
                className="internet_cableSvgprefix__c"
                d="M7.902 21.882h3.647v1.216H7.902zM7.902 27.353h3.647v1.216H7.902z"
            />
            <path
                className="internet_cableSvgprefix__c"
                d="M29.784 15.8h3.039V0H6.078v15.8h3.04v3.039H0V31h38.9V18.843h-9.116zM7.294 1.216h24.314v.608H7.294zm0 1.824h24.314v9.725H7.294zm0 11.549v-.609h24.314v.608zM28.569 15.8v3.039H10.333V15.8zM17.627 29.784H1.216v-4.255l16.412.018zm0-5.452l-16.411-.018v-4.255h16.411zm4.863 5.452h-1.215v-6.078h1.215zm4.255 0h-1.216v-6.078h1.216zm4.255 0h-1.216v-6.078H31zm4.255 0h-1.216v-6.078h1.216zm2.431-9.725v9.725h-1.215V22.49h-3.647v7.294h-.608V22.49h-3.647v7.294h-.608V22.49h-3.647v7.294h-.608V22.49h-3.647v7.294h-1.216v-9.725z"
            />
            <g>
                <path
                    className="internet_cableSvgprefix__c"
                    d="M23.82 6.133a.452.452 0 00-.015-.639A6.221 6.221 0 0019.408 3.8a6.407 6.407 0 00-4.389 1.753.452.452 0 10.625.653 5.507 5.507 0 013.773-1.5 5.317 5.317 0 013.764 1.442.452.452 0 00.639-.015zm0 0"
                />
                <path
                    className="internet_cableSvgprefix__c"
                    d="M22.362 7.524a.452.452 0 00-.012-.639 4.112 4.112 0 00-2.941-1.141 4.33 4.33 0 00-2.933 1.2.452.452 0 10.625.653 3.372 3.372 0 014.622-.061.452.452 0 00.639-.012zm0 0"
                />
                <path
                    className="internet_cableSvgprefix__c"
                    d="M20.982 8.852a.452.452 0 00-.014-.639 2.264 2.264 0 00-3.1.06.452.452 0 00.625.654 1.368 1.368 0 011.846-.06.452.452 0 00.643-.015zm0 0M19.413 9.508a1.1 1.1 0 10.779.322 1.1 1.1 0 00-.779-.322zm.141 1.242a.2.2 0 11.057-.14.2.2 0 01-.057.143zm0 0"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
