import React from 'react';
import { makeStyles } from '@material-ui/core';
import MovingTick from '../../Assets/images/Moving/MovingTick.svg';
import '../../styles/thankyouMoverCard.scss'


type iProps = {
className ? :string,
height ? :string,
width ? :string,
border ? :string,
icon ? :any,
imageHeight ? :any,
imageWidth ? :any,
title ? :string,
subTitle ?: string

};

const ThankyouMovingCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                // borderColor: '#333333 !important',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` :`${classes.root} thankyou-card-main`}
            style={{
                border: props.border ? props.border : '#E2E2E2 1px solid',
                width: props.width ? props.width : '500px',
                height: props.height ? props.height : '100px',
                borderRadius: '5px',
            
            }}
        >
            <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",margin:"24px 0"}}>
                <div className="thankyou-image-div">
                    <img src={props.icon} className="thankyou-image" width="100%" alt="" />
                </div>
                <div style={{display:"flex",flexDirection:"column",width:"50%"}}>
                    <div style={{color:"#989898"}} className="company">
                        {props.title}
                    </div>
                    <div className="company-name"
                    // style={{textAlign:"center"}}
                    >
                        {props.subTitle}
                    </div>

                </div>
               
                <div style={{width:"20%",marginLeft:"auto",marginRight:"auto"}}>
                    <img src={MovingTick} width="40px" height="40px" alt="" />
                </div>
              
           
            </div>
        </div>
    );
};

export default ThankyouMovingCard;
