import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import InsuranceHeader from '../../Assets/images/Yourbrokage.png';
// import InsuranceProfile from '../../Assets/images/profile2.png';
// import Button from '../../components/atoms/Button';
// import Avatar from '@material-ui/core/Avatar';
// import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import DropdownIcon from '../../Assets/images/chevron-down.svg';
// import Divider from '@material-ui/core/Divider';
import '../../components/Navbar/Navbar.css';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

// import ProfileImg from '../../Assets/images/profile2.png';

import { moveGet, header } from '../../store/common/actions';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { getMoveSelector } from '../../store/common/selectors';
// import Footer from './Footer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Popover } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreIconD from '../../Assets/images/DouglasImages/header/more';
import MoreIcon from '../../Assets/images/headerImages/more';
import { getIcon } from '../../components/_icon';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // marginBottom: '100px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: '15px',
        cursor: 'pointer',
    },
    tabRoot: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        marginTop: '10px',
        // marginBottom: '100px',
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

type initialProps = {
    history: any;
    commonState?: any;
};

const DashboardTabs = (props: initialProps) => {
    const dispatch = useDispatch();
    const classes: any = useStyles();
    const [value, setValue] = React.useState(0);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    // const [moveData, setMoveData] = React.useState({});
    // const [errorData, setErrorData] = React.useState(null);
    // const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    let headerOption: any = [];
    let moreOption: any = [];
    let AllOption: any = [];
    let totalValue: any = new Map();
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    // const [left, setleft] = React.useState(false);

    // const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    //     if (
    //         event.type === 'keydown' &&
    //         ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    //     ) {
    //         return;
    //     }

    //     setleft(open);
    // };

    let num: any = 7;
    if (window.screen.width >= 1920) {
        num = 8;
    }
    if (window.screen.width < 1025) {
        num = 5;
    }
    if (window.screen.width < 769) {
        num = 4;
    }
    useEffect(() => {
        let url = window.location.pathname;
        url = url.split('/')[2];
        if (headerOption && headerOption.length > 0 && totalValue && totalValue.size > 0 && url) {
            for (let [key, value1] of totalValue) {
                if (url === value1) {
                    setValue(key);
                }
            }
        } else {
            setValue(-1);
        }
    }, [headerOption]);
    useEffect(() => {
        dispatch(moveGet());
    }, []);

    useEffect(() => {
        let moveCheck = localStorage.getItem('moveId');
        if (slug) {
            const isHomeOwner = localStorage.getItem('isHomeOwner');
            dispatch(header({ partner_slug: slug.slug, fetchMoveService: isHomeOwner ? 1 : 0, moveId: moveCheck }));
        }
    }, []);
    // useEffect(() => {
    //     const data = move && move[0];
    //     setMoveData(data);
    // }, [move]);
    // useEffect(() => {
    //     setErrorData(error);
    // }, [error]);

    const handleMenuItemClick = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: false,
                    more_drop_down_list: true,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
        }
    };

    const handleAnalytics1 = (item: any) => {
        setValue(item.id);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                `Navigation Category Clicked`,
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_key: agent_key,
                    agent_name: agent_name,
                    main_navigation_bar: true,
                    more_drop_down_list: false,
                    category_name: item.name,
                },
                { user_id: move_id },
            );
        }
    };

    // const handleClick = (event: any) => {
    //     event.stopPropagation();
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClickMore = (event: any) => {
        event.stopPropagation();
        setAnchorEl1(event.currentTarget);
        // setValue(8);
    };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleCloseMore = () => {
        setAnchorEl1(null);
    };
    const handleChange = (newValue: any) => {
        if (parseInt(newValue.currentTarget.id.split('-')[2]) !== num) {
            setValue(parseInt(newValue.currentTarget.id.split('-')[2]));
        }
        // setValue(newValue.currentTarget.innerText)
        // return (<Link to='/dashboard/mortgage'></Link>);
    };

    // const logoutClicked = () => {
    //     localStorage.clear();
    //     props.history.push('/signUp');
    // };

    // const list = () => (
    //     <div
    //         className={clsx(classes.list)}
    //         role="presentation"
    //         onClick={toggleDrawer(false)}
    //         onKeyDown={toggleDrawer(false)}
    //     >
    //         <Link to={`/dashboard`}>
    //             <ListItem
    //                 key={0}
    //                 style={{
    //                     padding: '15px 16px',
    //                 }}
    //                 className={window.location.pathname === '/dashboard' ? 'mobileListActive' : 'mobileList'}
    //             >
    //                 <ListItemIcon>
    //                     <DashboardIcon />
    //                 </ListItemIcon>
    //                 <ListItemText
    //                     style={{
    //                         fontSize: '14px',
    //                     }}
    //                     primary="Dashboard"
    //                 />
    //             </ListItem>
    //         </Link>
    //         <Divider />
    //         <List>
    //             {AllOption &&
    //                 AllOption.length > 0 &&
    //                 AllOption.map((item: any) => {
    //                     let icon = getIcon(item);
    //                     return (
    //                         <Link
    //                             to={{
    //                                 pathname: `/dashboard/${item.route}`,
    //                                 state:
    //                                     props.commonState &&
    //                                     props.commonState.header &&
    //                                     props.commonState.header.length > 0
    //                                         ? props.commonState.header
    //                                         : '',
    //                             }}
    //                         >
    //                             <ListItem
    //                                 className={
    //                                     value === item.id && window.location.pathname !== '/dashboard'
    //                                         ? 'mobileListActive'
    //                                         : 'mobileList'
    //                                 }
    //                                 key={item.id}
    //                                 onClick={() => handleMenuItemClick(item)}
    //                             >
    //                                 <ListItemIcon>
    //                                     {/* <img src={icon}  /> */}
    //                                     {icon}
    //                                 </ListItemIcon>
    //                                 <div
    //                                     style={{
    //                                         fontSize: '14px',
    //                                         color: `${
    //                                             window.location.pathname === `/dashboard/${item.route}`
    //                                                 ? `#333333`
    //                                                 : '#6B6C6F'
    //                                         }`,
    //                                     }}
    //                                 >
    //                                     {item.name}
    //                                 </div>
    //                             </ListItem>
    //                         </Link>
    //                     );
    //                 })}
    //         </List>
    //     </div>
    // );

    // const handleChangeRoute = () => {
    //     props.history.push('/dashboard');
    // };

    if (props.commonState && props.commonState.header && props.commonState.header.length > 0) {
        let array123 = props.commonState.header.filter((item: any) => item.slug !== 'exclusive_deals');
        array123.map((item: any, index: number) => {
            let slug =
                item.react_url_route && item.react_url_route.split('/').length > 1
                    ? item.react_url_route.split('/')[2]
                    : item.slug;
            // if (item.slug === 'home-insurance') {
            //     slug = 'home-insurance';
            // }
            if (index < num) {
                headerOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index,
                });
                totalValue.set(index, slug);
            } else {
                moreOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index + 1,
                });
                AllOption.push({
                    name: item.name,
                    icon: item.image,
                    route: slug,
                    is_default: item.is_default,
                    id: index + 1,
                });
                totalValue.set(index + 1, slug);
            }
        });
        moreOption.push({
            name: 'Checklist',
            icon: '',
            route: 'checklist',
            id: props.commonState.header.length + 1,
        });
        // moreOption.push({
        //     name: 'Reserve storage facility',
        //     icon: '',
        //     route: 'storage',
        //     id: props.commonState.header.length + 1,
        // });
        // moreOption.push({
        //     name: 'Car Shipping',
        //     icon: '',
        //     route: 'autoease',
        //     id: props.commonState.header.length + 1,
        // });
        // AllOption.push({
        //     name: 'Checklist',
        //     icon: '',
        //     route: 'checklist',
        //     id: props.commonState.header.length + 1,
        // });
        // AllOption.push({
        //     name: 'Reserve storage facility',
        //     icon: '',
        //     route: 'storage',
        //     id: props.commonState.header.length + 1,
        // });
        // AllOption.push({
        //     name: 'Car Shipping',
        //     icon: '',
        //     route: 'autoease',
        //     id: props.commonState.header.length + 1,
        // });
        totalValue.set(props.commonState.header.length + 1, 'checklist');
        // totalValue.set(props.commonState.header.length + 1, 'storage');
        // totalValue.set(props.commonState.header.length + 1, 'autoease');
    }
    let SkeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <div>
            {/* <div className={`${classes.root} MobileScreenHeader`}>
                <AppBar position="fixed">
                    <Toolbar>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="menu"
                                >
                                    <MenuIcon onClick={toggleDrawer(true)} />
                                    <React.Fragment>
                                        <Drawer anchor="left" open={left} onClose={toggleDrawer(false)}>
                                            {list()}
                                        </Drawer>
                                    </React.Fragment>
                                </IconButton>
                            </div>
                            <div>
                                <img src={slug && slug.logo} width="80px" />
                            </div>
                            <div>
                                <Avatar alt="Remy Sharp" src={InsuranceProfile} />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>{' '} */}
            <div className={`${classes.root} bigScreenHeader`}>
                {/* <AppBar position="fixed" className="firstBar" style={{ zIndex: 10000 }}>
                    <div className="InnerNavDiv">
                        <Toolbar>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                    >
                                        <img src={slug && slug.logo} width="130px" />
                                    </IconButton>
                                    {window.location.pathname === '/' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ? null : (
                                        <Typography
                                            variant="h6"
                                            className={classes.title}
                                            noWrap
                                            onClick={handleChangeRoute}
                                        >
                                            Dashboard
                                        </Typography>
                                    )}
                                    {window.location.pathname === '/' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ? null : (
                                        <div className="service-main-div">
                                            Services
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                                className={classes.title}
                                            >
                                                <img className="dropdownIcon" src={DropdownIcon} />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}
                                            >
                                                {options.map(option => (
                                                    <MenuItem key={option.value} onClick={handleClose}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>
                                    )}
                                </div>
                                <div className="lastHeaderRow">
                                    <Avatar alt="Remy Sharp" src={InsuranceProfile} />
                                    <span className="profileText">
                                        {slug && slug.skill_name ? slug.skill_name : 'Kathie Allen'}
                                    </span>
                                    {window.location.pathname === '/' ||
                                    window.location.pathname === '/signUp' ||
                                    window.location.pathname === '/signUp/' ||
                                    window.location.pathname === '/signUp/next' ? null : (
                                        <Button
                                            className="btnICbtn"
                                            height="40px"
                                            // width="125px"
                                            margin="0px 0px 0px 10px"
                                            width="100px"
                                            backgroundColor={
                                                slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'
                                            }
                                            // onClick={() => this.tabChangeHandler('addressCard')}
                                            onClick={() => logoutClicked()}
                                        >
                                            Logout
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Toolbar>
                    </div>
                </AppBar> */}
                <AppBar position="fixed" style={{ marginTop: '60px' }} className="secondBar">
                    <div className="links">
                        <div className={`${classes.tabRoot} Dashboard`} style={{ marginTop: '20px' }}>
                            <div>
                                <Tabs
                                    value={value >= 0 && value <= headerOption?.length ? value : false}
                                    onChange={(e: any) => {
                                        handleChange(e);
                                    }}
                                    className="headerTab"
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: {
                                            background: slug && slug.primary_color ? slug.primary_color : '#273E59',
                                        },
                                    }}
                                    // scrollButtons="auto"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    // aria-label="simple tabs example"
                                    aria-label="icon label tabs example"
                                    // className={classes.customTabRoot}
                                >
                                    {headerOption && headerOption.length > 0
                                        ? headerOption.map((item: any, i: number) => {
                                              let icon = getIcon(isDouglasElliman, item);

                                              return (
                                                  <Tab
                                                      key={i}
                                                      onClick={() => handleAnalytics1(item)}
                                                      component={Link}
                                                      to={{
                                                          pathname: `/dashboard/${item.route}`,
                                                          state:
                                                              props.commonState &&
                                                              props.commonState.header &&
                                                              props.commonState.header.length > 0
                                                                  ? props.commonState.header
                                                                  : '',
                                                      }}
                                                      label={item.name}
                                                      icon={
                                                          //   <img src={icon} className="tabIcons" />
                                                          icon
                                                      }
                                                      {...a11yProps(item.id)}
                                                  />
                                              );
                                          })
                                        : num === 8
                                        ? SkeletonArray.map((_, i) => {
                                              return (
                                                  <Tab
                                                      key={i}
                                                      label={<Skeleton height={10} width={80} />}
                                                      icon={<Skeleton height={30} width={40} />}
                                                  />
                                              );
                                          })
                                        : num === 5
                                        ? SkeletonArray.map((index, i) => {
                                              if (index <= 6) {
                                                  return (
                                                      <Tab
                                                          key={i}
                                                          label={<Skeleton height={10} width={80} />}
                                                          icon={<Skeleton height={30} width={40} />}
                                                      />
                                                  );
                                              }
                                          })
                                        : num === 4
                                        ? SkeletonArray.map((index, i) => {
                                              if (index <= 5) {
                                                  return (
                                                      <Tab
                                                          key={i}
                                                          label={<Skeleton height={10} width={80} />}
                                                          icon={<Skeleton height={30} width={40} />}
                                                      />
                                                  );
                                              }
                                          })
                                        : SkeletonArray.map((index, i) => {
                                              if (index <= 8) {
                                                  return (
                                                      <Tab
                                                          key={i}
                                                          label={<Skeleton height={10} width={80} />}
                                                          icon={<Skeleton height={30} width={40} />}
                                                      />
                                                  );
                                              }
                                          })}

                                    {headerOption && headerOption.length > 0 && moreOption && moreOption.length > 0 && (
                                        <Tab
                                            label={
                                                open1 ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        More
                                                        <ExpandLessIcon
                                                            style={{
                                                                fill: '#6b6c6f',
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        More
                                                        <ExpandMoreIcon
                                                            height="5px"
                                                            width="8px"
                                                            style={{
                                                                fill: '#6b6c6f',
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            icon={
                                                isDouglasElliman ? (
                                                    <MoreIconD className={`moreIcon ${open1 || value > num && 'activeIcon'}`} />
                                                ) : (
                                                    <MoreIcon className={`moreIcon ${open1 || value > num && 'activeIcon'}`} />
                                                )
                                            }
                                            {...a11yProps(num)}
                                            // onMouseOver={num >= 7 ? handleClickMore : () => {}}
                                            onClick={handleClickMore}
                                        />
                                    )}
                                </Tabs>
                                <Popover
                                    open={open1}
                                    anchorEl={anchorEl1}
                                    onClick={handleCloseMore}
                                    className="headerPopover"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {moreOption.map((item: any, i: number) => {
                                        let icon = getIcon(isDouglasElliman, item);
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => handleMenuItemClick(item)}
                                                // onClick={() => handleMenuItemClick(item.route)}
                                                className="HeaderDropdownValues"
                                            >
                                                <Link to={`/dashboard/${item.route}`}>
                                                    <div
                                                        style={{
                                                            width: '30%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        {/* <img className="tabIcons" src={icon} /> */}
                                                        {icon}
                                                    </div>
                                                    <MenuItem>{item.name}</MenuItem>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </Popover>
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </AppBar>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, null)(DashboardTabs);
