import React, { Component } from 'react';
import './HomeWarantyMain.scss';
// import WarantyBooked from '../../components/HomeWaranty/HomeWarantyBooked';
import Button from '../../components/atoms/Button';
import callIcon from '../../Assets/images/InternetAndCable/call_icon.svg';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';
import arrowDown from '../../Assets/images/InternetAndCable/arrow_down.svg';
import insuranceimg from '../../Assets/images/insurance-img.png';
import Footer from './Footer';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
const moment = require('moment');

type initialProps = {
    history?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
};

type initialState = {
    homeWarrantyData: any;
    homeWarrantProviderDetails: any;
    isShowContent: boolean;
    isShowHideText: string;
};

export class HomeWarantyHome extends Component<initialProps, initialState> {
    state: initialState = { 
        homeWarrantyData: null,
        homeWarrantProviderDetails: null,
        isShowContent: false,
        isShowHideText: 'More Details',
    };

    componentDidMount() {
        if (
            this.props &&
            this.props.homeWarrantyState &&
            this.props.homeWarrantyState.getHomeWarrantyDetails &&
            this.props.homeWarrantyState.getHomeWarrantyDetails.submission_date != null &&
            this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number != null
        ) {
            // this.props.homeWarrantyAction.getHomeWarrantyDetails();
            this.setState({
                homeWarrantyData: this.props.homeWarrantyState.getHomeWarrantyDetails,
            });
        } else {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
        }
        let move_id: any = localStorage.getItem('moveId');
        if(move_id) {
            this.props.homeWarrantyAction.getHomeWarrantProviderDetails(move_id);
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        let prevW = prevProps.homeWarrantyState;
        let curW = this.props.homeWarrantyState;
        if (prevW.getHomeWarrantyDetails !== curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails) {
            this.setState({
                homeWarrantyData: curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails,
            });
        }
        if (prevW.getHomeWarrantProviderDetails !== curW.getHomeWarrantProviderDetails && curW.getHomeWarrantProviderDetails) {
            this.setState({
                homeWarrantProviderDetails: curW.getHomeWarrantProviderDetails[0] && curW.getHomeWarrantProviderDetails[0],
            });
        }
    }

    onClickKnowMore = () => {
        this.props.history.push({
            pathname: '/dashboard/home-warranty',
            state: {
                from: 'home',
            },
        });
    };
    onShowHideView = (isShow: boolean) => {
        let tempState: any = {
            isShowContent: isShow,
        }
        if (isShow) tempState.isShowHideText = 'Show Less'
        else tempState.isShowHideText = 'More Details'
        this.setState(tempState)
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        const breadList = [
            {
                name: 'Home Warranty',
                url: ''
            }
        ]
        
        return (
            <div>
                <div className="greybg">
                    <div className="main-head">
                        <h2 className="heading">Home Warranty</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                    </div>
                    <div className='summaryHeader summaryHeaderHome'>
                        <h3 className='summaryHomeWarrenty'>Subscription Details For Home Warranty</h3>
                        <p>Thank you for selecting security plans from our concierge team, if you need any help please reach out to our team in setting up a call</p>
                    </div>
                </div>
                <div className='summaryCards summarybox summarybox2 summaryboxHome'>
                    <div className="summarybox_head">
                        <h5 className='summaryHeader'>Your selected warranty provider</h5>
                    </div>
                    <div className="summarybox_body summarybox_body_homeWarranty">
                        <div className='summaryContent'>
                            <div className='summaryContent_l'>
                                <div className="img_wrapper">
                                    <img src={this.state.homeWarrantProviderDetails? this.state.homeWarrantProviderDetails.provider_logo : ""} alt="plan detail logo" />
                                    <p>{this.state.homeWarrantProviderDetails? this.state.homeWarrantProviderDetails.name : ""}</p>
                                </div>
                            </div>
                        </div>
                        <div className="call_bar hw_summary">
                            <p>Want to change your subscription? Call</p>
                            <span>
                                Customer Service
                                <a href={`tel:(800)-735-4663`}>
                                    <span className="call_icon">
                                        <img src={callIcon} alt="phone" />
                                    </span>
                                    (800)-735-4663</a>
                            </span>
                        </div>
                    </div>
                    <div className={`summaryfooter ${this.state.isShowContent ? 'showcontent' : ''}`}>
                        <div className="summary_hidden_content">
                            <div className="content_row">
                            {this.state.homeWarrantyData && this.state.homeWarrantyData.plan_name? 
                                (<div className="content_col33">
                                        <p className="lab_val">Plan Name</p>
                                        <p><b>
                                            {this.state.homeWarrantyData.plan_name}</b></p>
                                    </div>)
                            : ''}
                            {this.state.homeWarrantyData && this.state.homeWarrantyData.submission_date?
                                (<div className="content_col33">
                                    <p className="lab_val">Date of order submission</p>
                                    <p><b>
                                        {moment(this.state.homeWarrantyData.submission_date).format('MMMM DD, YYYY')}</b></p>
                                </div>)
                            : ''}
                            {this.state.homeWarrantyData && this.state.homeWarrantyData.policy_number?
                                (<div className="content_col33">
                                    <p className="lab_val">Policy Number</p>
                                    <p><b>#{this.state.homeWarrantyData.policy_number}</b></p>
                                </div>)
                            : ''}
                            {this.state.homeWarrantyData && this.state.homeWarrantyData.effective_date?
                                (<div className="content_col33">
                                    <p className="lab_val">Effective Date</p>
                                    <p><b>{moment(this.state.homeWarrantyData.effective_date).format('MMMM DD, YYYY')}</b></p>
                                </div>)
                            : ''}
                            {this.state.homeWarrantyData && this.state.homeWarrantyData.expiry_date?
                                (<div className="content_col33">
                                    <p className="lab_val">Expiration Date</p>
                                    <p><b>{moment(this.state.homeWarrantyData.expiry_date).format('MMMM DD, YYYY')}</b></p>
                                </div>)
                            : ''}
                            </div>
                        </div>
                        <button className="readmorebtn"
                                onClick={() =>
                                    this.onShowHideView(!this.state.isShowContent)
                                }
                        >
                            {this.state.isShowHideText}
                            <img src={arrowDown} alt="arrow icon" />
                        </button>
                    </div>
                </div>
                <div className="utility_box">
                    <div className="utility_boxl">
                        <div>
                            <img src={insuranceimg} alt="" />
                        </div>
                    </div>
                    <div className="utility_boxr">
                        <div>
                            <h3 className="utility_title">Get Insurance Quotes In Minutes</h3>
                            <p>Get multiple quotes in minutes! Advertising Disclosure</p>
                            <Button className="gotoutility_btn"
                            color={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : window.location.pathname.includes('landing')
                                    ? '#30AB86'
                                    : '#273e59'
                            }
                            backgroundColor={
                                isDouglasElliman
                                    ? '#ffffff'
                                    : slug && slug.primary_font_color
                                    ? slug.primary_font_color
                                    : window.location.pathname.includes('landing')
                                    ? '#ffffff'
                                    : '#ffffff'
                            }
                            boxShadow={
                            isDouglasElliman
                            ? 'none'
                            : `0 0 3px 0 ${
                                    slug && slug.primary_color
                                        ? slug.primary_color
                                        : window.location.pathname.includes('landing')
                                        ? '#30AB86'
                                        : '#273E59'
                                }`
                            }
                            border={
                            isDouglasElliman
                            ? '1px solid #100B28'
                            : `1px solid ${
                                    slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273E59'
                                }`
                            }
                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                            margin="0 5px 0 0"
                            onClick={() => {
                                this.props.history.push('/dashboard/home-insurance');
                            }}
                            >Explore Insurance</Button>
                        </div>
                    </div>
                </div>
                <div className="bittom_btn_div havetwobtns">
                    {/* <button className="borderbtn"
                        onClick={this.onClickKnowMore}
                    >Explore New Plans</button> */}
                    <Button
                    boxShadow={
                        isDouglasElliman
                            ? 'none'
                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                    }
                    backgroundColor={
                        isDouglasElliman
                            ? '#100B28'
                            : slug && slug.primary_color
                            ? slug.primary_color
                            : window.location.pathname.includes('landing')
                            ? '#30AB86'
                            : '#273e59'
                    }
                        onClick={() => {
                            this.props.history.push('/dashboard');
                        }}
                    >
                        Back To Dashboard
                    </Button>
                </div>
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarantyHome);
