import React from 'react';
import './AwesomeSavings.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Button from '../../components/atoms/Button';
import SavingMoney from '../../Assets/images/PreMove/SavingMoney.gif';
// import nextMove from '../../Assets/images/NextMove/profileImage.png';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import Modal from '../../components/atoms/Modal';
// import CallWithMike from '../../components/dashboardComponents/CallWithMikeCard';
import CallWith from './callwith';
import Button from '../../components/atoms/Button';
import moveEasy from '../../Assets/images/move_easy.svg';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { scheduleSegment, buttonClicked } from '../../utils/utlis';
type initialProps = {
    history?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    activeStep: number;
    fullName: string;
    toAddress: string;
    toPin: string;
    fromAddress: string;
    fromPin: string;
    movingDate: any;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    moveDataArrived: boolean;
    cancelOpen: boolean;
    reasonError: string;
    reason: string;
    editHash: string;
    showLoader: boolean;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info: any;
    custom_schedule_call_info_enabled: boolean;
    moveId: string;
    calendlyOpen: boolean;
    hashcode: string;
    customBrokerageName: string;
    customBrandName: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
    concierge_ref: any;
};

class AwesomeSavings extends React.Component<initialProps, initialState> {
    state: initialState = {
        activeStep: 6,
        fullName: '',
        firstName: '',
        toAddress: '',
        toPin: '',
        fromAddress: '',
        fromPin: '',
        movingDate: '',
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        moveDataArrived: true,
        cancelOpen: false,
        reasonError: '',
        reason: '',
        editHash: '',
        showLoader: true,
        formOpen: false,
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        custom_schedule_call_info: '',
        custom_schedule_call_info_enabled: false,
        moveId: '',
        calendlyOpen: false,
        hashcode: '',
        customBrokerageName: '',
        customBrandName: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
        concierge_ref: '',
    };
    componentDidMount() {
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }

            if (slug && slug.display_name) {
                let brokerage: any;

                brokerage = slug.display_name;
                let brokerage_key: any = slug.brokerage_key;
                let agent_key: any = slug.realtor_key;

                let agent_name: any = slug.contact_name;
                let move_id: any = localStorage.getItem('moveKey');

                let windownew: any = window;
                let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
                analytics.track(
                    'Savings Calculator Completed',
                    {
                        brokerage_name: brokerage,
                        brokerage_key: brokerage_key,
                        agent_name: agent_name,
                        agent_key: agent_key,
                        page: 'Savings Completed',
                    },
                    { user_id: move_id },
                );
            }
        }
    }

    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Button Clicked',
            'Awesome Saving',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Savings',
            'Schedule A Call',
            true,
            `Schedule A Call with ${this.props.commonState &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].assigned_concierge &&
                this.props.commonState.move[0].assigned_concierge.full_name}`,
            false,
            ' ',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Savings Page',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };
    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            JSON.stringify(nextProps.commonState.appointmentDetails) !==
                JSON.stringify(currentState.appointment_details) &&
            nextProps.commonState.appointmentDetailsArrived
        ) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            currentState.editHash =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.edit_hash;

            currentState.showLoader = false;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        }
        if (nextProps && nextProps.commonState && nextProps.commonState.move[0] && currentState.moveDataArrived) {
            currentState.moveDataArrived = false;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.fullName =
                nextProps.commonState.move[0].user_ref &&
                `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            currentState.toAddress =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.lookup_name;
            currentState.toPin =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.zip_code;
            currentState.fromAddress =
                nextProps.commonState.move[0].source && nextProps.commonState.move[0].source.lookup_name;
            currentState.fromPin =
                nextProps.commonState.move[0].source && nextProps.commonState.move[0].source.zip_code;
            currentState.movingDate = nextProps.commonState.move[0].moving_date;

            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.editHash =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.edit_hash;
            currentState.custom_schedule_call_info =
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.custom_schedule_call_info;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.customBrokerageName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.display_name;
            currentState.customBrandName =
                nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.brand;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.concierge_ref =
                nextProps.commonState.move[0] && nextProps.commonState.move[0].concierge_reference_field;
            currentState.showLoader = false;

            // let obj = {
            //     image:
            //         nextProps.commonState.move[0] &&
            //         nextProps.commonState.move[0].assigned_concierge &&
            //         nextProps.commonState.move[0].assigned_concierge.headshot_logo,
            //     title:
            //         nextProps.commonState.move[0] &&
            //         nextProps.commonState.move[0].assigned_concierge &&
            //         nextProps.commonState.move[0].assigned_concierge.full_name,
            //     subTitle: 'You’re dedicated concierge who will be contacting you shortly',
            //     // taskProgressValue: 5,
            //     // taskCompletionTime: "3 min",
            //     btnCancel: 'Cancel',
            //     btnCompleteTask: 'Reschedule',
            // };

            // currentState.pendingTasksData = [obj].concat(currentState.pendingTasksData);
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
            currentState.isSubmitClicked = false;
        }

        return currentState;
    }
    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            this.state.phone.length >= 12
        ) {
            //   this.setState({
            //      formOpen:false
            //   })
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            let slug: any = localStorage.getItem('slug');
            if (slug) {
                slug = JSON.parse(slug);
            }
            if (slug && slug.display_name) {
                let brokerage: any;

                brokerage = slug.display_name;
                let brokerage_key: any = slug.brokerage_key;
                let agent_key: any = slug.realtor_key;

                let agent_name: any = slug.contact_name;
                let move_id: any = localStorage.getItem('moveKey');

                buttonClicked(
                    'Button Clicked',
                    'Energy Savings',
                    brokerage,
                    brokerage_key,
                    agent_name,
                    agent_key,
                    'Energy Savings',
                    'Submit',
                    false,
                    '',
                    false,
                    '',
                    '',
                    false,
                    '',
                    false,
                    '',
                    '',
                    move_id,
                );
            }
        }
    };

    nylasModalClosed = () => {
        // console.log("clicked")
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        // if(this.state.cancelClicked){
        //     let countNew=0
        //     setInterval(() => {
        //         if (countNew < 10) {
        //             this.props.commonAction.appointmentGet(payload)
        //                 countNew=countNew + 1
        //         }
        //     }, 5000);
        // }else{
        this.props.commonAction.appointmentGet(payload);
        this.setState({
            showLoader: true,
        });
        // }
    };

    handleGoBack = () => {
        this.props.history.push('/dashboardhomeowner');
    };
    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
    };

    cancelModalOpened = () => {
        this.setState({
            cancelOpen: true,
        });
    };

    cancelModalClosed = () => {
        this.setState({
            cancelOpen: false,
        });
    };

    cancelButtonClicked = () => {
        if (!this.state.reason) {
            this.setState({
                reasonError: 'Please provide a reason',
            });
        } else {
            this.props.commonAction.cancelAppointment({
                hash: this.state.editHash,
                reason: this.state.reason,
            });

            this.setState({
                cancelOpen: false,
            });

            let hash_code =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
            };
            this.setState({
                showLoader: true,
            });
            let countNew = 0;
            setInterval(() => {
                if (countNew < 6) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 10000);

            // setTimeout(()=>{
            //     this.props.commonAction.appointmentGet(payload)
            // },20000)
        }
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            buttonClicked(
                'Button Clicked',
                'Energy Savings',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                'Energy Savings',
                'Cancel',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                false,
                '',
                '',
                move_id,
            );
        }
    };

    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let moveData =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0];
        return (
            <div className="awesomeSavingsPage">
                <div className="progressBarFinalSavings">
                    <Box>
                        <LinearProgress variant="determinate" value={((this.state.activeStep + 1) / 7) * 100} />
                    </Box>
                </div>
                <div className="awesomeSavingsWhiteBackground">
                    <div className="awesomeSavingsInnerContentFinal">
                        <div
                            className=""
                            onClick={() => {
                                this.props.history.push('/dashboardhomeowner');
                                // window.location.reload();
                            }}
                        >
                            <ArrowBackIcon />
                        </div>

                        <div className="awesomeSavingsInnerData">
                            <div className="awesomeSavingsInnerImage">
                                <img src={SavingMoney} alt="" />
                            </div>
                            <div className="awesomeSavingsInnerHead">Awesome, you can save up to</div>

                            <div className="awesomeSavingsInnerDiscount">
                                <span>$</span>300
                            </div>
                            <div className="awesomeSavingsInnerSubHead">
                                Consult your {this.state.concierge_ref}, they can qualify you for even more savings
                            </div>
                            <div className="awesomeSavingsInnerNext">What’s Next?</div>
                            {this.state.custom_schedule_call_info_enabled ? (
                                <CallWith
                                    icon={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.headshot_logo
                                    }
                                    // title={
                                    //     moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                    // }
                                    titleNew={`${
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.short_description
                                            ? moveData.assigned_concierge.short_description
                                            : ''
                                    } ${
                                        moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                            ? moveData.assigned_concierge.full_name
                                            : ''
                                    }`}
                                    subTitle={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.long_description
                                            ? moveData.assigned_concierge.long_description
                                            : ''
                                    }
                                    // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    buttonText="Contact Us"
                                    buttonColor="#273E59"
                                    buttonBackGroundColor="#fff"
                                    buttonShadow="0 1px 1px #161D251A"
                                    buttonBorder="1px solid #273E59"
                                    // paragraph={
                                    //     <div>
                                    //         {(moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description) ||
                                    //         (moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.long_description) ? (
                                    //             <div style={{ fontWeight: 'bold' }}>About</div>
                                    //         ) : null}
                                    //         <div>
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description
                                    //                 ? moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.short_description
                                    //                 : moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.long_description}{' '}
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description ? (
                                    //                 <a
                                    //                     style={{
                                    //                         color: '#0066F5',
                                    //                         textDecoration: 'underline',
                                    //                         cursor: 'pointer',
                                    //                     }}
                                    //                     onClick={() => {
                                    //                         this.setState({ moreClicked: true });
                                    //                     }}
                                    //                 >
                                    //                     Learn More
                                    //                 </a>
                                    //             ) : null}
                                    //         </div>
                                    //     </div>
                                    // }
                                    onClick={() => this.formHandler()}
                                />
                            ) : (
                                <CallWith
                                    showLoader={this.state.showLoader}
                                    appointmentTime={`${new Date(this.state.appointmentTime)
                                        .toString()
                                        .substring(0, 15)},${new Date(this.state.appointmentTime).toLocaleTimeString(
                                        'en-US',
                                    )} `}
                                    icon={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.headshot_logo
                                    }
                                    title={
                                        moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                    }
                                    subTitle={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.long_description
                                            ? moveData.assigned_concierge.long_description
                                            : "I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    }
                                    // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    buttonText="Schedule A Call"
                                    buttonColor="#273E59"
                                    buttonBackGroundColor="#fff"
                                    buttonShadow="0 1px 1px #161D251A"
                                    buttonBorder="1px solid #273E59"
                                    rescheduleText={this.state.reschedule_link ? 'Reschedule' : ''}
                                    rescheduleBackGroundColor="#273E59"
                                    rescheduleColor="#fff"
                                    rescheduleShadow="0 1px 1px #161D251A"
                                    rescheduleBorder="1px solid #273E59"
                                    cancelText={this.state.editHash ? 'Cancel' : ''}
                                    cancelColor="#273E59"
                                    cancelBackGroundColor="#fff"
                                    cancelShadow="0 1px 1px #161D251A"
                                    cancelBorder="1px solid #273E59"
                                    // paragraph={
                                    //     <div>
                                    //         {(moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description) ||
                                    //         (moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.long_description) ? (
                                    //             <div style={{ fontWeight: 'bold' }}>About</div>
                                    //         ) : null}
                                    //         <div>
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description
                                    //                 ? moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.short_description
                                    //                 : moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.long_description}{' '}
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description ? (
                                    //                 <a
                                    //                     style={{
                                    //                         color: '#0066F5',
                                    //                         textDecoration: 'underline',
                                    //                         cursor: 'pointer',
                                    //                     }}
                                    //                     onClick={() => {
                                    //                         this.setState({ moreClicked: true });
                                    //                     }}
                                    //                 >
                                    //                     Learn More
                                    //                 </a>
                                    //             ) : null}
                                    //         </div>
                                    //     </div>
                                    // }
                                    onClick={() => this.calendlyHandle()}
                                    onClickReschedule={() => {
                                        this.calendlyHandleReschedule();
                                    }}
                                    onClickCancel={() => {
                                        this.cancelModalOpened();
                                    }}
                                />
                            )}

                            <div className="awesomeSavingsInnerNotInterested" onClick={this.handleGoBack}>
                                No, I’m not interested in savings
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.formOpen && (
                    <Modal
                        isShowModal={this.state.formOpen}
                        onCloseModal={() => {
                            this.setState({ formOpen: false });
                        }}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="line12"></div>
                            <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className="date11"
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                              !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                            ? 'Please enter valid email'
                                            : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className="date11"
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                            ? 'Please enter valid phone number'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={`0 0 3px 0 ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    width="130px"
                                    height="50px"
                                    border={`1px solid ${
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }`}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                        let slug: any = localStorage.getItem('slug');
                                        if (slug) {
                                            slug = JSON.parse(slug);
                                        }
                                        if (slug && slug.display_name) {
                                            let brokerage: any;

                                            brokerage = slug.display_name;
                                            let brokerage_key: any = slug.brokerage_key;
                                            let agent_key: any = slug.realtor_key;

                                            let agent_name: any = slug.contact_name;
                                            let move_id: any = localStorage.getItem('moveKey');

                                            buttonClicked(
                                                'Button Clicked',
                                                'Energy Savings',
                                                brokerage,
                                                brokerage_key,
                                                agent_name,
                                                agent_key,
                                                'Energy Savings',
                                                'Cancel',
                                                false,
                                                '',
                                                false,
                                                '',
                                                '',
                                                false,
                                                '',
                                                false,
                                                '',
                                                '',
                                                move_id,
                                            );
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius={'5px'}
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeFormHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}

                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                        // style={{
                        //     position: 'absolute',
                        //     width: '700px',
                        //     // height: '650px',
                        //     backgroundColor: '#fff',
                        //     left: '25%',
                        //     top: '5%',
                        // }}
                    >
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}

                        {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                        (this.state.rescheduleClicked && this.state.reschedule_link) ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.scheduleClicked
                                            ? this.state.nylas_full_service_scheduler_url
                                            : this.state.rescheduleClicked
                                            ? this.state.reschedule_link
                                            : null
                                    }&utm_source=platform&utm_medium=web&utm_content=dashboard&utm_campaign=moving-platform
`}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
                {
                    <Modal
                        isShowModal={this.state.cancelOpen}
                        onCloseModal={this.cancelModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                    >
                        <div className="cancellation-modal">
                            <div className="logo-heading-div">
                                <div className="moveaesy-logo">
                                    <img src={moveEasy} alt="" />
                                </div>
                                <div className="cancellation-heading">Appointment With Moving Concierge</div>
                            </div>
                            <div className="modal-body">
                                <div className="left-detail">
                                    <div className="appointment-details-heading">Appoinment Details</div>
                                    <div className="appointment-details">
                                        Call is Scheduled On
                                        <div className="details">
                                            {`${new Date(this.state.appointmentTime)
                                                .toString()
                                                .substring(0, 15)},${new Date(
                                                this.state.appointmentTime,
                                            ).toLocaleTimeString('en-US')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="right-form">
                                    <div className="reason-cancellation">Reason For Cancelling</div>
                                    <div className="input-reason">
                                        <TextField
                                            id="standard-basic"
                                            label="Enter Reason"
                                            variant="standard"
                                            onChange={(e: any) => {
                                                this.setState({
                                                    reason: e.target.value,
                                                });
                                            }}
                                        />
                                        {!this.state.reason ? (
                                            <div style={{ fontSize: '14px', color: 'red' }}>
                                                {this.state.reasonError}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="cancel-event-button">
                                        <Button onClick={this.cancelButtonClicked} width="150px">
                                            Cancel Event
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(AwesomeSavings);
