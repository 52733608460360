import React, { Component } from 'react';
import './TrustedProsRequest.scss';
import HomeProsImg from '../../Assets/images/TrustedPros/homeProsImg.svg';
import Button from '../atoms/Button';
import requestSend from '../../Assets/images/TrustedPros/requestSend.svg';

type initialProps = {
    submit: boolean;
    searchRequest: any;
};

type initialState = {};

export class TrustedProsRequest extends Component<initialProps, initialState> {
    state: initialState = {};

    componentDidMount() {}
    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div
                className={
                    this.props.submit
                        ? isDouglasElliman
                            ? 'main-div-TrustedPros SubmitTrust'
                            : 'main-div-TrustedPros SubmitTrust1'
                        : 'main-div-TrustedPros'
                }
            >
                {this.props.submit ? (
                    <div className="">
                        <div className={this.props.submit ? ' SubmitTrustSucess' : 'sub-div-trust'}>
                            <div className="sub-div-trust-img">
                                <img src={requestSend} alt="" />
                            </div>
                        </div>
                        <div className="sub-div-Info">
                            <div className="sub-div-divide">
                                <div className="sub-div-homePro">Great! Request Submitted</div>
                                <div className="sub-div-homeProDetail">
                                    Our research team will email you a list of local trusted professionals within the
                                    next 24-48 hours.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="sub-div-trust">
                            <div className="sub-div-trust-img">
                                <img src={HomeProsImg} alt="" />
                            </div>
                        </div>

                        <div className="sub-div-Info">
                            <div className="sub-div-divide">
                                <div className="sub-div-homePro">Let Us Find Your Home Pro</div>
                                <div className="sub-div-homeProDetail">
                                    Our research team can search for local trusted professionals and email them to you
                                    in 24-48 hours.
                                </div>
                            </div>
                            <div className="sub-div-button-Requested">
                                <Button onClick={this.props.searchRequest}>Submit Request</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default TrustedProsRequest;
