import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 29.727}
        height={props.height ? props.height : 29.254}
        viewBox="0 0 29.727 29.254"
    >
        <g data-name="Group 35699" fill={window.location.pathname.includes('title') ? '#009CBD' : '#100b28'}>
            <path
                data-name="Path 42878"
                d="M8.155 8.728a.578.578 0 0 0 0 1.157h7.488a2.231 2.231 0 0 1 .5-.483l.989-.674Z"
            />
            <path
                data-name="Path 42879"
                d="M20.288 5.982a.578.578 0 0 0-.578-.578H8.155a.578.578 0 0 0 0 1.157H19.71a.578.578 0 0 0 .578-.578"
            />
            <path
                data-name="Path 42880"
                d="M8.155 12.93h7.75a2.27 2.27 0 0 1-.357-.4 2.234 2.234 0 0 1-.326-.757H8.155a.578.578 0 0 0 0 1.157"
            />
            <path
                data-name="Path 42881"
                d="M1.907 27.843a.752.752 0 0 1-.751-.751v-2.468h19.369v2.468a1.892 1.892 0 0 0 .154.751Zm21.755-.759a.87.87 0 0 1-.955.751h-.316a.747.747 0 0 1-.708-.742v-3.046a.578.578 0 0 0-.578-.578h-16.9V1.157h19.454v3.282a2.135 2.135 0 0 1 1.157 0V.579a.578.578 0 0 0-.578-.578H3.626a.578.578 0 0 0-.578.578v22.89H.579a.578.578 0 0 0-.578.578v3.046A1.909 1.909 0 0 0 1.908 29h20.808a2.015 2.015 0 0 0 2.1-1.907v-7.728h-1.157Z"
            />
            <path
                data-name="Path 42882"
                d="M7.577 18.72a.578.578 0 0 0 .578.578h10.629a2.249 2.249 0 0 1-1.5-1.157H8.15a.578.578 0 0 0-.578.578"
            />
            <path
                data-name="Path 42883"
                d="M7.577 15.397a.578.578 0 0 0 .578.578h8.868v-1.157H8.155a.578.578 0 0 0-.578.578"
            />
            <path
                data-name="Path 42884"
                d="M28.627 10.298v6.226h-8.772v-6.226l4.386-2.987Zm2.768.486-1.864-1.27-4.965-3.381a.576.576 0 0 0-.651 0l-6.828 4.651a.578.578 0 1 0 .651.956l.961-.655v6.017a.578.578 0 0 0 .578.578h9.929a.578.578 0 0 0 .578-.578v-6.017l.961.655a.572.572 0 0 0 .324.1.578.578 0 0 0 .326-1.057"
            />
        </g>
    </svg>
);

export default SvgComponent;
