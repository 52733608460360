import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './ElectricityGasMainSkeleton.scss';
// import Radio from '@material-ui/core/Radio';

export class QuestionSkeleton extends Component {
    render() {
        return (
            <div style={{ margin: '15px 0' }}>
                <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                    <div className="ElectricityGasMainSkeletonCard">
                        <div className="ElectricityGasMainSkeletonCard1">
                            <div
                                className="div0"
                                style={{
                                    width: '250px',
                                    marginBottom: '5px',
                                }}
                            >
                                {/* <div className="div1"> */}
                                <Skeleton height="30px" />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        );
    }
}
