import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import area from '../../Assets/images/serial1.png';
import recommendedDeals from '../../Assets/images/serial2.png';
import shop from '../../Assets/images/serial3.png';

import '../../styles/howItWorksLanding.scss';
import SwipeableViews from 'react-swipeable-views';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
}));

export default function HowItWorksMobileComponent() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue: any) => {
        // setValue(newValue);
        setValue(parseInt(newValue.currentTarget.id.split('-')[3]));
    };
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    return (
        <div className={`${classes.root} tabHead`}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="off"
                aria-label="scrollable prevent tabs example"
                TabIndicatorProps={{ style: { background: '#1D7E64' } }}
            >
                <Tab label="Your area" {...a11yProps(0)} />
                <Tab label="Recommended deals" {...a11yProps(1)} />
                <Tab label="Get help." {...a11yProps(2)} />
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0}>
                    <div className="tab-content-image">
                        <img src={area} alt="" />
                    </div>

                    <div style={{ fontWeight: 'bold', padding: '10px 0', fontSize: '14px' }}>Enter Your Area</div>
                    <div className="tab-content-details">
                        It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="tab-content-image">
                        <img src={recommendedDeals} alt="" />
                    </div>
                    <div style={{ fontWeight: 'bold', padding: '10px 0', fontSize: '14px' }}>
                        Choose our recommended deals
                    </div>
                    <div className="tab-content-details">
                        Search Providers,compare plans and other service all on your screen site.
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className="tab-content-image">
                        <img src={shop} alt=""/>
                    </div>
                    <div style={{ fontWeight: 'bold', padding: '10px 0', fontSize: '14px' }}>
                        Shop on your own or get help
                    </div>
                    <div className="tab-content-details">
                        Use our patented technology to search providers, compare plans and order service all on our
                        secure site.
                    </div>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}
