import React from 'react';
import '../../styles/quoteDetailsCard.css';

interface quoteDetailsCardProps {
    deductible?: number;
    dwellingCoverage?: number;
    liabilityCoverage?: number;
    medicalCoverage?: number;
    personalProperty?: number;
    lossOfUse?: number;
    width?: string;
}

const QuoteDetailsCard = (props: quoteDetailsCardProps) => {
    return (
        <div
            className="insuranceQuote"
            style={{
                border: '1px solid #e2e2e2',
                width: props.width ? props.width : '40%',
            }}
        >
            <div className="card-heading">Quote Details</div>
            <div className="divider"></div>
            <div className="row">
                <div className="value">Deductible</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.deductible}
                </div>
            </div>
            <div className="divider"></div>

            <div className="row">
                <div className="value">Dwelling Coverage</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.dwellingCoverage}
                </div>
            </div>
            <div className="divider"></div>

            <div className="row">
                <div className="value">Liability Coverage</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.liabilityCoverage}
                </div>
            </div>
            <div className="divider"></div>

            <div className="row">
                <div className="value">Medical Coverage</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.medicalCoverage}
                </div>
            </div>
            <div className="divider"></div>

            <div className="row">
                <div className="value">Personal Property</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.personalProperty}
                </div>
            </div>
            <div className="divider"></div>

            <div className="row">
                <div className="value">Loss Of Use</div>
                <div className="value">
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                    {props.lossOfUse}
                </div>
            </div>
            <div className="terms-and-condition">
                *The insurance quote(s) displayed above are provided by licensed insurance agencies or carriers and are
                preliminary estimates only and are subject to change based on additional information we may receive
                later in the quoting process, the coverages, deductibles and payment options you choose along with other
                discounts, features or savings that may apply.
            </div>
        </div>
    );
};

export default QuoteDetailsCard;
