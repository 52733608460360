export const list: any = [
    { name: 'AL', label: 'AL' },
    { name: 'AK', label: 'AK' },
    { name: 'AZ', label: 'AZ' },
    { name: 'AR', label: 'AR' },
    { name: 'CA', label: 'CA' },
    { name: 'CO', label: 'CO' },
    { name: 'CT', label: 'CT' },
    { name: 'DE', label: 'DE' },
    { name: 'FL', label: 'FL' },
    { name: 'GA', label: 'GA' },
    { name: 'HI', label: 'HI' },
    { name: 'ID', label: 'ID' },
    { name: 'IL', label: 'IL' },
    { name: 'IN', label: 'IN' },
    { name: 'IA', label: 'IA' },
    { name: 'KS', label: 'KS' },
    { name: 'KY', label: 'KY' },
    { name: 'LA', label: 'LA' },
    { name: 'ME', label: 'ME' },
    { name: 'MD', label: 'MD' },
    { name: 'MA', label: 'MA' },
    { name: 'MI', label: 'MI' },
    { name: 'MN', label: 'MN' },
    { name: 'MS', label: 'MS' },
    { name: 'MO', label: 'MO' },
    { name: 'MT', label: 'MT' },
    { name: 'NE', label: 'NE' },
    { name: 'NV', label: 'NV' },
    { name: 'NH', label: 'NH' },
    { name: 'NJ', label: 'NJ' },
    { name: 'NM', label: 'NM' },
    { name: 'NY', label: 'NY' },
    { name: 'NC', label: 'NC' },
    { name: 'ND', label: 'ND' },
    { name: 'OH', label: 'OH' },
    { name: 'OK', label: 'OK' },
    { name: 'OR', label: 'OR' },
    { name: 'PA', label: 'PA' },
    { name: 'RI', label: 'RI' },
    { name: 'SC', label: 'SC' },
    { name: 'SD', label: 'SD' },
    { name: 'TN', label: 'TN' },
    { name: 'TX', label: 'TX' },
    { name: 'UT', label: 'UT' },
    { name: 'VT', label: 'VT' },
    { name: 'VA', label: 'VA' },
    { name: 'WA', label: 'WA' },
    { name: 'WV', label: 'WV' },
    { name: 'WI', label: 'WI' },
    { name: 'WY', label: 'WY' },
];
