import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/vendorNameCheckboxCard.scss';


interface cardProps {
cardTitle ? :any;
checked ?:boolean;
handleChange ? : any
}

const VendorNameCheckboxCard = (props: cardProps) => {

    return (
          <div className="wrapper-main"   onClick={props.handleChange}>
                         <div className="checkbox-wrapper">
                         <Checkbox
                                checked={props.checked}
                                color="default"
                                style={{ color: props.checked ? '#273E59' : '#BEBEBE' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                         </div>

                         <div className="name-wrapper">
                           {
                               props.cardTitle
                           }
                         </div>
                       </div>
        
    );
};

export default VendorNameCheckboxCard;
