import React, { Component } from 'react';
import './homeSecurity.scss';
// import Button from '../../components/atoms/Button';
// import CalenderIcon from '../../Assets/images/InsuranceImages/Iconmaterial-date-range.svg';
// import NavbarLanding from '../../components/Navbar/NavbarLanding';
// import Modal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as ServiceActions from '../../store/savings/actions';
// import Button from '../../components/atoms/Button';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectInput from '../../components/atoms/SelectInput';
// import InternetCard2 from '../../Assets/images/InternetCard2.svg';
// import CloseIcon from '@material-ui/icons/Close';
// import TextField from '@material-ui/core/TextField';
// import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import Footer from './Footer';
import './SecurityEdit.scss';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    serviceAction?: any;
    serviceState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    getSubscriptionDetails?: any;
    history?: any;
    moveId?: any;
    commonAction?: any;
    commonState?: any;
};

type initialState = {
    securityAmount: string;
    securityProvider: any;
    securityOtherProvider: any;
    securityProviderOptions: any;
    securityAccountNumber: string;
    securityId: string;
    selectedService: any;
    calendlyOpen2: boolean;
    commonAction?: any;
    commonState?: any;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    date: any;
    time: any;
    moveId: string;
    serviceId: string;
    submitForm: boolean;
};

class SecurityEdit extends Component<initialProps, initialState> {
    state: initialState = {
        securityAmount: '',
        securityProvider: null,
        securityOtherProvider: '',
        securityProviderOptions: [],
        securityAccountNumber: '',
        securityId: '',
        selectedService: [],
        calendlyOpen2: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        date: '',
        time: '',
        moveId: '',
        serviceId: '',
        submitForm: false,
    };

    componentDidMount() {
        window.scroll(0, 0);
        let moveId: any = localStorage.getItem('moveId');
        this.setState({ moveId: moveId })
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.selectedService
        ) {
            this.setState({ selectedService: this.props.history.location.state.selectedService });
        }
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            this.setState({
                firstName: this.props.history.location.state.firstName,
                lastName: this.props.history.location.state.lastName,
                email: this.props.history.location.state.email,
                phone: this.props.history.location.state.phone,
                date: this.props.history.location.state.date,
                time: this.props.history.location.state.time,
            });
        }
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.moveId
        ) {
            this.setState({ moveId: this.props.history.location.state.moveId });
        }
        this.props.serviceAction.getServiceProvider();
        this.props.dashboardAction.getSubscriptionDetails();
    }
    
    componentDidUpdate(prevProps: initialProps) {
        if(prevProps.homeOwnerDashboardState.updateSubscriptionDetails !== this.props.homeOwnerDashboardState.getSubscriptionDetails  && (this.props.history.location.state.from !== 'summary' || this.state.submitForm)){
            if(this.props.homeOwnerDashboardState.updateSubscriptionDetails?.id || this.props.homeOwnerDashboardState.updateSubscriptionDetails?.length > 0){
                this.props.history.push('/dashboard/home-security/summary');
            }
        }
        if (
            prevProps.serviceState.getServiceProvider !== this.props.serviceState.getServiceProvider &&
            this.props.serviceState.getServiceProvider &&
            this.props.serviceState.isGetServiceProvider
        ) {
            let serviceProvider: any = [];

            this.props.serviceState.getServiceProvider.map((item: any) => {
                serviceProvider.push({ label: item.name, value: item.name, logo: item.logo });
            });
            serviceProvider.push({ label: 'Other', value: 'Other' });

            this.setState({
                securityProviderOptions: serviceProvider,
            });
        }
        
        setTimeout(() => {
            if (this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0 &&
                prevProps.homeOwnerDashboardState.getSubscriptionDetails != this.props.homeOwnerDashboardState.getSubscriptionDetails
            ) {
                this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((item: any) => {
                    if(item.service.slug === 'home-security'){
                        let serviceOptions: any = this.state.securityProviderOptions;
                        let service: any = null;
                        serviceOptions.map((item1: any) => {
                            if (item1.value == item.provider) {
                                service = item1;
                            }
                        });
                        if (service == null) {
                            service = { value: 'Other', label: 'Other' };
                        }
                        this.setState({
                            securityId: item.id,
                            securityAmount: item.price,
                            securityProvider: service,
                            securityOtherProvider: item.provider,
                            securityAccountNumber: item.account_number,
                            serviceId: item.service_id
                        });
                    }
                })
            }
        }, 1000);
    }

    onSelect = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };

    onChange = (e: any, name: string) => {
        if ( name == 'securityAmount' ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/[^\d.]/g, '') });
        } else if ( name == 'securityAccountNumber' ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/\D/g, '') });
        } else {
            this.setState({ ...this.state, [name]: e.target.value });
        }
    };

    securityPostSubcriptionDetails = () => {

            if (
                this.state.serviceId &&
                this.state.securityAmount &&
                this.state.securityAccountNumber &&
                this.state.securityProvider &&
                this.state.securityProvider.value &&
                ((this.state.securityProvider.value == 'Other' && this.state.securityOtherProvider) ||
                    this.state.securityProvider.value != 'Other')
            ) {
                let payload = {
                    id: this.state.securityId,
                    body: {
                        id: this.state.securityId,
                        service_id: this.state.serviceId,
                        provider:
                            this.state.securityProvider &&
                            this.state.securityProvider.value &&
                            this.state.securityProvider.value != 'Other'
                                ? this.state.securityProvider.value
                                : this.state.securityProvider &&
                                  this.state.securityProvider.value &&
                                  this.state.securityProvider.value == 'Other'
                                ? this.state.securityOtherProvider
                                : '',
                        provider_logo:
                            this.state.securityProvider &&
                            this.state.securityProvider.logo &&
                            this.state.securityProvider.logo != 'Other'
                                ? this.state.securityProvider.logo
                                : this.state.securityProvider &&
                                    this.state.securityProvider.logo &&
                                    this.state.securityProvider.logo == 'Other'
                                ? this.state.securityOtherProvider
                                : '',
                        price: this.state.securityAmount,
                        account_number: this.state.securityAccountNumber,
                        move: this.state.moveId,
                    },
                };
                this.setState({submitForm: true})
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                // History.push('/dashboard/home-security/summary');
            }
    }

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        // let moveData =
        //     this.props.commonState &&
        //     this.props.commonState.move &&
        //     this.props.commonState.move.length > 0 &&
        //     this.props.commonState.move[0];
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        const breadList = [
            {
                name: 'Edit Security details',
                url: ''
            }
        ]

        return (
            <div>
                {isDouglasElliman ? (
                    <></>
                ) : (
                    <>
                        <div className="greybglong">
                            <div className="main-head">
                                <h2 className="heading">Security</h2>
                                <div className='mb-4'>
                                     <BreadCrumb breadList={breadList} />
                                </div>
                            </div>
                            <div className='summaryHeader'>
                                <h3>Edit Security Details</h3>
                                <p>Update your subscription plan details.</p>
                            </div>
                        </div>
                        <div className="form_section">
                            <form>
                                <div className="form_box">
                                    <div className="edit_securityform">
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <SelectInput
                                                        label={'What home security provider do you have today?'}
                                                        value={this.state.securityProvider && this.state.securityProvider.value}
                                                        onChange={(e: any) => this.onSelect(e, 'securityProvider')}
                                                        placeholder={
                                                            this.state.securityProvider && this.state.securityProvider.label
                                                                ? this.state.securityProvider.label
                                                                : 'Select security provider'
                                                        }
                                                        options={this.state.securityProviderOptions}
                                                        // inputLa
                                                    />
                                                    <div className="error">
                                                        {this.state.securityProvider == null
                                                            ? 'Enter security Provider'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.securityProvider &&
                                            this.state.securityProvider.value &&
                                            this.state.securityProvider.value == 'Other' ? (
                                                <div className="inputFlexDiv2">
                                                    <div className="inputDiv1">
                                                        <CustomizedInput
                                                            labelName="Enter provider name"
                                                            value={this.state.securityOtherProvider}
                                                            onChange={(e: any) => this.onChange(e, 'securityOtherProvider')}
                                                            placeholder=""
                                                        />
                                                        <div className="error">
                                                            {!this.state.securityOtherProvider
                                                                ? 'Enter security Provider'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="inputFlexDiv1">
                                            <div className="inputFlexDiv2 edit_paying_input">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="How much are you paying?"
                                                        value={this.state.securityAmount}
                                                        onChange={(e: any) => this.onChange(e, 'securityAmount')}
                                                        placeholder=""
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                    />
                                                    <div className="error">
                                                        {!this.state.securityAmount
                                                            ? 'Enter security amount'
                                                            : this.state.securityAmount &&
                                                            parseInt(this.state.securityAmount) <= 0
                                                            ? 'Amount must be greater than 0'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="inputsupportText">/Month</div>
                                            </div>
                                            <div className="inputFlexDiv2">
                                                <div className="inputDiv1">
                                                    <CustomizedInput
                                                        labelName="Your Account Number"
                                                        value={this.state.securityAccountNumber}
                                                        onChange={(e: any) => this.onChange(e, 'securityAccountNumber')}
                                                        placeholder=""
                                                        // InputProps={{
                                                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        // }}
                                                    />
                                                    <div className="error">
                                                        {!this.state.securityAccountNumber
                                                            ? 'Enter security account number'
                                                            : this.state.securityAccountNumber.length < 4
                                                            ? 'Account number should be atleast 4 digits'
                                                            : this.state.securityAccountNumber &&
                                                            parseInt(this.state.securityAccountNumber) <= 0
                                                            ? 'invalid account number'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form_btns">
                                    <button className="theme_btn" onClick={() => {
                                        History.push('/dashboard/home-security/summary');
                                    }}>Cancel</button>
                                    {/* <Button
                                        className="theme_btn"
                                        onClick={() => {
                                            this.securityPostSubcriptionDetails();
                                        }}
                                    >
                                        Confirm Details
                                    </Button> */}
                                    <button className="theme_btn" type="button" onClick={() => { this.securityPostSubcriptionDetails() }}>Confirm Details</button>
                                </div>
                            </form>
                        </div>
                    </>
                )
                }
                <Footer />
            </div >
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeOwnerDashboardState: state.homeOwnerdashboard,
    serviceState: state.service
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityEdit);
