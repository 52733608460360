import { all, call, put, takeLatest } from 'redux-saga/effects';
// import { Store } from 'react-notifications-component';
import {
    checklistFailure,
    checklistSuccess,
    updateServicePercentageFailure,
    updateServicePercentageSuccess
   
} from './actions';
import {
    GET_CHECKLIST, UPDATE_SERVICE_PERCENTAGE,
    
} from './actionTypes';
import apiJunction from '../../utils/api';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* checklist() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/checklist/checklist-api/?must_do=true`,
        });
        if (response.data && response.status === 200) {
            yield put(checklistSuccess({ checklist: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(checklistFailure({ error: e instanceof Error ? e.message : "Something went wrong!" }));
        // history.push('/server-error');
    }
}

function* updateServicePercentage(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'patch',
            url: `/api/checklist/checklist-api/${action.payload.service_id}/update-checklist-percentage/`,
            body: action.payload.data,
        });
        if (response.data && response.status === 200) {
            yield put(updateServicePercentageSuccess({ updateServicePercentage: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(updateServicePercentageFailure({ error: e instanceof Error ? e.message : "Something went wrong"}));
        // history.push('/server-error');
    }
}


export default function* checklistDashboardSaga() {
    yield all([takeLatest(GET_CHECKLIST, checklist)]);
    yield all([takeLatest(UPDATE_SERVICE_PERCENTAGE, updateServicePercentage)]);
  
}
