import React, { Component } from 'react';
import './Documents.scss';
import DocumentTabs from './DocumentsTab';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import notFound from '../../Assets/images/documentry/notFound.svg';
// import consumerImage from '../../Assets/images/documentry/consumerImage.png';
import Modal from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SelectInput from '../../components/atoms/SelectInput';
import Button from '../../components/atoms/Button';
import copyIconImage from '../../Assets/images/documentry/copyIconImage.png';
import * as DocumentryAction from '../../store/documentary/actions';
import * as CommonAction from '../../store/common/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import pdfIcon from '../../Assets/images/documentry/pdfIcon.png';
import csvIcon from '../../Assets/images/documentry/csvIcon.png';
import wordIcon from '../../Assets/images/documentry/wordIcon.png';
import docIcon from '../../Assets/images/documentry/documentIcon.jpg';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
    location?: any;
    documentryAction?: any;
    documentryState?: any;
    dashboardAction: any;
};

type initialState = {
    documents: any;
    documentData: any;
    loader: boolean;
    documentAdd: boolean;
    uploadFor?: any;
    title: string;
    image: string;
    folder: any;
    folderOptionsSelect: any;
    windowSize: number;
    isGetFolders: boolean;
    description: string;
    moveId: string;
    brokerage_name: string;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    isMoveDataArrived: boolean;
    selectedImage: any;
    documentDataCompleted: boolean;
    contineuClicked: boolean;
    brokerage_key: any;
    agent_key: any;
    // selectedTab: number;
};

class Documents extends Component<initialProps, initialState> {
    state: initialState = {
        documents: [],
        documentData: [],
        loader: true,
        documentAdd: false,
        uploadFor: '',
        title: '',
        image: '',
        folder: null,
        folderOptionsSelect: [],
        windowSize: 0,
        isGetFolders: true,
        description: '',
        moveId: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        isMoveDataArrived: true,
        selectedImage: null,
        documentDataCompleted: false,
        contineuClicked: false,
        brokerage_key: '',
        agent_key: '',
        // selectedTab: 0,
    };
    componentDidMount() {
        this.setState({ windowSize: window.screen.width });
        // if (
        //     this.props &&
        //     this.props.history &&
        //     this.props.history.location &&
        //     this.props.history.location.state &&
        //     this.props.history.location.state.selectedfolder
        // ) {
        //     this.setState({ selectedTab: this.props.history.location.state.selectedfolder });
        // }
        if (
            this.props &&
            this.props.documentryState &&
            this.props.documentryState.getFolders &&
            this.props.documentryState.getFolders.data
        ) {
            let foldersOptions: any = [];
            let documents: any = [];
            this.props.documentryState.getFolders.data.map((item: any, index: number) => {
                foldersOptions.push({ item: item, label: item[1], value: item[0] });
                documents.push({ item: item, name: item[1], id: item[0], tabId: index });
            });
            this.setState({ folderOptionsSelect: foldersOptions, documents: documents });
        } else {
            this.props.documentryAction.getFolders();
        }
        this.props.documentryAction.getDocuments();
    }
    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length > 0 &&
            nextProps.commonState.move[0] &&
            currentState.isMoveDataArrived
        ) {
            currentState.isMoveDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getFolders &&
            nextProps.documentryState.getFolders.data &&
            (nextProps.documentryState.isGetFolders || currentState.isGetFolders)
        ) {
            nextProps.documentryState.isGetFolders = false;
            currentState.isGetFolders = false;
            let foldersOptions: any = [];
            let documents: any = [];

            nextProps.documentryState.getFolders.data.map((item: any, index: number) => {
                foldersOptions.push({ item: item, label: item[1], value: item[0] });
                documents.push({ item: item, name: item[1], id: item[0], tabId: index });
            });
            currentState.folderOptionsSelect = foldersOptions;
            currentState.documents = documents;
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.postDocuments &&
            // nextProps.documentryState.postDocuments.data &&
            nextProps.documentryState.isPostDocuments
        ) {
            nextProps.documentryState.isPostDocuments = false;
            // let windownew: any = window;
            // let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            // analytics.track(
            //     `Document Upload Completed`,
            //     {
            //         page: 'Document',
            //         brokerage_name: currentState.brokerage_name,
            //         brokerage_key: currentState.brokerage_key,
            //         agent_key: currentState.agent_key,
            //         agent_name: currentState.agent_name,
            //         folder_name: currentState.folder && currentState.folder.label,
            //     },
            //     { user_id: currentState.moveId },
            // );
            currentState.documentAdd = false;
            currentState.documentDataCompleted = true;
            nextProps.documentryAction.getDocuments();
            currentState.contineuClicked = false;
            currentState.folder = null;
            currentState.title = '';
            currentState.description = '';
            currentState.selectedImage = null;
        }
        if (
            nextProps &&
            nextProps.documentryState &&
            nextProps.documentryState.getDocuments &&
            nextProps.documentryState.getDocuments.results &&
            nextProps.documentryState.isGetDocuments
        ) {
            nextProps.documentryState.isGetDocuments = false;
            let documentsResults: any = [];

            nextProps.documentryState.getDocuments.results.map((item: any) => {
                documentsResults.push({ ...item, showAddTopButton: true });
                return documentsResults;
            });
            currentState.documentData = documentsResults;
            currentState.documentDataCompleted = true;
        }
        if (currentState.documents.length && currentState.documentDataCompleted && !currentState.isGetFolders) {
            currentState.documentDataCompleted = false;
            currentState.isGetFolders = true;
            let folderIds: any = [];

            let documentData: any = currentState.documentData;
            documentData.map((item: any) => {
                folderIds.push(item.folders_id);
            });
            currentState.documents.map((item: any) => {
                if (!folderIds.includes(item.id)) {
                    documentData.push({
                        folders_id: item.id,
                        name: item.name,
                        head: `There are no ${item.name} added`,
                        notFound: notFound,
                        addData: `Add Photos and Videos`,
                        showAddTopButton: false,
                    });
                }
            });
            currentState.documentData = documentData;
        }
        return currentState;
    }
    handleBack = () => {
        this.props.history.push('/dashboard/my-documents');
    };
    updateUploadfor = () => {
        this.setState({ uploadFor: 'upload' });
    };
    onPasteImage = (name: string, value: any) => {
        if (name === 'image') {
            this.setState({ image: value });
        }
    };
    handletitleChange = (e: any) => {
        this.setState({
            title: e.target.value,
        });
    };
    onSelectFolder = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };
    handleAddDocuments = () => {
        this.setState({
            documentAdd: true,
        });
    };

    setSize = () => {
        this.setState({ windowSize: window.screen.width });
    };
    uploadDocuments = () => {
        this.setState({ contineuClicked: true });
        if (
            this.state.moveId &&
            this.state.folder &&
            this.state.folder.value &&
            this.state.title &&
            this.state.selectedImage &&
            this.state.selectedImage != null
        ) {
            let payload: any = {
                move_id: this.state.moveId,
                folders_id: this.state.folder && this.state.folder.value,
                name: this.state.title,
                description: this.state.description,
                document: this.state.selectedImage,
            };
            const form = new FormData();
            for (var key in payload) {
                form.append(key, payload[key]);
            }
            this.props.documentryAction.postDocuments({ formData: form });
            updateChecklistTask(100, this.props.dashboardAction);
        }
    };
    addDocumntsCliked = (folder_id: number) => {
        let folderOptionsSelect: any = this.state.folderOptionsSelect;
        folderOptionsSelect.map((item: any) => {
            if (folder_id === item.value) {
                this.setState({ folder: item, documentAdd: true });
            }
        });
    };
    goPreview = (folder_id: number, id: number) => {
        // let documentData = this.state.documentData;
        // documentData = documentData.filter((item: any) => item.folders_id == folder_id);
        // let currentDocument: any;
        // documentData.map((item: any) => {
        //     if (item.id == id) {
        //         currentDocument = item;
        //         return currentDocument;
        //     }
        // });

        this.props.history.push({
            pathname: '/dashboard/my-documents/preview',
            state: {
                folderId: folder_id,
                currentDocumentId: id,
                folderList: this.state.folderOptionsSelect,
            },
        });
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        window.onresize = this.setSize;

        const breadList = [
            {
                name: 'Documents',
                url: ''
            },
            {
                name: 'Document',
                url: ''
            }
        ]

        return (
            <div>
                <div className="documentryMainDiv">
                    <div className="headingDocumentryNextStep">
                        <span className="arrowIconDivNext" onClick={this.handleBack}>
                            <ArrowBackIcon />
                        </span>
                        <span>Document</span>
                    </div>
                    <div className="titleFlexDivDocument">
                        <div className='mb-4'>
                           <BreadCrumb breadList={breadList} />
                         </div>
                    </div>
                    
                    <div>
                        <DocumentTabs
                            tabsHeading={this.state.documents}
                            tabsContentData={this.state.documentData}
                            loader={this.state.loader}
                            addDocumntsCliked={this.addDocumntsCliked}
                            goPreview={this.goPreview}
                            handleAddDocuments={this.handleAddDocuments}
                            selectedFolder={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.state &&
                                this.props.history.location.state.selectedfolder
                            }
                        />
                    </div>
                </div>
                {this.state.documentAdd ? (
                    <Modal
                        isShowModal={this.state.documentAdd}
                        onCloseModal={() => {
                            this.setState({ documentAdd: false, contineuClicked: false });
                        }}
                        showClose={false}
                        className="modalFolderCreate"
                    >
                        <div>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        margin: 'auto',
                                        fontFamily: 'Roboto',
                                    }}
                                >
                                    Add Document
                                </div>
                                <div className="closeIconWaranty">
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({
                                                documentAdd: false,
                                                contineuClicked: false,
                                                title: '',
                                                description: '',
                                                selectedImage: null,
                                                folder: null,
                                                image: '',
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>

                            <div className="ConfirmationDetailsDiv">
                                <span className="ConfirmationLabel">
                                    <input
                                        type="file"
                                        id="file"
                                        ref="fileUploader"
                                        style={{ display: 'none' }}
                                        onChange={(e: any) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            let currentTarget = document.querySelector(`.uploadText`);
                                            if (e.target.files.length > 0) {
                                                let fileSize: number =
                                                    e.target.files[0] && e.target.files[0].size / 1024;
                                                if (fileSize <= 2048) {
                                                    const fileReader = new FileReader();
                                                    this.setState({ selectedImage: e.target.files[0] });
                                                    fileReader.readAsDataURL(e.target.files[0]);
                                                    let files = e.target.files[0];
                                                    fileReader.onload = () => {
                                                        let image: any = '';
                                                        let isVideo: boolean = false;
                                                        let videoType: string = '';
                                                        if (files && files.type && files.type == 'application/pdf') {
                                                            image = pdfIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('application/') &&
                                                            files.name &&
                                                            files.name.split('.')[1] == 'csv'
                                                        ) {
                                                            image = csvIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('application/') &&
                                                            files.name &&
                                                            (files.name.split('.')[1] == 'doc' ||
                                                                files.name.split('.')[1] == 'docx' ||
                                                                files.name.split('.')[1] == 'docm' ||
                                                                files.name.split('.')[1] == 'dot' ||
                                                                files.name.split('.')[1] == 'dotx' ||
                                                                files.name.split('.')[1] == 'dotm')
                                                        ) {
                                                            image = wordIcon;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('image/')
                                                        ) {
                                                            image = fileReader.result;
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mp4') || files.type.includes('MP4'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mp4';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mov') || files.type.includes('MOV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mov';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('wmv') || files.type.includes('WMV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'wmv';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('avi') || files.type.includes('AVI'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'avi';
                                                        } else if (
                                                            files &&
                                                            files.type &&
                                                            files.type.includes('video') &&
                                                            (files.type.includes('mkv') || files.type.includes('MKV'))
                                                        ) {
                                                            isVideo = true;
                                                            image = fileReader.result;
                                                            videoType = 'mkv';
                                                        } else {
                                                            image = docIcon;
                                                        }
                                                        if (currentTarget && currentTarget !== null) {
                                                            if (isVideo) {
                                                                currentTarget.innerHTML = `<video width="100%" height="100px">
                                                                        <source
                                                                            src="${image}"
                                                                            type="video/${videoType}"
                                                                        />
                                                            </video>`;
                                                            } else {
                                                                currentTarget.innerHTML = `<img src="${image}" alt="" />`;
                                                            }
                                                        }
                                                    };
                                                }
                                            }
                                        }}
                                    />
                                </span>
                                <div className="inputFileDiv" id="upload">
                                    <div
                                        className="uploadText"
                                        onClick={() =>
                                            // `current` points to the mounted file input element
                                            {
                                                this.updateUploadfor();
                                                let element: any = document.getElementById('file') as HTMLElement;
                                                element.click();
                                            }
                                        }
                                    >
                                        <img src={copyIconImage} alt="" />
                                        <div className="helperTextUpload">Upload the file here (Max 2mb each file)</div>
                                        <div className="textUpload">Upload File</div>
                                    </div>
                                </div>
                                <div className="error">
                                    {this.state.contineuClicked && this.state.selectedImage == null
                                        ? 'Upload Image'
                                        : ''}
                                </div>
                            </div>
                            <div className="inputValueDiv">
                                <TextField
                                    className="input1"
                                    label="Title"
                                    id="outlined-start-adornment"
                                    onChange={(e: any) => this.handletitleChange(e)}
                                    variant="filled"
                                    value={this.state.title}
                                    // error={this.state.step3.titleAgent === ''}
                                    // helperText={
                                    //     this.state.step3.titleAgent === '' ? 'Please enter Title Agent' : ''
                                    // }
                                />
                                <div className="error">
                                    {this.state.contineuClicked && this.state.title == '' ? 'Enter Title' : ''}
                                </div>
                            </div>
                            <div className="inputValueDiv">
                                <SelectInput
                                    onChange={(e: any) => this.onSelectFolder(e, 'folder')}
                                    options={this.state.folderOptionsSelect}
                                    placeholder={
                                        this.state.folder && this.state.folder.label
                                            ? this.state.folder.label
                                            : 'Select Folder'
                                    }
                                    value={this.state.folder && this.state.folder.value ? this.state.folder.value : ''}
                                    label="Add to"
                                />
                                <div className="error">
                                    {this.state.contineuClicked && this.state.folder == null ? 'Select Folder' : ''}
                                </div>
                            </div>
                            <div className="inputTextValueDiv">
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    rows={6}
                                    placeholder="Enter Description here (Optional)"
                                    value={this.state.description}
                                    onChange={(e: any) => this.setState({ description: e.target.value })}
                                />
                            </div>

                            <div className="buttonDocument">
                                <Button
                                    // color="#fff"
                                    onClick={this.uploadDocuments}
                                    // backgroundColor={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                >
                                    Finish
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        documentryAction: bindActionCreators(DocumentryAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    documentryState: state.documentry,
    commonState: state.common,
});
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
