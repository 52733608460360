import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './PowerKioskCardSkeleton.scss';

export class PowerKioskCardSkeleton extends Component {
    render() {
        return (
            <div style={{ width: '30%', marginRight: '10px' }}>
                <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                    <div className="PowerKioskCardSkeletonCard">
                        <div className="PowerKioskCardSkeletonCard1">
                            <div className="div0">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div1">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div2">
                                <Skeleton height="50px" />
                            </div>
                            <div className="div3">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div4">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div5">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div6">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div7">
                                <Skeleton height="30px" />
                            </div>
                            <div className="div8">
                                <Skeleton height="20px" />
                            </div>
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        );
    }
}
