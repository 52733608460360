import {
  GET_VENDORS,
  GET_VENDORS_ERROR,
  GET_VENDORS_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
  POST_IMPRESSION,
  POST_IMPRESSION_SUCCESS,
  POST_IMPRESSION_ERROR,
  GET_CATEGORIES_QUESTIONS,
  GET_CATEGORIES_QUESTIONS_SUCCESS,
  GET_CATEGORIES_QUESTIONS_ERROR,
  POST_VENDORS,
  POST_VENDORS_SUCCESS,
  POST_VENDORS_ERROR,
  REQUEST_VENDORS,
  REQUEST_VENDORS_SUCCESS,
  REQUEST_VENDORS_ERROR,
  GET_MOVE_VENDOR,
  GET_MOVE_VENDOR_SUCCESS,
  GET_MOVE_VENDOR_ERROR,
  GET_HOME_ADVISOR,
  GET_HOME_ADVISOR_SUCCESS,
  GET_HOME_ADVISOR_ERROR,
  GET_STANDALONE_VENDORS,
  GET_STANDALONE_VENDORS_SUCCESS,
  GET_STANDALONE_VENDORS_ERROR,
  GET_STANDALONE_OFFICES,
  GET_STANDALONE_OFFICES_SUCCESS,
  GET_STANDALONE_OFFICES_ERROR,
  POST_STANDALONE_VENDORS,
  POST_STANDALONE_VENDORS_ERROR,
  POST_STANDALONE_VENDORS_SUCCESS,
  POST_STANDALONE_REQUESTVENDORS,
  POST_STANDALONE_REQUESTVENDORS_SUCCESS,
  POST_STANDALONE_REQUESTVENDORS_ERROR,
  POST_STANDALONE_IMPRESSION_ERROR,
  POST_STANDALONE_IMPRESSION,
  POST_STANDALONE_IMPRESSION_SUCCESS
} from './actionTypes';
import {
  Vendors,
  VendorsRequest,
  VendorsFailure,
  VendorsFailurePayload,
  VendorsSuccess,
  VendorsSuccessPayload,
  Categories,
  CategoriesRequest,
  CategoriesFailure,
  CategoriesFailurePayload,
  CategoriesSuccess,
  CategoriesSuccessPayload,
  Impression,
  ImpressionFailure,
  ImpressionSuccess,
  ImpressionFailurePayload,
  ImpressionSuccessPayload,
  ImpressionRequest,
  CategoriesQuestions,
  CategoriesQuestionsRequest,
  CategoriesQuestionsSuccess,
  CategoriesQuestionsFailure,
  CategoriesQuestionsFailurePayload,
  CategoriesQuestionsSuccessPayload,
  PostVendors,
  PostVendorsFailure,
  PostVendorsFailurePayload,
  PostVendorsRequest,
  PostVendorsSuccess,
  PostVendorsSuccessPayload,
  RequestVendors,
  RequestVendorsFailure,
  RequestVendorsFailurePayload,
  RequestVendorsRequest,
  RequestVendorsSuccess,
  RequestVendorsSuccessPayload,
  GetMoveVendors,
  GetMoveVendorsFailure,
  GetMoveVendorsSuccess,
  GetMoveVendorsFailurePayload,
  GetMoveVendorsSuccessPayload,
  GetHomeAdvisor,
  GetHomeAdvisorFailure,
  GetHomeAdvisorSuccess,
  GetHomeAdvisorRequest,
  GetHomeAdvisorFailurePayload,
  GetHomeAdvisorSuccessPayload,
  StandAloneVendors,
  StandAloneVendorsFailure,
  StandAloneVendorsSuccess,
  StandAloneVendorsFailurePayload,
  StandAloneVendorsRequest,
  StandAloneVendorsSuccessPayload,
  StandAloneOffices,
  StandAloneOfficesFailure,
  StandAloneOfficesFailurePayload,
  StandAloneOfficesRequest,
  StandAloneOfficesSuccess,
  StandAloneOfficesSuccessPayload,
  PostStandAloneVendors,
  PostStandAloneVendorsFailure,
  PostStandAloneVendorsSuccess,
  PostStandAloneVendorsFailurePayload,
  PostStandAloneVendorsRequest,
  PostStandAloneVendorsSuccessPayload,
  PostStandAloneRequestVendors,
  PostStandAloneRequestVendorsFailure,
  PostStandAloneRequestVendorsSuccess,
  PostStandAloneRequestVendorsFailurePayload,
  PostStandAloneRequestVendorsRequest,
  PostStandAloneRequestVendorsSuccessPayload,
  PostStandAloneImpression,
  PostStandAloneImpressionFailure,
  PostStandAloneImpressionRequest,
  PostStandAloneImpressionFailurePayload,
  PostStandAloneImpressionSuccess,
  PostStandAloneImpressionSuccessPayload
} from './types';

export const vendors = (payload: VendorsRequest): Vendors => ({
    type: GET_VENDORS,
    payload,
});

export const vendorsSuccess = (payload: VendorsSuccessPayload): VendorsSuccess => ({
    type: GET_VENDORS_SUCCESS,
    payload,
});

export const vendorsFailure = (payload: VendorsFailurePayload): VendorsFailure => ({
    type: GET_VENDORS_ERROR,
    payload,
});

export const getMoveVendors = (): GetMoveVendors => ({
    type: GET_MOVE_VENDOR,
});

export const getMoveVendorsSuccess = (payload: GetMoveVendorsSuccessPayload): GetMoveVendorsSuccess => ({
    type: GET_MOVE_VENDOR_SUCCESS,
    payload,
});

export const getMoveVendorsFailure = (payload: GetMoveVendorsFailurePayload): GetMoveVendorsFailure => ({
    type: GET_MOVE_VENDOR_ERROR,
    payload,
});

export const categories = (payload: CategoriesRequest): Categories => ({
    type: GET_CATEGORIES,
    payload,
});

export const categoriesSuccess = (payload: CategoriesSuccessPayload): CategoriesSuccess => ({
    type: GET_CATEGORIES_SUCCESS,
    payload,
});

export const categoriesFailure = (payload: CategoriesFailurePayload): CategoriesFailure => ({
    type: GET_CATEGORIES_ERROR,
    payload,
});

//impression

export const impression = (payload: ImpressionRequest): Impression => ({
    type: POST_IMPRESSION,
    payload,
});

export const impressionSuccess = (payload: ImpressionSuccessPayload): ImpressionSuccess => ({
    type: POST_IMPRESSION_SUCCESS,
    payload,
});

export const impressionFailure = (payload: ImpressionFailurePayload): ImpressionFailure => ({
    type: POST_IMPRESSION_ERROR,
    payload,
});

//categories Questions

export const categoriesQuestions = (payload: CategoriesQuestionsRequest): CategoriesQuestions => ({
    type: GET_CATEGORIES_QUESTIONS,
    payload,
});

export const categoriesQuestionsSuccess = (payload: CategoriesQuestionsSuccessPayload): CategoriesQuestionsSuccess => ({
    type: GET_CATEGORIES_QUESTIONS_SUCCESS,
    payload,
});

export const categoriesQuestionsFailure = (payload: CategoriesQuestionsFailurePayload): CategoriesQuestionsFailure => ({
    type: GET_CATEGORIES_QUESTIONS_ERROR,
    payload,
});

//postVendors

export const postVendors = (payload: PostVendorsRequest): PostVendors => ({
    type: POST_VENDORS,
    payload,
});

export const postVendorsSuccess = (payload: PostVendorsSuccessPayload): PostVendorsSuccess => ({
    type: POST_VENDORS_SUCCESS,
    payload,
});

export const postVendorsFailure = (payload: PostVendorsFailurePayload): PostVendorsFailure => ({
  type: POST_VENDORS_ERROR,
  payload,
});

//request Vendors

export const requestVendors = (payload: RequestVendorsRequest): RequestVendors => ({
  type: REQUEST_VENDORS,
  payload,
});

export const requestVendorsSuccess = (payload: RequestVendorsSuccessPayload): RequestVendorsSuccess => ({
  type: REQUEST_VENDORS_SUCCESS,
  payload,
});

export const requestVendorsFailure = (payload: RequestVendorsFailurePayload): RequestVendorsFailure => ({
  type: REQUEST_VENDORS_ERROR,
  payload,
});

//home advisor

export const getHomeAdvisor = (payload: GetHomeAdvisorRequest): GetHomeAdvisor => ({
  type: GET_HOME_ADVISOR,
  payload,
});

export const getHomeAdvisorSuccess = (payload: GetHomeAdvisorSuccessPayload): GetHomeAdvisorSuccess => ({
  type: GET_HOME_ADVISOR_SUCCESS,
  payload,
});

export const getHomeAdvisorFailure = (payload: GetHomeAdvisorFailurePayload): GetHomeAdvisorFailure => ({
  type: GET_HOME_ADVISOR_ERROR,
  payload,
});


//stand alone vendors
export const standAloneVendors = (payload: StandAloneVendorsRequest): StandAloneVendors => ({
  type: GET_STANDALONE_VENDORS,
  payload,
});

export const standAloneVendorsSuccess = (payload: StandAloneVendorsSuccessPayload): StandAloneVendorsSuccess => ({
  type: GET_STANDALONE_VENDORS_SUCCESS,
  payload,
});

export const standAloneVendorsFailure = (payload: StandAloneVendorsFailurePayload): StandAloneVendorsFailure => ({
  type: GET_STANDALONE_VENDORS_ERROR,
  payload,
});



//stand alone offices
export const standAloneOffices = (payload: StandAloneOfficesRequest): StandAloneOffices => ({
  type: GET_STANDALONE_OFFICES,
  payload,
});

export const standAloneOfficesSuccess = (payload: StandAloneOfficesSuccessPayload): StandAloneOfficesSuccess => ({
  type: GET_STANDALONE_OFFICES_SUCCESS,
  payload,
});

export const standAloneOfficesFailure = (payload: StandAloneOfficesFailurePayload): StandAloneOfficesFailure => ({
  type: GET_STANDALONE_OFFICES_ERROR,
  payload,
});

//post stand alone vendors
export const postStandAloneVendors = (payload: PostStandAloneVendorsRequest): PostStandAloneVendors => ({
  type: POST_STANDALONE_VENDORS,
  payload,
});

export const postStandAloneVendorsSuccess = (payload: PostStandAloneVendorsSuccessPayload): PostStandAloneVendorsSuccess => ({
  type: POST_STANDALONE_VENDORS_SUCCESS,
  payload,
});

export const postStandAloneVendorsFailure = (payload: PostStandAloneVendorsFailurePayload): PostStandAloneVendorsFailure => ({
  type: POST_STANDALONE_VENDORS_ERROR,
  payload,
});

//post stand alone vendors
export const postStandAloneRequestVendors = (payload: PostStandAloneRequestVendorsRequest): PostStandAloneRequestVendors => ({
  type: POST_STANDALONE_REQUESTVENDORS,
  payload,
});

export const postStandAloneRequestVendorsSuccess = (payload: PostStandAloneRequestVendorsSuccessPayload): PostStandAloneRequestVendorsSuccess => ({
  type: POST_STANDALONE_REQUESTVENDORS_SUCCESS,
  payload,
});

export const postStandAloneRequestVendorsFailure = (payload: PostStandAloneRequestVendorsFailurePayload): PostStandAloneRequestVendorsFailure => ({
  type: POST_STANDALONE_REQUESTVENDORS_ERROR,
  payload,
});

//post stand alone impression
export const postStandAloneImpression = (payload: PostStandAloneImpressionRequest): PostStandAloneImpression => ({
  type: POST_STANDALONE_IMPRESSION,
  payload,
});

export const postStandAloneImpressionSuccess = (payload: PostStandAloneImpressionSuccessPayload): PostStandAloneImpressionSuccess => ({
  type: POST_STANDALONE_IMPRESSION_SUCCESS,
  payload,
});

export const postStandAloneImpressionFailure = (payload: PostStandAloneImpressionFailurePayload): PostStandAloneImpressionFailure => ({
  type: POST_STANDALONE_IMPRESSION_ERROR,
  payload,
});