export const phoneRegExp: RegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const emailRegExp: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const fullNameRegExp: RegExp = /^[a-zA-Z ]*$/;
export const wordRegExp: RegExp = /^[a-zA-Z]*$/;
export const numberRegExp: RegExp = /^[0-9.]*$/;
export const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const errorMessages = {
    'required' : "This field is required"
}

/**
 * SLIDER STARTS FROM 50 INSTEAD OF ZERO.
 */
export const SAVINGCALC_DEFAULT_VALUE = 50;
/**
 * Saving Calculator slider in home valuation.
 * * Slider has 5 parts to 100%. So 5*20 = 100
 * * So, one part i.e, 20% has weight of 50
 * * So, 50 divided by 20 which equals to 2.5 which is multiplier for percent selected in slider.
 */
export const SAVINGCALC_MULTIPIER = 2.5;

export const tooltipText: string ='Our valuations come from independent providers used by many in the lending industry to help support their mortgage decisions. Each one applies a unique approach so we can provide a range of values you can use as a starting point for discussions with a real estate professional.Historical values are estimates, not actual transaction values';
export const estimatedValueTooltipText: string ='This estimated home value is taking into account several sources. This is not a formal appraisal and we still recommend working with a real estate agent or professional appraiser for an updated valuation.';
export const sinceDataTooltipText: string ='Data is updated quarterly when your account is active.';
export const pendingLoanAmountTooltipText: string ='Pending loan amount is an estimate of the remaining loan amount on your home.';
export const equityBalanceTooltipText: string ="Equity balance is an estimate of the difference between your home’s valuation and your pending loan amount.";
export const remainingMortgageBalanceTooltipText: string ="Remaining mortgage balance is an estimate of the remaining loan amount on your home.";
export const estimatedNetProceedsTooltipText: string ="This is an estimate of the dollar amount you would earn from the sale of your home. This number takes into account your estimated home value, remaining loan balance, and closing costs amounting to 6% of your home value.";

export const loanTermOptions: { value: string, label: string }[] = [
    { value: '30-years fixed', label: '30-years fixed' },
    { value: '30-years ARM', label: '30-years ARM' },
    { value: '20-years fixed', label: '20-years fixed' },
    { value: '20-years ARM', label: '20-years ARM' },
    { value: '15-years fixed', label: '15-years fixed' },
    { value: '15-years ARM', label: '15-years ARM' },
    { value: '10-years fixed', label: '10-years fixed' },
    { value: '10-years ARM', label: '10-years ARM' },
    { value: '7-years ARM', label: '7-years ARM' },
    { value: '5-years ARM', label: '5-years ARM' },
    { value: '3-years ARM', label: '3-years ARM' },
];
