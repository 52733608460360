import { Store } from "react-notifications-component";

export const DefaultToast = (config: ToastConfig) => Store.addNotification({
    insert: 'bottom',
    container: 'bottom-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    duration: 5000,
    onScreen: true,
    ...config,
});

export interface ToastConfig {
    title: string;
    message: string,
    type: "success" | "danger" | "info" | "default" | "warning";
    insert?: "bottom" | "top",
    container?: "bottom-center" | "top-full" | "top-left" | "top-right" | "top-center" | "center" | "bottom-full" | "bottom-left" | "bottom-right";
    animationIn?: string[],
    animationOut?: string[],
    duration?: number | undefined;
    onScreen?: boolean;
};