import rentFill from '../../Assets/images/HomeValuation/rentFill.png';
import rent from '../../Assets/images/HomeValuation/rent.png';
import buildingFill from '../../Assets/images/HomeValuation/buildingFill.png';
import restate from '../../Assets/images/HomeValuation/restate.png';
import restateFill from '../../Assets/images/HomeValuation/restateFill.png';
import building from '../../Assets/images/HomeValuation/building.png';

const AffordabalityCardData = [
    {
        title: 'Sell & Buy ',

        subTitle: 'a New Home',
        image: restateFill,
        imageNotSelected: restate,
    },
    {
        title: 'Buy a Second Home ',
        subTitle: 'or Vacation Home',

        image: buildingFill,
        imageNotSelected: building,
    },
    {
        title: 'Invest & Rent ',
        subTitle: 'a New Property',
        image: rentFill,
        imageNotSelected: rent,
    },
];

export default AffordabalityCardData;
