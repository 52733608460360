import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={118.739}
            height={187.778}
            viewBox="0 0 118.739 187.778"
            {...props}
        >
            <defs>
                <style>
                    {
                        '.prefix__a{fill:#a95c4b}.prefix__d{fill:#3662c1}.prefix__e{fill:#263238}.prefix__f{fill:#407bff}.prefix__h{fill:#e68922}.prefix__k{fill:#3c536f}.prefix__o{fill:#fff}'
                    }
                </style>
            </defs>
            <path className="prefix__a" d="M80.737 172.145l6.822-3.939 4.165 8.339-5.695 3.3z" />
            <path
                d="M83.189 145.258c4.833-11.2 12.793-24.2 9.531-36.209l-21.721-3.433s-4.491 26.832-3.6 37.838c.925 11.444 14.431 31.755 14.431 31.755l7.779-4.566s-3.379-17.362-6.42-25.385z"
                fill="#6d89cf"
            />
            <path
                d="M83.189 145.258c4.833-11.2 12.793-24.2 9.531-36.209l-21.721-3.433s-4.491 26.832-3.6 37.838c.925 11.444 14.431 31.755 14.431 31.755l7.779-4.566s-3.379-17.362-6.42-25.385z"
                fill="#5281ec"
            />
            <path
                className="prefix__d"
                d="M90.397 170.553l-8.887 5.246-2.828-3.676 10.472-5.545zM84.78 141.721c2.612-5.667 5.628-11.7 7.334-17.772-5.131.875-9.478 5.045-7.334 17.772z"
            />
            <path
                className="prefix__e"
                d="M91.501 174.568a.323.323 0 00-.522.029c-1.12 1.919-4.346 3.763-5.592 4.424a1.259 1.259 0 00-.608.735 19.149 19.149 0 01-3.018 4.671 6.057 6.057 0 00.439 1.249.91.91 0 001.391.318l9.913-8.054a.765.765 0 00.049-1.147 22.075 22.075 0 01-2.052-2.225z"
            />
            <path className="prefix__a" d="M93.548 175.945l7.876.075-.715 9.3-6.582-.053z" />
            <path
                className="prefix__e"
                d="M101.399 183.89a.293.293 0 00-.394-.307c-2.146.786-5.765.185-7.126-.087a1.319 1.319 0 00-.951.187 19.072 19.072 0 01-4.832 1.725 6.689 6.689 0 00-.53 1.177.907.907 0 00.861 1.192l12.142-.006a.766.766 0 00.766-.8 22.094 22.094 0 01.064-3.081z"
            />
            <path
                className="prefix__f"
                d="M106.411 144.617c2.455-11.7 6.875-26.9 3.761-32.936l-25.427-3.937s3.162 24.2 4 35.467c.866 11.719 4.295 35.931 4.295 35.931l8.524.022s5.93-20.597 4.847-34.547z"
            />
            <path
                d="M106.411 144.617c2.455-11.7 6.875-26.9 3.761-32.936l-25.427-3.937s3.162 24.2 4 35.467c.866 11.719 4.295 35.931 4.295 35.931l8.524.022s5.93-20.597 4.847-34.547z"
                opacity={0.4}
                fill="#6d89cf"
            />
            <path className="prefix__d" d="M102.491 179.485H92.235l-.158-4.269 11.293.183z" />
            <path
                className="prefix__a"
                d="M91.459 70.808l-.649.521-.6.471c-.4.308-.808.6-1.214.893a57.624 57.624 0 01-2.479 1.679 57.227 57.227 0 01-5.247 2.994 43.383 43.383 0 01-5.755 2.433 28.448 28.448 0 01-6.593 1.465l-.44.043a4.425 4.425 0 01-3.113-.894 12.183 12.183 0 01-3.594-4.577 14.2 14.2 0 01-.863-2.292 19.807 19.807 0 01-.516-2.212c-.116-.727-.2-1.442-.258-2.147s-.06-1.405-.059-2.1.052-1.378.112-2.06L60.314 64l.179-1.074a2.8 2.8 0 015.333-.645l.223.529c.084.2.181.5.277.761l.281.807c.183.537.385 1.067.583 1.585a28.888 28.888 0 001.288 2.927 11.816 11.816 0 001.447 2.267 2.572 2.572 0 001.2.917l-3.552-.852a23.574 23.574 0 004.123-1.427c.716-.311 1.439-.673 2.159-1.049s1.453-.767 2.175-1.183a86.49 86.49 0 004.317-2.681c.717-.467 1.419-.96 2.125-1.441l1.044-.732.964-.687.219-.156a5.606 5.606 0 016.763 8.937z"
            />
            <path
                className="prefix__a"
                d="M65.811 62.696L63.03 64.51c-3.407.748-3.611-3.8-3.611-3.8l-.981-2.147a1.76 1.76 0 01.759-2.147l1.934-1.283a1.835 1.835 0 012.539.32l1.151 1.708a3.637 3.637 0 01.6 1.666z"
            />
            <path
                className="prefix__h"
                d="M91.275 59.505c-5.328-.318-16.278 6.554-16.278 6.554-1.262 4.015.936 11.627 4.145 13.509 0 0 8.153-3.5 11.99-6.907 4.636-4.117 10.92-12.515.143-13.156z"
            />
            <path
                d="M91.768 59.512a99.179 99.179 0 0121.6 3.7c4.063 1.225 6.595 4.265 4.774 12.692-3.693 17.1-3.389 31.065-6.546 36.929 0 0-36.966 2.284-41.909-6.243 1-21.109 11.12-37.2 15.633-43.846a7.359 7.359 0 016.448-3.232z"
                fill="#feb33c"
            />
            <path
                className="prefix__a"
                d="M93.862 61.85c.955-.683 1.745-.077 2.337-1.236a4.283 4.283 0 00-.02-2.883l10.676-3.79c-.755 3.6-1.344 6.436.038 8.124.049 2.774-6.36 5.309-11.776 4.28-4.769-.906-2.629-3.512-1.255-4.495z"
            />
            <path
                d="M96.275 58.062l8.081-.742c-2.444 2.789-6.247 3.932-8.033 2.89a4.294 4.294 0 00-.048-2.148z"
                opacity={0.2}
            />
            <path d="M95.492 47.943c-.426-4.138 3.606-9.374 6.976-7.568s-6.976 7.568-6.976 7.568z" />
            <path
                className="prefix__a"
                d="M109.703 50.304c-2.136 4.714-3.688 7.869-7.266 8.842-7.109 1.932-9.448-1.975-8.762-7.246.618-4.745 4.008-11.656 9.458-11.733a7.132 7.132 0 016.57 10.137z"
            />
            <path d="M108.472 54.082c-2.437-.354-4.22-4.754-1.67-7.057s1.67-4.514-.181-5.168-5.213-.181-5.981-1.828 4.356-2.844 7.335-.948 2.821.745 2.821.745a.716.716 0 01-.558 1.173c2.341 1.557 4.937 5.3 2.521 6.839 3.048 1.713-1.669 5.785-4.287 6.244z" />
            <path
                className="prefix__a"
                d="M109.407 55.409a4.87 4.87 0 01-3.539 1.232c-1.721-.116-1.5-1.471-.552-2.8.851-1.2 2.247-2.9 3.75-2.133a2.224 2.224 0 01.341 3.701z"
            />
            <g>
                <path
                    className="prefix__f"
                    d="M38.684 76.177l-2.338 6.375a3.2 3.2 0 01-2.536 2.127l-31.034 4.7c-2 .3-3.187 2.358-2.645 4.59l10.866 44.753a4.375 4.375 0 004.607 3.491l73.878-11.191c2-.3 3.187-2.358 2.645-4.59L79.037 72.51a4.376 4.376 0 00-4.607-3.491L41.221 74.05a3.2 3.2 0 00-2.537 2.127z"
                />
                <path
                    className="prefix__k"
                    d="M38.684 76.177l-2.338 6.375a3.2 3.2 0 01-2.536 2.127l-31.034 4.7c-2 .3-3.187 2.358-2.645 4.59l10.866 44.753a4.375 4.375 0 004.607 3.491l73.878-11.191c2-.3 3.187-2.358 2.645-4.59L79.037 72.51a4.376 4.376 0 00-4.607-3.491L41.221 74.05a3.2 3.2 0 00-2.537 2.127zM22.759 10.747a21.093 21.093 0 012.177.114 26.2 26.2 0 0145.637 5.8 18 18 0 01-3.047 35.746H22.759a20.829 20.829 0 010-41.657z"
                />
                <path className="prefix__f" d="M24.688 45.122v51.929h38.084V56.426l-11.3-11.3z" />
                <path d="M24.688 45.122v51.929h38.084V56.426l-11.3-11.3z" fill="#bed2fa" opacity={0.6} />
                <path d="M51.471 56.426v-11.3l11.3 11.3z" opacity={0.4} fill="#407bff" />
                <path
                    d="M52.264 81.278l-3.513 5.864a4.129 4.129 0 01-2.877 1.979l-31.034 4.7a4.181 4.181 0 00-3.464 4.24l1.8 40.814a3.235 3.235 0 003.789 3.135l73.879-11.191a4.181 4.181 0 003.463-4.234l-2.173-49.177a3.235 3.235 0 00-3.789-3.135l-33.209 5.03a4.133 4.133 0 00-2.872 1.975z"
                    fill="#5b7088"
                />
                <path
                    className="prefix__o"
                    d="M53.346 37.591a.668.668 0 01-.473-1.141 12.93 12.93 0 10-18.286 0 .668.668 0 11-.945.945 14.266 14.266 0 1120.176 0 .665.665 0 01-.472.196z"
                />
                <path
                    className="prefix__o"
                    d="M49.453 41.59V26.698a2.43 2.43 0 00-2.429-2.43h-6.586a2.43 2.43 0 00-2.43 2.43v14.893h-2.477l8.2 7.063 8.2-7.063z"
                />
            </g>
            <g>
                <path
                    className="prefix__a"
                    d="M115.836 78.586l-.327.739-.309.674c-.207.445-.428.877-.645 1.31a56.976 56.976 0 01-1.383 2.55 56.434 56.434 0 01-3.167 4.918c-.581.8-1.175 1.592-1.809 2.373s-1.307 1.541-2.019 2.292a29.617 29.617 0 01-4.894 4.24l-.371.255a3.054 3.054 0 01-.6.318 18.258 18.258 0 01-5.888 1.111 34.477 34.477 0 01-5.087-.2 47.251 47.251 0 01-4.788-.806 41.043 41.043 0 01-4.655-1.309 2.83 2.83 0 01.7-5.488l.116-.009 4.364-.373c1.45-.13 2.882-.27 4.272-.455.694-.094 1.376-.184 2.042-.3s1.307-.253 1.921-.385a10.46 10.46 0 002.889-1.036l-.975.572a25.62 25.62 0 002.9-3.168c.473-.6.929-1.237 1.378-1.886s.9-1.316 1.334-2a84.771 84.771 0 002.5-4.235c.407-.72.791-1.456 1.184-2.183l1.092-2.109.131-.253a5.606 5.606 0 0110.1 4.845z"
                />
                <path
                    className="prefix__h"
                    d="M117.55 69.074c2.588 9.3-3.983 18.544-3.983 18.544-3.485 1-12.659-2.578-14.606-6 0 0 3.2-8.793 7-13.468 3.521-4.328 10.072-4.527 11.589.924z"
                />
            </g>
            <path
                className="prefix__a"
                d="M81.67 91.77l.512 3.28c-.717 3.414-4.947 1.732-4.947 1.732l-2.361.013a1.761 1.761 0 01-1.646-1.574l-.376-2.291a1.836 1.836 0 011.334-2.184l2.03-.348a3.635 3.635 0 011.766.136z"
            />
        </svg>
    );
}

export default SvgComponent;
