import React from 'react';
import { makeStyles } from '@material-ui/core';
import '../../styles/diyMovingCard.scss';
// import Rating from '@material-ui/lab/Rating';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import StarIcon from '@material-ui/icons/Star';
import Button from './Button';

interface cardProps {
    discount?: any;
    logo?: any;
    title?: any;
    rate?: any;
    reviews?: any;
    knowMoreHandler?: any;
    onNameClick?: any;
    showKnowMore?: boolean;
}

const DiyMovingCard = (props: cardProps) => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));

    const classes = useStyles();

    return (
        <div className={`main-class-diy ${classes}`}>
            <div className="discountmainDiv">
                {props.discount && parseInt(props.discount) && parseInt(props.discount) > 0 ? (
                    <div className="discount-div">
                        <div className="tag-div">
                            <LocalOfferIcon
                                style={{
                                    color: '#fff',
                                    height: '20px',
                                    width: '20px',
                                }}
                            />
                        </div>
                        <div className="discount-value">
                            Get{' '}
                            {props.discount &&
                            props.discount.toString().split('.')[1] &&
                            parseInt(props.discount.toString().split('.')[1]) > 0
                                ? props.discount
                                : props.discount.toString().split('.')[0]}
                            % Off
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="body-div">
                <div className="logo-div">
                    <img src={props.logo} className="logo-diy" alt="" />
                </div>
                <div className="title-div" onClick={props.onNameClick}>
                    {props.title}
                </div>
                {/* {props.rate ? (
                    <div className="rating-capsule-div">
                        <div className="star-diy">
                            <StarIcon style={{ color: '#fff', height: '20px', width: '20px' }} />
                        </div>

                        <div className="rate-diy">{props.rate}</div>
                    </div>
                ) : null} */}
                {/* {props.rate ? (
                    <div className="rating-div">
                        <Rating name="read-only" value={props.rate} precision={0.1} readOnly />
                    </div>
                ) : null} */}
                {/* <div style={{ height: '20px' }}>
                    {' '}
                    {props.reviews ? <div className="reviews-div">{props.reviews} Verified Reviews</div> : null}
                </div> */}
                <div className="button-div">
                    {props.showKnowMore ? (
                        <Button
                            className="button-know"
                            fontSize="14px"
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100b28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : '#273E59'
                            }
                            borderRadius={isDouglasElliman ? '0px !important' : '8px !important'}
                            onClick={props.knowMoreHandler}
                        >
                            Learn More
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default DiyMovingCard;
