import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            width={props.width ? props.width : 87.841}
            height={props.height ? props.height : 86.841}
            className="checkBoximg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 87.841 86.841"
        >
            <defs>
                <style>
                    {`.PhoneStepprefix__a{fill:${
                      props.fromlanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="PhoneStepprefix__a"
                d="M47.6 54.503l-5.674 3.275a2.232 2.232 0 01-2.746-.422l-.018-.032a56.021 56.021 0 01-11.296-19.565l-.031-.054a2.236 2.236 0 011.006-2.591l5.673-3.276a5.489 5.489 0 002.087-7.556l-5.207-9.018a5.56 5.56 0 00-7.585-2.071l-2.37 1.368a23.046 23.046 0 00-10.443 27.532l1.685 4.775a59.816 59.816 0 0010.983 19.023l3.294 3.85a23.044 23.044 0 0029.066 4.723l2.325-1.343a5.559 5.559 0 002.069-7.586l-5.23-9.059a5.489 5.489 0 00-7.588-1.973zm9.554 12.856a1.817 1.817 0 01-.624 2.494l-2.376 1.372a19.306 19.306 0 01-24.355-3.914l-3.292-3.848a56.051 56.051 0 01-10.3-17.84l-1.688-4.777A19.306 19.306 0 0123.31 17.8l2.325-1.342a1.817 1.817 0 012.493.626l5.236 9.069a1.749 1.749 0 01-.717 2.45l-5.673 3.276a5.983 5.983 0 00-2.685 7.019l.02.035a59.733 59.733 0 0012.028 20.831l.034.06a5.989 5.989 0 007.424 1.193l5.674-3.276a1.749 1.749 0 012.48.605zm0 0M47.759 7.63a1.87 1.87 0 001.348 2.275A34.464 34.464 0 0173.89 52.832a1.87 1.87 0 003.595 1.027l.005-.017A38.2 38.2 0 0050.032 6.284a1.87 1.87 0 00-2.273 1.347zm0 0"
            />
            <path
                className="PhoneStepprefix__a"
                d="M46.746 17.993a1.87 1.87 0 10-.941 3.619l.016.004a22.643 22.643 0 0116.487 27.451q-.095.379-.202.756a1.87 1.87 0 003.595 1.027l.004-.018a26.381 26.381 0 00-18.117-32.61q-.423-.123-.842-.229zm0 0M40.874 32.162a1.869 1.869 0 001.35 2.275 9.7 9.7 0 017.068 11.759c-.027.111-.058.222-.088.332a1.87 1.87 0 003.595 1.026l.005-.017A13.441 13.441 0 0043.57 30.93q-.21-.06-.42-.112a1.869 1.869 0 00-2.276 1.345zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
