import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 37.938}
            height={props.height ? props.height : 31}
            viewBox="0 0 37.938 31"
        >
            <defs>
                <style>
                    {// ".prefix__a{fill:#fff}.prefix__b{fill:#bebebe}.prefix__c{fill:#6b6c6f}"
                    `.movingSvgprefix__a{fill:#fff}.movingSvgprefix__b{fill:${
                        window.location.pathname.includes('movers') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.movingSvgprefix__c{fill:${
                        window.location.pathname.includes('movers') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path
                className="movingSvgprefix__a"
                d="M37.434 16.806v8.482a1.894 1.894 0 01-1.893 1.894h-2.365a3.314 3.314 0 10-6.628 0h-4.04V16.806zm0 0"
            />
            <path
                className="movingSvgprefix__b"
                d="M32.055 11.376l5.379 5.43H22.505v-5.928h8.357a1.676 1.676 0 011.193.498zm0 0M29.858 23.868a3.314 3.314 0 11-3.313 3.314 3.314 3.314 0 013.313-3.314zm0 0"
            />
            <path
                className="movingSvgprefix__a"
                d="M22.504 16.804v10.379H11.393a3.314 3.314 0 00-6.628 0H2.397a1.894 1.894 0 01-1.893-1.894V2.397A1.894 1.894 0 012.397.504h18.215a1.893 1.893 0 011.892 1.893zm-5.944-6.185h1.967l-2.1-1.53-4.614-3.359-6.715 4.889h1.968v7.931h9.5zm0 0"
            />
            <path
                className="movingSvgprefix__b"
                d="M18.528 10.622H16.56v7.931h-9.5v-7.931H5.098l6.715-4.89 4.614 3.359zm0 0M8.079 23.868a3.314 3.314 0 11-3.315 3.314 3.313 3.313 0 013.315-3.314zm0 0"
            />
            <path
                className="movingSvgprefix__c"
                d="M2.4 27.686h1.9a3.817 3.817 0 007.567 0h14.208a3.817 3.817 0 007.567 0h1.9a2.4 2.4 0 002.4-2.4v-8.479a.477.477 0 00-.009-.089.025.025 0 000-.007.487.487 0 00-.025-.086v-.009a.489.489 0 00-.042-.08v-.007a.5.5 0 00-.061-.076l-5.379-5.43a2.193 2.193 0 00-1.551-.647h-7.866V2.4a2.4 2.4 0 00-2.4-2.4H2.4A2.4 2.4 0 000 2.4v22.888a2.4 2.4 0 002.4 2.398zm27.461 2.307a2.81 2.81 0 112.811-2.81 2.81 2.81 0 01-2.814 2.81zm-6.85-18.611h7.854a1.181 1.181 0 01.835.349l4.527 4.569H23.009zm0 5.928h13.92v7.978a1.392 1.392 0 01-1.39 1.391h-1.9a3.817 3.817 0 00-7.567 0h-3.065zM8.08 29.993a2.81 2.81 0 112.81-2.81 2.81 2.81 0 01-2.81 2.81zM1.007 2.4A1.392 1.392 0 012.4 1.007h18.212A1.391 1.391 0 0122 2.4v24.279H11.862a3.817 3.817 0 00-7.567 0H2.4a1.392 1.392 0 01-1.39-1.391zm0 0"
            />
            <path
                className="movingSvgprefix__c"
                d="M5.098 11.128h1.464v7.427a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5v-7.427h1.463a.5.5 0 00.3-.911l-1.257-.915V7.863a.503.503 0 00-1.007 0v.7l-4.452-3.235a.5.5 0 00-.593 0l-6.715 4.889a.5.5 0 00.3.911zm6.715-4.77l5.168 3.763h-.42a.5.5 0 00-.5.5v7.427H7.569v-7.426a.5.5 0 00-.5-.5h-.42zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
