import React from 'react';
import BasicTableData from './BasicTableData';
import './BasicTable.scss';
import ButtonNew from '../atoms/Button';
import UpArrowImage from '../../Assets/images/HomeValuation/uparrow.png';
import AnalyticsService from '../../pages/analytics-service';

export default function BasicTable(props: BasicTableProps) {

    const { brokerage_key, move_key, analyticsData } = props;

    const handleClickViewMore = (amt: number, project: string) => {
        AnalyticsService.buttonClicked({
            ...analyticsData,
            button_copy: "View more details",
            category_name: `View More Details Button Clicked for ${project} Project`,
        });

        window.open(`https://www.acornfinance.com/apply/?d=MOV-TOP5-${amt}K&loanAmount=${amt * 1000}&utm_medium=MOV-${brokerage_key}&utm_content=MOV-${move_key}#/financing`, '_blank');
      
    }

    const handleTableCard = () => {
        AnalyticsService.cardClicked({
            ...analyticsData,
            category_name: `Home Projects Clicked`,
        });
    }

    return (
        <div className="TableContainer">
            <table>
                <tbody>
                <tr>
                    <th className="TableHeading">Projects</th>
                    <th className="TableHeading">Est. Project Cost*</th>
                    <th className="TableHeading">Est. Value Increase*</th>
                    <th className="TableHeading">Est. Monthly Payment*</th>
                    <th className="TableHeading"></th>
                </tr>
                {BasicTableData.map((data, i) => {
                    const {cost, amt, project, increaseCost, payment, per } = data;
                    return (
                        <tr key={i} onClick={handleTableCard}> 
                            <td className="TableConatinerText">{project}</td>
                            <td className="TableConatinerText">
                                {cost}
                            </td>
                            <td style={{ color: '#00D56D' }} className="TableConatinerText">
                            <img src={UpArrowImage}  alt="UpArrow"/> {increaseCost}
                                <span style={{ color: '#333333', fontWeight: 600, paddingLeft: '3px' }}>
                                    {' '}
                                    ({per})
                                </span>
                            </td>
                            <td className="TableConatinerText">
                                From
                                <span style={{ color: '#0066F5', fontWeight: 600, paddingLeft: '3px' }}>
                                    {payment}
                                </span>
                                /month
                            </td>
                            <td className="TableConatinerText">
                                <ButtonNew
                                    backgroundColor="#ffffff"
                                    color={'#100B28'}
                                    border={`1px solid #100B28`}
                                    borderRadius={'5px'}
                                    className="buttonHomeService"
                                    fontSize="14px"
                                    onClick={() => handleClickViewMore(amt, project)}
                                >
                                    View more details
                                </ButtonNew>
                            </td>
                        </tr>
                    );
                })}
              </tbody>
            </table>
        </div>
    );
}

export interface BasicTableProps {
    brokerage_key?: string;
    move_key?: string;
    analyticsData?: any;
};
