import React, { Component } from 'react';
import './Refinance.scss';
// import refineConceige from '../../Assets/images/HomeValuation/refineConceige.png';
import Button from '../atoms/Button';
import Skeleton from 'react-loading-skeleton';
import AnalyticsService from '../../pages/analytics-service';
import { renderContactButtonText } from '../../utils/common-functions';

type initialProps = {
    image?: any;
    onContact?: any;
    serviceAgentData?: any;
    serviceProviders?: any;
    mortgageData?: any;
    isLoading?: boolean;
    analyticsData?: any;
};

type initialState = {};

export class Refinance extends Component<initialProps, initialState> {
    state: initialState = {};
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        AnalyticsService.cardViewed({
            ...this.props.analyticsData,
            category_name: "Mortgage Contact Card Viewed"
        });
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        const { serviceAgentData, serviceProviders } = this.props;
        const agentFullName = serviceAgentData?.first_name || '' + ' ' + serviceAgentData?.last_name || '';
        const getMessage = () => {
            if (serviceAgentData?.photo_url?.length) {
                return `Hi, I'm ${agentFullName} from ${serviceAgentData?.service_provider?.name}. I am here to help answer any
                questions.`;
            } else if (!serviceAgentData?.photo_url?.length && serviceAgentData?.service_provider?.logo?.length) {
                return `Hi, I'm ${agentFullName} from ${serviceAgentData?.service_provider?.name}. I am here to help answer any questions.`;
            } else if (
                serviceProviders?.logo &&
                !(serviceAgentData?.photo_url?.length && serviceAgentData?.service_provider?.logo?.length)
            ) {
                return `${serviceProviders?.name} is here to help you manage your mortgage whether you want to refinance or take advantage of your home equity.`;
            }
        };

        return (
            <>
                {!this.props.isLoading ? (
                    <div className="main-div-Refinance">
                        <div className="main--head-div-Refinance">
                            {(serviceAgentData && serviceAgentData?.service_provider?.name) ||
                                serviceProviders?.name ||
                                'Thinking About Refinancing?'}
                        </div>
                        <div
                            className={`main--image-div-Refinance ${
                                serviceAgentData?.photo_url ? 'agent-headshot' : ''
                            }`}
                        >
                            {serviceAgentData?.photo_url?.length ? (
                                <img
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        borderRadius: '100px',
                                    }}
                                    src={serviceAgentData?.photo_url}
                                />
                            ) : (
                                <img
                                    width={186}
                                    style={{ paddingTop: '20px' }}
                                    src={serviceAgentData?.service_provider?.logo || serviceProviders?.logo}
                                />
                            )}
                        </div>
                        {serviceAgentData?.photo_url?.length ? (
                            <div className="main--broker-div-Refinance">
                                <img
                                    width={186}
                                    style={{ paddingTop: '20px' }}
                                    src={serviceAgentData?.service_provider?.logo || serviceProviders?.logo}
                                ></img>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="main--content-div-Refinance">{getMessage()}</div>
                        <div className="main--button-div-Refinance">
                            {' '}
                            <Button
                                backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                width="113px"
                                height="38px"
                                color="#FFF"
                                borderRadius={'5px'}
                                fontSize="14px"
                                onClick={this.props.onContact}
                            >
                                {renderContactButtonText(serviceAgentData, serviceProviders)}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Skeleton width={'98%'} height={'98%'} />
                )}
            </>
        );
    }
}
export default Refinance;
