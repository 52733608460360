import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={485}
            height={149.251}
            viewBox="0 0 485 149.251"
        >
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={-0.237}
                    y1={0.502}
                    x2={0.756}
                    y2={0.502}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#ff9b2b" />
                    <stop offset={0.191} stopColor="#f69427" />
                    <stop offset={0.602} stopColor="#ea8c23" />
                    <stop offset={1} stopColor="#e68922" />
                </linearGradient>
                <linearGradient
                    id="prefix__b"
                    x1={1.075}
                    y1={-0.178}
                    x2={0.044}
                    y2={0.973}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#feb840" />
                    <stop offset={0.369} stopColor="#feb33c" />
                    <stop offset={0.75} stopColor="#fea734" />
                    <stop offset={1} stopColor="#ff9b2b" />
                </linearGradient>
                <linearGradient
                    id="prefix__c"
                    x1={1.018}
                    y1={-0.026}
                    x2={0.042}
                    y2={0.945}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#feb840" />
                    <stop offset={0.25} stopColor="#feab36" />
                    <stop offset={0.631} stopColor="#fe9f2e" />
                    <stop offset={1} stopColor="#ff9b2b" />
                </linearGradient>
                <linearGradient id="prefix__d" x1={-0.238} y1={0.5} x2={0.762} y2={0.5} xlinkHref="#prefix__a" />
                <style>
                    {slug &&
                    slug.color_shade1 &&
                    slug.color_shade2 &&
                    slug.color_shade3 &&
                    slug.color_shade4 &&
                    slug.primary_color
                        ? `.brokerageprefix__b{fill:#5b7088}.brokerageprefix__c{fill:${slug.color_shade2}}.brokerageprefix__d{fill:#bed2fa}.brokerageprefix__e{fill:#ffd5c0}.brokerageprefix__f{fill:#e8988c}.brokerageprefix__i{fill:#7eb3ff}.brokerageprefix__k{fill:${slug.color_shade3}}.brokerageprefix__l{fill:#fff}.brokerageprefix__m{fill:#3c3c3b}.brokerageprefix__n{fill:#575756}.brokerageprefix__o{fill:${slug.color_shade1}}.brokerageprefix__p{fill:#9d9d9c}.brokerageprefix__q{fill:#c8e9f7}.brokerageprefix__r{fill:${slug.primary_color}}.brokerageprefix__s{fill:#1e2859}.brokerageprefix__u{fill:#e68922}.brokerageprefix__v{fill:#ffd980}.brokerageprefix__x{fill:#fec1b5}`
                        : '.brokerageprefix__b{fill:#5b7088}.brokerageprefix__c{fill:#162c47}.brokerageprefix__d{fill:#bed2fa}.brokerageprefix__e{fill:#ffd5c0}.brokerageprefix__f{fill:#e8988c}.prefix__i{fill:#7eb3ff}.brokerageprefix__k{fill:#3c536f}.brokerageprefix__l{fill:#fff}.brokerageprefix__m{fill:#3c3c3b}.brokerageprefix__n{fill:#575756}.brokerageprefix__o{fill:#08192f}.brokerageprefix__p{fill:#9d9d9c}.brokerageprefix__q{fill:#c8e9f7}.brokerageprefix__r{fill:#273e59}.brokerageprefix__s{fill:#1e2859}.brokerageprefix__u{fill:#e68922}.brokerageprefix__v{fill:#ffd980}.brokerageprefix__x{fill:#fec1b5}'}
                </style>
            </defs>
            <path
                d="M485 144.419c-50.479-.2-123.9-.113-205.638-.113-152.24 0-275.654-.318-275.654 1.555s123.414 3.391 275.654 3.391c81.734 0 155.159-.438 205.638-1.133z"
                fill="#002e62"
            />
            <path className="brokerageprefix__b" d="M354.564 122.954h27.09v23.028h-27.09z" />
            <path className="brokerageprefix__c" d="M387.83 145.983h-6.177v-23.029h6.177z" />
            <path className="brokerageprefix__b" d="M360.509 99.926h27.09v23.028h-27.09z" />
            <path className="brokerageprefix__c" d="M393.775 122.954h-6.177V99.926h6.177z" />
            <path
                className="brokerageprefix__d"
                d="M433.788 49.944c.07-1.2.153-2.672-1.824-2.225-2.444.553-13.714 2.808-17.76 3.65-1.532.319-4.385.761-4.63 1.925-.284 1.346.916 1.548 2.109 1.629 3.38.231 13.788-.219 17.46-.486a5.439 5.439 0 004.645-4.493z"
            />
            <path
                className="brokerageprefix__d"
                d="M429.498 34.857a1.068 1.068 0 00-1.8-.406c-.8.727-17.29 16.806-17.814 17.5a1.927 1.927 0 00-.4 2.029c.739 1.623 4.259.63 5.443-.33 1.215-.986 14.021-17.158 14.336-17.55a1.184 1.184 0 00.235-1.243z"
            />
            <path
                className="brokerageprefix__e"
                d="M444.22 107.619c.067-4.055.121-8.535-.827-11.7a2.888 2.888 0 00-3.55-2.164c-.741.1-1.852.984-2.261 2.991-.511 2.5-.219 6.517-.1 9.143.214 4.622 2.163 28.727 2.523 33.29.163 2.078.976 2.475 1.69 2.448 1.253-.047 1.513-1.33 1.606-2.571.055-.718.827-25.844.919-31.437zM437.191 139.877c-1.355-8.219-6-29.842-6.5-31.7a6.81 6.81 0 00-3.123-4.229 2.5 2.5 0 00-3.4 1.661 7.934 7.934 0 00.351 2.92c1.289 4.681 8.987 29.256 9.678 31.254a2.257 2.257 0 002.109 1.647c.649-.058 1.04-.713.885-1.553z"
            />
            <path
                className="brokerageprefix__f"
                d="M444.221 107.62a433.96 433.96 0 01-.066 3.042c-2.652-.482-5.278-.53-6.313.693-.179-2.486-.31-4.446-.358-5.463-.121-2.626-.413-6.639.1-9.142.409-2.008 1.521-2.9 2.262-2.991a2.887 2.887 0 013.549 2.164c.947 3.162.892 7.641.826 11.697zM431.496 111.707c-2.347-.947-4.9-1.287-6.25-.646-.324-1.09-.578-1.961-.735-2.533a7.962 7.962 0 01-.35-2.921 2.5 2.5 0 013.4-1.66 6.8 6.8 0 013.122 4.228c.11.397.403 1.68.813 3.532z"
            />
            <path
                d="M446.501 106.598c-.336-1.69-.786-4.547-1.172-6.734a23.6 23.6 0 01-.354-5.2c.206-4.117 1.334-7.534.315-11.7a22.247 22.247 0 00-2.292-5.623 10.391 10.391 0 00-4.225-3.429 8.962 8.962 0 00-5.12-.705c-1.066.118-5.519-.084-6.623.075a2.239 2.239 0 00-1.916 1.352c-.737 2.761-1.9 6.853-2.707 11.3a126.321 126.321 0 00-2.006 20.573c0 1.9.958 2.137 3.064 2.353a91.608 91.608 0 0011.289.146c2.433-.105 4.269-.164 6.458-.307l1.038-12.076 1.137 11.92a60.545 60.545 0 002.252-.227c1.07-.137 1.001-1.02.862-1.718z"
                fill="#120e59"
            />
            <path
                d="M446.501 106.598c-.336-1.69-.786-4.547-1.173-6.734a23.613 23.613 0 01-.354-5.2c.207-4.117 1.334-7.534.315-11.7a22.224 22.224 0 00-2.292-5.624 10.39 10.39 0 00-4.225-3.429l-.129-.054a10.747 10.747 0 001.536 2.5 15.921 15.921 0 012.2 7.827c-.01 2.589-.794 4.656-.967 7.229a11.529 11.529 0 00.839 5.2l1.137 11.92c.777-.065 1.53-.139 2.252-.227 1.069-.128 1-1.01.861-1.708z"
                fill="#0d0d38"
            />
            <path
                className="brokerageprefix__d"
                d="M444.483 45.984c-2.86-.406-8.382.553-10.512 1.213-1.629.5-2.164.666-2.6 1.565a31.314 31.314 0 00-1.35 3.817 8.622 8.622 0 01-1.777 3.416 14.039 14.039 0 00-2.481 2.452 2.617 2.617 0 00.673 3.354c.4.376 1.189.764 1.348 1.17a2.94 2.94 0 01-.147 1.478 71.734 71.734 0 00-2.5 10.037c-.2 1.373.8 2.131 1.982 2.377a21.754 21.754 0 005.678.207 30.312 30.312 0 007.654-1.618c.725-.3 1.064-.444.866-1.753-.089-.589-.549-1.913-.909-3.541a4.347 4.347 0 01.338-2.433c.82-1.865 3.467-6.479 4.756-10.394.77-2.339 1.983-6.074 1.958-8.712-.016-1.097-.638-2.303-2.977-2.635z"
            />
            <path
                className="brokerageprefix__e"
                d="M441.667 45.887c-.312-.264-1.979.145-2.5-.2-.61-.41-.546-1.574-.528-2.213.037-1.3 1.2-5.362.839-7.769-.131-.863-.237-1.35-1.154-1.36a7.1 7.1 0 01-2.859-.455 5.7 5.7 0 01-2.359-1.517c-.274-.274-.553-.705-.875-.656-.35.053-.585.6-.681 1.137-.411 2.278.514 3.9 1.6 5.879a4.788 4.788 0 001.769 1.927c.739.433.578.717.615 1.228.078 1.1.121 2.633.135 3.633a1.369 1.369 0 01-.309 1.008 2.68 2.68 0 01-1.051.468.934.934 0 00-.542.444 9.5 9.5 0 00-.869 2.371 6.489 6.489 0 00-.172 2.828c.193.841 1.019 1.094 1.96.908a9.224 9.224 0 003.959-2.377 15.407 15.407 0 002.93-4.012c.187-.475.392-1.021.092-1.272z"
            />
            <path
                className="brokerageprefix__e"
                d="M432.623 30.006a16.536 16.536 0 00-2.373-.36.456.456 0 00-.55.4c-.184 1.117-.309 2.25-.453 2.884-.327.347-1.4 1.3-1.7 1.662a1.372 1.372 0 00.258 1.532 1.2 1.2 0 001.492-.009 6.559 6.559 0 011.59-1.316c.471-.244.168-1.337.383-2.213a3.806 3.806 0 011.208-1.851c.41-.319.606-.612.145-.729z"
            />
            <path
                className="brokerageprefix__i"
                d="M440.922 58.744a8.982 8.982 0 01-2.326 1.642c-1.586.766-5.705.665-8.334.665a5.974 5.974 0 01-3.249-.562c-.757-.46-1.28-1.366-1.544-1.214-.138.08-.147.729.087 1.315a3.444 3.444 0 001.163 1.473c.485.357.967.652 1.073 1 .057.188.229.369.637.369.661 0 3.223.066 4.483.066h4.816a3.9 3.9 0 002.869-1.362 6.237 6.237 0 001.277-3.18c.091-1.029-.491-.655-.952-.212z"
            />
            <path
                className="brokerageprefix__d"
                d="M459.839 63.945c-2.626-4.013-8.2-12.283-10.877-15.15a8.353 8.353 0 00-2.81-2.348c-1.018-.441-2.4.737-2.908 1.488a3.668 3.668 0 00-.377 3.328 10.6 10.6 0 002.8 4.134c1.644 1.692 7.846 7.683 9.022 9.034.829.954.519 1.853-.615 2.768-2.667 2.151-6.872 5.082-10.012 7.4-.931.687-1.208 1.14-1.182 1.937.036 1.143 1.455 1.2 2.392.671 3.034-1.725 12.725-7.706 14.037-8.714 1.464-1.126 1.714-2.739.53-4.548z"
            />
            <path
                className="brokerageprefix__f"
                d="M439.141 38.518c-.087-.82-.4-.817-.494-.426a5.539 5.539 0 01-.64 1.468 2.639 2.639 0 01-1.275 1.129c-.668.172-1.373-.181-1.558 0-.259.251.091.871.45 1.146a2.232 2.232 0 002.1.14 3.5 3.5 0 001.417-3.457z"
            />
            <path
                d="M445.351 42.534a23.041 23.041 0 00-2.269-3.7c-.847-1.433-.8-3.319-1.262-4.919a7.814 7.814 0 00-1.874-3.228 5.988 5.988 0 00-4.739-1.854 4.515 4.515 0 00-4.429 3.569c-.465 1.705.262 3.411.31 5.38.054 2.179-1.454 4.247-1.842 5.982-.547 2.444.537 3.53 1.232 4.034a1.117 1.117 0 00.723.164c1.119-.226 3.086-.745 3.979-1.042a.941.941 0 00.638-.717c.107-1.441-.044-4.15-.084-4.95-.025-.5 0-.53-.351-.652a2.8 2.8 0 01-.828-.477c-1.054-.879-1.26-2.264-1.765-3.1-1.271-2.091-1.058-3.976-.436-4.868a9.456 9.456 0 006.092 2.585c.212.015.33.117.418.392a10.364 10.364 0 01-.371 4.979 11.063 11.063 0 00-.583 5 3.449 3.449 0 003.255 2.859 7.753 7.753 0 004.41-1.731 5.424 5.424 0 00-.224-3.706z"
                fill="#333"
            />
            <path
                className="brokerageprefix__e"
                d="M439.662 35.544c-.017.577-.07 1.067-.5 1.131s-.839-.437-.923-1.008a.9.9 0 01.622-1.149c.556-.186.819.437.801 1.026zM446.123 75.282a1.518 1.518 0 00-1.269-1.11c-.265.013-.61.36-.972.414-1.14.17-1.476-.682-2.208-1.335-.668-.595-1.619.06-2.035.315a1.851 1.851 0 01-.534.2 9.227 9.227 0 00-2.861.911c-.115.269.267.841.617 1.166a4.208 4.208 0 001.628.175 13.429 13.429 0 011.882 0 3.013 3.013 0 011.54.68 2.587 2.587 0 003.624.369 1.431 1.431 0 00.588-1.785z"
            />
            <g>
                <path className="brokerageprefix__b" d="M401.226 122.954h36.184v23.028h-36.184z" />
                <path className="brokerageprefix__c" d="M446.991 145.983h-9.581v-23.029h9.581z" />
                <path
                    className="brokerageprefix__k"
                    d="M429.288 131.076h-36.184l8.122-8.122h36.184zM444.926 131.076h9.581l-7.514-8.122h-9.581z"
                />
                <g>
                    <path
                        className="brokerageprefix__l"
                        d="M423.24 137.729h9.873v.438h-9.873zM423.24 139.083h9.873v.438h-9.873zM423.24 140.437h9.873v.438h-9.873zM423.24 141.791h6.346v.438h-6.346z"
                    />
                </g>
            </g>
            <g>
                <g transform="translate(46.025 111.074)">
                    <path className="brokerageprefix__m" d="M26.368 0a17.454 17.454 0 110 34.909h-8.914V0z" />
                    <circle className="brokerageprefix__m" cx={17.454} cy={17.454} r={17.454} />
                    <circle
                        className="brokerageprefix__n"
                        cx={11.171}
                        cy={11.171}
                        r={11.171}
                        transform="translate(6.284 6.284)"
                    />
                </g>
                <path className="brokerageprefix__m" d="M43.768 111.074a17.454 17.454 0 110 34.909h-8.914v-34.909z" />
                <g transform="translate(177.013 111.074)">
                    <path className="brokerageprefix__m" d="M26.369 0a17.454 17.454 0 010 34.909h-8.911V0z" />
                    <circle className="brokerageprefix__m" cx={17.454} cy={17.454} r={17.454} />
                    <circle
                        className="brokerageprefix__n"
                        cx={11.171}
                        cy={11.171}
                        r={11.171}
                        transform="translate(6.284 6.284)"
                    />
                </g>
                <path className="brokerageprefix__m" d="M174.756 111.074a17.454 17.454 0 110 34.909h-8.914v-34.909z" />
                <path
                    className="brokerageprefix__k"
                    d="M224.389 125.413l12.179-.856a6.566 6.566 0 006.106-6.549v-47.08l-5.46-62.152A9.618 9.618 0 00227.633 0H124.149v125.413z"
                />
                <path
                    className="brokerageprefix__k"
                    d="M202.228 0H44.574a10.34 10.34 0 00-9.775 6.971L10.282 78.113a20.673 20.673 0 01-4.041 6.94l-3.656 4.145A10.338 10.338 0 000 96.037v27.378a4.963 4.963 0 004.963 4.963h139.66l54.406-3.821a6.566 6.566 0 006.106-6.55v-47.08l2.006-62.08c.157-4.84-1.119-8.847-4.913-8.847z"
                />
                <path className="brokerageprefix__o" d="M187.062 125.393v-2.649a21.22 21.22 0 10-42.44 0v5.63z" />
                <path
                    className="brokerageprefix__m"
                    d="M187.063 122.749v2.648l-21.22 1.491v-15.813h8.915a17.366 17.366 0 0110.762 3.712 20.996 20.996 0 011.022 3.257l.038.168.054.258.052.26c.017.086.032.174.048.261s.033.194.049.292c.013.077.025.155.036.232l.041.272.034.258a24.048 24.048 0 01.086.801l.017.194c0 .045.006.091.01.138.012.159.022.32.029.479 0 .091.009.181.012.271a.316.316 0 010 .032 18.86 18.86 0 01.015.789z"
                />
                <circle
                    className="brokerageprefix__n"
                    cx={17.454}
                    cy={17.454}
                    r={17.454}
                    transform="translate(148.388 111.074)"
                />
                <circle
                    className="brokerageprefix__p"
                    cx={11.171}
                    cy={11.171}
                    r={11.171}
                    transform="translate(154.671 117.358)"
                />
                <path className="brokerageprefix__o" d="M56.078 128.378v-5.629a21.22 21.22 0 10-42.44 0v5.63z" />
                <path
                    className="brokerageprefix__m"
                    d="M56.074 122.749v5.629h-21.22v-17.304h8.915a17.369 17.369 0 0110.76 3.712 21.155 21.155 0 011.545 7.963z"
                />
                <circle
                    className="brokerageprefix__n"
                    cx={17.454}
                    cy={17.454}
                    r={17.454}
                    transform="translate(17.4 111.074)"
                />
                <circle
                    className="brokerageprefix__p"
                    cx={11.171}
                    cy={11.171}
                    r={11.171}
                    transform="translate(23.683 117.358)"
                />
                <path
                    className="brokerageprefix__o"
                    d="M69.754 15.247h.584v102.697h-.584zM64.1 81.14H52.355v-3.15H64.1zm-11.162-.584h10.578V78.57H52.939zM34.854 97.642H16.977a2.363 2.363 0 01-2.36-2.359V80.844h.584v14.438a1.778 1.778 0 001.776 1.776h17.877z"
                />
                <path
                    className="brokerageprefix__o"
                    d="M133.729 118.237h-75.3l-.039-.247c-1.771-11.41-12.11-20.347-23.538-20.347v-.584a24.123 24.123 0 0115.953 6.2 24.81 24.81 0 018.122 14.39h74.8zM142.265 118.237h-8.535v-.584h8.037a24.811 24.811 0 018.122-14.39 24.123 24.123 0 0115.953-6.2 23.526 23.526 0 0111.381 3.336 21.213 21.213 0 017.836 7.281h20.076v.584h-20.42l-.083-.151a20.417 20.417 0 00-7.7-7.207 22.937 22.937 0 00-11.09-3.258c-11.428 0-21.768 8.938-23.539 20.347zM238.356 14.955v.584H31.845l.2-.584z"
                />
                <path
                    className="brokerageprefix__i"
                    d="M66.759 61.698V18.142a2.9 2.9 0 00-2.9-2.9H37.602a2.9 2.9 0 00-2.762 2.029L16.357 76.225a2.9 2.9 0 003.306 3.71l38.025-7.268a11.167 11.167 0 009.071-10.969z"
                />
                <path className="brokerageprefix__o" d="M14.63 80.756l1.447-4.617.557.174-1.447 4.618z" />
                <path
                    className="brokerageprefix__o"
                    d="M19.108 80.28a3.189 3.189 0 01-3.029-4.142l18.482-58.95a3.174 3.174 0 013.041-2.233v.583a2.593 2.593 0 00-2.484 1.825l-18.482 58.95a2.6 2.6 0 002.973 3.336l38.025-7.269.11.573-38.026 7.265a3.259 3.259 0 01-.61.062z"
                />
                <rect
                    className="brokerageprefix__q"
                    width={7.14}
                    height={19.146}
                    rx={3.57}
                    transform="translate(23.111 61.698)"
                />
                <path
                    className="brokerageprefix__r"
                    d="M252.327 14.977l-4.167 55.951q-.317 4.258-.317 8.531v35.41h16.569v-99.9z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M250.274 14.977l-5.626 55.951q-.317 4.258-.317 8.531v35.41h15.513V75.187l1.056-60.21z"
                />
                <path
                    className="brokerageprefix__o"
                    d="M240.382 70.928v43.942h-32.955V70.928l1.506-53.138a2.9 2.9 0 012.894-2.813h21.559a2.9 2.9 0 012.886 2.672z"
                />
                <path
                    className="brokerageprefix__r"
                    d="M195.482 14.977l4.167 55.951q.317 4.258.317 8.531v35.41h-16.569v-99.9z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M197.535 14.977l5.626 55.951q.317 4.258.317 8.531v35.41h-15.513V75.187l-1.056-60.21z"
                />
                <path className="brokerageprefix__l" d="M0 114.754h5.118v5.209H0z" />
                <path
                    className="brokerageprefix__q"
                    d="M6.035 90.703l-.443 2.043a20.67 20.67 0 00-.47 4.381v9.6a1.241 1.241 0 01-1.241 1.241H.004V96.037a10.337 10.337 0 012.585-6.838h2.233a1.241 1.241 0 011.213 1.504z"
                />
                <path className="brokerageprefix__o" d="M19.92 107.675H0v.584h19.353q.282-.298.567-.584z" />
            </g>
            <g>
                <path
                    className="brokerageprefix__s"
                    d="M337.456 140.752c.213-1.428-.108-2.241.022-3.68a30.021 30.021 0 01.57-3.443c.1-.423.374-1.739-.821-2.258-1.159-.5-2.058-.5-2.886-.954-.93-.507-2.337-1.279-2.776-1.562a2.278 2.278 0 00-3.2.4 1.667 1.667 0 00.374 2.246c.817.735 1.678 1.268 2.474 2.1.887.927 1.787 4.715 1.769 6.236-.015 1.213-1.9 4.175-.87 4.646s3.164-.566 3.876-1.169a4.456 4.456 0 001.468-2.562zM287.888 139.097c.23-2.992-.362-3.894-2.445-3.332a3.4 3.4 0 00-1.979 1.844 7.577 7.577 0 01-2.482 3.219 14.492 14.492 0 01-4.033 2.055c-1.047.393-3.577.671-3.888 1.811-.352 1.289 2.318 1.289 4.081 1.289 2.27 0 5.141-1.082 6.6-1.235.77-.081 1.927.065 2.854-.049 1.716-.211 1.884-.645 1.752-2.01a20.6 20.6 0 01-.46-3.592z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M313.745 51.697c-.154-2.911-.788-3.575-3.545-4.132a41.057 41.057 0 00-8.91-.815 51.383 51.383 0 00-10.2 1.316c-2.434.862-3 2.239-3.135 3.568-.212 2.086 1.315 23.489 2.254 26.961.752 2.778 2.639 3.876 5.6 4.023 2.091.1 10.267 0 13.386-.186 3.286-.192 4.021-2.037 4.073-4.889.077-4.546.573-24.058.477-25.846z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M270.205 89.815c3.692-2.219 8.925-5.991 12.645-8.5a15.333 15.333 0 006.166-7.592c1.255-3.678 5.337-14.723 6.058-18.4a9.878 9.878 0 00-.285-4.678c-.522-1.531-2.291-2.912-3.81-2.535-3.764.936-4.223 4.092-4.866 7.469-.8 4.174-2.016 12.683-2.563 15.525a12.209 12.209 0 01-3.324 5.868c-2.541 2.26-10.543 8.937-11.339 9.659-1.21 1.1-1.438 1.888-.965 2.9.29.618 1.248.909 2.283.284z"
                />
                <path
                    className="brokerageprefix__c"
                    d="M332.459 127.774c-2.2-2.352-12.247-13.628-13.457-15.007a14.333 14.333 0 01-3.472-8.93c-.21-5.418-.746-22.325-.713-24.259a7.478 7.478 0 00-.265-2.251c-.483-.778-.853.03-1.975.117a5.578 5.578 0 01-4.144-1.67c-2.88-2.221-2.93-5.757-3.132-9.044-.112-1.825-.239-4.748-.213-6.331.081-4.911.42-7.743 1.814-9.761a5.9 5.9 0 013.676-2.789c.439-.072.473-.171.219-.244a12.317 12.317 0 00-1.93-.334c-1.357 0-2.979 1.218-4.156 3.078-1.211 1.914-1.753 5.191-1.956 9.179h-8.4a40.449 40.449 0 01.576-11.237c.227-.816.405-1.184.063-1.138-.317.042-1.227.169-1.493.206-.392.056-.456.132-.653.725-.836 2.529-.937 9.092-.913 12.632.013 1.935-.131 8.692-.17 10.89a11.3 11.3 0 01-.612 4.484 4.23 4.23 0 01-1.891 1.793 1.638 1.638 0 00-.763 1.524c-.154 2.635-.2 6.208-.609 7.709a46.573 46.573 0 01-1.85 5.623c-1.692 4.255-3.583 7.8-5.145 11.869-1.377 3.589-1.188 6.809-.724 10.577.546 4.435 2.282 18.236 2.644 21.684.165 1.563 2.4 1.634 3.891 1.775 3.462.326 2.91-1.847 2.931-3.827.022-2.1-.161-10.661-.193-12.98a22.06 22.06 0 011.628-9.422c1.442-4.053 6.041-12.822 6.824-14.447.294-.61.975-2.194 2.071-2.1 1.274.109 1.317.957 1.829 2.427.637 1.828 2.814 9.323 3.611 11.728a15.75 15.75 0 004.094 6.541c3.048 2.981 13.956 13.208 18.16 16.927 1.082.957 1.759.742 2.967.048a11.64 11.64 0 002.882-2.858c.68-1.176.182-1.587-1.051-2.907z"
                />
                <path
                    d="M301.221 96.559c-.406-1-.626-1.471-1.049-2.436a1.477 1.477 0 00-1.1-.755c-.6-.084-4.043-.318-4.777-.308-1.49 2.724-.355 6-1.085 8.369-.473 1.533-2.048 2.6-2.549 3.844-.85 2.114-.655 3.919-.978 6.145-.29 1.995-1.691 3.526-2.017 5.421-.406 2.36.833 4.527.978 7.353.127 2.5-.12 4.854-.109 5.988a5.484 5.484 0 001.094 3.241c-.026-3.208-.159-9.625-.188-11.583a22.084 22.084 0 011.627-9.424c1.443-4.052 6.042-12.821 6.824-14.446.294-.61.975-2.194 2.07-2.1a1.47 1.47 0 011.416 1.184 4.391 4.391 0 00-.157-.493z"
                    fill="#1f367a"
                />
                <path
                    className="brokerageprefix__u"
                    d="M275.171 112.612l-.707-9.067h2.642v9.067zM296.587 112.612l-.707-9.067h2.642v9.067z"
                />
                <path
                    className="brokerageprefix__v"
                    d="M295.184 70.044h-15.633a2.053 2.053 0 00-1.987 1.536l-2.619 10.059h15.633l2.619-10.059a2.053 2.053 0 011.987-1.536z"
                />
                <path d="M1090.561 597.568l.017.019z" transform="translate(-795.118 -515.257)" fill="url(#prefix__a)" />
                <path
                    className="brokerageprefix__e"
                    d="M293.967 32.85c.489-1.982 2.941-3.1 5.25-3.249a5.616 5.616 0 015.371 2.839c.787 1.629 1.013 4.315-1.022 8.38-.486.972-.262 1.374-.084 2.431.041.239.3 1.543.405 1.989.352 1.456 1.006 1.088 1.211 1.614.774 1.982-3.163 4.906-6.427 5.041-3.039.125-2.946-3.358-2.762-4.158.146-.636.716-.738 1.028-.937.517-.329.58-.623.568-1.238a7.208 7.208 0 00-.032-.412c-.072-.676-.165-2.393-.606-2.757-.375-.31-.564-.42-.982-.738a4.774 4.774 0 01-1.128-2.027 29.663 29.663 0 01-.265-3.664c-.105-1.227-.83-1.88-.525-3.114z"
                />
                <path
                    className="brokerageprefix__f"
                    d="M295.573 41.316a1.439 1.439 0 00.312.339c.417.318.6.433.982.738a2.165 2.165 0 01.436 1.256 2.879 2.879 0 00.807-.04 5.225 5.225 0 002.941-2.228 5.006 5.006 0 00.717-2.6c-.106-.157-.953 1.09-1.1 1.272a4.727 4.727 0 01-5.095 1.263zM294.491 35.966c.012.145.022.307.029.48.112-.408.045-.905.336-1.29a2.1 2.1 0 011.251-.7 20.463 20.463 0 013.933-.2c.949-.029 2.289-.245 1.472-1.3-1.342-1.738-4.728-2.163-6.659-1.258a2.459 2.459 0 00-.561.36 2.924 2.924 0 00-.326.8c-.303 1.226.422 1.879.525 3.108z"
                />
                <path d="M294.347 30.521a6.467 6.467 0 014.56-2.33c2.826-.174 5.91.928 6.339 2.718.162.673.09 1.335.522 1.782 1.206 1.247.188 3.851-.206 4.83-.542 1.347-1.857 4.149-2.338 3.963a2.172 2.172 0 01-.691-1.473c.064-.882.1-1.8.242-2.674a1.218 1.218 0 00-.193-1.164c-.108.231-.355.9-.355.9l-.9-.416a7.228 7.228 0 01-.992-4.487 1.865 1.865 0 00-.708-.529 5.73 5.73 0 00-4.963.879 1.4 1.4 0 00-.588 1.252c0 .275.166.958-.1.894s-.559-.741-.6-1.711a4.46 4.46 0 01.971-2.434z" />
                <path
                    className="brokerageprefix__x"
                    d="M302.267 36.641c-.233.889.036 1.73.6 1.879s1.214-.452 1.447-1.341-.036-1.731-.6-1.879-1.213.452-1.447 1.341z"
                />
                <path
                    className="brokerageprefix__c"
                    d="M302.631 28.399c-1.731-.515-4.713-.811-6.1.036a5.91 5.91 0 00-3.019 3.706c-.1.359.114.607.339.5a7.632 7.632 0 014.612-.415 9.822 9.822 0 013.273 1.392 13.892 13.892 0 013.781 3.152c.407.471.724-.2.806-.554.714-3.075.699-6.51-3.692-7.817z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M301.39 33.201c-.126-.611-.984-2.04-3.4-2.794-.923-.288-2.44-1.1-3.424-1.412-.471-.151-.571-.059-.5-.047a2.65 2.65 0 011.045.332 11.956 11.956 0 011.959 1.68 11.952 11.952 0 001.905 1.187c.67.331 1.834.82 2.187 1.017.169.086.25.141.228.037z"
                />
                <path
                    className="brokerageprefix__c"
                    d="M301.314 33.209c-1.368-.764-3.477-1.344-5.01-3.072a3.486 3.486 0 00-4.889-.517c-.584.48-1.455 1.581-1.064 2.613.351.928 1.941 1.449 2.809 1.563.423.056.52-.007.7-.2a3.777 3.777 0 011.56-1.13 7.25 7.25 0 014.155.146 13.858 13.858 0 011.571.709c.221.118.364.003.168-.112z"
                />
                <path
                    d="M876.511 522.791a2.875 2.875 0 00-2.752-2.042h-66.541v23.191h67.518l2.619-10.059a2.053 2.053 0 011.987-1.536h.059z"
                    transform="translate(-599.791 -462.301)"
                    fill="url(#brokerageprefix__b)"
                />
                <path
                    d="M893.715 595.407h-86.5v10.953h86.5a2.054 2.054 0 002.054-2.054v-6.846a2.053 2.053 0 00-2.054-2.053z"
                    transform="translate(-599.791 -513.768)"
                    fill="url(#brokerageprefix__c)"
                />
                <path
                    d="M299.109 70.044h-3.927a2.053 2.053 0 00-1.987 1.536l-2.619 10.059h1.52v10.953h-84.669v10.954h91.682a2.054 2.054 0 002.054-2.054V72.097a2.053 2.053 0 00-2.054-2.053z"
                    fill="#feb840"
                />
                <path
                    d="M895.435 598.41a2.038 2.038 0 01.334 1.12v6.845a2.054 2.054 0 01-2.054 2.054h-86.5v5.209h93.738V605.6z"
                    transform="translate(-599.791 -515.838)"
                    fill="url(#brokerageprefix__d)"
                />
                <path
                    className="brokerageprefix__l"
                    d="M299.109 69.827h-19.34l-2.843-9.4a3.075 3.075 0 00-2.96-2.2h-66.539v.435h66.542a2.642 2.642 0 012.544 1.888l2.809 9.286a2.27 2.27 0 00-1.968 1.686l-.028.11h.449a1.837 1.837 0 011.777-1.374h14.3a2.263 2.263 0 00-.865 1.264l-2.577 9.9h-82.983v.435h86.5a1.838 1.838 0 011.836 1.836v6.846a1.838 1.838 0 01-1.836 1.836v.435a2.273 2.273 0 002.271-2.271v-6.846a2.273 2.273 0 00-2.271-2.271h-3.068l2.548-9.787a1.837 1.837 0 011.777-1.374h3.925a1.838 1.838 0 011.836 1.836v29.395a1.838 1.838 0 01-1.836 1.836v.435a2.274 2.274 0 002.271-2.271V72.097a2.273 2.273 0 00-2.271-2.27z"
                />
                <path
                    className="brokerageprefix__v"
                    d="M293.924 81.639h-86.5v2.156h86.5a2.054 2.054 0 012.054 2.054v-2.157a2.053 2.053 0 00-2.054-2.053z"
                />
                <path
                    className="brokerageprefix__b"
                    d="M301.639 92.41c2.9-2.8 9.554-9.208 12.315-12.291 2.07-2.312 4.323-4.612 4.033-8.379-.389-5.058-1.352-14.054-2.077-17.748-.347-1.77-.531-4.274-2.052-5.089-1.316-.7-2.095-1.238-3.019-.694-3.084 1.814-2.475 4.929-2.131 7.957.45 3.956 1.557 11.787 1.708 14.452a13.133 13.133 0 01-1.9 6.275c-1.614 2.561-8.1 11.86-8.691 12.656-.978 1.316-.983 2.039-.411 2.946a1.549 1.549 0 002.225-.085z"
                />
                <path
                    className="brokerageprefix__x"
                    d="M301.416 89.982a1.675 1.675 0 00-1.865-.072c-.7.471-2.169 1.322-3.043 1.853a3.792 3.792 0 01-2.563.446 3.9 3.9 0 00-2.345.118c-.407.267-1.6.812-.664 1.21 1 .425 3.508 1.388 4.41 1.706a3.021 3.021 0 001.607.176c1-.289 1.947-.636 2.558-.862.674-.249 1.291-1.29 1.9-1.963a1.773 1.773 0 00.005-2.612z"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
