import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={76.722}
            height={71}
            viewBox="0 0 76.722 71"
        >
            <defs>
                <style>{`.hspackageprefix__a{fill:${
                    slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                }}`}</style>
            </defs>
            <path
                className="hspackageprefix__a"
                d="M61.738 37.319v-19.1a.934.934 0 00-.468-.811L31.337.126a.934.934 0 00-.936 0L.468 17.408a.935.935 0 00-.468.811v34.563a.936.936 0 00.468.811L30.4 70.875a.937.937 0 00.936 0l16.009-9.243a15.9 15.9 0 1014.394-24.313zM30.869 2.016l12.8 7.39-28.06 16.2-12.8-7.39zm-.936 66.427L1.871 52.242V19.836l28.063 16.2zm.936-34.027l-13.389-7.73 28.06-16.2 13.389 7.73zm.936 34.027V36.038l28.062-16.2V37.32a15.9 15.9 0 00-13.422 22.672zm29.013-1.219a14.031 14.031 0 1114.032-14.03 14.032 14.032 0 01-14.031 14.031zm0 0"
            />
            <path
                className="hspackageprefix__a"
                d="M61.369 44.545a.936.936 0 00-1.1 0l-9.357 6.809a.936.936 0 00.551 1.692h1.806v8.036a.935.935 0 00.936.936h13.231a.935.935 0 00.936-.936v-8.036h1.805a.936.936 0 00.551-1.692zm5.13 7.568v8.041h-11.36v-8.036a.937.937 0 00-.813-.928l6.493-4.727 6.492 4.727a.936.936 0 00-.812.923zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
