export const POST_FOLDERS = 'POST_FOLDERS';
export const POST_FOLDERS_SUCCESS = 'POST_FOLDERS_SUCCESS';
export const POST_FOLDERS_ERROR = 'POST_FOLDERS_ERROR';

export const GET_FOLDERS = 'GET_FOLDERS';
export const GET_FOLDERS_SUCCESS = 'GET_FOLDERS_SUCCESS';
export const GET_FOLDERS_ERROR = 'GET_FOLDERS_ERROR';

export const POST_DOCUMENTS = 'POST_DOCUMENTS';
export const POST_DOCUMENTS_SUCCESS = 'POST_DOCUMENTS_SUCCESS';
export const POST_DOCUMENTS_ERROR = 'POST_DOCUMENTS_ERROR';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_DOCUMENTS_SUCCESS = 'UPDATE_DOCUMENTS_SUCCESS';
export const UPDATE_DOCUMENTS_ERROR = 'UPDATE_DOCUMENTS_ERROR';

export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS';
export const DELETE_DOCUMENTS_ERROR = 'DELETE_DOCUMENTS_ERROR';
