export function anaySeg(
    view: any,
    page: any,

    category_name: any,
) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    if (slug) {
        let brokerage: any;

        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;        
        let agent_key: any = slug.realtor_key;
        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');
        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            `${view}`,
            {
                page: page,
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_key: agent_key,
                agent_name: agent_name,
                category_name: `${category_name}`,
            },
            { user_id: move_id },
        );
    }
}
export function scheduleSegment(
    view: any,
    page: any,
    brokerage: any,
    brokerage_key: any,
    agent_key: any,
    agent_name: any,
    category_name: any,
    button_copy: any,
    card: boolean,
    card_title: string,
    banner: boolean,
    banner_title: string,
    move_id: any,
) {
    let windownew: any = window;
    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    analytics.track(
        `${view}`,
        {
            page: page,
            brokerage_name: brokerage,
            brokerage_key: brokerage_key,
            agent_key: agent_key,
            agent_name: agent_name,
            category_name: `${category_name}`,
            button_copy: `${button_copy}`,
            card: `${card}`,
            card_title: `${card_title}`,
            banner: `${banner}`,
            banner_title: `${banner_title}`,
        },
        { user_id: move_id },
    );
}

export function popUpSegment(
    view: any,
    page: any,
    brokerage: any,
    brokerage_key: any,
    agent_key: any,
    agent_name: any,
    popup_header: string,
    move_id: any,
) {
    let windownew: any = window;
    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    analytics.track(
        `${view}`,
        {
            page: page,
            brokerage_name: brokerage,
            brokerage_key: brokerage_key,
            agent_key: agent_key,
            agent_name: agent_name,
            popup_header: popup_header,
        },
        { user_id: move_id },
    );
}

export function formStartedSegment(
    view: any,
    page: any,
    category_name: string,
    brokerage: any,
    brokerage_key: any,
    agent_name: any,
    agent_key: any,
    brand_partner_name: any,
    button_copy: any,
    form_button: boolean,
    form_pre_filled: boolean,
    form_step_number: any,
    move_id: any,
) {
    let windownew: any = window;
    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    analytics.track(
        `${view}`,
        {
            page: page,
            brokerage_name: brokerage,
            brokerage_key: brokerage_key,
            agent_key: agent_key,
            agent_name: agent_name,
            category_name: category_name,
            brand_partner_name: brand_partner_name,
            button_copy: button_copy,
            form_button: form_button,
            form_pre_filled: form_pre_filled,
            form_step_number: form_step_number,
        },
        { user_id: move_id },
    );
}

export function formCompletedSegment(
    view: any,
    page: any,
    category_name: string,
    brokerage: any,
    brokerage_key: any,
    agent_name: any,
    agent_key: any,
    brand_partner_name: any,
    button_copy: any,
    form_button: boolean,
    form_pre_filled: boolean,
    form_name: any,
    form_step_number: any,
    move_id: any,
) {
    let windownew: any = window;
    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    analytics.track(
        `${view}`,
        {
            page: page,
            brokerage_name: brokerage,
            brokerage_key: brokerage_key,
            agent_key: agent_key,
            agent_name: agent_name,
            category_name: category_name,
            brand_partner_name: brand_partner_name,
            button_copy: button_copy,
            form_button: form_button,
            form_pre_filled: form_pre_filled,
            form_name: form_name,
            form_step_number: form_step_number,
        },
        { user_id: move_id },
    );
}

export function buttonClicked(
    view: any,
    page: any,
    brokerage_name: any,
    brokerage_key: any,
    agent_name: any,
    agent_key: any,
    category_name: any, 
    button_copy: any,
    popup: boolean,
    popup_title: any,
    card: boolean,
    card_title: any,
    card_type: any,
    banner: boolean,
    banner_title: any,
    form_button: boolean,
    form_step_number: any,
    brand_name: any,
    move_id: any,
) {
    let windownew: any = window;
    let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
    analytics.track(
        `${view}`,
        {
            page: page,
            brokerage_name: brokerage_name,
            brokerage_key: brokerage_key,
            agent_key: agent_key,
            agent_name: agent_name,
            category_name: category_name,
            brand_name: brand_name,
            button_copy: button_copy,
            form_button: form_button,
            banner: banner,
            banner_title: banner_title,
            popup: popup,
            popup_title: popup_title,
            card: card,
            card_title: card_title,
            card_type: card_type,
            form_step_number: form_step_number,
        },
        { user_id: move_id },
    );
}

