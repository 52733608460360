import React, { Component } from 'react';
import './brokerageCard.scss';

import Button from '../../components/atoms/Button';

type initialProps = {
    profileLogo?: any;

    profileName?: string;
    phoneNumber?: string;
    email?: string;
    website?: any;
    brokLogo?: any;
    handleAgentBrok?: any;
    brokLogoMobile?: any;
};
type initialState = {
    slug: any;
};

export class NextMoveBrokerageCard extends Component<initialProps, initialState> {
    state: initialState = {
        slug: null,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        return (
            <div className="nextMoveBrokerageCard">
                <div className="nextMoveBrokerageCardSub">
                    <div className="imageInfoLeft">
                        <div className="imageLogoBrok">
                            <img src={this.props.profileLogo} alt="" />
                        </div>
                        <div className="addressBrokNextMove">
                            <div className="profileName">{this.props.profileName}</div>
                            <div className="profileInfoDetails">{this.props.phoneNumber}</div>
                            <div className="profileInfoDetails">{this.props.email}</div>
                            <div className="profileInfoDetails">{this.props.website}</div>
                        </div>
                    </div>

                    <div className="brokrageNotify">
                        <div className="brokLogo">
                            <img src={this.props.brokLogo} alt="" />
                        </div>
                        <div className="cBtnCover">
                            <Button
                                className="brokrageNotifyWrapper"
                                // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                // color={'fff'}
                                iconMarginLeft="120px"
                                onClick={this.props.handleAgentBrok}
                            >
                                Notify Agent
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="brokrageNotifyMobile">
                    <div className="brokrageNotifyMobileInner">
                        <div className="brokLogo">
                            <img src={this.props.brokLogoMobile} alt="" />
                        </div>
                        <div className=" brokrageNotifyWrapper2">
                            <Button
                                className="buttonBrokerage"
                                // backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                                // color={'fff'}
                                onClick={this.props.handleAgentBrok}
                            >
                                Notify Agent
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NextMoveBrokerageCard;
