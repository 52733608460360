import {
    POST_SERVICE,
    POST_SERVICE_ERROR,
    POST_SERVICE_SUCCESS,
    GET_SERVICE_PROVIDER,
    GET_SERVICE_PROVIDER_ERROR,
    GET_SERVICE_PROVIDER_SUCCESS,
    GET_DIGITAL_SERVICE_PROVIDER,
    GET_DIGITAL_SERVICE_PROVIDER_ERROR,
    GET_DIGITAL_SERVICE_PROVIDER_SUCCESS,
    GET_INSURANCE_PROVIDER,
    GET_INSURANCE_PROVIDER_ERROR,
    GET_INSURANCE_PROVIDER_SUCCESS,
} from './actionTypes';
import * as types from './types';
export const postService = (payload: types.PostServiceRequest): types.PostService => ({
    type: POST_SERVICE,
    payload,
});
export const postServiceSuccess = (payload: types.PostServiceSuccessPayload): types.PostServiceSuccess => ({
    type: POST_SERVICE_SUCCESS,
    payload,
});
export const postServiceFailure = (payload: types.PostServiceFailurePayload): types.PostServiceFailure => ({
    type: POST_SERVICE_ERROR,
    payload,
});

export const getServiceProvider = (payload: types.GetServiceProviderRequest): types.GetServiceProvider => ({
    type: GET_SERVICE_PROVIDER,
    payload,
});
export const getServiceProviderSuccess = (
    payload: types.GetServiceProviderSuccessPayload,
): types.GetServiceProviderSuccess => ({
    type: GET_SERVICE_PROVIDER_SUCCESS,
    payload,
});
export const getServiceProviderFailure = (
    payload: types.GetServiceProviderFailurePayload,
): types.GetServiceProviderFailure => ({
    type: GET_SERVICE_PROVIDER_ERROR,
    payload,
});
export const getDigitalServiceProvider = (
    payload: types.GetDigitalServiceProviderRequest,
): types.GetDigitalServiceProvider => ({
    type: GET_DIGITAL_SERVICE_PROVIDER,
    payload,
});
export const getDigitalServiceProviderSuccess = (
    payload: types.GetDigitalServiceProviderSuccessPayload,
): types.GetDigitalServiceProviderSuccess => ({
    type: GET_DIGITAL_SERVICE_PROVIDER_SUCCESS,
    payload,
});
export const getDigitalServiceProviderFailure = (
    payload: types.GetDigitalServiceProviderFailurePayload,
): types.GetDigitalServiceProviderFailure => ({
    type: GET_DIGITAL_SERVICE_PROVIDER_ERROR,
    payload,
});
export const getInsuranceProvider = (payload: types.GetInsuranceProviderRequest): types.GetInsuranceProvider => ({
    type: GET_INSURANCE_PROVIDER,
    payload,
});
export const getInsuranceProviderSuccess = (
    payload: types.GetInsuranceProviderSuccessPayload,
): types.GetInsuranceProviderSuccess => ({
    type: GET_INSURANCE_PROVIDER_SUCCESS,
    payload,
});
export const getInsuranceProviderFailure = (
    payload: types.GetInsuranceProviderFailurePayload,
): types.GetInsuranceProviderFailure => ({
    type: GET_INSURANCE_PROVIDER_ERROR,
    payload,
});
