import {
    EXTERNAL_DIGITAL_PLANS,
    EXTERNAL_DIGITAL_PLANS_ERROR,
    EXTERNAL_DIGITAL_PLANS_SUCCESS,
    SELECT_DIGITAL_PLANS,
    SELECT_DIGITAL_PLANS_ERROR,
    SELECT_DIGITAL_PLANS_SUCCESS,
    SELECT_DIGITAL_PLANS_GET,
    SELECT_DIGITAL_PLANS_GET_ERROR,
    SELECT_DIGITAL_PLANS_GET_SUCCESS,
    GET_STEPS,
    GET_STEPS_SUCCESS,
    GET_STEPS_ERROR,
    CHANGE_STEPS,
    CHANGE_STEPS_SUCCESS,
    CHANGE_STEPS_ERROR,
    LANDING_DIGITAL_PLANS,
    LANDING_DIGITAL_PLANS_ERROR,
    LANDING_DIGITAL_PLANS_SUCCESS,
    GET_IC_SUMMARY,
    GET_IC_SUMMARY_SUCCESS,
    GET_IC_SUMMARY_ERROR
} from './actionTypes';

import { InternetPlanActions, InternetPlanState } from './types';

const initialState: InternetPlanState = {
    internet: [],
    error: null,
    selectDigitalPlan: [],
    errorDigitalPlan: null,
    steps: [],
    errorSteps: null,
    isStepsSuccess: false,
    isInternetDataArrived: false,
    summary: [],
    isICSummaryArrived: false,
    errorICSummary: null
};

export default (state = initialState, action: InternetPlanActions) => {
    switch (action.type) {
        case EXTERNAL_DIGITAL_PLANS:
            return {
                ...state,
                internet: [],
                isInternetDataArrived: false,
                error: null,
            };
        case EXTERNAL_DIGITAL_PLANS_SUCCESS:
            return {
                ...state,
                internet: action.payload.internet,
                isInternetDataArrived: true,
                error: null,
            };
        case EXTERNAL_DIGITAL_PLANS_ERROR:
            return {
                ...state,
                internet: [],
                isInternetDataArrived: false,
                error: action.payload.error,
            };

        case SELECT_DIGITAL_PLANS:
            return {
                ...state,
                selectDigitalPlan: [],
                error: null,
            };
        case SELECT_DIGITAL_PLANS_SUCCESS:
            return {
                ...state,
                selectDigitalPlan: action.payload.selectDigitalPlan,
                error: null,
            };
        case SELECT_DIGITAL_PLANS_ERROR:
            return {
                ...state,
                selectDigitalPlan: [],
                error: action.payload.errorDigitalPlan,
            };

        case SELECT_DIGITAL_PLANS_GET:
            return {
                ...state,
                selectDigitalPlan: [],
                error: null,
            };
        case SELECT_DIGITAL_PLANS_GET_SUCCESS:
            return {
                ...state,
                selectDigitalPlan: action.payload.selectDigitalPlan,
                error: null,
            };
        case SELECT_DIGITAL_PLANS_GET_ERROR:
            return {
                ...state,
                selectDigitalPlan: [],
                error: action.payload.errorDigitalPlan,
            };
        case GET_STEPS:
            return {
                ...state,
                steps: [],
                error: null,
            };
        case GET_STEPS_SUCCESS:
            return {
                ...state,
                steps: action.payload,
                error: null,
            };
        case GET_STEPS_ERROR:
            return {
                ...state,
                steps: [],
                error: action.payload.errorSteps,
            };
        case CHANGE_STEPS:
            return {
                ...state,
                steps: [],
                isStepsSuccess: false,
                error: null,
            };
        case CHANGE_STEPS_SUCCESS:
            return {
                ...state,
                steps: action.payload,
                isStepsSuccess: true,
                error: null,
            };
        case CHANGE_STEPS_ERROR:
            return {
                ...state,
                steps: [],
                isStepsSuccess: false,
                error: action.payload.errorSteps,
            };

        case LANDING_DIGITAL_PLANS:
            return {
                ...state,
                internet: [],
                isInternetDataArrived: false,
                error: null,
            };
        case LANDING_DIGITAL_PLANS_SUCCESS:
            return {
                ...state,
                internet: action.payload.internet,
                isInternetDataArrived: true,
                error: null,
            };
        case LANDING_DIGITAL_PLANS_ERROR:
            return {
                ...state,
                internet: [],
                isInternetDataArrived: false,
                error: action.payload.error,
            };
        case GET_IC_SUMMARY:
            return {
                ...state,
                summary: [],
                isICSummaryArrived: false,
                errorICSummary: null,
            };
        case GET_IC_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.payload.summary,
                isICSummaryArrived: true,
                errorICSummary: null,
            };
        case GET_IC_SUMMARY_ERROR:
            return {
                ...state,
                summary: [],
                isICSummaryArrived: false,
                errorICSummary: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
