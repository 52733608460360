import React from 'react';
import { makeStyles } from '@material-ui/core';
import '../../styles/trustedProsImageHeadingCard.scss';
import Skeleton from 'react-loading-skeleton';

type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    padding?: any;
    onClick?: any;
};

const TrustedProsImageHeadingCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            // '&:hover': {
            //     '& $checkroot': {
            //         color: '#333333 !important',
            //     },
            // },
            cursor: 'pointer',
        },
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.root} ` : `trusted-main-div ${classes.root}`}
            style={{
                width: props.width ? props.width : '285px',
                height: props.height ? props.height : 'auto',
                padding: props.padding ? props.padding : '0px',
                borderRadius: '5px',
                border: '1px solid #DADCE0',
            }}
            onClick={props.onClick}
        >
            <div className="trusted-pros-Div">
                <div className="trusted-pros-ImgDiv">
                    {props.icon ? (
                        <img
                            src={props.icon}
                            height={props.imageHeight ? props.imageHeight : '210px'}
                            width={props.imageWidth ? props.imageWidth : '100%'}
                        />
                    ) : (
                        <Skeleton height="200px" width="100%" />
                    )}
                </div>
                <div className="trusted-pros-ContentDiv">
                    <div>
                        <div className="trusted-pros-Title">{props.title ? props.title : <Skeleton />} </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustedProsImageHeadingCard;
