import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="30.415" height="31.118" viewBox="0 0 30.415 31.118">
            <defs>
                <style>
                    {`.Insuranceprefix__a{fill:${
                        window.location.pathname.includes('home-insurance') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <g transform="translate(-.148 -.001)">
                <path
                    fill="#fff"
                    d="M238.554 242.514l1.108.806.173.126v10.3h-12.269V243.5l-.027-.037 6.162-4.486.264.192 2.865 2.086zm0 0"
                    data-name="Path 2545"
                    transform="translate(-212.216 -223.032)"
                ></path>
                <path
                    fill="#fff"
                    d="M393.882 246.919v2.994l-.173-.126-1.108-.806v-3.071zm0 0"
                    data-name="Path 2546"
                    transform="translate(-366.263 -229.499)"
                ></path>
                <path
                    className="Insuranceprefix__a"
                    fill="#bebebe"
                    d="M30.322 11.935v10.629a7.992 7.992 0 01-.1 1.262l-2.87-2.086v-7.886l-9.115-4.432-9.115 4.464v8.541a7.8 7.8 0 003.038 5.746l6.077 4.144 2.717-1.853V34.1l-2.717 1.853-7.837-5.347a10.577 10.577 0 01-4.251-8.042V11.919L18.238 6zm0 0"
                    data-name="Path 2547"
                    transform="translate(-5.603 -5.599)"
                ></path>
                <path
                    fill="#fff"
                    d="M62.457 73.889l.027.037v4.394l-2.717 1.853-6.077-4.144a7.8 7.8 0 01-3.038-5.746v-8.542l9.115-4.464 9.115 4.433v7.89l-.264-.192zm0 0"
                    data-name="Path 2548"
                    transform="translate(-47.134 -53.454)"
                ></path>
                <g
                    className="Insuranceprefix__a"
                    fill="#6b6c6f"
                    data-name="Group 1180"
                    transform="translate(.148 .001)"
                >
                    <path
                        d="M141.207 195.144l-3.754 3.438-1.868-2.025a.4.4 0 00-.589.543l2.138 2.318a.4.4 0 00.565.024l4.048-3.708a.4.4 0 00-.541-.591zm0 0"
                        data-name="Path 2549"
                        transform="translate(-125.899 -182.023)"
                    ></path>
                    <path
                        d="M30.4 21.941l-2.377-1.73V17.42a.4.4 0 00-.152-.314L26.586 16.1a.4.4 0 00-.648.315V18.7l-.894-.651a8.221 8.221 0 00.075-1.08V6.336a.4.4 0 00-.224-.359L12.812.042a.4.4 0 00-.353 0L.373 5.961a.4.4 0 00-.224.359v10.645a11.012 11.012 0 004.426 8.373l7.833 5.343a.4.4 0 00.451 0l2.091-1.426v1.464a.4.4 0 00.4.4h12.269a.4.4 0 00.4-.4V21.2l1.905 1.387a.4.4 0 10.471-.648zm-3.657-4.7l.48.378v2.013l-.48-.349zm-14.1 12.629l-7.617-5.194A10.141 10.141 0 01.95 16.965V6.57L12.635.847l11.683 5.738v10.38c0 .175-.008.353-.022.535l-2.147-1.563V8.256a.4.4 0 00-.225-.36l-9.115-4.433a.4.4 0 00-.351 0L3.343 7.928a.4.4 0 00-.224.359v8.541A8.241 8.241 0 006.331 22.9l6.077 4.144a.4.4 0 00.451 0l2.091-1.426v2.662zm-.06-7.924a.4.4 0 00.472.647L14.95 21.2v3.452l-2.316 1.579-5.851-3.99a7.459 7.459 0 01-2.864-5.416V8.537l8.715-4.268 8.714 4.237v7.067a.4.4 0 00-.1.052zm14.645 8.377H15.751v-9.7l5.734-4.175 5.734 4.175zm0 0"
                        data-name="Path 2550"
                        transform="translate(-.148 -.001)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
