import { DMV_DETAILS, DMV_DETAILS_SUCCESS, DMV_DETAILS_ERROR } from './actionTypes';

import { DMVActions, DMVState } from './types';

const initialState: DMVState = {
    dmv: [],
    error: null,
};

export default (state = initialState, action: DMVActions) => {
    switch (action.type) {
        case DMV_DETAILS:
            return {
                ...state,
                dmv: [],
                error: null,
            };
        case DMV_DETAILS_SUCCESS:
            return {
                ...state,
                dmv: action.payload.dmv,
                error: null,
            };
        case DMV_DETAILS_ERROR:
            return {
                ...state,
                dmv: [],
                error: action.payload.error,
            };

        default:
            return {
                ...state,
            };
    }
};
