import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={643.285}
            height={313.875}
            viewBox="0 0 643.285 313.875"
            {...props}
        >
            <defs>
                <style>
                    {slug &&
                    slug.color_shade1 &&
                    slug.color_shade2 &&
                    slug.color_shade3 &&
                    slug.color_shade4 &&
                    slug.primary_color
                        ? `.titleHomeprefix__b{fill:${slug.color_shade1}}.titleHomeprefix__c{fill:${slug.color_shade3}}.titleHomeprefix__d{fill:${slug.primary_color}}.titleHomeprefix__e{fill:${slug.color_shade4}}.titleHomeprefix__f{fill:#f9ddbf}.titleHomeprefix__h{fill:#f2f2f2}.titleHomeprefix__i{fill:#bbd5fc}.titleHomeprefix__j{fill:#d2e4fd}.titleHomeprefix__k{fill:#2d0622}.titleHomeprefix__l{fill:#f0bf97}.titleHomeprefix__n{fill:#fc9490}.titleHomeprefix__o{fill:#0c7dfb}`
                        : '.titleHomeprefix__b{fill:#08192f}.titleHomeprefix__c{fill:#3c536f}.titleHomeprefix__d{fill:#273e59}.titleHomeprefix__e{fill:#5b7088}.titleHomeprefix__f{fill:#f9ddbf}.titleHomeprefix__h{fill:#f2f2f2}.titleHomeprefix__i{fill:#bbd5fc}.titleHomeprefix__j{fill:#d2e4fd}.titleHomeprefix__k{fill:#2d0622}.titleHomeprefix__l{fill:#f0bf97}.titleHomeprefix__n{fill:#fc9490}titleHome.prefix__o{fill:#0c7dfb}'}
                </style>
            </defs>
            <path
                d="M562.665 310.558s47.412 11.24 70.752-39.7c30.28-66.087-12.219-128.866-85.53-133.387C442.152 130.95 500.752-37.862 333.902 7.942c-46.915 12.879-148.958-53.607-209.029 59.046-10.214 19.156-40.749 18.215-81.43 50.894-71.763 57.646-45.929 193.287 14.536 193.9s504.686-1.224 504.686-1.224z"
                fill="#dedede"
            />
            <path className="titleHomeprefix__b" d="M190.15 131.219v66.983H76.103l4.09-66.983z" />
            <path
                className="titleHomeprefix__c"
                d="M404.111 117.934h130.731v195.941H404.111zM190.14 105.287h156.4v208.098h-156.4z"
            />
            <path className="titleHomeprefix__d" d="M183.904 35.507h223.575v101.497H183.904z" />
            <path className="titleHomeprefix__c" d="M302.883 123.885l117.54-111.131 117.539 111.131z" />
            <path
                className="titleHomeprefix__d"
                d="M547.486 134.807a4.3 4.3 0 01-2.935-1.15l-124.129-108c-39.538 36-85.461 70.67-86.841 71.589a4.317 4.317 0 01-4.99-7.042c2.845-2.353 54.644-49.433 88.925-80.649a4.324 4.324 0 015.841.025L550.42 127.316a4.318 4.318 0 01-2.935 7.486z"
            />
            <path className="titleHomeprefix__e" d="M268.34 105.287h156.4v208.098h-156.4z" />
            <path className="titleHomeprefix__e" d="M268.34 105.287l78.2-73.936 78.2 73.936-134.87.513z" />
            <path
                className="titleHomeprefix__c"
                d="M369.164 129.807v40.27h-45.945v-39.493a23.227 23.227 0 017.571-17.213 21.6 21.6 0 012.389-1.9 18.027 18.027 0 012.562-1.583 23.146 23.146 0 0110.823-2.649 21.936 21.936 0 0110.765 2.764 14.167 14.167 0 012.562 1.612 20.624 20.624 0 012.159 1.756 22.506 22.506 0 017.114 16.436z"
            />
            <path
                className="titleHomeprefix__d"
                d="M369.927 123.763h-47.284l.731-1.945a24.7 24.7 0 016.451-9.51 22.767 22.767 0 012.544-2.021 18.909 18.909 0 012.715-1.678 24.737 24.737 0 0111.483-2.808 23.25 23.25 0 0111.469 2.946 15.612 15.612 0 012.746 1.734 20.961 20.961 0 012.243 1.83 24.166 24.166 0 016.229 9.538zm-43.018-2.879h38.849a21.527 21.527 0 00-4.695-6.468 18.67 18.67 0 00-1.99-1.619 13.029 13.029 0 00-2.37-1.5 20.48 20.48 0 00-10.137-2.62 21.84 21.84 0 00-10.151 2.482 16 16 0 00-2.364 1.462 19.907 19.907 0 00-2.283 1.808 21.953 21.953 0 00-4.858 6.455z"
            />
            <path className="titleHomeprefix__c" d="M301.305 122.333h89.785v47.735h-89.785z" />
            <path className="titleHomeprefix__d" d="M344.758 122.332h2.879v47.736h-2.879z" />
            <path
                className="titleHomeprefix__d"
                d="M302.365 136.993h86.758v1.439h-86.758zM302.365 152.677h86.758v1.439h-86.758z"
            />
            <path
                className="titleHomeprefix__d"
                d="M324.788 122.332h2.879v47.736h-2.879zM364.728 122.332h2.879v47.736h-2.879zM388.212 122.332h2.878v47.736h-2.878zM301.305 122.332h2.879v47.736h-2.879z"
            />
            <path
                className="titleHomeprefix__d"
                d="M301.31 167.191h89.785v2.879H301.31zM301.31 122.332h89.785v2.878H301.31z"
            />
            <path
                className="titleHomeprefix__d"
                d="M359.896 111.615l-13.558 14.365-13.155-14.508a18.04 18.04 0 012.562-1.583l10.622 11.716 10.967-11.6a14.171 14.171 0 012.562 1.61z"
            />
            <g>
                <path className="titleHomeprefix__c" d="M301.305 218.1h89.785v47.735h-89.785z" />
                <path className="titleHomeprefix__d" d="M344.758 218.099h2.879v47.736h-2.879z" />
                <path
                    className="titleHomeprefix__d"
                    d="M302.365 232.76h86.758v1.439h-86.758zM302.365 248.445h86.758v1.439h-86.758z"
                />
                <path className="titleHomeprefix__f" d="M324.788 218.099h2.879v47.736h-2.879z" />
                <path className="titleHomeprefix__d" d="M364.728 218.099h2.879v47.736h-2.879z" />
                <path
                    className="titleHomeprefix__f"
                    d="M388.212 218.099h2.878v47.736h-2.878zM301.305 218.099h2.879v47.736h-2.879z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M301.31 262.958h89.785v2.879H301.31zM301.31 218.099h89.785v2.879H301.31z"
                />
            </g>
            <g>
                <path className="titleHomeprefix__e" d="M459.221 113.125h39.792v47.735h-39.792z" />
                <path className="titleHomeprefix__d" d="M496.543 113.124h2.472v47.736h-2.472z" />
                <path
                    className="titleHomeprefix__d"
                    d="M459.691 127.785h38.45v1.439h-38.45zM459.691 143.469h38.45v1.439h-38.45z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M477.679 113.124h2.472v47.736h-2.472zM459.221 113.124h2.472v47.736h-2.472z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M459.223 157.983h39.792v2.879h-39.792zM459.223 113.124h39.792v2.879h-39.792z"
                />
            </g>
            <g>
                <path
                    className="titleHomeprefix__b"
                    d="M257.309 116.575a4.318 4.318 0 01-2.6-7.767c2.848-2.353 54.644-49.433 88.925-80.649a4.325 4.325 0 015.842.025l87.314 80.905a4.319 4.319 0 11-5.87 6.336l-85.339-69.253c-37.037 31.826-84.5 68.759-85.882 69.678a4.275 4.275 0 01-2.39.725z"
                />
            </g>
            <path className="titleHomeprefix__e" d="M82.484 198.211H190.14v114.394H82.484z" />
            <path className="titleHomeprefix__b" d="M424.749 168.984h115.544v45.941H424.749z" />
            <g>
                <path
                    className="titleHomeprefix__b"
                    d="M472.474 230.517a20.72 20.72 0 0120.72 20.72v49.917h-41.681v-49.676a20.961 20.961 0 0120.961-20.961z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M489.23 251.771v.921h-33.765v-.749a16.2 16.2 0 0116.206-16.206h1.525a16.02 16.02 0 0116.034 16.034zM455.466 257.923h33.765v13.57h-33.765zM455.466 275.974h33.765v22.181h-33.765z"
                />
                <path
                    className="titleHomeprefix__b"
                    d="M441.14 300.381h62.428v4.498H441.14zM435.859 304.879h72.99v4.498h-72.99zM431.52 309.377h81.667v4.498H431.52z"
                />
            </g>
            <path className="titleHomeprefix__b" d="M153.449 198.201H63.833l16.36-66.983h43.492z" />
            <g>
                <path
                    className="titleHomeprefix__b"
                    d="M254.824 29.73a6.051 6.051 0 016.052 6.051v21.564h-12.22V35.898a6.168 6.168 0 016.168-6.168zM245.792 27.126h17.811l-8.838-8.424z"
                />
                <path
                    className="titleHomeprefix__b"
                    d="M251.006 29.73h-.793l-.121-2.845h1.087zM259.171 29.73h-.793l-.121-2.845h1.087z"
                />
            </g>
            <g>
                <path className="titleHomeprefix__e" d="M211.515 150.465h39.792V198.2h-39.792z" />
                <path className="titleHomeprefix__d" d="M248.837 150.464h2.472V198.2h-2.472z" />
                <path
                    className="titleHomeprefix__d"
                    d="M211.985 165.125h38.45v1.439h-38.45zM211.985 180.809h38.45v1.439h-38.45z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M229.973 150.464h2.472V198.2h-2.472zM211.515 150.464h2.472V198.2h-2.472z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M211.517 195.323h39.792v2.879h-39.792zM211.517 150.464h39.792v2.879h-39.792z"
                />
            </g>
            <g>
                <path className="titleHomeprefix__e" d="M211.515 218.1h39.792v47.735h-39.792z" />
                <path className="titleHomeprefix__d" d="M248.837 218.099h2.472v47.736h-2.472z" />
                <path
                    className="titleHomeprefix__d"
                    d="M211.985 232.76h38.45v1.439h-38.45zM211.985 248.444h38.45v1.439h-38.45z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M229.973 218.099h2.472v47.736h-2.472zM211.515 218.099h2.472v47.736h-2.472z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M211.517 262.958h39.792v2.879h-39.792zM211.517 218.099h39.792v2.879h-39.792z"
                />
            </g>
            <g transform="translate(220.555 20.004)">
                <path
                    className="titleHomeprefix__e"
                    d="M16.86.002s-26.991-1.32-12.673 80.941c0 0 9.633 27.923-2.527 45.921h179V.002z"
                />
                <path className="titleHomeprefix__b" d="M28.211 48.692h186.88v243.717H28.211z" />
                <path fill="#f8fbff" d="M34.319 48.692h183.739v243.717H34.319z" />
                <path
                    className="titleHomeprefix__h"
                    d="M218.06 48.688H34.321c1.016-13.894-.515-23.984-2.968-31.239C26.641 3.498 18.52-.002 18.52-.002h173.281c14.79 0 26.257 8.91 29.379 20.736.116.445.225.888.319 1.331 2.963 13.533-3.317 26.376-3.439 26.623z"
                />
                <path
                    className="titleHomeprefix__h"
                    d="M218.06 48.689H34.32c1.017-13.895-.514-23.984-2.968-31.239 18.777 3.438 65.284 10.765 115.063 9.531 32.2-.8 57.652-2.954 75.086-4.915 2.961 13.533-3.32 26.375-3.441 26.623z"
                />
                <rect
                    className="titleHomeprefix__i"
                    width={101.078}
                    height={4.571}
                    rx={0.349}
                    transform="translate(70.264 100.319)"
                />
                <rect
                    className="titleHomeprefix__i"
                    width={62.981}
                    height={4.571}
                    rx={0.349}
                    transform="translate(70.264 108.009)"
                />
                <path
                    className="titleHomeprefix__i"
                    d="M60.882 111.839v-9.336H59.65q-1.121 0-1.121-.934 0-.672.877-.952a6.522 6.522 0 011.429-.225q.98-.074 1.577-.074.579 0 .728.233a2.772 2.772 0 01.15 1.185v10.1h1.269q1.232 0 1.232 1.064 0 1.12-1.232 1.12h-5.116a1.409 1.409 0 01-.906-.289.979.979 0 01-.364-.812q0-1.082 1.251-1.083z"
                />
                <rect
                    className="titleHomeprefix__j"
                    width={101.078}
                    height={4.57}
                    rx={0.349}
                    transform="translate(70.264 148.454)"
                />
                <rect
                    className="titleHomeprefix__j"
                    width={62.981}
                    height={4.57}
                    rx={0.349}
                    transform="translate(70.264 156.144)"
                />
                <path
                    className="titleHomeprefix__j"
                    d="M65.982 159.782q0 .933-1.3.932h-5.413q-1.172 0-1.172-.94a1.191 1.191 0 01.314-.693q.461-.528 1.353-1.584 1.353-1.7 2.26-2.937.313-.412.891-1.254a3.031 3.031 0 00.479-1.419 1.462 1.462 0 00-.412-1.064 1.415 1.415 0 00-1.056-.421 1.62 1.62 0 00-1.09.4 1.877 1.877 0 00-.495.99 10.927 10.927 0 01-.3 1.089.835.835 0 01-.807.528.927.927 0 01-.776-.363 1.238 1.238 0 01-.264-.825 3.986 3.986 0 01.182-1.172 3.523 3.523 0 011.4-1.947 4.071 4.071 0 012.327-.643 3.6 3.6 0 012.507.909 3.178 3.178 0 011.04 2.446 4.259 4.259 0 01-.743 2.281q-.412.645-1.7 2.281-.661.843-2.079 2.446h3.564q1.29-.005 1.29.96z"
                />
                <rect
                    className="titleHomeprefix__j"
                    width={101.078}
                    height={4.571}
                    rx={0.349}
                    transform="translate(70.264 188.605)"
                />
                <rect
                    className="titleHomeprefix__j"
                    width={62.981}
                    height={4.57}
                    rx={0.349}
                    transform="translate(70.264 196.295)"
                />
                <path
                    className="titleHomeprefix__j"
                    d="M65.982 197.037a3.541 3.541 0 01-1.23 2.817 4.341 4.341 0 01-2.953 1.012 4.133 4.133 0 01-2.361-.657 3.008 3.008 0 01-1.552-2.513 1.055 1.055 0 01.27-.726.884.884 0 01.692-.3 1.055 1.055 0 01.826.59 12.979 12.979 0 00.726 1.08 1.882 1.882 0 001.435.59 1.791 1.791 0 001.686-.877 2.329 2.329 0 00.236-1.114q0-1.652-1.94-1.838-1.1-.1-1.1-.928a.883.883 0 01.636-.86q1.189-.438 1.207-.455a1.629 1.629 0 00.636-1.468 1.322 1.322 0 00-.369-.987 1.357 1.357 0 00-.992-.363 1.817 1.817 0 00-1.21.371 2.432 2.432 0 00-.623 1.1.768.768 0 01-.773.59.792.792 0 01-.664-.354 1.281 1.281 0 01-.26-.776 2.153 2.153 0 01.287-1.046 3.227 3.227 0 011.45-1.366 4.245 4.245 0 011.957-.455 3.408 3.408 0 012.386.869 2.945 2.945 0 01.953 2.285 3.13 3.13 0 01-1.417 2.7 3.057 3.057 0 012.061 3.079z"
                />
                <rect
                    className="titleHomeprefix__i"
                    width={64.479}
                    height={5.31}
                    rx={0.405}
                    transform="translate(95.582 59.57)"
                />
                <path
                    className="titleHomeprefix__i"
                    d="M79.726 67.757h96.187a.4.4 0 01.4.4v4.5a.405.405 0 01-.405.405H79.731a.405.405 0 01-.405-.405v-4.5a.4.4 0 01.4-.4z"
                />
                <path
                    className="titleHomeprefix__k"
                    d="M120.704 129.536c-20.016 0-39.01-2.512-53.17-6.1-14.306-3.627-22.012-9.555-22.012-14.813 0-4.766 6.28-9.148 18.161-12.675-11.386 3.38-17.475 8.343-17.475 12.675 0 4.808 7.893 9.566 21.655 13.056 14.11 3.577 32.876 5.548 52.841 5.548s38.73-1.971 52.841-5.548c13.762-3.489 21.656-8.248 21.656-13.056 0-5.091-8.312-11.419-21.691-16.513-15.213-5.793-33.967-7.942-52.805-7.942-35.369 0-49.928 4.129-62.561 9.3 12.687-5.2 27.067-11.086 62.561-11.086 18.914 0 37.748 3.206 53.032 9.025 13.662 5.2 22.149 11.8 22.149 17.214 0 5.258-7.878 10.151-22.184 13.778-14.161 3.591-32.982 7.137-52.998 7.137z"
                />
                <path
                    className="titleHomeprefix__l"
                    d="M242.252 176.458s-.659 6.408-1.382 7.62-3.8 5.086-5.114 5.845.8-5.1.534-6.244-1.08 2.082-1.658 2.158-.43-2.825-.45-3.881 2.812-3.662 3.811-5.91 4.259.412 4.259.412zM163.526 117.359l-1.472-2.779s.73-4.952-5.474-8.084a3.028 3.028 0 00-3.547.926c-1.179 1.7 2.247 1.4 2.063 4.31a5.359 5.359 0 003.125 5.573l1.592 1.843z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M222.159 110.716s-5.328 26.15-34.647 34.694c-9.153 2.667-29.945-27.464-29.945-27.464s.731-2.778 5.311-2.14c0 0 20.245 13.4 22.793 14.859 2.263 1.292 17.135-16.608 23.916-19.5a17.307 17.307 0 0112.572-.449z"
                />
                <path fill="#fb7369" d="M143.309 110.987l.831-5.413 27.227 4.182-.831 5.413z" />
                <path
                    className="titleHomeprefix__n"
                    d="M137.395 107.308l5.915 3.679.83-5.413zM174.255 112.039l-.262 1.712a1.928 1.928 0 01-2.2 1.613l-1.259-.193.831-5.413 1.37.21a1.816 1.816 0 011.52 2.071z"
                />
                <path
                    className="titleHomeprefix__k"
                    d="M257.161 275.102s3.637 3.32 2.927 5.678-3.992 3.93-4.436 6.813-2.217 4.455-3.814 4.455h-7.613s-1.175-2.028 1.664-2.639 3.908-4 3.731-7.143a55.42 55.42 0 01.267-7.337l6.032-1.4zM212.538 283.688s.652 7.554-.944 8.34l-20.436-.041s0-2.358 3.282-2.883 9.049-2.446 10.025-6.726 8.073 1.31 8.073 1.31z"
                />
                <path
                    className="titleHomeprefix__o"
                    d="M195.288 176.979s1.077 13.241 2.344 29.158c1.722 21.622 3.793 48.182 4 53.213.354 8.737 1.776 22.713 1.776 22.713s3.369 3.32 10.113 1.921a225.505 225.505 0 007.726-41.059l.08-1.043c1.6-21.489 4.08-66.737 4.08-66.737z"
                />
                <path
                    d="M195.288 176.979s1.077 13.241 2.344 29.158l23.612 36.787.08-1.043c1.6-21.489 4.08-66.737 4.08-66.737z"
                    fill="#006dff"
                />
                <path
                    className="titleHomeprefix__o"
                    d="M200.208 178.551s19.428 47.956 32.2 70.493 16.768 28.826 16.768 28.826 7.318-2.883 7.983-4.848c0 0-4.885-43.508-13.439-54.114-4.834-5.993-10.513-23.978-10.78-30.268s-1.729-18.737-1.729-18.737z"
                />
                <path
                    className="titleHomeprefix__l"
                    d="M225.77 104.647s-13.145 6.343-20.723 3.989a17.885 17.885 0 015.129-4.1c-.262-1.521-.466-2.841-.583-3.961a36.618 36.618 0 01-.32-4.047l5.072-3.989 4.81-3.817c-.381 10.731 6.615 15.925 6.615 15.925z"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M232.698 172.361c.029.8-1.69 1.722-2.449 2.066a53.678 53.678 0 01-16.818 4.764c-7.025.718-15.331-.861-18.187-.8a1.189 1.189 0 01-.787-.315c-.2-.144-.35-8.61-.2-19.258 0-.431 0-.861.03-1.292v-.143c.058-4.162.2-8.524.407-12.886.408-9.586 1.166-18.828 2.448-23.936a25.947 25.947 0 018.219-12.915l2.624-2.3a12.6 12.6 0 008.161-.975c3.031-1.464 6.3-3.042 6.821-2.641 3.963 2.9 6.645 9.414 8.073 17.737a92.286 92.286 0 011.194 15.785c0 2.066-.059 4.19-.174 6.285a198.289 198.289 0 00.407 26.949c.114 1.665.201 2.956.231 3.875z"
                />
                <path
                    d="M219.24 92.823s-3.323 6.056-9.647 7.749a36.678 36.678 0 01-.32-4.047l5.071-3.989z"
                    fill="#f48484"
                />
                <path
                    className="titleHomeprefix__l"
                    d="M206.959 96.351s12.541 2.051 13.718-5.658 3.588-12.637-4.368-14.272-9.965 1.075-10.994 3.554-1.587 15.644 1.644 16.376z"
                />
                <path
                    d="M225.236 75.497c.114-.13.376-.236.96-.268a2.369 2.369 0 00-1.091-.308 2.94 2.94 0 00-1.954-2.6 1.754 1.754 0 01.851 1.265c-1.62-1.663-5.091-2.874-14.7-2.116-12.666 1-8.491 9.109-6.852 10.076s5.768-1.684 8.822-1.441 3.637 4.657 4.674 5.263.629-.465 2.031-1.1 1.536 1.69 1.131 3.2 1.522 3.5 1.522 3.5l1.947-2.1c1.948-2.1 3.782-10.978 2.9-12.872-.084-.16-.157-.329-.241-.499z"
                    fill="#6f3425"
                />
                <path
                    className="titleHomeprefix__d"
                    d="M222.957 101.666s7.935 3.757 11.739 9.534c5.9 8.959 12.741 41.613 12.867 44.636s-4.992 24.371-4.992 24.371-5.192-.342-6.531-2.962c0 0 1.038-21.934.38-23.344s-21.643-29.422-19.284-35.4 5.821-16.835 5.821-16.835z"
                />
                <path
                    className="titleHomeprefix__l"
                    d="M162.027 114.449s.431-1.7-.75-2.47-2.271-2.85-2.769-2.567c-1.032.584.565 2.4.272 4.67-.101.793 1.747 2.874 3.247.367z"
                />
                <path
                    className="titleHomeprefix__j"
                    d="M176.398 239.409H63.414a1.379 1.379 0 01-1.375-1.375 1.379 1.379 0 011.375-1.375h112.984a1.379 1.379 0 011.375 1.375 1.379 1.379 0 01-1.375 1.375zM176.398 244.91H63.414a1.379 1.379 0 01-1.375-1.375 1.379 1.379 0 011.375-1.375h112.984a1.379 1.379 0 011.375 1.375 1.378 1.378 0 01-1.375 1.375zM176.398 251.008H63.414a1.379 1.379 0 01-1.375-1.375 1.379 1.379 0 011.375-1.375h112.984a1.378 1.378 0 011.375 1.375 1.379 1.379 0 01-1.375 1.375z"
                />
                <rect
                    className="titleHomeprefix__j"
                    width={100.81}
                    height={2.75}
                    rx={0.21}
                    transform="translate(62.04 254.358)"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
