import {
    GET_ENERGY_TYPE,
    GET_ENERGY_TYPE_FAILURE,
    GET_ENERGY_TYPE_SUCCESS,
    GET_COMPANY,
    GET_COMPANY_FAILURE,
    GET_COMPANY_SUCCESS,
    GET_ENERGY_PLAN,
    GET_ENERGY_PLAN_FAILURE,
    GET_ENERGY_PLAN_SUCCESS,
    GET_SWITCH_TYPE,
    GET_SWITCH_TYPE_FAILURE,
    GET_SWITCH_TYPE_SUCCESS,
    POST_SUBMIT_CONTRACT,
    POST_SUBMIT_CONTRACT_FAILURE,
    POST_SUBMIT_CONTRACT_SUCCESS,
    GET_ENERGY_SUMMARY,
    GET_ENERGY_SUMMARY_FAILURE,
    GET_ENERGY_SUMMARY_SUCCESS,
    GET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN_FAILURE,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_DISCLAIMER_DETAILS,
    GET_DISCLAIMER_DETAILS_FAILURE,
    GET_DISCLAIMER_DETAILS_SUCCESS,
    GET_ACCOUNT_NUMBER,
    GET_ACCOUNT_NUMBER_FAILURE,
    GET_ACCOUNT_NUMBER_SUCCESS,
} from './actionTypes';

import { EnergyActions, EnergyState } from './types';

const initialState: EnergyState = {
    pending: false,
    error: null,
    getEnergyType: [],
    isGetEnergyType: false,
    getCompany: [],
    isGetCompany: false,
    getEnergyPlan: [],
    isGetEnergyPlan: false,
    getSwitchType: [],
    isGetSwitchType: false,
    postSubmitContract: [],
    isPostSubmitContract: false,
    getEnergySummary: [],
    isGetEnergySummary: false,
    getAccessToken: [],
    isGetAccessToken: false,
    getDisclaimerDetails: [],
    isGetDisclaimerDetails: false,
    getAccountNumber: [],
    isGetAccountNumber: false,
    postSubmitContractError: '',
};

export default (state = initialState, action: EnergyActions) => {
    switch (action.type) {
        case GET_ENERGY_TYPE:
            return {
                ...state,
                getEnergyType: [],
                isGetEnergyType: false,
            };
        case GET_ENERGY_TYPE_SUCCESS:
            return {
                ...state,
                getEnergyType: action.payload.getEnergyType,
                isGetEnergyType: true,
                error: null,
            };
        case GET_ENERGY_TYPE_FAILURE:
            return {
                ...state,
                getEnergyType: [],
                isGetEnergyType: false,
                error: action.payload.error,
            };
        case GET_COMPANY:
            return {
                ...state,
                getCompany: [],
                isGetCompany: false,
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                getCompany: action.payload.getCompany,
                isGetCompany: true,
                error: null,
            };
        case GET_COMPANY_FAILURE:
            return {
                ...state,
                getCompany: [],
                isGetCompany: false,
                error: action.payload.error,
            };
        case GET_ENERGY_PLAN:
            return {
                ...state,
                getEnergyPlan: [],
                isGetEnergyPlan: false,
            };
        case GET_ENERGY_PLAN_SUCCESS:
            return {
                ...state,
                getEnergyPlan: action.payload.getEnergyPlan,
                isGetEnergyPlan: true,
                error: null,
            };
        case GET_ENERGY_PLAN_FAILURE:
            return {
                ...state,
                getEnergyPlan: [],
                isGetEnergyPlan: false,
                error: action.payload.error,
            };
        case GET_SWITCH_TYPE:
            return {
                ...state,
                getSwitchType: [],
                isGetSwitchType: false,
            };
        case GET_SWITCH_TYPE_SUCCESS:
            return {
                ...state,
                getSwitchType: action.payload.getSwitchType,
                isGetSwitchType: true,
                error: null,
            };
        case GET_SWITCH_TYPE_FAILURE:
            return {
                ...state,
                getSwitchType: [],
                isGetSwitchType: false,
                error: action.payload.error,
            };
        case POST_SUBMIT_CONTRACT:
            return {
                ...state,
                postSubmitContract: [],
                isPostSubmitContract: false,
                postSubmitContractError: '',
            };
        case POST_SUBMIT_CONTRACT_SUCCESS:
            return {
                ...state,
                postSubmitContract: action.payload.postSubmitContract,
                isPostSubmitContract: true,
                error: '',
            };
        case POST_SUBMIT_CONTRACT_FAILURE:
            return {
                ...state,
                postSubmitContract: [],
                isPostSubmitContract: false,
                postSubmitContractError: action.payload.postSubmitContractError,
            };
        case GET_ENERGY_SUMMARY:
            return {
                ...state,
                getEnergySummary: [],
                isGetEnergySummary: false,
            };
        case GET_ENERGY_SUMMARY_SUCCESS:
            return {
                ...state,
                getEnergySummary: action.payload.getEnergySummary,
                isGetEnergySummary: true,
                error: null,
            };
        case GET_ENERGY_SUMMARY_FAILURE:
            return {
                ...state,
                getEnergySummary: [],
                isGetEnergySummary: false,
                error: action.payload.error,
            };
        case GET_ACCESS_TOKEN:
            return {
                ...state,
                getAccessToken: [],
                isGetAccessToken: false,
            };
        case GET_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                getAccessToken: action.payload.getAccessToken,
                isGetAccessToken: true,
                error: null,
            };
        case GET_ACCESS_TOKEN_FAILURE:
            return {
                ...state,
                getAccessToken: [],
                isGetAccessToken: false,
                error: action.payload.error,
            };
        case GET_DISCLAIMER_DETAILS:
            return {
                ...state,
                getDisclaimerDetails: [],
                isGetDisclaimerDetails: false,
            };
        case GET_DISCLAIMER_DETAILS_SUCCESS:
            return {
                ...state,
                getDisclaimerDetails: action.payload.getDisclaimerDetails,
                isGetDisclaimerDetails: true,
                error: null,
            };
        case GET_DISCLAIMER_DETAILS_FAILURE:
            return {
                ...state,
                getDisclaimerDetails: [],
                isGetDisclaimerDetails: false,
                error: action.payload.error,
            };
        case GET_ACCOUNT_NUMBER:
            return {
                ...state,
                getAccountNumber: [],
                isGetAccountNumber: false,
            };
        case GET_ACCOUNT_NUMBER_SUCCESS:
            return {
                ...state,
                getAccountNumber: action.payload.getAccountNumber,
                isGetAccountNumber: true,
                error: null,
            };
        case GET_ACCOUNT_NUMBER_FAILURE:
            return {
                ...state,
                getAccountNumber: [],
                isGetAccountNumber: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
