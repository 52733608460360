import React from 'react';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import locationIcon from '../../Assets/images/MovingImages/location-icon.svg';
import styles from '../../styles/trustedProsCard.module.scss';
import homeAdvisorImage from '../../Assets/images/TrustedPros/homeAdvisorImage.png';
import LanguageIcon from '@material-ui/icons/Language';
import tick from '../../Assets/images/TrustedPros/tick.svg';
import mail from '../../Assets/images/TrustedPros/message.svg';
import callIcon from '../../Assets/images/MovingImages/call.svg';

import { ReactComponent as MsgIcon } from '../../Assets/images/TrustedPros/msgDe.svg';
interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    boxShadow?: any;
    checked: boolean;
    handleChange: any;
    rate?: number;
    verifiedReview?: any;
    phoneNumber?: string;
    locationtext?: string;
    subHeading?: string;
    logoInitials?: string;
    homeAdvisor?: boolean;
    website?: string;
    email?: string;
    representation?: any;
    brokerage_image?: any;
    logoImage?: any;
}

let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

const TrustedProsCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
            checkroot: {},
        },
    }));


    const getMainIcon = () => {
        return <div className={styles.cardRightBottom}>
            {isDouglasElliman ? <MsgIcon className={styles.img}/> : <img src={mail} alt="" />}
        </div>
    }

    const classes = useStyles();
    if (props.homeAdvisor) {
    }
    let splitArray: any = props.representation?.split(' ') ? props.representation?.split(' ') : props.representation;
    let splitAgent: any = props.homeAdvisor ? props.representation : splitArray[0];

    return (
        <div
            style={{
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                border: props.border ? props.border : props.checked ? '1px solid #002E62' : '',
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '20px 0px',
                padding: props.cardPadding ? props.cardPadding : '0px',
                cursor: 'pointer',
            }}
            onClick={props.handleChange}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} trusted-card-body`}
        >
            <div className={styles.cardMainTrusted}>
                <div className={styles.cardLeftContainer}>
                    <div className={styles.cardLeftContent}>
                        <div>
                            <Checkbox
                                checked={props.checked}
                                color="default"
                                style={{ color: props.checked ? '#273E59' : '#BEBEBE' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        <div className={props.logoImage ? styles.imageSection : styles.imageTextSection}>
                            {props.logoImage ? <img src={props.logoImage} alt="" /> : <span>{props.logoInitials}</span>}
                        </div>
                        <div className={styles.cardDetails}>
                            <div className={styles.cardTitle}>{props.cardTitle}</div>
                            <div className={styles.cardSubDetails}>
                                <div>
                                    {props.phoneNumber ? (
                                        <div className={styles.cardSubGroup}>
                                            <img src={callIcon}  className={styles.img} alt=""/>
                                            <span>{props.phoneNumber}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {props.email ? (
                                        <div className={styles.cardSubGroup}>
                                            <MsgIcon className={styles.img} />
                                            <span>{props.email}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {props.locationtext ? (
                                        <div className={styles.cardSubGroup}>
                                            <img src={locationIcon}  className={styles.img}/>
                                            <span>{props.locationtext}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {props.website ? (
                                        <div className={styles.cardSubGroup}>
                                            <LanguageIcon className={styles.img}/>
                                            <span>{props.website}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {props.homeAdvisor == true ? (
                            <div className={styles.cardRightContent}>
                                <div className={styles.cardRightTopContent}>
                                    <img src={homeAdvisorImage} alt="" />
                                    <div className={styles.agentType}>
                                        <span> {props.representation} </span>
                                    </div>
                                </div>
                                {getMainIcon()}
                            </div>
                        ) : props.representation == 'Agent Preferred' ? (
                            <div className={styles.cardRightContent}>
                                <div className={styles.cardRightTopContent} style={{ display: "flex", flexDirection: "row", textAlign:"left"}}>
                                    <img src={tick} alt="" />
                                    <div className={styles.agentType}>
                                        <div>{props.representation ? `${splitAgent}` : null}</div>
                                        <span>PREFERRED</span>
                                    </div>
                                </div>
                                {getMainIcon()}
                            </div>
                        ) : props.representation == 'Brokerage Preferred' ? (
                            <div className={styles.cardRightContent}>
                                <div className={styles.cardRightTopContent}>
                                    {props.representation ? <img src={props.brokerage_image} style={{ width: '100%' }} alt="" /> : null}
                                    <div className={styles.agentType}>
                                        <div></div>
                                        <span>PREFERRED</span>
                                    </div>
                                </div>
                                {getMainIcon()}
                            </div>
                        ) : (
                            <div className={styles.cardRightContent}>
                                <div className={styles.cardRightTopContent} style={{ display: "flex", flexDirection: "row", textAlign:"left"}}>
                                    <img src={tick} alt="" />
                                    <div className={styles.agentType}>
                                        <div>{props.representation ? `${splitAgent}` : null} </div>
                                        <span>PREFERRED</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustedProsCard;
