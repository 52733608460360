import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_DATA,
    RESET_PASSWORD_DATA_ERROR,
    RESET_PASSWORD_DATA_SUCCESS,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_DATA,
    SET_PASSWORD_DATA_ERROR,
    SET_PASSWORD_DATA_SUCCESS
} from './actionTypes';

import { LoginActions, LoginState } from './types';

const initialState: LoginState = {
    login: [],
    error: null,
    resetPassword: [],
    errorResetPassword: null,
    resetPasswordData: [],
    setPassword: [],
    passwordResetSuccessful:false,
    setPasswordData: [],
    passwordSetSuccessful:false,
};

export default (state = initialState, action: LoginActions) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                login: [],
                error: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload.token,
                error: null,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                login: [],
                error: action.payload.error,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                resetPassword: [],
                errorResetPassword: null,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: action.payload.resetPassword,
                errorResetPassword: null,
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPassword: [],
                errorResetPassword: action.payload.errorResetPassword,
            };
        case RESET_PASSWORD_DATA:
            return {
                ...state,
                resetPasswordData: [],
                errorResetPasswordData: null,
            };
        case RESET_PASSWORD_DATA_SUCCESS:
                return {
                    ...state,
                    resetPasswordData: action.payload.resetPasswordData,
                    errorResetPasswordData: null,
                    passwordResetSuccessful:true
                };
        case RESET_PASSWORD_DATA_ERROR:
            return {
                ...state,
                resetPasswordData: [],
                errorResetPasswordData: action.payload.errorResetPasswordData,
            };

        case SET_PASSWORD:
            return {
                ...state,
                setPassword: [],
                error: null,
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                setPassword: action.payload.setPassword,
                error: null,
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                setPassword: [],
                error: action.payload.error,
            };

            case SET_PASSWORD_DATA:
            return {
                ...state,
                setPasswordData: [],
                error: null,
            };
        case SET_PASSWORD_DATA_SUCCESS:
            return {
                ...state,
                setPasswordData: action.payload.setPasswordData,
                passwordSetSuccessful:true,
                error: null,
            };
        case SET_PASSWORD_DATA_ERROR:
            return {
                ...state,
                setPasswordData: [],
                error: action.payload.error,
            };    
        default:
            return {
                ...state,
            };
    }
};
