import React from 'react';

import './Disclamer.scss';

interface cardProps {
    content: string;
    title?: boolean;
}

const Disclamer = (props: cardProps) => {
    return (
        <div className="disclamerContainer">
            {props.title && <span className="disclamerTitle">Disclaimer:-</span>}

            <span className="disclamerContent">{props.content}</span>
        </div>
    );
};

export default Disclamer;
