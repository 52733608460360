import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './insuranceSkeleton.scss';

export class CallQouteCardSeleton extends Component {
    render() {
        return (
            <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                <div className="callQuoteSkeletonCard">
                    <div className="callQuoteSkeletonCard1">
                        <Skeleton height="60px" />
                    </div>
                    <div className="callQuoteSkeletonCard2">
                        <div className="callQuoteSkeletonCard3">
                            <Skeleton height="40px" />
                        </div>
                        <div className="callQuoteSkeletonCard4">
                            <Skeleton height="20px" />
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        );
    }
}
export class PlanCardSeleton extends Component {
    render() {
        return (
            <SkeletonTheme color="#EEEEEE" highlightColor="#D3D3D3">
                <div className="PlanskeletonCard">
                    <div className="PlanskeletonCard1">
                        <Skeleton height="50px" />
                        <Skeleton height="20px" style={{ marginTop: '5px' }} />
                    </div>
                    {/* <div className="PlanskeletonCard2"> */}
                    <div className="PlanskeletonCard2">
                        <Skeleton height="30px" />
                        <Skeleton height="20px" style={{ marginTop: '5px' }} />
                    </div>
                    <div className="PlanskeletonCard3">
                        <Skeleton height="40px" />
                    </div>
                </div>
                {/* </div> */}
            </SkeletonTheme>
        );
    }
}
