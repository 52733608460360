import React from 'react';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
// import '../../styles/planCard.scss';
import '../../styles/longCheckboxCard.css';
import Rating from '@material-ui/lab/Rating';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    boxShadow?: any;
    checked: boolean;
    handleChange: any;
    label?: string;
    bestForImage?: any;
    bestForTitle?: string;
    cardTitel?: string;
    rate?: number;
    ratingIcon?: any;
    verifiedReview?: any;
    phoneIcon?: any;
    phoneNumber?: string;
    locationIcon?: any;
    locationtext?: string;
    yearsIcon?: any;
    yearText?: string;
    licensedIcon?: any;
    licensedText?: string;
    insuredIcon?: any;
    insuredText?: string;
}

const MovingPlanCard = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #9C9DA1',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
            checkroot: {},
        },
    }));
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

    const classes = useStyles();
    return (
        <div
            style={{
                width: props.width ? props.width : '200px',
                height: props.height ? props.height : 'auto',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: props.borderRadius ? props.borderRadius : '3px',
                boxShadow: props.boxShadow ? props.boxShadow : '0 0 3px #9C9DA1',
                border: props.border ? props.border : props.checked ? '1px solid #002E62' : '',
                opacity: '1',
                margin: props.cardMargin ? props.cardMargin : '20px 0px',
                padding: props.cardPadding ? props.cardPadding : '15px',
                cursor: 'pointer',
            }}
            onClick={props.handleChange}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} main-body-card`}
        >
            <div className="mover-card-main">
                <div className="inside-mover">
                    <div className="checkbox-image-div">
                        <div style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                            <Checkbox
                                checked={props.checked}
                                // onChange={props.handleChange}
                                color="default"
                                style={{ color: props.checked ? '#273E59' : '#BEBEBE' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>

                        <div style={{ marginLeft: '20%' }} className="image-div-other-card">
                            <img src={props.cardImage} className="image-image" alt="" />
                        </div>
                    </div>
                    <div className="best-moves">
                        {/* {props.bestForImage ? (
                            <div className="best-image-div">
                                <img
                                    src={props.bestForImage}
                                    className="bestImage"
                                    alt=""
                                />
                                <span className="best-title">{props.bestForTitle}</span>
                            </div>
                        ) : null} */}
                        <div className="movers-heading">{props.cardTitle}</div>
                        <div className="main-div-rate">
                            {props.rate !== 0 ? (
                                <div className="rating-section">
                                    <Rating name="read-only" value={props.rate} readOnly />
                                </div>
                            ) : null}
                            {props.rate !== 0 ? (
                                <div className="test-rate">
                                    <div className="rating-div">
                                        <div className={isDouglasElliman ? 'rating-button' : 'rating-buttonDoug'}>
                                            <img style={{ marginRight: '5px' }} src={props.ratingIcon} alt="" />
                                            <span> {props.rate}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="verified-review">{props.verifiedReview ? props.verifiedReview : null}</div>
                    </div>
                </div>
                <div className="last-icon-main-div">
                    <div className="last-icon-div">
                        <div className="icon-div">
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {' '}
                                <img src={props.yearsIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                {' '}
                                <p className="years-licencsed-text">{props.yearText}yrs</p>
                            </div>
                        </div>
                        <div className="icon-div">
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {' '}
                                <img src={props.licensedIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                {' '}
                                <p className="years-licencsed-text">{props.licensedText}</p>
                            </div>
                        </div>
                        <div className="icon-div">
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {' '}
                                <img src={props.insuredIcon} height="25px" width="25px" alt="" />
                            </div>
                            <div>
                                <p className="years-licencsed-text">{props.insuredText}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }} className="phoneAddress">
                        <img src={props.phoneIcon} alt="" />
                        <p className="bottom-card-detail">{props.phoneNumber}</p>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={props.locationIcon} alt="" />
                        <p className="bottom-card-detail">{props.locationtext}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovingPlanCard;
