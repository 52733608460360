import React from 'react';
import '../../styles/policyDetailsCard.css';

interface policyDetailsCardProps {
    premiumMonthly?: string;
    premiumYearly?: string;
    logo?: string;
    width?: string;
    border?: string;
}

const PolicyDetailsCard = (props: policyDetailsCardProps) => {
    return (
        <div
            style={{
                border: props.border ? props.border : '1px solid #e2e2e2',
                width: props.width ? props.width : '30%',
            }}
        >
            <div className="content">
                <div className="logoDiv">
                    <img src={props.logo} alt="" />
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div className="premium" style={{ marginRight: '20px' }}>
                        <div className="premium">Premium</div>
                        <div style={{ display: 'flex' }}>
                            <span className="coverageCurrency1">$</span>
                            <span className="premium-monthly">{props.premiumMonthly}</span>
                            <span>{'*'}</span>
                        </div>
                        <div className="estimate">Monthly estimate</div>
                    </div>
                    <div className="premium">
                        <div style={{ paddingTop: '20px', display: 'flex' }}>
                            <span className="coverageCurrency1">$</span>
                            <span className="premium-monthly">{props.premiumYearly}</span>
                            <span>{'*'}</span>
                        </div>
                        <div className="estimate">Yearly estimate</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyDetailsCard;
