import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={49.512} height={54} viewBox="0 0 49.512 54" {...props}>
            <path
                d="M48.387 19.121H1.125v-9h40.51z"
                fill={
                    slug && slug.color_shade4
                        ? props.isdouglaselliman
                            ? '#ffffff'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }
            />
            <path
                d="M49.287 18.441l-6.752-9a1.125 1.125 0 00-.9-.45H21.846L13.172.32l-.011-.009a1.22 1.22 0 00-1.2-.241h-.009L.713 4.564a1.125 1.125 0 00-.707 1.044v18a1.125 1.125 0 002.251 0v-3.376h9V51.74H3.38a1.125 1.125 0 100 2.251h27.007a1.125 1.125 0 000-2.251H22.51V20.242h15.754V30.37a1.124 1.124 0 001.125 1.125 3.376 3.376 0 11-3.376 3.376 1.125 1.125 0 10-2.251 0 5.626 5.626 0 106.752-5.514v-9.115h7.877a1.125 1.125 0 00.9-1.8zM13.5 3.828l5.161 5.16H13.5zm-2.251-1.056v6.216h-9V6.375zm9 41.1l-6-4.5 6-4.5zm-6.752-6.752v-9l6 4.5zm6 9l-6 4.5v-9zm-5.248-20.255l6-4.5v9zm-.752-2.251v-3.376H18zm2.251 28.132l4.5-3.376v3.376zm5.626-33.758H2.244v-6.752h38.822l5.064 6.752z"
                fill={
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }
            />
        </svg>
    );
}

export default SvgComponent;
