import {
    GET_MOVERS,
    GET_MOVERS_ERROR,
    GET_MOVERS_SUCCESS,
    GET_SELECTED_MOVERS,
    GET_SELECTED_MOVERS_ERROR,
    GET_SELECTED_MOVERS_SUCCESS,
    GET_REVERSE_STORAGE,
    GET_REVERSE_STORAGE_SUCCESS,
    GET_REVERSE_STORAGE_ERROR,
    GET_RESERVE_STORAGE_BY_FACILITY_ID,
    GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS,
    GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR,
    POST_MOVERS,
    POST_MOVERS_ERROR,
    POST_MOVERS_SUCCESS,
    POST_RESERVE_STORAGE,
    POST_RESERVE_STORAGE_SUCCESS,
    POST_RESERVE_STORAGE_ERROR,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS,
    UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR,
    UPDATE_DISCLAIMER,
    UPDATE_DISCLAIMER_SUCCESS,
    UPDATE_DISCLAIMER_ERROR,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS,
    UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR,
    GET_DIY,
    GET_DIY_SUCCESS,
    GET_DIY_ERROR,
} from './actionTypes';

import { MoversActions, MoversState } from './types';

const initialState: MoversState = {
    Postmovers: [],
    selectedMovers: [],
    movers: [],
    reverseStorage: [],
    reserveStorageByFacilityId: [],
    postReserveStorage: [],
    updateMoveSourceHomeInfo: [],
    updateDisclaimer: [],
    updateMoveDestinationHomeInfo: [],
    error: null,
    postDataArrived: false,
    postReserveStorageArrived: false,
    diy: [],
    diyDataArrived: false,
};

export default (state = initialState, action: MoversActions) => {
    switch (action.type) {
        case GET_SELECTED_MOVERS:
            return {
                ...state,
                selectedMovers: [],
                error: null,
            };
        case GET_SELECTED_MOVERS_SUCCESS:
            return {
                ...state,
                selectedMovers: action.payload.selectedMovers,
                error: null,
            };
        case GET_SELECTED_MOVERS_ERROR:
            return {
                ...state,
                selectedMovers: [],
                error: action.payload.error,
            };
        case GET_MOVERS:
            return {
                ...state,
                movers: [],
                error: null,
            };
        case GET_MOVERS_SUCCESS:
            return {
                ...state,
                movers: action.payload.movers,
                error: null,
            };
        case GET_MOVERS_ERROR:
            return {
                ...state,
                movers: [],
                error: action.payload.error,
            };
        case POST_MOVERS:
            return {
                ...state,
                Postmovers: [],
                error: null,
            };
        case POST_MOVERS_SUCCESS:
            return {
                ...state,
                Postmovers: action.payload.Postmovers,
                postDataArrived: true,
                error: null,
            };
        case POST_MOVERS_ERROR:
            return {
                ...state,
                Postmovers: [],
                error: action.payload.error,
            };
        case GET_REVERSE_STORAGE:
            return {
                ...state,
                reverseStorage: [],
                error: null,
            };
        case GET_REVERSE_STORAGE_SUCCESS:
            return {
                ...state,
                reverseStorage: action.payload.reverseStorage,
                error: null,
            };
        case GET_REVERSE_STORAGE_ERROR:
            return {
                ...state,
                reverseStorage: [],
                error: action.payload.error,
            };
        case GET_RESERVE_STORAGE_BY_FACILITY_ID:
            return {
                ...state,
                reserveStorageByFacilityId: [],
                error: null,
            };
        case GET_RESERVE_STORAGE_BY_FACILITY_ID_SUCCESS:
            return {
                ...state,
                reserveStorageByFacilityId: action.payload.reserveStorageByFacilityId,
                error: null,
            };
        case GET_RESERVE_STORAGE_BY_FACILITY_ID_ERROR:
            return {
                ...state,
                reserveStorageByFacilityId: [],
                error: action.payload.error,
            };
        case POST_RESERVE_STORAGE:
            return {
                ...state,
                postReserveStorage: [],
                postReserveStorageArrived: false,
                error: null,
            };
        case POST_RESERVE_STORAGE_SUCCESS:
            return {
                ...state,
                postReserveStorage: action.payload.postReserveStorage,
                postReserveStorageArrived: true,
                error: null,
            };
        case POST_RESERVE_STORAGE_ERROR:
            return {
                ...state,
                postReserveStorage: [],
                postReserveStorageArrived: false,
                error: action.payload.error,
            };
        case UPDATE_MOVE_SOURCE_HOME_INFORMATION:
            return {
                ...state,
                updateMoveSourceHomeInfo: [],
                error: null,
            };
        case UPDATE_MOVE_SOURCE_HOME_INFORMATION_SUCCESS:
            return {
                ...state,
                updateMoveSourceHomeInfo: action.payload.updateMoveSourceHomeInfo,
                error: null,
            };
        case UPDATE_MOVE_SOURCE_HOME_INFORMATION_ERROR:
            return {
                ...state,
                updateMoveSourceHomeInfo: [],
                error: action.payload.error,
            };

            //reducers for disclaimer

            case UPDATE_DISCLAIMER:
                return {
                    ...state,
                    updateDisclaimer: [],
                    error: null,
                };
            case UPDATE_DISCLAIMER_SUCCESS:
                return {
                    ...state,
                    updateDisclaimer: action.payload.updateDisclaimer,
                    error: null,
                };
            case UPDATE_DISCLAIMER_ERROR:
                return {
                    ...state,
                    updateDisclaimer: [],
                    error: action.payload.error,
                };
         
            //reducers for move destination
        case UPDATE_MOVE_DESTINATION_HOME_INFORMATION:
            return {
                ...state,
                updateMoveDestinationHomeInfo: [],
                error: null,
            };
        case UPDATE_MOVE_DESTINATION_HOME_INFORMATION_SUCCESS:
            return {
                ...state,
                updateMoveDestinationHomeInfo: action.payload.updateMoveDestinationHomeInfo,
                error: null,
            };
        case UPDATE_MOVE_DESTINATION_HOME_INFORMATION_ERROR:
            return {
                ...state,
                updateMoveDestinationHomeInfo: [],
                error: action.payload.error,
            };

        case GET_DIY:
            return {
                ...state,
                diy: [],
                error: null,
                diyDataArrived: false,
            };
        case GET_DIY_SUCCESS:
            return {
                ...state,
                diy: action.payload.diy,
                error: null,
                diyDataArrived: true,
            };
        case GET_DIY_ERROR:
            return {
                ...state,
                diy: [],
                error: action.payload.error,
                diyDataArrived: false,
            };

        default:
            return {
                ...state,
            };
    }
};
