import React, { useState, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import './Main.scss';
// import materialInfo from '../../../Assets/images/HomeValuation/material-info.svg';
import Skeleton from 'react-loading-skeleton';
import Slider from './Slider';
import Button from '../../../components/atoms/Button';
import RadioButton from './RadioButton';
import { estimatedNetProceedsTooltipText } from '../../../utils/constants';
import ReactTooltip from 'react-tooltip';
import { CircularProgress } from '@material-ui/core';
import AnalyticsService from '../../analytics-service';
interface defaultProps {
    title1?: string;
    title2?: string;
    title3?: string;
    affordcalculator?: any;
    property_valuations: any[];
    isFetchingHomeValuation: boolean;
    homeValuationCardDetails: any;
    onPostAffordablityDataPost?: any;
    hash_code?: string;
    Rent: number;
    setRent: any;
    remainingMortgageBalance: any;
    estimatedHomeValue: any;
    isCalculatingAffordability: boolean;
    stopCalculating: () => void;
    analyticsData?: any;
}

function numberWithCommas(num: any) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const tempErrros: any = {
    purchasePrice: false,
    interestRate: false,
};

type Itypes = {
    [salePrice: string]: boolean;
    purchasePrice: boolean;
    downPayment: boolean;
    term: boolean;
    apr: boolean;
};

export default function TextInputs(props: defaultProps) {
    const {
        Rent,
        estimatedHomeValue,
        isFetchingHomeValuation,
        remainingMortgageBalance,
        setRent,
        affordcalculator,
        hash_code,
        onPostAffordablityDataPost,
        title2,
        title3,
        isCalculatingAffordability,
        stopCalculating,
        analyticsData
    } = props;

    
    const [sale_price, setSalePriceValue] = useState<number>(0);
    const [estimated_net_procced, setEstimatedNetProcced] = useState<number>(0);
    const [purchase_price, setPurchasePrice] = useState<number>(450000);
    const [downPayment, setDownPayment] = useState<number>(90000);
    const [downPaymentPer, setDownPaymentper] = useState<number>(20);
    const [sliderValue, setSlider] = useState<any>(100);
    const [equity, setEquity] = useState<number>(0);
    const [addtionalPayment, setAddtionalPayment] = useState<number>(0);
    const [year, setYear] = useState<number>(30);
    const [interest, setInterest] = useState<number>(5.6);

    const [errors, setErrors] = useState({
        purchasePrice: false,
        interestRate: false,
    });

    const [sliderError, setSliderError] = useState(false);
    const [sliderMaxValue, setSliderMaxValue] = useState(100);
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState<Itypes>({
        salePrice: false,
        purchasePrice: false,
        downPayment: false,
        term: false,
        apr: false

    });
    const [isFormStarted, setIsFormStarted] = useState(false);

    const handleAnalytics = (fieldLabel: string, fieldName: string) => {
        if (!isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
                ...analyticsData,
            category_name: 'Affordability Calculator',
            button_copy: 'Calculate',
            form_button:  true,
            form_pre_filled: true,
            form_field_name: fieldLabel
            };

            if (isFormStarted) {
                AnalyticsService.formFieldInput(trackProperties);
            } else {
                AnalyticsService.formFieldStarted(trackProperties);
            }
            setIsFormStarted(true);
        }

        setIsAnalitycsTriggerredOnce({
            ...isAnalitycsTriggerredOnce,
            [fieldName]: true,
        });
    };

    const onChangeCurrentValue = (event: any) => {
        const value = event.target.value.replace(/,/g, '');
        setSalePriceValue(parseFloat(value));
        handleAnalytics('Estimated Sale Price (Valuation)', 'salePrice');
    };

    const onChangePurchasePrice = (event: any) => {
        const value = parseFloat(event.target.value.replace(/,/g, ''));

        if (value < 50000) {
            setErrors({ ...errors, purchasePrice: true });
        } else {
            setErrors({ ...errors, purchasePrice: false });
        }

        setPurchasePrice(value);
        handleAnalytics('Purchase Price', 'purchasePrice');
    };
    
    useEffect(() => {
        const setMaxSlider = () => {
            const maxValuePercentage = Math.floor((purchase_price * 100) / estimated_net_procced);
            setSliderMaxValue(maxValuePercentage ? maxValuePercentage : 0);

            if (sliderValue > maxValuePercentage) {
                setSlider(maxValuePercentage);
            }
        }
        setMaxSlider();
      return () => {
        setSliderMaxValue(0);
      }
    }, [purchase_price, estimated_net_procced])
    
    const calculatorMode = () => {
        if (affordcalculator == 0){
            return 'Sell & Buy'
        } else if (affordcalculator == 1) {
            return 'Buy a Second Home'
        } else {
            return 'Invest & Rent'
        }
    }

    const onAffordablitySubmit = () => {
        const data = {
            no_of_years: year,
            rate_of_interest: interest,
            additional_amount: 0,
            hash_code: hash_code,
            mortgage: purchase_price - downPayment || 0,
            property_value: purchase_price,
        };

        handleSetErrors(purchase_price, 'purchasePrice');
        handleSetErrors(interest, 'interestRate');

        if (!tempErrros.interestRate && !tempErrros.purchasePrice && purchase_price > 0 && interest > 0 && interest <= 15) {
            onPostAffordablityDataPost(data);
        } else {
            stopCalculating();
        }

        AnalyticsService.formCompleted({ 
            ...analyticsData,
            category_name: `Affordability Calculator type: ${calculatorMode()}, Form Completed`,
            form_name: 'What Can I Afford if I Choose and explore your options',
            form_pre_filled: true,
            button_copy: 'Calculate'
        });
    };

    const onSetDownPayment = (event: any) => {
        const value = event.target.value;
        const finalValue = Number(value.replace(/,/g, ''));
        if (value === '') {
            setDownPayment(0);
            setDownPaymentper(0);
            return;
        }

        if (parseFloat(value.replace(/,/g, '')) < (purchase_price * 90) / 100) {
            setDownPayment(finalValue);
            setDownPaymentper((purchase_price && (finalValue / purchase_price) * 100) || 0);
        }

        handleAnalytics('with a total down payment of', 'downPayment');

    };
    const onChangeRent = (event: any) => {
        setRent(parseFloat(event.target.value.replace(/,/g, '')));
    };

    useEffect(() => {
            if (downPaymentPer == 0) {
                setDownPayment(Math.round(purchase_price && 0.2 * purchase_price) || 0);
                setDownPaymentper(Math.round(purchase_price && 0.2 * 100) || 0);
            } else {
                setDownPayment(Math.round(purchase_price && (downPaymentPer / 100) * purchase_price) || 0);
                setDownPaymentper(Math.round(purchase_price && (downPaymentPer / 100) * 100) || 0);
            }
    }, [purchase_price]);

    useEffect(() => {
          if (estimated_net_procced < downPayment && estimated_net_procced > 0)
          {
                  const value = (downPayment / estimated_net_procced) * 100;
                  setSlider(value > 100 ? 100 : value);
          }
    
            if (downPayment === 0) {
                setDownPaymentper(0);
            }
    }, [downPayment]);

    useEffect(() => {
        if (downPayment < estimated_net_procced && sliderValue <= sliderMaxValue) {
            const value = (estimated_net_procced * sliderValue) / 100;
            if (value <= purchase_price) {
                setSliderError(false);
                setDownPayment(Math.round(value));
                setDownPaymentper(Math.round(purchase_price && (downPayment * 100) / purchase_price) || 0);
            } else {
                setSliderError(true);
            }
        }
    }, [sliderValue]);

    useEffect(() => {
        const sliderPercetageValue = estimated_net_procced * (sliderValue / 100);
        if (sliderPercetageValue <= purchase_price && sliderValue <= sliderMaxValue) {
            if (estimated_net_procced <= downPayment) {
                setEquity(sliderPercetageValue);
            } else {
                setEquity(sliderPercetageValue);
            }
        }
    }, [sliderValue, addtionalPayment, estimated_net_procced]);

    useEffect(() => {
        const sliderPercetageValue = estimated_net_procced * (sliderValue / 100);

        if (sliderPercetageValue <= purchase_price && sliderValue <= sliderMaxValue) {
            const additionalPayment = downPayment - sliderPercetageValue;

            if (estimated_net_procced <= downPayment) {
                if (sliderValue < 100) {
                    setAddtionalPayment(additionalPayment >= 0 ? additionalPayment : 0);

                    if (sliderValue === 0) {
                        setAddtionalPayment(downPayment);
                    }
                } else {
                    if (downPayment >= sliderPercetageValue) {
                        setAddtionalPayment(additionalPayment >= 0 ? additionalPayment : 0);
                    } else {
                        setAddtionalPayment(0);
                    }
                }
            } else {
                if (sliderValue < 100) {
                    setAddtionalPayment(additionalPayment >= 0 ? additionalPayment : 0);
                    if (sliderValue === 0) {
                        setAddtionalPayment(downPayment);
                    }
                } else {
                    setAddtionalPayment(0);
                }
            }
        }
    }, [sliderValue, downPayment, estimated_net_procced]);

    useEffect(() => {
        if (sale_price && remainingMortgageBalance) {
            let value = 0;

            if (affordcalculator === 1 || affordcalculator === 2) {
                value = sale_price - remainingMortgageBalance;
            } else {
                value = sale_price - remainingMortgageBalance - ( 0.06 * sale_price );
            }

            setEstimatedNetProcced(value > 0 ? value : 0);
        } else {
            setEstimatedNetProcced(0);
        }
    }, [sale_price, affordcalculator, remainingMortgageBalance]);

    useEffect(() => {
        if (affordcalculator !== 2) {
            setRent(0);
        }

        return () => {
            setRent(0);
        };
    }, [affordcalculator]);

    useEffect(() => {
        const fetchSalePriceValue = () => {
            setSalePriceValue(estimatedHomeValue);
        };

        if (estimatedHomeValue && !sliderError) fetchSalePriceValue();
        return () => {
            setSalePriceValue(0);
        };
    }, [estimatedHomeValue]);

    const handleSetErrors = (value: any, type: string) => {
        if (type === 'purchasePrice') {
            if (value < 50000 || value === 0 || !value) {
                tempErrros.purchasePrice = true;
            } else {
                tempErrros.purchasePrice = false;
            }
        } else if (type === 'interestRate') {
            if (value < 1 || value > 15 || !value) {
                tempErrros.interestRate = true;
            } else {
                tempErrros.interestRate = false;
            }
        } else {
            if (value === 0 || !value) {
                tempErrros[type] = true;
            } else {
                tempErrros[type] = false;
            }
        }

        setErrors(tempErrros);
    };

    return (
        <>
            <div className="equity-grey-box">
                {affordcalculator === 0 && (
                    <>
                        <div className="equity-grey-boxTextHeading">If I sell my old home for the</div>
                        <div className="equity-grey-boxTextSubHeading">Estimated Sale Price (Valuation)</div>
                        <div className="equity-grey-boxText">
                            {!isFetchingHomeValuation ? (
                                <div className="dollarEstimated">
                                    <>
                                        <div
                                            style={{
                                                width: '45%',
                                                backgroundColor: '#fff',
                                            }}
                                            className="inputFeildMain1"
                                        >
                                            <span className="per">$</span>
                                            <input
                                                type="text"
                                                className="inputFeild1"
                                                onChange={event => onChangeCurrentValue(event)}
                                                value={numberWithCommas(sale_price || 0)}
                                            />
                                        </div>
                                    </>
                                </div>
                            ) : (
                                <Skeleton width={250} height={40} />
                            )}
                        </div>
                    </>
                )}

                <div
                    style={{
                        margin: affordcalculator === 0 ? '' : '40px 0px',
                    }}
                    className="estimationContainer"
                >
                    <div className="dark-14px-roboto estimationLabel">
                        {' '}
                        {affordcalculator === 0 ? 'I would earn' : 'You Have'}
                    </div>
                    <div className="estimationValue">
                        <span>
                            <div>
                                <div className="light-12px-roboto estimationLabelCover">
                                    <span
                                        style={{
                                            marginLeft: affordcalculator === 0 ? '0px' : '-6px',
                                        }}
                                    >
                                        {affordcalculator === 0 ? 'ESTIMATED NET PROCEEDS' : 'TOTAL EQUITY'}
                                    </span>

                                    <span>
                                        <ReactTooltip
                                            place="top"
                                            border={false}
                                            effect="solid"
                                            multiline={true}
                                            className="tooltipCover"
                                            id="estimated-proceeds-tip"
                                        >
                                            {estimatedNetProceedsTooltipText}
                                        </ReactTooltip>
                                        <InfoIcon
                                            data-tip
                                            data-for="estimated-proceeds-tip"
                                            style={{
                                                width: '14px',
                                                marginTop: '-15px',
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                            <span>
                                <div className="dollarArrangment">$</div>
                                <span className="boldest-24px-text">
                                    {estimated_net_procced ? numberWithCommas(Math.floor(estimated_net_procced)) : 0}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="WithoutGraySection">
                <div
                    className="headingText"
                    style={{
                        color: '#333333',
                        fontSize: '14px',
                        marginTop: '22x',
                    }}
                >
                    {affordcalculator === 0
                        ? 'I would like to buy a new home'
                        : affordcalculator === 1
                        ? 'I would like to buy a second home'
                        : 'I would like to invest & rent a new property'}
                </div>

                <div className={`specCalcFieldsCover ${affordcalculator === 2 && 'fieldsWithRent'}`}>
                <div className={affordcalculator === 2 ? 'purchaseAndRent affFieldsCover' : 'affFieldsCover'}>
                    <div className='purchasePriceCover'>
                        <div style={{ margin: '10px auto' }} className="headingText">
                            {title2 || 'Purchase Price'}
                        </div>

                        <div
                            style={{
                                width: affordcalculator === 2 ? '95%' : '45%',
                            }}
                            className="inputFeildMain1 "
                        >
                            <span className="per">$</span>
                            <input
                                type="text"
                                className="inputFeild1"
                                onChange={event => {
                                    onChangePurchasePrice(event);
                                }}
                                value={numberWithCommas(purchase_price || 0)}
                            />
                        </div>
                        {errors.purchasePrice && (
                            <span className="errMessage">Purchase price shouldn't be less than 50,000.</span>
                        )}
                    </div>
                    <div
                        style={{
                            display: affordcalculator === 2 ? 'block' : 'none',
                        }}
                        className='rentCover'
                    >
                        <div style={{ margin: '10px auto' }} className="headingText">
                            {title2 || 'Estimated Rent'}
                        </div>

                        <div style={{ width: '100%' }} className="inputFeildMain1 ">
                            <span className="per">$</span>
                            <input
                                type="text"
                                className="inputFeild1"
                                onChange={event => {
                                    onChangeRent(event);
                                }}
                                value={numberWithCommas(Rent || 0)}
                            />
                        </div>
                    </div>
                </div>

                <div className="downpaymentInputCover">
                    <div className='headTextCover'>
                    <div
                        className="headingText"
                        style={{
                            color: '#333333',
                            textTransform: 'lowercase',
                            fontSize: '14px',
                            marginTop: '22x',
                            marginBottom: '10px',
                        }}
                    >
                        {title3 || 'With a total down payment of'}
                    </div>
                    </div>

                    <div
                        style={{
                            width: '45%',
                            display: 'inline-block',
                        }}
                        className="inputFeildMain1 "
                    >
                        <span className="per">$</span>
                        <input
                            type="text"
                            className="inputFeild1"
                            onChange={event => {
                                onSetDownPayment(event);
                            }}
                            value={numberWithCommas(downPayment || 0)}
                        />
                    </div>
                    <div
                        style={{
                            width: '15%',
                            position: 'relative',
                            display: 'inline-block',
                        }}
                        className="inputFeildMain1"
                    >
                        <input
                            style={{ marginLeft: '0px' }}
                            step="0.01"
                            type="text"
                            className="inputFeild1"
                            value={Math.floor(downPaymentPer || 0)}
                            disabled
                        />
                        <span className="per2">%</span>
                    </div>
                </div>
                </div>

                <Slider
                    sliderValue={sliderValue ? Math.floor(sliderValue) : 0}
                    setSliderValue={setSlider}
                    style={{ marginBottom: 0 }}
                    sliderMaxValue={sliderMaxValue}
                    setSliderError={setSliderError}
                />
                <div
                    className="headingText"
                    style={{
                        marginTop: 0,
                        textTransform: 'lowercase',
                        color: '#333333',
                        fontSize: '14px',
                        marginBottom: '10px',
                    }}
                >
                    % net proceeds towards my down payment
                </div>
                {sliderError && <span className="errMessage">You can only use maximum {sliderMaxValue}% net proceeds, for given purchase price.</span>}
                <RadioButton
                    yearData={year}
                    setYearData={setYear}
                    interestData={interest}
                    setInterestData={setInterest}
                    equityData={equity > 0 ? numberWithCommas(Math.floor(equity)) : 0}
                    addtionalPaymentData={numberWithCommas(Math.floor(addtionalPayment || 0))}
                    handleSetErrors={handleSetErrors}
                    errors={errors}
                    handleAnalytics={handleAnalytics}
                />
                <div className="saveBtnCover">
                    <Button fontSize="14px" onClick={() => !isCalculatingAffordability && onAffordablitySubmit()}>
                        {isCalculatingAffordability ? <CircularProgress color="inherit" /> : 'Calculate'}
                    </Button>
                </div>
            </div>
        </>
    );
}
