import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../atoms/Button';
import './dashboardCss/getStartedCard.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import TimerIcon from '@material-ui/icons/Timer';
import Skeleton from 'react-loading-skeleton';

interface cardProps {
    onClick?: any;
    className?: string;
    cardTitle?: string;
    cardSubtitle?: string;
    cardImage?: any;
    imgClassName?: string;
    buttonTitle?: string;
    progress?: boolean;
    progressValue?: number;
    boxShadow?: any;
    width?: any;
    noBorder?: any;
}

const GetStartedCard = (props: cardProps) => {
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            border: isDouglasElliman ? '1px solid #DADCE0' : '1px solid #D92F25',
            padding: 'auto',
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
                '&:hover': {
                    // boxShadow: '0 0 10px #fbada9',
                    // border: "none !important"
                },
            },
        },
    }));

    const classes = useStyles();
    return (
        <div
            style={{
                opacity: '1',
                cursor: 'pointer',
                width: props.width ? props.width : '',
                borderRadius: isDouglasElliman ? '0px' : '4px'
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} getStartedCard`}
        >
            {props.cardImage ? (
                <div className={isDouglasElliman ? 'getStartedImageDivD' : 'getStartedImageDiv'}>
                    {props.cardImage && props.cardImage.includes('<svg') ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.cardImage,
                            }}
                            className="svg-image-mobile"
                        />
                    ) : (
                        <img
                            src={props.cardImage}
                            style={{
                                marginRight: 'auto',
                            }}
                            alt=""
                            className={
                                isDouglasElliman
                                    ? 'getStartedImageD'
                                    : props.imgClassName
                                    ? props.imgClassName
                                    : 'getStartedImage'
                            }
                        />
                    )}
                </div>
            ) : (
                <Skeleton height="100px" width="100px" />
            )}

            <div className="getStartedContentDiv">
                <div className="getStartedCardTitle">{props.cardTitle ? props.cardTitle : <Skeleton />}</div>
                <p className="getStartedCardPara">{props.cardSubtitle ? props.cardSubtitle : <Skeleton />}</p>
                {props.progress ? (
                    <div className="progress">
                        <div className="currentStatus">Current Status</div>
                        <div className="progressDiv">
                            <div className="progressBarDiv">
                                <LinearProgress
                                    className=""
                                    variant="determinate"
                                    value={props.progressValue ? props.progressValue : 2}
                                    style={{
                                        backgroundColor: '#E0E0E0',
                                        borderRadius: '15px',
                                        height: '7px',
                                    }}
                                />
                            </div>
                            <div className="completedPercent">{`${props.progressValue}% completed`}</div>
                        </div>
                        <div className="timer">
                            <TimerIcon className="timerIcon" />3 min task
                        </div>
                    </div>
                ) : null}
                <Button
                    className={
                        !props.buttonTitle
                            ? 'getStartedDashboardButtonDSke'
                            : isDouglasElliman
                            ? 'getStartedDashboardButton getStartedDashboardButtonD'
                            : 'getStartedDashboardButton'
                    }
                    onClick={props.onClick}
                >
                    {props.buttonTitle ? props.buttonTitle : <Skeleton />}
                </Button>
            </div>
        </div>
    );
};

export default GetStartedCard;
