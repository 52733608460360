
import React from 'react';

const domain = window.location.hostname;
let domianName = domain.split('.');

const uiConfig = {
  domain: domianName[0],
  dashboard: { home_services: true },
};

export const UIContext = React.createContext({
  ...uiConfig,
  // doSomething: () => {}
});

const UIControlProvider = (props: any) => {
  return (
    <UIContext.Provider value={uiConfig}>
      {props.children}
    </UIContext.Provider>
  );
};
export default UIControlProvider