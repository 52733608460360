import {
    GET_HS_SUMMARY,
    GET_HS_SUMMARY_SUCCESS,
    GET_HS_SUMMARY_ERROR
} from './actionTypes';
import * as types from './types';

//Summary List

export const getHSSummary = (payload: types.HSSummaryRequest): types.HSSummaryGet => ({
    type: GET_HS_SUMMARY,
    payload,
});

export const getHSSummarySuccess = (payload: types.HSSummarySuccessPayload): types.HSSummarySuccess => ({
    type: GET_HS_SUMMARY_SUCCESS,
    payload,
});

export const getHSSummaryFailure = (payload: types.HSSummaryFailurePayload): types.HSSummaryFailure => ({
    type: GET_HS_SUMMARY_ERROR,
    payload,
});
