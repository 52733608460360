import React, { Component } from 'react';
import './HomeWarantyMain.scss';
import Image from '../../Assets/images/WarantyHome/MainBannerImage.svg';
import Button from '../../components/atoms/Button';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';
import Footer from './Footer';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
// import history from '../../routes/History';

type initialProps = {
    history?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
};

type initialState = {
    homeWarrantyData: any;
};

export class HomeWarantyMain extends Component<initialProps, initialState> {
    state: initialState = {
        homeWarrantyData: null,
    };

    componentDidMount() {
        if (
            !(
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.from &&
                this.props.history.location.state.from == 'home'
            )
        ) {
            if (
                this.props &&
                this.props.homeWarrantyState &&
                this.props.homeWarrantyState.getHomeWarrantyDetails &&
                this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number != null
            ) {
                // this.props.homeWarrantyAction.getHomeWarrantyDetails();
                this.setState({
                    homeWarrantyData: this.props.homeWarrantyState.getHomeWarrantyDetails,
                });
                this.props.history.push({
                    pathname: '/dashboard/home-warranty/home',
                    state: this.props.homeWarrantyState.getHomeWarrantyDetails,
                });
            } else {
                this.props.homeWarrantyAction.getHomeWarrantyDetails();
            }
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Home Warranty',
                    category_name: 'Home Warranty',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        let prevW = prevProps.homeWarrantyState;
        let curW = this.props.homeWarrantyState;

        if (prevW.getHomeWarrantyDetails !== curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails) {
            this.setState({
                homeWarrantyData: curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails,
            });
            if (
                curW.getHomeWarrantyDetails &&
                curW.getHomeWarrantyDetails.policy_number != null
            ) {
                this.props.history.push({
                    pathname: '/dashboard/home-warranty/home',
                    state: curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails,
                });
            }
        }
    }
    handleAnalytics = () => {
        this.props.history.push({
            pathname: '/dashboard/home-warranty/steps',
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Home Warranty',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'GET STARTED',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        const breadList = [
            {
                name: 'Home Warranty',
                url: ''
            }
        ]

        return (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            pageImage="homeWarranty"
                            onClick={() =>
                                this.props.history.push({
                                    pathname: '/dashboard/home-warranty/steps',
                                })
                            }
                            isCoverImage={true}
                            headerText={`Protect Your Home`}
                            subheaderText={'Everything for your home warranty needs'}
                            highlightedText={'Today'}
                            buttonText={'Get Started'}
                        />
                    </div>
                ) : (
                    <div>
                        <div style={{ marginTop: '40px' }}></div>
                        <div className="main-head">
                            <h2 className="heading">Home Warranty</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className="WarantycoverDiv">
                            <div className="landingPage">
                                <img className="mainImg" src={Image} alt="" />
                                <h2 className="heading">Protect Your Home Today </h2>
                                <p className="subtext"> Everything for your home warranty needs</p>
                                <div className="line"></div>
                                <div className="btn-div">
                                    <div className="btn-div2">
                                        <Button
                                            className="btnICbtn"
                                            height="50px"
                                            // width="125px"
                                            width="100%"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            // onClick={() => this.tabChangeHandler('addressCard')}
                                            onClick={this.handleAnalytics}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarantyMain);
