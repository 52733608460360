import React from 'react';
import { makeStyles } from '@material-ui/core';
import './servicePreferenceCard.scss';
import Skeleton from 'react-loading-skeleton';
import CheckIcon from '@material-ui/icons/Check';
import sideTag from '../../../Assets/images/PreMove/Trending.svg';
import FavoriteIcon from '@material-ui/icons/Favorite';


type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    padding?: any;
    onClick?: any;
    points ? : any
    tag ? : boolean
    favoriteText ? : string
};

const ServicePreferenceCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            // '&:hover': {
            //        border:'2px solid #0066F5'        
            // },
            cursor: 'pointer',
        },
    }));
    const classes = useStyles();
    return (
        <div
            className={props.className ? `${props.className} ${classes.root} ` : `service-card-main-div ${classes.root}`}
            style={{
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : 'auto',
                padding: props.padding ? props.padding : '0px',
                borderRadius: '8px',
                border: '2px solid #DADCE0',
                boxShadow:"0 0 10px #e4e6e8"
            }}
            onClick={props.onClick}
        >
            <div className="service-card-Div">
                <div className="service-card-ImgDiv">
                  {props.icon ?  <div style={{backgroundImage:`url(${props.icon})`,backgroundRepeat:'no-repeat', backgroundPosition: 'center',
  backgroundSize: 'cover',
                  height:props.imageHeight ? props.imageHeight : '100%',width:props.imageWidth ? props.imageWidth : '100%'}}>
                       {/* <img
                        src={props.icon}
                        height={props.imageHeight ? props.imageHeight : '100%'}
                        width={props.imageWidth ? props.imageWidth : '100%'}
                    /> */}
                    <div className="tag-div"> 
                       {
                           props.tag ? 
                           <img src={sideTag} className="sideTag" alt="" />
                           : null
                       }
                    </div>
                    
                     </div> : <Skeleton height='200px' width='100%' />}
                </div>
                <div className="service-card-ContentDiv">
                        <div className="service-card-Title">{props.title ? props.title : <Skeleton />} </div>
                        <div className="service-card-points">
                            {
                              props.points && props.points.length ? props.points.map((item:any, i: number)=>{
                                  return <div key={i} className="check-point-div">
                                      <div className="check">
                                      <CheckIcon
                                style={{
                                    fontSize: '22px',
                                    color: '#00D56D',
                                    marginRight: '6px',
                                }}
                            /> </div>
                                      <div className="point"> {item} </div>
                                      
                                       </div>
                              }) : null
                            }

                        </div>

                        <div className="likes-div">

                          <div className="favoriteIcon" >
                          <FavoriteIcon style={{
                                color:"red"
                            }} />
                          </div>

                            <div className="favoriteText">{props.favoriteText}</div>


                        </div>
                   
                </div>
            </div>
        </div>
    );
};

export default ServicePreferenceCard;
