import React from 'react';
import './Signup.scss';
import SignupCarousel from './SignupCarousel';
import SignupForm from './SignupForm';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import * as CommonAction from '../../../store/common/actions';
import { bindActionCreators } from 'redux';
import * as Action from '../../../store/login/signup/actions';

type InitialProps = {
    commonAction: any;
    commonState: any;
    history?: any;
    signupAction: any;
    signupState: any;
};

type InitialState = {
    fullName: string;
    email: string;
    phone: string;
    street: string;
    street2: string;
    streetError: string;
    street2Error: string;
    date: any;
    dateError: string;
    isHashcode: boolean;
    anchorEl: any;
    isClosingDate: boolean;
    closingDate: any;
    hashCode: any;
    isDataArrived: boolean;
    isDataArrivedNew: boolean;
    moveId: any;
    userId: any;
    site_name: string;
    dateChanged: boolean;
    sourceStreetOnly: string;
    destinationStreetOnly: string;
};

class PreMoveSignup extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        street: '',
        street2: '',
        streetError: '',
        street2Error: '',
        date: new Date(),
        dateError: '',
        isHashcode: false,
        anchorEl: null,
        isClosingDate: false,
        closingDate: new Date(),
        hashCode: '',
        isDataArrived: true,
        fullName: '',
        email: '',
        phone: '',
        moveId: '',
        site_name: '',
        userId: '',
        dateChanged: false,
        sourceStreetOnly: '',
        destinationStreetOnly: '',
        isDataArrivedNew: true,
    };

    componentDidMount() {
        if (window.location.search && window.location.search.split('=')[0] === '?hash_code') {
            this.setState({ hashCode: window.location.search.split('=')[1] });
            const payload = {
                hashCode: window.location.search.split('=')[1],
            };
            this.props.commonAction.moveGetWithHashCode(payload);
        }
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            // console.log("test",    nextProps.commonState.moveWithHashCode[0])
            currentState.fullName = `${nextProps.commonState.moveWithHashCode[0].user_ref.first_name} ${nextProps.commonState.moveWithHashCode[0].user_ref.last_name}`;
            currentState.email = nextProps.commonState.moveWithHashCode[0].user_ref.email;
            currentState.userId = nextProps.commonState.moveWithHashCode[0].user_ref.id;
            currentState.phone = nextProps.commonState.moveWithHashCode[0].user_ref.phone;
            currentState.street = nextProps.commonState.moveWithHashCode[0].source_full_address;
            currentState.street2 = nextProps.commonState.moveWithHashCode[0].destination_full_address;
            currentState.date = nextProps.commonState.moveWithHashCode[0].moving_date;
            currentState.moveId = nextProps.commonState.moveWithHashCode[0].id;
            currentState.site_name = nextProps.commonState.moveWithHashCode[0].user_ref.site_name;
            currentState.sourceStreetOnly = nextProps.commonState.moveWithHashCode[0].source_street;
            currentState.destinationStreetOnly = nextProps.commonState.moveWithHashCode[0].destination_street;
            localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
        }
        if (nextProps && nextProps.signupState && nextProps.signupState.signUpUpdated) {
            nextProps.signupState.signUpUpdated = false;
            if (currentState.street && currentState.street2 && currentState.date) {
                nextProps.history.push({
                    pathname: '/servicerequirement',
                    state: {
                        fromSignUpPre: true,
                        hashcode: currentState.hashCode,
                        name: currentState.fullName,
                        email: currentState.email,
                        phone: currentState.phone,
                    },
                });
            }
        }

        // if (
        //     nextProps.history &&
        //     nextProps.history.location &&
        //     nextProps.history.location.state &&
        //     nextProps.history.location.state.hashcode &&
        //     nextProps.history.location.state.name &&
        //     nextProps.history.location.state.email &&
        //     nextProps.history.location.state.phone &&
        //       currentState.isDataArrivedNew
        //   ) {
        //       currentState.isDataArrivedNew = false;
        //       currentState.hashCode =  nextProps.history.location.state.hashcode
        //       currentState.fullName= nextProps.history.location.state.name
        //       currentState.phone= nextProps.history.location.state.phone
        //       currentState.email= nextProps.history.location.state.email

        //   }
        return currentState;
    }

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    // console.log("street",route,streetNumber)
                    this.setState({
                        street: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                        // source: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        sourceStreetOnly: `${streetNumber} ${route}`,

                        //  ${streetNumber} ${route}, ${city}, ${state} ${zip}
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    handleSelect2 = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });

                if (country === 'USA' || country === 'US') {
                    this.setState({
                        street2: `${streetNumber} ${route}, ${country}, ${city}, ${state} ${zip}`,
                        // destination: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        destinationStreetOnly: `${streetNumber} ${route}`,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    dateHandler = (e: any) => {
        if (this.state.isClosingDate) {
            this.setState({
                closingDate: e,
                anchorEl: null,
                dateChanged: true,
            });
        } else {
            this.setState({
                date: e,
                anchorEl: null,
                dateChanged: true,
            });
        }
    };
    handleClick = (event: any) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleChange = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    proceedButtonHandler = () => {
        let payload: any = [];
        payload = {
            moveId: this.state.moveId,
            data: {
                // ...this.state.data,
                destination_full_address: this.state.street2,
                source_full_addres: this.state.street,
                destination_detail: this.state.street2,
                source_street: this.state.sourceStreetOnly,
                destination_street: this.state.destinationStreetOnly,
                destination_home: '1',
                moving_date: this.state.isClosingDate
                    ? this.state.closingDate.toISOString().split('T')[0]
                    : this.state.dateChanged
                    ? this.state.date.toISOString().split('T')[0]
                    : this.state.date,
                moving_in_date: null,
                moving_items_list: ['2'],
                moving_option_value: ['2'],
                selected_service_list: ['11'],
                source_detail: this.state.street,
                source_home: '1',
                user_ref: {
                    id: this.state.userId,
                    email: this.state.email,
                    first_name:
                        this.state.fullName && this.state.fullName.split(' ')[0]
                            ? this.state.fullName.split(' ')[0]
                            : '',
                    last_name:
                        this.state.fullName && this.state.fullName.split(' ')[1]
                            ? this.state.fullName.split(' ')[1]
                            : '',
                    // password: password,
                    phone: this.state.phone,
                },
                site_name: this.state.site_name,
                secondary_email: '',
                secondary_phone: '',
            },
        };
        this.props.signupAction.signupUpdate(payload);

        // if(this.state.street && this.state.street2 && this.state.date){
        //     this.props.history.push({
        //         pathname:'/servicerequirement',
        //         state:{
        //             fromSignUpPre:true,
        //             hashcode:this.state.hashCode
        //         }
        //     })
        // }
    };

    handleSwitch = (event: any) => {
        this.setState({ isClosingDate: event.target.checked });
    };

    getAge = (a: any, b: any) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        return (
            <div className="preMoveSignupMain">
                <div className="carouselDiv">
                    <SignupCarousel />
                </div>
                <div className="formDiv">
                    <div className="signupHeadingDiv">{`Hi, Welcome to Your ${
                        slug && slug.display_name ? slug.display_name : ''
                    } Brokerage`}</div>
                    <div className="signupSubHeadingDiv">We need few moving details to set you up</div>
                    <SignupForm
                        street={this.state.street}
                        street2={this.state.street2}
                        streetError={this.state.streetError}
                        street2Error={this.state.street2Error}
                        date={this.state.date}
                        dateError={this.state.dateError}
                        isHashCode={this.state.isHashcode}
                        anchorEl={this.state.anchorEl}
                        handleSelect={this.handleSelect}
                        handleSelect2={this.handleSelect2}
                        handleChange={this.handleChange}
                        dateHandler={this.dateHandler}
                        handleClick={this.handleClick}
                        handleClose={this.handleClose}
                        proceedButtonHandler={this.proceedButtonHandler}
                        isClosingDate={this.state.isClosingDate}
                        handleSwitch={this.handleSwitch}
                        closingDate={this.state.closingDate}
                        getAge={this.getAge}
                    />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        signupAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreMoveSignup);
