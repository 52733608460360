import React, { useEffect } from 'react';
import moment, { isMoment } from 'moment';
import Skeleton from 'react-loading-skeleton';
import materialInfo from '../../../Assets/images/HomeValuation/material-info.svg';
import CommonService from '../../../utils/common-functions';

import Places from '../../../Assets/images/HomeValuation/Places.svg';
import trending_up from '../../../Assets/images/HomeValuation/trending_up_black.svg';
import trending_down from '../../../Assets/images/HomeValuation/trending_down_black_24dp.svg';
import DoubleBed from '../../../Assets/images/HomeValuation/double-bed.svg';
import Stairs from '../../../Assets/images/HomeValuation/stairs.svg';
import Square from '../../../Assets/images/HomeValuation/square.svg';
import Bathtub from '../../../Assets/images/HomeValuation/bathtub.svg';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './EstimatedHomeValue.scss';
import ReactTooltip from 'react-tooltip';
import { estimatedValueTooltipText } from '../../../utils/constants';
import AddLoanDetails from '../../../components/homeOwnerDashboardComponents/AddLoanDetails';
import MaterialEditIcon from '../../../Assets/images/Icon material-edit.svg';
import AnalyticsService from '../../../pages/analytics-service';

interface EstHomeValCardProps {
    property_valuations: any[];
    isFetchingHomeValuation: boolean;
    homeValuationCardDetails: any;
    isFetchingAverage?: boolean;
    averageCalulations: any;
    street: string;
    loanDetails: any;
    handleLoanDetailsClick?: any;
    analyticsData: any;
}

const { formatCurrency } = CommonService;

const EstimatedHomeValue = (props: EstHomeValCardProps) => {
    const { address, total_area_sq_ft, baths, beds_count, stories } = props?.homeValuationCardDetails;
    const average_value = props?.averageCalulations?.average_value;
    const last_record_date = props?.averageCalulations?.last_record_date;
    const percentage_difference = props?.averageCalulations?.percentage_difference;
    const created_date = last_record_date ? moment(last_record_date, 'YYYY-MM-DD') : '';
    const showTrend = isMoment(created_date) && percentage_difference != 0;


    const showPerchasePriceView = (Object.keys(props.homeValuationCardDetails).length === 0 || !props.homeValuationCardDetails?.address || !props.averageCalulations) && props.loanDetails?.purchase_price > 0;

    useEffect(() => {
        AnalyticsService.cardViewed({
           ...props.analyticsData,
            cardTitle: !props.isFetchingHomeValuation && !props.averageCalulations ? 'Your Home Address' : 'Your Estimated Home Value',
            category_name: "Home Valuation Page Top Card Viewed"
        });
    }, [])
    

    return (
        <div className="mainEstimated">

            {showPerchasePriceView ? 
                <div className="remaining-balance-edit" style={{ float: 'right', marginTop: '16px', marginRight: '16px' }} onClick={() => props.handleLoanDetailsClick('Edit')}>
                        <span className="edit-icon">
                            <img src={MaterialEditIcon} width={'10px'} height={'10px'} alt="" />
                        </span>
                        <span>Edit</span>
                    </div>
             :
            <div className="estimatedText">
                {!props.isFetchingHomeValuation && !props.averageCalulations ? (
                    <>Your Home Address</>
                ) : (
                    <>
                        Your Estimated Home Value{' '}
                        <div style={{ position: 'absolute', display: 'inline-block', margin: '-10px 3px' }}>
                            <ReactTooltip
                                effect="solid"
                                border={false}
                                multiline={true}
                                className="tooltipCover"
                                id="estimated-tooltip"
                            >
                                {estimatedValueTooltipText}
                            </ReactTooltip>
                            <img
                                data-tip
                                data-for="estimated-tooltip"
                                src={materialInfo}
                                style={{ paddingRight: '20px' }}
                            />
                        </div>
                    </>
                )}
                {!props.isFetchingHomeValuation && !props.averageCalulations && (
                    <div>
                        <div
                            className="section-1-content address-small-title"
                            style={{
                                fontSize: '16px',
                                color: 'rgba(51, 51, 51, 1)',
                                fontFamily: "'Roboto', sans-serif ",
                            }}
                        >
                            {props.street}
                        </div>
                    </div>
                )}
            </div>}

            {!showPerchasePriceView && <div
                className={`estimatedDollarDateMain ${!props.isFetchingHomeValuation &&
                    !props.averageCalulations &&
                    'd-block'}`}
            >
                {!props.isFetchingHomeValuation && !props?.isFetchingAverage ? (
                    <div className="dollarEstimated">
                        {average_value ? (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        fontWeight: 900,
                                        color: '#000',
                                        display: 'inline-block',
                                    }}
                                >
                                    $
                                </div>
                                <span className="dollarEstimatedText">{formatCurrency({ value: average_value })}</span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <>{props.isFetchingHomeValuation ? <Skeleton width={250} height={40} /> : ''}</>
                )}

                {!props.isFetchingHomeValuation ? (
                    <div className="dateEstimated">
                        {showTrend ? (
                            <>
                                {percentage_difference > 0 ? (
                                    <>
                                        <span style={{ position: 'absolute', marginTop: '-2px', marginLeft: '1px' }}>
                                            <img src={trending_up} style={{ paddingRight: '20px' }} alt="" />
                                        </span>
                                        <span style={{ color: '#00d56d', paddingLeft: '30px', fontWeight: 'bold' }}>
                                            {percentage_difference}%{' '}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span style={{ position: 'absolute', marginTop: '-2px', marginLeft: '1px' }}>
                                            <img src={trending_down} style={{ paddingRight: '20px' }} alt="" />
                                        </span>
                                        <span style={{ color: '#D92F25', paddingLeft: '30px', fontWeight: 'bold' }}>
                                            {Math.abs(percentage_difference)}%{' '}
                                        </span>
                                    </>
                                )}

                                <span
                                    style={{
                                        textAlign: 'left',
                                        letterSpacing: '0px',
                                        opacity: 1,
                                        padding: '0px 12px',
                                    }}
                                >
                                    {' '}
                                    since {(isMoment(created_date) && created_date.format('MMM DD YYYY')) || ''}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <>{props.isFetchingHomeValuation ? <Skeleton width={250} height={40} /> : ''}</>
                )}
            </div>}

            {!props.isFetchingHomeValuation ? (
                <div style={{ width: '100%', borderTop: !showPerchasePriceView ? '0.5px solid rgba(0,0,0,0.3)' : 'none' }}>
                    {address ? (
                        <div className="addressEstimated">
                            <span className="imageAddressEstimated1">
                                <img src={Places} alt="" />
                            </span>

                            <span style={{ color: '#0066F5', paddingLeft: '20px' }}>{address}</span>
                            {/* <KeyboardArrowDownIcon style={{ color: '#0066F5' }} /> */}
                        </div>
                    ) : (
                        ''
                    )}
                    {!props.isFetchingHomeValuation && (Object.keys(props.homeValuationCardDetails).length === 0 || !props.homeValuationCardDetails?.address) && (props.loanDetails?.message || props.loanDetails?.purchase_price == 0) && (
                            <AddLoanDetails
                                handleClickAdd={() => props.handleLoanDetailsClick('Update Home details')}
                                title="Tell us a little more about your home."
                                description="We'll help you find opportunities to save money and increase the value of your home."
                                btnTxt="Update Home details"
                                fromTopCard
                            />
                        )}
                   {
                    showPerchasePriceView && (
                        <div className='purchaseInfoCover'>
                            <h4 className='purchaseTitle'>Purchase Details</h4>
                            <h1 className='price'>{formatCurrency({ value: props.loanDetails?.purchase_price })}</h1>
                            <span className='purchaseDate'>Purchased on {props.loanDetails?.start_date}</span>
                            {props.street ? (
                              <div className="addressEstimated">
                                 <span className="locationIconCover">
                                     <img src={Places} alt="" />
                                 </span>

                                <span className='addressText'>{props.street}</span>
                               </div>
                            ) : (
                             ''
                                )}
                        </div>)
                    
                   }

                    <div
                        className={`lastSectionEstimated ${!props.isFetchingHomeValuation &&
                            !props.averageCalulations &&
                            'm-0'}`}
                    >
                        {beds_count || total_area_sq_ft || baths || stories ? (
                            <div className="row">
                                {beds_count ? (
                                    <div className="col-3 lastSectionEstimatedFlex">
                                        <div style={{ position: 'absolute', marginTop: '1px' }}>
                                            <img src={DoubleBed} alt="" />
                                        </div>

                                        <div
                                            style={{
                                                textAlign: 'left',
                                                paddingLeft: '30px',
                                                letterSpacing: '0px',
                                                fontSize: '14px',
                                                fontWeight: 'normal',
                                                color: '#6B6C6F',
                                                textTransform: 'capitalize',
                                                opacity: 1,
                                                marginTop: '4px',
                                            }}
                                        >
                                            {' '}
                                            <span style={{ fontWeight: 'bold', color: '#000', paddingRight: '5px' }}>
                                                {beds_count || 0}
                                            </span>
                                            bed
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {baths ? (
                                    <div className="col-3 lastSectionEstimatedFlex">
                                        <div style={{ position: 'absolute', marginTop: '1px' }}>
                                            <img src={Bathtub} alt="" />
                                        </div>

                                        <div
                                            style={{
                                                textAlign: 'left',
                                                paddingLeft: '30px',
                                                letterSpacing: '0px',
                                                fontSize: '14px',
                                                fontWeight: 'normal',
                                                color: '#6B6C6F',
                                                textTransform: 'capitalize',
                                                opacity: 1,
                                                marginTop: '4px',
                                            }}
                                        >
                                            {' '}
                                            <span style={{ fontWeight: 'bold', color: '#000', paddingRight: '5px' }}>
                                                {baths || 0}
                                            </span>
                                            bath
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {stories && stories !== '0' ? (
                                    <div className="col-3 lastSectionEstimatedFlex">
                                        <div style={{ position: 'absolute', marginTop: '1px' }}>
                                            <img src={Stairs} alt="" />
                                        </div>

                                        <div
                                            style={{
                                                textAlign: 'left',
                                                paddingLeft: '30px',
                                                letterSpacing: '0px',
                                                fontWeight: 'normal',
                                                fontSize: '14px',
                                                color: '#6B6C6F',
                                                textTransform: 'capitalize',
                                                opacity: 1,
                                                marginTop: '4px',
                                            }}
                                        >
                                            {' '}
                                            <span style={{ fontWeight: 'bold', color: '#000', paddingRight: '5px' }}>
                                                {stories || 0}
                                            </span>
                                            {Number(stories) > 1 ? 'floors' : 'floor'}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {total_area_sq_ft ? (
                                    <div className="col-md-3 col-sm-12 lastSectionEstimatedFlex estimatedAlignement">
                                        <span style={{ position: 'absolute', marginTop: '1px' }}>
                                            <img src={Square} alt="" />
                                        </span>

                                        <span
                                            style={{
                                                textAlign: 'left',
                                                paddingLeft: '30px',
                                                letterSpacing: '0px',
                                                fontWeight: 'normal',
                                                fontSize: '14px',
                                                color: '#6B6C6F',
                                                textTransform: 'capitalize',
                                                opacity: 1,
                                                marginTop: '4px',
                                            }}
                                        >
                                            {' '}
                                            <span style={{ fontWeight: 'bold', color: '#000', paddingRight: '5px' }}>
                                                {formatCurrency({ value: total_area_sq_ft || 0 })}
                                            </span>
                                            sqft
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                <Skeleton width={250} height={40} />
            )}
        </div>
    );
};

export default EstimatedHomeValue;
