import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={51.075} height={54} {...props}>
            <defs>
                <style>{`.InsuraceMainFoundationprefix__a{fill:${
                    slug && slug.color_shade4
                        ? props.isdouglaselliman
                            ? '#ffffff'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }}.InsuraceMainFoundationprefix__b{fill:${
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }}.prefix__c{fill:#fff}`}</style>
            </defs>
            <g data-name="Group 39946" fill="#5cbd9d">
                <g data-name="Path 50791">
                    <path
                        d="M42.393 53a7.672 7.672 0 01-4.963-1.825 7.7 7.7 0 01-2.6-4.531l-.144-.828h-5.061l-.297.445-2.917 4.376c-.443.666-1.066 1.055-1.71 1.067h-.036c-.638 0-1.265-.368-1.721-1.01l-3.25-4.55-.182-.255-.294-.106a.493.493 0 01-.27-.232L9.65 27.919l-.282-.534H1.496a.495.495 0 110-.99l8.173.001c.184 0 .351.1.437.263l3.334 6.323.281.533H35.67l-5.162 9.847-.768 1.465h4.945l.145-.828a7.708 7.708 0 012.6-4.533 7.668 7.668 0 014.963-1.827c4.236 0 7.682 3.446 7.682 7.68 0 4.235-3.446 7.68-7.682 7.68zm-6.507-7.184l.37 1.278c.788 2.72 3.312 4.619 6.137 4.619a6.4 6.4 0 006.394-6.393 6.4 6.4 0 00-6.394-6.392c-2.825 0-5.349 1.9-6.136 4.62l-.37 1.279h6.507a.495.495 0 110 .989h-6.507zm-16.313 0l1.129 1.58 2.314 3.243c.449.626 1.035.97 1.653.97h.018c.626-.007 1.21-.37 1.647-1.021l2.143-3.218 1.035-1.554h-9.939z"
                        className="InsuraceMainFoundationprefix__a"
                    />
                    <path
                        d="M14.26 34.515l5.43 10.3h8.927l5.4-10.3H14.258M1.495 25.396H9.67c.554 0 1.063.308 1.321.797l3.334 6.322h22.999l-5.93 11.312h2.452c.712-4.077 4.273-7.188 8.548-7.188 4.789 0 8.682 3.895 8.682 8.68 0 4.788-3.893 8.68-8.682 8.68-4.275 0-7.836-3.106-8.548-7.183H30.16l-2.917 4.375c-.628.946-1.552 1.498-2.534 1.513h-.044c-.966 0-1.888-.518-2.536-1.43l-3.25-4.55a1.492 1.492 0 01-.816-.706L8.766 28.385h-7.27a1.495 1.495 0 110-2.989zm40.898 25.317a5.4 5.4 0 005.394-5.393 5.4 5.4 0 00-5.394-5.392c-2.452 0-4.525 1.651-5.176 3.899h5.177a1.494 1.494 0 110 2.989h-5.177c.651 2.247 2.724 3.897 5.176 3.897zm-20.878-3.897l2.315 3.242c.257.359.552.551.839.551h.007c.288-.003.58-.21.826-.577l2.142-3.216h-6.129z"
                        // fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50792">
                    <path d="M37.404 29.352h-.193a4.296 4.296 0 00-2.845-3.614l-.054-.019-.01-.058a8.947 8.947 0 00-8.85-7.467 8.975 8.975 0 00-8.707 6.76l-.012.048-.045.016a5.193 5.193 0 00-1.602.955c-.035-.055-.07-.11-.107-.165a5.28 5.28 0 011.192-.78l.394-.187.13-.416a9.099 9.099 0 013.312-4.64A9.122 9.122 0 0125.452 18c2.078 0 4.11.708 5.723 1.992a9.178 9.178 0 013.21 5.098l.106.464.428.21a4.509 4.509 0 012.485 3.588z" />
                    <path
                        d="M38.396 30.352H36.18c.032-.187.053-.375.053-.57a3.294 3.294 0 00-2.2-3.1l-.607-.216-.11-.635a7.949 7.949 0 00-7.864-6.637 7.975 7.975 0 00-7.738 6.006l-.14.558-.541.199a4.212 4.212 0 00-2.09 1.698c-.388-.673-.81-1.328-1.252-1.972a6.362 6.362 0 012.05-1.557A10.166 10.166 0 0125.451 17c4.775 0 8.848 3.272 9.908 7.866a5.485 5.485 0 013.037 5.486z"
                        // fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50793">
                    <path d="M26.973 21.631a1.1 1.1 0 000 2.193 1.1 1.1 0 000-2.193z" />
                    <path
                        d="M26.973 21.631c1.415 0 1.415 2.193 0 2.193-1.414 0-1.414-2.193 0-2.193z"
                        //     fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50794">
                    <path d="M18.322 30.136c-.19 0-.415-.08-.415-.461 0-.211.072-.462.415-.462.189 0 .414.08.414.462 0 .21-.072.461-.414.461z" />
                    <path
                        d="M18.322 28.213c1.885 0 1.885 2.923 0 2.923-1.887 0-1.887-2.923 0-2.923z"
                        //     fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50795">
                    <path d="M32.13 29.405c-.26 0-.39-.147-.39-.436 0-.29.13-.436.39-.436.258 0 .388.147.388.436 0 .29-.13.436-.389.436z" />
                    <path
                        d="M32.13 27.533c1.851 0 1.851 2.872 0 2.872-1.853 0-1.853-2.872 0-2.872z"
                        //     fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50796">
                    <path d="M24.537 24.339c-.123 0-.293-.136-.293-.269s.17-.269.293-.269c.122 0 .292.136.292.27 0 .132-.17.268-.292.268z" />
                    <path
                        d="M24.537 24.801c-.943 0-.943-1.462 0-1.462s.943 1.462 0 1.462z"
                        //     fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50797">
                    <path d="M27.461 27.017c-.122 0-.293-.135-.293-.269 0-.133.17-.269.293-.269.122 0 .293.136.293.27 0 .133-.17.268-.293.268z" />
                    <path
                        d="M27.461 27.48c-.943 0-.943-1.463 0-1.463s.943 1.462 0 1.462z"
                        //     fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50798">
                    <path d="M41.286 17.956l-4.023-3.706 7.694-8.351a3.455 3.455 0 004.164-.7c.016-.017.03-.035.045-.054l.434-.493L44.552 0l-.455.475a.6.6 0 00-.05.048 3.453 3.453 0 00-.368 4.19l-7.7 8.355-4.023-3.706-1.291 1.4 9.326 8.593zm3.406-15.974l2.923 2.694a2.087 2.087 0 01-2.923-2.694z" />
                    <path
                        d="M39.994 19.358l-9.326-8.593 1.291-1.403 4.023 3.706 7.698-8.355a3.453 3.453 0 01.368-4.19c.016-.017.032-.033.05-.048L44.553 0l5.048 4.652-.434.493-.045.054a3.455 3.455 0 01-4.165.7l-7.694 8.35 4.023 3.707-1.292 1.402zm4.698-17.376a2.091 2.091 0 00.48 2.414 2.09 2.09 0 002.444.28l-2.924-2.694z"
                        // fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
                <g data-name="Path 50799">
                    <path d="M35.96 20.175a12.115 12.115 0 00-3.957-4.547l-.03-.021-.033-.019-1.291-.75-.028-.017-.028-.014a7.142 7.142 0 00-.053-.026c.13-.176.264-.346.4-.51l1.405 1.294 4.313 3.975c-.22.22-.453.431-.697.635z" />
                    <path
                        d="M30.882 12.857l.433.4 6.756 6.225a12 12 0 01-2.525 2.25 11.03 11.03 0 00-4.108-5.28l-1.292-.75a11.3 11.3 0 00-1.101-.477 11.997 11.997 0 011.837-2.368z"
                        // fill="#1d7e64"
                        className="InsuraceMainFoundationprefix__b"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
