import FetchService from './fetch-service';
import locaStorageService from '../utils/localStorage';

const token: string = locaStorageService.getItem('token') || '';
let moveId: number = parseInt(locaStorageService.getItem('moveId'));
moveId = moveId && !isNaN(moveId) && moveId || 0;

export default {
    // Get
    getLoanDetails: async (moveData: any) => {
        const url = `api/move/move-savings-data/get-loan-details/?hash_code=${moveData?.hash_code}`;
        if (moveData?.hash_code) {
            return FetchService.fetchAPI({ token, url })
                .then(data => data)
                .catch(err => console.debug(err));
        }
    },

    getHomeValuation: (moveId: number) => {
        if (moveId) {
            const url = `api/move/move-api/${moveId}/get-move-home-valuation/`;
            return FetchService.fetchAPI({ token, url })
                .then(data => data)
                .catch(err => console.debug(err));
        }
    },

    getMoveData: (moveId?: number) => {
        const url = `api/move/move-api/${moveId ? `${moveId}/` : ''}`;
        return FetchService.fetchAPI({ token, url })
            .then(data => data)
            .catch(err => console.debug(err));
    },

    getBlogs: async (SERVICE_PAGE_NAME = 'Home-Valuation') => {
        return FetchService.fetchAPI({ token, url: `api/concierge/moveeasy-blogs/?service_page_name=${SERVICE_PAGE_NAME}` })
            .then((blogs) => blogs)
            .catch(err => console.warn("Something went wrong while fetching blogs! Error: ", err?.message));
    },

    getDeals: async (SERVICE_PAGE_NAME = 'Home-Valuation') => {
        return FetchService.fetchAPI({ token, url: `api/realtors/deals/?service_name=${SERVICE_PAGE_NAME}` })
            .then(deals => deals)
            .catch(err => console.warn("Something went wrong while fetching deals! Error: ", err?.message));
    },

    getServiceAgents: async () => {
        return FetchService.fetchAPI({ token, url: 'api/services/service-agents/' })
            .then((serviceAgents) => serviceAgents)
            .catch(err => console.warn("Something went wrong while fetching Agent details! Error: ", err));
    },

    getServiceProviders: async (providerType?: any, moveHash?: any) => {
        if(providerType && moveHash) {
            return FetchService.fetchAPI({ token, url: `api/services/service-agents/get-service-providers/?service_provider_type=${providerType}&hash_code=${moveHash}` })
            .then((serviceProviders) => serviceProviders)
            .catch(err => console.warn("Something went wrong while fetching Service Provider details! Error: ", err));
        }else{
            return FetchService.fetchAPI({ token, url: 'api/services/service-agents/get-service-providers/' })
            .then((serviceProviders) => serviceProviders)
            .catch(err => console.warn("Something went wrong while fetching Service Provider details! Error: ", err));
        }
    },

    getMortageData: async () => {
        return FetchService.fetchAPI({ token, url: `api/move/move-savings-data/?move_id=${moveId}` })
            .then((mortageData) => mortageData)
            .catch(err => console.warn("Something went wrong while fetching Mortage details! Error: ", err));
    },

    getPropertyValuationFromTP: async (propertyId: number) => {
        return FetchService.fetchAPI({ token, url: `api/move/move-api/${moveId}/get-other-source-move-home-valuation/?property_id=${propertyId}` })
            .then((propertyValuation) => propertyValuation)
            .catch(err => console.warn("Something went wrong while fetching Mortage details! Error: ", err));
    },

    getPropertyAverageValuation: async (propertyId: number) => {
        return FetchService.fetchAPI({ token, url: `api/move/move-api/${moveId}/compute-property-home-valuation/?property_id=${propertyId}` })
            .then((propertyAverageValuation) => propertyAverageValuation)
            .catch(err => console.warn("Something went wrong while fetching Mortage details! Error: ", err));
    },

    // Post

    postSavingsCalcData: async (data: any) => {
        const url = `api/move/move-savings-data/get-savings-calculator-details/`;
        if (data) {
            return FetchService.postData({ token, url, data })
                .then(data => data)
                .catch(() => { });
        }
    },

    postRealtorData: async (data: any) => {
        const url = `api/move/move-service-realtor-contact/`;
        if (data && data.hash_code) {
            return FetchService.postData({ token, url, data })
                .then(data => data)
                .catch(() => { });
        }
    },

    postCreateContactRequest: async (data: any, agentID: number, isServiceAgent = false) => {
        const url = isServiceAgent ? `api/services/service-agents/${agentID}/create-contact-service-agents/` :  `api/services/service-agents/create-contact-service-providers/`;
        if (data && data.contact_from) {
            return FetchService.postData({ token, url, data })
                .then(data => data)
                .catch((error) => error)
        }
    },
    postAffordabilityData: (data: any) => {
        const url = `api/move/move-savings-data/get-affordability-calculator-details/`;
        if (data && data.hash_code && data.mortgage && data.rate_of_interest && data.no_of_years) {
            return FetchService.postData({ token, url, data })
                .then(data => data)
                .catch(() => { });
        }
    },
    postLoanOrMortageDetails: (data: any) => {
        const url = `api/move/move-savings-data/?moveId=${moveId}`;
        if (data) {
            return FetchService.postData({ token, url, data })
                .then(data => data)
                .catch(() => { });
        }
    },

    // PUT
    putLoanOrMortageDetails: (data: any, id: number | any) => {
        const url = `api/move/move-savings-data/${id}/`;
        if (data) {
            return FetchService.putData({ token, url, data })
                .then(data => data)
                .catch(() => { });
        }
    },
}