import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={33.249} height={56} {...props}>
            <defs>
                <style>{`.InsuraceMainConstructionprefix__a{fill:${
                    slug && slug.color_shade4
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }}.InsuraceMainConstructionprefix__b{fill:${
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#ffffff'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }}.prefix__c{fill:#fff}`}</style>
            </defs>
            ;
            <g
                data-name="Group 39945"
                // fill="#5cbd9d"
                fill={
                    slug && slug.color_shade4
                        ? props.isdouglaselliman
                            ? '#ffffff'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }
                stroke={
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }
                strokeWidth={2}
            >
                <path
                    data-name="Path 50790"
                    d="M28.49 35.008L17.906 29.57v-1.836l-.429.342a2.7 2.7 0 01-1.765.628h-.018a2.9 2.9 0 110-5.8 2.854 2.854 0 011.614.506l.414.282v-7.084h6.943v-4.721h4.556V8.863h1.849V1.001h-7.808v2.4H9.417v-2.4H1.609v7.86h1.91v3.024h4.556v4.716h7.185v3.922a5.314 5.314 0 00-2.625 9.769L4.32 35.004H1v19.997h31.248V35.008zM15.873 31.42l6.981 3.588H9.548zm12.3 19.5h-23.1V39.08h23.1z"
                />
                <path data-name="Rectangle 3881" d="M18.369 42.818h5.985v4.375h-5.985z" />
                <path data-name="Rectangle 3882" d="M8.187 42.818h6.296v4.375H8.187z" />
            </g>
        </svg>
    );
}

export default SvgComponent;
