import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    internetPlanFailure,
    internetPlanSuccess,
    selectDigitalPlanFailure,
    selectDigitalPlanSuccess,
    selectDigitalPlanGetFailure,
    selectDigitalPlanGetSuccess,
    getStepsSuccess,
    getStepsFailure,
    changeStepsSuccess,
    changeStepsFailure,
    landingInternetPlanFailure,
    landingInternetPlanSuccess,
    getICSummarySuccess,
    getICSummaryFailure
} from './actions';
import {
    EXTERNAL_DIGITAL_PLANS,
    SELECT_DIGITAL_PLANS,
    SELECT_DIGITAL_PLANS_GET,
    GET_STEPS,
    CHANGE_STEPS,
    LANDING_DIGITAL_PLANS,
    GET_IC_SUMMARY
} from './actionTypes';
import apiJunction from '../../utils/api';
import { getErrorMessage } from '../errorHandler';
// import history from '../../routes/History';
export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* internetPlan(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/external-digital-plans/?city=${action.payload.city}&state=${action.payload.state}&zip_code=${action.payload.zip_code}`,
        });
        if (response.data && response.status === 200) {
            yield put(internetPlanSuccess({ internet: { ...response.data, status: response.status } }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(internetPlanFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* selectDigitalPlan(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/move-selected-digital-plans/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(selectDigitalPlanSuccess({ selectDigitalPlan: { ...response.data, status: response.status } }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(selectDigitalPlanFailure({ errorDigitalPlan: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* selectDigitalPlanGet() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/move-selected-digital-plans/`,
        });
        if (response.data) {
            yield put(selectDigitalPlanGetSuccess({ selectDigitalPlan: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(selectDigitalPlanGetFailure({ errorDigitalPlan: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* getSteps() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/storing-filters/`,
        });
        if (response.data) {
            yield put(getStepsSuccess(response.data));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getStepsFailure({ errorSteps: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* changeSteps(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/services/storing-filters/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(changeStepsSuccess(response.data));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(changeStepsFailure({ errorSteps: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}


function* internetLandingPlan() {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `api/services/external-landing-digital-plans/?city=Columbus&zip_code=43221&state=oh`,
        });
        if (response.data && response.status === 200) {
            yield put(landingInternetPlanSuccess({ internet: { ...response.data, status: response.status } }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(landingInternetPlanFailure({ error: getErrorMessage(e) }));
        // history.push('/server-error');
    }
}

function* getICSummaryList(action: any) { //action: any
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            // url: `csr/api/v1/move-order/summary/?move=224917&service=digital`,
            // url: `csr/api/v1/move-order/summary/?move=${action.payload.moveId}&service=digital`,
            url: `/api/move/csr-summary-details/summary/?move=${action.payload.moveId}&service=digital`,
        });
        if (response.data && response.status === 200) {
            yield put(getICSummarySuccess({ summary: { ...response.data.move_orders, status: response.status } }));
        }
    } catch (e) {
        yield put(getICSummaryFailure({ error: getErrorMessage(e) }));
    }
}

export default function* internetSaga() {
    yield all([
        takeLatest(EXTERNAL_DIGITAL_PLANS, internetPlan),
        takeLatest(SELECT_DIGITAL_PLANS, selectDigitalPlan),
        takeLatest(SELECT_DIGITAL_PLANS_GET, selectDigitalPlanGet),
        takeLatest(GET_STEPS, getSteps),
        takeLatest(CHANGE_STEPS, changeSteps),
        takeLatest(LANDING_DIGITAL_PLANS, internetLandingPlan),
        takeLatest(GET_IC_SUMMARY, getICSummaryList)
    ]);
}
