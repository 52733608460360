import React from 'react';
// import history from '../../routes/History';
import { connect } from 'react-redux';
import * as Action from '../../store/login/signup/actions';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';
import './preApproval.scss';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import ApiService from '../api-service';
import user from '../../Assets/images/welcome/user.png'
import Button from '../../components/atoms/Button';
import { InputAdornment, InputLabel} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import _ from 'lodash';

type initialProps = {
    history: any;
    location?: any;
    signupAction: any;
    signupState: any;
    commonAction: any;
    commonState: any;
};

type initialState = {    
    homeOwnerVideoURL: string;
    fullName: string;
    phone: string;
    email: string;
    image: string;
    licenceNumber: string;
    buttonShow: boolean;
    serviceAgentData: any;
    serviceProviders: any;
    expirationDate: any;
    amountApproved: any;
    companyName: any;
    expirationDateError: any;
    amountApprovedError: any;
    companyNameError: any;
    showSuccessMessage: boolean;
    hashcode: string;
    moveID: any;
};
class welcomeVideo extends React.Component<initialProps, initialState> {
    state: initialState = {
        homeOwnerVideoURL: '',
        fullName: '',
        phone: '',
        email: '',
        image: '',
        licenceNumber: '',
        buttonShow: true,
        serviceAgentData: '',
        serviceProviders: '',
        expirationDate: '',
        amountApproved: '',
        companyName: '',
        expirationDateError: '',
        amountApprovedError: '',
        companyNameError: '',
        showSuccessMessage: false,
        hashcode: '',
        moveID: '',
    };


    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].hash_code &&
            !currentState.hashcode
        ) {
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceAgents()
            .then(serviceAgentData => {
                nextProps.commonAction.getServiceAgentInfo(serviceAgentData)
            })
            .catch(e => {
                console.debug(e);
            });
            var providerType = "brokerage-home-inspection"
            var moveHash = nextProps.commonState.move[0].hash_code;
            ApiService.getServiceProviders(providerType,moveHash)
            .then(serviceProviders => {
                nextProps.commonAction.getServiceProviderInfo(serviceProviders)
            })
            .catch(e => {
                console.debug(e);
            });
        }
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move[0] &&
            nextProps.commonState.move[0].realtor
        ) {
            currentState.moveID = 
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].id
            currentState.homeOwnerVideoURL =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.home_owner_video_url
            currentState.fullName =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.contact_name
            currentState.phone =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.phone
            currentState.email =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.email
            currentState.image =
            nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor.contact_logo ? nextProps.commonState.move[0].realtor.contact_logo : user
            currentState.licenceNumber =
                nextProps.commonState.move[0] &&
                    nextProps.commonState.move[0].realtor.dre_no
        }
        if(nextProps.commonState.serviceAgentInfo){
            currentState.serviceAgentData = nextProps.commonState.serviceAgentInfo
        }

        if(nextProps.commonState.serviceProviderInfo){
            currentState.serviceProviders = nextProps.commonState.serviceProviderInfo
        }
        if(nextProps.signupState.haveAPreapproval?.amount_approved){
            currentState.showSuccessMessage = true
            setTimeout(() => {
                currentState.serviceProviders?.data?.length > 0 ? nextProps.history.push('/signUp/preApproval/next') : nextProps.history.push('/dashboard')
              }, 1000);
        }
        return currentState;
    }

    onChangeHandler = (e: any, name: string) => {
        var regexPattern = /(^[0-9]*$)/i;
        this.setState({
            ...this.state,
            [name]: name === "amountApproved" && !regexPattern.test(this.state.amountApproved.toString()) ? "": e.target.value,
        });
        this.validateFields(name)
        
    };

    validateFields = (name: any) => {
        let validity: Boolean = true;
        var regexPattern = /(^[0-9]*$)/i;
        switch(name) {
            case "amountApproved":
                if (this.state.amountApproved === '') {
                    this.setState({ amountApprovedError: 'Please Enter amount' });
                    validity = false;
                } else {
                    this.setState({ amountApprovedError: '' });
                }
                if(!regexPattern.test(this.state.amountApproved.toString())){
                    this.setState({ amountApprovedError: 'Please input numbers only!' });
                    validity = false;
                } else {
                    this.setState({ amountApprovedError: '' });
                }
              break;
            case "expirationDate":
                if (this.state.expirationDate === '') {
                    this.setState({ expirationDateError: 'Please Enter expiration date' });
                    validity = false;
                } else {
                    this.setState({ expirationDateError: '' });
                }
            break;
            case "companyName":
                if (this.state.companyName === '') {
                    this.setState({ companyNameError: 'Please Enter company name' });
                    validity = false;
                } else {
                    this.setState({ companyNameError: '' });
                }
              break;
            case "submit":
                if (this.state.amountApproved === '') {
                    this.setState({ amountApprovedError: 'Please Enter amount' });
                    validity = false;
                } else {
                    this.setState({ amountApprovedError: '' });
                }
                if(!regexPattern.test(this.state.amountApproved.toString())){
                    this.setState({ amountApprovedError: 'Please input numbers only!' });
                    validity = false;
                } else {
                    this.setState({ amountApprovedError: '' });
                }
                if (this.state.expirationDate === '') {
                    this.setState({ expirationDateError: 'Please Enter expiration date' });
                    validity = false;
                } else {
                    this.setState({ expirationDateError: '' });
                }
        
                if (this.state.companyName === '') {
                    this.setState({ companyNameError: 'Please Enter company name' });
                    validity = false;
                } else {
                    this.setState({ companyNameError: '' });
                }
          }

        return validity;
    };

    proceedButtonHandler = () => {
        if (!_.isEmpty(this.state.serviceProviders.data)) {
            if (this.validateFields("submit")) {
                let payload: any = {
                    "mortgage_company_name": this.state.companyName,
                    "amount_approved": this.state.amountApproved,
                    "expiration_date": moment(this.state.expirationDate).format('YYYY-MM-DD'),
                    // "move": localStorage.getItem('moveId'),
                    "move": this.state.moveID
                };
                this.props.signupAction.haveAPreapproval(payload);
            }
        } else {
            this.props.history.push({
                pathname: '/dashboard',
                state: {
                    fromSignUp: 'fromSignUp',
                },
            });
        }
    }

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        return (
            <div className="innercontainer_page greybg">
                <div className="innerbox_width havesidebar">
                    <div className="sidebar_blue">
                        <h2 className="sidebartitle">Set up a<br></br><span>Preapproval</span></h2>
                    </div>
                    <div className="contentbar">
                        <h2 className="contentbar_maintitle">
                            <a href="#">                         
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { this.props.history.push('/signUp/preapproval') }}>
                                    <path d="M33.5164 15.3643C32.1448 15.3643 8.47879 15.3643 8.47879 15.3643L19.5526 4.25335C20.5227 3.2806 20.5227 1.70261 19.5526 0.729997C18.5825 -0.243332 17.0096 -0.243332 16.0409 0.729997L0.727602 16.094C-0.242534 17.0668 -0.242534 18.6449 0.727602 19.6174L16.0409 34.9821C16.5259 35.469 17.1614 35.712 17.7968 35.712C18.4321 35.712 19.0677 35.469 19.5526 34.9821C20.5227 34.0094 20.5227 32.4314 19.5526 31.4589L8.47879 20.3474C8.47879 20.3474 32.145 20.3474 33.5164 20.3474C34.8879 20.3474 36 19.2317 36 17.8558C36 16.4798 34.888 15.3643 33.5164 15.3643Z" fill="#333333"/>
                                </svg>
                                Preapproval details
                            </a>
                        </h2>
                        
                        <div className="contentlist">
                            <div className="checkbox_wrapper smallinput">
                                <div className="single_checkbox">
                                    <CustomizedInput
                                        labelName="Amount Approved"
                                        value={this.state.amountApproved}
                                        onChange={(e: any) => this.onChangeHandler(e, 'amountApproved')}
                                        placeholder=""
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            'data-cy': 'signup-input-amountApproved'
                                        }}
                                    />
                                    <div className="error">
                                        {this.state.amountApprovedError 
                                            ? this.state.amountApprovedError
                                            : ''}
                                    </div>
                                </div>
                                <div className="single_checkbox preApprovalDates">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <InputLabel
                                                htmlFor="bootstrap-input"
                                                className={'text-black ml-2 mb-2 mt-3'}
                                                style={{ fontFamily: 'Poppins', color: '#162855' }}
                                            >
                                                Expiration date
                                            </InputLabel>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    // disablePast
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    inputVariant="filled"
                                                    label="Select date"
                                                    className="date11"
                                                    value={
                                                        this.state.expirationDate
                                                            ? new Date(this.state.expirationDate)
                                                            : null
                                                    }
                                                    // onChange={(e: any) => this.onChangeHandler(e, 'expirationDate')}
                                                    onChange={(e: any) => this.setState({ expirationDate: e })}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    PopoverProps={{
                                                        style: { zIndex: 1000000 },
                                                    }}
                                                    data-cy="signup-input-expiration-date"
                                                    autoOk={true}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div className="error">
                                                {this.state.expirationDateError && !this.state.expirationDate
                                                    ? this.state.expirationDateError
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single_checkbox">
                                    <CustomizedInput
                                        labelName="Mortgage Company Name"
                                        value={this.state.companyName}
                                        onChange={(e: any) => this.onChangeHandler(e, 'companyName')}
                                        placeholder="Enter company name"
                                        InputProps={{
                                            'data-cy': 'signup-input-companyName'
                                        }}
                                    />
                                    <div className="error">
                                        {this.state.companyNameError 
                                            ? this.state.companyNameError
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            {this.state.showSuccessMessage ? (
                                <div className="thankyou_wrapper havebg">
                                    <div className="thankyou_wrapper_ic">
                                        <svg id="Group_42451" data-name="Group 42451" xmlns="http://www.w3.org/2000/svg" width="39.835" height="39.835" viewBox="0 0 39.835 39.835">
                                        <circle id="Ellipse_135" data-name="Ellipse 135" cx="19.918" cy="19.918" r="19.918" fill="#00d56d"/>
                                        <path id="Check" d="M110.895,2642.323l-6.618-6.664a.94.94,0,0,1,0-1.332l1.337-1.334a.952.952,0,0,1,1.337,0l4.614,4.666,10.284-10.385a.952.952,0,0,1,1.337,0l1.337,1.334a.938.938,0,0,1,0,1.33l-12.291,12.385a.947.947,0,0,1-1.337,0" transform="translate(-94.205 -2615.093)" fill="#fff"/>
                                        </svg>
                                    </div>
                                    <div className="thankyou_wrapper_content">
                                        <h4>Great! Your request has been submitted</h4>
                                        <p>Thank you for your submission. You will be contacted soon.</p>
                                    </div>
                                </div>
                            ) : ""}
                            <div className="btns">
                                <Button
                                    className='bluebtn'
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                    }
                                    data-cy="signup-step-three-submit"
                                    disabled={this.state.companyName && this.state.amountApproved && this.state.expirationDate ? false : true}
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                        onClick={this.proceedButtonHandler}
                                    >
                                        Save & Next
                                </Button>
                                <Button 
                                backgroundColor= '#ffffff'
                                color='#0066F5'
                                className="actionlink"
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/dashboard',
                                        state: {
                                            fromSignUp: 'fromSignUp',
                                        },
                                    });
                                }}
                                >
                                    Skip This
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='stepwrapper'>Step 3 of 4</p>
            </div>
            
               
        );
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        signupAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    signupState: state.signup,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeVideo);
