import React from 'react';
import './LaCarteServices.scss';
import Button from '../../components/atoms/Button';
import LaCarteCard from '../../components/atoms/PreMove/LaCarteCard';
import { connect } from 'react-redux';
import * as Action from '../../store/premove/actions';
import { bindActionCreators } from 'redux';

type InitialProps = {
    history?: any;
    selectableServicesAction?: any;
    selectableServicesState?: any;
};

type InitialState = {
    services: any;
    slug: any;
    isDataArrived: boolean;
    isDataArrived2: boolean;
    hashcode: any;
    name: string;
    email: string;
    phone: string;
};

class LaCarteServices extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        slug: '',
        services: [],
        hashcode: '',
        isDataArrived: true,
        isDataArrived2: true,
        name: '',
        email: '',
        phone: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        this.props.selectableServicesAction.selectableServices();
    }

    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            nextProps.history.location.state.hashcode &&
            nextProps.history.location.state.name &&
            nextProps.history.location.state.email &&
            nextProps.history.location.state.phone &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.hashcode = nextProps.history.location.state.hashcode;
            currentState.name = nextProps.history.location.state.name;
            currentState.phone = nextProps.history.location.state.phone;
            currentState.email = nextProps.history.location.state.email;
        }

        if (
            nextProps.selectableServicesState &&
            nextProps.selectableServicesState.selectableServices &&
            nextProps.selectableServicesState.selectableServices.length &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            // console.log("test", nextProps.selectableServicesState.selectableServices)
            let services = nextProps.selectableServicesState.selectableServices;
            let servicesNew: any = [];

            services.map((item: any) => {
                if (item.is_active_to_display == true) {
                    servicesNew.push({
                        heading: item.frontend_name,
                        subheading: item.description,
                        slug: item.slug,
                        checked: false,
                    });
                }
            });

            currentState.services = servicesNew;
        }

        return currentState;
    }

    handleBack = () => {
        this.props.history.push({
            pathname: '/servicerequirement',
            state: {
                hashcode: this.state.hashcode,
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
            },
        });
    };

    handleContinue = () => {
        let carteServices: any = '';
        this.state.services.map((item: any) => {
            if (item.checked == true) {
                carteServices = carteServices + item.slug + ',';
            }
        });
        this.props.history.push({
            pathname: '/scheduleacall',
            state: {
                service: 'La Carte',
                hashcode: this.state.hashcode,
                carteServices: carteServices.substring(0, carteServices.length - 1),
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
            },
        });
    };

    handleCheck = (name: any) => {
        let services = this.state.services;
        services.map((item: any) => {
            if (item.slug == name) {
                item.checked = !item.checked;
            }
        });

        this.setState({
            services: services,
        });
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className="cartePageMain" style={{ marginTop: '150px' }}>
                <div className="carte-main-heading">À la carte</div>
                <div className="carte-sub-heading">Services I am interested are…</div>
                <div className="carte-cards-div">
                    {this.state.services.map((item: any, i: number) => {
                        return (
                            <div key={i} className="carte-card-div">
                                <LaCarteCard
                                    heading={item.heading}
                                    subheading={item.subheading}
                                    checked={item.checked}
                                    checkHandler={() => {
                                        this.handleCheck(item.slug);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="carte-button-div">
                    <Button
                        className="carte-button"
                        onClick={this.handleBack}
                        backgroundColor="#FFFFFF"
                        boxShadow={`0 0 3px 0 ${
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }`}
                        color={
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }
                        borderRadius="5px"
                        fontSize="16px"
                        margin="0 15px 0 0"
                    >
                        Back
                    </Button>
                    <Button
                        className="carte-button"
                        onClick={this.handleContinue}
                        backgroundColor={
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }
                        color="#fff"
                        borderRadius="5px"
                        fontSize="16px"
                        margin="0 15px 0 0"
                    >
                        Continue
                    </Button>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        selectableServicesAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    selectableServicesState: state.premove,
});

export default connect(mapStateToProps, mapDispatchToProps)(LaCarteServices);
