import * as React from 'react';

type initialProps = {
    height?: any;
    width?: any;
    item?: any;
    className?: any;
};
function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    // console.log(props.item);
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 41.965}
            height={props.height ? props.height : 35}
            viewBox="0 0 41.965 35"
        >
            <defs>
                <style>
                    {// ".prefix__a{fill:#fff}.prefix__b{fill:#bebebe}.prefix__c{fill:#6b6c6f}"
                    `.mortgageprefix__a{fill:#fff}.mortgageSvgprefix__b{fill:${
                        window.location.pathname.includes('mortgage') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#bebebe !important'
                    }}.mortgageSvgprefix__c{fill:${
                        window.location.pathname.includes('mortgage') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path
                className="mortgageprefix__a"
                d="M24.767 30.378a9.082 9.082 0 117.589 4.093 9.082 9.082 0 01-7.589-4.093zm11.314-5.6h1.544l-1.648-1.2-3.621-2.635-5.269 3.836h1.543v5.052h7.451zm0 0"
            />
            <path
                className="mortgageSvgprefix__b"
                d="M37.625 24.786h-1.544v5.052H28.63v-5.052h-1.543l5.269-3.836 3.621 2.635zm0 0"
            />
            <path
                className="mortgageprefix__a"
                d="M29.952.527v16.1a9.084 9.084 0 00-5.185 13.747h-.053a5 5 0 01-4.63-5.032v-3.263H6.353L6.571.527zm0 0"
            />
            <path
                className="mortgageSvgprefix__b"
                d="M24.715 30.375v.01H5.459a4.988 4.988 0 01-4.932-5.042v-3.264h19.558v3.264a5 5 0 004.63 5.032zm0 0"
            />
            <path
                className="mortgageSvgprefix__c"
                d="M11.934 5.823h4.1a.527.527 0 000-1.054h-4.1a.527.527 0 000 1.054zm0 0M11.934 11.607h11.94a.527.527 0 100-1.054h-11.94a.527.527 0 100 1.054zm0 0M24.399 16.869a.527.527 0 00-.527-.527H11.934a.527.527 0 000 1.054h11.94a.527.527 0 00.525-.527zm0 0"
            />
            <path
                className="mortgageSvgprefix__c"
                d="M32.356 15.782a9.637 9.637 0 00-1.877.185V.527A.527.527 0 0029.952 0H6.571a.527.527 0 00-.527.522l-.213 21.03H.527a.527.527 0 00-.527.527v3.264A5.529 5.529 0 005.116 30.9a.532.532 0 00.112.012h19.265a9.608 9.608 0 107.864-15.129zM5.459 29.858a4.466 4.466 0 01-4.4-4.515v-2.737h18.5v2.737a5.6 5.6 0 002.266 4.515zm15.153-4.515v-3.264a.527.527 0 00-.527-.527h-13.2l.207-20.5h22.333v15.186a9.612 9.612 0 00-5.673 13.432 4.509 4.509 0 01-3.14-4.327zm11.745 8.6a8.555 8.555 0 01-2.266-16.803 8.555 8.555 0 112.265 16.805zm0 0"
            />
            <path
                className="mortgageSvgprefix__c"
                d="M37.936 24.359l-5.269-3.836a.527.527 0 00-.62 0l-5.269 3.836a.527.527 0 00.31.953h1.016v4.525a.527.527 0 00.527.527h7.451a.527.527 0 00.527-.527v-4.525h1.017a.527.527 0 00.31-.953zm-2.381.426v4.525h-6.4v-4.525a.527.527 0 00-.457-.522l3.656-2.662 3.656 2.658a.527.527 0 00-.455.524zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
