import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface IButtonProps {
    endIcon?: any;
    iconMarginLeft?: string;
    active?: any;
    backgroundColor?: string;
    fontSize?: string;
    fontWeight?: any;
    width?: string;
    height?: string;
    borderRadius?: string;
    margin?: string;
    marginTop?: string;
    color?: string;
    padding?: string;
    borderLeft?: string;
    minWidth?: string;
    onClick?: any;
    className?: string;
    disabled?: any;
    children?: any;
    boxShadow?: any;
    border?: string;
    opacity?: string;
    "data-cy"?: string;
}
const ButtonNew = (props: IButtonProps) => {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);

    const domain = window.location.hostname;
    let name = domain.split('.');
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const useStyles = makeStyles(() => ({
        root: {
            //     '&:hover': {
            //         backgroundColor: `${slug && slug.primary_color ? slug.primary_color : '#273e59'} !important`,
            //         color: '#fff !important',
            //         opacity: '0.9',
            //     },
        },
    }));
    const classes = useStyles();
    let icon = null;
    if (props.endIcon) {
        icon = <img src={props.endIcon} style={{ marginLeft: props.iconMarginLeft ? props.iconMarginLeft : '0px' }} alt="" />;
    }
    return (
        <Button
            data-cy={props["data-cy"]}
            style={{
                fontFamily: isDouglasElliman ? 'EuclidCircularA-Regular' : 'normal normal 600 13px/14px Muli',
                // backgroundColor: props.disabled ? '#BEBEBE' : props.backgroundColor ? props.backgroundColor : '#273E59',
                backgroundColor: props.disabled
                    ? '#BEBEBE'
                    : props.backgroundColor
                    ? props.backgroundColor
                    : isDouglasElliman
                    ? '#100B28'
                    : slug && slug.primary_color
                    ? slug && slug.primary_color
                    : name[0].includes('internet') || name[0].includes('insurance') || name[0].includes('security')
                    ? '#30AB86'
                    : '#273E59',
                letterSpacing: '-0.32px',
                fontSize: props.fontSize ? props.fontSize : '16px',
                fontWeight: props.fontWeight ? props.fontWeight : 700,
                color: props.disabled
                    ? '#ffffff'
                    : props.color
                    ? props.color
                    : isDouglasElliman
                    ? '#FFFFFF'
                    : slug && slug.primary_font_color
                    ? slug.primary_font_color
                    : '#fff',
                // color: props.disabled ? '#FFFFFF' : props.color ? props.color : '#FFFFFF',
                width: props.width ? props.width : '180px',
                height: props.height ? props.height : isDouglasElliman ? '52px' : '50px',
                borderRadius: isDouglasElliman ? '0px' : props.borderRadius ? props.borderRadius : '0px',
                textAlign: 'center',
                textTransform: 'capitalize',
                margin: props.margin ? props.margin : '2px',
                marginTop: props.marginTop ? props.marginTop : '2px',
                padding: props.padding ? props.padding : '',
                borderLeft: props.borderLeft ? props.borderLeft : 'none',
                minWidth: props.minWidth ? props.minWidth : '30px',
                boxShadow: props.boxShadow ? props.boxShadow : 'none',
                border: props.disabled ? '#BEBEBE' : props.border ? props.border : 'none',
                // opacity: props.disabled ? '50%' : '100%',
            }}
            onClick={props.onClick}
            className={props.className ? props.className : classes.root}
            disabled={props.disabled ? props.disabled : false}
            endIcon={icon}
        >
            {props.children}
        </Button>
    );
};

export default ButtonNew;
