import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36.505}
      height={35.924}
      viewBox="0 0 36.505 35.924"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupTitle_prefix__a{fill:${slug.color_shade3}}.signupTitle_prefix__c{fill:${slug.color_shade1}}`
            : ".signupTitle_prefix__a{fill:#5b7088}.signupTitle_prefix__c{fill:#273e59}"
          }
          </style>
      </defs>
      <path
        className="signupTitle_prefix__a"
        d="M29.253 13.223l4.565 3.323 2.122 1.545h-1.987v8.012h-9.59V18.09h-1.99l6.783-4.939zm0 0M35.836 3.855v6.113h-6.583V3.855a3.291 3.291 0 013.1-3.286q.093-.006.188-.006a3.292 3.292 0 013.295 3.292zm0 0"
      />
      <path
        d="M32.357.564v.01a3.291 3.291 0 00-3.1 3.286v9.367l-.1-.071-6.783 4.939h1.986v8.009h4.894v3.856a5.341 5.341 0 01-5.281 5.4c-.109 0-.216 0-.324-.01a5.349 5.349 0 01-4.958-5.389v-3.5H5.863l.2-22.177a3.762 3.762 0 013.76-3.72zm0 0"
        fill="#fff"
      />
      <path
        className="signupTitle_prefix__a"
        d="M23.644 35.353v.01h-17.8a5.341 5.341 0 01-5.28-5.403v-3.5h18.123v3.5a5.349 5.349 0 004.957 5.393zm0 0"
      />
      <path
        className="signupTitle_prefix__c"
        d="M9.956 9.057h9.285a.564.564 0 000-1.128H9.956a.564.564 0 100 1.128zm0 0M20.555 12.243h-5.811a.564.564 0 100 1.128h5.811a.564.564 0 000-1.128zm0 0M12.483 13.373a.564.564 0 100-1.128H9.956a.564.564 0 100 1.128zm0 0M25.187 8.493a.564.564 0 00-.564-.564h-3.144a.564.564 0 000 1.128h3.144a.564.564 0 00.564-.564zm0 0M11.367 18.594a.659.659 0 01.658.658.564.564 0 001.128 0 1.789 1.789 0 00-1.223-1.694v-.2a.564.564 0 00-1.128 0v.2a1.786 1.786 0 00.564 3.481.659.659 0 110 1.317h-.009a.659.659 0 01-.654-.658.564.564 0 10-1.128 0 1.789 1.789 0 001.223 1.694v.256a.564.564 0 001.128 0v-.256a1.786 1.786 0 00-.564-3.481.659.659 0 110-1.317zm0 0M17.95 18.594a.659.659 0 01.658.658.564.564 0 001.128 0 1.789 1.789 0 00-1.223-1.694v-.2a.564.564 0 00-1.128 0v.2a1.786 1.786 0 00.564 3.481.659.659 0 110 1.317h-.009a.659.659 0 01-.654-.658.564.564 0 10-1.128 0 1.789 1.789 0 001.223 1.694v.256a.564.564 0 001.128 0v-.256a1.786 1.786 0 00-.564-3.481.659.659 0 010-1.317zm0 0"
      />
      <path
        className="signupTitle_prefix__c"
        d="M36.273 17.635l-1.658-1.207v-1.885a.564.564 0 00-1.128 0v1.067l-3.668-2.671v-2.4h6.019a.564.564 0 00.564-.564V3.856A3.856 3.856 0 0032.545 0c-.064 0-.127 0-.188.005V0H9.82a4.34 4.34 0 00-4.326 4.283L5.3 25.901H.564a.564.564 0 00-.564.564v3.5a5.918 5.918 0 005.6 5.957v.006h18.044v-.018a5.2 5.2 0 00.328.01 5.912 5.912 0 005.846-5.963v-3.289h4.136a.564.564 0 00.564-.564v-7.448h1.422a.564.564 0 00.332-1.02zm-3.882-16.5h.154a2.727 2.727 0 012.727 2.727v5.542h-5.454V3.856a2.727 2.727 0 012.573-2.723zM5.849 34.795a4.782 4.782 0 01-4.721-4.835v-2.931h16.995v2.931a6 6 0 002.426 4.835zm22.84-4.835a4.783 4.783 0 01-4.721 4.835c-.093 0-.187 0-.289-.01a4.788 4.788 0 01-4.428-4.825v-3.5a.564.564 0 00-.564-.564H6.432l.19-21.602a3.208 3.208 0 013.2-3.165h20a3.847 3.847 0 00-1.131 2.727v8.939l-6.648 4.84a.564.564 0 00.332 1.02H23.8v7.448a.564.564 0 00.564.564h4.33zm5.264-12.433a.564.564 0 00-.564.564v7.449h-8.465v-7.449a.564.564 0 00-.564-.564h-.253l5.05-3.677 5.05 3.677zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
