import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={41.4} height={54} viewBox="0 0 41.4 54" {...props}>
            <defs>
                <style>{`.InsuarnceMainHeaterprefix__b{fill:${
                    slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? '#333333'
                            : slug.primary_color
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#1D7E64'
                        : '#273e59'
                }}`}</style>
            </defs>
            <path
                d="M2.7 44.1h36a1.8 1.8 0 001.8-1.8V2.7A1.8 1.8 0 0038.7.9h-36A1.8 1.8 0 00.9 2.7v39.6a1.8 1.8 0 001.8 1.8zM35.1 4.5a1.8 1.8 0 11-1.8 1.8 1.8 1.8 0 011.8-1.8zM20.7 20.7a6.3 6.3 0 11-6.3 6.3 6.3 6.3 0 016.3-6.3zm-7.2 16.2h14.4v3.6H13.5zm0 0"
                fill={
                    slug && slug.color_shade4
                        ? props.isDouglasElliman
                            ? '#ffffff'
                            : slug.color_shade4
                        : props.fromlanding || window.location.pathname.includes('landing')
                        ? '#30ab86'
                        : '#8195ac'
                }
            />
            <path
                className="InsuarnceMainHeaterprefix__b"
                d="M38.7 0h-36A2.7 2.7 0 000 2.7v39.6A2.7 2.7 0 002.7 45h2.7v6.3a2.7 2.7 0 105.4 0V45h19.8v6.3a2.7 2.7 0 105.4 0V45h2.7a2.7 2.7 0 002.7-2.7V2.7A2.7 2.7 0 0038.7 0zM9 51.3a.9.9 0 11-1.8 0V45H9zm25.2 0a.9.9 0 11-1.8 0V45h1.8zm5.4-9a.9.9 0 01-.9.9h-36a.9.9 0 01-.9-.9V2.7a.9.9 0 01.9-.9h36a.9.9 0 01.9.9zm0 0"
            />
            <path
                className="InsuarnceMainHeaterprefix__b"
                d="M20.7 34.2a7.2 7.2 0 10-7.2-7.2 7.2 7.2 0 007.2 7.2zm0-12.6a5.4 5.4 0 11-5.4 5.4 5.4 5.4 0 015.4-5.4zm0 0M35.1 3.6a2.7 2.7 0 102.7 2.7 2.7 2.7 0 00-2.7-2.7zm0 3.6a.9.9 0 11.9-.9.9.9 0 01-.9.9zm0 0M27.9 36H13.5a.9.9 0 00-.9.9v3.6a.9.9 0 00.9.9h14.4a.9.9 0 00.9-.9v-3.6a.9.9 0 00-.9-.9zm-.9 3.6H14.4v-1.8H27zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
