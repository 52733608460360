export const GET_CHECKLIST_DATA = 'GET_CHECKLIST_DATA';
export const GET_CHECKLIST_DATA_SUCCESS = 'GET_CHECKLIST_DATA_SUCCESS';
export const GET_CHECKLIST_DATA_ERROR = 'GET_CHECKLIST_DATA_ERROR';

export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR';

export const SET_REMINDER = 'SET_REMINDER';
export const SET_REMINDER_SUCCESS = 'SET_REMINDER_SUCCESS';
export const SET_REMINDER_ERROR = 'SET_REMINDER_ERROR';

export const UPDATE_REMINDER = 'UPDATE_REMINDER';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export const UPDATE_REMINDER_ERROR = 'UPDATE_REMINDER_ERROR';

export const DELETE_REMINDER = 'DELETE_REMINDER';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const DELETE_REMINDER_ERROR = 'DELETE_REMINDER_ERROR';
