import serverAddress from '../config';

export default {
    fetchAPI: async ({ token, url }: FetchService) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${token}`);
        myHeaders.append("Content-Type", "application/json");
        return await fetch(`${serverAddress}/${url}`, {
            method: 'GET',
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    postData: async ({ token, url, data }: FetchService) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${token}`);
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify(data || {});
        return await fetch(`${serverAddress}/${url}`, {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        })
            .then(response => response.json())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    putData: async ({ token, url, data }: FetchService) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${token}`);
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify(data || {});
        return await fetch(`${serverAddress}/${url}`, {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
        })
            .then(response => response.json())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
}

interface FetchService {
    token: string;
    url: string;
    data?: any;
};