import {
    POST_NEXTMOVE,
    POST_NEXTMOVE_ERROR,
    POST_NEXTMOVE_SUCCESS,
    SEND_REALTOR_NOTIFICATION,
    SEND_REALTOR_NOTIFICATION_ERROR,
    SEND_REALTOR_NOTIFICATION_SUCCESS,
    GET_NEXTMOVE,
    GET_NEXTMOVE_ERROR,
    GET_NEXTMOVE_SUCCESS,
    UPDATE_NEXTMOVE,
    UPDATE_NEXTMOVE_ERROR,
    UPDATE_NEXTMOVE_SUCCESS,
} from './actionTypes';
import * as types from './types';

export const postNextMove = (payload: types.postNextMoveRequest): types.PostNextMove => ({
    type: POST_NEXTMOVE,
    payload,
});
export const postNextMoveSuccess = (payload: types.postNextMoveSuccessPayload): types.PostNextMoveSuccess => ({
    type: POST_NEXTMOVE_SUCCESS,
    payload,
});
export const postNextMoveFailure = (payload: types.postNextMoveFailurePayload): types.PostNextMoveFailure => ({
    type: POST_NEXTMOVE_ERROR,
    payload,
});
export const updateNextMove = (payload: types.updateNextMoveRequest): types.UpdateNextMove => ({
    type: UPDATE_NEXTMOVE,
    payload,
});
export const updateNextMoveSuccess = (payload: types.updateNextMoveSuccessPayload): types.UpdateNextMoveSuccess => ({
    type: UPDATE_NEXTMOVE_SUCCESS,
    payload,
});
export const updateNextMoveFailure = (payload: types.updateNextMoveFailurePayload): types.UpdateNextMoveFailure => ({
    type: UPDATE_NEXTMOVE_ERROR,
    payload,
});

export const sendRealtorNotification = (
    payload: types.SendRealtorNotificationRequest,
): types.SendRealtorNotification => ({
    type: SEND_REALTOR_NOTIFICATION,
    payload,
});
export const sendRealtorNotificationSuccess = (
    payload: types.SendRealtorNotificationSuccessPayload,
): types.SendRealtorNotificationSuccess => ({
    type: SEND_REALTOR_NOTIFICATION_SUCCESS,
    payload,
});
export const sendRealtorNotificationFailure = (
    payload: types.SendRealtorNotificationFailurePayload,
): types.SendRealtorNotificationFailure => ({
    type: SEND_REALTOR_NOTIFICATION_ERROR,
    payload,
});
export const getNextMove = (payload: types.GetNextMoveRequest): types.GetNextMove => ({
    type: GET_NEXTMOVE,
    payload,
});
export const getNextMoveSuccess = (payload: types.GetNextMoveSuccessPayload): types.GetNextMoveSuccess => ({
    type: GET_NEXTMOVE_SUCCESS,
    payload,
});
export const getNextMoveFailure = (payload: types.GetNextMoveFailurePayload): types.GetNextMoveFailure => ({
    type: GET_NEXTMOVE_ERROR,
    payload,
});
