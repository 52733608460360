import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37.938" height="31" viewBox="0 0 37.938 31" {...props}>
            <defs>
                <style>
                    {`.pettransportSvgprefix__c{fill:${
                        window.location.pathname.includes('pettransport') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path
                fill="#fff"
                d="M283.058 200.238v8.482a1.894 1.894 0 01-1.893 1.894H278.8a3.314 3.314 0 10-6.628 0h-4.04v-10.376zm0 0"
                data-name="Path 2257"
                transform="translate(-245.624 -183.432)"
            ></path>
            <path
                className="pettransportSvgprefix__c"
                d="M277.679 130.107l5.379 5.43h-14.929v-5.928h8.357a1.676 1.676 0 011.193.498zm0 0"
                data-name="Path 2258"
                transform="translate(-245.624 -118.731)"
            ></path>
            <path
                className="pettransportSvgprefix__c"
                d="M319.575 284.371a3.314 3.314 0 11-3.313 3.314 3.314 3.314 0 013.313-3.314zm0 0"
                data-name="Path 2259"
                transform="translate(-289.717 -260.503)"
            ></path>
            <path
                fill="#fff"
                d="M28 22.3v10.379H16.889a3.314 3.314 0 00-6.628 0H7.893A1.894 1.894 0 016 30.785V7.893A1.894 1.894 0 017.893 6h18.215A1.893 1.893 0 0128 7.893zm-5.944-6.185h1.967l-2.1-1.53-4.614-3.359-6.715 4.889h1.968v7.931h9.5zm0 0"
                data-name="Path 2260"
                transform="translate(-5.496 -5.496)"
            ></path>
            <path
                fill="#fff"
                d="M74.168 73.19H72.2v7.931h-9.5V73.19h-1.962l6.715-4.89 4.614 3.359zm0 0"
                data-name="Path 2261"
                transform="translate(-55.64 -62.568)"
            ></path>
            <path
                className="pettransportSvgprefix__c"
                d="M60.084 284.371a3.314 3.314 0 11-3.315 3.314 3.313 3.313 0 013.315-3.314zm0 0"
                data-name="Path 2262"
                transform="translate(-52.005 -260.503)"
            ></path>
            <g data-name="Group 1120">
                <path
                    className="pettransportSvgprefix__c"
                    d="M2.4 27.686h1.9a3.817 3.817 0 007.567 0h14.208a3.817 3.817 0 007.567 0h1.9a2.4 2.4 0 002.4-2.4v-8.479a.477.477 0 00-.009-.089.025.025 0 000-.007.487.487 0 00-.025-.086v-.009a.489.489 0 00-.042-.08v-.007a.5.5 0 00-.061-.076l-5.379-5.43a2.193 2.193 0 00-1.551-.647h-7.866V2.4a2.4 2.4 0 00-2.4-2.4H2.4A2.4 2.4 0 000 2.4v22.888a2.4 2.4 0 002.4 2.398zm27.461 2.307a2.81 2.81 0 112.811-2.81 2.81 2.81 0 01-2.814 2.81zm-6.85-18.611h7.854a1.181 1.181 0 01.835.349l4.527 4.569H23.009zm0 5.928h13.92v7.978a1.392 1.392 0 01-1.39 1.391h-1.9a3.817 3.817 0 00-7.567 0h-3.065zM8.08 29.993a2.81 2.81 0 112.81-2.81 2.81 2.81 0 01-2.81 2.81zM1.007 2.4A1.392 1.392 0 012.4 1.007h18.212A1.391 1.391 0 0122 2.4v24.279H11.862a3.817 3.817 0 00-7.567 0H2.4a1.392 1.392 0 01-1.39-1.391zm0 0"
                    data-name="Path 2263"
                ></path>
            </g>
            <g transform="rotate(-6.02 -195.625 83.907)">
                <g className="pettransportSvgprefix__c" data-name="Group 43472" transform="translate(10.25 26.525)">
                    <path
                        d="M444.611 89.143a1.077 1.077 0 01.451 1.41c-.2.632-.736 1.025-1.189.878a1.077 1.077 0 01-.451-1.41c.204-.632.736-1.021 1.189-.878z"
                        data-name="Path 53255"
                        transform="translate(-428.74 -87.172)"
                    ></path>
                    <path
                        d="M282.281 138.2a1.384 1.384 0 01-1.628 2.179l-.4-.2a1.381 1.381 0 00-1.154-.028l-.458.2a1.381 1.381 0 01-1.762-.614l-.03-.055a1.388 1.388 0 01.224-1.622l1.388-1.435a1.63 1.63 0 012.361.019z"
                        data-name="Path 53256"
                        transform="translate(-267.778 -132.58)"
                    ></path>
                    <ellipse
                        cx="0.953"
                        cy="1.299"
                        data-name="Ellipse 413"
                        rx="0.953"
                        ry="1.299"
                        transform="translate(12.324)"
                    ></ellipse>
                    <path
                        d="M294.993 35.82c.526 0 .953.582.953 1.3s-.427 1.3-.953 1.3-.953-.582-.953-1.3.427-1.3.953-1.3z"
                        data-name="Path 53257"
                        transform="translate(-284.54 -35.7)"
                    ></path>
                    <path
                        d="M231.851 304.2a1.077 1.077 0 01.451 1.41c-.2.632-.736 1.025-1.189.879a1.077 1.077 0 01-.451-1.41c.204-.629.738-1.022 1.189-.879z"
                        data-name="Path 53258"
                        transform="translate(-223.25 -294.884)"
                    ></path>
                    <path
                        d="M230.474 91.631a1.077 1.077 0 01-.38 1.431c-.445.169-1-.2-1.232-.818a1.077 1.077 0 01.38-1.43c.446-.17.997.196 1.232.817z"
                        data-name="Path 53259"
                        transform="translate(-221.487 -88.775)"
                    ></path>
                    <path
                        d="M69.522 353.251a1.384 1.384 0 01-1.628 2.179l-.4-.2a1.379 1.379 0 00-1.154-.028l-.458.2a1.382 1.382 0 01-1.763-.614l-.029-.055a1.387 1.387 0 01.223-1.622l1.388-1.435a1.629 1.629 0 012.361.018z"
                        data-name="Path 53260"
                        transform="translate(-62.288 -340.285)"
                    ></path>
                    <ellipse
                        cx="0.953"
                        cy="1.299"
                        data-name="Ellipse 414"
                        rx="0.953"
                        ry="1.299"
                        transform="translate(5.055 7.348)"
                    ></ellipse>
                    <path
                        d="M82.233 250.88c.526 0 .953.582.953 1.3s-.427 1.3-.953 1.3-.953-.582-.953-1.3.427-1.3.953-1.3z"
                        data-name="Path 53261"
                        transform="translate(-79.049 -243.412)"
                    ></path>
                    <path
                        d="M17.716 306.691a1.078 1.078 0 01-.38 1.431c-.445.169-1-.2-1.232-.818a1.077 1.077 0 01.38-1.43c.445-.174.997.196 1.232.817z"
                        data-name="Path 53262"
                        transform="translate(-15.998 -296.486)"
                    ></path>
                </g>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M19.4 31.862a1.586 1.586 0 00-.257 1.862l.03.055a1.579 1.579 0 001.392.837 1.6 1.6 0 00.632-.131l.458-.2a1.174 1.174 0 01.982.024l.4.2a1.589 1.589 0 001.868-2.5l-1.457-1.555a1.835 1.835 0 00-2.658-.021zm2.707-1.584a1.43 1.43 0 011.04.451l1.455 1.553a1.179 1.179 0 01-1.386 1.857l-.4-.2a1.583 1.583 0 00-1.325-.033l-.458.2a1.17 1.17 0 01-1.5-.523l-.03-.055a1.178 1.178 0 01.191-1.382l1.388-1.435a1.433 1.433 0 011.027-.434z"
                    data-name="Path 53267"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M20.706 29.449a1.556 1.556 0 10-1.158-1.5 1.367 1.367 0 001.158 1.5zm0-2.6c.405 0 .748.5.748 1.095s-.343 1.094-.748 1.094-.748-.5-.748-1.094.34-1.094.748-1.094z"
                    data-name="Path 53268"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M23.528 29.329a1.367 1.367 0 001.158-1.5 1.2 1.2 0 10-2.316 0 1.367 1.367 0 001.158 1.5zm0-2.6c.405 0 .748.5.748 1.095s-.343 1.094-.748 1.094-.748-.5-.748-1.094.343-1.094.748-1.094z"
                    data-name="Path 53269"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M25.319 30.979a.841.841 0 00.26.041.971.971 0 00.443-.113 1.585 1.585 0 00.745-.939 1.276 1.276 0 00-.583-1.667.906.906 0 00-.7.072 1.584 1.584 0 00-.745.939 1.276 1.276 0 00.58 1.667zm-.193-1.542a1.192 1.192 0 01.541-.7.575.575 0 01.258-.067.428.428 0 01.132.02c.339.11.485.637.319 1.152a1.191 1.191 0 01-.541.7.513.513 0 01-.39.047c-.338-.11-.485-.636-.319-1.151z"
                    data-name="Path 53270"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M18.224 30.968a.986.986 0 00.405.091.843.843 0 00.3-.055 1.276 1.276 0 00.5-1.695 1.584 1.584 0 00-.79-.9.906.906 0 00-.706-.036 1.276 1.276 0 00-.5 1.695 1.584 1.584 0 00.79.9zm-.146-2.212a.432.432 0 01.154-.028.588.588 0 01.238.055 1.191 1.191 0 01.575.672c.191.506.072 1.04-.261 1.166a.513.513 0 01-.392-.027 1.191 1.191 0 01-.575-.672c-.191-.506-.072-1.04.261-1.166z"
                    data-name="Path 53271"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M12.13 39.209a1.586 1.586 0 00-.257 1.862l.03.055a1.578 1.578 0 002.024.705l.458-.2a1.174 1.174 0 01.982.024l.4.2a1.589 1.589 0 001.868-2.5L16.178 37.8a1.835 1.835 0 00-2.658-.021zm2.707-1.58a1.431 1.431 0 011.04.451l1.455 1.553a1.179 1.179 0 01-1.386 1.857l-.4-.2a1.584 1.584 0 00-1.325-.033l-.458.2a1.17 1.17 0 01-1.5-.523l-.03-.055a1.178 1.178 0 01.191-1.382l1.388-1.435a1.433 1.433 0 011.025-.433z"
                    data-name="Path 53272"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M13.434 36.797a1.556 1.556 0 10-1.158-1.5 1.367 1.367 0 001.158 1.5zm0-2.6c.405 0 .748.5.748 1.095s-.343 1.095-.748 1.095-.748-.5-.748-1.095.343-1.094.748-1.094z"
                    data-name="Path 53273"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M16.258 36.677a1.556 1.556 0 10-1.158-1.5 1.367 1.367 0 001.158 1.5zm0-2.6c.405 0 .748.5.748 1.095s-.342 1.094-.748 1.094-.748-.5-.748-1.094.343-1.094.748-1.094z"
                    data-name="Path 53274"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M19.497 37.318a1.276 1.276 0 00-.583-1.668.906.906 0 00-.7.072 1.584 1.584 0 00-.745.939 1.276 1.276 0 00.583 1.668.842.842 0 00.26.041.971.971 0 00.443-.113 1.584 1.584 0 00.742-.939zm-1.321.621c-.339-.11-.485-.637-.319-1.152a1.191 1.191 0 01.541-.7.574.574 0 01.258-.067.428.428 0 01.132.021c.339.11.485.637.319 1.152a1.192 1.192 0 01-.541.7.513.513 0 01-.39.045z"
                    data-name="Path 53275"
                ></path>
                <path
                    className="pettransportSvgprefix__c"
                    strokeWidth="0.4"
                    d="M10.955 38.316a.986.986 0 00.405.091.842.842 0 00.3-.055 1.276 1.276 0 00.5-1.695 1.584 1.584 0 00-.79-.9.906.906 0 00-.706-.036 1.276 1.276 0 00-.5 1.695 1.584 1.584 0 00.791.9zm-.146-2.212a.432.432 0 01.154-.028.587.587 0 01.237.055 1.191 1.191 0 01.575.672c.191.506.072 1.04-.261 1.166a.514.514 0 01-.392-.027 1.191 1.191 0 01-.575-.672c-.191-.506-.071-1.04.262-1.166z"
                    data-name="Path 53276"
                ></path>
            </g>
        </svg>
    );
}

export default SvgComponent;
