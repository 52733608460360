import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  let slug: any = localStorage.getItem('slug');
  if (slug) {
      slug = JSON.parse(slug);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28.546}
      height={36.541}
      viewBox="0 0 28.546 36.541"
      {...props}
    >
      <defs>
        <style>
        {slug && slug.color_shade1 && slug.color_shade2 && slug.color_shade3 && slug.color_shade4 && slug.primary_color
            ? `.signupUtility_prefix__c{fill:${slug.color_shade3}}.signupUtility_prefix__a{fill:${slug.color_shade1}}`
            : ".signupUtility_prefix__c{fill:#273e59}.signupUtility_prefix__c{fill:#5b7088}"
          }
        </style>
      </defs>
      <path
        d="M28.011 22.268a13.738 13.738 0 01-27.476 0C.535 10.574 14.273.535 14.273.535s13.738 10.039 13.738 21.733zm0 0"
        fill="#fff"
      />
      <path
        d="M18.66 11.537h-5.937L7.99 20.199l5.376-.008-3.216 7.741 10.623-10.559h-5.992zm0 0"
        // fill="#5b7088"
        className="signupUtility_prefix__a"
      />
      <path
        className="signupUtility_prefix__c"
        d="M24.466 25.466a14.17 14.17 0 00.46-1.508.535.535 0 10-1.04-.255 13.2 13.2 0 01-.425 1.394.535.535 0 001.005.369zm0 0M19.738 31.885a14.394 14.394 0 004.141-5.047.535.535 0 10-.961-.472 13.318 13.318 0 01-3.832 4.67.535.535 0 10.651.849zm0 0M10.521 28.31l10.623-10.559a.536.536 0 00-.377-.915h-4.994l3.327-5a.536.536 0 00-.446-.832h-5.933a.534.534 0 00-.47.279l-2.5 4.565a.535.535 0 10.939.513l2.344-4.287h4.621l-3.327 5a.535.535 0 00.446.832h4.694l-7.8 7.757 2.189-5.268a.535.535 0 00-.494-.741l-4.472.007 1.816-3.323a.535.535 0 00-.94-.513l-2.249 4.116a.535.535 0 00.47.792l4.572-.007-2.908 7a.536.536 0 00.869.584zm0 0"
      />
      <path
        className="signupUtility_prefix__c"
        d="M25.654 12.405a.535.535 0 00-.931.528 19.04 19.04 0 012.754 9.335 13.203 13.203 0 01-26.405 0 18.2 18.2 0 012.085-8.08 33.131 33.131 0 014.639-6.8 47.476 47.476 0 016.479-6.176 66.986 66.986 0 0110.451 11.717.535.535 0 10.893-.591A70.8 70.8 0 0014.589.099a.535.535 0 00-.631 0A46.523 46.523 0 007 6.666a34.251 34.251 0 00-4.8 7.033A19.259 19.259 0 000 22.268a14.273 14.273 0 0028.546 0 20.088 20.088 0 00-2.892-9.863zm0 0"
      />
    </svg>
  )
}

export default SvgComponent
