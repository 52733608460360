import React from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonNew from '../atoms/Button';
import './dashboardCss/pendingTaskCard.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import TimerIcon from '@material-ui/icons/Timer';
import MaskGroup from '../../Assets/images/DashBoard/Mask Group 24.svg';
import Storage from '../../Assets/images/DashBoard/storage.svg';
import MovingCar from '../../Assets/images/Moving/MovingCar.svg';
import Utilities from '../../Assets/images/DashBoard/utilities.svg';
import HomeRepair from '../../Assets/images/DashBoard/homeRepair.svg';
import ChangeAddress from '../../Assets/images/DashBoard/changeAddress.svg';
import Providers from '../../Assets/images/DashBoard/providers.svg';
import Moving from '../../Assets/images/DashBoard/Moving.svg';

import ProvidersD from '../../Assets/images/DouglasImages/dashboardIcons/internetD.png';
import changeAddressD from '../../Assets/images/DouglasImages/dashboardIcons/changeAddressD.png';
import homeRepairD from '../../Assets/images/DouglasImages/dashboardIcons/homeRepairD.png';
import insuranceD from '../../Assets/images/DouglasImages/dashboardIcons/insuranceD.png';
import movingD from '../../Assets/images/DouglasImages/dashboardIcons/movingD.png';
import storageD from '../../Assets/images/DouglasImages/dashboardIcons/storageD.png';
import utilityD from '../../Assets/images/DouglasImages/dashboardIcons/utilityD.png';

type iProps = {
    className?: string;
    data?: any;
    border?: string;
    width?: string;
    height?: string;
    padding?: string;
    imageHeight?: string;
    imageWidth?: string;
    onClick?: any;
    isDouglasElliman?: boolean;
};

const PendingTasksCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                '& $checkroot': {
                    color: '#333333 !important',
                },
                boxShadow: '0 0 10px #e4e6e8 !important',
                // border:"1px solid #DADCE0"
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    const { isDouglasElliman } = props;
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} pendingTaskCardMain`}
            style={{
                border: props.border ? props.border : '1px solid #DADCE0',
                width: props.width ? props.width : '100%',
                height: props.height ? props.height : '100%',
                padding: props.padding ? props.padding : '0px',
            }}
        >
            <div className={isDouglasElliman ? 'pendingTaskImageDivD' : 'pendingTaskImageDiv'}>
                {props.data && props.data.url.includes('/dashboard/internet') ? (
                    <img
                        src={isDouglasElliman ? ProvidersD : Providers}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/utilities') ? (
                    <img
                        src={isDouglasElliman ? utilityD : Utilities}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/autoease') ? (
                    <img
                        src={isDouglasElliman ? movingD : MovingCar}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/address') ? (
                    <img
                        src={isDouglasElliman ? changeAddressD : ChangeAddress}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/ourvendors') ? (
                    <img
                        src={isDouglasElliman ? homeRepairD : HomeRepair}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/storage') ? (
                    <img
                        src={isDouglasElliman ? storageD : Storage}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/home-insurance') ? (
                    <img
                        src={isDouglasElliman ? insuranceD : MaskGroup}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : props.data && props.data.url.includes('/dashboard/movers') ? (
                    <img
                        src={isDouglasElliman ? movingD : Moving}
                        height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                        width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                        alt=""
                    />
                ) : (
                    <div
                        className="svg-image"
                        dangerouslySetInnerHTML={{
                            __html: props.data.image,
                        }}
                    />
                )}
                {/* {props.data.image && props.data.image.includes('<svg') ? (
                   
                ) : (
                    <img
                        src={props.data.image}
                        height={props.imageHeight ? props.imageHeight : '100%'}
                        width={props.imageWidth ? props.imageWidth : '100%'}
                    />
                )} */}
            </div>
            <div className="pendingTaskContentDiv">
                <div>
                    <div className="pendingTitle">{props.data.title}</div>
                    <div className="pendingSubtitle">{props.data.subTitle}</div>
                </div>

                {props.data.taskProgressValue ? (
                    <div className="progress">
                        <div className="progressDiv">
                            <div className="progressBarDiv">
                                <LinearProgress
                                    className=""
                                    variant="determinate"
                                    value={props.data.taskProgressValue ? props.data.taskProgressValue : 2}
                                    style={{
                                        backgroundColor: '#E0E0E0',
                                        borderRadius: '15px',
                                        height: '7px',
                                    }}
                                />
                            </div>
                            <div className="completedPercent">{`${props.data.taskProgressValue}% completed`}</div>
                        </div>
                        <div className="timer">
                            <TimerIcon className="timerIcon" />
                            {`${props.data.taskCompletionTime} task`}
                        </div>
                    </div>
                ) : null}
                <div className="pendingButtonDiv">
                    {/* <ButtonNew className="pendingCancelButton">{props.data.btnCancel}</ButtonNew> */}
                    <ButtonNew
                        className={isDouglasElliman ? 'pendingCompleteTaskButtonD' : 'pendingCompleteTaskButton'}
                        onClick={props.onClick}
                    >
                        {props.data.btnCompleteTask}
                    </ButtonNew>
                </div>
            </div>
        </div>
    );
};

export default PendingTasksCard;
