import React, { Component } from 'react';
import './HomeWarantyThanku.scss';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import CloseIcon from '@material-ui/icons/Close';
// import Checkbox from '@material-ui/core/Checkbox';
import HomeWarrantyTableCard from './HomeWarantyTableCard';
import * as CommonAction from '../../store/common/actions';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import Checkmark from 'react-typescript-checkmark';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import Checkmark from 'react-typescript-checkmark';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Skeleton from 'react-loading-skeleton';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import ButtonNew from '../../components/atoms/Button';
import { CircularProgress } from '@material-ui/core';
import { updateChecklistTask } from '../../utils/common-functions';
import * as DashboardAction from '../../store/dashboard/actions';

type initialProps = {
    history?: any;
    commonState?: any;
    commonAction?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
    dashboardAction: any;
};

type initialState = {
    slug: any;
    submit: boolean;
    // tableData: any;
    thanku: any;
    selectedPlan: any;
    optionalCoverageTotal: any;
    tradeServiceFee: any;
    myselfEmail: string;
    closingAgentEmail: string;
    realEstateAgentEmail: string;
    policyNumber: string;
    date: string;
    thankyou: boolean;
    optionalCoverages: any;
    showOptionalCoverages: boolean;
    taxAmount: string;
    totalAmount: string;
    loader: boolean;
    tableData: any;
    clickAddAdditional: boolean;
    otherEmail: string;
    isAddEmailClicked: boolean;
    address: string;
    showSummary: boolean;
    showLoader: boolean;
};

class HomeWarantyThanku extends Component<initialProps, initialState> {
    state: initialState = {
        slug: { primary_color: '' },
        submit: false,
        thanku: false,
        selectedPlan: null,
        optionalCoverageTotal: 0,
        tradeServiceFee: 100,
        myselfEmail: '',
        closingAgentEmail: '',
        realEstateAgentEmail: '',
        policyNumber: '',
        date: '',
        thankyou: true,
        optionalCoverages: [],
        showOptionalCoverages: false,
        taxAmount: '',
        totalAmount: '',
        loader: true,
        tableData: [
            {
                id: 1,
                confirmation: true,
                invoice: true,
                contract: false,
                myself: 'Myself',
                email: '',
                header: false,
            },
            {
                id: 2,
                confirmation: true,
                invoice: false,
                contract: false,
                myself: 'Real Estate Agent',
                email: '',
                header: false,
            },
            {
                id: 3,
                confirmation: true,
                invoice: false,
                contract: false,
                myself: 'Closing Agent',
                email: '',
                header: false,
            },
        ],
        clickAddAdditional: false,
        otherEmail: '',
        isAddEmailClicked: false,
        address: '',
        showSummary: false,
        showLoader: false,
    };

    componentDidMount() {
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            let optionalCoverageTotal: any = 0;
            let optionalCoverages: any = [];
            let optionalCoveragesIds: any = [];
            this.props.history.location.state.optionalCoverages &&
                this.props.history.location.state.optionalCoverages.map((item: any) => {
                    if (item.isSelected) {
                        optionalCoverageTotal = optionalCoverageTotal + item.coveragePrice;
                        optionalCoverages.push(item);
                        optionalCoveragesIds.push(`${item.coverageId}`);
                    }
                });
            this.setState({
                selectedPlan: this.props.history.location.state.planSelected,
                optionalCoverageTotal: optionalCoverageTotal,
                optionalCoverages: optionalCoverages,
            });

            let payload = {
                product_id:
                    this.props.history.location.state.planSelected && this.props.history.location.state.planSelected.id,
                tenant_id:
                    this.props.history.location.state.planSelected &&
                    this.props.history.location.state.planSelected.tenantId,
                selected_optional_coverages: optionalCoveragesIds,
            };

            this.props.homeWarrantyAction.getPlanTax(payload);
        }
        if (
            this.props &&
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move &&
            this.props.commonState.move[0]
        ) {
            let tableData = this.state.tableData;
            tableData.map((item: any) => {
                if (item.myself == 'Myself') {
                    item.email =
                        this.props.commonState.move[0] &&
                        this.props.commonState.move[0].user_ref &&
                        this.props.commonState.move[0].user_ref.email;
                }
                if (item.myself == 'Real Estate Agent') {
                    item.email =
                        this.props.commonState.move[0] &&
                        this.props.commonState.move[0].realtor &&
                        this.props.commonState.move[0].realtor.email;
                }
            });
            this.setState({
                myselfEmail:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].user_ref &&
                    this.props.commonState.move[0].user_ref.email,
                // closingAgentEmail:
                //     this.props.commonState.move[0] &&
                //     this.props.commonState.move[0].property &&
                //     this.props.commonState.move[0].property.closing_agent_email,
                realEstateAgentEmail:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].realtor &&
                    this.props.commonState.move[0].realtor.email,
                tableData: tableData,
                address: this.props.commonState.move[0] && this.props.commonState.move[0].destination_full_address,
            });
        }

        if (this.props && this.props.homeWarrantyState && this.props.homeWarrantyState.getHomeWarrantyDetails) {
            // this.props.homeWarrantyAction.getHomeWarrantyDetails();
            let tableData = this.state.tableData;
            tableData.map((item: any) => {
                if (item.myself == 'Closing Agent') {
                    item.email =
                        this.props.homeWarrantyState.getHomeWarrantyDetails &&
                        this.props.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                            ? this.props.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                            : '';
                }
            });
            this.setState({
                closingAgentEmail:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                        : '',
                policyNumber:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number
                        : '',
                date:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.submission_date
                        ? this.props.homeWarrantyState.getHomeWarrantyDetails.submission_date
                        : '',
                thankyou:
                    this.props.homeWarrantyState.getHomeWarrantyDetails &&
                    this.props.homeWarrantyState.getHomeWarrantyDetails.policy_number
                        ? false
                        : true,
                tableData: tableData,
            });
        } else {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        let prev = prevProps.commonState;
        let cur = this.props.commonState;

        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            let tableData = this.state.tableData;
            tableData.map((item: any) => {
                if (item.myself == 'Myself') {
                    item.email = cur.move[0] && cur.move[0].user_ref && cur.move[0].user_ref.email;
                }
                if (item.myself == 'Real Estate Agent') {
                    item.email = cur.move[0] && cur.move[0].realtor && cur.move[0].realtor.email;
                }
            });
            this.setState({
                myselfEmail: cur.move[0] && cur.move[0].user_ref && cur.move[0].user_ref.email,
                // closingAgentEmail: cur.move[0] && cur.move[0].property && cur.move[0].property.closing_agent_email,
                realEstateAgentEmail: cur.move[0] && cur.move[0].realtor && cur.move[0].realtor.email,
                tableData: tableData,
                address: cur.move[0] && cur.move[0].destination_full_address,
            });
        }

        let prevW = prevProps.homeWarrantyState;
        let curW = this.props.homeWarrantyState;

        if (prevW.postOrderWarranty !== curW.postOrderWarranty && curW.postOrderWarranty && curW.isPostOrderWarranty) {
            curW.isPostOrderWarranty = false;
            this.setState({
                thankyou: false,
                showLoader: false,
                submit: false,
                showSummary: true,
            });
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
        } else if (prevW.error != curW.error && curW.error != '' && curW.error != null) {
            this.setState({
                showLoader: false,
            });
        }

        if (prevW.getHomeWarrantyDetails !== curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails) {
            let tableData = this.state.tableData;
            tableData.map((item: any) => {
                if (item.myself == 'Closing Agent') {
                    item.email =
                        curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails.closing_agent_email
                            ? curW.getHomeWarrantyDetails.closing_agent_email
                            : '';
                }
            });
            this.setState({
                closingAgentEmail:
                    curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails.closing_agent_email
                        ? curW.getHomeWarrantyDetails.closing_agent_email
                        : '',
                policyNumber:
                    curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails.policy_number
                        ? curW.getHomeWarrantyDetails.policy_number
                        : '',
                date:
                    curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails.submission_date
                        ? curW.getHomeWarrantyDetails.submission_date
                        : '',
                thankyou: curW.getHomeWarrantyDetails && curW.getHomeWarrantyDetails.policy_number ? false : true,
                tableData: tableData,
            });
        }

        if (prevW.getPlanTax !== curW.getPlanTax && curW.getPlanTax && curW.isGetPlanTax) {
            curW.isGetPlanTax = false;
            this.setState({
                taxAmount: curW.getPlanTax.price && curW.getPlanTax.price.tax,
                totalAmount: curW.getPlanTax.price && curW.getPlanTax.price.totalWithTax,
                loader: false,
            });
        }
    }
    handleCancel = () => {
        this.props.history.push('/dashboard/home-warranty');
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Thanku Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Cancel Order',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    handleSubmit = () => {
        this.setState({
            submit: true,
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Thanku Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Submit Order',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: false,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }
    };
    handleSubmitForm = () => {
        this.setState({ showLoader: true });
        let optionalcoverage: any = [];
        this.state.optionalCoverages &&
            this.state.optionalCoverages.map((item: any) => {
                if (item.isSelected) {
                    optionalcoverage.push(item.coverageId);
                }
            });
        let mailingList: any = [];
        this.state.tableData.map((item: any) => {
            if (item.email && item.email != '') {
                let emailType: any = [];
                if (item.confirmation) {
                    emailType.push('CONFIRMATION');
                }
                if (item.invoice) {
                    emailType.push('INVOICE');
                }
                if (emailType.length) {
                    mailingList.push({ email_id: item.email, email_types: emailType });
                }
            }
        });
        let payload: any = {
            product_id: this.state.selectedPlan && this.state.selectedPlan.id,
            tenant_id: this.state.selectedPlan && this.state.selectedPlan.tenantId,
            selected_optional_coverages: optionalcoverage,
            mailing_list: mailingList,
        };

        this.props.homeWarrantyAction.postOrderWarranty(payload);
        // this.setState({
        //     thankyou: false,
        // });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Thanku Page',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Send Documents',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: true,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );
        }

        updateChecklistTask(100, this.props.dashboardAction);
    };

    handleBackDashBoard = () => {
        this.props.history.push('/dashboard/home-warranty/home');
    };

    onConfirmationClick = (index: number) => {
        let tableData = this.state.tableData;
        let tempObject = tableData[index];
        if (tempObject.myself != 'Myself') {
            tempObject.confirmation = !tempObject.confirmation;
        }
        tableData[index] = tempObject;

        this.setState({ tableData: tableData });
    };
    onInvoiceClick = (index: number) => {
        let tableData = this.state.tableData;
        let tempObject = tableData[index];
        if (tempObject.myself != 'Myself') {
            tempObject.invoice = !tempObject.invoice;
        }

        tableData[index] = tempObject;
        this.setState({ tableData: tableData });
    };

    addAdditionalEmail = () => {
        let tableData: any = this.state.tableData;
        this.setState({ isAddEmailClicked: true });
        if (this.state.otherEmail.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')) {
            tableData.push({
                id: tableData.length + 1,
                confirmation: true,
                invoice: false,
                contract: false,
                myself: 'Others',
                email: this.state.otherEmail,
                header: false,
            });

            this.setState({ tableData: tableData, otherEmail: '', clickAddAdditional: false });
        }
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }

        let tableData: any = this.state.tableData;
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div>
                <div className="waranty-thankyou-main ">
                    <div className="waranty-thanku-submain">
                        {this.state.thankyou ? (
                            <div className="testing">
                                <div className="waranty-thanku-Heading-div ">
                                    <div className=" heading-main ">
                                        Thank you for your interest in our home warranty{' '}
                                    </div>
                                    <div className="heading-small">Here is your quote based on your selection</div>
                                </div>
                                <div className="waranty-thanku-content">
                                    <div className="waranty-thanku-subcontent">
                                        <div className="quote-heading">Quote summary</div>
                                        <div className="border-thanku"></div>
                                        {/* <div className="inside-modal-content-main-first"> */}
                                        {/* <div className="inside-content-first-row">
                                                <div className="inside-modal-content">
                                                    {this.state.selectedPlan &&
                                                        this.state.selectedPlan.plan &&
                                                        this.state.selectedPlan.plan.description}
                                                </div>
                                                <div className="inside-modal-content">
                                                    {this.state.selectedPlan &&
                                                    this.state.selectedPlan.plan &&
                                                    this.state.selectedPlan.plan.basePriceWithoutTax
                                                        ? `$${this.state.selectedPlan.plan.basePriceWithoutTax}`
                                                        : ''}
                                                </div>
                                            </div> */}
                                        {/* <div className="inside-modal-content">
                                                Seller’s Coverage Effective: 09/13/2019 - 08/23/2019
                                            </div> */}
                                        {/* </div> */}
                                        {/* <div className="border-thanku"></div> */}
                                        <div className="inside-modal-content-main">
                                            <div className="inside-modal-content">
                                                {this.state.selectedPlan && this.state.selectedPlan.description}
                                            </div>
                                            {this.state.loader ? (
                                                <Skeleton height="30px" width="100%" />
                                            ) : (
                                                <div className="inside-modal-content">{`$${this.state.selectedPlan &&
                                                    this.state.selectedPlan.basePriceWithoutTax}`}</div>
                                            )}
                                        </div>

                                        <div className="border-thanku"></div>
                                        <div className="inside-modal-content-main">
                                            <div
                                                className="inside-modal-content"
                                                onClick={() => {
                                                    this.setState({
                                                        showOptionalCoverages: !this.state.showOptionalCoverages,
                                                    });
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {!this.state.showOptionalCoverages ? (
                                                    <ChevronRightIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}{' '}
                                                Optional Coverage Charges(
                                                {this.state.optionalCoverages.length})
                                            </div>
                                            {this.state.loader ? (
                                                <Skeleton height="30px" width="100%" />
                                            ) : (
                                                <div className="inside-modal-content">{`$${this.state.optionalCoverageTotal}`}</div>
                                            )}
                                        </div>
                                        {this.state.showOptionalCoverages &&
                                            this.state.optionalCoverages.map((item: any, i: number) => {
                                                return (
                                                    <div key={i} className="inside-modal-content-main">
                                                        <div className="inside-modal-content">{item.description}</div>
                                                        {this.state.loader ? (
                                                            <Skeleton height="30px" width="100%" />
                                                        ) : (
                                                            <div className="inside-modal-content">{`$${item.coveragePrice}`}</div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {/* <div className="border-thanku"></div>
                                    <div className="inside-modal-content-main">
                                        <div className="inside-modal-content">Total Options</div>
                                        <div className="inside-modal-content">$501.00</div>
                                    </div> */}
                                        <div className="border-thanku"></div>
                                        {/* <div className="inside-modal-content-main">
                                            <div className="inside-modal-content">Trade Service Fee</div>
                                            <div className="inside-modal-content">{`$${this.state.tradeServiceFee}`}</div>
                                        </div> */}
                                        <div className="border-thanku"></div>
                                        <div className="inside-modal-content-main">
                                            <div className="inside-modal-content">Tax</div>
                                            {this.state.loader ? (
                                                <Skeleton height="30px" width="100%" />
                                            ) : (
                                                <div className="inside-modal-content">${this.state.taxAmount}</div>
                                            )}
                                        </div>
                                        <div className="border-thanku"></div>
                                        <div className="inside-modal-content-main">
                                            <div className="inside-modal-content">Due at Closing</div>
                                            {this.state.loader ? (
                                                <Skeleton height="30px" width="100%" />
                                            ) : (
                                                <div className="inside-modal-content">${this.state.totalAmount}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="waranty-button">
                                    <Button
                                        className="thankuWaranty"
                                        onClick={this.handleCancel}
                                        backgroundColor="#FFFFFF"
                                        boxShadow={`0 0 3px 0 ${
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : '#273E59'
                                        }`}
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : '#273E59'
                                        }
                                        borderRadius="5px"
                                        fontSize="16px"
                                        margin="0 15px 0 0"
                                    >
                                        Cancel Order
                                    </Button>
                                    <Button
                                        className="thankuWaranty"
                                        onClick={this.handleSubmit}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : '#273E59'
                                        }
                                        color={'#fff'}
                                        borderRadius="4px"
                                    >
                                        Submit Order
                                    </Button>
                                </div>
                            </div>
                        ) : this.state.showSummary ? (
                            <div className="testing">
                                <div className="waranty-thanku-Heading-div ">
                                    <div className=" heading-main ">Confirmation </div>
                                    <div className="heading-small">
                                        Here’s your date of submission and policy number
                                    </div>
                                </div>
                                <div className="warantyThankuPage">
                                    {this.state.date ? (
                                        <div className="inside-modal-content-main">
                                            <div className="inside-modal-content">Date of submission</div>
                                            <div className="inside-modal-content">{this.state.date}</div>
                                        </div>
                                    ) : null}
                                    <div className="border-thanku"></div>
                                    <div className="inside-modal-content-main">
                                        <div className="inside-modal-content">Policy Number</div>
                                        <div className="inside-modal-content">{this.state.policyNumber}</div>
                                    </div>
                                </div>
                                <div className="waranty-thanku-contact-main">
                                    <div className="waranty-thanku-rightnow">
                                        Right now, you don’t have to do anything
                                    </div>
                                    <div className="waranty-thanku-payment">
                                        The home warranty payment will be included in your closing process if you shared
                                        it with your closing/title agent. If you have any questions or need to manage
                                        your policy, please call the customer service number.
                                    </div>
                                    <div className="waranty-thanku-contact">
                                        <span>
                                            {' '}
                                            <WhatsAppIcon className="checkit" />
                                        </span>{' '}
                                        <span>800-735-4663</span>
                                    </div>
                                </div>
                                <div className="waranty-thanku-home">
                                    <Button
                                        className="thankuWaranty"
                                        onClick={this.handleBackDashBoard}
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : '#273E59'
                                        }
                                        color={'#fff'}
                                        borderRadius="4px"
                                    >
                                        Back to Home
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.submit ? (
                    <Modal
                        isShowModal={this.state.submit}
                        onCloseModal={() => {
                            this.setState({ submit: false });
                        }}
                        showClose={false}
                        className="modalHomeWaranty"
                    >
                        <div>
                            {this.state.showLoader ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CircularProgress />
                                    Processing your request please wait
                                </div>
                            ) : (
                                <div>
                                    <div className="CloseIcon">
                                        <div
                                            style={{
                                                color: '#333333',
                                                fontSize: '18px',
                                                fontWeight: 500,
                                                textAlign: 'center',
                                                margin: 'auto',
                                            }}
                                        >
                                            Confirmation
                                        </div>
                                        <div className="closeIconWaranty">
                                            <CloseIcon
                                                onClick={() => {
                                                    this.setState({ submit: false });
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="subHeadingWarantyModal">
                                        Once an order is placed, you will select where you want the order confirmation
                                        and escrow invoice emails to be sent to
                                    </div>
                                    <div className="sendDocuments">Send Documents</div>
                                    <div className="sendAddress sendAddressRight">
                                        {/* 876 Longwood Boulevard, Chattanooga, TN 38923 */}
                                        {this.state.address}
                                    </div>

                                    <div className="closeDocument">
                                        <div className="closeDocumentInner">
                                            Choose documents and intended recipients
                                        </div>
                                        <div className="sendAddress">Choose which documents to send the recipients</div>
                                    </div>
                                    <div className=" tableSetting">
                                        <HomeWarrantyTableCard
                                            head1="Confirmation"
                                            head2="Invoice"
                                            // head3="Contract"
                                            header={true}
                                        />
                                        {tableData &&
                                            tableData.map((item: any, index: number) => {
                                                if (item.email) {
                                                    return (
                                                        <HomeWarrantyTableCard
                                                            key={index}
                                                            confirmation={item.confirmation}
                                                            invoice={item.invoice}
                                                            // contract={item.contract}
                                                            myself={item.myself}
                                                            email={item.email}
                                                            header={item.header}
                                                            id={index}
                                                            onConfirmationClick={this.onConfirmationClick}
                                                            onInvoiceClick={this.onInvoiceClick}
                                                        />
                                                    );
                                                }
                                            })}
                                    </div>
                                    {this.state.clickAddAdditional ? (
                                        <div className="additionalInputButtonDiv">
                                            <div className="additionalInputDiv">
                                                <CustomizedInput
                                                    value={this.state.otherEmail}
                                                    onChange={(e: any) => {
                                                        this.setState({
                                                            otherEmail: e.target.value,
                                                            isAddEmailClicked: false,
                                                        });
                                                    }}
                                                    placeholder="Enter additional email ID"
                                                    labelName="Enter additional email ID"
                                                />
                                                <div className="error" style={{ color: 'red' }}>
                                                    {this.state.otherEmail == '' && this.state.isAddEmailClicked
                                                        ? 'Enter email id'
                                                        : this.state.isAddEmailClicked &&
                                                          !this.state.otherEmail.match(
                                                              '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$',
                                                          )
                                                        ? 'Enter valid email'
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="ButtonDiv">
                                                <ButtonNew
                                                    className="addButton"
                                                    backgroundColor="#ffffff"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug && slug.primary_color
                                                            : '#273E59'
                                                    }
                                                    border={
                                                        slug && slug.primary_color
                                                            ? `1px solid ${slug && slug.primary_color}`
                                                            : '1px solid #273E59'
                                                    }
                                                    onClick={this.addAdditionalEmail}
                                                >
                                                    Add
                                                </ButtonNew>
                                                <ButtonNew
                                                    className="cancleButton"
                                                    backgroundColor="#ffffff"
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug && slug.primary_color
                                                            : '#273E59'
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            clickAddAdditional: false,
                                                            otherEmail: '',
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </ButtonNew>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div
                                        className="additionCardsAdd"
                                        onClick={() => {
                                            this.setState({ clickAddAdditional: true });
                                        }}
                                    >
                                        + Add an additional recipient
                                    </div>
                                    <div className="waranty-button">
                                        <Button
                                            className="thankuWaranty"
                                            onClick={this.handleCancel}
                                            backgroundColor="#FFFFFF"
                                            boxShadow={`0 0 3px 0 ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }`}
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 15px 0 0"
                                        >
                                            Cancel Order
                                        </Button>
                                        <Button
                                            className="thankuWaranty"
                                            onClick={this.handleSubmitForm}
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            color={'#fff'}
                                            borderRadius="4px"
                                        >
                                            Send Documents
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal>
                ) : null}
                {/* {this.state.thanku ? (
                    <Modal
                        isShowModal={this.state.thanku}
                        onCloseModal={() => {
                            this.setState({ thanku: false });
                        }}
                        showClose={false}
                        className="modalClassesSecureWaranty"
                    >
                        <div>
                            <div className="iconDivWaranty">
                                <div className="keyboard">
                                    <Checkmark
                                        size="xxl"
                                        backgroundColor="green"
                                        animationDuration={0.8}
                                        explosion={1.2}
                                    />
                                </div>
                            </div>
                            <div className="HeadingWarantyModalThanku">
                                If you sent the confirmation and invoice to the Closing agent, they will include this in
                                escrow during closing
                            </div>
                            <div className="subHeadingWarantyModalThanku">
                                If you are going to pay outside of the closing process, please call this number
                                800-XXX-XXXX, to complete the purchase and have your Policy number available
                            </div>
                            <div className="modalTableThanku">
                                <div className="modalTableSubContentThanku">
                                    <div className="contentTable">Date of submission</div>
                                    <div className="contentTable">{this.state.date}</div>
                                </div>
                                <div className="borderLineThanku"></div>
                                <div className="modalTableSubContentThanku">
                                    <div className="contentTable">Policy Number</div>
                                    <div className="contentTable">{this.state.policyNumber}</div>
                                </div>
                            </div>
                            <div className="buttonThanku">
                                <Button
                                    className="thankuWaranty"
                                    onClick={this.handleBackDashBoard}
                                    backgroundColor={
                                        this.state.slug && this.state.slug.primary_color
                                            ? this.state.slug.primary_color
                                            : '#273E59'
                                    }
                                    borderRadius="4px"
                                >
                                    Back to Home
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null} */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarantyThanku);
