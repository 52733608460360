import {
    POST_FOLDERS,
    POST_FOLDERS_ERROR,
    POST_FOLDERS_SUCCESS,
    GET_FOLDERS,
    GET_FOLDERS_ERROR,
    GET_FOLDERS_SUCCESS,
    POST_DOCUMENTS,
    POST_DOCUMENTS_ERROR,
    POST_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS,
    GET_DOCUMENTS_ERROR,
    GET_DOCUMENTS_SUCCESS,
    UPDATE_DOCUMENTS,
    UPDATE_DOCUMENTS_ERROR,
    UPDATE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS,
    DELETE_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_SUCCESS,
} from './actionTypes';

import { DocumentryActions, DocumentryState } from './types';
const initialState: DocumentryState = {
    postFolders: [],
    isPostFolders: false,
    getFolders: [],
    isGetFolders: false,
    error: '',
    postDocuments: [],
    isPostDocuments: false,
    getDocuments: [],
    isGetDocuments: false,
    updateDocuments: [],
    isUpdateDocuments: false,
    deleteDocuments: [],
    isDeleteDocuments: false,
};
export default (state = initialState, action: DocumentryActions) => {
    switch (action.type) {
        case POST_FOLDERS:
            return {
                ...state,
                postFolders: [],
                isPostFolders: false,
                error: null,
            };
        case POST_FOLDERS_SUCCESS:
            return {
                ...state,
                postFolders: action.payload.postFolders,
                isPostFolders: true,
                error: null,
            };
        case POST_FOLDERS_ERROR:
            return {
                ...state,
                postFolders: [],
                isPostFolders: false,
                error: action.payload.error,
            };
        case GET_FOLDERS:
            return {
                ...state,
                getFolders: [],
                isGetFolders: false,
                error: null,
            };
        case GET_FOLDERS_SUCCESS:
            return {
                ...state,
                getFolders: action.payload.getFolders,
                isGetFolders: true,
                error: null,
            };
        case GET_FOLDERS_ERROR:
            return {
                ...state,
                getFolders: [],
                isGetFolders: false,
                error: action.payload.error,
            };
        case POST_DOCUMENTS:
            return {
                ...state,
                postDocuments: [],
                isPostDocuments: false,
                error: null,
            };
        case POST_DOCUMENTS_SUCCESS:
            return {
                ...state,
                postDocuments: action.payload.postDocuments,
                isPostDocuments: true,
                error: null,
            };
        case POST_DOCUMENTS_ERROR:
            return {
                ...state,
                postDocuments: [],
                isPostDocuments: false,
                error: action.payload.error,
            };
        case GET_DOCUMENTS:
            return {
                ...state,
                getDocuments: [],
                isGetDocuments: false,
                error: null,
            };
        case GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                getDocuments: action.payload.getDocuments,
                isGetDocuments: true,
                error: null,
            };
        case GET_DOCUMENTS_ERROR:
            return {
                ...state,
                getDocuments: [],
                isGetDocuments: false,
                error: action.payload.error,
            };
        case UPDATE_DOCUMENTS:
            return {
                ...state,
                updateDocuments: [],
                isUpdateDocuments: false,
                error: null,
            };
        case UPDATE_DOCUMENTS_SUCCESS:
            return {
                ...state,
                updateDocuments: action.payload.updateDocuments,
                isUpdateDocuments: true,
                error: null,
            };
        case UPDATE_DOCUMENTS_ERROR:
            return {
                ...state,
                updateDocuments: [],
                isUpdateDocuments: false,
                error: action.payload.error,
            };
        case DELETE_DOCUMENTS:
            return {
                ...state,
                deleteDocuments: [],
                isDeleteDocuments: false,
                error: null,
            };
        case DELETE_DOCUMENTS_SUCCESS:
            return {
                ...state,
                deleteDocuments: action.payload.deleteDocuments,
                isDeleteDocuments: true,
                error: null,
            };
        case DELETE_DOCUMENTS_ERROR:
            return {
                ...state,
                deleteDocuments: [],
                isDeleteDocuments: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
