import React from 'react';
import ServicePreferenceCard from '../../components/atoms/PreMove/ServicePreferenceCard';
import FullService from '../../Assets/images/PreMove/FullService.svg';
import UtilityBundle from '../../Assets/images/PreMove/UtilityBundle.svg';
import LaCarte from '../../Assets/images/PreMove/LaCarte.svg';
import './serviceRequirement.scss';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import * as CommonAction from '../../store/common/actions';
import { bindActionCreators } from 'redux';

type InitialProps = {
    history?: any;
    commonAction: any;
    commonState: any;
};

type InitialState = {
    fullService: any;
    utilityBundle: any;
    laCarte: any;
    slug: any;
    hashcode: any;
    isDataArrived: boolean;
    name: string;
    email: string;
    phone: string;
};

class ServiceRequirement extends React.Component<InitialProps, InitialState> {
    state: InitialState = {
        fullService: ['Expert movers with all services', 'Convenient & saves time', 'Best for Interstate movers'],
        utilityBundle: ['Internet & cable entire setup', 'Electricity & gas', 'Get additional savings'],
        laCarte: ['Select any services you like', 'Flexible', 'You control everything'],
        slug: '',
        hashcode: '',
        isDataArrived: true,
        name: '',
        email: '',
        phone: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
    }

    handleBack = () => {
        this.props.commonAction.moveGetWithHashCode({
            hashCode: this.state.hashcode,
        });
        this.props.history.push(`/premovesignup?hash_code=${this.state.hashcode}`);
    };
    public static getDerivedStateFromProps(nextProps: InitialProps, currentState: InitialState) {
        if (
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            nextProps.history.location.state.hashcode &&
            nextProps.history.location.state.name &&
            nextProps.history.location.state.email &&
            nextProps.history.location.state.phone &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.hashcode = nextProps.history.location.state.hashcode;
            currentState.name = nextProps.history.location.state.name;
            currentState.phone = nextProps.history.location.state.phone;
            currentState.email = nextProps.history.location.state.email;
        }
        return currentState;
    }

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;

        // const fromSignUpPre= this.props &&
        // this.props.history &&
        // this.props.history.location &&
        // this.props.history.location.state &&
        // this.props.history.location.state.fromSignUpPre
        return (
            <div className="servicePageMain" style={{ marginTop: '150px' }}>
                <div className="main-heading">Great ! Select your service as per your requirement</div>
                <div className="sub-heading">Select only one services our concierge will help you out</div>
                <div className="service-cards-div">
                    <div className="service-card-div">
                        <ServicePreferenceCard
                            icon={FullService}
                            points={this.state.fullService}
                            tag={true}
                            title="Full Service Move"
                            favoriteText="1.6K people preferred this service"
                            onClick={() => {
                                this.props.history.push({
                                    pathname: '/scheduleacall',
                                    state: {
                                        service: 'Full Service Move',
                                        hashcode: this.state.hashcode,
                                        name: this.state.name,
                                        email: this.state.email,
                                        phone: this.state.phone,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className="service-card-div">
                        <ServicePreferenceCard
                            icon={UtilityBundle}
                            points={this.state.utilityBundle}
                            title="Utility Bundle"
                            favoriteText="256 people preferred this service"
                            onClick={() => {
                                this.props.history.push({
                                    pathname: '/scheduleacall',
                                    state: {
                                        service: 'Utility Bundle',
                                        hashcode: this.state.hashcode,
                                        name: this.state.name,
                                        email: this.state.email,
                                        phone: this.state.phone,
                                    },
                                });
                            }}
                        />
                    </div>

                    <div className="service-card-div">
                        <ServicePreferenceCard
                            icon={LaCarte}
                            points={this.state.laCarte}
                            title="À la carte"
                            favoriteText="1.1K people preferred this service"
                            onClick={() => {
                                this.props.history.push({
                                    pathname: '/laCarte',
                                    state: {
                                        service: 'La Carte',
                                        hashcode: this.state.hashcode,
                                        name: this.state.name,
                                        email: this.state.email,
                                        phone: this.state.phone,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="button-div">
                    <Button
                        className="btnMoving"
                        onClick={this.handleBack}
                        backgroundColor="#FFFFFF"
                        boxShadow={`0 0 3px 0 ${
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }`}
                        color={
                            isDouglasElliman
                                ? '#100B28'
                                : this.state.slug && this.state.slug.primary_color
                                ? this.state.slug.primary_color
                                : '#273E59'
                        }
                        borderRadius="5px"
                        fontSize="16px"
                        margin="0 15px 0 0"
                    >
                        Back
                    </Button>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequirement);
