import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="checkBoximg"
            width={59.435}
            height={71}
            viewBox="0 0 59.435 71"
        >
            <defs>
                <style>{`.hsfootageprefix__a{fill:${
                    slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                }}`}</style>
            </defs>
            <path
                className="hsfootageprefix__a"
                d="M22.525 40.819v3.169a7.204 7.204 0 1014.408 0v-3.169a7.204 7.204 0 10-14.408 0zm12.677 0v3.169a5.473 5.473 0 11-10.946 0v-3.169a5.473 5.473 0 0110.946 0zm0 0M41.856 44.08v2.774a6.414 6.414 0 1012.827 0V44.08a6.414 6.414 0 10-12.827 0zm11.1 0v2.774a4.683 4.683 0 11-9.365 0V44.08a4.683 4.683 0 119.365 0zm0 0"
            />
            <path
                className="hsfootageprefix__a"
                d="M52.754 53.628a1.032 1.032 0 00-.889.514 4.509 4.509 0 01-7.114-.174.878.878 0 00-.718-.351 7.038 7.038 0 00-3.561 1.06 7.975 7.975 0 00-5.618-2.825c-.456-.028-.681.249-.921.542a5.275 5.275 0 01-8.323-.2.857.857 0 00-.718-.35 7.951 7.951 0 00-5.518 2.407c-.139.143-.27.291-.4.441a7.089 7.089 0 00-3.328-1.059 1.03 1.03 0 00-.889.514 4.509 4.509 0 01-7.114-.174.878.878 0 00-.718-.351 7.109 7.109 0 00-6.928 7.106v6.242a.865.865 0 00.866.866h16.234v2.3a.866.866 0 00.866.866h23.533a.865.865 0 00.866-.866v-2.3h16.207a.865.865 0 00.866-.866v-6.242a7.117 7.117 0 00-6.681-7.1zm-35.657 6.206V66.1H1.731v-5.376a5.414 5.414 0 014.825-5.348 6.241 6.241 0 009.411.023 5.329 5.329 0 012.04.728 7.958 7.958 0 00-.91 3.707zm23.533 9.435h-21.8v-9.435a6.23 6.23 0 011.04-3.458 6.308 6.308 0 01.74-.915 6.229 6.229 0 013.9-1.862 7.007 7.007 0 0010.65.024 6.273 6.273 0 015.465 6.215zM57.703 66.1H42.362v-6.266a7.951 7.951 0 00-.92-3.719 5.3 5.3 0 012.217-.74 6.192 6.192 0 004.555 2.15h.163a6.2 6.2 0 004.693-2.129 5.384 5.384 0 014.633 5.326zm0 0"
            />
            <path
                className="hsfootageprefix__a"
                d="M4.751 44.08v2.774a6.414 6.414 0 1012.827 0V44.08a6.414 6.414 0 10-12.827 0zm6.414-4.682a4.687 4.687 0 014.682 4.682v2.774a4.682 4.682 0 11-9.364 0V44.08a4.688 4.688 0 014.682-4.682zm0 0M51.356 0H8.079a.865.865 0 00-.786 1.227l1.333 2.9a.866.866 0 00.786.5h2.531v2.219a.866.866 0 00.866.866h.342v4.227a16.567 16.567 0 0033.133 0V7.711h.343a.865.865 0 00.866-.866V4.629h2.534a.865.865 0 00.786-.5l1.334-2.9A.866.866 0 0051.356 0zm-6.8 11.939a14.835 14.835 0 01-29.67 0V7.712h29.67zm1.208-5.958h-32.09V4.629H45.76zM49.467 2.9h-2.669a.869.869 0 00-.345 0H12.982a.869.869 0 00-.345 0h-2.67l-.54-1.169h40.578zm0 0"
            />
            <path
                className="hsfootageprefix__a"
                d="M35.928 21.344a9.977 9.977 0 01-1.832 1.164.866.866 0 10.762 1.554 11.748 11.748 0 002.151-1.364.866.866 0 00-1.081-1.352zm0 0M38.655 19.725a.866.866 0 001.183-.316 11.627 11.627 0 001.564-5.844.866.866 0 10-1.731 0 9.906 9.906 0 01-1.332 4.977.866.866 0 00.316 1.183zm0 0M29.717 20.554a6.269 6.269 0 005.158-2.706h.035a2.488 2.488 0 00.965-4.782 6.275 6.275 0 10-6.158 7.487zm5.193-5.951a.758.758 0 11-.757.757.758.758 0 01.757-.757zm-5.193-4.872a4.527 4.527 0 014.367 3.282 2.481 2.481 0 00-.868 4.161 4.544 4.544 0 11-3.5-7.443zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
