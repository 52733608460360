import React from 'react';
import AnalyticsService from '../../../pages/analytics-service';

import './BlogCard.scss';

interface cardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: any;
    cardMargin?: string;
    cardPadding?: string;
    onClick?: any;
    className?: string;
    imgWidth?: string;
    imgHeight?: string;
    cardImage?: any;
    cardTitle?: string;
    cardContent?: string;
    boxShadow?: any;
    imgClassname?: string;
    cardLink?: string;
    analyticsData?: any;
}

const BlogCard = (props: cardProps) => {
    const { cardTitle, cardImage, cardContent, cardLink, analyticsData } = props;

    const handleReadMoreClick = (blogUrl: string) => {
        blogUrl && window.open(blogUrl, '_blank', 'noopener,noreferrer');

        analyticsData && AnalyticsService.buttonClicked({
            ...analyticsData,
            category_name: 'Blog Read More Link Clicked',
            button_copy: 'Read More',
            card: true,
            card_title: cardTitle,
            card_type: 'Blog Card',
        });
    };

    return (
        <div className="blogCardMain">
            <div className="blogCardContainer ">
                <div className="blogCardContainerText ">{cardTitle}</div>
                <div className="blogImgCover">
                    <img width={300} src={cardImage} alt="" />
                </div>
                <div className="blogCardContainerContent">{cardContent}</div>
            </div>
            <div className="blogCardContainerReadMore">
                <span onClick={() => handleReadMoreClick(cardLink || '')}>Read More&nbsp;{'>'}</span>
            </div>
        </div>
    );
};

export default BlogCard;
