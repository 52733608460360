import React, { Component } from 'react';
import './Dashboard.scss';
import DashboardBackground from '../../Assets/images/DashBoard/dashboardBackground.svg';
import DashboardBackground1 from '../../Assets/images/DouglasImages/dashboardImgWeb.png';
import DashboardBackgroundIpadPro from '../../Assets/images/DouglasImages/dashboardIpadProImg.png';
import DashboardBackgroundIpad from '../../Assets/images/DouglasImages/dashboardIpadImg.png';
import DashboardBackgroundMobile from '../../Assets/images/DouglasImages/dashboardMobileImg.png';
// import bellIcon from '../../Assets/images/DashBoard/bellIcon.png';
import InfoIcon from '../../Assets/images/DashBoard/infoIcon.svg';
import CallWithMikeCard from '../../components/dashboardComponents/CallWithMikeCard';
import Moving from '../../Assets/images/DashBoard/MovingImage.svg';
// import completedCardImage from '../../Assets/images/DashBoard/completedcardImg.svg';
import MaskGroup from '../../Assets/images/DashBoard/Mask Group 24.svg';
import GetStartedMovingServiceCard from '../../components/dashboardComponents/getStartedMovingServiceCard';
import MovingNextCard from '../../components/atoms/MovingNextCard';
import MovingCar from '../../Assets/images/Moving/MovingCar.svg';
import Utilities from '../../Assets/images/DashBoard/utilities.svg';
import HomeRepair from '../../Assets/images/DashBoard/homeRepair.svg';
import ChangeAddress from '../../Assets/images/DashBoard/changeAddress.svg';
import Providers from '../../Assets/images/DashBoard/providers.svg';
// import SecuritySystem from '../../Assets/images/DashBoard/securitySystem.svg';
import Storage from '../../Assets/images/DashBoard/storage.svg';
import { scroller } from 'react-scroll';
import ChecklistIcon from '../../Assets/images/DashBoard/pending_actions_black_24dp.svg';
import BlogIcon from '../../Assets/images/DashBoard/auto_stories_black_24dp.svg';
// import BestDealsIcon from '../../Assets/images/DashBoard/local_offer_black_24dp.svg';
import BlogCardTopImage from '../../components/dashboardComponents/BlogCardTopImage';
import BlogCardRightImage from '../../components/dashboardComponents/BlogCardRightImage';
import Blog1 from '../../Assets/images/DashBoard/dashboardInterCable.jpg';
import Blog2 from '../../Assets/images/DashBoard/dashboardGuideToHome.png';
import Blog3 from '../../Assets/images/DashBoard/dashboardThingsTodo.jpg';
import PODSSvg from '../../Assets/images/Moving/banner.svg';
// import BestDealsCard from '../../components/dashboardComponents/bestDealsCard';
// import Img2 from '../../Assets/images/DashBoard/Group 33415.svg';
// import Img1 from '../../Assets/images/DashBoard/Image 33.png';
import '../../styles/dashboard.scss';
import DashBoardWelcome from '../../components/dashboardComponents/DashBoardWelcome';
import DashBoardWelcomeImage from '../../Assets/images/DashBoard/DashBoardWelcome.svg';
// import MoveFromMoveToCard from '../../components/dashboardComponents/MoveFromMoveToCard';
import Modal from 'react-modal';
import StepRight from '../../Assets/images/DashBoard/StepRight.svg';
import DashBoardstepRight from '../../components/dashboardComponents/DashBoardStepRight';
import fourIcons from '../../Assets/images/DashBoard/fourIcons.svg';
import storage from '../../Assets/images/DashBoard/storage.svg';
import security from '../../Assets/images/DashBoard/security.svg';
import Map from '../../Assets/images/DashBoard/Map.svg';
import MovingChecklistCompletedCard from '../../components/dashboardComponents/movingChecklistCompletedCard';
import Pending from '../../Assets/images/DashBoard/pending_black_24dp.svg';
import PendingTasksCard from '../../components/dashboardComponents/PendingTasksCard';
import Button from '../../components/atoms/Button';
import MoveFromMoveToCard2 from '../../components/dashboardComponents/MoveFromMoveToCard2';
// import MobileDateCard from '../../components/dashboardComponents/MobileDateCard';
// import AdditionalDiscountImage from '../../Assets/images/DashBoard/additionalDiscountImage.svg';
import { connect } from 'react-redux';
import * as Action from '../../store/dashboard/actions';
import { bindActionCreators } from 'redux';
import PrintModal from '../../components/atoms/Modal';
// import { InlineWidget } from 'react-calendly';
import * as CommonAction from '../../store/common/actions';
import Joyride from 'react-joyride';
// import DashboardHeader from "./DashboardHeader";
import DashboardTabs from './DashboardTabs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Skeleton from 'react-loading-skeleton';
import moveEasy from '../../Assets/images/move_easy.svg';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SibcyCLineBlog from '../../Assets/images/DashBoard/sibcyClineBlog2.png';
import SibcyClineNewsBlog from '../../Assets/images/DashBoard/sibcyClineNewsBlog.png';
import ProvidersD from '../../Assets/images/DouglasImages/dashboardIcons/internetD.png';
import changeAddressD from '../../Assets/images/DouglasImages/dashboardIcons/changeAddressD.png';
import homeRepairD from '../../Assets/images/DouglasImages/dashboardIcons/homeRepairD.png';
import insuranceD from '../../Assets/images/DouglasImages/dashboardIcons/insuranceD.png';
import movingD from '../../Assets/images/DouglasImages/dashboardIcons/movingDouglus.svg';
import shippingD from '../../Assets/images/DouglasImages/dashboardIcons/movingD.png';
import storageD from '../../Assets/images/DouglasImages/dashboardIcons/storageD.png';
import utilityD from '../../Assets/images/DouglasImages/dashboardIcons/utilityD.png';
import homeSecurityD from '../../Assets/images/DouglasImages/dashboardIcons/homeSecurityD.png';
import { scheduleSegment, popUpSegment, buttonClicked } from '../../utils/utlis';
import insurance from '../../Assets/images/DashBoard/Insurance.svg';
// import internet from '../../Assets/images/DashBoard/Internet.svg';
import homeInsuranceIllustration from '../../Assets/images/DashBoard/HomeInsuranceIllustration.svg';
import { UIContext } from '../../UIContext';
import HomeServiceCard from './CustomServicesCard/CustomServiceCard';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LanguageOutlined } from '@material-ui/icons';
import { getServiceTitle } from '../../utils/helper';

var intervalVar: any;
type initialProps = {
    dashboardAction: any;
    CheckListDashboardState: any;
    commonAction?: any;
    commonState?: any;
    history?: any;
    location?: any;
};
type Services = {
    image: string,
    name: string
}
type ServicesList =  Services[]

const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

type initialState = {
    fullName: string;
    toAddress: string;
    toPin: string;
    fromAddress: string;
    fromPin: string;
    movingDate: any;
    modalIsOpen: boolean;
    currentStep: number;
    isServiceChecklist?: boolean;
    isCompletedChecklist?: boolean;
    checklistData?: any;
    completedChecklistData?: any;
    pendingTasksData?: any;
    isDataArrived?: boolean;
    isDataArrived2?: boolean;
    calendlyOpen: boolean;
    moreClicked: boolean;
    run: boolean;
    run2: boolean;
    run3: boolean;
    inCompleted?: any;
    noOfLogin?: any;
    isTourOpen?: any;
    stepIndex?: any;
    count?: any;
    showPods?: boolean;
    url?: any;
    reschedule_link: any;
    cancel_link: any;
    appointment_details: any;
    nylas_full_service_scheduler_url: any;
    scheduleClicked: boolean;
    rescheduleClicked: boolean;
    cancelClicked: boolean;
    appointmentTime: any;
    moveDataArrived: boolean;
    cancelOpen: boolean;
    reasonError: string;
    reason: string;
    editHash: string;
    showLoader: boolean;
    firstName: string;
    formOpen: boolean;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
    custom_schedule_call_info: any;
    custom_schedule_call_info_enabled: boolean;
    moveId: string;
    hashcode: string;
    brokerage_key: any;
    agent_key: any;

    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    concierge_name: string;
    appointment_type: any;
    cardTitle: any;
    customServices: ServicesList
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
    },
    overlay: {
        background: '#14141480',
        zIndex: 20000,
    },
};

const customStylesNew = {
    content: {
        top: '48%',
        left: '40%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
    },
    overlay: {
        background: '#14141480',
        zIndex: 20000,
    },
};
class Dashboard extends Component<initialProps, initialState> {
    uiControl = this.context
    state: initialState = {
        fullName: '',
        firstName: '',
        toAddress: '',
        toPin: '',
        fromAddress: '',
        fromPin: '',
        movingDate: '',
        moreClicked: false,
        modalIsOpen: false,
        currentStep: 0,
        isServiceChecklist: true,
        isCompletedChecklist: false,
        isDataArrived: true,
        isDataArrived2: true,
        completedChecklistData: [],
        calendlyOpen: false,
        run: false,
        run2: true,
        run3: true,
        inCompleted: [],
        checklistData: [],
        pendingTasksData: [],
        noOfLogin: 0,
        isTourOpen: false,
        stepIndex: 0,
        count: 0,
        showPods: false,
        url: '',
        reschedule_link: null,
        cancel_link: null,
        appointment_details: '',
        nylas_full_service_scheduler_url: null,
        scheduleClicked: false,
        rescheduleClicked: false,
        cancelClicked: false,
        appointmentTime: '',
        moveDataArrived: true,
        cancelOpen: false,
        reasonError: '',
        reason: '',
        editHash: '',
        showLoader: true,
        formOpen: false,
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        custom_schedule_call_info: '',
        custom_schedule_call_info_enabled: false,
        moveId: '',
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        concierge_name: '',
        appointment_type: '',
        cardTitle: '',
        brokerage_key: '',
        agent_key: '',
        customServices: []
    };

    componentDidMount() {
        
        if (!localStorage.getItem('token')) {
            this.props.history.push('/signUp');
        }
        intervalVar = setInterval(() => {
            if (this.state.count < 20) {
                this.props.dashboardAction.checklist();
                this.setState({
                    count: this.state.count + 1,
                });
            }
        }, 4000);
        window.scrollTo(0, 0);
        // console.log(this.props.commonState);
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
            if (
                slug &&
                slug.enabled_features &&
                slug.enabled_features.includes('Customize Concierge Contact Options')
            ) {
                this.setState({ custom_schedule_call_info_enabled: true });
            }
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }
    handlingAnalytics = () => {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);

        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Moving Details Clicked',
                {
                    page: 'Dashboard',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    movingDate: this.state.movingDate,
                    movingTo: this.state.toAddress,
                    movingFrom: this.state.fromAddress,
                },
                { user_id: move_id },
            );
        }
    };

    componentWillUnmount() {
        clearInterval(intervalVar);
    }

    handleAnalytics = () => {};

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            JSON.stringify(nextProps.commonState.appointmentDetails) !==
                JSON.stringify(currentState.appointment_details) &&
            nextProps.commonState.appointmentDetailsArrived
        ) {
            nextProps.commonState.appointmentDetailsArrived = false;
            currentState.appointment_details = nextProps.commonState.appointmentDetails;
            currentState.cancel_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.cancellation_link;
            currentState.reschedule_link =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
            currentState.appointmentTime =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
            currentState.editHash =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.edit_hash;
            currentState.showLoader = false;
            // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
            currentState.concierge_name =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.concierge_name;
            currentState.appointment_type =
                nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.appointment_type;
        }

        // if(nextProps && nextProps.commonState && JSON.stringify(nextProps.commonState.appointmentDetails) === JSON.stringify(currentState.appointment_details)   && nextProps.commonState.appointmentDetailsArrived){
        //     nextProps.commonState.appointmentDetailsArrived=false;
        //     currentState.showLoader=false
        // }
        if (
            nextProps &&
            nextProps.CheckListDashboardState &&
            nextProps.CheckListDashboardState.checklist &&
            nextProps.CheckListDashboardState.checklist.length &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.count = 20;
            let array: any[] = [];
            let array2: any[] = [];
            let completedFalse: any[] = [];
            let pendingArray: any[] = [];

            let orderedChecklist = nextProps.CheckListDashboardState.checklist;

            orderedChecklist = orderedChecklist
                .sort((a: any, b: any) => (a.ordering > b.ordering ? 1 : -1))
                .map((item: any) => {
                    return item;
                });

            let orderedChecklistNew: any = [];

            orderedChecklist.map((item2: any) => {
                if (
                    item2 &&
                    item2.do_it_now &&
                    item2.do_it_now.new_url &&
                    item2.do_it_now.new_url.includes('/dashboard') &&
                    !(item2.name.toUpperCase() == 'Junk Removal'.toUpperCase())
                ) {
                    orderedChecklistNew.push(item2);
                }
            });

            orderedChecklistNew.map((item: any) => {
                if (item.is_completed == false && item.completed_percentage === 0) {
                    let obj = {
                        title: item.name,
                        subTitle: item.description_short,
                        icon: item.new_image && item.new_image.image_svg_string,
                        buttonText: item.do_it_now && item.do_it_now.heading,
                        percentage: item.completed_percentage,
                        url: item.do_it_now && item.do_it_now.new_url,
                        id: item.id
                    };
                    array.push(obj);
                }
                if (item.completed_percentage) {
                    if (item.is_completed == true && item.completed_percentage == 100) {
                        let obj2 = {
                            title: item.name,
                            subTitle: item.description_short,
                            icon: item.new_image && item.new_image.image_svg_string,
                            percentage: item.completed_percentage,
                            url: item.do_it_now && item.do_it_now.new_url,
                            id: item.id
                        };
                        array2.push(obj2);
                    }
                } else {
                    if (item.is_completed == true) {
                        let obj2 = {
                            title: item.name,
                            subTitle: item.description_short,
                            icon: item.new_image && item.new_image.image_svg_string,
                            percentage: item.completed_percentage,
                            url: item.do_it_now && item.do_it_now.new_url,
                            id: item.id
                        };
                        array2.push(obj2);
                    }
                }
                if (!item.is_completed && item.completed_percentage == 0) {
                    let obj3 = {
                        className: 'dashboardCard cardMargin',
                        cardImage: item.new_image && item.new_image.image_svg_string,
                        cardTitle: item.name,
                        cardSubtitle: item.description_short,
                        buttonTitle: item.do_it_now && item.do_it_now.heading,
                        percentage: item.completed_percentage,
                        progress: false,
                        url: item.do_it_now && item.do_it_now.new_url,
                        id: item.id
                    };
                    completedFalse.push(obj3);
                }
                if (item.completed_percentage > 0 && !item.is_completed) {
                    pendingArray.push({
                        title: item.name,
                        subTitle: item.description_short,
                        image: item.new_image && item.new_image.image_svg_string,
                        taskCompletionTime: item.required_time,
                        btnCancel: 'Cancel',
                        btnCompleteTask: 'Complete',
                        taskProgressValue: item.completed_percentage,
                        percentage: item.completed_percentage,
                        url: item.do_it_now && item.do_it_now.new_url,
                        id: item.id
                    });
                }
            });

            array = array.filter(item => {
                return item.title != completedFalse[0].cardTitle && item.title != completedFalse[1].cardTitle;
            });

            currentState.pendingTasksData = currentState.pendingTasksData.concat(pendingArray);
            currentState.checklistData = array;
            currentState.completedChecklistData = array2;
            currentState.inCompleted = completedFalse;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.move[0] && currentState.moveDataArrived) {
            currentState.moveDataArrived = false;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.fullName =
                nextProps.commonState.move[0].user_ref &&
                `${nextProps.commonState.move[0].user_ref.first_name} ${nextProps.commonState.move[0].user_ref.last_name}`;
            currentState.toAddress =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.lookup_name;
            currentState.toPin =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.zip_code;
            currentState.fromAddress =
                nextProps.commonState.move[0].source && nextProps.commonState.move[0].source.lookup_name;
            currentState.fromPin =
                nextProps.commonState.move[0].source && nextProps.commonState.move[0].source.zip_code;
            currentState.movingDate = nextProps.commonState.move[0].moving_date;
            currentState.noOfLogin = nextProps.commonState.move[0].no_of_login;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            currentState.appointment_details = nextProps.commonState.move[0].appointment_details;
            currentState.reschedule_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.reschedule_link;
            currentState.cancel_link =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.cancellation_link;
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.appointmentTime =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.start_time;
            currentState.editHash =
                nextProps.commonState.move[0].appointment_details &&
                nextProps.commonState.move[0].appointment_details.edit_hash;
            currentState.custom_schedule_call_info =
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.custom_schedule_call_info;
            currentState.showLoader = false;
            currentState.hashcode = nextProps.commonState.move[0].hash_code
                ? nextProps.commonState.move[0].hash_code
                : '';
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            if (nextProps.commonState.move[0].no_of_login < 2) {
                if (
                    nextProps &&
                    nextProps.history &&
                    nextProps.history.location &&
                    nextProps.history.location.state &&
                    nextProps.history.location.state.fromSignUp == 'fromSignUp'
                ) {
                    currentState.modalIsOpen = true;
                    let slug: any = localStorage.getItem('slug');
                    let move_id: any = localStorage.getItem('moveKey');
                    popUpSegment(
                        'Popup Viewed',
                        'Dashboard',
                        currentState.brokerage_name,
                        currentState.brokerage_key,
                        currentState.agent_key,
                        currentState.agent_name,
                        `Hi ${currentState.firstName},  welcome to  ${
                            slug && slug.display_name ? slug.display_name : ''
                        } `,
                        move_id,
                    );
                }
            }
            // let obj = {
            //     image:
            //         nextProps.commonState.move[0] &&
            //         nextProps.commonState.move[0].assigned_concierge &&
            //         nextProps.commonState.move[0].assigned_concierge.headshot_logo,
            //     title:
            //         nextProps.commonState.move[0] &&
            //         nextProps.commonState.move[0].assigned_concierge &&
            //         nextProps.commonState.move[0].assigned_concierge.full_name,
            //     subTitle: 'You’re dedicated concierge who will be contacting you shortly',
            //     // taskProgressValue: 5,
            //     // taskCompletionTime: "3 min",
            //     btnCancel: 'Cancel',
            //     btnCompleteTask: 'Reschedule',
            // };

            // currentState.pendingTasksData = [obj].concat(currentState.pendingTasksData);
        }

        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
        }

        if (nextProps && nextProps.commonState && nextProps.commonState.header) {
            let options: ServicesList = [];
            nextProps.commonState.header.forEach((item : any)=> {
                if(item.is_default === false)
                    options.push(item);
            });
            
            currentState.customServices = options;
        }
        return currentState;
    }

    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (
            this.state.firstName &&
            this.state.lastName &&
            this.state.email &&
            this.state.phone &&
            this.state.phone.length >= 12
        ) {
            //   this.setState({
            //      formOpen:false
            //   })
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            this.setState({ formOpen: false });
        }
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
            isSubmitClicked: false,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
            isSubmitClicked: false,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
            isSubmitClicked: false,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
            isSubmitClicked: false,
        });
    };

    serviceCardHandler = async (item: any) => {
        if (item.url.includes('moveeasy.com')) {

            const redirectPath = item.url.split('moveeasy.com')[1] || '';

            const taskDataToStore = {
                id: item.id,
                url: redirectPath
            }

            localStorage.setItem('activeChecklistTask', JSON.stringify(taskDataToStore));
            this.props.history.push(redirectPath);

        } else {
            await this.props.dashboardAction.updateServicePercentage({
                data: {
                    completed_percentage: 100,
                },
                service_id: item.id
            });

            window.open(item.url, '_blank');
        }

        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Complete It Now',
            false,
            '',
            true,
            this.state.cardTitle,
            'Service Card',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    pendingCardHandler = (url: any, percentage: any, id: string) => {
        if (url.includes('/dashboard')) {
            let urlString = url.split('/');
            let service = urlString[urlString.length - 1];

            const redirectPath = url.split('moveeasy.com')[1] || '';
            const taskDataToStore = {
                id: id,
                url: redirectPath
            }

            localStorage.setItem('activeChecklistTask', JSON.stringify(taskDataToStore));
            //movers
            if (service == 'movers') {
                if (percentage < 60) {
                    this.props.history.push('/dashboard/movers/steps');
                } else {
                    this.props.history.push('/dashboard/movers/recommended');
                }
            } else if (service === 'internet') {
                if (percentage === 25) this.props.history.push('/dashboard/internet/steps');
                else if (percentage === 50) this.props.history.push('/dashboard/internet/recommendations');
                else if (percentage === 75) this.props.history.push('/dashboard/internet/order');
                else if (percentage === 100) this.props.history.push('/dashboard/internet/summary');
            } else if (service === 'utilities') {
                if (percentage === 50) this.props.history.push('/dashboard/utilities');
            } else if (service === 'storage') {
                if (percentage < 100) this.props.history.push('/dashboard/storage');
                // else if(percentage === 66) this.props.history.push('/dashboard/movers/reserve_order_place');
                else if (percentage === 100) this.props.history.push('/dashboard/movers/reserve_storage_thankyou');
            } else if (service === 'address') {
                if (percentage <= 100) this.props.history.push('/dashboard/address');
            } else if (service === 'ourvendors') {
                this.props.history.push('/dashboard/ourvendors');
            } else if (service === 'autoease') {
                this.props.history.push('/dashboard/autoease');
            }
        }
        let move_id: any = localStorage.getItem('moveKey');
        buttonClicked(
            'Button Clicked',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Complete',
            false,
            '',
            true,
            this.state.cardTitle,
            'Pending Task Card',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    onEditCompleteCard = (url: any) => {
        if (url.includes('/dashboard')) {
            let urlString = url.split('/');
            let service = urlString[urlString.length - 1];
            //movers
            if (service == 'movers') {
                this.props.history.push('/dashboard/movers/recommended');
            } else if (service === 'internet') {
                this.props.history.push('/dashboard/internet/recommendations');
            } else if (service === 'utilities') {
                this.props.history.push('/dashboard/utilities');
            } else if (service === 'storage') {
                this.props.history.push('/dashboard/storage');
            } else if (service === 'address') {
                this.props.history.push('/dashboard/address');
            } else if (service === 'ourvendors') {
                this.props.history.push('/dashboard/ourvendors');
            } else if (service === 'autoease') {
                this.props.history.push('/dashboard/autoease');
            }
        }
    };

    tabCardHandler = () => {
        this.setState({ run2: false });
    };

    completedCardHandler = () => {
        this.setState({ run3: false });
    };

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };

    skipHandler = () => {
        this.closeModal();
        this.setState({
            run: false,
        });

        scroller.scrollTo('dashboardHeader', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    startedHandler = () => {
        this.setState({ currentStep: 1, modalIsOpen: false, run: true });
        let move_id: any = localStorage.getItem('moveKey');
        popUpSegment(
            'Popup Viewed',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Your Moving Details',
            move_id,
        );

        // scroller.scrollTo("dashboardHeader", {
        //     duration: 800,
        //     delay: 0,
        //     smooth: 'easeInOutQuart',
        // } )
    };
    cardOneHandler = () => {
        this.setState({ currentStep: 2, stepIndex: 1 });
        let move_id: any = localStorage.getItem('moveKey');
        popUpSegment(
            'Popup Viewed',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Your Personal Assistant',
            move_id,
        );
        // scroller.scrollTo("things-to-do-div", {
        //     duration: 800,
        //     delay: 0,
        //     smooth: 'easeInOutQuart',
        // } )
    };

    cardTwoHandler = () => {
        this.setState({ currentStep: 3, stepIndex: 2 });
        let move_id: any = localStorage.getItem('moveKey');
        popUpSegment(
            'Popup Viewed',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Your home services',
            move_id,
        );

        // scroller.scrollTo("MovingChecklistMainDiv", {
        //     duration: 800,
        //     delay: 0,
        //     smooth: 'easeInOutQuart',
        // } )
    };
    cardThreeHandler = () => {
        this.setState({ currentStep: 4, stepIndex: 3 });
        let move_id: any = localStorage.getItem('moveKey');
        popUpSegment(
            'Popup Viewed',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Read our expert blogs on home tips',
            move_id,
        );
        // scroller.scrollTo(" blogsMainDiv", {
        //     duration: 800,
        //     delay: 0,
        //     smooth: 'easeInOutQuart',
        // } )
    };

    arrowHandler2 = () => {
        this.setState({ currentStep: this.state.currentStep - 1, stepIndex: this.state.stepIndex - 1 });
    };

    onServiceClick = () => {
        this.setState({
            isServiceChecklist: true,
            isCompletedChecklist: false,
        });
    };

    onCompletedClick = () => {
        this.setState({
            isServiceChecklist: false,
            isCompletedChecklist: true,
        });
    };

    handleBookNow = () => {
        this.setState({
            showPods: true,
        });
    };

    handleClosePODSModal = () => {
        this.setState({ showPods: false });
    };

    handleCancel = () => {
        this.setState({
            showPods: false,
        });
    };

    calendlyHandle = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: true,
            rescheduleClicked: false,
            cancelClicked: false,
        });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Button Clicked',
            'Dashboard',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Dashboard',
            'Schedule A Call',
            true,
            `Connect with your Moving Concierge ${this.props.commonState.move[0] &&
                this.props.commonState.move[0].assigned_concierge &&
                this.props.commonState.move[0].assigned_concierge.full_name}`,
            true,
            'Here are the top 3 things to do',
            move_id,
        );

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Dashboard',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };

    calendlyHandleReschedule = () => {
        this.setState({
            calendlyOpen: true,
            scheduleClicked: false,
            rescheduleClicked: true,
            cancelClicked: false,
        });
    };

    // calendlyHandleCancel=()=>{
    //     this.setState({ calendlyOpen: true,
    //         scheduleClicked:false,
    //         rescheduleClicked:false,
    //         cancelClicked:true
    //      });

    // }

    closeTour = () => {
        this.setState({ isTourOpen: false });
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    nylasModalClosed = () => {
        // console.log("clicked")
        this.setState({ calendlyOpen: false });
        let hash_code =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0].hash_code;

        const payload = {
            hash_code: hash_code,
        };

        // if(this.state.cancelClicked){
        //     let countNew=0
        //     setInterval(() => {
        //         if (countNew < 10) {
        //             this.props.commonAction.appointmentGet(payload)
        //                 countNew=countNew + 1
        //         }
        //     }, 5000);
        // }else{
        this.props.commonAction.appointmentGet(payload);
        this.setState({
            showLoader: true,
        });
        // }
    };

    cancelModalOpened = () => {
        this.setState({
            cancelOpen: true,
        });
    };

    cancelModalClosed = () => {
        this.setState({
            cancelOpen: false,
        });
    };

    cancelButtonClicked = () => {
        if (!this.state.reason) {
            this.setState({
                reasonError: 'Please provide a reason',
            });
        } else {
            this.props.commonAction.cancelAppointment({
                hash: this.state.editHash,
                reason: this.state.reason,
            });

            this.setState({
                cancelOpen: false,
            });

            let hash_code =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
            };
            this.setState({
                showLoader: true,
            });
            let countNew = 0;
            setInterval(() => {
                if (countNew < 6) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 10000);

            // setTimeout(()=>{
            //     this.props.commonAction.appointmentGet(payload)
            // },20000)
        }
    };

    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };

    render() {
        let moveData =
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0];
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let displayData: any;
        displayData =
            this.props.location && this.props.location.state && this.props.location.state.length > 0
                ? this.props.location.state.filter((el: any) => el.name === 'Trusted Pros')
                : this.props.commonState && this.props.commonState.header && this.props.commonState.header.length > 0
                ? this.props.commonState.header.filter((el: any) => el.name === 'Trusted Pros')
                : [];



        const { brokerage_name, brokerage_id, agent_name, agent_id } = this.state;
        const analyticsData = { brokerage_name, brokerage_id, agent_name, agent_id, page: "Dashboard" };

        let steps: any[] = [
            {
                target: '.moveFromMoveToCard2',
                content:
                    window.screen.width > 768 ? (
                        <div className="step2-right-card">
                            <DashBoardstepRight
                                leftarrow={window.screen.width == 1024 ? false : true}
                                toparrow={window.screen.width == 1024 ? true : false}
                                icon={StepRight}
                                currentStep="1"
                                totalSteps="4"
                                title="Your Moving Details"
                                subTitle="Here is all your moving information. Should your move date or your address change, feel free to modify it here."
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardOneHandler}
                                className2={window.screen.width == 1024 ? 'only-iPadPro' : ''}
                            />
                        </div>
                    ) : (
                        <div className="step2-right-card-mobile">
                            <DashBoardstepRight
                                arrowMarginTop="0"
                                toparrow
                                icon={StepRight}
                                currentStep="1"
                                totalSteps="4"
                                title="Your Moving Details"
                                subTitle="Here is your moving address information and the date of Move"
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardOneHandler}
                            />
                        </div>
                    ),
                placement: window.screen.width > 768 ? `right` : `bottom`,
                disableBeacon: true,
                offset: 0,
            },
            {
                target: '.scheduleCardDiv',
                content:
                    window.screen.width > 768 ? (
                        <div className="step2-right-card">
                            <DashBoardstepRight
                                leftarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="2"
                                totalSteps="4"
                                title={`Your ${moveData && moveData.concierge_reference_field}`}
                                subTitle={`This is your dedicated ${moveData &&
                                    moveData.concierge_reference_field}. Be sure to set up a consultation call with ${
                                    moveData && moveData.assigned_concierge && moveData.assigned_concierge.gender == 'M'
                                        ? 'him.'
                                        : 'her.'
                                }`}
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardTwoHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ) : (
                        <div>
                            <DashBoardstepRight
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="2"
                                totalSteps="4"
                                title="Your Personal Assistant"
                                subTitle={`This is your dedicated concierge. Be sure to set up a consultation call with ${
                                    moveData && moveData.assigned_concierge && moveData.assigned_concierge.gender == 'M'
                                        ? 'him.'
                                        : 'her.'
                                }`}
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardTwoHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ),
                placement: window.screen.width > 768 ? `right` : `top`,
                disableBeacon: true,
                offset: 0,
            },

            {
                target: '.step-3-left-cards',
                content:
                    window.screen.width > 768 ? (
                        <div className="step-3-right">
                            <DashBoardstepRight
                                leftarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="3"
                                totalSteps="4"
                                title="Your home services"
                                subTitle="Here are all the most important tasks for your move."
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardThreeHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ) : (
                        <div className="only-mobile-step-3">
                            <DashBoardstepRight
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="3"
                                totalSteps="4"
                                title="Your home services"
                                subTitle="Here are all the most important tasks for your move."
                                buttonText="Next"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.cardThreeHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ),
                placement: window.screen.width > 768 ? `right` : `top`,
                disableBeacon: true,
                offset: 0,
            },
            {
                target: window.screen.width > 425 ? '.blogCard-div1' : '.blogCard-div1Mobile',
                content:
                    window.screen.width > 768 ? (
                        <div className="step-4-right">
                            <DashBoardstepRight
                                leftarrow={window.screen.width == 1024 ? false : true}
                                toparrow={window.screen.width == 1024 ? true : false}
                                arrowBack
                                icon={StepRight}
                                currentStep="4"
                                totalSteps="4"
                                title="Read our expert blogs on home tips"
                                subTitle="Finally, here are some blogs with useful advice and guidance from experts as you navigate your move."
                                buttonText="Done"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick={this.skipHandler}
                                arrowHandler={this.arrowHandler2}
                                className2={window.screen.width == 1024 ? 'only-iPadPro' : ''}
                            />
                        </div>
                    ) : (
                        <div className="only-mobile-step-4">
                            <DashBoardstepRight
                                bottomarrow
                                arrowBack
                                icon={StepRight}
                                currentStep="4"
                                totalSteps="4"
                                title="Read our expert blogs on home tips"
                                subTitle="Finally, here are some blogs with useful advice and guidance from experts as you navigate your move."
                                buttonText="Done"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick={this.skipHandler}
                                arrowHandler={this.arrowHandler2}
                            />
                        </div>
                    ),
                placement: window.screen.width > 768 ? `right` : `top`,
                disableBeacon: true,
                offset: 0,
            },
        ];

        let steps2: any[] = [
            {
                target: '.links',
                content: (
                    <div>
                        <DashBoardstepRight
                            toparrow
                            title="Here is all your moving checklist you can easily navigate acrosss"
                            buttonText="Done"
                            buttonColor="#fff"
                            buttonBackGroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? `${slug.primary_color}`
                                    : '#002E60'
                            }
                            buttonShadow={
                                isDouglasElliman
                                    ? 'none'
                                    : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                            }
                            onClick={this.tabCardHandler}
                            className2="tabs-card"
                            icon={StepRight}
                        />
                    </div>
                ),
                placement: `bottom`,
                disableBeacon: true,
                offset: 0,
            },
        ];

        let steps3: any[] = [
            {
                target: '.completedCard',
                content: (
                    <div className="step2-left-card">
                        <DashBoardstepRight
                            leftarrow
                            title="Great! You have completed your first task"
                            subTitle="You check your completed task under your moving checklist"
                            buttonText="Done"
                            buttonColor="#fff"
                            buttonBackGroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? `${slug.primary_color}`
                                    : '#002E60'
                            }
                            buttonShadow={
                                isDouglasElliman
                                    ? 'none'
                                    : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                            }
                            onClick={this.completedCardHandler}
                            icon={StepRight}
                            imageClass="image-class"
                            // className2="completed-card-one"
                        />
                    </div>
                ),
                placement: `right`,
                disableBeacon: true,
                offset: 0,
            },
        ];

        return (
            <div
                className="dashboard-main-page"
                style={{
                    marginTop: window.location.pathname === '/' ? '0px' : '-7%'
                }}
            >
                {/* {
                  this.state.run==true  ? null : <div className="dashboardMainHeader">
                    <DashboardHeader history={this.props.history}/>
                </div>

    } */}
                {/* {this.state.run == false &&
                this.state.modalIsOpen == false &&
                (this.state.completedChecklistData.length || this.state.pendingTasksData.length) ? ( */}
                {this.state.run == false ? (
                    <div className="dashboardMainHeaderTabs">
                        <DashboardTabs history={this.props.history} />
                    </div>
                ) : null}
                {/* ) : null} */}

                {this.state.formOpen && (
                    <PrintModal
                        isShowModal={this.state.formOpen}
                        onCloseModal={() => {
                            this.setState({ formOpen: false });
                        }}
                        showClose={false}
                        className="modalClassesSecurity"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="CloseIcon">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontSize: '20px',
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Schedule a Call Back
                                </div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ formOpen: false });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div className="line12"></div>
                            <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                <div
                                    style={{
                                        fontSize: '16px',
                                        // marginTop: '10px',
                                        color: '#333333',
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Personal Details
                                </div>
                                <div className="peronalDetailsInsurance">
                                    <div className="flex-div">
                                        <div className="div-1-name">
                                            <TextField
                                                id="time"
                                                label="First Name"
                                                value={this.state.firstName}
                                                onChange={(e: any) => this.firstNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.firstName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.firstName === ''
                                                    ? 'Please enter first name'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="div-2-name">
                                            <TextField
                                                id="time"
                                                label="Last Name"
                                                value={this.state.lastName}
                                                onChange={(e: any) => this.lastNameHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.lastName === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.lastName === ''
                                                    ? 'Please enter last name'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <TextField
                                        id="time"
                                        label="Email"
                                        value={this.state.email}
                                        onChange={(e: any) => this.emailHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.email === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.email === ''
                                            ? 'Please enter email'
                                            : this.state.isSubmitClicked &&
                                              !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                            ? 'Please enter valid email'
                                            : ''}
                                    </div>
                                    <TextField
                                        id="time"
                                        label="Phone Number"
                                        value={this.state.phone}
                                        onChange={(e: any) => this.phoneHandler(e)}
                                        type="text"
                                        variant="filled"
                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                        // disabled
                                        error={this.state.isSubmitClicked && this.state.phone === ''}
                                    />
                                    <div className="error">
                                        {this.state.isSubmitClicked && this.state.phone === ''
                                            ? 'Please enter phone number'
                                            : this.state.isSubmitClicked && this.state.phone.length < 12
                                            ? 'Please enter valid phone number'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="line12"></div>
                            <div className="InsuranceOrderPageButton">
                                <Button
                                    className="btnOrder"
                                    backgroundColor="#FFF"
                                    boxShadow={
                                        isDouglasElliman
                                            ? 'none'
                                            : `0 0 3px 0 ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                    }
                                    width="130px"
                                    height="50px"
                                    border={
                                        isDouglasElliman
                                            ? '1px solid #100B28'
                                            : `1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`
                                    }
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : '#273e59'
                                    }
                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                    fontSize="16px"
                                    margin="0 10px 0 0"
                                    onClick={() => this.setState({ formOpen: false })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btnOrder"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : slug && slug.primary_color
                                            ? slug.primary_color
                                            : window.location.pathname.includes('landing')
                                            ? '#30AB86'
                                            : '#273e59'
                                    }
                                    // boxShadow="0 0 3px 0 #273E59"
                                    width="130px"
                                    height="50px"
                                    // color="#FFFFFF"
                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                    fontSize="16px"
                                    margin="0 5px 0 0"
                                    onClick={() => this.closeFormHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </PrintModal>
                )}

                <div className="dashboard-modal">
                    {this.state.currentStep >= 1 ? (
                        <Joyride
                            floaterProps={{
                                disableAnimation: true,
                                disableFlip: true,
                            }}
                            scrollOffset={window.screen.width > 425 ? 200 : 100}
                            run={this.state.run}
                            steps={steps}
                            hideBackButton={true}
                            stepIndex={this.state.stepIndex}
                            spotlightClicks={false}
                            spotlightPadding={0}
                            disableOverlayClose={true}
                            //  hideCloseButton={true}
                            styles={{
                                options: {
                                    width: '100%',
                                    beaconSize: 0,
                                    arrowColor: 'transparent',
                                    spotlightShadow: 'none',
                                    backgroundColor: 'transparent',
                                    overlayColor: '#14141480',
                                    primaryColor: '#000',
                                    textColor: '#333',
                                    zIndex: 20000,
                                },
                                tooltip: {
                                    animation: 'none',
                                },

                                buttonClose: {
                                    display: 'none',
                                },
                                buttonNext: {
                                    display: 'none',
                                },

                                //  beacon:{
                                //      display:'none'
                                //  }
                            }}
                        />
                    ) : null}

                    {window.screen.width > 600 &&
                    window.screen.width <= 1920 &&
                    this.state.run == false &&
                    this.state.modalIsOpen == false &&
                    this.state.noOfLogin < 2 &&
                    (this.state.completedChecklistData.length == 1 || this.state.pendingTasksData.length == 1) ? (
                        <div className="joyride-2">
                            <Joyride
                                floaterProps={{
                                    disableAnimation: true,
                                    disableFlip: true,
                                }}
                                scrollOffset={150}
                                run={this.state.run2}
                                steps={steps2}
                                hideBackButton={true}
                                // stepIndex={this.state.stepIndex}
                                spotlightClicks={false}
                                spotlightPadding={0}
                                disableOverlayClose={true}
                                //  hideCloseButton={true}
                                styles={{
                                    options: {
                                        width: '100%',
                                        beaconSize: 0,
                                        arrowColor: 'transparent',
                                        spotlightShadow: 'none',
                                        backgroundColor: 'transparent',
                                        overlayColor: '#14141480',
                                        primaryColor: '#000',
                                        textColor: '#333',
                                        zIndex: 20000,
                                    },
                                    tooltip: {
                                        animation: 'none',
                                    },

                                    buttonClose: {
                                        display: 'none',
                                    },
                                    buttonNext: {
                                        display: 'none',
                                    },
                                }}
                            />{' '}
                        </div>
                    ) : null}
                    {this.state.completedChecklistData.length == 1 &&
                    this.state.isCompletedChecklist == true &&
                    window.screen.width > 1024 &&
                    window.screen.width <= 1920 ? (
                        <Joyride
                            floaterProps={{
                                disableAnimation: true,
                                disableFlip: true,
                            }}
                            scrollOffset={150}
                            run={this.state.run3}
                            steps={steps3}
                            hideBackButton={true}
                            // stepIndex={this.state.stepIndex}
                            spotlightClicks={false}
                            spotlightPadding={0}
                            disableOverlayClose={true}
                            //  hideCloseButton={true}
                            styles={{
                                options: {
                                    width: '100%',
                                    beaconSize: 0,
                                    arrowColor: 'transparent',
                                    spotlightShadow: 'none',
                                    backgroundColor: 'transparent',
                                    overlayColor: '#14141480',
                                    primaryColor: '#000',
                                    textColor: '#333',
                                    zIndex: 20000,
                                },
                                tooltip: {
                                    animation: 'none',
                                },

                                buttonClose: {
                                    display: 'none',
                                },
                                buttonNext: {
                                    display: 'none',
                                },
                            }}
                        />
                    ) : null}
                    {/* <button onClick={this.openModal}>Open Modal</button> */}
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        //   onRequestClose={this.closeModal}
                        style={
                            this.state.currentStep == 0 || window.screen.width < 1366 ? customStyles : customStylesNew
                        }
                        contentLabel="Dashboard Modal"
                    >
                        {this.state.currentStep == 0 && this.state.noOfLogin < 2 ? (
                            <DashBoardWelcome
                                icon={DashBoardWelcomeImage}
                                title={`Hi ${this.state.firstName},  welcome to ${
                                    slug && slug.display_name ? slug.display_name : ''
                                }`}
                                subTitle="We've created a short guide to help you learn about our services and navigate through this site."
                                buttonText="Get Started"
                                buttonColor="#fff"
                                buttonBackGroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonShadow={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                buttonText2="Skip"
                                buttonColor2={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                buttonBackGroundColor2="#fff"
                                buttonShadow2={
                                    isDouglasElliman
                                        ? 'none'
                                        : `0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`
                                }
                                onClick2={this.skipHandler}
                                onClick={this.startedHandler}
                            />
                        ) : null}
                    </Modal>
                </div>

                <div className={isDouglasElliman ? 'dashboardHeader dashboardHeaderBackground' : 'dashboardHeader'}>
                    <div
                        className={
                            isDouglasElliman
                                ? 'dashboardHeaderTitleDiv dashboardHeaderTitleDivDouglas'
                                : 'dashboardHeaderTitleDiv'
                        }
                        style={{
                            marginTop: isDouglasElliman
                                ? '3%'
                                : this.state.run == false &&
                                  this.state.modalIsOpen == false &&
                                  (this.state.completedChecklistData.length || this.state.pendingTasksData.length)
                                ? '5%'
                                : '',
                        }}
                    >
                        {/* <div className="welcomeTextDiv">
                            {isDouglasElliman ? (
                                <div className="welomeText1">
                                    Welcome aboard,
                                    <div className="nameWelcome">
                                        {isDouglasElliman
                                            ? `${
                                                  this.props.commonState &&
                                                  this.props.commonState.move &&
                                                  this.props.commonState.move[0] &&
                                                  this.props.commonState.move[0].user_ref &&
                                                  this.props.commonState.move[0].user_ref.first_name
                                                      ? this.props.commonState.move[0].user_ref.first_name
                                                      : ''
                                              } ${
                                                  this.props.commonState &&
                                                  this.props.commonState.move &&
                                                  this.props.commonState.move[0] &&
                                                  this.props.commonState.move[0].user_ref &&
                                                  this.props.commonState.move[0].user_ref.last_name
                                                      ? this.props.commonState.move[0].user_ref.last_name
                                                      : ''
                                              }`
                                            : slug && slug.display_name
                                            ? slug.display_name
                                            : ''}
                                    </div>
                                </div>
                            ) : (
                                <div className="welomeText">{`Welcome to your ${
                                    slug && slug.display_name ? slug.display_name : ''
                                } Concierge Dashboard`}</div>
                            )}
                            {!isDouglasElliman && <img src={bellIcon} />}
                        </div>
                        {!isDouglasElliman && (
                            <div className="nameDiv">{this.state.fullName ? this.state.fullName : <Skeleton />}</div>
                        )}
                        <div className={isDouglasElliman ? 'morningText1' : 'morningText'}>
                            {isDouglasElliman
                                ? 'Good Morning! What a lovely day for moving.'
                                : 'Your one stop shop for all your moving and home management priorities.'}
                        </div> */}
                        <div
                            className={
                                isDouglasElliman ? 'moveFromMoveToCard2 moveFromMoveToCard2D' : 'moveFromMoveToCard2'
                            }
                        >
                            <MoveFromMoveToCard2
                                toAddress={this.state.toAddress}
                                toPin={this.state.toPin}
                                fromAddress={this.state.fromAddress}
                                fromPin={this.state.fromPin}
                                movingDate={this.state.movingDate}
                                border={isDouglasElliman ? '30px solid #100B28' : '1px solid #DADCE0'}
                                isDouglasElliman={isDouglasElliman}
                                fullName={this.state.fullName}
                                onClick={this.handlingAnalytics}
                            />
                            {/* {!isDouglasElliman && (
                                <div className="only-mobile-date">
                                    <MobileDateCard width="100%" />
                                </div>
                            )} */}
                        </div>
                    </div>
                    {isDouglasElliman ? (
                        <div className="dashboardImgDiv1">
                            <img className="webImage" alt="" src={DashboardBackground1} />
                            <img className="ipadProImage" alt="" src={DashboardBackgroundIpadPro} />
                            <img className="ipadImage" alt="" src={DashboardBackgroundIpad} />
                            <img className="mobileImage" alt="" src={DashboardBackgroundMobile} />
                        </div>
                    ) : (
                        <div className="dashboardImgDiv">
                            <img src={DashboardBackground} alt="" />
                        </div>
                    )}
                </div>

                <div className="things-to-do-div">
                    <div className="things-title-div">
                        <img src={InfoIcon} alt="" />
                        <div className="thingsTitle">Here are the top 3 things to do</div>
                    </div>
                    <div className="thingsFlexDiv">
                        <div className="scheduleCardDiv">
                            {this.state.custom_schedule_call_info_enabled ? (
                                <CallWithMikeCard
                                    icon={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.headshot_logo
                                    }
                                    // title={
                                    //     moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                    // }
                                    titleNew={`${
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.short_description
                                            ? moveData.assigned_concierge.short_description
                                            : ''
                                    } ${
                                        moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                            ? moveData.assigned_concierge.full_name
                                            : ''
                                    }`}
                                    subTitle={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.long_description
                                    }
                                    // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    buttonText="Contact Us"
                                    buttonColor={isDouglasElliman ? '#FFF' : '#D92F25'}
                                    buttonBackGroundColor="#fff"
                                    buttonShadow="0 1px 1px #161D251A"
                                    buttonBorder={isDouglasElliman ? '1px solid #100B28' : '1px solid #D92F25'}
                                    // paragraph={
                                    //     <div>
                                    //         {(moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description) ||
                                    //         (moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.long_description) ? (
                                    //             <div style={{ fontWeight: 'bold' }}>About</div>
                                    //         ) : null}
                                    //         <div>
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description
                                    //                 ? moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.short_description
                                    //                 : moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.long_description}{' '}
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description ? (
                                    //                 <a
                                    //                     style={{
                                    //                         color: '#0066F5',
                                    //                         textDecoration: 'underline',
                                    //                         cursor: 'pointer',
                                    //                     }}
                                    //                     onClick={() => {
                                    //                         this.setState({ moreClicked: true });
                                    //                     }}
                                    //                 >
                                    //                     Learn More
                                    //                 </a>
                                    //             ) : null}
                                    //         </div>
                                    //     </div>
                                    // }
                                    onClick={() => this.formHandler()}
                                />
                            ) : (
                                <CallWithMikeCard
                                    showLoader={this.state.showLoader}
                                    appointmentTime={`${new Date(this.state.appointmentTime)
                                        .toString()
                                        .substring(0, 15)},${new Date(this.state.appointmentTime).toLocaleTimeString(
                                        'en-US',
                                    )} `}
                                    icon={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.headshot_logo
                                    }
                                    title={
                                        moveData && moveData.assigned_concierge && moveData.assigned_concierge.full_name
                                    }
                                    concierge={moveData && moveData.concierge_reference_field}
                                    subTitle={
                                        moveData &&
                                        moveData.assigned_concierge &&
                                        moveData.assigned_concierge.long_description
                                            ? moveData.assigned_concierge.long_description
                                            : "I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    }
                                    // subTitle="I've been assigned to help you with all your moving tasks and ensure that you have the best moving experience possible. Please click below to schedule a 30 minute consultation call with me."
                                    buttonText="Schedule A Call"
                                    buttonColor={isDouglasElliman ? '#FFF' : '#D92F25'}
                                    buttonBackGroundColor="#fff"
                                    buttonShadow="0 1px 1px #161D251A"
                                    buttonBorder="1px solid #D92F25"
                                    rescheduleText={this.state.reschedule_link ? 'Reschedule' : ''}
                                    rescheduleBackGroundColor={isDouglasElliman ? '#100B28' : '#D92F25'}
                                    rescheduleColor="#fff"
                                    rescheduleShadow="0 1px 1px #161D251A"
                                    rescheduleBorder="1px solid #D92F25"
                                    cancelText={this.state.editHash ? 'Cancel' : ''}
                                    cancelColor="#D92F25"
                                    cancelBackGroundColor="#fff"
                                    cancelShadow="0 1px 1px #161D251A"
                                    cancelBorder="1px solid #D92F25"
                                    // paragraph={
                                    //     <div>
                                    //         {(moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description) ||
                                    //         (moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.long_description) ? (
                                    //             <div style={{ fontWeight: 'bold' }}>About</div>
                                    //         ) : null}
                                    //         <div>
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description
                                    //                 ? moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.short_description
                                    //                 : moveData &&
                                    //                   moveData.assigned_concierge &&
                                    //                   moveData.assigned_concierge.long_description}{' '}
                                    //             {this.state.moreClicked == false &&
                                    //             moveData &&
                                    //             moveData.assigned_concierge &&
                                    //             moveData.assigned_concierge.short_description ? (
                                    //                 <a
                                    //                     style={{
                                    //                         color: '#0066F5',
                                    //                         textDecoration: 'underline',
                                    //                         cursor: 'pointer',
                                    //                     }}
                                    //                     onClick={() => {
                                    //                         this.setState({ moreClicked: true });
                                    //                     }}
                                    //                 >
                                    //                     Learn More
                                    //                 </a>
                                    //             ) : null}
                                    //         </div>
                                    //     </div>
                                    // }
                                    onClick={() => this.calendlyHandle()}
                                    onClickReschedule={() => {
                                        this.calendlyHandleReschedule();
                                    }}
                                    onClickCancel={() => {
                                        this.cancelModalOpened();
                                    }}
                                />
                            )}
                        </div>
                        <div className="otherCardDiv">
                            <div className="getStartedDashBoard">
                                {this.state.inCompleted.length ? (
                                    this.state.inCompleted.slice(0, 2).map((item: any, i: number) => {
                                        let icon = item.url.includes('/dashboard/internet')
                                            ? isDouglasElliman
                                                ? ProvidersD
                                                : Providers
                                            : item.url.includes('/dashboard/utilities')
                                            ? isDouglasElliman
                                                ? utilityD
                                                : Utilities
                                            : item.url.includes('/dashboard/autoease')
                                            ? isDouglasElliman
                                                ? movingD
                                                : MovingCar
                                            : item.url.includes('/dashboard/address')
                                            ? isDouglasElliman
                                                ? changeAddressD
                                                : ChangeAddress
                                            : item.url.includes('/dashboard/ourvendors')
                                            ? isDouglasElliman
                                                ? homeRepairD
                                                : HomeRepair
                                            : item.url.includes('/dashboard/storage')
                                            ? isDouglasElliman
                                                ? storageD
                                                : Storage
                                            : item.url.includes('/dashboard/home-insurance')
                                            ? isDouglasElliman
                                                ? insuranceD
                                                : MaskGroup
                                            : item.url.includes('/dashboard/movers')
                                            ? isDouglasElliman
                                                ? shippingD
                                                : Moving
                                            : item.url.includes('/dashboard/insurance')
                                            ? isDouglasElliman
                                                ? insurance
                                                : homeInsuranceIllustration
                                            : item.icon;
                                        return (
                                            <GetStartedMovingServiceCard
                                                key={i}
                                                className={item.className}
                                                cardImage={icon}
                                                // cardImage={item.cardImage}
                                                cardTitle={item.cardTitle}
                                                cardSubtitle={item.cardSubtitle}
                                                buttonTitle={item.buttonTitle}
                                                progress={item.progress}
                                                onClick={() => {
                                                    this.serviceCardHandler(item);
                                                }}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="getStartedSkeleton">
                                        <GetStartedMovingServiceCard className="dashboardCardSkeleton" />
                                        <GetStartedMovingServiceCard className="dashboardCardSkeleton" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                { (!isDouglasElliman &&  this.state.customServices.length > 0) &&
                    <div className='mainDashboardBottom'>
                        <div className="dashboardBlocks">
                            <div className="blocksTitleDiv">
                                <div className="checklistIconDiv">
                                    <LanguageOutlined style={{color:"#6B6C6F"}}/>{" "}
                                </div>
                                <div className="blocksTitle">{getServiceTitle()}</div>
                            </div>
                            <div className="homeserviceCardFlexDiv1 ownerDashboardServicesCardCover">
                                <Slider {...sliderSettings}>
                                    { this.state.customServices.length > 0 &&
                                        this.state.customServices.map((item: any, i: number) => {
                                            return (
                                                <HomeServiceCard
                                                    key={i}
                                                    image={item.image}
                                                    title={item.name?.length > 30 ? `${item.name?.slice(0, 30)}...` : item.name || ''}
                                                    buttonText={"Learn More"}
                                                    onButtonClick={() => this.props.history.push(`/dashboard/${item.slug}`)}
                                                />
                                            );
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                }
                <div className="MovingChecklistMainDiv">
                    <div className="MovingChecklistTitleDiv">
                        <div className="movingchecklist">
                            <div className="checklistIconDiv">
                                <img src={ChecklistIcon} alt="" />
                            </div>
                            <div className="checklistTitle">Your moving checklist</div>
                        </div>
                        <div
                            className={
                                isDouglasElliman
                                    ? 'viewAllChecklistMobile viewAllChecklistMobileD'
                                    : 'viewAllChecklistMobile'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/checklist');
                            }}
                        >
                            View All
                        </div>
                    </div>
                    <div className={isDouglasElliman ? 'tab-div tab-divD' : 'tab-div'}>
                        <div className="tabs">
                            <div
                                className={this.state.isServiceChecklist ? 'tabButton-active' : 'tabButton'}
                                onClick={this.onServiceClick}
                            >
                                All Services ({this.state.checklistData.length})
                            </div>
                            <div
                                className={this.state.isCompletedChecklist ? 'tabButton-active' : 'tabButton'}
                                onClick={this.onCompletedClick}
                            >
                                Completed ({this.state.completedChecklistData.length})
                            </div>
                        </div>
                        <div
                            className="viewAllChecklist"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.props.history.push('/dashboard/checklist');
                            }}
                        >
                            View All Checklist
                        </div>
                    </div>
                    {this.state.run == true && this.state.stepIndex == 2 ? (
                        <div style={{ display: 'flex' }}>
                            <div className="step-3-left-cards">
                                <div className="step-left-card">
                                    <MovingNextCard
                                        title="Set up utilities"
                                        subTitle="Easily set up your utilities in a few easy steps so they’re ready when you move in."
                                        icon={isDouglasElliman ? utilityD : fourIcons}
                                        buttonText="Set Up Utilities"
                                        buttonColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? `${slug.primary_color}`
                                                : '#002E60'
                                        }
                                        buttonBackGroundColor="#fff"
                                        buttonShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0  ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                  }`
                                        }
                                        buttonWidth="130px"
                                        // border="none"
                                        boxShadow="none"
                                        height="160px"
                                        imageHeight="100px"
                                    />
                                </div>

                                <div className="step-left-card">
                                    <MovingNextCard
                                        title="Get Storage Facility"
                                        subTitle="Best quotes on storage facility guaranteed."
                                        icon={isDouglasElliman ? storageD : storage}
                                        buttonText="Get a Quote"
                                        buttonColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? `${slug.primary_color}`
                                                : '#002E60'
                                        }
                                        buttonBackGroundColor="#fff"
                                        buttonShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0  ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                  }`
                                        }
                                        buttonWidth="130px"
                                        // border="none"
                                        boxShadow="none"
                                        height="160px"
                                        imageHeight="100px"
                                    />
                                </div>

                                <div className="step-left-card">
                                    <MovingNextCard
                                        title="Install a security system"
                                        subTitle="Ensure your new home is set up to be safe and secure."
                                        icon={isDouglasElliman ? homeSecurityD : security}
                                        buttonText="Learn More"
                                        buttonColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? `${slug.primary_color}`
                                                : '#273E59'
                                        }
                                        buttonBackGroundColor="#fff"
                                        buttonShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0  ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                  }`
                                        }
                                        buttonWidth="130px"
                                        // border="none"
                                        boxShadow="none"
                                        height="160px"
                                        imageHeight="100px"
                                    />
                                </div>

                                <div className="step-left-card">
                                    <MovingNextCard
                                        title="Change your address"
                                        subTitle="Update your address and set up mail forwarding. It takes 5 minutes!"
                                        icon={isDouglasElliman ? changeAddressD : Map}
                                        buttonText="Change Address"
                                        buttonColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? `${slug.primary_color}`
                                                : '#002E60'
                                        }
                                        buttonBackGroundColor="#fff"
                                        buttonShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0  ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273E59'
                                                  }`
                                        }
                                        buttonWidth="130px"
                                        // border="none"
                                        boxShadow="none"
                                        height="160px"
                                        imageHeight="100px"
                                    />
                                </div>
                                <div className="empty-div"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="checklistCardDiv">
                            {this.state.isServiceChecklist && this.state.checklistData.length > 0 ? (
                                this.state.checklistData.map((item: any, i: number) => {
                                    let icon = item.url.includes('/dashboard/internet')
                                        ? isDouglasElliman
                                            ? ProvidersD
                                            : Providers
                                        : item.url.includes('/dashboard/utilities')
                                        ? isDouglasElliman
                                            ? utilityD
                                            : Utilities
                                        : item.url.includes('/dashboard/autoease')
                                        ? isDouglasElliman
                                            ? movingD
                                            : MovingCar
                                        : item.url.includes('/dashboard/address')
                                        ? isDouglasElliman
                                            ? changeAddressD
                                            : ChangeAddress
                                        : item.url.includes('/dashboard/ourvendors')
                                        ? isDouglasElliman
                                            ? homeRepairD
                                            : HomeRepair
                                        : item.url.includes('/dashboard/storage')
                                        ? isDouglasElliman
                                            ? storageD
                                            : Storage
                                        : item.url.includes('/dashboard/home-insurance')
                                        ? isDouglasElliman
                                            ? insuranceD
                                            : MaskGroup
                                        : item.url.includes('/dashboard/movers')
                                        ? isDouglasElliman
                                            ? shippingD
                                            : Moving
                                        : item.icon;

                                    // Junk removal
                                    //
                                    // Order Packing Supplies
                                    return (
                                         <MovingNextCard
                                            key={i}
                                            className="dashboardChecklistCard"
                                            title={item.title}
                                            subTitle={item.subTitle}
                                            icon={icon}
                                            buttonText={item.buttonText}
                                            buttonColor={
                                                isDouglasElliman
                                                    ? '#FFF'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#002E60'
                                            }
                                            buttonBackGroundColor={isDouglasElliman ? '#100B28' : 'transparent'}
                                            boxShadow="none"
                                            buttonShadow={isDouglasElliman ? 'none' : '0 1px 1px #161D251A'}
                                            onClick={() => {
                                                this.serviceCardHandler(item);
                                            }}
                                        />
                                    );
                                })
                            ) : this.state.isServiceChecklist && !this.state.pendingTasksData.length ? (
                                <div className="movingCardSkeleton">
                                    <MovingNextCard className="dashboardChecklistCardSkeleton" />
                                    <MovingNextCard className="dashboardChecklistCardSkeleton" />
                                </div>
                            ) : null}
                            {this.state.isCompletedChecklist
                                ? this.state.completedChecklistData.map((item: any, i: number) => {
                                      let icon = item.url.includes('/dashboard/internet')
                                          ? isDouglasElliman
                                              ? ProvidersD
                                              : Providers
                                          : item.url.includes('/dashboard/utilities')
                                          ? isDouglasElliman
                                              ? utilityD
                                              : Utilities
                                          : item.url.includes('/dashboard/autoease')
                                          ? isDouglasElliman
                                              ? movingD
                                              : MovingCar
                                          : item.url.includes('/dashboard/address')
                                          ? isDouglasElliman
                                              ? changeAddressD
                                              : ChangeAddress
                                          : item.url.includes('/dashboard/ourvendors')
                                          ? isDouglasElliman
                                              ? homeRepairD
                                              : HomeRepair
                                          : item.url.includes('/dashboard/storage')
                                          ? isDouglasElliman
                                              ? storageD
                                              : Storage
                                          : item.url.includes('/dashboard/home-insurance')
                                          ? isDouglasElliman
                                              ? insuranceD
                                              : MaskGroup
                                          : item.url.includes('/dashboard/movers')
                                          ? isDouglasElliman
                                              ? shippingD
                                              : Moving
                                          : item.icon;
                                      return (
                                          <MovingChecklistCompletedCard
                                              key={i}
                                              cardImage={icon}
                                              cardTitle={item.title}
                                              cardSubtitle={item.subTitle}
                                              onEdit={() => this.onEditCompleteCard(item.url)}
                                              // tasks={['American Van Lines', 'U-Pack']}
                                          />
                                      );
                                  })
                                : null}
                        </div>
                    )}
                </div>

                {this.state.pendingTasksData.length ? (
                    <div className="pendingTasksMainDiv">
                        <div className="blogsTitleDiv">
                            <div className="checklistIconDiv">
                                <img src={Pending} alt="" />
                            </div>
                            <div className="checklistTitle">{`Pending tasks (${this.state.pendingTasksData.length})`}</div>
                        </div>
                        <div className={`pendingTasksCardsDiv ${!isDouglasElliman ? 'pendingCardWrapper' : ''}`}>
                            {this.state.pendingTasksData.map((item: any, i: number) => {
                                return (
                                    <div key={i} className="pendingTaskCard">
                                        <PendingTasksCard
                                            isDouglasElliman={isDouglasElliman}
                                            data={item}
                                            onClick={() => {
                                                this.pendingCardHandler(item.url, item.percentage, item.id);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}

                <div className="blogsMainDiv">
                    <div className="blogsHeadingDiv">
                        <div className="blogsTitleDiv">
                            <div className="checklistIconDiv">
                                <img src={BlogIcon} alt="" />
                            </div>
                            <div className="checklistTitle">Blogs</div>
                        </div>
                        {/* <div className="viewAllChecklist">View More</div> */}
                    </div>
                    {slug && slug.slug === 'sibcycline' ? (
                        <div className="BlogsCardDiv">
                            <div className="blogCard-div1SibcyCline">
                                <BlogCardTopImage
                                    icon={SibcyCLineBlog}
                                    title="Haus and Home Magazine"
                                    subTitle=" A Guide To Midwestern Living"
                                    viewLink="https://haushomemagazine.com/"
                                    analyticsData={analyticsData}
                                />
                            </div>
                            <div className="blogCard-div1SibcyCline">
                                <BlogCardTopImage
                                    icon={SibcyClineNewsBlog}
                                    title=" Sibcy Cline News"
                                    subTitle="Stay up to date on the latest news from Sibcy Cline Realtors"
                                    viewLink="https://sibcyclinenews.com/category/sibcyclinenews/"
                                    analyticsData={analyticsData}
                                />
                            </div>
                            {/* <div className="blogCard-div2">
                                <BlogCardTopImage
                                    icon={Blog1}
                                    title="How To Determine The Best Cable & Internet Provider"
                                    subTitle="Your cable & internet connection is not something you typically think about, until it stops..."
                                    viewLink="http://blog.moveeasy.com/index.php/how-to-determine-your-internet-provider/"
                                />
                            </div> */}
                        </div>
                    ) : (
                        <div className="BlogsCardDiv fromMoversDash">
                            <div className="blogCard-div1">
                                <BlogCardRightImage
                                    icon={Blog2}
                                    title="A Guide to Home Owners Insurance and Why You Need It"
                                    subTitle="Now that you’ve purchased your home you may be asking yourself how to keep it protected? Sure..."
                                    viewLink="http://blog.moveeasy.com/index.php/a-guide-to-home-owners-insurance-and-why-you-need-it/"
                                    analyticsData={analyticsData}
                                    height={'100%'}
                                    imageHeight={'100%'}
                                />
                            </div>
                            <div className="blogCard-div1Mobile">
                                <BlogCardTopImage
                                    icon={Blog2}
                                    title="A Guide to Home Owners Insurance and Why You Need It"
                                    subTitle="Now that you’ve purchased your home you may be asking yourself how to keep it protected? Sure..."
                                    viewLink="http://blog.moveeasy.com/index.php/a-guide-to-home-owners-insurance-and-why-you-need-it/"
                                    analyticsData={analyticsData}
                                    height={'100%'}
                                    imageHeight={'100%'}
                                />
                            </div>
                            <div className="blogCard-div2">
                                <BlogCardTopImage
                                    icon={Blog3}
                                    title="100 Things to Do When You Move Into a New Home"
                                    subTitle="Your move has finally been completed and now you’re in your new home, but now what..."
                                    viewLink="http://blog.moveeasy.com/index.php/100-things-to-do-when-you-move-into-a-new-home/"
                                    analyticsData={analyticsData}
                                    height={'100%'}
                                    imageHeight={'100%'}
                                />
                            </div>
                            <div className="blogCard-div2">
                                <BlogCardTopImage
                                    icon={Blog1}
                                    title="How To Determine The Best Cable & Internet Provider"
                                    subTitle="Your cable & internet connection is not something you typically think about, until it stops..."
                                    viewLink="http://blog.moveeasy.com/index.php/how-to-determine-your-internet-provider/"
                                    analyticsData={analyticsData}
                                    height={'100%'}
                                    imageHeight={'100%'}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {isDouglasElliman ? (
                    <div className="mortgage-last-div">
                        <div className="mortgage-pleasenote">Please Note:</div>
                        <div style={{ color: '#6B6C6F', fontSize: '12px', textAlign: 'left' }}>
                            {displayData && displayData[0] && displayData[0].disclaimer ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: displayData && displayData[0] && displayData[0].disclaimer,
                                    }}
                                />
                            ) : (
                                'This Site contains general information with respect to departments of motor vehicles in the various United States for informational purposes only. The information and features included in this Site have been compiled from a variety of sources and are for informational purposes only, and are subject to change at any time without notice. This Site and all information it contains are provided "AS IS." While we will attempt to keep the Information accurate, we cannot and do not guarantee the accuracy of the Information, and we accept no responsibility, and shall have no liability, for any loss or damage which may arise from using or relying on the Information. If you believe this Information is inaccurate, please let us know by contacting us at help@moveeasy.com to help us serve you and other customers like you better.'
                            )}
                        </div>
                    </div>
                ) : null}

                {
                    <PrintModal
                        isShowModal={this.state.cancelOpen}
                        onCloseModal={this.cancelModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                    >
                        <div className="cancellation-modal">
                            <div className="logo-heading-div">
                                <div className="moveaesy-logo">
                                    <img src={moveEasy} alt="" />
                                </div>
                                <div className="cancellation-heading">Appointment With Moving Concierge</div>
                            </div>
                            <div className="modal-body">
                                <div className="left-detail">
                                    <div className="appointment-details-heading">Appoinment Details</div>
                                    <div className="appointment-details">
                                        Call is Scheduled On
                                        <div className="details">
                                            {`${new Date(this.state.appointmentTime)
                                                .toString()
                                                .substring(0, 15)},${new Date(
                                                this.state.appointmentTime,
                                            ).toLocaleTimeString('en-US')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="right-form">
                                    <div className="reason-cancellation">Reason For Cancelling</div>
                                    <div className="input-reason">
                                        <TextField
                                            id="standard-basic"
                                            label="Enter Reason"
                                            variant="standard"
                                            onChange={(e: any) => {
                                                this.setState({
                                                    reason: e.target.value,
                                                });
                                            }}
                                        />
                                        {!this.state.reason ? (
                                            <div style={{ fontSize: '14px', color: 'red' }}>
                                                {this.state.reasonError}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="cancel-event-button">
                                        <Button onClick={this.cancelButtonClicked} width="150px">
                                            Cancel Event
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintModal>
                }
                {this.state.calendlyOpen && (
                    <PrintModal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={this.nylasModalClosed}
                        showClose={false}
                        className="dashboardCalandly"
                    >

                        {(this.state.scheduleClicked && this.state.nylas_full_service_scheduler_url) ||
                        (this.state.rescheduleClicked && this.state.reschedule_link) ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${
                                        this.state.scheduleClicked
                                            ? this.state.nylas_full_service_scheduler_url
                                            : this.state.rescheduleClicked
                                            ? this.state.reschedule_link
                                            : null
                                    }&utm_source=platform&utm_medium=web&utm_content=dashboard&utm_campaign=moving-platform`}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </PrintModal>
                )}

                {this.state.showPods ? (
                    <div>
                        <Dialog
                            open={this.state.showPods}
                            onClose={this.handleClosePODSModal}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10%' }}>
                                <img src={PODSSvg} width="200px" height="70px" alt="" />
                            </div>
                            <DialogTitle id="alert-dialog-title">
                                {'You will be rerouted to PODS website to complete your order'}
                            </DialogTitle>

                            <DialogActions>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <Button
                                        onClick={this.handleClosePODSModal}
                                        width="300px"
                                    >
                                        <a
                                            href="https://www.pods.com/partners/moveeasy"
                                            target="_blank"
                                            style={{ color: '#ffffff' }}
                                        >
                                            Continue to PODS Website
                                        </a>
                                    </Button>
                                    <Button
                                        onClick={this.handleClosePODSModal}
                                        width="300px"
                                        backgroundColor="#FFFFFF"
                                        boxShadow={
                                            slug && slug.primary_color
                                                ? `0 0 3px 0 ${slug.primary_color}`
                                                : '0 0 3px 0 #273E59'
                                        }
                                        color={slug && slug.primary_color ? `${slug.primary_color}` : '#273E59'}
                                        borderRadius="5px"
                                        marginTop="20px"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        dashboardAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    CheckListDashboardState: state.checklistDashboard,
    commonState: state.common,
});

Dashboard.contextType = UIContext

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
