export const UPDATE_ADDRESS_NEW_SERVICES_REQUEST = 'UPDATE_ADDRESS_NEW_SERVICES_REQUEST';
export const UPDATE_ADDRESS_NEW_SERVICES_SUCCESS = 'UPDATE_ADDRESS_NEW_SERVICES_SUCCESS';
export const UPDATE_ADDRESS_NEW_SERVICES_FAILURE = 'UPDATE_ADDRESS_NEW_SERVICES_FAILURE';

export const UPDATE_ADDRESS_REQUEST_FILED_REQUEST = 'UPDATE_ADDRESS_REQUEST_FILED_REQUEST';
export const UPDATE_ADDRESS_REQUEST_FILED_SUCCESS = 'UPDATE_ADDRESS_REQUEST_FILED_SUCCESS';
export const UPDATE_ADDRESS_REQUEST_FILED_FAILURE = 'UPDATE_ADDRESS_REQUEST_FILED_FAILURE';

export const UPDATE_ADDRESS_PHONE_VERIFY_REQUEST = 'UPDATE_ADDRESS_PHONE_VERIFY_REQUEST';
export const UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS = 'UPDATE_ADDRESS_PHONE_VERIFY_SUCCESS';
export const UPDATE_ADDRESS_PHONE_VERIFY_FAILURE = 'UPDATE_ADDRESS_PHONE_VERIFY_FAILURE';

export const UPDATE_ADDRESS_OTP_VERIFY_REQUEST = 'UPDATE_ADDRESS_OTP_VERIFY_REQUEST';
export const UPDATE_ADDRESS_OTP_VERIFY_SUCCESS = 'UPDATE_ADDRESS_OTP_VERIFY_SUCCESS';
export const UPDATE_ADDRESS_OTP_VERIFY_FAILURE = 'UPDATE_ADDRESS_OTP_VERIFY_FAILURE';

export const UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST = 'UPDATE_ADDRESS_DELETE_REQUEST_FILED_REQUEST';
export const UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS = 'UPDATE_ADDRESS_DELETE_REQUEST_FILED_SUCCESS';
export const UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE = 'UPDATE_ADDRESS_DELETE_REQUEST_FILED_FAILURE';

export const UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST = 'UPDATE_ADDRESS_SAVE_RESPONSE_REQUEST';
export const UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS = 'UPDATE_ADDRESS_SAVE_RESPONSE_SUCCESS';
export const UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE = 'UPDATE_ADDRESS_SAVE_RESPONSE_FAILURE';

export const UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST = 'UPDATE_ADDRESS_UPDATE_RESPONSE_REQUEST';
export const UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS = 'UPDATE_ADDRESS_UPDATE_RESPONSE_SUCCESS';
export const UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE = 'UPDATE_ADDRESS_UPDATE_RESPONSE_FAILURE';