import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import HomeBanner from '../HomeBanner';
import './AffordablityChart.scss';
import NavigationTop from './NavigationTop';
import CongratsQuestion from '../SavingCalculator/CongratsQuestion';
interface chartProps {
    widtd?: string;
    height?: string;
    boxShadow?: any;
    onAgentContact: any;
    affordabilyDataFromAPI?: any;
    onPostAffordablityDataPost?: any;
    affordcalculator: any;
    rent: number;
    serviceAgentData?: any;
    serviceProviders?: any;
    agentImage?: any;
    primaryData: any;
    homeValue: any;
    primaryMonthlyPayment: any;
    analyticsData: any;
}

const SVGCustomCircle = ({ color = 'red', strokeColor = 'red', strokeWidtd = '2', size = 1 }) => (
    <svg height={size} width={size}>
        <circle
            cx={16 / 2}
            cy={16 / 2}
            r={12 / 2 - (12 / 2) * 0.1}
            stroke={strokeColor}
            stroke-widtd={strokeWidtd}
            fill={color}
        />
    </svg>
);

function numberWithCommas(num: any) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const AffordabilityChart = (props: chartProps) => {
    const {
        boxShadow,
        onAgentContact,
        affordabilyDataFromAPI,
        affordcalculator,
        rent,
        serviceAgentData,
        serviceProviders,
        agentImage,
        primaryData,
        homeValue,
        primaryMonthlyPayment,
        analyticsData
    } = props;

    const [isDropDownOpen, updateDropDown] = React.useState(false);
    const [principleAndInterest, setPrincipleAndInterest] = useState<number>(0);
    const [propertyTaxes, setPropertyTaxes] = useState<number>(0);
    const [homeOwnerInsurance, setHomeOwnerInsurance] = useState<number>(0);
    const [HOA, setHOA] = useState<number>(0);

    const [utility, setUtility] = useState<number>(0);
    const [internet, setInternet] = useState<number>(0);
    const [homeSecurity, setHomeSecurity] = useState<number>(0);
    const [homeWarrenty, setHomeWarrenty] = useState<number>(0);
    const [otherExpenses, setOtherExpenses] = useState<number>(internet + utility + homeSecurity + homeWarrenty);
    const [totalExpenses, setTotalExpenses] = useState<number>(0);
    const [navigationTab, setNavigationTab] = useState(0);


    useEffect(() => {
        if (affordcalculator !== 0) {
            setNavigationTab(0);
        }
    
      return () => {
        setNavigationTab(0)
      }
    }, [affordcalculator])
    

    useEffect(() => {
        const setChartFieldsData = (data: any) => {
            setPropertyTaxes(data.propertyTax);
            setHomeOwnerInsurance(data.insurance);
            setPrincipleAndInterest(data.pricipleAndInterest);
        }
        const setChartData = () => {
            const { home_owner_insurance, property_tax, monthly_loan_payment } = affordabilyDataFromAPI;
           
            if (affordcalculator === 0 || !primaryData || !homeValue || !primaryMonthlyPayment) {
                setPropertyTaxes(property_tax);
                setHomeOwnerInsurance(home_owner_insurance);
                setPrincipleAndInterest(monthly_loan_payment);
            } else {

                const { home_insurance_amount, property_tax_percentage} = primaryData;
                const propertyTaxAmount = Math.round(homeValue * (property_tax_percentage / 100));
                const chartData: any = {};

                if (navigationTab === 0) {
                    chartData.propertyTax = property_tax + propertyTaxAmount;
                    chartData.insurance = home_owner_insurance + home_insurance_amount;
                    chartData.pricipleAndInterest = monthly_loan_payment + primaryMonthlyPayment;
                    setChartFieldsData(chartData);
    
                } else if (navigationTab === 1) {
                    chartData.propertyTax = propertyTaxAmount;
                    chartData.insurance = home_insurance_amount;
                    chartData.pricipleAndInterest = primaryMonthlyPayment;
                    setChartFieldsData(chartData);
                } else {
                    setPropertyTaxes(property_tax);
                    setHomeOwnerInsurance(home_owner_insurance);
                    setPrincipleAndInterest(monthly_loan_payment);
                }
            }
        };
        
        if (affordabilyDataFromAPI) {
            setChartData();
        }

        return () => {
            setPropertyTaxes(0);
            setHomeOwnerInsurance(0);
        };
    }, [affordabilyDataFromAPI, navigationTab, affordcalculator, primaryData, homeValue, primaryMonthlyPayment]);

    const setDropDown = () => {
        updateDropDown(!isDropDownOpen);
    };

    const onChangeHomeOwnerInsuranceValue = (event: any) => {
        setHomeOwnerInsurance(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };

    const onChangeHOAValue = (event: any) => {
        setHOA(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };

    const onChangeOtherExpensesValue = (event: any) => {
        setOtherExpenses(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };

    const onChangeUtilityValue = (event: any) => {
        setUtility(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };

    const onChangeInternetValue = (event: any) => {
        setInternet(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };

    const onChangeHomeSecurityValue = (event: any) => {
        setHomeSecurity(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };
    const onChangeHomeWarrentysValue = (event: any) => {
        setHomeWarrenty(event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : 0);
    };
    useEffect(() => {
        setOtherExpenses(internet + utility + homeSecurity + homeWarrenty);
    }, [internet, utility, homeSecurity, homeWarrenty]);

    useEffect(() => {
        setTotalExpenses(principleAndInterest + propertyTaxes + homeOwnerInsurance + HOA + otherExpenses);
    }, [principleAndInterest, propertyTaxes, homeOwnerInsurance, HOA, otherExpenses]);

    const showRant = affordcalculator == 2 && navigationTab != 1;
    const chartData = [principleAndInterest, propertyTaxes, homeOwnerInsurance, HOA, otherExpenses];
    const data = {
        datasets: [
            {
                data: showRant ? [...chartData, rent] : chartData,
                backgroundColor: ['#0567FE', '#F64785 ', '#F4AE33', '#5CBD9D', '#273E59', '#D92F25'],
                hoverOffset: 4,
                circumference: 10,
                weight: 10,
                borderWidth: 0,
            },
        ],
        labels: ['Principal And Interset', 'Property Taxes', 'Home Owner Insurance', 'HOA', 'Other Expenses', 'Rent'],
    };
   
    const totalExpenseAmount = totalExpenses ? Math.round(showRant ? totalExpenses - rent : totalExpenses) : 0;

    const options = {
        centerText: {
            display: true,
            text: `Total monthly payment`,
            text2: `${numberWithCommas(totalExpenseAmount >= 0 ? totalExpenseAmount : 0)}`,
            text3: '$'
        },
        legend: { display: false },
        cutoutPercentage: 70,
        maintainAspectRatio: false,
    }

    const plugins = [{
        beforeDraw: function (chart: any) {
            let width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;
            ctx.restore();
            ctx.font = `12px Roboto`;
            ctx.textBaseline = "bottom";
             let text = chart.config.options.centerText.text,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2 - 10;
            ctx.fillText(text, textX, textY);
            ctx.save();
            ctx.font = `bold 24px Poppins`;
            ctx.textBaseline = "top";
             let text2 = chart.config.options.centerText.text2,
                textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
                textY2 = height / 2;
            ctx.fillText(text2, textX2, textY2);
            ctx.save();
            ctx.font = `600 14px Poppins`;
            ctx.textBaseline = "top";
             let text3 = chart.config.options.centerText.text3,
                textX3 = Math.round((width - ctx.measureText(text3).width) / 2 - (totalExpenseAmount > 1000000 ? 62 : 53)),
                textY3 = height / 2 - 5;
            ctx.fillText(text3, textX3, textY3);
            ctx.save();
        }
      }]
      
    return (
        <div
            style={{
                boxShadow: boxShadow ? boxShadow : '0px 1px 3px #0000000A',
            }}
        >
            {affordcalculator !== 0 && primaryData && homeValue && primaryMonthlyPayment && <NavigationTop navigationTab={navigationTab} setNavigationTab={setNavigationTab} showRental={affordcalculator == 2}  />}
          {affordcalculator === 0 && <h4 className='affTitle'>My Monthly Expenses will be</h4>}
            <div style={{ position: 'relative' }} className="doughnutChartCover">
                <div>
                    <Doughnut data={data} options={options} plugins={plugins} />
                </div>
            </div>
            <div className="AffChartData">
                <table className="AffChartDataContainer">
                    <tbody>
                    {!isDropDownOpen ? (
                        <>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {SVGCustomCircle({
                                        color: '#0567FE',
                                        strokeColor: '#0567FE',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">Principle & Interest</td>
                                <td className="inputBoxData"> $ {principleAndInterest ? numberWithCommas(Math.round(principleAndInterest)) : 0}</td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {' '}
                                    {SVGCustomCircle({
                                        color: '#F64785',
                                        strokeColor: '#F64785',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">Property Taxes</td>
                                <td className="inputBoxData"> $ {numberWithCommas(Math.round(propertyTaxes) || 0)}</td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {' '}
                                    {SVGCustomCircle({
                                        color: '#F4AE33',
                                        strokeColor: '#F4AE33',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">Homeowners Insurance</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeHomeOwnerInsuranceValue(event)}
                                            value={numberWithCommas(Math.round(homeOwnerInsurance) || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {' '}
                                    {SVGCustomCircle({
                                        color: '#5CBD9D',
                                        strokeColor: '#5CBD9D',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">HOA</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeHOAValue(event)}
                                            value={numberWithCommas(HOA || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {' '}
                                    {SVGCustomCircle({
                                        color: '#273E59',
                                        strokeColor: '#273E59',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">Other Home Expenses</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                        }}
                                        className="inputFeildMain1 oldExpenseInputBox"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeOtherExpensesValue(event)}
                                            value={numberWithCommas(otherExpenses || 0)}
                                        />
                                    </div>
                                </td>

                                <td
                                    onClick={() => {
                                        setDropDown();
                                    }}
                                    className="dropDownIconCover"
                                >
                                    {' '}
                                    {isDropDownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </td>
                            </tr>
                        </>
                    ) : (
                        <>
                            <tr className="tableConatinerMain">
                                <td></td>
                                <td className="tableNameHeading">Principal+Intrest+HOA...</td>
                                <td className="inputBoxData">
                                    ${principleAndInterest + propertyTaxes + homeOwnerInsurance + HOA}
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot">
                                    {' '}
                                    {SVGCustomCircle({
                                        color: '#273E59',
                                        strokeColor: '#273E59',
                                        size: 15,
                                    })}
                                </td>
                                <td className="tableNameHeading">Other Home Expenses</td>
                                <td className="inputBoxData">${otherExpenses}</td>

                                <td
                                    onClick={() => {
                                        setDropDown();
                                    }}
                                    className="dropDownIconCover"
                                >
                                    {' '}
                                    {isDropDownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </td>
                            </tr>

                            <tr className="tableConatinerMain">
                                <td className="tableBot"></td>
                                <td className="tableNameHeading">Utility</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                            padding: '1px 10px 1px 10px',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeUtilityValue(event)}
                                            value={numberWithCommas(utility || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot"></td>
                                <td className="tableNameHeading">Internet & Cable</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                            padding: '2px 10px 2px 10px',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeInternetValue(event)}
                                            value={numberWithCommas(internet || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot"></td>
                                <td className="tableNameHeading">Home Security</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                            padding: '2px 10px 2px 10px',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeHomeSecurityValue(event)}
                                            value={numberWithCommas(homeSecurity || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr className="tableConatinerMain">
                                <td className="tableBot"></td>
                                <td className="tableNameHeading">Home Warranty</td>
                                <td className="inputBoxData">
                                    <div
                                        style={{
                                            width: '50%',
                                            backgroundColor: '#fff',
                                            padding: '2px 10px 2px 10px',
                                        }}
                                        className="inputFeildMain1"
                                    >
                                        <span className="per">$</span>
                                        <input
                                            type="text"
                                            className="inputFeild1"
                                            onChange={event => onChangeHomeWarrentysValue(event)}
                                            value={numberWithCommas(homeWarrenty || 0)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </>
                    )}
                    {showRant &&  (
                        <tr className="tableConatinerMain">
                            <td className="tableBot">
                                {SVGCustomCircle({
                                    color: '#D92F25',
                                    strokeColor: '#D92F25',
                                    size: 15,
                                })}
                            </td>
                            <td className="tableNameHeading">Rent</td>
                            <td className="inputBoxData rentAmount">-${numberWithCommas(rent || 0)}</td>
                        </tr>
                    )}

                    <tr className="tableConatinerMain">
                        <td className="tableBot"></td>
                        <td className="Monthlyexpenses totalEx">Total Monthly Payment</td>
                        <td className="inputBoxData">
                            {' '}
                            $ {numberWithCommas(totalExpenseAmount >= 0 ? totalExpenseAmount : 0)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <CongratsQuestion
                onAgentContact={onAgentContact}
                agentImage={agentImage}
                displayText="Ready to search for your new home or have questions?"
                serviceAgentData={serviceAgentData}
                serviceProviders={serviceProviders}
                analyticsData={analyticsData}
            />
        </div>
    );
};
export default AffordabilityChart;
