import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={72.738}
            height={71}
            viewBox="0 0 72.738 71"
            className="checkBoximg"
        >
            <defs>
                <style>{`.hsleaksprefix__a{fill:${
                    slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                }}`}</style>
            </defs>
            <path
                className="hsleaksprefix__a"
                d="M36.369 67.371h.759a.87.87 0 00-.02-1.739h-.739a.87.87 0 000 1.739zM24.304 68.552a79.618 79.618 0 01-3-.427.87.87 0 10-.278 1.716c.992.161 2.024.308 3.068.436a.87.87 0 10.213-1.726zM53.258 67.806c-.807.153-1.647.3-2.5.426a97.141 97.141 0 01-14.391 1.029c-1.483 0-2.973-.031-4.429-.091a97.627 97.627 0 01-2.058-.107.87.87 0 10-.108 1.735 108.05 108.05 0 006.593.201 98.893 98.893 0 0014.654-1.049c.871-.133 1.732-.28 2.559-.437a.87.87 0 10-.324-1.708zM59.431 66.268c-.625.2-1.29.4-1.976.585a.87.87 0 10.454 1.678c.714-.193 1.406-.4 2.059-.609a.87.87 0 10-.536-1.654zM70.42 0h-5.6a2.321 2.321 0 00-2.22 1.668h-4.063a.869.869 0 00-.869.869v1.529H15.07V2.537a.869.869 0 00-.869-.869h-4.064A2.321 2.321 0 007.914 0h-5.6A2.321 2.321 0 000 2.318v25.568A2.321 2.321 0 002.318 30.2h5.6a2.321 2.321 0 002.224-1.669H14.2a.869.869 0 00.869-.869v-1.524h18.964a.869.869 0 00.775-.475l1.239-2.432a.869.869 0 00.07-.6l-.492-2.016 2.049 2.022v2.632a.869.869 0 00.869.869h19.126v1.529a.869.869 0 00.869.869H62.6a2.321 2.321 0 002.226 1.664h5.594a2.321 2.321 0 002.318-2.318V2.318A2.321 2.321 0 0070.42 0zM8.493 27.886a.58.58 0 01-.58.58h-5.6a.58.58 0 01-.58-.58v-6.554h6.754zm0-8.293H1.739v-8.982h6.754zm0-10.721H1.739V2.318a.58.58 0 01.58-.58h5.6a.58.58 0 01.58.58zM13.332 26.8h-3.1V3.407h3.1zm44.336-2.4H39.412v-2.128a.869.869 0 00-.259-.619l-4.49-4.431a.869.869 0 00-1.455.825l1.143 4.682L33.5 24.4H15.07V5.8h42.6zm4.839 2.4h-3.1V3.407h3.1zM71 27.886a.58.58 0 01-.58.58h-5.6a.58.58 0 01-.58-.58v-6.554H71zm0-8.293h-6.755v-8.982H71zm0-10.721h-6.755V2.318a.58.58 0 01.58-.58h5.6a.58.58 0 01.58.58zM30.683 67.123a.869.869 0 00.078-1.735c-1.1-.1-2.173-.232-3.192-.391a40.808 40.808 0 01-2.361-.437.87.87 0 10-.37 1.7c.779.169 1.608.323 2.463.456 1.055.164 2.165.3 3.3.4a.709.709 0 00.082.007z"
            />
            <path
                className="hsleaksprefix__a"
                d="M50.085 56.148a50.917 50.917 0 00-13.716-1.669 50.917 50.917 0 00-13.716 1.669c-4.059 1.188-6.2 2.841-6.2 4.779 0 2.15 2.647 3.55 4.867 4.346a.87.87 0 00.587-1.637c-1.8-.644-3.715-1.693-3.715-2.71 0-1.225 2.669-2.441 4.955-3.111a49.164 49.164 0 0113.227-1.6 49.164 49.164 0 0113.227 1.6c2.286.669 4.955 1.886 4.955 3.111 0 1.5-4.554 4-14.33 4.593a.869.869 0 00.052 1.737h.054a42.269 42.269 0 0011.056-1.969c2.239-.8 4.908-2.2 4.908-4.361-.006-1.934-2.151-3.589-6.211-4.778z"
            />
            <path
                className="hsleaksprefix__a"
                d="M37.239 62.766a.869.869 0 00-.869-.869 18.8 18.8 0 01-2.533-.163.87.87 0 00-.235 1.723 20.546 20.546 0 002.769.179.869.869 0 00.869-.869zM38.768 61.751l-.165.02a.87.87 0 10.2 1.727l.183-.022a.869.869 0 00-.22-1.724zM40.995 62.248a.869.869 0 001.165.394c1.122-.555 1.357-1.239 1.357-1.715 0-1.346-1.759-2.009-3.234-2.329a.87.87 0 10-.368 1.7 5.127 5.127 0 011.748.628 2.118 2.118 0 01-.273.158.869.869 0 00-.394 1.165z"
            />
            <path
                className="hsleaksprefix__a"
                d="M37.863 58.27c-.486-.033-.989-.05-1.494-.05a17.371 17.371 0 00-4.684.573c-1.635.478-2.463 1.2-2.463 2.134 0 .816.631 1.468 1.874 1.938a.87.87 0 00.615-1.627 2.9 2.9 0 01-.64-.317 11.221 11.221 0 015.3-.964c.465 0 .927.016 1.375.046a.87.87 0 10.119-1.735zM15.287 66.853a37.35 37.35 0 01-3.6-1.158c-3.733-1.432-5.874-3.17-5.874-4.767 0-1.979 3.207-4.1 8.58-5.673a51.176 51.176 0 013.726-.931.869.869 0 00-.361-1.7c-1.363.29-2.66.614-3.854.963-6.431 1.884-9.83 4.422-9.83 7.342 0 2.427 2.417 4.637 6.99 6.391a39.074 39.074 0 003.771 1.213.87.87 0 10.454-1.679zM60.866 54.238a.87.87 0 10-.58 1.64c4.22 1.491 6.64 3.331 6.64 5.05 0 1.124-1.054 2.119-1.938 2.757a15.125 15.125 0 01-2.611 1.461.87.87 0 10.714 1.585 16.836 16.836 0 002.914-1.636c1.765-1.272 2.66-2.674 2.66-4.167.001-2.574-2.696-4.887-7.799-6.69zM57.829 55.107a.87.87 0 10.468-1.675 54.88 54.88 0 00-3.315-.809.869.869 0 10-.361 1.7c1.124.242 2.208.503 3.208.784zM31.867 50.949a.87.87 0 10.072 1.737c1.456-.061 2.946-.091 4.429-.091a97.322 97.322 0 0114.27 1.011.87.87 0 10.26-1.719 97.7 97.7 0 00-14.53-1.031c-1.506 0-3.021.028-4.501.093zM21.966 53.615a.894.894 0 00.131-.01c1.175-.178 2.4-.335 3.645-.467a93.53 93.53 0 011.962-.187.87.87 0 10-.147-1.733c-.672.057-1.345.121-2 .191-1.268.135-2.52.3-3.721.477a.869.869 0 00.129 1.729zM42.079 35.796l-5-6.97a.869.869 0 00-1.411 0l-5 6.95q-.024.034-.045.07a5.936 5.936 0 00.032 6.008 6.578 6.578 0 0011.447-.007 5.906 5.906 0 00.026-5.984zm-1.491 5.186a4.84 4.84 0 01-8.431.006 4.162 4.162 0 01-.058-4.231l4.27-5.933 4.272 5.953a4.133 4.133 0 01-.054 4.206z"
            />
            <path
                className="hsleaksprefix__a"
                d="M39.142 37.452a.869.869 0 00-1.025.68 4.781 4.781 0 01-1.371 2.456.87.87 0 101.208 1.251 6.535 6.535 0 001.867-3.362.869.869 0 00-.679-1.025z"
            />
        </svg>
    );
}

export default SvgComponent;
