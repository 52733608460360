import React, { useState, useEffect } from 'react';
import Button from '../../../../components/atoms/Button';
import './Model.scss';
import { emailRegExp, fullNameRegExp, phoneRegExp, wordRegExp } from '../../../../utils/constants';
import GreenCheckMark from '../../../../Assets/images/green_check.png';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import AnalyticsService from '../../../../pages/analytics-service';

interface defaultPropsToMortageModal {
    onSubmit: any;
    isLoading?: boolean;
    details?: any;
    toggleModal?: any;
    property_valuations?: any;
    move_key?: string;
    type: string;
    userDetails?: any;
    onPrefferedMortgage?: any;
    providersData?: any;
    prefferedMortgage?: any;
    isSubmitSuccess?: boolean;
    analyticsData: any;
}

type Itypes = {
    [firstName: string]: boolean;
    lastName: boolean;
    email: boolean;
    phone: boolean;
}

export default function ContactDataModal({
    onSubmit,
    toggleModal,
    isLoading,
    move_key,
    type,
    userDetails,
    onPrefferedMortgage,
    providersData,
    prefferedMortgage,
    isSubmitSuccess,
    analyticsData
}: defaultPropsToMortageModal) {
    const [firstName, setFirstName] = useState(userDetails?.first_name || null);
    const [lastName, setLastName] = useState(userDetails?.last_name || null);
    const [email, setEmail] = useState(userDetails?.email || null);
    const [phone, setPhone] = useState(userDetails?.phone || null);
    const [contactType, setContactType] = useState('Email');
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState<Itypes>({
        firstName: false,
        lastName: false,
        email: false,
        phone: false
    })
    const [isFormStarted, setIsFormStarted] = useState(false);

    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        const setDefaultMortgage = () => {
            const mortgage = providersData[0];
            onPrefferedMortgage({
                name: mortgage.name,
                id: mortgage.id,
                email: mortgage.email,
                phone: mortgage.phone,
                address: mortgage.full_address,
            });
        };

        providersData?.length > 0 && setDefaultMortgage();

        return () => {};
    }, []);

    const handleMortgageChange = (mortgage: any) => {
        onPrefferedMortgage({
            name: mortgage.name,
            id: mortgage.id,
            email: mortgage.email,
            phone: mortgage.phone,
            address: mortgage.full_address,
        });
    };

    const contactData: any = {
        move_hash: move_key,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
    };

    const validateAll = () => {
        let tempErrors: any = { ...errors }
        let tempErrorMessages: any = { ...errorMessages }
        const fields: any = { 'email': email, 'firstName': firstName, 'phone': phone, 'lastName': lastName}
        Object.keys(fields).forEach((key) => {
            if (fields[key] === null || fields[key] === "") {
                tempErrors[key] = true
                tempErrorMessages[key] = 'This field is required'
            }
        })

        setErrors(tempErrors)
        setErrorMessages(tempErrorMessages)
    }

    const submitHandler = () => {

        const isNotEverythingFilled = [email, firstName, lastName, phone].some(val => val === null || val === '' || !val);
        validateAll()
        if (isNotEverythingFilled)  return
        const hasAnyError = Object.values(errors).some(val => val === true);
        contactData.communication = contactType;
        
        !hasAnyError && onSubmit(contactData);
    };

    const validate = (fieldValue: any, name: string) => {
        if (name == 'email') {
            if (fieldValue === '') {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'This feild is required.' });
            } else if (!fieldValue.match(emailRegExp)) {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'Please enter valid Email.' });
            } else {
                setErrors({ ...errors, [name]: false });
                setErrorMessages({ ...errorMessages, [name]: '' });
            }
        }

        if (name == 'firstName') {
            if (fieldValue === '') {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'This feild is required.' });
            } else if (!fieldValue.match(fullNameRegExp)) {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'Please enter valid First Name.' });
            } else {
                setErrors({ ...errors, [name]: false });
                setErrorMessages({ ...errorMessages, [name]: '' });
            }
        }

        if (name == 'lastName') {
            if (fieldValue === '') {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'This feild is required.' });
            } else if (!fieldValue.match(wordRegExp)) {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'Please enter valid Last Name.' });
            } else {
                setErrors({ ...errors, [name]: false });
                setErrorMessages({ ...errorMessages, [name]: '' });
            }
        }

        if (name == 'phone') {
            if (fieldValue === '') {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'This feild is required.' });
            } else if (!fieldValue.match(phoneRegExp)) {
                setErrors({ ...errors, [name]: true });
                setErrorMessages({ ...errorMessages, [name]: 'Please enter valid Phone Number.' });
            } else {
                setErrors({ ...errors, [name]: false });
                setErrorMessages({ ...errorMessages, [name]: '' });
            }
        }
    };

    const handleAnalytics = (fieldName: string) => {

        if (!isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
            ...analyticsData,
            category_name: 'Inputting Contact Form Field',
            button_copy: 'Submit',
            form_button:  true,
            form_pre_filled: true,
            form_field_name: fieldName
            }
            
            if (isFormStarted) {
                AnalyticsService.formFieldInput(trackProperties);
            } else {
                AnalyticsService.formFieldStarted(trackProperties);
            }
            setIsFormStarted(true);
        }

        setIsAnalitycsTriggerredOnce({
            ...isAnalitycsTriggerredOnce,
            [fieldName]: true
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const fieldName = e.target.name;

        if (fieldName === 'firstName') {
            setFirstName(value);
        }
        if (fieldName === 'lastName') {
            setLastName(value);
        }
        if (fieldName === 'email') {
            setEmail(value);
        }
        if (fieldName === 'phone') {
            setPhone(value);
        }

        validate(value, fieldName);

        handleAnalytics(fieldName)
       
    };

    const isNotEverythingFilled = Object.values(contactData).some(val => val === (null || undefined));

    return (
        <div className="mainDetailsContainer mortgageLoanDetailsCover">
            <div className="agentContactContainer">
                {type === 'agentContact' && !isSubmitSuccess && (
                    <div className="innerTitle">
                        <label>Select preferred mortgages for inquire</label>
                        <div className="rated">
                            {providersData?.length > 0 && (
                                <ul>
                                    {providersData.map((brokerage: any) => (
                                        <li
                                            key={brokerage?.id}
                                            className={`mortgageCard ${prefferedMortgage?.id === brokerage?.id &&
                                                'active'}`}
                                            onClick={() => handleMortgageChange(brokerage)}
                                        >
                                            <span className="imgspan">
                                                <img src={brokerage?.logo} alt="" />
                                            </span>
                                            <span className="cardtxt">{brokerage?.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                )}

                {!isSubmitSuccess && (
                    <>
                        <div className={`generalFieldsContainer ${type === 'realtorContact' && 'pt-4'}`}>
                            <div className="mainContainer mr">
                                <div className="inputFeildMain">
                                    <TextField
                                        className="inputFeild"
                                        type="text"
                                        error={errors.firstName}
                                        label="First Name"
                                        name="firstName"
                                        id="outlined-start-adornment"
                                        onChange={handleChange}
                                        value={firstName}
                                        variant="outlined"
                                    />
                                </div>
                                {errors.firstName && <span className="errMessage">{errorMessages.firstName}</span>}
                            </div>
                            <div className="mainContainer">
                                <div className="inputFeildMain">
                                    <TextField
                                        className="inputFeild"
                                        type="text"
                                        error={errors.lastName}
                                        name="lastName"
                                        label="Last Name"
                                        id="outlined-start-adornment"
                                        onChange={handleChange}
                                        value={lastName}
                                        variant="outlined"
                                    />
                                </div>
                                {errors.lastName && <span className="errMessage">{errorMessages.lastName}</span>}
                            </div>

                            <div className="mainContainer mr">
                                <div className="inputFeildMain">
                                    <TextField
                                        type="email"
                                        className="inputFeild"
                                        error={errors.email}
                                        label="Email"
                                        name="email"
                                        id="outlined-start-adornment"
                                        onChange={handleChange}
                                        value={email}
                                        variant="outlined"
                                    />
                                </div>
                                {errors.email && <span className="errMessage">{errorMessages.email}</span>}
                            </div>

                            <div className="mainContainer">
                                <div className="inputFeildMain">
                                    <TextField
                                        type="phone"
                                        className="inputFeild"
                                        error={errors.phone}
                                        label="Phone Number"
                                        name="phone"
                                        id="outlined-start-adornment"
                                        onChange={handleChange}
                                        value={phone}
                                        variant="outlined"
                                    />
                                </div>
                                {errors.phone && <span className="errMessage">{errorMessages.phone}</span>}
                            </div>
                        </div>

                        <FormControl className="innerTitle contactPrefSelection">
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Please select your preferred contact method.
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={contactType}
                                onChange={e => setContactType(e.target?.value || 'Email')}
                            >
                                <FormControlLabel value="Email" control={<Radio />} label="Email" />
                                <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                            </RadioGroup>
                        </FormControl>
                    </>
                )}

                {isSubmitSuccess && (
                    <div className="successfullyInquiry">
                        <div className="successTitle realtorSuccessTitle">
                            <img src={GreenCheckMark} alt="" />
                            <span>Your request has been submitted successfully</span>
                            <p className="subConfirmText">Thank you for your submission. You will be contacted soon.</p>
                        </div>
                    </div>
                )}
            </div>

            {!isSubmitSuccess && (
                <div className={`buttons actionButtons ${type === 'agentContact' && 'agentContactButtons'}`}>
                    <Button
                        className="home-warranty-sub"
                        color="#273E59"
                        border="1px solid #273E59"
                        boxShadow={'none'}
                        backgroundColor={'#fff'}
                        borderRadius="5px"
                        width="160px"
                        height="50px"
                        fontSize="16px"
                        onClick={toggleModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="home-warranty-sub"
                        color="#FFFFFF"
                        boxShadow={'0 0 3px 0 #273E59'}
                        backgroundColor={'#273E59'}
                        borderRadius="5px"
                        width="160px"
                        height="50px"
                        fontSize="16px"
                        onClick={submitHandler}
                        disabled={isLoading || isNotEverythingFilled || Object.values(errors).some(val => val === true)}
                    >
                        Submit
                    </Button>
                </div>
            )}
        </div>
    );
}
