import React from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonNew from './Button';
import '../../styles/MovingCardNext.scss';
import Skeleton from 'react-loading-skeleton';

type iProps = {
    className?: string;
    height?: string;
    width?: string;
    border?: string;
    icon?: any;
    imageHeight?: any;
    imageWidth?: any;
    title?: string;
    subTitle?: string;
    buttonText?: string;
    buttonBackGroundColor?: string;
    buttonColor?: string;
    buttonShadow?: string;
    buttonHeight?: string;
    buttonWidth?: string;
    onClick?: any;
    padding?: any;
    boxShadow?: string;
    imgDivClass?: string;
};

const MovingNextCard = (props: iProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                //  borderColor: '#333333 !important',
                boxShadow: ' 0px 4px 4px #0000000A !important',
                '& $checkroot': {
                    color: '#333333 !important',
                },
            },
        },
        checkroot: {},
    }));
    const classes = useStyles();
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    return (
        <div
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} main-card-class`}
            style={{
                border: props.border ? props.border : '#E2E2E2 1px solid',
                width: props.width ? props.width : '500px',
                height: props.height ? props.height : 'fit-content',
                padding: props.padding ? props.padding : '0px',
                // boxShadow: props.boxShadow ? props.boxShadow : '0 0 10px #9C9DA1',
            }}
        >
            <div className="wrapper-card">
                <div
                    className="MovingNextCardContentDiv"
                    style={{
                        // width: '60%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: 1
                        // marginLeft: '20px',
                    }}
                >
                    <div className="movingNextCardTitle">
                        {props.title ? props.title : <Skeleton height="30px" width="150px" />}
                    </div>
                    <div className="movingNextCardSubTitle">{props.subTitle}</div>
                    <div>
                        {props.buttonText ? (
                            <ButtonNew
                                //   boxShadow={`0 0 3px 0  ${slug && slug.primary_color ? slug.primary_color : '#273E59'}`}
                                color={
                                    isDouglasElliman
                                        ? '#FFF'
                                        : slug && slug.primary_color
                                        ? `${slug.primary_color}`
                                        : '#002E60'
                                }
                                className="movingNextCardButton"
                                backgroundColor={isDouglasElliman ? '#100B28' : props.buttonBackGroundColor}
                                // boxShadow={props.buttonShadow}
                                // color={props.buttonColor}
                                border={
                                    isDouglasElliman
                                        ? '1px solid #100b28'
                                        : `1px solid ${
                                              slug && slug.primary_color ? `${slug.primary_color}` : '#002E60'
                                          }`
                                }
                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                fontSize="14px"
                                fontWeight="600"
                                height={props.buttonHeight ? props.buttonHeight : '30px'}
                                width={props.buttonWidth ? props.buttonWidth : '100px'}
                                onClick={props.onClick}
                            >
                                {props.buttonText}
                            </ButtonNew>
                        ) : (
                            <Skeleton height="40px" width="100px" />
                        )}
                    </div>
                </div>

                {props.icon ? (
                    <div
                        // style={{ width: '40%' }}
                        className="MovingNextCardImgDiv"
                        style={{ width: '30%', maxWidth: 140 }}
                    >
                        {props.icon && props.icon.includes('<svg') ? (
                            <div
                                className="mobile-svg-div"
                                dangerouslySetInnerHTML={{
                                    __html: props.icon,
                                }}
                            />
                        ) : (
                            <img
                                className="movingNextCardImage"
                                src={props.icon}
                                height={isDouglasElliman ? 'unset' : props.imageHeight ? props.imageHeight : '100%'}
                                width={isDouglasElliman ? 'unset' : props.imageWidth ? props.imageWidth : '100%'}
                            />
                        )}
                    </div>
                ) : (
                    <div className="MovingNextCardImgDiv" style={{ width: '30%', maxWidth: 140 }}>
                        <Skeleton className="movingNextCardImage" width="85px" height="80px" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MovingNextCard;
