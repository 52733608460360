import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    const isDouglasElliman = props.className.includes('douglasElliman');

    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={37.128} height={52.478} viewBox="0 0 37.128 52.478">
            <defs>
                <style>
                    {slug && slug.color_shade1 && slug.color_shade4
                        ? isDouglasElliman
                            ? `.gasprefix__a{fill:#ffffff;stroke:#333333;stroke-width:2px}`
                            : `.gasprefix__a{fill:${slug.color_shade4};stroke:${slug.color_shade1};stroke-width:2px}`
                        : '.gasprefix__a{fill:#5b7088;stroke:#002e62;stroke-width:2px}'}
                </style>
            </defs>
            <path
                className="gasprefix__a"
                d="M11.084 49.455c-3.185-1.761-5.468-3.533-7.282-6.786a22.257 22.257 0 01-2.8-9.73.659.659 0 011.174-.448 17.1 17.1 0 003.952 3.579.473.473 0 00.684-.6c-1.619-3.289-4.759-11.471.345-17.581a.724.724 0 011.277.48 8.745 8.745 0 002.582 6.6 33.642 33.642 0 01.644-5.8c1.057-5.119 3.844-12.533 11.161-17.586 1.1-.76 2.838-1.183 2.364 1.5-1.434 8.124-1.107 18.528 3.769 25.246a1.019 1.019 0 001.445.214 7.9 7.9 0 002.642-4.335c.269-1 .173-1.171.45-1.373a.59.59 0 01.918.315 37.934 37.934 0 011.716 9.666c.284 12.999-14.057 22.713-25.041 16.639z"
            />
            <path
                className="gasprefix__a"
                d="M27.1 39.771c-1.223 9.855-20.123 9.9-16.928-5.622a.864.864 0 011.232-.6c.2.134 3.481 1.538 4.727 3.71-.789-1.845-2.764-8.515 3.4-19.92a.777.777 0 011.46.312c.267 3.667 1.231 10.441 4.672 16.184a9.252 9.252 0 011.437 5.936z"
            />
        </svg>
    );
}

export default SvgComponent;
