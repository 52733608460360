import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38.867}
            height={53}
            viewBox="0 0 38.867 53"
            {...props}
            className={props.className}
        >
            <defs>
                <style>
                    {`.insuranceMainLocationIconprefix__a{fill:${
                        slug && slug.color_shade4
                            ? props.isdouglaselliman
                                ? '#ffffff'
                                : slug.color_shade4
                            : props.fromlanding || window.location.pathname.includes('landing')
                            ? '#30ab86'
                            : '#8195ac'
                    }}.insuranceMainLocationIconprefix__b{fill:#fff}.insuranceMainLocationIconprefix__c{fill:${
                        slug && slug.primary_color
                            ? props.isdouglaselliman
                                ? '#333333'
                                : slug.primary_color
                            : props.fromlanding || window.location.pathname.includes('landing')
                            ? '#1D7E64'
                            : '#293e58'
                    }}`}
                </style>
            </defs>
            <path
                className="insuranceMainLocationIconprefix__a"
                d="M19.433 52.116c-6.332 0-11.483-1.585-11.483-3.533 0-1.585 3.429-2.977 8.339-3.392a128.408 128.408 0 002.5 2.726l.641.677.641-.677c.549-.579 1.427-1.518 2.5-2.726 4.91.415 8.339 1.807 8.339 3.392.007 1.949-5.145 3.533-11.477 3.533z"
            />
            <path
                className="insuranceMainLocationIconprefix__b"
                d="M37.983 19.433c0 3.2-1.82 7.579-5.388 13.02 0 .018-.018.018-.018.035a122.7 122.7 0 01-13.109 16.059l-.035.035h-.018C18.673 47.805.883 29.52.883 19.432a18.55 18.55 0 1137.1 0z"
            />
            <path
                className="insuranceMainLocationIconprefix__a"
                d="M27.383 12.844v12.773a1.767 1.767 0 01-1.5 1.749c-.071 0-.159.018-.265.018H13.25a1.772 1.772 0 01-1.767-1.767V12.844l7.95-4.894z"
            />
            <path
                className="insuranceMainLocationIconprefix__b"
                d="M22.083 22.084v5.3h-5.3v-5.3a1.825 1.825 0 011.767-1.767h1.767a1.772 1.772 0 011.766 1.767z"
            />
            <path
                className="insuranceMainLocationIconprefix__c"
                d="M25.617 28.267a2.653 2.653 0 002.65-2.65v-8.833a.884.884 0 00-1.767 0v8.833a.885.885 0 01-.883.883h-2.65v-4.417a2.653 2.653 0 00-2.65-2.65H18.55a2.653 2.653 0 00-2.65 2.65V26.5h-2.65a.885.885 0 01-.883-.883v-8.833a.884.884 0 10-1.767 0v8.833a2.653 2.653 0 002.65 2.65zm-7.95-1.767v-4.417a.885.885 0 01.883-.883h1.767a.885.885 0 01.883.883V26.5z"
            />
            <path
                className="insuranceMainLocationIconprefix__c"
                d="M30.454 15.766a.866.866 0 00.461.131.883.883 0 00.465-1.636L19.897 7.194a.882.882 0 00-.926 0L7.487 14.266a.884.884 0 10.926 1.505l11.02-6.782z"
            />
            <path
                className="insuranceMainLocationIconprefix__c"
                d="M24.376 44.506c5.56-6.349 14.49-17.693 14.49-25.072a19.434 19.434 0 10-38.867 0c0 7.379 8.93 18.723 14.49 25.072-3.844.542-7.424 1.832-7.424 4.077 0 2.9 6.22 4.417 12.367 4.417s12.367-1.516 12.367-4.417c.001-2.245-3.578-3.535-7.423-4.077zM19.433 1.767A17.687 17.687 0 0137.1 19.433c0 8.72-14.446 24.47-17.667 27.873-3.222-3.4-17.667-19.147-17.667-27.873A17.687 17.687 0 0119.433 1.767zm0 49.467c-6.576 0-10.6-1.715-10.6-2.65 0-.654 2.367-2.005 7.084-2.472a111.243 111.243 0 002.885 3.092.888.888 0 001.261 0c.178-.182 1.291-1.325 2.885-3.092 4.717.466 7.084 1.818 7.084 2.472.001.934-4.023 2.649-10.599 2.649z"
            />
        </svg>
    );
}

export default SvgComponent;
