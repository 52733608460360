import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
// import OldZip from '../../Assets/images/Moving/OldZip';
import AutoComplete from '../../components/atoms/autoComplete';
import TextField from '@material-ui/core/TextField';
import SelectInput from '../../components/atoms/SelectInput';
// import { list } from '../../components/atoms/USAlist';
import Linear from '../../components/atoms/linearProgress';
import './HomeWarantyMain.scss';
import HomeSize from '../../Assets/images/Moving/HomeSize';
// import YearBuilt from "../../Assets/images/HomeWaranty/yearbuilt";
import HomeType from '../../Assets/images/Moving/HomeType';
// import StoriesUp from '../../Assets/images/Moving/Stories';
import { InputLabel } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox';
// import AutoComplete from '../../components/atoms/autoComplete';
import RoomIcon from '@material-ui/icons/Room';
// import TermLength from '../../Assets/images/HomeWarranty/termlength';
import ProjectClosing from '../../Assets/images/HomeWarranty/projectclosing';
import AgentEmail from '../../Assets/images/HomeWarranty/agentEmail';
// import ClosingTitle from '../../Assets/images/HomeWarranty/closingTitle';
// import ClosingAgent from '../../Assets/images/HomeWarranty/closingAgent';
// import ClosingFile from '../../Assets/images/HomeWarranty/closingFile';
// import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
// import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import InfoIcon from '@material-ui/icons/Info';
// import RoofIcon from '../../Assets/images/InsuranceImages/roof';
import BuiltIcon from '../../Assets/images/InsuranceImages/craneb';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as CommonAction from '../../store/common/actions';
import * as InsuranceAction from '../../store/insurance/actions';
import * as HomeWarrantyAction from '../../store/homeWarranty/actions';
import * as DashboardAction from '../../store/dashboard/actions';
import { geocodeByAddress } from 'react-places-autocomplete';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import { formStartedSegment, formCompletedSegment, buttonClicked } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import _ from 'lodash';
import { updateChecklistTask } from '../../utils/common-functions';


type initialProps = {
    history?: any;
    commonState?: any;
    commonAction?: any;
    insuranceState?: any;
    insuranceAction?: any;
    homeWarrantyState?: any;
    homeWarrantyAction?: any;
    dashboardAction: any;
};
type initialState = {
    street: string;
    continueClick: boolean;
    streetNo: string;
    city: string;
    state: any;
    zipcode: string;
    activeStep: number;
    slug: any;
    new_sizeError: string;
    yearError: string;
    new_noOfStoriesError: string;
    roofmaterialError: string;
    typeOfHomeError: string;
    // termLengthError: string;
    step2: {
        new_size: string;
        year: string;
        // new_noOfStories: any;
        // roofMaterial: any;
        typeOfHome: any;
        // termLength: any;
        isNewConstruction: string;
    };
    step3: {
        // titleCompany: string;
        // titleAgent: string;
        // closingFile: string;
        agentEmail: string;
        // workingWithTitleCompany: string;
    };
    // storiesOptions: any;
    // roofMaterialOptions: any;
    homeOptions: any;
    moveId: string;
    // companyTitle: boolean;
    // labelCompany: string;
    // labelCompanyno: string;
    date: any;
    isDataArrived: boolean;
    imaDetails: any;
    destination_detail: any;
    address: any;
    termLengthOptions: any;
    termLengthOldConstruction: any;
    lableShrink: boolean;
    updateHomeWarranty: boolean;
    isDouglasElliman: boolean;
    showNewConstruction: boolean;
    homeWarrantyDetails: any;
    prefilled: boolean;
};
export class WarantySteps extends Component<initialProps, initialState> {
    state: initialState = {
        destination_detail: '',
        moveId: '',
        street: '',
        continueClick: false,
        streetNo: '',
        address: '',
        city: '',
        state: null,
        zipcode: '',
        activeStep: 0,
        slug: { primary_color: '' },
        new_sizeError: '',
        new_noOfStoriesError: '',
        roofmaterialError: '',
        yearError: '',
        typeOfHomeError: '',
        // termLengthError: '',
        step2: {
            new_size: '',
            year: '',
            // new_noOfStories: '',
            // roofMaterial: '',
            typeOfHome: null,
            // termLength: '',
            isNewConstruction: '',
        },
        step3: {
            // titleCompany: '',
            // titleAgent: '',
            // closingFile: '',
            agentEmail: '',
            // workingWithTitleCompany: 'yes',
        },
        // storiesOptions: [
        //     { value: '1', label: 'One' },
        //     { value: '2', label: 'Two' },
        //     { value: '3', label: 'Three' },
        //     { value: '4', label: 'Four' },
        // ],
        // roofMaterialOptions: [
        //     { value: '1', label: 'Architectural Shingle' },
        //     { value: '2', label: 'Architectural Shingle' },
        //     { value: '3', label: 'Architectural Shingle' },
        //     { value: '4', label: 'Architectural Shingle' },
        // ],
        homeOptions: [
            { value: 'SingleFamilyResidence', label: 'Single Family Residence' },
            { value: 'Condominium', label: 'Condominium' },
            { value: 'Fourplex', label: 'Fourplex' },
            { value: 'Townhouse', label: 'Town house' },
            { value: 'Duplex', label: 'Duplex' },
            { value: 'Triplex', label: 'Triplex' },
            { value: 'MobileHome', label: 'Mobile Home' },
        ],
        termLengthOptions: [
            { value: '1', label: '1' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
        ],
        termLengthOldConstruction: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
        ],
        // companyTitle: true,
        // labelCompany: 'yes',
        // labelCompanyno: 'no',
        date: null,
        isDataArrived: true,
        imaDetails: 'buyer',
        // submitClicked: false,
        lableShrink: false,
        updateHomeWarranty: false,
        isDouglasElliman: false,
        showNewConstruction: false,
        homeWarrantyDetails: null,
        prefilled: false,
    };
    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }

        if (!(this.props.commonAction && this.props.commonAction.move && this.props.commonAction.move[0])) {
            this.props.commonAction.moveGet();
        }

        this.props.homeWarrantyAction.getHomeWarrantyDetails();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            // nextProps.commonState.move.map((item: any) => {
            currentState.isDataArrived = false;
            currentState.moveId = nextProps.commonState.move[0].id ? nextProps.commonState.move[0].id : '';
            currentState.zipcode =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.zip_code
                    ? nextProps.commonState.move[0].destination.zip_code
                    : '';
            currentState.city =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.city_name
                    ? nextProps.commonState.move[0].destination.city_name
                    : '';
            currentState.state =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination.state_code
                    ? nextProps.commonState.move[0].destination.state_code
                    : '';
            currentState.street =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination_street
                    ? nextProps.commonState.move[0].destination_street
                    : '';
            currentState.destination_detail =
                nextProps.commonState.move[0].destination && nextProps.commonState.move[0].destination_full_address
                    ? nextProps.commonState.move[0].destination_full_address
                    : '';
            currentState.step2.new_size =
                nextProps.commonState.move[0].property && nextProps.commonState.move[0].property.total_area_sq_ft
                    ? nextProps.commonState.move[0].property.total_area_sq_ft
                    : currentState.step2.new_size;
            currentState.step2.year =
                nextProps.commonState.move[0].property && nextProps.commonState.move[0].property.year_built
                    ? nextProps.commonState.move[0].property.year_built
                    : currentState.step2.year;
            currentState.address = nextProps.commonState.move[0].destination_full_address;
        }

        if (
            nextProps &&
            nextProps.homeWarrantyState &&
            nextProps.homeWarrantyState.getHomeWarrantyDetails &&
            nextProps.homeWarrantyState.isGetHomeWarrantyDetails
        ) {
            nextProps.homeWarrantyState.isGetHomeWarrantyDetails = false;
            // currentState.step2.termLength =
            //     nextProps.homeWarrantyState.getHomeWarrantyDetails &&
            //     nextProps.homeWarrantyState.getHomeWarrantyDetails.term_length
            //         ? nextProps.homeWarrantyState.getHomeWarrantyDetails.term_length
            //         : '';
            currentState.homeWarrantyDetails = nextProps.homeWarrantyState.getHomeWarrantyDetails;
            // console.log(nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type);
            let loanTerm: any = null;
            let valuetype =
                nextProps.homeWarrantyState.getHomeWarrantyDetails &&
                nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
                    ? nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
                    : '';

            loanTerm = { label: valuetype, value: valuetype };
            currentState.step2.typeOfHome = loanTerm;
            // currentState.step2.typeOfHome =
            //     nextProps.homeWarrantyState.getHomeWarrantyDetails &&
            //     nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
            //         ? nextProps.homeWarrantyState.getHomeWarrantyDetails.residence_type
            //         : '';
            currentState.step3.agentEmail =
                nextProps.homeWarrantyState.getHomeWarrantyDetails &&
                nextProps.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                    ? nextProps.homeWarrantyState.getHomeWarrantyDetails.closing_agent_email
                    : '';
            currentState.date =
                nextProps.homeWarrantyState.getHomeWarrantyDetails &&
                nextProps.homeWarrantyState.getHomeWarrantyDetails.closing_date
                    ? moment(nextProps.homeWarrantyState.getHomeWarrantyDetails.closing_date)
                    : '';
            currentState.lableShrink = true;
            currentState.updateHomeWarranty = true;
        }

        return currentState;
    }

    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        const prevI = prevProps && prevProps.insuranceState;
        const curI = this.props && this.props.insuranceState;

        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress && cur.moveAddress.length !== 0) {
            // if (this.state.activeStep === 0) {
            //     this.setState({ activeStep: 1, continueClick: false });
            // }
            // window.scrollTo(0, 0);
            this.props.commonAction.moveGet();
        }

        if (prevI.updateProperty !== curI.updateProperty && curI.updateProperty && curI.updateProperty.message) {
            if (this.state.activeStep == 0) {
                this.setState({
                    activeStep: 1,
                    continueClick: false,
                });
            } else if (this.state.activeStep == 1) {
                this.setState({
                    activeStep: 2,
                    continueClick: false,
                });

                this.props.history.push('/dashboard/home-warranty/step4');
            }
            window.scrollTo(0, 0);
            this.props.commonAction.moveGet();
        }

        if (
            prevProps.homeWarrantyState.updateHomeWarantyDetailsInfo !=
                this.props.homeWarrantyState.updateHomeWarantyDetailsInfo &&
            this.props.homeWarrantyState.updateHomeWarantyDetailsInfo &&
            this.props.homeWarrantyState.updateHomeWarantyDetailsInfo.message
        ) {
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
            this.props.history.push('/dashboard/home-warranty/step4');
        }

        if (
            prevProps.homeWarrantyState.postHomeWarrantyDetails !=
                this.props.homeWarrantyState.postHomeWarrantyDetails &&
            this.props.homeWarrantyState.postHomeWarrantyDetails &&
            this.props.homeWarrantyState.isPostHomeWarrantyDetails
        ) {
            this.props.homeWarrantyState.isPostHomeWarrantyDetails = false;
            this.props.homeWarrantyAction.getHomeWarrantyDetails();
            this.props.history.push('/dashboard/home-warranty/step4');
        }
    }

    handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map((el: any) => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        state: state,
                        zipcode: zip,
                        // fromPin: zip,
                        // clicked1: false,
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    });
                    if (
                        this.state.address &&
                        this.state.street &&
                        this.state.city &&
                        this.state.state &&
                        this.state.zipcode &&
                        this.state.destination_detail
                    ) {
                        this.setState({
                            prefilled: true,
                        });
                    }
                }
                // if (
                //     (country === 'USA' || country === 'US') &&
                //     // state &&
                //     // city &&
                //     // zip &&
                //     // streetNumber &&
                //     // route &&
                //     this.state.moveId
                // ) {
                //     // this.props.commonAction.moveAddressUpdate({
                //     //     destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                //     //     move_id: this.state.moveId,
                //     // });
                // }
            })
            .catch((error: any) => console.error('error', error));
    };
    streetHandler = (e: any) => {
        this.setState({
            street: e.target.value,
            city: '',
            state: '',
            zipcode: '',
        });
    };
    agentEmailHandler = (e: any) => {
        let step3: any = { ...this.state.step3, agentEmail: e.target.value };
        this.setState({ step3: step3 });
    };
    titleAgentHandler = (e: any) => {
        let step3: any = { ...this.state.step3, titleAgent: e.target.value };
        this.setState({ step3: step3 });
    };
    cityHandler = (e: any) => {
        this.setState({
            city: e.target.value,
        });
    };
    stateHandler = (e: any) => {
        this.setState({
            state: e,
        });
    };
    dateHandler = (e: any) => {
        this.setState({
            date: e,
            lableShrink: true,
        });
    };

    zipcodeHandler = (e: any) => {
        this.setState({
            zipcode: e.target.value,
        });
    };
    onHomeSelectHandlerStep = () => {};
    onChangeHandlerStep2 = (e: any, name: string) => {
        let step2: any;
        if (name === 'year') {
            let year = this.formatYearInput(e.target.value);
            step2 = { ...this.state.step2, [name]: year };
        } else if (name === 'new_size') {
            step2 = { ...this.state.step2, [name]: e.target.value.replace(/\D/g, '') };
        } else {
            step2 = { ...this.state.step2, [name]: e.target.value };
        }
        this.setState(() => ({ step2 }));
    };
    onSelectHandlerStep2 = async (e: any, name: string) => {
        const steps = { ...this.state.step2, [name]: e };
        await this.setState(() => ({ step2: steps }));
    };
    onHomeSelectHandler = async (e: any, name: string) => {
        if (name == 'typeOfHome') {
            const steps = { ...this.state.step2, [name]: e };
            await this.setState(() => ({ step2: steps }));
        } else {
            const steps = { ...this.state.step2, [name]: e.value };
            await this.setState(() => ({ step2: steps }));
        }
    };
    // ontermLengthSelectHandler = async (e: any, name: string) => {
    //     const steps = { ...this.state.step2, [name]: e.value };
    //     await this.setState(() => ({ step2: steps }));
    // };
    getSteps = () => {
        return ['', '', ''];
    };
    // handleStep1 = () => {
    //     this.setState({ continueClick: true });
    //     if (this.state.destination_detail && this.state.moveId) {
    //         this.props.commonAction.moveAddressUpdate({
    //             destination_detail: this.state.destination_detail,
    //             move_id: this.state.moveId,
    //         });
    //     }
    //     // this.setState({
    //     //     activeStep: 1,
    //     // });
    // };
    handleStep2 = async () => {
        this.setState({ continueClick: true });
        if (
            this.state.step2.year &&
            this.state.step2.new_size &&
            // this.state.step2.termLength &&
            this.state.step2.typeOfHome &&
            this.state.step2.typeOfHome.value &&
            this.state.moveId
        ) {
            const payload = {
                year_built: this.state.step2.year,
                total_area_sq_ft: this.state.step2.new_size,
            };
            await this.props.insuranceAction.updateProperty({ move_id: this.state.moveId, payload });
           
            updateChecklistTask(35, this.props.dashboardAction);
        }

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Home Warranty',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Next',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: false,
                    form_button: true,
                    form_step_number: 1,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );

            formStartedSegment(
                'Form Started',
                'Home Warranty',
                'Home Warranty',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                '',
                'Next',
                true,
                this.state.prefilled,
                1,
                move_id,
            );
        }
    };
    handleStep3 = async () => {
        this.setState({ continueClick: true });
        if (this.state.date) {
            if (this.state.updateHomeWarranty) {
                const payload = {
                    ...this.state.homeWarrantyDetails,
                    closing_agent_email: this.state.step3.agentEmail,
                    closing_date: moment(this.state.date).format('YYYY-MM-DD'),
                    residence_type: this.state.step2.typeOfHome && this.state.step2.typeOfHome.value,
                    term_length: '1',
                    is_new_construction:
                        this.state.step2.isNewConstruction && this.state.step2.isNewConstruction == 'yes'
                            ? true
                            : false,
                };
                await this.props.homeWarrantyAction.updateHomeWarrantyDetailsInfo(payload);
            } else {
                const payload = {
                    closing_agent_email: this.state.step3.agentEmail,
                    closing_date: moment(this.state.date).format('YYYY-MM-DD'),
                    residence_type: this.state.step2.typeOfHome && this.state.step2.typeOfHome.value,
                    term_length: '1',
                    submission_date: null,
                    policy_number: null,
                    expiry_date: null,
                    effective_date: null,
                    is_new_construction:
                        this.state.step2.isNewConstruction && this.state.step2.isNewConstruction == 'yes'
                            ? true
                            : false,
                };
                await this.props.homeWarrantyAction.postHomeWarrantyDetails(payload);
            }

            updateChecklistTask(70, this.props.dashboardAction);
        }

        // this.props.insuranceAction.updateProperty({ move_id: this.state.moveId, payload });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;
            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

            analytics.track(
                'Button Clicked',
                {
                    page: 'Home Warranty',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                    button_copy: 'Next',
                    card: false,
                    card_title: '',
                    card_type: '',
                    category_name: 'Home Warranty',
                    popup: false,
                    form_button: true,
                    form_step_number: 2,
                    banner: false,
                    banner_title: '',
                    brand_name: '',
                },
                { user_id: move_id },
            );

            formCompletedSegment(
                'Form Completed',
                'Home Warranty',
                'Home Warranty',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                '',
                'Next',
                true,
                false,
                'Home Warranty Form',
                3,
                move_id,
            );
        }
    };
    titleCompanyHandler = (e: any) => {
        let step3: any = { ...this.state.step3, titleCompany: e.target.value };
        this.setState({ step3: step3 });
    };
    closingFileHandler = (e: any) => {
        let step3: any = { ...this.state.step3, closingFile: e.target.value };
        this.setState({ step3: step3 });
    };
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');
            buttonClicked(
                'Button Clicked',
                'Home Waraanty',
                brokerage,
                brokerage_key,
                agent_name,
                agent_key,
                'Home Warranty',
                'Previous',
                false,
                '',
                false,
                '',
                '',
                false,
                '',
                true,
                this.state.activeStep,
                '',
                move_id,
            );
        }
    };
    formatYearInput = (year: string) => {
        let year_built = year;
        var d = new Date();
        var n = d.getFullYear();
        year_built = year_built.replace(/\D/g, '');
        year_built = year_built.substring(0, 4);

        if (parseInt(year_built) <= n) {
            return year_built;
        } else if (year_built !== null) {
            return '';
        }
    };

    // validating = () => {
    //     var d = new Date();
    //     var n = d.getFullYear();
    //     return n;
    // };

    getStepContent = (step: number) => {
        switch (step) {
            // case 0:
            //     return (
            //         <div>
            //             <div className="warantyAddressCard">
            //                 <div className="warantyBoxCard">
            //                     <div className="headingSteps" style={{ fontWeight: 700 }}>
            //                         Confirm your home address you would like to insure
            //                     </div>

            //                     <div className="warantyBox">
            //                         <div className="inputsWaranty">
            //                             <div className="warantyAddress">
            //                                 Why do we ask for your address?
            //                                 <InfoIcon />
            //                             </div>
            //                             {/* <div className="warantyNewConst">
            //                                 {' '}
            //                                 <Checkbox />
            //                                 This is a new construction
            //                             </div> */}
            //                             <div className="innerInputsWaranty">
            //                                 <div className="addressFlexDivIconWarantyInput">
            //                                     <div className="iconWaranty">
            //                                         <OldZip className="icons"  isDouglasElliman={this.state.isDouglasElliman}/>
            //                                     </div>
            //                                     <div className="autoStreet">
            //                                         <AutoComplete
            //                                             value={this.state.street}
            //                                             onChange={e => {
            //                                                 this.setState({ street: e });
            //                                             }}
            //                                             onSelect={this.handleSelect}
            //                                             placeholder="Street"
            //                                             // inputTitle="To"
            //                                             error={this.state.continueClick && this.state.street === ''}
            //                                             helperText={
            //                                                 this.state.continueClick && this.state.street === ''
            //                                                     ? 'Please enter street'
            //                                                     : ''
            //                                             }
            //                                         />
            //                                     </div>
            //                                 </div>
            //                                 {/* <div className=" warantyStreet">
            //                                     <AutoComplete
            //                                         value={this.state.streetNo}
            //                                         onChange={e => {
            //                                             this.setState({ streetNo: e });
            //                                         }}
            //                                         onSelect={this.handleSelect}
            //                                         placeholder="Street No"
            //                                         // inputTitle="To"
            //                                         error={this.state.continueClick && this.state.streetNo === ''}
            //                                         helperText={
            //                                             this.state.continueClick && this.state.streetNo === ''
            //                                                 ? 'Street No.'
            //                                                 : ''
            //                                         }
            //                                     />
            //                                 </div> */}
            //                             </div>
            //                             <div className="innerInputsWaranty topWarantyMargin">
            //                                 <div className="iconWaranty"></div>
            //                                 <div className="inputWarrantyCoverDiv">
            //                                     <div className="warantyCity">
            //                                         <TextField
            //                                             className="input2"
            //                                             label="City"
            //                                             id="outlined-start-adornment"
            //                                             onChange={this.cityHandler}
            //                                             variant="filled"
            //                                             value={this.state.city}
            //                                             error={this.state.continueClick && this.state.city === ''}
            //                                             helperText={
            //                                                 this.state.continueClick && this.state.city === ''
            //                                                     ? 'Please enter city'
            //                                                     : ''
            //                                             }
            //                                         />
            //                                     </div>
            //                                     <div style={{ position: 'relative' }} className="warantyState">
            //                                         {this.state.state && (
            //                                             <div className="stateTextAddressWaranty">State</div>
            //                                         )}
            //                                         <SelectInput
            //                                             className="react-select-waranty input-waranty"
            //                                             options={list}
            //                                             placeholder={this.state.state ? this.state.state : 'State'}
            //                                             value={this.state.state}
            //                                             onChange={(e: any) => {
            //                                                 this.stateHandler(e);
            //                                             }}
            //                                         />

            //                                         <div className="error">
            //                                             {this.state.continueClick &&
            //                                                 this.state.state === {} &&
            //                                                 'Please select state'}
            //                                         </div>
            //                                     </div>
            //                                     <div className="warantyZipcode">
            //                                         <TextField
            //                                             className="input4"
            //                                             label="Zip code"
            //                                             id="outlined-start-adornment"
            //                                             onChange={this.zipcodeHandler}
            //                                             variant="filled"
            //                                             value={this.state.zipcode}
            //                                             error={this.state.continueClick && this.state.zipcode === ''}
            //                                             helperText={
            //                                                 this.state.continueClick && this.state.zipcode === ''
            //                                                     ? 'Please enter  zip code'
            //                                                     : ''
            //                                             }
            //                                         />
            //                                         {/* <div className="error">
            //                                     {this.state.continueClick &&
            //                                         this.state.zipcode === '' &&
            //                                         'Please enter Zip code'}
            //                                 </div> */}
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     );
            case 0:
                return (
                    <div>
                        <div className="warantyAddressCard">
                            <div className="warantyBoxCard">
                                <div className="headingSteps heading-padding" style={{ fontWeight: 700 }}>
                                    Confirm your home specification
                                </div>

                                <div
                                    className={
                                        this.state.isDouglasElliman ? 'warantyBox warantyBoxDouglas' : 'warantyBox'
                                    }
                                >
                                    <div className="inputsWarantyCase2">
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <HomeSize
                                                    className="iconsWarantyCase1"
                                                    isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                />
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel className="inputLable">Size of your home</InputLabel>
                                                </div>
                                                <TextField
                                                    className="input1"
                                                    error={Boolean(this.state.new_sizeError)}
                                                    label="Square FT"
                                                    id="reddit-input"
                                                    // id="outlined-start-adornment"
                                                    onChange={(e: any) => this.onChangeHandlerStep2(e, 'new_size')}
                                                    variant="filled"
                                                    value={this.state.step2.new_size}
                                                />
                                                <div
                                                    // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.continueClick && this.state.step2.new_size == ''
                                                        ? 'Enter size'
                                                        : this.state.continueClick &&
                                                          this.state.step2.new_size != '' &&
                                                          parseInt(this.state.step2.new_size) <= 0
                                                        ? 'Size will always be more than 0'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                {/* <img src={yearbuilt} className="iconsWarantyCase1"></img> */}
                                                <BuiltIcon isdouglaselliman={this.state.isDouglasElliman ? 1 : 0} />
                                                {/* <YearBuilt className="iconsWarantyCase1" /> */}
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel className="inputLable">Year Built</InputLabel>
                                                </div>
                                                <TextField
                                                    className="input1"
                                                    error={Boolean(this.state.yearError)}
                                                    label="Year"
                                                    id="reddit-input"
                                                    // id="outlined-start-adornment"
                                                    onChange={(e: any) => this.onChangeHandlerStep2(e, 'year')}
                                                    variant="filled"
                                                    value={this.state.step2.year}
                                                />
                                                <div
                                                    // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.continueClick && this.state.step2.year == ''
                                                        ? 'Enter year'
                                                        : this.state.continueClick &&
                                                          this.state.step2.year != '' &&
                                                          parseInt(this.state.step2.year) <= 1900
                                                        ? 'Enter valid year'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="inputsWarantyCase2">
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <StoriesUp className="iconsWarantyCase1" />
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel>Number Of Stories</InputLabel>
                                                </div>
                                                <SelectInput
                                                    className="react-select alert-select"
                                                    onChange={(e: any) => this.onSelectHandlerStep2(e, 'noOfStories')}
                                                    options={this.state.storiesOptions}
                                                    placeholder={
                                                        this.state.step2.new_noOfStories
                                                            ? this.state.step2.new_noOfStories.label
                                                            : 'Select Number Of Stories'
                                                    }
                                                    value={
                                                        this.state.step2.new_noOfStories
                                                            ? this.state.step2.new_noOfStories.value
                                                            : ''
                                                    }
                                                    label="Number Of Stories"
                                                />
                                                <div
                                                    // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.step2.new_noOfStories == '' ? (
                                                        <small>{this.state.new_noOfStoriesError}</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <RoofIcon />
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel className="inputLable">Roof material</InputLabel>
                                                </div>
                                                <SelectInput
                                                    className="react-select alert-select"
                                                    onChange={(e: any) => this.onSelectHandlerStep2(e, 'noOfStories')}
                                                    options={this.state.roofMaterialOptions}
                                                    placeholder={
                                                        this.state.step2.roofMaterial
                                                            ? this.state.step2.roofMaterial.label
                                                            : 'Select roof type'
                                                    }
                                                    value={
                                                        this.state.step2.roofMaterial
                                                            ? this.state.step2.roofMaterial.value
                                                            : ''
                                                    }
                                                    label="Number Of Stories"
                                                />
                                                <div
                                                    // style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.step2.roofMaterial == '' ||
                                                    parseInt(this.state.step2.roofMaterial) <= 0 ? (
                                                        <small>{this.state.roofmaterialError}</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="inputsWarantyCase2">
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <HomeType
                                                    className="iconsWarantyCase1"
                                                    isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                />
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel>Type Of Home</InputLabel>
                                                </div>
                                                <SelectInput
                                                    error={this.state.typeOfHomeError != '' ? true : false}
                                                    // id="demo-simple-select-error"
                                                    className="react-select alert-select"
                                                    // error={Boolean(this.state.typeOfHomeError)}
                                                    onChange={(e: any) => this.onHomeSelectHandler(e, 'typeOfHome')}
                                                    options={this.state.homeOptions}
                                                    placeholder={
                                                        this.state.step2.typeOfHome && this.state.step2.typeOfHome.label
                                                            ? this.state.step2.typeOfHome.label
                                                            : 'Select Type Of Home'
                                                    }
                                                    value={
                                                        this.state.step2.typeOfHome && this.state.step2.typeOfHome.value
                                                            ? this.state.step2.typeOfHome.value
                                                            : ''
                                                    }
                                                    // label="Type Of Home"
                                                />
                                                <div
                                                    //  style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.continueClick &&
                                                    !(
                                                        this.state.step2 &&
                                                        this.state.step2.typeOfHome &&
                                                        this.state.step2.typeOfHome.value
                                                    ) ? (
                                                        <small>Select home type</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <TermLength isDouglasElliman={this.state.isDouglasElliman} />
                                            </div>
                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel>Term Length</InputLabel>
                                                </div>
                                                <SelectInput
                                                    error={this.state.termLengthError != '' ? true : false}
                                                    className="react-select alert-select"
                                                    onChange={(e: any) =>
                                                        this.ontermLengthSelectHandler(e, 'termLength')
                                                    }
                                                    options={
                                                        parseInt(this.state.step2.year) === this.validating()
                                                            ? this.state.termLengthOptions
                                                            : parseInt(this.state.step2.year) < this.validating()
                                                            ? this.state.termLengthOldConstruction
                                                            : null
                                                    }
                                                    placeholder={
                                                        this.state.step2.termLength
                                                            ? this.state.step2.termLength
                                                            : 'Select Term Length'
                                                    }
                                                    value={
                                                        this.state.step2.termLength ? this.state.step2.termLength : ''
                                                    }
                                                    // label="Term Length"
                                                />
                                                <div
                                                    //  style={{ color: 'red', paddingTop: '5px' ,height:'20px' }}
                                                    className="errorMoving"
                                                >
                                                    {this.state.continueClick && this.state.step2.termLength == '' ? (
                                                        <small>Select term length</small>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {this.state.showNewConstruction ? (
                                        <div className="choiceFilling">
                                            <div className="checkButonBox"></div>
                                            <div className="iAm">
                                                Is it a new construction?{' '}
                                                <ReactTooltip
                                                    place="right"
                                                    type="light"
                                                    effect="float"
                                                    border={true}
                                                    multiline={true}
                                                    id="new-construction-tip"
                                                >A New Construction Home Warranty provides coverage<br /> after the builder&apos;s one-year warranty expires. Coverage<br /> term length will vary</ReactTooltip>{' '}
                                                <InfoIcon
                                                    data-tip data-for="new-construction-tip"
                                                    style={{ height: '20px', width: '20px', marginBottom: '-4px' }}
                                                />
                                            </div>
                                            <div className="radioButtons">
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        aria-label="homeSeller"
                                                        defaultValue={this.state.step2.isNewConstruction}
                                                        name="radio-buttons-group"
                                                        onChange={(e: any) => {
                                                            let step2: any = {
                                                                ...this.state.step2,
                                                                isNewConstruction: e.target.value,
                                                            };
                                                            this.setState({ step2: step2 });
                                                        }}
                                                    >
                                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="warantyAddressCard">
                            <div className="warantyBoxCard">
                                {/* <div className="headingSteps" style={{ fontWeight: 700 }}>
                                    Confirm your closing information
                                </div> */}
                                <div
                                    className={
                                        this.state.isDouglasElliman ? 'warantyBox warantyBoxDouglas' : 'warantyBox'
                                    }
                                >
                                    <div className="inputsWarantyCase2">
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <ProjectClosing
                                                    isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                    className="iconsWarantyCase4"
                                                />
                                            </div>

                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel className="inputLable">
                                                        *Projected Closing Date
                                                    </InputLabel>
                                                </div>
                                                <div className="dateChangeClass">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            error={
                                                                (this.state.date == '' || this.state.date == null) &&
                                                                this.state.continueClick
                                                            }
                                                            // disablePast
                                                            // helperText=""
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Closing Date"
                                                            className=""
                                                            value={this.state.date}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: this.state.lableShrink,
                                                            }}
                                                            autoOk={true}
                                                            minDate={
                                                                new Date(new Date().setDate(new Date().getDate() - 10))
                                                            }
                                                            maxDate={
                                                                new Date(new Date().setDate(new Date().getDate() + 365))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ color: 'red' }}>
                                                        {(this.state.date == '' || this.state.date == null) &&
                                                        this.state.continueClick
                                                            ? 'Select date'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addressFlexDivIconInput">
                                            <div className="iconDiv">
                                                <AgentEmail
                                                    isdouglaselliman={this.state.isDouglasElliman ? 1 : 0}
                                                    className="iconsWarantyCase4"
                                                />
                                            </div>

                                            <div className="warantySelectFilter">
                                                <div className="inputLabel">
                                                    <InputLabel className="inputLable">
                                                        Closing/Title Agent Email
                                                    </InputLabel>
                                                </div>
                                                <TextField
                                                    className="input2"
                                                    label="Agent Email"
                                                    id="outlined-start-adornment"
                                                    onChange={this.agentEmailHandler}
                                                    variant="filled"
                                                    value={this.state.step3.agentEmail}
                                                    // error={
                                                    //     this.state.continueClick && this.state.step3.agentEmail === ''
                                                    // }
                                                    // helperText={
                                                    //     this.state.continueClick && this.state.step3.agentEmail === ''
                                                    //         ? 'Enter Agent Email Id'
                                                    //         : ''
                                                    // }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="workingWithTitleCheckCompany">
                                        <Checkbox />
                                        Date currently unknown
                                    </div> */}
                                    {/* <div className="workingWithTitleCompany">
                                        <div className="">Are you working with a title company?</div>

                                        <div className="radioButtons">
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="yesNo"
                                                    // defaultValue="companyTitle"
                                                    name="radio-buttons-group"
                                                    value={this.state.step3.workingWithTitleCompany}
                                                    onChange={(e: any) => {
                                                        let step3: any = {
                                                            ...this.state.step3,
                                                            workingWithTitleCompany: e.target.value,
                                                        };
                                                        this.setState({
                                                            step3: step3,
                                                        });
                                                    }}
                                                >
                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>

                                        {this.state.step3.workingWithTitleCompany &&
                                        this.state.step3.workingWithTitleCompany === 'yes' ? (
                                            <div className="yesNoWarantyStep3">
                                                <div className="inputsWarantyCase2">
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <ClosingTitle className="iconsWarantyCase4" />
                                                        </div>
                                                        <div className="warantySelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel className="inputLable">
                                                                    *Closing/Title Company
                                                                </InputLabel>
                                                            </div>
                                                            <TextField
                                                                className="input2"
                                                                label="Closing/Title Company"
                                                                id="outlined-start-adornment"
                                                                onChange={this.titleCompanyHandler}
                                                                variant="filled"
                                                                value={this.state.step3.titleCompany}
                                                                error={
                                                                    this.state.continueClick &&
                                                                    this.state.step3.titleCompany === ''
                                                                }
                                                                helperText={
                                                                    this.state.continueClick &&
                                                                    this.state.step3.titleCompany === ''
                                                                        ? 'Please enter company name'
                                                                        : ''
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <ClosingAgent className="iconsWarantyCase4" />
                                                        </div>
                                                        <div className="warantySelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel className="inputLable">
                                                                    Closing/Title Agent
                                                                </InputLabel>
                                                            </div>
                                                            <TextField
                                                                className="input1"
                                                                label="Closing/title agent or officer"
                                                                id="outlined-start-adornment"
                                                                onChange={this.titleAgentHandler}
                                                                variant="filled"
                                                                value={this.state.step3.titleAgent}
                                                                // error={this.state.step3.titleAgent === ''}
                                                                // helperText={
                                                                //     this.state.step3.titleAgent === '' ? 'Please enter Title Agent' : ''
                                                                // }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inputsWarantyCase2">
                                                    <div className="addressFlexDivIconInput">
                                                        <div className="iconDiv">
                                                            <ClosingFile className="iconsWarantyCase4" />
                                                        </div>
                                                        <div className="warantySelectFilter">
                                                            <div className="inputLabel">
                                                                <InputLabel className="inputLable">
                                                                    Closing/Title Agent
                                                                </InputLabel>
                                                            </div>
                                                            <TextField
                                                                className="input1"
                                                                label="Closing file #"
                                                                id="outlined-start-adornment"
                                                                onChange={this.closingFileHandler}
                                                                variant="filled"
                                                                value={this.state.step3.closingFile}
                                                                // error={this.state.step3.closingFile === ''}
                                                                // helperText={
                                                                //     this.state.step3.closingFile === ''
                                                                //         ? 'Please enter closing File'
                                                                //         : ''
                                                                // }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="addressFlexDivIconInput"></div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {this.state.step3.workingWithTitleCompany &&
                                        this.state.step3.workingWithTitleCompany === 'yes' ? (
                                            <div className="sureUwant">
                                                <InfoOutlinedIcon />
                                                You can check “No” if you are unsure
                                            </div>
                                        ) : null}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return 'step not found';
        }
    };

    handleSelect2 = (address: any) => {
        // let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                //    add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        state: state,
                        zipcode: zip,
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    country === 'USA' ||
                    (country === 'US' &&
                        // state &&
                        // city &&
                        // zip &&
                        // streetNumber &&
                        // route &&
                        this.state.moveId)
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    render() {
        const steps = this.getSteps();
        const active = 4;

        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let { year } = this.state.step2;
        let currentYear: number = new Date().getFullYear();

        if (currentYear - parseInt(year) < 3) {
            this.state.showNewConstruction = true;
            this.state.step2.isNewConstruction = 'yes';
        }
        if (currentYear - parseInt(year) >= 3) {
            this.state.showNewConstruction = false;
            this.state.step2.isNewConstruction = 'no';
        }

        const breadList = [
            {
                name: 'Home Warranty',
                url: ''
            }
        ]

        return (
            <div>
                <div className={isDouglasElliman ? 'waranty-main-page waranty-main-pageD' : 'waranty-main-page'}>
                    <div className="main-head-waranty-main">
                        <h2 className="heading">Home Warranty</h2>
                        <div className='mb-4'>
                            <BreadCrumb breadList={breadList} />
                        </div>
                        {/* {this.state.activeStep != 0 ? ( */}
                        <div
                            className={
                                this.state.isDouglasElliman
                                    ? ' warrantyAddressDiv warrantyAddressDivDoug '
                                    : 'warrantyAddressDiv'
                            }
                        >
                            <div className="addressText">Your address</div>
                            <RoomIcon className="addressRoomIcon" style={{ color: '#006DFF' }} />
                            <AutoComplete
                                className="warrantyStepsAutoComplete"
                                value={this.state.address}
                                onChange={e => {
                                    this.setState({ address: e });
                                }}
                                onSelect={this.handleSelect2}
                                // placeholder="Street"
                                // inputTitle="To"
                            />
                        </div>
                        {/* ) : null} */}
                    </div>
                    <div className="coverDivWaranty-2">
                        <div className="landingPageWaranty">
                            <div>{this.getStepContent(this.state.activeStep)}</div>
                            <div className="Para11Waranty">
                                STEP {this.state.activeStep + 1} OF {steps.length}
                            </div>
                            <div
                                style={{ marginBottom: '15px' }}
                                className={isDouglasElliman ? 'progressStepD' : 'progressStep'}
                            >
                                <Linear value={((this.state.activeStep + 1) / 4) * 100} />
                            </div>
                            <Stepper activeStep={this.state.activeStep}>
                                {/* {steps.map((label: any) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: { optional?: React.ReactNode } = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}></StepLabel>
                                        </Step>
                                    );
                                })} */}
                            </Stepper>
                            <div className="buttons-waranty">
                                <div></div>
                                <div>
                                    {this.state.activeStep === 0 ? null : (
                                        <Button
                                            className="btnWaranty3"
                                            disabled={this.state.activeStep === 0}
                                            onClick={this.handleBack}
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? 'none'
                                                    : `0 0 3px 0 ${
                                                          this.state.slug && this.state.slug.primary_color
                                                              ? this.state.slug.primary_color
                                                              : '#273E59'
                                                      }`
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            border={`1px solid ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }`}
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 15px 0 0"
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    {this.state.activeStep == 0 ? (
                                        <Button
                                            className="btnWaranty3"
                                            // onClick={this.handleStep1}
                                            onClick={this.handleStep2}
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 15px 0 0"
                                            // disabled={!this.state.step3.checkbox2 && !this.state.step3.checkbox3}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btnWaranty3"
                                            onClick={
                                                this.state.activeStep === 1
                                                    ? this.handleStep3
                                                    : // : this.state.activeStep === 2
                                                      // ? this.handleStep3
                                                      ''
                                            }
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : '#273E59'
                                            }
                                            borderRadius={isDouglasElliman ? '0px' : '5px'}
                                            fontSize="16px"
                                            margin="0 15px 0 0"
                                            // disabled={
                                            //     this.state.activeStep === 2 &&
                                            //     !(
                                            //         this.state.step3.checkbox2 ||
                                            //         this.state.step3.checkbox3 ||
                                            //         this.state.step3.checkbox1
                                            //     )
                                            // }
                                        >
                                            {this.state.activeStep === active - 1 ? 'Back' : 'Next'}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="moving-Footer">
                    <Footer />
                </div> */}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        insuranceAction: bindActionCreators(InsuranceAction, dispatch),
        homeWarrantyAction: bindActionCreators(HomeWarrantyAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch)
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    insuranceState: state.insurance,
    homeWarrantyState: state.warranty,
});

export default connect(mapStateToProps, mapDispatchToProps)(WarantySteps);
