import * as React from 'react';

function SvgComponent(props: any) {
    let slug: any = localStorage.getItem('slug');
    slug = JSON.parse(slug);
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width={51.798} height={51.799} viewBox="0 0 51.798 51.799">
            <defs>
                <style>
                    {slug && slug.primary_color
                        ? props.isdouglaselliman
                            ? `.homeTypeprefix__c{fill:#333333}`
                            : `.homeTypeprefix__c{fill:${slug.primary_color}}`
                        : '.homeTypeprefix__c{fill:#002e62}'}
                </style>
            </defs>
            <path
                d="M36.855 20.24v16.615a1.949 1.949 0 01-.179.817c-.02.02-.02.06-.04.08a1.968 1.968 0 01-1.773 1.1h-17.93a2 2 0 01-1.992-1.997V20.24l.359-.239 10.598-7.052z"
                fill={slug && slug.color_shade4 ? (props.isdouglaselliman ? '#ffffff' : slug.color_shade4) : '#5b7088'}
            />
            <path
                d="M28.887 31.875v6.973H22.91v-6.754a3.122 3.122 0 012.988-3.207 2.984 2.984 0 012.989 2.988z"
                fill="#fff"
            />
            <path
                className="homeTypeprefix__c"
                d="M25.9 0A25.928 25.928 0 004.63 11.142l-.673-3.409A1 1 0 102 8.118l1.159 5.865a1.02 1.02 0 001.169.783l5.863-1.159a1 1 0 10-.386-1.952l-3.56.7A23.853 23.853 0 111.992 25.9 1 1 0 100 25.9 25.9 25.9 0 1025.9 0z"
            />
            <path
                className="homeTypeprefix__c"
                d="M41.668 23.462a1 1 0 00-.277-1.381l-14.941-9.96a1 1 0 00-1.106 0l-14.941 9.961a1 1 0 001.106 1.657l14.39-9.592 14.388 9.592a1 1 0 001.381-.277z"
            />
            <path
                className="homeTypeprefix__c"
                d="M14.945 23.906a1 1 0 00-1 1v11.949a2.991 2.991 0 002.988 2.988h17.93a2.992 2.992 0 002.988-2.988V24.906a1 1 0 00-1.992 0v11.949a1 1 0 01-1 1h-4.98v-5.98a3.985 3.985 0 10-7.969 0v5.977h-4.98a1 1 0 01-1-1V24.906a1 1 0 00-.985-1zm8.965 7.969a1.992 1.992 0 113.984 0v5.977h-3.988z"
            />
        </svg>
    );
}

export default SvgComponent;
