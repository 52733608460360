import React from 'react';
import './HomeProjectBanner.scss';
import Worker from '../../Assets/images/HomeValuation/worker.png';
import Button from '../atoms/Button';
import AnalyticsService from '../../pages/analytics-service';

const HomeProjectBanner = (props: any) => {
    const { analyticsData } = props;

    const handleBtnClick = () => {
        AnalyticsService.buttonClicked({
            ...analyticsData,
            button_copy: 'Explore Home Pros',
            banner: true,
            banner_title: 'Need A Professional For Your Next Home Project?',
            category_name: 'Explore Home Pros Button Clicked',
        });
        props.navigateToRoute('/dashboard/ourvendors');
    };

    return (
        <section className="Main">
            <div className="MainBox1">
                <img src={Worker} alt="" />
            </div>
            <div className="MainBox2">
                <span className="mainHeading">Need A Professional For Your Next Home Project?</span>
                <span className="subHeading">Find reputable home pros and get quotes</span>
                <Button color="#FFF" borderRadius={'5px'} fontSize="14px" onClick={handleBtnClick}>
                    Explore Home Pros
                </Button>
            </div>
        </section>
    );
};

export default HomeProjectBanner;
