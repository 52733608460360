import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 38.98}
            height={props.height ? props.height : 33.678}
            viewBox="0 0 38.98 33.678"
        >
            <defs>
                <style>
                    {/* {".prefix__b{fill:#bebebe}"} */}
                    {`.reserveStorageSvgprefix__b{fill:${
                        window.location.pathname.includes('storage') && slug && slug.primary_color
                            ? `${slug.primary_color} !important`
                            : '#ffffff !important'
                    }}.reserveStorageSvgprefix__c{fill:${
                        window.location.pathname.includes('storage') && slug && slug.primary_color
                            ? `#009cbd !important`
                            : '#6b6c6f !important'
                    }}`}
                </style>
            </defs>
            <path d="M33.234 10.985v21.567h-5.963v-16.56H11.694v16.559H5.731V10.995l13.78-9.83z" fill="#fff" />
            <path
                className="reserveStorageSvgprefix__b"
                d="M11.694 28.677h15.577v3.875H11.694zM11.694 24.449h15.577v4.228H11.694zM11.694 20.221h15.577v4.228H11.694zM11.694 15.993h15.577v4.228H11.694z"
            />
            <path
                d="M1.166 15.419a1.059 1.059 0 00.641-.216l2.689-1.944v19.294a1.194 1.194 0 001.236 1.126h27.5a1.194 1.194 0 001.236-1.126v-19.3l2.687 1.948a1.145 1.145 0 001.609-.27 1.168 1.168 0 00-.278-1.626L20.195.217a1.169 1.169 0 00-1.358 0L.489 13.304a1.166 1.166 0 00.677 2.115zm24.978 3.605H12.821v-1.832h13.323zm-13.322 2.331h13.322v2H12.821zm0 4.33h13.322v1.832H12.821zm0 4.163h13.322v1.5H12.821zm6.685-27.25l12.632 8.987v19.761h-3.664V15.993a1.171 1.171 0 00-1.2-1.132h-15.58a1.171 1.171 0 00-1.2 1.132v15.354H6.827V11.595z"
                // fill="#6b6c6f"
                className="reserveStorageSvgprefix__c"
            />
        </svg>
    );
}

export default SvgComponent;
