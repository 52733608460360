import {
    POST_NEXTMOVE,
    POST_NEXTMOVE_ERROR,
    POST_NEXTMOVE_SUCCESS,
    SEND_REALTOR_NOTIFICATION,
    SEND_REALTOR_NOTIFICATION_ERROR,
    SEND_REALTOR_NOTIFICATION_SUCCESS,
    GET_NEXTMOVE,
    GET_NEXTMOVE_ERROR,
    GET_NEXTMOVE_SUCCESS,
    UPDATE_NEXTMOVE,
    UPDATE_NEXTMOVE_ERROR,
    UPDATE_NEXTMOVE_SUCCESS,
} from './actionTypes';

import { NextMoveActions, NextMoveState } from './types';
const initialState: NextMoveState = {
    postNextMove: [],
    isPostNextMove: false,
    error: '',
    sendRealtorNotification: [],
    isSendRealtorNotification: false,
    getNextMove: [],
    isGetNextMove: false,
    updateNextMove: [],
    isUpdateNextMove: false,
};
export default (state = initialState, action: NextMoveActions) => {
    switch (action.type) {
        case POST_NEXTMOVE:
            return {
                ...state,
                postNextMove: [],
                isPostNextMove: false,
                error: null,
            };
        case POST_NEXTMOVE_SUCCESS:
            return {
                ...state,
                postNextMove: action.payload.postNextMove,
                isPostNextMove: true,
                error: null,
            };
        case POST_NEXTMOVE_ERROR:
            return {
                ...state,
                postNextMove: [],
                isPostNextMove: false,
                error: action.payload.error,
            };
        case UPDATE_NEXTMOVE:
            return {
                ...state,
                updateNextMove: [],
                isUpdateNextMove: false,
                error: null,
            };
        case UPDATE_NEXTMOVE_SUCCESS:
            return {
                ...state,
                updateNextMove: action.payload.updateNextMove,
                isUpdateNextMove: true,
                error: null,
            };
        case UPDATE_NEXTMOVE_ERROR:
            return {
                ...state,
                updateNextMove: [],
                isUpdateNextMove: false,
                error: action.payload.error,
            };
        case SEND_REALTOR_NOTIFICATION:
            return {
                ...state,
                sendRealtorNotification: [],
                isSendRealtorNotification: false,
                error: null,
            };
        case SEND_REALTOR_NOTIFICATION_SUCCESS:
            return {
                ...state,
                sendRealtorNotification: action.payload.sendRealtorNotification,
                isSendRealtorNotification: true,
                error: null,
            };
        case SEND_REALTOR_NOTIFICATION_ERROR:
            return {
                ...state,
                sendRealtorNotification: [],
                isSendRealtorNotification: false,
                error: action.payload.error,
            };
        case GET_NEXTMOVE:
            return {
                ...state,
                getNextMove: [],
                isGetNextMove: false,
                error: null,
            };
        case GET_NEXTMOVE_SUCCESS:
            return {
                ...state,
                getNextMove: action.payload.getNextMove,
                isGetNextMove: true,
                error: null,
            };
        case GET_NEXTMOVE_ERROR:
            return {
                ...state,
                getNextMove: [],
                isGetNextMove: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
