import {
    GET_VENDORS,
    GET_VENDORS_ERROR,
    GET_VENDORS_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_ERROR,
    GET_CATEGORIES_SUCCESS,
    POST_IMPRESSION,
    POST_IMPRESSION_ERROR,
    POST_IMPRESSION_SUCCESS,
    GET_CATEGORIES_QUESTIONS,
    GET_CATEGORIES_QUESTIONS_SUCCESS,
    GET_CATEGORIES_QUESTIONS_ERROR,
    POST_VENDORS,
    POST_VENDORS_ERROR,
    POST_VENDORS_SUCCESS,
    REQUEST_VENDORS,
    REQUEST_VENDORS_SUCCESS,
    REQUEST_VENDORS_ERROR,
    GET_MOVE_VENDOR,
    GET_MOVE_VENDOR_ERROR,
    GET_MOVE_VENDOR_SUCCESS,
    GET_HOME_ADVISOR,
    GET_HOME_ADVISOR_ERROR,
    GET_HOME_ADVISOR_SUCCESS,
    GET_STANDALONE_VENDORS,
    GET_STANDALONE_VENDORS_ERROR,
    GET_STANDALONE_VENDORS_SUCCESS,
    GET_STANDALONE_OFFICES,
    GET_STANDALONE_OFFICES_ERROR,
   GET_STANDALONE_OFFICES_SUCCESS,
   POST_STANDALONE_VENDORS,
   POST_STANDALONE_VENDORS_ERROR,
   POST_STANDALONE_VENDORS_SUCCESS,
   POST_STANDALONE_REQUESTVENDORS,
   POST_STANDALONE_REQUESTVENDORS_SUCCESS,
   POST_STANDALONE_REQUESTVENDORS_ERROR,
   POST_STANDALONE_IMPRESSION,
   POST_STANDALONE_IMPRESSION_SUCCESS,
   POST_STANDALONE_IMPRESSION_ERROR,
} from './actionTypes';

import { TrustedProsActions, TrustedProsState } from './types';

const initialState: TrustedProsState = {
    vendors: [],
    categories: [],
    impression: [],
    categoriesQuestions: [],
    postVendors: [],
    postVendorsArrived: false,
    requestVendors: [],
    getMoveVendorerror: null,
    getMoveVendors: [],
    homeAdvisor: [],
    standAloneVendors:[],
    standAloneVendorsDataArrived:false,
    standAloneOffices:[],
    standAloneOfficesDataArrived:false,
    standAloneVendorsPosted:[],
    standAlonetVendorsPostedFlag:false,
    standAloneRequestVendorsPosted:[],
    standAloneRequestVendorsPostedFlag:false,
    standAloneImpressionPosted:[],
    standAloneImpressionPostedFlag:false
};

export default (state = initialState, action: TrustedProsActions) => {
    switch (action.type) {
        case GET_VENDORS:
            return {
                ...state,
                vendors: [],
                error: null,
            };
        case GET_VENDORS_SUCCESS:
            return {
                ...state,
                vendors: action.payload.vendors,
                error: null,
            };
        case GET_VENDORS_ERROR:
            return {
                ...state,
                vendors: [],
                error: action.payload.error,
            };

        case GET_MOVE_VENDOR:
            return {
                ...state,
                getMoveVendor: [],
                error: null,
            };
        case GET_MOVE_VENDOR_SUCCESS:
            return {
                ...state,
                getMoveVendor: action.payload.getMoveVendor,
                error: null,
            };
        case GET_MOVE_VENDOR_ERROR:
            return {
                ...state,
                getMoveVendor: [],
                error: action.payload.getMoveVendorerror,
            };

        case GET_CATEGORIES:
            return {
                ...state,
                categories: [],
                error: null,
            };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.categories,
                error: null,
            };
        case GET_CATEGORIES_ERROR:
            return {
                ...state,
                categories: [],
                error: action.payload.error,
            };

        case POST_IMPRESSION:
            return {
                ...state,
                impression: [],
                error: null,
            };
        case POST_IMPRESSION_SUCCESS:
            return {
                ...state,
                impression: action.payload.impression,
                error: null,
            };
        case POST_IMPRESSION_ERROR:
            return {
                ...state,
                impression: [],
                error: action.payload.error,
            };
        case GET_CATEGORIES_QUESTIONS:
            return {
                ...state,
                categoriesQuestions: [],
                error: null,
            };
        case GET_CATEGORIES_QUESTIONS_SUCCESS:
            return {
                ...state,
                categoriesQuestions: action.payload.categoriesQuestions,
                error: null,
            };
        case GET_CATEGORIES_QUESTIONS_ERROR:
            return {
                ...state,
                categoriesQuestions: [],
                error: action.payload.error,
            };

        case POST_VENDORS:
            return {
                ...state,
                postVendors: [],
                error: null,
            };
        case POST_VENDORS_SUCCESS:
            return {
                ...state,
                postVendors: action.payload.postVendors,
                error: null,
                postVendorsArrived: true,
            };
        case POST_VENDORS_ERROR:
            return {
                ...state,
                postVendors: [],
                error: action.payload.error,
            };

        case POST_VENDORS:
            return {
                ...state,
                postVendors: [],
                postVendorsArrived: false,
                error: null,
            };
        case POST_VENDORS_SUCCESS:
            return {
                ...state,
                postVendors: action.payload.postVendors,
                postVendorsArrived: true,
                error: null,
            };
        case POST_VENDORS_ERROR:
            return {
                ...state,
                postVendors: [],
                postVendorsArrived: false,
                error: action.payload.error,
            };

        case REQUEST_VENDORS:
            return {
                ...state,
                requestVendors: [],
                isRequestVendors: false,
                error: null,
            };
        case REQUEST_VENDORS_SUCCESS:
            return {
                ...state,
                requestVendors: action.payload.requestVendors,
                isRequestVendors: true,
                error: null,
            };
        case REQUEST_VENDORS_ERROR:
            return {
                ...state,
                requestVendors: [],
                isRequestVendors: false,
                error: action.payload.error,
            };

        case GET_HOME_ADVISOR:
            return {
                ...state,
                homeAdvisor: [],
                isGetHomeAdvisor: false,
                error: null,
            };
        case GET_HOME_ADVISOR_SUCCESS:
            return {
                ...state,
                homeAdvisor: action.payload.homeAdvisor,
                isGetHomeAdvisor: true,
                error: null,
            };
        case GET_HOME_ADVISOR_ERROR:
            return {
                ...state,
                homeAdvisor: [],
                isGetHomeAdvisor: false,
                error: action.payload.error,
            };

            case GET_STANDALONE_VENDORS:
                return {
                    ...state,
                    standAloneVendors: [],
                    error: null,
                    standAloneVendorsDataArrived:false
                };
            case GET_STANDALONE_VENDORS_SUCCESS:
                return {
                    ...state,
                    standAloneVendors: action.payload.standAloneVendors,
                    error: null,
                    standAloneVendorsDataArrived:true
                };
            case GET_STANDALONE_VENDORS_ERROR:
                return {
                    ...state,
                    standAloneVendors: [],
                    error: action.payload.error,
                    standAloneVendorsDataArrived:false
                };

                case GET_STANDALONE_OFFICES:
                    return {
                        ...state,
                        standAloneOffices: [],
                        error: null,
                        standAloneOfficesDataArrived:false
                    };
                case GET_STANDALONE_OFFICES_SUCCESS:
                    return {
                        ...state,
                        standAloneOffices: action.payload.standAloneOffices,
                        error: null,
                        standAloneOfficesDataArrived:true
                    };
                case GET_STANDALONE_OFFICES_ERROR:
                    return {
                        ...state,
                        standAloneOffices: [],
                        error: action.payload.error,
                        standAloneOfficesDataArrived:false
                    };

                    case POST_STANDALONE_VENDORS:
                        return {
                            ...state,
                            standAloneVendorsPosted: [],
                            standAlonetVendorsPostedFlag:false,
                            error: null,
                        };
                    case POST_STANDALONE_VENDORS_SUCCESS:
                        return {
                            ...state,
                            standAloneVendorsPosted: action.payload.standAloneVendorsPosted,
                            standAlonetVendorsPostedFlag:true,
                            error: null,
                        };
                    case POST_STANDALONE_VENDORS_ERROR:
                        return {
                            ...state,
                            standAloneVendorsPosted: [],
                            error: action.payload.error,
                            standAlonetVendorsPostedFlag:false,
                        };    

                        case POST_STANDALONE_REQUESTVENDORS:
                            return {
                                ...state,
                                standAloneRequestVendorsPosted: [],
                                error: null,
                                standAloneRequestVendorsPostedFlag:false
                            };
                        case POST_STANDALONE_REQUESTVENDORS_SUCCESS:
                            return {
                                ...state,
                                standAloneRequestVendorsPosted: action.payload.standAloneRequestVendorsPosted,
                                error: null,
                                standAloneRequestVendorsPostedFlag:true
                            };
                        case POST_STANDALONE_REQUESTVENDORS_ERROR:
                            return {
                                ...state,
                                standAloneRequestVendorsPosted: [],
                                error: action.payload.error,
                                standAloneRequestVendorsPostedFlag:false
                            };  
                            
                            case POST_STANDALONE_IMPRESSION:
                                return {
                                    ...state,
                                    standAloneImpressionPosted: [],
                                    error: null,
                                    standAloneImpressionPostedFlag:false
                                    
                                };
                            case POST_STANDALONE_IMPRESSION_SUCCESS:
                                return {
                                    ...state,
                                    standAloneImpressionPosted: action.payload.standAloneImpressionPosted,
                                    error: null,
                                    standAloneImpressionPostedFlag:true
                                };
                            case POST_STANDALONE_IMPRESSION_ERROR:
                                return {
                                    ...state,
                                    standAloneImpressionPosted: [],
                                    error: action.payload.error,
                                    standAloneImpressionPostedFlag:false
                                };    

        default:
            return {
                ...state,
            };
    }
};
