import React, { Component } from 'react';
import './utility.scss';
import Radio from '@material-ui/core/Radio';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonNew from '../../components/atoms/Button';
import * as EnergyAction from '../../store/energy/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QuestionSkeleton } from '../../components/skeletonLoaders/EnergySkeleton/QuestionSkeleton';
import SelectInput from '../../components/atoms/SelectInput';
import Footer from './Footer';
import * as CommonAction from '../../store/common/actions';
import { formCompletedSegment, buttonClicked, formStartedSegment } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history: any;
    energyAction?: any;
    energyState?: any;
    commonAction?: any;
    commonState?: any;
};
type initialState = {
    selectAddressOptions: any;
    selectAddress: any;
    slug: any;
    serviceTypeId: any;
    selectedCompany: any[];
    energyType: any;
    selectedEnergyType: any;
    energyTypeSelectedObject: any;
    electricityCompleted: boolean;
    gasCompleted: boolean;
    deregulatedState: any;
    deregulated: boolean;
    isDataArrived2: boolean;
    summaryData: any;
    loader: boolean;
    isLoading: boolean;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    moveId: any;
    prefilled: boolean;
    brokerage_key: any;
    agent_key: any;
};

export class utilitySteps extends Component<initialProps, initialState> {
    state: initialState = {
        selectAddressOptions: [],
        selectAddress: '',
        slug: { primary_color: '' },
        serviceTypeId: '',
        selectedCompany: [],
        energyType: [],
        selectedEnergyType: 'Electricity',
        energyTypeSelectedObject: [],
        electricityCompleted: false,
        gasCompleted: false,
        deregulatedState: [],
        deregulated: false,
        isDataArrived2: true,
        summaryData: [],
        loader: false,
        isLoading: true,
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        moveId: '',
        prefilled: false,
        brokerage_key: '',
        agent_key: '',
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.energyAction.getEnergySummary();
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let move_id: any = localStorage.getItem('moveKey');
        this.setState({
            slug: slug,
            brokerage_name: slug.display_name,
            brokerage_id: slug.brokerage_link,
            agent_name: slug.contact_name,
            agent_id: slug.id,
        });
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state) {
            if (this.props.history.location.state.serviceTypeId) {
                this.setState({ serviceTypeId: this.props.history.location.state.serviceTypeId });
                this.props.energyAction.getCompany({
                    service_type_id: this.props.history.location.state.serviceTypeId,
                });
            }
            if (this.props.history.location.state.showCompletedElectricity) {
                this.setState({
                    electricityCompleted: this.props.history.location.state.showCompletedElectricity,
                    selectedEnergyType: 'Natural Gas',
                });
            }
            if (this.props.history.location.state.showCompletedGas) {
                this.setState({ gasCompleted: this.props.history.location.state.showCompletedGas });
            }
            if (this.props.history.location.state.selectedCompany) {
                this.setState({
                    selectAddress: this.props.history.location.state.selectedCompany[0].utility_name,
                    selectedCompany: this.props.history.location.state.selectedCompany,
                });
            }
        }
        this.props.energyAction.getEnergyType();
        if (this.state.selectedEnergyType && this.state.selectAddress) {
            this.setState({
                prefilled: true,
            });
        }

        formStartedSegment(
            'Form Started',
            'Energy Savings',
            'Energy Savings',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_name,
            this.state.agent_key,
            '',
            'Next',
            true,
            this.state.prefilled,
            1,
            move_id,
        );

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
        analytics.track(
            'Page Viewed',
            {
                page: 'Energy Savings',
                brokerage_name: this.state.brokerage_name,
                brokerage_key: this.state.brokerage_key,
                agent_name: this.state.agent_name,
                agent_key: this.state.agent_key,
            },
            { user_id: move_id },
        );
    }

    componentDidUpdate(prevProps: initialProps) {
        const prevEnergy = prevProps && prevProps.energyState;
        const curEnergy = this.props && this.props.energyState;

        if (
            prevEnergy.getEnergyType != curEnergy.getEnergyType &&
            curEnergy.getEnergyType.services &&
            curEnergy.isGetEnergyType
        ) {
            curEnergy.isGetEnergyType = false;
            let energyType: any = [];
            let data: any = [];
            curEnergy.getEnergyType.services &&
                curEnergy.getEnergyType.services.map((item: any) => {
                    energyType.push(item);
                    return energyType;
                });
            data.push(energyType.find((item: any) => item.name === this.state.selectedEnergyType));
            this.setState({
                selectedEnergyType: this.state.selectedEnergyType,
                energyTypeSelectedObject: data,
                serviceTypeId: data[0].serviceTypeId,
            });
            this.props.energyAction.getCompany({
                service_type_id: data[0].serviceTypeId,
            });
            this.setState({ energyType: energyType });
        }

        if (prevEnergy.getCompany != curEnergy.getCompany && curEnergy.getCompany.utilities && curEnergy.isGetCompany) {
            curEnergy.isGetCompany = false;

            let options: any = [];
            curEnergy.getCompany.utilities &&
                curEnergy.getCompany.utilities.map((item: any) => {
                    options.push({ item: item, label: item.utility_name, value: item.utility_name });
                    return options;
                });
            this.setState({ selectAddressOptions: options, isLoading: false });
        }

        if (
            prevEnergy.getEnergySummary != curEnergy.getEnergySummary &&
            curEnergy.getEnergySummary &&
            curEnergy.isGetEnergySummary
        ) {
            curEnergy.isGetEnergySummary = false;
            this.setState({ summaryData: curEnergy.getEnergySummary });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived2
        ) {
            currentState.isDataArrived2 = false;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.deregulatedState =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.energy_options_deregulated &&
                nextProps.commonState.move[0].regulated_details.energy_options_deregulated.length
                    ? nextProps.commonState.move[0].regulated_details.energy_options_deregulated
                    : [];
            currentState.deregulated =
                nextProps.commonState.move[0].regulated_details &&
                nextProps.commonState.move[0].regulated_details.is_deregulated
                    ? nextProps.commonState.move[0].regulated_details.is_deregulated
                    : false;
        }

        return currentState;
    }

    handleChangeSelectAddress = (e: any) => {
        let data: any[] = [];

        this.state.selectAddressOptions.map((item: any) => {
            if (item && item.item && item.item.utility_name === e.value) {
                // console.log(item.item);
                data.push(item.item);
                return data;
            }
        });
        this.setState({ selectAddress: e.value, selectedCompany: data });
    };
    handleChangeEnergyType = (e: any) => {
        let data: any = [];
        data.push(this.state.energyType.find((item: any) => item.name === e.target.value));
        this.setState({
            selectedEnergyType: e.target.value,
            energyTypeSelectedObject: data,
            serviceTypeId: data[0].serviceTypeId,
        });
        this.props.energyAction.getCompany({
            service_type_id: data[0].serviceTypeId,
        });
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let showCompletedElectricity: boolean = false;
        let showCompletedGas: boolean = false;
        this.state.summaryData &&
            this.state.summaryData.map((item: any) => {
                if (item.service_type === 'Electricity') {
                    showCompletedElectricity = true;
                }
                if (item.service_type === 'Gas' || item.service_type === 'Natural Gas') {
                    showCompletedGas = true;
                }
            });

        const breadList = [
            {
                name: 'Energy Savings',
                url: ''
            }
        ]   

        return (
            <div>
                <div className="utility-powersaving-head">
                    <h2 className="heading">Energy Savings</h2>
                    <div className='mb-4'>
                        <BreadCrumb breadList={breadList} />
                    </div>
                    <div className="utility">
                        <div className="utilitesSteps">
                            <div className="utilityhead">
                                Select your current {this.state.selectedEnergyType} Provider
                            </div>
                            {this.state.energyType && this.state.energyType.length ? (
                                <div className="utilityInput">
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={this.state.selectedEnergyType}
                                            onChange={this.handleChangeEnergyType}
                                        >
                                            {this.state.energyType.map((option: any, i: number) => {
                                                let name: string = option && option.name ? option.name : '';
                                                let regulatedState: any = [];
                                                regulatedState = this.state.deregulatedState;
                                                if (this.state.deregulated) {
                                                    if (regulatedState.includes(name)) {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className="radioInputsUtility"
                                                                style={{
                                                                    border:
                                                                        this.state.selectedEnergyType === option.name
                                                                            ? '1px solid #273E59'
                                                                            : '1px solid #BEBEBE',
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value={option.name}
                                                                    control={
                                                                        <Radio
                                                                            disabled={
                                                                                option.name === 'Electricity' &&
                                                                                showCompletedElectricity
                                                                                    ? true
                                                                                    : option.name === 'Natural Gas' &&
                                                                                      showCompletedGas
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    }
                                                                    label={option.name}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className="radioInputsUtility"
                                                            style={{
                                                                border:
                                                                    this.state.selectedEnergyType === option.name
                                                                        ? '1px solid #273E59'
                                                                        : '1px solid #BEBEBE',
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                value={option.name}
                                                                control={
                                                                    <Radio
                                                                        disabled={
                                                                            option.name === 'Electricity' &&
                                                                            showCompletedElectricity
                                                                                ? true
                                                                                : option.name === 'Natural Gas' &&
                                                                                  showCompletedGas
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                }
                                                                label={option.name}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            ) : (
                                <div
                                    className="utilityInput"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <QuestionSkeleton />
                                    <QuestionSkeleton />
                                </div>
                            )}
                            <div className="selectDiv">
                                <SelectInput
                                    label={'Select Current Provider'}
                                    value={this.state.selectAddress}
                                    onChange={this.handleChangeSelectAddress}
                                    placeholder={
                                        this.state.selectAddress ? this.state.selectAddress : 'Select current provider'
                                    }
                                    options={this.state.selectAddressOptions}
                                    isLoading={this.state.isLoading}
                                    // inputLa
                                />
                            </div>
                            <div className="buttonUtilitySteps">
                                <ButtonNew
                                    className="btnUtilitySecondSteps"
                                    backgroundColor={'#fff'}
                                    color={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : this.state.slug && this.state.slug.primary_color
                                            ? this.state.slug.primary_color
                                            : '#273E59'
                                    }
                                    border={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : this.state.slug && this.state.slug.primary_color
                                            ? `1px solid ${this.state.slug.primary_color}`
                                            : '1px solid #273E59'
                                    }
                                    onClick={() => {
                                        this.props.history.push('/dashboard/utilities');
                                        let move_id: any = localStorage.getItem('moveKey');
                                        buttonClicked(
                                            'Button Clicked',
                                            'Energy Savings Page',
                                            this.state.brokerage_name,
                                            this.state.brokerage_key,
                                            this.state.agent_name,
                                            this.state.agent_key,
                                            'Energy Savings',
                                            'Go Back',
                                            false,
                                            '',
                                            false,
                                            '',
                                            '',
                                            false,
                                            '',
                                            true,
                                            1,
                                            '',
                                            move_id,
                                        );
                                    }}
                                >
                                    Go Back
                                </ButtonNew>
                                <ButtonNew
                                    className="btnUtilitySecondSteps"
                                    backgroundColor={
                                        isDouglasElliman
                                            ? '#100B28'
                                            : this.state.slug && this.state.slug.primary_color
                                            ? this.state.slug.primary_color
                                            : '#273E59'
                                    }
                                    color={'#fff'}
                                    onClick={() => {
                                        if (this.state.selectedEnergyType && this.state.selectedCompany.length) {
                                            this.props.history.push({
                                                pathname: '/dashboard/utilities/utilitiesPowerSaving',
                                                state: {
                                                    ...this.props.history.location.state,
                                                    serviceTypeId: this.state.serviceTypeId,
                                                    serviceTypeName: this.state.selectedEnergyType,
                                                    selectedCompany: this.state.selectedCompany,
                                                },
                                            });
                                            let move_id: any = localStorage.getItem('moveKey');
                                            buttonClicked(
                                                'Button Clicked',
                                                'Energy Savings Page',
                                                this.state.brokerage_name,
                                                this.state.brokerage_key,
                                                this.state.agent_name,
                                                this.state.agent_key,
                                                'Energy Savings',
                                                'Next',
                                                false,
                                                '',
                                                false,
                                                '',
                                                '',
                                                false,
                                                '',
                                                true,
                                                1,
                                                '',
                                                move_id,
                                            );

                                            formCompletedSegment(
                                                'Form Completed',
                                                'Energy Savings Page',
                                                'Energy Savings',
                                                this.state.brokerage_name,
                                                this.state.brokerage_key,
                                                this.state.agent_name,
                                                this.state.agent_key,
                                                '',
                                                'Next',
                                                true,
                                                this.state.prefilled,
                                                'Energy Savings Form',
                                                1,
                                                move_id,
                                            );
                                        }
                                    }}
                                    disabled={
                                        this.state.selectedEnergyType &&
                                        this.state.selectedCompany.length &&
                                        this.state.selectAddress
                                            ? false
                                            : true
                                    }
                                >
                                    Next
                                </ButtonNew>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        energyAction: bindActionCreators(EnergyAction, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    energyState: state.energy,
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(utilitySteps);
