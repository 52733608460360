import { DMV_DETAILS, DMV_DETAILS_SUCCESS, DMV_DETAILS_ERROR } from './actionTypes';
import * as types from './types';

export const dmv = (payload: types.DMVRequest): types.DMV => ({
    type: DMV_DETAILS,
    payload,
});

export const dmvSuccess = (payload: types.DMVSuccessPayload): types.DMVSuccess => ({
    type: DMV_DETAILS_SUCCESS,
    payload,
});

export const dmvFailure = (payload: types.DMVFailurePayload): types.DMVFailure => ({
    type: DMV_DETAILS_ERROR,
    payload,
});
