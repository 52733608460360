import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding ? : any
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 95.704}
            height={props.height ? props.height : 71}
            viewBox="0 0 95.704 71"
            className="checkBoximg"
        >
            <defs>
                <style>
                    {`.TVStepprefix__a{fill:${
                       props.fromlanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="TVStepprefix__a"
                d="M81.884 43.531a1.558 1.558 0 001.556-1.556V1.556A1.558 1.558 0 0081.884 0H13.821a1.558 1.558 0 00-1.556 1.556v40.419a1.558 1.558 0 001.556 1.556zm-67.285-41.2h66.507V41.2H14.599zM94.148 46.502H1.556A1.558 1.558 0 000 48.058v21.387a1.561 1.561 0 001.563 1.556H94.14a1.561 1.561 0 001.56-1.556V48.058a1.558 1.558 0 00-1.552-1.556zm-.778 10.977H65.52v-8.643h27.85zm-60.851 0v-8.643h30.667v8.643zM2.335 48.836h27.85v8.643H2.334zm0 10.977h44.35v8.853H2.334zm91.036 8.853H49.019v-8.853H93.37z"
            />
            <path
                className="TVStepprefix__a"
                d="M27.406 61.354h-4.129a1.167 1.167 0 100 2.334h4.129a1.167 1.167 0 100-2.334zM67.897 63.773h4.129a1.167 1.167 0 100-2.334h-4.129a1.167 1.167 0 100 2.334z"
            />
        </svg>
    );
}

export default SvgComponent;
