import React, { Component } from 'react';
import SelectInput from '../../components/atoms/SelectInput';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '../../components/atoms/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import PhoneSvg from '../../Assets/images/local_phone-greypx.svg';
import WifiSvg from '../../Assets/images/wifi-greypx.svg';
import TvSvg from '../../Assets/images/desktop_windows-greypx.svg';
import VerifiedSvg from '../../Assets/images/verified_user-24px.svg';
// import { InlineWidget } from 'react-calendly';
import Modal from '../../components/atoms/Modal';
import './ICmain.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/internet/actions';
import * as CommonAction from '../../store/common/actions';
import * as SignupAction from '../../store/login/signup/actions';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as DashboardAction from '../../store/dashboard/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import calendlySvg from '../../Assets/images/ICImages/Calendly .svg';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CloseIcon from '@material-ui/icons/Close';
import { scheduleSegment } from '../../utils/utlis';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';
import { updateChecklistTask } from '../../utils/common-functions';

type initialProps = {
    moveData?: any;
    buyNowData?: any;
    orderSummaryHandler?: any;
    commonAction?: any;
    commonState?: any;
    history?: any;
    location?: any;
    internetAction?: any;
    internetState?: any;
    dashboardAction: any;
    dashboardState: any;
    signupAction?: any;
    signupState?: any;
};
type initialState = {
    secretQuestion: any;
    dob: string;
    pin: string;
    buttonEnabled: boolean;
    schedule: boolean;
    calendlyOpen: boolean;
    showSecretInput: boolean;
    isOpen?: boolean;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    continueClick: boolean;
    destinationDetails: any;
    moving_date: any;
    site_name: string;
    nylas_full_service_scheduler_url: string;
    isDataArrived: boolean;
    updatedSomething: any;
    isSubmitClicked: boolean;
    custom_schedule_call_info_enabled: any;
    formOpen: boolean;
    slug: any;
    moveId: string;
    hashcode: string;
    brokerage_name: any;
    brokerage_id: any;
    agent_id: any;
    agent_name: any;
    brokerage_key: any;
    agent_key: any;
    concierge_ref: any;
};

export class Order extends Component<initialProps, initialState> {
    state = {
        secretQuestion: { value: '', label: '' },
        dob: '',
        pin: '',
        buttonEnabled: false,
        schedule: false,
        calendlyOpen: false,
        showSecretInput: false,
        isOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        continueClick: false,
        destinationDetails: '',
        moving_date: new Date(),
        site_name: 'moveeasy-demo',
        nylas_full_service_scheduler_url: '',
        isDataArrived: true,
        updatedSomething: '',
        isSubmitClicked: false,
        custom_schedule_call_info_enabled: false,
        formOpen: false,
        slug: { slug: '' },
        moveId: '',
        hashcode: '',
        brokerage_name: '',
        brokerage_id: '',
        agent_id: '',
        agent_name: '',
        brokerage_key: '',
        agent_key: '',
        concierge_ref: '',
    };

    componentDidMount() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({
            slug: slug,
        });
        if (slug && slug.enabled_features && slug.enabled_features.includes('Customize Concierge Contact Options')) {
            this.setState({ custom_schedule_call_info_enabled: true });
        }

        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.destinationData
        ) {
            this.setState({
                destinationDetails: this.props.history.location.state.destinationData.destination_full_address,
            });
        }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0]
            )
        ) {
            this.props.commonAction.moveGet();
        }
        this.props.dashboardAction.checklist();
        window.scroll(0, 0);
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;
            currentState.lastName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.last_name;
            currentState.email = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.email;
            currentState.phone = nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.phone;
            currentState.firstName =
                nextProps.commonState.move[0].user_ref && nextProps.commonState.move[0].user_ref.first_name;
            // currentState.custom_schedule_call_info = nextProps.commonState.move[0].realtor && nextProps.commonState.move[0].realtor.custom_schedule_call_info
            currentState.nylas_full_service_scheduler_url = nextProps.commonState.move[0].full_service_scheduler_url;
            // nextProps.commonState.move[0].assigned_concierge &&
            // nextProps.commonState.move[0].assigned_concierge.nylas_full_service_scheduler_url;
            currentState.moveId = nextProps.commonState.move[0].id;
            currentState.hashcode = nextProps.commonState.move[0].hash_code;
            currentState.brokerage_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.display_name;
            currentState.brokerage_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_link;
            currentState.agent_id =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.id;
            currentState.brokerage_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.brokerage_key;
            currentState.agent_key =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.realtor_key;
            currentState.agent_name =
                nextProps.commonState.move[0] &&
                nextProps.commonState.move[0].realtor &&
                nextProps.commonState.move[0].realtor.contact_name;
            currentState.concierge_ref =
                nextProps.commonState.move[0] && nextProps.commonState.move[0].concierge_reference_field;
        }
        if (
            nextProps &&
            nextProps.commonState &&
            // nextProps.commonState.scheduleCallPost &&
            nextProps.commonState.isScheduleCallPost
        ) {
            nextProps.commonState.isScheduleCallPost = false;
            currentState.formOpen = false;
            currentState.isSubmitClicked = false;
            nextProps.history.push('/dashboard/internet/summary');
        }

        // if (nextProps && nextProps.commonState && nextProps.commonState.appointmentDetailsArrived) {
        //     nextProps.commonState.appointmentDetailsArrived = false;
        //     // currentState.appointment_details = nextProps.commonState.appointmentDetails;
        //     // currentState.appointmentTime =
        //     //     nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.start_time;
        //     // currentState.reschedule_link =
        //     //     nextProps.commonState.appointmentDetails && nextProps.commonState.appointmentDetails.reschedule_link;
        //     // let hashcode: any = currentState && currentState.hashcode ? currentState.hashcode : '';
        // }

        return currentState;
    }

    componentDidUpdate(prevProps: initialProps) {
        const prev = prevProps && prevProps.internetState;
        const cur = this.props && this.props.internetState;
        if (
            prev.selectDigitalPlan !== cur.selectDigitalPlan &&
            cur.selectDigitalPlan &&
            cur.selectDigitalPlan.hash_code
        ) {

            updateChecklistTask(100, this.props.dashboardAction);
            if (
                this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.fromLanding &&
                this.props.location.state.fromLanding === 'fromLanding'
            ) {
                this.props.history.push({
                    pathname: '/landing/internet/summary',
                    state: {
                        fromLanding: 'fromLanding',
                    },
                });
            } else {
                this.props.history.push('/dashboard/internet/summary');
            }
        }

        if (
            (prevProps && prevProps.signupState && prevProps.signupState.landingMovePost) !==
            (this.props && this.props.signupState && this.props.signupState.landingMovePost)
        ) {
            this.setState({
                buttonEnabled: true,
                schedule: true,
            });
        }

        if (prevProps?.commonState?.appointmentDetailsArrived !== this.props.commonState?.appointmentDetailsArrived &&
            this.props.commonState.appointmentDetails && Object.keys(this.props.commonState.appointmentDetails).length &&
            this.props.commonState.appointmentDetails.start_time
        ) {
            this.orderSummaryHandler();
        }
    }
    closeFormHandler = async () => {
        await this.setState({ isSubmitClicked: true });
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            let payload = {
                move_id: this.state.moveId,
                body: {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                },
            };
            this.props.commonAction.scheduleCallPost(payload);
            await this.orderSummaryHandler();
            this.setState({ formOpen: false });
        }
    };
    nylasModalClosed = () => {
        // console.log("clicked")
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fromLanding &&
            this.props.location.state.fromLanding === 'fromLanding'
        ) {
            this.orderSummaryHandler();
        } else {
            this.setState({ calendlyOpen: false });
            let hash_code =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0].hash_code;

            const payload = {
                hash_code: hash_code,
            };

            let countNew = 0;
            setInterval(() => {
                if (countNew < 6) {
                    this.props.commonAction.appointmentGet(payload);
                    countNew = countNew + 1;
                }
            }, 10000);
        }
    };

    secretQuestionHandler = (e: any) => {
        this.setState({
            secretQuestion: {
                label: e.label,
                value: this.state.secretQuestion.value,
            },
            showSecretInput: true,
        });
    };

    secretQuestionValue = (e: any) => {
        this.setState({
            secretQuestion: {
                value: e.target.value,
                label: this.state.secretQuestion.label,
            },
        });
    };
    dobHandler = (e: any) => {
        this.setState({
            dob: e === '' || e === null ? '' : new Date(e).toLocaleDateString(),
        });
    };
    pinHandler = (e: any) => {
        this.setState({
            pin: e.target.value,
        });
    };
    nextHandler = () => {
        if (
            (this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.fromLanding &&
                this.props.location.state.fromLanding === 'fromLanding') ||
            window.location.pathname.includes('/landing')
        ) {
            this.setState({ continueClick: true });
            const { email, firstName, lastName, phone } = this.state;
            if (
                email &&
                firstName &&
                lastName &&
                phone &&
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)
            ) {
                //        this.setState({
                // buttonEnabled: true,
                // schedule: true,

                // });

                const domain = window.location.hostname;
                let name = domain.split('.');
                const payload = {
                    destination_detail: this.state.destinationDetails,
                    destination_home: '1',
                    moving_date: this.state.moving_date.toISOString().split('T')[0],
                    moving_in_date: null,
                    moving_items_list: ['2'],
                    moving_option_value: ['2'],
                    selected_service_list: ['11'],
                    user_ref: {
                        email: email,
                        first_name: firstName ? firstName : '',
                        last_name: lastName ? lastName : '',
                        // password: password,
                        phone: phone,
                        site_name:
                            name[0] == 'internet'
                                ? 'dtc-marketing'
                                : name[0] == 'internet-demo'
                                ? 'apps-demo'
                                : 'moveeasy-demo',
                    },
                };
                this.props.signupAction.landingMovePost(payload);
            }
        } else {
            this.setState({
                buttonEnabled: true,
                schedule: true,
            });
        }
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    firstNameHandler = (e: any) => {
        this.setState({ firstName: e.target.value });
    };
    lastNameHandler = (e: any) => {
        this.setState({ lastName: e.target.value });
    };
    emailHandler = (e: any) => {
        this.setState({ email: e.target.value });
    };
    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({ phone: phone });
    };
    editHandler = () => {
        this.setState({
            buttonEnabled: false,
            schedule: false,
        });
    };
    calendlyHandle = () => {
        this.setState({ calendlyOpen: true });
        let move_id: any = localStorage.getItem('moveKey');
        scheduleSegment(
            'Button Clicked',
            'Internet & Cable Order',
            this.state.brokerage_name,
            this.state.brokerage_key,
            this.state.agent_key,
            this.state.agent_name,
            'Internet & Cable',
            'Schedule A Call',
            true,
            `Schedule a call with your concierge`,
            true,
            'Great, you are almost done ',
            move_id,
        );
        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;
        let agent_name: any = brokerageCheck.contact_name;

        let windownew: any = window;
        let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];

        analytics.track(
            'Schedule Call Started',
            {
                page: 'Internet and Cable',
                brokerage_name: brokerage,
                brokerage_key: brokerage_key,
                agent_name: agent_name,
                agent_key: agent_key,
                concierge_name:
                    this.props.commonState.move[0] &&
                    this.props.commonState.move[0].assigned_concierge &&
                    this.props.commonState.move[0].assigned_concierge.full_name,

                appointment_type: 'FULL_MOVE',
            },
            { user_id: move_id },
        );
    };
    orderSummaryHandler = async () => {
        let buyNowData = this.props.location && this.props.location.state && this.props.location.state.data;
        // console.log("data",buyNowData)
        if (buyNowData) {
            this.props.internetAction.selectDigitalPlan({
                provider_name: buyNowData && buyNowData.provider_name,
                plan_name: buyNowData && buyNowData.name,
                price: buyNowData && buyNowData.base_price,
                plan_details: buyNowData,
            });
        }
    };

    formHandler = () => {
        this.setState({
            formOpen: true,
        });
    };
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        let moveData =
            this.props && this.props.commonState && this.props.commonState.move && this.props.commonState.move[0];
        let buyNowData = this.props.location && this.props.location.state && this.props.location.state.data;

        const breadList = [
            {
                name: 'Internet And Cable',
                url: ''
            },
            {
                name: 'Order placing',
                url: ''
            }
        ]

        return (
            <div className="OrderInternet">
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="main-head">
                    <h2 className="heading">Internet And Cable</h2>
                    <div className='mb-4'>
                        <BreadCrumb breadList={breadList} />
                    </div>
                </div>
                <div className="orderMainDivInternet">
                    {this.state.formOpen && (
                        <Modal
                            isShowModal={this.state.formOpen}
                            onCloseModal={() => {
                                this.setState({ formOpen: false });
                            }}
                            showClose={false}
                            className="modalClassesSecurity"
                        >
                            <div style={{ height: '100%' }}>
                                <div className="CloseIcon">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        Schedule a Call Back
                                    </div>
                                    <CloseIcon
                                        onClick={() => {
                                            this.setState({ formOpen: false });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="line12"></div>
                                <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                    <div
                                        style={{
                                            fontSize: '16px',
                                            // marginTop: '10px',
                                            color: '#333333',
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Personal Details
                                    </div>
                                    <div className="peronalDetailsInsurance">
                                        <div className="flex-div">
                                            <div className="div-1-name">
                                                <TextField
                                                    id="time"
                                                    label="First Name"
                                                    value={this.state.firstName}
                                                    onChange={(e: any) => this.firstNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.firstName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.firstName === ''
                                                        ? 'Please enter first name'
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="div-2-name">
                                                <TextField
                                                    id="time"
                                                    label="Last Name"
                                                    value={this.state.lastName}
                                                    onChange={(e: any) => this.lastNameHandler(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                    // disabled
                                                    error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                />
                                                <div className="error">
                                                    {this.state.isSubmitClicked && this.state.lastName === ''
                                                        ? 'Please enter last name'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <TextField
                                            id="time"
                                            label="Email"
                                            value={this.state.email}
                                            onChange={(e: any) => this.emailHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.email === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.email === ''
                                                ? 'Please enter email'
                                                : this.state.isSubmitClicked &&
                                                  !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                ? 'Please enter valid email'
                                                : ''}
                                        </div>
                                        <TextField
                                            id="time"
                                            label="Phone Number"
                                            value={this.state.phone}
                                            onChange={(e: any) => this.phoneHandler(e)}
                                            type="text"
                                            variant="filled"
                                            className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                            // disabled
                                            error={this.state.isSubmitClicked && this.state.phone === ''}
                                        />
                                        <div className="error">
                                            {this.state.isSubmitClicked && this.state.phone === ''
                                                ? 'Please enter phone number'
                                                : this.state.isSubmitClicked && this.state.phone.length < 12
                                                ? 'Please enter valid phone number'
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="line12"></div>
                                <div className="InsuranceOrderPageButton">
                                    <Button
                                        className="btnOrder"
                                        backgroundColor="#FFF"
                                        boxShadow={
                                            isDouglasElliman
                                                ? 'none'
                                                : `0 0 3px 0 ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        width="130px"
                                        height="50px"
                                        color={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : '#273e59'
                                        }
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 10px 0 0"
                                        border={
                                            isDouglasElliman
                                                ? '1px solid #100B28'
                                                : `1px solid ${
                                                      slug && slug.primary_color ? slug.primary_color : '#273e59'
                                                  }`
                                        }
                                        onClick={() => this.setState({ formOpen: false })}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btnOrder"
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : slug && slug.primary_color
                                                ? slug.primary_color
                                                : window.location.pathname.includes('landing')
                                                ? '#30AB86'
                                                : '#273e59'
                                        }
                                        // boxShadow="0 0 3px 0 #273E59"
                                        width="130px"
                                        height="50px"
                                        // color="#FFFFFF"
                                        borderRadius={isDouglasElliman ? '0px' : '5px'}
                                        fontSize="16px"
                                        margin="0 5px 0 0"
                                        onClick={() => this.closeFormHandler()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div>
                        <div className="head">Great, you are almost done </div>
                        <div className="headsub">
                            Set up a call with your {this.state.concierge_ref} to finalize the order and schedule your
                            installation
                        </div>
                    </div>
                    <div className="main-flex">
                        <div className="BoxCardLeft">
                            {!this.state.buttonEnabled &&
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.fromLanding &&
                                this.props.location.state.fromLanding === 'fromLanding' && (
                                    <div className="BoxCardInner">
                                        <div
                                            className="upperHeadDiv"
                                            style={{
                                                background: isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : this.props &&
                                                      this.props.location &&
                                                      this.props.location.state &&
                                                      this.props.location.state.fromLanding &&
                                                      this.props.location.state.fromLanding === 'fromLanding'
                                                    ? '#30AB86'
                                                    : '#273E59',
                                            }}
                                        >
                                            <span
                                                className="spanNumber"
                                                style={{
                                                    color: isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug && slug.primary_color
                                                        : this.props &&
                                                          this.props.location &&
                                                          this.props.location.state &&
                                                          this.props.location.state.fromLanding &&
                                                          this.props.location.state.fromLanding === 'fromLanding'
                                                        ? '#30AB86'
                                                        : '#273E59',
                                                    border: isDouglasElliman
                                                        ? '1px solid #100B28'
                                                        : `1px solid ${
                                                              slug && slug.primary_color
                                                                  ? slug && slug.primary_color
                                                                  : '#273E59'
                                                          }`,
                                                }}
                                            >
                                                1
                                            </span>{' '}
                                            Additional Information
                                        </div>
                                        <div className="InputDivs">
                                            {(this.props &&
                                                this.props.location &&
                                                this.props.location.state &&
                                                this.props.location.state.fromLanding &&
                                                this.props.location.state.fromLanding === 'fromLanding') ||
                                            window.location.pathname.includes('/landing') ? (
                                                <div className="onlyLandingDiv">
                                                    <div className="internet-formRow">
                                                        <div className="internet-formRow1">
                                                            <TextField
                                                                className="internet-input"
                                                                label="First Name"
                                                                id="outlined-start-adornment"
                                                                onChange={this.firstNameHandler}
                                                                variant="filled"
                                                                value={this.state.firstName}
                                                                error={
                                                                    this.state.continueClick && !this.state.firstName
                                                                }
                                                            />
                                                            <div className="error">
                                                                {this.state.continueClick &&
                                                                    !this.state.firstName &&
                                                                    'Please enter first name'}
                                                            </div>
                                                        </div>
                                                        <div className="internet-formRow1">
                                                            <TextField
                                                                className="internet-input"
                                                                label="Last Name"
                                                                id="outlined-start-adornment"
                                                                onChange={this.lastNameHandler}
                                                                variant="filled"
                                                                value={this.state.lastName}
                                                                error={this.state.continueClick && !this.state.lastName}
                                                            />
                                                            <div className="error">
                                                                {this.state.continueClick &&
                                                                    !this.state.lastName &&
                                                                    'Please enter last name'}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="internet-formRow">
                                                        <div className="internet-formRow1">
                                                            <TextField
                                                                className="internet-input"
                                                                label="Email"
                                                                id="outlined-start-adornment"
                                                                onChange={this.emailHandler}
                                                                variant="filled"
                                                                value={this.state.email}
                                                                error={this.state.continueClick && !this.state.email}
                                                            />
                                                            <div className="error">
                                                                {this.state.continueClick &&
                                                                    !this.state.email &&
                                                                    'Please enter email'}
                                                            </div>
                                                        </div>
                                                        <div className="internet-formRow1">
                                                            <TextField
                                                                className="internet-input"
                                                                label="Phone"
                                                                id="outlined-start-adornment"
                                                                onChange={this.phoneHandler}
                                                                variant="filled"
                                                                value={this.state.phone}
                                                                error={
                                                                    (this.state.continueClick && !this.state.phone) ||
                                                                    (this.state.continueClick &&
                                                                        this.state.phone !== '' &&
                                                                        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                                            this.state.phone,
                                                                        ))
                                                                }
                                                            />
                                                            <div className="error">
                                                                {this.state.continueClick && !this.state.phone
                                                                    ? 'Please enter phone'
                                                                    : this.state.continueClick &&
                                                                      this.state.phone &&
                                                                      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                                                                          this.state.phone,
                                                                      )
                                                                    ? 'Please enter valide phone number'
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <SelectInput
                                                        className="react-select questionInternet"
                                                        onChange={(e: any) => this.secretQuestionHandler(e)}
                                                        options={[
                                                            { value: '', label: 'What was your first pet' },
                                                            {
                                                                value: '',
                                                                label: 'In what city were you born?',
                                                            },
                                                            {
                                                                value: '',
                                                                label: 'What was your childhood nickname?',
                                                            },
                                                        ]}
                                                        placeholder={
                                                            this.state.secretQuestion.label
                                                                ? this.state.secretQuestion.label
                                                                : 'Select Secret Question'
                                                        }
                                                        value={this.state.secretQuestion.label}
                                                    />
                                                </div>
                                            )}
                                            {this.state.showSecretInput ? (
                                                <TextField
                                                    id="input"
                                                    label="Enter answer to your secret question"
                                                    value={this.state.secretQuestion.value}
                                                    onChange={(e: any) => this.secretQuestionValue(e)}
                                                    type="text"
                                                    variant="filled"
                                                    className="answer"
                                                />
                                            ) : null}
                                            {(this.props &&
                                                this.props.location &&
                                                this.props.location.state &&
                                                this.props.location.state.fromLanding &&
                                                this.props.location.state.fromLanding === 'fromLanding') ||
                                            window.location.pathname.includes('/landing') ? null : (
                                                <div className="bithdatePinDiv">
                                                    {/* <TextField
                                                    id="date"
                                                    label="Select date of birth"
                                                    // defaultValue={Date.now}
                                                    value={this.state.dob}
                                                    onChange={(e: any) => this.dobHandler(e)}
                                                    type="date"
                                                    variant="filled"
                                                    className="birthdate"
                                                /> */}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            // disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date of birth"
                                                            className="birthdate"
                                                            value={
                                                                this.state.dob === '' ? null : new Date(this.state.dob)
                                                            }
                                                            onChange={(e: any) => this.dobHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                                // onFocus: () => {
                                                                //     this.setState({ isOpen: true });
                                                                // },
                                                            }}
                                                            autoOk={true}
                                                            // PopoverProps={{
                                                            //     disableRestoreFocus: true,
                                                            //     onClose: () => {
                                                            //         this.setState({ isOpen: false });
                                                            //     },
                                                            // }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen: true });
                                                            //     },
                                                            // }}
                                                            // open={this.state.isOpen}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <TextField
                                                        // type="password"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <LockOutlinedIcon style={{ color: '#6B6C6F' }} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        id="outlined-basic"
                                                        className="pin"
                                                        value={this.state.pin}
                                                        label="Enter pin number here"
                                                        onChange={(e: any) => this.pinHandler(e)}
                                                        variant="filled"
                                                    />
                                                </div>
                                            )}

                                            <div className="btnnext">
                                                <Button
                                                    className="btnOrder"
                                                    backgroundColor={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : this.props &&
                                                              this.props.location &&
                                                              this.props.location.state &&
                                                              this.props.location.state.fromLanding &&
                                                              this.props.location.state.fromLanding === 'fromLanding'
                                                            ? '#30AB86'
                                                            : '#273E59'
                                                    }
                                                    // boxShadow="0 0 3px 0 #273E59"
                                                    width="100%"
                                                    height="50px"
                                                    // color="#FFFFFF"
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="16px"
                                                    margin="0 5px 0 0"
                                                    onClick={() => this.nextHandler()}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {this.state.buttonEnabled &&
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.fromLanding &&
                                this.props.location.state.fromLanding === 'fromLanding' && (
                                    <div className="BoxCardInner">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                className="upperHeadDiv1"
                                                style={{
                                                    color: isDouglasElliman
                                                        ? '#100B28'
                                                        : slug && slug.primary_color
                                                        ? slug.primary_color
                                                        : this.props &&
                                                          this.props.location &&
                                                          this.props.location.state &&
                                                          this.props.location.state.fromLanding &&
                                                          this.props.location.state.fromLanding === 'fromLanding'
                                                        ? '#30AB86'
                                                        : '#273E59',
                                                }}
                                            >
                                                <span
                                                    className="spanNumber1"
                                                    style={{
                                                        background: isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug && slug.primary_color
                                                            : this.props &&
                                                              this.props.location &&
                                                              this.props.location.state &&
                                                              this.props.location.state.fromLanding &&
                                                              this.props.location.state.fromLanding === 'fromLanding'
                                                            ? '#30AB86'
                                                            : '#273E59',
                                                    }}
                                                >
                                                    1
                                                </span>{' '}
                                                Additional Information
                                            </div>
                                            <div>
                                                <Button
                                                    className="btnOrder"
                                                    backgroundColor="#FFFFFF"
                                                    boxShadow={
                                                        isDouglasElliman
                                                            ? 'none'
                                                            : `0 0 3px 0 ${
                                                                  slug && slug.primary_color
                                                                      ? slug.primary_color
                                                                      : '#273E59'
                                                              }`
                                                    }
                                                    color={
                                                        isDouglasElliman
                                                            ? '#100B28'
                                                            : slug && slug.primary_color
                                                            ? slug.primary_color
                                                            : '#273E59'
                                                    }
                                                    width="100px"
                                                    height="50px"
                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                    fontSize="16px"
                                                    margin="0 20px"
                                                    onClick={() => this.editHandler()}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="InputDivs">
                                            {(this.props &&
                                                this.props.location &&
                                                this.props.location.state &&
                                                this.props.location.state.fromLanding &&
                                                this.props.location.state.fromLanding === 'fromLanding') ||
                                            window.location.pathname.includes('/landing') ? (
                                                <div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <PersonIcon style={{ marginRight: '5px' }} />
                                                        <div>First Name</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.firstName ? this.state.firstName : 'null'}
                                                    </div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <PersonIcon style={{ marginRight: '5px' }} />
                                                        <div>Last Name</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.lastName ? this.state.lastName : 'null'}
                                                    </div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <EmailIcon style={{ marginRight: '5px' }} />
                                                        <div>Email</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.email ? this.state.email : 'null'}
                                                    </div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <PhoneIcon style={{ marginRight: '5px' }} />
                                                        <div>Phone</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.phone ? this.state.phone : 'null'}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <HelpOutlineIcon style={{ marginRight: '5px' }} />
                                                        <div>
                                                            {this.state.secretQuestion.label
                                                                ? this.state.secretQuestion.label
                                                                : 'null'}
                                                        </div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.secretQuestion.value
                                                            ? this.state.secretQuestion.value
                                                            : 'null'}
                                                    </div>
                                                </div>
                                            )}
                                            {this.props &&
                                            this.props.location &&
                                            this.props.location.state &&
                                            this.props.location.state.fromLanding &&
                                            this.props.location.state.fromLanding === 'fromLanding' ? null : (
                                                <div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <DateRangeIcon style={{ marginRight: '5px' }} />
                                                        <div>Date of Birth</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.dob ? this.state.dob : 'null'}
                                                    </div>
                                                    <div className="headLabels" style={{ color: '#333' }}>
                                                        <LockOutlinedIcon style={{ marginRight: '5px' }} />
                                                        <div>PIN</div>
                                                    </div>
                                                    <div className="answerDivs">
                                                        {this.state.pin ? this.state.pin : 'null'}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            <div className="BoxCardInner" style={{ marginTop: '15px' }}>
                                <div
                                    className={this.state.schedule ? 'upperHeadDiv' : 'upperHeadDiv1'}
                                    onClick={this.nextHandler}
                                    style={{
                                        background: isDouglasElliman
                                            ? '#100B28'
                                            : // !this.state.schedule
                                            //     ? '#fff'
                                            //     :
                                            slug && slug.primary_color
                                            ? slug && slug.primary_color
                                            : this.props &&
                                              this.props.location &&
                                              this.props.location.state &&
                                              this.props.location.state.fromLanding &&
                                              this.props.location.state.fromLanding === 'fromLanding'
                                            ? '#30AB86'
                                            : '#273E59',
                                        color:
                                            //  this.state.schedule
                                            //     ? '#fff'
                                            //     :
                                            //  slug && slug.primary_color
                                            // ? slug && slug.primary_color
                                            // : (   this.props &&
                                            //     this.props.location &&
                                            //     this.props.location.state &&
                                            //     this.props.location.state.fromLanding &&
                                            //     this.props.location.state.fromLanding === 'fromLanding')
                                            // ? '#30AB86'
                                            // :
                                            '#fff',
                                    }}
                                >
                                    {this.props &&
                                    this.props.location &&
                                    this.props.location.state &&
                                    this.props.location.state.fromLanding &&
                                    this.props.location.state.fromLanding === 'fromLanding' ? (
                                        <span
                                            className="spanNumber"
                                            style={{
                                                color: isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? slug && slug.primary_color
                                                    : '#273E59',
                                                border: isDouglasElliman
                                                    ? '1px solid #100B28'
                                                    : `1px solid ${
                                                          slug && slug.primary_color
                                                              ? slug && slug.primary_color
                                                              : '#273E59'
                                                      }`,
                                            }}
                                        >
                                            2
                                        </span>
                                    ) : null}
                                    Schedule a call with your {this.state.concierge_ref}
                                </div>
                                {this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.fromLanding &&
                                this.props.location.state.fromLanding === 'fromLanding' ? (
                                    this.state.schedule ? (
                                        <div className="InputDivs">
                                            <div className="scheduleCallInnerDivsDetails">
                                                <div className="scheduleCallInnerDivsDetails1">
                                                    {moveData &&
                                                    moveData.assigned_concierge &&
                                                    moveData.assigned_concierge.headshot_logo ? (
                                                        <img
                                                            src={moveData.assigned_concierge.headshot_logo}
                                                            // alt={'Assigned Concierge'}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                borderRadius: '50%',
                                                                margin: '0 20px 0 0',
                                                            }}
                                                        />
                                                    ) : null}
                                                    <div>
                                                        <div className="orderConciergeName">
                                                            {moveData &&
                                                            moveData.assigned_concierge &&
                                                            moveData.assigned_concierge.full_name
                                                                ? moveData.assigned_concierge.full_name
                                                                : 'Jeff Nevers'}
                                                        </div>
                                                        <div className="orderConciergeNameLabel">
                                                            Dedicated {this.state.concierge_ref}
                                                        </div>
                                                        <div>
                                                            <Button
                                                                className="btnOrder"
                                                                backgroundColor={
                                                                    isDouglasElliman
                                                                        ? '#100B28'
                                                                        : slug && slug.primary_color
                                                                        ? slug.primary_color
                                                                        : this.props &&
                                                                          this.props.location &&
                                                                          this.props.location.state &&
                                                                          this.props.location.state.fromLanding &&
                                                                          this.props.location.state.fromLanding ===
                                                                              'fromLanding'
                                                                        ? '#30AB86'
                                                                        : '#273E59'
                                                                }
                                                                // boxShadow="0 0 3px 0 #273E59"
                                                                width="140px"
                                                                height="50px"
                                                                // color="#FFFFFF"
                                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                                fontSize="14px"
                                                                fontWeight="600"
                                                                margin="0 5px 0 0"
                                                                onClick={() => this.calendlyHandle()}
                                                            >
                                                                Schedule a Call
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {moveData &&
                                                moveData.assigned_concierge &&
                                                moveData.assigned_concierge.phone ? (
                                                    <div style={{ display: 'flex' }}>
                                                        <img
                                                            src={PhoneSvg}
                                                            style={{
                                                                width: '19px',
                                                                margin: '0px 4px 0 0',
                                                                height: '19px',
                                                            }}
                                                        />

                                                        <span style={{ fontSize: '16px', color: '#333' }}>
                                                            {moveData.assigned_concierge.phone}
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <img src={calendlySvg} alt="" />
                                            </div>
                                        </div>
                                    ) : null
                                ) : (
                                    <div className="InputDivs">
                                        <div className="scheduleCallInnerDivsDetails">
                                            <div className="scheduleCallInnerDivsDetails1">
                                                <img
                                                    src={
                                                        moveData &&
                                                        moveData.assigned_concierge &&
                                                        moveData.assigned_concierge.headshot_logo
                                                    }
                                                    alt={'Assigned Concierge'}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        margin: '0 20px 0 0',
                                                    }}
                                                />
                                                <div>
                                                    <div className="orderConciergeName">
                                                        {moveData &&
                                                        moveData.assigned_concierge &&
                                                        moveData.assigned_concierge.full_name
                                                            ? moveData.assigned_concierge.full_name
                                                            : 'Jeff Nevers'}
                                                    </div>
                                                    <div className="orderConciergeNameLabel">
                                                        Dedicated {this.state.concierge_ref}
                                                    </div>
                                                    <div>
                                                        {this.state.custom_schedule_call_info_enabled ? (
                                                            <div>
                                                                <Button
                                                                    className="footerbtn"
                                                                    backgroundColor="#FFFFFF"
                                                                    width="120px"
                                                                    height="35px"
                                                                    color={
                                                                        isDouglasElliman
                                                                            ? '#100B28'
                                                                            : slug && slug.primary_color
                                                                            ? slug.primary_color
                                                                            : '#273e59'
                                                                    }
                                                                    borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                                    fontSize="12px"
                                                                    fontWeight="600"
                                                                    border={
                                                                        isDouglasElliman
                                                                            ? '1px solid #100B28'
                                                                            : `1px solid ${
                                                                                  slug && slug.primary_color
                                                                                      ? slug.primary_color
                                                                                      : '#273e59'
                                                                              }`
                                                                    }
                                                                    onClick={() => this.formHandler()}
                                                                >
                                                                    Contact Us
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <Button
                                                                className="btnOrder"
                                                                backgroundColor={
                                                                    isDouglasElliman
                                                                        ? '#100B28'
                                                                        : slug && slug.primary_color
                                                                        ? slug.primary_color
                                                                        : this.props &&
                                                                          this.props.location &&
                                                                          this.props.location.state &&
                                                                          this.props.location.state.fromLanding &&
                                                                          this.props.location.state.fromLanding ===
                                                                              'fromLanding'
                                                                        ? '#30AB86'
                                                                        : '#273E59'
                                                                }
                                                                // boxShadow="0 0 3px 0 #273E59"
                                                                width="140px"
                                                                height="50px"
                                                                // color="#FFFFFF"
                                                                borderRadius={isDouglasElliman ? '0px' : '5px'}
                                                                fontSize="14px"
                                                                fontWeight="600"
                                                                margin="0 5px 0 0"
                                                                onClick={() => this.calendlyHandle()}
                                                            >
                                                                Schedule a Call
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {moveData &&
                                            moveData.assigned_concierge &&
                                            moveData.assigned_concierge.phone ? (
                                                <div style={{ display: 'flex' }}>
                                                    <img
                                                        src={PhoneSvg}
                                                        style={{ width: '19px', margin: '0px 4px 0 0', height: '19px' }}
                                                    />

                                                    <span style={{ fontSize: '16px', color: '#333' }}>
                                                        {moveData.assigned_concierge.phone}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <img src={calendlySvg} alt="" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="BoxCardRight">
                            <div className="BoxCardInner">
                                <div className="BoxPlanDetailsRight">Plan details</div>
                                <div className="boxPlanDetail">
                                    <img
                                        src={buyNowData && buyNowData.provider_logo}
                                        style={{ width: '23%', margin: '0 auto' }}
                                    />

                                    <div style={{ width: '65%' }}>
                                        <div className="planDetailsTags">Company</div>
                                        <div className="planDetailsTags1">{buyNowData && buyNowData.provider_name}</div>
                                        <div className="planDetailsTags">Services selected</div>
                                        <div className="planDetailsTags1" style={{ display: 'flex' }}>
                                            {buyNowData && buyNowData.is_internet ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={WifiSvg} style={{ margin: '0px 0 -6px 0' }} alt="" /> Internet
                                                </div>
                                            ) : null}
                                            {buyNowData && buyNowData.is_tv ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={TvSvg} style={{ margin: '0px 0 -6px 0' }} alt="" /> TV
                                                </div>
                                            ) : null}
                                            {buyNowData && buyNowData.is_phone ? (
                                                <div style={{ marginRight: '5px' }}>
                                                    <img src={PhoneSvg} style={{ margin: '0px 0 -6px 0' }} alt="" /> Phone
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="planDetailsTags">Contract term</div>
                                        <div className="planDetailsTags1">
                                            {buyNowData && buyNowData.service_contract_length
                                                ? buyNowData && buyNowData.service_contract_length
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="BoxCardInner">
                                <div className="orderSummaryDetailHeader">Order summary</div>
                                <div className="boxPlanDetail1">
                                    <div className="orderSummaryPrice">
                                        <div>Your Plan</div>
                                        <div style={{ textAlign: 'right' }}>
                                            <div style={{ marginBottom: '5px', color: '#3E3F42' }}>
                                                <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                {buyNowData && buyNowData.base_price}/pm
                                            </div>
                                            <div style={{ color: '#6B6C6F', fontSize: '12px' }}>
                                                Plus taxes for 12mo
                                            </div>
                                        </div>
                                    </div>
                                    {buyNowData && buyNowData.setup_cost ? (
                                        <div className="orderSummaryPrice">
                                            <div style={{ fontWeight: 'bold' }}>Total Setup cost</div>
                                            <div style={{ color: isDouglasElliman ? '#009CBD' : '#0066F5' }}>
                                                <span style={{ fontFamily: 'Poppins, sans-serif' }}>$</span>
                                                {buyNowData && buyNowData.setup_cost}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="secureTab">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={VerifiedSvg} alt="" />{' '}
                                    <span style={{ color: '#6B6C6F', fontSize: '16px' }}>Safe and secure</span>
                                </div>
                                <span style={{ color: '#6B6C6F', fontSize: '12px' }}>
                                    Onetime cost includes your monthly bill
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="placeOrderBtnInternet">
                        <Button
                            className="btnOrder"
                            color="#FFFFFF"
                            backgroundColor={slug && slug.primary_color ? slug.primary_color : '#273E59'}
                            width="140px"
                            height="50px"
                            borderRadius="5px"
                            fontSize="16px"
                            margin="0 10px"
                            disabled={!this.state.schedule}
                            onClick={() => this.orderSummaryHandler()}
                        >
                            Place Order
                        </Button>
                    </div> */}
                </div>
                {this.state.calendlyOpen && (
                    <Modal
                        isShowModal={this.state.calendlyOpen}
                        onCloseModal={() => {
                            this.nylasModalClosed();
                        }}
                        showClose={false}
                        className="scheduleCallInternet"
                        // style={{
                        //     position: 'absolute',
                        //     width: '700px',
                        //     height: '650px',
                        //     backgroundColor: '#ffffff',
                        //     left: '25%',
                        //     top: '5%',
                        // }}
                    >
                        <div
                            className="scheduleCallBack"
                            onClick={() => {
                                this.setState({ calendlyOpen: false });
                                this.nylasModalClosed();
                            }}
                        >
                            <ArrowBackIcon />
                        </div>
                        {/* <InlineWidget
                            styles={{ height: '100%' }}
                            url={moveData && moveData.assigned_concierge && moveData.assigned_concierge.calendly_url}
                            prefill={{
                                email:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.email,
                                firstName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.first_name,
                                lastName:
                                    this.props.commonState &&
                                    this.props.commonState.move &&
                                    this.props.commonState.move[0] &&
                                    this.props.commonState.move[0].user_ref &&
                                    this.props.commonState.move[0].user_ref.last_name,
                            }}
                        /> */}
                        {this.state.nylas_full_service_scheduler_url ? (
                            <div className="iFrame">
                                <iframe
                                    src={`${this.state.nylas_full_service_scheduler_url}&utm_source=platform&utm_medium=web&utm_content=internet-request&utm_campaign=internet-platform
`}
                                    allow="encrypted-media"
                                    // position="relative"
                                    className="iframe"
                                    //  referrerPolicy="same-origin"
                                    //  sandbox="allow-scripts"
                                    id="myFrame"
                                ></iframe>
                            </div>
                        ) : null}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        internetAction: bindActionCreators(Action, dispatch),
        commonAction: bindActionCreators(CommonAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        signupAction: bindActionCreators(SignupAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    internetState: state.internet,
    commonState: state.common,
    dashboardState: state.checklistDashboard,
    signupState: state.signup,
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
