import React, { Component } from 'react';
import './ICmain.scss';
import Image from '../../Assets/images/ICImages/MainPageBanner';
import Button from '../../components/atoms/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import Footer from './Footer';
import * as InternetAction from '../../store/internet/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import history from '../../routes/History';
import TemplateUpper from '../../components/DouglasComponents/TemplateUpper';
import { formStartedSegment } from '../../utils/utlis';
import AnalyticsService from '../analytics-service';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

// import { CircularProgress } from '@material-ui/core';
type initialProps = {
    fromLanding?: any;
    street?: string;
    state?: string;
    city?: string;
    zipcode?: string;
    checkedG?: any;
    commonAction?: any;
    commonState?: any;
    internetAction?: any;
    internetState?: any;
    history?: any;
    location?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    apicall: boolean;
    hashCode: any;
    isDataArrived: boolean;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
    utm_campaign: string;
    isDouglasElliman: boolean;
    prefilled: boolean;
    homeOwnerDashboardRedirection: boolean;
};

class ICmain extends Component<initialProps, initialState> {
    state: initialState = {
        apicall: false,
        hashCode: '',
        isDataArrived: true,
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_campaign: '',
        isDouglasElliman: false,
        prefilled: false,
        homeOwnerDashboardRedirection: false,
    };

    async componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        if (
            !(
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.event === 'start'
            )
        ) {
            this.props.internetAction.selectDigitalPlanGet();
        }
        // else {
        //     this.setState({
        //         tabName:
        //             this.props &&
        //             this.props.history &&
        //             this.props.history.location &&
        //             this.props.history.location.state &&
        //             this.props.history.location.state.event,
        //     });
        // }
        // if (this.props.fromLanding) {
        //     this.setState({
        //         tabName: 'steps',
        //     });
        // }
        if (
            !(
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move[0] &&
                this.props.commonState.move[0].user_ref
            )
        ) {
            this.props.commonAction.moveGet();
        }

        if (window.location.search) {
            let search = window.location.search.split('&');
            let hash_code: any = '';
            let utm_source: any = '';
            let utm_medium: any = '';
            let utm_content: any = '';
            let utm_campaign: any = '';
            search &&
                search.map((item: any) => {
                    if (item.includes('hash_code')) {
                        hash_code = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_source')) {
                        utm_source = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_medium')) {
                        utm_medium = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_content')) {
                        utm_content = item && item.split('=') && item.split('=')[1];
                    }
                    if (item.includes('utm_campaign')) {
                        utm_campaign = item && item.split('=') && item.split('=')[1];
                    }
                });
            await this.setState({
                hashCode: hash_code,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_medium: utm_medium,
                utm_source: utm_source,
            });
        }
        if (!this.state.hashCode && !localStorage.getItem('token')) {
            if (this.state.utm_campaign || this.state.utm_content || this.state.utm_medium || this.state.utm_source) {
                history.push({
                    pathname: '/signUp',
                    search: `utm_source=${this.state.utm_source}&utm_medium=${this.state.utm_medium}&utm_content=${this.state.utm_content}&utm_campaign=${this.state.utm_campaign}`,
                });
            } else {
                history.push({
                    pathname: '/signUp',
                });
            }
        }

        if (this.state.hashCode) {
            const payload = {
                hashCode: this.state.hashCode,
            };
            await this.props.commonAction.moveGetWithHashCode(payload);
        }
        let moveId: any = localStorage.getItem('moveId');
        this.props.internetAction.getICSummary({ moveId });
        this.props.dashboardAction.getSubscriptionDetails();

        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        if (slug && slug.display_name) {
            let brokerage: any;

            brokerage = slug.display_name;
            let brokerage_key: any = slug.brokerage_key;
            let agent_key: any = slug.realtor_key;

            let agent_name: any = slug.contact_name;
            let move_id: any = localStorage.getItem('moveKey');

            let windownew: any = window;

            let analytics: any = windownew && windownew.analytics ? windownew.analytics : [];
            analytics.track(
                'Page Viewed',
                {
                    page: 'Internet & Cable',
                    category_name: 'Internet & Cable',
                    brokerage_name: brokerage,
                    brokerage_key: brokerage_key,
                    agent_name: agent_name,
                    agent_key: agent_key,
                },
                { user_id: move_id },
            );
        }
    }

    componentDidUpdate(prevProps: any) {
        if(this.props.homeOwnerDashboardState.getSubscriptionDetails?.results !== prevProps.homeOwnerDashboardState.getSubscriptionDetails?.results){
            if(this.props.homeOwnerDashboardState.getSubscriptionDetails?.results?.length > 0){
                this.props.homeOwnerDashboardState.getSubscriptionDetails.results.map((element: any) => {
                    if(element.service.name === 'Internet & Cable'){
                        this.setState({homeOwnerDashboardRedirection: true})
                    }
                })
            }
        }
        const prevI = prevProps && prevProps.internetState;
        const curI = this.props && this.props.internetState;
        if (
            prevI.selectDigitalPlan !== curI.selectDigitalPlan &&
            curI.selectDigitalPlan &&
            curI.selectDigitalPlan.length > 0
        ) {
            this.props.history.push('/dashboard/internet/summary');
            this.setState({ apicall: false });
        }
        if(this.state.homeOwnerDashboardRedirection || curI.summary[0]){
            this.props.history.push('/dashboard/internet/summary');
            this.setState({ apicall: false });
        }
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.moveWithHashCode &&
            nextProps.commonState.moveWithHashCode[0] &&
            currentState.isDataArrived
        ) {
            currentState.isDataArrived = false;

            let moveData = nextProps.commonState.moveWithHashCode[0];
            if (
                moveData.destination &&
                moveData.destination.zip_code &&
                moveData.source &&
                moveData.source.zip_code &&
                moveData.source_full_address &&
                moveData.destination_full_address &&
                moveData.user_ref.is_signup_done
            ) {
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);
                const isHomeOwner = localStorage.getItem('isHomeOwner');
                let slug: any = localStorage.getItem('slug');
                slug = JSON.parse(slug);
                localStorage.setItem('token', nextProps.commonState.moveWithHashCode[0].user_ref.token);

                nextProps.commonAction.header({
                    partner_slug: window.location.hostname.split('.')[0]
                        ? window.location.hostname.split('.')[0]
                        : slug && slug.slug,
                    fetchMoveService: isHomeOwner ? 1 : 0,
                });
                nextProps.internetAction.selectDigitalPlanGet();
            } else {
                setTimeout(() => {
                    localStorage.clear();
                    history.push({
                        pathname: `/signUp`,
                        search: `hash_code=${moveData.hash_code}&utm_source=${currentState.utm_source}&utm_medium=${currentState.utm_medium}&utm_content=${currentState.utm_content}&utm_campaign=${currentState.utm_campaign}`,
                    });
                }, 1000);
            }
        }

        return currentState;
    }

    handleAnalytics = () => {
        this.props.history.push({
            pathname: '/dashboard/internet/location',
            state: {
                data: this.props.commonState && this.props.commonState.move && this.props.commonState.move[0],
            },
        });

        if (this.props.city && this.props.street && this.props.state && this.props.zipcode) {
            this.setState({ prefilled: true });
        }

        let brokerageCheck: any = this.props.commonState && this.props.commonState.realtor;

        let brokerage: any;

        brokerage = brokerageCheck.display_name;
        let brokerage_key: any = brokerageCheck.brokerage_key;
        let agent_key: any = brokerageCheck.realtor_key;

        let agent_name: any = brokerageCheck.contact_name;
        let move_id: any = localStorage.getItem('moveKey');
 

        AnalyticsService.buttonClicked({
            category_name: 'Internet & Cable',
            button_copy: 'Get Started',
            page: 'Internet & Cable'
        });

        formStartedSegment(
            'Form Started',
            'Internet & Cable',
            'Internet & Cable',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            '',
            'Continue',
            true,
            this.state.prefilled,
            1,
            move_id,
        );
    };

    handleSkipAndShowOffers = () => {
        this.props.history.push({
            pathname: '/dashboard/internet/recommendations',
            state: { type: 'skip' },
        });

        AnalyticsService.buttonClicked({
            category_name: 'Internet & Cable',
            button_copy: 'Skip & show all offers',
            page: 'Internet & Cable'
        });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;

        // if (gonext) {
        //     return (
        //         <div
        //             style={{
        //                 marginTop: '20px',
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center',
        //             }}
        //         >
        //             <CircularProgress />
        //         </div>
        //     );
        // } else {

            const breadList = [
                {
                    name: 'Internet And Cable',
                    url: ''
                }
            ]

        return (
            <div>
                {isDouglasElliman ? (
                    <div>
                        <TemplateUpper
                            isCoverImage={true}
                            pageImage="internet"
                            onClick={() =>
                                this.props.history.push({
                                    pathname: '/dashboard/internet/location',
                                    state: {
                                        data:
                                            this.props.commonState &&
                                            this.props.commonState.move &&
                                            this.props.commonState.move[0],
                                    },
                                })
                            }
                            onClickWhite={() =>
                                this.props.history.push({
                                    pathname: '/dashboard/internet/recommendations',
                                    state: { type: 'skip' },
                                })
                            }
                            headerText={`Setting Up Your`}
                            subheaderText={
                                'Search for all the providers, compare plans, and order your service in a matter of minutes'
                            }
                            highlightedText={'Internet & Cable'}
                            buttonText={'Get Started'}
                            buttonText1={'Skip & show all offers'}
                        />
                    </div>
                ) : (
                    <div>
                        {/* <div style={{ marginTop: '40px' }}></div> */}
                        <div className="main-head">
                            <h2 className="heading">Internet And Cable</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>

                        <div className="InternetcoverDiv">
                            <div className="landingPage">
                                {/* <img src={image} alt="image.svg" height="15%" width="25%" /> */}
                                <Image className="mainImg" />
                                <h2 className="heading">
                                    Hi{' '}
                                    <span style={{ textTransform: 'capitalize' }}>{`${
                                        this.props.commonState &&
                                        this.props.commonState.move &&
                                        this.props.commonState.move[0] &&
                                        this.props.commonState.move[0].user_ref &&
                                        (this.props.commonState.move[0].user_ref.first_name ||
                                            this.props.commonState.move[0].user_ref.last_name)
                                            ? this.props.commonState.move[0].user_ref.first_name ||
                                              this.props.commonState.move[0].user_ref.last_name
                                            : ''
                                    },`}</span>{' '}
                                    let us help in finding you the best internet and cable deals
                                </h2>
                                <p className="subtext">
                                    Search for all the providers, compare plans and order your service in a matter of
                                    minutes
                                </p>
                                <div className="line"></div>
                                <div className="btn-div">
                                    <div className="btn-div1">
                                        <Button
                                            className="btnICbtn"
                                            backgroundColor="#FFFFFF"
                                            boxShadow={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `0 0 3px 0 ${slug.primary_color}`
                                                    : '0 0 3px 0 #273E59'
                                            }
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            borderRadius="5px"
                                            fontSize="16px"
                                            // width="200px"
                                            width="100%"
                                            height="50px"
                                            // disabled={
                                            //     this.props &&
                                            //     this.props.commonState &&
                                            //     this.props.commonState.move &&
                                            //     this.props.commonState.move[0]
                                            //         ? false
                                            //         : true
                                            // }
                                            onClick={this.handleSkipAndShowOffers}
                                        >
                                            Skip & show all offers
                                        </Button>
                                    </div>
                                    <div className="btn-div2">
                                        <Button
                                            className="btnICbtn"
                                            height="50px"
                                            // width="125px"
                                            width="100%"
                                            backgroundColor={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : slug && slug.primary_color
                                                    ? `${slug.primary_color}`
                                                    : '#273E59'
                                            }
                                            // onClick={() => this.tabChangeHandler('addressCard')}
                                            onClick={this.handleAnalytics}
                                            disabled={
                                                (this.props &&
                                                    this.props.commonState &&
                                                    this.props.commonState.move &&
                                                    this.props.commonState.move[0]) ||
                                                (this.props &&
                                                    this.props.commonState &&
                                                    this.props.commonState.moveWithHashCode &&
                                                    this.props.commonState.moveWithHashCode[0])
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer />
            </div>
        );
        // }
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
        internetAction: bindActionCreators(InternetAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    internetState: state.internet,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(ICmain);
