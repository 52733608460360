import React, { Component } from 'react';
import ButtonNew from '../../components/atoms/Button';
import '../../styles/landingpageHeader.css';
import landingImage from '../../Assets/images/landingImage.svg';
import layer from '../../Assets/images/layer.svg';
import flyers from '../../Assets/images/flyers.svg';
import Person from '../../Assets/images/person.svg';
import xfinity from '../../Assets/images/xfinity.png';
import at from '../../Assets/images/at.png';
import hughes from '../../Assets/images/hughes.png';
import verizon from '../../Assets/images/verizon.png';
import cox from '../../Assets/images/cox.png';
import century from '../../Assets/images/century.png';
import dish from '../../Assets/images/dish.png';
import hulu from '../../Assets/images/hulu.png';
import frontier from '../../Assets/images/frontier.png';
import sling from '../../Assets/images/sling.png';
import optimum from '../../Assets/images/optimum.png';
import test_avatar from '../../Assets/images/test_avatar.png';
import review from '../../Assets/images/review.svg';
import serialOneImg from '../../Assets/images/serial1.png';
import serialTwoImg from '../../Assets/images/serial2.png';
import serialThreeImg from '../../Assets/images/serial3.png';
import HowItWorksCard from './howItWorksCard';
import Card from '../../components/atoms/Card';
import SimpleAccordion from '../../components/atoms/FAQ';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../components/atoms/Button';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CarouselCard from '../../components/atoms/CarouselCard';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIcon from '../../Assets/images/ArrowForwardIcon.svg';
import ArrowBackIcon from '../../Assets/images/ArrowBackIcon.svg';
import TabComponent from './tabComponent';
import InternetLandingFooter from './InternetLandingFooter';
// import { NavLink } from 'react-router-dom';
// import history from '../../routes/History';
// import Avatar from '@material-ui/core/Avatar';
import moveEasy from '../../Assets/images/move_easy.svg';
import PhoneIcon from '@material-ui/icons/Phone';
import { scroller } from 'react-scroll';
// import ICMain from '../InternetAndCable/ICmain';
// import SelectInput from '../../components/atoms/SelectInput';
import customerServiceImg from '../../Assets/images/InternetCall.png';
import HowItWorksMobile from './HowItWorksMobileComponent';
import InfoIcon from '@material-ui/icons/Info';
import TextLoop from 'react-text-loop';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { list } from '../../components/atoms/USAlist';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Action from '../../store/common/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '../../components/atoms/Modal';
// const Text = [
// 'Streaming Movies',
// 'Working From Home',
// 'Playing video games',
// 'Making video calls',
// 'Downloading files',
// ];
// const Cards=['Card1','Card2','Card3']

type initialProps = {
    commonAction?: any;
    commonState?: any;
    history?: any;
};

type initialState = {
    moveId: string;
    address: string;
    active: number;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    streetError: string;
    cityError: string;
    stateError: string;
    zipcodeError: string;
    checkedG: boolean;
    counter: number;
    serialOne: boolean;
    serialTwo: boolean;
    serialThree: boolean;
    clicked: number;
    navLinks: any;
    activeTab: string;
    inactiveTab: string;
    quote: boolean;
    counter2: number;
    isDataArrived: boolean;
    progress1: boolean;
    progress2: boolean;
    progress3: boolean;
    hostname: string;
    progressCard1: any;
    progressCard2: any;
    progressCard3: any;
    calendlyOpen2: boolean;
    date: string;
    time: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isSubmitClicked: boolean;
};

export class LandingPageInternet extends Component<initialProps, initialState> {
    state: initialState = {
        date: '',
        time: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isSubmitClicked: false,
        moveId: '',
        address: '',
        isDataArrived: true,
        calendlyOpen2: false,
        active: 0,
        street: '',
        city: '',
        state: '',
        zipcode: '',
        streetError: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        checkedG: false,
        counter: 0,
        counter2: 0,
        serialOne: true,
        serialTwo: false,
        serialThree: false,
        clicked: 1,
        progress1: false,
        progress2: false,
        progress3: false,
        quote: false,
        hostname: '',
        navLinks: [
            {
                main: 'Home',
                subLinks: [
                    {
                        // link: '/home',
                        heading: 'Home',
                        exact: true,
                        class: 'mortgage',
                        className: 'dynamic-div',
                    },
                ],
            },
            {
                main: 'How it Works',
                subLinks: [
                    {
                        // link: '/how-it-works',
                        // link: '/home',
                        heading: 'How it Works',
                        exact: true,
                        class: 'how-it-works',
                        className: 'how-it-works-wrapper-internet',
                    },
                ],
            },
            {
                main: 'Support',
                subLinks: [
                    {
                        // link: '/support',
                        // link: '/home',
                        heading: 'Support',
                        exact: true,
                        class: 'support',
                        className: 'call-wrapper-internet',
                    },
                ],
            },
            {
                main: 'How We Compare',
                subLinks: [
                    {
                        // link: '/how-we-compare',
                        // link: '/home',
                        heading: 'How We Compare',
                        exact: true,
                        class: 'how-we-compare',
                        className: 'compare-section-internet',
                    },
                ],
            },
            {
                main: 'Reviews',
                subLinks: [
                    {
                        // link: '/reviews',
                        // link: '/home',
                        heading: 'Reviews',
                        exact: true,
                        class: 'reviews',
                        className: 'carousel-internet',
                    },
                ],
            },
            {
                main: 'FAQ',
                subLinks: [
                    {
                        // link: '/faq',
                        // link: '/home',
                        heading: 'FAQ',
                        exact: true,
                        class: 'faq',
                        className: 'questions-internet',
                    },
                ],
            },
        ],
        activeTab: 'nav_selected',
        inactiveTab: 'nav_unselected',
        progressCard1: 0,
        progressCard2: 0,
        progressCard3: 0,
    };

    componentDidMount() {
        const domain = window.location.hostname;
        let name = domain.split('.');
        if (name[0]) {
            this.setState({ hostname: name[0] });
        }
        if (!name[0].includes('internet')) {
            this.props.commonAction.moveGet();
        }

        // setInterval(() => this.handleTextChange(), 3000);
        setInterval(() => this.handleCardChange(), 3000);
        setInterval(() => this.handleProgressChange(), 500);
        setInterval(() => this.handleProgressChange2(), 500);
        setInterval(() => this.handleProgressChange3(), 500);
    }

    handleProgressChange = () => {
        if (this.state.progressCard1 >= 75) {
            this.setState({ progressCard1: 100 });
        } else if (this.state.progressCard1 < 75) {
            this.setState({
                progressCard1: this.state.progressCard1 + 15,
            });
        }
    };
    handleProgressChange2 = () => {
        if (this.state.progressCard2 >= 75) {
            this.setState({ progressCard2: 100 });
        } else if (this.state.progressCard2 < 75) {
            this.setState({
                progressCard2: this.state.progressCard2 + 15,
            });
        }
    };
    handleProgressChange3 = () => {
        if (this.state.progressCard3 >= 75) {
            this.setState({ progressCard3: 100 });
        } else if (this.state.progressCard3 < 75) {
            this.setState({
                progressCard3: this.state.progressCard3 + 15,
            });
        }
    };

    // componentWillUnmount() {
    //     clearInterval();
    // }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.commonState &&
            nextProps.commonState.move &&
            nextProps.commonState.move.length &&
            currentState.isDataArrived
        ) {
            nextProps.commonState.move.map((item: any) => {
                currentState.isDataArrived = false;
                currentState.zipcode = item.destination && item.destination.zip_code ? item.destination.zip_code : '';
                currentState.city = item.destination && item.destination.city_name ? item.destination.city_name : '';
                currentState.state = item.destination && item.destination.state_code ? item.destination.state_code : '';
                currentState.street = item.destination && item.destination_street ? item.destination_street : '';
                currentState.moveId = item.id ? item.id : '';
            });
        }
        return currentState;
    }
    closeModal = () => {
        this.setState({ calendlyOpen2: false, isSubmitClicked: false });
    };

    closeCalendlyModal = async () => {
        await this.setState({ isSubmitClicked: true });
        // const { street, state, zipcode, city } = this.state;
        if (this.state.firstName && this.state.lastName && this.state.email && this.state.phone) {
            await this.setState({ calendlyOpen2: false, isSubmitClicked: false });

            // this.props.history.push({
            //     pathname: '/landing/internet/order',
            //     state: {
            //         fromLanding: 'fromMainLanding',
            //         data: {
            //             destination: {
            //                 lookup_name: city,
            //                 zip_code: zipcode,
            //                 state_data: state,
            //             },
            //             destination_street: street,
            //             destination_full_address: this.state.address,
            //         },
            //         date: this.state.date,
            //         time: this.state.time,
            //         firstName:this.state.firstName,
            //         lastName:this.state.lastName,
            //         email:this.state.email,
            //         phone:this.state.phone
            //     },
            // });
        }
    };

    streetHandler = (e: any) => {
        this.setState({
            street: e,
        });
    };
    cityHandler = (e: any) => {
        this.setState({
            city: e.target.value,
        });
    };
    stateHandler = (e: any) => {
        this.setState({
            state: e.target.value,
        });
    };
    zipcodeHandler = (e: any) => {
        this.setState({
            zipcode: e.target.value,
        });
    };

    tabChangeHandler = () => {};

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checkedG: event.target.checked });
    };

    // handleTextChange = () => {
    //     let { counter } = this.state;
    //     let myContainer = document.getElementById('change-text') as HTMLInputElement;
    //     if (myContainer) {
    //         myContainer.innerHTML = Text[counter];
    //     }
    //     if (counter == 0 || counter == 1 || counter == 2 || counter == 3) {
    //         this.setState({
    //             counter: counter + 1,
    //         });
    //     } else {
    //         this.setState({
    //             counter: 0,
    //         });
    //     }
    // };

    handleCardChange = async () => {
        let { counter2 } = this.state;
        if (counter2 == 0) {
            this.handleSerialOne();
            await this.setState({
                counter2: counter2 + 1,
                progress1: true,
                progress2: false,
                progress3: false,
                progressCard1: 0,
            });
        }
        if (counter2 == 1) {
            this.handleSerialTwo();
            await this.setState({
                counter2: counter2 + 1,
                progress1: false,
                progress2: true,
                progress3: false,
                progressCard2: 0,
            });
        }
        if (counter2 == 2) {
            this.handleSerialThree();
            await this.setState({
                counter2: 0,
                progress1: false,
                progress2: false,
                progress3: true,
                progressCard3: 0,
            });
        }
    };

    handleSerialOne = () => {
        this.setState({
            serialOne: true,
            serialTwo: false,
            serialThree: false,
            clicked: 1,
        });
    };

    handleSerialTwo = () => {
        this.setState({
            serialOne: false,
            serialTwo: true,
            serialThree: false,
            clicked: 2,
        });
    };

    handleSerialThree = () => {
        this.setState({
            serialOne: false,
            serialTwo: false,
            serialThree: true,
            clicked: 3,
        });
    };

    validateFields = () => {
        let validity: Boolean = true;

        if (this.state.street === '') {
            this.setState({ streetError: 'Enter street Name' });
            validity = false;
        } else {
            this.setState({ streetError: '' });
        }

        if (this.state.state === '') {
            this.setState({ stateError: 'Enter State Name' });
            validity = false;
        } else {
            this.setState({ stateError: '' });
        }

        if (this.state.city === '') {
            this.setState({ cityError: 'Enter City Name' });
            validity = false;
        } else {
            this.setState({ cityError: '' });
        }

        if (this.state.zipcode === '') {
            this.setState({ zipcodeError: 'Enter Zip Code' });
            validity = false;
        } else {
            this.setState({ zipcodeError: '' });
        }

        return validity;
    };

    handleQuote = async (fromQuote: boolean) => {
        if (fromQuote == true) {
            if (this.validateFields()) {
                const { street, state, zipcode, city } = this.state;
                if (street && state && zipcode && city) {
                    if (!(this.state.hostname.includes('internet') || this.state.hostname.includes('internet-demo'))) {
                        this.props.commonAction.moveAddressUpdate({
                            destination_detail: `${street}, ${city}, ${state}, ${zipcode}`,
                            move_id: this.state.moveId,
                        });
                        this.props.history.push({
                            pathname: '/landing/internet/location',
                            state: {
                                fromLanding: 'fromLanding',
                            },
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/landing/internet/steps',
                            state: {
                                fromLanding: 'fromLanding',
                                data: {
                                    destination: {
                                        lookup_name: city,
                                        zip_code: zipcode,
                                        state_data: state,
                                    },
                                    destination_street: street,
                                    destination_full_address: this.state.address,
                                },
                            },
                        });
                    }
                }
            }
        } else {
            this.props.history.push({
                pathname: '/landing/internet/location',
                state: {
                    fromLanding: 'fromLanding',
                },
            });
        }
    };

    dateHandler = (e: any) => {
        this.setState({
            date: e,
        });
    };

    timeHandler = (e: any) => {
        this.setState({
            time: e,
        });
    };

    firstNameHandler = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });
    };

    lastNameHandler = (e: any) => {
        this.setState({
            lastName: e.target.value,
        });
    };

    emailHandler = (e: any) => {
        this.setState({
            email: e.target.value,
        });
    };

    formatPhoneInput = (phone: string) => {
        let phone_number = phone;
        phone_number = phone_number.replace(/\D/g, '');

        phone_number = phone_number.substring(0, 10);
        const size = phone_number.length;
        if (size < 4) {
            phone_number = phone_number;
        } else if (size < 7) {
            phone_number = phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6);
        } else {
            phone_number =
                phone_number.substring(0, 3) + '-' + phone_number.substring(3, 6) + '-' + phone_number.substring(6, 10);
        }
        return phone_number;
    };

    phoneHandler = (e: any) => {
        let phone = this.formatPhoneInput(e.target.value);
        this.setState({
            phone: phone,
        });
    };

    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';

                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                        street: `${streetNumber} ${route}`,
                        city: city,
                        zipcode: zip,
                        state: state,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };

    render() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        const { navLinks } = this.state;
        const { serialOne, serialTwo, serialThree, clicked } = this.state;
        const quesAns: any = [
            {
                ques: 'How much does the platform cost after the trial period?',
                ans: 'Not Available',
            },
            {
                ques: 'Is your Human Concierge included in the overall service?',
                ans:
                    'Yes. Its all included. Your clients can talk to a live human being who will help them switch all their utilities in just one call at no extra charge. This includes electric, gas, water, internet, cable, TV & security, moving, home services etc.',
            },
            {
                ques: 'How does my client access this service?',
                ans: 'Not Available',
            },
            {
                ques: 'Do you sell our clients contact information?',
                ans: 'Not Available',
            },
            {
                ques: 'Does your utility and DMV list cover my city and state?',
                ans: 'Not Available',
            },
        ];

        let carouselItems: any = [
            [
                {
                    avatar: test_avatar,
                    name: 'Jessica Mules',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 2',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 3',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 4',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
            [
                {
                    avatar: test_avatar,
                    name: 'Person 5',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 6',
                    title: 'Customer',
                    rate: 4,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 7',
                    title: 'Customer',
                    rate: 3,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
                {
                    avatar: test_avatar,
                    name: 'Person 8',
                    title: 'Customer',
                    rate: 5,
                    desc: 'It is very comfortable because there is free internet for tasks, and cheap food',
                },
            ],
        ];

        let carouselItemsMedia: any = [];

        carouselItems.map((item: any) => {
            item.map((item2: any) => {
                carouselItemsMedia.push(item2);
            });
        });

        return (
            <div style={{ fontFamily: 'Lato !important' }}>
                <div className="landingHeaderInternet">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="logo-container">
                            <img src={moveEasy} alt="" />
                        </div>
                        {this.state.quote === false ? (
                            <div className="navLinkFlexDivInternet">
                                <nav>
                                    <ul style={{ display: 'flex', flexDirection: 'row', margin: '0 11px' }}>
                                        {navLinks.map((row: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {row.subLinks.map((sublink: any) => {
                                                        return (
                                                            <li
                                                                className="landingNav-li-internet"
                                                                key={sublink.heading}
                                                                onClick={() => {
                                                                    scroller.scrollTo(sublink.className, {
                                                                        duration: 800,
                                                                        delay: 0,
                                                                        smooth: 'easeInOutQuart',
                                                                    });
                                                                }}
                                                            >
                                                                <div
                                                                    className={`${sublink.class} navlinkHeading`}
                                                                    // style={{
                                                                    //     textDecoration: 'none',
                                                                    //     fontSize: '14px',
                                                                    //     fontFamily: 'Lato',
                                                                    //     fontWeight: 'bold',
                                                                    // }}
                                                                >
                                                                    <span style={{ color: '#707070' }}>
                                                                        {sublink.heading}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </nav>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="phoneInternetLanding">
                                        <span className="phone-icon">
                                            {' '}
                                            <PhoneIcon />
                                        </span>
                                        <a href={`tel: (810) 525-8185`}>(810) 525-8185</a>
                                    </span>
                                    <span>
                                        <ButtonNew
                                            className="landingHeaderButtonInternet"
                                            border="none"
                                            boxShadow="none"
                                            // onClick={() => {
                                            //     scroller.scrollTo('address-wrapper-internet', {
                                            //         duration: 800,
                                            //         delay: 0,
                                            //         smooth: 'easeInOutQuart',
                                            //     });
                                            // }}
                                            onClick={() => {
                                                this.handleQuote(false);
                                            }}
                                        >
                                            Get Started
                                        </ButtonNew>
                                    </span>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="mobile-div-internet">
                    <Button
                        className="getStartedButtonInternet"
                        border="none"
                        boxShadow="none"
                        // onClick={() => {
                        //     scroller.scrollTo('address-wrapper-internet', {
                        //         duration: 800,
                        //         delay: 0,
                        //         smooth: 'easeInOutQuart',
                        //     });
                        // }}
                        onClick={() => {
                            this.handleQuote(false);
                        }}
                    >
                        Get Started
                    </Button>
                </div>

                {this.state.quote === false ? (
                    <div id="dynamic-div">
                        <div className="dynamicHeaderImageDiv">
                            <img src={landingImage} width="100%" alt="" />
                        </div>
                        <div
                            className="internetLanding-main-page"
                            // style={{
                            //     // paddingTop: '70px',
                            //     marginTop: '-500px',
                            //     paddingLeft: '4%',
                            //     display: 'flex',
                            //     flexDirection: 'row',
                            //     boxShadow: '0px -10px 10px #eee inset',
                            // }}
                        >
                            <div>
                                <div className="you-deserve-text-internet">You deserve ultra fast internet</div>
                                <div>
                                    <span className="you-deserve-text-internet">for</span>&nbsp;&nbsp;&nbsp;
                                    {/* <span className="wfh" id="change-text">
                                        {''}
                                    </span> */}
                                    {''}
                                    <TextLoop>
                                        <span className="wfh-internet" id="change-text">
                                            Streaming Movies
                                        </span>
                                        <span className="wfh-internet" id="change-text">
                                            Working From Home
                                        </span>
                                        <span className="wfh-internet" id="change-text">
                                            Playing video games
                                        </span>
                                        <span className="wfh-internet" id="change-text">
                                            Making video calls
                                        </span>
                                        <span className="wfh-internet" id="change-text">
                                            Downloading files
                                        </span>
                                    </TextLoop>
                                </div>
                                <p className="header-desc-internet">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s..
                                </p>
                                <ButtonNew
                                    className="landingGetQuoteButtonInternet"
                                    border="none"
                                    boxShadow="none"
                                    // onClick={() => {
                                    //     scroller.scrollTo('address-wrapper-internet', {
                                    //         duration: 800,
                                    //         delay: 0,
                                    //         smooth: 'easeInOutQuart',
                                    //     });
                                    // }}
                                    onClick={() => {
                                        this.handleQuote(false);
                                    }}
                                >
                                    Get a Quote
                                </ButtonNew>
                            </div>
                        </div>

                        <div className="worlds-internet">
                            <div className="worlds-heading-internet">Trusted by the world's best</div>
                            <div className="worlds-para-internet">
                                MoveEasy powers millions of websites across hundreds of industries for people just like
                                you.
                            </div>

                            <div className="main-div-internet">
                                <div className="div-1">
                                    <img src={xfinity} alt="" />
                                    <img src={at} alt="" />
                                    <img src={hughes} alt="" />
                                    <img src={verizon} alt="" />
                                    <img src={cox} alt="" />
                                </div>

                                <div className="div-2">
                                    <img src={century} alt="" />
                                    <img src={dish} alt="" />
                                    <img src={hulu} alt="" />
                                    <img src={frontier} alt="" />
                                    <img src={sling} alt="" />
                                    <img src={optimum} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="collectionInternet">
                            <Card
                                className="collectionCardInternet"
                                imgClassname="collectionCardImgInternet"
                                // width="32%"
                                // height="300px"
                                cardImage={layer}
                                cardTitle="30+ Leading Brands to Choose"
                                cardContent="Select plans from the top internet and TV providers like AT&T, Xfinity, Verizon and many more."
                            />

                            <Card
                                // width="32%"
                                className="collectionCardInternet"
                                // height="300px"
                                imgClassname="collectionCardImgInternet"
                                cardImage={flyers}
                                cardTitle="Discover our best plans"
                                cardContent="Use our patented technology to search providers, compare plans and order service all on our secure site."
                            />

                            <Card
                                className="collectionCardInternet"
                                imgClassname="collectionCardImgInternet"
                                // width="32%"
                                // height="300px"
                                cardImage={Person}
                                cardTitle="24/7 human concierge"
                                cardContent="Save your clients tons of time and money by helping them switch all their utilities with just one call"
                            />
                        </div>

                        <div className="how-it-works-wrapper-internet">
                            <div className="worlds-heading-internet">How It Works</div>
                            <div className="worlds-para-internet">
                                MoveEasy powers millions of websites across hundreds of industries for people just like
                                you.
                            </div>
                            <div className="card-img-wrapper-internet" style={{ width: '100%' }}>
                                {/* <div
                                    style={{
                                        width: '10%',
                                        // paddingTop: '50px',
                                        // display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    }}
                                ></div> */}
                                <div
                                    className="how-it-works-card-div-internet"
                                    // style={{
                                    //     width: '30%',
                                    //     paddingTop: '50px',
                                    //     // display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                    // }}
                                >
                                    <div
                                        className="progressBarFlexDiv"
                                        //  onClick={() => this.handleSerialOne()}
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={1}
                                            heading="Enter your area"
                                            desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress1 ? (
                                                <LinearProgress
                                                    className="linearProgressInternetLanding"
                                                    variant="determinate"
                                                    value={this.state.progressCard1}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className="progressBarFlexDiv"
                                        //  onClick={() => this.handleSerialTwo()}
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={2}
                                            heading="Choose Your Recommended Deals"
                                            desc="Search Providers,compare plans and other service all on your screen site."
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress2 ? (
                                                <LinearProgress
                                                    className="linearProgressInternetLanding"
                                                    variant="determinate"
                                                    value={this.state.progressCard2}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className="progressBarFlexDiv"
                                        //  onClick={() => this.handleSerialThree()}
                                    >
                                        <HowItWorksCard
                                            clicked={clicked}
                                            serial={3}
                                            heading="Shop on your own or get help. Either way, we’re here."
                                            desc="Use our patented technology to search providers, compare plans and order service all on our secure site."
                                        />
                                        <div style={{ height: '3px' }}>
                                            {this.state.progress3 ? (
                                                <LinearProgress
                                                    className="linearProgressInternetLanding"
                                                    variant="determinate"
                                                    value={this.state.progressCard3}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '15px',
                                                        height: '3px',
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="how-it-works-image-div-internet"
                                    // style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
                                >
                                    {serialOne ? (
                                        <img src={serialOneImg} height={620} alt="" />
                                    ) : serialTwo ? (
                                        <img src={serialTwoImg} height={620} alt="" />
                                    ) : serialThree ? (
                                        <img src={serialThreeImg} height={620} alt="" />
                                    ) : (
                                        <img src={serialOneImg} height={620} alt="" />
                                    )}
                                </div>
                            </div>

                            <div className="how-it-works-tab-mobile">
                                <HowItWorksMobile />
                            </div>
                        </div>

                        {/* Give us a call */}
                        <div className="call-wrapper-internet card-img-wrapper-internet">
                            <div className="imgDiv">
                                <img src={customerServiceImg} alt="" />
                            </div>
                            <div className="scheduleCallDiv">
                                <div className="worlds-heading-internet">Give us a call.</div>
                                <div className="worlds-heading-internet">We'll get you taken care of.</div>
                                <div className="card-desc-internet">
                                    It doesn’t matter if you know exactly what you need or want a little help, we’ll
                                    find your ideal plan and order your service in a matter of minutes.
                                </div>
                                <div className="call-now-internet">Call now: (810) 525 - 8185</div>
                                <div className="week-time-div-internet">
                                    <div className="week-time-internet">
                                        <div className="day-internet">Monday - Friday</div>
                                        <div className="time-internet">8:00 a.m. to 10:00 p.m. EST</div>
                                    </div>

                                    <div className="week-time-internet">
                                        <div className="day-internet">Saturday</div>
                                        <div className="time-internet">9:00 a.m. to 7:00 p.m. EST</div>
                                    </div>

                                    <div className="week-time-internet">
                                        <div className="day-internet">Sunday</div>
                                        <div className="time-internet">10:00 a.m. to 7:00 p.m. EST</div>
                                    </div>
                                    <div className="scheduleCallButtonDiv">
                                        <ButtonNew
                                            className="scheduleCallButton"
                                            onClick={() => {
                                                this.setState({
                                                    calendlyOpen2: true,
                                                });
                                            }}
                                        >
                                            Schedule a Call
                                        </ButtonNew>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.calendlyOpen2 && (
                            <Modal
                                isShowModal={this.state.calendlyOpen2}
                                onCloseModal={this.closeModal}
                                showClose={false}
                                className="modalClassesSecurity"
                            >
                                <div style={{ height: '100%' }}>
                                    <div className="CloseIcon">
                                        <div
                                            style={{
                                                color: '#333333',
                                                fontSize: '20px',
                                                fontWeight: 800,
                                                textAlign: 'left',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            Schedule a Call Back
                                        </div>
                                        <CloseIcon onClick={() => this.closeModal()} style={{ cursor: 'pointer' }} />
                                    </div>
                                    <div className="line12"></div>
                                    <div style={{ padding: '10px 20px 10px 20px' }} className="scheduleInsuranceCall">
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Call Schedule Details
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disablePast
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="date"
                                                            inputVariant="filled"
                                                            label="Select date"
                                                            className={
                                                                isDouglasElliman ? 'date11 dateNoDoug' : 'date11'
                                                            }
                                                            value={this.state.date ? new Date(this.state.date) : null}
                                                            onChange={(e: any) => this.dateHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            autoOk={true}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.date) ||
                                                                (this.state.date !== '' && !Date.parse(this.state.date))
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.date &&
                                                            'Please select date') ||
                                                            (this.state.date !== '' &&
                                                                !Date.parse(this.state.date) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            disableToolbar
                                                            ampm={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="time"
                                                            inputVariant="filled"
                                                            label="Select time"
                                                            className={
                                                                isDouglasElliman ? 'date11 dateNoDoug' : 'date11'
                                                            }
                                                            value={this.state.time ? this.state.time : null}
                                                            onChange={(e: any) => this.timeHandler(e)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                            keyboardIcon={<AccessAlarmIcon />}
                                                            autoOk={true}
                                                            PopoverProps={{
                                                                style: { zIndex: 1000000 },
                                                            }}
                                                            // InputProps={{
                                                            //     onFocus: () => {
                                                            //         this.setState({ isOpen1: true });
                                                            //     },
                                                            // }}
                                                            error={
                                                                (this.state.isSubmitClicked && !this.state.time) ||
                                                                (this.state.time !== '' && !Date.parse(this.state.time))
                                                            }
                                                            // open={this.state.isOpen1}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div className="error" style={{ height: '20px', margin: '0 10px' }}>
                                                        {(this.state.isSubmitClicked &&
                                                            !this.state.time &&
                                                            'Please select time') ||
                                                            (this.state.time !== '' &&
                                                                !Date.parse(this.state.time) &&
                                                                'Invalid Date')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '16px',
                                                // marginTop: '10px',
                                                color: '#333333',
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            Personal Details
                                        </div>
                                        <div className="peronalDetailsInsurance">
                                            <div className="flex-div">
                                                <div className="div-1-name">
                                                    <TextField
                                                        id="time"
                                                        label="First Name"
                                                        value={this.state.firstName}
                                                        onChange={(e: any) => this.firstNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        // disabled
                                                        error={
                                                            this.state.isSubmitClicked && this.state.firstName === ''
                                                        }
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.firstName === ''
                                                            ? 'Please enter first name'
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className="div-2-name">
                                                    <TextField
                                                        id="time"
                                                        label="Last Name"
                                                        value={this.state.lastName}
                                                        onChange={(e: any) => this.lastNameHandler(e)}
                                                        type="text"
                                                        variant="filled"
                                                        className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                        // disabled
                                                        error={this.state.isSubmitClicked && this.state.lastName === ''}
                                                    />
                                                    <div className="error">
                                                        {this.state.isSubmitClicked && this.state.lastName === ''
                                                            ? 'Please enter last name'
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Email"
                                                value={this.state.email}
                                                onChange={(e: any) => this.emailHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.email === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.email === ''
                                                    ? 'Please enter email'
                                                    : this.state.isSubmitClicked &&
                                                      !this.state.email.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
                                                    ? 'Please enter valid email'
                                                    : ''}
                                            </div>
                                            <TextField
                                                id="time"
                                                label="Phone Number"
                                                value={this.state.phone}
                                                onChange={(e: any) => this.phoneHandler(e)}
                                                type="text"
                                                variant="filled"
                                                className={isDouglasElliman ? 'date11 dateNoDoug' : 'date11'}
                                                // disabled
                                                error={this.state.isSubmitClicked && this.state.phone === ''}
                                            />
                                            <div className="error">
                                                {this.state.isSubmitClicked && this.state.phone === ''
                                                    ? 'Please enter phone number'
                                                    : this.state.isSubmitClicked && this.state.phone.length < 12
                                                    ? 'Please enter valid phone number'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line12"></div>
                                    <div className="InsuranceOrderPageButton">
                                        <Button
                                            className="btnOrder"
                                            backgroundColor="#FFF"
                                            boxShadow={`0 0 3px 0 ${'#30ab86'}`}
                                            width="130px"
                                            height="50px"
                                            color={'#30ab86'}
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 10px 0 0"
                                            onClick={() => this.closeModal()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btnOrder"
                                            backgroundColor={'#30AB86'}
                                            // boxShadow="0 0 3px 0 #273E59"
                                            width="130px"
                                            height="50px"
                                            // color="#FFFFFF"
                                            borderRadius="5px"
                                            fontSize="16px"
                                            margin="0 5px 0 0"
                                            onClick={() => this.closeCalendlyModal()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        <div className="compare-section-internet">
                            <h2 className="worlds-heading-internet" style={{ textAlign: 'center' }}>
                                How we compare?
                            </h2>
                            <p className="card-desc-internet" style={{ textAlign: 'center', marginTop: '-10px' }}>
                                Compare the traditional experience with ours
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TabComponent />
                            </div>
                        </div>

                        <div className="carousel-internet">
                            <div className="heading2-internet">We Care About Our Customer’s Experience Too</div>
                            <div className="review">
                                <img src={review} alt="" />
                            </div>
                            <div className="carousel-div-internet">
                                <Carousel
                                    autoPlay={false}
                                    NextIcon={<img src={ArrowForwardIcon} className="arrowIconInternet" alt="" />}
                                    PrevIcon={
                                        <img
                                            src={ArrowBackIcon}
                                            height="20px"
                                            width="32px"
                                            className="arrowIconInternet"
                                        />
                                    }
                                    indicators={false}
                                    navButtonsAlwaysVisible={true}
                                    navButtonsWrapperProps={{
                                        // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: 0,
                                            top: 'unset',
                                        },
                                        className: 'arrow-wrapper-internet',
                                    }}
                                    navButtonsProps={{
                                        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            backgroundColor: 'transparent',
                                            borderRadius: 0,
                                            marginTop: '100%',
                                        },
                                        className: 'arrowsInternet',
                                    }}
                                >
                                    {carouselItems.map((item: any) => {
                                        return (
                                            <div className="carousel-inner">
                                                {item.map((item2: any, i: number) => {
                                                    return (
                                                        <CarouselCard
                                                            key={i}
                                                            avatar={item2.avatar}
                                                            name={item2.name}
                                                            title={item2.title}
                                                            rate={item2.rate}
                                                            desc={item2.desc}
                                                            className="card-insurance"
                                                        />
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div>

                        <div className="carousel-2-internet">
                            <div className="heading2-internet">We Care About Our Custemer’s Experience Too</div>
                            <div className="review">
                                <img src={review} className="review-image" alt="" />
                            </div>
                            <div className="carousel-div-internet">
                                <Carousel
                                    autoPlay={false}
                                    next={(active: any) => {
                                        this.setState({ active: active });
                                    }}
                                    indicators={false}
                                    navButtonsAlwaysVisible={false}
                                    animation="slide"
                                >
                                    {carouselItemsMedia.map((item2: any, i: number) => {
                                        return (
                                            <CarouselCard
                                                key={i}
                                                avatar={item2.avatar}
                                                name={item2.name}
                                                title={item2.title}
                                                rate={item2.rate}
                                                desc={item2.desc}
                                                className="card-insurance"
                                            />
                                        );
                                    })}
                                </Carousel>
                                <div className="slider">
                                    <Slider
                                        min={0}
                                        max={carouselItemsMedia.length}
                                        step={this.state.active}
                                        value={this.state.active}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="questions-internet">
                            <div className="frequently-internet">Frequently Asked Questions</div>
                            {quesAns.map((item: any, i: number) => {
                                return (
                                    <div key={i} className="accordion-internet">
                                        <SimpleAccordion question={item.ques} answer={item.ans} />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="address-wrapper-internet">
                            <div className="address-internet">
                                <div className="heading-internet">
                                    Enter your address to view what's available near you
                                </div>
                                <div className="boxCard-internet">
                                    <div className="Para1">
                                        Why do we ask for your address? <InfoIcon style={{ marginLeft: '10px' }} />
                                    </div>
                                    {/* <p style={{ color: '#0066F5', textDecoration: 'underline', cursor: 'pointer' }} className="gpsIconDiv">
                                        <GpsFixedIcon fontSize="small" /> Use my current location
                                    </p> */}
                                    <div className="inputs-internet">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <AutoComplete
                                                    error={Boolean(this.state.streetError)}
                                                    className="input1"
                                                    placeholder="Street"
                                                    // id="outlined-start-adornment"
                                                    onChange={this.streetHandler}
                                                    onSelect={this.handleSelect}
                                                    // variant="outlined"
                                                    value={this.state.street}
                                                    // disabled={this.state.street!==""}
                                                />
                                            </div>
                                            {this.state.streetError ? (
                                                <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                    {''}
                                                    <small>{this.state.streetError}</small>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="innerInputs-internet">
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput1Internet"
                                            >
                                                <div>
                                                    {' '}
                                                    <TextField
                                                        error={Boolean(this.state.cityError)}
                                                        className="input2"
                                                        label="City"
                                                        id="outlined-start-adornment"
                                                        onChange={this.cityHandler}
                                                        variant="outlined"
                                                        value={this.state.city}
                                                        // disabled={this.state.city!==""}
                                                    />
                                                </div>
                                                {this.state.cityError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.cityError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput2Internet"
                                            >
                                                <div>
                                                    {/* <TextField
                                    className="input3"
                                    label="State"
                                    id="outlined-start-adornment"
                                    onChange={this.stateHandler}
                                    variant="outlined"
                                /> */}

                                                    {/* <SelectInput
                                                        className="react-select input3"
                                                        // onChange={}
                                                        options={[{ value: '1x', label: '1x' }]}
                                                        placeholder={this.state.state ? this.state.state : 'State'}
                                                        value={this.state.state}
                                                        onChange={(e: any) => {
                                                            this.stateHandler(e);
                                                        }}
                                                    /> */}
                                                    <TextField
                                                        error={Boolean(this.state.stateError)}
                                                        id="outlined-select-currency"
                                                        select
                                                        className="input3landing"
                                                        label="State"
                                                        value={this.state.state}
                                                        onChange={(e: any) => this.stateHandler(e)}
                                                        // helperText="Please select your currency"
                                                        variant="outlined"
                                                        // disabled={this.state.state!==""}
                                                    >
                                                        {list.map((option: any) => (
                                                            <MenuItem key={option.name} value={option.name}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                                {this.state.stateError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.stateError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                className="innerInput1Internet"
                                            >
                                                <div>
                                                    <TextField
                                                        error={Boolean(this.state.zipcodeError)}
                                                        className="input4"
                                                        label="Zip code"
                                                        id="outlined-start-adornment"
                                                        onChange={this.zipcodeHandler}
                                                        variant="outlined"
                                                        value={this.state.zipcode}
                                                        // disabled={this.state.zipcode !== ""}
                                                    />
                                                </div>
                                                {this.state.zipcodeError ? (
                                                    <div style={{ paddingTop: '10px', color: 'red', fontSize: '12px' }}>
                                                        {''}
                                                        <small>{this.state.zipcodeError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <FormControlLabel
                                        label="I live in an apartment"
                                        control={
                                            <Checkbox
                                                color="primary"
                                                className="checkbox"
                                                checked={this.state.checkedG}
                                                onChange={this.handleChange}
                                                name="checkedG"
                                            />
                                        }
                                    /> */}
                                    <div className="landingFormButtonDiv">
                                        <Button
                                            border="none"
                                            boxShadow="none"
                                            className="landingGetQuoteButtonInternet"
                                            marginTop="10px"
                                            onClick={() => {
                                                this.handleQuote(true);
                                            }}
                                        >
                                            Get A Quote
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <InternetLandingFooter />
                        </div>
                    </div>
                ) : // <ICMain
                //     fromLanding="fromLanding"
                //     street={this.state.street}
                //     city={this.state.city}
                //     zipcode={this.state.zipcode}
                //     state={this.state.state}
                //     checkedG={this.state.checkedG}
                // />
                null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageInternet);
