import React, { Component } from 'react';
import './checklistCard.scss';
import Button from '../Button';
import Circle from '../../../Assets/images/Mortgage&title/check_circle_black_24dp.svg';

type initialProps = {
    mainData?: string;
    paraData?: string;
    blueCard?: string;
    isQuote?: boolean;
    onClick?: any;
    reminderText?: string;
    doneClicked?: any;
    reminderClicked?: any;
};

type initialState = {};

export class ChecklistCompletedCard extends Component<initialProps, initialState> {
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={isDouglasElliman ? 'cardDivChecklist DouglascardDivChecklist' : 'cardDivChecklist'}>
                <div className="cardInnerContentChecklist">
                    <div className="cardContentChecklist">
                        <div className="upperContentChecklist">
                            <div className="upperContentMainChecklist">{this.props.mainData}</div>
                            {this.props.blueCard && (
                                <div className="upperContentBlueChecklist">{this.props.blueCard}</div>
                            )}
                        </div>
                        <div className="upperContentParaChecklist">{this.props.paraData}</div>
                    </div>

                    <div className="checklistCompleteButton">
                        {this.props.isQuote ? (
                            <div></div>
                        ) : (
                            <Button
                                width="95px"
                                backgroundColor="#FFFFFF"
                                boxShadow={`0 0 3px 0 ${
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59'
                                }`}
                                color={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#273e59'
                                }
                                borderRadius="5px"
                                fontSize="16px"
                                border={`1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`}
                                onClick={() => this.props.onClick()}
                            >
                                Read It
                            </Button>
                        )}
                        <div style={{ marginLeft: this.props.isQuote ? '' : '30px' }}>
                            <img src={Circle} width="50px" height="50px" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChecklistCompletedCard;
