import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './Main.scss';
import Slider from '@material-ui/core/Slider';
import SlidingImage from '../../../Assets/images/HomeValuation/sliding.png'

interface data {
    sliderValue: number;
    setSliderValue: any;
    style: any
    sliderMaxValue: number;
    setSliderError: (x: boolean) => void;
}


export default function DiscreteSlider(props: data) {

    const { sliderValue, setSliderValue, style, sliderMaxValue, setSliderError } = props;

    function valuetext(value: number) {
        // props.setSliderValue(value);
        return `${value}`;
        // console.log(value, 'z');
        // return `${value}°C`;
    }
    const PrettoSlider = withStyles({
        root: {
            color: '#006DFF',
            height: 2,
            marginBottom: 30,
            marginLeft: 10,
            width: '95%'
        },
        thumb: {
            height: 32,
            width: 32,
            backgroundColor: '#0066F5',
            marginTop: -14,
            marginLeft: -12,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
            backgroundImage: `url(${SlidingImage})`
        },

        active: {},
        valueLabel: {
            height: 2,
            marginBottom: 100,
            left: 'calc(-50% + 20px)',
            top: -22,
            '& *': {
                background: 'transparent',
                color:'#333333',
                font: 'normal normal bold 16px/22px Roboto'
            },
        },

        track: {
            height: 6,
            marginLeft: -10,
            borderRadius: '4px',
        },
        rail: {
            height: 6,
            opacity: 0.5,
            marginLeft: -10,
            borderRadius: '4px',
            backgroundColor: '#bfbfbf',
        },
        mark: {
            backgroundColor: '#ffffff',
            height: 8,
            color: '#ffffff',
            width: 4,
            marginLeft: -10,
            marginBottom: 100,
            opacity: 1,
        },
        markActive: {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    })(Slider);

    const handleSliderChange = (_: React.ChangeEvent<{}>, value: number | number[]) => {
        if (value <= sliderMaxValue) {
            setSliderValue(value);
        } else {
            setSliderError(true);
            setTimeout(() => {
                setSliderError(false);
            }, 2000);
        }
    }

    return (
        <div style={{ margin: '42px auto 0px' }}>
            <PrettoSlider
                defaultValue={sliderValue <= 100 ? sliderValue : 100}
                getAriaValueText={valuetext}
                // aria-labelledby="discrete-slider-always"
                step={1}
                onChange={handleSliderChange}
                style={style}
                valueLabelDisplay="on"
                value={sliderValue}
                valueLabelFormat={(value: number) => { return `${value}%`}}
            />
        </div>
    );
}
