import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getHSSummarySuccess,
    getHSSummaryFailure
} from './actions';
import {
    GET_HS_SUMMARY
} from './actionTypes';
import apiJunction from '../../utils/api';
import { getErrorMessage } from '../errorHandler';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* getHSSummaryList(action: any) { //action: any
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            // url: `csr/api/v1/move-order/summary/?move=224965&service=Home Security`
            // url: `csr/api/v1/move-order/summary/?move=${action.payload.moveId}&service=Home Security`,
            url: `/api/move/csr-summary-details/summary/?move=${action.payload.moveId}&service=Home Security`,
        });
        if (response.data && response.status === 200) {
            yield put(getHSSummarySuccess({ summary: { ...response.data.move_orders, status: response.status } }));
        }
    } catch (e) {
        yield put(getHSSummaryFailure({ error: getErrorMessage(e) }));
    }
}


export default function* homeSecuritySaga() {
    yield all([
        takeLatest(GET_HS_SUMMARY, getHSSummaryList)
    ]);
}
