import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={387.78} height={238.549} viewBox="0 0 387.78 238.549" {...props}>
            <g data-name="Group 7754" transform="translate(-529.203 -231.771)">
                <ellipse
                    cx={193.89}
                    cy={11.32}
                    rx={193.89}
                    ry={11.32}
                    transform="translate(529.203 447.68)"
                    fill="#f5f5f5"
                />
                <g data-name="Group 7750" transform="translate(2088 1016.95)">
                    <path data-name="Rectangle 522" fill="#e0e0e0" d="M-1542.213-713.46h97.165v4.991h-97.165z" />
                    <path data-name="Rectangle 523" fill="#ebebeb" d="M-1537.743-731.867h4.283v18.399h-4.283z" />
                    <path data-name="Rectangle 524" fill="#ebebeb" d="M-1517.042-731.867h4.283v18.399h-4.283z" />
                    <path
                        data-name="Rectangle 525"
                        fill="#ebebeb"
                        d="M-1493.482-731.597l4.219-.728 3.128 18.123-4.22.728z"
                    />
                    <path data-name="Rectangle 526" fill="#ebebeb" d="M-1532.439-729.438h4.283v15.978h-4.283z" />
                    <path data-name="Rectangle 527" fill="#ebebeb" d="M-1522.599-729.438h4.283v15.978h-4.283z" />
                    <path data-name="Rectangle 528" fill="#ebebeb" d="M-1497.764-729.438h4.283v15.978h-4.283z" />
                    <path
                        data-name="Rectangle 529"
                        fill="#ebebeb"
                        d="M-1511.315-729.069l4.181-.93 3.466 15.598-4.18.93z"
                    />
                    <path data-name="Rectangle 530" fill="#ebebeb" d="M-1526.599-732.776h2.138v19.315h-2.138z" />
                    <path data-name="Rectangle 531" fill="#ebebeb" d="M-1501.138-732.776h2.138v19.315h-2.138z" />
                    <path data-name="Rectangle 532" fill="#ebebeb" d="M-1475.231-728.023h20.857v14.399h-20.857z" />
                    <path data-name="Rectangle 533" fill="#e0e0e0" d="M-1445.055-671.202h-97.165v-4.991h97.165z" />
                    <path data-name="Rectangle 534" fill="#ebebeb" d="M-1449.524-676.193h-4.283v-18.399h4.283z" />
                    <path data-name="Rectangle 535" fill="#ebebeb" d="M-1470.225-676.193h-4.283v-18.399h4.283z" />
                    <path
                        data-name="Rectangle 536"
                        fill="#ebebeb"
                        d="M-1496.901-676.197l-4.219-.728 3.128-18.123 4.219.728z"
                    />
                    <path data-name="Rectangle 537" fill="#ebebeb" d="M-1454.828-676.193h-4.283v-15.978h4.283z" />
                    <path data-name="Rectangle 538" fill="#ebebeb" d="M-1464.668-676.193h-4.283v-15.978h4.283z" />
                    <path data-name="Rectangle 539" fill="#ebebeb" d="M-1489.503-676.193h-4.283v-15.978h4.283z" />
                    <path
                        data-name="Rectangle 540"
                        fill="#ebebeb"
                        d="M-1479.406-676.197l-4.181-.93 3.466-15.597 4.181.93z"
                    />
                    <path data-name="Rectangle 541" fill="#ebebeb" d="M-1460.668-676.193h-2.138v-19.315h2.138z" />
                    <path data-name="Rectangle 542" fill="#ebebeb" d="M-1486.136-676.193h-2.138v-19.315h2.138z" />
                    <path data-name="Rectangle 543" fill="#ebebeb" d="M-1512.029-676.357h-20.857v-14.399h20.857z" />
                    <rect
                        data-name="Rectangle 544"
                        width={121.837}
                        height={4.849}
                        rx={2.12}
                        transform="translate(-1391.928 -756.218)"
                        fill="#e0e0e0"
                    />
                    <path data-name="Rectangle 545" fill="#e6e6e6" d="M-1384.695-752.359h107.363v95.273h-107.363z" />
                    <path data-name="Rectangle 546" fill="#f0f0f0" d="M-1382.006-749.745h101.851v90.096h-101.851z" />
                    <path data-name="Path 4410" d="M-1358.102-749.745l-23.9 89.9v-42.248l12.619-47.652z" fill="#fff" />
                    <path data-name="Path 4411" d="M-1349.64-749.745l-24.113 90.1h-3.843l24.113-90.1z" fill="#fff" />
                    <path data-name="Path 4412" d="M-1280.155-692.893v14.39l-4.879 18.854h-3.851z" fill="#fff" />
                    <path data-name="Rectangle 547" fill="#e6e6e6" d="M-1348.195-751.368h1.713v93.731h-1.713z" />
                    <path data-name="Rectangle 548" fill="#e6e6e6" d="M-1314.95-751.368h1.713v93.731h-1.713z" />
                    <path
                        data-name="Path 4413"
                        d="M-1294.83-754.668s2.473 32.232 16.678 48.657c0 0-10.347 41.484-10.339 55.95l19.226-3.553-6.928-52.143 3.315-48.9z"
                        fill="#e0e0e0"
                    />
                    <path
                        data-name="Path 4414"
                        d="M-1367.197-754.668s-2.466 32.232-16.671 48.657c0 0 10.347 41.484 10.339 55.95l-19.233-3.553 6.935-52.143-3.315-48.9z"
                        fill="#e0e0e0"
                    />
                    <circle
                        data-name="Ellipse 136"
                        cx={16.522}
                        cy={16.522}
                        r={16.522}
                        transform="rotate(-13.28 -4096.578 6111.875)"
                        fill="#e0e0e0"
                    />
                    <circle
                        data-name="Ellipse 137"
                        cx={14.049}
                        cy={14.049}
                        r={14.049}
                        transform="rotate(-9.22 -5572.52 8973.284)"
                        fill="#f0f0f0"
                    />
                    <path
                        data-name="Path 4415"
                        d="M-1492.967-774.945v9.818l6.5-3.68"
                        fill="#f0f0f0"
                        stroke="#e0e0e0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                    />
                </g>
                <g data-name="Group 6812">
                    <path
                        data-name="Path 2559"
                        d="M703.371 409.069c-.558 1.325-5.92 2.554-13.878 3.642-1.4.192-2.884.379-4.436.563-27.083 3.19-101.262 4.971-105.835 4.03-6.318-1.3-18.891-64.835-18.891-64.835s37.018-.642 66.417-.955c10.057-.105 45.04-.371 51.527-.372 10.047 0 25.997 55.785 25.096 57.927z"
                        fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                    />
                    <path
                        data-name="Path 2560"
                        d="M693.125 404.197s-11.364-46.406-12.185-50.306a2.94 2.94 0 00-2.627-2.528c2-.205 3.451-.221 3.451-.221 3.932 0 7.427 2.821 8.689 7.013 2.752 9.144 7.82 26.007 12.954 43.182-.259-.005-10.282 2.86-10.282 2.86z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <path
                        data-name="Path 2561"
                        d="M762.008 440.712c-1.812 10.921-13.664 17.875-25.821 17.875h-24.078l1.539-62.99s23.317-7.594 29.087-1c5.055 5.778 18.763 28.65 19.485 42.187a17.977 17.977 0 01-.212 3.928z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <path
                        data-name="Path 2562"
                        d="M762.008 440.712c-1.812 10.92-13.664 17.875-25.82 17.875h-11.811c7.876-5.02 14.998-20.489 37.631-17.875z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <path
                        data-name="Path 2563"
                        d="M698.091 401.676s32.481-15.588 38.633-5.406c3.267 5.407 12.257 26.977 12.92 40.514.646 13.153-11.475 21.8-23.942 21.8h-22.145z"
                        fill={slug && slug.color_shade2 ? slug.color_shade2 : '#162c47'}
                    />
                    <path
                        data-name="Path 2564"
                        d="M740.889 447.874q-.539.431-1.106.849a50.216 50.216 0 01-17.81 7.915 70.132 70.132 0 01-17.138 1.927h-10.672l-49.838.014-28.567.008h-.422v-.012l-.991-5.185-9.9-51.792s126.064-4.357 136.243 1.453c4.726 2.7 7.38 14.913 8.867 27.228.791 6.557-2.563 12.712-8.666 17.595z"
                        fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                    />
                    <g data-name="Group 1193">
                        <path
                            data-name="Path 2565"
                            d="M725.664 396.914l9.591 24.255-2.528.843s-14.085-11.337-15.335-21.233z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2566"
                            d="M712.935 397.575l8.129 28.3-2.932.671s-14.588-14.276-14.912-25.531z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2567"
                            d="M694.4 383.323s-1.792 4.818-1.185 8.634 20.061 6.595 21.339 8.243a50.621 50.621 0 013.553 6.887s8.306.129 11.984-3.156c0 0-4-8.8-6.3-11.466s-18.577-6.511-18.577-6.511z"
                            fill="#3662c1"
                        />
                        <path
                            data-name="Path 2568"
                            d="M681.518 387.363s-1.791 4.819-1.184 8.635 20.06 6.595 21.339 8.244a50.667 50.667 0 013.553 6.887s7.11.444 10.788-2.841c0 0-.6-9.626-2.9-12.289s-15.76-7.8-15.76-7.8z"
                            fill="#6d89cf"
                        />
                        <path
                            data-name="Path 2569"
                            d="M701.597 353.396s-3.819-.225-3.248 10.427-.081 16.646 8 21.164c7.071 3.954 7.182 4.185 7.182 4.185l.524-2.088s-7.379-8.879-7.707-11.022c-2.033-13.294 2.013-24.737-4.751-22.666z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2570"
                            d="M709.405 365.277s-.35-7.539-1.168-9.7a5.618 5.618 0 00-3.189-2.879 1.97 1.97 0 00-.269-.1c-2.551-.7-5.335-1.283-7.722-1.72-3.454-.633-7.658 3.658-8.811 6.976-2.216 6.377-7.883 22.767-7.93 31.539a2.349 2.349 0 002.6 2.2c20.166.04 24.075-5.2 24.075-5.2s.134-1.086.313-2.871c.226-2.256.526-5.627.723-9.328.143-2.671.231-5.514.2-8.231.693-.364 1.178-.686 1.178-.686z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2571"
                            d="M721.064 425.872a15.385 15.385 0 004.446 2.7c2.029.6 4.819.73 4.566 1.617s-5.029 1.718-6.912 1.458-4.565 1.4-5.39.508.358-5.614.358-5.614z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2572"
                            d="M734.985 420.633a15.4 15.4 0 004.446 2.7c2.03.6 4.819.729 4.566 1.617s-5.029 1.718-6.912 1.458-4.566 1.4-5.39.507.358-5.613.358-5.613z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2573"
                            d="M692.198 353.042s-3.911 2.2-3.34 12.851.594 15.608 8 21.164a43.884 43.884 0 006.888 4.548l1.76-.6s-8.32-10.729-8.648-12.872c-2.034-13.295 2.104-27.162-4.66-25.091z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2574"
                            d="M713.176 388.926a19.08 19.08 0 011.685 1.508c.635.678 2.984 1.15 3.055.711s-.976-.286-.684-.673 2.983.658 3.039 0a3.133 3.133 0 00-2.339-2.7 13.614 13.614 0 00-3.879-.691z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2575"
                            d="M694.643 352.099s-2.562-.182-4.5 2.854-1.854 9.416-1.854 9.416 5.518 2.378 10.654-.571-4.3-11.699-4.3-11.699z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2576"
                            d="M704.784 352.604a4.315 4.315 0 01-1.388-2.222 14.307 14.307 0 01-.1-.381 10.02 10.02 0 01-.129-3.051 6.26 6.26 0 01.024-.217 11.259 11.259 0 01.243-1.4l-6.1-3.127a19.944 19.944 0 01-.156 4.281 17.249 17.249 0 01-1.192 4.33c.084.089 2.722 2.952 5.174 3.634a3.361 3.361 0 001.36.119c2.748-.387 2.264-1.966 2.264-1.966z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2577"
                            d="M705.503 391.003a12.971 12.971 0 012.9.6c1.141.446 1.9 1.9 1.757 2.315s-1.709-.344-3.136-.631a10.6 10.6 0 01-3.282-1.684z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2578"
                            d="M703.393 350.382c-2.6.247-6.22-3.891-6.22-3.891a9.9 9.9 0 015.993.46 9.957 9.957 0 00.129 3.05c.029.128.061.254.098.381z"
                            fill="#fdac84"
                        />
                        <g data-name="Group 1192">
                            <path
                                data-name="Path 2579"
                                d="M704.978 348.067s-6.925 1.828-9.313-3.678c-2.578-5.942.25-10.272 2.6-10.564 4.689-.583 7.413 1.206 8.237 3.1s2.297 10.168-1.524 11.142z"
                                fill="#ffd5c0"
                            />
                            <g data-name="Group 1191">
                                <path
                                    data-name="Path 2581"
                                    d="M696.901 346.316a12.839 12.839 0 01-.715-3.655s-1.807-2.3-.754-2.874 1.621.793 1.862.525.618-4.8 1.9-4.653 6.009 1.609 8.083-.179-2.957-8.776-11.483-6.077-6.789 12.179 1.107 16.913z"
                                />
                                <path
                                    data-name="Path 2582"
                                    d="M691.584 333.121a3 3 0 01.028-1.975 3.156 3.156 0 00.466 1.637c.445.505-.494.338-.494.338z"
                                />
                                <path
                                    data-name="Path 2583"
                                    d="M691.757 332.315a1.7 1.7 0 00-1.552.692 1.356 1.356 0 011.494.03z"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        data-name="Path 2584"
                        d="M740.889 447.87q-.539.432-1.106.849a50.215 50.215 0 01-17.81 7.915 70.144 70.144 0 01-17.138 1.927h-10.672l-49.838.013-28.567.008-.425-.012-.991-5.185c17.509-3.383 41.739-6.82 67.014-6.554 24.138.259 44.349.226 59.533 1.039z"
                        fill={slug && slug.color_shade2 ? slug.color_shade2 : '#162c47'}
                    />
                    <g data-name="Group 1195">
                        <path
                            data-name="Path 2585"
                            d="M585.275 353.508s-1.651 1.281-1.782 9.147 4.647 33.9 7.037 33.483 5.643-1.347 5.465-8.845.007-32.661.007-32.661z"
                            fill="#a95c4b"
                        />
                        <path
                            data-name="Path 2586"
                            d="M664.761 351.621a4.754 4.754 0 01-.915-.474h6.7s-2.573-1.456-4.245-2.2-5.768 0-5.768 0-15.17-2.257-23.674-2.045c-2.281.057-18.506-2.588-23.181-1.651-2.464.494-4.7.884-6.133 1.119a1.529 1.529 0 01-1.768-1.375v-.015a40.675 40.675 0 00-.648-4.783l-5.707-2.761-2.866 1.524c.027.316.047.626.057.931a16.44 16.44 0 01-.391 4.2 6.238 6.238 0 01-4.508 4.624l-.028.007c4.278 3.684 26.044 10.1 26.414 8.22.5-2.532 42.434-5.8 42.434-5.8v.005a4.336 4.336 0 011.458.86c.289.418 2.631.354 3.068.418s.217-.643-.299-.804z"
                            fill="#a95c4b"
                        />
                        <path
                            data-name="Path 2587"
                            d="M679.646 444.711s4.423 7.978 6.382 8.877c1.556.715 5.768 1.422 8.114 3.229 2.721 2.095-1.991 1.984-3.027 1.984 0 0-5.273-.628-7.771-.575-1.439.03-5.728.575-5.728.575a5.4 5.4 0 01-5.32-6.387l.018-.093c.612-3.083 1.087-7.059 1.087-7.059z"
                            fill="#dde8fd"
                        />
                        <path
                            data-name="Path 2588"
                            d="M624.529 349.957c-.093 2.074-.67 4.348-2.518 5.547-2.918 1.893-2.179 6.833-2.161 8.772l-12.49-17.885a61.44 61.44 0 017.605-1.34c2.521-.276 9.155.483 9.155.483a17.575 17.575 0 01.409 4.423z"
                            fill="#e68922"
                        />
                        <path
                            data-name="Path 2589"
                            d="M660.327 447.833s4.408 4.856 6.367 5.755c1.4.643 5.168 1.017 7.411 2.646 2.369 1.72.554 2.567-.822 2.567 0 0-6.3.5-10.312-.629-1.094-.308-4.087.305-5.154.542a21.472 21.472 0 01-2.709.2c-2.446-.312-2.559-2.657-2.328-5.181a13.681 13.681 0 01.2-1.409c.612-3.083.979-5.949.979-5.949z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2590"
                            d="M651.312 358.565c0 .024 3.471 10.762 5.532 12.063s9.052 11.035 10.746 11.035-2.092 1.182-2.092 1.182-13.1-7.592-13.86-8.061-4.307-7.759-4.555-11.315 4.229-4.904 4.229-4.904z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2591"
                            d="M692.439 434.147s-2.026 5.425-1.6 7.437-.264 5.914.56 7.658 2.294 4.28.883 4.922c-.536.244-6.769-4.591-7.126-6.018s-.635-5.635-1.828-7.921a3.4 3.4 0 011.531-4.968c1.724-.84 4.128-5.442 4.128-5.442z"
                            fill="#bed2fa"
                        />
                        <path
                            data-name="Path 2592"
                            d="M698.485 443.077s1.952 5.452 3.579 6.712 3.614 4.689 5.369 5.49 4.514 1.79 3.851 3.191c-.252.531-8.135.859-9.328 0s-4.122-3.9-6.507-4.872a3.4 3.4 0 01-2.036-4.784c.775-1.754-.358-6.821-.358-6.821z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 2593"
                            d="M658.79 382.888s35.6-.566 40.347 2.455-4.747 51.256-4.747 51.256-9.206.294-9.939-2.143c0 0 1.656-22.6.207-26.507s-.555-9.315-.555-9.315-18.007 2.748-23.726 1.4-1.587-17.146-1.587-17.146z"
                            fill="#0d0d38"
                        />
                        <path
                            data-name="Path 2594"
                            d="M637.701 324.208a10.1 10.1 0 00-6.089-.556c-8.606-2.59-10.831 7.32-10.721 10.557s.478 6.714-3.124 10.919c-10.406 12.147 11.388 12.683 11.388 12.683.184-9.705 12.12-9.018 11.611-8.035 14.912 3.581 2.93-22.457-3.065-25.568z"
                        />
                        <path
                            data-name="Path 2595"
                            d="M643.92 384.581s36.926 3.649 40.18 7.408 16.145 52.721 16.145 52.721-3.761 2.111-7.191.158c0 0-9.814-16.594-14.149-21.8s-6-21.312-6.713-21.475-20.289 4.528-28.272-1.564 0-15.448 0-15.448z"
                            fill="#120e59"
                        />
                        <path
                            data-name="Path 2596"
                            d="M623.268 351.142s10.09-6.013 19.91-3.253c3.72 1.045 5.206 10.7 6.074 13.846s-.76 8.648.759 10.778 10.916 7.392 11.388 10.333c.867 5.4-15.222 10.537-20.1 8.914s-25.375-36.457-18.031-40.618z"
                            fill="#bed2fa"
                        />
                        <path
                            data-name="Path 2597"
                            d="M682.05 445.163a14.205 14.205 0 01-2.992 1.579c-2.2.735-6.313.394-6.313.394s-10.491-20.352-12.706-25.5c-2.1-4.881-3.855-15.581-3.855-15.581-1.6-.087-13.587 1.61-24.117.472-5.6-.6-10.339-1.988-11.945-4.817-4.627-8.15 10.153-17.237 10.153-17.237s28.9 5.209 35.246 9.82c6.019 4.37 16.529 50.87 16.529 50.87z"
                            fill="#3662c1"
                        />
                        <path
                            data-name="Path 2598"
                            d="M661.9 447.594a16.641 16.641 0 01-3.7 1.423 13.655 13.655 0 01-5.491-.436s-1.614-9.75-8.229-25.349c-1.66-3.915-1.4-11.939-1.627-12.039 0 0-36.148.308-40.347-7.809a18.893 18.893 0 01-1.356-6.236c.439-6.421 8.776-8.9 8.776-8.9s37.681 6.663 44.149 11.107 7.825 48.239 7.825 48.239z"
                            fill="#6d89cf"
                        />
                        <path
                            data-name="Path 2599"
                            d="M633.316 384.74c-2.2 3.919-4.482 7.019-12.888 8.474a123.779 123.779 0 00-19.332 4.962c-2.419.731-5.329-7.757-5.565-19.51-.266-13.185-1.971-30.743-1.966-30.777 0 0 7.444 5.062 11.5 3.3 3.994-1.733 2.35-4.716 2.3-4.8l10.742 10.547a133.021 133.021 0 005.335 15.978c4.416 10.187 10.478 10.745 9.874 11.826z"
                            fill="#feb33c"
                        />
                        <path
                            data-name="Path 2600"
                            d="M626.101 340.035a20.545 20.545 0 01-3.362-5.588c-.626-1.907-.662-9.3 6.775-9.3a10 10 0 014.328.968c1.734.843 4.347 2.826 2.694 9.518l-.359 2.232z"
                        />
                        <g data-name="Group 1194">
                            <path
                                data-name="Path 2601"
                                d="M638.068 348.529c.033.009 1.568 3.536-1.8 4.148-2.214.4-7.543-1.177-7.553-3.148 0-.937 1.126-3.628.258-8.986l.423.016 7.118.274s.187 2.2.506 4.244c.263 1.715.625 3.331 1.048 3.452z"
                                fill="#ffd5c0"
                            />
                            <path
                                data-name="Path 2602"
                                d="M636.51 340.834s.187 2.2.505 4.244c-3.879.5-6.478-2.7-7.623-4.518z"
                                fill="#fdac84"
                            />
                            <path
                                data-name="Path 2603"
                                d="M637.393 343.882s-9.251-.1-9.1-5.863-.939-9.654 4.974-9.832 7 2.05 7.42 3.985-.883 11.588-3.294 11.71z"
                                fill="#ffd5c0"
                            />
                            <path
                                data-name="Path 2604"
                                d="M637.484 328.162s-2.718 6.688-6.631 7.58-5.57-.2-5.57-.2a11.009 11.009 0 003.779-6.4s6.6-4.249 8.422-.98z"
                            />
                            <path
                                data-name="Path 2605"
                                d="M636.841 328.694s1.351 1.807 2.27 3.04c1.361 1.827 2.135 4.343 1.654 5.435 0 0 1.808-5.549-.264-8.207-2.197-2.822-3.66-.268-3.66-.268z"
                            />
                        </g>
                        <path
                            data-name="Path 2606"
                            d="M605.132 340.198a40.638 40.638 0 01.648 4.783c-4.844-.159-8-3.584-9.163-5.089a16.77 16.77 0 00-.057-.931l2.866-1.524z"
                            fill="#6f3425"
                        />
                        <path
                            data-name="Path 2607"
                            d="M606.463 342.586s-10.128 1.165-10.756-5.154-2.359-10.424 4.081-11.435 7.939 1.275 8.661 3.333.633 12.79-1.986 13.256z"
                            fill="#a95c4b"
                        />
                        <path
                            data-name="Path 2608"
                            d="M596.616 339.897a4.757 4.757 0 01.229-3.573s-1.036-1.938-.317-2.513 1.388 1.112 1.684.732a8.684 8.684 0 001.76-4.265c.167-2.112 7.373-1.683 8.234-1.559a1.03 1.03 0 00.519-.1 2.316 2.316 0 00.354-4.055 4.537 4.537 0 01-.261-.183c-.848-.641-6.722-4.923-12.263-1.635s-5.536 6.382-4.492 8.735 1.867 8.294 4.553 8.416z"
                        />
                        <path
                            data-name="Path 2609"
                            d="M598.572 368.926c-2.328.6-5.738 1.742-9.761.689a23.912 23.912 0 01-6.9-3.209s1.437-13.652 5.443-16.032a30.722 30.722 0 016.215-2.485s11.75 19.314 5.003 21.037z"
                            fill="#e68922"
                        />
                        <path
                            data-name="Path 2610"
                            d="M625.502 351.684s-2.437-.669-5.182 3.435 5.128 28.484 9.954 29.354c5.361.966 18.2 3.892 18.2 3.892l.455-1.952s-15.914-7.074-16.671-7.855-5.05-26.624-6.756-26.874z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2611"
                            d="M624.383 350.725a6.825 6.825 0 00-4.909 6.551 51.029 51.029 0 00.145 8.743s5.643 1.519 9.775-1.464-1.192-13.94-5.011-13.83z"
                            fill="#bed2fa"
                        />
                        <path
                            data-name="Path 2612"
                            d="M643.177 347.889a11.5 11.5 0 016.035 4.85c2.317 3.79 3.4 5.826 3.4 5.826s-.217 1.76-3.362 3.17-6.073-13.846-6.073-13.846z"
                            fill="#bed2fa"
                        />
                        <path
                            data-name="Path 2613"
                            d="M648.928 386.416a39.464 39.464 0 015.237.95c.466.327 1.515 1.2 2.156 1.726a5.26 5.26 0 011.127 1.761l-8.974-2.484z"
                            fill="#ffd5c0"
                        />
                        <path
                            data-name="Path 2614"
                            d="M665.497 382.842a13.721 13.721 0 00.976 1.115 8.736 8.736 0 002.36.513c.495.175.436-.149.087-.44s-1.28-.277-.895-.335a10.263 10.263 0 013.2.029 2.864 2.864 0 002.258.029 9.99 9.99 0 00-3.438-1.8c-.816-.03-2.452-.293-2.452-.293z"
                            fill="#ffd5c0"
                        />
                    </g>
                    <path
                        data-name="Path 2617"
                        d="M614.305 388.249l3.975.844a5.064 5.064 0 013.043 1.975c.993 1.364 2.286 3.153 2.392 3.367a12.524 12.524 0 01.237 2.276l-10.493-4.95z"
                        fill="#a95c4b"
                    />
                    <path
                        data-name="Path 2618"
                        d="M640.045 441.291c-1.953 10.591-12.667 17.3-23.649 17.3h-22.143l1.732-63.651s21.126-6.933 26.433-.338c4.649 5.778 17.256 28.649 17.92 42.187a19.432 19.432 0 01-.293 4.502z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <path
                        data-name="Path 2619"
                        d="M640.046 441.291c-1.953 10.591-12.667 17.3-23.65 17.3h-17.72c8.295-5.282 19.462-19.887 41.37-17.3z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <path
                        data-name="Path 2620"
                        d="M605.719 458.549q-.248.016-.5.03-.524.026-1.054.034h-22.494v-.01l-1.278.015c-2.28.026-4.661.052-6.871.069l-1.094.009h-.4c-2.183.014-4.113.02-5.489.011a38.858 38.858 0 01-5.476-.4 25.642 25.642 0 01-6.1-1.642 20.3 20.3 0 01-1.941-.927 19.2 19.2 0 01-2.4-1.54 20.749 20.749 0 01-5.108-5.64 30.011 30.011 0 01-2.654-5.439 50.024 50.024 0 01-2.329-8.726 106.826 106.826 0 01-1.484-16.432q-.088-3.807-.013-8.032.119-6.755.641-14.629c.486-7.38 1.23-15.521 2.213-24.483.9-8.185 1.93-13.907 3.434-15.21 2.219-1.923 6.845-2.7 10.064-3.006 2-.192 3.45-.207 3.45-.207a9.233 9.233 0 018.689 6.6 21399.1 21399.1 0 0111.932 37.412l.072.226.824 2.594.127.4c.269-.113.548-.231.836-.35a.21.21 0 01.029-.013c1.145-.477 2.44-1 3.839-1.532.725-.276 1.476-.557 2.25-.835a102.091 102.091 0 016.934-2.236c1.061-.3 2.129-.576 3.191-.822a52.165 52.165 0 014.035-.786l.115-.017c.156-.024.311-.045.466-.067 5.685-.785 10.62-.24 12.66 3.146 3.267 5.423 12.256 27.056 12.919 40.632.619 12.527-10.268 20.972-22.035 21.803z"
                        fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                    />
                    <path
                        data-name="Path 2621"
                        d="M570.2 402.171s-11.365-43.535-12.185-47.193a2.873 2.873 0 00-2.627-2.372c2-.192 3.451-.207 3.451-.207a9.228 9.228 0 018.689 6.579c2.752 8.578 7.821 24.4 12.955 40.51-.26-.002-10.283 2.683-10.283 2.683z"
                        fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                    />
                    <g data-name="Group 1198">
                        <g data-name="Group 1196">
                            <path
                                data-name="Path 2622"
                                d="M872.715 425.989h-52.841a2.3 2.3 0 01-2.3-2.3V410.13a2.3 2.3 0 012.3-2.3h52.841a2.3 2.3 0 012.3 2.3v13.559a2.3 2.3 0 01-2.3 2.3zm-52.841-16.542a.685.685 0 00-.684.684v13.559a.685.685 0 00.684.683h52.841a.684.684 0 00.684-.683v-13.559a.684.684 0 00-.684-.684z"
                                fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                            />
                        </g>
                        <path
                            data-name="Rectangle 467"
                            fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                            d="M782.463 351.876h114.662v65.355H782.463z"
                        />
                        <path
                            data-name="Path 2631"
                            d="M786.321 351.876h114.662v65.355H786.321z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                        <path
                            data-name="Rectangle 469"
                            fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                            d="M783.151 425.989h103.422v18.54H783.151z"
                        />
                        <path
                            data-name="Path 2630"
                            d="M793.703 425.989h103.422v18.54H793.703v-18.54z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                        <g data-name="Group 1197">
                            <path
                                data-name="Rectangle 471"
                                fill={slug && slug.color_shade2 ? slug.color_shade2 : '#162c47'}
                                d="M819.847 379.388h50.968v18.233h-50.968z"
                            />
                            <path
                                data-name="Path 2623"
                                d="M871.994 397.62h-50.967l1.015-18.233h51.034z"
                                fill={slug && slug.color_shade3 ? slug.color_shade3 : '#3c536f'}
                            />
                        </g>
                        <path
                            data-name="Path 2624"
                            d="M809.277 414.611H791.85a1.912 1.912 0 01-1.906-1.906 1.912 1.912 0 011.906-1.906h17.427a1.912 1.912 0 011.906 1.906 1.912 1.912 0 01-1.906 1.906z"
                            fill={slug && slug.color_shade2 ? slug.color_shade2 : '#162c47'}
                        />
                    </g>
                    <g data-name="Group 1199">
                        <path
                            data-name="Path 2625"
                            d="M808.543 458.447a.928.928 0 01-.673-1.126l3.27-12.984 3.385.193-5.2 13.449a.647.647 0 01-.782.468z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                    </g>
                    <g data-name="Group 1200">
                        <path
                            data-name="Path 2626"
                            d="M794.405 458.447a.929.929 0 01-.674-1.126l3.27-12.984 3.385.193-5.2 13.449a.645.645 0 01-.781.468z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                    </g>
                    <g data-name="Group 1201">
                        <path
                            data-name="Path 2627"
                            d="M874.974 458.447a.928.928 0 00.673-1.126l-3.27-12.984-3.385.193 5.2 13.449a.645.645 0 00.782.468z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                    </g>
                    <g data-name="Group 1202">
                        <path
                            data-name="Path 2628"
                            d="M889.109 458.447a.928.928 0 00.673-1.126l-3.27-12.984-3.385.193 5.2 13.449a.646.646 0 00.782.468z"
                            fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                        />
                    </g>
                    <path
                        data-name="Path 2629"
                        d="M587.313 386.731s20.7 1.375 26.992 1.515l-.845 3.511s-22.335 4.3-22.408 3.905-3.739-8.931-3.739-8.931z"
                        fill="#a95c4b"
                    />
                    <g data-name="Group 6813">
                        <g data-name="Group 990">
                            <g data-name="Group 989" opacity={0.22}>
                                <path
                                    data-name="Path 1842"
                                    d="M844.174 324.506a16.4 16.4 0 00-13.171 6.615.969.969 0 00.1 1.263l.007.008a.974.974 0 001.47-.113 14.471 14.471 0 0123.206-.023.974.974 0 001.471.111l.007-.008a.972.972 0 00.1-1.263 16.4 16.4 0 00-13.19-6.59z"
                                    fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                                />
                            </g>
                        </g>
                        <g data-name="Group 992">
                            <g data-name="Group 991" opacity={0.42}>
                                <path
                                    data-name="Path 1843"
                                    d="M844.177 328.381a12.59 12.59 0 00-10.422 5.558.964.964 0 00.125 1.218l.015.016a.963.963 0 001.48-.153 10.613 10.613 0 0117.616-.017.963.963 0 001.48.15l.013-.012a.973.973 0 00.122-1.226 12.587 12.587 0 00-10.429-5.534z"
                                    fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                                />
                            </g>
                        </g>
                        <g data-name="Group 994">
                            <g data-name="Group 993" opacity={0.65}>
                                <path
                                    data-name="Path 1844"
                                    d="M844.182 332.256a8.732 8.732 0 00-7.652 4.559.971.971 0 00.167 1.153.971.971 0 001.541-.225 6.765 6.765 0 0111.9-.011.973.973 0 001.542.222.971.971 0 00.164-1.154 8.732 8.732 0 00-7.662-4.544z"
                                    fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                                />
                            </g>
                        </g>
                        <g data-name="Group 995">
                            <circle
                                data-name="Ellipse 125"
                                cx={3.875}
                                cy={3.875}
                                fill={slug && slug.color_shade4 ? slug.color_shade4 : '#5b7088'}
                                r={3.875}
                                transform="translate(840.314 337.1)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
