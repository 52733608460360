import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
import Footer from './Footer';
import './EditPlan.scss';
import SelectInput from '../../components/atoms/SelectInput';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import WifiIcon from '@material-ui/icons/Wifi';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputLabel } from '@material-ui/core';
import * as ServiceActions from '../../store/savings/actions';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import History from '../../routes/History';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    history?: any;
    location?: any;
    commonAction?: any;
    commonState?: any;
    serviceAction?: any;
    serviceState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
};

type initialState = {
    isHomeOwner: string | null;
    internetProviderFormDetails: any;
    isAddAnotherInternetProviderClicked: boolean;
    internetProviderOptions: any;
    continueClicked: boolean;
    isGetValueMapped: boolean;
    getDigitalProviders: boolean;
    internetId: string;
    selectedPlanDetails: [{
        id: any,
        provider: string,
        price: number,
        account_number: string,
        services_brought: string,
        service: {
            id: any
        },
        move: any
    }];
    isInternet: boolean;
    isTV: boolean;
    isPhone: boolean;
    isAddAnotherProviderClicked: boolean;
    isShowCreate: boolean;
    serviceId: string;
    submitForm: boolean;
};

class EditPlan extends Component<initialProps, initialState> {
    state: initialState = {
        isHomeOwner: 'false',
        internetProviderFormDetails: [
            {
                id: '',
                formId: 1,
                internetAmount: '',
                internetProvider: null,
                isInternet: false,
                isTV: false,
                isPhone: false,
                internetAccountNumber: '',
                otherProvider: '',
            },
        ],
        isAddAnotherInternetProviderClicked: false,
        internetProviderOptions: [],
        continueClicked: false,
        isGetValueMapped: false,
        getDigitalProviders: false,
        internetId: '',
        selectedPlanDetails: [{
            id: '',
            provider: '',
            price: 0,
            account_number: '',
            services_brought: '',
            service: {
                id: ''
            },
            move: ''
        }],
        isInternet: false,
        isTV: false,
        isPhone: false,
        isAddAnotherProviderClicked: false,
        isShowCreate: false,
        serviceId: '',
        submitForm: false,
    };

    componentDidMount() {

        this.setState({ isHomeOwner: localStorage.getItem('isHomeOwner') })

        if (this.props.location && this.props.location.state) {
            this.setState({ selectedPlanDetails: this.props.location.state.detail })
        }
        this.props.serviceAction.getDigitalServiceProvider();

    }
    componentDidUpdate = (prevProps: initialProps) => {    
        if(prevProps.homeOwnerDashboardState.updateSubscriptionDetails !== this.props.homeOwnerDashboardState.getSubscriptionDetails && (this.props.history.location.state.from !== 'summary' || this.state.submitForm)){
            if(this.props.homeOwnerDashboardState.updateSubscriptionDetails?.id || this.props.homeOwnerDashboardState.updateSubscriptionDetails?.length > 0){
                this.props.history.push('/dashboard/internet/summary');
            }
        }
        if (
            prevProps.serviceState.getDigitalServiceProvider !== this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.getDigitalServiceProvider &&
            this.props.serviceState.isGetDigitalServiceProvider
        ) {
            let digitalProvider: any = [];

            this.props.serviceState.getDigitalServiceProvider.map((item: any) => {
                digitalProvider.push({ label: item.logo ? <div className="otipnval"><img src={item.logo} height="30px" width="30px" alt='logo' />{item.name} </div> : item.name , value: item.name, logo: item.logo });
            });
            digitalProvider.push({ label: 'Other', value: 'other' });

            this.setState({
                internetProviderOptions: digitalProvider,
                getDigitalProviders: true,
            });
        }

        if (
            this.props &&
            this.props.location.state.detail &&
            !this.state.isGetValueMapped &&
            this.state.getDigitalProviders
        ) {
            let internetData: any = [];
            console.log(this.props.location.state.detail)
            this.props.location.state.detail.map((item: any) => {
                if (item.service && item.service.slug == 'internet_cable') {
                    let options: any = this.state.internetProviderOptions;
                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'other', label: 'Other' };
                    }
                    internetData.push({
                        id: item.id,
                        formId: internetData.length + 1,
                        internetAmount: item.price,
                        internetProvider: provider,
                        isInternet: item.services_brought && item.services_brought.includes('Internet') ? true : false,
                        isTV: item.services_brought && item.services_brought.includes('TV') ? true : false,
                        isPhone: item.services_brought && item.services_brought.includes('Phone') ? true : false,
                        internetAccountNumber: item.account_number,
                        otherProvider: item.provider,
                    });
                    this.setState({ internetId: item.id ,serviceId: item.service_id  });
                }
            });

            let tempData: any = {};
            internetData.forEach((data:any) => {
                if (data.isInternet) {
                    tempData.isInternet = true;
                }
                if (data.isTV) {
                    tempData.isTV = true;
                }
                if (data.isPhone) {
                    tempData.isPhone = true;
                }
            });
            tempData = {...tempData, isGetValueMapped: true, internetProviderFormDetails: internetData}
            this.setState(tempData);
        }
    }

    onChange = (e: any, name: string, index: number) => {
        let internetProviderFormDetails = this.state.internetProviderFormDetails;
        let tempData = internetProviderFormDetails[index];
        if (name == 'internetProvider') {
            tempData[name] = e;
        } else if (name == 'internetAccountNumber') {
            tempData[name] = e.target.value.replace(/\D/g, '');
        } else if (name == 'internetAmount') {
            tempData[name] = e.target.value.replace(/[^\d.]/g, '');
        } else {
            tempData[name] = e.target.value;
        }
        internetProviderFormDetails[index] = tempData;
        this.setState({ internetProviderFormDetails: internetProviderFormDetails });
    };

    internetService = (name: string, index: number) => {
        let internetProviderFormDetails = this.state.internetProviderFormDetails;
        let tempData = internetProviderFormDetails[index];
        tempData[name] = !tempData[name];
        internetProviderFormDetails[index] = tempData;
        this.setState({ internetProviderFormDetails: internetProviderFormDetails });

        let serviceData: any = {};
        if (name === 'isInternet') serviceData.isInternet = true
        else if (name === 'isTV') serviceData.isTV = true
        else if (name === 'isPhone') serviceData.isPhone = true

        this.setState(serviceData);
    };

    onEditConfirm = () => {
        this.setState({ continueClicked: true });
        let payloadData = this.state.internetProviderFormDetails;
        let payloadArray: any[] = [], id: any, body: any[] = [];
       
        payloadData.forEach((entry: any, index: any) => {
            if (index == 0) id = entry.id;
            let serviceBrought: string = '';
            if (entry.isInternet && serviceBrought == '') {
                this.setState({ isInternet: true })
                serviceBrought = serviceBrought + 'Internet';
            }
            if (entry.isTV && serviceBrought !== '') {
                this.setState({ isTV: true })
                serviceBrought = serviceBrought + ', TV';
            } else if (entry.isTV && serviceBrought == '') {
                serviceBrought = serviceBrought + 'TV';
            }
            if (entry.isPhone && serviceBrought !== '') {
                this.setState({ isPhone: true })
                serviceBrought = serviceBrought + ', Phone';
            } else if (entry.isPhone && serviceBrought == '') {
                serviceBrought = serviceBrought + 'Phone';
            }
            let payload: any = {
                data: {
                    id: entry.id,
                    service_id: this.state.serviceId ? this.state.serviceId : this.state.selectedPlanDetails[0].service.id,
                    // service_id: this.state.selectedPlanDetails[index].service.id,
                    move: this.state.selectedPlanDetails[index].move,
                    provider:
                        entry.internetProvider &&
                            entry.internetProvider.value &&
                            entry.internetProvider.value != 'other'
                            ? entry.internetProvider.value
                            : entry.internetProvider &&
                                entry.internetProvider.value &&
                                entry.internetProvider.value == 'other'
                                ? entry.otherProvider
                                : '',
                    provider_logo: 
                        entry.internetProvider &&
                        entry.internetProvider.logo &&
                        entry.internetProvider.logo != 'other'
                            ? entry.internetProvider.logo
                            : entry.internetProvider &&
                                entry.internetProvider.logo &&
                                entry.internetProvider.logo == 'other'
                            ? entry.otherProvider
                            : '',   
                    services_brought: serviceBrought,
                    price: entry.internetAmount,
                    account_number: entry.internetAccountNumber
                }
            };
            body.push(payload.data)
        });
        payloadArray.push({ id, body })
        this.setState({submitForm: true})
        this.props.dashboardAction.updateSubscriptionDetails(payloadArray[0]);
        // History.push('/dashboard/internet/summary');
    }

    addAnotherInternetForm = () => {
        this.setState({isShowCreate: true})
        this.setState({ isAddAnotherProviderClicked: true })
        let internetProviderFormDetails: any = this.state.internetProviderFormDetails;
        internetProviderFormDetails.push({
            id: '',
            formId: internetProviderFormDetails.length + 1,
            internetAmount: '',
            internetProvider: null,
            isInternet: false,
            isTV: false,
            isPhone: false,
            internetAccountNumber: '',
            otherProvider: '',
        });
        this.setState({
            isAddAnotherInternetProviderClicked: true,
            internetProviderFormDetails: internetProviderFormDetails,
        });
    };

    isShowAddAnother = () => {
        let isShow = false;
        isShow = this.state.isInternet && this.state.isPhone && this.state.isTV ? false : true
        return isShow;
    }

    onCreateSubscription = () => {
        let lastRecordIndex = this.state.internetProviderFormDetails.length && this.state.internetProviderFormDetails.length - 1;
        let data = this.state.internetProviderFormDetails[lastRecordIndex];
        
        let payload = {
            // service_id: this.state.selectedPlanDetails[0].service.id,
            service_id: this.state.serviceId ? this.state.serviceId : this.state.selectedPlanDetails[0].service.id,
            move: this.state.selectedPlanDetails[0].move,
            provider:
            data.internetProvider &&
            data.internetProvider.value &&
            data.internetProvider.value != 'other'
            ? data.internetProvider.value
            : data.internetProvider &&
                data.internetProvider.value &&
                data.internetProvider.value == 'other'
                ? data.otherProvider
                : '',
            provider_logo: 
            data.internetProvider &&
            data.internetProvider.logo &&
            data.internetProvider.logo != 'other'
                ? data.internetProvider.logo
                : data.internetProvider &&
                    data.internetProvider.logo &&
                    data.internetProvider.logo == 'other'
                ? data.otherProvider
                : '',
            price: data.internetAmount,
            account_number: data.internetAccountNumber,
        };
        this.props.dashboardAction.postSubscriptionDetails(payload);
    }

    render() {

        const breadList = [
            {
                name: 'Order summary internet & cable',
                url: ''
            }
        ]

        return (
            <div>
                <>
                    <div className="greybglong">
                        <div className="main-head">
                            <h2 className="heading">Internet and Cable</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} />
                            </div>
                        </div>
                        <div className='summaryHeader'>
                            <h3>Edit Internet & Cable Subscription Details</h3>
                            <p>Update your subscription plan details.</p>
                        </div>
                    </div>

                    <div className="form_section">
                        <form>
                            <div className="form_box">
                                <div className="edit_securityform edit_securityformleft">
                                    {this.state.internetProviderFormDetails.map((form: any, index: number) => {
                                        return (
                                            <>
                                                <div className="form_group">
                                                    <SelectInput
                                                        label={'Which provider are you using?'}
                                                        value={form.internetProvider && form.internetProvider.value}
                                                        defaultValue={form.internetProvider && form.internetProvider.value}
                                                        onChange={(e: any) =>
                                                            this.onChange(e, 'internetProvider', index)
                                                        }
                                                        placeholder={
                                                            form.internetProvider && form.internetProvider.label
                                                                ? form.internetProvider.label
                                                                : 'Select internet provider'
                                                        }
                                                        options={this.state.internetProviderOptions}
                                                        className="form_fix_width form_fix_widthhaveimgoptions"
                                                    />
                                                    <div className="error">
                                                        {
                                                            this.state.continueClicked &&
                                                                form.internetProvider == null
                                                                ? 'Enter insurance Provider'
                                                                : ''}
                                                    </div>
                                                </div>
                                                <div className="form_group">
                                                    <InputLabel
                                                        htmlFor="bootstrap-input"
                                                        className={'text-black ml-2 mb-2'}
                                                        style={{ fontFamily: 'Poppins', color: '#162855' }}
                                                    >
                                                        What services do you have with them?
                                                    </InputLabel>
                                                    <div className="inputFlexDiv1 internerserviceoptions">
                                                        <div
                                                            className="internetServiceCardOuterDiv"
                                                            style={{
                                                                border: form.isInternet
                                                                    ? '1px solid #273E59'
                                                                    : '1px solid #BEBEBE',
                                                                background: form.isInternet ? '#273E59' : '#ffffff',
                                                                color: form.isInternet ? '#ffffff' : '#333333',
                                                            }}
                                                        >
                                                            <div
                                                                className="internetServiceCard"
                                                                onClick={() => {
                                                                    this.internetService('isInternet', index);
                                                                }}
                                                            >
                                                                <WifiIcon className="internetIcon" />
                                                                Internet
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="internetServiceCardOuterDiv"
                                                            style={{
                                                                border: form.isTV
                                                                    ? '1px solid #273E59'
                                                                    : '1px solid #BEBEBE',
                                                                background: form.isTV ? '#273E59' : '#ffffff',
                                                                color: form.isTV ? '#ffffff' : '#333333',
                                                            }}
                                                        >
                                                            <div
                                                                className="internetServiceCard"
                                                                onClick={() => {
                                                                    this.internetService('isTV', index);
                                                                }}
                                                            >
                                                                <LaptopWindowsIcon className="internetIcon" />
                                                                TV
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="internetServiceCardOuterDiv"
                                                            style={{
                                                                border: form.isPhone
                                                                    ? '1px solid #273E59'
                                                                    : '1px solid #BEBEBE',
                                                                background: form.isPhone ? '#273E59' : '#ffffff',
                                                                color: form.isPhone ? '#ffffff' : '#333333',
                                                            }}
                                                        >
                                                            <div
                                                                className="internetServiceCard"
                                                                onClick={() => {
                                                                    this.internetService('isPhone', index);
                                                                }}
                                                            >
                                                                <PhoneInTalkIcon className="internetIcon" />
                                                                Phone
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="error">
                                                        {
                                                            this.state.continueClicked &&
                                                                !form.isInternet &&
                                                                !form.isTV &&
                                                                !form.isPhone
                                                                ? 'Select Internet Service'
                                                                : ''}
                                                    </div>
                                                </div>
                                                <div className="form_group">
                                                    <div className="inputFlexDiv2 edit_paying_input">
                                                        <div className="inputDiv1 form_fix_widthmonth">
                                                            <CustomizedInput
                                                                labelName="How much are you paying?"
                                                                value={form.internetAmount}
                                                                onChange={(e: any) => this.onChange(e, 'internetAmount', index)}
                                                                placeholder=""
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                }}
                                                                className="form_fix_width "
                                                            />
                                                            <div className="inputsupportText form_fix_widthmonthtext">/Month</div>
                                                            <div className="error">
                                                                {
                                                                    this.state.continueClicked &&
                                                                        !form.internetAmount
                                                                        ? 'Enter Amount'
                                                                        : this.state.continueClicked &&
                                                                            form.internetAmount &&
                                                                            parseInt(form.internetAmount) <= 0
                                                                            ? 'Amount must be greater than 0'
                                                                            : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_group">
                                                    <div className="inputFlexDiv2">
                                                        <div className="inputDiv1">
                                                            <CustomizedInput
                                                                labelName="Your Account Number"
                                                                value={form.internetAccountNumber}
                                                                onChange={(e: any) => this.onChange(e, 'internetAccountNumber', index)}
                                                                placeholder=""
                                                                className="form_fix_width"
                                                            />
                                                            <div className="error">
                                                                {
                                                                    this.state.continueClicked &&
                                                                        !form.internetAccountNumber
                                                                        ? 'Enter Account Number'
                                                                        : this.state.continueClicked &&
                                                                            form.internetAccountNumber.length < 4
                                                                            ? 'Account number should be atleast 4 digits'
                                                                            : this.state.continueClicked &&
                                                                                form.internetAccountNumber &&
                                                                                parseInt(form.internetAccountNumber) <= 0
                                                                                ? 'invalid account number'
                                                                                : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="form_group mb_0">
                                                    <label>Service Start Date</label>
                                                </div>
                                               <div className="form_row">
                                             <div className="col50">
                                                 <label className="smalllabel">Select month</label>
                                                 <select className="form_control">
                                                     <option>June</option>
                                                     <option>July</option>
                                                     <option>May</option>
                                                 </select>
                                             </div>
                                             <div className="col50">
                                                 <label className="smalllabel">Select year</label>
                                                 <select className="form_control">
                                                     <option>2021</option>
                                                     <option>2022</option>
                                                     <option>2023</option>
                                                 </select>
                                             </div>
                                         </div> */}
                                            </>
                                        )
                                    }
                                    )}
                                    {this.isShowAddAnother() &&
                                        <>
                                            <div className="imnotsure sectiontitle" onClick={this.addAnotherInternetForm}>
                                                Add Another Provider
                                            </div>
                                        </>
                                        }
                                        {this.state.isAddAnotherProviderClicked &&
                                            <>
                                                <hr className="form_sepertor" />
                                                <div className="text_right">
                                                    <a className="theme_btn small" onClick={() => this.onCreateSubscription()}>Create</a>
                                                </div>
                                            </>}
                                       
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_btns">
                                <button className="theme_btn" onClick={() => {
                                    History.push('/dashboard/internet/summary');
                                }}>Cancel</button>
                                <button className="theme_btn" disabled={this.state.isAddAnotherProviderClicked} type="button" onClick={() => { this.onEditConfirm() }}>Confirm Details</button>
                            </div>
                        </form>
                    </div>

                </>
                <Footer />
            </div >
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
    serviceState: state.service,
    homeOwnerDashboardState: state.homeOwnerdashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);
