import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../atoms/Button';
import './dashboardCss/bestDeals.scss';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

interface cardProps {
    onClick?: any;
    className?: string;
    cardTitle?: string;
    cardSubtitle?: string;
    cardImage1?: string;
    cardImage2?: string;
    img1ClassName?: string;
    img2ClassName?: string;
    buttonTitle?: string;
    boxShadow?: any;
    onButtonClick?: any;
}

const BestDealsCard = (props: cardProps) => {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    const useStyles = makeStyles(() => ({
        root: {
            '&:hover': {
                boxShadow: '0 0 10px #e4e6e8',
            },
        },
    }));
    let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
    const classes = useStyles();
    return (
        <div
            style={{
                opacity: '1',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} bestDealsCard`}
        >
            <div className="leftImageDiv">
                <img
                    src={props.cardImage1}
                    style={{
                        marginRight: 'auto',
                    }}
                    alt=""
                    className={props.img1ClassName ? props.img1ClassName : 'leftImage'}
                />
                <LocalOfferIcon className="leftImageMobile" style={{ color: '#006DFF' }} />
            </div>

            <div className="contentDiv">
                <div className="bestDealsCardTitle">{props.cardTitle}</div>
                <p className="bestDealsCardPara">{props.cardSubtitle}</p>
                <Button
                    className="bestDealsButton"
                    backgroundColor="#FFFFFF"
                    boxShadow={
                        isDouglasElliman
                            ? '#100B28'
                            : slug && slug.primary_color
                            ? `0 0 3px 0 ${slug && slug.primary_color}`
                            : '0 0 3px 0 #273E59'
                    }
                    color={
                        isDouglasElliman
                            ? '#100B28'
                            : slug && slug.primary_color
                            ? `${slug && slug.primary_color}`
                            : '#273E59'
                    }
                    onClick={props.onButtonClick}
                >
                    {props.buttonTitle}
                </Button>
            </div>

            <div className="rigthImageDiv">
                <img
                    src={props.cardImage2}
                    style={{
                        marginRight: 'auto',
                    }}
                    alt=""
                    className={props.img2ClassName ? props.img2ClassName : 'rightImage'}
                />
            </div>
        </div>
    );
};

export default BestDealsCard;
