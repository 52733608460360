import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 30.415}
        height={props.height ? props.height : 31.118}
        viewBox="0 0 30.415 31.118"
    >
        <path
            data-name="Path 42871"
            d="m12.547 22.745-4.541-3.093-4.541 3.093v6.433h9.082Zm3.142.348a.741.741 0 0 1-.834 1.224l-.827-.563v6.165a.741.741 0 0 1-.741.741H2.725a.741.741 0 0 1-.741-.741v-6.165l-.826.563a.741.741 0 1 1-.834-1.224l1.984-1.352 5.282-3.6a.742.742 0 0 1 .834 0Zm14.5-17.6L19.368.079a.739.739 0 0 0-.662 0L7.89 5.49a.741.741 0 0 0-.409.663v9.887a2.813 2.813 0 0 1 .524-.056 2.76 2.76 0 0 1 .957.177V6.61l10.076-5.04 10.077 5.04v11.515c0 5.307-8.272 10.269-10.077 11.289a37.41 37.41 0 0 1-2.979-1.89v1.8c1.426.944 2.5 1.515 2.634 1.587a.739.739 0 0 0 .689 0c.458-.24 11.214-5.97 11.214-12.79V6.152a.741.741 0 0 0-.41-.663m-13.338 11.9-2.662-2.751a1.112 1.112 0 0 1 1.6-1.545l1.9 1.963 4.645-4.377a1.111 1.111 0 1 1 1.524 1.617l-5.443 5.129a1.111 1.111 0 0 1-1.56-.036"
            fill={window.location.pathname.includes('insurance') ? '#009CBD' : '#100b28'}
        />
    </svg>
);

export default SvgComponent;
