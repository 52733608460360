import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 36.72}
        height={props.height ? props.height : 35}
        viewBox="0 0 36.72 35"
    >
        <path
            data-name="Path 41971"
            d="m32.301 12.969 3.649 4.53-3.649 4.53.289 5.811-5.614 1.519-3.181 4.87-5.436-2.072-5.435 2.072-3.181-4.87-5.614-1.52.289-5.811-3.649-4.529 3.649-4.53-.289-5.81L9.744 5.64l3.18-4.871 5.436 2.072L23.795.769l3.181 4.871 5.615 1.519Zm-7.462 9.462a2.418 2.418 0 1 0-2.417 2.418 2.417 2.417 0 0 0 2.417-2.418Zm-8.316-9.671a2.418 2.418 0 1 0-2.417 2.418 2.417 2.417 0 0 0 2.417-2.418Zm0 0"
            fill="#fff"
        />
        <path
            data-name="Path 41972"
            d="M24.84 22.431a2.418 2.418 0 1 1-2.417-2.417 2.418 2.418 0 0 1 2.417 2.417Zm0 0"
            fill="#fff"
        />
        <path
            data-name="Path 41973"
            d="M16.523 12.761a2.418 2.418 0 1 1-2.417-2.417 2.418 2.418 0 0 1 2.417 2.417Zm0 0"
            fill="#fff"
        />
        <g data-name="Group 34494" fill={window.location.pathname.includes('deals') ? '#009CBD' : '#100b28'}>
            <path
                data-name="Path 41974"
                d="m3.635 22.284-.275 5.518a.77.77 0 0 0 .568.781l5.332 1.443 3.02 4.625a.77.77 0 0 0 .919.3l5.161-1.967 5.162 1.967a.77.77 0 0 0 .918-.3l3.02-4.625 5.332-1.443a.769.769 0 0 0 .568-.781l-.275-5.518 3.465-4.3a.77.77 0 0 0 0-.966l-3.465-4.3.275-5.516a.77.77 0 0 0-.568-.781L27.46 4.974 24.44.349a.77.77 0 0 0-.919-.3L18.36 2.017 13.199.051a.77.77 0 0 0-.918.3L9.26 4.973 3.928 6.416a.77.77 0 0 0-.568.781l.275 5.517-3.465 4.3a.769.769 0 0 0 0 .965Zm1.382-8.831a.769.769 0 0 0 .17-.521L4.929 7.74l5.016-1.357a.771.771 0 0 0 .444-.322L13.23 1.71l4.856 1.851a.768.768 0 0 0 .548 0L23.49 1.71l2.841 4.351a.771.771 0 0 0 .444.322l5.016 1.358-.259 5.191a.771.771 0 0 0 .17.521l3.261 4.049-3.264 4.044a.771.771 0 0 0-.17.521l.259 5.191-5.016 1.358a.771.771 0 0 0-.444.322l-2.841 4.351-4.856-1.851a.768.768 0 0 0-.548 0l-4.856 1.851-2.838-4.351a.771.771 0 0 0-.444-.322l-5.016-1.357.259-5.191a.769.769 0 0 0-.17-.521l-3.26-4.045Zm0 0"
            />
            <path
                data-name="Path 41975"
                d="M14.11 15.949a3.187 3.187 0 1 0-3.187-3.187 3.187 3.187 0 0 0 3.187 3.187Zm0-4.835a1.648 1.648 0 1 1-1.648 1.648 1.648 1.648 0 0 1 1.648-1.649Zm0 0"
            />
            <path
                data-name="Path 41976"
                d="M19.234 22.432a3.187 3.187 0 1 0 3.187-3.187 3.187 3.187 0 0 0-3.187 3.187Zm4.835 0a1.648 1.648 0 1 1-1.648-1.648 1.648 1.648 0 0 1 1.649 1.648Zm0 0"
            />
            <path
                data-name="Path 41977"
                d="M12.501 26.485a.769.769 0 0 0 1.065-.224l10.88-16.682a.77.77 0 0 0-1.289-.841l-10.88 16.68a.77.77 0 0 0 .224 1.067Zm0 0"
            />
        </g>
    </svg>
);

export default SvgComponent;
