import React from 'react';
import { makeStyles } from '@material-ui/core';
// import Button from '../atoms/Button';
import './selectedVendor.scss';
import deleteIcon from '../../Assets/images/TrustedPros/delete-24px.svg';
import TickIcon from '../../Assets/images/TrustedPros/greenTick.svg';

interface cardProps {
    onClick?: any;
    className?: string;
    name?: string;
    image?: string;
    id?: string;
    boxShadow?: any;
    fromOrderConfirm?: boolean;
    type?: string;
    deleteHandler ? : any
}

const SlectedVendor = (props: cardProps) => {
    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #E0E0E0',
            justifyContent: 'space-between',
            padding: 'auto',
            // '&:hover': {
            //     boxShadow: '0 0 10px #9C9DA1',
            // },
        },
    }));

    const classes = useStyles();
    return (
        <div
            style={{
                opacity: '1',
                cursor: 'pointer',
            }}
            onClick={props.onClick}
            className={props.className ? `${props.className} ${classes.root}` : `${classes.root} selectedVendorCard`}
        >
            <div className="vendorImage">{props.image}</div>
            {!props.fromOrderConfirm ? (
              <div className="vendorNameDeleteButton">
              <div className="vendorName">{props.name}</div>
              <div className="vendorDeleteButton" onClick={props.deleteHandler}>
                  <img src={deleteIcon} alt="" />
              </div>
          </div>
            ) : (
              <div className="vendorNameDeleteButton">
                <div style={{textAlign: "left"}}>
                <div className="vendorTypefromOrderConfirm">{props.type}</div>
                <div className="vendorNamefromOrderConfirm">{props.name}</div>
                </div>
                <div className="vendorDeleteButton">
                    <img src={TickIcon} alt="" />
                </div>
            </div>
            )}
        </div>
    );
};

export default SlectedVendor;
