import React, { Component } from 'react';
import './homeSecurity.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommonAction from '../../store/common/actions';
// import AutoComplete from '../../components/atoms/autoComplete';
import { geocodeByAddress } from 'react-places-autocomplete';
// import Loc from '@material-ui/icons/Room';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/atoms/Button';
import Linear from '../../components/atoms/linearProgress';
import CheckboxCard from '../../components/atoms/CheckboxCards';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Footer from './Footer';
import RemoteControle from '../../Assets/images/HomeSecurity/remoteControl';
import Repair from '../../Assets/images/HomeSecurity/repair';
import Professional from '../../Assets/images/HomeSecurity/professionalInstall';
import HomeSecurity from '../../Assets/images/HomeSecurity/homeSecurity';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import BreadCrumb from '../../components/common/breadCrumb/BreadCrumb';

type initialProps = { commonAction?: any; commonState?: any; history?: any };
let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
type initialState = {
    activeStep: number;
    slug: any;
    street: string;
    city: string;
    state: any;
    zipcode: string;
    address: string | null | undefined;
    moveId: any;
    step1: {
        // breakIns: boolean;
        // preventingFire: boolean;
        // burstPipes: boolean;
        // waterLeaks: boolean;
        // packageTheft: boolean;
        // medicalDispatch: boolean;
        // videoFootage: boolean;
        homeSecurity: boolean;
        smartAutomation: boolean;
    };
    step2: {
        securityInstallation: string;
    };
    // step3: {
    //     burglarySensors: boolean;
    //     hazardSensors: boolean;
    //     cameraSystem: boolean;
    //     monitoring: boolean;
    //     smartLocks: boolean;
    // };
};

class Steps extends Component<initialProps, initialState> {
    state: initialState = {
        address: null,
        street: '',
        city: '',
        state: null,
        zipcode: '',
        moveId: null,
        activeStep: 0,
        slug: { primary_color: '' },
        step1: {
            // breakIns: false,
            // preventingFire: false,
            // burstPipes: false,
            // waterLeaks: false,
            // packageTheft: false,
            // medicalDispatch: false,
            // videoFootage: false,
            homeSecurity: true,
            smartAutomation: true,
        },
        step2: {
            securityInstallation: 'professional',
        },
        // step3: {
        //     burglarySensors: false,
        //     hazardSensors: false,
        //     cameraSystem: false,
        //     monitoring: false,
        //     smartLocks: false,
        // },
    };

    componentDidMount() {
        this.props.commonAction.moveGet();
        if (
            this.props.commonState &&
            this.props.commonState.move &&
            this.props.commonState.move.length > 0 &&
            this.props.commonState.move[0]
        ) {
            const data =
                this.props.commonState &&
                this.props.commonState.move &&
                this.props.commonState.move.length > 0 &&
                this.props.commonState.move[0];
            this.setState({
                address: data && data.destination_full_address,
                moveId: data && data.id,
            });
        } else {
            this.props.commonAction.moveGet();
        }
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        this.setState({ slug: slug });

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.fromLanding &&
            this.props.history.location.state.fromLanding == 'fromLanding' &&
            this.props.history.location.state.data
        ) {
            const data = this.props.history.location.state.data;
            const dest: any = data && data.destination;
            this.setState({
                city: dest && dest.city_name,
                state: { name: dest && dest.state_code, label: dest && dest.state_code },
                zipcode: dest && dest.zip_code,
                street: data && data.destination_street,
                address: data && data.destination_full_address,
                moveId: data && data.id,
            });
        }
    }
    componentDidUpdate(prevProps: any) {
        const prev = prevProps && prevProps.commonState;
        const cur = this.props && this.props.commonState;
        if (prev.move !== cur.move && cur.move && cur.move[0]) {
            this.setState({
                address: cur.move[0] && cur.move[0].destination_full_address,
                moveId: cur.move[0] && cur.move[0].id,
            });
        }
        if (prev.moveAddress !== cur.moveAddress && cur.moveAddress) {
            this.props.commonAction.moveGet();
        }
    }
    handleSelect = (address: any) => {
        let add: string;
        geocodeByAddress(address)
            .then(results => {
                // this.setState({ address: results[0].formatted_address });
                let city: any = '',
                    state: any = '',
                    zip: any = '',
                    country: any = '',
                    route: any = '',
                    streetNumber: any = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map(el => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes('locality')
                            ? el.long_name
                            : el.types.includes('sublocality')
                            ? el.long_name
                            : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.short_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                    });
                add = results[0].formatted_address;
                if (country === 'USA' || country === 'US') {
                    this.setState({
                        address: add,
                    });
                }
                // "destination_detail": "490 Geary Street, San Francisco, CA, 94102"
                // "414 East Erie Street, Chicago, IL 60611"
                if (
                    (country === 'USA' || country === 'US') &&
                    // state &&
                    // city &&
                    // zip &&
                    // streetNumber &&
                    // route &&
                    this.state.moveId
                ) {
                    this.props.commonAction.moveAddressUpdate({
                        destination_detail: `${streetNumber} ${route}, ${city}, ${state}, ${zip}`,
                        move_id: this.state.moveId,
                    });
                }
            })
            .catch(error => console.error('error', error));
    };
    getSteps = () => {
        return ['', ''];
    };
    setCheckboxValStep1 = async (e: any) => {
        const step1 = { ...this.state.step1, [e.target.name]: e.target.checked };
        await this.setState(() => ({ step1 }));
    };
    handleChange1 = async (e: any, e1: any) => {
        const step1 = { ...this.state.step1, [e]: !e1 };
        await this.setState(() => ({ step1 }));
    };
    // setCheckboxValStep3 = async (e: any) => {
    //     const step3 = { ...this.state.step3, [e.target.name]: e.target.checked };
    //     await this.setState(() => ({ step3 }));
    // };
    // handleChange3 = async (e: any, e1: any) => {
    //     const step3 = { ...this.state.step3, [e]: !e1 };
    //     await this.setState(() => ({ step3 }));
    // };
    step2Handler = async (e: any, e1: any) => {
        // let step2 = {
        //     no: false,
        //     yes: false,
        // };
        const step2 = { ...this.state.step2, [e]: !e1 };

        await this.setState(() => ({ step2 }));
    };
    step2Handler1 = async (e: any) => {
        this.setState({
            step2: {
                securityInstallation: e.target.value,
            },
        });
    };

    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <div>
                        <div className="headinghs">What are you looking for in your Smart Home Security System? </div>
                        <div className="para1hs">Select All That Apply</div>
                        <div className="hscheckboxCards">
                            <CheckboxCard
                                className="hsindividualCard"
                                checked={this.state.step1.homeSecurity}
                                handleChange={(e: any) => this.setCheckboxValStep1(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange1(e, e1)}
                                name="homeSecurity"
                                icon={
                                    <HomeSecurity
                                        checked={this.state.step1.homeSecurity}
                                        className="checkboxCardImage"
                                        fromlanding={
                                            this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.state &&
                                            this.props.history.location.state.fromLanding &&
                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? 1
                                                : 0
                                        }
                                    />
                                }
                                label={'24X7 Home Protection'}
                                iconColour={
                                    this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : this.props.history &&
                                          this.props.history.location &&
                                          this.props.history.location.state &&
                                          this.props.history.location.state.fromLanding &&
                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? '#30ab86'
                                        : '#273E59'
                                }
                            />
                            <CheckboxCard
                                className="hsindividualCard"
                                checked={this.state.step1.smartAutomation}
                                handleChange={(e: any) => this.setCheckboxValStep1(e)}
                                handleChange1={(e: any, e1: any) => this.handleChange1(e, e1)}
                                name="smartAutomation"
                                icon={
                                    <RemoteControle
                                        checked={this.state.step1.smartAutomation}
                                        className="checkboxCardImage"
                                        fromlanding={
                                            this.props.history &&
                                            this.props.history.location &&
                                            this.props.history.location.state &&
                                            this.props.history.location.state.fromLanding &&
                                            this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? 1
                                                : 0
                                        }
                                    />
                                }
                                label={'Smart Home Automation'}
                                iconColour={
                                    this.state.slug && this.state.slug.primary_color
                                        ? this.state.slug.primary_color
                                        : this.props.history &&
                                          this.props.history.location &&
                                          this.props.history.location.state &&
                                          this.props.history.location.state.fromLanding &&
                                          this.props.history.location.state.fromLanding == 'fromLanding'
                                        ? '#30ab86'
                                        : '#273E59'
                                }
                            />
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div className="headinghs">Select how you would like your system installed </div>
                        <div className="para1hs">Installation type for your home</div>
                        <RadioGroup
                            aria-label="homeAutomation"
                            name="homeAutomation"
                            value={this.state.step2.securityInstallation}
                            onChange={this.step2Handler1}
                        >
                            <div className="RadioStep2">
                                <div
                                    style={{
                                        border: `${
                                            this.state.slug &&
                                            this.state.slug.primary_color &&
                                            this.state.step2.securityInstallation === 'professional'
                                                ? `1px solid ${this.state.slug.primary_color}`
                                                : this.props.history &&
                                                  this.props.history.location &&
                                                  this.props.history.location.state &&
                                                  this.props.history.location.state.fromLanding &&
                                                  this.props.history.location.state.fromLanding == 'fromLanding' &&
                                                  this.state.step2.securityInstallation === 'professional'
                                                ? '2px solid #30ab86'
                                                : this.state.step2.securityInstallation === 'professional'
                                                ? '2px solid #273E59'
                                                : '1px solid #333'
                                        }`,
                                    }}
                                    className="radioCardDiv"
                                >
                                    <FormControlLabel
                                        className="innerStep2Radio1"
                                        // style={{
                                        //     border: `${
                                        //         this.state.slug &&
                                        //         this.state.slug.primary_color &&
                                        //         this.state.step2.securityInstallation === 'professional'
                                        //             ? `1px solid ${this.state.slug.primary_color}`
                                        //             : '1px solid #bebebe'
                                        //     }`,
                                        // }}
                                        value="professional"
                                        control={
                                            <Radio
                                                style={{
                                                    color: `${
                                                        this.state.slug &&
                                                        this.state.slug.primary_color &&
                                                        this.state.step2.securityInstallation === 'professional'
                                                            ? this.state.slug.primary_color
                                                            : this.props.history &&
                                                              this.props.history.location &&
                                                              this.props.history.location.state &&
                                                              this.props.history.location.state.fromLanding &&
                                                              this.props.history.location.state.fromLanding ==
                                                                  'fromLanding' &&
                                                              this.state.step2.securityInstallation === 'professional'
                                                            ? '#30ab86'
                                                            : this.state.step2.securityInstallation === 'professional'
                                                            ? '#273E59'
                                                            : '#CACACA'
                                                    }`,
                                                }}
                                            />
                                        }
                                        label=""
                                    />
                                    <div className="label-component">
                                        <div className="label-icon">
                                            <Professional
                                                checked={
                                                    this.state.step2.securityInstallation === 'professional'
                                                        ? true
                                                        : false
                                                }
                                                className="checkboxCardImage"
                                                fromlanding={
                                                    this.props.history &&
                                                    this.props.history.location &&
                                                    this.props.history.location.state &&
                                                    this.props.history.location.state.fromLanding &&
                                                    this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? 1
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                color: `${
                                                    this.state.slug &&
                                                    this.state.slug.primary_color &&
                                                    this.state.step2.securityInstallation === 'professional'
                                                        ? `${this.state.slug.primary_color}`
                                                        : this.props.history &&
                                                          this.props.history.location &&
                                                          this.props.history.location.state &&
                                                          this.props.history.location.state.fromLanding &&
                                                          this.props.history.location.state.fromLanding ==
                                                              'fromLanding' &&
                                                          this.state.step2.securityInstallation === 'professional'
                                                        ? '#30ab86'
                                                        : this.state.step2.securityInstallation === 'professional'
                                                        ? '#273E59'
                                                        : '#bebebe'
                                                }`,
                                            }}
                                            className="label-text"
                                        >
                                            Professional Install
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        border: `${
                                            this.state.slug &&
                                            this.state.slug.primary_color &&
                                            this.state.step2.securityInstallation === 'self'
                                                ? `1px solid ${this.state.slug.primary_color}`
                                                : this.props.history &&
                                                  this.props.history.location &&
                                                  this.props.history.location.state &&
                                                  this.props.history.location.state.fromLanding &&
                                                  this.props.history.location.state.fromLanding == 'fromLanding' &&
                                                  this.state.step2.securityInstallation === 'self'
                                                ? '2px solid #30ab86'
                                                : this.state.step2.securityInstallation === 'self'
                                                ? '2px solid #273E59'
                                                : '1px solid #333'
                                        }`,
                                    }}
                                    className="radioCardDiv"
                                >
                                    <FormControlLabel
                                        className="innerStep2Radio"
                                        // style={{
                                        //     border: `${
                                        //         this.state.slug &&
                                        //         this.state.slug.primary_color &&
                                        //         this.state.step2.securityInstallation === 'self'
                                        //             ? `1px solid ${this.state.slug.primary_color}`
                                        //             : '1px solid #bebebe'
                                        //     }`,
                                        // }}
                                        value="self"
                                        control={
                                            <Radio
                                                style={{
                                                    color: `${
                                                        this.state.slug &&
                                                        this.state.slug.primary_color &&
                                                        this.state.step2.securityInstallation === 'self'
                                                            ? this.state.slug.primary_color
                                                            : this.props.history &&
                                                              this.props.history.location &&
                                                              this.props.history.location.state &&
                                                              this.props.history.location.state.fromLanding &&
                                                              this.props.history.location.state.fromLanding ==
                                                                  'fromLanding' &&
                                                              this.state.step2.securityInstallation === 'self'
                                                            ? '#30ab86'
                                                            : this.state.step2.securityInstallation === 'self'
                                                            ? '#273E59'
                                                            : '#CACACA'
                                                    }`,
                                                }}
                                            />
                                        }
                                        // labelPlacement="bottom"
                                        label=""
                                        // label={
                                        //     <div className="label-component">
                                        //         <div className="label-icon">
                                        //             <Repair checked={this.state.step2.securityInstallation === "self"} />
                                        //         </div>
                                        //         <div className="label-text">Self Install (DIY)</div>
                                        //     </div>
                                        // }
                                    />
                                    <div className="label-component">
                                        <div className="label-icon">
                                            <Repair
                                                checked={
                                                    this.state.step2.securityInstallation === 'self' ? true : false
                                                }
                                                className="checkboxCardImage"
                                                fromlanding={
                                                    this.props.history &&
                                                    this.props.history.location &&
                                                    this.props.history.location.state &&
                                                    this.props.history.location.state.fromLanding &&
                                                    this.props.history.location.state.fromLanding == 'fromLanding'
                                                        ? 1
                                                        : 0
                                                }
                                            />
                                        </div>
                                        <div
                                            className="label-text"
                                            style={{
                                                color: `${
                                                    this.state.slug &&
                                                    this.state.slug.primary_color &&
                                                    this.state.step2.securityInstallation === 'self'
                                                        ? `${this.state.slug.primary_color}`
                                                        : this.props.history &&
                                                          this.props.history.location &&
                                                          this.props.history.location.state &&
                                                          this.props.history.location.state.fromLanding &&
                                                          this.props.history.location.state.fromLanding ==
                                                              'fromLanding' &&
                                                          this.state.step2.securityInstallation === 'self'
                                                        ? '#30ab86'
                                                        : this.state.step2.securityInstallation === 'self'
                                                        ? '#273E59'
                                                        : '#bebebe'
                                                }`,
                                            }}
                                        >
                                            Self Install (DIY)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                );
            // case 2:
            //     return (
            //         <div>
            //             <div className="headinghs">What smart home automation systems are important for you? </div>
            //             <div className="para1hs">Select all that apply</div>
            //             <div className="hscheckboxCards">
            //                 <CheckboxCard
            //                     className="hsindividualCard"
            //                     checked={this.state.step3.burglarySensors}
            //                     handleChange={(e: any) => this.setCheckboxValStep3(e)}
            //                     handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
            //                     name="burglarySensors"
            //                     icon={<Bsensors checked={this.state.step3.burglarySensors} />}
            //                     label={'Burglary sensors'}
            //                     iconColour={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                 />
            //                 <CheckboxCard
            //                     className="hsindividualCard"
            //                     checked={this.state.step3.hazardSensors}
            //                     handleChange={(e: any) => this.setCheckboxValStep3(e)}
            //                     handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
            //                     name="hazardSensors"
            //                     icon={<Hsensors checked={this.state.step3.hazardSensors} />}
            //                     label={'Burglary sensors'}
            //                     iconColour={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                 />
            //                 <CheckboxCard
            //                     className="hsindividualCard"
            //                     checked={this.state.step3.cameraSystem}
            //                     handleChange={(e: any) => this.setCheckboxValStep3(e)}
            //                     handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
            //                     name="cameraSystem"
            //                     icon={<Camera checked={this.state.step3.cameraSystem} />}
            //                     label={'Camera system'}
            //                     iconColour={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                 />
            //                 <CheckboxCard
            //                     className="hsindividualCard"
            //                     checked={this.state.step3.monitoring}
            //                     handleChange={(e: any) => this.setCheckboxValStep3(e)}
            //                     handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
            //                     name="monitoring"
            //                     icon={<Monitoring checked={this.state.step3.monitoring} />}
            //                     label={'24/7 monitoring'}
            //                     iconColour={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                 />
            //                 <CheckboxCard
            //                     className="hsindividualCard"
            //                     checked={this.state.step3.smartLocks}
            //                     handleChange={(e: any) => this.setCheckboxValStep3(e)}
            //                     handleChange1={(e: any, e1: any) => this.handleChange3(e, e1)}
            //                     name="smartLocks"
            //                     icon={<Locks checked={this.state.step3.smartLocks} />}
            //                     label={'Smart locks'}
            //                     iconColour={
            //                         this.state.slug && this.state.slug.primary_color
            //                             ? this.state.slug.primary_color
            //                             : '#273E59'
            //                     }
            //                 />
            //             </div>
            //         </div>
            //     );
            default:
                return 'step not found';
        }
    };

    handleNext = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1,
        });

        if (this.state.activeStep == 2) {
            let securityType: any = [];
            if (this.state.step1.homeSecurity && this.state.step1.smartAutomation) {
                securityType = ['homeSecurity', 'smartAutomation'];
            } else if (this.state.step1.homeSecurity && !this.state.step1.smartAutomation) {
                securityType = ['homeSecurity'];
            } else if (!this.state.step1.homeSecurity && this.state.step1.smartAutomation) {
                securityType = ['smartAutomation'];
            }
            if (
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding'
                // && this.props.history.location.state.data
            ) {
                this.props.history.push({
                    pathname: '/landing/home-security/recommendations',
                    state: {
                        securityType: securityType,
                        securityInstallation: this.state.step2.securityInstallation,
                        fromLanding: 'fromLanding',
                        data: this.props.history.location.state.data,
                    },
                });
            } else {
                this.props.history.push({
                    pathname: '/dashboard/home-security/recommendations',
                    state: {
                        securityType: securityType,
                        securityInstallation: this.state.step2.securityInstallation,
                    },
                });
            }
        }
        // if (this.state.step2.homeAutomation === 'yes' && this.state.activeStep === 3) {
        //     this.props.history.push('/dashboard/home-security/recommendations');
        // }
        window.scrollTo(0, 0);
    };

    handleBack = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1,
        });
        window.scrollTo(0, 0);
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const steps = this.getSteps();
        const breadList = [
            {
                name: 'Home Security',
                url: ''
            }
        ]
        return (
            <div>
                {this.props.history &&
                this.props.history.location &&
                this.props.history.location.state &&
                this.props.history.location.state.fromLanding &&
                this.props.history.location.state.fromLanding == 'fromLanding' ? (
                    <NavbarLanding />
                ) : null}
                <div className="hsPage">
                    <div className="hs-main-head">
                        <div>
                            <h2 className="hs-heading">Home Security</h2>
                            <div className='mb-4'>
                                <BreadCrumb breadList={breadList} homeCrumb={this.props.history.location?.state?.fromLanding == 'fromLanding' ? 'Home' : 'Dashboard'} />
                            </div>
                        </div>
                        {/* <div className="hs-address">
                        <div className="maintext">Your address</div>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Loc className="locationIcon" />
                            <AutoComplete
                                value={this.state.address ? this.state.address : ''}
                                onChange={e => {
                                    this.setState({ address: e });
                                }}
                                onSelect={this.handleSelect}
                                placeholder="Location"
                                // inputTitle="To"
                                edit={true}
                                disabled={this.props.history &&
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.fromLanding &&
                                    this.props.history.location.state.fromLanding == 'fromLanding' && this.props.history.location.state.data}
                            />
                        </div>
                    </div> */}
                    </div>
                    <div className="hs-steps">
                        <div>
                            <div>{this.getStepContent(this.state.activeStep)}</div>
                            <div className="Para11">
                                STEP {this.state.activeStep + 1} OF 2
                                {/* {this.state.step2.homeAutomation === 'yes' ? '3' : '2'} */}
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                {/* <LinearProgress
                                                className="linearProgressInternet"
                                                variant="determinate"
                                                value={((this.state.activeStep + 1) / 5) * 100}
                                                style={{ backgroundColor: '#fff', borderRadius: '15px', height: '3px' }}
                                            /> */}
                                <Linear
                                    fromLanding={
                                        this.props.history &&
                                        this.props.history.location &&
                                        this.props.history.location.state &&
                                        this.props.history.location.state.fromLanding &&
                                        this.props.history.location.state.fromLanding == 'fromLanding'
                                            ? true
                                            : false
                                    }
                                    value={((this.state.activeStep + 1) / 2) * 100}
                                />
                            </div>
                            <Stepper activeStep={this.state.activeStep} className="hs-Stepper">
                                {steps.map((label: any) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: { optional?: React.ReactNode } = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}></StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className="hs-stepsbuttons">
                                <div className="hsButtonDiv">
                                    {this.state.activeStep === 0 ? null : (
                                        <Button
                                            className="btnIC"
                                            disabled={this.state.activeStep === 0}
                                            onClick={this.handleBack}
                                            backgroundColor="#FFFFFF"
                                            boxShadow={`0 0 3px 0 ${
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : this.props.history &&
                                                      this.props.history.location &&
                                                      this.props.history.location.state &&
                                                      this.props.history.location.state.fromLanding &&
                                                      this.props.history.location.state.fromLanding == 'fromLanding'
                                                    ? '#30ab86'
                                                    : '#273E59'
                                            }`}
                                            color={
                                                isDouglasElliman
                                                    ? '#100B28'
                                                    : this.state.slug && this.state.slug.primary_color
                                                    ? this.state.slug.primary_color
                                                    : this.props.history &&
                                                      this.props.history.location &&
                                                      this.props.history.location.state &&
                                                      this.props.history.location.state.fromLanding &&
                                                      this.props.history.location.state.fromLanding == 'fromLanding'
                                                    ? '#30ab86'
                                                    : '#273E59'
                                            }
                                            borderRadius="5px"
                                            margin={
                                                this.state.activeStep === steps.length - 1
                                                    ? '15px 15px 0 15px'
                                                    : '0 15px 0 0'
                                            }
                                            fontSize="16px"
                                            width="110px"
                                            height="50px"
                                        >
                                            Previous
                                        </Button>
                                    )}

                                    <Button
                                        backgroundColor={
                                            isDouglasElliman
                                                ? '#100B28'
                                                : this.state.slug && this.state.slug.primary_color
                                                ? this.state.slug.primary_color
                                                : this.props.history &&
                                                  this.props.history.location &&
                                                  this.props.history.location.state &&
                                                  this.props.history.location.state.fromLanding &&
                                                  this.props.history.location.state.fromLanding == 'fromLanding'
                                                ? '#30ab86'
                                                : '#273E59'
                                        }
                                        // color="#fff"
                                        width={
                                            this.state.activeStep === steps.length - 1 ||
                                            (this.state.step2.securityInstallation === 'professional' &&
                                                this.state.activeStep === 1)
                                                ? '210px'
                                                : '110px'
                                        }
                                        height="50px"
                                        margin={this.state.activeStep === steps.length - 1 ? '0 0 0 0' : '0'}
                                        onClick={this.handleNext}
                                    >
                                        {this.state.activeStep === steps.length - 1 ||
                                        (this.state.step2.securityInstallation === 'professional' &&
                                            this.state.activeStep === 1)
                                            ? 'See My Offers'
                                            : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer history={this.props.history} />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        commonAction: bindActionCreators(CommonAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    commonState: state.common,
});

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
