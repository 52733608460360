import React from 'react';
// import roof from '../../Assets/images/InsuranceImages/roof.svg';
// import stairs from '../../Assets/images/InsuranceImages/stepsb.svg';
// import pool from '../../Assets/images/InsuranceImages/poolb.svg';
// import location from '../../Assets/images/InsuranceImages/insuranceHomeLocation.svg';
// import home from '../../Assets/images/InsuranceImages/homefeetb.svg';
// import heater from '../../Assets/images/InsuranceImages/heaterb.svg';
// import built from '../../Assets/images/InsuranceImages/craneb.svg';
import Location from '../../Assets/images/InsuranceImages/insuranceHomeLocation';
import Home from '../../Assets/images/InsuranceImages/homefeetb';
import Built from '../../Assets/images/InsuranceImages/craneb';
import Heater from '../../Assets/images/InsuranceImages/heaterb';
import Roof from '../../Assets/images/InsuranceImages/roof';
import Pool from '../../Assets/images/InsuranceImages/poolb';
import Stairs from '../../Assets/images/InsuranceImages/stepsb';
import '../../styles/propertyDetailsCard.css';
import EditIcon from '@material-ui/icons/Edit';
import AnalyticsService from '../../pages/analytics-service';
// import history from '../../routes/History';

interface propertyDetailsCardProps {
    address?: string;
    sqFootage?: number;
    yearBuilt?: number;
    noOfStories?: string;
    roofType?: string;
    heater?: string;
    pool?: string;
    width?: string;
    history?: any;
    isDouglasElliman: boolean;
}

const PropertyDetailsCard = (props: propertyDetailsCardProps) => {

    const handleEditClick = () => {
        AnalyticsService.buttonClicked({
            page: 'Home Insurance',
            category_name: 'Home Insurance Plans',
            button_copy: 'Edit',
            card: true,
            card_title: 'Property Details',
        });

        window.location.pathname.includes('landing')
        ? props.history.push({
              pathname: '/landing/insurance/steps',
              state: {
                  edit: true,
                  fromLanding: 'fromLanding',
              },
          })
        : props.history.push({ pathname: '/dashboard/home-insurance/steps', state: { edit: true } })
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <div className="card-heading-insurance">Property Details</div>
            <div className="propertycard" style={{ width: props.width ? props.width : '98%' }}>
                <div
                    className={props.isDouglasElliman ? 'edit editDouglas' : 'edit'}
                    style={{ cursor: 'pointer' }}
                    onClick={handleEditClick}
                >
                    <EditIcon style={{ fontSize: '18px', marginRight: '5px' }} /> Edit
                </div>
                <div className="property-details-content">
                    <div className="vertical-centre">
                        {/* <img src={home} width="22px" height="29px" /> */}
                        <Location isdouglaselliman={props.isDouglasElliman ? 1 : 0} style={{ width: '25px', height: '29px' }} />
                    </div>
                    <div className="column">
                        <div className="text">Address</div>
                        <div className="value">{props.address}</div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="property-flex">
                    <div className="property-details-content col-md-auto">
                        <div className="vertical-centre">
                            {/* <img src={location} width="22px" height="29px" /> */}
                            <Home isdouglaselliman={props.isDouglasElliman ? 1 : 0} style={{ width: '25px', height: '29px' }} />
                        </div>
                        <div className="column">
                            <div className="text">Sq. Footage</div>
                            <div className="value">{props.sqFootage}</div>
                        </div>
                    </div>
                    <div className="property-details-content col-md-auto">
                        <div className="vertical-centre">
                            {/* <img src={built} width="22px" height="29px" /> */}
                            <Built
                                isdouglaselliman={props.isDouglasElliman ? 1 : 0}
                                style={{ width: '25px', height: '29px' }}
                            />
                        </div>
                        <div className="column">
                            <div className="text">Year Built</div>
                            <div className="value">{props.yearBuilt}</div>
                        </div>
                    </div>
                </div>

                <div className="divider"></div>
                <div className="property-details-content">
                    <div className="vertical-centre">
                        {/* <img src={stairs} width="22px" height="29px" /> */}
                        <Stairs isdouglaselliman={props.isDouglasElliman ? 1 : 0} style={{ width: '25px', height: '29px' }} />
                    </div>
                    <div className="column">
                        <div className="text">Number of stories</div>
                        <div className="value">{props.noOfStories}</div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="property-details-content">
                    <div className="vertical-centre">
                        {/* <img src={roof} width="22px" height="29px" /> */}
                        <Roof isdouglaselliman={props.isDouglasElliman ? 1 : 0} style={{ width: '25px', height: '29px' }} />
                    </div>
                    <div className="column">
                        <div className="text">Roof type</div>
                        <div className="value">{props.roofType}</div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="property-flex">
                    <div className="property-details-content col-md-auto">
                        <div className="vertical-centre">
                            {/* <img src={heater} width="22px" height="29px" /> */}
                            <Heater
                                isdouglaselliman={props.isDouglasElliman ? 1 : 0}
                                style={{ width: '25px', height: '29px' }}
                            />
                        </div>
                        <div className="column">
                            <div className="text">Heater</div>
                            <div className="value">{props.heater}</div>
                        </div>
                    </div>
                    <div className="property-details-content col-md-auto">
                        <div className="vertical-centre">
                            {/* <img src={pool} width="22px" height="29px" /> */}
                            <Pool isdouglaselliman={props.isDouglasElliman  ? 1 : 0} style={{ width: '25px', height: '29px' }} />
                        </div>
                        <div className="column">
                            <div className="text">Pool</div>
                            <div className="value poolValue">{props.pool}</div>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
        </div>
    );
};

export default PropertyDetailsCard;
