import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './HomeOwnerDashboardUtilityForm.scss';
import CustomizedInput from '../../components/atoms/CustomizedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectInput from '../../components/atoms/SelectInput';
// import { InputLabel, FormControlLabel, RadioGroup, FormControl, Radio } from '@material-ui/core';
import Button from '../../components/atoms/Button';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as DashboardAction from '../../store/homeOwnerDashboard/actions';
import * as ServiceActions from '../../store/savings/actions';
import * as Action from '../../store/nextMove/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import insuranceSubscriptionImage from '../../Assets/images/homeOwnerDashboard/insuranceSubscription.svg';
import warrantySubscriptionImage from '../../Assets/images/homeOwnerDashboard/warrantySubscription.svg';
import securitySubscriptionImage from '../../Assets/images/homeOwnerDashboard/securitySubscription.svg';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

type initialProps = {
    // closeModal?: any;
    homeProtectionServiceList?: any;
    moveId?: any;
    serviceAction?: any;
    serviceState?: any;
    dashboardAction: any;
    homeOwnerDashboardState: any;
    getSubscriptionDetails?: any;
    history?: any;
    nextMoveState?: any;
    nextMoveAction: any;
};
type initialState = {
    insuranceProvider: any;
    insuranceProviderOptions: any;
    insuranceDeductableAmount: string;
    insuranceOtherProvider: string;
    insuranceAmount: string;
    insuranceAccountNumber: string;
    warrantyAmount: string;
    warrantyProvider: any;
    warrantyOtherProvider: any;
    warrantyProviderOptions: any;
    warrantyAccountNumber: string;
    securityAmount: string;
    securityProvider: any;
    securityOtherProvider: any;
    securityProviderOptions: any;
    securityAccountNumber: string;
    activeStep: number;
    totalStep: number;
    size: number;
    moveId: string;
    showInsurance: boolean;
    showWarranty: boolean;
    showSecurity: boolean;
    continueClickedInsurance: boolean;
    continueClickedWarranty: boolean;
    continueClickedSecurity: boolean;
    isGetValueMapped: boolean;
    insuranceId: string;
    warrantyId: string;
    securityId: string;
    progressValue: number;
    insuranceClicked: boolean;
    warrantyClicked: boolean;
    securityClicked: boolean;
    isInsuranceOptionsMap: boolean;
};

class HomeOwnerDashboardProtectionForm extends React.Component<initialProps, initialState> {
    state: initialState = {
        warrantyAmount: '',
        warrantyProvider: null,
        warrantyOtherProvider: '',
        warrantyProviderOptions: [{ label: 'Other', value: 'Other' }],
        warrantyAccountNumber: '',
        securityAmount: '',
        securityProvider: null,
        securityOtherProvider: '',
        securityProviderOptions: [],
        securityAccountNumber: '',
        insuranceProvider: null,
        insuranceProviderOptions: [],
        insuranceDeductableAmount: '',
        insuranceOtherProvider: '',
        insuranceAmount: '',
        insuranceAccountNumber: '',
        activeStep: 1,
        totalStep: 3,
        size: 0,
        moveId: '',
        showInsurance: false,
        showWarranty: false,
        showSecurity: false,
        continueClickedInsurance: false,
        continueClickedWarranty: false,
        continueClickedSecurity: false,
        isGetValueMapped: false,
        insuranceId: '',
        warrantyId: '',
        securityId: '',
        progressValue: 0,
        insuranceClicked: false,
        warrantyClicked: false,
        securityClicked: false,
        isInsuranceOptionsMap: false,
    };
    componentDidMount() {
        this.setState({ size: window.screen.width });
        let showInsurance: boolean = false;
        let showWarranty: boolean = false;
        let showSecurity: boolean = false;
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeProtectionServiceList &&
            this.props.history.location.state.homeProtectionServiceList.map((item: any) => {
                if (item.slug === 'home-insurance' || item.slug === 'insurance') {
                    showInsurance = true;
                }
                if (item.slug === 'home-warranty' || item.slug === 'homewarranty') {
                    showWarranty = true;
                }
                if (item.slug === 'home-security') {
                    showSecurity = true;
                }
            });
        this.setState({
            showInsurance: showInsurance,
            showWarranty: showWarranty,
            showSecurity: showSecurity,
        });
        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.moveId
        ) {
            this.setState({ moveId: this.props.history.location.state.moveId });
            let payload2 = {
                moveId: this.props.history.location.state.moveId,
            };
            this.props.nextMoveAction.getNextMove(payload2);
        }
        if (showInsurance) {
            this.props.serviceAction.getInsuranceProvider();
        }
        if (showSecurity) {
            this.props.serviceAction.getServiceProvider();
        }
    }

    componentDidUpdate(prevProps: initialProps) {
        if (
            prevProps.serviceState.getInsuranceProvider !== this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.getInsuranceProvider &&
            this.props.serviceState.isGetInsuranceProvider
        ) {
            let insuranceProvider: any = [];

            this.props.serviceState.getInsuranceProvider.map((item: any) => {
                insuranceProvider.push({ label: item.name, value: item.name, logo:item.logo });
            });
            insuranceProvider.push({ label: 'Other', value: 'Other' });

            this.setState({
                insuranceProviderOptions: insuranceProvider,
                isInsuranceOptionsMap: true,
            });
        }
        if (
            prevProps.serviceState.getServiceProvider !== this.props.serviceState.getServiceProvider &&
            this.props.serviceState.getServiceProvider &&
            this.props.serviceState.isGetServiceProvider
        ) {
            let serviceProvider: any = [];

            this.props.serviceState.getServiceProvider.map((item: any) => {
                serviceProvider.push({ label: item.name, value: item.name, logo:item.logo });
            });
            serviceProvider.push({ label: 'Other', value: 'Other' });

            this.setState({
                securityProviderOptions: serviceProvider,
            });
        }

        if (
            prevProps.homeOwnerDashboardState.postSubscriptionDetails !=
                this.props.homeOwnerDashboardState.postSubscriptionDetails &&
            this.props.homeOwnerDashboardState.postSubscriptionDetails &&
            this.props.homeOwnerDashboardState.isPostSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.isPostSubscriptionDetails = false;
            this.setState({
                continueClickedInsurance: false,
                continueClickedWarranty: false,
                continueClickedSecurity: false,
                insuranceClicked: false,
                warrantyClicked: false,
                securityClicked: false,
            });
            // this.props.closeModal();
            let progressValue: number = 0;
            if (this.state.activeStep == 1) {
                if (
                    (this.state.showWarranty && this.state.showSecurity) ||
                    (this.state.showWarranty && !this.state.showSecurity)
                ) {
                    if (this.state.showSecurity) {
                        progressValue = (1 / 3) * 100;
                    } else {
                        progressValue = (1 / 2) * 100;
                    }
                    this.setState({ activeStep: 2 });
                } else if (!this.state.showWarranty && this.state.showSecurity) {
                    this.setState({ activeStep: 3 });
                    progressValue = (1 / 2) * 100;
                }
                if (!this.state.showWarranty && !this.state.showSecurity) {
                    this.props.dashboardAction.getSubscriptionDetails();
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 2) {
                if (this.state.showSecurity) {
                    this.setState({ activeStep: 3 });
                    progressValue = (2 / 2) * 100;
                }
                if (!this.state.showSecurity) {
                    this.props.dashboardAction.getSubscriptionDetails();
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 3) {
                this.props.dashboardAction.getSubscriptionDetails();
                this.props.history.push('/dashboardhomeowner');
            }
            this.setState({ progressValue: progressValue });
        } else if (
            prevProps.homeOwnerDashboardState.error != this.props.homeOwnerDashboardState.error &&
            this.props.homeOwnerDashboardState.error &&
            this.props.homeOwnerDashboardState.error != null &&
            this.props.homeOwnerDashboardState.error != ''
        ) {
            this.setState({ insuranceClicked: false, warrantyClicked: false, securityClicked: false });
        }

        if (
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.getSubscriptionDetails &&
            this.props.history.location.state.getSubscriptionDetails.length &&
            !this.state.isGetValueMapped &&
            this.state.isInsuranceOptionsMap
        ) {
            this.props.history.location.state.getSubscriptionDetails.map((item: any) => {
                if (item.service && (item.service.slug == 'home-insurance' || item.service.slug == 'insurance')) {
                    let options: any = this.state.insuranceProviderOptions;
                    let provider: any = null;
                    options.map((item1: any) => {
                        if (item1.value == item.provider) {
                            provider = item1;
                        }
                    });
                    if (provider == null) {
                        provider = { value: 'Other', label: 'Other' };
                    }
                    this.setState({
                        insuranceId: item.id,
                        insuranceProvider: provider,
                        insuranceDeductableAmount: item.deductible,
                        insuranceOtherProvider: item.provider,
                        insuranceAmount: item.price,
                        insuranceAccountNumber: item.account_number,
                    });
                }
                if (item.service && (item.service.slug == 'home-warranty' || item.service.slug == 'homewarranty')) {
                    let serviceOptions: any = this.state.warrantyProviderOptions;
                    let service: any = null;
                    serviceOptions.map((item1: any) => {
                        if (item1.value == item.provider) {
                            service = item1;
                        }
                    });
                    if (service == null) {
                        service = { value: 'Other', label: 'Other' };
                    }
                    this.setState({
                        warrantyId: item.id,
                        warrantyAmount: item.price,
                        warrantyProvider: service,
                        warrantyOtherProvider: item.provider,
                        warrantyAccountNumber: item.account_number,
                    });
                }

                if (item.service && item.service.slug == 'home-security') {
                    let serviceOptions: any = this.state.securityProviderOptions;
                    let service: any = null;
                    serviceOptions.map((item1: any) => {
                        if (item1.value == item.provider) {
                            service = item1;
                        }
                    });
                    if (service == null) {
                        service = { value: 'Other', label: 'Other' };
                    }
                    this.setState({
                        securityId: item.id,
                        securityAmount: item.price,
                        securityProvider: service,
                        securityOtherProvider: item.provider,
                        securityAccountNumber: item.account_number,
                    });
                }
            });
            this.setState({ isGetValueMapped: true, isInsuranceOptionsMap: false });
        }

        if (
            prevProps.homeOwnerDashboardState.updateSubscriptionDetails !=
                this.props.homeOwnerDashboardState.updateSubscriptionDetails &&
            this.props.homeOwnerDashboardState.updateSubscriptionDetails &&
            this.props.homeOwnerDashboardState.isUpdateSubscriptionDetails
        ) {
            this.props.homeOwnerDashboardState.isUpdateSubscriptionDetails = false;

            this.setState({
                continueClickedInsurance: false,
                continueClickedWarranty: false,
                continueClickedSecurity: false,
            });
            let progressValue: number = 0;
            if (this.state.activeStep == 1) {
                if (
                    (this.state.showWarranty && this.state.showSecurity) ||
                    (this.state.showWarranty && !this.state.showSecurity)
                ) {
                    if (this.state.showSecurity) {
                        progressValue = (1 / 3) * 100;
                    } else {
                        progressValue = (1 / 2) * 100;
                    }
                    this.setState({ activeStep: 2 });
                } else if (!this.state.showWarranty && this.state.showSecurity) {
                    this.setState({ activeStep: 3 });
                    progressValue = (1 / 2) * 100;
                }
                if (!this.state.showWarranty && !this.state.showSecurity) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 2) {
                if (this.state.showSecurity) {
                    this.setState({ activeStep: 3 });
                    progressValue = (1 / 2) * 100;
                }
                if (!this.state.showSecurity) {
                    this.props.history.push('/dashboardhomeowner');
                }
            }
            if (this.state.activeStep == 3) {
                this.props.history.push('/dashboardhomeowner');
            }
            this.setState({ progressValue: progressValue });
        }

        if (
            prevProps.nextMoveState != this.props.nextMoveState &&
            this.props.nextMoveState.getNextMove &&
            this.props.nextMoveState.isGetNextMove
        ) {
            this.props.nextMoveState.isGetNextMove = false;
            this.props.nextMoveState.getNextMove.results &&
                this.props.nextMoveState.getNextMove.results.map((item: any) => {
                    if (item.service === 'Home Insurance' || item.service === 'Insurance') {
                        let options: any = this.state.insuranceProviderOptions;
                        let provider: any = null;
                        options.map((item1: any) => {
                            if (item1.value == item.provider) {
                                provider = item1;
                            }
                        });
                        if (provider == null) {
                            provider = { value: 'Other', label: 'Other' };
                        }
                        this.setState({
                            // insuranceId: item.id,
                            insuranceProvider: provider,
                            insuranceDeductableAmount: item.deductible,
                            insuranceOtherProvider: item.provider,
                            insuranceAmount: item.price,
                            insuranceAccountNumber: item.account_number,
                        });
                    } else if (item.service === 'Home Security') {
                        let serviceOptions: any = this.state.securityProviderOptions;
                        let service: any = null;
                        serviceOptions.map((item1: any) => {
                            if (item1.value == item.provider) {
                                service = item1;
                            }
                        });
                        if (service == null) {
                            service = { value: 'Other', label: 'Other' };
                        }
                        this.setState({
                            // securityId: item.id,
                            securityAmount: item.price,
                            securityProvider: service,
                            securityOtherProvider: item.provider,
                            securityAccountNumber: item.account_number,
                        });
                    }
                });
        }
    }

    onChange = (e: any, name: string) => {
        if (
            name == 'insuranceAmount' ||
            name == 'securityAmount' ||
            name == 'warrantyAmount' ||
            name == 'insuranceDeductableAmount'
        ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/[^\d.]/g, '') });
        } else if (
            name == 'insuranceAccountNumber' ||
            name == 'warrantyAccountNumber' ||
            name == 'securityAccountNumber'
        ) {
            this.setState({ ...this.state, [name]: e.target.value.replace(/\D/g, '') });
        } else {
            this.setState({ ...this.state, [name]: e.target.value });
        }
    };
    onSelect = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e });
    };
    radioChange = (e: any, name: string) => {
        this.setState({ ...this.state, [name]: e.target.value });
    };
    setSize = () => {
        this.setState({ size: window.screen.width });
    };

    insurancePostSubcriptionDetails = () => {
        this.setState({ continueClickedInsurance: true });
        let serviceId: any = '';
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeProtectionServiceList &&
            this.props.history.location.state.homeProtectionServiceList.map((item: any) => {
                if (item.slug === 'home-insurance' || item.slug === 'insurance') {
                    serviceId = item.id;
                }
            });
        if (
            serviceId &&
            this.state.insuranceAmount &&
            this.state.insuranceAccountNumber &&
            this.state.insuranceDeductableAmount &&
            this.state.insuranceProvider &&
            this.state.insuranceProvider.value &&
            ((this.state.insuranceProvider.value == 'Other' && this.state.insuranceOtherProvider) ||
                this.state.insuranceProvider.value != 'Other')
        ) {
            if (this.state.insuranceId) {
                let payload = {
                    id: this.state.insuranceId,
                    body: {
                        id: this.state.insuranceId,
                        service_id: serviceId,
                        provider:
                            this.state.insuranceProvider &&
                            this.state.insuranceProvider.value &&
                            this.state.insuranceProvider.value != 'Other'
                                ? this.state.insuranceProvider.value
                                : this.state.insuranceProvider &&
                                  this.state.insuranceProvider.value &&
                                  this.state.insuranceProvider.value == 'Other'
                                ? this.state.insuranceOtherProvider
                                : '',
                        provider_logo:
                            this.state.insuranceProvider &&
                            this.state.insuranceProvider.logo &&
                            this.state.insuranceProvider.logo != 'Other'
                                ? this.state.insuranceProvider.logo
                                : this.state.insuranceProvider &&
                                    this.state.insuranceProvider.logo &&
                                    this.state.insuranceProvider.logo == 'Other'
                                ? this.state.insuranceOtherProvider
                                : '',
                        price: this.state.insuranceAmount,
                        account_number: this.state.insuranceAccountNumber,
                        move: this.state.moveId,
                        deductible: this.state.insuranceDeductableAmount,
                    },
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                this.setState({ insuranceClicked: true });
            } else {
                let payload = {
                    service_id: serviceId,
                    provider:
                        this.state.insuranceProvider &&
                        this.state.insuranceProvider.value &&
                        this.state.insuranceProvider.value != 'Other'
                            ? this.state.insuranceProvider.value
                            : this.state.insuranceProvider &&
                              this.state.insuranceProvider.value &&
                              this.state.insuranceProvider.value == 'Other'
                            ? this.state.insuranceOtherProvider
                            : '',
                    provider_logo:
                        this.state.insuranceProvider &&
                        this.state.insuranceProvider.logo &&
                        this.state.insuranceProvider.logo != 'Other'
                            ? this.state.insuranceProvider.logo
                            : this.state.insuranceProvider &&
                                this.state.insuranceProvider.logo &&
                                this.state.insuranceProvider.logo == 'Other'
                            ? this.state.insuranceOtherProvider
                            : '',
                    price: this.state.insuranceAmount,
                    account_number: this.state.insuranceAccountNumber,
                    move: this.state.moveId,
                    deductible: this.state.insuranceDeductableAmount,
                };
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.setState({ insuranceClicked: true });
            }
        }
    };

    warrantyPostSubcriptionDetails = () => {
        this.setState({ continueClickedWarranty: true });
        let serviceId: any = '';
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeProtectionServiceList &&
            this.props.history.location.state.homeProtectionServiceList.map((item: any) => {
                if (item.slug === 'home-warranty' || item.slug === 'homewarranty') {
                    serviceId = item.id;
                }
            });
        if (
            serviceId &&
            this.state.warrantyAmount &&
            this.state.warrantyAccountNumber &&
            this.state.warrantyProvider &&
            this.state.warrantyProvider.value &&
            ((this.state.warrantyProvider.value == 'Other' && this.state.warrantyOtherProvider) ||
                this.state.warrantyProvider.value != 'Other')
        ) {
            if (this.state.warrantyId) {
                let payload = {
                    id: this.state.warrantyId,
                    body: {
                        id: this.state.warrantyId,
                        service_id: serviceId,
                        provider:
                            this.state.warrantyProvider &&
                            this.state.warrantyProvider.value &&
                            this.state.warrantyProvider.value != 'Other'
                                ? this.state.warrantyProvider.value
                                : this.state.warrantyProvider &&
                                  this.state.warrantyProvider.value &&
                                  this.state.warrantyProvider.value == 'Other'
                                ? this.state.warrantyOtherProvider
                                : '',
                        provider_logo:
                            this.state.warrantyProvider &&
                            this.state.warrantyProvider.logo &&
                            this.state.warrantyProvider.logo != 'Other'
                                ? this.state.warrantyProvider.logo
                                : this.state.warrantyProvider &&
                                    this.state.warrantyProvider.logo &&
                                    this.state.warrantyProvider.logo == 'Other'
                                ? this.state.warrantyOtherProvider
                                : '',
                        price: this.state.warrantyAmount,
                        account_number: this.state.warrantyAccountNumber,
                        move: this.state.moveId,
                    },
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                this.setState({ warrantyClicked: true });
            } else {
                let payload = {
                    service_id: serviceId,
                    provider:
                        this.state.warrantyProvider &&
                        this.state.warrantyProvider.value &&
                        this.state.warrantyProvider.value != 'Other'
                            ? this.state.warrantyProvider.value
                            : this.state.warrantyProvider &&
                              this.state.warrantyProvider.value &&
                              this.state.warrantyProvider.value == 'Other'
                            ? this.state.warrantyOtherProvider
                            : '',
                    provider_logo:
                        this.state.warrantyProvider &&
                        this.state.warrantyProvider.logo &&
                        this.state.warrantyProvider.logo != 'Other'
                            ? this.state.warrantyProvider.logo
                            : this.state.warrantyProvider &&
                                this.state.warrantyProvider.logo &&
                                this.state.warrantyProvider.logo == 'Other'
                            ? this.state.warrantyOtherProvider
                            : '',
                    price: this.state.warrantyAmount,
                    account_number: this.state.warrantyAccountNumber,
                    move: this.state.moveId,
                };
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.setState({ warrantyClicked: true });
            }
        }
    };

    securityPostSubcriptionDetails = () => {
        this.setState({ continueClickedSecurity: true });
        let serviceId: any = '';
        this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.homeProtectionServiceList &&
            this.props.history.location.state.homeProtectionServiceList.map((item: any) => {
                if (item.slug === 'home-security') {
                    serviceId = item.id;
                }
            });
        if (
            serviceId &&
            this.state.securityAmount &&
            this.state.securityAccountNumber &&
            this.state.securityProvider &&
            this.state.securityProvider.value &&
            ((this.state.securityProvider.value == 'Other' && this.state.securityOtherProvider) ||
                this.state.securityProvider.value != 'Other')
        ) {
            if (this.state.securityId) {
                let payload = {
                    id: this.state.securityId,
                    body: {
                        id: this.state.securityId,
                        service_id: serviceId,
                        provider:
                            this.state.securityProvider &&
                            this.state.securityProvider.value &&
                            this.state.securityProvider.value != 'Other'
                                ? this.state.securityProvider.value
                                : this.state.securityProvider &&
                                  this.state.securityProvider.value &&
                                  this.state.securityProvider.value == 'Other'
                                ? this.state.securityOtherProvider
                                : '',
                        provider_logo:
                            this.state.securityProvider &&
                            this.state.securityProvider.logo &&
                            this.state.securityProvider.logo != 'Other'
                                ? this.state.securityProvider.logo
                                : this.state.securityProvider &&
                                    this.state.securityProvider.logo &&
                                    this.state.securityProvider.logo == 'Other'
                                ? this.state.securityOtherProvider
                                : '',
                        price: this.state.securityAmount,
                        account_number: this.state.securityAccountNumber,
                        move: this.state.moveId,
                    },
                };
                this.props.dashboardAction.updateSubscriptionDetails(payload);
                this.setState({ securityClicked: true });
            } else {
                let payload = {
                    service_id: serviceId,
                    provider:
                        this.state.securityProvider &&
                        this.state.securityProvider.value &&
                        this.state.securityProvider.value != 'Other'
                            ? this.state.securityProvider.value
                            : this.state.securityProvider &&
                              this.state.securityProvider.value &&
                              this.state.securityProvider.value == 'Other'
                            ? this.state.securityOtherProvider
                            : '',
                    provider_logo:
                        this.state.securityProvider &&
                        this.state.securityProvider.logo &&
                        this.state.securityProvider.logo != 'Other'
                            ? this.state.securityProvider.logo
                            : this.state.securityProvider &&
                                this.state.securityProvider.logo &&
                                this.state.securityProvider.logo == 'Other'
                            ? this.state.securityOtherProvider
                            : '',
                    price: this.state.securityAmount,
                    account_number: this.state.securityAccountNumber,
                    move: this.state.moveId,
                };
                this.props.dashboardAction.postSubscriptionDetails(payload);
                this.setState({ securityClicked: true });
            }
        }
    };

    onSkip = () => {
        if (this.state.activeStep == 1) {
            if (
                (this.state.showWarranty && this.state.showSecurity) ||
                (this.state.showWarranty && !this.state.showSecurity)
            ) {
                this.setState({ activeStep: 2 });
            } else if (!this.state.showWarranty && this.state.showSecurity) {
                this.setState({ activeStep: 3 });
            }
            if (!this.state.showWarranty && !this.state.showSecurity) {
                this.props.history.push('/dashboardhomeowner');
            }
        }
        if (this.state.activeStep == 2) {
            if (this.state.showSecurity) {
                this.setState({ activeStep: 3 });
            }
            if (!this.state.showSecurity) {
                this.props.history.push('/dashboardhomeowner');
            }
        }
        if (this.state.activeStep == 3) {
            this.props.history.push('/dashboardhomeowner');
        }
    };

    render() {
        window.onresize = this.setSize;
        return (
            <div>
                <div className="progressBarFinal">
                    <Box>
                        <LinearProgress variant="determinate" value={this.state.progressValue} />
                    </Box>
                </div>

                <div className="modalMainFlexDiv">
                    <div className="modalMainLeftDiv">
                        <div className="imageDiv">
                            <img
                                src={
                                    this.state.activeStep === 1
                                        ? insuranceSubscriptionImage
                                        : this.state.activeStep == 2
                                        ? warrantySubscriptionImage
                                        : this.state.activeStep == 3
                                        ? securitySubscriptionImage
                                        : ''
                                }
                            />
                        </div>
                        <div className="leftTitle">
                            {this.state.activeStep === 1
                                ? 'Home Insurance'
                                : this.state.activeStep == 3
                                ? 'Home Warranty'
                                : this.state.activeStep == 2
                                ? 'Home Security'
                                : ''}
                        </div>
                        <div className="leftDescription">
                            {this.state.activeStep === 1
                                ? 'Looking to renew your home insurance? Our team can help you find the best deals.'
                                : this.state.activeStep == 2
                                ? 'Not only can you get $1,700 worth of free equipment, but you may qualify for savings in other areas of your home.'
                                : this.state.activeStep == 3
                                ? 'Not only do we have 1700 worth free equipments. On top of that, you may qualify savings on other aspects for your home.'
                                : ''}
                        </div>
                    </div>
                    <div className="HomeOwnerDashboardModalMainDiv">
                        <div style={{ width: '100%' }}>
                            <ArrowBackIcon
                                onClick={() => {
                                    this.props.history.push('/dashboardhomeowner');
                                }}
                            />
                        </div>
                        <div className="HomeOwnerDashboardModalTitle">
                            {this.state.size > 600
                                ? 'Fill your subscription details for home protection'
                                : 'Home Protection Subscription Details'}
                        </div>
                        {this.state.showInsurance ? (
                            <div className="stepperHeaderMainFlexDiv">
                                <div className="stepperHeaderMainFlexDiv">
                                    {this.state.activeStep > 1 ? (
                                        <CheckCircleIcon className="checkIconForm" />
                                    ) : (
                                        <div
                                            className="circleNumber"
                                            style={{ background: this.state.activeStep === 1 ? '#006DFF' : '#C6C6C6' }}
                                        >
                                            1
                                        </div>
                                    )}

                                    <div className="stepperHeaderSecondFlexDiv">
                                        <div
                                            className="steppertitle"
                                            style={{ color: this.state.activeStep >= 1 ? '#333333' : '#6B6C6F' }}
                                        >
                                            Insurance
                                        </div>
                                        <div className="steppersubtitle">
                                            {this.state.activeStep > 1
                                                ? 'Selected Service Provider'
                                                : 'Select Service Provider'}
                                        </div>
                                    </div>
                                </div>
                                {/* <div>{this.state.activeStep === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                            </div>
                        ) : null}
                        {/* InsuranceForm */}
                        {this.state.activeStep === 1 && this.state.showInsurance ? (
                            <div className="HomeOwnerDashboardformDiv">
                                <div className="formTitleFlexDiv">
                                    <div className="titleServiceName">Insurance</div>
                                    {/* <div className="imnotsure">I am not sure</div> */}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <SelectInput
                                                label={'What insurance provider do you have today?'}
                                                value={
                                                    this.state.insuranceProvider && this.state.insuranceProvider.value
                                                }
                                                onChange={(e: any) => this.onSelect(e, 'insuranceProvider')}
                                                placeholder={
                                                    this.state.insuranceProvider && this.state.insuranceProvider.label
                                                        ? this.state.insuranceProvider.label
                                                        : 'Select insurance provider'
                                                }
                                                options={this.state.insuranceProviderOptions}
                                                // inputLa
                                            />
                                            <div className="error">
                                                {this.state.continueClickedInsurance &&
                                                this.state.insuranceProvider == null
                                                    ? 'Enter insurance Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.insuranceProvider &&
                                    this.state.insuranceProvider.value &&
                                    this.state.insuranceProvider.value == 'Other' ? (
                                        <div className="inputFlexDiv2">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="Enter provider name"
                                                    value={this.state.insuranceOtherProvider}
                                                    onChange={(e: any) => this.onChange(e, 'insuranceOtherProvider')}
                                                    placeholder=""
                                                />
                                                <div className="error">
                                                    {this.state.continueClickedInsurance &&
                                                    !this.state.insuranceOtherProvider
                                                        ? 'Enter Insurance Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="What is your deductible?"
                                                value={this.state.insuranceDeductableAmount}
                                                onChange={(e: any) => this.onChange(e, 'insuranceDeductableAmount')}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedInsurance &&
                                                !this.state.insuranceDeductableAmount
                                                    ? 'Enter Insurance deductable amount'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="How much are you paying?"
                                                value={this.state.insuranceAmount}
                                                onChange={(e: any) => this.onChange(e, 'insuranceAmount')}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedInsurance && !this.state.insuranceAmount
                                                    ? 'Enter Insurance amount'
                                                    : this.state.continueClickedInsurance &&
                                                      this.state.insuranceAmount &&
                                                      parseInt(this.state.insuranceAmount) <= 0
                                                    ? 'Amount must be greater than 0'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="inputsupportText">/month</div>
                                    </div>
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="Your Policy Number"
                                                value={this.state.insuranceAccountNumber}
                                                onChange={(e: any) => this.onChange(e, 'insuranceAccountNumber')}
                                                placeholder=""
                                                // InputProps={{
                                                //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                // }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedInsurance &&
                                                !this.state.insuranceAccountNumber
                                                    ? 'Enter Insurance policy number'
                                                    : this.state.continueClickedInsurance &&
                                                      this.state.insuranceAccountNumber.length < 4
                                                    ? 'Policy number should be atleast 4 digits'
                                                    : this.state.continueClickedInsurance &&
                                                      this.state.insuranceAccountNumber &&
                                                      parseInt(this.state.insuranceAccountNumber) <= 0
                                                    ? 'invalid policy number'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formTitleFlexDiv">
                                    <div className="imnotsure"></div>
                                    <div>
                                        <Button
                                            className="DashboardUtilitiesSubscriptionFormButton"
                                            onClick={() => {
                                                this.insurancePostSubcriptionDetails();
                                            }}
                                            disabled={this.state.insuranceClicked}
                                        >
                                            Next
                                        </Button>
                                        {this.state.insuranceId ? null : (
                                            <span className="skipButton" onClick={this.onSkip}>
                                                Skip
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    onClick={
                                        () => this.insurancePostSubcriptionDetails()
                                        // this.setState({activeStep: 2 })
                                    }
                                >
                                    Next
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                            </div>
                        ) : null}
                        {this.state.showWarranty ? <div className="lineDiv"></div> : null}
                        {this.state.showWarranty ? (
                            <div className="stepperHeaderMainFlexDiv">
                                <div className="stepperHeaderMainFlexDiv">
                                    {this.state.activeStep > 2 ? (
                                        <CheckCircleIcon className="checkIconForm" />
                                    ) : (
                                        <div
                                            className="circleNumber"
                                            style={{ background: this.state.activeStep === 2 ? '#006DFF' : '#C6C6C6' }}
                                        >
                                            2
                                        </div>
                                    )}

                                    <div className="stepperHeaderSecondFlexDiv">
                                        <div
                                            className="steppertitle"
                                            style={{ color: this.state.activeStep >= 2 ? '#333333' : '#6B6C6F' }}
                                        >
                                            Home Warranty
                                        </div>
                                        <div className="steppersubtitle">
                                            {this.state.activeStep > 2
                                                ? 'Selected Service Provider'
                                                : 'Select Service Provider'}
                                        </div>
                                    </div>
                                </div>
                                {/* <div>{this.state.activeStep === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                            </div>
                        ) : null}
                        {/* Home WarrantyForm */}
                        {this.state.activeStep === 2 && this.state.showWarranty ? (
                            <div className="HomeOwnerDashboardformDiv">
                                <div className="formTitleFlexDiv">
                                    <div className="titleServiceName">Home Warranty</div>
                                    {/* <div className="imnotsure">I am not sure</div> */}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <SelectInput
                                                label={'What home warranty provider do you have today?'}
                                                value={this.state.warrantyProvider && this.state.warrantyProvider.value}
                                                onChange={(e: any) => this.onSelect(e, 'warrantyProvider')}
                                                placeholder={
                                                    this.state.warrantyProvider && this.state.warrantyProvider.label
                                                        ? this.state.warrantyProvider.label
                                                        : 'Select warranty provider'
                                                }
                                                options={this.state.warrantyProviderOptions}
                                                // inputLa
                                            />
                                            <div className="error">
                                                {this.state.continueClickedWarranty &&
                                                this.state.warrantyProvider == null
                                                    ? 'Enter warranty Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.warrantyProvider &&
                                    this.state.warrantyProvider.value &&
                                    this.state.warrantyProvider.value == 'Other' ? (
                                        <div className="inputFlexDiv2">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="Enter provider name"
                                                    value={this.state.warrantyOtherProvider}
                                                    onChange={(e: any) => this.onChange(e, 'warrantyOtherProvider')}
                                                    placeholder=""
                                                />
                                                <div className="error">
                                                    {this.state.continueClickedWarranty &&
                                                    !this.state.warrantyOtherProvider
                                                        ? 'Enter warranty Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="How much are you paying?"
                                                value={this.state.warrantyAmount}
                                                onChange={(e: any) => this.onChange(e, 'warrantyAmount')}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedWarranty && !this.state.warrantyAmount
                                                    ? 'Enter warranty amount'
                                                    : this.state.continueClickedWarranty &&
                                                      this.state.warrantyAmount &&
                                                      parseInt(this.state.warrantyAmount) <= 0
                                                    ? 'Amount must be greater than 0'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="inputsupportText">/month</div>
                                    </div>
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="Your Account Number"
                                                value={this.state.warrantyAccountNumber}
                                                onChange={(e: any) => this.onChange(e, 'warrantyAccountNumber')}
                                                placeholder=""
                                                // InputProps={{
                                                //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                // }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedWarranty && !this.state.warrantyAccountNumber
                                                    ? 'Enter warranty account number'
                                                    : this.state.continueClickedWarranty &&
                                                      this.state.insuranceAccountNumber.length < 4
                                                    ? 'Account number should be atleast 4 digits'
                                                    : this.state.continueClickedWarranty &&
                                                      this.state.warrantyAccountNumber &&
                                                      parseInt(this.state.warrantyAccountNumber) <= 0
                                                    ? 'invalid account number'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formTitleFlexDiv">
                                    <div className="imnotsure"></div>
                                    <div>
                                        {this.state.warrantyId ? null : (
                                            <span className="skipButton" onClick={this.onSkip}>
                                                Skip
                                            </span>
                                        )}
                                        <Button
                                            className="DashboardUtilitiesSubscriptionFormButton"
                                            onClick={() => {
                                                this.warrantyPostSubcriptionDetails();
                                            }}
                                            disabled={this.state.warrantyClicked}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                                {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    onClick={
                                        () => this.warrantyPostSubcriptionDetails()
                                        // this.setState({activeStep: 3 })
                                    }
                                >
                                    Next
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                            </div>
                        ) : null}
                        {this.state.showSecurity ? <div className="lineDiv"></div> : null}
                        {this.state.showSecurity ? (
                            <div className="stepperHeaderMainFlexDiv">
                                <div className="stepperHeaderMainFlexDiv">
                                    {this.state.activeStep > 3 ? (
                                        <CheckCircleIcon className="checkIconForm" />
                                    ) : (
                                        <div
                                            className="circleNumber"
                                            style={{ background: this.state.activeStep === 3 ? '#006DFF' : '#C6C6C6' }}
                                        >
                                            3
                                        </div>
                                    )}

                                    <div className="stepperHeaderSecondFlexDiv">
                                        <div
                                            className="steppertitle"
                                            style={{ color: this.state.activeStep >= 3 ? '#333333' : '#6B6C6F' }}
                                        >
                                            Home Security
                                        </div>
                                        <div className="steppersubtitle">
                                            {this.state.activeStep > 3
                                                ? 'Selected Service Provider'
                                                : 'Select Service Provider'}
                                        </div>
                                    </div>
                                </div>
                                {/* <div>{this.state.activeStep === 3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> */}
                            </div>
                        ) : null}
                        {/* Home SecurityForm */}
                        {this.state.activeStep === 3 && this.state.showSecurity ? (
                            <div className="HomeOwnerDashboardformDiv">
                                <div className="formTitleFlexDiv">
                                    <div className="titleServiceName">Home Security</div>
                                    {/* <div className="imnotsure">I am not sure</div> */}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <SelectInput
                                                label={'What home security provider do you have today?'}
                                                value={this.state.securityProvider && this.state.securityProvider.value}
                                                onChange={(e: any) => this.onSelect(e, 'securityProvider')}
                                                placeholder={
                                                    this.state.securityProvider && this.state.securityProvider.label
                                                        ? this.state.securityProvider.label
                                                        : 'Select security provider'
                                                }
                                                options={this.state.securityProviderOptions}
                                                // inputLa
                                            />
                                            <div className="error">
                                                {this.state.continueClickedSecurity &&
                                                this.state.securityProvider == null
                                                    ? 'Enter security Provider'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.securityProvider &&
                                    this.state.securityProvider.value &&
                                    this.state.securityProvider.value == 'Other' ? (
                                        <div className="inputFlexDiv2">
                                            <div className="inputDiv1">
                                                <CustomizedInput
                                                    labelName="Enter provider name"
                                                    value={this.state.securityOtherProvider}
                                                    onChange={(e: any) => this.onChange(e, 'securityOtherProvider')}
                                                    placeholder=""
                                                />
                                                <div className="error">
                                                    {this.state.continueClickedSecurity &&
                                                    !this.state.securityOtherProvider
                                                        ? 'Enter security Provider'
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="inputFlexDiv1">
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="How much are you paying?"
                                                value={this.state.securityAmount}
                                                onChange={(e: any) => this.onChange(e, 'securityAmount')}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedSecurity && !this.state.securityAmount
                                                    ? 'Enter security amount'
                                                    : this.state.continueClickedSecurity &&
                                                      this.state.securityAmount &&
                                                      parseInt(this.state.securityAmount) <= 0
                                                    ? 'Amount must be greater than 0'
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="inputsupportText">/month</div>
                                    </div>
                                    <div className="inputFlexDiv2">
                                        <div className="inputDiv1">
                                            <CustomizedInput
                                                labelName="Your Account Number"
                                                value={this.state.securityAccountNumber}
                                                onChange={(e: any) => this.onChange(e, 'securityAccountNumber')}
                                                placeholder=""
                                                // InputProps={{
                                                //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                // }}
                                            />
                                            <div className="error">
                                                {this.state.continueClickedSecurity && !this.state.securityAccountNumber
                                                    ? 'Enter security account number'
                                                    : this.state.continueClickedSecurity &&
                                                      this.state.securityAccountNumber.length < 4
                                                    ? 'Account number should be atleast 4 digits'
                                                    : this.state.continueClickedSecurity &&
                                                      this.state.securityAccountNumber &&
                                                      parseInt(this.state.securityAccountNumber) <= 0
                                                    ? 'invalid account number'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formTitleFlexDiv">
                                    <div className="imnotsure"></div>
                                    <div>
                                        {this.state.securityId ? null : (
                                            <span className="skipButton" onClick={this.onSkip}>
                                                Skip
                                            </span>
                                        )}
                                        <Button
                                            className="DashboardUtilitiesSubscriptionFormButton"
                                            onClick={() => {
                                                this.securityPostSubcriptionDetails();
                                            }}
                                            disabled={this.state.securityClicked}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                                {/* <div className="DashboardUtilitiesSubscriptionFormButtonDiv">
                                <Button
                                    className="DashboardUtilitiesSubscriptionFormButton"
                                    // onClick={() => this.setState({ activeStep: 2 })}
                                    onClick={
                                        this.securityPostSubcriptionDetails
                                        // this.props.closeModal
                                    }
                                >
                                    Finish
                                </Button>
                                <div className="imnotsure">I am not sure</div>
                            </div> */}
                            </div>
                        ) : null}
                        <div className="lineDiv"></div>
                        {/* <div className="HomeOwnerDashboardModalButtonDiv">
                    <Button className="HomeOwnerDashboardModalButton" onClick={this.props.closeModal}>
                        Submit
                    </Button>
                </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
        serviceAction: bindActionCreators(ServiceActions, dispatch),
        nextMoveAction: bindActionCreators(Action, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    homeOwnerDashboardState: state.homeOwnerdashboard,
    serviceState: state.service,
    nextMoveState: state.nextMove,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOwnerDashboardProtectionForm);
