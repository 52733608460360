import React from 'react';
import Avatar from '@material-ui/core/Avatar';

interface detailCardProps {
    avatarImage?: any;
    name?: string;
    subtext?: string;
    phoneNumber?: string;
    phoneIcon?: any;
}
let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
const DetailCard = (props: detailCardProps) => {
    return (
        <div className="detail-main-card">
            <div>
                <Avatar alt="Remy Sharp" src={props.avatarImage} />
            </div>
            <div style={{ marginLeft: '15px' }}>
                <p className="name-detail">{props.name}</p>
                <p className="sub-text">{props.subtext}</p>
                <div style={{ display: 'flex' }}>
                    <img src={props.phoneIcon} alt="" />
                    <span style={ { color: isDouglasElliman ?  '#009cbd' : "#006dff", fontSize: '13px' }}>{props.phoneNumber}</span>
                </div>
            </div>
        </div>
    );
};

export default DetailCard;
