import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import Button from '../../../../components/atoms/Button';
import InputBase from '@material-ui/core/InputBase';
import './Model.scss';
import AnalyticsService from '../../../../pages/analytics-service';
import { generateDropdownValueObject, generateYearList, isValidPercentage } from '../../../../utils/helper';
import { loanTermOptions, monthList } from '../../../../utils/constants';


const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                width: '160px',
                [theme.breakpoints.down('sm')]: {
                    width: '100px',
                },
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            fontWeight: 'bold',

            padding: '13px 26px 13px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
            },
        },
    }),
)(InputBase);
const BootstrapInput1 = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                width: '280px',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            fontWeight: 'bold',
            padding: '13px 26px 13px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
            },
        },
    }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0),
        },
    }),
);



const menuProps: any = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
}

interface defaultPropsToMortageModal {
    onMortageSubmit: any;
    isFetchingLoanData?: boolean;
    loanDetails?: any;
    toggleMortageModal?: any;
    analyticsData?: any;
    property_valuations?: any;
    currentAvgValuation?: any
    loanData?: any;
    showPurchasePriceField?: boolean;
}


type Itypes = {
    [loanStartYear: string]: boolean;
    loanStartMonth: boolean;
    loanTerm: boolean;
    loanAmount: boolean;
    apr: boolean;
    purchasePrice: boolean;
}

export default function CustomizedSelects({ onMortageSubmit, toggleMortageModal, loanDetails, currentAvgValuation, isFetchingLoanData, analyticsData, loanData, showPurchasePriceField }: defaultPropsToMortageModal) {

    const classes = useStyles();
    const [month, setMonth] = React.useState(moment().format('MMMM'));
    const [loanAmount, setLoanAmount] = React.useState('');
    const [apr, setApr] = React.useState<string>('');
    const [year, setstartYear] = React.useState(moment().format('YYYY'));
    const [fixed, setFixed] = React.useState('');
    const [years, setYears] = useState<any>([]);
    const [purchasePriceError, setPurchasePriceError] = useState('');
    const [purchasePrice, setPurchasePrice] = useState('');
    const [isAnalitycsTriggerredOnce, setIsAnalitycsTriggerredOnce] = useState<Itypes>({
        loanStartYear: false,
        loanStartMonth: false,
        loanTerm: false,
        loanAmount: false,
        apr: false,
        purchasePrice: false
    })
    const [isFormStarted, setIsFormStarted] = useState(false);

    useEffect(() => {

        const setPrifilledData = () => {
            if (loanDetails && loanDetails.price) {
                setMonth(loanDetails.start_date?.split(' ')[0]);
                setLoanAmount(loanDetails?.price);
                setApr(loanDetails.loan_percentage);
                setstartYear(loanDetails.start_date?.split(' ')[1]);
                setFixed(loanDetails?.loan_year);
                if(loanDetails?.purchase_price != 0) setPurchasePrice(loanDetails?.purchase_price);

            } else {
                if (loanData) {
                const { loan_amount, original_contract_date, loan_interest_rate } = loanData;

                const startingMonth = moment(original_contract_date, 'YYYY-MM-DD').format('MMMM');
                const startingYear = moment(original_contract_date, 'YYYY-MM-DD').format('YYYY');

                setMonth(startingMonth);
                setLoanAmount(loan_amount);
                setApr(loan_interest_rate);
                setstartYear(startingYear);
                }
            }

        }

     if(loanDetails && loanDetails.price || loanData) setPrifilledData();
      return () => {
      }
    }, [])
    

    const [errors, setErrors] = React.useState({
        loanAmount: false,
        apr: false,
        term: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        loanAmount: '',
        apr: '',
        term: ''
    })

    const handleAnalytics = (fieldLabel: string, fieldName: string) => {
        if (!isAnalitycsTriggerredOnce[fieldName]) {
            const trackProperties = {
                ...analyticsData,
                category_name: 'Inputting Mortgage Details Form Field',
                button_copy: 'Submit',
                form_button:  true,
                form_pre_filled: true,
                form_field_name: fieldLabel
                }
                
                if (isFormStarted) {
                    AnalyticsService.formFieldInput(trackProperties);
                } else {
                    AnalyticsService.formFieldStarted(trackProperties);
                }
                setIsFormStarted(true);
        }

        setIsAnalitycsTriggerredOnce({
            ...isAnalitycsTriggerredOnce,
            [fieldName]: true
        })
    }

    const selectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
            setstartYear(event.target.value as string);
            handleAnalytics('Loan Start Date (Year)', 'loanStartYear');
    };
    
    const selectMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
            setMonth(event.target.value as string);
            handleAnalytics('Loan Start Date (Month)', 'loanStartMonth');
    };

    const selectFixed = (event: React.ChangeEvent<{ value: unknown, name: string }>) => {
        const value = event.target.value as string;
        const fieldName = event.target.name;

        setFixed(value);
        handleAnalytics('Loan Term', 'loanTerm');
        validate(value, fieldName);
    };

    useEffect(() => {
        const setYearList = () => {
            const currentYear: number = Number(moment().format('YYYY'));
            const startYearOption: number[] = generateYearList(currentYear - 25, currentYear)
            
            setYears(generateDropdownValueObject(startYearOption))
        }
        if (years.length < 1) {
            setYearList()
        }
        return () => {
            setYears([]);
        }
    }, []);

    /*
    TODO: Use Redux form validator
    Need to change this section
    */
    const validateAll = () => {
        let tempErrors: any = { ...errors }
        let tempErrorMessages: any = { ...errorMessages }
        const fields: any = { 'loanAmount': loanAmount, 'apr': apr, 'term': fixed}

        Object.keys(fields).forEach((key) => {
            if (fields[key] === null || fields[key] === "") {
                tempErrors[key] = true
                tempErrorMessages[key] = 'This field is required'
            }
        })

        setErrors(tempErrors)
        setErrorMessages(tempErrorMessages)
    }

    const submitHandler = () => {

        const isNotEverythingFilled = [month, loanAmount, apr, year, fixed].some(val => val === null || val === '' || !val);
        validateAll()
        if (showPurchasePriceField) {
            validate(purchasePrice, 'purchasePrice');
            validate(loanAmount, 'loanAmount');
        }
        if (showPurchasePriceField && (!purchasePrice || purchasePriceError)) return;
        if (isNotEverythingFilled) {
            return
        } else {
            const hasAnyError = Object.values(errors).some(val => val === true)
            if (hasAnyError) return

            let moveId: any = localStorage.getItem('moveId');
            moveId = (moveId && parseInt(moveId)) || null;
            
            const mortageDetails: any = {
                service: "Mortgage",
                provider: "AT&T",
                services_brought: "",
                price: loanAmount,
                deductible: 0.0,
                loan_year: fixed,
                loan_percentage: apr,
                service_start_date: `${year}-${monthList.indexOf(month) + 1}-01`,
                is_completed: true,
                amort_chart: null,
                move: moveId,
            };

            if (showPurchasePriceField) {
                mortageDetails.purchase_price = purchasePrice
                mortageDetails.purchase_date = `${year}-${monthList.indexOf(month) + 1}-01`
            }

            onMortageSubmit(mortageDetails);

            AnalyticsService.formCompleted({
                ...analyticsData,
                category_name: 'Submitted Loan Details',
                button_copy: 'Submit',
                form_name: 'Mortgage Details',
                form_button: true,
                form_pre_filled: true,
            });

            if(showPurchasePriceField) {
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        }
    };

    // const valuation_ob_len = property_valuations && property_valuations?.length;
    // const latest_valuation_object: any = valuation_ob_len && property_valuations && property_valuations[valuation_ob_len - 1] || [];
    const current_value = parseFloat(currentAvgValuation);

    const validate = (fieldValue: any, name: string) => {
        if (name === 'apr') {
            let aprError = false
            let aprErrorMsg = ''
            if (fieldValue) {
                const numberFieldValue = Number(fieldValue)
                if (numberFieldValue) {
                    if (!isValidPercentage(numberFieldValue)) {
                        aprError = true
                        aprErrorMsg = 'Please enter Valid APR. 0 < APR <= 100'
                    }
                } else {
                    aprError = true
                    aprErrorMsg = 'Invalid APR'
                }
            } else {
                aprError = true
                aprErrorMsg = 'This field is required'
            }

            setErrors({ ...errors, [name]: aprError })
            setErrorMessages({ ...errorMessages, [name]: aprErrorMsg })
        } else if (name === 'loanAmount') {
            let lbError = false
            let lbErrorMsg = ''
            if (fieldValue) {
                const numberFieldValue = Number(fieldValue)
                if (!numberFieldValue) {
                    lbError = true
                    lbErrorMsg = 'Please enter valid Loan Amount'
                } else if (numberFieldValue > current_value) {
                    lbError = true
                    lbErrorMsg = 'Loan amount should be not exceed the Estimated Home Value'
                } else if (showPurchasePriceField && (numberFieldValue > Number(purchasePrice))) {
                    lbError = true
                    lbErrorMsg = 'Loan amount should be not exceed the purchasePrice'
                } else if (numberFieldValue < 0) {
                    lbError = true
                    lbErrorMsg = 'Loan amount should not be negative.'
                }
            } else {
                lbError = true
                lbErrorMsg = 'This field is required'
            }

            setErrors({ ...errors, [name]: lbError })
            setErrorMessages({ ...errorMessages, [name]: lbErrorMsg })
        } else if (name == 'term') {
            let error = false
            let errorMsg = ''
            if (!fieldValue) {
                error = true
                errorMsg = 'This field is required'
            } 

            setErrors({ ...errors, [name]: error })
            setErrorMessages({ ...errorMessages, [name]: errorMsg })
        } else if (name == 'purchasePrice') {
            let errorMsg = ''
            if (!fieldValue) {
                errorMsg = 'This field is required'
            } else if (fieldValue < loanAmount) {
                errorMsg = 'Purchase price can not be less than loan amount.'
            } else if (fieldValue < 0) {
                errorMsg = 'Loan amount should not be negative.'
            }
            setPurchasePriceError(errorMsg);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const fieldName = e.target.name;
        let label = '';

        if (fieldName === 'loanAmount') {
            const numValue = parseInt(value)
            setLoanAmount(numValue.toString());
            label = 'Original Loan Amoun'
        }
        if (fieldName === 'apr') {
            setApr(value);
            label = 'APR'
        }
        if (fieldName === 'purchasePrice') {
            setPurchasePrice(value);
            label = 'What was the purchase price of your current home?'
        }

        validate(value, fieldName);
        handleAnalytics(label, fieldName);
    };

    const currentMonth = moment().format("MMMM");
    const currentYear = parseInt(moment().format("YYYY"));

    return (
        <div className="mainDetailsContainer mortgageLoanDetailsCover">
           { showPurchasePriceField && <div className="mainContainer purchasePriceFieldCover">
                <h3 className="mainHeading">Home purchase details</h3>
                <h3 className="mainDetailsContainerHeading">What was the purchase price of your current home?</h3>
                <div className="inputFeildMain">
                    <span className="per1">$</span>
                    <input
                        type="number"
                        className="inputFeild inputFeild1"
                        name="purchasePrice"
                        onChange={handleChange}
                        value={`${purchasePrice}`}
                    />
                </div>
                {purchasePriceError.length > 0 && <span className="errMessage">{purchasePriceError}</span>}
            </div>}
            <div className="mainContainer">
                <h3 className="mainHeading">Mortgage Details</h3>
                <h3 className="mainDetailsContainerHeading">Original Loan Amount</h3>
                <div className="inputFeildMain">
                    <span className="per1">$</span>
                    <input
                        type="number"
                        className="inputFeild inputFeild1"
                        name="loanAmount"
                        onChange={handleChange}
                        value={`${loanAmount}`}
                    />
                </div>
                {errors.loanAmount && <span className="errMessage">{errorMessages.loanAmount}</span>}
            </div>

            <div className="mainContainer d-flex flex-column">
                <h3 className="mainDetailsContainerHeading">Loan Term</h3>
                <FormControl className={classes.margin}>
                    <InputLabel id="demo-customized-select-label"></InputLabel>
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={fixed}
                        onChange={(e: any) => selectFixed(e)}
                        name='term'
                        input={<BootstrapInput1 />}
                        MenuProps={menuProps}
                    >
                        {loanTermOptions.map((ele: any, i: number) => {
                            return <MenuItem key={i} value={ele.value}>{ele.label}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
                {errors.term && <span className="errMessage">{errorMessages.term}</span>}
            </div>

            <div className="mainContainer">
                <h3 className="mainDetailsContainerHeading">Loan Start Date</h3>
                <div className="flexsection">
                    <div className="monthSection">
                        <FormControl className={classes.margin}>
                            <InputLabel id="demo-customized-select-label"></InputLabel>
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={month}
                                onChange={(e) => selectMonth(e)}
                                input={<BootstrapInput />}
                                MenuProps={menuProps}
                            >
                                {monthList.map((ele: any, index: number) => {
                                    if (currentYear === parseInt(year)) {
                                        const indexOfCurrentMonth = monthList.indexOf(currentMonth);
                                        if (indexOfCurrentMonth && indexOfCurrentMonth !== -1 && index <= indexOfCurrentMonth) return <MenuItem key={index} value={ele}>{ele}</MenuItem>;
                                    }
                                    else {
                                        return <MenuItem key={index} value={ele}>{ele}</MenuItem>;
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="yearSection">
                        <FormControl className={classes.margin}>
                            <InputLabel id="demo-customized-select-label"></InputLabel>
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={year}
                                onChange={(e) => selectYear(e)}
                                input={<BootstrapInput />}
                                MenuProps={menuProps}
                            >
                                {years.map((ele: any, i: number) => {
                                    return <MenuItem key={i} value={ele.value}>{ele.label}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div className="mainContainer">
                <h3 className="mainDetailsContainerHeading">APR</h3>
                <div className="inputFeildMain">
                    <input
                        type="number"
                        className="inputFeild"
                        name='apr'
                        onChange={handleChange}
                        value={`${apr}`}
                    />
                    <span className="per">%</span>
                </div>
                {errors.apr && <span className="errMessage">{errorMessages.apr}</span>}
            </div>
           
            <div className="buttons actionButtons">
                <Button
                    className="home-warranty-sub"
                    color="#273E59"
                    border="1px solid #273E59"
                    boxShadow={'none'}
                    backgroundColor={'#fff'}
                    borderRadius="5px"
                    width="160px"
                    height="50px"
                    fontSize="16px"
                    onClick={toggleMortageModal}
                >
                    Cancel
                </Button>
                <Button
                    className="home-warranty-sub"
                    color="#FFFFFF"
                    boxShadow={'0 0 3px 0 #273E59'}
                    backgroundColor={'#273E59'}
                    borderRadius="5px"
                    width="160px"
                    height="50px"
                    fontSize="16px"
                    onClick={submitHandler}
                    // onClick={!isNotEverythingFilled ? submitHandler : () => defaultToast({ title: "Warning", type: "warning", message: "Please fill all feilds first!" })}
                    disabled={isFetchingLoanData || Object.values(errors).some(val => val === true)}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}
