import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : 24.218}
        height={props.height ? props.height : 31}
        viewBox="0 0 24.218 31"
    >
        <g data-name="Group 35697">
            <path
                data-name="Path 42874"
                d="M2.113 16.412h7.661a.664.664 0 0 1 .611.919l-3.956 9.433L16.97 14.589H9.31a.663.663 0 0 1-.611-.919l3.956-9.434Zm2.1 14.589a.663.663 0 0 1-.611-.919l5.177-12.344H.664a.663.663 0 0 1-.5-1.1L14.374.227a.663.663 0 0 1 1.112.69l-5.177 12.344h8.115a.663.663 0 0 1 .5 1.1L4.714 30.772a.662.662 0 0 1-.5.229"
                fill={window.location.pathname.includes('utilities') ? '#009CBD' : '#100b28'}
            />
        </g>
    </svg>
);

export default SvgComponent;
