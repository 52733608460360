import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, FormControl, TextField } from '@material-ui/core';

interface IinputProps {
    inputIcon?: any;
    noMargin?: any;
    imageHeight?: string;
    imageWidth?: string;
    required?: any;
    labelName?: string;
    deviceAddInput?: any;
    error?: any;
    name?: string;
    placeholder?: string;
    onChange?: any;
    type?: string;
    className?: any;
    size?: any;
    helperText?: string;
    value?: any;
    disabled?: any;
    textFieldColor?: any;
    inputProps?: any;
    imageOnClick?: any;
    InputProps?: any;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: 'Poppins',
    },
    input: {
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#7C9CBF',
        },
    },
    inputMaindiv: {
        width: '100%',
    },
}));

const CustomizedInput = (props: IinputProps) => {
    const classes = useStyles();

    //   let icon:any

    //  if (props.inputIcon) {
    //   icon = <img src={props.inputIcon} height={props.imageHeight} width={props.imageWidth} style={{paddingLeft:"10px", marginRight:"20px"}} role="button" onClick={props.imageOnClick} />;
    //  }

    return (
        <form className={classes.root} style={{ width: '100%' }} noValidate>
            <div className={`flex flex-col w-full ${classes.inputMaindiv}`}>
                <InputLabel
                    htmlFor="bootstrap-input"
                    className={props.noMargin ? 'text-black ml-2 mb-2' : 'text-black ml-2 mb-2 mt-3'}
                    style={{ fontFamily: 'Poppins', color: '#162855' }}
                >
                    {props.required ? `${props.labelName} *` : props.labelName}
                </InputLabel>
                <FormControl
                    //   className={classes.margin}
                    style={props.deviceAddInput ? { width: '88%' } : { width: '100%' }}
                    error={props.error}
                >
                    <TextField
                        id="bootstrap-input"
                        name={props.name ? props.name : ''}
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        type={props.type ? props.type : 'text'}
                        style={{ font: 'normal normal normal 14px/21px Poppins' }}
                        className={`${props.className} input-fields`}
                        size={props.size ? props.size : 'small'}
                        error={props.error}
                        required={props.required}
                        inputProps={{
                            maxLength: props.inputProps ? props.inputProps : 100,
                        }}
                        helperText={props.error && props.helperText}
                        // InputProps={{
                        //   className: classes.textInput,
                        // }}
                        // InputProps={{
                        //   startAdornment: icon
                        // }}
                        value={props.value}
                        variant="outlined"
                        disabled={props.disabled}
                        onKeyPress={e => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        // InputProps={{ classes: {input:props.textFieldColor ?classes['input']:""} }}
                        InputProps={props.InputProps ? props.InputProps : null}
                    />
                </FormControl>
            </div>
        </form>
    );
};

export default CustomizedInput;
