import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MoverAction from '../../../store/move/actions';
import * as DashboardAction from '../../../store/dashboard/actions';
import { bindActionCreators } from 'redux';
import MovingNextCard from '../../../components/atoms/MovingNextCard';
import Button from '../../../components/atoms/Button';
import MovingStorage from '../../../Assets/images/Moving/MovingStorage.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './AutoShippingThankYou.scss';
import storageD from '../../../Assets/images/DouglasImages/dashboardIcons/storageD.png';
import { buttonClicked } from '../../../utils/utlis';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';

type initialProps = {
    location: any;
    history: any;
    moversAction?: any;
    moversState?: any;
    dashboardAction: any;
    CheckListDashboardState: any;
};

type initialState = {
    serviceId: string;
    isDataArrived: boolean;
    isDataArrived1: boolean;
    checkedItems: any;
    completedMovingPercentage: any;
    vehicleDetails: any;
    destinationZip: string;
    destinationAddress: string;
    pickupZip: string;
    pickupAddress: string;
    transportType: string;
    isShowPlan: boolean;
    pickupDate: any;
    isStateDataArrived: boolean;
    isDouglasElliman: boolean;
};

class AutoShippingThankYou extends Component<initialProps, initialState> {
    state: initialState = {
        serviceId: '',
        isDataArrived: true,
        isDataArrived1: true,
        checkedItems: [],
        completedMovingPercentage: '',
        vehicleDetails: [],
        destinationZip: '',
        destinationAddress: '',
        pickupZip: '',
        pickupAddress: '',
        transportType: '',
        isShowPlan: true,
        isStateDataArrived: true,
        isDouglasElliman: false,
        pickupDate: '',
    };

    componentDidMount() {
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        if (isDouglasElliman) {
            this.setState({
                isDouglasElliman: true,
            });
        }
        this.props.moversAction.selectedMovers();
        this.props.dashboardAction.checklist();
    }

    public static getDerivedStateFromProps(nextProps: initialProps, currentState: initialState) {
        if (
            nextProps &&
            nextProps.history &&
            nextProps.history.location &&
            nextProps.history.location.state &&
            currentState.isStateDataArrived
        ) {
            currentState.isStateDataArrived = false;
            currentState.vehicleDetails = nextProps.history.location.state.vehicleDetails;
            currentState.destinationZip = nextProps.history.location.state.destinationZip;
            currentState.destinationAddress = nextProps.history.location.state.destinationAddress;
            currentState.pickupZip = nextProps.history.location.state.pickupZip;
            currentState.pickupAddress = nextProps.history.location.state.pickupAddress;
            currentState.transportType = nextProps.history.location.state.transportType;
            currentState.pickupDate = nextProps.history.location.state.pickupDate;
        }

        return currentState;
    }

    backToDashboard = () => {
        this.props.history.push({
            pathname: '/dashboard/movers',
            // state: {
            //     event: 'start',
            // },
        });
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        let brokerage: any;

        brokerage = slug.display_name;
        let brokerage_key: any = slug.brokerage_key;
        let agent_key: any = slug.realtor_key;

        let agent_name: any = slug.contact_name;
        let move_id: any = localStorage.getItem('moveKey');

        buttonClicked(
            'Button Clicked',
            'Auto Shipping Thankyou',
            brokerage,
            brokerage_key,
            agent_name,
            agent_key,
            'Moving Services',
            'Back to Dashboard',
            false,
            '',
            false,
            '',
            '',
            false,
            '',
            false,
            '',
            '',
            move_id,
        );
    };

    expandMore = () => {
        this.setState({ isShowPlan: !this.state.isShowPlan });
    };

    render() {
        let slug: any = localStorage.getItem('slug');
        if (slug) {
            slug = JSON.parse(slug);
        }
        const { isDouglasElliman } = this.state;

        const breadList = [
            {
                name: 'Moving Services',
                url: '/dashboard/movers'
            },
            {
                name: 'Auto shipping',
                url: '/dashboard/autoease'
            },
            {
                name: 'Order Placing',
                url: '/dashboard/autoease/order_placing',
                redirectStates: this.props.history.location.state
            },
            {
                name: 'Order Summary',
                url: ''
            },
        ]

        return (
            <div
                className={
                    isDouglasElliman ? 'autoshipping-main-page moving-main-pageDouglas' : 'autoshipping-main-page'
                }
            >
                <div
                    className={
                        isDouglasElliman ? 'main-head-moving-main moving-main-pageDouglas' : 'main-head-moving-main'
                    }
                >
                    <h2 className="heading">Moving Services</h2>
                    <BreadCrumb breadList={breadList} />
                </div>

                <div className="autoShippingTabs">
                    <div className="tab-div-moving">
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/movers' ||
                                    this.props.history.location.pathname === '/dashboard/movers/recommended' ||
                                    this.props.history.location.pathname === '/dashboard/movers/quote' ||
                                    this.props.history.location.pathname === '/dashboard/movers/thankyou')
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                            onClick={() => {
                                if (
                                    this.state.completedMovingPercentage >= 60 &&
                                    this.state.completedMovingPercentage < 100
                                ) {
                                    this.props.history.push('/dashboard/movers/recommended');
                                }
                                if (this.state.completedMovingPercentage == 100) {
                                    this.props.history.push({
                                        pathname: '/dashboard/movers/thankyou',
                                        state: {
                                            checkedItems: this.state.checkedItems,
                                        },
                                    });
                                }
                                if (this.state.completedMovingPercentage < 60) {
                                    this.props.history.push('/dashboard/movers');
                                }
                                window.scrollTo(0, 0);
                            }}
                        >
                            Moving
                        </div>
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/autoease' ||
                                    this.props.history.location.pathname === `/dashboard/autoease/thank_you`)
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                        >
                            Auto Shipping
                        </div>
                        <div
                            className={
                                this.props &&
                                this.props.history &&
                                this.props.history.location &&
                                this.props.history.location.pathname &&
                                (this.props.history.location.pathname === '/dashboard/storage' ||
                                    this.props.history.location.pathname === `/dashboard/storage/`)
                                    ? 'tab-active'
                                    : 'tab-text'
                            }
                            onClick={() => {
                                this.props.history.push('/dashboard/storage');
                                window.scrollTo(0, 0);
                            }}
                        >
                            Reserve a Storage Unit
                        </div>
                    </div>
                </div>

                <div className="autoshippingThankYouPage">
                    <div className="thankYouText">Done, thank you for your interest</div>
                    <div className="thankYouSubText">Your dedicated auto shippers will call you in 24 hrs.</div>

                    <div className="autoshippingThankYouPlanDiv">
                        <div className="thankyouPlanHeading">
                            Plan details <ExpandMoreIcon className="expandIcon" onClick={this.expandMore} />
                        </div>
                        {this.state.isShowPlan ? (
                            <div className="planFlexDiv">
                                <div className="dest">
                                    <div className="destHeading">Move details</div>
                                    <div className="destText">{`From - ${this.state.pickupAddress}`}</div>
                                    <div className="destText">{`To - ${this.state.destinationAddress}`}</div>
                                    <div className="destText">{`Transport Type - ${this.state.transportType}`}</div>
                                </div>
                                <div className="vehicle">
                                    <div className="destHeading">Vehical details</div>
                                    {this.state.vehicleDetails &&
                                        this.state.vehicleDetails.map((vehicleDetails: any, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        borderBottom:
                                                            this.state.vehicleDetails.length - 1 === index
                                                                ? 'none'
                                                                : '1px solid #C6C6C6',
                                                    }}
                                                >
                                                    <div className="destText">{`Vehicle make - ${vehicleDetails.make}`}</div>
                                                    <div className="destText">{`Model - ${vehicleDetails.model}`}</div>
                                                    <div className="destText">{`Operable - ${vehicleDetails.operable}`}</div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="whatNextDiv">
                        <div className="what-next">What's Next?</div>

                        <div style={{ marginTop: '20px' }}>
                            <MovingNextCard
                                title="Get Storage Facility"
                                subTitle="Best quotes on storage facility guaranteed"
                                icon={isDouglasElliman ? storageD : MovingStorage}
                                buttonText="Learn More"
                                buttonColor={slug && slug.primary_color ? slug.primary_color : '#002E60'}
                                height="140px"
                                padding="0px 0px 15px 0px"
                                buttonBackGroundColor="#fff"
                                buttonShadow={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? `0 0 3px 0 ${slug.primary_color}`
                                        : '0 0 3px 0 #273E59'
                                }
                                onClick={() => {
                                    this.props.history.push('/dashboard/storage');

                                    let slug: any = localStorage.getItem('slug');
                                    slug = JSON.parse(slug);

                                    let brokerage: any;
                                    brokerage = slug.display_name;
                                    let brokerage_key: any = slug.brokerage_key;
                                    let agent_key: any = slug.realtor_key;

                                    let agent_name: any = slug.contact_name;
                                    let move_id: any = localStorage.getItem('moveKey');
                                    buttonClicked(
                                        'Button Clicked',
                                        'Auto Shipping Thankyou',
                                        brokerage,
                                        brokerage_key,
                                        agent_name,
                                        agent_key,
                                        'Moving Services',
                                        'Learn More',
                                        false,
                                        '',
                                        true,
                                        'Get Storage Facility',
                                        'Moving Next Card',
                                        false,
                                        '',
                                        false,
                                        '',
                                        '',
                                        move_id,
                                    );
                                }}
                            />
                        </div>
                        <div className="btn-wrapper">
                            <Button
                                backgroundColor={
                                    isDouglasElliman
                                        ? '#100B28'
                                        : slug && slug.primary_color
                                        ? slug.primary_color
                                        : '#002E60'
                                }
                                // color="#FFFFFF"
                                fontSize="16px"
                                onClick={() => {
                                    this.backToDashboard();
                                }}
                            >
                                Back to Dashboard
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        moversAction: bindActionCreators(MoverAction, dispatch),
        dashboardAction: bindActionCreators(DashboardAction, dispatch),
    };
}

const mapStateToProps = (state: any) => ({
    moversState: state.move,
    CheckListDashboardState: state.checklistDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoShippingThankYou);
