import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    loginFailure,
    loginSuccess,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordDataFailure,
    resetPasswordDataSuccess,
    setPasswordSuccess,
    setPasswordFailure,
    setPasswordDataFailure,
    setPasswordDataSuccess,
} from './actions';
import { LOGIN, RESET_PASSWORD, RESET_PASSWORD_DATA, SET_PASSWORD, SET_PASSWORD_DATA } from './actionTypes';
import apiJunction from '../../utils/api';
import { Store } from 'react-notifications-component';
import { getErrorMessage } from '../errorHandler';
// import history from '../../routes/History';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}

function* login(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.login, {
            method: 'post',
            url: `/api/accounts/token-auth/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
            }
            yield put(
                loginSuccess({
                    token: response.data,
                }),
            );
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        }
    } catch (e: any) {
        yield put(
            loginFailure({
                error: getErrorMessage(e),
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.non_field_errors && e.response.data.non_field_errors[0]
                    ? e.response.data.non_field_errors[0]
                    : getErrorMessage(e),
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
    }
}

function* ResetPassword(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/accounts/users/send_forgot_password_email/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(
                resetPasswordSuccess({
                    resetPassword: response.data,
                }),
            );
            Store.addNotification({
                title: 'Success',
                message: 'Email sent successfully',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(
            resetPasswordFailure({
                errorResetPassword: getErrorMessage(e),
            }),
        );
        Store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

//Reset Password Data
function* ResetPasswordData(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/accounts/users/set_password/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(
                resetPasswordDataSuccess({
                    resetPasswordData: response.data,
                }),
            );
            Store.addNotification({
                title: 'Success',
                message: 'Password Reset successful!',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(
            resetPasswordDataFailure({
                errorResetPasswordData: getErrorMessage(e),
            }),
        );
        Store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

//set password
function* SetPassword(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/accounts/users/user_with_hash_exists/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(
                setPasswordSuccess({
                    setPassword: response.data,
                }),
            );
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(
            setPasswordFailure({
                error: getErrorMessage(e),
            }),
        );
        Store.addNotification({
            title: 'Error',
            message:
                e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Error Occured',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

//set password data
function* SetPasswordData(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `api/accounts/users/set_password_realtor/`,
            body: action.payload,
        });
        if (response.data && response.status === 200) {
            yield put(
                setPasswordDataSuccess({
                    setPasswordData: response.data,
                }),
            );
            Store.addNotification({
                title: 'Success',
                message: 'Password Reset successful!',
                type: 'success',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
        } else {
            Store.addNotification({
                title: 'Error',
                message: 'Error Occurred!',
                type: 'danger',
                insert: 'bottom',
                container: 'bottom-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            // history.push('/bad-request');
        }
    } catch (e: any) {
        yield put(
            setPasswordDataFailure({
                error: getErrorMessage(e),
            }),
        );
        Store.addNotification({
            title: 'Error',
            message: e.response && e.response.data && e.response.data.message,
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        });
        // history.push('/server-error');
    }
}

function* loginSaga() {
    yield all([takeLatest(LOGIN, login)]);
    yield all([takeLatest(RESET_PASSWORD, ResetPassword)]);
    yield all([takeLatest(RESET_PASSWORD_DATA, ResetPasswordData)]);
    yield all([takeLatest(SET_PASSWORD, SetPassword)]);
    yield all([takeLatest(SET_PASSWORD_DATA, SetPasswordData)]);
}

export default loginSaga;
