const BasicTableData = [
    {
        project: 'Entry Door Replacement',
        cost: '$2K - $3k',
        increaseCost: '$1k - $2k',
        per: '64%',
        payment: '$42',
        amt: 2,
    },
    {
        project: 'Garage Door Replacement',
        cost: '$4K - $5k',
        increaseCost: '$3k - $4k',
        per: '93%',
        payment: '$85',
        amt: 4,
    },

    {
        project: 'Deck Addition',
        cost: '$19K - $20k',
        increaseCost: '$12k - $13k',
        per: '65%',
        payment: '$300',
        amt: 19,
    },

    {
        project: 'Bath Remodel',
        cost: '$27K - $28k',
        increaseCost: '$15k - $16k',
        per: '60%',
        payment: '$270',
        amt: 27,
    },
    {
        project: 'Major Kitchen Remodel',
        cost: '$80K - $81k',
        increaseCost: '$45k - $46k',
        per: '56%',
        payment: '$799',
        amt: 80,
    },
];

export default BasicTableData;
