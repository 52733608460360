import {
    POST_FOLDERS,
    POST_FOLDERS_ERROR,
    POST_FOLDERS_SUCCESS,
    GET_FOLDERS,
    GET_FOLDERS_ERROR,
    GET_FOLDERS_SUCCESS,
    POST_DOCUMENTS,
    POST_DOCUMENTS_ERROR,
    POST_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS,
    GET_DOCUMENTS_ERROR,
    GET_DOCUMENTS_SUCCESS,
    UPDATE_DOCUMENTS,
    UPDATE_DOCUMENTS_ERROR,
    UPDATE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS,
    DELETE_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_SUCCESS,
} from './actionTypes';
import * as types from './types';

export const postFolders = (payload: types.PostFoldersRequest): types.PostFolders => ({
    type: POST_FOLDERS,
    payload,
});
export const postFoldersSuccess = (payload: types.PostFoldersSuccessPayload): types.PostFoldersSuccess => ({
    type: POST_FOLDERS_SUCCESS,
    payload,
});
export const postFoldersFailure = (payload: types.PostFoldersFailurePayload): types.PostFoldersFailure => ({
    type: POST_FOLDERS_ERROR,
    payload,
});

export const getFolders = (payload: types.GetFoldersRequest): types.GetFolders => ({
    type: GET_FOLDERS,
    payload,
});
export const getFoldersSuccess = (payload: types.GetFoldersSuccessPayload): types.GetFoldersSuccess => ({
    type: GET_FOLDERS_SUCCESS,
    payload,
});
export const getFoldersFailure = (payload: types.GetFoldersFailurePayload): types.GetFoldersFailure => ({
    type: GET_FOLDERS_ERROR,
    payload,
});

export const postDocuments = (payload: types.PostDocumentsRequest): types.PostDocuments => ({
    type: POST_DOCUMENTS,
    payload,
});
export const postDocumentsSuccess = (payload: types.PostDocumentsSuccessPayload): types.PostDocumentsSuccess => ({
    type: POST_DOCUMENTS_SUCCESS,
    payload,
});
export const postDocumentsFailure = (payload: types.PostDocumentsFailurePayload): types.PostDocumentsFailure => ({
    type: POST_DOCUMENTS_ERROR,
    payload,
});

export const getDocuments = (payload: types.GetDocumentsRequest): types.GetDocuments => ({
    type: GET_DOCUMENTS,
    payload,
});
export const getDocumentsSuccess = (payload: types.GetDocumentsSuccessPayload): types.GetDocumentsSuccess => ({
    type: GET_DOCUMENTS_SUCCESS,
    payload,
});
export const getDocumentsFailure = (payload: types.GetDocumentsFailurePayload): types.GetDocumentsFailure => ({
    type: GET_DOCUMENTS_ERROR,
    payload,
});

export const updateDocuments = (payload: types.UpdateDocumentsRequest): types.UpdateDocuments => ({
    type: UPDATE_DOCUMENTS,
    payload,
});
export const updateDocumentsSuccess = (payload: types.UpdateDocumentsSuccessPayload): types.UpdateDocumentsSuccess => ({
    type: UPDATE_DOCUMENTS_SUCCESS,
    payload,
});
export const updateDocumentsFailure = (payload: types.UpdateDocumentsFailurePayload): types.UpdateDocumentsFailure => ({
    type: UPDATE_DOCUMENTS_ERROR,
    payload,
});

export const deleteDocuments = (payload: types.DeleteDocumentsRequest): types.DeleteDocuments => ({
    type: DELETE_DOCUMENTS,
    payload,
});
export const deleteDocumentsSuccess = (payload: types.DeleteDocumentsSuccessPayload): types.DeleteDocumentsSuccess => ({
    type: DELETE_DOCUMENTS_SUCCESS,
    payload,
});
export const deleteDocumentsFailure = (payload: types.DeleteDocumentsFailurePayload): types.DeleteDocumentsFailure => ({
    type: DELETE_DOCUMENTS_ERROR,
    payload,
});
