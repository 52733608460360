import React, { Component } from 'react';
import './checklistCard.scss';
import Button from '../Button';
import CheckIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';

type initialProps = {
    mainData?: string;
    onClick?: any;
    reminderText?: string;
    doneClicked?: any;
    reminderClicked?: any;
    editClicked?: any;
    disabled?: any;
};

type initialState = {};

export class checklistCard extends Component<initialProps, initialState> {
    render() {
        let slug: any = localStorage.getItem('slug');
        slug = JSON.parse(slug);
        let isDouglasElliman: boolean = localStorage.getItem('isDouglasElliman') ? true : false;
        return (
            <div className={isDouglasElliman ? 'AddTaskDivChecklistDoug' : 'AddTaskDivChecklist'}>
                <div className="cardInnerContentChecklist">
                    <div className="cardContentChecklist">
                        <div className="upperContentChecklist">
                            <div className="upperContentMainChecklist">{this.props.mainData}</div>
                        </div>
                    </div>

                    <div className="checklistCardHover">
                        <div className="checklistDone" onClick={this.props.doneClicked}>
                            <div className="circleDiv">
                                <CheckIcon />
                            </div>
                            <span className="textChecklistCard">Done</span>
                        </div>
                        <div className="checklistDone" onClick={this.props.reminderClicked}>
                            <div className="circleDiv">
                                <EventIcon />
                            </div>
                            <span className="textChecklistCard">{this.props.reminderText}</span>
                        </div>
                        <div className="checklistDone" onClick={this.props.editClicked}>
                            <div className="circleDiv">
                                <EditIcon />
                            </div>
                            <span className="textChecklistCard">Edit</span>
                        </div>
                    </div>

                    <div className={'buttonCardChecklist'}>
                        <Button
                            width="100%"
                            // color="#FFFFFF"
                            backgroundColor={
                                isDouglasElliman
                                    ? '#100B28'
                                    : slug && slug.primary_color
                                    ? slug.primary_color
                                    : '#273e59'
                            }
                            borderRadius="5px"
                            fontSize="16px"
                            border={`1px solid ${slug && slug.primary_color ? slug.primary_color : '#273e59'}`}
                            onClick={() => this.props.onClick()}
                            disabled={this.props.disabled}
                        >
                            Complete Now
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default checklistCard;
