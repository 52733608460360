import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    postServiceFailure,
    postServiceSuccess,
    getServiceProviderFailure,
    getServiceProviderSuccess,
    getDigitalServiceProviderFailure,
    getDigitalServiceProviderSuccess,
    getInsuranceProviderFailure,
    getInsuranceProviderSuccess,
} from './actions';
import {
    POST_SERVICE,
    GET_SERVICE_PROVIDER,
    GET_DIGITAL_SERVICE_PROVIDER,
    GET_INSURANCE_PROVIDER,
} from './actionTypes';
import apiJunction from '../../utils/api';

export interface ResponseGenerator {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}
function* postService(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'post',
            url: `/api/move/move-savings-data/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(postServiceSuccess({ postService: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(postServiceFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getServiceProvider(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/security-provider-list/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getServiceProviderSuccess({ getServiceProvider: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getServiceProviderFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

function* getDigitalServiceProvider(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/digital-service-provider/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getDigitalServiceProviderSuccess({ getDigitalServiceProvider: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getDigitalServiceProviderFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}
function* getInsuranceProvider(action: any) {
    try {
        const response: ResponseGenerator = yield call(apiJunction.makeRequest, {
            method: 'get',
            url: `/api/services/insurance-provider-list/`,
            body: action.payload,
        });
        if (response.data) {
            yield put(getInsuranceProviderSuccess({ getInsuranceProvider: response.data }));
        } else {
            // history.push('/bad-request');
        }
    } catch (e) {
        yield put(getInsuranceProviderFailure({ error: (e as Error).message }));
        // history.push('/server-error');
    }
}

export default function* serviceSaga() {
    yield all([
        takeLatest(POST_SERVICE, postService),
        takeLatest(GET_SERVICE_PROVIDER, getServiceProvider),
        takeLatest(GET_DIGITAL_SERVICE_PROVIDER, getDigitalServiceProvider),
        takeLatest(GET_INSURANCE_PROVIDER, getInsuranceProvider),
    ]);
}
