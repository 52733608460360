import * as React from 'react';

type initialProps = {
    checked?: boolean;
    height?: any;
    width?: any;
    fromlanding ? : any;
};

function SvgComponent(props: initialProps) {
    let slug: any = localStorage.getItem('slug');
    if (slug) {
        slug = JSON.parse(slug);
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 77.518}
            height={props.height ? props.height : 71}
            viewBox="0 0 77.518 71"
            className="checkBoximg"
        >
            <defs>
                <style>
                    {`.internetStepprefix__a{fill:${
                        props.fromlanding && props.checked ? '#30AB86' :  slug && slug.primary_color && props.checked ? slug.primary_color : '#6b6c6f'
                    }}`}
                </style>
            </defs>
            <path
                className="internetStepprefix__a"
                d="M76.934 17.631a2.1 2.1 0 00-.067-2.971A53.926 53.926 0 0038.74.003 57.212 57.212 0 0018.426 3.92 54.047 54.047 0 00.657 15.203a2.1 2.1 0 102.9 3.039A51.424 51.424 0 0138.784 4.207h.561a49.669 49.669 0 0134.617 13.491 2.1 2.1 0 002.972-.067zm0 0M63.843 33.954a2.1 2.1 0 00-.056-2.972 34.955 34.955 0 00-25.022-9.7 36.949 36.949 0 00-25.021 10.236 2.1 2.1 0 102.9 3.039 32.759 32.759 0 0122.184-9.072 30.8 30.8 0 0122.039 8.523 2.1 2.1 0 002.976-.054zm0 0M51.424 49.721a2.1 2.1 0 00-.067-2.971 17.2 17.2 0 00-12.619-4.661 18.94 18.94 0 00-12.477 5.2 2.1 2.1 0 002.9 3.039 14.943 14.943 0 019.714-4.043 13.049 13.049 0 019.573 3.5 2.1 2.1 0 002.976-.064zm0 0M46.707 63.059a7.941 7.941 0 10-7.944 7.938 7.941 7.941 0 007.944-7.938zm-7.941 3.737a3.737 3.737 0 113.737-3.737 3.737 3.737 0 01-3.74 3.738zm0 0"
            />
        </svg>
    );
}

export default SvgComponent;
